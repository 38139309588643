import React from "react";
import { Button } from "../../../../../components";
import { useTranslations } from "../../../../../util";

export default function AddBomComponentsButton(props: {
  isDisabled?: boolean;
  onClick: () => void;
}) {
  const { isDisabled, onClick } = props;

  const tr = useTranslations();

  return (
    <Button
      isDense
      isDisabled={isDisabled}
      onClick={onClick}
      intent="secondary"
      icon="new-link"
    >
      {tr.translate("product.bom.components.new")}
    </Button>
  );
}
