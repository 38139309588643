import { PropertyType } from "@aletiq/types";
import { IconName, MaybeElement } from "@blueprintjs/core";
import React from "react";
import { useTranslations } from "../../util";
import { SingleSelectionIcon } from "../icons";

export type PropertyDetails = {
  type: PropertyType;
  label: string;
  icon: IconName | MaybeElement;
};

export const PROPERTY_TYPES: PropertyDetails[] = [
  {
    type: "multi-enum",
    label: "admin.attribute.new.type.multi-selection",
    icon: "properties",
  },
  {
    type: "enum",
    label: "admin.attribute.new.type.single-selection",
    icon: <SingleSelectionIcon />,
  },
  {
    type: "string",
    label: "admin.attribute.new.type.text",
    icon: "align-left",
  },
  {
    type: "number",
    label: "admin.attribute.new.type.number",
    icon: "numerical",
  },
  {
    type: "user",
    label: "admin.attribute.new.type.user",
    icon: "user",
  },
  {
    type: "date",
    label: "admin.attribute.new.type.date",
    icon: "calendar",
  },
];

export function PropertyTypeLabel(props: { type: PropertyType }) {
  const { type } = props;
  const tr = useTranslations();

  const selectedType = PROPERTY_TYPES.find((p) => p.type === type);

  return tr.translate(selectedType?.label ?? "");
}
