import { User } from "@aletiq/types";
import React, { useState } from "react";
import { useUsers } from "../../hooks";
import { useTranslations } from "../../util";
import { default as UserName } from "../User/User";
import { UserSingleSelect } from "../UserSingleSelect";
import { EditablePropertyButtonGroup } from "./buttons";
import EditablePropertyTemplate from "./EditablePropertyTemplate";

export default function EditableOwner(props: {
  initialOwner: number;
  allowedOwners?: User[];
  onSubmit: (newOwner: number) => void;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  inline?: boolean;
  minWidth?: number;
}) {
  const {
    isDisabled,
    isSubmitting,
    initialOwner,
    allowedOwners,
    onSubmit,
    inline,
    minWidth,
  } = props;
  const tr = useTranslations();

  const [isEditing, setIsEditing] = useState(false);
  const [editedOwner, setEditedOwner] = useState(initialOwner);

  const { data: allUsers = [] } = useUsers();

  const handleSubmit = () => {
    if (editedOwner !== initialOwner) {
      onSubmit(editedOwner);
    }
    toggleEditOwner();
  };

  const handleReset = () => {
    setEditedOwner(initialOwner);
    toggleEditOwner();
  };

  const toggleEditOwner = () => {
    setIsEditing(!isEditing);
  };

  return (
    <EditablePropertyTemplate
      label={tr.translateAsString("admin.attribute.documents.owner")}
      isEditable={!isDisabled}
      triggerEditMode={toggleEditOwner}
      isInEditMode={isEditing}
      intent="default"
      inline={inline}
      minWidth={minWidth}
    >
      {!isEditing && (
        <UserName userId={initialOwner} size="small" intent="default" />
      )}
      {isEditing && (
        <>
          <UserSingleSelect
            isFill
            users={allowedOwners ?? allUsers}
            selectedUser={editedOwner}
            onSubmit={setEditedOwner}
          />
          <EditablePropertyButtonGroup
            onSubmit={handleSubmit}
            onReset={handleReset}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </EditablePropertyTemplate>
  );
}
