import { SortDirection } from "@aletiq/types";
import sortBy from "./sortBy";

export default function sortByString<T>(
  values: T[],
  key: (value: T) => string,
  order?: SortDirection
) {
  return sortBy(values, key, emptyLastCompare, order);
}

function emptyLastCompare(s1: string, s2: string) {
  if (s1 === "" && s2 === "") return 0;
  if (s1 === "") return 1;
  if (s2 === "") return -1;
  return s1.localeCompare(s2);
}
