import { API } from "@aletiq/api";
import {
  ProjectField,
  PropertyValue,
  PropertyValueUpdate,
} from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { projectKeys } from "./queries";

export default function useDuplicateProject() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (projectId: number) => {
      const project = await api.project.getProject(projectId);
      const definitionIdx = project.lastDefinition.index;
      const propertyValues = copyProductProperties(project.properties);
      const copy = await api.project.createProject({
        name:
          project.name +
          " - " +
          tr.translateAsString("project.actions.duplicate.copy"),
        description: project.description,
        isTool: project.isTool,
        isArticle: project.isArticle,
        properties: propertyValues,
        definition: {
          name: project.lastDefinition.name,
        },
      });

      await copyProjectImage(api, projectId, copy.id);

      await copyProjectBOM(api, projectId, definitionIdx, copy.id);

      await copyProjectTooling(api, projectId, copy.id);

      await copyProjectDocuments(api, projectId, copy.id);

      await copyProjectParts(api, projectId, copy.id);

      await copyLastOperationBill(api, projectId, copy.id);

      await copyProjectOptions(api, project.options, copy.id);

      return copy.id;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(projectKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}

async function copyProjectImage(
  api: API,
  projectId: number,
  destination: number
) {
  const image = await api.project.getProjectImage(projectId);
  if (image) {
    api.project.setProjectImage(destination, image);
  }
}

async function copyProjectBOM(
  api: API,
  projectId: number,
  definitionIdx: number,
  copy: number
) {
  const entries = await api.project.getProjectBOM(projectId, definitionIdx);
  for (const entry of entries) {
    await api.project.addBOMEntry(copy, 1, entry);
  }
}

async function copyProjectTooling(
  api: API,
  projectId: number,
  destination: number
) {
  const projectTooling = await api.project.listToolingRequirements(projectId);
  for (const toolingEntry of projectTooling) {
    await api.project.addToolingRequirement(destination, {
      tool: toolingEntry.tool,
      quantity: toolingEntry.quantity,
      comment: toolingEntry.comment,
    });
  }
}

async function copyProjectDocuments(
  api: API,
  projectId: number,
  destination: number
) {
  const projectDocuments = await api.document.listDocuments({
    projects: [projectId],
  });
  const projectDocumentIds = projectDocuments.list.map((d) => d.id);
  await api.document.addProjectDocuments(destination, projectDocumentIds);
}

async function copyProjectParts(
  api: API,
  projectId: number,
  destination: number
) {
  let parts: number[] = [];
  let partsPage = await api.pdm.listPartsPage({
    projects: [projectId],
    limit: 100,
  });
  while (partsPage.list.length > 0) {
    parts = parts.concat(partsPage.list.map((p) => p.id));
    partsPage = await api.pdm.listPartsPage({
      projects: [projectId],
      limit: 100,
    });
  }

  for (const partId of parts) {
    await api.pdm.updatePartProjects(partId, [destination]);
  }
}

async function copyLastOperationBill(
  api: API,
  source: number,
  destination: number
) {
  const operationBills = await api.operations.listProjectOperations(source);
  const lastBill = operationBills[0];
  if (lastBill) {
    const billCopy = await api.operations.createProjectOperationBill(
      destination,
      {
        index: lastBill.index,
        description: lastBill.description,
        branch: lastBill.branch,
      }
    );

    for (const operation of lastBill.operations) {
      await api.operations.addProjectOperation(destination, billCopy, {
        number: operation.number,
        name: operation.name,
        description: operation.description,
      });

      for (const document of operation.documents) {
        await api.operations.addProjectOperationDocument(
          destination,
          billCopy,
          operation.number,
          document.document,
          document.revision.type === "specific" && document.revision.revision
            ? { type: "specific", revision: document.revision.revision.id }
            : { type: "last" }
        );
      }
    }
  }
}

async function copyProjectOptions(
  api: API,
  options: ProjectField[],
  destination: number
) {
  for (const option of options) {
    const optionCopy = await api.project.addProjectOptionField(destination, {
      name: option.name,
      description: option.description,
      isMandatory: option.isMandatory,
      allowMultiValues: option.allowMultiValues,
    });

    for (const optionValue of option.values) {
      if (optionValue.isDeleted) {
        continue;
      }
      await api.project.updateProjectOptionField(destination, optionCopy, [
        {
          type: "addValue",
          value: {
            name: optionValue.name,
            isDefault: optionValue.isDefault,
            isDeleted: false,
          },
        },
      ]);
    }
  }
}

function copyProductProperties(
  properties: Record<string, PropertyValue>
): PropertyValueUpdate[] {
  const propertyValues = Object.keys(properties).map((property) => {
    return {
      property,
      value: properties[property],
    };
  });

  return propertyValues;
}
