import { PostProperty, PropertyObject } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { activityKeys } from "../../../activities/hooks/queries";
import { propertyKeys } from "./queries";

export default function useUpdateCustomProperty(objectType: PropertyObject) {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    (params: { propertyName: string; update: PostProperty }) =>
      api.property.updateProperty(
        objectType,
        params.propertyName,
        params.update
      ),
    {
      onSuccess: ({ propertyName }) => {
        queryClient.invalidateQueries(
          propertyKeys.byNameId(objectType, propertyName)
        );
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.custom-property.update"),
        });
      },
    }
  );
}
