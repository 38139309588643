import { EntityId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { commentKeys } from "./queries";

export default function useDeleteComment(entityId: EntityId) {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    (commentId: number) => api.comment.deleteComment(commentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(commentKeys.byEntity(entityId));
      },
    }
  );
}
