import React, { useState } from "react";
import { Button, Subtitle } from "../../../../components";
import { useTranslations } from "../../../../util";
import {
  DeleteProjectComponentsDialog,
  EditComponentQuantitiesDialog,
} from "../../dialogs";
import { ProductTree } from "../hooks";

export default function ComponentsBatchActionHeader(props: {
  selectedComponents: ProductTree[];
  canEditComponent?: boolean;
  canDeleteComponent?: boolean;
}) {
  const {
    selectedComponents,
    canEditComponent = true,
    canDeleteComponent = true,
  } = props;
  const tr = useTranslations();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editQuantitiesDialog, setEditQuantitiesDialog] = useState(false);

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const toggleEditQtyDialog = () => {
    setEditQuantitiesDialog(!editQuantitiesDialog);
  };

  return (
    <>
      <Subtitle
        text={tr.translateAsString("product.bom.components.selected", {
          length: selectedComponents.length,
        })}
      />
      {canDeleteComponent && (
        <Button isDense icon="trash" onClick={toggleDeleteDialog}>
          {tr.translate("generic.action.delete")}
        </Button>
      )}

      {canEditComponent && (
        <Button isDense icon="edit" onClick={toggleEditQtyDialog}>
          {tr.translate("product.bom.components.edit-qty")}
        </Button>
      )}

      {showDeleteDialog && (
        <DeleteProjectComponentsDialog
          components={selectedComponents}
          onClose={toggleDeleteDialog}
        />
      )}

      {editQuantitiesDialog && (
        <EditComponentQuantitiesDialog
          components={selectedComponents}
          onClose={toggleEditQtyDialog}
        />
      )}
    </>
  );
}
