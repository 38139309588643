import { Icon, Tooltip } from "@aletiq/design-system";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import React from "react";
import { useTranslations } from "../../../../../util";
import styles from "./ModelAnalysisCard.module.scss";

export default function ModelAnalysisCard(props: {
  title: string;
  tooltipText: string;
  icon: IconName;
  isLoading: boolean;
  isEmptyState: boolean;
  children: React.ReactNode;
}) {
  const { title, tooltipText, icon, isLoading, isEmptyState, children } = props;
  const tr = useTranslations();

  return (
    <div className={styles.wrapper}>
      <Tooltip
        content={<span className={styles.inner_tooltip}>{tooltipText}</span>}
        position="top-left"
      >
        <div className={styles.header}>
          <Icon icon={icon} inline size={20} />
          {title}
        </div>
      </Tooltip>
      <div className={styles.card}>
        {!isLoading && !isEmptyState && (
          <div className={styles.inner}>{children}</div>
        )}
        {!isLoading && isEmptyState && (
          <NonIdealState
            description={tr.translateAsString("analysis.card.empty-state")}
            icon={<img src="/assets/no-data.svg" height={80} alt="no data" />}
            className={styles.no_data}
          />
        )}
        {isLoading && (
          <div className={styles.center}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
