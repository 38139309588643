import { Project, ProjectField } from "@aletiq/types";
import React, { useState } from "react";
import { ConfigOptionIcon, Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import { PassportConfigurationsInput } from "../../common";
import { useUpdatePassportsConfigs } from "../../hooks";
import styles from "./UpdatePassportsConfigsDialog.module.scss";

export default function UpdatePassportConfigDialog(props: {
  passportsIds: number[];
  parentProduct: Project;
  onClose: () => void;
}) {
  const { passportsIds, parentProduct, onClose } = props;
  const tr = useTranslations();

  const configSpec = parentProduct?.options || [];
  const [updatedConfig, setUpdatedConfig] = useState<Record<number, number[]>>(
    productFieldsToConfig(configSpec)
  );

  const { mutateAsync: setPassportsConfigs, isLoading } =
    useUpdatePassportsConfigs();

  const handleSaveChanges = async () => {
    setPassportsConfigs({
      passportsIds,
      configSpec,
      configValues: updatedConfig,
    });
    onClose();
  };

  const handleUpdateConfig = (fieldId: number, valueIds: number[]) => {
    setUpdatedConfig({ ...updatedConfig, [fieldId]: valueIds });
  };

  return (
    <Dialog
      isOpen
      className={styles.dialog}
      title={tr.translateAsString("passport.dialogs.update-configs.title", {
        count: passportsIds.length,
      })}
      icon={<ConfigOptionIcon />}
      onClose={onClose}
      onSecondaryClick={onClose}
      onPrimaryClick={handleSaveChanges}
      submitting={isLoading}
    >
      <p>{tr.translate("passport.dialogs.update-configs.subtitle")}</p>
      <PassportConfigurationsInput
        configSpec={configSpec}
        config={updatedConfig}
        onSetConfig={handleUpdateConfig}
      />
    </Dialog>
  );
}

function productFieldsToConfig(
  fields: ProjectField[]
): Record<number, number[]> {
  const config: Record<number, number[]> = {};
  fields.forEach((field) => {
    if (field.values !== []) config[field.id] = [];
  });
  return config;
}
