import { HighlightedText } from "@aletiq/design-system";
import { Text } from "@blueprintjs/core";
import React from "react";
import {
  DateWithSpaces,
  StatusSelect,
  StatusTag,
  WorkflowNameLink,
} from "../../../components";
import { useCurrentUser, useHasPermission } from "../../../hooks";
import { useTasks, useWorkflowColor } from "../hooks";
import { ResolvedTask } from "../hooks/useUserTasks";
import styles from "./UserTask.module.scss";

export default function UserTaskRow(props: {
  task: ResolvedTask;
  search?: string;
}) {
  const { task, search = "" } = props;

  const { data: tasks = [] } = useTasks();
  const { data: currentUser } = useCurrentUser();
  const canUpdateOwnTaskStatus = useHasPermission("update:own-task-status");

  const hasTaskStatusUpdateRights =
    task.process.owner === currentUser?.id ||
    (canUpdateOwnTaskStatus && task?.owners.includes(currentUser?.id!));

  const hasLockedDependencies = tasks
    .filter((otherTask) => task.dependencies.includes(otherTask.id))
    .some((dep) => dep.status !== "done");

  const workflowColor = useWorkflowColor(task.process);

  return (
    <tr>
      <td
        style={{
          borderLeft: `8px solid ${workflowColor}`,
        }}
      >
        <Text ellipsize>
          <HighlightedText text={task.title} highlight={search} />
        </Text>
      </td>
      <td>
        <WorkflowNameLink
          workflowId={task.process.id}
          search={search}
          hasIcon
        />
      </td>
      <td className={styles.date_cell}>
        {task.dueDate && <DateWithSpaces date={task.dueDate} />}
      </td>
      <td className={styles.status_cell}>
        {task.process.isArchived && (
          <StatusTag
            isDisabled
            isTaskLocked={hasLockedDependencies}
            completionDate={task.completionDate}
          >
            {task.status}
          </StatusTag>
        )}
        {!task.process.isArchived && (
          <StatusSelect
            task={task}
            hasUpdateRights={hasTaskStatusUpdateRights}
            hasLockedDependencies={hasLockedDependencies}
          />
        )}
      </td>
    </tr>
  );
}
