import { useProject } from "../../../features/project/hooks";
import { useTranslations } from "../../../util";
import productLinkProps from "../props/productLinkProps";

export default function useProductLink(
  projectId: number,
  useThumbnail?: boolean
) {
  const tr = useTranslations();

  const { data: project } = useProject(projectId);

  return productLinkProps(tr, project, useThumbnail);
}
