import { Property, PropertyValue } from "@aletiq/types";
import { IconName, MaybeElement } from "@blueprintjs/core";
import {
  customCreatedAtColumn,
  customDescriptionColumn,
  customNameColumn,
} from ".";
import { ExtendedColumn } from "..";
import { customPropertyColumn } from "../..";

export function defaultNameColumn<
  T extends { name: string | null | undefined },
  U extends string
>(icon: IconName | MaybeElement): ExtendedColumn<T, U | "name"> {
  return customNameColumn<T, U | "name">(
    {
      id: "name",
      accessor: (v) => v.name,
    },
    icon
  );
}

export function defaultDescriptionColumn<
  T extends { description: string | null | undefined },
  U extends string
>(): ExtendedColumn<T, U | "description"> {
  return customDescriptionColumn<T, U | "description">({
    id: "description",
    accessor: (v) => v.description,
  });
}

export function defaultCreatedAtColumn<
  T extends { createdAt: string | null | undefined },
  U extends string
>(): ExtendedColumn<T, U | "createdAt"> {
  return customCreatedAtColumn<T, U | "createdAt">({
    id: "createdAt",
    accessor: (v) => v.createdAt,
  });
}

export function defaultUpdatedAtColumn<
  T extends { updatedAt: string | null | undefined },
  U extends string
>(): ExtendedColumn<T, U | "updatedAt"> {
  return customCreatedAtColumn<T, U | "updatedAt">({
    id: "updatedAt",
    accessor: (v) => v.updatedAt,
  });
}

export function defaultPropertyColumn<
  T extends { properties: Record<string, PropertyValue> },
  U extends string
>(prop: Property): ExtendedColumn<T, U> {
  return customPropertyColumn((row) => row.properties[prop.name]?.value, prop);
}
