import { Part, PartQueryOrder, SortState } from "@aletiq/types";
import React, { useState } from "react";
import { ANALYTICS_PARTS_SHOWN, useAnalytics } from "../../../analytics";
import { Table } from "../../../components";
import { useSort } from "../../../hooks";
import { ExpandTree, toggleExpandAt, TreePath } from "../../../util";
import { EditPartBomDialog } from "../../project/dialogs";
import { useAccessSearchResult } from "../../search/hooks";
import { PartIterationViewer } from "../dialogs";
import { usePartRowOptions, usePartTableColumns } from "../hooks";
import styles from "./AllParts.module.scss";
import { FullTree } from "./AllPartsType";
import usePartTree from "./usePartTree";

type AllPartListDialog =
  | {
      type: "edit-bom";
      partId: number;
    }
  | {
      type: "view";
      part: Part;
    };

export default function AllPartsList(props: { parts: Part[]; search: string }) {
  const { parts, search } = props;

  const analytics = useAnalytics();

  const [expandTrees, setExpandTrees] = useState<ExpandTree[]>([]);
  const [dialog, setDialog] = useState<AllPartListDialog>();

  const {
    sortKey,
    sortDirection,
    onSortKeyChange,
    sortedList: sortedParts,
  } = useSort(parts, "updatedAt", "desc");
  const visibleParts = usePartTree(expandTrees, sortedParts);

  const { mutate: accessSearchResult } = useAccessSearchResult();

  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  const handleOpenPartView = (part: Part) => {
    setDialog({
      type: "view",
      part,
    });
    analytics.track(ANALYTICS_PARTS_SHOWN);
  };

  const handleToggleExpand = (path: TreePath[]) => (expanded: boolean) =>
    setExpandTrees((t) => toggleExpandAt(t, path, expanded));

  const handleEditBom = (partId: number) => () => {
    setDialog({
      type: "edit-bom",
      partId,
    });
  };

  const columns = usePartTableColumns(
    handleToggleExpand,
    handleEditBom,
    handleOpenPartView
  );

  const { onOpen, isRowUnselectable: rowCannotBeOpened } = usePartRowOptions();

  const handleOpenPartRow = (partTree: FullTree) => {
    onOpen(partTree);
    partTree.part && accessSearchResult({ type: "part", id: partTree.part.id });
  };

  return (
    <div className={styles.table_wrapper}>
      <Table
        columns={columns}
        data={visibleParts}
        searchString={search}
        sortOptions={{
          sortState: {
            key: sortKey,
            direction: sortDirection,
          } as SortState<PartQueryOrder>,
          onSort: onSortKeyChange as (key: PartQueryOrder) => void,
        }}
        openRowOptions={{ onOpen: handleOpenPartRow, rowCannotBeOpened }}
      />

      {dialog?.type === "view" && (
        <PartIterationViewer
          part={dialog.part}
          iteration={dialog.part.lastIteration.number}
          onClose={handleCloseDialog}
        />
      )}

      {dialog?.type === "edit-bom" && (
        <EditPartBomDialog partId={dialog.partId} onClose={handleCloseDialog} />
      )}
    </div>
  );
}
