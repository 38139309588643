import React from "react";
import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
} from "../../../../components";
import { useTranslations } from "../../../../util";

export default function ProjectDefinitionActionMenu(props: {
  isLoading?: boolean;
  isDeleteDisabled?: boolean;
  isApproveDisabled?: boolean;
  onDelete: () => void;
  onRename: () => void;
  onValidate: () => void;
}) {
  const {
    isLoading = false,
    isDeleteDisabled,
    isApproveDisabled,
    onDelete,
    onRename,
    onValidate,
  } = props;
  const tr = useTranslations();

  return (
    <Popover2
      content={
        <Menu isDense>
          <MenuItem
            isDense
            icon="edit"
            text={tr.translateAsString("generic.action.rename")}
            onClick={onRename}
          />
          <MenuItem
            isDense
            icon="trash"
            text={tr.translateAsString("generic.action.delete")}
            disabled={isDeleteDisabled}
            onClick={onDelete}
          />

          <MenuDivider />
          <MenuItem
            isDense
            icon="tick"
            text={tr.translateAsString("project.definition.validate")}
            disabled={isApproveDisabled}
            onClick={onValidate}
          />
        </Menu>
      }
    >
      <Button icon="more" isDense isLoading={isLoading} />
    </Popover2>
  );
}
