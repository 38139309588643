import { ProjectQueryFilterParams } from "@aletiq/types";
import React, { useMemo } from "react";
import {
  CustomPropertyFilter,
  DateFilter,
  MoreFilters,
} from "../../../components";
import { HandleClear, HandleFilter, useCustomFilters } from "../../../hooks";
import { useTranslations } from "../../../util";
import {
  useAletiqPropertiesDefaultDisplay,
  useProperties,
} from "../../administration/Attributes/hooks";

export default function MoreProductFilters(props: {
  filters: ProjectQueryFilterParams;
  handleFilter: HandleFilter<ProjectQueryFilterParams>;
  handleClear: HandleClear<ProjectQueryFilterParams>;
}) {
  const { filters: productFilters, handleFilter, handleClear } = props;

  const tr = useTranslations();

  const { data: customProperties = [] } = useProperties("product");
  const { data: aletiqProperties = [] } =
    useAletiqPropertiesDefaultDisplay("product");

  const { setCustomFilter, clearCustomFilter } = useCustomFilters(
    productFilters,
    handleFilter
  );

  const additionalFilters = useMemo(() => {
    const filtersHiddenByDefault: (keyof ProjectQueryFilterParams)[] = [];
    if (
      aletiqProperties.some(
        (p) => p.name === "created_at" && !p.displayByDefault
      )
    ) {
      filtersHiddenByDefault.push("createdAt");
    }
    if (
      aletiqProperties.some(
        (p) => p.name === "updated_at" && !p.displayByDefault
      )
    ) {
      filtersHiddenByDefault.push("updatedAt");
    }

    return filtersHiddenByDefault;
  }, [aletiqProperties]);

  return (
    <MoreFilters
      filterValues={productFilters}
      additionalFilters={additionalFilters}
      onClear={handleClear}
      customProperties={customProperties}
    >
      {additionalFilters.includes("createdAt") && (
        <DateFilter
          selected={productFilters?.createdAt}
          onSelect={handleFilter("createdAt")}
          label={tr.translateAsString("generic.label.creation-date.title")}
        />
      )}

      {additionalFilters.includes("updatedAt") && (
        <DateFilter
          selected={productFilters?.updatedAt}
          onSelect={handleFilter("updatedAt")}
          label={tr.translateAsString("generic.label.last-update.title")}
        />
      )}

      {/* Only display properties that are not displayed by default */}
      {customProperties.map((property) =>
        property.displayByDefault ? null : (
          <CustomPropertyFilter
            key={property.id}
            property={property}
            value={productFilters.filters?.find((p) => p.id === property.id)}
            onClear={clearCustomFilter}
            onSetValue={setCustomFilter}
          />
        )
      )}
    </MoreFilters>
  );
}
