import useApi from "../../../app/useApi";
import { useDownloadQueue } from "../../../hooks";

export default function useDownloadArchiveByDocumentIds() {
  const api = useApi();
  const { handleDownload } = useDownloadQueue();

  const downloadArchive = (documentIds: number[]) => {
    return handleDownload({
      fetchToken: () => api.document.downloadArchiveByDocumentIds(documentIds),
      forceDownload: true,
    });
  };

  return downloadArchive;
}
