import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { projectKeys } from "../../hooks/queries";

export default function useAddOperationDocuments(
  projectId: number,
  bill: number,
  operation: number
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (documents: number[]) => {
      if (documents.length !== 0) {
        for (const documentId of documents) {
          const document = await api.document.getDocument(documentId);
          const revisionId = document.revisions[0].id;
          await api.operations.addProjectOperationDocument(
            projectId,
            bill,
            operation,
            documentId,
            { type: "specific", revision: revisionId }
          );
        }
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(projectKeys.allBills);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
