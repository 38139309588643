import classNames from "classnames";
import React, { Fragment } from "react";
import { useQuery } from "react-query";
import useApi from "../../../../../app/useApi";
import {
  ButtonSP,
  CreateTag,
  EditableText,
  GenericStatusTag,
  IgnoreTag,
  PartIcon,
  UpdateTag,
} from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { pdmQueries } from "../../../hooks/queries";
import { PartInfo, PartState } from "../types";
import PartActionSelect from "./PartActionSelect";
import styles from "./UploadedPartsList.module.scss";

export default function ValidParts(props: {
  parts: PartState[];
  onUpdatePartState: (partState: PartState) => void;
}) {
  const { parts, onUpdatePartState } = props;

  return (
    <Fragment>
      {parts.map((partState, index) => (
        <ValidPartRow
          key={index}
          partId={partState.partId}
          partSpec={partState.partInfo}
          onUpdate={(partInfo) => onUpdatePartState({ ...partState, partInfo })}
        />
      ))}
    </Fragment>
  );
}

function ValidPartRow(props: {
  partSpec: PartInfo;
  partId: number | null;
  onUpdate: (action: PartInfo) => void;
}) {
  const { partSpec, partId, onUpdate } = props;
  const { name, action } = partSpec;

  const api = useApi();
  const tr = useTranslations();

  const existingPartResult = useQuery(pdmQueries.byName(api, name));

  const isPartNameUsed =
    partSpec.action === "create" &&
    name &&
    existingPartResult.data &&
    existingPartResult.data.list[0] !== undefined;

  return (
    <tr>
      <td className={styles.tag_col}>
        {!partId && partSpec.action !== "ignore" && (
          <GenericStatusTag status="new" />
        )}
      </td>
      <td className={isPartNameUsed ? styles.part_name_used : undefined}>
        <div
          className={classNames(
            styles.part_name,
            partSpec.action === "ignore" && styles.ignored
          )}
        >
          <PartIcon isStandard type={partSpec.type} inline />
          {partSpec.action !== "create" ? (
            partSpec.name
          ) : (
            <EditableText
              isFill
              value={name}
              intent={isPartNameUsed ? "danger" : undefined}
              onChange={(name) => onUpdate({ ...partSpec, name })}
              placeholder={tr.translateAsString(
                "part.dialog.drop-parts.part.name.placeholder"
              )}
            />
          )}
        </div>
        {isPartNameUsed && (
          <p>{tr.translate("part.dialog.drop-parts.part.name.in-use")}</p>
        )}
      </td>
      <td className={styles.action_col}>
        <PartActionSelect
          onChangePartAction={(action) => onUpdate({ ...partSpec, action })}
          selectedAction={action}
        >
          <ButtonSP
            isDense
            color="primary"
            view="flat"
            rightIcon="caret-down"
            isFill
          >
            {action === "create" && <CreateTag />}
            {action === "update" && <UpdateTag />}
            {action === "ignore" && <IgnoreTag />}
          </ButtonSP>
        </PartActionSelect>
      </td>
    </tr>
  );
}
