import { Task, TaskModel } from "@aletiq/types";
import React, { useMemo } from "react";
import { Button, Menu, MenuItem, Popover2 } from "../../../../components";
import { taskIsValidDependency } from "../../WorklowModels/cycleDetection";
import TaskDependencyList from "../TaskDependencyList";
import styles from "./TaskDependencyMultiSelect.module.scss";

export default function TaskDependencyMultiSelect(props: {
  task: Task | TaskModel;
  onDepSelected: (task: number[]) => void;
  workflowTasks: (Task | TaskModel)[];
  workflowColor: string;
}) {
  const { task, workflowTasks, onDepSelected, workflowColor } = props;

  const otherTasks = useMemo(
    () => workflowTasks.filter((t) => t.id !== task.id),
    [workflowTasks, task.id]
  );

  const selectedDeps = useMemo(
    () => workflowTasks.filter((t) => task.dependencies.includes(t.id)),
    [workflowTasks, task.dependencies]
  );

  //check dependencies to avoid tasks that depend on each other
  const isInvalidDependency = (task: number, depIndex: number) => {
    const indexMap: Record<number, number> = {};
    workflowTasks.forEach((t, index) => (indexMap[t.id] = index));
    return !taskIsValidDependency(
      workflowTasks.map((t) => t.dependencies.map((dep) => indexMap[dep])),
      indexMap[depIndex],
      indexMap[task]
    );
  };

  const handleSelectDep = (task: Task | TaskModel) => () => {
    if (selectedDeps.find((dep) => dep.id === task.id))
      onDepSelected(
        selectedDeps.filter((dep) => dep.id !== task.id).map((t) => t.id)
      );
    else onDepSelected([...selectedDeps, task].map((t) => t.id));
  };

  return (
    <div className={styles.wrapper}>
      <TaskDependencyList
        task={task}
        workflowTasks={workflowTasks}
        workflowColor={workflowColor}
      />
      <div className={styles.button_wrapper}>
        <Popover2
          fill
          position="bottom-right"
          content={
            <Menu intent="minimal" isDense>
              {otherTasks.map((t) => {
                const isSelected = task.dependencies.includes(t.id);

                return (
                  <MenuItem
                    isDense
                    key={t.id}
                    text={t.title}
                    active={isSelected}
                    icon={isSelected ? "tick" : "blank"}
                    disabled={isInvalidDependency(task.id, t.id)}
                    onClick={handleSelectDep(t)}
                  />
                );
              })}
            </Menu>
          }
        >
          <Button
            isDense
            intent="array_default"
            icon="edit"
            className={styles.button}
          />
        </Popover2>
      </div>
    </div>
  );
}
