import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./Tabs.module.scss";

export default function Tabs(props: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={classNames(styles.container, props.className)}>
      {props.children}
    </div>
  );
}
