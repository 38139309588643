import { useMutation } from "react-query";
import useApi from "../../../app/useApi";
import { useNavigate } from "../../../hooks";

export default function useDeleteProductBomEntry(productId: number) {
  const api = useApi();
  const navigate = useNavigate();

  return useMutation(
    (versionId: number) =>
      api.project.deleteProjectVersion(productId, versionId),
    {
      onSuccess: () => {
        navigate({
          path: `/project/${productId}/versions`,
        });
      },
      onError: () => {},
    }
  );
}
