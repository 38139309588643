import classNames from "classnames";
import React from "react";
import {
  workflowColor1,
  workflowColor2,
  workflowColor3,
  workflowColor4,
  workflowColor5,
  workflowColor6,
  workflowColor7,
  workflowColor8,
  workflowColor9,
  workflowColor10,
} from "../../../../util";
import styles from "./TaskTable.module.scss";

export default function TaskTable(props: {
  color?: string;
  children?: React.ReactNode;
  isBeingEdited?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}) {
  const { color, children, isBeingEdited, onClick } = props;
  const indexClass = workflowColorToClass(color ?? "");
  return (
    <table
      className={classNames(
        styles.container,
        indexClass,
        isBeingEdited && styles.edited
      )}
      onClick={onClick}
    >
      {children}
    </table>
  );
}

function workflowColorToClass(color: string): string {
  switch (color.toLowerCase()) {
    case workflowColor1:
      return styles["workflow-1"];
    case workflowColor2:
      return styles["workflow-2"];
    case workflowColor3:
      return styles["workflow-3"];
    case workflowColor4:
      return styles["workflow-4"];
    case workflowColor5:
      return styles["workflow-5"];
    case workflowColor6:
      return styles["workflow-6"];
    case workflowColor7:
      return styles["workflow-7"];
    case workflowColor8:
      return styles["workflow-8"];
    case workflowColor9:
      return styles["workflow-9"];
    case workflowColor10:
      return styles["workflow-10"];
    default:
      return styles["workflow-default"];
  }
}
