import { Process } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../util";
import workflowLinkProps from "../../link/props/workflowLinkProps";
import ActivityLink from "./ActivityLink";

export default function ActivityWorkflowLink(props: {
  workflow: Process;
  onClick?: () => void;
}) {
  const { workflow, onClick } = props;
  const tr = useTranslations();

  const linkProps = workflowLinkProps(tr, workflow);

  return <ActivityLink {...linkProps} onClick={onClick} />;
}
