import { Task, TaskModel } from "@aletiq/types";
import React, { useMemo } from "react";
import { Tag, Tooltip } from "../../../../components";
import styles from "./TaskDependencyList.module.scss";

export default function TaskDependencyList(props: {
  task: Task | TaskModel;
  workflowColor: string;
  workflowTasks: (Task | TaskModel)[];
}) {
  const { workflowTasks, task, workflowColor } = props;

  const otherTasks = useMemo(
    () => workflowTasks.filter((t) => t.id !== task.id),
    [workflowTasks, task.id]
  );
  const dependencies = useMemo(
    () => otherTasks.filter((t) => task.dependencies.includes(t.id)),
    [otherTasks, task.dependencies]
  );

  const firstDep = dependencies.length > 0 && dependencies[0];
  const additionalDeps = dependencies.length > 1 && dependencies.slice(1);

  return (
    <div className={styles.dependencies}>
      {firstDep && (
        <div
          className={styles.first_dependency}
          style={{
            borderLeft: `5px solid ${workflowColor}`,
          }}
        >
          {firstDep.title}
        </div>
      )}
      {additionalDeps && (
        <div className={styles.more_dependencies}>
          <Tooltip
            content={
              <>
                {additionalDeps.map((dep) => (
                  <div key={dep.id}>{dep.title}</div>
                ))}
              </>
            }
          >
            <Tag isDense>+{additionalDeps.length}</Tag>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
