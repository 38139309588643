import React from "react";
import { Dialog, DialogHint, StateTagTransition } from "../../../components";
import { useTranslations } from "../../../util";
import useValidateBill from "./hooks/useValidateBill";
import styles from "./ValidateBill.module.scss";

export default function ValidateBill(props: {
  projectId: number;
  billId: number;
  onClose: () => void;
}) {
  const { projectId, billId, onClose } = props;
  const tr = useTranslations();

  const { isLoading, mutate } = useValidateBill(projectId, billId);

  const handleValidate = () => {
    mutate(undefined, { onSuccess: onClose });
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      title={tr.translateAsString("project.operation-bill.validate")}
      icon="tick"
      submitting={isLoading}
      primaryText="Enregistrer"
      onPrimaryClick={handleValidate}
      className={styles.validate_form}
    >
      <DialogHint>
        {tr.translate("project.operation-bill.validate.confirm")}
      </DialogHint>

      <StateTagTransition initialProps={{ state: "draft" }} />
    </Dialog>
  );
}
