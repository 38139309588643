import React, { useState } from "react";
import { Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import { PasswordCreator } from "../common";
import { useUpdateUserPassword } from "../hooks";
import styles from "./Dialogs.module.scss";

export function ChangePasswordUserDialog(props: {
  onClose: () => void;
  userId: number;
}) {
  const { onClose, userId } = props;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const tr = useTranslations();

  const { mutate: updatePassword, isLoading } = useUpdateUserPassword(userId);
  const canSubmit = passwordIsValid && password === confirmPassword;

  const handlePasswordChange = (value: string, passwordIsValid: boolean) => {
    setPassword(value);
    setPasswordIsValid(passwordIsValid);
  };

  const onSubmit = () => {
    updatePassword(password, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      icon="edit"
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("admin.users.dialog.change-password.title")}
      onPrimaryClick={onSubmit}
      disablePrimary={!canSubmit}
      submitting={isLoading}
    >
      <div className={styles.dialog__inner}>
        <div className={styles.description}>
          {tr.translate("admin.users.dialog.change-password.description")}
        </div>
        <PasswordCreator
          label={tr.translateAsString(
            "admin.users.dialog.change-password.label"
          )}
          value={password}
          valueConfirm={confirmPassword}
          onSetValue={handlePasswordChange}
          onSetConfirmValue={setConfirmPassword}
          containerClassName={styles.spaceTop}
          isMandatory
          autoFocus
        />
      </div>
    </Dialog>
  );
}
