import { EditableText } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../util";

export default function RevisionEditableDescription(props: {
  isEditable?: boolean;
  defaultValue: string;
  onConfirm: (value: string) => void;
}) {
  const { defaultValue, isEditable = true, onConfirm } = props;

  const tr = useTranslations();

  return isEditable ? (
    <EditableText
      key={defaultValue}
      defaultValue={defaultValue}
      onConfirm={onConfirm}
      placeholder={tr.translateAsString("generic.label.description")}
      isFill
      multiline
      maxLines={2}
    />
  ) : (
    <div>{defaultValue}</div>
  );
}
