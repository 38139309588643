import { Process } from "@aletiq/types";
import React, { useMemo } from "react";
import { sortByNumber } from "../../../util";
import HeaderEntityProcessPanel from "../common/HeaderEntityProcessPanel/HeaderEntityProcessPanel";
import RowEntityProcessPanelView from "../common/RowEntityProcessPanelView/RowEntityProcessPanelView";
import styles from "./WorkflowDetails.module.scss";

export default function WorkflowTasks(props: { workflow: Process }) {
  const { workflow } = props;

  const tasks = workflow.tasks;

  const sortedTasks = useMemo(
    () => sortByNumber(tasks, (task) => task.order),
    [tasks]
  );

  return (
    <div>
      <HeaderEntityProcessPanel />
      <div className={styles.tasks}>
        {sortedTasks.map((task) => (
          <RowEntityProcessPanelView
            key={task.id}
            task={task}
            workflow={workflow}
            className={styles.task_row}
          />
        ))}
      </div>
    </div>
  );
}
