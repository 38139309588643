import { EntityId } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "./queries";

export default function useEntityActivities(entityId: EntityId) {
  const api = useApi();
  return useQuery(activityKeys.byEntity(entityId), () =>
    api.activity.getEntityActivities(entityId)
  );
}
