import {
  Button,
  ItemSelect,
  SearchInput,
  Select,
  Tooltip,
} from "@aletiq/design-system";
import React, { useMemo } from "react";
import {
  ActionBar,
  AlignRight,
  DateFilter,
  MoreFilters,
  WorkflowModelFilter,
  WorkflowOwnerFilter,
} from "../../../../components";
import { HandleClear, HandleFilter, useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useAddProcessRelation, useProcesses } from "../../hooks";
import { Filter } from "../../types";
import styles from "./WorkflowActionBar.module.scss";

export function WorkflowActionBar(props: {
  filter: Filter;
  handleFilter: HandleFilter<Filter>;
  handleClear: HandleClear<Filter>;
  projectId?: number;
  allOpen?: boolean;
  toggleAll?: () => void;
  onCreateWorkflow?: () => void;
}) {
  const {
    filter,
    handleFilter,
    handleClear,
    allOpen = false,
    projectId,
    onCreateWorkflow,
    toggleAll,
  } = props;
  const tr = useTranslations();

  const canLinkWorkflows = useHasPermission("link:workflows");
  const canCreateWorkflows = useHasPermission("create:workflows");

  const { mutate: addWorkflowRelation, isLoading: addingRelation } =
    useAddProcessRelation();

  const { data: allWorkflows = [] } = useProcesses();
  const { data: workflows = [] } = useProcesses({
    relation: projectId ? { type: "project", id: projectId } : undefined,
  });
  const linkedWorkflowsIds = workflows.map((p) => p.id);
  const linkableWorkflows: ItemSelect<number>[] = useMemo(
    () =>
      allWorkflows
        .filter((p) => !p.isArchived && !linkedWorkflowsIds.includes(p.id))
        .map((w) => ({ key: w.id, text: w.title })),
    [allWorkflows, linkedWorkflowsIds]
  );

  const handleLinkWorkflow = (workflowId: number) => {
    projectId &&
      addWorkflowRelation({
        process: workflowId,
        entity: {
          type: "project",
          id: projectId,
        },
      });
  };

  return (
    <ActionBar>
      {toggleAll && (
        <Button
          isDense
          icon={allOpen ? "collapse-all" : "expand-all"}
          onClick={toggleAll}
          className={styles.toggle}
        />
      )}
      <Tooltip
        content={tr.translateAsString("task.filter.tooltip-search")}
        position="top-left"
      >
        <SearchInput
          value={filter?.search ?? ""}
          onChange={handleFilter("search")}
          className={styles.searchbar}
          isDense
        />
      </Tooltip>
      <WorkflowModelFilter
        selected={filter?.models ?? []}
        noModelSelected={filter?.emptyModel ?? false}
        onSelect={handleFilter("models")}
        onSelectNoModel={handleFilter("emptyModel")}
        onClear={handleClear(["models", "emptyModel"])}
      />
      <WorkflowOwnerFilter
        selected={filter?.owners ?? []}
        myParticipationWorkflowSelected={
          filter?.myContributionWorkflow ?? false
        }
        onSelect={handleFilter("owners")}
        onSelectMyParticipationWorkflow={handleFilter("myContributionWorkflow")}
        onClear={handleClear(["owners", "myContributionWorkflow"])}
      />
      <MoreFilters
        onClear={handleClear}
        filterValues={filter}
        additionalFilters={["createdAt", "updatedAt"]}
      >
        <DateFilter
          selected={filter?.createdAt}
          onSelect={handleFilter("createdAt")}
          label={tr.translateAsString("generic.label.creation-date.title")}
        />
        <DateFilter
          selected={filter?.updatedAt}
          onSelect={handleFilter("updatedAt")}
          label={tr.translateAsString("generic.label.last-update.title")}
        />
      </MoreFilters>
      <AlignRight />
      {projectId && onCreateWorkflow && (
        <Select
          filterable
          autoFocus
          items={linkableWorkflows}
          onItemSelect={handleLinkWorkflow}
          popoverProps={{ position: "bottom-right" }}
          isDense
        >
          <Button
            isDense
            icon="new-link"
            intent="secondary"
            isLoading={addingRelation}
            isDisabled={!canLinkWorkflows}
          >
            {tr.translate("entity.workflow.link")}
          </Button>
        </Select>
      )}
      {onCreateWorkflow && (
        <Button
          isDense
          intent="secondary"
          icon="plus"
          isDisabled={!canCreateWorkflows}
          onClick={onCreateWorkflow}
        >
          {tr.translate("task.action.new-process")}
        </Button>
      )}
    </ActionBar>
  );
}
