import React from "react";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import {
  ANALYTICS_VISITED_PRODUCT_NEW_VERSION,
  useTrackEvent,
} from "../../../../analytics";
import { ProjectPage } from "../../common";
import { useFetchVersionDetails } from "../../hooks";
import VersionDetails from "../VersionDetails";
import NewVersion from "./NewVersion";

export default function NewVersionPage(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_NEW_VERSION);

  const canSeeEditVersion = useHasPermission("update:project-versions");

  const { name, documentsData } = useFetchVersionDetails(projectId);

  return (
    <ProjectPage projectId={projectId}>
      {canSeeEditVersion && <NewVersion projectId={projectId} />}
      {!canSeeEditVersion && (
        <VersionDetails
          projectId={projectId}
          documents={documentsData}
          title={tr.translateAsString(
            "project.version.details.sidebar.version.name.draft",
            {
              name,
            }
          )}
        />
      )}
    </ProjectPage>
  );
}
