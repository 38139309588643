import { PassportFile } from "@aletiq/types";
import {
  Filter,
  ResolvedPassportFile,
} from "../PassportDetails/PassportFiles/types";
import usePassportFileTypes from "./usePassportFileTypes";

export default function usePassportFilesFilter(
  passportFiles: PassportFile[],
  filter: Filter
): ResolvedPassportFile[] {
  const { data: fileTypes = [] } = usePassportFileTypes();

  const resolvedPassportFiles = passportFiles.map(({ file, type }) => ({
    file,
    type: fileTypes.find((t) => t.id === type),
  }));

  const filteredFiles = resolvedPassportFiles.filter(
    passportFileTypePredicate(filter)
  );

  return filteredFiles;
}

function passportFileTypePredicate(filter: Filter) {
  return (passportFile: ResolvedPassportFile): boolean => {
    const { search = "", emptyType, types = [] } = filter;

    if (!hasValidType(emptyType, types, passportFile)) {
      return false;
    }
    return matchSearchString(
      search,
      passportFile.file.name,
      passportFile.type?.value ?? ""
    );
  };
}

const matchSearchString = (
  search: string,
  ...fields: (string | undefined)[]
) => {
  if (!search) {
    return true;
  }
  const searched = search.toLocaleLowerCase();
  for (const field of fields) {
    if (field && field.toLocaleLowerCase().includes(searched)) {
      return true;
    }
  }
  return false;
};

const hasValidType = (
  emptyType: boolean = false,
  types: number[] = [],
  passportFile: ResolvedPassportFile
) => {
  if (!emptyType && types.length === 0) {
    return true;
  }
  if (emptyType && !passportFile.type) {
    return true;
  }
  if (types.length > 0 && types.includes(passportFile.type?.id ?? -1)) {
    return true;
  }
  return false;
};
