import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import styles from "./OverflowList.module.scss";

// This component is similar to OverflowList from blueprint we reimplement it
// because this version have better performance
function OverflowList<T>(props: {
  items: T[];
  visibleItemRenderer: (item: T) => React.ReactNode;
  overflowRenderer: (items: T[]) => React.ReactNode;
  className?: string;
}) {
  const { items, visibleItemRenderer, overflowRenderer, className } = props;
  const ref = useRef<HTMLDivElement>(null);
  const calculatingRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, triggerRerender] = useState(false);

  const obs = useMemo(
    () =>
      new ResizeObserver(() => {
        triggerRerender((prevState) => !prevState);

        return;
      }),
    []
  );

  useLayoutEffect(() => {
    const element = ref?.current;
    if (element) {
      obs.observe(element);

      return () => {
        element && obs.unobserve(element);
      };
    }
  }, [ref, obs]);

  let widthSum = 0;
  const widthContainer = ref?.current?.clientWidth ?? 0;
  let indexOverflowing = 0;

  for (let i = 0; i < (calculatingRef?.current?.children?.length ?? 0); i++) {
    const children = calculatingRef?.current?.children[i];
    widthSum += children?.clientWidth ?? 0;

    if (widthSum > widthContainer) {
      break;
    }
    indexOverflowing = i + 1;
  }

  const itemsToShow = items.slice(0, indexOverflowing);

  return (
    <div className={classnames(styles.overflow_list, className)} ref={ref}>
      <div className={styles.calculating_list} ref={calculatingRef}>
        {items.map((item) => visibleItemRenderer(item))}
      </div>
      {widthContainer !== 0 && (
        <>
          {itemsToShow.map((item) => visibleItemRenderer(item))}
          {indexOverflowing !== items.length &&
            overflowRenderer(items.slice(indexOverflowing))}
        </>
      )}
    </div>
  );
}

export declare class ResizeObserver {
  constructor(obs: (entries: { target: Element }[]) => void);
  observe(target: Element): void;
  unobserve(targe: Element): void;
}

export default OverflowList;
