import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../../app/useApi";
import { useToaster } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { activityKeys } from "../../../../activities/hooks/queries";
import { pdmKeys } from "../../../../pdm/hooks/queries";
import { PartMutationList } from "../partBomUtils";

export default function useUpdatePartComponents() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (mutations: PartMutationList) => {
      await Promise.all(
        mutations.deletions.map((deletion) =>
          api.pdm.unregisterPartComponent(deletion.parentId, deletion.name)
        )
      );

      await Promise.all(
        mutations.additions.map((addition) =>
          api.pdm.registerPartComponent(addition.parentId, {
            name: addition.name,
            part: addition.partId,
            isIgnored: false,
            iteration: addition.iteration,
          })
        )
      );

      await Promise.all(
        mutations.ignores.map((ignore) =>
          api.pdm.updateIgnorePartComponent(
            ignore.parentId,
            ignore.name,
            ignore.isIgnored
          )
        )
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.part.components.register"),
        });
      },
    }
  );
}
