import React from "react";
import {
  ExpandIcon,
  HighlightedText,
  OffsetCell,
  PartIcon,
  Tooltip,
} from "../../../../../components";
import {
  NoChildRow,
  NotFetchedRow,
} from "../../../../../components/table/rows";
import {
  ExpandCellProps,
  TreePath,
  treePathFromRowId,
  useTranslations,
} from "../../../../../util";
import { useRefreshPartData } from "../../../hooks";
import { isPartParent } from "../../../services";
import { FullTree } from "../../AllPartsType";
import IncompleteBomRow from "../IncompleteBomRow";
import NotLoadedRow from "../NotLoadedRow";

const makePath = (original: FullTree) =>
  original.type === "part"
    ? {
        id: original.part!.id,
        revision: original.iteration!.number,
      }
    : undefined;

export default function PartNameCell(
  props: ExpandCellProps<FullTree> & {
    onEditBom: (partId: number) => () => void;
  }
) {
  const { rowsById, row, onToggleExpand, onEditBom, onRefresh, searchString } =
    props;
  const { depth, isExpanded, original: partTree } = row;
  const tr = useTranslations();

  const part = partTree.type === "part" ? partTree.part : undefined;
  const canExpand = isPartParent(part?.type ?? "part");

  const refreshPartData = useRefreshPartData();

  const handleExpandRow = () => {
    if (!part || !canExpand) {
      return;
    }

    const path: TreePath[] = [
      ...treePathFromRowId(row, rowsById, makePath),
      { id: part.id, revision: part.lastIteration.number },
    ];

    // Toggling expansion status for the part tree
    onToggleExpand(path)(!partTree.expanded);
  };

  const handleEditBom = () => {
    partTree.type === "incomplete" && onEditBom(partTree.partId)();
  };

  //get id of part or parent part and refresh partId request
  const handleRefresh = () => {
    if (!onRefresh || partTree.type === "not-fetched") {
      return;
    }
    refreshPartData(partTree.part!.id);
  };

  const isPartPrivate = part && !part.hasAccess;

  return (
    <OffsetCell depth={depth}>
      <Tooltip
        content={tr.translate("part.details.private")}
        position="top"
        isDisabled={!isPartPrivate}
      >
        {partTree.type === "incomplete" && (
          <IncompleteBomRow onClick={handleEditBom} />
        )}
        {partTree.type === "not-fetched" && <NotFetchedRow />}
        {partTree.type === "empty" && <NoChildRow />}
        {partTree.type === "not-loaded" && (
          <NotLoadedRow onRefresh={handleRefresh} />
        )}
        {partTree.type === "part" && (
          <>
            <ExpandIcon
              hasExpand={canExpand}
              isExpanded={isExpanded}
              onExpand={handleExpandRow}
            />
            <PartIcon inline type={part?.type} isStandard={part?.isStandard} />
            <HighlightedText text={part?.name} highlight={searchString} />
          </>
        )}
      </Tooltip>
    </OffsetCell>
  );
}
