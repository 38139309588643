import { HighlightedText, Tooltip } from "@aletiq/design-system";
import { Text } from "@blueprintjs/core";
import React, { ReactNode } from "react";
import { PageTarget } from "../../util/general";
import { PageLink } from "../page";

export default function NameLink(props: {
  hasIcon?: boolean;
  className?: string;
  search?: string;
  icon: ReactNode;
  text: string;
  tooltip?: string;
  isDisabled: boolean;
  location: PageTarget;
  onClick?: () => void;
}) {
  const {
    className,
    icon,
    text,
    location,
    isDisabled,
    tooltip,
    hasIcon,
    search,
    onClick,
  } = props;

  const link = (
    <PageLink
      className={className}
      to={location}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {hasIcon && text && icon}
      <Text ellipsize tagName="span">
        <HighlightedText text={text} highlight={search} />
      </Text>
    </PageLink>
  );

  if (tooltip)
    return (
      <Tooltip position="top-left" isDisabled={!tooltip} content={tooltip}>
        {link}
      </Tooltip>
    );

  return link;
}
