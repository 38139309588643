import { SortDirection } from "@aletiq/types";
import sortBy from "./sortBy";

export default function sortByNumber<T>(
  values: T[],
  key: (value: T) => number,
  order?: SortDirection
) {
  return sortBy(values, key, (k1, k2) => k1 - k2, order);
}
