import { Part } from "@aletiq/types";
import React, { ReactNode } from "react";
import { Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useDeleteParts } from "../../hooks";
import styles from "./DeletePartsDialog.module.scss";

export default function DeletePartsDialog(props: {
  parts: Part[];
  elementName?: ReactNode;
  onClose: () => void;
}) {
  const { parts, onClose } = props;
  const tr = useTranslations();

  const { mutate: deleteParts, isLoading } = useDeleteParts();

  const handleDelete = () => {
    deleteParts(parts, {
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      title={tr.translateAsString("part.dialog.delete.parts.title", {
        nbr: parts.length,
      })}
      icon="trash"
      intent="danger"
      onClose={onClose}
      onPrimaryClick={handleDelete}
      onSecondaryClick={onClose}
      submitting={isLoading}
      className={styles.dialog}
      primaryText={tr.translateAsString("generic.action.delete")}
    >
      {tr.translate("part.dialog.delete.parts.subtitle")}
    </Dialog>
  );
}
