import { useHistory } from "react-router-dom";
import { encodePageLocation, parsePageLocation } from "../../util";

export default function usePreviousPanel() {
  const history = useHistory();

  const pageLocation = parsePageLocation(history.location);
  const hasPrevious = pageLocation.panels.length > 1;

  const handleGoBack = () => {
    if (!hasPrevious) return;
    history.push(
      encodePageLocation({
        ...pageLocation,
        panels: pageLocation.panels.slice(0, -1),
      })
    );
  };

  return { hasPrevious, handleGoBack };
}
