import { Icon } from "@aletiq/design-system";
import { Document } from "@aletiq/types";
import React from "react";
import { Translations } from "../../../util/general";

export default function documentLinkProps(
  tr: Translations,
  document: Document | undefined
) {
  const iconIntent =
    document?.isDeleted || !document?.hasAccess ? "none" : "primary";

  let tooltipText = undefined;
  if (!document?.hasAccess) {
    tooltipText = tr.translateAsString("documents.link.private", {
      type: "document",
    });
  }
  if (document?.isDeleted) {
    tooltipText = tr.translateAsString("link.deleted-object", {
      type: "document",
    });
  }

  return {
    icon: <Icon icon="document" inline intent={iconIntent} />,
    tooltip: tooltipText,
    isDisabled: !document?.hasAccess || (document?.isDeleted ?? true),
    text: document?.name ?? "",
    location: { panel: `documentId-${document?.id}` },
  };
}
