import {
  Button,
  ButtonSP,
  Dialog,
  Field,
  Input,
  ItemSelect,
  Radio,
  Select,
  TextArea,
} from "@aletiq/design-system";
import { ListTable } from "../../../../components";
import React, { useState } from "react";
import { useTranslations } from "../../../../util";
import { useAddProjectOptionField } from "../../hooks";
import useFieldState from "../../ProjectConfig/useFieldState";
import styles from "./AddConfigDialog.module.scss";
type ConfigType = "multi" | "single";

export default function AddConfigDialog(props: {
  projectId: number;
  onClose: () => void;
}) {
  const { projectId, onClose } = props;
  const tr = useTranslations();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState<ConfigType>("single");
  const { values, onUpdate } = useFieldState([]);

  const { mutate: addConfigField, isLoading } = useAddProjectOptionField();

  const disableSubmit =
    name === "" ||
    values.length === 0 ||
    values.some((value) => value.name === "");

  const configTypes: ItemSelect<ConfigType>[] = [
    {
      key: "single",
      text: tr.translateAsString("product.config.type.select", {
        type: "single",
      }),
    },
    {
      key: "multi",
      text: tr.translateAsString("product.config.type.select", {
        type: "multi",
      }),
    },
  ];

  const handleSubmit = () => {
    addConfigField(
      {
        projectId: projectId,
        spec: {
          name,
          description,
          isMandatory: false,
          allowMultiValues: type === "multi",
          values: values.map(({ name, isDefault }) => ({
            name,
            isDefault,
          })),
        },
      },
      { onSettled: onClose }
    );
  };

  const handleUpdateValueName = (value: number, name: string) => {
    onUpdate({ type: "valueName", value, name });
  };

  const handleSetDefaultValue = (isDefault: boolean, valueId: number) => {
    //if option is already default, set default as none
    onUpdate({
      type: "default",
      value: isDefault ? null : valueId,
    });
  };

  const handleDeleteValue = (value: number) => {
    onUpdate({
      type: "deleteValue",
      value,
    });
  };

  const handleAddValue = () =>
    onUpdate({
      type: "addValue",
      value: { name: "", isDefault: false, isDeleted: false },
    });

  return (
    <Dialog
      isOpen
      icon="edit"
      title={tr.translateAsString("project.dialog.config.new.title")}
      onPrimaryClick={handleSubmit}
      disablePrimary={disableSubmit}
      submitting={isLoading}
      onClose={onClose}
    >
      <div className={styles.flex}>
        <Field
          label={tr.translateAsString("generic.label.name")}
          className={styles.input}
        >
          <Input
            fill
            value={name}
            onChange={setName}
            placeholder={tr.translateAsString(
              "project.dialog.config.name.placeholder"
            )}
          />
        </Field>

        <Field
          label={tr.translateAsString("project.dialog.config.type")}
          className={styles.select}
        >
          <Select
            isFill
            intent="outlined"
            items={configTypes}
            onItemSelect={setType}
            activeItem={type}
          >
            {tr.translateAsString("product.config.type.select", { type })}
          </Select>
        </Field>
      </div>

      <Field label={tr.translateAsString("generic.label.description")}>
        <TextArea
          fill
          value={description}
          onChange={setDescription}
          placeholder={tr.translateAsString(
            "project.dialog.config.desc.placeholder"
          )}
        />
      </Field>

      {values.length > 0 && (
        <ListTable
          columns={
            <tr>
              <th>{tr.translate("project.dialog.config.value")}</th>
              <th className={styles.default}>
                {tr.translate("project.dialog.config.default")}
              </th>
              <th className={styles.delete} />
            </tr>
          }
        >
          {values.map((value) => (
            <tr key={value.id}>
              <td>
                <Input
                  fill
                  isDense
                  value={value.name}
                  onChange={(newName) =>
                    handleUpdateValueName(value.id, newName)
                  }
                  placeholder={tr.translateAsString(
                    "project.dialog.config.value.name.placeholder"
                  )}
                />
              </td>
              <td>
                <Radio
                  isDense
                  checked={value.isDefault}
                  onClick={() =>
                    handleSetDefaultValue(value.isDefault, value.id)
                  }
                />
              </td>
              <td>
                <Button
                  isDense
                  icon="trash"
                  onClick={() => handleDeleteValue(value.id)}
                />
              </td>
            </tr>
          ))}
        </ListTable>
      )}

      <ButtonSP
        icon="plus"
        view="flat"
        color="primary"
        onClick={handleAddValue}
      >
        {tr.translate("project.dialog.config.add-value")}
      </ButtonSP>
    </Dialog>
  );
}
