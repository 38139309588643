import {
  BOMEntry,
  Project,
  ProjectDefinitionId,
  Property,
} from "@aletiq/types";
import React, { useCallback, useMemo } from "react";
import { CellProps } from "react-table";
import {
  AlertPopover,
  Button,
  customCreatedAtColumn,
  customDescriptionColumn,
  customPropertyColumn,
  customUpdatedAtColumn,
  EditableNumberCell,
  ExtendedColumn,
  GenericStateTag,
  GenericStatusTag,
} from "../../../../components";
import { ColumnOptionConfig, useHasPermission } from "../../../../hooks";
import {
  extractBy,
  indexBy,
  mapRecord,
  TreePath,
  useTranslations,
} from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import {
  useDeleteProductTreeComponents,
  useUpdateProductTreeComponentQuantity,
} from "../../hooks";
import { ProductTree } from "../hooks";
import styles from "./ProjectComponents.module.scss";
import ProjectNameCell from "./ProjectNameCell";

export type ProductComponentSortableColumn =
  | "projectName"
  | "definition"
  | "description"
  | "quantity"
  | "createdAt"
  | "updatedAt";

export type ProductComponentsColumn =
  | ProductComponentSortableColumn
  | "status"
  | number;

export const DEFAULT_PRODUCT_COMPONENTS_COLUMNS: ColumnOptionConfig<ProductComponentsColumn> =
  {
    projectName: true,
    definition: true,
    quantity: true,
    description: false,
    createdAt: false,
    updatedAt: false,
    status: true,
  };

export default function useProjectComponentsColumns(options: {
  config?: ColumnOptionConfig<ProductComponentsColumn>;
  canEditQuantity?: boolean;
  canDelete?: boolean;
  onToggleExpand: (paths: TreePath[]) => (enabled: boolean) => void;
  definitionIdx: number;
}): ExtendedColumn<ProductTree, ProductComponentSortableColumn>[] {
  const {
    config = DEFAULT_PRODUCT_COMPONENTS_COLUMNS,
    canEditQuantity = true,
    canDelete = true,
    onToggleExpand,
    definitionIdx,
  } = options;

  const tr = useTranslations();

  const canUpdateProduct = useHasPermission("update:projects");
  const { mutate: removeComponent } = useDeleteProductTreeComponents();
  const { mutate: updateQuantity } = useUpdateProductTreeComponentQuantity();

  const { data: properties = [] } = useProperties("product");

  const handleRemoveComponent = useCallback(
    (parent: ProjectDefinitionId, project: Project) => () => {
      removeComponent([{ ...parent, componentId: project.id }]);
    },
    [removeComponent]
  );

  const handleEditQuantity = useCallback(
    (
      parent: ProjectDefinitionId,
      editedComponent: BOMEntry,
      quantity: number
    ) => {
      updateQuantity([
        {
          ...parent,
          componentId: editedComponent.entity,
          quantity,
        },
      ]);
    },
    [updateQuantity]
  );

  return useMemo<
    ExtendedColumn<ProductTree, ProductComponentSortableColumn>[]
  >(() => {
    const propertyRecord: Record<ProductComponentsColumn, Property> = indexBy(
      properties,
      "id"
    );

    const columns: Record<
      ProductComponentsColumn,
      ExtendedColumn<ProductTree, ProductComponentSortableColumn>
    > = {
      projectName: {
        id: "projectName",
        Header: tr.translate("generic.label.name"),
        accessor: (row: ProductTree) =>
          row.type === "project" ? row.project.name : "",
        Cell: (cellProps: CellProps<ProductTree>) => (
          <ProjectNameCell
            {...cellProps}
            {...cellProps.row.getToggleRowExpandedProps()}
            onToggleExpand={onToggleExpand}
          />
        ),
        hoverButton: canDelete
          ? (component, className?: string) =>
              component.type === "project" ? (
                <DeleteProductPopover
                  className={className}
                  isDisabled={!canUpdateProduct}
                  onClick={handleRemoveComponent(
                    component.parent,
                    component.project
                  )}
                />
              ) : (
                <></>
              )
          : undefined,
      },
      definition: {
        id: "definition",
        Header: tr.translate("project.definition.index"),
        accessor: (row: ProductTree) =>
          row.type === "project" ? row.definition.name : null,
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ProductTree>) => {
          if (cellProps.row.original.type !== "project") {
            return null;
          }
          const component = cellProps.row.original;
          return (
            <GenericStateTag
              name={component.definition.name}
              state={component.definition.state}
            />
          );
        },
      },
      quantity: {
        id: "quantity",
        Header: tr.translate("generic.label.quantity"),
        accessor: (row: ProductTree) =>
          row.type === "project" ? row.entry.quantity : 1,
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ProductTree>) => {
          if (cellProps.row.original.type !== "project") {
            return null;
          }
          const component = cellProps.row.original;
          return canEditQuantity ? (
            <EditableNumberCell
              allowNumericCharactersOnly={false}
              value={component.entry.quantity}
              onConfirmNewValue={(quantity) =>
                handleEditQuantity(
                  component.parent,
                  {
                    ...component.entry,
                    definition: component.definition.index,
                  },
                  quantity
                )
              }
            />
          ) : (
            <span>{component.entry.quantity}</span>
          );
        },
      },
      description: customDescriptionColumn({
        id: "description",
        accessor: (row) =>
          row.type === "project" ? row.project.description : "",
      }),
      createdAt: customCreatedAtColumn({
        id: "createdAt",
        accessor: (row) =>
          row.type === "project" ? row.project.createdAt : "",
      }),
      updatedAt: customUpdatedAtColumn({
        id: "updatedAt",
        accessor: (row) =>
          row.type === "project" ? row.project.updatedAt : "",
      }),
      status: {
        id: "status",
        canSort: false,
        Header: tr.translateAsString("generic.label.status"),
        accessor: (row: ProductTree) =>
          row.type === "project" ? row.diff.status : "",
        Cell: (props: CellProps<ProductTree>) =>
          definitionIdx > 1 && <GenericStatusTag status={props.value} />,
      },
    };

    const propertyColumns: Record<
      ProductComponentsColumn,
      ExtendedColumn<ProductTree, ProductComponentSortableColumn>
    > = mapRecord(propertyRecord, (prop) =>
      customPropertyColumn(
        (row) =>
          row.type === "project" ? row.project.properties[prop.id]?.value : "",
        prop
      )
    );

    return [
      ...extractBy(columns, config),
      ...extractBy(propertyColumns, config),
    ];
  }, [
    properties,
    tr,
    canDelete,
    config,
    onToggleExpand,
    canUpdateProduct,
    handleRemoveComponent,
    canEditQuantity,
    handleEditQuantity,
    definitionIdx,
  ]);
}

function DeleteProductPopover(props: {
  isDisabled?: boolean;
  className?: string;
  onClick: () => void;
}) {
  const { isDisabled, className, onClick } = props;
  const tr = useTranslations();

  return (
    <div className={className}>
      <AlertPopover
        onPrimaryClick={onClick}
        title={tr.translateAsString("product.bom.components.delete.title")}
        content={tr.translate("product.bom.components.delete.description")}
        position="left-top"
        isDense
      >
        <Button
          isDense
          intent="array_primary"
          icon="trash"
          className={styles.hover_button}
          isDisabled={isDisabled}
        />
      </AlertPopover>
    </div>
  );
}
