import { Project } from "@aletiq/types";
import { useEffect } from "react";
import { usePagination, useSortKey } from "../../../../hooks";
import { useProductUsedIn } from "../../hooks";

export default function useProductUsedInPanel(
  projectId: number,
  search: string
) {
  const { sortState, setSortKey } = useSortKey<keyof Project>("name");

  const pagination = usePagination();
  const { limit, selectedPage, resetSelectedPage } = pagination;

  const { data, isLoading } = useProductUsedIn(projectId, {
    limit,
    offset: selectedPage * limit,
    orderDirection: sortState.direction,
    search,
  });

  useEffect(() => {
    resetSelectedPage();
  }, [search, sortState, resetSelectedPage]);

  return {
    usedIn: data?.list ?? [],
    totalUsedIn: data?.count ?? 0,
    isLoading,
    sortState,
    onSort: setSortKey,
    pagination,
  };
}
