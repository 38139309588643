import { Tab } from "@blueprintjs/core";
import React, { useState } from "react";
import { PageContents, PageTitle, Tabs } from "../../../components";
import { useTranslations } from "../../../util";
import { ANALYTICS_VISITED_USERS, useTrackEvent } from "../../../analytics";
import AdministrationTabs from "../common/AdministrationTabs/AdministrationTabs";
import GroupList from "./GroupList";
import UserList from "./UserList";
import styles from "./Users.module.scss";

type TabId = "users" | "groups";

export default function Users() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_USERS);

  const [tab, setTab] = useState<TabId>("users");

  return (
    <PageContents leftPanel={<AdministrationTabs />}>
      <PageTitle icon="user">{tr.translate("admin.users.title")}</PageTitle>
      <div className={styles.page}>
        <Tabs
          selectedTabId={tab}
          onChange={(tabId) => setTab(tabId as TabId)}
          renderActiveTabPanelOnly
        >
          <Tab
            id="users"
            title={tr.translate("admin.users.title")}
            panel={<UserList />}
          />
          <Tab
            id="groups"
            title={tr.translate("admin.groups")}
            panel={<GroupList />}
          />
        </Tabs>
      </div>
    </PageContents>
  );
}
