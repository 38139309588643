import React, { useState } from "react";
import { useProcesses } from "../../features/tasks/hooks";
import { filterByString, sortByString, useTranslations } from "../../util";
import FilterView from "./FilterView";

export default function WorkflowFilter(props: {
  selected: number[];
  onSelect: (values?: number[]) => void;
  activeWorkflowsOnly?: boolean;
}) {
  const tr = useTranslations();
  const { selected, onSelect, activeWorkflowsOnly } = props;

  const [search, setSearch] = useState("");
  const { data: allWorkflows = [] } = useProcesses();

  const onClear = () => {
    onSelect();
  };

  const workflowsFilteredByStatus = activeWorkflowsOnly
    ? allWorkflows.filter((w) => !w.isArchived)
    : allWorkflows;

  const searchedWorkflows = filterByString(
    workflowsFilteredByStatus,
    (workflow) => workflow.title,
    search
  );

  const sortedWorkflows = sortByString(
    searchedWorkflows,
    (workflow) => workflow.title
  );

  return (
    <FilterView
      items={sortedWorkflows.map((w) => ({ id: w.id, text: w.title }))}
      selected={selected}
      all={sortedWorkflows.map((w) => w.id)}
      placeholder={tr.translateAsString("filter.workflow.placeholder")}
      onSelect={onSelect}
      onClear={onClear}
      search={search}
      onSearch={setSearch}
      hasSelectAll
    />
  );
}
