import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { processKeys, taskKeys } from "./queries";

export default function useDeleteProcess() {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation((process: number) => api.task.deleteProcess(process), {
    onSuccess: () => {
      queryClient.invalidateQueries(processKeys.all);
      queryClient.invalidateQueries(taskKeys.all);
      queryClient.invalidateQueries(activityKeys.all);
    },
  });
}
