import { API } from "@aletiq/api";
import { RevisionSpec } from "@aletiq/types";
import { isPartParent } from "../../../services";
import { handleApproveSubComponents } from "./handleApproveSubComponents";
import waitComponentsComplete from "./waitComponentsComplete";

export default async function savePartRevision(
  api: API,
  partId: number,
  revision: RevisionSpec
): Promise<
  | "success"
  | "cant-approve"
  | "incomplete-components"
  | "cant-approve-components"
> {
  try {
    const part = await api.pdm.getPart(partId);
    // Already saved (likely when saving parent assembly)
    if (part.lastIteration.state === "approved") {
      return "success";
    }

    if (isPartParent(part.type)) {
      const componentsComplete = await waitComponentsComplete(api, partId);
      if (!componentsComplete) {
        return "incomplete-components";
      }

      const componentsApproved = await handleApproveSubComponents(
        api,
        partId,
        componentsComplete
      );
      if (!componentsApproved) {
        return "cant-approve-components";
      }
    }

    await api.pdm.savePartRevision(partId, revision);
    return "success";
  } catch (_) {
    return "cant-approve";
  }
}
