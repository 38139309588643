import {
  ButtonSP,
  HighlightedText,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  SearchInput as SearchInputDS,
} from "@aletiq/design-system";
import { Entity, EntityType } from "@aletiq/types";
import { IconName } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  useAccessSearchResult,
  useSearchHistory,
} from "../../features/search/hooks";
import { useNavigate, useOnClickOutside } from "../../hooks";
import { useTranslations } from "../../util";
import styles from "./NavBar.module.scss";
import NavigationButton from "./NavigationButton";

export default function SearchInput() {
  const match = useRouteMatch({
    path: "/search/:searchString",
  }) as { params?: { searchString?: string } } | null;

  const tr = useTranslations();

  const [value, setValue] = useState(match?.params?.searchString ?? "");
  const [isShowingInput, setIsShowingInput] = useState(false);
  const [isShowingHistory, setIsShowingHistory] = useState(true);

  const { data: searchHistory = [], isFetching } = useSearchHistory(value);
  const { mutate: updateSearchHistoryEntry } = useAccessSearchResult();

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleSubmit = () => {
    if (value === "") {
      return;
    }
    navigate({ path: `/search/${encodeURIComponent(value)}` });
  };

  const handleSetValue = (newValue: string) => {
    setValue(newValue);
    handleShowHistory();
  };

  const handleShowInput = () => {
    setIsShowingInput(true);
    handleShowHistory();
  };

  const handleShowHistory = () => setIsShowingHistory(true);
  const handleCloseHistory = () => setIsShowingHistory(false);

  const handleOpenRecentSearchResult = (entity: Entity) => () => {
    switch (entity.type) {
      case "file":
        navigate({ panel: `documentId-${entity.entity.id}` });
        break;
      case "passport":
        navigate({ panel: `passportId-${entity.entity.id}` });
        break;
      case "process":
        navigate({ panel: `workflowId-${entity.entity.id}` });
        break;
      case "part":
        navigate({ panel: `partId-${entity.entity.id}` });
        break;
      case "project":
        navigate({ path: `/project/${entity.entity.id}` });
        break;
      case "bill":
        navigate({
          path: `/project/${entity.entity.product}/operation-bills/${entity.entity.id}`,
        });
        break;
    }
    updateSearchHistoryEntry({ type: entity.type, id: entity.entity.id });
  };

  const handleClickOutsideInput = () => {
    value === "" && setIsShowingInput(false);
  };

  useOnClickOutside(inputRef, handleClickOutsideInput);

  useEffect(() => {
    //when leaving search page, reset searched value and hide input
    if (!match) {
      setValue("");
      setIsShowingInput(false);
    }
  }, [match]);

  return (
    <>
      {isShowingInput ? (
        <Popover2
          fill
          intent="primary"
          position="bottom-left"
          isOpen={isShowingHistory}
          disabled={searchHistory.length === 0 && !isFetching}
          popoverClassName={styles.search_history}
          onInteraction={handleShowHistory}
          onClose={handleCloseHistory}
          enforceFocus={false}
          autoFocus={false}
          content={
            <Menu isDense className={styles.search_history}>
              <MenuDivider
                isDense
                title={tr.translate("navbar.quick-search.recent")}
                className={styles.dividerTitle}
              />
              {searchHistory.map((entry, index) => (
                <MenuItem
                  key={index}
                  intent="primary"
                  className={styles.search_history_entry}
                  text={
                    <HighlightedText
                      highlight={value}
                      text={getEntityName(entry.entity)}
                    />
                  }
                  icon={getEntityIcon(entry.entity)}
                  onClick={handleOpenRecentSearchResult(entry.entity)}
                  disabled={
                    entry.entity.type !== "bill" &&
                    entry.entity.entity.isDeleted
                  }
                  shouldDismissPopover
                  isDense
                />
              ))}
            </Menu>
          }
        >
          <SearchInputDS
            ref={inputRef}
            value={value}
            onChange={handleSetValue}
            onKeyDown={(ev) =>
              ev.key === "Enter" && handleSubmit() && ev.currentTarget.blur()
            }
            intent="default"
            removeClearButton
            autoFocus
            className={styles.searchbar}
            rightElement={
              <ButtonSP
                icon="arrow-right"
                view="flat"
                color="primary"
                className={styles.arrow}
                onClick={handleSubmit}
              />
            }
          />
        </Popover2>
      ) : (
        <NavigationButton onClick={handleShowInput} rightIcon="search">
          {tr.translate("navbar.quick-search")}
        </NavigationButton>
      )}
    </>
  );
}

function getEntityName(entity: Entity): string {
  if (entity.type === "process") {
    return entity.entity.title;
  }
  if (entity.type === "bill") {
    return entity.entity.index;
  }
  return entity.entity.name;
}

function getEntityIcon(entity: Entity): IconName {
  if (entity.type !== "project") {
    return ENTITY_ICON[entity.type];
  }
  if (entity.entity.isTool) {
    return "wrench";
  }
  return "projects";
}

const ENTITY_ICON: Record<EntityType, IconName> = {
  passport: "id-number",
  file: "document",
  project: "projects",
  part: "cube",
  process: "flow-linear",
  bill: "numbered-list",
};
