import { useEffect, useRef, useState } from "react";

export default function useDelayed(
  input: boolean,
  delay: number = 300
): boolean {
  const delayRef = useRef(delay);
  const [output, setOutput] = useState(false);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    if (!input) {
      setOutput(false);
      return;
    }

    const ref = setTimeout(() => {
      setOutput(input);
    }, delayRef.current);
    return () => {
      clearTimeout(ref);
    };
  }, [input]);
  return output;
}
