import React from "react";
import { Tag } from "../../../../components";
import { useTranslations } from "../../../../util";
import { Target } from "../PassportAttributes/Dialogs/CreatePassportPropertyDialog";
import styles from "./ProductPropertyTarget.module.scss";

export default function ProductPropertyTarget(props: { target?: Target }) {
  const { target = "all" } = props;
  const tr = useTranslations();

  return (
    <div className={styles.target}>
      {(target === "all" || target === "article") && (
        <Tag isDense rightIcon="projects" unclickable>
          {tr.translate("admin.attribute.products.target.articles")}
        </Tag>
      )}
      {(target === "all" || target === "tool") && (
        <Tag isDense rightIcon="wrench" unclickable>
          {tr.translate("admin.attribute.products.target.tools")}
        </Tag>
      )}
    </div>
  );
}
