import { BOMEntry, ProjectDefinition } from "@aletiq/types";
import { useMemo } from "react";
import { useQueries, useQueryClient, UseQueryResult } from "react-query";
import useApi from "../../../app/useApi";

export default function useProjectBOMDefinitions(entries: BOMEntry[]) {
  const api = useApi();
  const queryClient = useQueryClient();

  const projectsQueries = useQueries(
    entries.map((e) => ({
      queryKey: ["projects", e.entity, "definitions", e.definition],
      queryFn: () => api.project.getProjectDefinition(e.entity, e.definition),
    }))
  ) as UseQueryResult<ProjectDefinition>[];

  return useMemo(() => {
    const results = projectsQueries.flatMap((res) => res.data);
    const isLoading = projectsQueries.reduce(
      (acc: boolean, res) => acc || res.isLoading,
      false
    );
    if (results.some((p) => p === undefined)) {
      return { data: undefined, isLoading };
    }
    const data: Record<number, Record<number, ProjectDefinition>> = {};
    entries.forEach((e) => {
      data[e.entity] = {
        [e.definition]: queryClient.getQueryData([
          "projects",
          e.entity,
          "definitions",
          e.definition,
        ]) as ProjectDefinition,
      };
    });
    return { data, isLoading };
  }, [entries, projectsQueries, queryClient]);
}
