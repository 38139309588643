import { Callout } from "@aletiq/design-system";
import React from "react";
import { StateTagTransition } from "../tags";
import { GenericStateTagProps } from "../tags/GenericStateTag/GenericStateTag";
import styles from "./ChangeRevisionStateButton.module.scss";

export type ChangeRevisionContentProps = {
  description: string | JSX.Element;
  initialProps: GenericStateTagProps;
  finalProps?: GenericStateTagProps;
  callout?: {
    title: string;
    description: string | JSX.Element;
  };
};

export default function ChangeRevisionContent(
  props: ChangeRevisionContentProps
) {
  const { description, callout, initialProps, finalProps } = props;

  return (
    <div className={styles.content}>
      {callout && (
        <Callout
          className={styles.callout}
          intent={"warning"}
          title={callout.title}
          isDense
          icon={"error"}
        >
          {callout.description}
        </Callout>
      )}
      {description}
      <div className={styles.center}>
        <div className={styles.schema}>
          <StateTagTransition
            initialProps={initialProps}
            finalProps={finalProps}
            arrowSize={24}
          />
        </div>
      </div>
    </div>
  );
}
