import { API } from "@aletiq/api";
import { Part } from "@aletiq/types";

export default async function getPartFile(
  api: API,
  file: File
): Promise<{
  part: Part | undefined;
  file: File;
}> {
  return {
    part: (
      await api.pdm.listPartsPage({
        limit: 1,
        fileName: file.name,
      })
    ).list[0],
    file,
  };
}
