import { CheckBox, Dialog, Field } from "@aletiq/design-system";
import { Property, PropertyType } from "@aletiq/types";
import React, { useEffect, useState } from "react";
import { EditProperty } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import {
  useResolvedPropertyOptions,
  useUpdateCustomProperty,
} from "../../hooks";
import { ResolvedPropertyOption } from "../../hooks/useResolvedPropertyOptions";
import styles from "./CreateProductProperty.module.scss";

export default function EditProductPropertyDialog(props: {
  property: Property;
  onClose: () => void;
}) {
  const { property, onClose } = props;
  const tr = useTranslations();

  const { data: resolvedOptions = [], isLoading: isResolvingOptions } =
    useResolvedPropertyOptions("product", property.name, property.options);
  const { mutate: updateProperty, isLoading } =
    useUpdateCustomProperty("product");

  const [propertyType, setPropertyType] = useState<PropertyType>(property.type);
  const [options, setOptions] =
    useState<ResolvedPropertyOption[]>(resolvedOptions);
  const [label, setLabel] = useState(property.label);
  const [description, setDescription] = useState(property.description);

  const isEnum = propertyType === "enum" || propertyType === "multi-enum";
  const canSubmit = label !== "" && ((isEnum && options.length > 0) || !isEnum);

  const onSubmit = () => {
    const postProperty = {
      label: property.label !== label ? label : undefined,
      description:
        property.description !== description ? description : undefined,
      type: property.type !== propertyType ? propertyType : undefined,
      options: isEnum && property.options !== options ? options : undefined,
    };

    updateProperty(
      { propertyName: property.name, update: postProperty },
      {
        onSettled: () => {
          onClose();
        },
      }
    );
  };

  useEffect(() => {
    setOptions(resolvedOptions);
  }, [resolvedOptions]);

  return (
    <Dialog
      isOpen
      icon="edit"
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("passport.dialog-edit.property.title", {
        name: property.label,
      })}
      onPrimaryClick={onSubmit}
      disablePrimary={!canSubmit}
      submitting={isLoading}
    >
      {!isResolvingOptions && (
        <EditProperty
          propertyType={propertyType}
          label={label}
          description={description}
          options={options.filter((o) => !o.deleted)}
          canEditPropertyType={property.canUpdateType}
          onEditDescription={setDescription}
          onEditLabel={setLabel}
          onEditOptions={setOptions}
          onEditPropertyType={setPropertyType}
          targetSelector={
            <Field label={tr.translate("admin.attribute.new.target")}>
              <div className={styles.targets}>
                <CheckBox
                  isDisabled
                  label={tr.translateAsString(
                    "admin.attribute.target.articles"
                  )}
                  checked={
                    property.group === "article" || property.group === "all"
                  }
                />

                <CheckBox
                  isDisabled
                  label={tr.translateAsString("admin.attribute.target.tools")}
                  checked={
                    property.group === "tool" || property.group === "all"
                  }
                />
              </div>
            </Field>
          }
          icon="id-number"
        />
      )}
    </Dialog>
  );
}
