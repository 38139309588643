import {
  Project,
  ProjectQueryFilterParams,
  ProjectQueryOrder,
  ToolingRequirement,
} from "@aletiq/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActionBar, DefaultFilters, Dialog, Table } from "../../../components";
import { useFilter, usePagination, useSortKey } from "../../../hooks";
import { useTranslations } from "../../../util";
import { MoreProductFilters } from "../common";
import {
  useAddToolingRequirements,
  useDefaultProductColumnConfig,
  usePaginatedProjects,
  useProductColumns,
} from "../hooks";
import styles from "./Dialog.module.scss";

export default function AddToolDialog(props: {
  projectId: number;
  projectTools: ToolingRequirement[];
  onClose: () => void;
}) {
  const { projectId, projectTools, onClose } = props;
  const tr = useTranslations();

  const [selectedTools, setSelectedTools] = useState<number[]>([]);

  const { mutate: addTools, isLoading } = useAddToolingRequirements(projectId);

  const {
    limit,
    selectedPage,
    handleLimit,
    handleSelectPage,
    resetSelectedPage,
  } = usePagination();
  const { sortState, setSortKey } = useSortKey<ProjectQueryOrder>("name");

  const { filter, handleFilter, handleClear } =
    useFilter<ProjectQueryFilterParams>({
      search: "",
    });

  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, resetSelectedPage]);

  const { data } = usePaginatedProjects({
    ...filter,
    projectType: "tool",
    limit: limit,
    offset: limit * selectedPage,
    order: sortState.key,
    orderDirection: sortState.direction,
  });
  const allTools = data?.list;
  const count = data?.count ?? 0;

  const availableTools = useMemo(() => {
    const projectToolsIds = projectTools.map((entry) => entry.tool);
    return (allTools ?? []).filter(
      (p) => p.id !== projectId && !projectToolsIds.includes(p.id)
    );
  }, [allTools, projectTools, projectId]);

  const { config, isLoading: isLoadingColumns } =
    useDefaultProductColumnConfig();
  const columns = useProductColumns({
    config,
  });

  const handleSelect = useCallback(
    (tools: Project[]) => setSelectedTools(tools.map((p) => p.id)),
    []
  );

  const handleSubmit = () => {
    addTools(
      selectedTools.map((tool) => ({ tool, comment: "", quantity: 1 })),
      { onSettled: onClose }
    );
  };

  return (
    <Dialog
      isOpen
      icon="new-link"
      title={tr.translateAsString("project.dialog.tools.add.title")}
      disablePrimary={selectedTools.length === 0}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
      onClose={onClose}
      className={styles.dialog_with_filters}
    >
      <div className={styles.dialog_contents}>
        <p>{tr.translate("project.dialog.tools.add.description")}</p>
        <ActionBar>
          <DefaultFilters
            objectType="product"
            filters={filter}
            handleFilter={handleFilter}
          />
          <MoreProductFilters
            filters={filter}
            handleFilter={handleFilter}
            handleClear={handleClear}
          />
        </ActionBar>
        <Table
          data={availableTools}
          columns={columns}
          searchString={filter.search}
          onSelect={handleSelect}
          sortOptions={{
            sortState,
            onSort: setSortKey,
          }}
          paginationOptions={{
            limit: limit,
            onSetLimit: handleLimit,
            onSetPage: handleSelectPage,
            selectedPage: selectedPage,
            itemCount: count,
          }}
          className={styles.table}
          isFetching={isLoadingColumns}
        />
      </div>
    </Dialog>
  );
}
