import { Icon, MenuDivider } from "@aletiq/design-system";
import { PropertyType } from "@aletiq/types";
import { IconName } from "@blueprintjs/core";
import React from "react";
import { getPropertyTypeIcon } from "../../../../components";
import FilterMenu from "../../../../components/filter/FilterMenu";
import FilterMenuItem from "../../../../components/filter/FilterMenuItem";
import FilterTemplate from "../../../../components/filter/FilterTemplate";
import { useTranslations } from "../../../../util";

const PROPERTY_TYPES: PropertyType[] = [
  "string",
  "number",
  "enum",
  "multi-enum",
  "date",
  "user",
];

export default function PropertyTypeFilter(props: {
  selected: PropertyType[];
  onSelect: (values?: PropertyType[]) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const { selected, onClear, onSelect } = props;

  const filterItems: {
    type: PropertyType;
    text: string;
    icon: IconName | JSX.Element;
  }[] = PROPERTY_TYPES.map((type) => ({
    type,
    text: tr.translateAsString("admin.attribute.type", {
      type: type === "multi-enum" ? "multi" : type,
    }),
    icon: getPropertyTypeIcon(type),
  }));

  const isAllSelected = filterItems?.length === selected.length;

  const handleSelect = (type: PropertyType) => () => {
    if (selected.includes(type)) {
      onSelect(selected.filter((t) => t !== type));
      return;
    }
    onSelect([...selected, type]);
  };

  const handleSelectAll = () => onSelect(isAllSelected ? [] : PROPERTY_TYPES);

  return (
    <FilterTemplate
      count={selected.length}
      onClear={onClear}
      placeholder={tr.translateAsString("admin.attribute.type.label")}
      content={
        <FilterMenu>
          <FilterMenuItem
            onSelect={handleSelectAll}
            text={tr.translate("generic.action-select-all")}
            selected={isAllSelected ?? false}
          />
          <MenuDivider />
          {filterItems.map((item) => (
            <FilterMenuItem
              key={item.type}
              selected={selected.includes(item.type)}
              onSelect={handleSelect(item.type)}
              text={
                <>
                  <Icon icon={item.icon} inline />
                  {item.text}
                </>
              }
            />
          ))}
        </FilterMenu>
      }
    />
  );
}
