import { PartComponent } from "@aletiq/types";
import React, { useState } from "react";
import { CellProps } from "react-table";
import {
  Button,
  EditableText,
  GenericStateTag,
  Select,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { ComponentTree } from "../../AllParts/AllPartsType";
import { usePartIterations, useUpdatePartRevisionSpec } from "../../hooks";
import partRevisionNotification from "../../partRevisionNotification";
import { isComponentsComplete } from "../../services";
import styles from "./ApproveAssemblyIterationDialog.module.scss";

export default function SelectedIterationCell(
  props: CellProps<ComponentTree> & {
    onUpdateComponentIteration: (
      assemblyId: number,
      component: PartComponent
    ) => void;
  }
) {
  const {
    rowsById,
    row: { id: rowId, depth, original: component },
    onUpdateComponentIteration,
  } = props;
  const tr = useTranslations();

  const [editedRevisionName, setEditedRevisionName] = useState<string>();

  const { data: iterations = [] } = usePartIterations(component.part?.id!);
  const { mutate: updatePartDraftRevisionSpec } = useUpdatePartRevisionSpec();

  const iterationIsApproved = component.iteration?.state === "approved";

  const revisionName = editedRevisionName ?? component.iteration?.name ?? "";

  const isRevisionNameUsed = iterations.some(
    (i) =>
      i.number !== component.iteration?.number! &&
      i.name.toLocaleLowerCase() === revisionName.toLocaleLowerCase()
  );

  const parentRowIndex = rowId.split(".").slice(0, depth).join(".");
  const parentComponent = rowsById[parentRowIndex]
    ? rowsById[parentRowIndex].original
    : undefined;

  const isIncomplete = !isComponentsComplete(component?.subRows || []);
  const hasObsoleteComponents = component.subRows?.some(
    (c) => c.iteration?.state === "obsolete"
  );
  const notification = partRevisionNotification({
    isIncomplete,
    hasObsoleteComponents,
  });

  const iterationItems = iterations.map((iteration) => ({
    text: iteration.name,
    key: iteration.number,
    node: (
      <GenericStateTag
        name={iteration.name}
        state={iteration.state}
        notification={notification}
      />
    ),
  }));

  const handleUpdateComponentIteration = (iterationId: number) => {
    const iteration = iterations.find((it) => it.number === iterationId);

    if (!iteration || !parentComponent || !parentComponent.part) {
      return;
    }

    onUpdateComponentIteration(parentComponent.part.id, {
      ...component,
      iteration,
    });
  };

  const handleSubmitNewRevisionName = () => {
    component.iteration &&
      component.part &&
      updatePartDraftRevisionSpec({
        partId: component.part.id,
        spec: {
          name: revisionName,
          description: component.iteration.description,
        },
      });
  };

  const isPartPrivate = (component.part && !component.part.hasAccess) ?? false;

  //no revision selection for standard parts
  if (component.part?.isStandard) {
    return null;
  }

  return (
    <div className={styles.revision_cell}>
      {isPartPrivate && revisionName}
      {!iterationIsApproved && !isPartPrivate && (
        <EditableText
          className={styles.revision_name}
          value={revisionName}
          onChange={setEditedRevisionName}
          onConfirm={handleSubmitNewRevisionName}
        />
      )}
      {/* no revision selection for root assembly (it's the revsion to approve) */}
      {depth > 0 && !isPartPrivate && (
        <Select
          filterable={false}
          onItemSelect={handleUpdateComponentIteration}
          items={iterationItems}
          isDense
        >
          <Button
            isDense
            rightIcon="caret-down"
            isDisabled={
              parentComponent && parentComponent.iteration?.state !== "draft"
            }
            className={styles.selected_revision}
          >
            <GenericStateTag
              name={iterationIsApproved ? component.iteration?.name : ""}
              state={iterationIsApproved ? "approved" : "draft"}
            />
          </Button>
        </Select>
      )}

      {isRevisionNameUsed && (
        <span className={styles.name_used}>
          {tr.translate("part.details.revisions.in-use")}
        </span>
      )}
    </div>
  );
}
