import React from "react";
import {
  CreateTag,
  IgnoreTag,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  UpdateTag,
  ValidateTag,
} from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { PartAction } from "../types";
import styles from "./UploadedPartsList.module.scss";

export default function PartActionSelect(props: {
  canApproveRevision: boolean;
  canUpdateDraft: boolean;
  children: React.ReactNode;
  isBatchSelector?: boolean;
  isDisabled?: boolean;
  onChangePartAction: (action: PartAction) => void;
  selectedAction?: PartAction;
}) {
  const {
    canApproveRevision,
    canUpdateDraft,
    children,
    isBatchSelector,
    isDisabled,
    onChangePartAction,
    selectedAction,
  } = props;

  const tr = useTranslations();

  return (
    <Popover2
      fill
      intent="primary"
      disabled={isDisabled}
      position="bottom-right"
      content={
        <Menu isDense>
          {/* Existing parts actions */}
          {isBatchSelector && (
            <MenuDivider
              isDense
              className={styles.first_menu_section}
              title={
                <span className={styles.menu_section}>
                  {tr.translate("part.dialog.drop-parts.action.existing")}
                </span>
              }
            />
          )}
          {(isBatchSelector || canUpdateDraft) && (
            <>
              <MenuItem
                isDense
                intent="primary"
                disabled={isDisabled || !canUpdateDraft}
                active={
                  selectedAction?.type === "update" && !selectedAction.approve
                }
                onClick={() =>
                  onChangePartAction({ type: "update", approve: false })
                }
                text={<UpdateTag />}
              />
              <MenuItem
                isDense
                intent="primary"
                disabled={isDisabled || !canUpdateDraft || !canApproveRevision}
                active={
                  selectedAction?.type === "update" && selectedAction.approve
                }
                onClick={() =>
                  onChangePartAction({ type: "update", approve: true })
                }
                text={
                  <>
                    <UpdateTag />
                    {" & "}
                    <ValidateTag />
                  </>
                }
              />
            </>
          )}

          {/* New parts actions */}
          {isBatchSelector && (
            <MenuDivider
              isDense
              title={
                <span className={styles.menu_section}>
                  {tr.translate("part.dialog.drop-parts.action.new")}
                </span>
              }
            />
          )}
          {(isBatchSelector || !canUpdateDraft) && (
            <>
              <MenuItem
                isDense
                intent="primary"
                disabled={isDisabled}
                active={
                  selectedAction?.type === "create" && !selectedAction.approve
                }
                onClick={() =>
                  onChangePartAction({ type: "create", approve: false })
                }
                text={<CreateTag />}
              />
              <MenuItem
                isDense
                intent="primary"
                disabled={isDisabled || !canApproveRevision}
                active={
                  selectedAction?.type === "create" && selectedAction.approve
                }
                onClick={() =>
                  onChangePartAction({ type: "create", approve: true })
                }
                text={
                  <>
                    <CreateTag />
                    {" & "}
                    <ValidateTag />
                  </>
                }
              />
            </>
          )}

          {/* All parts */}
          {isBatchSelector && (
            <MenuDivider
              isDense
              title={
                <span className={styles.menu_section}>
                  {tr.translate("part.dialog.drop-parts.action.all")}
                </span>
              }
            />
          )}
          <MenuItem
            isDense
            intent="primary"
            disabled={isDisabled}
            active={selectedAction?.type === "ignore"}
            onClick={() =>
              onChangePartAction({ type: "ignore", approve: false })
            }
            text={<IgnoreTag />}
          />
        </Menu>
      }
    >
      {children}
    </Popover2>
  );
}
