import React, { useMemo, useState } from "react";
import useClients from "../../features/clients/hooks/useClients";
import { filterByString, sortByString, useTranslations } from "../../util";
import FilterView from "./FilterView";

export default function ClientFilter(props: {
  selected: number[];
  noClientSelected: boolean;
  onSelect: (values?: number[]) => void;
  onSelectNoClient: (value?: boolean) => void;
  onClear: () => void;
}) {
  const { selected, onClear, noClientSelected, onSelectNoClient, onSelect } =
    props;

  const tr = useTranslations();

  const { data: clients = [] } = useClients();

  const [search, setSearch] = useState("");

  const sortedClients = useMemo(() => {
    const filteredClients = filterByString(clients, (c) => c.name, search);
    return sortByString(filteredClients, (c) => c.name);
  }, [clients, search]);

  return (
    <FilterView
      items={sortedClients.map((c) => ({ id: c.id, text: c.name }))}
      selected={selected}
      all={clients.map((c) => c.id)}
      placeholder={tr.translateAsString("filter.client.placeholder")}
      noSelection={noClientSelected}
      onSelect={onSelect}
      onClear={onClear}
      search={search}
      onSearch={setSearch}
      onSelectNone={onSelectNoClient}
      hasSelectAll
    />
  );
}
