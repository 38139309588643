import { DropdownButton } from "@aletiq/design-system";
import { Operation, OperationDocument, OperationTool } from "@aletiq/types";
import React, { useCallback, useMemo, useState } from "react";
import { CellProps } from "react-table";
import {
  ExtendedColumn,
  Field,
  Icon,
  MultistepDialog,
  Select,
  Table,
} from "../../../components";
import { isNotUndefined, useTranslations } from "../../../util";
import { useProjects } from "../hooks";
import styles from "./CopyOperationsForm.module.scss";
import { useCopyOperations, useProjectBills } from "./hooks";
import { OperationsInfoForm } from "./OperationsInfoForm";

type OperationCopyInfo = {
  number: string;
  name: string;
  description: string;
  documents: OperationDocument[];
  tools: OperationTool[];
};

export default function CopyOperationsForm(props: {
  projectId: number;
  billId: number;
  onClose: () => void;
}) {
  const { projectId, billId, onClose } = props;
  const tr = useTranslations();

  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [selectedBillId, setSelectedBillId] = useState<number | null>(null);
  const [selectedOperations, setSelectedOperations] = useState<number[]>([]);
  const [operationInfo, setOperationInfos] = useState<OperationCopyInfo[]>([]);

  const { data: projectBills = [] } = useProjectBills(selectedProject ?? 0);

  const handleOperationChange = useCallback((values: Operation[]) => {
    setSelectedOperations(values.map((op) => op.number));
  }, []);

  const handleChangeNumber = (index: number) => (number: string) => {
    setOperationInfos((arr) => {
      const updatedArray = [...arr];
      const newOperation = updatedArray[index];
      newOperation.number = number;
      updatedArray[index] = newOperation;

      return updatedArray;
    });
  };
  const handleChangeName = (index: number) => (name: string) => {
    setOperationInfos((arr) => {
      const updatedArray = [...arr];
      const newOperation = updatedArray[index];
      newOperation.name = name;
      updatedArray[index] = newOperation;

      return updatedArray;
    });
  };

  const { mutate: copyOperations, isLoading } = useCopyOperations(
    projectId,
    billId
  );

  const parsedOperations = operationInfo.map((op) => {
    const number = Number.parseInt(op.number);
    if (Number.isNaN(number)) {
      return undefined;
    }
    return {
      number: number,
      name: op.name,
      description: op.description,
      documents: op.documents,
      tools: op.tools,
    };
  });

  const isValid = parsedOperations.every(isNotUndefined);

  const handleSubmit = () => {
    const validOperations = parsedOperations.filter(isNotUndefined);
    copyOperations(validOperations, { onSettled: onClose });
  };

  const isPrimaryDisabled = (step: number) => {
    switch (step) {
      case 0:
        return !selectedProject || !selectedBillId;
      case 1:
        return selectedOperations.length === 0;
      case 2:
        return !isValid;
      default:
        return false;
    }
  };

  const handleNext = (step: number) => {
    if (step !== 1) {
      return;
    }
    const selectedOperationData =
      projectBills
        .find((b) => b.id === selectedBillId)
        ?.operations?.filter((op) => selectedOperations.includes(op.number)) ??
      [];
    setOperationInfos(
      selectedOperationData.map((op) => ({
        number: op.number.toString(),
        name: op.name,
        description: op.description,
        documents: op.documents,
        tools: op.tools,
      }))
    );
  };

  return (
    <MultistepDialog
      title={tr.translateAsString(
        "project.operation-bill.copy-operations.title"
      )}
      icon="duplicate"
      className={styles.form}
      isOpen
      onClose={onClose}
      nextButtonProps={{
        onClick: handleNext,
        isDisabled: isPrimaryDisabled,
      }}
      submitButtonProps={{
        onClick: handleSubmit,
        isDisabled: isPrimaryDisabled,
        isLoading,
      }}
      closeButtonProps={{
        onClick: onClose,
      }}
      panels={[
        {
          id: "choose-product",
          title: tr.translateAsString(
            "project.operation-bill.copy-operations.choose-product.title"
          ),
          panel: (
            <ChooseProduct
              selectedBillId={selectedBillId}
              selectedProjectId={selectedProject}
              onSelectBillId={setSelectedBillId}
              onSelectProject={setSelectedProject}
            />
          ),
        },
        {
          id: "choose-operations",
          title: tr.translateAsString(
            "project.operation-bill.copy-operations.choose-operations.title"
          ),
          panel: (
            <ChooseOperations
              selectedProjectId={selectedProject}
              selectedBillId={selectedBillId}
              onOperationChange={handleOperationChange}
            />
          ),
        },
        {
          id: "check",
          title: tr.translateAsString(
            "project.operation-bill.copy-operations.check.title"
          ),
          panel: (
            <CheckOperations
              operationInfo={operationInfo}
              onChangeNumber={handleChangeNumber}
              onChangeName={handleChangeName}
            />
          ),
        },
      ]}
    />
  );
}

function ChooseProduct(props: {
  selectedProjectId: number | null;
  selectedBillId: number | null;
  onSelectProject: (project: number | null) => void;
  onSelectBillId: (bill: number | null) => void;
}) {
  const { selectedProjectId, selectedBillId, onSelectProject, onSelectBillId } =
    props;
  const tr = useTranslations();
  const [projectSearch, setProjectSearch] = useState("");
  const { data: projects = [] } = useProjects({
    limit: 100,
    search: projectSearch,
  });
  const { data: projectBills = [] } = useProjectBills(selectedProjectId ?? 0);

  const selectedBill = projectBills.find((b) => b.id === selectedBillId);
  const selectedProject = projects.find((p) => p.id === selectedProjectId);

  return (
    <div>
      <Field
        label={tr.translate(
          "project.operation-bill.copy-operations.choose-product.product"
        )}
      >
        <Select
          filterable
          onQueryChange={(q) => setProjectSearch(q)}
          onItemSelect={onSelectProject}
          items={projects.map((p) => ({ key: p.id, text: p.name }))}
        >
          <DropdownButton
            view="outlined"
            color="primary"
            className={styles.dropdown}
            text={
              selectedProjectId
                ? selectedProject?.name
                : tr.translateAsString(
                    "project.operation-bill.copy-operations.choose-product.product-button"
                  )
            }
          />
        </Select>
      </Field>
      <Field label={tr.translateAsString("project.operation-bill.bill-index")}>
        <Select
          filterable
          items={projectBills.map((b) => ({
            key: b.id,
            text: b.index,
          }))}
          onItemSelect={onSelectBillId}
        >
          <DropdownButton
            disabled={selectedProject === null}
            color="primary"
            className={styles.dropdown}
            view="outlined"
            text={
              selectedBill
                ? selectedBill?.index
                : tr.translateAsString(
                    "project.operation-bill.copy-operations.choose-operations.title"
                  )
            }
          />
        </Select>
      </Field>
    </div>
  );
}

function ChooseOperations(props: {
  selectedProjectId: number | null;
  selectedBillId: number | null;
  onOperationChange: (operations: Operation[]) => void;
}) {
  const tr = useTranslations();

  const { selectedProjectId, selectedBillId, onOperationChange } = props;

  const { data: projectBills = [] } = useProjectBills(selectedProjectId ?? 0);
  const selectedBill = projectBills.find((b) => b.id === selectedBillId);

  const columns: ExtendedColumn<Operation>[] = useMemo(
    () => [
      {
        Header: tr.translateAsString(
          "project.operation-bill.copy-operations.table.number"
        ),
        id: "number",
        accessor: "number",
      },
      {
        Header: tr.translateAsString(
          "project.operation-bill.copy-operations.table.operation"
        ),
        id: "name",
        accessor: "name",
      },
      {
        Header: tr.translateAsString(
          "project.operation-bill.copy-operations.table.documents"
        ),
        id: "documents",
        accessor: (op: Operation) => op.documents.length,
        Cell: (cellProps: CellProps<Operation>) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon icon="document" inline />
            {cellProps.value}
          </div>
        ),
      },
      {
        Header: tr.translateAsString(
          "project.operation-bill.copy-operations.table.tools"
        ),
        id: "tools",
        accessor: (op: Operation) => op.tools.length,
        Cell: (cellProps: CellProps<Operation>) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon icon="wrench" inline />
            {cellProps.value}
          </div>
        ),
      },
    ],
    [tr]
  );

  const operations = useMemo(() => {
    return selectedBill?.operations ?? [];
  }, [selectedBill]);

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        {tr.translate(
          "project.operation-bill.copy-operations.choose-operations.caption"
        )}
      </div>
      <Table columns={columns} data={operations} onSelect={onOperationChange} />
    </div>
  );
}

function CheckOperations(props: {
  operationInfo: OperationCopyInfo[];
  onChangeNumber: (index: number) => (number: string) => void;
  onChangeName: (index: number) => (number: string) => void;
}) {
  const { operationInfo, onChangeName, onChangeNumber } = props;

  const tr = useTranslations();

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        {tr.translate("project.operation-bill.copy-operations.check.caption")}
      </div>
      <OperationsInfoForm
        operations={operationInfo}
        handleChangeNumber={onChangeNumber}
        handleChangeName={onChangeName}
      />
    </div>
  );
}
