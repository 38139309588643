import { Project, SortState } from "@aletiq/types";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { ExtendedColumn, Table } from "../../../../components";
import ProductLink from "../../../../components/link/ProductLink";
import { Pagination } from "../../../../hooks/utils/usePagination";
import { useTranslations } from "../../../../util";
import { useProject } from "../../hooks";

export default function ProjectUsedInTable(props: {
  projectId: number;
  usedIn: Project[];
  totalUsedIn: number;
  search?: string;
  pagination: Pagination;
  sortState: SortState<keyof Project>;
  onSort: (key: keyof Project) => void;
  isLoading?: boolean;
}) {
  const {
    projectId,
    usedIn,
    search,
    sortState,
    onSort,
    pagination,
    totalUsedIn,
    isLoading,
  } = props;
  const tr = useTranslations();

  const { data: project } = useProject(projectId);

  const columns = useMemo<ExtendedColumn<Project>[]>(
    () => [
      {
        Header: tr.translate("generic.label.name"),
        accessor: "name",
        Cell: (cellProps: CellProps<Project>) => (
          <ProductLink
            product={cellProps.row.original}
            search={cellProps.searchString}
            hasThumbnail
          />
        ),
      },
    ],
    [tr]
  );

  return (
    <Table
      columns={columns}
      data={usedIn}
      searchString={search}
      sortOptions={{ sortState, onSort }}
      paginationOptions={{
        itemCount: totalUsedIn,
        limit: pagination.limit,
        selectedPage: pagination.selectedPage,
        onSetLimit: pagination.handleLimit,
        onSetPage: pagination.handleSelectPage,
      }}
      noData={tr.translate(
        search === ""
          ? "product.bom.used-in.no-data"
          : "product.bom.used-in.no-data.filtered",
        { name: project?.name ?? "" }
      )}
      isFetching={isLoading}
    />
  );
}
