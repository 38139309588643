import React from "react";
import { ExtendedColumn, Table } from "../../components";
import { useNavigate, useSort } from "../../hooks";
import { useAccessSearchResult } from "../search/hooks";
import { usePassportTableColumns } from "./hooks";
import { ResolvedPassport } from "./types";

export default function PassportList(props: {
  filteredPassports: ResolvedPassport[];
  search?: string;
}) {
  const { filteredPassports, search } = props;

  const navigate = useNavigate();

  const {
    sortKey,
    sortDirection: direction,
    onSortKeyChange: onSort,
    sortedList: sortedPassports,
  } = useSort<ResolvedPassport>(filteredPassports, "updatedAt", "desc");

  const { mutate: accessSearchResult } = useAccessSearchResult();

  const handleOpenPassportPanel = (passport: ResolvedPassport) => {
    navigate({
      panel: `passportId-${passport.id}`,
    });
    accessSearchResult({ type: "passport", id: passport.id });
  };

  const handleAccessBillSearchResult = (billId: number) => {
    accessSearchResult({ type: "bill", id: billId });
  };

  const columns = usePassportTableColumns({
    onOpenBill: handleAccessBillSearchResult,
    projectType: "article",
  }) as ExtendedColumn<ResolvedPassport>[];

  return (
    <Table
      columns={columns}
      data={sortedPassports}
      searchString={search}
      openRowOptions={{ onOpen: handleOpenPassportPanel }}
      sortOptions={{
        sortState: { key: sortKey, direction },
        onSort,
      }}
    />
  );
}
