import { ProjectDefinitionId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { projectKeys } from "./queries";

type DeleteProjectTreeComponentsSpec = {
  componentId: number;
} & ProjectDefinitionId;

export default function useDeleteProductTreeComponents() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (specs: DeleteProjectTreeComponentsSpec[]) => {
      await Promise.all(
        specs.map(async ({ productId, definitionIdx, componentId }) => {
          await api.project.deleteBOMEntry(
            productId,
            definitionIdx,
            componentId
          );
        })
      );
    },
    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(activityKeys.all);
        spec.forEach(({ productId, definitionIdx }) => {
          queryClient.invalidateQueries(
            projectKeys.allBom(productId, definitionIdx)
          );
        });
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.product.bom.delete"),
        });
      },
    }
  );
}
