import { DocumentQueryFilterParams, DocumentQueryOrder } from "@aletiq/types";
import React, { useEffect, useState } from "react";
import { Button, Table } from "../../../../components";
import {
  ColumnOptionConfig,
  useDelayed,
  useDocumentId,
  useHasPermission,
  useNavigate,
  usePagination,
  useSortKey,
} from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { NewDocumentDialog } from "../../dialogs";
import {
  useDocuments,
  useDocumentTableColumns,
  useResolvedDocuments,
} from "../../hooks";
import { DocumentColumn } from "../../hooks/useDocumentTableColumns";
import { ResolvedDocument } from "../documentTypes";
import DocumentBatchActionHeader from "./DocumentBatchActionHeader/DocumentBatchActionHeader";
import styles from "./DocumentListPaginated.module.scss";

export default function DocumentListPaginated(props: {
  filter: DocumentQueryFilterParams;
  projectId?: number;
  onShowFilePreview: (documentId: number) => void;
  columnConfig: ColumnOptionConfig<DocumentColumn> | undefined;
}) {
  const { projectId, filter, onShowFilePreview, columnConfig } = props;
  const tr = useTranslations();

  const [createDocDialog, setCreateDocDialog] = useState(false);

  const navigate = useNavigate();
  const pagination = usePagination();
  const activeDocumentId = useDocumentId();
  const { limit, selectedPage, resetSelectedPage } = pagination;

  const canCreateDocuments = useHasPermission("create:documents");
  const canUpdateDocuments = useHasPermission("update:documents");
  const canDeleteDocuments = useHasPermission("delete:documents");
  const canCreateWorkflows = useHasPermission("create:workflows");
  const canSelectRows =
    canUpdateDocuments || canDeleteDocuments || canCreateWorkflows;

  const { sortState, setSortKey } = useSortKey<DocumentQueryOrder>(
    "updatedAt",
    "desc"
  );

  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, projectId, resetSelectedPage]);

  const {
    data: allDocuments,
    isLoading: documentsLoading,
    isFetching: documentsFetching,
  } = useDocuments({
    ...filter,
    projects: projectId
      ? [projectId, ...(filter?.projects ?? [])]
      : filter.projects,
    limit,
    offset: selectedPage * limit,
    order: sortState.key,
    orderDirection: sortState.direction,
    properties: true,
  });

  const { documents, isLoading: documentsResolving } = useResolvedDocuments(
    allDocuments?.list
  );

  const isLoading = documentsLoading || documentsResolving;
  const isFetching = useDelayed(documentsFetching);

  const columns = useDocumentTableColumns(columnConfig, onShowFilePreview);

  const renderBatchActionsHeader = (
    selectedDocuments: ResolvedDocument[],
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => (
    <DocumentBatchActionHeader
      selectedDocuments={selectedDocuments}
      toggleAllRowsSelected={toggleAllRowsSelected}
      projectId={projectId}
    />
  );

  const handleOpenDocumentPanel = (document: ResolvedDocument) => {
    navigate({
      panel: `documentId-${document.id}`,
    });
  };

  const isRowActive = (document: ResolvedDocument) =>
    document.id === activeDocumentId;

  const handleToggleNewDocDialog = () => setCreateDocDialog(!createDocDialog);

  return (
    <>
      <Table
        columns={columns}
        data={documents}
        noData={
          <>
            {tr.translate(
              !filter.search || filter.search === ""
                ? "documents.list.no-data"
                : "documents.list.no-data.filtered"
            )}
            <Button
              isDense
              isDisabled={!canCreateDocuments}
              className={styles.create_button}
              onClick={handleToggleNewDocDialog}
              intent="primary"
              icon="plus"
            >
              {tr.translate("documents.new.button")}
            </Button>
          </>
        }
        searchString={filter.search}
        sortOptions={{
          sortState,
          onSort: setSortKey,
        }}
        paginationOptions={{
          itemCount: allDocuments?.count ?? 0,
          limit,
          selectedPage,
          onSetLimit: pagination.handleLimit,
          onSetPage: pagination.handleSelectPage,
        }}
        renderBatchActionsHeader={
          canSelectRows ? renderBatchActionsHeader : undefined
        }
        openRowOptions={{ onOpen: handleOpenDocumentPanel }}
        isRowActive={isRowActive}
        isFetching={isFetching || isLoading || !columnConfig}
      />

      {createDocDialog && (
        <NewDocumentDialog
          projectId={projectId}
          onClose={handleToggleNewDocDialog}
        />
      )}
    </>
  );
}
