import { isInString } from "../predicate";

export default function filterByString<T>(
  values: T[],
  key: (value: T) => string,
  search: string
) {
  if (search === "") {
    return values;
  }

  return values.filter((value) => isInString(key(value), search));
}
