import { ProjectFieldValue, ProjectOptionFieldUpdate } from "@aletiq/types";
import { useState } from "react";
import { sortByNumber } from "../../../util";

export default function useFieldState(initialState: ProjectFieldValue[]) {
  const [nameChanges, setNameChanges] = useState<Record<number, string>>({});
  const [modifiedDefault, setModifiedDefault] = useState<number | null>(null);
  const [deletedValues, setDeletedValues] = useState<number[]>([]);
  const [newRows, setNewRows] = useState<
    {
      id: number;
      name: string;
      isDefault: boolean;
    }[]
  >([]);

  const onUpdate = (update: ProjectOptionFieldUpdate) => {
    const initialValue = initialState.find((v) => v.id === update.value);
    switch (update.type) {
      case "addValue":
        setNewRows((rows) => [
          ...rows,
          {
            id: initialState.length + rows.length + 1,
            name: update.value.name,
            isDefault: update.value.isDefault,
          },
        ]);
        break;

      case "deleteValue":
        if (initialValue) {
          if (initialValue.isDefault || modifiedDefault === update.value)
            setModifiedDefault(-1);
          setDeletedValues((vs) =>
            vs.includes(update.value)
              ? vs.filter((v) => v !== update.value)
              : [...vs, update.value]
          );
        } else {
          if (newRows[update.value - initialState.length - 1].isDefault) {
            setModifiedDefault(-1);
          }
          setNewRows((rows) =>
            rows
              .filter((row) => row.id !== update.value)
              .map((row, index) => ({
                ...row,
                id: initialState.length + index + 1,
              }))
          );
        }
        break;

      case "valueName":
        if (initialValue) {
          setNameChanges((rec) => ({
            ...rec,
            [update.value]: update.name,
          }));
        } else {
          setNewRows((rows) =>
            rows.map((row) =>
              row.id === update.value ? { ...row, name: update.name } : row
            )
          );
        }
        break;

      case "default":
        setModifiedDefault(-1);
        setNewRows((rows) => rows.map((v) => ({ ...v, isDefault: false })));
        if (update.value !== null) {
          if (initialValue) {
            setModifiedDefault(update.value);
          } else {
            setNewRows((rows) =>
              rows.map((row) =>
                row.id === update.value
                  ? { ...row, isDefault: true }
                  : { ...row, isDefault: false }
              )
            );
          }
        }
    }
  };

  const updatedValues = sortByNumber(initialState, (value) => value.id).map(
    (value) => ({
      id: value.id,
      name: nameChanges[value.id] ?? value.name,
      isDeleted: deletedValues.includes(value.id)
        ? !value.isDeleted
        : value.isDeleted,
      isNewlyDeleted: deletedValues.includes(value.id),
      isDefault:
        (modifiedDefault && modifiedDefault === value.id) ||
        (!modifiedDefault && value.isDefault),
      isNew: false,
    })
  );

  const newValues = newRows.map((value) => ({
    ...value,
    isDeleted: false,
    isNew: true,
  }));

  const nameUpdates: ProjectOptionFieldUpdate[] = Object.entries(
    nameChanges
  ).map(([id, name]) => ({
    type: "valueName",
    value: Number.parseInt(id),
    name,
  }));

  const deleteUpdates: ProjectOptionFieldUpdate[] = deletedValues.map((id) => ({
    type: "deleteValue",
    value: id,
  }));

  const newValueUpdates: ProjectOptionFieldUpdate[] = newRows.map((value) => ({
    type: "addValue",
    value: { ...value, isDeleted: false },
  }));

  const newDefault: ProjectOptionFieldUpdate[] =
    modifiedDefault === null
      ? []
      : modifiedDefault === -1
      ? [{ type: "default", value: null }]
      : [{ type: "default", value: modifiedDefault }];

  return {
    values: [...updatedValues, ...newValues],
    onUpdate,
    updates: nameUpdates.concat(deleteUpdates, newValueUpdates, newDefault),
  };
}
