import { EntityId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { documentKeys } from "./queries";

export default function useAddProductsDocuments(entities: EntityId[]) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation<void, Error, number[]>(
    async (documents: number[]) => {
      for (const entity of entities) {
        await api.document.addProjectDocuments(entity.id, documents);
      }
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.document.link"),
        });
      },
    }
  );
}
