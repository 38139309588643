import React from "react";
import {
  Intent,
  StateNotification,
} from "../../components/tags/GenericStateTag/GenericStateTag";
import { useTranslations } from "../../util";

export type PartRevisionFlags = {
  isIncomplete?: boolean;
  hasObsoleteComponents?: boolean;
};

export function PartRevisionNotificationText(params: PartRevisionFlags) {
  const { isIncomplete, hasObsoleteComponents } = params;

  const tr = useTranslations();

  if (isIncomplete) {
    return tr.translate("part.details.incomplete.bom");
  }

  if (hasObsoleteComponents) {
    return tr.translate("part.details.obsolete-components");
  }

  return <></>;
}

export function partRevisionNotificationIntent(
  params: PartRevisionFlags
): Intent {
  const { isIncomplete, hasObsoleteComponents } = params;

  if (isIncomplete) {
    return "warning";
  }

  if (hasObsoleteComponents) {
    return "danger";
  }

  return "default";
}

export default function partRevisionNotification(
  params: PartRevisionFlags
): StateNotification | undefined {
  const { isIncomplete, hasObsoleteComponents } = params;

  if (!isIncomplete && !hasObsoleteComponents) {
    return undefined;
  }

  return {
    content: <PartRevisionNotificationText {...params} />,
    intent: partRevisionNotificationIntent(params),
  };
}
