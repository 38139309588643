export const VALID_DOC_TABS = [
  "activities",
  "products",
  "revisions",
  "workflows",
  "citations",
];

export const VALID_PART_TABS = [
  "activities",
  "products",
  "revisions",
  "used-in",
  "workflows",
];

export const VALID_PASSPORT_TABS = [
  "activities",
  "files",
  "nomenclature",
  "workflows",
];

export const VALID_STANDARD_PART_TABS = ["activities", "used-in", "products"];
export const VALID_WORKFLOW_TABS = ["activities", "entities", "tasks"];
