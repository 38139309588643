import { ButtonSP, Tooltip } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../util";

export default function EditColumnButton() {
  const tr = useTranslations();

  return (
    <Tooltip
      content={tr.translate("generic.action.edit-columns.title")}
      position="top"
    >
      <ButtonSP isDense icon="th" view="flat" color="primary" />
    </Tooltip>
  );
}
