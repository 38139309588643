import { MenuItem, MenuSP, Popover2 } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../util";
import styles from "./NavBar.module.scss";
import UserButton from "./UserButton";

export default function UserMenu(props: {
  user: string;
  tenant: string;
  onLogout: () => void;
}) {
  const { user, tenant, onLogout } = props;
  const tr = useTranslations();

  return (
    <Popover2
      content={
        <MenuSP
          view="raised"
          color="primary"
          isDense
          className={styles.userMenu}
        >
          <div className={styles.menuTitle}>{user}</div>
          <MenuItem
            text={tr.translate("navbar.logout")}
            icon="log-out"
            onClick={onLogout}
            isDense
          />
        </MenuSP>
      }
      position="bottom-right"
      minimal
    >
      <UserButton user={user} currentTenant={tenant} />
    </Popover2>
  );
}
