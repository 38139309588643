import { Project } from "@aletiq/types";
import React from "react";
import { HighlightedText, MenuItem, ProductIcon } from "../../../../components";

export default function ProductSelectItem(props: {
  product: Project;
  onSelect: (product: Project) => void;
  isSelected: boolean;
  search: string;
}) {
  const { product, onSelect, isSelected, search } = props;
  const type = product.isTool ? "tool" : "article";
  return (
    <MenuItem
      onClick={() => onSelect(product)}
      text={<HighlightedText text={product.name} highlight={search} />}
      icon={<ProductIcon type={type} />}
      active={isSelected}
      isDense
    />
  );
}
