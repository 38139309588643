import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { pdmKeys } from "./queries";

export default function usePartIterationViewFiles(
  partId: number,
  partIteration: number,
  enableRequest: boolean = true
) {
  const api = useApi();

  return useQuery(
    pdmKeys.iterationView(partId, partIteration),
    () => api.pdm.getPartIterationWebViewFiles(partId, partIteration),
    { enabled: enableRequest }
  );
}
