import { Process } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../util";
import NameLink from "./NameLink";
import workflowLinkProps from "./props/workflowLinkProps";

export default function WorkflowLink(props: {
  workflow: Process | undefined;
  hasIcon?: boolean;
  className?: string;
  search?: string;
}) {
  const { className, workflow, hasIcon, search } = props;
  const tr = useTranslations();
  const linkProps = workflowLinkProps(tr, workflow);

  return (
    <NameLink
      className={className}
      hasIcon={hasIcon}
      search={search}
      {...linkProps}
    />
  );
}
