import { CheckBox, Dialog } from "@aletiq/design-system";
import { Part } from "@aletiq/types";
import { ProgressBar } from "@blueprintjs/core/lib/esm/components";
import React, { useState } from "react";
import useApi from "../../../../app/useApi";
import { useDownloadQueue, useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useLockParts } from "../../hooks";
import styles from "./styles.module.scss";

export default function LocksPartDialog(props: {
  parts: Part[];
  onClose: () => void;
}) {
  const { parts, onClose } = props;
  const tr = useTranslations();

  const api = useApi();
  const toaster = useToaster();
  const { handleDownload } = useDownloadQueue();

  const { mutate: lockParts, isLoading } = useLockParts();
  const [isDownloadSelected, setIsDownloadSelected] = useState(false);

  const partsToLock = parts
    .filter((part) => part.lockOwner === null)
    .map((part) => part.id);

  const handleConfirm = () => {
    lockParts(partsToLock, {
      onSettled: async () => {
        if (isDownloadSelected && partsToLock.length > 0) {
          const key = Math.random().toString();
          toaster.show(
            {
              intent: "primary",
              icon: "info-sign",
              timeout: 0,
              message: (
                <>
                  <div>{tr.translate("toaster.part.download.in-progress")}</div>
                  <ProgressBar intent="primary" value={0} />
                </>
              ),
            },
            `part-lock-download-${key}`
          );
          for (let i = 0; i < partsToLock.length; i++) {
            await handleDownload({
              fetchToken: () => api.pdm.getPartCadFileLink(partsToLock[i]),
              forceDownload: true,
            });
            const isDone = i === partsToLock.length - 1;
            toaster.show(
              {
                intent: isDone ? "success" : "primary",
                icon: isDone ? "tick" : "info-sign",
                timeout: isDone ? 2000 : 0,
                message: (
                  <>
                    <div>
                      {tr.translate(
                        isDone
                          ? "toaster.part.download.success"
                          : "toaster.part.download.in-progress"
                      )}
                    </div>
                    <ProgressBar
                      intent={isDone ? "success" : "primary"}
                      value={i + 1 / partsToLock.length}
                    />
                  </>
                ),
              },
              `part-lock-download-${key}`
            );
          }
        }
        onClose();
      },
    });
  };

  const handleSelect = () => {
    setIsDownloadSelected(!isDownloadSelected);
  };

  return (
    <Dialog
      isOpen
      title={tr.translateAsString("part.dialog.lock.parts.title", {
        nbr: partsToLock.length,
      })}
      className={styles.dialog}
      icon="lock"
      onClose={onClose}
      onPrimaryClick={handleConfirm}
      onSecondaryClick={onClose}
      submitting={isLoading}
    >
      {tr.translate("part.dialog.lock.parts.subtitle")}
      <div className={styles.checkbox_wrapper}>
        <CheckBox
          fill
          checked={isDownloadSelected}
          onChange={handleSelect}
          labelElement={
            <span className={styles.checkbox_label}>
              {tr.translate("part.dialog.lock.parts.download")}
            </span>
          }
        />
      </div>
    </Dialog>
  );
}
