import { Project, ProjectType } from "@aletiq/types";
import React, { useState } from "react";
import { usePaginatedProjects } from "../../features/project/hooks";
import { useTranslations } from "../../util";
import TooManyResultsCallout from "../callouts";
import FilterView from "./FilterView";

export default function ProjectFilter(props: {
  selected: number[];
  noProjectSelected?: boolean;
  projectType?: ProjectType;
  onSelect: (values?: number[]) => void;
  onSelectNone?: (value?: boolean) => void;
  onClear: () => void;
}) {
  const {
    selected,
    noProjectSelected,
    onClear,
    onSelect,
    onSelectNone,
    projectType,
  } = props;

  const tr = useTranslations();

  const [search, setSearch] = useState("");

  const { data } = usePaginatedProjects({ projectType, search, limit: 100 });
  const projects: Project[] = data?.list ?? [];

  return (
    <FilterView
      items={projects.map((c) => ({ id: c.id, text: c.name }))}
      selected={selected}
      all={projects.map((c) => c.id)}
      placeholder={tr.translateAsString("filter.project.placeholder", {
        type: projectType,
      })}
      noSelection={noProjectSelected}
      onSelect={onSelect}
      onClear={onClear}
      search={search}
      onSearch={setSearch}
      hasSelectAll
      onSelectNone={onSelectNone}
      headItems={projects.length >= 100 && <TooManyResultsCallout />}
    />
  );
}
