import { Part, PartIteration, PartIterationId } from "@aletiq/types";
import React from "react";
import {
  Card,
  GenericStateTag,
  GenericStatusTag,
  PartIcon,
  Subtitle,
} from "../../../../components";
import { Diff, useTranslations } from "../../../../util";
import partRevisionNotification from "../../../pdm/partRevisionNotification";
import { RevisionSelectMenu } from "../../dialogs/LinkNomenclatureDialog/RevisionSelectMenu";
import { useUpdatePartProjectBom } from "../../hooks";
import styles from "./ProjectPart.module.scss";
import ProjectPartActionBar from "./ProjectPartActionBar";

export default function ProjectPartCard(props: {
  projectId: number;
  definitionIdx: number;
  part: Part;
  iteration: PartIteration;
  diff: Record<number, Diff<PartIterationId>>;
  isRevisionSelectDisabled?: boolean;
  hasDeletePart?: boolean;
  hasCompleteBom?: boolean;
  hasObsoleteComponents?: boolean;
}) {
  const {
    part,
    iteration,
    diff,
    projectId,
    definitionIdx,
    isRevisionSelectDisabled,
    hasDeletePart,
    hasCompleteBom,
    hasObsoleteComponents,
  } = props;

  const tr = useTranslations();

  const { mutate: updatePartProjectBom } = useUpdatePartProjectBom(
    projectId,
    definitionIdx
  );

  const notification = partRevisionNotification({
    isIncomplete: !hasCompleteBom,
    hasObsoleteComponents: hasObsoleteComponents,
  });

  const handleAssignRevision = (revision: PartIteration) => {
    updatePartProjectBom({ part: part.id, iteration: revision.number });
  };

  return (
    <Card
      className={styles.card}
      icon={<PartIcon type={part.type} />}
      title={part.name}
      headerActions={
        <>
          <GenericStatusTag
            status={definitionIdx > 1 ? diff[part.id].status : undefined}
          />
          <ProjectPartActionBar
            projectId={projectId}
            definitionIdx={definitionIdx}
            part={part}
            iteration={iteration}
            hasDeletePart={hasDeletePart}
          />
        </>
      }
    >
      <div className={styles.revision_info}>
        <div className={styles.subtitle}>
          <Subtitle
            text={tr.translateAsString(
              "project.dialog.link.part.column.revision"
            )}
          />
        </div>
        {isRevisionSelectDisabled && (
          <GenericStateTag
            name={iteration.name}
            state={iteration.state}
            notification={notification}
          />
        )}
        {!isRevisionSelectDisabled && (
          <RevisionSelectMenu
            part={part}
            isDense
            view={"smooth"}
            intent={"default"}
            isDisabled={isRevisionSelectDisabled}
            assignedRevision={iteration}
            onAssignRevision={handleAssignRevision}
          />
        )}
      </div>
    </Card>
  );
}
