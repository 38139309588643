import { NonIdealState, Spinner } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { Card } from "../../components";
import { useFilter } from "../../hooks";
import { useTranslations } from "../../util";
import { WorkflowActionBar, WorkflowListArchived } from "./common";
import { useFilteredWorkflows, useProcesses } from "./hooks";
import { Filter } from "./types";
import styles from "./Workflows.module.scss";

export default function ArchivedWorkflows(props: { projectId?: number }) {
  const { projectId } = props;
  const tr = useTranslations();

  const { filter, handleFilter, handleClear } = useFilter<Filter>({
    search: "",
    isArchived: true,
  });

  const { data: workflows = [], isLoading } = useProcesses({
    relation: projectId ? { type: "project", id: projectId } : undefined,
    ...filter,
  });

  const [openWorkflows, setOpenWorkflows] = useState<number[]>([]);
  const allOpen = workflows.length === openWorkflows.length;

  const toggleAll = () => {
    setOpenWorkflows(allOpen ? [] : workflows.map((w) => w.id));
  };

  const toggleWorkflow = useCallback(
    (workflow: number) =>
      setOpenWorkflows((openWorkflows) => {
        const isFolded = openWorkflows.includes(workflow);
        if (isFolded) {
          return openWorkflows.filter((id) => id !== workflow);
        }
        return [workflow, ...openWorkflows];
      }),
    [setOpenWorkflows]
  );

  const archivedWorkflows = useFilteredWorkflows(workflows, filter);

  return (
    <>
      <WorkflowActionBar
        filter={filter}
        handleFilter={handleFilter}
        handleClear={handleClear}
        toggleAll={toggleAll}
        allOpen={allOpen}
        projectId={projectId}
      />
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          {archivedWorkflows.length > 0 && (
            <WorkflowListArchived
              workflows={archivedWorkflows}
              openWorkflows={openWorkflows}
              toggleFoldWorkflow={toggleWorkflow}
              search={filter.search}
            />
          )}
          {archivedWorkflows.length === 0 && (
            <Card className={styles.no_workflows_card}>
              <NonIdealState
                icon={
                  <img src="/assets/no-data.svg" height={80} alt="no data" />
                }
                description={tr.translate(
                  filter.search === ""
                    ? "workflow.page.archived.empty"
                    : "workflow.page.archived.empty.filtered"
                )}
              />
            </Card>
          )}
        </>
      )}
    </>
  );
}
