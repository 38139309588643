import { assign, defaultOptions, noop, replace } from "./helper";
import { ParserOption, Token, TokenLink } from "./types";

/*eslint no-useless-escape: 0*/
/*eslint no-cond-assign: 0*/
/*eslint no-control-regex: 0*/

/**
 * Block-Level Grammar
 */

type BlockBasic = {
  newline: RegExp;
  fences?: RegExp;
  heading: RegExp;
  nptable: RegExp | (() => void);
  lheading: RegExp;
  blockquote: RegExp;
  orderedList: RegExp;
  unOrderedList: RegExp;
  def: RegExp;
  paragraph: RegExp;
  text: RegExp;
  bullet: RegExp;
  item: RegExp;
};

type Block = BlockBasic & { gfm?: BlockBasic };
const block: Block = {
  newline: /^\n+/,
  heading: /^ *(#{1,6}) *([^\n]+?) *#* *(?:\n+|$)/,
  nptable: noop,
  lheading: /^([^\n]+)\n *(=|-){2,} *(?:\n+|$)/,
  blockquote: /^(>( >)*)+((?!^(?!>)).)*/,
  orderedList: /^(\d+\.) .*/,
  unOrderedList: /^([*+-]) .*/,
  def: /^ *\[([^\]]+)\]: *<?([^\s>]+)>?(?: +["(]([^\n]+)[")])? *(?:\n+|$)/,
  paragraph: /^((?:[^\n]+\n?(?!bull|heading|lheading|blockquote|def))+)\n*/,
  text: /^[^\n]+/,
  bullet: /(?:[*+-]|\d+\.)/,
  item: /^( *)(bull) [^\n]*(?:\n(?!\2bull )[^\n]*)*/,
};

block.item = replace(block.item)("bull", block.bullet)();
block.blockquote = replace(block.blockquote)("def", block.def)();
block.paragraph = replace(block.paragraph)("heading", block.heading)(
  "lheading",
  block.lheading
)("blockquote", block.blockquote)("def", block.def)("bull", block.bullet)();

/**
 * GFM Block Grammar
 */
export class Lexer {
  tokens: Token[];
  tokensLinks: TokenLink;
  options: ParserOption;
  rules: BlockBasic;

  constructor(options: ParserOption) {
    this.tokens = [];
    this.tokensLinks = {};
    this.options = options
      ? assign({}, defaultOptions, options)
      : assign({}, defaultOptions);
    this.rules = block;
  }

  /**
   * Preprocessing
   */
  parse = (src: string): { tokens: Token[]; tokensLinks: TokenLink } => {
    src = src
      .replace(/\r\n|\r/g, "\n")
      .replace(/\t/g, "    ")
      .replace(/\u00a0/g, " ")
      .replace(/\u2424/g, "\n");
    return this.token(src, true);
  };

  /**
   * Lexing
   */

  token = (
    src: string,
    top: boolean,
    bq?: boolean
  ): { tokens: Token[]; tokensLinks: TokenLink } => {
    let cap;
    src = src.replace(/^ +$/gm, "");

    while (src) {
      // newline
      if ((cap = this.rules.newline.exec(src))) {
        src = src.substring(cap[0].length);

        if (cap[0].length > 1) {
          this.tokens.push({
            type: "space",
          });
        }
      }
      // heading

      if ((cap = this.rules.heading.exec(src))) {
        src = src.substring(cap[0].length);
        this.tokens.push({
          type: "heading",
          depth: cap[1].length,
          text: cap[2],
        });
        continue;
      } // lheading

      if ((cap = this.rules.lheading.exec(src))) {
        src = src.substring(cap[0].length);
        this.tokens.push({
          type: "heading",
          depth: cap[2] === "=" ? 1 : 2,
          text: cap[1],
        });
        continue;
      } // blockquote

      if ((cap = this.rules.blockquote.exec(src))) {
        src = src.substring(cap[0].length);
        this.tokens.push({
          type: "blockquote",
          text: cap[1].replace(/^(>( >))*/gms, ""),
        });
        continue;
      } // ordered list

      if (this.rules.orderedList) {
        const cap = this.rules.orderedList.exec(src) as RegExpMatchArray | null;

        if (cap) {
          src = src.substring(cap[0].length);

          this.tokens.push({
            type: "orderedList",
            text: cap[0].slice(2, cap[0].length),
          });
          continue;
        }
      }

      if (this.rules.unOrderedList) {
        const cap = this.rules.unOrderedList.exec(
          src
        ) as RegExpMatchArray | null;

        if (cap) {
          src = src.substring(cap[0].length);

          this.tokens.push({
            type: "unOrderedList",
            text: cap[0].slice(2, cap[0].length),
          });
          continue;
        }
      }

      // def
      if (!bq && top && (cap = this.rules.def.exec(src))) {
        src = src.substring(cap[0].length);
        this.tokensLinks[cap[1].toLowerCase()] = {
          href: cap[2],
          title: cap[3],
        };
        continue;
      } // top-level paragraph

      if (top && (cap = this.rules.paragraph.exec(src))) {
        src = src.substring(cap[0].length);
        this.tokens.push({
          type: "paragraph",
          text:
            cap[1].charAt(cap[1].length - 1) === "\n"
              ? cap[1].slice(0, -1)
              : cap[1],
        });
        continue;
      } // text

      if ((cap = this.rules.text.exec(src))) {
        // Top-level should never reach here.
        src = src.substring(cap[0].length);
        this.tokens.push({
          type: "text",
          text: cap[0],
        });
        continue;
      }

      if (src) {
        throw new Error(`Infinite loop on byte: ${src.charCodeAt(0)}`);
      }
    }

    return { tokens: this.tokens, tokensLinks: this.tokensLinks };
  };
}
