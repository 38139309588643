import { CellProps, Row, TableExpandedToggleProps } from "react-table";
import { isNotUndefined } from "../predicate";

export type Id = { id: number };

export type TreePath = { revision: number } & Id;

export type ExpandTree = {
  subRows: ExpandTree[];
} & TreePath;

export type ExpandCellProps<T extends object> = CellProps<T> &
  TableExpandedToggleProps & {
    onToggleExpand: (path: TreePath[]) => (expanded: boolean) => void;
  };

export function toggleExpandAt(
  expandTrees: ExpandTree[],
  path: TreePath[],
  expanded: boolean
): ExpandTree[] {
  return expanded ? expandAt(expandTrees, path) : removeAt(expandTrees, path);
}

export function expandAt(trees: ExpandTree[], path: TreePath[]): ExpandTree[] {
  if (path.length === 0) {
    return trees;
  }

  const { id, revision } = path[0];

  const subtreeIndex = trees.findIndex(
    (tree) => tree.id === id && tree.revision === revision
  );

  if (subtreeIndex >= 0) {
    return [
      ...trees.slice(0, subtreeIndex),
      {
        id,
        revision,
        subRows: expandAt(trees[subtreeIndex].subRows, path.slice(1)),
      },
      ...trees.slice(subtreeIndex + 1),
    ];
  }

  return [...trees, { id, revision, subRows: expandAt([], path.slice(1)) }];
}

export function removeAt(trees: ExpandTree[], path: TreePath[]): ExpandTree[] {
  if (path.length === 0) {
    return trees;
  }

  const { id, revision } = path[0];

  const subtreeIndex = trees.findIndex(
    (tree) => tree.id === id && tree.revision === revision
  );

  if (subtreeIndex < 0) {
    return trees;
  }

  if (path.length > 1) {
    return [
      ...trees.slice(0, subtreeIndex),
      {
        id,
        revision,
        subRows: removeAt(trees[subtreeIndex].subRows, path.slice(1)),
      },
      ...trees.slice(subtreeIndex + 1),
    ];
  }

  return [...trees.slice(0, subtreeIndex), ...trees.slice(subtreeIndex + 1)];
}

export function flattenExpandTree(tree: ExpandTree): TreePath[] {
  const { id, revision, subRows } = tree;
  return [
    { id, revision },
    ...subRows.flatMap((subtree) => flattenExpandTree(subtree)),
  ];
}

export function findExpandedTrees<T extends Id>(
  parents: T[],
  expandTrees: ExpandTree[]
) {
  return expandTrees
    .filter((t) => parents.find((p) => p.id === t.id))
    .flatMap(flattenExpandTree);
}

//get parent row info to get full tree leaf path
//parent rows ids can be deduced from current row id
//current row id = parentId.index (and so on)
export function treePathFromRowId<T extends object>(
  row: Row<T>,
  rowsById: Record<string, Row<T>>,
  makePath: (original: T) => TreePath | undefined
): TreePath[] {
  const { id, depth } = row;
  return id
    .split(".")
    .slice(0, depth)
    .map((_, idx, rowIdTokens) => {
      const parentRowIndex = rowIdTokens.slice(0, idx + 1).join(".");
      const parentRow = rowsById[parentRowIndex];
      return makePath(parentRow.original);
    })
    .filter(isNotUndefined);
}
