import { Button, ButtonSP } from "@aletiq/design-system";
import { User } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import { useTranslations } from "../../../util";
import UserInitialsList from "../UserInitialsList";
import styles from "./EditableUserList.module.scss";
import UserSelect from "./UserSelect";

export default function EditableUserList(props: {
  onEditSelection: (users: number[]) => void;
  users: number[];
  className?: string;
  allowedUsers?: User[];
  intent?: "default" | "primary";
  isDense?: boolean;
  isDisabled?: boolean;
}) {
  const {
    onEditSelection,
    users,
    className,
    allowedUsers,
    intent = "default",
    isDense = true,
    isDisabled,
  } = props;
  const tr = useTranslations();

  const handleClearOwners = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    onEditSelection([]);
  };

  return (
    <div className={classNames(styles.owners, className)}>
      <UserSelect
        users={users}
        onEditSelection={onEditSelection}
        allowedUsers={allowedUsers}
        isDense={isDense}
        isDisabled={isDisabled}
      >
        <ButtonSP
          view="outlined"
          isDense={isDense}
          isDisabled={isDisabled}
          isFill
          color={intent}
          className={classNames(
            styles.button,
            users.length === 0 && styles.no_users
          )}
        >
          <div className={styles.pseudo_input}>
            {users.length > 0 ? (
              <UserInitialsList users={users} fill />
            ) : (
              tr.translate("generic.label.none")
            )}
            <Button
              isDense={isDense}
              isDisabled={users.length === 0}
              icon="cross"
              onClick={handleClearOwners}
            />
          </div>
        </ButtonSP>
      </UserSelect>
    </div>
  );
}
