import { EntryComponentProps } from "@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry";
import React, { PropsWithChildren } from "react";
import styles from "./CommentEditor.module.scss";

export default function MentionRow(
  props: PropsWithChildren<EntryComponentProps>
): React.ReactElement {
  const {
    mention,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps} className={styles.menuItem}>
      <div>{mention.name}</div>
    </div>
  );
}
