import { ToolingRequirement } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { projectKeys } from "./queries";

export default function useUpdateToolingRequirements() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (requirements: ToolingRequirement[]) => {
      for (const { article, id, quantity, comment, tool } of requirements) {
        await api.project.updateToolingRequirement(article, id, {
          quantity,
          comment,
          tool,
        });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.toolingRequirement);
        queryClient.invalidateQueries(projectKeys.toolingArticles);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.project.tool.update"),
        });
      },
    }
  );
}
