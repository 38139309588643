import { API } from "@aletiq/api";
import { Part } from "@aletiq/types";
import { partitionWith, removeFileExtension } from "../../../../../util";
import {
  getPartFile,
  isSupportedPartFile,
  isTempFile,
  partFormatFromFileName,
  partTypeFromFileName,
} from "../../../services";
import { GroupedPartFiles, PartState, StandardPartState } from "../types";

function makeCreatePart(file: File): PartState {
  return {
    file,
    partId: null,
    partInfo: {
      name: removeFileExtension(file.name),
      type: partTypeFromFileName(file.name)!,
      format: partFormatFromFileName(file.name)!,
      action: { type: "create", approve: false },
      revision: {
        name: "",
        description: "",
      },
    },
  };
}

function makeIgnoreStandardPart(part: Part, file: File): StandardPartState {
  return {
    action: "ignore",
    partId: part.id,
    name: part.name,
    type: part.type,
    file,
  };
}

function makeUpdatePart(part: Part, file: File): PartState {
  const lastIteration = part.lastIteration;
  const hasDraft = lastIteration.state === "draft";
  return {
    partId: part.id,
    file,
    partInfo: {
      name: part.name,
      type: partTypeFromFileName(file.name)!,
      format: partFormatFromFileName(file.name)!,
      action: { type: "update", approve: false },
      revision: {
        name: hasDraft ? lastIteration.name : "",
        description: hasDraft ? lastIteration.description : "",
      },
    },
  };
}

export default async function groupPartFiles(
  api: API,
  files: File[]
): Promise<GroupedPartFiles> {
  const currentUser = await api.auth.getCurrentUser();
  const partsFiles = await Promise.all(
    files
      .filter((file) => !isTempFile(file))
      .map((file) => getPartFile(api, file))
  );

  const [
    unsupportedPartsFiles,
    createdPartsFiles,
    standardPartsFiles,
    lockedPartsFile,
    unlockedPartsFile,
  ] = partitionWith(
    partsFiles,
    ({ file }) => !isSupportedPartFile(file),
    ({ part }) => part === undefined,
    ({ part }) => part!.isStandard,
    ({ part }) => part!.lockOwner !== null && part!.lockOwner !== currentUser.id
  );

  const created = createdPartsFiles.map(({ file }) => makeCreatePart(file));

  const unsupported = unsupportedPartsFiles.map(({ file }) => file);

  const locked = lockedPartsFile.map(({ part }) => part!);

  const standard = standardPartsFiles.map(({ part, file }) =>
    makeIgnoreStandardPart(part!, file)
  );

  const updated = unlockedPartsFile.map(({ part, file }) =>
    makeUpdatePart(part!, file)
  );

  return {
    valid: [...created, ...updated],
    unsupported,
    locked,
    standard,
  };
}
