import { PartFormat } from "@aletiq/types";

export default function partFormatFromFileName(
  fileName: string
): PartFormat | null {
  const nameParts = fileName.split(".");
  const extension = nameParts[nameParts.length - 1].toLocaleLowerCase();
  switch (extension) {
    case "catpart":
    case "catproduct": {
      return "catia";
    }
    case "sldprt":
    case "sldasm":
    case "slddrw": {
      return "solidworks";
    }
    default: {
      return null;
    }
  }
}
