import { Dialog } from "@aletiq/design-system";
import React, { ReactElement } from "react";
import { useTranslations } from "../../util";

export default function DeleteAlert(props: {
  elementName: string;
  title?: string;
  message?: string;
  children?: ReactElement;
  className?: string;
  isLoading?: boolean;
  onDelete: () => void;
  onClose: () => void;
}) {
  const tr = useTranslations();
  const {
    message,
    title,
    elementName,
    className,
    children,
    onClose,
    onDelete,
    isLoading,
  } = props;

  return (
    <Dialog
      isOpen={true}
      title={title || tr.translateAsString("alert.delete.title")}
      primaryText={tr.translateAsString("generic.action.delete")}
      intent="danger"
      icon="trash"
      onPrimaryClick={onDelete}
      onSecondaryClick={onClose}
      onClose={onClose}
      className={className}
      submitting={isLoading}
    >
      <p>
        {message || tr.translate("alert.delete.description", { elementName })}
      </p>
      {children}
    </Dialog>
  );
}
