import { DragEvent, DragEventHandler } from "react";
import { getDataTransferFiles, MAX_DROPPED_FILES } from "../util";
import { useTooManyFilesToaster } from "./toaster";

export default function useDragNDropFiles<T>(
  onDropFiles: (files: File[]) => void,
  canImportFiles: boolean = true
): {
  onDrop: DragEventHandler<T>;
  onDragOver: DragEventHandler<T>;
  onDragEnter: DragEventHandler<T>;
} {
  const showTooManyFilesError = useTooManyFilesToaster();

  return {
    onDrop: async (ev: DragEvent<T>) => {
      ev.preventDefault();

      if (ev.dataTransfer === null || !canImportFiles) {
        return;
      }

      const entries = ev.dataTransfer.items;
      const files: File[] = await getDataTransferFiles(entries);
      if (files.length > MAX_DROPPED_FILES) {
        showTooManyFilesError();
        return;
      }
      if (files.length > 0) {
        onDropFiles(files);
      }
    },
    onDragOver: (ev: DragEvent<T>) => {
      if (!canImportFiles) {
        return;
      }
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "copy";
    },
    onDragEnter: (ev: DragEvent<T>) => {
      if (!canImportFiles) {
        return;
      }
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "copy";
    },
  };
}
