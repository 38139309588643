import {
  Button,
  EditableText,
  Field,
  Icon,
  Input,
  Tooltip,
} from "@aletiq/design-system";
import { PropertyOption, PropertyType } from "@aletiq/types";
import { IconName } from "@blueprintjs/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CellProps } from "react-table";
import { ResolvedPropertyOption } from "../../features/administration/Attributes/hooks/useResolvedPropertyOptions";
import { useTranslations } from "../../util";
import { BatchActionButton } from "../BatchActionButton";
import { ExtendedColumn, Table } from "../table";
import styles from "./EditProperty.module.scss";
import { PropertyTypePicker } from "./PropertyTypePicker";

export default function EditProperty(props: {
  showUseCounts?: boolean;
  icon?: IconName;
  label: string;
  onEditLabel?: (value: string) => void;
  description: string;
  onEditDescription?: (value: string) => void;
  options: ResolvedPropertyOption[];
  onEditOptions?: (
    update:
      | ((old: ResolvedPropertyOption[]) => ResolvedPropertyOption[])
      | ResolvedPropertyOption[]
  ) => void;
  propertyType: PropertyType;
  canEditPropertyType?: boolean;
  onEditPropertyType?: (type: PropertyType) => void;
  targetSelector?: JSX.Element;
}) {
  const {
    description,
    label,
    options,
    propertyType,
    onEditDescription,
    onEditLabel,
    onEditOptions,
    onEditPropertyType,
    canEditPropertyType = false,
    showUseCounts = true,
    icon,
    targetSelector,
  } = props;
  const tr = useTranslations();

  const handleSelectType = (type_: PropertyType) => {
    onEditPropertyType && canEditPropertyType && onEditPropertyType(type_);
  };

  const addOptions = () => {
    onEditOptions &&
      onEditOptions([
        ...options,
        {
          name: "",
          label: "",
          deleted: false,
          uses: 0,
        },
      ]);
  };

  const handleEditValue = useCallback(
    (updateIndex: number) => (newLabel: string) => {
      onEditOptions?.((options) =>
        options.map((o, index) =>
          index === updateIndex ? { ...o, label: newLabel } : o
        )
      );
    },
    [onEditOptions]
  );

  const handleEditDescription = (value: string) => {
    onEditDescription && onEditDescription(value);
  };

  const handleEditLabel = (value: string) => {
    onEditLabel && onEditLabel(value);
  };

  const handleDeleteOptions = (toDelete: ResolvedPropertyOption[]) => {
    onEditOptions?.((options) =>
      options.map((option) => {
        if (
          toDelete.some(
            (o) => o.value === option.value && o.label === option.label
          )
        ) {
          return { ...option, deleted: true };
        }
        return option;
      })
    );
  };

  const columns: ExtendedColumn<ResolvedPropertyOption>[] = useMemo(
    () => [
      {
        id: "label",
        accessor: "label",
        fullWidthContent: true,
        Header: tr.translate("admin.attribute.new.values.value"),
        Cell: (cellProps: CellProps<PropertyOption>) => (
          <OptionValueCell {...cellProps} onConfirm={handleEditValue} />
        ),
      },
      {
        id: "uses",
        accessor: "uses",
        Header: (
          <div className={styles.option_uses_header}>
            {icon && <Icon icon={icon} inline color="var(--blue100)" />}#
          </div>
        ),
        width: "100px",
        minWidth: 100,
        Cell: (cellProps: CellProps<PropertyOption>) =>
          showUseCounts ? <>{cellProps.value}</> : <></>,
      },
    ],
    [tr, icon, handleEditValue, showUseCounts]
  );

  const renderActionsHeader = (
    selectedOptions: ResolvedPropertyOption[],
    toggleSelectAll: (value?: boolean | undefined) => void
  ) => (
    <ActionsHeader
      selectedOptions={selectedOptions}
      onDeleteOptions={handleDeleteOptions}
      onToggleSelectAll={toggleSelectAll}
    />
  );

  return (
    <>
      <div className={styles.first_line}>
        <div>
          <Tooltip
            content={tr.translateAsString(
              "passport.dialog-edit.property.option.disable-type"
            )}
            position="top"
            isDisabled={canEditPropertyType}
          >
            <PropertyTypePicker
              type={propertyType}
              isDisabled={!canEditPropertyType}
              onSelect={handleSelectType}
            />
          </Tooltip>
        </div>
        <Field
          className={styles.right_col}
          label={tr.translateAsString("admin.attribute.new.title")}
          isMandatory
        >
          <Input
            fill
            placeholder={tr.translateAsString("admin.attribute.new.title")}
            value={label}
            onChange={handleEditLabel}
            disabled={!onEditLabel}
            removeClearButton
            autoFocus
          />
        </Field>
      </div>
      <Field
        label={tr.translate("admin.attribute.new.description")}
        className={styles.description}
      >
        <Input
          fill
          placeholder={tr.translateAsString("admin.attribute.new.description")}
          value={description}
          onChange={handleEditDescription}
          disabled={!onEditDescription}
          removeClearButton
          autoFocus={false}
        />
      </Field>
      {targetSelector}
      {(propertyType === "enum" || propertyType === "multi-enum") && (
        <>
          {options.length > 0 && (
            <Field label={tr.translate("admin.attribute.new.values")}>
              <Table
                data={options}
                columns={columns}
                className={styles.table}
                renderBatchActionsHeader={renderActionsHeader}
              />
            </Field>
          )}
          <Button
            intent="secondary"
            className={styles.addOptions}
            onClick={addOptions}
            icon="plus"
          >
            {tr.translate("admin.attribute.new.new-option")}
          </Button>
        </>
      )}
    </>
  );
}

function ActionsHeader(props: {
  selectedOptions: ResolvedPropertyOption[];
  onDeleteOptions: (toDelete: ResolvedPropertyOption[]) => void;
  onToggleSelectAll: (value?: boolean | undefined) => void;
}) {
  const { selectedOptions, onDeleteOptions, onToggleSelectAll } = props;
  const tr = useTranslations();

  const canDeleteOptions = !selectedOptions.some((o) => o.uses > 0);

  const handleDeleteOptions = () => {
    canDeleteOptions && onDeleteOptions(selectedOptions);
    onToggleSelectAll(false);
  };

  return (
    <>
      <div className={styles.subtitle}>
        {tr.translate("passport.page.selected", {
          count: selectedOptions.length,
        })}
      </div>
      <Tooltip
        content={tr.translate("admin.attribute.new.values.delete.tooltip")}
        isDisabled={canDeleteOptions}
        position="top"
      >
        <BatchActionButton
          title={tr.translateAsString("admin.attribute.new.values.delete")}
          icon="trash"
          onClick={handleDeleteOptions}
          isDisabled={!canDeleteOptions}
          className={styles.delete_button}
        />
      </Tooltip>
    </>
  );
}

export function OptionValueCell(
  props: CellProps<PropertyOption> & {
    onConfirm: (updateIndex: number) => (newLabel: string) => void;
  }
) {
  const { onConfirm, value, row } = props;
  const index = row.index;

  const [editedValue, setEditedValue] = useState(value);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const handleConfirm = useCallback(
    (newLabel: string) => {
      if (newLabel !== value) {
        onConfirm(index)(newLabel);
      }
    },
    [onConfirm, value, index]
  );

  return (
    <EditableText
      autoFocus
      value={editedValue}
      onChange={setEditedValue}
      onConfirm={handleConfirm}
      className={styles.option_value}
    />
  );
}
