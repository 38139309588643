import { ProjectField } from "@aletiq/types";
import { NonIdealState } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  ANALYTICS_VISITED_PRODUCT_CONFIG,
  useTrackEvent,
} from "../../../analytics";
import {
  ActionBar,
  AlignRight,
  Button,
  Card,
  CardList,
  ConfigOptionIcon,
  PageTitle,
} from "../../../components";
import { useHasPermission } from "../../../hooks";
import { sortByNumber, useTranslations } from "../../../util";
import { ProjectPage } from "../common";
import { AddConfigDialog, EditConfigDialog } from "../dialogs";
import { useProject } from "../hooks";
import ConfigOptionCard from "./ConfigOptionCard";
import styles from "./ProjectConfig.module.scss";

export type ConfigDialog =
  | { type: "create-config" }
  | { type: "edit-config"; field: ProjectField };

export default function ProductConfig(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_CONFIG);

  const [dialog, setDialog] = useState<ConfigDialog>();

  const { data: project } = useProject(projectId);
  const canUpdateProject = useHasPermission("update:projects");

  const productOptions = project?.options ?? [];
  const productOptionsSorted = sortByNumber(
    productOptions,
    (option) => option.id
  );

  const handleOpenDialog = (dialog: ConfigDialog) => () => setDialog(dialog);

  const handleCloseDialog = () => setDialog(undefined);

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.config")}
    >
      <PageTitle icon={<ConfigOptionIcon />}>
        {tr.translate("page.title.config")}
      </PageTitle>
      <div className={styles.centered}>
        <ActionBar>
          <AlignRight />
          <Button
            isDense
            intent="secondary"
            icon="plus"
            isDisabled={!canUpdateProject}
            onClick={handleOpenDialog({ type: "create-config" })}
          >
            {tr.translate("product.config.option.new")}
          </Button>
        </ActionBar>

        <CardList>
          {productOptionsSorted.map((field) => (
            <ConfigOptionCard
              key={field.id}
              field={field}
              isDisabled={!canUpdateProject}
              onClickEdit={handleOpenDialog({ type: "edit-config", field })}
            />
          ))}
          {productOptionsSorted.length === 0 && (
            <Card>
              <NonIdealState
                className={styles.empty_state}
                icon={
                  <img src="/assets/no-data.svg" height={80} alt="no-data" />
                }
              >
                {tr.translate("product.config.no-data")}
                <Button
                  isDense
                  intent="primary"
                  icon="plus"
                  isDisabled={!canUpdateProject}
                  onClick={handleOpenDialog({ type: "create-config" })}
                  className={styles.empty_state_button}
                >
                  {tr.translate("product.config.option.new")}
                </Button>
              </NonIdealState>
            </Card>
          )}
        </CardList>

        {dialog && dialog.type === "edit-config" && (
          <EditConfigDialog
            projectId={projectId}
            configField={dialog.field}
            onClose={handleCloseDialog}
          />
        )}

        {dialog && dialog.type === "create-config" && (
          <AddConfigDialog projectId={projectId} onClose={handleCloseDialog} />
        )}
      </div>
    </ProjectPage>
  );
}
