import {
  ActiveDocument,
  ActiveDocumentRevision,
  Property,
} from "@aletiq/types";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import {
  defaultCreatedAtColumn,
  defaultPropertyColumn,
  defaultUpdatedAtColumn,
  EditableTextCell,
  ExtendedColumn,
  HighlightedText,
} from "../../../../../components";
import { ColumnOptionConfig } from "../../../../../hooks";
import {
  extractBy,
  indexBy,
  mapRecord,
  useTranslations,
} from "../../../../../util";
import { useProperties } from "../../../../administration/Attributes/hooks";
import { NewVersionDoc } from "../../../hooks";
import { VersionDocColumn } from "../../useVersionDocsColumnConfig";
import ActionCell from "./ActionCell";
import ActiveRevisionCell from "./ActiveRevisionCell";
import NewVersionDocumentNameCell from "./NewVersionDocumentNameCell";
import styles from "./NewVersionDocuments.module.scss";

export function useNewVersionDocsColumns(
  onAddDoc: (doc: ActiveDocument) => void,
  onRemoveDoc: (docId: number, state: "confirmed" | "unconfirmed") => void,
  onDownload: (docId: number, revision: number) => Promise<void>,
  onPreview: (value: {
    documentId: number;
    revisionId?: number;
  }) => Promise<void>,
  onSetDescription: (doc: NewVersionDoc, description: string) => void,
  onSetActiveRevision: (
    doc: NewVersionDoc,
    revision: ActiveDocumentRevision
  ) => void,
  config: Partial<ColumnOptionConfig<VersionDocColumn>> = {}
): ExtendedColumn<NewVersionDoc, keyof NewVersionDoc>[] {
  const tr = useTranslations();
  const { data: properties = [] } = useProperties("document");

  const propertyRecord: Record<VersionDocColumn, Property> = indexBy(
    properties,
    "id"
  );

  return useMemo(() => {
    const columns: Record<
      VersionDocColumn,
      ExtendedColumn<NewVersionDoc, keyof NewVersionDoc>
    > = {
      name: {
        accessor: "name",
        Header: tr.translate("generic.label.name"),
        fullWidthContent: true,
        Cell: (cell: CellProps<NewVersionDoc>) => (
          <NewVersionDocumentNameCell
            document={cell.row.original}
            search={cell.searchString}
          />
        ),
      },
      revisionName: {
        accessor: "revisionName",
        Header: tr.translate("version.details.revision.active"),
        fullWidthContent: true,
        Cell: (cell: CellProps<NewVersionDoc>) => (
          <ActiveRevisionCell
            document={cell.row.original}
            search={cell.searchString}
            onChange={(revision) =>
              onSetActiveRevision(cell.row.original, revision)
            }
          />
        ),
      },
      description: {
        accessor: "description",
        fullWidthContent: true,
        Header: tr.translate("version.details.operations"),
        Cell: (cell: CellProps<NewVersionDoc>) => {
          const document = cell.row.original;
          if (!document.doc.hasAccess) {
            return (
              <HighlightedText
                text={cell.value}
                highlight={cell.searchString}
                className={styles.no_access}
              />
            );
          }
          return (
            <EditableTextCell
              onConfirmNewValue={(description) =>
                onSetDescription(cell.row.original, description)
              }
              value={document.description}
              highlight={cell.searchString}
            />
          );
        },
      },
      createdAt: defaultCreatedAtColumn(),
      updatedAt: defaultUpdatedAtColumn(),
      action: {
        id: "action",
        width: 110,
        minWidth: 110,
        canSort: false,
        disableResizing: true,
        Cell: (cell: CellProps<NewVersionDoc>) => (
          <ActionCell
            document={cell.row.original}
            onPreview={onPreview}
            onDownload={onDownload}
            onAddDocument={onAddDoc}
            onRemove={onRemoveDoc}
          />
        ),
      },
    };

    const propertyColumns: Record<
      VersionDocColumn,
      ExtendedColumn<NewVersionDoc, keyof NewVersionDoc>
    > = mapRecord(propertyRecord, (prop) => defaultPropertyColumn(prop));

    return [
      ...extractBy(columns, config),
      ...extractBy(propertyColumns, config),
      ...extractBy(columns, {
        action: true,
      }),
    ];
  }, [
    tr,
    config,
    propertyRecord,
    onAddDoc,
    onRemoveDoc,
    onDownload,
    onPreview,
    onSetDescription,
    onSetActiveRevision,
  ]);
}
