import { Project, ProjectType, Property, PropertyValue } from "@aletiq/types";
import React, { useState } from "react";
import {
  Dialog,
  DropdownButton,
  EditCustomPropertyField,
  Field,
  getPropertyTypeIcon,
  Input,
  Menu,
  MenuItem,
  Popover2,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import { useUpdateProductsProperties } from "../../hooks";
import useUpdateProductsDescriptions from "../../hooks/useUpdateProductsDescriptions";
import styles from "./EditProductsPropertiesDialog.module.scss";

type ProductProperty =
  | { type: "custom"; property: Property }
  | { type: "description" };

export default function EditProductsPropertiesDialog(props: {
  products: Project[];
  productType?: ProjectType;
  onClose: () => void;
}) {
  const { products, productType, onClose } = props;
  const tr = useTranslations();

  const [selectedProperty, setSelectedProperty] = useState<ProductProperty>();
  const [propertyValue, setPropertyValue] = useState<PropertyValue>(null);
  const [description, setDescription] = useState("");

  const selectedProductsIds = products.map((p) => p.id);

  const { data: productProperties = [] } = useProperties("product");
  const { mutate: updateDescriptions, isLoading: isUpdatingDescriptions } =
    useUpdateProductsDescriptions(selectedProductsIds);
  const { mutate: updateProperties, isLoading: isUpdatingProperties } =
    useUpdateProductsProperties(selectedProductsIds);

  const filteredProperties = productProperties.filter(
    (p) => p.group === "all" || p.group === productType
  );

  const handleSaveChanges = async () => {
    if (selectedProperty?.type === "description") {
      updateDescriptions(description, { onSettled: onClose });
    } else if (selectedProperty?.type === "custom") {
      updateProperties(
        [{ property: selectedProperty?.property.name, value: propertyValue }],
        { onSettled: onClose }
      );
    }
  };

  // select property and reset values
  const handleSelectProperty = (selected: ProductProperty) => () => {
    setSelectedProperty(selected);
    setPropertyValue(null);
    setDescription("");
  };

  const selectedPropertyLabel =
    selectedProperty?.type === "description"
      ? tr.translate("generic.label.description")
      : selectedProperty?.type === "custom"
      ? selectedProperty.property.label
      : // placeholder
        tr.translate("passport.dialogs.edit-property.name");

  return (
    <Dialog
      isOpen
      className={styles.dialog}
      title={tr.translateAsString("product.dialog.set-properties.title")}
      icon="edit"
      onClose={onClose}
      onSecondaryClick={onClose}
      onPrimaryClick={handleSaveChanges}
      disablePrimary={!selectedProperty}
      submitting={isUpdatingDescriptions || isUpdatingProperties}
    >
      <p>
        {tr.translate("product.dialog.set-properties.subtitle", {
          type: productType,
        })}
      </p>
      <Field
        label={
          <span className={styles.label}>
            {tr.translate("passport.dialogs.edit-property.subtitle")}
          </span>
        }
      >
        <Popover2
          fill
          content={
            <Menu>
              <MenuItem
                icon="align-left"
                intent="primary"
                text={tr.translate("generic.label.description")}
                active={selectedProperty?.type === "description"}
                onClick={handleSelectProperty({
                  type: "description",
                })}
              />
              {filteredProperties.map((property) => (
                <MenuItem
                  key={property.id}
                  text={property.label}
                  intent="primary"
                  icon={getPropertyTypeIcon(property.type)}
                  onClick={handleSelectProperty({
                    type: "custom",
                    property,
                  })}
                  active={
                    selectedProperty?.type === "custom" &&
                    selectedProperty.property.id === property.id
                  }
                />
              ))}
            </Menu>
          }
        >
          <DropdownButton fill intent="primary" text={selectedPropertyLabel} />
        </Popover2>
      </Field>

      {selectedProperty?.type === "description" && (
        <Field label={selectedPropertyLabel}>
          <Input
            fill
            intent="primary"
            value={description}
            placeholder={tr.translateAsString("generic.label.description")}
            onChange={setDescription}
          />
        </Field>
      )}

      {selectedProperty?.type === "custom" && (
        <Field label={selectedPropertyLabel}>
          <EditCustomPropertyField
            propertyValue={propertyValue}
            onEditValue={setPropertyValue}
            property={selectedProperty.property}
          />
        </Field>
      )}
    </Dialog>
  );
}
