import { useReducer } from "react";

export default function useReducerAcess() {
  return useReducer(reducer, {}, initFunction);
}

export type ReducerAccessState = {
  groupsAdded: number[];
  groupsRemoved: number[];
  usersAdded: number[];
  usersRemoved: number[];
  isAllPublic: boolean;
  isNonePublic: boolean;
};

export type ReducerAccessAction =
  | {
      type: "addGroup";
      group: number;
    }
  | {
      type: "addGroups";
      groups: number[];
    }
  | {
      type: "removeGroup";
      group: number;
    }
  | {
      type: "addUser";
      user: number;
    }
  | {
      type: "addUsers";
      users: number[];
    }
  | {
      type: "removeUser";
      user: number;
    }
  | {
      type: "addAllPublic";
    }
  | {
      type: "removeAllPublic";
    };

const reducer = (
  state: ReducerAccessState,
  action: ReducerAccessAction
): ReducerAccessState => {
  switch (action.type) {
    case "addGroup":
      state.groupsRemoved = state.groupsRemoved.filter(
        (g) => g !== action.group
      );
      state.groupsAdded = [...state.groupsAdded, action.group];
      return { ...state };
    case "addGroups":
      state.groupsRemoved = state.groupsRemoved.filter(
        (g) => !action.groups.includes(g)
      );
      state.groupsAdded = [...state.groupsAdded, ...action.groups];
      return { ...state };
    case "removeGroup":
      state.groupsRemoved = [...state.groupsRemoved, action.group];
      state.groupsAdded = state.groupsAdded.filter((g) => g !== action.group);
      return { ...state };
    case "addUser":
      state.usersRemoved = state.usersRemoved.filter((u) => u !== action.user);
      state.usersAdded = [...state.usersAdded, action.user];
      return { ...state };
    case "addUsers":
      state.usersRemoved = state.usersRemoved.filter(
        (u) => !action.users.includes(u)
      );
      state.usersAdded = [...state.usersAdded, ...action.users];
      return { ...state };
    case "removeUser":
      state.usersRemoved = [...state.usersRemoved, action.user];
      state.usersAdded = state.usersAdded.filter((g) => g !== action.user);
      return { ...state };
    case "addAllPublic":
      state.isAllPublic = true;
      state.isNonePublic = false;
      return { ...state };
    case "removeAllPublic":
      state.isAllPublic = false;
      state.isNonePublic = true;
      return { ...state };
    default:
      return state;
  }
};

const initFunction = () => ({
  groupsAdded: [],
  groupsRemoved: [],
  usersAdded: [],
  usersRemoved: [],
  isAllPublic: false,
  isNonePublic: false,
});
