import React from "react";
import { Field, Input, TextArea } from "../../../../components";
import { useTranslations } from "../../../../util";
import { NewPartInfo } from "./PartCreationDialog";

export default function PartInfoForm(props: {
  partInfo: NewPartInfo;
  onEditName: (name: string) => void;
  onEditDescription: (desc: string) => void;
  disabled: boolean;
  partNameExists: boolean;
}) {
  const tr = useTranslations();
  const { disabled, partNameExists, partInfo, onEditDescription, onEditName } =
    props;

  return (
    <React.Fragment>
      <Field label={tr.translate("part.dialog.new.name.label")}>
        <Input
          fill
          autoFocus
          disabled={disabled}
          placeholder={tr.translateAsString("part.dialog.new.name.placeholder")}
          value={partInfo.name}
          onChange={onEditName}
          hasError={partNameExists}
          errorHelperText={tr.translateAsString(
            "part.dialog.new.name.duplicate"
          )}
        />
      </Field>
      <Field label={tr.translate("generic.label.description")}>
        <TextArea
          fill
          rows={3}
          disabled={disabled}
          value={partInfo.description}
          placeholder={tr.translateAsString(
            "part.dialog.new.description.placeholder"
          )}
          onChange={onEditDescription}
        />
      </Field>
    </React.Fragment>
  );
}
