import { Reducer, useReducer } from "react";
import { SortDirection, SortState } from "@aletiq/types";

export default function useSortKey<T>(
  initKey: T,
  initDirection: SortDirection = "asc"
) {
  const [sortState, setSortKey] = useReducer<Reducer<SortState<T>, T>>(
    ({ key, direction }, newKey) => {
      if (newKey === key) {
        return { key, direction: direction === "asc" ? "desc" : "asc" };
      }
      return { key: newKey, direction: "asc" };
    },
    { key: initKey, direction: initDirection }
  );

  return { sortState, setSortKey };
}
