import { DialogBase, DialogContent, DialogFooter } from "@aletiq/design-system";
import React, { useState } from "react";
import { Field } from "../../../components";
import { useTranslations } from "../../../util";
import useProjectBills from "../../project/Operations/hooks/useProjectBills";
import OperationBillSelect from "../../project/Operations/OperationBillSelect";
import useSetPassportsOperationBillIndex from "../hooks/useSetPassportsOperationBillIndex";

export default function UpdatePassportsBillIndexDialog(props: {
  onClose: () => void;
  passportIds: number[];
  articleId: number;
}) {
  const { passportIds, articleId, onClose } = props;
  const tr = useTranslations();
  const { data: bills = [] } = useProjectBills(articleId);
  const [value, setValue] = useState<number | null>(null);
  const activeBill =
    value !== null ? bills.find((b) => b.id === value) : undefined;

  const { mutate: setPassportsBillIndex, isLoading } =
    useSetPassportsOperationBillIndex(passportIds);

  const handleSubmit = () =>
    setPassportsBillIndex(value, { onSettled: () => onClose() });

  const isBillsEmpty = bills.length === 0;

  return (
    <DialogBase isOpen isDense onClose={onClose}>
      <DialogContent>
        <Field
          label={tr.translateAsString("project.operation-bill.bill-index")}
        >
          <OperationBillSelect
            disabled={isBillsEmpty}
            activeBill={activeBill}
            bills={bills}
            onSelect={setValue}
          />
        </Field>
        {isBillsEmpty
          ? tr.translate("passport.update-bill-index.empty-state")
          : null}
      </DialogContent>
      <DialogFooter
        isDense
        primaryButtonProps={{ onClick: handleSubmit, isLoading }}
        secondaryButtonProps={{ onClick: onClose }}
      />
    </DialogBase>
  );
}
