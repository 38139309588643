import { Process } from "@aletiq/types";
import { Tab, TabId } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  ANALYTICS_VISITED_PRODUCT_WORKFLOWS,
  useTrackEvent,
} from "../../analytics";
import { PageTitle, Tabs } from "../../components";
import { useTranslations } from "../../util";
import { ProjectPage } from "../project/common";
import ActiveWorkflows from "../tasks/ActiveWorkflows";
import ArchivedWorkflows from "../tasks/ArchivedWorkflows";
import { useProcesses } from "../tasks/hooks";

export default function ProjectWorkflows(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_WORKFLOWS);

  const [tab, setTab] = useState<"active" | "archived">("active");

  const { data: workflows = [] } = useProcesses({
    relation: { type: "project", id: projectId },
  });

  const sortedWorkflows = workflows.reduce(
    (acc: { active: number[]; archived: number[] }, workflow: Process) => {
      if (workflow.isArchived) {
        return { ...acc, archived: [...acc.archived, workflow.id] };
      }
      return { ...acc, active: [...acc.active, workflow.id] };
    },
    { active: [], archived: [] }
  );

  const handleSetTab = (newTab: TabId) => {
    if (newTab === "active" || newTab === "archived") setTab(newTab);
  };

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.workflow")}
    >
      <PageTitle icon="flow-linear">
        {tr.translate("sidebar.processes")}
      </PageTitle>
      <Tabs
        selectedTabId={tab}
        onChange={handleSetTab}
        renderActiveTabPanelOnly
      >
        <Tab
          id="active"
          panel={<ActiveWorkflows projectId={projectId} />}
          title={tr.translate("project.workflows.tab.active", {
            count: sortedWorkflows.active.length,
          })}
        />
        <Tab
          id="archived"
          panel={<ArchivedWorkflows projectId={projectId} />}
          title={tr.translate("project.workflows.tab.archived", {
            count: sortedWorkflows.archived.length,
          })}
        />
      </Tabs>
    </ProjectPage>
  );
}
