import {
  AnchorButton,
  ButtonGroup,
  Divider,
  Tooltip,
} from "@aletiq/design-system";
import { Classes, Text } from "@blueprintjs/core";
import classnames from "classnames";
import React from "react";
import PanelBackButton from "../panel/PanelBackButton";
import PanelCloseButton from "../panel/PanelCloseButton";
import styles from "./PanelDetails.module.scss";

export default function PanelDetailsHeader(props: {
  title: React.ReactNode;
  additionalButtons: React.ReactNode;
  navigateTooltip: string;
  canNavigateToList: boolean;
  navigateToList: () => void;
}) {
  const {
    title,
    additionalButtons,
    navigateTooltip,
    canNavigateToList,
    navigateToList,
  } = props;

  return (
    <div className={classnames(Classes.DRAWER_HEADER, styles.header)}>
      <div className={styles.flex}>
        <Text tagName="h3" ellipsize className={styles.title}>
          {title}
        </Text>
        <div>
          <ButtonGroup isDense>
            {additionalButtons}
            <Divider isVertical intent="default" />
            <PanelBackButton />
            <Tooltip position="top-left" content={navigateTooltip}>
              <AnchorButton
                isDense
                icon="share"
                isDisabled={!canNavigateToList}
                onClick={navigateToList}
              />
            </Tooltip>
            <PanelCloseButton />
          </ButtonGroup>
        </div>
      </div>
      {/* <Divider className={styles.bottom_divider} /> */}
    </div>
  );
}
