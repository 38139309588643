import React, { Fragment } from "react";
import { Icon, UserName } from "../../../components";

export default function PartLockOwner(props: {
  lockOwner: number | null | undefined;
}) {
  return (
    <Fragment>
      {props.lockOwner && (
        <Fragment>
          <Icon inline icon="lock" />
          <UserName userId={props.lockOwner} />
        </Fragment>
      )}
    </Fragment>
  );
}
