import {
  ActiveDocument,
  ActiveDocumentRevision,
  DocumentQueryFilterParams,
  ProjectVersionActionSpec,
} from "@aletiq/types";
import React, { useState } from "react";
import {
  ActionBar,
  AlertPopover,
  AlignRight,
  Button,
  ButtonGroup,
  Callout,
  DefaultFilters,
  EditableField,
  EditableText,
  Field,
  PageTitle,
} from "../../../../components";
import { useFilter } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import {
  useCreateOrUpdateVersion,
  useDeleteVersion,
  useFetchVersionDetails,
  useVersionDocsFilter,
} from "../../hooks";
import styles from "./NewVersion.module.scss";
import NewVersionDocuments from "./NewVersionDocuments";

export default function NewVersion(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();

  const { name, description, versions, versionDraft, documentsData } =
    useFetchVersionDetails(projectId);

  const [editedName, setEditedName] = useState(name);
  const [isMissingName, setIsMissingName] = useState(false);
  const [isAddingDocuments, setIsAddingDocuments] = useState(false);
  const { filter, handleFilter } = useFilter<DocumentQueryFilterParams>({
    search: "",
  });

  const {
    mutate: persist,
    submit,
    isLoading,
  } = useCreateOrUpdateVersion(versionDraft, projectId);
  const { filteredDocs, allVersionDocs } = useVersionDocsFilter(
    documentsData,
    filter
  );
  const { mutate: deleteVersion } = useDeleteVersion(projectId);

  const previousVersionNames = versions
    .filter((v) => !v.isDraft)
    .map((v) => v.version.toLocaleLowerCase());

  const isRevisionNameUsed = previousVersionNames.some(
    (versionName) => versionName === editedName.toLocaleLowerCase()
  );

  const handleChangeName = async (name: string) => {
    if (isRevisionNameUsed) return;
    persist({ name, comment: description, isDraft: true, actions: [] });
    setIsMissingName(name === "");
  };

  const handleChangeDescription = (description: string) => {
    persist({ name, comment: description, isDraft: true, actions: [] });
  };

  const addChange = (change: ProjectVersionActionSpec) =>
    persist({ name, comment: description, isDraft: true, actions: [change] });

  const addChanges = (newChanges: ProjectVersionActionSpec[]) =>
    persist({
      name,
      comment: description,
      isDraft: true,
      actions: newChanges,
    });

  const handleDeleteDocument = (document: number) =>
    addChange({ type: "deactivateDocument", value: { document } });

  const handleEdit = (
    document: number,
    revision: ActiveDocumentRevision,
    comment: string
  ) =>
    addChanges([
      {
        type: "updateDocument",
        value: { document, revision },
      },
      {
        type: "updateDocumentDescription",
        value: { document, description: comment },
      },
    ]);

  const handleAddNodes = (nodes: ActiveDocument[]) =>
    addChanges(
      nodes.map((activeDocument) => ({
        type: "activateDocument",
        value: {
          document: activeDocument.document.id,
          description: activeDocument.description,
          revision: activeDocument.revision,
        },
      }))
    );

  const handleSubmit = () => {
    // Check name
    if (
      name.length === 0 ||
      versions
        .slice(1)
        .map((v) => v.version)
        .includes(name)
    ) {
      setIsMissingName(true);
      return;
    }

    submit({
      name,
      description,
      allVersionDocs,
    });
  };

  const handleCreateButton = () => {
    setIsAddingDocuments(true);
  };

  const handleDelete = () => {
    if (versionDraft) {
      deleteVersion(versionDraft.id);
    }
  };

  let lastVersionName = "";
  const savedVersions = versions.filter((v) => !v.isDraft);
  if (savedVersions.length > 0) {
    lastVersionName = savedVersions[0].version;
  }

  return (
    <>
      <PageTitle>{tr.translate("version.new.title")}</PageTitle>
      <div className={styles.formContainer}>
        <div className={styles.formFields}>
          <Field label={tr.translateAsString("version.new.version-number")}>
            <EditableText
              intent={
                isMissingName || isRevisionNameUsed ? "danger" : "default"
              }
              placeholder={tr.translateAsString(
                "version.new.version-placeholder",
                {
                  name: lastVersionName,
                  type: lastVersionName ? "new" : "first",
                }
              )}
              value={editedName}
              onChange={setEditedName}
              onConfirm={handleChangeName}
            />
            {isRevisionNameUsed && (
              <span className={styles.name_used}>
                <em>{tr.translate("version.new.version-number.in-use")}</em>
              </span>
            )}
          </Field>
          <EditableField
            multiline
            name={tr.translateAsString("version.new.description")}
            placeholder={tr.translateAsString(
              "version.new.description-placeholder"
            )}
            value={description}
            onSubmit={handleChangeDescription}
          />
        </div>
        <div>
          <ButtonGroup isDense>
            <AlertPopover
              isDense
              icon="tick"
              disablePrimary={!name}
              onPrimaryClick={handleSubmit}
              title={tr.translateAsString("version.new.popup.confirm-creation")}
              content={
                <>
                  <p className={styles.popupWarning}>
                    {name &&
                      tr.translate("version.new.popup.description", { name })}
                    {!name && (
                      <Callout isDense icon="warning-sign" intent="warning">
                        {tr.translate("version.new.popup.warning.missing-name")}
                      </Callout>
                    )}
                  </p>
                </>
              }
            >
              <Button
                isDense
                isLoading={isLoading}
                icon="floppy-disk"
                intent="secondary"
                isDisabled={!name}
              >
                {tr.translate("version.new.popup.warning.save-new-version")}
              </Button>
            </AlertPopover>
            <AlertPopover
              isDense
              icon="trash"
              intent="danger"
              title={tr.translateAsString("version.new.popup.supress-draft")}
              onPrimaryClick={handleDelete}
            >
              <Button isDense icon="trash" />
            </AlertPopover>
          </ButtonGroup>
        </div>
      </div>
      <ActionBar>
        <DefaultFilters
          objectType="document"
          filters={filter}
          handleFilter={handleFilter}
        />
        <AlignRight />
        <Button
          isDense
          intent="secondary"
          icon="new-link"
          onClick={handleCreateButton}
        >
          {tr.translate("version.new.button.link-documents")}
        </Button>
      </ActionBar>
      <NewVersionDocuments
        projectId={props.projectId}
        documents={filteredDocs}
        onEdit={handleEdit}
        onAddNodes={handleAddNodes}
        onDelete={handleDeleteDocument}
        searchValue={filter.search}
        isAddingDocuments={isAddingDocuments}
        setIsAddingDocuments={setIsAddingDocuments}
      />
    </>
  );
}
