import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";

export default function SwDrawingIcon(
  props: { size?: string | number } & React.HTMLProps<any>
) {
  const { size = 20 } = props;
  return (
    <span {...props} className={classNames(Classes.ICON, props.className)}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        strokeWidth="0.4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.25"
          y="0.25"
          width="15.5"
          height="15.5"
          stroke="#3C464B"
          stroke-width="0.5"
        />
        <rect
          x="2.25"
          y="2.25"
          width="6.5"
          height="3.5"
          fill="#FFD42F"
          stroke="#3C464B"
          stroke-width="0.5"
        />
        <rect
          x="10.25"
          y="2.25"
          width="3.5"
          height="3.5"
          fill="#FFD42F"
          stroke="#3C464B"
          stroke-width="0.5"
        />
        <mask id="path-4-inside-1" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 7H6V10H3V14H13V10H10V7Z"
          />
        </mask>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 7H6V10H3V14H13V10H10V7Z"
          fill="#FFD42F"
        />
        <path
          d="M6 7V6.5H5.5V7H6ZM10 7H10.5V6.5H10V7ZM6 10V10.5H6.5V10H6ZM3 10V9.5H2.5V10H3ZM3 14H2.5V14.5H3V14ZM13 14V14.5H13.5V14H13ZM13 10H13.5V9.5H13V10ZM10 10H9.5V10.5H10V10ZM6 7.5H10V6.5H6V7.5ZM6.5 10V7H5.5V10H6.5ZM3 10.5H6V9.5H3V10.5ZM3.5 14V10H2.5V14H3.5ZM13 13.5H3V14.5H13V13.5ZM12.5 10V14H13.5V10H12.5ZM10 10.5H13V9.5H10V10.5ZM9.5 7V10H10.5V7H9.5Z"
          fill="#3C464B"
          mask="url(#path-4-inside-1)"
        />
      </svg>
    </span>
  );
}
