import { Passport } from "@aletiq/types";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import useApi from "../../../app/useApi";
import { passportKeys } from "./queries";

export default function usePassport<T = Passport>(
  passportId: number | null,
  queryOptions?: UseQueryOptions<Passport, unknown, T>
) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: passportKeys.byId(passportId!),
    queryFn: () => api.passport.getPassport(passportId!),
    enabled: passportId !== null,
    initialData: () => {
      const passports: { list: Passport[]; count: number } | undefined =
        queryClient.getQueryData("passports");
      return passports?.list?.find((p) => p.id === passportId);
    },
    ...queryOptions,
  });
}
