import React, { useState } from "react";
import { ActionBar, AlignRight, SearchInput } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useAddProjectDefinitionDocument } from "../../hooks";
import AddDocumentsForm from "../../Operations/AddDocumentsForm";
import ProjectDefinitionDocumentAddButton from "./ProjectDefinitionDocumentAddButton";

export default function ProjectDefinitionDocumentsActionBar(props: {
  projectId: number;
  definitionIdx: number;
  search?: string;
  hasEdition?: boolean;
  onSearchChange?: (search: string) => void;
}) {
  const { search, onSearchChange, projectId, definitionIdx, hasEdition } =
    props;

  const tr = useTranslations();

  const [dialog, setDialog] = useState(false);

  const handleOpenAddDocumentDialog = () => {
    setDialog(true);
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const { mutate: addDocuments, isLoading } = useAddProjectDefinitionDocument(
    projectId,
    definitionIdx
  );

  const handleSubmitAddDocuments = (documents: number[]) => {
    documents.forEach((document) => {
      addDocuments({ document }, { onSuccess: handleCloseDialog });
    });
  };

  return (
    <ActionBar>
      {search !== undefined && onSearchChange !== undefined && (
        <SearchInput
          isDense
          intent="primary"
          value={search}
          onChange={onSearchChange}
        />
      )}
      <AlignRight />
      {hasEdition && (
        <ProjectDefinitionDocumentAddButton
          onClick={handleOpenAddDocumentDialog}
        />
      )}
      {dialog && (
        <AddDocumentsForm
          projectId={projectId}
          onClose={handleCloseDialog}
          title={tr.translateAsString(
            "project.definition.docs.add.dialog.title"
          )}
          caption={tr.translateAsString(
            "project.definition.docs.add.dialog.caption"
          )}
          onSubmit={handleSubmitAddDocuments}
          isSubmitting={isLoading}
        />
      )}
    </ActionBar>
  );
}
