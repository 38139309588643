import { EntityId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { commentKeys } from "./queries";

export default function useUpdateComment(entityId: EntityId) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (values: { commentId: number; content: string; mentions: string[] }) =>
      api.comment.updateComment(
        values.commentId,
        values.content,
        values.mentions
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(commentKeys.byEntity(entityId)),
    }
  );
}
