import { Button } from "@aletiq/design-system";
import { FormattedMessage } from "@aletiq/translation";
import React, { ErrorInfo } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Page, PageContents } from "../../components";
import { formatMail } from "../../util";
import styles from "./ErrorBoundary.module.scss";

class ErrorBoundary extends React.Component<
  RouteComponentProps,
  {
    error?: Error;
    errorInfo?: ErrorInfo;
  }
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      error: undefined,
      errorInfo: undefined,
    };
  }

  // Watch on all the children if an error is triggered
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  formatMail = () => {
    const { error, errorInfo } = this.state;
    const breakLine = "%0D%0A";
    let mailBody = `${breakLine}${breakLine}${breakLine}Details of the bugs${breakLine}`;
    mailBody += error && error.toString();
    const regexBr = new RegExp("\\n");
    mailBody += errorInfo?.componentStack.replace(regexBr, breakLine);
    return formatMail("support@aletiq.com", "[BUG]-", mailBody);
  };

  goBack = () => {
    this.setState({
      error: undefined,
      errorInfo: undefined,
    });
    this.props.history.push("/home");
  };

  render() {
    const { errorInfo } = this.state;

    if (errorInfo) {
      // Error path
      return (
        <Page>
          <PageContents>
            <div className={styles.page}>
              <div className={styles.title}>
                <FormattedMessage id="error.page.title" />
              </div>
              <div className={styles.subtitle}>
                <b>
                  <FormattedMessage id="error.page.subtitle" />
                </b>
              </div>
              <div>
                <FormattedMessage id="error.page.description" />
              </div>
              <div className={styles.buttonsWrapper}>
                <a href={this.formatMail()}>
                  <Button intent="primary" icon="envelope">
                    <FormattedMessage id="error.page.send-report" />
                  </Button>
                </a>
                <Button
                  onClick={this.goBack}
                  className={styles.spacedButton}
                  intent="secondary"
                  icon="undo"
                >
                  <FormattedMessage id="generic.action.go-back" />
                </Button>
              </div>
            </div>
          </PageContents>
        </Page>
      );
    }

    // Classic use juste send the children
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
