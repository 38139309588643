import { Project } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React from "react";
import {
  AlertPopover,
  AlignRight,
  AnchorButton,
  Icon,
  PageLink,
  ProjectNameLink,
  RemoveLinkIcon,
  Tooltip,
} from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import ArticleBillIndexLink from "../../../project/Version/ArticleBillIndexLink";
import { useBillsUsedIn, useProductVersionsUsedIn } from "../../hooks";
import styles from "./LinkedProducts.module.scss";

export default function ProductListItem(props: {
  documentId: number;
  product: Project;
  onDelete: () => void;
  canEdit?: boolean;
}) {
  const { canEdit, documentId, onDelete, product } = props;
  const tr = useTranslations();

  const versionsUsedIn = useProductVersionsUsedIn(documentId, product.id);
  const billsUsedIn = useBillsUsedIn(documentId, product.id);
  const canUpdateProductVersions = useHasPermission("update:project-versions");

  return (
    <div className={styles.project_list_item}>
      <div className={styles.header}>
        <Text ellipsize>
          <ProjectNameLink projectId={product.id} hasIcon />
        </Text>
        <AlignRight />
        <div>
          <AlertPopover
            disabled={!canEdit}
            icon={<RemoveLinkIcon />}
            title={tr.translateAsString(
              "document.details.products.unlink.title"
            )}
            content={tr.translateAsString("document.details.products.unlink")}
            onPrimaryClick={onDelete}
            isDense
          >
            <Tooltip content={tr.translate("document.details.products.unlink")}>
              <AnchorButton
                isDense
                isDisabled={!canEdit}
                icon={<RemoveLinkIcon />}
              />
            </Tooltip>
          </AlertPopover>
        </div>
      </div>

      {product.isTool && (
        <div className={styles.versions}>
          <span className={styles.label}>
            {tr.translate("document.details.products.versions")}
          </span>
          <div className={styles.version_list}>
            {versionsUsedIn.map((version, index) => (
              <div className={styles.version} key={version.id}>
                <PageLink
                  to={{
                    path: `/project/${product.id}/versions/${
                      version.isDraft && canUpdateProductVersions
                        ? "new"
                        : version.id
                    }`,
                  }}
                >
                  <Icon intent="primary" inline icon="git-commit" />
                  {version.name}
                </PageLink>
                {`(${version.docRevisionName}) ${
                  index < versionsUsedIn.length - 1 ? ", " : ""
                }`}
              </div>
            ))}
          </div>
        </div>
      )}

      {product.isArticle && (
        <div className={styles.versions}>
          <span className={styles.label}>
            {tr.translate("document.details.products.operation-bills")}
          </span>
          <div className={styles.version_list}>
            {billsUsedIn.map((bill, index) => (
              <div className={styles.bill} key={bill.id}>
                <ArticleBillIndexLink
                  hasIcon
                  product={product.id}
                  billId={bill.id}
                />
                {`(${bill.revisionNames.join(", ")}) ${
                  index < billsUsedIn.length - 1 ? ", " : ""
                }`}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
