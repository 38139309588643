import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { passportKeys } from "./queries";

export default function usePassportUsedIn(passportId: number | null) {
  const api = useApi();
  return useQuery(
    passportKeys.componentsUsedIn(passportId!),
    () => api.passport.listPassportUsedIn(passportId!),
    { enabled: passportId !== null }
  );
}
