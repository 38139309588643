export const userKeys = {
  all: ["users"] as const,
  current: () => [...userKeys.all, "current"] as const,
  list: (withEmail: boolean, withDeactivated: boolean) =>
    [...userKeys.all, { withDeactivated, withEmail }] as const,

  groups: ["user-groups"] as const,
  groupAllUsers: () => [...userKeys.groups, "users"] as const,
};

export const integrationKeys = {
  all: ["integrations"] as const,

  allReports: () => [...integrationKeys.all, "reports"] as const,
};

export const exportKeys = {
  all: ["exports"] as const,

  schedule: () => [...exportKeys.all, "schedule"] as const,
};
