import { OperationBill } from "@aletiq/types";
import React from "react";
import { DropdownMenu, GenericStateTag, MenuItem } from "../../../components";
import MenuDivider from "../../../components/nav-bar/MenuDivider";
import { useTranslations } from "../../../util";
import operationBillNotification from "./operationBillNotification";
import styles from "./OperationBillSelect.module.scss";

export default function OperationBillSelect(props: {
  isDense?: boolean;
  disabled?: boolean;
  activeBill: OperationBill | undefined;
  bills: OperationBill[];
  onSelect: (bill: number) => void;
}) {
  const {
    isDense = true,
    disabled = false,
    activeBill,
    bills,
    onSelect,
  } = props;
  const tr = useTranslations();

  const handleSelect = (bill: number) => () => onSelect(bill);

  const activeBillHasObsoleteDocs = activeBill?.operations
    .flatMap((op) => op.documents)
    .some((doc) => doc.revision.revision?.state === "obsolete");

  const notification = operationBillNotification({
    hasObsoleteDocs: activeBillHasObsoleteDocs,
  });

  return (
    <DropdownMenu
      isDense={isDense}
      isDisabled={disabled}
      placeholder={tr.translateAsString("generic.label.none")}
      activeLabel={
        activeBill && (
          <GenericStateTag
            name={activeBill.index}
            state={activeBill.state}
            notification={notification}
          />
        )
      }
    >
      {bills.map((bill, index) => {
        const hasObsoleteDocs = bill.operations
          .flatMap((op) => op.documents)
          .some((doc) => doc.revision.revision?.state === "obsolete");

        const notification = operationBillNotification({ hasObsoleteDocs });
        const isDifferentBranchFromPrevious =
          index > 0 && bills[index - 1]?.branch !== bill.branch;

        return (
          <React.Fragment key={bill.id}>
            {isDifferentBranchFromPrevious && <MenuDivider />}
            <MenuItem
              isDense={isDense}
              className={styles.bill_menu_item}
              text={
                <GenericStateTag
                  name={bill.index}
                  state={bill.state}
                  notification={notification}
                />
              }
              onClick={handleSelect(bill.id)}
            />
          </React.Fragment>
        );
      })}
    </DropdownMenu>
  );
}
