import { ToolingRequirement } from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_VISITED_PRODUCT_ARTICLES,
  useTrackEvent,
} from "../../../analytics";
import {
  ActionBar,
  AlignRight,
  Button,
  PageTitle,
  SearchInput,
  Table,
  Tooltip,
} from "../../../components";
import {
  useDelayed,
  useHasPermission,
  useSort,
  useToolingArticles,
} from "../../../hooks";
import { isInString, useTranslations } from "../../../util";
import { ProjectPage, ToolingBatchActionHeader } from "../common";
import { AddArticleDialog } from "../dialogs";
import useProjectRecord from "../hooks/useProjectsRecord";
import styles from "./ProjectArticles.module.scss";
import useArticleColumns from "./useArticleColumns";

export type ResolvedToolingRequirement = ToolingRequirement & {
  name: string;
};

export default function ProductArticles(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_ARTICLES);

  const [addArticleDialog, setAddArticleDialog] = useState(false);
  const [search, setSearch] = useState("");

  const canEditProject = useHasPermission("update:projects");
  const {
    data: projectArticles = [],
    isLoading: toolingLoading,
    isFetching: toolingFetching,
  } = useToolingArticles(projectId);
  const { data: projectsById, isLoading: articlesLoading } = useProjectRecord(
    projectArticles.map((p) => p.article)
  );

  const isLoading = toolingLoading || articlesLoading;
  const isFetching = useDelayed(toolingFetching || articlesLoading);

  //get article names to sort in alphabetical order
  const resolvedArticle: ResolvedToolingRequirement[] = projectArticles.map(
    (a) => ({
      ...a,
      name: projectsById?.[a.article]?.name ?? "-",
    })
  );

  const {
    sortKey,
    sortDirection: direction,
    onSortKeyChange: onSort,
    sortedList: sortedArticles,
  } = useSort<ResolvedToolingRequirement>(resolvedArticle, "name");

  const filteredArticles = sortedArticles.filter(
    (article) =>
      isInString(article.name, search) || isInString(article.comment, search)
  );

  const handleToggleAddArticleDialog = () => {
    setAddArticleDialog(!addArticleDialog);
  };

  const handleRenderBatchActionsHeader = (
    selectedToolingRequirements: ToolingRequirement[]
  ) => (
    <ToolingBatchActionHeader
      selectedToolingRequirements={selectedToolingRequirements}
    />
  );

  const columns = useArticleColumns(projectId);

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.product", { type: "article" })}
    >
      <PageTitle icon="projects">
        {tr.translate("project.tab.articles")}
      </PageTitle>
      <ActionBar>
        <Tooltip
          content={tr.translateAsString("project.tooling.search.tooltip")}
          position="top-left"
        >
          <SearchInput isDense value={search} onChange={setSearch} />
        </Tooltip>
        <AlignRight />
        <Button
          isDense
          icon="new-link"
          intent="secondary"
          isDisabled={!canEditProject || addArticleDialog}
          onClick={handleToggleAddArticleDialog}
        >
          {tr.translate("product.articles.new")}
        </Button>
      </ActionBar>
      <Table
        columns={columns}
        data={filteredArticles}
        sortOptions={{ sortState: { key: sortKey, direction }, onSort }}
        renderBatchActionsHeader={handleRenderBatchActionsHeader}
        isFetching={isFetching || isLoading}
        noData={
          <div className={styles.no_data}>
            {tr.translate(
              search === ""
                ? "project.list.no-data"
                : "project.list.no-data.filtered",
              { type: "article" }
            )}
            <Button
              isDense
              isDisabled={!canEditProject || addArticleDialog}
              onClick={handleToggleAddArticleDialog}
              icon="new-link"
              intent="primary"
              className={styles.create_button}
            >
              {tr.translate("product.articles.new")}
            </Button>
          </div>
        }
      />

      {addArticleDialog && (
        <AddArticleDialog
          projectId={projectId}
          projectArticles={projectArticles}
          onClose={handleToggleAddArticleDialog}
        />
      )}
    </ProjectPage>
  );
}
