import { HighlightedText, Tag, Tooltip } from "@aletiq/design-system";
import { IconName } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { RevisionState, useTranslations } from "../../../util";
import { Subtitle } from "../../Subtitle";
import styles from "./GenericStateTag.module.scss";

type NotificationColor = "yellow" | "red" | "default";

export type Intent = "danger" | "warning" | "default" | "success";

export type StateNotification = {
  intent: Intent;
  content: string | JSX.Element;
};

export function iconFromState(state: RevisionState): IconName {
  switch (state) {
    case "draft":
      return "edit";
    case "approved":
      return "tick";
    case "obsolete":
      return "disable";
  }
}

export function intentFromState(state: RevisionState): Intent {
  switch (state) {
    case "draft":
      return "default";
    case "approved":
      return "success";
    case "obsolete":
      return "danger";
  }
}

function colorFromIntent(intent: Intent): NotificationColor {
  switch (intent) {
    case "danger":
      return "red";
    case "warning":
      return "yellow";
    default:
      return "default";
  }
}

export type GenericStateTagProps = {
  state: RevisionState;
  name?: string;
  description?: string;
  notification?: StateNotification;
  isLarge?: boolean;
  searchString?: string;
  onClick?: () => void;
  hasInteraction?: boolean;
};

export default function GenericStateTag(props: GenericStateTagProps) {
  const {
    name,
    description,
    state,
    notification,
    isLarge,
    searchString,
    onClick,
    hasInteraction,
  } = props;
  const tr = useTranslations();

  const hasClick = onClick !== undefined || hasInteraction;

  return (
    <Tooltip
      position="right"
      content={notification?.content ?? ""}
      isDisabled={!notification}
    >
      <>
        <div className={styles.container}>
          {name && (
            <HighlightedText
              text={name}
              highlight={searchString}
              className={styles.name}
            />
          )}
          <Tag
            unclickable={!hasClick}
            interactive={hasClick}
            onClick={onClick}
            isDense
            intent={intentFromState(state)}
            className={styles.tag}
            large={isLarge}
            rightIcon={hasClick ? "caret-down" : null}
          >
            {tr.translate("generic.label.state.value", { state })}
            {notification && (
              <div
                className={classNames(
                  styles.notification,
                  styles[colorFromIntent(notification.intent ?? "default")]
                )}
              />
            )}
          </Tag>
        </div>
        {description && (
          <div className={styles.description}>
            <Subtitle text={description} />
          </div>
        )}
      </>
    </Tooltip>
  );
}
