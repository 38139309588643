import React, { Fragment } from "react";
import usePartIteration from "../hooks/usePartIteration";

export default function PartIterationName(props: {
  partId: number;
  iteration: number;
}) {
  const iteration = usePartIteration(props.partId, props.iteration);
  const iterationName = iteration?.name ?? "";
  return <Fragment>{iterationName}</Fragment>;
}
