import { CheckBox, Dialog } from "@aletiq/design-system";
import React, { useEffect, useState } from "react";
import { Tile } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useLockParts, usePart, useWaitForPartDownload } from "../../hooks";
import styles from "./LockPartDialog.module.scss";
import PickComponentTree from "./PickComponentTree";

export default function LockPartDialog(props: {
  partId: number;
  onClose: () => void;
}) {
  const { partId, onClose } = props;
  const tr = useTranslations();

  const downloadPart = useWaitForPartDownload(partId);

  const { data: part } = usePart(partId);
  const { mutate: lockParts, isLoading: submitting } = useLockParts();

  const [downloadNow, setDownloadNow] = useState(false);
  const [selected, setSelected] = useState<number[]>([partId]);

  const toggleSelected = (id: number) =>
    setSelected((selected) => {
      if (selected.includes(id)) {
        return selected.filter((value) => value !== id);
      }
      return [id, ...selected];
    });

  const handleConfirm = () => {
    lockParts(selected, {
      onSuccess: async () => {
        if (downloadNow) {
          downloadPart();
        }
        onClose();
      },
    });
  };

  const fileName = part?.lastIteration?.file?.name ?? "";
  useEffect(() => {
    if (fileName) {
      setDownloadNow(true);
    }
  }, [fileName]);

  const toggleDownloadNow = () => setDownloadNow((value) => !value);

  return (
    <Dialog
      isOpen
      icon="lock"
      onClose={onClose}
      onSecondaryClick={onClose}
      onPrimaryClick={handleConfirm}
      title={tr.translateAsString("part.dialog.lock.title")}
      submitting={submitting}
      className={styles.dialog}
    >
      <div>{tr.translate("part.dialog.lock.msg")}</div>
      <div className={styles.header}>
        {tr.translate("part.dialog.lock.select.edit.part")}
      </div>
      <Tile>
        <PickComponentTree
          part={partId}
          selected={selected}
          toggleSelected={toggleSelected}
        />
      </Tile>
      {fileName !== "" && (
        <CheckBox
          checked={downloadNow}
          onChange={toggleDownloadNow}
          labelElement={tr.translate("part.dialog.lock.download.file")}
          fill
        />
      )}
    </Dialog>
  );
}
