import React from "react";
import { AlignRight, ButtonSP, Tooltip } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { PartAction } from "../types";
import PartActionSelect from "./PartActionSelect";
import styles from "./UploadedPartsList.module.scss";

export default function PartTableHeader(props: {
  onBatchUpdateAction: (action: PartAction) => void;
  isScrollbarVisible: boolean;
  scrollbarSize: number;
}) {
  const { onBatchUpdateAction, isScrollbarVisible, scrollbarSize } = props;

  const tr = useTranslations();

  return (
    <thead>
      <tr>
        <th className={styles.tag_col}>
          {tr.translate("parts.dialog.drop-standard-parts.header.tag")}
        </th>
        <th>
          {tr.translate("parts.dialog.drop-standard-parts.header.part-name")}
        </th>
        <th className={styles.action_col}>
          <div className={styles.batch_action}>
            {tr.translate("parts.dialog.drop-standard-parts.header.action")}
            <AlignRight />
            <PartActionSelect onChangePartAction={onBatchUpdateAction}>
              <Tooltip
                position="top"
                content={tr.translate("part.dialog.drop-parts.apply-all-parts")}
              >
                <ButtonSP isDense color="primary" view="flat" icon="edit" />
              </Tooltip>
            </PartActionSelect>
          </div>
        </th>
        {isScrollbarVisible && (
          <th style={{ width: `${scrollbarSize}px`, borderLeft: "none" }} />
        )}
      </tr>
    </thead>
  );
}
