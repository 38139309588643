import { ProgressBar } from "@blueprintjs/core";
import React, { useState } from "react";
import { useMutation } from "react-query";
import useApi from "../../../app/useApi";
import { useDownloadQueue, useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";

export default function useExportAStep(partId: number) {
  const tr = useTranslations();
  const api = useApi();
  const toaster = useToaster();
  const { handleDownload } = useDownloadQueue();

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  const handleExportDownload = async (
    jobResultId: number,
    iterationId: number
  ) => {
    await handleDownload({
      fetchToken: () => api.getJobResultFileDownloadToken(jobResultId),
      forceDownload: true,
    });
    toaster.show(
      {
        intent: "success",
        icon: "tick",
        message: tr.translate("toaster.part.export.step.success"),
      },
      `step-export-${partId}-${iterationId}`
    );
  };

  const showErrorToaster = (iterationId: number) => {
    toaster.show(
      {
        intent: "danger",
        icon: "warning-sign",
        message: tr.translate("toaster.part.export.step.failure"),
      },
      `step-export-${partId}-${iterationId}`
    );
  };

  return useMutation(
    (iterationId: number) => {
      toaster.show(
        {
          intent: "primary",
          icon: "info-sign",
          timeout: 0,
          onDismiss: () => intervalId && clearInterval(intervalId),
          message: (
            <>
              <div>{tr.translate("toaster.part.export.step.in-progress")}</div>
              <ProgressBar intent="primary" animate />
            </>
          ),
        },
        `step-export-${partId}-${iterationId}`
      );
      return api.pdm.startPartIterationStepExport(
        partId,
        iterationId,
        "monolithic"
      );
    },
    {
      onSuccess: async (jobResultId, iterationId) => {
        const interval = setInterval(() => {
          api.getJobResult(jobResultId).then((jobResult) => {
            if (jobResult.status === "success") {
              clearInterval(interval);
              handleExportDownload(jobResultId, iterationId);
            } else if (jobResult.status === "failure") {
              clearInterval(interval);
              showErrorToaster(iterationId);
            }
          });
        }, 1000);
        setIntervalId(interval);
      },
      onError: showErrorToaster,
    }
  );
}
