import { DateRangePicker } from "@aletiq/design-system";
import { DateRange, DateRangeShortcut } from "@blueprintjs/datetime";
import React from "react";
import {
  dateLocalizationHelper,
  formatDate,
  useTranslations,
} from "../../util";
import styles from "./DateFilter.module.scss";
import FilterTemplate from "./FilterTemplate";

export type DateShortcut =
  | "expired"
  | "last-month"
  | "last-week"
  | "today"
  | "next-week"
  | "next-month";

export default function TaskDateFilter(props: {
  selected?: { from?: string; to?: string };
  onSelect: (values?: { from: string | undefined; to: string }) => void;
}) {
  const tr = useTranslations();
  const { selected, onSelect } = props;

  const addDays = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);

    return newDate;
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = addDays(today, -1);

  const handleSelect = (values: DateRange) => {
    const from = values[0];
    let to = values[1];

    if (!to && !from) {
      return onSelect();
    }
    if (to === null && from !== null) {
      to = new Date(from);
    }

    onSelect({
      from: from ? formatDate(from) ?? "" : undefined,
      to: to ? formatDate(to) ?? "" : "",
    });
  };

  const onClear = () => {
    onSelect();
  };

  const shortcuts: DateRangeShortcut[] = [
    {
      label: tr.translateAsString("filter.date.expired"),
      dateRange: [null, yesterday],
      includeTime: true,
    },
    {
      label: tr.translateAsString("filter.date.today"),
      dateRange: [today, today],
      includeTime: true,
    },
    {
      label: tr.translateAsString("filter.date.next-week"),
      dateRange: [today, addDays(today, 7)],
      includeTime: true,
    },
    {
      label: tr.translateAsString("filter.date.next-month"),
      dateRange: [today, addDays(today, 30)],
      includeTime: true,
    },
  ];

  const defaultValue: DateRange | undefined = selected
    ? [
        selected.from ? new Date(selected.from) : null,
        selected.to ? new Date(selected.to) : null,
      ]
    : [null, null];

  return (
    <FilterTemplate
      count={Math.min(1, defaultValue.filter((v) => v !== null).length)}
      onClear={onClear}
      placeholder={tr.translateAsString("filter.date.limit-date")}
      content={
        <DateRangePicker
          allowSingleDayRange
          onChange={handleSelect}
          shortcuts={shortcuts}
          defaultValue={defaultValue}
          dayPickerProps={{
            locale: navigator.language,
            localeUtils: dateLocalizationHelper,
          }}
          className={styles.datepicker_expired_tasks}
        />
      }
    />
  );
}
