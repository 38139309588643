import {
  ButtonSP,
  Card,
  CheckBox,
  Dialog,
  Field,
  Input,
  Radio,
} from "@aletiq/design-system";
import { DocumentRevision } from "@aletiq/types";
import { RadioGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  ANALYTICS_DOCUMENTS_SIGNED,
  ANALYTICS_MUTATION_STATUS,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../../analytics";
import { AlignRight, GenericStateTag } from "../../../../components";
import { useTranslations } from "../../../../util";
import SignatureIcon from "../../DocumentDetails/Revisions/SignatureIcon";
import { useSignDocumentRevision } from "../../hooks";
import styles from "./DocumentSigningDialog.module.scss";

export default function DocumentSigningDialog(props: {
  documentId: number;
  documentName: string;
  revisionId: number;
  onClose: () => void;
  entry: DocumentRevision;
  onShowFile: (revisionId: number) => void;
}) {
  const { documentId, documentName, revisionId, onClose, entry, onShowFile } =
    props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const [checked, setChecked] = useState(false);
  const [role, setRole] = useState("");
  const [otherRole, setOtherRole] = useState("");

  const { mutate: signRevision } = useSignDocumentRevision();

  const signatureRole =
    role === "other"
      ? otherRole
      : role === ""
      ? ""
      : tr.translateAsString(
          "document.details.revisions.sign-document.dialog.role.preset",
          { role: role }
        );

  const handleSubmit = () =>
    signRevision(
      { documentId, revisionId, role: signatureRole },
      {
        onSettled: (_, error) => {
          analytics.track(ANALYTICS_DOCUMENTS_SIGNED, {
            [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
          });
          onClose();
        },
      }
    );

  return (
    <Dialog
      icon={<SignatureIcon size={24} />}
      title={tr.translateAsString(
        "document.details.revisions.sign-document.dialog.title"
      )}
      isOpen={true}
      onClose={onClose}
      primaryText={tr.translateAsString(
        "document.details.revisions.sign-document.dialog.action"
      )}
      onPrimaryClick={handleSubmit}
      className={styles.signature_dialog}
      disablePrimary={!checked}
    >
      <div className={styles.signature_dialog_content}>
        <div>
          {tr.translateAsString(
            "document.details.revisions.sign-document.dialog.hint"
          )}
        </div>
        <Card intent="primary" icon="document" title={documentName}>
          <div className={styles.signature_dialog_card_content}>
            <span className={styles.signature_dialog_card_content_label}>
              {tr.translateAsString("generic.label.revision")}
            </span>
            <GenericStateTag name={entry.revisionName} state={entry.state} />
            <AlignRight />
            <ButtonSP
              isDense
              icon="eye-open"
              view="filled"
              color="primary"
              text={tr.translateAsString(
                "document.details.revisions.sign-document.dialog.visualize"
              )}
              onClick={() => onShowFile(entry.id)}
            />
          </div>
        </Card>
        <Field
          label={tr.translateAsString(
            "document.details.revisions.sign-document.dialog.role.field-label"
          )}
        >
          <RadioGroup onChange={() => {}}>
            <Radio
              label={tr.translateAsString(
                "document.details.revisions.sign-document.dialog.role.preset",
                {
                  role: "editor",
                }
              )}
              value="editor"
              checked={role === "editor"}
              onClick={() => setRole("editor")}
            />
            <Radio
              label={tr.translateAsString(
                "document.details.revisions.sign-document.dialog.role.preset",
                {
                  role: "auditor",
                }
              )}
              value="auditor"
              checked={role === "auditor"}
              onClick={() => setRole("auditor")}
            />
            <Radio
              label={tr.translateAsString(
                "document.details.revisions.sign-document.dialog.role.preset",
                {
                  role: "approver",
                }
              )}
              value="approver"
              checked={role === "approver"}
              onClick={() => setRole("approver")}
            />
            <div className={styles.radio_other}>
              <Radio
                label={tr.translateAsString(
                  "document.details.revisions.sign-document.dialog.role.preset",
                  {
                    role: "other",
                  }
                )}
                value="other"
                checked={role === "other"}
                onClick={() => setRole("other")}
              />
              <Input
                disabled={role !== "other"}
                onChange={setOtherRole}
                value={otherRole}
              ></Input>
            </div>
          </RadioGroup>
        </Field>
        <Field
          label={tr.translateAsString(
            "document.details.revisions.sign-document.dialog.confirm.field-label"
          )}
        >
          <CheckBox
            intent="primary"
            checked={checked}
            onChange={() => setChecked((checked) => !checked)}
            label={tr.translateAsString(
              "document.details.revisions.sign-document.dialog.confirm.checkbox-label"
            )}
          />
        </Field>
      </div>
    </Dialog>
  );
}
