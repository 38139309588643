import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { documentKeys } from "./queries";

export default function useSignDocumentRevision() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (values: {
      documentId: number;
      revisionId: number;
      role: string;
    }) => {
      await api.document.signDocumentRevision(
        values.documentId,
        values.revisionId,
        values.role
      );
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(documentKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
