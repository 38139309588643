import { Tag } from "@aletiq/design-system";
import { ApiActivity, Document, Entity, RevisionState } from "@aletiq/types";
import React, { ReactElement } from "react";
import { GenericStateTag } from "..";
import { useProperty } from "../../features/administration/Attributes/hooks";
import { useTranslations } from "../../util";
import { DateWithSpaces } from "../date";
import { UserInitialsList } from "../user-list";
import styles from "./Activities.module.scss";
import ActivityValue from "./ActivityValue";
import { findDocument } from "./findEntity";
import ActivityDocumentLink from "./links/ActivityDocumentLink";

export default function DocumentActivity(props: {
  activity: ApiActivity;
}): ReactElement | null {
  const tr = useTranslations();
  const { activity } = props;

  const documentLink = (id: number) => {
    const document = findDocument(activity.entities, id);
    return document ? <ActivityDocumentLink document={document} /> : null;
  };

  switch (activity.type) {
    case "document_created":
      return tr.translate("activity.document_created", {
        document: documentLink(activity.metadata.document),
      });

    case "document_deleted":
      return tr.translate("activity.document_deleted", {
        document: documentLink(activity.metadata.document),
      });

    case "document_renamed":
      return tr.translate("activity.document_renamed", {
        document: documentLink(activity.metadata.document),
      });

    case "document_revision_updated":
      return <DocumentRevisionUpdatedActivity activity={activity} />;

    case "document_revision_validated": {
      return (
        <DocumentRevisionActivity
          entities={activity.entities}
          activityDocument={activity.metadata.document}
          activityRevision={activity.metadata.revision}
          type={{
            kind: "validated",
          }}
        />
      );
    }

    case "document_revision_marked_obsolete": {
      return (
        <DocumentRevisionActivity
          entities={activity.entities}
          activityDocument={activity.metadata.document}
          activityRevision={activity.metadata.revision}
          type={{
            kind: "marked_as",
            state: "obsolete",
          }}
        />
      );
    }

    case "document_revision_drafted": {
      return (
        <DocumentRevisionActivity
          entities={activity.entities}
          activityDocument={activity.metadata.document}
          activityRevision={activity.metadata.revision}
          type={{
            kind: "reverted",
            state: "draft",
          }}
        />
      );
    }

    case "document_revision_revalidated": {
      return (
        <DocumentRevisionActivity
          entities={activity.entities}
          activityDocument={activity.metadata.document}
          activityRevision={activity.metadata.revision}
          type={{
            kind: "reverted",
            state: "approved",
          }}
        />
      );
    }

    case "document_property_updated":
      return <DocumentPropertyUpdatedActivity activity={activity} />;

    default:
      return null;
  }
}

function DocumentRevisionUpdatedActivity(props: {
  activity: ApiActivity & { type: "document_revision_updated" };
}) {
  const tr = useTranslations();
  const { activity } = props;
  const { document: documentId, file, name, description } = activity.metadata;

  const document = findDocument(activity.entities, documentId);

  return (
    <>
      {tr.translate("activity.document_revision_updated", {
        document: document ? (
          <ActivityDocumentLink document={document} />
        ) : null,
      })}
      <ul>
        <li>
          {tr.translate("activity.document_revision_updated.name", {
            name: <ActivityValue>{name}</ActivityValue>,
          })}
        </li>
        <li>
          {tr.translate("activity.document_revision_updated.file", {
            file: <ActivityValue>{file}</ActivityValue>,
          })}
        </li>
        {description && (
          <li>
            {tr.translate("activity.document_revision_updated.description", {
              description: <ActivityValue>{description}</ActivityValue>,
            })}
          </li>
        )}
      </ul>
    </>
  );
}

function DocumentRevisionName(props: { document: Document; revision: number }) {
  const { document, revision: revisionId } = props;

  const revision = document.revisions.find((r) => r.id === revisionId);

  return <ActivityValue>{revision?.revisionName}</ActivityValue>;
}

function DocumentRevisionActivity(props: {
  entities: Entity[];
  activityDocument: number;
  activityRevision: number;
  type:
    | { state: RevisionState; kind: "marked_as" }
    | { state: RevisionState; kind: "reverted" }
    | { kind: "validated" };
}) {
  const { entities, activityDocument, activityRevision, type } = props;

  const tr = useTranslations();

  const document = findDocument(entities, activityDocument);

  const documentLinkComponent = document ? (
    <ActivityDocumentLink document={document} />
  ) : null;
  const documentRevisionName = document ? (
    <DocumentRevisionName document={document} revision={activityRevision} />
  ) : null;

  switch (type.kind) {
    case "marked_as":
      return tr.translate("activity.document_revision_marked_as", {
        document: documentLinkComponent,
        revision: documentRevisionName,
        tag: <GenericStateTag state={type.state} />,
      });
    case "reverted":
      return tr.translate("activity.document_revision_reverted", {
        document: documentLinkComponent,
        revision: documentRevisionName,
        tag: <GenericStateTag state={type.state} />,
      });
    case "validated":
      return tr.translate("activity.document_revision_validated", {
        document: documentLinkComponent,
        revision: documentRevisionName,
      });
  }
}

function DocumentPropertyUpdatedActivity(props: {
  activity: ApiActivity & {
    type: "document_property_updated";
  };
}) {
  const tr = useTranslations();
  const { activity } = props;
  const {
    document: documentId,
    property: propertyId,
    value: propertyValue,
  } = activity.metadata;

  const document = findDocument(activity.entities, documentId);

  const { data: property } = useProperty(propertyId, "document");

  const options = property?.options ?? [];

  if (!activity.metadata.value) {
    return tr.translate("activity.entity_property_cleared", {
      entity: document ? <ActivityDocumentLink document={document} /> : null,
      property: property?.label,
    });
  }

  const getValueLabel = (value: string) =>
    options.find((option) => option.name === value)?.label ?? "";

  return (
    <>
      {tr.translate("activity.entity_property_updated", {
        entity: document ? <ActivityDocumentLink document={document} /> : null,
        property: property?.label,
        value:
          propertyValue?.type === "multi-enum" ? (
            propertyValue?.value?.map((val) => (
              <Tag isDense className={styles.tag}>
                {getValueLabel(val)}
              </Tag>
            ))
          ) : propertyValue?.type === "enum" ? (
            <ActivityValue>
              {getValueLabel(propertyValue.value ?? 0)}
            </ActivityValue>
          ) : propertyValue?.type === "user" ? (
            <ActivityValue>
              <UserInitialsList
                users={propertyValue?.value ?? []}
                type="small"
              />
            </ActivityValue>
          ) : propertyValue?.type === "date" ? (
            <ActivityValue>
              <DateWithSpaces date={propertyValue.value} />
            </ActivityValue>
          ) : (
            <ActivityValue>{propertyValue?.value}</ActivityValue>
          ),
      })}
    </>
  );
}
