import React from "react";
import { CustomIcon, CustomIconProps } from "./CustomIcon";

const paths = [
  "M0 11.9C1.11082 12.7013 2.88487 13.3168 5.0033 13.5921L5 13.75C5 16.3491 6.3221 18.6394 8.33048 19.9851C8.05837 19.9948 7.78097 20 7.5 20C3.48071 20 0.192775 18.9384 0.00817433 17.6172L0 17.5V11.9ZM12.5 7.5C15.9531 7.5 18.75 10.2969 18.75 13.75C18.75 17.2031 15.9531 20 12.5 20C9.04688 20 6.25 17.2031 6.25 13.75C6.25 10.2969 9.04688 7.5 12.5 7.5ZM13.75 8.75L8.75 15H11.875L11.25 18.75L16.25 12.5H13.125L13.75 8.75ZM15 4.4L15.0009 6.67707C14.2187 6.4005 13.3769 6.25 12.5 6.25C8.82856 6.25 5.77328 8.88807 5.12631 12.3721L4.81523 12.3341C2.10526 11.986 0.14934 11.1275 0.00817433 10.1172L0 10V4.4C1.525 5.5 4.3 6.25 7.5 6.25C10.7 6.25 13.475 5.5 15 4.4ZM7.5 0C11.6375 0 15 1.125 15 2.5C15 3.875 11.6375 5 7.5 5C3.35 5 0 3.875 0 2.5C0 1.125 3.35 0 7.5 0Z",
];

export default function DataConnectionIcon(props: CustomIconProps) {
  return (
    <CustomIcon
      paths={paths}
      iconName="data-connection"
      pixelGridSize={20}
      {...props}
    />
  );
}
