import { ProjectQueryParams } from "@aletiq/types";
import usePaginatedProjects from "./usePaginatedProjects";

export default function useTools(
  props?: Omit<ProjectQueryParams, "projectType">
) {
  return usePaginatedProjects(
    { ...props, projectType: "tool" },
    { keepPreviousData: false, select: (result) => result.list }
  );
}
