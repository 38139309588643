import {
  Document,
  DocumentQueryFilterParams,
  DocumentQueryOrder,
} from "@aletiq/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActionBar,
  DefaultFilters,
  Dialog,
  Table,
} from "../../../../components";
import { useFilter, usePagination, useSortKey } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { MoreDocumentFilters } from "../../common";
import { ResolvedDocument } from "../../Documents/documentTypes";
import {
  useAddDocumentCitations,
  useDefaultDocumentColumnConfig,
  useDocuments,
  useDocumentTableColumns,
  useResolvedDocuments,
} from "../../hooks";
import styles from "./AddCitationsDialog.module.scss";
import EmbeddedPdfPreview from "./EmbeddedPdfPreview";

export default function AddCitationsDialog(props: {
  document: Document;
  citedDocuments: number[];
  onClose: () => void;
}) {
  const { document, onClose, citedDocuments } = props;
  const tr = useTranslations();

  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);

  const { sortState, setSortKey } = useSortKey<DocumentQueryOrder>("name");
  const pagination = usePagination();
  const { filter, handleFilter, handleClear } =
    useFilter<DocumentQueryFilterParams>({
      search: "",
    });

  const { resetSelectedPage } = pagination;
  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, resetSelectedPage]);

  const { data: allDocuments } = useDocuments({
    ...filter,
    limit: pagination.limit,
    offset: pagination.selectedPage * pagination.limit,
    order: sortState.key,
    orderDirection: sortState.direction,
  });

  const { mutate: addCitations, isLoading: isSubmitting } =
    useAddDocumentCitations();

  const { config } = useDefaultDocumentColumnConfig();
  const columns = useDocumentTableColumns(config);
  const { documents } = useResolvedDocuments(allDocuments?.list);

  // filter out the current document to avoid linking to itself
  // And the documents already cited
  const filteredDocuments = useMemo(
    () =>
      documents.filter(
        (d) => d.id !== document.id && !citedDocuments.includes(d.id)
      ),
    [documents, document.id, citedDocuments]
  );

  const handleSelect = useCallback(
    (documents: ResolvedDocument[]) =>
      setSelectedDocuments(documents.map((d) => d.id)),
    []
  );

  const handleSubmit = () => {
    addCitations(
      selectedDocuments.map((citation) => ({ citedBy: document.id, citation })),
      { onSettled: onClose }
    );
  };

  return (
    <Dialog
      isOpen
      isFullPage
      icon="new-link"
      title={tr.translateAsString("document.dialog.cite-docs.title", {
        document: document.name,
      })}
      onClose={onClose}
      submitting={isSubmitting}
      onPrimaryClick={handleSubmit}
    >
      <div className={styles.dialog_contents}>
        <div className={styles.description}>
          {tr.translate("document.dialog.cite-docs.description", {
            document: <em className={styles.document_name}>{document.name}</em>,
          })}
        </div>
        <div className={styles.columns}>
          <EmbeddedPdfPreview document={document} className={styles.preview} />
          <div className={styles.table_wrapper}>
            <ActionBar>
              <DefaultFilters
                autoFocusSearch
                objectType="document"
                handleFilter={handleFilter}
                filters={filter}
              />
              <MoreDocumentFilters
                filters={filter}
                handleFilter={handleFilter}
                handleClear={handleClear}
              />
            </ActionBar>
            <Table
              columns={columns}
              data={filteredDocuments}
              searchString={filter.search}
              onSelect={handleSelect}
              paginationOptions={{
                itemCount: allDocuments?.count ?? 0,
                limit: pagination.limit,
                selectedPage: pagination.selectedPage,
                onSetLimit: pagination.handleLimit,
                onSetPage: pagination.handleSelectPage,
              }}
              sortOptions={{
                sortState,
                onSort: setSortKey,
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
