import React from "react";
import { Button } from "../../../../components";
import { useTranslations } from "../../../../util";

export default function CreateDefinitionButton(props: { onClick: () => void }) {
  const { onClick } = props;

  const tr = useTranslations();

  return (
    <Button icon={"edit"} intent="primary" onClick={onClick}>
      {tr.translateAsString("product.bom.modify-definition")}
    </Button>
  );
}
