import React, { useMemo, useState } from "react";
import { useCurrentUser, useUsers } from "../../hooks";
import {
  filterByString,
  sortByString,
  toggle,
  useTranslations,
} from "../../util";
import FilterMenuItem from "./FilterMenuItem";
import FilterView from "./FilterView";

export default function PartOwnerFilter(props: {
  selected: number[];
  noLockedOwnerSelected: boolean;
  onSelect: (values?: number[]) => void;
  onSelectNoLockedOwner: (value?: boolean) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const {
    selected,
    onSelect,
    onSelectNoLockedOwner,
    noLockedOwnerSelected,
    onClear,
  } = props;
  const { data: currentUser } = useCurrentUser();
  const { data: users = [] } = useUsers();

  const [search, setSearch] = useState("");

  const handleSelectMe = () => {
    onSelect(toggle(selected, currentUser?.id ?? -1));
  };

  const sortedUsers = useMemo(() => {
    const filteredUsers = filterByString(users, (u) => u.displayName, search);
    return sortByString(filteredUsers, (u) => u.displayName);
  }, [users, search]);

  return (
    <FilterView
      items={sortedUsers.map((u) => ({ id: u.id, text: u.displayName }))}
      selected={selected}
      all={users.map((u) => u.id)}
      placeholder={tr.translateAsString("filter.owner-parts.placeholder")}
      noSelection={noLockedOwnerSelected}
      search={search}
      onSelect={onSelect}
      onClear={onClear}
      onSearch={setSearch}
      hasSelectAll
      selectNoneText={tr.translate("filter.owner-parts.none")}
      onSelectNone={onSelectNoLockedOwner}
      headItems={
        <FilterMenuItem
          selected={selected.includes(currentUser?.id ?? -1)}
          onSelect={handleSelectMe}
          text={tr.translate("filter.owner.me", {
            name: currentUser?.displayName ?? "",
          })}
        />
      }
    />
  );
}
