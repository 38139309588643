export function formatMail(to: string, subject?: string, body?: string) {
  let mailToString = `mailto:${to}`;
  const mailSubject = subject !== undefined ? `subject=${subject}` : "";
  const mailBody = body !== undefined ? `&body=${body}` : "";

  mailToString += mailSubject !== "" || mailBody !== "" ? "?" : "";
  mailToString += mailSubject;
  mailToString += mailSubject !== "" && mailBody !== "" ? "&" : "";
  mailToString += mailBody;
  return mailToString;
}
