import React from "react";
import { Icon } from "../../components";
import NameLink from "../../components/link/NameLink";
import { useProjectDefinition } from "./hooks";

export default function ProjectDefinitionLink(props: {
  hasIcon?: boolean;
  projectId: number;
  definitionIdx: number;
  onClick?: () => void;
}) {
  const { projectId, definitionIdx, onClick, hasIcon } = props;

  const { data: definition } = useProjectDefinition(projectId, definitionIdx);

  return (
    <NameLink
      hasIcon={hasIcon}
      icon={<Icon icon={"diagram-tree"} inline intent="primary" />}
      text={definition?.name ?? ""}
      isDisabled={false}
      location={{
        path: `/project/${projectId}/nomenclature/${definitionIdx}`,
      }}
      onClick={onClick}
    />
  );
}
