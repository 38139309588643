import React, { useState } from "react";
import {
  BatchActionButton,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  RemoveLinkIcon,
} from "../../../../../components";
import { useHasPermission } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { useDownloadArchiveByDocumentIds } from "../../../hooks";
import { ResolvedDocument } from "../../documentTypes";
import DocumentBatchActionHeaderDialogs from "./DocumentBatchActionDialogs";
import styles from "./DocumentBatchActionHeader.module.scss";

export type DialogType =
  | "create-workflow"
  | "delete"
  | "edit-property"
  | "link-workflow"
  | "link-project"
  | "unlink-project"
  | "share"
  | null;

export default function DocumentBatchActionHeader(props: {
  selectedDocuments: ResolvedDocument[];
  toggleAllRowsSelected: (value?: boolean | undefined) => void;
  projectId?: number;
}) {
  const { toggleAllRowsSelected, selectedDocuments, projectId } = props;
  const tr = useTranslations();

  const [dialog, setDialog] = useState<DialogType>(null);

  const canUpdateDocuments = useHasPermission("update:documents");
  const canDeleteDocuments = useHasPermission("delete:documents");
  const canCreateWorkflows = useHasPermission("create:workflows");

  const downloadArchive = useDownloadArchiveByDocumentIds();

  const handleDialog = (dialog: DialogType) => () => {
    setDialog(dialog);
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleCloseResetSelection = () => {
    setDialog(null);
    toggleAllRowsSelected(false);
  };

  const handleDownloadArchive = async () => {
    downloadArchive(selectedDocuments.map((d) => d.id));
  };

  return (
    <>
      <div className={styles.subtitle}>
        {tr.translate("passport.page.selected", {
          count: selectedDocuments.length,
        })}
      </div>

      {canUpdateDocuments && (
        <BatchActionButton
          icon="edit"
          onClick={handleDialog("edit-property")}
          title={tr.translateAsString("document.page.action.edit.properties")}
        />
      )}
      {canCreateWorkflows && (
        <>
          <BatchActionButton
            icon="plus"
            onClick={handleDialog("create-workflow")}
            title={tr.translateAsString("passport.page.action.create-workflow")}
          />
          <BatchActionButton
            icon="new-link"
            onClick={handleDialog("link-workflow")}
            title={tr.translateAsString("passport.page.action.link-workflow")}
          />
        </>
      )}

      <Popover2
        minimal
        position="bottom-right"
        content={
          <Menu isDense intent="minimal">
            <MenuItem
              isDense
              text={tr.translate("part.details.link-part.existing-project")}
              icon="new-link"
              onClick={handleDialog("link-project")}
            />
            {projectId && (
              <MenuItem
                isDense
                icon={<RemoveLinkIcon />}
                text={tr.translate("document.page.action.unlink")}
                onClick={handleDialog("unlink-project")}
              />
            )}
            <MenuItem
              isDense
              text={tr.translate("document.page.action.download")}
              icon="download"
              onClick={handleDownloadArchive}
            />
            <MenuItem
              isDense
              text={tr.translate("document.page.action.share")}
              icon="people"
              onClick={handleDialog("share")}
            />
            {canDeleteDocuments && (
              <>
                <MenuDivider />
                <MenuItem
                  isDense
                  text={tr.translate("generic.action.delete")}
                  icon="trash"
                  onClick={handleDialog("delete")}
                />
              </>
            )}
          </Menu>
        }
      >
        <BatchActionButton
          intent="outlined"
          rightIcon="caret-down"
          title={tr.translateAsString("part.details.plus")}
        />
      </Popover2>
      {dialog && (
        <DocumentBatchActionHeaderDialogs
          selectedDocuments={selectedDocuments}
          dialog={dialog}
          handleCloseDialog={handleCloseDialog}
          handleCloseResetSelection={handleCloseResetSelection}
          projectId={projectId}
        />
      )}
    </>
  );
}
