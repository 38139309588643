import { ReactNode, useState } from "react";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";

export default function useDelayedAction(
  message: ReactNode,
  delay: number = 4000
) {
  const toaster = useToaster();
  const tr = useTranslations();
  const [isDelayPending, setPendingDelay] = useState(false);

  const delayAction = (action: () => void) => {
    setPendingDelay(true);
    let hasCanceled = false;
    toaster.show({
      intent: "success",
      icon: "tick",
      timeout: delay,
      message: message,
      action: {
        text: tr.translate("generic.action.cancel"),
        onClick: () => {
          hasCanceled = true;
        },
      },
      onDismiss: async () => {
        if (hasCanceled) {
          return setPendingDelay(false);
        }
        action();
        setPendingDelay(false);
      },
    });
  };

  return { isDelayPending, delayAction };
}
