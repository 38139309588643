import { useMemo } from "react";
import { ColumnOptionConfig } from "../../../hooks";
import {
  useAletiqPropertiesDefaultDisplay,
  useProperties,
} from "../../administration/Attributes/hooks";

export type VersionDocColumn =
  | "name"
  | "description"
  | "revisionName"
  | "createdAt"
  | "updatedAt"
  | "action"
  | number;

export function useVersionDocsColumnConfig() {
  const { data: properties = [], isLoading: isLoadingCustomProperties } =
    useProperties("document");
  const { data: aletiqProperties = [], isLoading: isLoadingAletiqProperties } =
    useAletiqPropertiesDefaultDisplay("document");

  const isLoading = useMemo(
    () => isLoadingAletiqProperties || isLoadingCustomProperties,
    [isLoadingAletiqProperties, isLoadingCustomProperties]
  );

  const config = useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    const config: ColumnOptionConfig<VersionDocColumn> = {
      name: true,
      revisionName: true,
      description: true,
      createdAt:
        aletiqProperties.find((p) => p.name === "created_at")
          ?.displayByDefault ?? false,
      updatedAt:
        aletiqProperties.find((p) => p.name === "updated_at")
          ?.displayByDefault ?? false,
      action: false,
    };

    for (const property of properties) {
      config[property.id] = property.displayByDefault;
    }

    return config;
  }, [properties, aletiqProperties, isLoading]);

  return { isLoading, config };
}
