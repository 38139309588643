import { UserGroup } from "@aletiq/types";
import React from "react";
import { AlertPopover, Button, User } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useUpdateUserGroup } from "../hooks";
import styles from "./GroupList.module.scss";

export default function GroupUserRow(props: {
  group: UserGroup;
  userId: number;
  search?: string;
}) {
  const { group, userId, search } = props;

  const tr = useTranslations();

  const { mutate: updateUserGroup, isLoading: isUpdatingGroup } =
    useUpdateUserGroup();

  const handleRemoveUserFromGroup = () => {
    updateUserGroup({
      groupId: group.id,
      spec: {
        users: group.users.filter((u) => u !== userId),
        name: group.name,
      },
    });
  };

  return (
    <div className={styles.row}>
      <User userId={userId} search={search} intent="default" />
      <div>
        <AlertPopover
          isDense
          icon="info-sign"
          submitting={isUpdatingGroup}
          onPrimaryClick={handleRemoveUserFromGroup}
          title={tr.translate("admin.groups.popup.remove-user.title")}
          content={tr.translate("admin.groups.popup.remove-user.description")}
        >
          <Button isDense>{tr.translate("admin.groups.button.remove")}</Button>
        </AlertPopover>
      </div>
    </div>
  );
}
