export function mapRecord<T, U, K extends keyof any>(
  collection: Record<K, T>,
  value: (value: T, key: K, record: Record<K, T>) => U
): Record<K, U> {
  const res = {} as Record<K, U>;
  for (const key in collection) {
    res[key] = value(collection[key], key, collection);
  }
  return res;
}
