import { UserExport } from "@aletiq/types";
import { useQuery, UseQueryResult } from "react-query";
import useApi from "../../../app/useApi";
import { adminKeys } from "./queries";

export default function useUserExports(): UseQueryResult<UserExport[]> {
  const api = useApi();

  return useQuery(adminKeys.userExports(), () =>
    api.http.getJson("/admin/users-exports")
  );
}
