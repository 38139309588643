import { API } from "@aletiq/api";
import { ProcessModelSpec } from "@aletiq/types";
import { QueryClient, useQuery } from "react-query";
import useApi from "../../app/useApi";
import { processModelKeys, processModelQueries } from "./hooks/queries";

export function useProcessModels() {
  const api = useApi();
  return useQuery(processModelQueries.all(api));
}

export function useProcessModel(modelId: number | null) {
  const api = useApi();
  return useQuery({
    ...processModelQueries.all(api),
    enabled: modelId !== null,
    select: (data) => data.find((m) => m.id === modelId),
  });
}

export async function createProcessModel(
  api: API,
  queryClient: QueryClient,
  model: ProcessModelSpec
) {
  await api.task.createProcessModel(model);
  queryClient.invalidateQueries(processModelKeys.all);
}

export async function updateProcessModel(
  api: API,
  queryClient: QueryClient,
  modelId: number,
  model: ProcessModelSpec
) {
  await api.task.updateProcessModel(modelId, model);
  queryClient.invalidateQueries(processModelKeys.all);
}

export async function deleteProcessModel(
  api: API,
  queryClient: QueryClient,
  modelId: number
) {
  await api.task.deleteProcessModel(modelId);
  queryClient.invalidateQueries(processModelKeys.all);
}
