import { usePart } from "../../../features/pdm/hooks";
import { useTranslations } from "../../../util";
import partLinkProps from "../props/partLinkProps";

export default function usePartLink(partId: number) {
  const tr = useTranslations();

  const { data: part } = usePart(partId);

  return partLinkProps(tr, part);
}
