import { PassportComponent } from "@aletiq/types";
import React, { useState } from "react";
import {
  AlertPopover,
  AlignRight,
  AnchorButton,
  Callout,
  Card,
  PassportNameLink,
  ProjectNameLink,
  RemoveLinkIcon,
  Tooltip,
} from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useDeletePassportComponent, usePassports } from "../../hooks";
import styles from "./PassportNomenclature.module.scss";
import PassportSelector from "./PassportSelector";

export default function PassportComponents(props: {
  hasConflictWithParentBom: boolean;
  passportId: number;
  passportBomComponents: {
    product: number;
    component: PassportComponent | null;
  }[];
}) {
  const { hasConflictWithParentBom, passportId, passportBomComponents } = props;
  const tr = useTranslations();

  const [isOpen, setIsOpen] = useState(true);

  const canEditNomenclature = useHasPermission("update:passport-nomenclature");
  const { data: allPassports = [] } = usePassports();
  const { mutate: handleDeleteComponent, isLoading: deletingComponent } =
    useDeletePassportComponent(passportId);

  const handleToggleFold = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Card
      isFolded={!isOpen}
      className={styles.card}
      onToggleFold={handleToggleFold}
      title={tr.translateAsString("passport.bom.components", {
        length: passportBomComponents.length,
      })}
    >
      {hasConflictWithParentBom && (
        <Callout
          isDense
          className={styles.callout}
          icon="warning-sign"
          intent="warning"
        >
          {tr.translate("passport.bom.components.conflict")}
        </Callout>
      )}
      <div className={styles.table}>
        {passportBomComponents.map((component, index) =>
          component.component ? (
            <div className={styles.row} key={index}>
              <ProjectNameLink
                hasThumbnail
                projectId={component.product}
                className={styles.product}
              />
              <PassportNameLink
                hasIcon
                passportId={component.component.passport}
                className={styles.passportName}
              />
              <AlignRight />
              <AlertPopover
                icon={<RemoveLinkIcon />}
                title={tr.translateAsString(
                  "passport.bom.components.remove.title"
                )}
                content={tr.translateAsString(
                  "passport.bom.components.remove.description"
                )}
                onPrimaryClick={() =>
                  component.component &&
                  handleDeleteComponent(component.component.id)
                }
                disabled={!canEditNomenclature}
                isDense
              >
                <Tooltip
                  position="top-right"
                  content={tr.translate("passport.bom.components.remove")}
                >
                  <AnchorButton
                    isDense
                    icon={<RemoveLinkIcon />}
                    isLoading={deletingComponent}
                    isDisabled={!canEditNomenclature}
                  />
                </Tooltip>
              </AlertPopover>
            </div>
          ) : (
            <div className={styles.row} key={index}>
              <ProjectNameLink
                hasThumbnail
                projectId={component.product}
                className={styles.product}
              />
              <div className={styles.passport}>
                <PassportSelector
                  product={component.product}
                  passportId={passportId}
                  passports={allPassports}
                />
              </div>
            </div>
          )
        )}
      </div>
    </Card>
  );
}
