import React from "react";
import { AlertPopover } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useDeletePartProjectBom } from "../../hooks";

export default function DeleteNomenclaturePopover(props: {
  children: string | JSX.Element;
  projectId: number;
  definitionIdx: number;
  onClose?: () => void;
}) {
  const { children, projectId, definitionIdx, onClose } = props;

  const tr = useTranslations();

  const { mutate: updatePartProjectBom, isLoading } = useDeletePartProjectBom(
    projectId,
    definitionIdx
  );

  const handleSubmit = () => {
    updatePartProjectBom(undefined, { onSettled: onClose });
  };

  return (
    <AlertPopover
      isDense
      intent="danger"
      icon="trash"
      title={tr.translateAsString("project.dialog.delete.part.bom.title")}
      onPrimaryClick={handleSubmit}
      onClose={onClose}
      submitting={isLoading}
      content={tr.translate("project.dialog.delete.part.bom.subtitle")}
    >
      {children}
    </AlertPopover>
  );
}
