import { ProjectDefinitionId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { projectKeys } from "./queries";

export default function useUpdateComponentDefinition(
  projectId: number,
  definitionIdx: number
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    (spec: {
      parent: ProjectDefinitionId;
      componentId: number;
      definition: number;
    }) =>
      api.project.updateBOMEntry(
        spec.parent.productId,
        spec.parent.definitionIdx,
        spec.componentId,
        {
          definition: spec.definition,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          projectKeys.definition(projectId, definitionIdx)
        );
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.product.bom.update"),
        });
      },
    }
  );
}
