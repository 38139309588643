import { Project } from "@aletiq/types";
import React from "react";
import { Dialog2 } from "../../../components";
import { useNavigate } from "../../../hooks";
import { useTranslations } from "../../../util";
import { useUpdateProjectType } from "../hooks";

export default function ConvertProductDialog(props: {
  product: Project;
  onClose: () => void;
}) {
  const { product, onClose } = props;

  const tr = useTranslations();
  const navigate = useNavigate();
  const { mutate: updateProjectType, isLoading } = useUpdateProjectType(
    product.id
  );

  const handlePrimaryClick = () => {
    updateProjectType(product.isArticle ? "tool" : "article", {
      onSuccess: () => {
        navigate({
          path: "/project",
          search: product.isArticle ? "type=tool" : "type=article",
        });
      },
    });
  };

  return (
    <Dialog2
      isOpen
      onClose={onClose}
      title={tr.translateAsString("project.dialog.convert.title")}
      icon={"changes"}
      primaryButtonProps={{
        onClick: handlePrimaryClick,
        isLoading,
      }}
      secondaryButtonProps={{
        onClick: onClose,
      }}
    >
      {tr.translate(
        product.isArticle
          ? "project.dialog.convert.description.article"
          : "project.dialog.convert.description.tool"
      )}
    </Dialog2>
  );
}
