import { PropertyFilter } from "@aletiq/types";
import { HandleFilter } from "./useFilter";

export default function useCustomFilters<
  T extends Partial<{ filters: PropertyFilter[] }>
>(queryFilters: T, handleFilter: HandleFilter<T>) {
  const clearCustomFilter = (propertyId: number) => {
    handleFilter("filters")(
      queryFilters.filters?.filter((property) => property.id !== propertyId)
    );
  };

  const setCustomFilter = (filterValue: PropertyFilter) => {
    const existingFilters = queryFilters.filters ?? [];

    if (existingFilters.some((property) => property.id === filterValue.id)) {
      handleFilter("filters")(
        existingFilters.map((property) =>
          property.id === filterValue.id ? filterValue : property
        )
      );
      return;
    }
    handleFilter("filters")([...existingFilters, filterValue]);
  };

  return {
    setCustomFilter,
    clearCustomFilter,
  };
}
