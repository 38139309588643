import { TaskStatus, TaskStatusFilterValue } from "@aletiq/types";
import { useMemo } from "react";
import { useCurrentUser } from "../../../hooks";
import { sortByString } from "../../../util";
import useProcesses from "./useProcesses";
import useTasks from "./useTasks";

export default function useUserTasks(
  status: TaskStatusFilterValue[]
): ResolvedTask[] {
  const { data: user } = useCurrentUser();
  const { data: tasks = [] } = useTasks({
    owners: [user?.id ?? 0],
    taskStatuses: status,
    workflowIsArchived: false,
  });
  const { data: processes = [] } = useProcesses({});

  return useMemo(() => {
    const sortedTasks = sortByString(tasks, (task) => task.dueDate || "").slice(
      0,
      10
    );

    const resolvedTasks = sortedTasks
      .map((t) => {
        const process = processes.find((p) => p.id === t.process);
        return { ...t, process };
      })
      .filter((t) => t.process !== undefined) as ResolvedTask[];
    return resolvedTasks;
  }, [tasks, processes]);
}

export type ResolvedTask = {
  id: number;
  process: {
    id: number;
    title: string;
    isArchived: boolean;
    color?: string;
    owner: number;
  };
  title: string;
  owners: number[];
  dueDate: string | null;
  completionDate?: string;
  status: TaskStatus;
  dependencies: number[];
};
