import { Passport, ProjectVersion } from "@aletiq/types";
import React, { useState } from "react";
import { EditablePropertyTemplate } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProductVersions, useProject } from "../../../project/hooks";
import ProductVersionLink from "../../../project/Version/ProductVersionLink";
import { ChangeParentVersionDialog } from "../../dialogs";
import { useSetPassportParentVersion } from "../../hooks";

export default function VersionField(props: {
  passport: Passport;
  hasIcon?: boolean;
}) {
  const { passport, hasIcon = false } = props;
  const tr = useTranslations();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const canUpdatePassports = useHasPermission("update:passports");
  const { data: parentProject } = useProject(passport?.parentProject ?? null);
  const { data: projectVersions = [] } = useProductVersions(
    parentProject?.id ?? null
  );

  const projectVersion: ProjectVersion | undefined = projectVersions.find(
    (version) => version.id === passport?.projectIteration
  );

  const { mutate: setParentVersion, isLoading: parentVersionUpdating } =
    useSetPassportParentVersion();

  const handleOpenedDialog = () => {
    setDialogIsOpen((prevState) => !prevState);
  };

  return (
    <EditablePropertyTemplate
      label={tr.translateAsString("passport.version.pick.version.title")}
      isEditable={canUpdatePassports}
      triggerEditMode={handleOpenedDialog}
      isInEditMode={dialogIsOpen}
      inline
    >
      {dialogIsOpen && (
        <ChangeParentVersionDialog
          submitting={parentVersionUpdating}
          onSubmit={(versionId) => {
            setParentVersion(
              { passportId: passport.id, versionId },
              { onSuccess: handleOpenedDialog }
            );
          }}
          projectId={passport?.parentProject ?? 0}
          onClose={handleOpenedDialog}
        />
      )}
      {projectVersion && parentProject && (
        <ProductVersionLink
          product={parentProject.id}
          version={projectVersion.id}
          hasIcon={hasIcon}
        />
      )}
      {!projectVersion?.version && tr.translate("generic.label.none")}
    </EditablePropertyTemplate>
  );
}
