import { useEffect, useMemo } from "react";
import { QueryStatus } from "react-query";
import { useToaster } from "../../../hooks";

export default function useMutationToaster(
  status: QueryStatus,
  messages: { loading?: string; error?: string; success?: string }
) {
  const toaster = useToaster();
  const { loading, error, success } = messages;
  const key = useMemo(() => Math.random().toString(), []);
  useEffect(() => {
    switch (status) {
      case "loading":
        loading
          ? toaster.show(
              {
                intent: "primary",
                icon: "upload",
                message: loading,
                timeout: 0,
              },
              key
            )
          : toaster.dismiss(key);
        break;
      case "error":
        error
          ? toaster.show(
              {
                intent: "danger",
                icon: "upload",
                message: error,
              },
              key
            )
          : toaster.dismiss(key);
        break;
      case "success":
        success
          ? toaster.show(
              {
                intent: "success",
                icon: "upload",
                message: success,
              },
              key
            )
          : toaster.dismiss(key);
        break;
      case "idle":
        toaster.dismiss(key);
        break;
    }

    return () => toaster.dismiss(key);
  }, [status, toaster, loading, error, success, key]);
  return toaster;
}
