import { MenuItem } from "@aletiq/design-system";
import React, { ReactNode } from "react";
import { Intent } from "./FilterMenu";

export default function FilterMenuItemDS(props: {
  onSelect: () => void;
  text: ReactNode;
  selected: boolean;
  intent?: Intent;
  labelElement?: ReactNode;
}) {
  const { intent = "primary", onSelect, text, selected, labelElement } = props;
  return (
    <MenuItem
      onClick={onSelect}
      text={text}
      shouldDismissPopover={false}
      intent={intent}
      icon={selected ? "tick" : "blank"}
      active={selected}
      labelElement={labelElement}
      isDense
    />
  );
}
