export const draftJsObject = {
  INLINE_STYLE: {
    BOLD: "BOLD",
    CODE: "CODE",
    ITALIC: "ITALIC",
    STRIKETHROUGH: "STRIKETHROUGH",
    UNDERLINE: "UNDERLINE",
  },

  BLOCK_TYPE: {
    UNSTYLED: "unstyled",
    HEADER_ONE: "header-one",
    HEADER_TWO: "header-two",
    HEADER_THREE: "header-three",
    HEADER_FOUR: "header-four",
    HEADER_FIVE: "header-five",
    HEADER_SIX: "header-six",
    UNORDERED_LIST_ITEM: "unordered-list-item",
    ORDERED_LIST_ITEM: "ordered-list-item",
    BLOCKQUOTE: "blockquote",
    PULLQUOTE: "pullquote",
    CODE: "code-block",
    ATOMIC: "atomic",
  },

  ENTITY_TYPE: {
    LINK: "LINK",
    IMAGE: "IMAGE",
    EMBED: "embed",
  },
};
