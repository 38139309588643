import React from "react";
import { Icon, Subtitle } from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./PartRow.module.scss";

export default function NotLoadedRow(props: { onRefresh: () => void }) {
  const { onRefresh } = props;
  const tr = useTranslations();
  return (
    <>
      <Icon inline icon="time" />
      <Subtitle text={tr.translateAsString("part.details.loading.bom")} />
      <Icon
        icon="reset"
        inline
        onClick={onRefresh}
        className={styles.refresh_icon}
      />
    </>
  );
}
