import { PropertyObject } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { propertyKeys } from "./queries";

export default function useProperty(
  nameId: string,
  objectType: PropertyObject
) {
  const api = useApi();
  return useQuery(propertyKeys.byNameId(objectType, nameId), () =>
    api.property.getProperty(objectType, nameId)
  );
}
