import { ProjectType } from "@aletiq/types";
import React, { useState } from "react";
import {
  BatchActionButton,
  ConfigOptionIcon,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
} from "../../../components";
import { useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { ResolvedPassport } from "../types";
import PassportBatchActionDialogs from "./PassportBatchActionDialogs";
import styles from "./PassportBatchActionHeader.module.scss";

export type DialogType =
  | "create-workflow"
  | "delete"
  | "edit-property"
  | "link-workflow"
  | "update-config"
  | "update-version"
  | "upload-files"
  | "update-bill-index"
  | "update-definition-index"
  | null;

export default function AllPassportsHeaderBatchAction(props: {
  selectedPassports: ResolvedPassport[];
  toggleAllRowsSelected: (value?: boolean | undefined) => void;
  projectType?: ProjectType;
}) {
  const { toggleAllRowsSelected, selectedPassports, projectType } = props;
  const tr = useTranslations();

  const canDeletePassports = useHasPermission("delete:passports");
  const canUpdatePassports = useHasPermission("update:passports");

  const [dialog, setDialog] = useState<DialogType>(null);

  const areAllSelectedFromSameProduct =
    selectedPassports.length > 0 &&
    selectedPassports
      .map((p) => p.parentProjectId)
      .every((id) => id === selectedPassports[0].parentProjectId);

  const handleDialog = (dialog: DialogType) => () => {
    setDialog(dialog);
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleCloseDeleteDialog = () => {
    toggleAllRowsSelected(false);
    setDialog(null);
  };

  return (
    <>
      <div className={styles.subtitle}>
        {tr.translate("passport.page.selected", {
          count: selectedPassports.length,
        })}
      </div>
      {canUpdatePassports && (
        <>
          <BatchActionButton
            title={tr.translateAsString("passport.page.action.edit-property")}
            icon="edit"
            onClick={handleDialog("edit-property")}
          />
          <BatchActionButton
            title={tr.translateAsString("passport.page.action.create-workflow")}
            icon="plus"
            onClick={handleDialog("create-workflow")}
          />
          <BatchActionButton
            title={tr.translateAsString("passport.page.action.link-workflow")}
            icon="new-link"
            onClick={handleDialog("link-workflow")}
          />
        </>
      )}
      <Popover2
        position="bottom-right"
        content={
          <Menu isDense intent="minimal">
            {projectType === "tool" ? (
              <MenuItem
                isDense
                text={tr.translate("passport.page.action.update-version")}
                icon="git-commit"
                onClick={handleDialog("update-version")}
                disabled={!areAllSelectedFromSameProduct || !canUpdatePassports}
              />
            ) : null}
            {projectType === "article" ? (
              <MenuItem
                isDense
                text={tr.translate("passport.page.action.update-bill-index")}
                icon="numbered-list"
                onClick={handleDialog("update-bill-index")}
                disabled={!areAllSelectedFromSameProduct || !canUpdatePassports}
              />
            ) : null}
            <MenuItem
              isDense
              text={tr.translate(
                "passport.page.action.update-definition-index"
              )}
              icon="diagram-tree"
              onClick={handleDialog("update-definition-index")}
              disabled={!areAllSelectedFromSameProduct || !canUpdatePassports}
            />
            <MenuItem
              isDense
              text={tr.translate("passport.page.action.update-config")}
              icon={<ConfigOptionIcon />}
              onClick={handleDialog("update-config")}
              disabled={!areAllSelectedFromSameProduct || !canUpdatePassports}
            />
            <MenuItem
              isDense
              text={tr.translate("passport.page.action.upload-files")}
              icon="paperclip"
              onClick={handleDialog("upload-files")}
            />
            <MenuDivider isDense />
            <MenuItem
              isDense
              text={tr.translate("generic.action.delete")}
              icon="trash"
              onClick={handleDialog("delete")}
              disabled={!canDeletePassports}
            />
          </Menu>
        }
      >
        <BatchActionButton
          intent="outlined"
          title={tr.translateAsString("part.details.plus")}
          rightIcon="caret-down"
        />
      </Popover2>
      {dialog && (
        <PassportBatchActionDialogs
          selectedPassports={selectedPassports}
          dialog={dialog}
          handleCloseDialog={handleCloseDialog}
          handleCloseDeleteDialog={handleCloseDeleteDialog}
        />
      )}
    </>
  );
}
