import React from "react";
import { DateWithSpaces, Icon } from "../../../components";
import { useTranslations } from "../../../util";
import styles from "./Exports.module.scss";

export default function DateRange(props: { from?: string; to: string }) {
  const { from, to } = props;
  const tr = useTranslations();
  return (
    <div className={styles.date_range_container}>
      {from && <DateWithSpaces date={from} />}
      <span>
        {!from && tr.translate("admin.exports.label.account-creation")}
      </span>
      <Icon icon="arrow-right" inline />
      <DateWithSpaces date={to} />
    </div>
  );
}
