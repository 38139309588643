import { MenuItem } from "@aletiq/design-system";
import { IconName, MaybeElement } from "@blueprintjs/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigateTo } from "../../hooks";
import styles from "./Tabs.module.scss";

export default function TabItem(props: {
  text: string;
  icon: IconName | MaybeElement;
  isDisabled?: boolean;
  url: string;
  isExact?: boolean;
}) {
  const { url, isExact, ...restProps } = props;

  const handleRedirect = useNavigateTo({ path: url });
  const location = useLocation();

  return (
    <MenuItem
      {...restProps}
      intent="primary"
      className={styles.tab}
      onClick={handleRedirect}
      active={
        isExact ? location.pathname === url : location.pathname.includes(url)
      }
      isDense
    />
  );
}
