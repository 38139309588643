import { Notification } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { notificationKeys } from "./queries";

export default function useSetNotificationsStatus() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<
    Response,
    Error,
    { from: number; to: number; status: boolean }
  >(
    ({ from, to, status }) => api.setNotificationsReadStatus(from, to, status),
    {
      //do optimistic update and return snapshot of old data
      onMutate: async ({ from, to, status: newStatus }) => {
        await queryClient.cancelQueries(notificationKeys.all);

        const oldDataSnapshot: Notification[] =
          queryClient.getQueryData(notificationKeys.all, { exact: true }) || [];

        queryClient.setQueryData(
          notificationKeys.all,
          oldDataSnapshot.map((notification) => {
            if (notification.id >= from && notification.id <= to)
              return { ...notification, newStatus };
            return notification;
          })
        );

        return oldDataSnapshot;
      },
      //revert to old data in case of error
      onError: (error, newData, snapshot) => {
        queryClient.setQueryData(notificationKeys.all, snapshot);
      },
      onSuccess: () => queryClient.invalidateQueries(notificationKeys.all),
    }
  );
}
