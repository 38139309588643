import { Icon } from "@aletiq/design-system";
import { Process } from "@aletiq/types";
import React from "react";
import { Translations } from "../../../util";

export default function workflowLinkProps(
  tr: Translations,
  workflow: Process | undefined
) {
  const iconIntent = workflow?.isDeleted ? "none" : "primary";
  const icon = <Icon inline icon="flow-linear" intent={iconIntent} />;

  let tooltip = undefined;
  if (workflow?.isDeleted) {
    tooltip = tr.translateAsString("link.deleted-object", {
      type: "workflow",
    });
  }

  return {
    icon,
    tooltip,
    isDisabled: workflow?.isDeleted ?? false,
    text: workflow?.title ?? "",
    location: { panel: `workflowId-${workflow?.id}` },
  };
}
