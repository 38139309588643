import { Button, Card, SearchInput } from "@aletiq/design-system";
import { ProcessModelSpec } from "@aletiq/types";
import { NonIdealState, Spinner, SpinnerSize } from "@blueprintjs/core";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { ActionBar, AlignRight } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import {
  createProcessModel,
  deleteProcessModel,
  updateProcessModel,
  useProcessModels,
} from "../../tasksSlice";
import { Editor } from "../Editor";
import WorkflowModelView from "../WorkflowModelView";
import styles from "./WorkflowModelList.module.scss";

export default function WorkflowModelList() {
  const tr = useTranslations();
  const api = useApi();
  const queryClient = useQueryClient();

  const { data: models = [], isLoading } = useProcessModels();

  const canCreateWorkflowModels = useHasPermission("create:workflow-models");
  const canUpdateWorkflowModels = useHasPermission("update:workflow-models");

  const [modelBeingEdited, setModelBeingEdited] = useState<number | null>(null);
  const [modelBeingCreated, setModelBeingCreated] = useState(false);
  const [search, setSearch] = useState("");

  //filter models & sort by last created (id in descending order)
  const filteredModels = models.filter((model) =>
    model.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  const editModel = (modelId: number) => setModelBeingEdited(modelId);
  const newModel = () => setModelBeingCreated(true);

  const updateModel = (modelId: number, model: ProcessModelSpec) => {
    updateProcessModel(api, queryClient, modelId, model);
    setModelBeingEdited(null);
  };
  const saveModel = (model: ProcessModelSpec) => {
    createProcessModel(api, queryClient, model);
    setModelBeingCreated(false);
  };

  const handleDeleteModel = (model: number) =>
    deleteProcessModel(api, queryClient, model);

  return (
    <>
      <ActionBar>
        <SearchInput
          isDense
          intent="primary"
          value={search}
          onChange={setSearch}
        />

        <AlignRight />
        <Button
          isDisabled={!canCreateWorkflowModels || modelBeingCreated}
          intent="secondary"
          icon="plus"
          onClick={newModel}
          isDense
        >
          {tr.translate("workflow.model.new")}
        </Button>
      </ActionBar>

      {modelBeingCreated && (
        <Editor
          newProcess
          onSave={saveModel}
          onDiscard={() => setModelBeingCreated(false)}
        />
      )}
      {isLoading ? (
        <NonIdealState>
          <Spinner size={SpinnerSize.LARGE} />
        </NonIdealState>
      ) : (
        filteredModels.map((model) => {
          if (modelBeingEdited === model.id)
            return (
              <Editor
                key={model.id}
                model={model}
                onSave={(model) => updateModel(modelBeingEdited, model)}
                onDiscard={() => setModelBeingEdited(null)}
              />
            );
          else
            return (
              <WorkflowModelView
                key={model.id}
                model={model}
                handleDeleteModel={handleDeleteModel}
                editModel={editModel}
                disableEdit={!canUpdateWorkflowModels}
                search={search}
              />
            );
        })
      )}
      {!isLoading && filteredModels.length === 0 && (
        <Card className={styles.no_model_card}>
          <NonIdealState
            icon={<img src="/assets/no-data.svg" height={80} alt="no data" />}
            description={
              <>
                {tr.translate(
                  search === ""
                    ? "workflow.model.empty"
                    : "workflow.model.empty.filtered"
                )}
                <Button
                  isDense
                  intent="primary"
                  icon="plus"
                  isDisabled={!canCreateWorkflowModels || modelBeingCreated}
                  onClick={newModel}
                  className={styles.emptyButton}
                >
                  {tr.translate("workflow.model.new")}
                </Button>
              </>
            }
          />
        </Card>
      )}
    </>
  );
}
