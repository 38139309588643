import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useSetProductImage(productId: number) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<Response, Error, File>(
    (image) => api.project.setProjectImage(productId, image),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.image(productId));
      },
    }
  );
}
