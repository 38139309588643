import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { projectQueries } from "./queries";

export default function useProjectDefinition(
  projectId: number,
  definitionIdx: number
) {
  const api = useApi();
  return useQuery(projectQueries.definition(api, projectId, definitionIdx));
}
