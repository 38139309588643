import { Part } from "@aletiq/types";
import React from "react";
import {
  EditableOwner,
  EditablePropertyInput,
  NonEditablePropertyInput,
  OpenableImage,
  User,
} from "../../../components";
import { useHasPermission, useUsers } from "../../../hooks";
import { useTranslations } from "../../../util";
import {
  usePartImage,
  useUpdatePartDescription,
  useUpdatePartOwner,
} from "../hooks";
import styles from "./PartDetails.module.scss";

const INLINE_MIN_WIDTH = 150;

export default function PartInfo(props: { part: Part }) {
  const { part } = props;
  const tr = useTranslations();

  const canUpdateParts = useHasPermission("update:parts");
  const { data: image } = usePartImage(part.id);
  const { data: allUsers = [] } = useUsers();

  const { mutate: updatePartDescription } = useUpdatePartDescription();
  const { mutate: updatePartOwner, isLoading: isUpdatingOwner } =
    useUpdatePartOwner(part.id);

  const allowedPartOwners = allUsers.filter((u) =>
    u.permissions.includes("update:parts")
  );

  const handleDescription = (description: string) => {
    updatePartDescription({ partId: part.id, description });
  };

  return (
    <div className={styles.properties}>
      {image && (
        <OpenableImage
          imgUrl={URL.createObjectURL(image)}
          className={styles.img}
          alt={part.name}
        />
      )}
      <div className={styles.properties_inner}>
        <NonEditablePropertyInput
          value={<img alt={part.format} src={`/assets/${part.format}.png`} />}
          label={tr.translateAsString("part.details.format")}
          inline={!image}
          minWidth={INLINE_MIN_WIDTH}
        />
        <EditableOwner
          initialOwner={part.owner}
          allowedOwners={allowedPartOwners}
          onSubmit={updatePartOwner}
          isSubmitting={isUpdatingOwner}
          isDisabled={!canUpdateParts}
          inline={!image}
          minWidth={INLINE_MIN_WIDTH}
        />
        <EditablePropertyInput
          value={part?.description ?? ""}
          isEditable={canUpdateParts}
          label={tr.translateAsString("generic.label.description")}
          placeholder={tr.translateAsString("generic.action.modify")}
          emptyState={tr.translateAsString("generic.label.none")}
          onSubmit={handleDescription}
          inline={!image}
          minWidth={INLINE_MIN_WIDTH}
        />
        <NonEditablePropertyInput
          value={
            <>
              {part.lockOwner && (
                <User size="small" userId={part.lockOwner} intent="default" />
              )}
              {!part.lockOwner && tr.translateAsString("generic.label.none")}
            </>
          }
          label={tr.translateAsString("part.details.modification")}
          inline={!image}
          minWidth={INLINE_MIN_WIDTH}
        />
      </div>
    </div>
  );
}
