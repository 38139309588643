import { ButtonSP } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../../../util";
import styles from "./WorkflowView.module.scss";

export default function TaskAddButton(props: { onClick: () => void }) {
  const { onClick } = props;

  const tr = useTranslations();

  return (
    <ButtonSP
      isDense
      view="flat"
      color="primary"
      icon="plus"
      className={styles.new_task_button}
      onClick={onClick}
    >
      {tr.translate("task.action.new-task")}
    </ButtonSP>
  );
}
