import { API } from "@aletiq/api";
import { PartComponent } from "@aletiq/types";
import { isComponentsComplete } from "../../../services";

export default async function waitComponentsComplete(api: API, partId: number) {
  let componentsAreLoaded = false;
  let componentsAreComplete = false;
  let components: PartComponent[] = [];

  for (let i = 0; i < 10 && !componentsAreLoaded; i++) {
    const part = await api.pdm.getPart(partId);
    if (part.lastIteration.fileComponentLoaded) {
      componentsAreLoaded = true;
    } else {
      // RETRY
      await waitPromise(500);
    }
  }
  if (componentsAreLoaded) {
    for (let j = 0; j < 10 && !componentsAreComplete; j++) {
      components = await api.pdm.listAssemblyComponents(partId);
      if (!isComponentsComplete(components)) {
        // RETRY
        await waitPromise(500);
      } else {
        componentsAreComplete = true;
      }
    }
  }
  return componentsAreComplete ? components : false;
}

function waitPromise(millis: number) {
  return new Promise((resolve) => setTimeout(resolve, millis));
}
