import { Process, TaskStatus } from "@aletiq/types";
import { useMemo } from "react";
import { useUsers } from "../../../../hooks";

export type ResolvedWorkflow = Process & {
  doneTaskCount: number;
  taskCount: number;
  progress: number;
  status: TaskStatus;
  ownerName?: string;
};

export function useResolvedWorkflows(workflows: Process[]): {
  workflows: ResolvedWorkflow[];
  isLoading: boolean;
} {
  const withEmail = false;
  const withDeactivated = true;
  const { data: users = [], isLoading } = useUsers(withEmail, withDeactivated);

  const resolvedWorkflows: ResolvedWorkflow[] = useMemo(
    () =>
      workflows.map((w) => {
        const doneTaskCount = w.tasks.filter((t) => t.status === "done").length;

        const isDone = doneTaskCount === w.tasks.length;
        const isStarted = !!w.tasks.find((t) => t.status !== "not_started");
        const status = isDone ? "done" : isStarted ? "started" : "not_started";

        const ownerName = users.find((u) => u.id === w.owner)?.displayName;

        return {
          ...w,
          status,
          doneTaskCount,
          taskCount: w.tasks.length,
          progress: doneTaskCount / w.tasks.length,
          ownerName,
        };
      }),
    [workflows, users]
  );

  return {
    workflows: resolvedWorkflows,
    isLoading,
  };
}
