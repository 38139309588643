import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { documentKeys } from "./queries";

export default function useDocumentThumbnail(documentId: number) {
  const api = useApi();
  return useQuery(documentKeys.thumbnail(documentId), () =>
    api.document.getDocumentThumbnail(documentId)
  );
}
