import {
  CommentTagNotification,
  Notification,
  ProcessCompleteNotification,
  TaskDelayExpiredNotification,
  TaskUnlockedNotification,
} from "@aletiq/types";
import { Spinner } from "@blueprintjs/core";
import React from "react";
import { useNotifications } from "./hooks";
import { NoNotificationsCard } from "./NotificationCard";
import NotificationList from "./NotificationList";
import styles from "./Notifications.module.scss";
import {
  CommentTagNotificationView,
  TaskUnlockedNotificationView,
  WorkflowCompleteNotificationView,
} from "./NotificationsViews";
import TaskExpiredNotificationView from "./NotificationsViews/TaskExpiredNotificationView";

export default function Notifications(props: {
  className?: string;
  newNotificationCount: number;
  onClose: () => void;
}) {
  const { className, newNotificationCount, onClose } = props;

  const { data: notifications = [], isLoading } = useNotifications();

  return (
    <NotificationList className={className}>
      {isLoading && <Spinner className={styles.spinner} />}

      {!isLoading && notifications.length === 0 && <NoNotificationsCard />}

      {!isLoading &&
        notifications.length > 0 &&
        notifications.map((notification, index) => (
          <NotificationComponent
            key={index}
            notification={notification}
            isNew={index < newNotificationCount}
            onCloseNotifications={onClose}
          />
        ))}
    </NotificationList>
  );
}

function NotificationComponent(props: {
  notification: Notification;
  isNew: boolean;
  onCloseNotifications: () => void;
}) {
  const { notification, isNew, onCloseNotifications } = props;

  switch (notification.meta.content.type) {
    case "comment_tag": {
      return (
        <CommentTagNotificationView
          onFollowLink={onCloseNotifications}
          notification={notification as CommentTagNotification}
          isNew={isNew}
        />
      );
    }
    case "task_unlocked":
      return (
        <TaskUnlockedNotificationView
          onFollowLink={onCloseNotifications}
          notification={notification as TaskUnlockedNotification}
          isNew={isNew}
        />
      );
    case "task_delay_expired":
      return (
        <TaskExpiredNotificationView
          onFollowLink={onCloseNotifications}
          notification={notification as TaskDelayExpiredNotification}
          isNew={isNew}
        />
      );
    case "process_complete":
      return (
        <WorkflowCompleteNotificationView
          onFollowLink={onCloseNotifications}
          notification={notification as ProcessCompleteNotification}
          isNew={isNew}
        />
      );
    default:
      return null;
  }
}
