import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./NotificationList.module.scss";

export default function NotificationList(props: {
  children?: ReactNode;
  className?: string;
}) {
  const { children, className } = props;
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
}
