import { DatePicker, Input, Popover2 } from "@aletiq/design-system";
import { DateInputProps } from "@blueprintjs/datetime";
import React, { useState } from "react";
import {
  formatDate,
  jsDateFormatter,
  toDate,
  useTranslations,
} from "../../util";

export default function DateInput(
  props: Omit<DateInputProps, "onChange" | "formatDate" | "parseDate"> & {
    isDense?: boolean;
    intent?: "primary" | "default";
    fill?: boolean;
    addSpaces?: boolean;
    onChange: (newDate: Date | null) => void;
  }
) {
  const tr = useTranslations();
  const tenYearDate = new Date();
  tenYearDate.setFullYear(tenYearDate.getFullYear() + 10);

  const {
    value,
    className,
    dayPickerProps,
    isDense,
    intent = "default",
    fill,
    minDate,
    maxDate = tenYearDate,
    placeholder,
    addSpaces,
    onChange,
    ...restProps
  } = props;

  const [date, setDate] = useState<Date | undefined>(value || undefined);
  const [dateString, setDateString] = useState(
    value ? formatDate(value, { addSpaces }) : ""
  );

  const handleClickDay = (newDate: Date) => {
    setDate(newDate);
    setDateString(formatDate(newDate, { addSpaces }));
    onChange(newDate);
  };

  const handleInputDay = (newDateString: string) => {
    const newDate = toDate(newDateString);

    if (!newDate || newDateString === "") {
      return handleClearDate();
    }

    setDateString(newDateString);
    setDate(newDate);
    onChange(newDate);
  };

  const handleClearDate = () => {
    setDate(undefined);
    setDateString("");
    onChange(null);
  };

  return (
    <Popover2
      fill={fill}
      content={
        <DatePicker
          dayPickerProps={dayPickerProps}
          minDate={minDate}
          maxDate={maxDate}
          value={date}
          onChange={handleClickDay}
          {...jsDateFormatter}
          {...restProps}
        />
      }
    >
      <Input
        fill={fill}
        isDense={isDense}
        intent={intent}
        value={dateString}
        onChange={handleInputDay}
        className={className}
        placeholder={
          placeholder ||
          tr.translateAsString("task.dialog.new-task.duedate.placeholder")
        }
      />
    </Popover2>
  );
}
