import React, { useMemo, useState } from "react";
import { usePassportStates } from "../../features/passport/hooks";
import { filterByString, sortByString, useTranslations } from "../../util";
import FilterView from "./FilterView";

export default function PassortStateFilter(props: {
  selected: number[];
  noStateSelected: boolean;
  onSelect: (values?: number[]) => void;
  onSelectNoState: (value?: boolean) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const { selected, onClear, noStateSelected, onSelectNoState, onSelect } =
    props;
  const { data: states = [] } = usePassportStates();

  const [search, setSearch] = useState("");

  const sortedStates = useMemo(() => {
    const filteredStates = filterByString(states, (s) => s.value, search);
    return sortByString(filteredStates, (s) => s.value);
  }, [states, search]);

  return (
    <FilterView
      items={sortedStates.map((s) => ({ id: s.id, text: s.value }))}
      selected={selected}
      all={states.map((s) => s.id)}
      placeholder={tr.translateAsString("filter.passport-state.placeholder")}
      noSelection={noStateSelected}
      onSelect={onSelect}
      onClear={onClear}
      search={search}
      onSearch={setSearch}
      onSelectNone={onSelectNoState}
      hasSelectAll
    />
  );
}
