import { PassportQueryOrder, ProjectType, Property } from "@aletiq/types";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import {
  defaultCreatedAtColumn,
  defaultNameColumn,
  defaultPropertyColumn,
  defaultUpdatedAtColumn,
  ExtendedColumn,
} from "../../../components";
import ProductLink from "../../../components/link/ProductLink";
import { ColumnOptionConfig } from "../../../hooks";
import { extractBy, indexBy, mapRecord, useTranslations } from "../../../util";
import { useProperties } from "../../administration/Attributes/hooks";
import ProjectDefinitionLink from "../../project/ProjectDefinitionLink";
import PassportConfigCell from "../../project/ProjectPassports/PassportConfigCell";
import ArticleBillIndexLink from "../../project/Version/ArticleBillIndexLink";
import ProductVersionLink from "../../project/Version/ProductVersionLink";
import { ResolvedPassport } from "../types";

export type PassportColumn =
  | PassportQueryOrder
  | "config"
  | "parentProjectDefinition"
  | number;

export const defaultPassportColumnConfig: ColumnOptionConfig<PassportColumn> = {
  name: true,
  parentProjectId: true,
  parentProjectDefinition: true,
  clientName: true,
  stateName: true,
  projectIterationName: true,
  articleBill: true,
  config: false,
  createdAt: false,
  updatedAt: false,
};

export default function usePassportTableColumns(options: {
  projectId?: number;
  projectType?: ProjectType;
  config?: ColumnOptionConfig<PassportColumn>;
  onOpenBill?: (billId: number) => void;
}): ExtendedColumn<ResolvedPassport, PassportQueryOrder>[] {
  const {
    projectId,
    projectType,
    config = defaultPassportColumnConfig,
    onOpenBill,
  } = options;
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("passport");

  return useMemo(() => {
    const filteredProperties = properties.filter(
      (prop) => prop.group === "all" || prop.group === projectType
    );
    const propertyRecord: Record<PassportColumn, Property> = indexBy(
      filteredProperties,
      "id"
    );

    const handleOpenBill = (billId: number | null) => {
      onOpenBill && billId && onOpenBill(billId);
    };

    const propertyColumns: Record<
      PassportColumn,
      ExtendedColumn<ResolvedPassport, PassportQueryOrder>
    > = mapRecord(propertyRecord, (prop) => defaultPropertyColumn(prop));

    const columns: Record<
      PassportColumn,
      ExtendedColumn<ResolvedPassport, PassportQueryOrder>
    > = {
      name: {
        ...defaultNameColumn("id-number"),
        minWidth: 250,
      },
      parentProjectId: {
        Header: tr.translate("passport.page.title.parent-product", {
          type: projectType,
        }),
        accessor: "parentProjectId",
        Cell: (cellProps: CellProps<ResolvedPassport>) => (
          <ProductLink
            hasIcon
            product={cellProps.row.original.parentProject}
            search={cellProps.searchString}
          />
        ),
      },
      parentProjectDefinition: {
        Header: tr.translate("passport.page.title.parent-definition", {
          type: projectType,
        }),
        accessor: "parentProjectDefinition",
        canSort: false,
        Cell: (cellProps: CellProps<ResolvedPassport>) => (
          <ProjectDefinitionLink
            hasIcon
            projectId={cellProps.row.original.parentProjectId}
            definitionIdx={cellProps.row.original.parentProjectDefinition}
          />
        ),
      },
      articleBill: {
        Header: tr.translate("passport.page.title.operation-bill-index"),
        accessor: "articleBill",
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ResolvedPassport>) =>
          cellProps.row.original.articleBill ? (
            <ArticleBillIndexLink
              hasIcon
              product={cellProps.row.original.parentProjectId}
              billId={cellProps.row.original.articleBill}
              onClick={() => handleOpenBill(cellProps.row.original.articleBill)}
            />
          ) : null,
      },
      stateName: {
        Header: tr.translate("passport.page.title.state"),
        accessor: "stateName",
      },
      clientName: {
        Header: tr.translate("passport.page.title.client"),
        accessor: "clientName",
      },
      projectIterationName: {
        Header: tr.translate("passport.page.title.product-version", {
          type: projectType,
        }),
        accessor: "projectIterationName",
        Cell: (cellProps: CellProps<ResolvedPassport>) =>
          cellProps.row.original.projectIterationId ? (
            <ProductVersionLink
              product={cellProps.row.original.parentProjectId}
              version={cellProps.row.original.projectIterationId}
              search={cellProps.searchString}
              hasIcon
            />
          ) : null,
      },
      config: {
        id: "config",
        Header: tr.translate("passport.page.title.configs"),
        accessor: "config",
        canSort: false,
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ResolvedPassport>) => (
          <PassportConfigCell {...cellProps} />
        ),
      },
      createdAt: defaultCreatedAtColumn(),
      updatedAt: defaultUpdatedAtColumn(),
    };

    const overwrittenConfig: ColumnOptionConfig<PassportColumn> = {
      ...config,
      parentProjectId: config.parentProjectId && !projectId,
      articleBill: config.articleBill && projectType === "article",
      projectIterationName:
        config.projectIterationName && projectType === "tool",
    };

    return [
      ...extractBy(columns, overwrittenConfig),
      ...extractBy(propertyColumns, overwrittenConfig),
    ];
  }, [properties, tr, projectType, config, projectId, onOpenBill]);
}
