import {
  PassportQueryFilterParams,
  PassportQueryOrder,
  ProjectType,
} from "@aletiq/types";
import React, { useEffect } from "react";
import { Table } from "../../components";
import {
  useDelayed,
  useHasPermission,
  useNavigate,
  usePagination,
  usePassportId,
  useSortKey,
} from "../../hooks";
import { NoPassportFound } from "./common";
import {
  usePaginatedPassports,
  usePassportTableColumns,
  useResolvedPassports,
} from "./hooks";
import { PassportColumn } from "./hooks/usePassportTableColumns";
import PassportBatchActionHeader from "./PassportBatchActionHeader";
import { ResolvedPassport } from "./types";

export default function PaginatedPassportList(props: {
  filter: PassportQueryFilterParams;
  projectId?: number;
  projectType?: ProjectType;
  columnConfig: Record<PassportColumn, boolean>;
}) {
  const { filter, projectId, projectType, columnConfig } = props;

  const navigate = useNavigate();
  const pagination = usePagination();
  const { limit, selectedPage, resetSelectedPage } = pagination;

  const { sortState, setSortKey } = useSortKey<PassportQueryOrder>(
    "updatedAt",
    "desc"
  );

  useEffect(() => {
    resetSelectedPage();
  }, [filter, projectType, sortState, resetSelectedPage]);

  const canUpdatePassports = useHasPermission("update:passports");
  const canAttachFiles = useHasPermission("attach:passport-files");
  const activePassportId = usePassportId();

  const {
    data,
    isLoading: passportLoading,
    isFetching: passportFetching,
  } = usePaginatedPassports({
    ...filter,
    limit,
    offset: selectedPage * limit,
    order: sortState.key,
    orderDirection: sortState.direction,
    projectType: projectType,
    projectIterationName: true,
    properties: true,
  });

  const allPassports = data?.list ?? [];
  const passportCount = data?.count ?? 0;
  const { data: passports = [], isLoading: passportResolving } =
    useResolvedPassports(allPassports);

  const isLoading = passportLoading || passportResolving;
  const isFetching = useDelayed(passportFetching);

  const renderBatchActionsHeader = (
    selectedPassports: ResolvedPassport[],
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => (
    <PassportBatchActionHeader
      selectedPassports={selectedPassports}
      toggleAllRowsSelected={toggleAllRowsSelected}
      projectType={projectType}
    />
  );

  const handleOpenPassportPanel = (passport: ResolvedPassport) => {
    navigate({
      panel: `passportId-${passport.id}`,
    });
  };

  const isRowActive = (passport: ResolvedPassport) =>
    passport.id === activePassportId;

  const columns = usePassportTableColumns({
    projectId,
    projectType,
    config: columnConfig,
  });
  const canSelectRows = canUpdatePassports || canAttachFiles;
  return (
    <>
      <Table
        columns={columns}
        data={passports}
        noData={
          <NoPassportFound
            search={filter.search ?? ""}
            projectId={projectId}
            projectType={projectType}
          />
        }
        searchString={filter.search}
        isRowActive={isRowActive}
        paginationOptions={{
          itemCount: passportCount,
          limit,
          selectedPage,
          onSetLimit: pagination.handleLimit,
          onSetPage: pagination.handleSelectPage,
        }}
        renderBatchActionsHeader={
          canSelectRows ? renderBatchActionsHeader : undefined
        }
        openRowOptions={{ onOpen: handleOpenPassportPanel }}
        sortOptions={{
          sortState,
          onSort: setSortKey,
        }}
        isFetching={isFetching || isLoading}
      />
    </>
  );
}
