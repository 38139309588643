export function groupBy<
  T extends { [key in K]: V },
  K extends string,
  V extends string | number
>(collection: T[], key: K): Record<V, T[]> {
  const groupByObject = {} as Record<V, T[]>;

  collection.forEach((value: T) => {
    const keyToGroup = value[key];
    if (!groupByObject[keyToGroup]) {
      groupByObject[keyToGroup] = [];
    }

    groupByObject[keyToGroup].push(value);
  });

  return groupByObject;
}
