import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { isValidDbKey } from "../../../util/";
import { Nullable } from "../../../util/types";
import { projectQueries } from "./queries";

export default function usePartProjectBom(
  projectId: Nullable<number>,
  definitionIdx: Nullable<number>
) {
  const api = useApi();
  return useQuery({
    ...projectQueries.bomPart(api, projectId!, definitionIdx!),
    enabled: isValidDbKey(projectId) && isValidDbKey(definitionIdx),
  });
}
