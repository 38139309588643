import { ProjectType } from "@aletiq/types";
import React, { useState } from "react";
import { Button } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProject } from "../../../project/hooks";
import { PassportCreationDialog, PassportFunnel } from "../../dialogs";
import styles from "./NoPassportFound.module.scss";

export default function NoPassportFound(props: {
  search: string;
  projectId?: number;
  projectType?: ProjectType;
}) {
  const { search, projectId, projectType } = props;
  const tr = useTranslations();

  const [createPassportDialog, setCreatePassportDialog] = useState(false);

  const canCreatePassports = useHasPermission("create:passports");
  const { data: parentProject } = useProject(projectId ?? 0);

  const handleToggleDialog = () =>
    setCreatePassportDialog(!createPassportDialog);

  return (
    <>
      {tr.translate(
        search === "" ? "passport.page.no-data" : "passport.page.filter.no-data"
      )}
      <Button
        isDense
        intent="primary"
        icon="plus"
        isDisabled={!canCreatePassports}
        onClick={handleToggleDialog}
        className={styles.button}
      >
        {tr.translate("passport.page.create-passport")}
      </Button>
      {createPassportDialog && !projectId && (
        <PassportFunnel
          onClose={handleToggleDialog}
          projectType={projectType}
        />
      )}
      {createPassportDialog && projectId && parentProject && (
        <PassportCreationDialog
          isOpen
          product={parentProject}
          onClose={handleToggleDialog}
        />
      )}
    </>
  );
}
