import { Switch } from "@aletiq/design-system";
import React from "react";
import { AlignRight } from "../AlignRight";
import { ColumnOption } from "./SelectColumns";
import styles from "./SelectColumns.module.scss";

export default function SelectMandatoryColumn<
  T extends string | number
>(props: { item: ColumnOption<T> }) {
  const { item } = props;
  return (
    <div className={styles.row}>
      {item.label}
      <AlignRight />
      <Switch checked isDense isDisabled />
    </div>
  );
}
