import { ApiActivity } from "@aletiq/types";
import React from "react";
import { GenericStateTag } from "..";
import PartIterationName from "../../features/pdm/common/PartIterationName";
import { useTranslations } from "../../util";
import ActivityValue from "./ActivityValue";
import { findPart, findProduct } from "./findEntity";
import ActivityPartLink from "./links/ActivityPartLink";
import { ActivityProductLink } from "./links/ActivityProductLink";

export default function PartActivity(props: { activity: ApiActivity }) {
  const { activity } = props;
  const tr = useTranslations();

  const partLink = (id: number) => {
    const part = findPart(activity.entities, id);
    return part ? <ActivityPartLink part={part} /> : null;
  };

  switch (activity.type) {
    case "part_created":
      return tr.translate("activity.part_created", {
        part: partLink(activity.metadata.part),
      });

    case "part_revision_validated": {
      return tr.translate("activity.part_revision_validated", {
        revision: (
          <ActivityValue>
            <PartIterationName
              partId={activity.metadata.part}
              iteration={activity.metadata.revision}
            />
          </ActivityValue>
        ),
        part: partLink(activity.metadata.part),
      });
    }
    case "part_revision_marked_obsolete": {
      return tr.translate("activity.part_revision_marked_obsolete", {
        revision: (
          <ActivityValue>
            <PartIterationName
              partId={activity.metadata.part}
              iteration={activity.metadata.revision}
            />
          </ActivityValue>
        ),
        part: partLink(activity.metadata.part),
        obsolete: <GenericStateTag state="obsolete" />,
      });
    }
    case "part_revision_updated":
      return (
        <>
          {tr.translate("activity.part_revision_updated", {
            part: partLink(activity.metadata.part),
          })}
          <ul>
            <li>
              {tr.translate("activity.part_revision_updated.file", {
                file: <ActivityValue>{activity.metadata.file}</ActivityValue>,
              })}
            </li>
            <li>
              {tr.translate("activity.part_revision_updated.name", {
                name: <ActivityValue>{activity.metadata.name}</ActivityValue>,
              })}
            </li>
            {activity.metadata.description && (
              <li>
                {tr.translate("activity.part_revision_updated.description", {
                  description: (
                    <ActivityValue>
                      {activity.metadata.description}
                    </ActivityValue>
                  ),
                })}
              </li>
            )}
          </ul>
        </>
      );
    case "part_product_added": {
      const product = findProduct(activity.entities, activity.metadata.product);
      return tr.translate("activity.part_product_added", {
        product: product ? <ActivityProductLink product={product} /> : null,
        part: partLink(activity.metadata.part),
      });
    }
    case "part_product_removed": {
      const product = findProduct(activity.entities, activity.metadata.product);
      return tr.translate("activity.part_product_removed", {
        product: product ? <ActivityProductLink product={product} /> : null,
        part: partLink(activity.metadata.part),
      });
    }
    case "part_renamed":
      return tr.translate("activity.part_renamed", {
        part: partLink(activity.metadata.part),
      });
    default:
      return null;
  }
}
