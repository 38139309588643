import { ProjectVersion, ProjectVersionActionSpec } from "@aletiq/types";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useNavigate, useToaster } from "../../../hooks";
import { isNotUndefined } from "../../../util";
import VersionValidationError from "../Version/NewVersion/VersionValidationError";
import { projectKeys } from "./queries";
import { ResolvedVersionDocument } from "./useVersionDocsFilter";

export default function useCreateOrUpdateVersion(
  versionDraft: ProjectVersion | null,
  projectId: number
) {
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading, error } = useMutation<
    number,
    Error,
    {
      name: string;
      comment: string;
      isDraft?: boolean;
      actions: ProjectVersionActionSpec[];
    }
  >(async (spec) => {
    let versionId;
    if (versionDraft) {
      await api.project.updateProjectVersion(projectId, versionDraft.id, spec);
      versionId = versionDraft.id;
    } else {
      versionId = await api.project.createProjectVersion(projectId, spec);
    }
    queryClient.invalidateQueries(projectKeys.allVersions(projectId));
    return versionId;
  });

  useEffect(() => {
    if (error) {
      toaster.show({ intent: "danger", message: error["message"] });
    }
  }, [error, toaster]);

  const submit = (values: {
    name: string;
    description: string;
    allVersionDocs: ResolvedVersionDocument[];
  }) => {
    const { name, description, allVersionDocs } = values;
    const obsoleteAndDraftDocs = findDraftsAndObsoleteRevisions(allVersionDocs);
    if (obsoleteAndDraftDocs.length > 0) {
      toaster.show({
        intent: "danger",
        icon: "warning-sign",
        timeout: 0,
        message: (
          <VersionValidationError documentNames={obsoleteAndDraftDocs} />
        ),
      });
      return;
    }

    mutate(
      {
        name,
        comment: description,
        isDraft: false,
        actions: [],
      },
      {
        onSuccess: (versionId) =>
          navigate({
            path: `/project/${projectId}/versions/${versionId}`,
          }),
      }
    );
  };

  return {
    mutate,
    submit,
    isLoading,
    error,
  };
}

//new or updated documents must have approved revisions when submitting version
const findDraftsAndObsoleteRevisions = (
  allVersionDocs: ResolvedVersionDocument[]
) =>
  allVersionDocs
    .filter(
      (versionDoc) =>
        versionDoc.revisionInfo?.state !== "approved" &&
        !versionDoc.deleted &&
        !versionDoc.doc?.isDeleted
    )
    .map((versionDoc) => versionDoc.name)
    .filter(isNotUndefined);
