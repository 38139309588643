import {
  AlertPopoverBase,
  AlertPopoverContent,
  AlertPopoverHeader,
  ButtonSP,
  TagSP,
} from "@aletiq/design-system";
import { Property, PropertyFilter } from "@aletiq/types";
import React, { ReactNode, useMemo } from "react";
import { HandleClear } from "../../hooks";
import { useTranslations } from "../../util";
import styles from "./Filter.module.scss";

export default function MoreFilters<
  T extends Object & Partial<{ filters: PropertyFilter[] }>
>(props: {
  filterValues: T;
  additionalFilters: (keyof T)[];
  onClear: HandleClear<T>;
  intent?: "default" | "primary";
  isDisabled?: boolean;
  children: ReactNode;
  customProperties?: Property[];
}) {
  const {
    children,
    filterValues,
    additionalFilters = [],
    onClear,
    intent = "primary",
    isDisabled,
    customProperties = [],
  } = props;
  const tr = useTranslations();

  const handleClear = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    onClear(additionalFilters)();
  };

  const propertiesDisplayedByDefault = useMemo(
    () => customProperties.filter((p) => p.displayByDefault).map((p) => p.id),
    [customProperties]
  );

  const activeAdditionalFilters: number = useMemo(() => {
    const additionalFilterCount = additionalFilters
      .map((key) => {
        const value = filterValues[key];
        if (
          !value ||
          key === "filters" ||
          (Array.isArray(value) && value.length === 0)
        ) {
          return 0;
        }
        return 1;
      })
      .reduce((sum: number, current) => sum + current, 0);

    // properties displayed by default do not have their filters in the MoreFilters component
    const customFilterCount =
      filterValues.filters
        ?.map((property) =>
          propertiesDisplayedByDefault.includes(property.id) ? 0 : 1
        )
        .reduce((sum: number, current) => sum + current, 0) ?? 0;

    if (customFilterCount === 0) {
      return additionalFilterCount;
    }

    return additionalFilterCount + customFilterCount;
  }, [additionalFilters, filterValues, propertiesDisplayedByDefault]);

  return (
    <AlertPopoverBase
      isDense
      intent={intent}
      disabled={isDisabled}
      position="bottom-left"
      content={
        <>
          <AlertPopoverHeader
            icon="filter-list"
            isDense
            title={tr.translate("filter.more-filters.title")}
          />

          <AlertPopoverContent>
            <div className={styles.more_filters_description}>
              {tr.translate("filter.more-filters.description")}
            </div>
            <div className={styles.more_filters_list}>{children}</div>
          </AlertPopoverContent>
        </>
      }
    >
      <ButtonSP
        view="flat"
        color={intent}
        isDisabled={isDisabled}
        className={styles.filter_button}
        rightIcon="caret-down"
        icon="filter-list"
        isDense
      >
        {tr.translate("filter.more-filters.title")}
        {activeAdditionalFilters > 0 && (
          <TagSP
            unclickable
            isDense
            isRemovable
            color={intent}
            view="filled"
            onRemove={handleClear}
            className={styles.selectPart}
          >
            {activeAdditionalFilters}
          </TagSP>
        )}
      </ButtonSP>
    </AlertPopoverBase>
  );
}
