import { API } from "@aletiq/api";
import { ProjectDefinitionId } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { ProductComponentTree } from "./types";

async function fetchSubTree(
  api: API,
  parent: ProjectDefinitionId | null,
  productId: number,
  definitionIdx: number
): Promise<ProductComponentTree> {
  const child = { productId, definitionIdx };
  const product = await api.project.getProject(productId);
  const definition = await api.project.getProjectDefinition(
    productId,
    definitionIdx
  );
  const documents = await api.project.listProjectDefinitionDocument(
    productId,
    definitionIdx
  );
  const part = await api.pdm.getPartProjectBom(productId, definitionIdx);
  const bom = await api.project.getProjectBOM(productId, definitionIdx);
  const subRows = await Promise.all(
    bom.map((c) => fetchSubTree(api, child, c.entity, c.definition))
  );
  return {
    parent,
    ...(part ?? {}),
    product,
    definition,
    subRows,
    documents,
  };
}

export default function useProjectTree(
  productId: number,
  definitionIdx: number
) {
  const api = useApi();
  return useQuery(
    ["projects", productId, "definitions", definitionIdx, "tree"],
    async () => fetchSubTree(api, null, productId, definitionIdx)
  );
}
