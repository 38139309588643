import { Project } from "@aletiq/types";
import { TreeNodeInfo } from "@blueprintjs/core";
import React from "react";
import { PartIcon, Tree } from "../../../../components";
import TreeItemName from "../../../../components/name/TreeItemName";
import { isPartParent } from "../../../pdm/services";
import { hasPart, ProductTree } from "./partProjectBomUtils";

export default function EditPartTree(props: {
  tree: ProductTree;
  movedProduct?: Project;
  isExpanded: (pathId: string) => boolean;
  onExpand: (pathId: string) => void;
  onToggleExpandNode: (node: TreeNodeInfo<{ onExpand?: () => void }>) => void;
}) {
  const { tree, movedProduct, isExpanded, onExpand, onToggleExpandNode } =
    props;

  const isHighlighted = movedProduct !== undefined;

  function makePartNodeInfos(
    tree: ProductTree
  ): TreeNodeInfo<{ onExpand?: () => void }> {
    const { part, product, components, pathId } = tree;
    const isKnown = product !== null;
    const isHighlighted = isKnown && movedProduct !== undefined;

    return {
      id: pathId,
      hasCaret: isPartParent(part!.type) && isKnown,
      isExpanded: isExpanded(pathId),
      label: <TreeItemName name={part?.name} isHighlighted={isHighlighted} />,
      icon: (
        <PartIcon
          inline
          color={!isHighlighted ? "var(--blue100)" : undefined}
          intent={isHighlighted ? "primary" : "none"}
          isStandard={part!.isStandard}
          type={part!.type}
        />
      ),
      nodeData: {
        onExpand: () => onExpand(pathId),
      },
      childNodes: isKnown
        ? components.filter(hasPart).map(makePartNodeInfos)
        : [],
    };
  }

  return (
    <Tree
      onNodeExpand={onToggleExpandNode}
      onNodeCollapse={onToggleExpandNode}
      contents={[
        {
          isExpanded: true,
          hasCaret: false,
          id: tree.pathId,
          label: (
            <TreeItemName
              name={tree.part?.name}
              isHighlighted={isHighlighted}
            />
          ),
          icon: (
            <PartIcon
              inline
              color={!isHighlighted ? "var(--blue100)" : undefined}
              intent={isHighlighted ? "primary" : "none"}
              isStandard={tree.part!.isStandard}
              type={tree.part!.type}
            />
          ),
          childNodes: tree.components.filter(hasPart).map(makePartNodeInfos),
        },
      ]}
    />
  );
}
