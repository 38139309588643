import { Project, SortState } from "@aletiq/types";
import React from "react";
import { Table } from "../../components";
import { useSort } from "../../hooks";
import { useAccessSearchResult } from "../search/hooks";
import { useProductColumns } from "./hooks";
import {
  ProductColumn,
  useDefaultProductColumnConfig,
} from "./hooks/useProductColumns";

export default function ProductList(props: {
  products: Project[];
  searchString: string;
}) {
  const { products, searchString } = props;

  const {
    sortKey: key,
    sortDirection: direction,
    onSortKeyChange: onSort,
    sortedList: sortedProjects,
  } = useSort(products, "updatedAt", "desc");

  const { mutate: accessSearchResult } = useAccessSearchResult();

  const handleOpenProductResult = (productId: number) =>
    accessSearchResult({ type: "project", id: productId });

  const { config, isLoading } = useDefaultProductColumnConfig();
  const columns = useProductColumns({
    onOpenProduct: handleOpenProductResult,
    config,
  });

  return (
    <Table
      searchString={searchString}
      columns={columns}
      data={sortedProjects}
      isFetching={isLoading}
      sortOptions={{
        sortState: { key, direction } as SortState<ProductColumn>,
        onSort: onSort as React.Dispatch<ProductColumn>,
      }}
    />
  );
}
