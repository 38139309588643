import { Part, PartType } from "@aletiq/types";
import { TreeNodeInfo } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  DialogBase,
  DialogContent,
  DialogHeader,
  TreeContent,
  TreeHeader,
  TreeTitle,
} from "../../../../components";
import { makeFlatIds, togglePathId, useTranslations } from "../../../../util";
import { PartCreationDialog } from "../../../pdm/dialogs";
import EditCadTree from "./EditCadTree";
import EditPartTree from "./EditPartTree";
import usePartIterationTree from "./hooks/usePartIterationTree";
import useUpdatePartComponents from "./hooks/useUpdatePartComponents";
import {
  PartBomAddition,
  PartBomDeletion,
  PartBomIgnore,
  PartMutation,
} from "./partBomUtils";

export type PartCreationDialogState =
  | { isOpen: false }
  | {
      isOpen: true;
      type: PartType;
      isStandard: boolean;
      parentId: number;
      name: string;
      pathId: string;
    };

export default function EditPartBomDialog(props: {
  partId: number;
  iteration?: number;
  onClose: () => void;
}) {
  const { partId, iteration, onClose } = props;

  const tr = useTranslations();

  const [creationDialog, setCreationDialog] = useState<PartCreationDialogState>(
    {
      isOpen: false,
    }
  );

  const [mutatedPathId, setMutatedPathId] = useState<string | undefined>();
  const [expanded, setExpanded] = useState<string[]>([]);

  const expandedIds = makeFlatIds(expanded);

  const {
    data: tree,
    isFetching: isFetchingBom,
    isLoading: isLoadingBom,
  } = usePartIterationTree(
    partId,
    expandedIds,
    true,
    () => setMutatedPathId(undefined),
    iteration
  );

  const { mutate: updatePartComponents, isLoading: isUpdatingBom } =
    useUpdatePartComponents();

  const isFetching = isUpdatingBom || isFetchingBom;
  const fetchingPathId = isFetching ? mutatedPathId : undefined;

  const handleCloseCreationDialog = () => {
    setCreationDialog({ isOpen: false });
  };

  const handleOpenCreateDialog = (
    pathId: string,
    type: PartType,
    isStandard: boolean,
    parentId: number,
    name: string
  ) => {
    setCreationDialog({
      isOpen: true,
      type,
      isStandard,
      parentId,
      name,
      pathId,
    });
  };

  const handleExpand = (pathId: string): void => {
    setExpanded((expanded) => togglePathId(pathId, expanded));
  };

  const handleAssign = (pathId: string, addition: PartBomAddition) => {
    makeMutations(pathId, { addition });
  };

  const handleDelete = (pathId: string, deletion: PartBomDeletion) => {
    makeMutations(pathId, { deletion });
  };

  const handleIgnore = (pathId: string, ignore: PartBomIgnore) => {
    makeMutations(pathId, { ignore });
  };

  const makeMutations = (pathId: string, mutation: PartMutation) => {
    const { addition, deletion, ignore } = mutation;
    const mutations = {
      deletions: deletion ? [deletion] : [],
      additions: addition ? [addition] : [],
      ignores: ignore ? [ignore] : [],
    };
    setMutatedPathId(pathId);
    updatePartComponents(mutations);
  };

  const handleSuccessCreateComponent = (part: Part) => {
    if (!creationDialog.isOpen) {
      return;
    }
    const addition = {
      partId: part.id,
      parentId: creationDialog.parentId,
      name: creationDialog.name,
      iteration: part.lastIteration.number,
    };
    makeMutations(creationDialog.pathId, { addition });
    setCreationDialog({ isOpen: false });
  };

  const handleToggleExpandNode = (
    node: TreeNodeInfo<{ onExpand?: () => void }>
  ) => {
    node.nodeData?.onExpand && node.nodeData.onExpand();
  };

  function isExpanded(pathId: string) {
    return expanded.includes(pathId);
  }

  return (
    <DialogBase isOpen isFullPage onClose={onClose}>
      <DialogHeader
        icon="diagram-tree"
        title={tr.translateAsString("cad.bom.edition.title")}
        onClose={onClose}
      />
      <DialogContent>
        <div>
          <TreeHeader>
            <TreeTitle>{tr.translate("cad.bom.edition.bom.cad")}</TreeTitle>
            <TreeTitle>{tr.translate("cad.bom.edition.bom.parts")}</TreeTitle>
          </TreeHeader>

          <TreeContent isFetching={isLoadingBom}>
            {tree && (
              <>
                <div>
                  <EditCadTree
                    tree={tree}
                    isExpanded={isExpanded}
                    onExpand={handleExpand}
                    onToggleExpandNode={handleToggleExpandNode}
                  />
                </div>
                <div>
                  <EditPartTree
                    tree={tree}
                    isExpanded={isExpanded}
                    onExpand={handleExpand}
                    fetchingPathId={fetchingPathId}
                    onToggleExpandNode={handleToggleExpandNode}
                    onAssign={handleAssign}
                    onCreate={handleOpenCreateDialog}
                    onDelete={handleDelete}
                    onIgnore={handleIgnore}
                  />
                </div>
              </>
            )}
          </TreeContent>
        </div>

        {creationDialog.isOpen && (
          <PartCreationDialog
            type={creationDialog.type}
            isStandard={creationDialog.isStandard}
            onSuccess={handleSuccessCreateComponent}
            onClose={handleCloseCreationDialog}
          />
        )}
      </DialogContent>
    </DialogBase>
  );
}
