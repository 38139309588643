import { Icon } from "@aletiq/design-system";
import React from "react";

export default function ExpandIcon(props: {
  hasExpand?: boolean;
  isExpanded?: boolean;
  onExpand?: () => void;
}) {
  const { hasExpand, isExpanded, onExpand } = props;
  return (
    <Icon
      inline
      clickable={hasExpand}
      onClick={onExpand}
      icon={
        !hasExpand ? "blank" : isExpanded ? "chevron-down" : "chevron-right"
      }
    />
  );
}
