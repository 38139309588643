import React from "react";
import { CustomIcon, CustomIconProps } from "./CustomIcon";

const paths = [
  "M16.1936 4.38753L9.9999 8.25863L3.80615 4.38753L9.50376 1.13176C9.81119 0.956081 10.1886 0.956081 10.496 1.13176L16.1936 4.38753Z",
  "M18.4438 7.09766C18.4805 7.20234 18.5 7.31389 18.5 7.42823V14.2676C18.5 14.6264 18.3077 14.9578 17.9961 15.1358L12 18.5622V11.125L18.4438 7.09766Z",
  "M1.5562 7.09766L8 11.125V18.5622L2.00386 15.1358C1.69229 14.9578 1.5 14.6264 1.5 14.2676V7.42823C1.5 7.31389 1.51952 7.20234 1.5562 7.09766Z",
];

export default function ExplodeIcon(props: CustomIconProps) {
  return (
    <CustomIcon
      paths={paths}
      iconName="explode"
      {...props}
      pixelGridSize={20}
    />
  );
}
