import { ApiActivity, Comment } from "@aletiq/types";
import { NonIdealState, Spinner, SpinnerSize } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import {
  ActivityContent,
  AlignRight,
  Button,
  Card,
  CleanerReactMarkdown,
  H3,
  ItemSelect,
  SearchInput,
  Select,
} from "../../../components";
import FeedCard from "../../../components/activities/FeedCard";
import ActivityEntityLink from "../../../components/activities/links/ActivityEntityLink";
import {
  regexSanitize,
  sortByDateString,
  useTranslations,
} from "../../../util";
import useInfiniteActivities from "../hooks/useActivity";
import useInfiniteComments from "../hooks/useComments";
import styles from "./HomeActivity.module.scss";

type Filter = "all" | "activity" | "comment";
type FeedItem =
  | { type: "activity"; date: string; activity: ApiActivity }
  | { type: "comment"; date: string; comment: Comment };

export default function HomeActivity(props: { className?: string }) {
  const { className } = props;
  const tr = useTranslations();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<Filter>("all");
  const listRef = useRef<HTMLDivElement>(null);

  const {
    data: dataActivities,
    isLoading: isLoadingActivity,
    fetchNextPage: fetchNextActivities,
    isFetchingNextPage: isFetchingNextActivities,
  } = useInfiniteActivities();
  const {
    data: dataComments,
    isLoading: isLoadingComments,
    fetchNextPage: fetchNextComments,
    isFetchingNextPage: isFetchingNextComments,
  } = useInfiniteComments();

  const isLoading =
    isLoadingActivity ||
    isLoadingComments ||
    isFetchingNextActivities ||
    isFetchingNextComments;

  const feedItems: FeedItem[] = [
    ...(dataActivities?.pages ?? [])
      .flatMap((res) => res.list)
      .map<FeedItem>((activity) => ({
        type: "activity",
        date: activity.time,
        activity,
      })),
    ...(dataComments?.pages ?? [])
      .flatMap((res) => res.list)
      .map<FeedItem>((comment) => ({
        type: "comment",
        date: comment.date,
        comment,
      })),
  ].filter((item) => {
    if (filter === "all") {
      return true;
    }

    return item.type === filter;
  });

  const sortedFeedItems = sortByDateString(
    feedItems,
    (item) => item.date,
    "desc"
  );

  const lastItem: FeedItem | undefined =
    sortedFeedItems[sortedFeedItems.length - 1];

  let triggerFetchPercent = 1;
  if (lastItem) {
    const reversedItems = [...sortedFeedItems].reverse();

    const lastIndex = reversedItems.findIndex(
      (item) => item.type !== lastItem?.type
    );

    triggerFetchPercent =
      lastIndex !== -1 ? 1 - lastIndex / sortedFeedItems.length : 1;
  }

  useEffect(() => {
    const onScroll = () => {
      const containerHeight = listRef?.current?.scrollHeight ?? 0;
      const containerOffset = listRef?.current?.scrollTop ?? 0;
      const cursorSize = (listRef?.current?.offsetHeight ?? 0) + 200;

      if (isLoading) return;

      if (
        containerHeight * triggerFetchPercent <
        containerOffset + cursorSize
      ) {
        fetchNextActivities();
        fetchNextComments();
      }
    };

    const list = listRef?.current;
    if (!list) return;

    list.addEventListener("scroll", onScroll);
    return () => {
      list.removeEventListener("scroll", onScroll);
    };
  }, [
    listRef,
    isLoading,
    fetchNextActivities,
    fetchNextComments,
    triggerFetchPercent,
  ]);

  const selectOptions: ItemSelect<Filter>[] = [
    {
      key: "all",
      text: tr.translateAsString("home.activites.all"),
    },
    {
      key: "activity",
      text: tr.translateAsString("home.activities.activities"),
    },
    {
      key: "comment",
      text: tr.translateAsString("home.activities.comments"),
    },
  ];

  const selectedOptionText = selectOptions.find(
    (option) => option.key === filter
  )?.text;

  const getKey = (feedItem: FeedItem) => {
    if (feedItem.type === "activity") return `activity-${feedItem.activity.id}`;
    if (feedItem.type === "comment") return `comment-${feedItem.comment.id}`;
  };

  return (
    <div className={className}>
      <div className={styles.activity_header}>
        <H3>{tr.translate("home.activities.title")}</H3>
        <AlignRight />
        <Select
          items={selectOptions}
          onItemSelect={setFilter}
          activeItem={filter}
          isDense
          intent="outlined"
        >
          <Button intent="outlined" rightIcon="caret-down" isDense>
            {selectedOptionText || tr.translate("home.activites.all")}
          </Button>
        </Select>
        <SearchInput
          value={search}
          onChange={setSearch}
          className={styles.search}
          isDense
        />
      </div>
      {(isLoading || sortedFeedItems.length > 0) && (
        <div className={styles.activityList} ref={listRef}>
          {sortedFeedItems.map((feedItem) => (
            <FeedItemComponent
              feedItem={feedItem}
              key={getKey(feedItem)}
              search={search}
            />
          ))}
          {isLoading && <Spinner size={SpinnerSize.SMALL} />}
        </div>
      )}
      {!isLoading && sortedFeedItems.length === 0 && (
        <Card className={styles.activityList}>
          <NonIdealState
            icon={<img src="/assets/no-activity.svg" height={97} alt="bell" />}
            description={tr.translateAsString("home.activities.none")}
          />
        </Card>
      )}
    </div>
  );
}

const FeedItemComponent = React.memo(
  (props: { feedItem: FeedItem; search: string }) => {
    const { feedItem, search } = props;
    const tr = useTranslations();

    let isHidden = false;

    const time = feedItem.date;

    const user =
      feedItem.type === "activity"
        ? feedItem.activity.user
        : feedItem.comment.user;

    const myRef = useRef<HTMLDivElement>(null);

    if (search !== "") {
      const componentInHTML = myRef?.current?.outerHTML?.toLowerCase() ?? "";

      // regex that only look inside the content of DOM and not it's property
      const regex = new RegExp(
        `>[^>]*${regexSanitize(search.toLowerCase())}[^>]*<`
      );

      if (!regex.exec(componentInHTML)) {
        isHidden = true;
      }
    }

    const wrapperClassName = classNames({
      [styles.activityWrapper]: true,
      [styles.activityWrapper__hidden]: isHidden,
    });

    return (
      <div className={wrapperClassName} ref={myRef}>
        {feedItem.type === "activity" && (
          <FeedCard user={user} date={time}>
            <ActivityContent activity={feedItem.activity} />
          </FeedCard>
        )}
        {feedItem.type === "comment" && (
          <FeedCard user={user} date={time}>
            <CleanerReactMarkdown source={feedItem.comment.content} />
            <div className={styles.comment_link}>
              {tr.translate("home.activities.comments.in", {
                link: <ActivityEntityLink entity={feedItem.comment.entity} />,
              })}
            </div>
          </FeedCard>
        )}
      </div>
    );
  },
  (prev, current) => {
    if (prev.search !== current.search) return false;

    if (prev.feedItem.type !== current.feedItem.type) return false;
    if (prev.feedItem.date !== current.feedItem.date) return false;

    if (
      prev.feedItem.type === "activity" &&
      current.feedItem.type === "activity"
    )
      return prev.feedItem.activity === current.feedItem.activity;

    if (prev.feedItem.type === "comment" && current.feedItem.type === "comment")
      return prev.feedItem.comment === current.feedItem.comment;

    return false;
  }
);
