import { Icon } from "@blueprintjs/core";
import React from "react";
import { useTenant } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import ShareDocumentsRow from "./ShareDocumentsRow";

export default function PublicRow(props: {
  count: number;
  total: number;
  onAddAll: () => void;
  onRemoveAll: () => void;
}) {
  const { count, total, onAddAll, onRemoveAll } = props;
  const tr = useTranslations();

  const { data: tenant } = useTenant();

  return (
    <ShareDocumentsRow
      onAddAll={onAddAll}
      onRemoveAll={onRemoveAll}
      icon={<Icon icon="office" iconSize={20} />}
      title={tenant?.name ?? ""}
      count={count}
      total={total}
      deleteAlertText={tr.translateAsString(
        "document.dialog.shared-documents.remove-group"
      )}
    />
  );
}
