import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { documentQueries } from "./queries";

export default function useDocumentAccess(documentId: number | null) {
  const api = useApi();
  return useQuery({
    ...documentQueries.access(api, documentId!),
    enabled: documentId !== null,
  });
}
