import React, { useCallback } from "react";
import useApi from "../../../../app/useApi";
import { DownloadRequest, Table } from "../../../../components";
import { useSort } from "../../../../hooks";
import { FilteredVersionDoc } from "../../hooks";
import { useVersionDocsColumnConfig } from "../useVersionDocsColumnConfig";
import useVersionDocsColumns from "./useVersionDocColumns";

export default function VersionDocuments(props: {
  documents: FilteredVersionDoc[];
  onDownload: (req: DownloadRequest) => void;
  setPreview: (documentId: number, revisionId: number) => void;
  search?: string;
}) {
  const { documents, onDownload, setPreview, search } = props;

  const {
    sortKey,
    sortDirection,
    onSortKeyChange,
    sortedList: sortedDocs,
  } = useSort<FilteredVersionDoc>(documents, "name");

  const api = useApi();

  const handleDownload = useCallback(
    (documentId: number, revision: number) =>
      onDownload({
        fetchToken: () =>
          api.document.getDocumentDownloadToken(documentId, revision),
        forceDownload: true,
      }),
    [api, onDownload]
  );

  const { config: defaultConfig } = useVersionDocsColumnConfig();
  const columns = useVersionDocsColumns(
    handleDownload,
    setPreview,
    defaultConfig
  );

  return (
    <Table
      data={sortedDocs}
      columns={columns}
      searchString={search}
      sortOptions={{
        sortState: {
          key: sortKey,
          direction: sortDirection,
        },
        onSort: onSortKeyChange,
      }}
    />
  );
}
