import { PropertyValue } from "@aletiq/types";
import React from "react";
import {
  EditCustomPropertyField,
  Field,
  Input,
  Select,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import useClients from "../../../clients/hooks/useClients";
import { usePassportStates } from "../../hooks";
import { PassportProperty } from "./EditPassportsPropertiesDialog";

export default function EditedPassportProperty(props: {
  property: PassportProperty;
  onSetProperty: (property: PassportProperty) => void;
  label: string;
}) {
  const { property, onSetProperty, label } = props;
  const tr = useTranslations();

  const { data: clients = [] } = useClients();
  const { data: states = [] } = usePassportStates();

  const handleSetCustomProperty = (value: PropertyValue) => {
    if (property && property.type === "custom") {
      onSetProperty({ ...property, value });
    }
  };

  const handleSetDescription = (value: string) => {
    if (property.type === "description") {
      onSetProperty({ ...property, value });
    }
  };

  const handleSetClientOrState = (value: number) => {
    if (property.type === "state" || property.type === "client") {
      onSetProperty({ ...property, value });
    }
  };

  return (
    <Field label={label}>
      {property?.type === "description" && (
        <Input
          value={property.value || ""}
          onChange={handleSetDescription}
          fill
        />
      )}

      {property.type === "client" && (
        <Select
          isFill
          filterable
          activeItem={property.value ?? undefined}
          items={clients.map((c) => ({ key: c.id, text: c.name }))}
          onItemSelect={handleSetClientOrState}
          intent="outlined"
        >
          {clients.find((c) => c.id === property.value)?.name ||
            tr.translateAsString("generic.label.none")}
        </Select>
      )}

      {property.type === "state" && (
        <Select
          isFill
          filterable
          activeItem={property.value ?? undefined}
          items={states.map((c) => ({ key: c.id, text: c.value }))}
          onItemSelect={handleSetClientOrState}
          intent="outlined"
        >
          {states.find((c) => c.id === property.value)?.value ||
            tr.translateAsString("generic.label.none")}
        </Select>
      )}

      {property.type === "custom" && (
        <EditCustomPropertyField
          propertyValue={property.value}
          onEditValue={handleSetCustomProperty}
          property={property.property}
        />
      )}
    </Field>
  );
}
