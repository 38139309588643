import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { pdmKeys } from "./queries";

export default function useUpdatePartDescription() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    (variables: { partId: number; description: string }) =>
      api.pdm.updatePartDescription(variables.partId, variables.description),
    {
      onSettled: () => queryClient.invalidateQueries(pdmKeys.all),
      onError: () => {
        toaster.show({
          intent: "warning",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.part.update-description"),
        });
      },
    }
  );
}
