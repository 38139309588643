import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { Dialog, ExtendedColumn, Table } from "../../../../components";
import { useTranslations } from "../../../../util";
import {
  useUpdateComponentDefinition,
  useValidateProjectsDefinitions,
} from "../../hooks";
import {
  ProductComponentTree,
  useProjectTree,
} from "../../ProjectNomenclature/hooks";
import styles from "./ApproveNomenclatureDialog.module.scss";
import ComponentDefinitionCell from "./ComponentDefinitionCell";
import ComponentDefinitionSelectPopover from "./ComponentDefinitionSelectPopover";
import ProjectNameCell from "./ProjectNameCell";
import SelectedComponentAction from "./SelectedComponentAction";
import useComponentsToApprove from "./useComponentsToApprove";

export default function ApproveNomenclatureDialog(props: {
  projectId: number;
  definitionIdx: number;
  onClose: () => void;
}) {
  const { projectId, definitionIdx, onClose } = props;
  const tr = useTranslations();

  const { data: tree, isLoading } = useProjectTree(projectId, definitionIdx);

  const { components: componentsToApprove, canApprove } =
    useComponentsToApprove(tree);

  const { mutate: approveComponents, isLoading: isSubmitting } =
    useValidateProjectsDefinitions();

  const { mutate: updateDefinition } = useUpdateComponentDefinition(
    projectId,
    definitionIdx
  );

  const handleSubmit = () => {
    approveComponents(
      componentsToApprove.map((c) => ({
        productId: c.product.id,
        definitionIdx: c.definition.index,
      }))
    );
    onClose();
  };

  const columns: ExtendedColumn<ProductComponentTree>[] = useMemo(() => {
    const handleSelect = (tree: ProductComponentTree, definition: number) => {
      if (!tree.parent) {
        return;
      }
      updateDefinition({
        parent: tree.parent,
        componentId: tree.product.id,
        definition,
      });
    };

    return [
      {
        id: "name",
        Header: tr.translate("generic.label.name"),
        canSort: false,
        Cell: (cellProps: CellProps<ProductComponentTree>) => (
          <ProjectNameCell
            {...cellProps}
            {...cellProps.row.getToggleRowExpandedProps()}
          />
        ),
      },
      {
        id: "definition",
        Header: tr.translate("project.definition.index"),
        canSort: false,
        accessor: (c) => c.definition?.name,
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ProductComponentTree>) => (
          <ComponentDefinitionCell {...cellProps} />
        ),
        hoverButton: (component, className?: string) =>
          component.parent ? (
            <ComponentDefinitionSelectPopover
              tree={component}
              className={className}
              onSelect={handleSelect}
            />
          ) : (
            <></>
          ),
      },
      {
        id: "action",
        Header: tr.translate("generic.label.selected-action"),
        canSort: false,
        width: 80,
        minWidth: 80,
        Cell: (cellProps: CellProps<ProductComponentTree>) => (
          <SelectedComponentAction tree={cellProps.row.original} />
        ),
      },
    ];
  }, [tr, updateDefinition]);

  const tableData = useMemo(() => (tree ? [tree] : []), [tree]);

  return (
    <Dialog
      isFullPage
      isOpen
      onClose={onClose}
      onSecondaryClick={onClose}
      className={styles.dialog}
      icon="tick"
      title={tr.translateAsString("project.definition.validate.title")}
      disablePrimary={!canApprove}
      submitting={isSubmitting}
      onPrimaryClick={handleSubmit}
    >
      <Table
        isFetching={isLoading}
        data={tableData}
        columns={columns}
        className={styles.table}
        initialState={{ expandAll: true }}
      />
    </Dialog>
  );
}
