import { AnchorButton, Tooltip } from "@aletiq/design-system";
import React from "react";
import usePreviousPanel from "../../hooks/navigation/usePreviousPanel";
import { useTranslations } from "../../util";

export default function PanelBackButton() {
  const { hasPrevious, handleGoBack } = usePreviousPanel();
  const tr = useTranslations();

  return (
    <Tooltip
      position="top-right"
      content={tr.translateAsString("panel.action.previous")}
    >
      <AnchorButton
        isDense
        icon="arrow-left"
        isDisabled={!hasPrevious}
        onClick={handleGoBack}
      />
    </Tooltip>
  );
}
