import React from "react";
import { PageContents, PageTitle } from "../../components";
import { useTranslations } from "../../util";
import { ANALYTICS_VISITED_WORKFLOWS, useTrackEvent } from "../../analytics";
import ActiveWorkflows from "../tasks/ActiveWorkflows";

export default function WorkflowsPage() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_WORKFLOWS);

  return (
    <PageContents domTitle={tr.translateAsString("page.title.workflow")}>
      <PageTitle icon="flow-linear">
        {tr.translate("sidebar.processes")}
      </PageTitle>
      <ActiveWorkflows />
    </PageContents>
  );
}
