import { Part, PartIteration } from "@aletiq/types";
import React, { useState } from "react";
import {
  AlignRight,
  ChangeRevisionEditButton,
  GenericStateTag,
} from "../../../../components";
import {
  RevisionCard,
  RevisionCardHeader,
  RevisionEditableDescription,
  RevisionEditableName,
  RevisionInfos,
} from "../../../../components/revisions";
import { isAlreadyUsedName } from "../../../../util";
import { DeletePartDraftDialog } from "../../dialogs";
import {
  useAssemblyIterationComponents,
  useMakePartRevisionObsolete,
  usePartIterationViewFiles,
  useSavePartRevision,
  useUpdatePartRevisionSpec,
} from "../../hooks";
import partRevisionNotification from "../../partRevisionNotification";
import { isComponentsComplete } from "../../services";
import IterationButtonGroup from "./IterationButtonGroup";

export default function DraftPartIterationItem(props: {
  iteration: PartIteration;
  approvedIterationsNames: string[];
  onDownload: () => void;
  onView: () => void;
  onSaveAssemblyIteration: () => void;
  onShowNomenclature: () => void;
  onExportAsStep: () => void;
  onCompleteNomenclature: () => void;
  part: Part;
  hasLock: boolean;
  hasComponents: boolean;
  hasOtherRevisions: boolean;
  isEditable?: boolean;
}) {
  const {
    part,
    iteration,
    onDownload,
    onView,
    hasLock,
    hasComponents,
    hasOtherRevisions,
    onSaveAssemblyIteration,
    onShowNomenclature,
    onCompleteNomenclature,
    onExportAsStep,
    approvedIterationsNames,
    isEditable,
  } = props;

  const [editedName, setEditedName] = useState(iteration.name);
  const [showDeleteDraftDialog, setShowDeleteDraftDialog] = useState(false);

  const { mutate: updatePartDraftSpec } = useUpdatePartRevisionSpec();
  const { mutate: approveRevisionDraft } = useSavePartRevision();

  const { mutate: makeRevisionObsolete } = useMakePartRevisionObsolete(
    part.id,
    iteration.number
  );

  const handleToggleDialog = () => {
    setShowDeleteDraftDialog(!showDeleteDraftDialog);
  };

  const handleUpdateRevisionName = () => {
    updatePartDraftSpec({
      partId: part.id,
      spec: {
        name: editedName,
        description: iteration.description,
      },
    });
  };

  const handleUpdateRevisionDescription = (description: string) => {
    updatePartDraftSpec({
      partId: part.id,
      spec: {
        name: iteration.name,
        description,
      },
    });
  };

  const handleApproveRevision = () => {
    if (hasComponents) {
      onSaveAssemblyIteration();
      return;
    }
    approveRevisionDraft({
      partId: part.id,
      revisionSpec: {
        name: iteration.name,
        description: iteration.description,
      },
    });
  };

  const handleNomenclatureDialog = () => {
    if (hasCompleteBom) {
      return onShowNomenclature();
    }
    return onCompleteNomenclature();
  };

  const isDraft = iteration.state === "draft";
  const isNameInUse = isAlreadyUsedName(editedName, approvedIterationsNames);

  const { data: components = [] } = useAssemblyIterationComponents(
    part.id,
    iteration.number,
    hasComponents
  );

  const { data: viewFiles = [] } = usePartIterationViewFiles(
    part.id,
    iteration.number
  );

  const hasCompleteBom = isComponentsComplete(components);
  const canView = viewFiles.length > 0;

  //to approve the revision, part must be unlocked & have a unique revision name
  const canChange = isDraft
    ? isEditable && !hasLock && !isNameInUse && hasCompleteBom
    : isEditable;

  const isNomenclatureEnabled = hasCompleteBom
    ? hasComponents && iteration.fileComponentLoaded
    : isEditable && hasComponents && !hasLock;

  const notification = partRevisionNotification({
    isIncomplete: !hasCompleteBom,
  });

  return (
    <>
      <RevisionCard>
        <RevisionCardHeader>
          <RevisionEditableName
            isEditable={isEditable && isDraft}
            isAlreadyUsed={isDraft && isNameInUse}
            value={editedName}
            onChange={setEditedName}
            onConfirm={handleUpdateRevisionName}
          />

          {hasComponents ? (
            <GenericStateTag
              state={iteration.state}
              onClick={canChange ? handleApproveRevision : undefined}
              notification={notification}
              hasInteraction={canChange}
            />
          ) : (
            <ChangeRevisionEditButton
              canChange={canChange}
              onChange={{
                approved: handleApproveRevision,
                obsolete: makeRevisionObsolete,
              }}
              state={iteration.state}
              notification={notification}
              objectType={"part"}
            />
          )}

          <AlignRight />

          <IterationButtonGroup
            isEditable={isEditable && isDraft}
            onView={onView}
            onDownload={onDownload}
            onExportAsStep={onExportAsStep}
            onCompleteNomenclature={handleNomenclatureDialog}
            onDelete={handleToggleDialog}
            part={part}
            canView={canView}
            hasComponents={hasComponents}
            hasCompleteBom={hasCompleteBom}
            isDeleteDisabled={!hasOtherRevisions}
            isNomenclatureDisabled={!isNomenclatureEnabled}
          />
        </RevisionCardHeader>

        <RevisionInfos userId={iteration.author} date={iteration.updateTime} />

        <RevisionEditableDescription
          isEditable={isEditable && isDraft}
          defaultValue={iteration.description}
          onConfirm={handleUpdateRevisionDescription}
        />
      </RevisionCard>

      {showDeleteDraftDialog && (
        <DeletePartDraftDialog
          partId={part.id}
          iterationId={iteration.number}
          onClose={handleToggleDialog}
        />
      )}
    </>
  );
}
