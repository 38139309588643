import { HighlightedText } from "@aletiq/design-system";
import { MaybeElement } from "@blueprintjs/core";
import React from "react";
import { toggle, useTranslations } from "../../util";
import { toggleAll } from "../../util/array/toggleAll";
import MenuDivider from "../nav-bar/MenuDivider";
import FilterMenu from "./FilterMenu";
import FilterMenuItem from "./FilterMenuItem";
import FilterTemplate from "./FilterTemplate";

export default function FilterView<T extends React.Key>(props: {
  selected: T[];
  placeholder: string;
  items: {
    id: T;
    text: string;
  }[];
  all?: T[];
  noSelection?: boolean;
  onSelect: (values: T[]) => void;
  onSelectNone?: (value: boolean) => void;
  onClear: () => void;
  search?: string;
  onSearch?: (value: string) => void;
  selectNoneText?: string | React.ReactElement;
  hasSelectAll?: boolean;
  headItems?: MaybeElement;
}) {
  const {
    all,
    selected,
    noSelection,
    onSelect,
    onSelectNone,
    onClear,
    search,
    onSearch,
    placeholder,
    hasSelectAll,
    headItems,
    selectNoneText,
    items,
  } = props;

  const tr = useTranslations();

  const handleSelect = (id: T) => () => onSelect(toggle(selected, id));

  const handleSelectAll = () =>
    onSelect(toggleAll(selected, all ?? items.map((i) => i.id)));

  const handleSelectEmpty = () => {
    onSelectNone && onSelectNone(!noSelection);
  };

  const isAllSelected = all?.length === selected.length;

  return (
    <FilterTemplate
      count={noSelection ? selected.length + 1 : selected.length}
      onClear={onClear}
      placeholder={placeholder}
      content={
        <FilterMenu
          search={onSearch ? search : undefined}
          onSearchChange={onSearch}
        >
          {hasSelectAll && (
            <FilterMenuItem
              onSelect={handleSelectAll}
              text={tr.translate("generic.action-select-all")}
              selected={isAllSelected ?? false}
            />
          )}

          {headItems}

          {onSelectNone && (
            <FilterMenuItem
              selected={noSelection ?? false}
              onSelect={handleSelectEmpty}
              text={selectNoneText ?? tr.translate("filter.none")}
            />
          )}

          {(onSelectNone || headItems || hasSelectAll) && <MenuDivider />}

          {items.map((item) => (
            <FilterMenuItem
              key={item.id}
              selected={selected.includes(item.id)}
              onSelect={handleSelect(item.id)}
              text={<HighlightedText text={item.text} highlight={search} />}
            />
          ))}
        </FilterMenu>
      }
    />
  );
}
