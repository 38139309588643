import { API } from "@aletiq/api";
import savePartRevision from "./handleSavePartRevision";
import { PartError, PartState } from "../types";

export default async function handleValidate(
  api: API,
  state: PartState
): Promise<PartError | void> {
  if (!state.partInfo.action.approve) {
    return;
  }

  const partId = state.partId;

  if (!partId) {
    return {
      name: state.partInfo.name,
      stage: "saving-revision",
    };
  }

  const res = await savePartRevision(api, partId, state.partInfo.revision);

  if (res !== "success") {
    return {
      partId,
      name: state.partInfo.name,
      stage: "saving-revision",
    };
  }
}
