import { PropertyObject, PropertyOption } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { propertyKeys } from "./queries";

export type ResolvedPropertyOption = PropertyOption & {
  uses: number;
};

export default function useResolvedPropertyOptions(
  objectType: PropertyObject,
  propertyName: string,
  options: PropertyOption[]
) {
  const api = useApi();
  return useQuery(
    propertyKeys.optionUsedIn(
      objectType,
      propertyName,
      options.map((o) => o.value ?? 0)
    ),
    async () => {
      const resolvedOptions: (PropertyOption & { uses: number })[] = [];
      for (const option of options) {
        const uses = await api.property.countPropertyOptionUses(
          objectType,
          propertyName,
          option.name
        );
        resolvedOptions.push({ ...option, uses });
      }
      return resolvedOptions;
    }
  );
}
