import React from "react";
import { Divider } from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./HeaderEntityProcessPanel.module.scss";

export type Filter = "archived" | "active";

export default function HeaderEntityProcessPanel() {
  const tr = useTranslations();

  return (
    <div className={styles.header_task}>
      <div className={styles.task}>{tr.translate("tasklist.title")}</div>
      <Divider isVertical className={styles.divider} />
      <div className={styles.assignee_col}>
        {tr.translate("tasklist.assignee")}
      </div>
      <Divider isVertical className={styles.divider} />
      <div className={styles.status_col}>{tr.translate("tasklist.status")}</div>
    </div>
  );
}
