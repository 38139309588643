import { Part, ProjectSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { pdmKeys } from "../../pdm/hooks/queries";
import { projectKeys } from "./queries";

export default function useCreateProduct(part?: Part) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (spec: ProjectSpec) => {
      const product = await api.project.createProject(spec);
      if (!part) {
        return product;
      }
      await api.pdm.updatePartProjects(part.id, [...part.projects, product.id]);
      await api.pdm.updatePartProjectBom(product.id, 1, {
        part: part.id,
        iteration: part.lastIteration.number,
      });
      return product;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.all);
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.project.create"),
        });
      },
    }
  );
}
