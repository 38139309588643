import { Passport } from "@aletiq/types";
import React from "react";
import { EditablePropertyInput } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useSetPassportDescription } from "../../hooks";

export default function DescriptionField(props: { passport: Passport }) {
  const { passport } = props;
  const tr = useTranslations();

  const canUpdatePassports = useHasPermission("update:passports");

  const { mutate: setDescription } = useSetPassportDescription();

  const handleDescription = (description: string) => {
    setDescription({ passportId: passport.id, description });
  };

  return (
    <EditablePropertyInput
      value={passport?.description ?? ""}
      isEditable={canUpdatePassports}
      label={tr.translateAsString("generic.label.description")}
      placeholder={tr.translateAsString("generic.action.modify")}
      emptyState={tr.translateAsString("generic.label.none")}
      onSubmit={handleDescription}
      inline
    />
  );
}
