import { Field } from "@aletiq/design-system";
import { Operation, OperationBill } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  ActionBar,
  Button,
  Card,
  CardList,
  ChangeRevisionPopover,
  GenericStatusTag,
  HighlightedText,
  SearchInput,
  Tooltip,
} from "../../../components";
import { useCurrentUser, useNavigate } from "../../../hooks";
import { Diff, isInString, useTranslations } from "../../../util";
import BillActionsMenu from "./BillActionsMenu";
import { useRevertToDraftBill } from "./hooks";
import OperationBillSelect from "./OperationBillSelect";
import OperationItemsCount from "./OperationItemsCount";
import PageContent from "./PageContent";
import styles from "./ProjectOperationBill.module.scss";
export default function ProjectOperationBill(props: {
  projectId: number;
  activeBill: OperationBill;
  diff: Record<number, Diff<Operation>>;
  bills: OperationBill[];
}) {
  const { projectId, activeBill, diff, bills } = props;
  const tr = useTranslations();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const { data: user } = useCurrentUser();

  const { mutate: revertToDraftBill } = useRevertToDraftBill(
    projectId,
    activeBill.id
  );

  const isUserAdmin = user?.role === "administrator";

  const filteredOperations = activeBill.operations.filter(
    (op) =>
      isInString(op.name, search) || isInString(op.number.toString(), search)
  );

  const handleShowOperation = (number: number) => () => {
    navigate({
      path: `/project/${projectId}/operation-bills/${activeBill?.id}/operations/${number}`,
    });
  };

  const handleShowBill = (bill: number) => {
    navigate({
      path: `/project/${projectId}/operation-bills/${bill}`,
    });
  };

  return (
    <PageContent>
      <Field label={tr.translate("project.operation-bill.bill-index")}>
        <ActionBar>
          <OperationBillSelect
            activeBill={activeBill}
            bills={bills}
            onSelect={handleShowBill}
          />
          <BillActionsMenu
            projectId={projectId}
            bills={bills}
            activeBill={activeBill}
          />
          {isUserAdmin && (
            <ChangeRevisionPopover
              initial={{
                state: activeBill.state,
              }}
              final={{
                state: "draft",
              }}
              objectType={"bill"}
              onConfirm={revertToDraftBill}
            >
              <Button isDense icon={"edit"} />
            </ChangeRevisionPopover>
          )}
        </ActionBar>
      </Field>
      {activeBill?.description !== "" ? (
        <Field label={tr.translate("generic.label.description")}>
          {activeBill?.description?.split("\n")?.map((str) => (
            <div key={str}>{str}</div>
          ))}
        </Field>
      ) : null}
      <Field>
        <Tooltip
          position="top"
          content={tr.translate("project.operation-bill.search-operations")}
        >
          <SearchInput isDense value={search} onChange={setSearch} />
        </Tooltip>
      </Field>
      <Field>
        <CardList>
          {filteredOperations.map((operation) => (
            <Card
              key={operation.number}
              onClick={handleShowOperation(operation.number)}
              title={
                <Text ellipsize className={styles.operation_name}>
                  <HighlightedText
                    text={operation.number.toString()}
                    highlight={search}
                  />
                  {" - "}
                  <HighlightedText text={operation.name} highlight={search} />
                </Text>
              }
              headerActions={
                <>
                  <GenericStatusTag status={diff[operation.number]?.status} />
                  <OperationItemsCount
                    toolCount={operation.tools.length}
                    documentCount={operation.documents.length}
                    hasObsoleteDocs={operation.documents.some(
                      (doc) => doc.revision.revision?.state === "obsolete"
                    )}
                  />
                </>
              }
            />
          ))}
        </CardList>
      </Field>
    </PageContent>
  );
}
