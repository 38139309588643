import { FileAction, Property, PropertyValueUpdate } from "@aletiq/types";
import React, { useState } from "react";
import { AlertPopover, Input, Tooltip } from "../../../../components";
import { useTranslations } from "../../../../util";
import BatchSelectPropertyValue, {
  EditButton,
} from "./BatchSelectPropertyValue";
import DocumentActionSelect from "./DocumentActionSelect";
import styles from "./DocumentUploadWizard.module.scss";

export default function DocumentTableHeader(props: {
  submitting?: boolean;
  onBatchUpdateIndex: (index: string) => void;
  onBatchUpdateAction: (
    action: FileAction,
    state?: "draft" | "approved"
  ) => void;
  visibleProperties: Property[];
  onBatchUpdateProperty: (propertyValue: PropertyValueUpdate) => void;
  isScrollbarVisible: boolean;
  scrollbarSize: number;
}) {
  const {
    submitting,
    onBatchUpdateAction,
    onBatchUpdateProperty,
    onBatchUpdateIndex,
    isScrollbarVisible,
    scrollbarSize,
    visibleProperties,
  } = props;

  const tr = useTranslations();

  const [revisionIndex, setRevisionIndex] = useState("");

  return (
    <thead>
      <tr>
        <th className={styles.tag_col}>
          {tr.translate("storage.dialog.upload-file.tag")}
        </th>
        <th>{tr.translate("storage.dialog.upload-file.doc-name")}</th>
        <th className={styles.action_col}>
          <div className={styles.batch_action}>
            {tr.translate("storage.dialog.upload-file.action")}
            <DocumentActionSelect
              onChange={onBatchUpdateAction}
              disabled={submitting}
              isBatchSelector
            >
              <Tooltip
                position="top"
                content={tr.translate(
                  "storage.dialog.upload-file.action.batch.apply"
                )}
              >
                <EditButton />
              </Tooltip>
            </DocumentActionSelect>
          </div>
        </th>
        <th className={styles.revision_col}>
          <div className={styles.batch_action}>
            {tr.translate("storage.dialog.upload-file.revision.index")}
            <AlertPopover
              isDense
              disabled={submitting}
              disablePrimary={submitting}
              onPrimaryClick={() => onBatchUpdateIndex(revisionIndex)}
              primaryText={tr.translateAsString(
                "storage.dialog.upload-file.apply-all"
              )}
              content={
                <Input
                  fill
                  isDense
                  disabled={submitting}
                  placeholder={tr.translateAsString(
                    "storage.dialog.upload-file.apply-all.index"
                  )}
                  value={revisionIndex}
                  onChange={setRevisionIndex}
                />
              }
            >
              <EditButton isDisabled={submitting} />
            </AlertPopover>
          </div>
        </th>

        {visibleProperties.map((property) => (
          <th key={property.id}>
            <BatchSelectPropertyValue
              property={property}
              isDisabled={submitting}
              onSubmit={onBatchUpdateProperty}
            />
          </th>
        ))}

        {isScrollbarVisible && (
          <th style={{ width: `${scrollbarSize}px`, borderLeft: "none" }} />
        )}
      </tr>
    </thead>
  );
}
