import { UserGroupSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { userKeys } from "../../hooks/queries";

export default function useCreateUserGroup() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<number, Error, UserGroupSpec>(
    (spec) => api.user.createUserGroup(spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userKeys.groups);
      },
    }
  );
}
