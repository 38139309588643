import { Card, EditableText } from "@aletiq/design-system";
import { Task } from "@aletiq/types";
import React, { Dispatch, useMemo } from "react";
import { WorkflowEditorTaskTable } from "./EditorTaskTable";
import TaskAddButton from "./TaskAddButton";
import { makeDispatchHandlers, WorkflowEditorAction } from "./taskEditReducer";
import styles from "./WorkflowView.module.scss";

export default function WorkflowCreationEditorView(props: {
  currentTitle: string;
  currentTasks: Task[];
  workflowColor: string;
  editorDispatch: Dispatch<WorkflowEditorAction>;
}) {
  const { editorDispatch, currentTitle, workflowColor, currentTasks } = props;

  const {
    handleEditTitle,
    handleDragAndDrop,
    handleNewSpecEdited,
    handleTaskEdited,
    handleRemoveTask,
    handleMarkForDeletion,
    handleCreateTask,
  } = useMemo(() => makeDispatchHandlers(editorDispatch), [editorDispatch]);

  return (
    <Card
      icon="flow-linear"
      title={
        <EditableText
          value={currentTitle}
          onChange={handleEditTitle}
          className={styles.workflow_title}
        />
      }
    >
      <WorkflowEditorTaskTable
        onTaskEdited={handleTaskEdited}
        onMarkForDeletion={handleMarkForDeletion}
        onRemoveNewTask={handleRemoveTask}
        onNewSpecEdited={handleNewSpecEdited}
        onDragAndDrop={handleDragAndDrop}
        workflowColor={workflowColor}
        tasks={currentTasks}
      />
      <TaskAddButton onClick={handleCreateTask} />
    </Card>
  );
}
