import { TaskDelayExpiredNotification } from "@aletiq/types";
import React from "react";
import ActivityWorkflowLink from "../../../components/activities/links/ActivityWorkflowLink";
import { useTranslations } from "../../../util";
import { useProcess, useTask } from "../../tasks/hooks";
import NotificationCard from "../NotificationCard";

export default function TaskExpiredNotificationView(props: {
  notification: TaskDelayExpiredNotification;
  isNew: boolean;
  onFollowLink: () => void;
}) {
  const {
    isNew,
    notification: {
      id,
      meta: {
        read,
        date,
        content: { taskId },
      },
    },
    onFollowLink,
  } = props;
  const tr = useTranslations();

  const { data: task } = useTask(taskId);
  const { data: workflow } = useProcess(task?.process ?? -1);

  return (
    <NotificationCard
      isRead={read}
      isNew={isNew}
      notificationId={id}
      date={date}
    >
      {tr.translate("notification.task.delay-expired", {
        task: task?.title ?? null,
        workflow: workflow ? (
          <ActivityWorkflowLink workflow={workflow} onClick={onFollowLink} />
        ) : null,
      })}
    </NotificationCard>
  );
}
