import classNames from "classnames";
import React from "react";
import Color from "../../../styles/color.module.scss";
import { ChangeStatus, useTranslations } from "../../../util";
import styles from "./GenericStatusTag.module.scss";

function colorFromStatus(status: ChangeStatus) {
  switch (status) {
    case "unchanged":
      return "primary";
    case "new":
      return "success";
    case "deleted":
      return "danger";
    case "updated":
      return "warning";
  }
}

export default function GenericStatusTag(props: { status?: ChangeStatus }) {
  const { status } = props;
  const tr = useTranslations();

  if (status === "unchanged" || !status) {
    return null;
  }

  return (
    <div className={classNames(styles.status, Color[colorFromStatus(status)])}>
      {tr.translate("generic.label.status.value", { status })}
    </div>
  );
}
