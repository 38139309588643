export type These<A, B = A> =
  | { type: "first"; first: A }
  | { type: "second"; second: B }
  | { type: "both"; first: A; second: B };

export function mergeRecords<A, B, K extends keyof any>(
  first: Record<K, A>,
  second: Record<K, B>
): Record<K, These<A, B>> {
  const result: Record<K, These<A, B>> = {} as Record<K, These<A, B>>;
  for (const key in first) {
    result[key] = { type: "first", first: first[key] };
  }

  for (const key in second) {
    const current = result[key];
    const previous = second[key];
    if (!current) {
      result[key] = { type: "second", second: previous };
    } else if (current.type === "first" || current.type === "both") {
      result[key] = { type: "both", first: current.first, second: previous };
    } else {
      result[key] = { type: "second", second: previous };
    }
  }

  return result;
}
