import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { documentKeys } from "./queries";

export default function useUpdateDocumentsOwner() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (documents: { id: number; owner: number }[]) => {
      for (let i = 0; i < documents.length; i++) {
        await api.document.setDocumentOwner(
          documents[i].id,
          documents[i].owner
        );
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(documentKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.documents.owner.update"),
        });
      },
    }
  );
}
