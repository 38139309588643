import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useNavigateTo, useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { projectKeys } from "./queries";

export default function useDeleteProject(projectId: number) {
  const api = useApi();
  const tr = useTranslations();
  const queryClient = useQueryClient();
  const navigateToHome = useNavigateTo({ path: "/" });
  const toaster = useToaster();

  return useMutation(() => api.project.deleteProject(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(activityKeys.all);
      navigateToHome();
    },
    onError: () => {
      toaster.show({
        intent: "danger",
        icon: "warning-sign",
        timeout: 2000,
        message: tr.translate("toaster.project.delete"),
      });
    },
  });
}
