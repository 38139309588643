import { Part, Project, ProjectQueryFilterParams } from "@aletiq/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActionBar,
  DefaultFilters,
  Dialog,
  Table,
} from "../../../../components";
import { useFilter, usePagination, useSortKey } from "../../../../hooks";
import { unique, useTranslations } from "../../../../util";
import { MoreProductFilters } from "../../../project/common";
import {
  useDefaultProductColumnConfig,
  usePaginatedProjects,
  useProductColumns,
} from "../../../project/hooks";
import { useUpdatePartsProjects } from "../../hooks";
import styles from "./LinkProjectsDialog.module.scss";

export default function LinkProjectsDialog(props: {
  currentProjects: number[];
  parts: Part[];
  onClose: () => void;
}) {
  const { parts, onClose, currentProjects } = props;
  const tr = useTranslations();

  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

  const {
    sortState: { key, direction },
    setSortKey,
  } = useSortKey<"name" | "description">("name");
  const {
    limit,
    selectedPage,
    handleLimit,
    handleSelectPage,
    resetSelectedPage,
  } = usePagination();

  const { filter, handleFilter, handleClear } =
    useFilter<ProjectQueryFilterParams>({
      search: "",
    });

  useEffect(() => {
    resetSelectedPage();
  }, [filter, key, direction, resetSelectedPage]);

  const { data: projectsResponse } = usePaginatedProjects({
    ...filter,
    order: key,
    orderDirection: direction,
    limit,
    offset: selectedPage * limit,
  });

  const { mutate: updatePartsProject, isLoading: isUpdatePartsLoading } =
    useUpdatePartsProjects();

  const allProjects = useMemo(
    () => projectsResponse?.list ?? [],
    [projectsResponse]
  );
  const availableProjects = useMemo(
    () =>
      allProjects.filter((project) => !currentProjects.includes(project.id)),
    [allProjects, currentProjects]
  );

  const { config, isLoading } = useDefaultProductColumnConfig();
  const columns = useProductColumns({
    config,
  });

  const totalProjects = projectsResponse?.count ?? 0;

  const handleSelect = useCallback(
    (project: Project[]) => setSelectedProjects(project.map((p) => p.id)),
    []
  );

  const handleSubmit = async () => {
    const partsToUpdate = parts.map((part) => {
      return {
        partId: part.id,
        projects: unique([...part.projects, ...selectedProjects]),
      };
    });

    updatePartsProject(partsToUpdate, {
      onSettled: () => {
        onClose();
      },
    });
    return;
  };

  const handleSort = (sortKey: keyof Project) => {
    if (sortKey === "name" || sortKey === "description") {
      setSortKey(sortKey);
    }
  };

  const title =
    parts.length > 1
      ? tr.translateAsString("part.dialog.link-project.parts.title", {
          nbr: parts.length,
        })
      : tr.translateAsString("part.dialog.link-project.title");

  return (
    <Dialog
      isOpen
      icon="new-link"
      title={title}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={onClose}
      onClose={onClose}
      submitting={isUpdatePartsLoading}
      className={styles.dialog}
    >
      <div className={styles.dialog_contents}>
        <p>
          {tr.translate(
            parts.length > 1
              ? "part.dialog.link-project.parts.subtitle"
              : "part.dialog.link-project.subtitle"
          )}
        </p>

        <ActionBar>
          <DefaultFilters
            objectType="product"
            filters={filter}
            handleFilter={handleFilter}
          />
          <MoreProductFilters
            filters={filter}
            handleFilter={handleFilter}
            handleClear={handleClear}
          />
        </ActionBar>

        <Table
          columns={columns}
          data={availableProjects}
          searchString={filter.search}
          sortOptions={{
            sortState: { key, direction },
            onSort: handleSort,
          }}
          paginationOptions={{
            itemCount: totalProjects,
            limit,
            selectedPage,
            onSetLimit: handleLimit,
            onSetPage: handleSelectPage,
          }}
          onSelect={handleSelect}
          className={styles.table}
          isFetching={isLoading}
        />
      </div>
    </Dialog>
  );
}
