import { ProgressBar } from "@blueprintjs/core";
import React from "react";
import useApi from "../../../app/useApi";
import { useDownloadQueue, useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";

export default function useWaitForPartDownload(partId: number) {
  const api = useApi();
  const tr = useTranslations();
  const toaster = useToaster();
  const { handleDownload } = useDownloadQueue();

  return async (revisionId?: number) => {
    toaster.show(
      {
        intent: "primary",
        icon: "info-sign",
        timeout: 0,
        message: (
          <>
            <div>{tr.translate("toaster.part.download.in-progress")}</div>
            <ProgressBar intent="primary" animate />
          </>
        ),
      },
      `part-download-${partId}${revisionId}`
    );
    await handleDownload({
      fetchToken: () =>
        revisionId
          ? api.pdm.getPartIterationCadFileLink(partId, revisionId)
          : api.pdm.getPartCadFileLink(partId),
      forceDownload: true,
    });
    toaster.show(
      {
        intent: "success",
        icon: "tick",
        message: tr.translate("toaster.part.download.success"),
      },
      `part-download-${partId}${revisionId}`
    );
  };
}
