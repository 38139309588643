import { Icon, Tooltip } from "@aletiq/design-system";
import { EntityId } from "@aletiq/types";
import React from "react";
import { usePassport } from "../../../passport/hooks";
import styles from "./WorkflowCardHeader.module.scss";

export default function WorkflowPassportCount(props: {
  workflowPassports: EntityId[];
}) {
  const { workflowPassports } = props;
  const passportCount = workflowPassports.length;

  return (
    <Tooltip
      isDisabled={passportCount === 0}
      content={
        <>
          {workflowPassports.map((entity) => (
            <div>
              <PassportName passportId={entity.id} />
            </div>
          ))}
        </>
      }
    >
      <span
        className={passportCount > 0 ? styles.counter : styles.empty_counter}
      >
        <Icon icon="id-number" inline />
        {passportCount}
      </span>
    </Tooltip>
  );
}

function PassportName(props: { passportId: number }) {
  const { data: passport } = usePassport(props.passportId, {
    refetchOnMount: false,
  });
  return <>{passport?.name}</>;
}
