import { ToolingRequirement } from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_VISITED_PRODUCT_TOOLING,
  useTrackEvent,
} from "../../../analytics";
import {
  ActionBar,
  AlignRight,
  Button,
  PageTitle,
  SearchInput,
  Table,
  Tooltip,
} from "../../../components";
import { useDelayed, useHasPermission, useSort } from "../../../hooks";
import { isInString, useTranslations } from "../../../util";
import { ProjectPage, ToolingBatchActionHeader } from "../common";
import { AddToolDialog } from "../dialogs";
import { useProjectToolingRequirements } from "../hooks";
import useProjectRecord from "../hooks/useProjectsRecord";
import styles from "./ProjectTooling.module.scss";
import useToolingColumns from "./useToolingColumns";

export type ResolvedToolingRequirement = ToolingRequirement & {
  toolName: string;
  toolIsDeleted: boolean;
};

export default function ProjectTooling(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_TOOLING);

  const [addToolDialog, setAddToolDialog] = useState(false);
  const [search, setSearch] = useState("");

  const canEditProject = useHasPermission("update:projects");
  const {
    data: projectTools = [],
    isLoading: requirementsLoading,
    isFetching: requirementsFetching,
  } = useProjectToolingRequirements(projectId);

  const { data: projectsById, isLoading: toolsLoading } = useProjectRecord(
    projectTools.map((e) => e.tool)
  );

  const isLoading = requirementsLoading || toolsLoading;
  const isFetching = useDelayed(requirementsFetching || toolsLoading);

  const resolvedEntries = projectTools.map((e) => ({
    ...e,
    toolName: projectsById?.[e.tool]?.name ?? "-",
    toolIsDeleted: projectsById?.[e.tool]?.isDeleted ?? false,
  }));

  const {
    sortKey,
    sortDirection: direction,
    onSortKeyChange,
    sortedList: sortedTools,
  } = useSort<ResolvedToolingRequirement>(resolvedEntries, "toolName");

  const filteredTools = sortedTools.filter(
    (entry) =>
      isInString(entry.toolName, search) || isInString(entry.comment, search)
  );

  const columns = useToolingColumns(projectId);

  const handleToggleAddToolDialog = () => {
    setAddToolDialog(!addToolDialog);
  };

  const handleRenderBatchActionsHeader = (
    selectedToolingRequirements: ResolvedToolingRequirement[]
  ) => (
    <ToolingBatchActionHeader
      selectedToolingRequirements={selectedToolingRequirements}
    />
  );

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.product", { type: "tool" })}
    >
      <PageTitle icon="wrench">
        {tr.translate("sidebar.project.tooling")}
      </PageTitle>
      <ActionBar>
        <Tooltip
          content={tr.translateAsString("project.tooling.search.tooltip")}
          position="top-left"
        >
          <SearchInput
            isDense
            intent="primary"
            value={search}
            onChange={setSearch}
          />
        </Tooltip>
        <AlignRight />
        <Button
          isDense
          icon="new-link"
          intent="secondary"
          isDisabled={!canEditProject || addToolDialog}
          onClick={handleToggleAddToolDialog}
        >
          {tr.translate("project.tooling.new")}
        </Button>
      </ActionBar>
      <Table
        columns={columns}
        data={filteredTools}
        sortOptions={{
          sortState: { key: sortKey, direction },
          onSort: onSortKeyChange,
        }}
        renderBatchActionsHeader={handleRenderBatchActionsHeader}
        isFetching={isFetching || isLoading}
        noData={
          <div className={styles.no_data}>
            {tr.translate(
              search === ""
                ? "project.list.no-data"
                : "project.list.no-data.filtered",
              { type: "tool" }
            )}
            <Button
              isDense
              isDisabled={!canEditProject || addToolDialog}
              onClick={handleToggleAddToolDialog}
              icon="new-link"
              intent="primary"
              className={styles.create_button}
            >
              {tr.translate("project.tooling.new")}
            </Button>
          </div>
        }
      />

      {addToolDialog && (
        <AddToolDialog
          projectId={projectId}
          projectTools={projectTools}
          onClose={handleToggleAddToolDialog}
        />
      )}
    </ProjectPage>
  );
}
