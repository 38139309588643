import {
  PartIterationId,
  Project,
  ProjectDefinitionId,
  ProjectType,
} from "@aletiq/types";
import { TreeNodeInfo } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";
import {
  Button,
  DialogBase,
  DialogContent,
  DialogHeader,
  Divider,
  Subtitle,
  TreeContent,
  TreeHeader,
  TreeTitle,
} from "../../../../components";
import { makeFlatIds, togglePathId, useTranslations } from "../../../../util";
import { usePart } from "../../../pdm/hooks";
import { isPartParent } from "../../../pdm/services";
import { usePartProjectBom, useProject } from "../../hooks";
import { ProjectCreationDialog } from "../ProjectCreationDialog";
import { AssignNomenclatureDialog } from "./AssignNomenclatureDialog";
import DeleteNomenclatureDialog from "./DeleteNomenclatureDialog";
import EditAdditionalProductsTree from "./EditAdditionalProducts";
import EditPartTree from "./EditPartTree";
import styles from "./EditProductBomDialog.module.scss";
import EditProductTree from "./EditProductTree";
import usePartProjectBomTree from "./hooks/usePartProjectBomTree";
import useUpdatePartProjectBomTree from "./hooks/useUpdatePartProjectBomTree";
import {
  isComplete,
  makeAdditionalTrees,
  makeAutocomplete,
  makeMutationBatch,
  PartProductBomMutation,
  ProductBomMutation,
  ProductMutation,
} from "./partProjectBomUtils";

type EditProductBomActionDialog =
  | { state: "none" }
  | {
      state: "creation";
      type: ProjectType;
      part: PartIterationId;
      parent: ProjectDefinitionId;
      pathId: string;
    }
  | {
      state: "assign";
      addition?: ProductBomMutation;
      assignation?: PartProductBomMutation;
      pathId: string;
    }
  | {
      state: "delete";
      deletion: ProductBomMutation;
      pathId: string;
    };

export default function EditProductBomDialog(props: {
  projectId: number;
  definitionIdx: number;
  onClose: () => void;
}) {
  const { projectId, definitionIdx, onClose } = props;
  const tr = useTranslations();

  const [dialog, setDialog] = useState<EditProductBomActionDialog>({
    state: "none",
  });
  const [mutatedPathId, setMutatedPathId] = useState<string | undefined>();
  const [movedProductId, setMovedProductId] = useState<number | undefined>();
  const [expanded, setExpanded] = useState<string[]>([]);

  const { data: movedProduct } = useProject(movedProductId);
  const { data: part } = usePartProjectBom(projectId, definitionIdx);
  const { data: linkedPart } = usePart(
    (dialog.state === "creation" && dialog.part.part) || undefined
  );
  const {
    data: tree,
    isFetching: isFetchingBom,
    isLoading: isLoadingBom,
  } = usePartProjectBomTree(
    projectId,
    definitionIdx,
    makeFlatIds(expanded),
    part && isPartParent(part.part.type),
    () => setMutatedPathId(undefined)
  );

  const { mutate: updatePartProjectBom, isLoading: isUpdatingBom } =
    useUpdatePartProjectBomTree();

  const isLoading = isUpdatingBom || isFetchingBom;
  const fetchingPathId = isLoading ? mutatedPathId : undefined;
  const isGloballyLoading = isLoadingBom || (isLoading && !mutatedPathId);

  const makeMutations = (pathId: string, mutation: ProductMutation) => {
    const mutations = makeMutationBatch(mutation);
    updatePartProjectBom(mutations);
    setMutatedPathId(pathId);
    setMovedProductId(undefined);
  };

  const handleAutocomplete = () => {
    if (!tree) {
      return;
    }
    const mutations = {
      assignations: [],
      additions: makeAutocomplete(tree),
      deletions: [],
    };
    updatePartProjectBom(mutations);
  };

  const handleCloseDialog = () => {
    setDialog({ state: "none" });
  };

  const handleOpenCreateDialog = (
    pathId: string,
    type: ProjectType,
    part: PartIterationId,
    parent: ProjectDefinitionId
  ) => {
    setDialog({
      state: "creation",
      pathId,
      type,
      part,
      parent,
    });
  };

  const handleOpenAssignDialog = (
    pathId: string,
    isLinked: boolean,
    addition?: ProductBomMutation,
    assignation?: PartProductBomMutation
  ) => {
    isLinked
      ? makeMutations(pathId, { addition, assignation })
      : setDialog({ state: "assign", addition, assignation, pathId });
  };

  const handleOpenDeleteDialog = (
    pathId: string,
    deletion: ProductBomMutation
  ) => {
    setDialog({ state: "delete", deletion, pathId });
  };

  const handleExpand = (pathId: string): void => {
    setExpanded((expanded) => togglePathId(pathId, expanded));
  };

  const handleAssign = () => {
    if (dialog.state !== "assign") {
      return;
    }
    const { assignation, addition } = dialog;
    makeMutations(dialog.pathId, { addition, assignation });
    setDialog({ state: "none" });
  };

  const handleDelete = () => {
    if (dialog.state !== "delete") {
      return;
    }
    const { deletion } = dialog;
    makeMutations(dialog.pathId, { deletion });
    setDialog({ state: "none" });
  };

  const handleMove = (productId: number) => {
    setMovedProductId(productId);
  };

  const handleCancelMove = () => {
    setMovedProductId(undefined);
  };

  const handleSuccessCreateComponent = (product: Project) => {
    if (dialog.state !== "creation") {
      return;
    }
    const addition = {
      productId: product.id,
      parent: dialog.parent,
      definitionIdx: product.lastDefinition.index,
    };
    const assignation = {
      product: {
        productId: product.id,
        definitionIdx: 1,
      },
      part: dialog.part,
    };
    makeMutations(dialog.pathId, { addition, assignation });
  };

  const handleToggleExpandNode = (
    node: TreeNodeInfo<{ onExpand?: () => void }>
  ) => {
    node.nodeData?.onExpand && node.nodeData.onExpand();
  };

  function isExpanded(pathId: string) {
    return expanded.includes(pathId);
  }

  const isHighlighted = !!movedProduct;

  const treeIsComplete = useMemo(() => tree && isComplete(tree), [tree]);

  const additionalTrees = useMemo(
    () => tree && makeAdditionalTrees(tree),
    [tree]
  );

  return (
    <DialogBase isOpen isFullPage onClose={onClose}>
      <DialogHeader
        icon="shapes"
        title={tr.translateAsString("project.bom.edition.title")}
        onClose={onClose}
      />
      <div className={styles.action_bar}>
        <Button
          isDense
          text={tr.translateAsString("project.bom.edition.autocomplete")}
          onClick={handleAutocomplete}
          intent="primary"
          isDisabled={treeIsComplete || isGloballyLoading}
        />
      </div>
      <DialogContent>
        <TreeHeader>
          <TreeTitle isHighlighted={isHighlighted}>
            {tr.translate("cad.bom.edition.bom.parts")}
          </TreeTitle>
          <TreeTitle isHighlighted={isHighlighted}>
            {tr.translate("project.bom.edition.product")}
          </TreeTitle>
        </TreeHeader>
        <TreeContent isFetching={isGloballyLoading}>
          {tree && (
            <>
              <div>
                <EditPartTree
                  tree={tree}
                  movedProduct={movedProduct}
                  isExpanded={isExpanded}
                  onExpand={handleExpand}
                  onToggleExpandNode={handleToggleExpandNode}
                />
              </div>
              <div>
                <EditProductTree
                  tree={tree}
                  fetchingPathId={fetchingPathId}
                  movedProduct={movedProduct}
                  isExpanded={isExpanded}
                  onExpand={handleExpand}
                  onToggleExpandNode={handleToggleExpandNode}
                  onAssign={handleOpenAssignDialog}
                  onDelete={handleOpenDeleteDialog}
                  onCreate={handleOpenCreateDialog}
                />

                {additionalTrees && additionalTrees.length > 0 && (
                  <>
                    <Divider />
                    <Subtitle
                      text={tr.translateAsString(
                        "project.dialog.link.product.bom.additional-product"
                      )}
                    />
                    <EditAdditionalProductsTree
                      productId={projectId}
                      definitionIdx={definitionIdx}
                      additionalTrees={additionalTrees}
                      movedProduct={movedProduct}
                      onDelete={handleOpenDeleteDialog}
                      onMove={handleMove}
                      onCancel={handleCancelMove}
                      fetchingPathId={fetchingPathId}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </TreeContent>
      </DialogContent>
      {dialog.state === "creation" && linkedPart && (
        <ProjectCreationDialog
          type={dialog.type}
          part={linkedPart}
          onSuccess={handleSuccessCreateComponent}
          onClose={handleCloseDialog}
        />
      )}

      {dialog.state === "assign" && (
        <AssignNomenclatureDialog
          assignation={dialog.assignation!}
          onAssign={handleAssign}
          onClose={handleCloseDialog}
        />
      )}

      {dialog.state === "delete" && (
        <DeleteNomenclatureDialog
          onDelete={handleDelete}
          onClose={handleCloseDialog}
        />
      )}
    </DialogBase>
  );
}
