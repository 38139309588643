import { Project } from "@aletiq/types";
import { useMemo } from "react";
import { useQueries, UseQueryResult } from "react-query";
import useApi from "../../../app/useApi";
import { indexBy, unique } from "../../../util";

type ProjectRecordQueryResult = {
  data: Record<number, Project> | undefined;
  isLoading: boolean;
};

export default function useProjectRecord(
  projects: number[]
): ProjectRecordQueryResult {
  const api = useApi();

  const projectsQueries = useQueries(
    unique(projects).map((projectId) => ({
      queryKey: ["projects", projectId],
      queryFn: () => api.project.getProject(projectId),
    }))
  ) as UseQueryResult<Project>[];

  return useMemo(() => {
    const results = projectsQueries.flatMap((res) => res.data);
    const data = results.some((p) => p === undefined)
      ? undefined
      : indexBy(results as Project[], "id");
    const isLoading = projectsQueries.reduce(
      (acc: boolean, res) => acc || res.isLoading,
      false
    );
    return { data, isLoading };
  }, [projectsQueries]);
}
