import React, { useMemo, useState } from "react";
import { useProcessModels } from "../../features/tasks/tasksSlice";
import { filterByString, sortByString, useTranslations } from "../../util";
import FilterView from "./FilterView";

export default function WorkflowModelFilter(props: {
  selected: number[];
  noModelSelected?: boolean;
  onSelect: (values?: number[]) => void;
  onSelectNoModel?: (value?: boolean) => void;
  onClear: () => void;
  className?: string;
}) {
  const tr = useTranslations();
  const { selected, onClear, noModelSelected, onSelectNoModel, onSelect } =
    props;
  const { data: models = [] } = useProcessModels();

  const [search, setSearch] = useState("");

  const sortedModels = useMemo(() => {
    const filteredModels = filterByString(models, (m) => m.title, search);
    return sortByString(filteredModels, (m) => m.title);
  }, [models, search]);

  return (
    <FilterView
      items={sortedModels.map((m) => ({ id: m.id, text: m.title }))}
      selected={selected}
      all={models.map((m) => m.id)}
      placeholder={tr.translateAsString("filter.workflow-model.placeholder")}
      noSelection={noModelSelected}
      onSelect={onSelect}
      onClear={onClear}
      search={search}
      onSearch={setSearch}
      onSelectNone={onSelectNoModel}
      hasSelectAll
    />
  );
}
