import React from "react";
import classnames from "classnames";
import styles from "./CommentEditor.module.scss";
import { useTranslations } from "../../util";

const PopoverMention = React.forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactChildren }
>(function (props, ref) {
  const className = classnames(props.className, styles.menu);
  const tr = useTranslations();

  return (
    <div ref={ref} className={className}>
      <div className={styles.menuHeader}>
        <b>{tr.translate("comment.tag.user.placeholder")}</b>
      </div>
      {props.children}
    </div>
  );
});

export default PopoverMention;
