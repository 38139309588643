import React from "react";
import { EditablePropertySingleSelect } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import {
  usePassport,
  usePassportStates,
  useSetPassportState,
} from "../../hooks";

export default function StateField(props: { passportId: number }) {
  const { passportId } = props;
  const tr = useTranslations();

  const { data: passport } = usePassport(passportId);
  const { data: states = [] } = usePassportStates();
  const canUpdatePassports = useHasPermission("update:passports");

  const { mutate: setPassportState } = useSetPassportState();

  const handleSubmit = (state: number) => {
    if (state === -1) {
      setPassportState({ passportId, state: null });
    } else {
      setPassportState({ passportId, state });
    }
  };

  const itemStates = [
    {
      key: -1,
      text: tr.translateAsString("generic.label.none"),
    },
    ...states.map((state) => ({
      key: state.id,
      text: state.value,
    })),
  ];

  return (
    <EditablePropertySingleSelect
      value={passport?.state ?? -1}
      items={itemStates}
      isEditable={canUpdatePassports}
      label={tr.translateAsString("product.passports.state")}
      placeholder={tr.translateAsString("generic.action.modify")}
      emptyState={tr.translateAsString("generic.label.none")}
      onSubmit={handleSubmit}
      inline
    />
  );
}
