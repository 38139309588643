import {
  Button,
  Divider,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  Tabs,
} from "@aletiq/design-system";
import {
  Classes,
  Spinner,
  SpinnerSize,
  Tab,
  TabId,
  Text,
} from "@blueprintjs/core";
import classNames from "classnames";
import React, { useState } from "react";
import { useLocation } from "react-router";
import {
  ANALYTICS_OPENED_WORKFLOW_DETAILS,
  useTrackEvent,
} from "../../../analytics";
import { Activities, DeleteAlert, PanelDetails } from "../../../components";
import { useHasPermission, useNavigate, useNavigateTo } from "../../../hooks";
import {
  DEFAULT_WORKFLOW_TAB,
  isValidTabId,
  useTranslations,
} from "../../../util";
import WorkflowEntities from "../common/WorkflowEntities";
import {
  useArchiveProcess,
  useDeleteProcess,
  useHasUpdateRightOnWorkflow,
  useProcess,
  useRestoreProcess,
} from "../hooks";
import styles from "./WorkflowDetails.module.scss";
import WorkflowProperties from "./WorkflowProperties";
import WorkflowTasks from "./WorkflowTasks";

export default function WorkflowDetails(props: {
  workflowId: number;
  onClose: () => void;
  tab?: string;
}) {
  useTrackEvent(ANALYTICS_OPENED_WORKFLOW_DETAILS);

  const {
    workflowId,
    onClose: handleClose,
    tab = DEFAULT_WORKFLOW_TAB,
  } = props;
  const tr = useTranslations();

  const [deleteDialog, setDeleteDialog] = useState(false);

  const { data: workflow, isLoading } = useProcess(workflowId);

  const canLinkWorkflows = useHasPermission("link:workflows");
  const canUpdate = useHasUpdateRightOnWorkflow(workflow?.id);

  const navigate = useNavigate();
  const location = useLocation();
  const isInWorkflowSpace = workflow?.isArchived
    ? location.pathname?.includes("/workflows/archived")
    : location.pathname?.substring(0, 10) === "/workflows";

  const { mutate: archiveProcess } = useArchiveProcess();
  const { mutate: restoreProcess } = useRestoreProcess();
  const { mutate: deleteProcess } = useDeleteProcess();

  const handleOpenWorkflowSpace = useNavigateTo({
    path: workflow?.isArchived ? "/workflows/archived" : "/workflows",
    hash: `${workflowId}`,
  });

  const handleArchiveUnarchive = () => {
    if (!workflow) return;
    if (!workflow.isArchived) archiveProcess(workflowId);
    else restoreProcess(workflowId);
  };

  const handleSetTab = (tabId: TabId) => {
    navigate({ panelTab: tabId.toString() });
  };

  return (
    <>
      <PanelDetails
        id={workflow?.id ?? -1}
        title={
          <div className={styles.flex}>
            <Icon icon="flow-linear" size={20} />
            <Text ellipsize className={styles.h2}>
              {workflow?.title}
            </Text>
          </div>
        }
        additionalButtons={
          workflow &&
          !workflow.isDeleted && (
            <Popover2
              content={
                <Menu isDense intent="minimal">
                  <MenuItem
                    icon={workflow?.isArchived ? "unarchive" : "archive"}
                    disabled={!canUpdate}
                    text={tr.translate(
                      "workflow.details.action.archive-unarchive",
                      { action: workflow?.isArchived ? "unarchive" : "archive" }
                    )}
                    onClick={handleArchiveUnarchive}
                    isDense
                  />
                  <MenuDivider isDense />
                  <MenuItem
                    icon="trash"
                    disabled={!canUpdate}
                    text={tr.translate("workflow.details.action.delete")}
                    onClick={() => setDeleteDialog(true)}
                    isDense
                  />
                </Menu>
              }
            >
              <Button icon="more" isDense isDisabled={workflow?.isDeleted} />
            </Popover2>
          )
        }
        navigateTooltip={tr.translateAsString(
          "workflow.details.open.workflow-space"
        )}
        canNavigateToList={!isInWorkflowSpace}
        navigateToList={handleOpenWorkflowSpace}
      >
        {isLoading && (
          <div className={classNames(Classes.DRAWER_BODY, styles.drawer)}>
            <Spinner size={SpinnerSize.LARGE}></Spinner>
          </div>
        )}

        {workflow && workflow.isDeleted && (
          <div
            className={classNames(
              Classes.DRAWER_BODY,
              styles.drawer,
              styles.deleted
            )}
          >
            {tr.translate("workflow.details.deleted")}
          </div>
        )}

        {workflow && !workflow.isDeleted && (
          <div className={classNames(Classes.DRAWER_BODY, styles.drawer)}>
            <WorkflowProperties workflow={workflow} />
            <Divider className={styles.divider} />
            <Tabs
              selectedTabId={
                isValidTabId(tab, "workflow") ? tab : DEFAULT_WORKFLOW_TAB
              }
              className={styles.tabs}
              onChange={handleSetTab}
              renderActiveTabPanelOnly
              center
              isDense
            >
              <Tab
                id="activities"
                title={tr.translate("workflow.details.tabs.activities")}
                panel={
                  <Activities entity={{ type: "process", id: workflowId }} />
                }
              />
              <Tab
                id="entities"
                title={tr.translate("workflow.details.tabs.files")}
                panel={
                  <WorkflowEntities
                    workflow={workflow}
                    disableEdit={!canLinkWorkflows}
                  />
                }
              />
              <Tab
                id="tasks"
                title={tr.translate("workflow.details.tabs.tasks")}
                panel={workflow && <WorkflowTasks workflow={workflow} />}
              />
            </Tabs>
          </div>
        )}
      </PanelDetails>
      {deleteDialog && (
        <DeleteAlert
          elementName={workflow?.title!}
          onDelete={() => {
            deleteProcess(workflowId);
            handleClose();
          }}
          onClose={() => setDeleteDialog(false)}
        />
      )}
    </>
  );
}
