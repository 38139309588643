import React from "react";
import { PageContents, PageTitle } from "../../components";
import { useTranslations } from "../../util";
import {
  ANALYTICS_VISITED_ARCHIVED_WORKFLOWS,
  useTrackEvent,
} from "../../analytics";
import ArchivedWorkflows from "./ArchivedWorkflows";

export default function ArchivedWorkflowsPage() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_ARCHIVED_WORKFLOWS);

  return (
    <PageContents domTitle={tr.translateAsString("page.title.workflow")}>
      <PageTitle icon="archive">
        {tr.translate("workflow.page.archived.title")}
      </PageTitle>
      <ArchivedWorkflows />
    </PageContents>
  );
}
