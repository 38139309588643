import { Icon } from "@aletiq/design-system";
import { ProjectType } from "@aletiq/types";
import React, { useMemo } from "react";
import { useProjectImage } from "../../features/project/hooks";

export default function InlineProjectThumbnail(props: {
  projectId: number;
  projectType: ProjectType;
  size?: number;
}) {
  const { size, projectType, projectId } = props;
  const iconSize = size ? size : 20;
  const { data: image } = useProjectImage(projectId);

  const imageUrl = useMemo(() => {
    if (!image) return;
    return URL.createObjectURL(image);
  }, [image]);

  return imageUrl ? (
    <img
      alt="Project illustration"
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        height: `${iconSize}px`,
        width: `${iconSize}px`,
        marginRight: "7px",
      }}
      src={imageUrl}
    />
  ) : (
    <Icon
      inline
      intent="primary"
      icon={projectType === "article" ? "projects" : "wrench"}
      style={{ padding: "2px" }}
      iconSize={iconSize - 4}
    />
  );
}
