import { EntityId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { searchKeys } from "./queries";

export default function useAccessSearchResult() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    (entity: EntityId) => api.search.accessSearchResult(entity),
    {
      onSettled: () => {
        queryClient.invalidateQueries(searchKeys.allHistory());
        queryClient.invalidateQueries(searchKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.passport.generic"),
        });
      },
    }
  );
}
