import {
  Document,
  DocumentQueryFilterParams,
  DocumentQueryOrder,
} from "@aletiq/types";
import React, { useCallback, useMemo, useState } from "react";
import {
  ActionBar,
  AlignRight,
  DefaultFilters,
  Dialog,
  Table,
} from "../../../../components";
import { useFilter, useSortKey } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { ResolvedDocument } from "../../../documents/Documents/documentTypes";
import {
  useDefaultDocumentColumnConfig,
  useDocuments,
  useDocumentTableColumns,
  useResolvedDocuments,
} from "../../../documents/hooks";
import styles from "./AddDocumentsDialog.module.scss";

export default function AddDocumentsDialog(props: {
  projectId: number;
  onClose: () => void;
  onSubmit: (nodes: Document[]) => void;
  activeDocuments: number[];
}) {
  const { activeDocuments, projectId } = props;
  const tr = useTranslations();

  const [selectedDocs, setSelectedDocs] = useState<ResolvedDocument[]>([]);
  const { filter, handleFilter } = useFilter<DocumentQueryFilterParams>({
    search: "",
    projects: [projectId],
  });

  const { sortState, setSortKey } = useSortKey<DocumentQueryOrder>("name");

  const { data: allNodes } = useDocuments({
    ...filter,
    properties: (filter.filters || []).length > 0,
    order: sortState.key,
    orderDirection: sortState.direction,
  });

  const nodes = useMemo(
    () =>
      allNodes?.list?.filter((node) => !activeDocuments.includes(node.id)) ??
      [],
    [allNodes, activeDocuments]
  );
  const { documents } = useResolvedDocuments(nodes);

  const handleSubmit = () => {
    const docs = nodes.filter((d) => selectedDocs.find((rd) => rd.id === d.id));
    props.onSubmit(docs);
    props.onClose();
  };

  const handleCancel = () => {
    props.onClose();
  };

  const handleSelect = useCallback(
    (documents: ResolvedDocument[]) => setSelectedDocs(documents),
    []
  );

  const { config } = useDefaultDocumentColumnConfig();
  const columns = useDocumentTableColumns(config, () => undefined);

  return (
    <Dialog
      title={tr.translateAsString(
        "project.version.add.document.dialog.form.title"
      )}
      icon="new-link"
      isOpen={true}
      onClose={props.onClose}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={handleCancel}
      className={styles.dialog}
    >
      <div className={styles.dialog_contents}>
        <p>{tr.translate("project.version.add.document.dialog.title")}</p>
        <ActionBar>
          <DefaultFilters
            objectType="document"
            filters={filter}
            handleFilter={handleFilter}
            autoFocusSearch
          />
        </ActionBar>
        <AlignRight />
        <Table
          className={styles.tile}
          onSelect={handleSelect}
          data={documents}
          columns={columns}
          sortOptions={{
            sortState,
            onSort: setSortKey,
          }}
        />
      </div>
    </Dialog>
  );
}
