import { RevisionSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { pdmKeys } from "./queries";

export default function useUnlockPart() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (values: {
      partId: number;
      revision: { spec: RevisionSpec; file: File } | null;
    }) => {
      const { partId, revision } = values;
      if (revision) {
        await api.pdm.uploadCadFile(partId, revision.spec, revision.file);
      }

      await api.pdm.checkinPart(partId);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.unlock.part"),
        });
      },
    }
  );
}
