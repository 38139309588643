import { PassportUpdate, Property, PropertyValue } from "@aletiq/types";
import { IconName, MaybeElement } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  Dialog,
  DropdownButton,
  Field,
  getPropertyTypeIcon,
  Menu,
  MenuItem,
  Popover2,
  SingleSelectionIcon,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import { useUpdatePassports } from "../../hooks";
import { ResolvedPassport } from "../../types";
import EditedPassportProperty from "./EditedPassportProperty";
import styles from "./EditPassportsPropertiesDialog.module.scss";

export type PassportProperty =
  | {
      type: "custom";
      property: Property;
      value: PropertyValue;
    }
  | { type: "state" | "client"; value: number | null }
  | { type: "description"; value: string };

export default function EditPassportsPropertiesDialog(props: {
  passports: ResolvedPassport[];
  onClose: () => void;
}) {
  const { passports, onClose } = props;
  const tr = useTranslations();

  const [property, setProperty] = useState<PassportProperty>();

  const { data: customProperties = [] } = useProperties("passport");
  const { mutate: updatePassport, isLoading } = useUpdatePassports();

  const passportsIds = passports.map((p) => p.id);
  const areAllArticles = passports.every((p) => p.parentProjectIsArticle);
  const areAllTools = passports.every((p) => p.parentProjectIsTool);
  const applyableProperties = customProperties.filter(
    (p) =>
      (p.group === "article" && areAllArticles) ||
      (p.group === "tool" && areAllTools) ||
      p.group === "all"
  );

  const aletiqPassportProperties: {
    type: "client" | "state" | "description";
    text: string;
    icon: IconName | MaybeElement;
  }[] = [
    {
      type: "description",
      text: tr.translateAsString("generic.label.description"),
      icon: "align-left",
    },
    {
      type: "client",
      text: tr.translateAsString("product.passports.client"),
      icon: <SingleSelectionIcon />,
    },
    {
      type: "state",
      text: tr.translateAsString("product.passports.state"),
      icon: <SingleSelectionIcon />,
    },
  ];

  const selectedPropertyLabel = !property
    ? tr.translateAsString("passport.dialogs.edit-property.name")
    : property.type === "custom"
    ? property.property.label
    : aletiqPassportProperties.find((p) => p.type === property.type)?.text ??
      "";

  const handleSelectAletiqProperty =
    (type: "client" | "state" | "description") => () => {
      if (type === "description") {
        setProperty({ type, value: "" });
        return;
      }
      setProperty({ type, value: null });
    };

  const handleSelectCustomProperty = (property: Property) => () => {
    setProperty({ type: "custom", property, value: null });
  };

  const handleSubmit = () => {
    if (!property) {
      return;
    }
    let updates: PassportUpdate[] = [];

    switch (property.type) {
      case "client":
      case "state": {
        updates = [{ type: property.type, value: property.value }];
        break;
      }
      case "custom": {
        updates = [
          {
            type: "property",
            value: { property: property.property.name, value: property.value },
          },
        ];
        break;
      }
    }

    updatePassport(
      { passports: passportsIds, updates },
      { onSuccess: onClose }
    );
  };

  return (
    <Dialog
      isOpen
      title={tr.translateAsString("passport.dialogs.edit-property.title")}
      icon="edit"
      onPrimaryClick={handleSubmit}
      onClose={onClose}
      onSecondaryClick={onClose}
      submitting={isLoading}
    >
      <Field
        label={
          <span className={styles.label}>
            {tr.translate("document.dialog.edit.property.property.picker")}
          </span>
        }
      >
        <Popover2
          fill
          content={
            <Menu>
              {aletiqPassportProperties.map(({ type, text, icon }) => (
                <MenuItem
                  key={type}
                  icon={icon}
                  text={text}
                  onClick={handleSelectAletiqProperty(type)}
                  intent="primary"
                />
              ))}
              {applyableProperties.map((p) => (
                <MenuItem
                  key={p.id}
                  text={p.label}
                  intent="primary"
                  icon={getPropertyTypeIcon(p.type)}
                  onClick={handleSelectCustomProperty(p)}
                  active={
                    property?.type === "custom" && property.property.id === p.id
                  }
                />
              ))}
            </Menu>
          }
        >
          <DropdownButton fill intent="primary" text={selectedPropertyLabel} />
        </Popover2>
      </Field>

      {property && (
        <EditedPassportProperty
          property={property}
          onSetProperty={setProperty}
          label={selectedPropertyLabel}
        />
      )}
    </Dialog>
  );
}
