import { ContentState, convertToRaw } from "draft-js";

export default function mentionsFromDraftJs(content: ContentState): string[] {
  const rawContent = convertToRaw(content);
  const mentions: string[] = [];
  const entities = rawContent.entityMap;

  for (const key in entities) {
    mentions.push(entities[key].data.mention.id);
  }

  return mentions;
}
