import { EntityId } from "@aletiq/types";
import { useMutation } from "react-query";
import useApi from "../../app/useApi";
import { useDownloadQueue, useToaster } from "../../hooks";
import { useTranslations } from "../../util";

export default function useExportActivties(entity: EntityId) {
  const api = useApi();
  const tr = useTranslations();
  const toaster = useToaster();
  const { handleFile } = useDownloadQueue();

  return useMutation(async () => api.activity.exportActivities(entity), {
    onSuccess: (activities) => {
      // download activities as a CSV file
      handleFile(
        new File([activities], `${entity.type}-${entity.id}-activities.csv`, {
          type: "text/csv",
        })
      );
    },
    onError: () => {
      toaster.show(
        {
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.generic"),
        },
        "workflow-export"
      );
    },
  });
}
