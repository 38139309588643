import { MenuTypeIntent } from "@aletiq/design-system";
import { TaskStatus } from "@aletiq/types";
import { Translations } from "../../util";

export function statusToIntent(status: TaskStatus): MenuTypeIntent {
  switch (status) {
    case "not_started":
      return "danger";
    case "started":
      return "warning";
    case "done":
      return "success";
  }
}

export function statusToText(
  tr: Translations,
  status: TaskStatus,
  shorthand?: boolean
): string {
  switch (status) {
    case "not_started":
      return tr.translateAsString(
        shorthand
          ? "taskstatus.not_started.shorthand"
          : "taskstatus.not_started"
      );
    case "started":
      return tr.translateAsString(
        shorthand ? "taskstatus.started.shorthand" : "taskstatus.started"
      );
    case "done":
      return tr.translateAsString(
        shorthand ? "taskstatus.done.shorthand" : "taskstatus.done"
      );
  }
}
