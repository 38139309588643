import React from "react";
import styles from "./OffsetCell.module.scss";

export default function OffsetCell(props: {
  depth: number;
  children: React.ReactNode;
}) {
  const { depth, children } = props;
  return (
    <div className={styles.cell} style={{ marginLeft: `${depth * 23}px` }}>
      {children}
    </div>
  );
}
