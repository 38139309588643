import { Card, HighlightedText } from "@aletiq/design-system";
import { Process, Task } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useCurrentUser, useHasPermission } from "../../../../hooks";
import { sortByNumber } from "../../../../util";
import { useWorkflowColor } from "../../hooks";
import EmptyWorkflow from "../EmptyWorkflow";
import WorkflowCardHeader from "../WorkflowCardHeader";
import WorkflowTaskTable from "../WorkflowTaskTable";
import TaskRow from "./TaskRow";
import styles from "./WorkflowView.module.scss";

export default function WorkflowBasicView(props: {
  canUpdateWorkflow: boolean;
  isFolded: boolean;
  isSelected: boolean;
  onSetActive: (active: {
    task?: number | undefined;
    workflow?: number | undefined;
  }) => void;
  onStartEdit: () => void;
  tasks: Task[];
  toggleFold: (workflow: number) => void;
  workflow: Process;
  search?: string;
}) {
  const {
    canUpdateWorkflow,
    isFolded,
    isSelected,
    tasks,
    toggleFold,
    onSetActive,
    onStartEdit,
    workflow,
    search,
  } = props;

  const { data: currentUser } = useCurrentUser();
  const canUpdateOwnTaskStatus = useHasPermission("update:own-task-status");

  const hasUnresolvedDeps = (task: Task) =>
    tasks
      .filter((t) => task.dependencies.includes(t.id))
      .filter((t) => t.status !== "done").length !== 0;

  const sortedTasks = useMemo(
    () => sortByNumber(tasks, (task) => task.order),
    [tasks]
  );

  const workflowColor = useWorkflowColor(workflow);

  const handleOpenDetails = () => onSetActive({ workflow: workflow.id });

  const handleToggleFold = () => toggleFold(workflow.id);

  return (
    <Card
      isFolded={isFolded}
      isActive={isSelected}
      onToggleFold={handleToggleFold}
      onClick={handleOpenDetails}
      icon="flow-linear"
      title={
        <Text ellipsize>
          <HighlightedText text={workflow.title} highlight={search} />
        </Text>
      }
      headerActions={
        <WorkflowCardHeader
          workflow={workflow}
          disableEdit={!canUpdateWorkflow}
          onStartEdit={onStartEdit}
        />
      }
      className={classNames(!isFolded && styles.open_card)}
    >
      {tasks.length === 0 ? (
        <EmptyWorkflow />
      ) : (
        <WorkflowTaskTable color={workflow.color} noActions>
          {sortedTasks.map((task) => {
            const hasLockedDeps = hasUnresolvedDeps(task);
            const isTaskOwner = task.owners.includes(currentUser?.id!);
            const hasStatusUpdateRights =
              (isTaskOwner && canUpdateOwnTaskStatus) || canUpdateWorkflow;

            return (
              <tr
                className={classNames(isSelected && styles.selected)}
                key={task.id}
              >
                <TaskRow
                  task={task}
                  hasStatusUpdateRights={hasStatusUpdateRights}
                  hasLockedDependencies={hasLockedDeps}
                  workflowTasks={tasks}
                  workflowColor={workflowColor}
                />
              </tr>
            );
          })}
        </WorkflowTaskTable>
      )}
    </Card>
  );
}
