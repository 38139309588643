import { RevisionSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { pdmKeys } from "./queries";

export default function useSavePartRevision() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { partId: number; revisionSpec: RevisionSpec }) => {
      const { partId, revisionSpec } = values;
      await api.pdm.savePartRevision(partId, revisionSpec);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}
