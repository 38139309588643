import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks/toaster";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { projectKeys } from "./queries";

export default function useAddArticles(toolId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (articles: number[]) => {
      for (const articleId of articles) {
        await api.project.addToolingRequirement(articleId, {
          tool: toolId,
          quantity: 1,
          comment: "",
        });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.toolingArticlesById(toolId));
        queryClient.invalidateQueries(projectKeys.toolingRequirement);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.product.article.add"),
        });
      },
    }
  );
}
