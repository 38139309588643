import { useContext, useEffect } from "react";
import { AnalyticsContext } from "./providers";
import { Analytics } from "./types";

export function useAnalytics(): Analytics {
  return useContext(AnalyticsContext);
}

export function useTrackEvent(event: string, data?: any) {
  const analytics = useAnalytics();
  const strData = JSON.stringify(data ?? null); // Stringify data to avoid tracking duplicated events
  useEffect(() => {
    analytics.track(event, JSON.parse(strData));
  }, [analytics, event, strData]);
}
