import {
  Task,
  TaskModel,
  TaskSpec,
  TaskStatus,
  TaskUpdateSpec,
} from "@aletiq/types";
import { partitionWith, sortByNumber } from "../../../../util";

type TaskEditInfo = {
  id: number;
  spec: TaskSpec;
  deps: number[];
  order: number;
  workflow?: number;
  status?: TaskStatus;
};

export type TaskEditorState = {
  deleted: number[];
  edited: TaskEditInfo[];
  created: TaskEditInfo[];
};

export function makeNewTaskOrder(
  order: number,
  dragFrom: number,
  dragTo: number
) {
  const lowest = Math.min(dragFrom, dragTo);
  const highest = Math.max(dragFrom, dragTo);
  const isDraggedDown = lowest === dragFrom;

  if (order === dragFrom) {
    return dragTo;
  }
  if (order >= lowest && order <= highest && isDraggedDown) {
    return order - 1;
  }
  if (order >= lowest && order <= highest && !isDraggedDown) {
    return order + 1;
  }
  return order;
}

export function removeTaskFromDeps(id: number, task: TaskEditInfo) {
  return {
    ...task,
    deps: task.deps.filter((d) => d !== id),
  };
}

export function makeTaskAfterDelete(
  order: number,
  id: number,
  task: TaskEditInfo
) {
  return {
    ...task,
    order: task.order > order ? task.order - 1 : task.order,
    deps: task.deps.filter((d) => d !== id),
  };
}

export function makeDefaultTaskInfo(
  id: number,
  order: number,
  workflow?: number
): TaskEditInfo {
  return {
    id,
    order,
    spec: {
      title: "",
      description: "",
      owners: [],
      dueDate: null,
    },
    deps: [],
    status: "not_started",
    workflow: workflow ?? 0,
  };
}

export function makeEditInfoFromTask(task: Task): TaskEditInfo {
  return {
    id: task.id,
    workflow: task.process,
    spec: {
      title: task.title,
      description: task.description,
      owners: task.owners,
      dueDate: task.dueDate,
    },
    status: task.status,
    deps: task.dependencies,
    order: task.order,
  };
}

export function makeTaskFromEditInfo(info: TaskEditInfo): Task {
  return {
    status: info.status ?? "not_started",
    process: info.workflow ?? 0,
    owners: info.spec.owners,
    id: info.id,
    dueDate: info.spec.dueDate,
    title: info.spec.title,
    description: info.spec.description,
    dependencies: info.deps,
    order: info.order,
  };
}

export function makeEditInfoFromModel(
  task: TaskModel,
  idx: number,
  workflowCreator: number
): TaskEditInfo {
  return {
    id: task.id,
    status: "not_started",
    spec: {
      owners: task.owners.map((user) =>
        user.type === "specific" ? user.user : workflowCreator
      ),
      dueDate: null,
      title: task.title,
      description: task.description,
    },
    deps: task.dependencies,
    order: idx + 1,
  };
}

export function makeIdentifiedTasks(
  ids: Record<number, number>,
  tasks: TaskEditInfo[]
): TaskUpdateSpec[] {
  return tasks.map((task) => {
    const [depsOnOldRow, depsOnNewRow] = partitionWith(task.deps, (d) => d > 0);
    const { id, spec, order } = task;
    return {
      id: ids[id],
      title: spec.title,
      description: spec.description,
      dueDate: spec.dueDate,
      owners: spec.owners,
      order: order,
      dependencies: [
        ...depsOnOldRow.map((d) => ids[d]),
        ...depsOnNewRow.map((d) => ids[d]),
      ],
    };
  });
}

export function makeTasksFromEditorState(state: TaskEditorState) {
  return sortByNumber(
    [...state.edited, ...state.created].map(makeTaskFromEditInfo),
    (task) => task.order
  );
}

export default TaskEditInfo;
