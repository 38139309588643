import React from "react";
import { ColumnOption, SelectColumns } from "../../../components";
import { ColumnOptionConfig, useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { useProperties } from "../../administration/Attributes/hooks";
import { DocumentColumn } from "../hooks/useDocumentTableColumns";

export default function SelectDocumentColumn(props: {
  columnConfig: ColumnOptionConfig<DocumentColumn> | undefined;
  onToggleColumn: (column: DocumentColumn | number) => void;
  children: React.ReactNode;
}) {
  const { columnConfig, onToggleColumn, children } = props;
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("document");
  const canEditDefaultColumns = useHasPermission(
    "update:default-property-display"
  );

  const columnOptions: ColumnOption<DocumentColumn>[] = [
    {
      id: "name",
      label: tr.translate("generic.label.name"),
      isMandatory: true,
    },
    {
      id: "lastRevisionName",
      label: tr.translate("filelist.last-revision"),
    },
    {
      id: "ownerName",
      label: tr.translate("admin.attribute.documents.owner"),
    },
    {
      id: "createdAt",
      label: tr.translate("generic.label.creation-date.title"),
    },
    {
      id: "updatedAt",
      label: tr.translate("generic.label.last-update.title"),
    },
    ...properties,
  ];

  return (
    <SelectColumns
      config={columnConfig}
      options={columnOptions}
      onToggleColumn={onToggleColumn}
      canEditDefaultColumns={canEditDefaultColumns}
      objectType="document"
    >
      {children}
    </SelectColumns>
  );
}
