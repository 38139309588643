import { PartType } from "@aletiq/types";
import React from "react";
import { Menu, MenuItem, PartIcon } from "../../../components";
import { isNotUndefined, useTranslations } from "../../../util";

export default function PartCreationMenu(props: {
  allowStandard?: boolean;
  allowDrawing?: boolean;
  allowNormal?: boolean;
  type?: PartType;
  onCreatePart: (type: PartType, isStandard: boolean) => void;
}) {
  const { allowStandard, allowNormal, allowDrawing, type, onCreatePart } =
    props;
  const tr = useTranslations();

  const allowAssembly = !type || type === "assembly";
  const allowPart = !type || type === "part";

  const drawing = allowDrawing ? ("drawing" as const) : undefined;
  const assembly = allowAssembly ? ("assembly" as const) : undefined;
  const part = allowPart ? ("part" as const) : undefined;

  const types = allowNormal
    ? [drawing, assembly, part].filter(isNotUndefined)
    : [];
  const standardTypes = allowStandard
    ? [assembly, part].filter(isNotUndefined)
    : [];

  return (
    <Menu isDense intent="minimal">
      {types.map((type) => (
        <MenuItem
          key={type}
          isDense
          intent="primary"
          icon={<PartIcon type={type} />}
          text={tr.translate("part.dialog.new.title", {
            type,
          })}
          onClick={() => onCreatePart(type, false)}
        />
      ))}

      {standardTypes.map((type) => (
        <MenuItem
          key={type}
          isDense
          intent="primary"
          icon={<PartIcon type={type} isStandard />}
          text={tr.translate(
            allowNormal
              ? "part.dialog.new.title.standard"
              : "part.dialog.new.title",
            {
              type,
            }
          )}
          onClick={() => onCreatePart(type, true)}
        />
      ))}
    </Menu>
  );
}
