import { Spinner } from "@blueprintjs/core";
import React from "react";
import { PageContents, PageTitle } from "../../../components";
import { useTranslations } from "../../../util";
import {
  ANALYTICS_VISITED_WORKFLOW_ANALYSIS,
  useTrackEvent,
} from "../../../analytics";
import { useProcessModels } from "../tasksSlice";
import ModelAnalysis from "./ModelAnalysis";
import styles from "./ModelAnalysis.module.scss";

export default function ModelAnalysisPage() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_WORKFLOW_ANALYSIS);

  const { data: loadedModels = [], isLoading } = useProcessModels();

  return (
    <PageContents domTitle={tr.translateAsString("page.title.analysis")}>
      <PageTitle icon="timeline-bar-chart">
        {tr.translate("sidebar.analysis")}
      </PageTitle>
      {!isLoading && (
        <ModelAnalysis defaultModels={loadedModels.map((model) => model.id)} />
      )}
      {isLoading && (
        <div className={styles.center}>
          <Spinner />
        </div>
      )}
    </PageContents>
  );
}
