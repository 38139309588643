import { Tabs } from "@aletiq/design-system";
import { Tab } from "@blueprintjs/core";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ANALYTICS_VISITED_ATTRIBUTES,
  useTrackEvent,
} from "../../../analytics";
import { PageContents, PageTitle } from "../../../components";
import { useFilter, useHasPermission, useNavigate } from "../../../hooks";
import { parsePageLocation, useTranslations } from "../../../util";
import AdministrationTabs from "../common/AdministrationTabs/AdministrationTabs";
import styles from "./Attributes.module.scss";
import { DocumentAttributes } from "./DocumentAttributes";
import PartAttributes from "./PartAttributes";
import PassportAttributes from "./PassportAttributes";
import PassportFileAttributes from "./PassportFileAttributes";
import ProductAttributes from "./ProductAttributes";
import { PropertyFilters } from "./types";

type TabId = "document" | "part" | "product" | "passport" | "passport-file";
const VALID_TABS = ["document", "part", "product", "passport", "passport-file"];

export function useInitialPropertyPageTab(): TabId | null {
  const pageLocation = parsePageLocation(useLocation());
  const tabId = pageLocation.pageTab ?? undefined;

  if (tabId && VALID_TABS.includes(tabId)) {
    return tabId as TabId;
  }
  return null;
}

export default function Attributes() {
  const initialTab = useInitialPropertyPageTab();
  const tr = useTranslations();

  const [tab, setTab] = useState<TabId>(initialTab || "document");
  const { filter, handleFilter, handleClear } = useFilter<PropertyFilters>({
    search: "",
  });

  const navigate = useNavigate();
  useTrackEvent(ANALYTICS_VISITED_ATTRIBUTES);

  const canAccessPassportAttributes = useHasPermission(
    "read:passport-attributes"
  );
  const canUpdateCustomAttributes = useHasPermission(
    "update:custom-attributes"
  );

  const handleSetTab = (tabId: TabId) => {
    setTab(tabId);
    // reset the initial page tab in the URL
    // to avoid opening the popover on each tab change
    navigate({ pageTab: null });
  };

  return (
    <PageContents leftPanel={<AdministrationTabs />}>
      <PageTitle icon="properties">
        {tr.translate("admin.properties")}
      </PageTitle>
      <div className={styles.centered}>
        <Tabs
          selectedTabId={tab}
          onChange={handleSetTab}
          renderActiveTabPanelOnly
        >
          <span className={styles.subtitle}>
            {tr.translate("admin.attribute.tab.select")}
          </span>
          <Tab
            id="document"
            title={tr.translate("admin.attribute.tab.document")}
            panel={
              <DocumentAttributes
                filters={filter}
                handleFilter={handleFilter}
                handleClear={handleClear}
                openPopoverOnStart={initialTab === "document"}
              />
            }
            disabled={!canUpdateCustomAttributes}
          />
          <Tab
            id="part"
            title={tr.translate("admin.attribute.tab.part")}
            panel={
              <PartAttributes
                filters={filter}
                handleFilter={handleFilter}
                handleClear={handleClear}
              />
            }
          />
          <Tab
            id="product"
            title={tr.translate("admin.attribute.tab.product")}
            panel={
              <ProductAttributes
                filters={filter}
                handleFilter={handleFilter}
                handleClear={handleClear}
                openPopoverOnStart={initialTab === "product"}
              />
            }
            disabled={!canUpdateCustomAttributes}
          />
          <Tab
            id="passport"
            title={tr.translate("admin.attribute.tab.passport")}
            panel={
              <PassportAttributes
                filters={filter}
                handleFilter={handleFilter}
                handleClear={handleClear}
              />
            }
            disabled={!canAccessPassportAttributes}
          />
          <Tab
            id="passport-file"
            title={tr.translate("admin.attribute.tab.passport-file")}
            panel={
              <PassportFileAttributes
                filters={filter}
                handleFilter={handleFilter}
                handleClear={handleClear}
              />
            }
            disabled={!canAccessPassportAttributes}
          />
        </Tabs>
      </div>
    </PageContents>
  );
}
