import { PartFormat } from "@aletiq/types";
import React from "react";
import { toggle, useTranslations } from "../../util";
import FilterMenu from "./FilterMenu";
import FilterMenuItem from "./FilterMenuItem";
import FilterTemplate from "./FilterTemplate";

export default function PartFormatFilter(props: {
  selected: PartFormat[];
  onSelect: (values?: PartFormat[]) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const { selected, onClear, onSelect } = props;

  const handleSelect = (value: PartFormat) => () =>
    onSelect(toggle(selected, value));

  return (
    <FilterTemplate
      count={selected.length}
      onClear={onClear}
      placeholder={tr.translateAsString("part.details.format")}
      content={
        <FilterMenu>
          <FilterMenuItem
            selected={selected.includes("solidworks")}
            onSelect={handleSelect("solidworks")}
            text="Solidworks"
          />
          <FilterMenuItem
            selected={selected.includes("catia")}
            onSelect={handleSelect("catia")}
            text="Catia"
          />
        </FilterMenu>
      }
    />
  );
}
