import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { projectKeys } from "../../hooks/queries";

export default function useAddOperationTools(
  projectId: number,
  bill: number,
  operation: number
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (tools: number[]) => {
      if (tools.length !== 0) {
        for (const toolId of tools) {
          await api.operations.addProjectOperationTool(
            projectId,
            bill,
            operation,
            toolId
          );
        }
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(projectKeys.allBills);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
