import { Tag, Tooltip } from "@aletiq/design-system";
import { Property, PropertyOption } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React from "react";
import { CellProps } from "react-table";
import { isNotUndefined } from "../../util";
import { AlignRight } from "../AlignRight";
import { DateWithSpaces } from "../date";
import { UserInitialsList } from "../user-list";

export default function CustomPropertyCell<T extends object>(props: {
  cell: CellProps<T>;
  property: Property;
}) {
  const { cell, property } = props;

  if (!cell.value) {
    return null;
  }

  if (property.type === "string" || property.type === "number") {
    return <>{cell.value}</>;
  }

  if (property.type === "date" && cell.value) {
    return <DateWithSpaces date={cell.value as string} />;
  }

  if (property.type === "user") {
    return <UserInitialsList users={cell.value ?? []} type="small" />;
  }

  if (property.type === "enum") {
    const valueLabel = property.options.find(
      (option) => option.name === cell.value
    )?.label;

    return <>{valueLabel}</>;
  }

  //multi enum property
  const objectValues: PropertyOption[] = cell.value
    .map((valueName: string) =>
      property.options.find((option) => option.name === valueName)
    )
    .filter(isNotUndefined);

  const firstValue = objectValues.length > 0 ? objectValues[0] : null;
  const hiddenValues = objectValues.length > 1 ? objectValues.slice(1) : [];

  return (
    <>
      {firstValue && (
        <Tag isDense unclickable>
          <Text ellipsize>{firstValue.label}</Text>
        </Tag>
      )}
      <AlignRight />
      {hiddenValues.length > 0 && (
        <span>
          <Tooltip
            content={
              <>
                {hiddenValues.map((option) => (
                  <div key={option.value}>{option.label}</div>
                ))}
              </>
            }
          >
            <Tag isDense>+{hiddenValues.length}</Tag>
          </Tooltip>
        </span>
      )}
    </>
  );
}
