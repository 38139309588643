import { ButtonSP, Select } from "@aletiq/design-system";
import { User } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../util";
import { default as UserComponent } from "../User/User";

export default function UserSingleSelect(props: {
  users: User[];
  selectedUser?: number;
  onSubmit: (newUsers: number) => void;
  isFill?: boolean;
  intent?: "default" | "primary";
}) {
  const { users, selectedUser, onSubmit, isFill, intent = "default" } = props;
  const tr = useTranslations();

  const usersItem = users.map((user) => ({
    key: user.id,
    text: user.displayName,
    node: <UserComponent userId={user?.id} size="small" />,
  }));
  return (
    <Select
      filterable
      intent="outlined"
      onItemSelect={onSubmit}
      items={usersItem}
      isDense
      isFill
    >
      <ButtonSP
        view="outlined"
        color={intent}
        isDense
        rightIcon="caret-down"
        isFill={isFill}
      >
        {selectedUser ? (
          <UserComponent userId={selectedUser} size="small" />
        ) : (
          tr.translate("generic.label.none")
        )}
      </ButtonSP>
    </Select>
  );
}
