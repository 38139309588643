import React from "react";
import { EditPartBomDialog } from "../../project/dialogs";
import {
  ApproveAssemblyIterationDialog,
  LockPartDialog,
  SharePartDialog,
  UnlockPartDialog,
  UploadDesignFileDialog,
} from "../dialogs";

export type PartDialog =
  | "upload-design"
  | "lock-part"
  | "unlock-part"
  | "save-assembly-iteration"
  | "edit-bom"
  | "edit-access";

export default function PartDialogs(props: {
  partId: number;
  dialog?: PartDialog;
  onClose: () => void;
}) {
  const { partId, dialog, onClose } = props;

  if (!dialog) return null;

  switch (dialog) {
    case "lock-part": {
      return <LockPartDialog partId={partId} onClose={onClose} />;
    }
    case "unlock-part": {
      return <UnlockPartDialog onClose={onClose} partId={partId} />;
    }
    case "upload-design": {
      return <UploadDesignFileDialog partId={partId} onClose={onClose} />;
    }
    case "save-assembly-iteration": {
      return (
        <ApproveAssemblyIterationDialog partId={partId} onClose={onClose} />
      );
    }
    case "edit-bom": {
      return <EditPartBomDialog partId={partId} onClose={onClose} />;
    }
    case "edit-access": {
      return <SharePartDialog partId={partId} onClose={onClose} />;
    }
  }
  return <></>;
}
