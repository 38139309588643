import React from "react";
import {
  Button,
  ConfigOptionIcon,
  Field,
  Menu,
  SideTabs,
  TabItem,
} from "../../../../components";
import { useNavigate } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProject } from "../../hooks";
import ProductImage from "../ProductImage";
import ProjectMenu from "../ProjectMenu";
import styles from "./ProjectTabs.module.scss";

export default function ProjectTabs(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();

  const navigate = useNavigate();
  const { data: project } = useProject(projectId);

  const baseUrl = `/project/${projectId}`;
  const projectType = project?.isTool ? "tool" : "article";

  const handleNavigateToProductList = () => {
    navigate({ path: "/project", search: `?type=${projectType}` });
  };

  return (
    <SideTabs>
      <div className={styles.top_buttons}>
        <Button icon="arrow-left" onClick={handleNavigateToProductList} isDense>
          {project?.isArticle && tr.translate("navbar.projects.articles")}
          {project?.isTool && tr.translate("navbar.projects.tools")}
        </Button>

        {project && <ProjectMenu project={project} />}
      </div>
      <ProductImage
        productId={projectId}
        height={120}
        width={248}
        noPlaceholder
        className={styles.product_image}
        canPreview
      />
      <Field
        className={styles.product_name}
        label={tr.translate("project.details.type.label", {
          type: projectType,
        })}
      >
        {project?.name}
      </Field>
      <Menu isDense intent="minimal" className={styles.menu}>
        <TabItem
          text={tr.translateAsString("sidebar.home")}
          icon="home"
          url={baseUrl}
          isExact
        />
        <TabItem
          text={tr.translateAsString("sidebar.processes")}
          icon="flow-linear"
          url={`${baseUrl}/workflows`}
        />
        <TabItem
          text={tr.translateAsString("generic.label.documents")}
          icon="folder-close"
          url={`${baseUrl}/documents`}
        />
        <TabItem
          text={tr.translateAsString("sidebar.pdm.parts")}
          icon="cube"
          url={`${baseUrl}/cad`}
        />

        {project?.isArticle && (
          <TabItem
            text={tr.translateAsString("sidebar.project.tooling")}
            icon="wrench"
            url={`${baseUrl}/tooling`}
          />
        )}
        {project?.isTool && (
          <TabItem
            text={tr.translateAsString("sidebar.project.articles")}
            icon="projects"
            url={`${baseUrl}/articles`}
          />
        )}
      </Menu>

      {/* definition */}
      <span className={styles.subtitle}>
        {tr.translate("sidebar.project.configuration")}
      </span>
      <Menu isDense intent="minimal" className={styles.menu}>
        <TabItem
          text={tr.translateAsString("sidebar.project.definition")}
          icon="diagram-tree"
          url={`${baseUrl}/nomenclature`}
        />
        {project?.isTool && (
          <TabItem
            text={tr.translateAsString("sidebar.versions")}
            icon="git-commit"
            url={`${baseUrl}/versions`}
          />
        )}
        {project?.isArticle && (
          <TabItem
            text={tr.translateAsString("sidebar.operations")}
            icon="numbered-list"
            url={`${baseUrl}/operation-bills`}
          />
        )}

        <TabItem
          text={tr.translateAsString("sidebar.project.options")}
          icon={<ConfigOptionIcon />}
          url={`${baseUrl}/config`}
        />
      </Menu>

      {/* manufacturing */}
      <span className={styles.subtitle}>
        {tr.translate("sidebar.project.production")}
      </span>
      <Menu isDense intent="minimal" className={styles.menu}>
        <TabItem
          text={tr.translateAsString("sidebar.passports")}
          icon="id-number"
          url={`${baseUrl}/passports`}
        />
      </Menu>
    </SideTabs>
  );
}
