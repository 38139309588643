import React from "react";
import { Dialog } from "../../../components";
import { useTranslations } from "../../../util";
import { useDeletePartDraft } from "../hooks";

export default function DeleteDocumentDraftDialog(props: {
  partId: number;
  iterationId: number;
  onClose: () => void;
}) {
  const { partId, iterationId, onClose } = props;
  const tr = useTranslations();

  const { mutate: deletPartDraftRevision } = useDeletePartDraft(partId);

  const handleSubmit = () => {
    deletPartDraftRevision(iterationId);
    onClose();
  };

  return (
    <Dialog
      isOpen
      icon="trash"
      intent="danger"
      onClose={onClose}
      title={tr.translateAsString("part.dialog.delete.draft.title")}
      onPrimaryClick={handleSubmit}
    >
      {tr.translate("part.dialog.delete.draft.description")}
    </Dialog>
  );
}
