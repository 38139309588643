import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";

export default function CatiaAssemblyIcon(
  props: { size?: string | number } & React.HTMLProps<any>
) {
  const { size = 20 } = props;
  return (
    <span {...props} className={classNames(Classes.ICON, props.className)}>
      <svg
        width={size}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3521_30547)">
          <path
            d="M19.0729 16.1701L19.5 14.4383L17.9798 13.7657L17.8693 13.042L19.1208 11.9469L18.1982 10.4219L16.6488 11.0211L16.0582 10.5873L16.1688 8.92758L14.4374 8.5L13.7646 10.0219L13.0401 10.1325L11.9455 8.88318L10.4193 9.80514L11.0178 11.3507L10.5837 11.9407L8.92743 11.83L8.5 13.5618L10.0206 14.2346L10.131 14.9582L8.87943 16.053L9.80193 17.5784L11.3514 16.9792L11.9419 17.413L11.8314 19.0723L13.5627 19.5L14.2357 17.9783L14.9602 17.8676L16.0546 19.1169L17.5813 18.1948L16.9826 16.6497L17.4169 16.0596L19.0729 16.1701ZM15.1756 15.9434C14.1017 16.5911 12.7047 16.247 12.0551 15.1747C11.4069 14.1016 11.7516 12.7058 12.8246 12.0567C13.8985 11.409 15.2954 11.7533 15.945 12.8253C16.593 13.8984 16.2487 15.2943 15.1756 15.9434Z"
            fill="#F4FC8F"
            stroke="#19191D"
          />
          <path
            d="M9.70391 10.0807L9.05831 8.55691L9.47281 7.95427L11.1283 8.01229L11.5 6.27027L9.96241 5.64681L9.8295 4.92768L11.0434 3.79522L10.0752 2.30175L8.54857 2.94861L7.94582 2.53406L8.00374 0.873884L6.26341 0.501437L5.64007 2.04187L4.92074 2.175L3.78969 0.962372L2.29612 1.93056L2.9419 3.45439L2.52735 4.0572L0.871818 3.99891L0.5 5.74104L2.03781 6.36456L2.17064 7.08368L0.956677 8.21609L1.92494 9.70951L3.45155 9.06275L4.05431 9.47725L3.99636 11.1373L5.73678 11.5098L6.36006 9.96939L7.07929 9.83631L8.21044 11.0489L9.70391 10.0807ZM7.23376 7.90878C6.18298 8.58896 4.77853 8.28929 4.09686 7.23936C3.41661 6.18853 3.71652 4.78412 4.76631 4.10258C5.81723 3.4223 7.22158 3.72207 7.90318 4.7719C8.58341 5.82283 8.2838 7.22708 7.23376 7.90878Z"
            fill="#54FBFF"
            stroke="#19191D"
          />
        </g>
        <defs>
          <clipPath id="clip0_3521_30547">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
