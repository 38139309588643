import React from "react";
import { useTenant, useUsers } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { AlertPopover, Button, Icon } from "../../../../components";
import styles from "./ShareDocumentDialog.module.scss";

export default function OpenAccessRow(props: { onRemove: () => void }) {
  const { onRemove } = props;
  const tr = useTranslations();

  const { data: tenant } = useTenant();
  const { data: users = [] } = useUsers();
  const userCount = users.length;

  return (
    <div className={styles.row}>
      <div className={styles.row__left}>
        <div className={styles.groupIcon}>
          <Icon icon="office" iconSize={18} />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{tenant?.name}</div>
          <div className={styles.info}>
            {tr.translate("document.dialog.share.groups.usercount", {
              count: userCount,
            })}
          </div>
        </div>
      </div>
      <div>
        <AlertPopover
          isDense
          content={tr.translate("document.dialog.share.popup.remove-group")}
          onPrimaryClick={onRemove}
        >
          <Button intent="outlined">
            {tr.translate("document.dialog.share.button.remove")}
          </Button>
        </AlertPopover>
      </div>
    </div>
  );
}
