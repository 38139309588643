import { ToasterSP } from "@aletiq/design-system";
import { Toaster } from "@blueprintjs/core";
import React, { createContext, Fragment, ReactNode, useState } from "react";

export const ToasterContext = createContext<Toaster | null>(null);

export default function ToasterProvider(props: { children?: ReactNode }) {
  const { children } = props;
  const [toaster, setToaster] = useState<Toaster | null>(null);

  const handleRef = (ref: Toaster) => {
    if (toaster !== ref) {
      setToaster(ref);
    }
  };

  return (
    <Fragment>
      <ToasterSP ref={handleRef} />
      <ToasterContext.Provider value={toaster}>
        {toaster && children}
      </ToasterContext.Provider>
    </Fragment>
  );
}
