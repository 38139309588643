import classNames from "classnames";
import React from "react";
import { useTranslations } from "../../util";
import TreeItemLoading from "../loading";
import styles from "./TreeItemName.module.scss";

export default function TreeItemName(props: {
  name?: string;
  isHighlighted?: boolean;
  isIgnored?: boolean;
  isLoading?: boolean;
}) {
  const { name, isHighlighted, isIgnored, isLoading } = props;

  const tr = useTranslations();

  const labelStyle = classNames(
    styles.selectable,
    isHighlighted ? styles.highlighted : ""
  );

  return isLoading ? (
    <TreeItemLoading />
  ) : name ? (
    <span className={labelStyle}>{name}</span>
  ) : isIgnored ? (
    <em className={styles.emphasis}>
      {tr.translate("cad.bom.edition.bom.ignored")}
    </em>
  ) : (
    <em className={styles.emphasis}>
      {tr.translate("cad.bom.edition.bom.unknown")}
    </em>
  );
}
