import { PassportQueryFilterParams, ProjectType } from "@aletiq/types";
import React, { useState } from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  ClientFilter,
  CustomPropertyFilter,
  DateFilter,
  EditColumnButton,
  MoreFilters,
  PassportStateFilter,
  ProjectFilter,
  ProjectVersionFilter,
  SearchInput,
  Tooltip,
} from "../../../../components";
import {
  ColumnOptionConfig,
  HandleClear,
  HandleFilter,
  useCustomFilters,
  useHasPanelOpen,
  useHasPermission,
} from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import { useProject } from "../../../project/hooks";
import { PassportCreationDialog, PassportFunnel } from "../../dialogs";
import { PassportColumn } from "../../hooks/usePassportTableColumns";
import SelectPassportColumns from "../SelectPassportColumns";
import styles from "./PassportActionBar.module.scss";

export function PassportActionBar(props: {
  filter: PassportQueryFilterParams;
  handleFilter: HandleFilter<PassportQueryFilterParams>;
  handleClear: HandleClear<PassportQueryFilterParams>;
  projectId?: number;
  projectType?: ProjectType;
  columnConfig: ColumnOptionConfig<PassportColumn>;
  onToggleColumn: (column: PassportColumn) => void;
}) {
  const {
    filter: passportFilter,
    handleClear,
    handleFilter,
    projectId,
    projectType,
    columnConfig,
    onToggleColumn,
  } = props;
  const tr = useTranslations();

  const [newPassportDialog, setNewPassportDialog] = useState(false);

  const canCreatePassports = useHasPermission("create:passports");
  const { data: parentProject } = useProject(projectId ?? 0);
  const { data: properties = [] } = useProperties("passport");
  const isPanelOpen = useHasPanelOpen();

  const handleToggleNewPassportDialog = () =>
    setNewPassportDialog(!newPassportDialog);

  const filteredProperties = projectType
    ? properties.filter((p) => p.group === projectType || p.group === "all")
    : properties;

  const { setCustomFilter, clearCustomFilter } = useCustomFilters(
    passportFilter,
    handleFilter
  );

  return (
    <>
      <ActionBar>
        <Tooltip
          position="top-left"
          content={tr.translateAsString("passport.page.filter.tooltip-search")}
        >
          <SearchInput
            isDense
            intent="primary"
            value={passportFilter.search ?? ""}
            className={isPanelOpen ? styles.searchbar_small : styles.searchbar}
            onChange={handleFilter("search")}
            placeholder={tr.translateAsString("generic.action.search")}
          />
        </Tooltip>
        {!projectId && (
          <ProjectFilter
            selected={passportFilter.projects ?? []}
            onSelect={handleFilter("projects")}
            onClear={handleClear([
              "projects",
              "projectVersions",
              "emptyProjectVersion",
            ])}
            projectType={projectType}
          />
        )}
        {projectType === "tool" && (
          <ProjectVersionFilter
            disabled={
              !passportFilter.projects || passportFilter.projects.length === 0
            }
            selected={passportFilter.projectVersions}
            noProjectVersionSelected={
              passportFilter.emptyProjectVersion ?? false
            }
            onSelect={handleFilter("projectVersions")}
            onSelectNoProjectVersion={handleFilter("emptyProjectVersion")}
            projects={passportFilter.projects}
            onClear={handleClear(["projectVersions", "emptyProjectVersion"])}
          />
        )}
        <ClientFilter
          selected={passportFilter.clients ?? []}
          noClientSelected={passportFilter.emptyClient ?? false}
          onSelect={handleFilter("clients")}
          onSelectNoClient={handleFilter("emptyClient")}
          onClear={handleClear(["clients", "emptyClient"])}
        />
        <PassportStateFilter
          selected={passportFilter.passportStates ?? []}
          noStateSelected={passportFilter.emptyPassportState ?? false}
          onSelect={handleFilter("passportStates")}
          onSelectNoState={handleFilter("emptyPassportState")}
          onClear={handleClear(["passportStates", "emptyPassportState"])}
        />
        <MoreFilters
          filterValues={passportFilter}
          additionalFilters={["createdAt", "updatedAt", "filters"]}
          onClear={handleClear}
        >
          <DateFilter
            selected={passportFilter?.createdAt}
            onSelect={handleFilter("createdAt")}
            label={tr.translateAsString("generic.label.creation-date.title")}
          />
          <DateFilter
            selected={passportFilter?.updatedAt}
            onSelect={handleFilter("updatedAt")}
            label={tr.translateAsString("generic.label.last-update.title")}
          />
          {filteredProperties.map((property) => (
            <CustomPropertyFilter
              key={property.id}
              property={property}
              value={passportFilter.filters?.find((p) => p.id === property.id)}
              onClear={clearCustomFilter}
              onSetValue={setCustomFilter}
            />
          ))}
        </MoreFilters>
        <AlignRight />

        <SelectPassportColumns
          columnConfig={columnConfig}
          onToggleColumn={onToggleColumn}
          projectType={projectType}
        >
          <EditColumnButton />
        </SelectPassportColumns>

        {canCreatePassports && (
          <Button
            isDense
            intent="secondary"
            onClick={handleToggleNewPassportDialog}
          >
            {tr.translate("passport.page.create-passport")}
          </Button>
        )}
      </ActionBar>

      {newPassportDialog && !projectId && (
        <PassportFunnel
          onClose={handleToggleNewPassportDialog}
          projectType={projectType}
        />
      )}

      {newPassportDialog && projectId && parentProject && (
        <PassportCreationDialog
          isOpen
          product={parentProject}
          onClose={handleToggleNewPassportDialog}
        />
      )}
    </>
  );
}
