import { DocumentDefinitionQueryParams } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { documentKeys } from "./queries";

export default function useDocumentRelatedDefinitions(
  documentId: number,
  revisionId: number,
  params?: DocumentDefinitionQueryParams
) {
  const api = useApi();

  return useQuery(
    documentKeys.allDefinitionProjects(documentId, revisionId, params),
    () =>
      api.document.listDocumentRelatedDefinitions(
        documentId,
        revisionId,
        params
      )
  );
}
