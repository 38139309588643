import React, { useState } from "react";
import {
  ANALYTICS_VISITED_PRODUCT_HOME,
  useTrackEvent,
} from "../../../analytics";
import {
  Activities,
  AlignRight,
  H3,
  PageTitle,
  SearchInput,
} from "../../../components";
import { useTranslations } from "../../../util";
import { ProjectPage } from "../common";
import { useProject } from "../hooks";
import ProductProperties from "./ProductProperties";
import styles from "./ProjectHome.module.scss";

export default function ProjectHome(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_HOME);

  const [search, setSearch] = useState("");

  const { data: product } = useProject(projectId);

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.home")}
    >
      <PageTitle icon="home" className={styles.title}>
        {tr.translate("sidebar.home")}
      </PageTitle>
      <div className={styles.product_home}>
        <div className={styles.column}>
          <H3 className={styles.column_header}>
            {tr.translate("product.home.properties")}
            <AlignRight />
            <SearchInput isDense value={search} onChange={setSearch} />
          </H3>
          {product && <ProductProperties product={product} search={search} />}
        </div>
        <div className={styles.column}>
          <H3>{tr.translate("product.home.activities")}</H3>
          <Activities
            entity={{ type: "project", id: projectId }}
            className={styles.activities}
          />
        </div>
      </div>
    </ProjectPage>
  );
}
