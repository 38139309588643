import React from "react";
import { CellProps } from "react-table";
import { GenericStateTag } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useProjectDefinitionDocuments } from "../../hooks";
import { ProductComponentTree } from "../../ProjectNomenclature/hooks";
import styles from "./ApproveNomenclatureDialog.module.scss";

export default function ComponentDefinitionCell(
  props: CellProps<ProductComponentTree>
) {
  const {
    row: { original: tree },
  } = props;

  const tr = useTranslations();

  const { data: documents } = useProjectDefinitionDocuments(
    tree.product.id,
    tree.definition.index
  );
  const hasDraftDocuments =
    documents && documents.some((d) => d.revision?.state === "draft");
  const hasObsoleteDocuments =
    documents && documents.some((d) => d.revision?.state === "obsolete");

  const isPartIterationApproved = tree.iteration?.state === "approved";
  const hasPart = tree.iteration !== undefined;

  return (
    <div>
      <GenericStateTag
        name={tree.definition.name}
        state={tree.definition.state}
      />
      {
        <div className={styles.error_definition}>
          {!isPartIterationApproved &&
            hasPart &&
            tr.translate(
              "project.definition.validate.error.not-approved.iteration"
            )}{" "}
          {(hasDraftDocuments || hasObsoleteDocuments) &&
            tr.translate("project.definition.validate.error.invalid-documents")}
        </div>
      }
    </div>
  );
}
