import { useCallback } from "react";
import useApi from "../../../app/useApi";

export default function useDocumentPreviewToken() {
  const api = useApi();

  return useCallback(
    (value: { documentId: number; revisionId?: number; fileName?: string }) =>
      api.document.getDocumentPreviewToken(
        value.documentId,
        value.revisionId,
        value.fileName
      ),
    [api]
  );
}
