import {
  ButtonSP,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
} from "@aletiq/design-system";
import { UserRole } from "@aletiq/types";
import { PopoverPosition } from "@blueprintjs/core";
import React from "react";
import { useTranslations } from "../../../../../util";
import styles from "./RoleMenu.module.scss";

export const USER_ROLE_WITH_TRANSLATION: { key: UserRole; label: string }[] = [
  { key: "viewer", label: "admin.users.page.type.viewer" },
  { key: "manager", label: "admin.users.page.type.manager" },
  { key: "administrator", label: "admin.users.page.type.admin" },
  { key: "shared", label: "admin.users.page.type.computer" },
];

export function RoleMenu(props: {
  role?: UserRole;
  isDeactivated?: boolean;
  position?: PopoverPosition;
  updateStatus: (status: UserRole) => () => void;
  onDeactivateAccount?: () => void;
}) {
  const {
    role,
    isDeactivated,
    position = "bottom-right",
    onDeactivateAccount,
    updateStatus,
  } = props;
  const tr = useTranslations();

  const menuItems = USER_ROLE_WITH_TRANSLATION.filter((role) => {
    if (onDeactivateAccount && role.key === "shared") return false;
    return true;
  });

  const isShared = role === "shared";

  return (
    <Popover2
      fill
      className={styles.menu}
      content={
        <Menu isDense>
          {menuItems
            .filter((item) => item.key !== "shared")
            .map((menuItem) => (
              <MenuItem
                isDense
                active={role === menuItem.key}
                text={tr.translate(menuItem.label)}
                onClick={updateStatus(menuItem.key)}
                key={menuItem.key}
                disabled={isDeactivated || isShared}
              />
            ))}
          {onDeactivateAccount && !isDeactivated && (
            <>
              <MenuDivider />
              <MenuItem
                isDense
                text={tr.translate("admin.users.page.user.deactivate")}
                onClick={onDeactivateAccount}
              />
            </>
          )}
        </Menu>
      }
      position={position}
    >
      <ButtonSP
        isDense
        isFill
        isDisabled={isDeactivated}
        view="outlined"
        className={styles.button}
        icon={
          isDeactivated ? <Icon icon="disable" intent="danger" /> : undefined
        }
        rightIcon={!isDeactivated ? "caret-down" : undefined}
      >
        <div>
          {isDeactivated && tr.translate("admin.users.page.type.deactivated")}
          {!isDeactivated &&
            tr.translate(
              USER_ROLE_WITH_TRANSLATION.find((item) => item.key === role)
                ?.label ?? "admin.users.page.role.placeholder"
            )}
        </div>
      </ButtonSP>
    </Popover2>
  );
}
