import { HighlightedText, Icon } from "@aletiq/design-system";
import { Property } from "@aletiq/types";
import { MaybeElement } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import React from "react";
import { Accessor, CellProps } from "react-table";
import { DateWithSpaces } from "../../date";
import { CustomPropertyCell } from "../../properties";
import {
  CreatedAtHeader,
  DescriptionColumnHeader,
  NameHeader,
  UpdatedAtHeader,
} from "./headers";
import { ColumnDescriptor, ExtendedColumn } from "./types";

export function customNameColumn<T extends object, U extends string>(
  descriptor: ColumnDescriptor<T, U>,
  icon: IconName | MaybeElement
): ExtendedColumn<T, U> {
  return {
    ...descriptor,
    Header: <NameHeader />,
    Cell: (props: CellProps<T>) => (
      <>
        <Icon icon={icon} inline />
        <HighlightedText text={props.value} highlight={props.searchString} />
      </>
    ),
  };
}

export function customDescriptionColumn<T extends object, U extends string>(
  descriptor: ColumnDescriptor<T, U>
): ExtendedColumn<T, U> {
  return {
    ...descriptor,
    Header: <DescriptionColumnHeader />,
    Cell: (props: CellProps<T>) => (
      <HighlightedText text={props.value} highlight={props.searchString} />
    ),
  };
}

function customDateColumn<T extends object, U extends string>(
  descriptor: ColumnDescriptor<T, U>,
  header: JSX.Element
): ExtendedColumn<T, U> {
  return {
    ...descriptor,
    Header: header,
    Cell: (props: CellProps<T>) =>
      props.value ? <DateWithSpaces date={props.value} /> : <></>,
  };
}

export function customCreatedAtColumn<T extends object, U extends string>(
  descriptor: ColumnDescriptor<T, U>
): ExtendedColumn<T, U> {
  return customDateColumn(descriptor, <CreatedAtHeader />);
}

export function customUpdatedAtColumn<T extends object, U extends string>(
  descriptor: ColumnDescriptor<T, U>
): ExtendedColumn<T, U> {
  return customDateColumn(descriptor, <UpdatedAtHeader />);
}

export function customPropertyColumn<T extends object, U extends string>(
  accessor: Accessor<T>,
  prop: Property
): ExtendedColumn<T, U> {
  return {
    id: prop.label,
    Header: prop.label,
    accessor,
    fullWidthContent: prop.type === "multi-enum",
    canSort: false,
    Cell: (cell: CellProps<T>) => (
      <CustomPropertyCell property={prop} cell={cell} />
    ),
  };
}
