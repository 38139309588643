import { Entity, EntityId } from "@aletiq/types";

export function findPart(entities: Entity[], partId: number) {
  for (const entity of entities) {
    if (entity.type === "part" && entity.entity.id === partId) {
      return entity.entity;
    }
  }
  return undefined;
}

export function findDocument(entities: Entity[], documentId: number) {
  for (const entity of entities) {
    if (entity.type === "file" && entity.entity.id === documentId) {
      return entity.entity;
    }
  }
  return undefined;
}

export function findProduct(entities: Entity[], productId: number) {
  for (const entity of entities) {
    if (entity.type === "project" && entity.entity.id === productId) {
      return entity.entity;
    }
  }
  return undefined;
}

export function findPassport(entities: Entity[], passportId: number) {
  for (const entity of entities) {
    if (entity.type === "passport" && entity.entity.id === passportId) {
      return entity.entity;
    }
  }
  return undefined;
}

export function findWorkflow(entities: Entity[], workflowId: number) {
  for (const entity of entities) {
    if (entity.type === "process" && entity.entity.id === workflowId) {
      return entity.entity;
    }
  }
  return undefined;
}

export function findEntity(entities: Entity[], entityId: EntityId) {
  for (const entity of entities) {
    if (entity.type === entityId.type && entity.entity.id === entityId.id) {
      return entity;
    }
  }
  return undefined;
}
