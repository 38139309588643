import { API } from "@aletiq/api";
import { ProjectQueryParams } from "@aletiq/types";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";
import usePaginatedProjects from "./usePaginatedProjects";

export default function useAvailableProjectComponents(
  projectId: number,
  currentComponents: number[],
  queryParams: ProjectQueryParams
) {
  const { data: projectsResponse, isLoading: isLoadingProjects } =
    usePaginatedProjects(queryParams);

  const { data: ancestors = [], isLoading: isLoadingAncestors } =
    useProjectAncestors(projectId);

  return useMemo(() => {
    const projects = projectsResponse?.list ?? [];
    // remove ancestors to avoid cycles
    const availableProjects = projects.filter(
      (p) => !ancestors.includes(p.id) && !currentComponents.includes(p.id)
    );

    return {
      data: availableProjects,
      removed: ancestors,
      isLoading: isLoadingAncestors || isLoadingProjects,
      hasTooMany: (projectsResponse?.count ?? 0) > projects.length,
    };
  }, [
    projectsResponse,
    isLoadingProjects,
    ancestors,
    isLoadingAncestors,
    currentComponents,
  ]);
}

async function getProjectAncestors(
  api: API,
  projectId: number,
  visited: number[] = []
): Promise<number[]> {
  if (visited.includes(projectId)) {
    return [];
  }
  const projectsUsedIn = await api.project.getProjectUsedIn(projectId, {});
  let ancestors: number[] = [projectId];

  for (const project of projectsUsedIn.list ?? []) {
    const projectAncestors = await getProjectAncestors(api, project.id, [
      ...visited,
      ...ancestors,
    ]);
    ancestors = [...ancestors, ...projectAncestors];
  }

  return ancestors;
}

function useProjectAncestors(projectId: number) {
  const api = useApi();

  return useQuery(projectKeys.ancestors(projectId), () =>
    getProjectAncestors(api, projectId)
  );
}
