import React from "react";
import { CustomIcon, CustomIconProps } from "./CustomIcon";

const paths = [
  "M19 12h-4.1a5 5 0 00-9.8 0H1c-.55 0-1 .45-1 1s.45 1 1 1h4.1a5 5 0 009.8 0H19c.55 0 1-.45 1-1s-.45-1-1-1zm-9 4c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zM19 5h-2V3c0-.55-.45-1-1-1s-1 .45-1 1v2h-2c-.55 0-1 .45-1 1s.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1V7h2c.55 0 1-.45 1-1s-.45-1-1-1z",
];

export default function CommitAddIcon(props: CustomIconProps) {
  return (
    <CustomIcon
      paths={paths}
      iconName="commit-add"
      pixelGridSize={20}
      {...props}
    />
  );
}
