import { ProjectQueryParams, ProjectQueryResult } from "@aletiq/types";
import { useQuery, UseQueryOptions } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function usePaginatedProjects<A = ProjectQueryResult>(
  params?: ProjectQueryParams,
  options?: UseQueryOptions<ProjectQueryResult, Error, A>
) {
  const api = useApi();
  return useQuery({
    queryKey: projectKeys.list(params),
    queryFn: () => api.project.getProjects(params),
    keepPreviousData: true,
    ...options,
  });
}
