import { Part } from "@aletiq/types";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { DeletePartErrorToaster } from "../PartDetails/Toaster";
import { pdmKeys } from "./queries";

export default function useDeleteParts() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (parts: Part[]) => {
      const partsComponent = parts.filter((part) => part.type === "part");
      const assemblies = parts.filter((part) => part.type === "assembly");
      const drawing = parts.filter((part) => part.type === "drawing");

      // Drawing
      for (let i = 0; i < drawing.length; i++) {
        try {
          await api.pdm.deletePart(drawing[i].id);
        } catch (err) {
          toaster.show({
            intent: "warning",
            icon: "warning-sign",
            message: tr.translate("part.action.delete.failure.drawing", {
              name: drawing[i].name,
            }),
            timeout: 2000,
          });
        }
      }

      const assemblyChildren: AssemblyTree = {};
      // Get the assembly components
      for (let i = 0; i < assemblies.length; i++) {
        const children = await api.pdm.listAssemblyComponents(assemblies[i].id);

        assemblyChildren[assemblies[i].id] = {
          parentsId: undefined,
          part: assemblies[i],
          children: children
            .map((child) => child?.part?.id ?? -1)
            .filter(
              (p) =>
                assemblies.findIndex((assembly) => assembly.id === p) !== -1
            ),
        };
      }

      // Create the tree
      for (let i = 0; i < assemblies.length; i++) {
        assemblyChildren[assemblies[i].id].children.forEach((childId) => {
          if (assemblyChildren[childId].parentsId) {
            assemblyChildren?.[childId]?.parentsId?.push(assemblies[i].id);
          } else {
            assemblyChildren[childId].parentsId = [assemblies[i].id];
          }
        });
      }

      while ((Object.keys(assemblyChildren) || []).length > 0) {
        const assembliesToDelete: AssemblyTree = {};
        // Get the node at root level
        const assemblyKeys = Object.keys(assemblyChildren);
        for (let i = 0; i < assemblyKeys.length; i++) {
          const key = parseInt(assemblyKeys[i], 10);
          if (assemblyChildren[key].parentsId === undefined) {
            assembliesToDelete[key] = assemblyChildren[key];
          }
        }

        // Delete them
        const assembliesToDeleteKeys = Object.keys(assembliesToDelete);
        for (let i = 0; i < assembliesToDeleteKeys.length; i++) {
          const key = parseInt(assembliesToDeleteKeys[i], 10);
          const assemblyToDelete = assembliesToDelete[key];

          try {
            await api.pdm.deletePart(assemblyToDelete.part.id);
          } catch (err) {
            toaster.show({
              intent: "warning",
              icon: "warning-sign",
              message: tr.translate("part.action.delete.failure.drawing", {
                name: assemblyToDelete.part.name,
              }),
              timeout: 2000,
            });
          }

          // Remove this id from the parents in the other node
          const newAssemblyKeys = Object.keys(assemblyChildren) || [];

          for (let j = 0; j < newAssemblyKeys.length; j++) {
            const key = parseInt(newAssemblyKeys[j], 10);

            const newParent =
              assemblyChildren[key].parentsId?.filter(
                (p) => p !== assemblyToDelete.part.id
              ) || [];

            assemblyChildren[key].parentsId =
              newParent.length === 0 ? undefined : newParent;
          }

          delete assemblyChildren[assemblyToDelete.part.id];
        }
      }

      // Parts
      for (let i = 0; i < partsComponent.length; i++) {
        try {
          await api.pdm.deletePart(partsComponent[i].id);
        } catch (error) {
          toaster.show({
            intent: "warning",
            icon: "warning-sign",
            message: <DeletePartErrorToaster partId={partsComponent[i].id} />,
            timeout: 2000,
          });
        }
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}

type AssemblyTree = {
  [key: number]: { part: Part; children: number[]; parentsId?: number[] };
};
