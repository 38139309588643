import Chart from "chart.js/auto";
import React, { CSSProperties, FunctionComponent } from "react";
import ReactDOM from "react-dom";
import { Intl } from "../../app/Intl";

type GraphTooltipComponent = FunctionComponent<{
  defaultStyle: CSSProperties;
  data: Chart.Legend[];
}>;

export const createCustomGraphTooltip =
  (value: { graphId: string; tooltipComponent: GraphTooltipComponent }) =>
  (context: Chart.Context) => {
    const { tooltipComponent, graphId } = value;

    const { chart, tooltip } = context;
    getOrcreateTooltip(chart, graphId);
    const style = {
      left: tooltip.x,
      top: tooltip.y,
    };

    const component = React.createElement(
      tooltipComponent,
      {
        defaultStyle: { ...style, opacity: tooltip.opacity },
        data: tooltip?.dataPoints,
      },
      null
    );

    ReactDOM.render(
      <Intl lang={navigator.language}>{component}</Intl>,
      document.getElementById(`chartjs-tooltip-${graphId}`)
    );
  };

const getOrcreateTooltip = (chart: Chart, graphId: string) => {
  let tooltipEl = document.getElementById(`chartjs-tooltip-${graphId}`);

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.setAttribute("id", `chartjs-tooltip-${graphId}`);

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};
