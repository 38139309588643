import { EntityId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { processKeys } from "./queries";

export default function useDeleteWorkflowRelations(workflow: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (entities: EntityId[]) => {
      await Promise.all(
        entities.map(async (entity) => {
          await api.task.deleteProcessRelation(workflow, entity);
        })
      );
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(processKeys.allRelations());
        queryClient.invalidateQueries(processKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.workflow.unlink.all"),
        });
      },
    }
  );
}
