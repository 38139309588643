import { Task } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import {
  DateWithSpaces,
  StatusTag,
  UserInitialsList,
} from "../../../../components";
import TaskDependencyList from "../TaskDependencyList";
import styles from "./ArchivedWorkflowView.module.scss";

export function ArchivedTaskRow(props: {
  onClick: () => void;
  task: Task;
  workflowTasks: Task[];
  workflowColor: string;
  isSelected?: boolean;
}) {
  const { onClick, task, workflowTasks, workflowColor, isSelected } = props;
  const dependencies = workflowTasks.filter((t) =>
    task.dependencies.includes(t.id)
  );

  const isTaskLocked = dependencies.some((t) => t.status !== "done");

  return (
    <tr
      onClick={onClick}
      className={classNames(styles.row, {
        [styles.selected]: isSelected,
      })}
    >
      <td>{task.title}</td>
      <td>
        <div className={styles.owners}>
          <UserInitialsList users={task.owners} fill />
        </div>
      </td>
      <td>{task.dueDate && <DateWithSpaces date={task.dueDate} />}</td>
      <td>
        <TaskDependencyList
          task={task}
          workflowTasks={workflowTasks}
          workflowColor={workflowColor}
        />
      </td>
      <td>
        <StatusTag
          isDisabled
          isTaskLocked={isTaskLocked}
          completionDate={task.completionDate}
        >
          {task.status}
        </StatusTag>
      </td>
    </tr>
  );
}
