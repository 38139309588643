import { Project } from "@aletiq/types";
import React, { useState } from "react";
import { Dialog, Field, Input, TextArea } from "../../../components";
import { useTranslations } from "../../../util";
import { useSetProjectNameDescription } from "../hooks";

export default function RenameProjectDialog(props: {
  project: Project;
  onClose: () => void;
}) {
  const { project, onClose } = props;
  const tr = useTranslations();

  const [name, setName] = useState(project.name);
  const [description, setDescription] = useState(project.description);

  const { mutate: updateProjectInfo, isLoading } = useSetProjectNameDescription(
    project.id
  );

  const handleSubmit = () => {
    const newName = project.name !== name ? name : undefined;
    const newDescription =
      project.description !== description ? description : undefined;

    if (!newName && !newDescription) {
      onClose();
    }
    updateProjectInfo(
      { name: newName, description: newDescription },
      { onSettled: onClose }
    );
  };

  const projectType = project.isTool ? "tool" : "article";
  return (
    <Dialog
      isOpen
      icon="edit"
      title={tr.translateAsString("project.dialog.rename.title", {
        type: projectType,
      })}
      onClose={onClose}
      submitting={isLoading}
      onPrimaryClick={handleSubmit}
      disablePrimary={name === ""}
    >
      <p>
        {tr.translate("project.dialog.rename.description", {
          type: projectType,
        })}
      </p>
      <Field label={tr.translate("generic.label.name")}>
        <Input
          placeholder={project.name}
          value={name}
          onChange={setName}
          fill
        />
      </Field>
      <Field label={tr.translate("generic.label.description")}>
        <TextArea
          placeholder={tr.translateAsString("generic.label.description")}
          value={description}
          onChange={setDescription}
          fill
        />
      </Field>
    </Dialog>
  );
}
