import { ProcessUpdateSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../../app/useApi";
import { useToaster } from "../../../../../hooks";
import { indexBy, mapRecord, useTranslations } from "../../../../../util";
import { activityKeys } from "../../../../activities/hooks/queries";
import { notificationKeys } from "../../../../notifications/hooks/queries";
import { processKeys, taskKeys } from "../../../hooks/queries";
import { makeIdentifiedTasks, TaskEditorState } from "../taskEditUtils";

export default function useUpdateWorkflowAndTasks() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (params: {
      workflow: ProcessUpdateSpec;
      tasks: TaskEditorState;
      workflowId: number;
    }) => {
      const { workflow, tasks, workflowId } = params;

      await api.task.updateProcess(workflow, workflowId);

      for (const taskId of tasks.deleted) {
        await api.task.deleteTask(taskId);
      }

      const tasksInfo = [...tasks.created, ...tasks.edited];

      const idMap: Record<number, number> = mapRecord(
        indexBy(tasks.edited, "id"),
        (t) => t.id
      );
      for (const { id, spec } of tasks.created) {
        const newId = await api.task.createTask(spec, workflowId);
        idMap[id] = newId;
      }

      const identifiedTasks = makeIdentifiedTasks(idMap, tasksInfo);
      await api.task.updateProcessTasks(workflowId, identifiedTasks);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(processKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
        queryClient.invalidateQueries(notificationKeys.count());
        queryClient.invalidateQueries(taskKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.workflow.update"),
        });
      },
    }
  );
}
