import { Tab } from "@blueprintjs/core";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  ANALYTICS_VISITED_PRODUCT_NOMENCLATURE,
  useTrackEvent,
} from "../../../analytics";
import { PageTitle, Tabs } from "../../../components";
import { useNavigate } from "../../../hooks";
import { useTranslations } from "../../../util";
import { ProjectPage } from "../common";
import { useProject } from "../hooks";
import { ProjectComponentsPanel } from "./ProjectComponents";
import { ProjectComponentsSelection } from "./ProjectComponents/ProjectComponentSelect";
import ProjectDefinitionDocumentsPanel from "./ProjectDefinitionDocuments/ProjectDefinitionDocumentsPanel";
import styles from "./ProjectNomenclature.module.scss";
import {
  ProjectNomenclatureActionBar,
  useProjectNomenclatureState,
} from "./ProjectNomenclatureActionBar";

type ProjectNomenclatureTab = "bom" | "docs";

export default function ProjectNomenclature(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();

  useTrackEvent(ANALYTICS_VISITED_PRODUCT_NOMENCLATURE);

  const routeParams = useRouteMatch<{ definition: string }>();
  const navigate = useNavigate();

  const routeDefinitionIdx = parseInt(routeParams.params.definition);

  const [search, setSearch] = useState("");
  const [tab, setTab] = useState<ProjectNomenclatureTab>("bom");
  const [selected, setSelected] = useState<ProjectComponentsSelection>("uses");

  const { data: project } = useProject(projectId);

  const definitionIdx = isNaN(routeDefinitionIdx)
    ? project?.lastDefinition.index
    : routeDefinitionIdx;

  const projectNomenclatureState = useProjectNomenclatureState(
    projectId,
    definitionIdx ?? 1
  );

  const handleActiveDefinitionChange = (idx: number) => {
    navigate({ path: `/project/${projectId}/nomenclature/${idx}` });
  };

  const handleSetTab = (newTab: ProjectNomenclatureTab) => {
    setTab(newTab);
  };

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.definition")}
    >
      <PageTitle icon="diagram-tree">
        {tr.translate("page.title.definition")}
      </PageTitle>

      <ProjectNomenclatureActionBar
        projectId={projectId}
        onActiveDefinitionChange={handleActiveDefinitionChange}
        {...projectNomenclatureState}
      />

      <Tabs
        selectedTabId={tab}
        onChange={handleSetTab}
        className={styles.tabs}
        renderActiveTabPanelOnly
      >
        {definitionIdx && (
          <Tab
            id={"bom"}
            panelClassName={styles.tab_panel}
            title={tr.translate("project.definition.bom")}
            panel={
              <ProjectComponentsPanel
                selected={selected}
                search={search}
                onSelectChange={setSelected}
                onSearchChange={setSearch}
                projectId={projectId}
                definitionIdx={definitionIdx}
              />
            }
          />
        )}
        {definitionIdx && (
          <Tab
            id={"docs"}
            panelClassName={styles.tab_panel}
            title={tr.translate("project.definition.docs")}
            panel={
              <ProjectDefinitionDocumentsPanel
                projectId={projectId}
                definitionIdx={definitionIdx}
              />
            }
          />
        )}
      </Tabs>
    </ProjectPage>
  );
}
