import React from "react";
import EditablePropertyTemplate from "./EditablePropertyTemplate";

export default function NonEditablePropertyInput(props: {
  label: string;
  intent?: "primary" | "default";
  value: string | JSX.Element;
  inline?: boolean;
  minWidth?: number;
}) {
  const { label, intent = "default", value, inline, minWidth } = props;

  return (
    <EditablePropertyTemplate
      intent={intent}
      isEditable={false}
      label={label}
      triggerEditMode={() => undefined}
      isInEditMode={false}
      inline={inline}
      minWidth={minWidth}
    >
      {value}
    </EditablePropertyTemplate>
  );
}
