import { Classes, Spinner, SpinnerSize } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import {
  ANALYTICS_OPENED_PART_DETAILS,
  useTrackEvent,
} from "../../../analytics";
import { Panel } from "../../../components";
import PanelDetailsHeader from "../../../components/panel/PanelDetailsHeader";
import { useTranslations } from "../../../util";
import { usePart } from "../hooks";
import StandardPartDetails from "../StandardPartDetails/StandardPartDetails";
import NormalPartDetails from "./NormalPartDetails";
import styles from "./PartDetails.module.scss";

export default function PartDetails(props: {
  partId: number;
  onClose: () => void;
  tab?: string;
}) {
  useTrackEvent(ANALYTICS_OPENED_PART_DETAILS);

  const { partId, onClose, tab } = props;
  const tr = useTranslations();
  const { data: part, isLoading } = usePart(partId);

  if (isLoading) {
    return (
      <Panel width="500px">
        <div className={Classes.DRAWER_HEADER}></div>
        <div className={Classes.DRAWER_BODY}>
          <Spinner size={SpinnerSize.LARGE}></Spinner>
        </div>
      </Panel>
    );
  }

  if (part && part.isDeleted) {
    return (
      <Panel width="500px">
        <PanelDetailsHeader
          title={part?.name}
          additionalButtons={null}
          navigateTooltip={""}
          canNavigateToList={false}
          navigateToList={() => {}}
        />
        <div className={classNames(Classes.DRAWER_BODY, styles.deleted)}>
          {tr.translate("part.details.deleted")}
        </div>
      </Panel>
    );
  }

  if (part && !part.isStandard) {
    return <NormalPartDetails part={part} onClose={onClose} tab={tab} />;
  }

  if (part && part.isStandard) {
    return <StandardPartDetails part={part} onClose={onClose} tab={tab} />;
  }

  return null;
}
