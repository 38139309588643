import {
  DEFAULT_DOC_TAB,
  DEFAULT_PART_TAB,
  DEFAULT_PASSPORT_TAB,
  DEFAULT_WORKFLOW_TAB,
  isValidTabId,
} from "../../util";
import useDocumentId from "./useDocumentId";
import usePanelTab from "./usePanelTab";
import usePartId from "./usePartId";
import usePassportId from "./usePassportId";
import useWorkflowId from "./useWorkflowId";

export default function useDetailsPanel() {
  const passportId = usePassportId();
  const partId = usePartId();
  const workflowId = useWorkflowId();
  const documentId = useDocumentId();
  const panelTab = usePanelTab();

  return passportId
    ? {
        type: "passport",
        id: passportId,
        tab: isValidTabId(panelTab, "passport")
          ? panelTab
          : DEFAULT_PASSPORT_TAB,
      }
    : partId
    ? {
        type: "part",
        id: partId,
        tab: isValidTabId(panelTab, "part") ? panelTab : DEFAULT_PART_TAB,
      }
    : workflowId
    ? {
        type: "workflow",
        id: workflowId,
        tab: isValidTabId(panelTab, "workflow")
          ? panelTab
          : DEFAULT_WORKFLOW_TAB,
      }
    : documentId
    ? {
        type: "document",
        id: documentId,
        tab: isValidTabId(panelTab, "document") ? panelTab : DEFAULT_DOC_TAB,
      }
    : undefined;
}
