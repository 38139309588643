import { HighlightedText } from "@aletiq/design-system";
import { SortState } from "@aletiq/types";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import {
  CellProps,
  HeaderProps,
  useExpanded,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useTable,
} from "react-table";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ExtendedColumn } from ".";
import { useHasVisibleScrollbar } from "../../hooks";
import { ListPaginatedStepper } from "../pagination";
import { Tile } from "../Tile";
import RowCheckbox from "./RowCheckbox";
import styles from "./Table.module.scss";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

export function Table<
  T extends object,
  U extends string | number = Extract<keyof T, string>
>(props: {
  columns: ExtendedColumn<T, U>[];
  data: T[];
  //descriptive text or element to render if there is no data
  noData?: string | JSX.Element;
  //provide openRow options to display "open" button on row
  openRowOptions?: {
    onOpen: (listItem: T) => void;
    rowCannotBeOpened?: (listItem: T) => boolean;
  };
  //provide action header render method to allow row selection
  renderBatchActionsHeader?: (
    selectedItems: T[],
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => JSX.Element;
  searchString?: string;
  //provide sort optiosn to allow sorting
  sortOptions?: {
    sortState: SortState<U>;
    onSort: (key: U) => void;
  };
  //provide pagination options to use pagination
  paginationOptions?: {
    itemCount: number;
    selectedPage: number;
    limit: number;
    onSetPage: (page: number) => void;
    onSetLimit: (limit: number) => void;
  };
  className?: string;
  subRowKey?: keyof T;
  //are row contents open in a details panel ?
  isRowActive?: (rowData: T) => boolean;
  //row selection options
  onSelect?: (select: T[]) => void;
  isFetching?: boolean;
  isRowUnselectable?: (rowData: T) => boolean;
  // initial state options
  initialState?: {
    expandAll?: boolean;
  };
}) {
  const {
    className,
    columns,
    data,
    noData,
    searchString,
    openRowOptions,
    renderBatchActionsHeader,
    sortOptions,
    paginationOptions,
    isRowUnselectable,
    isRowActive,
    onSelect,
    isFetching,
    initialState,
  } = props;

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 120,
      canSort: true,
      canExpand: false,
      disableResizing: false,
      Cell: (cellProps: CellProps<T>) => {
        if (typeof cellProps.cell.value === "string")
          return (
            <HighlightedText
              text={cellProps.cell.value}
              highlight={cellProps.searchString}
            />
          );
        return <>{cellProps.cell.value}</>;
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetSelectedRows: false,
      autoResetExpanded: false,
      selectSubRows: true,
    },
    useFlexLayout,
    useResizeColumns,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumnsDeps.push((deps) => [
        ...deps,
        renderBatchActionsHeader,
        onSelect,
      ]);

      if (renderBatchActionsHeader || onSelect) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            disableResizing: true,
            canSort: false,
            Header: (headerProps: HeaderProps<T>) => (
              <RowCheckbox {...headerProps.getToggleAllRowsSelectedProps()} />
            ),
            Cell: (cellProps: CellProps<T>) => {
              const canSelectRow =
                isRowUnselectable === undefined ||
                !isRowUnselectable?.(cellProps.row.original);

              return canSelectRow ? (
                <RowCheckbox {...cellProps.row.getToggleRowSelectedProps()} />
              ) : null;
            },
          },
          ...columns,
        ]);
      }
    }
  );

  const {
    containerRef: tableRef,
    isScrollbarVisible: isTableScrollbarVisible,
  } = useHasVisibleScrollbar();

  const {
    containerRef: bodyTableRef,
    isScrollbarVisible: isTbodyScrollbarVisible,
    verticalScrollbarSize,
  } = useHasVisibleScrollbar();

  //expand all rows once table instance is loaded
  useEffect(() => {
    if (initialState?.expandAll) {
      toggleAllRowsExpanded(true);
    }
  }, [initialState?.expandAll, toggleAllRowsExpanded, isFetching]);

  const selectedItems = useMemo(
    () => selectedFlatRows.map((row) => row.original),
    [selectedFlatRows]
  );

  /* Use useDeepCompareEffect to avoid infinite loop updates in parent components
   */
  useDeepCompareEffect(() => {
    onSelect?.(selectedItems);
  }, [onSelect, selectedItems]);

  //reset selection on filtering
  useEffect(() => {
    toggleAllRowsSelected(false);
  }, [searchString, toggleAllRowsSelected]);

  const handleSort = (key: U) => {
    if (!sortOptions || key === "selection") {
      return;
    }

    sortOptions.onSort(key);
    paginationOptions && paginationOptions.onSetPage(0);
    paginationOptions && toggleAllRowsSelected(false);
  };

  const handleSetPage = (pageIndex: number) => {
    paginationOptions && paginationOptions.onSetPage(pageIndex);
    toggleAllRowsSelected(false);
  };

  const handleSetItemLimit = (limit: number) => {
    if (!paginationOptions) {
      return;
    }
    paginationOptions.onSetPage(0);
    paginationOptions.onSetLimit(limit);
    toggleAllRowsSelected(false);
  };

  return (
    <div className={styles.wrapper}>
      <Tile className={styles.tile}>
        <div
          {...getTableProps()}
          className={classNames(styles.table, className)}
          ref={tableRef}
        >
          {isFetching && (
            <Tile className={styles.spin_tile}>
              <NonIdealState>
                <Spinner />
              </NonIdealState>
            </Tile>
          )}

          <div className={styles.thead}>
            {headerGroups.map((headerGroup) => (
              <TableHeader
                key={headerGroup.getHeaderGroupProps().key}
                headerGroup={headerGroup}
                selectedItems={selectedItems}
                toggleAllRowsSelected={toggleAllRowsSelected}
                hasVisibleScrollbar={
                  isTbodyScrollbarVisible && !isTableScrollbarVisible
                }
                sortState={sortOptions?.sortState}
                onSort={handleSort}
                renderBatchActionsHeader={renderBatchActionsHeader}
                scrollbarSize={verticalScrollbarSize}
              />
            ))}
          </div>
          <div
            {...getTableBodyProps()}
            className={classNames(
              styles.tbody,
              data.length === 0 && styles.no_data
            )}
            ref={bodyTableRef}
          >
            {!isFetching && data.length === 0 && noData && (
              <NonIdealState
                icon={
                  <img src="/assets/no-data.svg" height={80} alt="no data" />
                }
                description={noData}
              />
            )}

            {data.length > 0 &&
              rows.map((row, rowIndex) => (
                <TableRow
                  searchString={searchString}
                  openRowOptions={openRowOptions}
                  prepareRow={prepareRow}
                  row={row}
                  rowIndex={rowIndex}
                  isRowActive={isRowActive}
                  key={row.id}
                />
              ))}
          </div>
        </div>
      </Tile>
      {paginationOptions && (
        <ListPaginatedStepper
          itemCount={paginationOptions.itemCount}
          limit={paginationOptions.limit}
          pageSelected={paginationOptions.selectedPage}
          onSelectLimit={handleSetItemLimit}
          onSelectPage={handleSetPage}
        />
      )}
    </div>
  );
}
