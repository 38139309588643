import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { AletiqPropertyOption } from "../../../../components";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { clientKeys } from "../../../clients/hooks/queries";

export default function useUpdateAndCreateClient() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (values: {
      clientsToAdd: AletiqPropertyOption[];
      clientsToUpdate: AletiqPropertyOption[];
    }) => {
      const { clientsToAdd, clientsToUpdate } = values;
      const clientsToAddParams = clientsToAdd.map((client) => ({
        name: client.label,
        deleted: client.deleted,
      }));
      const clientsToUpdateParams = clientsToUpdate.map((client) => ({
        id: client.value ?? -1,
        param: {
          name: client.label,
          deleted: client.deleted,
        },
      }));

      for (const client of clientsToAddParams) {
        await api.addClient(client);
      }
      for (const client of clientsToUpdateParams) {
        await api.updateClient(client.id, client.param);
      }
    },
    {
      onSettled: () => queryClient.invalidateQueries(clientKeys.all),
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.passport.client.update"),
        });
      },
    }
  );
}
