import { Passport } from "@aletiq/types";
import { useMemo } from "react";
import { groupBy } from "../../../util";
import useClients from "../../clients/hooks/useClients";
import useProjectRecord from "../../project/hooks/useProjectsRecord";
import { ResolvedPassport } from "../types";
import usePassportStates from "./usePassportStates";

export default function useResolvedPassport(passports: Passport[]): {
  data: ResolvedPassport[];
  isLoading: boolean;
} {
  const { data: projectsById, isLoading: projectsIsLoading } = useProjectRecord(
    passports.map((p) => p.parentProject)
  );
  const { data: clientName, isLoading: clientNameIsLoading } = useClients();
  const { data: passportStates, isLoading: passportStatesIsLoading } =
    usePassportStates();

  return useMemo(() => {
    const dicClientName = groupBy(clientName || [], "id");
    const dicPassportStates = groupBy(passportStates || [], "id");
    return {
      data: passports.map<ResolvedPassport>((passport) => {
        const parentProject = projectsById?.[passport.parentProject];
        return {
          id: passport.id,
          name: passport.name,
          description: passport.description,
          parentProject: parentProject,
          parentProjectName: parentProject?.name ?? "-",
          parentProjectIsTool: parentProject?.isTool ?? false,
          parentProjectIsArticle: parentProject?.isArticle ?? false,
          parentProjectId: passport.parentProject,
          parentProjectDefinition: passport.parentDefinition,
          projectIterationName: passport?.projectIterationName ?? "-",
          projectIterationId: passport?.projectIteration,
          clientId: passport?.client ?? null,
          clientName: dicClientName[passport?.client ?? -1]?.[0]?.name ?? "-",
          stateId: passport?.state ?? null,
          stateName:
            dicPassportStates[passport?.state ?? -1]?.[0]?.value ?? "-",
          config: passport.config,
          articleBill: passport.articleBill,
          properties: passport.properties,
          createdAt: passport.createdAt,
          updatedAt: passport.updatedAt,
        };
      }),
      isLoading:
        projectsIsLoading || clientNameIsLoading || passportStatesIsLoading,
    };
  }, [
    passports,
    projectsById,
    clientName,
    passportStates,
    projectsIsLoading,
    clientNameIsLoading,
    passportStatesIsLoading,
  ]);
}
