import React, { Fragment } from "react";
import {
  ButtonSP,
  IgnoreTag,
  PartIcon,
  UpdateTag,
} from "../../../../../components";
import { StandardPartState } from "../types";
import StandardPartActionSelect from "./StandardPartActionSelect";
import styles from "./UploadedPartsList.module.scss";

export default function StandardParts(props: {
  parts: StandardPartState[];
  onUpdateStandards: (parts: StandardPartState[]) => void;
}) {
  const { parts, onUpdateStandards } = props;

  const handleChangePartAction =
    (partId: number) => (action: "ignore" | "update") =>
      onUpdateStandards(
        parts.map((st) =>
          st.partId === partId ? { ...st, action: action } : st
        )
      );

  return (
    <Fragment>
      {parts.map((part) => (
        <tr
          key={part.partId}
          className={
            part.action === "ignore" ? styles.ignored_file_row : undefined
          }
        >
          <td />
          <td>
            <div>
              <PartIcon isStandard type={part.type} inline />
              {part.name}
            </div>
          </td>
          <td>
            <StandardPartActionSelect
              onChangePartAction={handleChangePartAction(part.partId)}
              selectedAction={part.action}
            >
              <ButtonSP
                isDense
                isFill
                view="flat"
                color="primary"
                rightIcon="caret-down"
              >
                {part.action === "update" && <UpdateTag />}
                {part.action === "ignore" && <IgnoreTag />}
              </ButtonSP>
            </StandardPartActionSelect>
          </td>
          <td colSpan={2} />
        </tr>
      ))}
    </Fragment>
  );
}
