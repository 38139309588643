import classNames from "classnames";
import React from "react";
import styles from "./ListTable.module.scss";

export default function ListTable(props: {
  children: React.ReactNode;
  columns?: React.ReactNode;
  className?: string;
}) {
  const { children, columns, className } = props;

  return (
    <table className={classNames(styles.table, className)}>
      <thead>{columns}</thead>
      <tbody>{children}</tbody>
    </table>
  );
}
