import { Part } from "@aletiq/types";
import React from "react";
import { useHasPermission, useHasVisibleScrollbar } from "../../../../../hooks";
import FileActionTable from "../../../../documents/common/FileActionTable";
import { PartAction, PartState, StandardPartState } from "../types";
import LockedParts from "./LockedParts";
import PartTableHeader from "./PartTableHeader";
import StandardParts from "./StandardParts";
import UnsupportedFiles from "./UnsupportedFiles";
import styles from "./UploadedPartsList.module.scss";
import ValidParts from "./ValidParts";

export default function UploadedPartsList(props: {
  isLoading: boolean;
  lockedParts: Part[];
  onUpdatePartStates: (states: PartState[]) => void;
  partStates: PartState[];
  unsupportedFiles: File[];
  standardParts: StandardPartState[];
  onUpdateStandards: (states: StandardPartState[]) => void;
}) {
  const {
    isLoading,
    lockedParts,
    onUpdatePartStates,
    partStates,
    unsupportedFiles,
    standardParts,
    onUpdateStandards,
  } = props;

  const canUpdateParts = useHasPermission("update:parts");

  const {
    verticalScrollbarSize,
    containerRef: tableBodyRef,
    isScrollbarVisible,
  } = useHasVisibleScrollbar();

  const handleBatchUpdateRevisionName = (name: string) =>
    onUpdatePartStates(
      partStates.map((state) => ({
        ...state,
        partInfo: {
          ...state.partInfo,
          revision: { ...state.partInfo.revision, name },
        },
      }))
    );

  const handleBatchUpdateDescription = (description: string) =>
    onUpdatePartStates(
      partStates.map((state) => ({
        ...state,
        partInfo: {
          ...state.partInfo,
          revision: { ...state.partInfo.revision, description },
        },
      }))
    );

  const handleBatchUpdateFileAction = (action: PartAction) => {
    onUpdatePartStates(
      partStates.map((state) => {
        const canUpdateAction =
          action.type === "ignore" ||
          // new files can only be created or ignored (no part to update)
          (action.type === "update" && state.partId) ||
          // existing parts can only be updated or ignored
          (action.type === "create" && !state.partId);

        return canUpdateAction
          ? {
              ...state,
              partInfo: {
                ...state.partInfo,
                action,
              },
            }
          : state;
      })
    );
    if (action.type !== "create") {
      onUpdateStandards(
        standardParts.map((part) =>
          action.type !== "create" ? { ...part, action: action.type } : part
        )
      );
    }
  };

  const handleUpdatePartState = (newState: PartState) =>
    onUpdatePartStates(
      partStates.map((state) =>
        state.file.name === newState.file.name ? newState : state
      )
    );

  return (
    <>
      <FileActionTable classname={styles.table_header}>
        <PartTableHeader
          isLoading={isLoading}
          canApproveRevision={canUpdateParts}
          onBatchUpdateIndex={handleBatchUpdateRevisionName}
          onBatchUpdateAction={handleBatchUpdateFileAction}
          onBatchUpdateDescription={handleBatchUpdateDescription}
          scrollbarSize={verticalScrollbarSize}
          isScrollbarVisible={isScrollbarVisible}
        />
      </FileActionTable>
      <div className={styles.table_wrapper} ref={tableBodyRef}>
        <FileActionTable classname={styles.table}>
          <tbody>
            <ValidParts
              isDisabled={isLoading}
              parts={partStates}
              canApproveRevision={canUpdateParts}
              onUpdatePartState={handleUpdatePartState}
            />
            <LockedParts parts={lockedParts} />
            <UnsupportedFiles files={unsupportedFiles} />
            <StandardParts
              parts={standardParts}
              onUpdateStandards={onUpdateStandards}
            />
          </tbody>
        </FileActionTable>
      </div>
    </>
  );
}
