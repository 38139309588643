import React from "react";
import { Dialog } from "../../../components";
import { useTranslations } from "../../../util";
import { useDeleteProjectDefinition } from "../hooks";

export default function DeleteProjectDefinitionDialog(props: {
  projectId: number;
  definitionIdx: number;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { projectId, definitionIdx, onClose, onSuccess } = props;

  const tr = useTranslations();

  const { mutate: deleteDefinition, isLoading } =
    useDeleteProjectDefinition(projectId);

  const handleDelete = () => {
    deleteDefinition(definitionIdx, { onSuccess });
  };

  return (
    <Dialog
      isOpen
      icon="trash"
      intent="danger"
      onSecondaryClick={onClose}
      onPrimaryClick={handleDelete}
      submitting={isLoading}
      primaryText={tr.translateAsString("generic.action.delete")}
      secondaryText={tr.translateAsString("generic.action.cancel")}
      title={tr.translateAsString("project.definition.delete.title")}
    >
      {tr.translate("project.definition.delete.subtitle")}
    </Dialog>
  );
}
