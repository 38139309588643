import { Process, Task } from "@aletiq/types";
import React, { CSSProperties } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { useHasVisibleScrollbar } from "../../../hooks";
import TaskTable from "../common/TaskTable";
import { useProcesses } from "../hooks";
import { default as TaskComponent } from "./Task";
import styles from "./Tasks.module.scss";
import TasksHeader from "./TasksHeader";

const HEIGHT_TASK_ROW = 45;

export default function TasksList(props: {
  isLoading?: boolean;
  tasks: Task[];
  search: string;
}) {
  const { isLoading, tasks, search } = props;
  const { data: processes = [] } = useProcesses();

  const {
    containerRef: bodyTableRef,
    isScrollbarVisible,
    verticalScrollbarSize,
  } = useHasVisibleScrollbar();

  return (
    <div className={styles.list}>
      <TasksHeader
        isScrollbarVisible={isScrollbarVisible}
        verticalScrollbarSize={verticalScrollbarSize}
      />
      {!isLoading && (
        <div className={styles.virtualizedList}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={tasks.length}
                itemKey={(index) => tasks[index]?.id}
                itemSize={HEIGHT_TASK_ROW}
                width={width}
                itemData={{
                  tasks,
                  search,
                  processes,
                }}
                outerRef={bodyTableRef}
              >
                {TaskRow}
              </List>
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  );
}

const TaskRow = React.memo(
  (props: {
    index: number;
    style: CSSProperties;
    data: {
      tasks: Task[];
      processes: Process[];
      search: string;
    };
  }) => {
    const {
      index,
      style,
      data: { tasks, processes, search },
    } = props;
    const task = tasks[index];
    const process = processes.find((process) => task.process === process.id);

    return (
      <div style={style} key={task.id}>
        <TaskTable color={process?.color} key={task.id}>
          <TaskComponent
            task={{
              ...task,
              process: {
                id: process?.id ?? -1,
                title: process?.title ?? "",
                isArchived: process?.isArchived ?? false,
                owner: process?.owner ?? 0,
              },
            }}
            search={search}
          />
        </TaskTable>
      </div>
    );
  }
);
