import { DocumentRevisionSpec } from "@aletiq/types";
import React, { useEffect, useState } from "react";
import {
  Callout,
  Dialog,
  Field,
  FileInput,
  GenericStateTag,
  Input,
  Radio,
  TextArea,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { useDocument } from "../../hooks";
import useDocumentRevisionSignatures from "../../hooks/useDocumentRevisionSignatures";
import styles from "./UploadRevisionDialog.module.scss";

export default function UploadRevisionDialog(props: {
  isOpen: boolean;
  documentId: number;
  onSave: (revisionSpec: DocumentRevisionSpec | null, file: File) => void;
  onDiscard: () => void;
}) {
  const { isOpen, onSave, onDiscard, documentId } = props;
  const tr = useTranslations();

  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [validateVersion, setValidateVersion] = useState(false);

  const { data: document } = useDocument(documentId);
  const revisions = document?.revisions || [];

  const isNameInUse =
    name !== "" &&
    revisions.some((r) => r.revisionName === name && r.state === "approved");

  const lastRevision = revisions.length > 0 ? revisions[0] : null;
  const lastRevisionName = lastRevision?.revisionName ?? "";

  const { data: signatures = [] } = useDocumentRevisionSignatures(
    documentId,
    lastRevision?.id ?? 0
  );

  const callout =
    lastRevision && lastRevision.state === "draft" && signatures.length > 0;

  useEffect(() => {
    //once document revisions are loaded,
    //use draft revision anme & description as default values
    if (document?.revisions[0].state === "draft") {
      setName(document.revisions[0].revisionName);
      setDescription(document.revisions[0].description);
    }
  }, [document]);

  const handleSubmit = () => {
    if (file) {
      if (!validateVersion) {
        onSave({ name: name, description: description, state: "draft" }, file);
      } else if (name !== "") {
        onSave(
          {
            name: name,
            description: description,
            state: validateVersion ? "approved" : "draft",
          },
          file
        );
      }
    }
    onDiscard();
  };

  const handleFileInput = (ev: React.FormEvent<HTMLInputElement>) => {
    ev.preventDefault();

    const files = ev.currentTarget.files;

    setFile((files && files[0]) || null);
  };

  const isComplete = file !== null && (!validateVersion || name !== "");

  return (
    <Dialog
      isOpen={isOpen}
      icon="upload"
      className={styles.dialog}
      onClose={onDiscard}
      title={tr.translateAsString("storage.dialog.upload-revision.title")}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={onDiscard}
      disablePrimary={!isComplete || isNameInUse}
    >
      <div className={styles.container}>
        <p>{tr.translate("storage.dialog.upload-revision.subtitle")}</p>
        <Field
          label={tr.translate("storage.dialog.upload-revision.file.label")}
        >
          <FileInput
            fill
            className={styles.file_input}
            inputProps={{ id: "file-input" }}
            files={file ? [file] : undefined}
            onInputChange={handleFileInput}
          />
        </Field>
        <Field
          label={tr.translate("storage.dialog.upload-revision.name.label")}
        >
          <div>
            <Input
              fill
              autoFocus
              aria-label="name"
              placeholder={tr.translateAsString(
                "storage.dialog.upload-revision.name.placeholder",
                { name: lastRevisionName }
              )}
              value={name}
              onChange={setName}
              hasError={isNameInUse}
              errorHelperText={tr.translateAsString(
                "document.details.revisions.name.in-use"
              )}
            />
          </div>
        </Field>

        <Field label={tr.translate("generic.label.description")}>
          <TextArea
            fill
            value={description}
            onChange={setDescription}
            placeholder={tr.translateAsString(
              "storage.dialog.upload-revision.desc.placeholder"
            )}
            growVertically
          />
        </Field>

        <Field
          label={tr.translate("storage.dialog.upload-revision.validate.label")}
        >
          <div className={styles.validation}>
            <div className={styles.radio}>
              <Radio
                checked={!validateVersion}
                onClick={() => setValidateVersion(false)}
                isDense
              />
              <GenericStateTag state="draft" />
            </div>
            <div className={styles.radio}>
              <Radio
                checked={validateVersion}
                onClick={() => setValidateVersion(true)}
                isDense
              />
              <GenericStateTag state="approved" />
            </div>
          </div>
        </Field>

        {validateVersion && (
          <Callout isDense icon="warning-sign" intent="warning">
            {tr.translate("storage.dialog.upload-revision.validate.warning")}
          </Callout>
        )}
        {callout && (
          <Callout
            isDense
            icon="info-sign"
            intent="warning"
            title={tr.translateAsString(
              "storage.dialog.upload-revision.signature.header"
            )}
          >
            {tr.translate("storage.dialog.upload-revision.signature.hint")}
          </Callout>
        )}
      </div>
    </Dialog>
  );
}
