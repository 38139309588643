import { Tenant } from "@aletiq/types";
import { useQuery, UseQueryResult } from "react-query";
import useApi from "../../../app/useApi";
import { adminKeys } from "./queries";

export default function useTenants(): UseQueryResult<Tenant[]> {
  const api = useApi();

  return useQuery(adminKeys.tenants(), () =>
    api.http.getJson("/admin/tenants")
  );
}
