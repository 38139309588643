import { useQueries } from "react-query";
import useApi from "../../../app/useApi";
import { isNotUndefined } from "../../../util";
import { pdmQueries } from "./queries";

export default function usePartsById(partsIds: number[]) {
  const api = useApi();
  const partsQueries = useQueries(
    partsIds.map((partId) => pdmQueries.byId(api, partId))
  );

  return partsQueries.flatMap((res) => res.data).filter(isNotUndefined);
}
