import { DocumentQueryOrder, Property } from "@aletiq/types";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import {
  Button,
  defaultCreatedAtColumn,
  defaultNameColumn,
  defaultPropertyColumn,
  defaultUpdatedAtColumn,
  ExtendedColumn,
  User,
} from "../../../components";
import { ColumnOptionConfig } from "../../../hooks";
import { extractBy, indexBy, mapRecord, useTranslations } from "../../../util";
import {
  useAletiqPropertiesDefaultDisplay,
  useProperties,
} from "../../administration/Attributes/hooks";
import DocumentRevisionCell from "../Documents/DocumentListPaginated/DocumentRevisionCell";
import { ResolvedDocument } from "../Documents/documentTypes";

export type DocumentColumn = DocumentQueryOrder | number;

export function useDefaultDocumentColumnConfig(
  options: {
    showRevision?: boolean;
  } = {}
) {
  const { showRevision = false } = options;

  const { data: properties = [], isLoading: isLoadingCustomProperties } =
    useProperties("document");
  const { data: aletiqProperties = [], isLoading: isLoadingAletiqProperties } =
    useAletiqPropertiesDefaultDisplay("document");

  const isLoading = useMemo(
    () => isLoadingAletiqProperties || isLoadingCustomProperties,
    [isLoadingAletiqProperties, isLoadingCustomProperties]
  );

  const config = useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    const config: ColumnOptionConfig<DocumentColumn> = {
      name: true,
      lastRevisionName: showRevision,
      ownerName: false,
      createdAt:
        aletiqProperties.find((p) => p.name === "created_at")
          ?.displayByDefault ?? false,
      updatedAt:
        aletiqProperties.find((p) => p.name === "updated_at")
          ?.displayByDefault ?? false,
    };

    for (const property of properties) {
      config[property.id] = property.displayByDefault;
    }

    return config;
  }, [properties, aletiqProperties, isLoading, showRevision]);

  return { isLoading, config };
}

export function useDocumentTableColumns(
  config: ColumnOptionConfig<DocumentColumn> = {} as ColumnOptionConfig<DocumentColumn>,
  onShowFilePreview?: (documentId: number) => void
): ExtendedColumn<ResolvedDocument, DocumentQueryOrder>[] {
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("document");

  const propertyRecord: Record<DocumentColumn, Property> = indexBy(
    properties,
    "id"
  );

  return useMemo(() => {
    const handleShowPreview = (document: ResolvedDocument) => () =>
      onShowFilePreview && onShowFilePreview(document.id);

    const columns: Record<
      DocumentColumn,
      ExtendedColumn<ResolvedDocument, DocumentQueryOrder>
    > = {
      name: defaultNameColumn("document"),
      lastRevisionName: {
        Header: tr.translate("filelist.last-revision"),
        accessor: "lastRevisionName",
        Cell: (cellProps: CellProps<ResolvedDocument>) => (
          <DocumentRevisionCell cellProps={cellProps} />
        ),
        hoverButton: (document: ResolvedDocument, className?: string) => (
          <Button
            isDense
            intent="array_primary"
            isDisabled={!document.lastRevisionState}
            onClick={handleShowPreview(document)}
            className={className}
          >
            {tr.translate("documents.view.action.preview")}
          </Button>
        ),
      },
      ownerName: {
        Header: tr.translate("admin.attribute.documents.owner"),
        id: "ownerName",
        accessor: (row: ResolvedDocument) => row.user,
        Cell: (props: CellProps<ResolvedDocument>) => (
          <User userId={props.row.original.user} size="small" />
        ),
      },
      createdAt: defaultCreatedAtColumn(),
      updatedAt: defaultUpdatedAtColumn(),
    };

    const propertyColumns: Record<
      DocumentColumn,
      ExtendedColumn<ResolvedDocument, DocumentQueryOrder>
    > = mapRecord(propertyRecord, (prop) => defaultPropertyColumn(prop));

    return [
      ...extractBy(columns, config),
      ...extractBy(propertyColumns, config),
    ];
  }, [tr, config, onShowFilePreview, propertyRecord]);
}
