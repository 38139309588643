import React from "react";
import { Dialog } from "../../../components";
import { isNotUndefined, useTranslations } from "../../../util";
import { useDeleteProductTreeComponents } from "../hooks";
import { ProductTree } from "../ProjectNomenclature/hooks";

export default function DeleteProjectComponentsDialog(props: {
  components: ProductTree[];
  onClose: () => void;
}) {
  const { components, onClose } = props;
  const tr = useTranslations();

  const { mutate: deleteProjectComponents, isLoading } =
    useDeleteProductTreeComponents();

  const handleSubmit = () => {
    deleteProjectComponents(
      components
        .map((c) =>
          c.type === "project"
            ? {
                productId: c.parent.productId,
                definitionIdx: c.parent.definitionIdx,
                componentId: c.entry.entity,
              }
            : undefined
        )
        .filter(isNotUndefined),
      { onSettled: onClose }
    );
  };

  return (
    <Dialog
      isOpen
      icon="trash"
      intent="danger"
      title={tr.translateAsString("project.dialog.components.delete.title", {
        count: components.length,
      })}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
      onClose={onClose}
    >
      {tr.translate("project.dialog.components.delete.description")}
    </Dialog>
  );
}
