import { Process } from "@aletiq/types";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { ExtendedColumn, HighlightedText, Icon, Table } from "../../components";
import { useNavigate, useSort } from "../../hooks";
import { useTranslations } from "../../util";
import { useAccessSearchResult } from "./hooks";

export default function ProcessSearchList(props: {
  processes: Process[];
  searchString: string;
}) {
  const { processes, searchString } = props;
  const tr = useTranslations();

  const navigate = useNavigate();

  const {
    sortKey: key,
    sortDirection: direction,
    onSortKeyChange: onSort,
    sortedList: sortedProcesses,
  } = useSort<Process>(processes, "updatedAt", "desc");

  const { mutate: accessSearchResult } = useAccessSearchResult();

  const handleOpenWorkflowPanel = (workflow: Process) => {
    navigate({
      panel: `workflowId-${workflow.id}`,
      hash: `${workflow.id}`,
    });
    accessSearchResult({ type: "process", id: workflow.id });
  };

  const columns: ExtendedColumn<Process>[] = useMemo(
    () => [
      {
        Header: tr.translate("search.tab.workflow"),
        accessor: "title",
        Cell: (props: CellProps<Process>) => (
          <>
            <Icon icon="flow-linear" inline />
            <HighlightedText
              text={props.row.original.title}
              highlight={props.searchString}
            />
          </>
        ),
      },
    ],
    [tr]
  );

  return (
    <Table
      columns={columns}
      data={sortedProcesses}
      searchString={searchString}
      openRowOptions={{ onOpen: handleOpenWorkflowPanel }}
      sortOptions={{
        sortState: { key, direction },
        onSort,
      }}
    />
  );
}
