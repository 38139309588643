import { PropertyObject } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { propertyKeys } from "./queries";

export default function useProperties(objectType: PropertyObject) {
  const api = useApi();
  return useQuery(propertyKeys.byObjectType(objectType), () =>
    api.property.getProperties(objectType)
  );
}
