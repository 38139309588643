import {
  Dialog,
  HighlightedText,
  Icon,
  SearchInput,
} from "@aletiq/design-system";
import { EntityId, Process } from "@aletiq/types";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { CellProps } from "react-table";
import { ExtendedColumn, Table } from "../../../../components";
import { useSort } from "../../../../hooks";
import { filterByString, useTranslations } from "../../../../util";
import { useAddProcessesRelations, useProcesses } from "../../hooks";
import styles from "./LinkEntitiesToProcessesDialog.module.scss";

export default function LinkEntitiesToProcessesDialog(props: {
  entities: EntityId[];
  onClose: () => void;
  title: string;
  subtitle?: ReactNode;
}) {
  const { entities, onClose, title, subtitle } = props;
  const tr = useTranslations();

  const { data: processes = [] } = useProcesses();
  const { mutate: addProcessesRelation, isLoading } =
    useAddProcessesRelations();

  const [search, setSearch] = useState("");
  const [selectedProcesses, setSelectedProcesses] = useState<number[]>([]);

  const activeProcesses = useMemo(
    () => processes.filter((process) => !process.isArchived),
    [processes]
  );

  const visibleProcesses = useMemo(
    () => filterByString(activeProcesses, (process) => process.title, search),
    [activeProcesses, search]
  );

  const { sortKey, sortDirection, onSortKeyChange, sortedList } =
    useSort<Process>(visibleProcesses, "title");

  const columns: ExtendedColumn<Process>[] = useMemo(
    () => [
      {
        Header: tr.translate("project.list.name"),
        accessor: "title",
        Cell: (props: CellProps<Process>) => (
          <>
            <Icon icon="flow-linear" inline />
            <HighlightedText
              text={props.row.original.title}
              highlight={props.searchString}
            />
          </>
        ),
      },
    ],
    [tr]
  );

  const handleSelect = useCallback((selection: Process[]) => {
    setSelectedProcesses(selection.map((p) => p.id));
  }, []);

  const handleConfirm = () => {
    const processToAdd = selectedProcesses.map((processId) => ({
      id: processId,
      entities,
    }));

    addProcessesRelation(processToAdd, {
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      title={title}
      className={styles.dialog}
      icon="new-link"
      onClose={onClose}
      onPrimaryClick={handleConfirm}
      onSecondaryClick={onClose}
      submitting={isLoading}
    >
      <div>{subtitle}</div>

      <SearchInput
        fill
        className={styles.searchInput}
        value={search}
        onChange={setSearch}
      />

      <div className={styles.tab}>
        <Table
          columns={columns}
          data={sortedList}
          searchString={search}
          sortOptions={{
            sortState: { key: sortKey, direction: sortDirection },
            onSort: onSortKeyChange,
          }}
          onSelect={handleSelect}
          className={styles.tab}
        />
      </div>
    </Dialog>
  );
}
