import { Property, PropertyValue } from "@aletiq/types";
import React from "react";
import { EditablePropertyInput } from "../../../../components";
import { useTranslations } from "../../../../util";

export function CustomTextField(props: {
  property: Property;
  propertyValue?: { type: "string"; value: string };
  onSubmit: (value: PropertyValue) => void;
  isSubmitting?: boolean;
  canEdit: boolean;
  hasDescriptionTooltip?: boolean;
  search?: string;
  minWidth?: number;
}) {
  const {
    propertyValue,
    property,
    canEdit,
    onSubmit,
    search,
    hasDescriptionTooltip,
    minWidth,
  } = props;
  const tr = useTranslations();

  const handleSubmit = (value: string) => {
    onSubmit({ type: "string", value });
  };

  return (
    <EditablePropertyInput
      value={propertyValue?.value ?? ""}
      isEditable={canEdit}
      label={property.label}
      placeholder={tr.translateAsString("generic.action.modify-value", {
        value: property.label,
      })}
      emptyState={tr.translateAsString("generic.label.none")}
      tooltipText={hasDescriptionTooltip ? property.description : undefined}
      search={search}
      onSubmit={handleSubmit}
      minWidth={minWidth}
      inline
    />
  );
}
