import { OperationTool } from "@aletiq/types";
import classNames from "classnames";
import React, { useState } from "react";
import {
  AlertPopover,
  Button,
  Card,
  Field,
  GenericStatusTag,
  HighlightedText,
  Menu,
  MenuItem,
  Popover2,
} from "../../../../components";
import { useNavigateTo } from "../../../../hooks";
import { ChangeStatus, isInString, useTranslations } from "../../../../util";
import { useProject } from "../../hooks";
import { useAddOperationTool, useDeleteOperationTool } from "../hooks";
import styles from "./OperationToolEditor.module.scss";
import ToolVersionSelect from "./ToolVersionSelect";

export default function OperationToolEditor(props: {
  projectId: number;
  billId: number;
  operationNumber: number;
  operationTool: OperationTool;
  status?: ChangeStatus;
  search: string;
}) {
  const {
    projectId,
    billId,
    operationNumber,
    operationTool,
    status = "unchanged",
    search,
  } = props;
  const tr = useTranslations();

  const [isDeleting, setDeleting] = useState(false);

  const { data: tool } = useProject(operationTool.tool);

  const { mutate: restoreTool, isLoading: isRestoringTool } =
    useAddOperationTool(projectId, billId, operationNumber);

  const { mutate: removeTool, isLoading: isRemovingTool } =
    useDeleteOperationTool(
      projectId,
      billId,
      operationNumber,
      operationTool.tool
    );

  const isToolVisible = isInString(tool?.name ?? "", search);

  const openToolPassports = useNavigateTo({
    path: `/project/${operationTool.tool}/passports`,
  });
  const openToolDocuments = useNavigateTo({
    path: `/project/${operationTool.tool}/documents`,
  });

  const handleRestore = () => restoreTool(operationTool);

  const handleRemove = () => removeTool();

  const toggleIsDeleting = () => setDeleting(!isDeleting);

  if (!isToolVisible) {
    return null;
  }

  return (
    <Card
      icon="wrench"
      title={<HighlightedText text={tool?.name} highlight={search} />}
      titleClassName={classNames(status === "deleted" && styles.tool_deleted)}
      headerActions={
        <>
          <GenericStatusTag status={status} />
          <AlertPopover
            icon="trash"
            isDense
            title={tr.translate("project.operation-bill.operation-tool.remove")}
            content={tr.translate(
              "project.operation-bill.operation-tool.remove.confirm"
            )}
            onClose={toggleIsDeleting}
            onPrimaryClick={handleRemove}
            isOpen={isDeleting}
          >
            <Popover2
              content={
                <Menu isDense>
                  <MenuItem
                    icon="id-number"
                    text={tr.translate(
                      "project.operation-bill.operation-tool.show-passports"
                    )}
                    onClick={openToolPassports}
                    isDense
                  />
                  <MenuItem
                    icon="document"
                    text={tr.translate(
                      "project.operation-bill.operation-tool.show-documents"
                    )}
                    onClick={openToolDocuments}
                    isDense
                  />
                  {status === "deleted" ? (
                    <MenuItem
                      isDense
                      icon="reset"
                      text={tr.translate("generic.action.restore")}
                      onClick={handleRestore}
                    />
                  ) : (
                    <MenuItem
                      isDense
                      icon="trash"
                      text={tr.translate("generic.action.delete")}
                      onClick={toggleIsDeleting}
                    />
                  )}
                </Menu>
              }
            >
              <Button
                isDense
                icon="more"
                isDisabled={status === "deleted"}
                isLoading={isRemovingTool || isRestoringTool}
                className={styles.menu_button}
              />
            </Popover2>
          </AlertPopover>
        </>
      }
      className={styles.tool_card}
    >
      <Field
        inline
        minWidth={180}
        label={tr.translate("generic.label.description")}
      >
        {tool?.description}
      </Field>
      <Field
        inline
        minWidth={180}
        label={tr.translate("project.operation-bill.operation-tool.version")}
      >
        <ToolVersionSelect
          projectId={projectId}
          billId={billId}
          operationNumber={operationNumber}
          tool={operationTool}
          isDisabled={status === "deleted"}
        />
      </Field>
    </Card>
  );
}
