import React from "react";
import { ANALYTICS_VISITED_HOME, useTrackEvent } from "../../analytics";
import { PageContents, PageTitle } from "../../components";
import { useSetPageTitle } from "../../hooks";
import { useTranslations } from "../../util";
import UserTasks from "../tasks/UserTasks/UserTasks";
import styles from "./Home.module.scss";
import { HomeActivity } from "./HomeActivity";
import { HomeShortcuts } from "./HomeShortcuts";

export default function Home() {
  const tr = useTranslations();
  useSetPageTitle(tr.translateAsString("page.title.home"));
  useTrackEvent(ANALYTICS_VISITED_HOME);

  return (
    <PageContents>
      <div className={styles.page}>
        <PageTitle icon="home">{tr.translate("navbar.home")}</PageTitle>
        <UserTasks className={styles.tasks} />
        <HomeActivity className={styles.activities} />
        <HomeShortcuts className={styles.shortcuts} />
      </div>
    </PageContents>
  );
}
