import { ProcessQueryParams } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { processKeys } from "./queries";

export default function useProcesses(params?: ProcessQueryParams) {
  const api = useApi();
  return useQuery(processKeys.list(params), () =>
    api.task.getProcesses(params)
  );
}
