import { ProjectType } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { projectKeys } from "../../../project/hooks/queries";

export default function useCountProductsWithDescriptions(
  productType?: ProjectType
) {
  const api = useApi();

  return useQuery(projectKeys.countWithDescription(productType), () =>
    api.project.countProductsWithDescriptions(productType)
  );
}
