import { PartQueryFilterParams, PartType } from "@aletiq/types";
import React from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  DateFilter,
  EditColumnButton,
  MoreFilters,
  Popover2,
  SearchInput,
  StandardPartTypeFilter,
  Tooltip,
} from "../../../../components";
import PartFormatFilter from "../../../../components/filter/PartFormatFilter";
import {
  ColumnOptionConfig,
  HandleClear,
  HandleFilter,
} from "../../../../hooks";
import { useTranslations } from "../../../../util";
import PartCreationMenu from "../../common/PartCreationMenu";
import { StandardPartColumn } from "../../hooks/useStandardPartTableColumns";
import SelectStandardPartColumn from "./SelectStandardPartColumn";
import styles from "./StandardPartsTable.module.scss";

export default function StandardPartsActionBar(props: {
  filter: PartQueryFilterParams;
  handleFilter: HandleFilter<PartQueryFilterParams>;
  handleClear: HandleClear<PartQueryFilterParams>;
  onCreatePart: (type: PartType, isStandrd: boolean) => void;
  columnConfig: ColumnOptionConfig<StandardPartColumn>;
  onToggleColumn: (column: StandardPartColumn) => void;
}) {
  const {
    onCreatePart,
    filter,
    handleClear,
    handleFilter,
    columnConfig,
    onToggleColumn,
  } = props;
  const tr = useTranslations();

  return (
    <ActionBar>
      <Tooltip
        content={tr.translateAsString("part.view.tooltip-search")}
        position="top-left"
      >
        <SearchInput
          isDense
          value={filter.search ?? ""}
          onChange={handleFilter("search")}
          className={styles.searchbar}
        />
      </Tooltip>
      <StandardPartTypeFilter
        selected={filter.partTypes ?? []}
        onClear={handleClear(["partTypes"])}
        onSelect={handleFilter("partTypes")}
      />
      <PartFormatFilter
        selected={filter.formats ?? []}
        onSelect={handleFilter("formats")}
        onClear={handleClear(["formats"])}
      />
      <MoreFilters
        filterValues={filter}
        additionalFilters={["createdAt", "updatedAt"]}
        onClear={handleClear}
      >
        <DateFilter
          selected={filter?.createdAt}
          onSelect={handleFilter("createdAt")}
          label={tr.translateAsString("generic.label.creation-date.title")}
        />
        <DateFilter
          selected={filter?.updatedAt}
          onSelect={handleFilter("updatedAt")}
          label={tr.translateAsString("generic.label.last-update.title")}
        />
      </MoreFilters>
      <AlignRight />

      <SelectStandardPartColumn
        columnConfig={columnConfig}
        onToggleColumn={onToggleColumn}
      >
        <EditColumnButton />
      </SelectStandardPartColumn>

      <Popover2
        position="bottom-right"
        content={<PartCreationMenu allowStandard onCreatePart={onCreatePart} />}
        intent="primary"
      >
        <Button isDense icon="plus" intent="secondary">
          {tr.translate("standard-parts.action.new")}
        </Button>
      </Popover2>
    </ActionBar>
  );
}
