import React from "react";
import { CustomIcon, CustomIconProps } from "./CustomIcon";

const paths = [
  "M1 7C1.55 7 2 6.55 2 6V2H6C6.55 2 7 1.55 7 1C7 0.45 6.55 0 6 0H1C0.45 0 0 0.45 0 1V6C0 6.55 0.45 7 1 7ZM6 8C6 7.45 5.55 7 5 7C4.72 7 4.47 7.11 4.29 7.29L2.29 9.29C2.11 9.47 2 9.72 2 10C2 10.28 2.11 10.53 2.29 10.71L4.29 12.71C4.47 12.89 4.72 13 5 13C5.55 13 6 12.55 6 12C6 11.72 5.89 11.47 5.71 11.29L4.41 10L5.7 8.71C5.89 8.53 6 8.28 6 8ZM8 6C8.28 6 8.53 5.89 8.71 5.71L10 4.41L11.29 5.7C11.47 5.89 11.72 6 12 6C12.55 6 13 5.55 13 5C13 4.72 12.89 4.47 12.71 4.29L10.71 2.29C10.53 2.11 10.28 2 10 2C9.72 2 9.47 2.11 9.29 2.29L7.29 4.29C7.11 4.47 7 4.72 7 5C7 5.55 7.45 6 8 6ZM6 18H2V14C2 13.45 1.55 13 1 13C0.45 13 0 13.45 0 14V19C0 19.55 0.45 20 1 20H6C6.55 20 7 19.55 7 19C7 18.45 6.55 18 6 18ZM14 12C14 12.55 14.45 13 15 13C15.28 13 15.53 12.89 15.71 12.71L17.71 10.71C17.89 10.53 18 10.28 18 10C18 9.72 17.89 9.47 17.71 9.29L15.71 7.29C15.53 7.11 15.28 7 15 7C14.45 7 14 7.45 14 8C14 8.28 14.11 8.53 14.29 8.71L15.59 10L14.3 11.29C14.11 11.47 14 11.72 14 12ZM19 0H14C13.45 0 13 0.45 13 1C13 1.55 13.45 2 14 2H18V6C18 6.55 18.45 7 19 7C19.55 7 20 6.55 20 6V1C20 0.45 19.55 0 19 0ZM12 14C11.72 14 11.47 14.11 11.29 14.29L10 15.59L8.71 14.3C8.53 14.11 8.28 14 8 14C7.45 14 7 14.45 7 15C7 15.28 7.11 15.53 7.29 15.71L9.29 17.71C9.47 17.89 9.72 18 10 18C10.28 18 10.53 17.89 10.71 17.71L12.71 15.71C12.89 15.53 13 15.28 13 15C13 14.45 12.55 14 12 14ZM19 13C18.45 13 18 13.45 18 14V18H14C13.45 18 13 18.45 13 19C13 19.55 13.45 20 14 20H19C19.55 20 20 19.55 20 19V14C20 13.45 19.55 13 19 13Z",
];

export default function ZoomToFitIcon(props: CustomIconProps) {
  return (
    <CustomIcon
      paths={paths}
      iconName="zoom-to-fit"
      {...props}
      pixelGridSize={20}
    />
  );
}
