import { DocumentRevision, RevisionState } from "@aletiq/types";
import React, { useState } from "react";
import { AlignRight } from "../../../../components";
import {
  ChangeRevisionAdminButton,
  ChangeRevisionEditButton,
} from "../../../../components/ChangeRevisionStateButton/";
import {
  RevisionCard,
  RevisionCardHeader,
  RevisionEditableDescription,
  RevisionEditableName,
  RevisionInfos,
} from "../../../../components/revisions";
import { isAlreadyUsedName } from "../../../../util";
import {
  DeleteDocumentDraftDialog,
  DocumentSigningDialog,
} from "../../dialogs";
import {
  useDocumentRelatedDefinitions,
  useDocumentRelatedOperations,
  useDocumentRevisionSignatures,
  useUpdateDocumentRevisionSpec,
} from "../../hooks";
import RevisionButtonGroup from "./RevisionButtonGroup";
import SignaturesTooltip from "./SignaturesTooltip";

export default function RevisionItem(props: {
  documentId: number;
  documentName: string;
  revision: DocumentRevision;
  isDeleteDisabled: boolean;
  closedRevisionsNames: string[];
  onShowFile: (revisionId: number) => void;
  onDownload: (revision: number) => void;
  onDownloadStamped: (revision: number) => void;
  isEditable?: boolean;
  isAdminUser?: boolean;
}) {
  const {
    isEditable,
    isAdminUser,
    documentId,
    documentName,
    revision,
    closedRevisionsNames,
    isDeleteDisabled,
    onShowFile,
    onDownload,
    onDownloadStamped,
  } = props;

  const [editedName, setEditedName] = useState(revision.revisionName);
  const [dialog, setDialog] = useState<"sign" | "delete">();

  const isDraft = revision.state === "draft";
  const isNameInUse = isAlreadyUsedName(editedName, closedRevisionsNames);

  const { mutate: updateDocDraftSpec } = useUpdateDocumentRevisionSpec();

  const handleUpdateName = () => {
    if (editedName === "") {
      setEditedName(revision.revisionName);
    } else {
      updateDocDraftSpec({
        documentId,
        revisionId: revision.id,
        spec: {
          name: editedName,
          description: revision.description,
          state: revision.state,
        },
      });
    }
  };

  const handleConfirmDescription = (description: string) => {
    updateDocDraftSpec({
      documentId,
      revisionId: revision.id,
      spec: {
        name: revision.revisionName,
        description,
        state: revision.state,
      },
    });
  };

  const handleChangeState = (state: RevisionState) => () => {
    updateDocDraftSpec({
      documentId,
      revisionId: revision.id,
      spec: {
        name: revision.revisionName,
        description: revision.description,
        state,
      },
    });
  };

  const handleCloseDialog = () => setDialog(undefined);

  const handleOpenDialog = (dialog: "sign" | "delete") => () => {
    setDialog(dialog);
  };

  const { data: signatures = [] } = useDocumentRevisionSignatures(
    documentId,
    revision.id
  );

  const { data: approvedDefinitions } = useDocumentRelatedDefinitions(
    documentId,
    revision.id,
    { states: ["approved"] }
  );

  const { data: approvedOperations } = useDocumentRelatedOperations(
    documentId,
    revision.id,
    { states: ["approved"] }
  );

  const isPublished =
    approvedDefinitions && approvedOperations
      ? approvedDefinitions.length > 0 || approvedOperations.length > 0
      : true;

  const canChange = isDraft ? isEditable && !isNameInUse : isEditable;

  return (
    <RevisionCard>
      <RevisionCardHeader>
        <span>
          <RevisionEditableName
            isEditable={isEditable && isDraft}
            isAlreadyUsed={isDraft && isNameInUse}
            value={editedName}
            onChange={setEditedName}
            onConfirm={handleUpdateName}
          />

          {isAdminUser ? (
            <ChangeRevisionAdminButton
              isPublished={isPublished}
              onChange={{
                obsolete: handleChangeState("obsolete"),
                approved: handleChangeState("approved"),
                draft: handleChangeState("draft"),
              }}
              state={revision.state}
              objectType={"document"}
            />
          ) : (
            <ChangeRevisionEditButton
              isPublished={isPublished}
              canChange={canChange}
              onChange={{
                obsolete: handleChangeState("obsolete"),
                approved: handleChangeState("approved"),
              }}
              state={revision.state}
              objectType={"document"}
            />
          )}
        </span>

        <AlignRight />

        <SignaturesTooltip signatures={signatures} />
        <RevisionButtonGroup
          onShowFile={onShowFile}
          onDownload={onDownload}
          onDownloadStamped={onDownloadStamped}
          onSign={handleOpenDialog("sign")}
          onDelete={handleOpenDialog("delete")}
          revision={revision}
          isDeleteDisabled={isDeleteDisabled}
          isEditable={isEditable && isDraft}
        />
      </RevisionCardHeader>

      <RevisionInfos userId={revision.user} date={revision.time} />

      <RevisionEditableDescription
        isEditable={isEditable && isDraft}
        defaultValue={revision.description}
        onConfirm={handleConfirmDescription}
      />

      {dialog === "sign" && (
        <DocumentSigningDialog
          documentId={documentId}
          documentName={documentName}
          revisionId={revision.id}
          onClose={handleCloseDialog}
          entry={revision}
          onShowFile={onShowFile}
        />
      )}
      {dialog === "delete" && (
        <DeleteDocumentDraftDialog
          documentId={documentId}
          revisionId={revision.id}
          onClose={handleCloseDialog}
        />
      )}
    </RevisionCard>
  );
}
