import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useProjectToolingRequirements(projectId: number) {
  const api = useApi();
  return useQuery(projectKeys.toolingRequirementById(projectId), () =>
    api.project.listToolingRequirements(projectId)
  );
}
