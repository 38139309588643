import React, { useState } from "react";
import {
  ANALYTICS_VISITED_INTEGRATION_REPORTS,
  useTrackEvent,
} from "../../../analytics";
import {
  ActionBar,
  CardList,
  ItemSelect,
  PageContents,
  PageTitle,
  SearchInput,
  Select,
} from "../../../components";
import { sortByDateString, useTranslations } from "../../../util";
import { AdministrationTabs } from "../common";
import { useIntegrationsErrorList, useUpdateIntegrationError } from "../hooks";
import styles from "./IntegrationsReport.module.scss";
import IntegrationReportCard from "./IntegrationsReportCard";

export type Filter = "all" | "processed" | "non-processed";

const FILTER_TRANSLATION: Record<Filter, string> = {
  all: "admin.integration.filter.all",
  processed: "admin.integration.filter.processed",
  "non-processed": "admin.integration.filter.non-processed",
};

export default function IntegrationsReport() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_INTEGRATION_REPORTS);

  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<Filter>("all");
  const [toggledReports, setToggledReports] = useState<number[]>([]);

  const { data: reportsList = [] } = useIntegrationsErrorList();
  const { mutate: updateIntegrationError } = useUpdateIntegrationError();

  const filteredReports = reportsList.filter((report) => {
    if (selectedFilter === "processed" && !report.isTreated) {
      return false;
    }

    if (selectedFilter === "non-processed" && report.isTreated) {
      return false;
    }

    if (search !== "") {
      const reportDate = new Date(report.date);
      const title = tr.translateAsString("admin.integration.report.name", {
        name: report.name,
        date: reportDate.toLocaleDateString(navigator.language),
        time: reportDate,
      });

      if (
        !title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
        !report.content.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      ) {
        return false;
      }
    }

    return true;
  });

  const reports = sortByDateString(
    filteredReports,
    (report) => report.date,
    "desc"
  );

  const handleToggleReport = (reportId: number) => {
    const indexInArray = toggledReports.indexOf(reportId);

    if (indexInArray !== -1) {
      setToggledReports([
        ...toggledReports.slice(0, indexInArray),
        ...toggledReports.slice(indexInArray + 1),
      ]);
    } else {
      setToggledReports([...toggledReports, reportId]);
    }
  };

  const handleTreatIntegration = (reportId: number, isTreated: boolean) => {
    updateIntegrationError({ id: reportId, isTreated: isTreated });
  };

  const filterOptions: ItemSelect<Filter>[] = [
    { key: "all", text: tr.translateAsString(FILTER_TRANSLATION["all"]) },
    {
      key: "processed",
      text: tr.translateAsString(FILTER_TRANSLATION["processed"]),
    },
    {
      key: "non-processed",
      text: tr.translateAsString(FILTER_TRANSLATION["non-processed"]),
    },
  ];

  return (
    <PageContents leftPanel={<AdministrationTabs />}>
      <PageTitle icon="data-connection">
        {tr.translate("admin.integration.title")}
      </PageTitle>
      <div className={styles.page}>
        <ActionBar>
          <SearchInput isDense value={search} onChange={setSearch} />
          <Select
            items={filterOptions}
            activeItem={selectedFilter}
            onItemSelect={setSelectedFilter}
            intent="outlined"
            isDense
          >
            {tr.translateAsString(FILTER_TRANSLATION[selectedFilter])}
          </Select>
        </ActionBar>
        <CardList>
          {reports.map((report) => (
            <IntegrationReportCard
              report={report}
              isOpen={toggledReports.includes(report.id)}
              onSetToggledReports={handleToggleReport}
              onSetIntegrationAsTreated={handleTreatIntegration}
              search={search}
            />
          ))}
        </CardList>
      </div>
    </PageContents>
  );
}
