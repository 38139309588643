import React from "react";
import { ExpandIcon, OffsetCell } from "../../../../components";
import ProductLink from "../../../../components/link/ProductLink";
import { NoChildRow, NotFetchedRow } from "../../../../components/table/rows";
import { ExpandCellProps, TreePath, treePathFromRowId } from "../../../../util";
import { ProductTree } from "../hooks";

const makePath = (tree: ProductTree) =>
  tree.type === "project"
    ? {
        id: tree.project.id,
        revision:
          tree.definition.state === "approved"
            ? tree.definition.index
            : tree.project.lastDefinition.index,
      }
    : undefined;

export default function ProjectNameCell(props: ExpandCellProps<ProductTree>) {
  const { rowsById, row, onToggleExpand, searchString } = props;
  const { depth, isExpanded, original: tree } = row;
  const canExpand = tree.type === "project" && !tree.entry.isLeaf;

  const handleExpandRow = () => {
    if (!canExpand) {
      return;
    }
    const project = tree.project;

    const definition =
      tree.definition.state === "approved"
        ? tree.definition
        : project.lastDefinition;

    const path: TreePath[] = [
      ...treePathFromRowId(row, rowsById, makePath),
      { id: project.id, revision: definition.index },
    ];

    onToggleExpand(path)(!tree.expanded);
  };

  return (
    <OffsetCell depth={depth}>
      {tree.type === "not-fetched" && <NotFetchedRow />}
      {tree.type === "empty" && <NoChildRow />}
      {tree.type === "project" && (
        <>
          <ExpandIcon
            hasExpand={canExpand}
            isExpanded={isExpanded}
            onExpand={handleExpandRow}
          />
          <ProductLink
            product={tree.project}
            search={searchString}
            hasThumbnail
          />
        </>
      )}
    </OffsetCell>
  );
}
