import { ProjectDefinitionDocument } from "@aletiq/types";
import React from "react";
import { CardList } from "../../../../components";
import { Diff } from "../../../../util";
import ProjectDefinitionDocumentCard from "./ProjectDefinitionDocumentCard";

export default function ProjectDefinitionDocumentsCardList(props: {
  search: string;
  projectId: number;
  definitionIdx: number;
  hasEdition?: boolean;
  documents: ProjectDefinitionDocument[];
  diff: Record<number, Diff<ProjectDefinitionDocument>>;
}) {
  const { search, documents, projectId, definitionIdx, hasEdition, diff } =
    props;
  return (
    <CardList>
      {documents.map((document) => (
        <ProjectDefinitionDocumentCard
          hasEdition={hasEdition}
          search={search}
          projectId={projectId}
          definitionIdx={definitionIdx}
          key={document.document}
          document={document}
          diff={diff[document.document]}
        />
      ))}
    </CardList>
  );
}
