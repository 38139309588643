import { PartComponentSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { pdmKeys } from "./queries";

export default function useRegisterPartComponent() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (values: { partId: number; component: PartComponentSpec }) => {
      const { partId, component } = values;
      await api.pdm.registerPartComponent(partId, component);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "warning",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.part.components.register"),
        });
      },
    }
  );
}
