import { These } from "../collection";

export type ChangeStatus = "unchanged" | "updated" | "new" | "deleted";

export type Diff<T> = {
  value: T;
  status: ChangeStatus;
};

export function diffThese<T>(
  these: These<T>,
  hasChanged: (first: T, second: T) => boolean
): Diff<T> {
  switch (these.type) {
    case "second":
      return {
        value: these.second,
        status: "deleted",
      };
    case "first":
      return {
        value: these.first,
        status: "new",
      };
    case "both":
      return hasChanged(these.first, these.second)
        ? {
            value: these.first,
            status: "updated",
          }
        : {
            value: these.first,
            status: "unchanged",
          };
  }
}
