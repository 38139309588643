import { ItemSelect, MultiSelectSP, Tag } from "@aletiq/design-system";
import React, { Key, useState } from "react";
import { EditablePropertyButtonGroup } from "./buttons";
import styles from "./EditableProperty.module.scss";
import EditablePropertyTemplate from "./EditablePropertyTemplate";

function EditablePropertyMultiSelect<T extends Key>(props: {
  label: string;
  isEditable: boolean;
  intent?: "primary" | "default";
  values: T[];
  items: ItemSelect<T>[];
  placeholder: string;
  emptyState: string;
  inline?: boolean;
  tooltipText?: string;
  search?: string;
  onSubmit: (value: T[]) => void;
  minWidth?: number;
}) {
  const {
    label,
    isEditable,
    intent = "default",
    values: initialValues,
    items,
    placeholder,
    inline,
    emptyState,
    tooltipText,
    search,
    onSubmit,
    minWidth,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState(initialValues);

  const handleToggleEditMode = () => {
    setEditedValues(initialValues);
    setEditMode(!editMode);
  };

  const handleSubmit = () => {
    onSubmit(editedValues);
    setEditMode(false);
  };

  const handleAddValue = (item: T) => {
    if (editedValues.includes(item)) {
      removeValues(item);
    } else {
      setEditedValues([...editedValues, item]);
    }
  };

  const handleRemoveValue = (item: T) => {
    removeValues(item);
  };

  const removeValues = (item: T) => {
    setEditedValues(editedValues.filter((val) => val !== item));
  };

  const valueItems = items?.filter((val) => initialValues.includes(val.key));

  return (
    <EditablePropertyTemplate
      intent={intent}
      isEditable={isEditable}
      label={label}
      triggerEditMode={handleToggleEditMode}
      isInEditMode={editMode}
      inline={inline}
      tooltipText={tooltipText}
      search={search}
      minWidth={minWidth}
    >
      {!editMode && (
        <div>
          <>{valueItems.length === 0 && emptyState}</>
          <div className={styles.tags}>
            {valueItems.length > 0 &&
              valueItems.map((item) => (
                <Tag
                  key={item.key}
                  intent={intent}
                  className={styles.tag}
                  unclickable
                  isDense
                >
                  {item.text}
                </Tag>
              ))}
          </div>
        </div>
      )}
      {editMode && (
        <>
          <MultiSelectSP
            filterable={false}
            popoverProps={{
              targetProps: { onClick: (ev) => ev.stopPropagation() },
              minimal: true,
              position: "bottom-left",
            }}
            selectedItems={editedValues}
            items={items}
            handleRemoveTag={handleRemoveValue}
            handleAddTag={handleAddValue}
            intent={intent}
            placeholder={placeholder}
            isDense
            fill
          />
          <EditablePropertyButtonGroup
            onSubmit={handleSubmit}
            onReset={handleToggleEditMode}
          />
        </>
      )}
    </EditablePropertyTemplate>
  );
}

export default EditablePropertyMultiSelect;
