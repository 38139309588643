import { SortDirection } from "@aletiq/types";

export default function sortBy<T, K>(
  values: T[],
  key: (value: T) => K,
  compare: (k1: K, k2: K) => number,
  order?: SortDirection
) {
  return values
    .slice()
    .sort((v1, v2) => compare(key(v1), key(v2)) * orderMultiplier(order));
}

function orderMultiplier(order: SortDirection = "asc") {
  if (order === "desc") {
    return -1;
  }
  return 1;
}
