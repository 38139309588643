import { Part } from "@aletiq/types";
import React from "react";
import FilterMenu from "../../../../components/filter/FilterMenu";
import PartSelectItem from "./PartSelectMenuItem";

export default function PartSelectMenu(props: {
  parts: Part[];
  search: string;
  onSearchChange: (search: string) => void;
  onAssign: (part: Part) => void;
}) {
  const { parts, search, onSearchChange, onAssign } = props;
  return (
    <FilterMenu search={search} onSearchChange={onSearchChange}>
      {parts.map((part) => (
        <PartSelectItem
          key={part.id}
          part={part}
          onSelect={onAssign}
          search={search}
        />
      ))}
    </FilterMenu>
  );
}
