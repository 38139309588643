import { AccessEntity } from "@aletiq/types";
import { ReducerAccessState } from "./hooks";

export default function mergeStateAndEntityAccess(
  objectIds: number[],
  objectsAccess: AccessEntity[][],
  state: ReducerAccessState
) {
  return objectIds.reduce(
    (acc: Record<number, AccessEntity[]>, docId, index) => {
      const entityAccess = objectsAccess[index];
      let accessPublic: AccessEntity[] = [];

      if (state.isAllPublic || entityAccess.some((a) => a.type === "open")) {
        accessPublic.push({ type: "open" });
      }

      if (state.isNonePublic) {
        accessPublic = [];
      }

      const groups: AccessEntity[] = [
        ...state.groupsAdded.map(
          (group): AccessEntity => ({
            type: "group",
            group,
          })
        ),
        ...entityAccess,
      ].filter(
        (a) => a.type === "group" && !state.groupsRemoved.includes(a.group)
      );

      const users: AccessEntity[] = [
        ...state.usersAdded.map(
          (user): AccessEntity => ({
            type: "user",
            user,
          })
        ),
        ...entityAccess,
      ].filter(
        (a) => a.type === "user" && !state.usersRemoved.includes(a.user)
      );

      acc[docId] = [...accessPublic, ...groups, ...users];
      return acc;
    },
    {}
  );
}
