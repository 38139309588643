import React from "react";
import { Button, Tooltip } from "../../../../../components";
import { useTranslations } from "../../../../../util";

export default function LinkPartBomButton(props: { onClick: () => void }) {
  const { onClick } = props;

  const tr = useTranslations();

  return (
    <Tooltip content={tr.translateAsString("product.bom.link-part.tooltip")}>
      <Button icon={"cube-add"} intent="primary" onClick={onClick} isDense>
        {tr.translateAsString("product.bom.link-part")}
      </Button>
    </Tooltip>
  );
}
