import { Entity } from "@aletiq/types";
import React from "react";
import ActivityDocumentLink from "./ActivityDocumentLink";
import ActivityPartLink from "./ActivityPartLink";
import ActivityPassportLink from "./ActivityPassportLink";
import { ActivityProductLink } from "./ActivityProductLink";
import ActivityWorkflowLink from "./ActivityWorkflowLink";

export default function ActivityEntityLink(props: {
  entity: Entity;
  onClick?: () => void;
}) {
  const { entity, onClick } = props;

  if (entity.type === "passport") {
    return <ActivityPassportLink passport={entity.entity} onClick={onClick} />;
  }
  if (entity.type === "project") {
    return <ActivityProductLink product={entity.entity} onClick={onClick} />;
  }
  if (entity.type === "file") {
    return <ActivityDocumentLink document={entity.entity} onClick={onClick} />;
  }
  if (entity.type === "process") {
    return <ActivityWorkflowLink workflow={entity.entity} onClick={onClick} />;
  }
  if (entity.type === "part") {
    return <ActivityPartLink part={entity.entity} onClick={onClick} />;
  }

  return null;
}
