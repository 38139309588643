import {
  AlertPopoverBase,
  AlertPopoverContent,
  AlertPopoverHeader,
  ButtonSP,
  Switch,
  Tooltip,
} from "@aletiq/design-system";
import { AletiqPropertyDisplay, Property, PropertyObject } from "@aletiq/types";
import React, { useState } from "react";
import { AlignRight } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import {
  useAletiqPropertiesDefaultDisplay,
  useSetAletiqPropertyDefaultDisplay,
  useUpdateCustomProperty,
} from "../../hooks";
import styles from "./SelectDefaultDisplayedProperties.module.scss";

export default function SelectDefaultDisplayedProperties(props: {
  properties: Property[];
  objectType: PropertyObject;
  openOnStart?: boolean;
}) {
  const { properties, objectType, openOnStart = false } = props;
  const tr = useTranslations();

  const [isOpen, setIsOpen] = useState(openOnStart);

  const { data: aletiqProperties = [] } =
    useAletiqPropertiesDefaultDisplay(objectType);

  const { mutate: updateProperty } = useUpdateCustomProperty(objectType);
  const { mutate: setAletiqPropertyDefaultDisplay } =
    useSetAletiqPropertyDefaultDisplay(objectType);

  const handleToggleDefaultPropertyDisplay = (property: Property) => () => {
    updateProperty({
      propertyName: property.name,
      update: { displayByDefault: !property.displayByDefault },
    });
  };

  const handleToggleAletiqPropertyDisplay =
    (property: AletiqPropertyDisplay) => () => {
      setAletiqPropertyDefaultDisplay({
        ...property,
        displayByDefault: !property.displayByDefault,
      });
    };

  const handleOpenPopover = () => {
    setIsOpen(true);
  };

  const handleClosePopover = () => {
    setIsOpen(false);
  };

  return (
    <AlertPopoverBase
      isDense
      isOpen={isOpen}
      onClose={handleClosePopover}
      content={
        <>
          <AlertPopoverHeader
            icon="th"
            isDense
            title={tr.translate("admin.attribute.edit.default-display.title")}
          />
          <AlertPopoverContent>
            <span className={styles.description}>
              {tr.translate("admin.attribute.edit.default-display.description")}
            </span>

            {/* Default properties */}
            <div className={styles.row}>
              {tr.translate("generic.label.name")}
              <AlignRight />
              <Switch isDense checked isDisabled />
            </div>

            {aletiqProperties.map((property) => (
              <div className={styles.row} key={property.name}>
                {tr.translate(
                  "admin.attribute.edit.default-display.aletiq-property",
                  { name: property.name }
                )}
                <AlignRight />
                <Switch
                  isDense
                  checked={property.displayByDefault}
                  onClick={handleToggleAletiqPropertyDisplay(property)}
                />
              </div>
            ))}

            {/* Custom properties */}
            {properties.map((property) => (
              <div className={styles.row} key={property.id}>
                {property.label}
                <AlignRight />
                <Switch
                  isDense
                  checked={property.displayByDefault}
                  onClick={handleToggleDefaultPropertyDisplay(property)}
                />
              </div>
            ))}
          </AlertPopoverContent>
        </>
      }
    >
      <Tooltip
        content={tr.translate("admin.attribute.edit.default-display.tooltip")}
        position="top"
      >
        <ButtonSP
          isDense
          icon="th"
          view="flat"
          color="primary"
          onClick={handleOpenPopover}
        />
      </Tooltip>
    </AlertPopoverBase>
  );
}
