import React from "react";
import { CellProps } from "react-table";
import { GenericStateTag } from "../../../../components";
import { ResolvedDocument } from "../documentTypes";

export default function DocumentRevisionCell(props: {
  cellProps: CellProps<ResolvedDocument>;
}) {
  const { cellProps } = props;
  const document = cellProps.row.original;

  return (
    <>
      {document.lastRevisionState && (
        <GenericStateTag
          name={cellProps.cell.value}
          state={document.lastRevisionState}
          searchString={cellProps.searchString}
        />
      )}
    </>
  );
}
