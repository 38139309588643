import React, { ReactNode } from "react";
import { Select } from "../../../../components";
import { useTranslations } from "../../../../util";

export default function PropertySelect(props: {
  items: { key: number; label: string; node?: ReactNode }[];
  selectedItem?: number;
  handleFunction: (value: number | undefined) => void;
}) {
  const { items: itemsProps, selectedItem, handleFunction } = props;
  const tr = useTranslations();

  const items = [
    ...itemsProps.map((item) => ({
      text: item.label,
      key: item.key,
      node: item.node,
    })),
    {
      text: tr.translateAsString("generic.label.none"),
      key: -1,
      addDividerOnTop: true,
    },
  ];

  const handleSelect = (key: number) => {
    handleFunction(key !== -1 ? key : undefined);
  };

  const selectedItemName =
    selectedItem !== undefined &&
    itemsProps.find((t) => t.key === selectedItem)?.label;

  return (
    <Select
      isFill
      items={items}
      activeItem={selectedItem}
      onItemSelect={handleSelect}
      intent="outlined"
    >
      {selectedItemName || tr.translateAsString("generic.label.none")}
    </Select>
  );
}
