import React from "react";
import { Button, Select, User } from "../../../../components";
import { useUsers } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import styles from "./ShareDocumentDialog.module.scss";

export default function OwnerRow(props: {
  onSetOwner: (userId: number) => void;
  userId: number;
}) {
  const { onSetOwner, userId } = props;
  const tr = useTranslations();

  const { data: allUsers = [] } = useUsers();

  const allowedEntityOwners = allUsers
    .filter(
      (u) =>
        u.permissions.includes("update:documents") &&
        u.permissions.includes("update:parts")
    )
    .map((u) => ({
      key: u.id,
      text: u.displayName,
    }));

  const handleSetOwner = (userId: number) => {
    onSetOwner(userId);
  };

  return (
    <div className={styles.row}>
      <div className={styles.row__left}>
        <User size="default" userId={userId} />
      </div>
      <div>
        <Select
          filterable
          items={allowedEntityOwners}
          onItemSelect={handleSetOwner}
          popoverProps={{
            position: "bottom-right",
          }}
          isDense
        >
          <Button intent="outlined">
            {tr.translate("document.dialog.share.button.edit")}
          </Button>
        </Select>
      </div>
    </div>
  );
}
