import { UserGroup } from "@aletiq/types";
import { Collapse } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import {
  AlertPopover,
  AlignRight,
  Button,
  ButtonGroup,
  H3,
  HighlightedText,
  Input,
  UserMultiselect,
} from "../../../../components";
import { useUsers } from "../../../../hooks";
import { isInString, isNotUndefined, useTranslations } from "../../../../util";
import { useDeleteUserGroup, useUpdateUserGroup } from "../hooks";
import styles from "./GroupList.module.scss";
import GroupUserRow from "./GroupUserRow";

const GET_EMAILS = true;
const GET_DEACTIVATED = true;

export default function GroupListItem(props: {
  group: UserGroup;
  groupNames: string[];
  isFolded: boolean;
  onToggleFold: () => void;
  search: string;
}) {
  const { group, groupNames, isFolded, onToggleFold, search } = props;
  const tr = useTranslations();

  const [editedName, setEditedName] = useState(group.name);
  const [isRenaming, setIsRenaming] = useState(false);

  const { data: users = [] } = useUsers(GET_EMAILS, GET_DEACTIVATED);

  const filteredGroupMembers = useMemo(
    () =>
      group.users
        .map((userId) => users.find((u) => u.id === userId))
        .filter(isNotUndefined)
        .filter(
          (u) =>
            isInString(u.displayName, search) ||
            isInString(u.email ?? "", search)
        ),
    [group, users, search]
  );

  const isGroupNameInUse =
    editedName !== "" &&
    groupNames.some(
      (name) => name !== "" && name !== group.name && name === editedName
    );

  const showAllUsers = search === "" || isInString(group.name, search);

  const { mutate: updateUserGroup, isLoading: isUpdatingGroup } =
    useUpdateUserGroup();

  const { mutate: deleteUserGroup, isLoading: isDeletingGroup } =
    useDeleteUserGroup();

  const handleSaveNewName = () => {
    updateUserGroup(
      {
        groupId: group.id,
        spec: { users: group.users, name: editedName },
      },
      { onSettled: () => setIsRenaming(false) }
    );
  };

  const handleAddUsers = (newUsers: number[]) => {
    updateUserGroup({
      groupId: group.id,
      spec: { users: [...group.users, ...newUsers], name: editedName },
    });
  };

  const handleClickRenameButton = () => {
    setEditedName(group.name);
    setIsRenaming(true);
  };

  const handleDeleteGroup = () => {
    deleteUserGroup(group.id);
  };

  // if neither group nor user names & emails match search, don't show group
  if (!showAllUsers && filteredGroupMembers.length === 0) {
    return null;
  }

  return (
    <div className={styles.list}>
      <div
        className={classNames(styles.group_header, isFolded && styles.folded)}
      >
        <Button
          isDense
          icon={isFolded ? "chevron-right" : "chevron-down"}
          onClick={onToggleFold}
        />
        {!isRenaming && (
          <H3 className={styles.title}>
            <HighlightedText text={group.name} highlight={search} />
          </H3>
        )}
        {isRenaming && (
          <Input
            autoFocus
            value={isRenaming ? editedName : group.name}
            onChange={setEditedName}
            hasError={isGroupNameInUse}
            errorHelperText={tr.translateAsString(
              "admin.groups.warning.name-used"
            )}
            intent="default"
            isDense
            fill
          />
        )}
        <AlignRight />
        <ButtonGroup isDense>
          {!isRenaming && (
            <Button isDense icon="edit" onClick={handleClickRenameButton} />
          )}
          {isRenaming && (
            <>
              <Button
                isDense
                icon="tick"
                intent="success"
                isDisabled={isGroupNameInUse}
                onClick={handleSaveNewName}
              />
              <Button
                isDense
                icon="cross"
                onClick={() => setIsRenaming(false)}
              />
            </>
          )}
          <AlertPopover
            isDense
            submitting={isDeletingGroup}
            onPrimaryClick={handleDeleteGroup}
            icon="info-sign"
            title={tr.translate("admin.groups.popup.delete.title")}
            content={tr.translate("admin.groups.popup.delete.description")}
          >
            <Button isDense icon="trash" />
          </AlertPopover>
        </ButtonGroup>
      </div>

      <Collapse isOpen={!isFolded} className={styles.collapse}>
        {showAllUsers
          ? group.users.map((userId) => (
              <GroupUserRow
                key={userId}
                userId={userId}
                group={group}
                search={search}
              />
            ))
          : filteredGroupMembers.map((user) => (
              <GroupUserRow
                key={user.id}
                userId={user.id}
                group={group}
                search={search}
              />
            ))}
        <UserMultiselect
          isDense
          intent="default"
          isSubmitting={isUpdatingGroup}
          onSubmit={handleAddUsers}
          usersToIgnore={group.users}
        />
      </Collapse>
    </div>
  );
}
