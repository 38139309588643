import { PassportQueryFilterParams } from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_PRODUCT_TYPE,
  ANALYTICS_VISITED_PASSPORTS,
  useTrackEvent,
} from "../../analytics";
import { PageContents, PageTitle } from "../../components";
import { useColumnConfig, useFilter } from "../../hooks";
import { useTranslations } from "../../util";
import { useProjectType } from "../project/hooks";
import { PassportActionBar } from "./common";
import { PassportFunnel } from "./dialogs";
import { defaultPassportColumnConfig } from "./hooks/usePassportTableColumns";
import PaginatedPassportList from "./PaginatedPassportList";
import styles from "./PassportList.module.scss";

export default function Passports() {
  const tr = useTranslations();

  const [createPassport, setCreatePassport] = useState(false);

  const { config: columnConfig, handleToggleColumn } = useColumnConfig(
    defaultPassportColumnConfig
  );

  const { filter, handleFilter, handleClear } =
    useFilter<PassportQueryFilterParams>({
      search: "",
    });

  const projectType = useProjectType();
  useTrackEvent(ANALYTICS_VISITED_PASSPORTS, {
    [ANALYTICS_PRODUCT_TYPE]: projectType,
  });

  return (
    <PageContents domTitle={tr.translateAsString("page.title.passport")}>
      <div className={styles.page}>
        <PageTitle icon="id-number">
          {tr.translate("passport.page.title", { type: projectType })}
        </PageTitle>
        <PassportActionBar
          filter={filter}
          handleClear={handleClear}
          handleFilter={handleFilter}
          projectType={projectType}
          columnConfig={columnConfig}
          onToggleColumn={handleToggleColumn}
        />
        <PaginatedPassportList
          filter={filter}
          projectType={projectType}
          columnConfig={columnConfig}
        />
      </div>
      {createPassport && (
        <PassportFunnel
          onClose={() => setCreatePassport(false)}
          projectType={projectType}
        />
      )}
    </PageContents>
  );
}
