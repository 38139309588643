import { Icon } from "@blueprintjs/core";
import React, { useState } from "react";
import { Button } from "../../../components";
import { useTranslations } from "../../../util";
import { CreateBill } from "./dialogs";
import styles from "./EmptyBill.module.scss";

export function EmptyBill(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();

  const [isCreating, setCreating] = useState(false);
  return (
    <div className={styles.content}>
      <div className={styles.message}>
        <Icon icon="info-sign" />
        {tr.translate("project.operation-bill.no-bill")}
      </div>
      <Button
        onClick={() => setCreating(true)}
        intent="secondary"
        icon="plus"
        className={styles.button}
      >
        {tr.translate("project.operation-bill.no-bill.action")}
      </Button>
      {isCreating ? (
        <CreateBill projectId={projectId} onClose={() => setCreating(false)} />
      ) : null}
    </div>
  );
}
