import { API } from "@aletiq/api";
import { PartType } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { isPartParent } from "../services";
import { pdmKeys } from "./queries";
import useParts from "./useParts";

export default function useAvailableComponentParts(
  parentPart: number,
  componentType: PartType,
  query: string
) {
  const { data: partsData } = useParts({
    partTypes: [componentType],
    search: query,
  });
  const parts = partsData?.list ?? [];

  const { data: ancestors = [] } = usePartAncestors(parentPart);

  //if the component is a part, it cannot create a cycle
  if (!isPartParent(componentType)) return parts;

  // remove them to avoid cycles
  const availableParts = parts.filter((p) => !ancestors.includes(p.id));

  return availableParts;
}

async function getPartAncestors(
  api: API,
  partId: number,
  visited: number[] = []
): Promise<number[]> {
  if (visited.includes(partId)) {
    return [];
  }
  const partsUsedIn = await api.pdm.getPartUsedIn(partId);
  let ancestors: number[] = [partId];

  for (const part of partsUsedIn) {
    const partAncestors = await getPartAncestors(api, part.part, [
      ...visited,
      ...ancestors,
    ]);
    ancestors = [...ancestors, ...partAncestors];
  }

  return ancestors;
}

function usePartAncestors(partId: number) {
  const api = useApi();

  return useQuery(pdmKeys.ancestors(partId), () =>
    getPartAncestors(api, partId)
  );
}
