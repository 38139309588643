import { DateFormatProps } from "@blueprintjs/datetime";
import { Unit } from "@formatjs/intl-utils/src/diff";

export function formatDate(date: Date, options?: { addSpaces?: boolean }) {
  const ownDate = new Date(date);
  ownDate.setHours(ownDate.getHours() - ownDate.getTimezoneOffset() / 60);
  if (options?.addSpaces) {
    return ownDate
      .toLocaleDateString(navigator.language)
      .split("/")
      .join(" / ");
  }
  return ownDate.toISOString().split("T")[0];
}

export function toDate(dateString: string) {
  const date = new Date(dateString);
  date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
  return date;
}

export const jsDateFormatter: DateFormatProps = {
  // note that the native implementation of Date functions differs between browsers
  formatDate: (date) =>
    `${pad2(date.getDate())}/${pad2(
      date.getMonth() + 1
    )}/${date.getFullYear()}`,
  parseDate: (str) => {
    const [day, month, year] = str.split("/").map((s) => Number.parseInt(s));
    const date = toDate(`${year}-${month}-${day}`);
    if (isNaN(date.getTime())) return null;
    return date;
  },
};

function pad2(value: number) {
  if (value < 10) return `0${value}`;
  return value;
}

export function selectUnit(from: Date): {
  value: number;
  unit: Unit;
} {
  const nowMs = Date.now();
  const fromMs = from.valueOf();
  const diffMs = fromMs - nowMs;
  const diffSecs = diffMs / 1000;
  const diffMins = diffSecs / 60;
  const diffHours = diffMins / 60;
  const diffDays = diffHours / 24;
  const diffMonths = diffDays / 30;
  const diffYears = diffMonths / 12;

  if (Math.abs(diffMins) <= 1) {
    return { value: Math.floor(diffSecs), unit: "second" };
  }

  if (Math.abs(diffHours) <= 1) {
    return { value: Math.floor(diffMins), unit: "minute" };
  }

  if (Math.abs(diffDays) <= 1) {
    return { value: Math.floor(diffHours), unit: "hour" };
  }

  if (Math.abs(diffMonths) <= 1) {
    return { value: Math.floor(diffDays), unit: "day" };
  }

  if (Math.abs(diffYears) <= 1) {
    return { value: Math.floor(diffMonths), unit: "month" };
  }

  return { value: Math.floor(diffYears), unit: "year" };
}
