import { Part, PartType } from "@aletiq/types";
import React, { useState } from "react";
import {
  CreateButton,
  DeleteButton,
  IgnoreButton,
  RestoreButton,
  SelectButton,
  TreeButtonGroup,
} from "../../../../components/tree/";
import { useHasPermission } from "../../../../hooks";
import PartCreationMenu from "../../../pdm/common/PartCreationMenu";
import { useAvailableComponentParts } from "../../../pdm/hooks";
import PartSelectMenu from "./PartSelectMenu";

export default function PartTreeActionBar(props: {
  parentId: number;
  isEditable: boolean;
  isKnown: boolean;
  isIgnored: boolean;
  type: PartType;
  onAssign: (part: Part) => void;
  onCreate: (type: PartType, isStandard: boolean) => void;
  onDelete: () => void;
  onIgnore: () => void;
  onRestore: () => void;
}) {
  const {
    parentId,
    type,
    isEditable,
    isKnown,
    isIgnored,
    onAssign,
    onCreate,
    onDelete,
    onIgnore,
    onRestore,
  } = props;

  const canCreateProjects = useHasPermission("create:projects");

  const [search, setSearch] = useState("");

  const parts = useAvailableComponentParts(parentId, type, search);

  return (
    <>
      {isEditable && (
        <TreeButtonGroup>
          {isKnown && <DeleteButton onClick={onDelete} />}
          {!isKnown && (
            <>
              {isIgnored && <RestoreButton onClick={onRestore} />}
              {!isIgnored && (
                <>
                  <IgnoreButton onClick={onIgnore} />
                  <SelectButton
                    content={
                      <PartSelectMenu
                        parts={parts}
                        search={search}
                        onAssign={onAssign}
                        onSearchChange={setSearch}
                      />
                    }
                  />
                  <CreateButton
                    isDisabled={!canCreateProjects}
                    content={
                      <PartCreationMenu
                        allowStandard
                        allowNormal
                        type={type}
                        onCreatePart={onCreate}
                      />
                    }
                  />
                </>
              )}
            </>
          )}
        </TreeButtonGroup>
      )}
    </>
  );
}
