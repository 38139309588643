import { FormattedDate } from "@aletiq/translation";
import { ProjectVersionAction } from "@aletiq/types";
import classNames from "classnames";
import React, { Fragment, useState } from "react";
import {
  ANALYTICS_VISITED_PRODUCT_VERSIONS,
  useTrackEvent,
} from "../../../analytics";
import {
  ActionBar,
  Button,
  CommitAddIcon,
  PageLink,
  PageTitle,
  SearchInput,
  UserName,
} from "../../../components";
import { useHasPermission, useNavigateTo } from "../../../hooks";
import { filterByString, sortByNumber, useTranslations } from "../../../util";
import { useDocument } from "../../documents/hooks";
import { ProjectPage } from "../common";
import { useProductVersions, useProject } from "../hooks";
import { computeDocumentChanges } from "./projectVersionActions";
import styles from "./ProjectVersions.module.scss";

const orderByDic = {
  activateDocument: 2,
  updateDocument: 3,
  updateDocumentDescription: 4,
  deactivateDocument: 1,
};

function sortByVersionActionType<T>(
  array: T[],
  key: (value: T) => keyof typeof orderByDic
) {
  return sortByNumber(array, (value) => orderByDic[key(value)]);
}

export default function ProjectVersions(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_VERSIONS);

  const handleOpenNewVersionPage = useNavigateTo({
    path: `/project/${projectId}/versions/new`,
  });

  const { data: project } = useProject(projectId);
  const { data: versions = [] } = useProductVersions(projectId);
  const canSeeEditVersion = useHasPermission("read:project-draft-version");

  const [searchValue, setSearchValue] = useState("");

  const versionsWithIndex = versions.map((it, versionIndex) => ({
    ...it,
    versionIndex,
  }));

  const visibleVersions = filterByString(
    versionsWithIndex,
    (version) => version.version,
    searchValue
  );

  const canCreateProjectVersions = useHasPermission("create:project-versions");

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.version")}
    >
      <PageTitle>{project?.name}</PageTitle>
      <ActionBar>
        <SearchInput
          autoFocus
          isDense
          value={searchValue}
          onChange={setSearchValue}
        />
        <Button
          isDense
          isDisabled={!canCreateProjectVersions}
          intent="secondary"
          icon={<CommitAddIcon />}
          onClick={handleOpenNewVersionPage}
        >
          {tr.translate("project.version.page.create")}
        </Button>
      </ActionBar>
      <div className={styles.VersionsContainer}>
        {visibleVersions.map((version) => {
          const previousVersion =
            version.versionIndex < versions.length - 1
              ? versions[version.versionIndex + 1]
              : { documents: [], parts: [] };
          const documentsChange = computeDocumentChanges(
            previousVersion.documents,
            version.documents
          );

          const changes = sortByVersionActionType(
            documentsChange,
            (change) => change.type
          );

          if (version.isDraft && !canSeeEditVersion) return <></>;

          return (
            <div key={version.id} className={styles.VersionContainer}>
              <div>
                <>
                  {version.isDraft ? (
                    <PageLink
                      className={classNames(
                        styles.VersionNameLink,
                        styles.Section
                      )}
                      to={{ path: `/project/${projectId}/versions/new` }}
                    >
                      {tr.translate("project.version.page.draft.version-name", {
                        version: version.version,
                      })}
                    </PageLink>
                  ) : (
                    <PageLink
                      className={classNames(
                        styles.VersionNameLink,
                        styles.Section
                      )}
                      to={{
                        path: `/project/${projectId}/versions/${version.id}`,
                      }}
                    >
                      {tr.translate(
                        "project.version.page.versions-count.created",
                        {
                          version: version.version,
                          date: <FormattedDate value={version.createdAt} />,
                          user: <UserName userId={version.author} />,
                        }
                      )}
                    </PageLink>
                  )}
                </>
                <ul className={styles.version_change_list}>
                  {changes.map((action, index) => (
                    <li key={index}>
                      <VersionAction action={action} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </ProjectPage>
  );
}

export function VersionAction(props: { action: ProjectVersionAction }) {
  const { action } = props;
  let content = null;

  switch (action.type) {
    case "activateDocument":
      content = (
        <ActivateDocument
          documentId={action.value.document}
          revisionId={action.value.revision.revisionId}
        />
      );
      break;
    case "updateDocument":
      content = (
        <UpdateDocument
          documentId={action.value.document}
          revisionId={action.value.revision.revisionId}
        />
      );
      break;

    case "updateDocumentDescription":
      content = (
        <UpdateDocumentDescription documentId={action.value.document} />
      );
      break;

    case "deactivateDocument":
      content = <DeactivateDocument documentId={action.value.document} />;
      break;
  }

  return <div>{content}</div>;
}

function ActivateDocument(props: { documentId: number; revisionId: number }) {
  const { documentId, revisionId } = props;
  const { data: document } = useDocument(documentId);
  const tr = useTranslations();
  const revision = document?.revisions.find((rev) => rev.id === revisionId);

  return (
    <Fragment>
      {tr.translate("project.version.page.action.document.activated", {
        name: document?.name ?? "",
        revision: revision?.revisionName || revisionId,
      })}
    </Fragment>
  );
}

function UpdateDocument(props: { documentId: number; revisionId: number }) {
  const { documentId, revisionId } = props;
  const { data: document } = useDocument(documentId);
  const revision = document?.revisions.find((rev) => rev.id === revisionId);
  const tr = useTranslations();

  return (
    <Fragment>
      {tr.translate("project.version.page.action.document.activated", {
        name: document?.name ?? "",
        revision: revision?.revisionName || revisionId,
      })}
    </Fragment>
  );
}

function UpdateDocumentDescription(props: { documentId: number }) {
  const { documentId } = props;
  const { data: document } = useDocument(documentId);
  const tr = useTranslations();

  return (
    <>
      {tr.translate("project.version.page.action.document.description.update", {
        name: document?.name ?? "",
      })}
    </>
  );
}

function DeactivateDocument(props: { documentId: number }) {
  const { documentId } = props;
  const { data: document } = useDocument(documentId);
  const tr = useTranslations();

  return (
    <Fragment>
      {tr.translate("project.version.page.action.document.deactivated", {
        name: document?.name ?? "",
      })}
    </Fragment>
  );
}
