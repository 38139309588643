import { Diff } from ".";
import { indexBy, mapRecord, mergeRecords, These } from "..";

export function diffBy<
  T extends { [key in K]: V },
  K extends keyof any,
  V extends keyof any
>(
  first: T[],
  second: T[],
  key: K,
  diff: (these: These<T>) => Diff<T>
): Record<K, Diff<T>> {
  const firstByKey = indexBy(first, key);
  const secondByKey = indexBy(second, key);
  return mapRecord(mergeRecords(firstByKey, secondByKey), diff);
}
