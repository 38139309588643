import { ProjectType } from "@aletiq/types";
import React from "react";
import { Menu, MenuItem } from "../../../components";
import { useTranslations } from "../../../util";

export default function ProductCreationMenu(props: {
  onCreateProduct: (type: ProjectType) => void;
}) {
  const { onCreateProduct } = props;
  const tr = useTranslations();

  return (
    <Menu intent="minimal" isDense>
      <MenuItem
        icon="projects"
        text={tr.translateAsString("product.bom.edition.articles.create")}
        onClick={() => onCreateProduct("article")}
        isDense
        intent="primary"
      />

      <MenuItem
        icon="wrench"
        text={tr.translateAsString("product.bom.edition.tools.create")}
        onClick={() => onCreateProduct("tool")}
        isDense
        intent="primary"
      />
    </Menu>
  );
}
