import { PassportUpdate } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useUpdatePassport(passportId: number) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (updates: PassportUpdate[]) =>
      api.passport.updatePassport(passportId, updates),
    {
      onSettled: () => {
        queryClient.invalidateQueries(passportKeys.all);
        queryClient.invalidateQueries("property-used-in");
        queryClient.invalidateQueries("property");
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}
