import { FormattedDate, FormattedRelativeTime } from "@aletiq/translation";
import { Card, NonIdealState } from "@blueprintjs/core";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { Badge } from "../../../components";
import { selectUnit, useTranslations } from "../../../util";
import { useSetNotificationsStatus } from "../hooks";
import styles from "./NotificationCard.module.scss";

export default function NotificationCard(props: {
  notificationId: number;
  isRead: boolean;
  isNew: boolean;
  date: string;
  children?: ReactNode;
}) {
  const { isRead, isNew, date, children, notificationId } = props;
  const { value, unit } = selectUnit(new Date(date));

  const { mutate: setNotificationStatus } = useSetNotificationsStatus();

  const handleChangeNotificationReadStatus = () => {
    setNotificationStatus({
      from: notificationId,
      to: notificationId,
      status: !isRead,
    });
  };

  return (
    <Card
      interactive
      className={classNames(styles.container, {
        [styles.read]: isRead,
        [styles.unread]: !isRead,
      })}
      onClick={handleChangeNotificationReadStatus}
    >
      <div className={styles.header}>
        <div className={styles.date}>
          <FormattedDate value={date} />
          <span> - </span>
          <FormattedRelativeTime value={value} unit={unit} numeric="auto" />
        </div>
        {isNew && (
          <Badge
            isDense
            intent="danger"
            Component="span"
            label=""
            className={styles.new}
          />
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </Card>
  );
}

export function NoNotificationsCard() {
  const tr = useTranslations();

  return (
    <Card className={classNames(styles.container, styles.empty)}>
      <NonIdealState
        icon={<img src="/assets/no-activity.svg" height={62} alt="bell" />}
        description={tr.translateAsString("notification.empty-state")}
      />
    </Card>
  );
}
