import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import React, { ReactElement, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { MixpanelAnalyticsProvider } from "../analytics";
import {
  DownloadProvider,
  ShowImgFileProvider,
  ShowTextFileProvider,
  ShowVideoFileProvider,
  ToasterProvider,
} from "../components";
import { auth0Config } from "../config";
import { Intl } from "./Intl";
import store from "./store";
import { ApiProvider } from "./useApi";

export default function AppProviders(props: { children?: ReactElement }) {
  const { children } = props;
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
    []
  );

  const history = useMemo(() => createBrowserHistory(), []);

  return (
    <Intl lang={navigator.language}>
      <Auth0Provider
        {...auth0Config}
        onRedirectCallback={(appState) =>
          appState.returnTo && history.replace(appState.returnTo)
        }
      >
        <MixpanelAnalyticsProvider>
          <ApiProvider>
            <QueryClientProvider client={queryClient}>
              <Router history={history}>
                <Provider store={store}>
                  <ToasterProvider>
                    <DownloadProvider>
                      <ShowTextFileProvider>
                        <ShowVideoFileProvider>
                          <ShowImgFileProvider>{children}</ShowImgFileProvider>
                        </ShowVideoFileProvider>
                      </ShowTextFileProvider>
                    </DownloadProvider>
                  </ToasterProvider>
                </Provider>
              </Router>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </ApiProvider>
        </MixpanelAnalyticsProvider>
      </Auth0Provider>
    </Intl>
  );
}
