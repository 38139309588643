import { FormattedDate } from "@aletiq/translation";
import { RevisionSignature } from "@aletiq/types";
import React from "react";
import { Tooltip, UserName } from "../../../../components";
import styles from "./Revisions.module.scss";
import SignatureIcon from "./SignatureIcon";

export default function SignaturesTooltip(props: {
  signatures: RevisionSignature[];
}) {
  const { signatures } = props;

  if (signatures.length === 0) {
    return null;
  }

  return (
    <Tooltip
      content={
        <>
          {signatures.map((sig, idx) => (
            <div key={idx}>
              <UserName userId={sig.user}></UserName> -{" "}
              <FormattedDate value={sig.date} />
            </div>
          ))}
        </>
      }
    >
      <span className={styles.signatures}>
        <span>Signatures :</span>
        <span>
          <SignatureIcon inline />
          {signatures.length}
        </span>
      </span>
    </Tooltip>
  );
}
