import {
  ActiveDocument,
  ActiveDocumentRevision,
  Document,
} from "@aletiq/types";
import React, { useCallback, useMemo, useState } from "react";
import useApi from "../../../../../app/useApi";
import { Table } from "../../../../../components";
import { useDownloadQueue, useSort } from "../../../../../hooks";
import { useShowDocumentPreview } from "../../../../documents/hooks";
import {
  FilteredVersionDoc,
  NewVersionDoc,
  UnconfirmedVersionDoc,
} from "../../../hooks";
import AddDocumentsDialog from "../../AddDocumentsDialog";
import { useVersionDocsColumnConfig } from "../../useVersionDocsColumnConfig";
import { useNewVersionDocsColumns } from "./useNewVersionDocsColumns";

export default function NewVersionDocuments(props: {
  projectId: number;
  isAddingDocuments: boolean;
  searchValue?: string;
  documents: FilteredVersionDoc[];
  setIsAddingDocuments: (value: boolean) => void;
  onEdit: (
    node: number,
    revision: ActiveDocumentRevision,
    description: string
  ) => void;
  onDelete: (node: number) => void;
  onAddNodes: (nodes: ActiveDocument[]) => void;
}) {
  const {
    projectId,
    searchValue,
    isAddingDocuments,
    documents,
    setIsAddingDocuments,
    onEdit,
    onDelete,
    onAddNodes,
  } = props;

  const api = useApi();

  const [unconfirmedNodes, setUnconfirmedNodes] = useState<
    UnconfirmedVersionDoc[]
  >([]);
  const { handleDownload } = useDownloadQueue();

  const onDownload = useCallback(
    (documentId: number, revisionId: number) =>
      handleDownload({
        fetchToken: () =>
          api.document.getDocumentDownloadToken(documentId, revisionId),
        forceDownload: true,
      }),
    [handleDownload, api.document]
  );

  const handleAddUnconfirmedNodes = (newNodes: Document[]) => {
    setUnconfirmedNodes((nodes) => [
      ...nodes,
      ...newNodes.map((doc) => ({
        doc,
        id: doc.id,
        name: doc.name,
        description: "",
        comment: "",
        properties: doc.properties,
        createdAt: doc.createdAt,
        updatedAt: doc.updatedAt,
      })),
    ]);
  };

  const handleAddNewNodeToVersion = useCallback(
    (node: ActiveDocument) => {
      onAddNodes([node]);
      setUnconfirmedNodes((nodes) =>
        nodes.filter((n) => n.id !== node.document.id)
      );
    },
    [onAddNodes]
  );

  const handleRemoveDoc = useCallback(
    (documentId: number, state: "confirmed" | "unconfirmed") => {
      if (state === "confirmed") {
        onDelete(documentId);
        return;
      }
      setUnconfirmedNodes((nodes) => nodes.filter((n) => n.id !== documentId));
    },
    [onDelete]
  );

  const handleSetActiveRevision = useCallback(
    (document: NewVersionDoc, newRevision: ActiveDocumentRevision) => {
      if (document.state === "confirmed") {
        onEdit(document.id, newRevision, document.description);
      } else {
        setUnconfirmedNodes((docs) =>
          docs.map((d) =>
            d.id === document.id ? { ...d, activeRevision: newRevision } : d
          )
        );
      }
    },
    [onEdit]
  );

  const handleSetDescription = useCallback(
    (document: NewVersionDoc, newDescription: string) => {
      if (document.state === "confirmed") {
        onEdit(document.id, document.activeRevision, newDescription);
      } else {
        setUnconfirmedNodes((docs) =>
          docs.map((d) =>
            d.id === document.id ? { ...d, description: newDescription } : d
          )
        );
      }
    },
    [onEdit]
  );

  const showPreview = useShowDocumentPreview();

  const { config: defaultConfig } = useVersionDocsColumnConfig();
  const columns = useNewVersionDocsColumns(
    handleAddNewNodeToVersion,
    handleRemoveDoc,
    onDownload,
    showPreview,
    handleSetDescription,
    handleSetActiveRevision,
    defaultConfig
  );

  const allVersionDocs: NewVersionDoc[] = useMemo(
    () => [
      ...unconfirmedNodes.map((doc) => ({
        ...doc,
        state: "unconfirmed" as const,
      })),
      ...documents.map((doc) => ({ ...doc, state: "confirmed" as const })),
    ],
    [documents, unconfirmedNodes]
  );

  const {
    sortKey,
    sortDirection,
    onSortKeyChange,
    sortedList: sortedDocs,
  } = useSort<NewVersionDoc>(allVersionDocs, "name");

  const activeDocumentIds = useMemo(
    () => documents.map((doc) => doc.id),
    [documents]
  );

  return (
    <>
      <Table
        data={sortedDocs}
        columns={columns}
        searchString={searchValue}
        sortOptions={{
          sortState: {
            key: sortKey,
            direction: sortDirection,
          },
          onSort: onSortKeyChange,
        }}
      />
      {isAddingDocuments && (
        <AddDocumentsDialog
          projectId={projectId}
          activeDocuments={activeDocumentIds}
          onSubmit={handleAddUnconfirmedNodes}
          onClose={() => setIsAddingDocuments(false)}
        />
      )}
    </>
  );
}
