import { useDocument } from "../../../features/documents/hooks";
import { useTranslations } from "../../../util/general";
import documentLinkProps from "../props/documentLinkProps";

export default function useDocumentLink(documentId: number) {
  const tr = useTranslations();

  const { data: document } = useDocument(documentId);

  return documentLinkProps(tr, document);
}
