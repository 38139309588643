import {
  PartIterationId,
  Project,
  ProjectDefinitionId,
  ProjectType,
} from "@aletiq/types";
import { TreeNodeInfo } from "@blueprintjs/core";
import React from "react";
import { ProductIcon, Tree } from "../../../../components";
import TreeItemName from "../../../../components/name/TreeItemName";
import { isPartParent } from "../../../pdm/services";
import {
  hasPart,
  PartProductBomMutation,
  ProductBomMutation,
  ProductTree,
} from "./partProjectBomUtils";
import ProductTreeActionBar from "./ProductTreeActionBar";

export default function EditProductTree(props: {
  tree: ProductTree;
  movedProduct?: Project;
  fetchingPathId?: string | undefined;
  isExpanded: (pathId: string) => boolean;
  onExpand: (pathId: string) => void;
  onToggleExpandNode: (node: TreeNodeInfo<{ onExpand?: () => void }>) => void;
  onAssign: (
    pathId: string,
    isLinked: boolean,
    addition?: ProductBomMutation,
    assignment?: PartProductBomMutation
  ) => void;
  onDelete: (pathId: string, deletion: ProductBomMutation) => void;
  onCreate: (
    pathId: string,
    type: ProjectType,
    part: PartIterationId,
    parent: ProjectDefinitionId
  ) => void;
}) {
  const {
    tree,
    movedProduct,
    fetchingPathId,
    isExpanded,
    onExpand,
    onAssign,
    onDelete,
    onCreate,
    onToggleExpandNode,
  } = props;

  const isHighlighted = movedProduct !== undefined;

  function makePartNodeInfos(
    tree: ProductTree,
    parent: ProductTree
  ): TreeNodeInfo<{ onExpand?: () => void }> {
    const { product, part, pathId, components } = tree;
    const isKnown = product !== null;
    const parentId = parent.product!.id;
    const isFetching = fetchingPathId === pathId;

    const handleMove = () => {
      if (movedProduct === undefined) {
        return;
      }
      handleAssign(movedProduct);
    };

    const handleAssign = (assignedProduct: Project) => {
      const existingProduct = parent.components.find(
        (c) => c.product?.id === assignedProduct.id
      );
      const addition = existingProduct
        ? undefined
        : {
            productId: assignedProduct.id,
            definitionIdx: assignedProduct.lastDefinition.index,
            parent: {
              productId: parent.product!.id,
              definitionIdx: parent.product!.definition,
            },
          };
      const assignment = {
        product: {
          productId: assignedProduct.id,
          definitionIdx: assignedProduct.lastDefinition.index,
        },
        part: {
          part: part!.id,
          iteration: part!.iteration,
        },
      };
      const isLinked = part?.projects.includes(assignedProduct.id) ?? false;

      onAssign(pathId, isLinked, addition, assignment);
    };

    const handleDelete = () => {
      const deletion = {
        productId: product!.id,
        definitionIdx: product!.definition,
        parent: {
          productId: parent.product!.id,
          definitionIdx: parent.product!.definition,
        },
      };
      onDelete(pathId, deletion);
    };

    const handleCreate = (type: ProjectType) => {
      const parentDefinition = {
        productId: parent.product!.id,
        definitionIdx: parent.product!.definition,
      };
      const partIteration = {
        part: part!.id,
        iteration: part!.iteration,
      };
      onCreate(pathId, type, partIteration, parentDefinition);
    };

    return {
      hasCaret: isPartParent(part!.type) && isKnown,
      id: pathId,
      label: (
        <TreeItemName
          name={product?.name}
          isHighlighted={isHighlighted}
          isLoading={isFetching}
        />
      ),
      isExpanded: isExpanded(pathId),
      nodeData: {
        onExpand: () => onExpand(pathId),
      },
      icon: !isFetching && (
        <ProductIcon
          inline
          color={!isHighlighted ? "var(--blue100)" : undefined}
          intent={isHighlighted ? "primary" : "none"}
          type={product?.type}
        />
      ),
      childNodes: isKnown
        ? components.filter(hasPart).map((t) => makePartNodeInfos(t, tree))
        : [],
      secondaryLabel: (
        <ProductTreeActionBar
          parentId={parentId}
          tree={tree}
          onAssign={handleAssign}
          onCreate={handleCreate}
          onMove={handleMove}
          onDelete={handleDelete}
          isKnown={isKnown}
          isMoving={isHighlighted}
        />
      ),
    };
  }

  return (
    <div>
      <Tree
        onNodeExpand={onToggleExpandNode}
        onNodeCollapse={onToggleExpandNode}
        contents={[
          {
            isExpanded: true,
            hasCaret: false,
            id: tree.pathId,
            label: (
              <TreeItemName
                name={tree.product?.name}
                isHighlighted={isHighlighted}
              />
            ),
            icon: (
              <ProductIcon
                inline
                color={!isHighlighted ? "var(--blue100)" : undefined}
                intent={isHighlighted ? "primary" : "none"}
                type={tree.product!.type}
              />
            ),
            childNodes: tree.components
              .filter(hasPart)
              .map((t) => makePartNodeInfos(t, tree)),
          },
        ]}
      />
    </div>
  );
}
