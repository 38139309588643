import { OperationBill } from "@aletiq/types";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import {
  ANALYTICS_VISITED_PRODUCT_OPERATION_BILL,
  useTrackEvent,
} from "../../../analytics";
import { PageTitle } from "../../../components";
import { useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { ProjectPage } from "../common";
import { diffOperations } from "./compareBill";
import { EmptyBill } from "./EmptyBill";
import useProjectBills from "./hooks/useProjectBills";
import ProjectOperationBill from "./ProjectOperationBill";
import ProjectOperationBillEditor from "./ProjectOperationBillEditor";

export default function ProjectOperationBillPage(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_OPERATION_BILL);

  const routeParams = useRouteMatch<{ bill: string }>();

  const hasUpdatePermission = useHasPermission("update:article-bills");

  const billId = Number.parseInt(routeParams.params.bill);

  const { data: bills = [] } = useProjectBills(projectId);

  const activeBillIndex: number = billId
    ? bills.findIndex((b) => b.id === billId)
    : 0;

  const activeBill: OperationBill | undefined = bills[activeBillIndex];
  const previousBill =
    bills.find(
      (b, billIndex) =>
        b.branch === activeBill?.branch && billIndex > activeBillIndex
    ) || null;

  const operationsDiff =
    previousBill && activeBill
      ? diffOperations(
          activeBill?.operations || [],
          previousBill?.operations || []
        )
      : {};

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.operation-bill")}
    >
      <PageTitle icon="numbered-list">
        {tr.translateAsString("project.operation-bill.title")}
      </PageTitle>
      {bills.length === 0 ? <EmptyBill projectId={projectId} /> : null}
      {activeBill ? (
        activeBill.state === "draft" && hasUpdatePermission ? (
          <ProjectOperationBillEditor
            projectId={projectId}
            activeBill={activeBill}
            diff={operationsDiff}
            bills={bills}
          />
        ) : (
          <ProjectOperationBill
            projectId={projectId}
            activeBill={activeBill}
            diff={operationsDiff}
            bills={bills}
          />
        )
      ) : null}
    </ProjectPage>
  );
}
