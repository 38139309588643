import { IconName } from "@blueprintjs/core";
import React, { useState } from "react";
import { PropertyCard } from "../../../../../components";
import { useHasPermission } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { usePassportStates } from "../../../../passport/hooks";
import { PropertyFilters } from "../../types";
import { EditPassportStateDialog } from "../Dialogs";
import PassportPropertyTarget from "./PassportPropertyTarget";

export default function PropertyCardState(props: {
  filters?: PropertyFilters;
  usedIn: { count: number; icon: IconName }[];
}) {
  const { filters, usedIn } = props;
  const tr = useTranslations();

  const [showDialog, setShowDialog] = useState<"editState" | undefined>();

  const { data: states = [], isLoading } = usePassportStates();

  const canEditProperty = useHasPermission("update:passport-attributes");

  const label = tr.translateAsString("admin.attribute.passports.state");
  const description = tr.translateAsString("admin.attribute.passports.state");

  const options = states.map((state) => ({
    label: state.value,
    value: state.id,
    deleted: state.deleted,
    uses: state.uses,
  }));

  const handleOpenedDialog = (type?: "editState") => () => {
    setShowDialog(type);
  };

  return (
    <>
      <PropertyCard
        label={label}
        type="enum"
        description={description}
        canEdit={canEditProperty}
        onEdit={handleOpenedDialog("editState")}
        target={<PassportPropertyTarget target="all" />}
        filters={filters}
        usedIn={usedIn}
      />
      {/* Dialogs */}
      {!isLoading && showDialog === "editState" && (
        <EditPassportStateDialog
          label={label}
          description={description}
          options={options}
          onClose={handleOpenedDialog(undefined)}
        />
      )}
    </>
  );
}
