import { PassportSpec, ProjectType } from "@aletiq/types";
import React, { useState } from "react";
import { Button, Dialog, Field, Select } from "../../../../components";
import { useTranslations } from "../../../../util";
import { usePaginatedProjects } from "../../../project/hooks";
import { useCreatePassports } from "../../hooks";
import { PassportCreationForm } from "../PassportCreationDialog";
import styles from "./PassportFunnel.module.scss";

export default function PassportFunnel(props: {
  onClose: () => void;
  projectType?: ProjectType;
}) {
  const { onClose, projectType } = props;
  const tr = useTranslations();
  const [search, setSearch] = useState("");

  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  );
  const [passportSpec, setPassportSpec] = useState<PassportSpec>();

  const { data: projectsData } = usePaginatedProjects({
    projectType,
    search,
    limit: 100,
  });
  const { mutate: createPassports } = useCreatePassports();

  const projects = projectsData?.list ?? [];
  const selectableProducts = projects.map((p) => ({
    key: p.id,
    text: p.name,
  }));
  const selectedProduct = projects.find((p) => p.id === selectedProductId);

  const handleSubmit = async () => {
    if (!passportSpec) {
      return;
    }

    createPassports(passportSpec, { onSettled: onClose });
  };

  const handleCancel = () => {
    setSelectedProductId(null);
    onClose();
  };

  const handleSelectProduct = (productId: number) => {
    setSelectedProductId(productId);
    const product = projects.find((p) => p.id === productId);

    if (!product) {
      return;
    }

    const defaultConfig: Record<number, number[]> = {};
    product.options.forEach((field) => {
      const value = field.values.find((value) => value.isDefault);
      if (value) defaultConfig[field.id] = [value.id];
      else defaultConfig[field.id] = [];
    });

    setPassportSpec({
      name: "",
      description: "",
      parent: productId,
      parentDefinition: product.lastDefinition.index,
      config: defaultConfig,
      projectIteration: null,
      articleBill: null,
    });
  };

  return (
    <Dialog
      icon="plus"
      title={tr.translateAsString("dialog.dialog.funnel.title")}
      isOpen
      onClose={onClose}
      className={styles.dialog}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={handleCancel}
    >
      {selectedProductId && selectedProduct && passportSpec ? (
        <PassportCreationForm
          product={selectedProduct}
          passportSpec={passportSpec}
          onEditSpec={setPassportSpec}
        />
      ) : (
        <Field
          label={tr.translate("dialog.dialog.funnel.product.label", {
            type: projectType,
          })}
        >
          <Select
            items={selectableProducts}
            onItemSelect={handleSelectProduct}
            intent="outlined"
            filterable
            onQueryChange={(q) => setSearch(q)}
            isFill
          >
            <Button isFill intent="outlined" rightIcon="caret-down">
              <span className={styles.button_text}>
                {selectedProduct
                  ? selectedProduct?.name
                  : tr.translate("dialog.dialog.funnel.product.placeholder", {
                      type: projectType,
                    })}
              </span>
            </Button>
          </Select>
        </Field>
      )}
    </Dialog>
  );
}
