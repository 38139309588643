import { Part } from "@aletiq/types";
import classNames from "classnames";
import React, { Fragment } from "react";
import { Icon, IgnoreTag, PartIcon, UserName } from "../../../../../components";
import styles from "./UploadedPartsList.module.scss";

export default function LockedParts(props: { parts: Part[] }) {
  const { parts } = props;

  return (
    <Fragment>
      {parts.map((part) => (
        <tr key={part.id}>
          <td className={styles.tag_col}>
            <div className={styles.locked}>
              <Icon inline icon="lock" />
              <UserName userId={part.lockOwner!} />
            </div>
          </td>
          <td>
            <div className={classNames(styles.part_name, styles.ignored)}>
              <PartIcon type={part.type} inline />
              {part.name}
            </div>
          </td>
          <td className={styles.locked_action_col}>
            <IgnoreTag />
          </td>
          <td colSpan={2} />
        </tr>
      ))}
    </Fragment>
  );
}
