import { Button, Field, Icon, Input, Tooltip } from "@aletiq/design-system";
import { PropertyOption } from "@aletiq/types";
import { IconName } from "@blueprintjs/core";
import React, { useCallback, useMemo } from "react";
import { CellProps } from "react-table";
import { useTranslations } from "../../util";
import { BatchActionButton } from "../BatchActionButton";
import { ExtendedColumn, Table } from "../table";
import { OptionValueCell } from "./EditProperty";
import styles from "./EditProperty.module.scss";
import { PropertyTypePicker } from "./PropertyTypePicker";

export type AletiqPropertyOption = {
  value?: number;
  label: string;
  deleted: boolean;
  uses: number;
};

export function EditAletiqProperty(props: {
  showUseCounts?: boolean;
  icon?: IconName;
  label: string;
  description: string;
  options: AletiqPropertyOption[];
  onEditOptions?: (opt: AletiqPropertyOption[]) => void;
  targetSelector?: JSX.Element;
}) {
  const {
    description,
    label,
    options,
    onEditOptions,
    showUseCounts = true,
    icon,
    targetSelector,
  } = props;
  const tr = useTranslations();

  const emptyFunction = () => {};

  const addOptions = () => {
    onEditOptions &&
      onEditOptions([
        ...options,
        {
          label: "",
          deleted: false,
          uses: 0,
        },
      ]);
  };

  const handleEditValue = useCallback(
    (updateIndex: number) => (newLabel: string) => {
      onEditOptions &&
        onEditOptions(
          options.map((o, index) =>
            index === updateIndex ? { ...o, label: newLabel } : o
          )
        );
    },
    [onEditOptions, options]
  );

  const handleDeleteOptions = (toDelete: AletiqPropertyOption[]) => {
    onEditOptions &&
      onEditOptions(
        options.map((option) => {
          if (
            toDelete.some(
              (o) => o.value === option.value && o.label === option.label
            )
          ) {
            return { ...option, deleted: true };
          }
          return option;
        })
      );
  };

  const columns: ExtendedColumn<AletiqPropertyOption>[] = useMemo(
    () => [
      {
        id: "label",
        accessor: "label",
        fullWidthContent: true,
        Header: tr.translate("admin.attribute.new.values.value"),
        Cell: (cellProps: CellProps<PropertyOption>) => (
          <OptionValueCell {...cellProps} onConfirm={handleEditValue} />
        ),
      },
      {
        id: "uses",
        accessor: "uses",
        Header: (
          <div className={styles.option_uses_header}>
            {icon && <Icon icon={icon} inline color="var(--blue100)" />}#
          </div>
        ),
        width: "100px",
        minWidth: 100,
        Cell: (cellProps: CellProps<AletiqPropertyOption>) =>
          showUseCounts ? <>{cellProps.value}</> : <></>,
      },
    ],
    [tr, icon, handleEditValue, showUseCounts]
  );

  const renderActionsHeader = (
    selectedOptions: AletiqPropertyOption[],
    toggleSelectAll: (value?: boolean | undefined) => void
  ) => (
    <ActionsHeader
      selectedOptions={selectedOptions}
      onDeleteOptions={handleDeleteOptions}
      onToggleSelectAll={toggleSelectAll}
    />
  );

  return (
    <>
      <div className={styles.first_line}>
        <div>
          <Tooltip
            content={tr.translateAsString(
              "passport.dialog-edit.property.option.disable-type"
            )}
            position="top"
            isDisabled
          >
            <PropertyTypePicker
              type="enum"
              isDisabled
              onSelect={emptyFunction}
            />
          </Tooltip>
        </div>
        <Field
          className={styles.right_col}
          label={tr.translateAsString("admin.attribute.new.title")}
          isMandatory
        >
          <Input
            fill
            disabled
            placeholder={tr.translateAsString("admin.attribute.new.title")}
            value={label}
            onChange={emptyFunction}
            removeClearButton
            autoFocus
          />
        </Field>
      </div>
      <Field
        label={tr.translate("admin.attribute.new.description")}
        className={styles.description}
      >
        <Input
          fill
          disabled
          placeholder={tr.translateAsString("admin.attribute.new.description")}
          value={description}
          onChange={emptyFunction}
          removeClearButton
          autoFocus={false}
        />
      </Field>
      {targetSelector}

      {options.length > 0 && (
        <Field label={tr.translate("admin.attribute.new.values")}>
          <Table
            data={options}
            columns={columns}
            className={styles.table}
            renderBatchActionsHeader={renderActionsHeader}
          />
        </Field>
      )}
      <Button
        intent="secondary"
        className={styles.addOptions}
        onClick={addOptions}
        icon="plus"
      >
        {tr.translate("admin.attribute.new.new-option")}
      </Button>
    </>
  );
}

function ActionsHeader(props: {
  selectedOptions: AletiqPropertyOption[];
  onDeleteOptions: (toDelete: AletiqPropertyOption[]) => void;
  onToggleSelectAll: (value?: boolean | undefined) => void;
}) {
  const { selectedOptions, onDeleteOptions, onToggleSelectAll } = props;
  const tr = useTranslations();

  const canDeleteOptions = !selectedOptions.some((o) => o.uses > 0);

  const handleDeleteOptions = () => {
    canDeleteOptions && onDeleteOptions(selectedOptions);
    onToggleSelectAll(false);
  };

  return (
    <>
      <div className={styles.subtitle}>
        {tr.translate("passport.page.selected", {
          count: selectedOptions.length,
        })}
      </div>
      <Tooltip
        content={tr.translate("admin.attribute.new.values.delete.tooltip")}
        isDisabled={canDeleteOptions}
        position="top"
      >
        <BatchActionButton
          title={tr.translateAsString("admin.attribute.new.values.delete")}
          icon="trash"
          onClick={handleDeleteOptions}
          isDisabled={!canDeleteOptions}
          className={styles.delete_button}
        />
      </Tooltip>
    </>
  );
}
