import React from "react";
import { Table } from "../../../../components";
import { useNavigate, useSort } from "../../../../hooks";
import { useAccessSearchResult } from "../../../search/hooks";
import {
  useDefaultDocumentColumnConfig,
  useDocumentTableColumns,
} from "../../hooks";
import { ResolvedDocument } from "../documentTypes";

export default function DocumentList(props: {
  nodes: ResolvedDocument[];
  searchString: string;
  onShowFilePreview: (documentId: number) => void;
}) {
  const { searchString, nodes, onShowFilePreview } = props;

  const {
    sortKey,
    sortDirection,
    onSortKeyChange,
    sortedList: sortedDocuments,
  } = useSort<ResolvedDocument>(nodes, "updatedAt", "desc");

  const navigate = useNavigate();

  const { mutate: accessSearchResult } = useAccessSearchResult();

  const handleOpenDocumentPanel = (document: ResolvedDocument) => {
    navigate({
      panel: `documentId-${document.id}`,
    });
    accessSearchResult({ type: "file", id: document.id });
  };

  const { config } = useDefaultDocumentColumnConfig({ showRevision: true });
  const columns = useDocumentTableColumns(config, onShowFilePreview);

  return (
    <Table
      columns={columns}
      data={sortedDocuments}
      searchString={searchString}
      openRowOptions={{ onOpen: handleOpenDocumentPanel }}
      sortOptions={{
        sortState: {
          key: sortKey,
          direction: sortDirection,
        },
        onSort: onSortKeyChange,
      }}
    />
  );
}
