import React from "react";
import { useTranslations } from "../../../../util";
import TaskTable from "../TaskTable";
import styles from "./WorkflowTaskTable.module.scss";

export default function WorkflowTaskTable(props: {
  children: React.ReactNode;
  noActions?: boolean;
  isBeingEdited?: boolean;
  color?: string;
}) {
  const { children, noActions, color, isBeingEdited } = props;
  const tr = useTranslations();

  const handleStopPropagation = (event: React.MouseEvent) =>
    event.stopPropagation();

  return (
    <TaskTable
      color={color}
      isBeingEdited={isBeingEdited}
      onClick={handleStopPropagation}
    >
      <thead>
        <tr>
          <th className={styles.cell}>{tr.translate("tasklist.title")}</th>
          <th className={styles.cell}>
            <div className={styles.owner_cell_content}>
              {tr.translate("tasklist.assignee")}
            </div>
          </th>
          <th className={styles.date_cell}>
            {tr.translate("tasklist.duedate")}
          </th>
          <th className={styles.dep_cell}>
            {tr.translate("taskdetails.dependencies")}
          </th>
          <th className={styles.status_cell}>
            {tr.translate("tasklist.status")}
          </th>
          {!noActions && <th className={styles.action_cell} />}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </TaskTable>
  );
}
