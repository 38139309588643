import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { passportKeys } from "./queries";

export default function useSetPassportFileType(
  passportId: number,
  fileId: number
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    (fileTypeId: number | null) =>
      api.passport.setPassportFileType(passportId, fileId, fileTypeId ?? null),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(passportKeys.allFiles(passportId)),
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.passport.file-type.set"),
        });
      },
    }
  );
}
