import { Spinner } from "@blueprintjs/core";
import React from "react";
import {
  Field,
  GenericStateTag,
  Icon,
  Input,
  Radio,
  TextArea,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { CreationStage, NewPartInfo } from "./PartCreationDialog";
import styles from "./PartCreationDialog.module.scss";
import { CreationStatus } from "./useCreatePart";

export default function PartRevisionInfoForm(props: {
  partInfo: NewPartInfo;
  onEditName: (name: string) => void;
  onEditDescription: (desc: string) => void;
  onToggleApproveRevision: () => void;
  stage: CreationStage;
  status: CreationStatus;
  error?: Error;
}) {
  const {
    partInfo,
    onEditDescription,
    onEditName,
    onToggleApproveRevision,
    stage,
    status,
    error,
  } = props;
  const tr = useTranslations();
  return (
    <>
      <Field label={tr.translate("part.dialog.new.revision.name.label")}>
        {stage === "filling-form" ? (
          <Input
            fill
            placeholder={tr.translateAsString(
              "part.dialog.new.revision.name.placeholder"
            )}
            value={partInfo.revision.name}
            onChange={onEditName}
            autoFocus={false}
          />
        ) : partInfo.revision.name ? (
          <div className={styles.upload_status}>
            {status === "approving" &&
              error &&
              (error.message === "incomplete_bom"
                ? tr.translateAsString("part.dialog.new.revision.error")
                : error.message)}
            {status === "approving" && !error ? (
              <Spinner size={16} />
            ) : status === "approving" && error ? (
              <Icon icon="warning-sign" intent="warning" />
            ) : (
              <Icon icon="tick" intent="success" />
            )}
          </div>
        ) : (
          tr.translateAsString("generic.label.none")
        )}
      </Field>

      <Field
        label={tr.translateAsString("part.dialog.new.revision.description")}
      >
        {stage === "filling-form" ? (
          <TextArea
            fill
            rows={3}
            value={partInfo.description}
            placeholder={tr.translateAsString(
              "part.dialog.new.revision.description"
            )}
            onChange={onEditDescription}
          />
        ) : partInfo.revision.description !== "" ? (
          partInfo.revision?.description
        ) : (
          tr.translateAsString("generic.label.none.f")
        )}
      </Field>

      <Field label={tr.translateAsString("part.dialog.new.revision.status")}>
        <>
          <Radio
            inline
            labelElement={<GenericStateTag state="draft" />}
            checked={!partInfo.approveRevision}
            onClick={onToggleApproveRevision}
            isDisabled={stage === "submitting"}
            className={styles.radio}
            minimal
          />
          <Radio
            inline
            labelElement={<GenericStateTag state="approved" />}
            checked={partInfo.approveRevision}
            onClick={onToggleApproveRevision}
            isDisabled={stage === "submitting"}
            className={styles.radio}
            minimal
          />
        </>
      </Field>
    </>
  );
}
