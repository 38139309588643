import { ProjectType } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useUpdateProjectType(projectId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    (projectType: ProjectType) =>
      api.project.setProjectType(projectId, projectType),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.byId(projectId));
      },
    }
  );
}
