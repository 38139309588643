import { Button, Card, CheckBox, HighlightedText } from "@aletiq/design-system";
import { IntegrationReport } from "@aletiq/types";
import { Collapse } from "@blueprintjs/core";
import React from "react";
import { Subtitle } from "../../../components";
import { useTranslations } from "../../../util";
import styles from "./IntegrationsReport.module.scss";

export default function IntegrationReportCard(props: {
  report: IntegrationReport;
  search: string;
  isOpen: boolean;
  onSetToggledReports: (index: number) => void;
  onSetIntegrationAsTreated: (index: number, isTreated: boolean) => void;
}) {
  const {
    search,
    report,
    onSetToggledReports,
    onSetIntegrationAsTreated,
    isOpen,
  } = props;
  const tr = useTranslations();

  const reportDate = new Date(report.date);
  const reportDateWithSpaces = reportDate
    .toLocaleDateString(navigator.language)
    .split("/")
    .join(" / ");

  const title = tr.translateAsString("admin.integration.report.name", {
    name: report.name,
    date: reportDateWithSpaces,
    time: reportDate,
  });

  const handleTreatIntegration = () => {
    onSetIntegrationAsTreated(report.id, !report.isTreated);
  };

  const handleToggleOpen = () => {
    onSetToggledReports(report.id);
  };

  const errors = report.content.split("\n").filter((e) => e !== "");

  return (
    <Card
      className={styles.report_card}
      title={<HighlightedText text={title} highlight={search} />}
      headerActions={
        <div className={styles.actions}>
          {tr.translate("admin.integration.report.treated")}
          <CheckBox
            isDense
            intent="default"
            checked={report.isTreated}
            onClick={handleTreatIntegration}
            className={styles.checkbox}
          />
        </div>
      }
    >
      <Subtitle
        text={tr.translateAsString("admin.integration.report.fileLinked", {
          file: report.fileName,
        })}
      />

      {errors.length > 0 && (
        <div className={styles.first_error}>
          {"1. "}
          <HighlightedText text={errors[0]} highlight={search} />
        </div>
      )}

      <Collapse isOpen={isOpen}>
        {errors.map((error, index) => {
          if (index > 1) {
            return (
              <div key={index}>
                {`${index + 1}. `}
                <HighlightedText text={error} highlight={search} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </Collapse>

      {errors.length > 1 && (
        <Button
          isDense
          onClick={handleToggleOpen}
          className={styles.toggle}
          icon={isOpen ? "chevron-up" : "chevron-down"}
        >
          {tr.translate(
            isOpen ? "generic.action.show-less" : "generic.action.show-more"
          )}
        </Button>
      )}
    </Card>
  );
}
