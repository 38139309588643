import React from "react";
import {
  CreateTag,
  IgnoreTag,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  UpdateTag,
  ValidateTag,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { FileAction } from "./state";
import styles from "./DocumentUploadWizard.module.scss";

export default function DocumentActionSelect(props: {
  children: React.ReactNode;
  disabled?: boolean;
  isApproved?: boolean;
  isBatchSelector?: boolean;
  isNew?: boolean;
  onChange: (action: FileAction, state?: "draft" | "approved") => void;
  selectedAction?: FileAction;
}) {
  const {
    children,
    disabled,
    isApproved,
    isBatchSelector,
    isNew,
    onChange,
    selectedAction,
  } = props;
  const tr = useTranslations();

  return (
    <Popover2
      fill
      intent="primary"
      disabled={disabled}
      position="bottom-right"
      content={
        <Menu isDense>
          {isBatchSelector && (
            <MenuDivider
              isDense
              className={styles.first_menu_section}
              title={
                <span className={styles.menu_section}>
                  {tr.translate(
                    "storage.dialog.upload-file.action.batch.existing"
                  )}
                </span>
              }
            />
          )}

          {(!isNew || isBatchSelector) && (
            <>
              <MenuItem
                isDense
                intent="primary"
                disabled={disabled || isNew}
                onClick={() => onChange("update", "draft")}
                active={selectedAction === "update" && !isApproved}
                text={<UpdateTag />}
              />
              <MenuItem
                isDense
                intent="primary"
                disabled={disabled || isNew}
                onClick={() => onChange("update", "approved")}
                active={selectedAction === "update" && isApproved}
                text={
                  <>
                    <UpdateTag />
                    {" & "}
                    <ValidateTag />
                  </>
                }
              />
            </>
          )}

          {isBatchSelector && (
            <MenuDivider
              isDense
              title={
                <span className={styles.menu_section}>
                  {tr.translate("storage.dialog.upload-file.action.batch.new")}
                </span>
              }
            />
          )}

          {(isNew || isBatchSelector) && (
            <>
              <MenuItem
                isDense
                intent="primary"
                disabled={disabled}
                onClick={() => onChange("create", "draft")}
                active={selectedAction === "create" && !isApproved}
                text={<CreateTag />}
              />
              <MenuItem
                isDense
                intent="primary"
                disabled={disabled}
                onClick={() => onChange("create", "approved")}
                active={selectedAction === "create" && isApproved}
                text={
                  <>
                    <CreateTag />
                    {" & "}
                    <ValidateTag />
                  </>
                }
              />
            </>
          )}

          {isBatchSelector && (
            <MenuDivider
              isDense
              title={
                <span className={styles.menu_section}>
                  {tr.translate("storage.dialog.upload-file.action.batch.all")}
                </span>
              }
            />
          )}
          <MenuItem
            isDense
            intent="primary"
            disabled={disabled}
            onClick={() => onChange("ignore")}
            active={selectedAction === "ignore"}
            text={<IgnoreTag />}
          />
        </Menu>
      }
    >
      {children}
    </Popover2>
  );
}
