import { ProjectDefinition } from "@aletiq/types";
import React, { useState } from "react";
import { Dialog, DialogHint, Field, Input } from "../../../components";
import { useTranslations } from "../../../util";
import { useCreateProjectDefinition } from "../hooks";

export default function CreateProjectDefinitionDialog(props: {
  projectId: number;
  onClose: () => void;
  onSuccess?: () => void;
  definitions: ProjectDefinition[];
}) {
  const { projectId, onClose, onSuccess, definitions } = props;
  const tr = useTranslations();

  const [name, setName] = useState("");
  const { isLoading, mutate } = useCreateProjectDefinition(projectId);
  const handleCreate = () => {
    mutate({ name }, { onSuccess });
  };
  const hasEmptyName = name === "";
  const hasExistingName = definitions.some((d) => d.name === name);
  const hasInvalidName = hasEmptyName || hasExistingName;

  return (
    <Dialog
      isOpen
      onClose={onClose}
      title={tr.translateAsString("project.definition.new-draft.title")}
      icon="plus"
      submitting={isLoading}
      disablePrimary={hasInvalidName}
      onPrimaryClick={handleCreate}
    >
      <DialogHint>
        {tr.translate("project.definition.new-draft.caption")}
      </DialogHint>
      <Field
        isMandatory
        label={tr.translate("project.definition.name.field-label")}
      >
        <Input
          fill
          removeClearButton
          placeholder={tr.translateAsString(
            "generic.label.example.placeholder",
            { example: definitions[0].name }
          )}
          onChange={setName}
          value={name}
          hasError={hasExistingName}
          errorHelperText={tr.translateAsString(
            hasExistingName
              ? "project.definition.new-draft.duplicate"
              : "generic.label.invalid-field"
          )}
          intent="primary"
        />
      </Field>
    </Dialog>
  );
}
