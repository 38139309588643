import useApi from "../../../../app/useApi";
import { useDownloadQueue } from "../../../../hooks";

export default function useDownloadRevision() {
  const { handleDownload } = useDownloadQueue();
  const api = useApi();

  return (documentId: number, revisionId: number) => {
    handleDownload({
      fetchToken: () =>
        api.document.getDocumentDownloadToken(documentId, revisionId),
      forceDownload: true,
    });
  };
}
