import React from "react";
import { Menu, MenuDivider, MenuItem } from "../../../components";
import { useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { LockState } from "../PartDetails/PartIterations";

export function StandardPartMenu(props: {
  onDownloadArchive: () => void;
  lockState: LockState;
  onStartRenaming: () => void;
  onDelete: () => void;
  onBomEdition: () => void;
  hasComponents?: boolean;
}) {
  const {
    onDownloadArchive,
    lockState,
    onStartRenaming,
    onDelete,
    onBomEdition,
    hasComponents = false,
  } = props;

  const tr = useTranslations();

  const canUpdateParts = useHasPermission("update:parts");
  const canDeleteParts = useHasPermission("delete:parts");

  const isLockedByOtherUser = lockState === "other_user";

  return (
    <Menu intent="minimal" isDense>
      <MenuItem
        icon="annotation"
        disabled={!canUpdateParts}
        text={tr.translateAsString("generic.action.rename")}
        onClick={onStartRenaming}
        isDense
      />
      <MenuItem
        icon="shapes"
        disabled={!hasComponents || !canUpdateParts || isLockedByOtherUser}
        text={tr.translateAsString("part.details.modify.bom")}
        onClick={onBomEdition}
        isDense
      />
      <MenuDivider />
      <MenuItem
        icon="download"
        text={tr.translateAsString("part.action.download")}
        onClick={onDownloadArchive}
        isDense
      />
      <MenuItem
        disabled={isLockedByOtherUser || !canDeleteParts}
        icon="trash"
        text={tr.translateAsString("generic.action.delete")}
        onClick={onDelete}
        isDense
      />
    </Menu>
  );
}
