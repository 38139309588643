import { History, LocationDescriptorObject } from "history";

export type PageTarget = {
  path?: string | null;
  search?: string | null;
  panel?: string | null;
  panelTab?: string | null;
  pageTab?: string | null;
  hash?: string | null;
};

type PageLocation = {
  path: string | null;
  search: string | null;
  panels: string[];
  panelTab: string | null;
  pageTab: string | null;
  hash: string | null;
};

export function pageLocationEquals(l1: PageLocation, l2: PageLocation) {
  if (l1.path !== l2.path) return false;
  if (l1.search !== l2.search) return false;
  if (l1.hash !== l2.hash) return false;
  if (l1.panelTab !== l2.panelTab) return false;
  if (l1.pageTab !== l2.pageTab) return false;
  if (l1.panels.length !== l2.panels.length) return false;

  for (let i = 0; i < l1.panels.length; i++) {
    if (l1.panels[i] !== l2.panels[i]) return false;
  }

  return true;
}

export function assignPageLocation(location: PageLocation, target: PageTarget) {
  let panels = location.panels;
  if (target.panel === null) {
    panels = [];
  } else if (
    target.panel !== undefined &&
    panels[panels.length - 1] !== target.panel
  ) {
    panels = [...panels, target.panel];
  }

  return {
    path: target.path === undefined ? location.path : target.path,
    search: target.search === undefined ? location.search : target.search,
    panels: panels,
    panelTab:
      target.panelTab === undefined ? location.panelTab : target.panelTab,
    pageTab: target.pageTab === undefined ? location.pageTab : target.pageTab,
    hash: target.hash === undefined ? location.hash : target.hash,
  };
}

export function parsePageLocation(
  location: LocationDescriptorObject
): PageLocation {
  const params = new URLSearchParams(location.search);
  const panel = params.get("panel");
  const panelTab = params.get("panelTab");
  const pageTab = params.get("pageTab");

  params.delete("panel");
  params.delete("panelTab");
  params.delete("pageTab");
  const panels = [...(location.state?.panelStack ?? [])];
  if (panel) panels.push(panel);

  const stringParams = params.toString();

  const pageLocation = {
    path: location.pathname ?? null,
    panels,
    panelTab,
    pageTab,
    search: stringParams === "" ? null : stringParams,
    hash: location.hash ?? null,
  };

  return pageLocation;
}

export function encodePageLocation(
  location: PageLocation
): LocationDescriptorObject {
  const params = location.search
    ? new URLSearchParams(location.search)
    : new URLSearchParams();

  const panels = location.panels.slice(0, -1);
  const panelTab = location.panelTab;
  const panel = location.panels[location.panels.length - 1];
  const pageTab = location.pageTab;

  if (panel) params.set("panel", panel);
  if (panelTab) params.set("panelTab", panelTab);
  if (pageTab) params.set("pageTab", pageTab);

  return {
    pathname: location.path ?? undefined,
    search: params.toString(),
    hash: location.hash ?? undefined,
    state: { panelStack: panels },
  };
}

export function navigateFrom(history: History, path: PageTarget) {
  const pageLocation = parsePageLocation(history.location);

  const nextLocation = assignPageLocation(pageLocation, path);

  if (pageLocationEquals(pageLocation, nextLocation)) return;

  history.push(encodePageLocation(nextLocation));
}
