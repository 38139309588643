import { useCallback, useMemo, useState } from "react";
import { toggleKey } from "../../util";

export type ColumnOptionConfig<T extends string | number> = Record<T, boolean>;

export function useColumnConfig<T extends string | number>(
  defaultConfig?: ColumnOptionConfig<T>
): {
  config: ColumnOptionConfig<T>;
  handleToggleColumn: (column: T) => void;
} {
  const [userConfig, setUserConfig] = useState({} as ColumnOptionConfig<T>);
  const handleToggleColumn = useCallback((column: T) => {
    setUserConfig((config) => toggleKey(config, column));
  }, []);

  const config = useMemo(
    () => ({ ...defaultConfig, ...userConfig }),
    [defaultConfig, userConfig]
  );

  return { config, handleToggleColumn };
}
