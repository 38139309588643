import { EntityId, Part } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../../../util";
import { LinkEntitiesToProcessesDialog } from "../../../../tasks/dialogs";
import { ProcessCreationDialog } from "../../../../tasks/dialogs/ProcessCreationDialog";
import {
  CreateAndLinkProjectsDialog,
  DeletePartsDialog,
  LinkProjectsDialog,
  LockPartsDialog,
  SharePartsDialog,
  UnlinkProjectDialog,
  UnlockPartsDialog,
} from "../../../dialogs";
import { DialogType } from "./AllPartHeaderBatchActions";

export default function AllPartsHeaderBatchActionDialogs(props: {
  parts: Part[];
  projectId?: number;
  openedDialog: DialogType;
  handleCloseDialog: () => void;
  handleCloseDialogResetSelection: () => void;
}) {
  const {
    openedDialog,
    parts,
    handleCloseDialog,
    handleCloseDialogResetSelection,
    projectId,
  } = props;
  const tr = useTranslations();

  const partsEntities: EntityId[] = parts.map(({ id }) => ({
    type: "part",
    id,
  }));

  return (
    <>
      {openedDialog === "link-project" && (
        <LinkProjectsDialog
          parts={parts}
          onClose={handleCloseDialog}
          currentProjects={[]}
        />
      )}
      {openedDialog === "lock-parts" && (
        <LockPartsDialog parts={parts} onClose={handleCloseDialog} />
      )}
      {openedDialog === "unlock-parts" && (
        <UnlockPartsDialog parts={parts} onClose={handleCloseDialog} />
      )}
      {openedDialog === "create-workflow" && (
        <ProcessCreationDialog
          initialEntities={partsEntities}
          onClose={handleCloseDialog}
        />
      )}
      {openedDialog === "link-workflow" && (
        <LinkEntitiesToProcessesDialog
          entities={partsEntities}
          onClose={handleCloseDialog}
          title={tr.translateAsString(
            "part.dialog.link.parts-processes.title",
            {
              nbr: partsEntities.length,
            }
          )}
          subtitle={tr.translate("part.dialog.link.parts-processes.subtitle")}
        />
      )}
      {openedDialog === "delete" && (
        <DeletePartsDialog
          parts={parts}
          onClose={handleCloseDialogResetSelection}
        />
      )}
      {openedDialog === "share-parts" && (
        <SharePartsDialog
          partIds={parts.map((p) => p.id)}
          onClose={handleCloseDialog}
        />
      )}
      {openedDialog === "unlink-project" && projectId && (
        <UnlinkProjectDialog
          parts={parts}
          projectId={projectId}
          onClose={handleCloseDialogResetSelection}
        />
      )}
      {(openedDialog === "create-articles" ||
        openedDialog === "create-tools") && (
        <CreateAndLinkProjectsDialog
          parts={parts}
          projectType={openedDialog === "create-tools" ? "tool" : "article"}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}
