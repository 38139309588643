import { API } from "@aletiq/api";
import { PartComponent } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { ComponentTree } from "../../AllParts/AllPartsType";
import { pdmKeys } from "../../hooks/queries";
import { isPartParent } from "../../services";

async function fetchSubTree(
  api: API,
  component: PartComponent
): Promise<ComponentTree> {
  const { part, iteration } = component;
  if (part && isPartParent(part.type) && iteration) {
    const componentsParts = await api.pdm.listAssemblyIterationComponents(
      part.id,
      iteration.number
    );

    const components = await Promise.all(
      componentsParts
        .filter((c) => c.part && c.iteration && !c.isIgnored)
        .map((component) => fetchSubTree(api, component))
    );
    return { ...component, subRows: components };
  }
  return component;
}

export default function useAssemblyTree(partId: number, iterationId?: number) {
  const api = useApi();
  return useQuery(pdmKeys.iterationTree(partId, iterationId), async () => {
    const part = await api.pdm.getPart(partId);
    const partIterations = await api.pdm.listPartIterations(partId);
    const iteration = iterationId
      ? partIterations.find((i) => i.number === iterationId)
      : part.lastIteration;

    const componentsParts = iterationId
      ? await api.pdm.listAssemblyIterationComponents(part.id, iterationId)
      : await api.pdm.listAssemblyComponents(part.id);
    const subRows = await Promise.all(
      componentsParts
        .filter((c) => !c.isIgnored)
        .map((c) => fetchSubTree(api, c))
    );
    return {
      name: part.name,
      part,
      iteration: iteration ?? null,
      isIgnored: false,
      subRows,
    };
  });
}
