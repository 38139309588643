import { Classes, Spinner, SpinnerSize, Tab, TabId } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import {
  ANALYTICS_DOCUMENTS_PREVIEW_DETAILS,
  ANALYTICS_DOCUMENTS_PREVIEW_SOURCE,
  ANALYTICS_DOCUMENTS_SHOWN,
  ANALYTICS_OPENED_DOCUMENT_DETAILS,
  useAnalytics,
  useTrackEvent,
} from "../../../analytics";
import {
  Activities,
  Divider,
  PanelDetails,
  PanelRenamableTitle,
  Tabs,
} from "../../../components";
import { useHasPermission, useNavigate } from "../../../hooks";
import { DEFAULT_DOC_TAB, isValidTabId, useTranslations } from "../../../util";
import {
  useDocument,
  useShowDocumentPreview,
  useUpdateDocumentName,
} from "../hooks";
import { DialogState, showUploadDocRevision } from "./DialogStateHandling";
import DocumentCitations from "./DocumentCitations";
import styles from "./DocumentDetails.module.scss";
import DocumentDetailsDialogs from "./DocumentDetailsDialogs";
import DocumentDetailsMenu from "./DocumentDetailsMenu";
import DocumentProperties from "./DocumentProperties";
import LinkedProducts from "./LinkedProducts";
import Processes from "./Processes";
import Revisions from "./Revisions";

export default function DocumentDetails(props: {
  documentId: number;
  onClose: () => void;
  projectId?: number;
  tab?: string;
}) {
  const { onClose, documentId, projectId, tab = DEFAULT_DOC_TAB } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  useTrackEvent(ANALYTICS_OPENED_DOCUMENT_DETAILS);

  const { data: document, isLoading } = useDocument(documentId);

  const canUpdateDocuments = useHasPermission("update:documents");
  const canLinkWorkflows = useHasPermission("link:workflows");

  const [isRenaming, setRenaming] = useState(false);
  const [dialog, setDialog] = useState<DialogState>({ type: "none" });

  const showPreview = useShowDocumentPreview();

  const handleShowFilePreview = useCallback(
    (revisionId: number) => {
      showPreview({ documentId, revisionId });
      analytics.track(ANALYTICS_DOCUMENTS_SHOWN, {
        [ANALYTICS_DOCUMENTS_PREVIEW_SOURCE]:
          ANALYTICS_DOCUMENTS_PREVIEW_DETAILS,
      });
    },
    [documentId, showPreview, analytics]
  );

  const handleCloseDialog = () => {
    if (dialog.type === "delete-document") onClose();
    setDialog({ type: "none" });
  };

  const { mutate: updateDocName } = useUpdateDocumentName(documentId);

  const handleEditName = () => {
    setRenaming(true);
  };

  const handleNewName = (value: string) => {
    updateDocName(value, { onSettled: () => setRenaming(false) });
  };

  const handleSetTab = (tabId: TabId) => {
    navigate({ panelTab: tabId.toString() });
  };

  return (
    <PanelDetails
      id={documentId}
      title={
        document ? (
          <PanelRenamableTitle
            name={document.name}
            icon="document"
            isRenaming={isRenaming}
            resetIsRenaming={() => {
              setRenaming(false);
            }}
            onSubmit={handleNewName}
          />
        ) : undefined
      }
      additionalButtons={
        <DocumentDetailsMenu
          documentId={documentId}
          projectId={projectId}
          toggleEditName={handleEditName}
          setDialog={setDialog}
        />
      }
      navigateTooltip={tr.translateAsString(
        "document.details.action.open.doc-space"
      )}
      canNavigateToList={false}
      navigateToList={() => {}}
    >
      <div className={classNames(Classes.DRAWER_BODY, styles.drawer)}>
        {document && <DocumentProperties document={document} />}
        <div>
          <Divider className={styles.divider} />
        </div>
        <div>
          {isLoading && (
            <div>
              <Spinner size={SpinnerSize.LARGE}></Spinner>
            </div>
          )}

          {document && !document.isDeleted && (
            <Tabs
              selectedTabId={
                isValidTabId(tab, "document") ? tab : DEFAULT_DOC_TAB
              }
              onChange={handleSetTab}
              className={styles.tabs}
              renderActiveTabPanelOnly
              center
              isDense
            >
              <Tab
                id="activities"
                title={tr.translate("document.details.tabs.activities")}
                panel={<Activities entity={{ type: "file", id: documentId }} />}
              />
              <Tab
                id="revisions"
                title={tr.translate("document.details.tabs.versions")}
                panel={
                  <Revisions
                    documentId={documentId}
                    onShowFile={handleShowFilePreview}
                    onUpload={() => setDialog(showUploadDocRevision())}
                    disableEdit={!canUpdateDocuments}
                  />
                }
              />
              <Tab
                id="workflows"
                title={tr.translate("document.details.tabs.processes")}
                panel={
                  <Processes
                    documentId={documentId}
                    disableEdit={!canLinkWorkflows}
                  />
                }
              />
              <Tab
                id="products"
                title={tr.translate("document.details.tabs.linked-projects")}
                panel={
                  <LinkedProducts
                    documentId={documentId}
                    canEdit={canUpdateDocuments}
                  />
                }
              />
              <Tab
                id="citations"
                title={tr.translate("document.details.tabs.citations")}
                panel={<DocumentCitations document={document} />}
              />
            </Tabs>
          )}

          {document && document.isDeleted && (
            <div className={styles.deleted}>
              {tr.translate("document.details.deleted")}
            </div>
          )}
        </div>
        {document && !document.isDeleted && (
          <DocumentDetailsDialogs
            dialog={dialog}
            document={document}
            onCloseDialog={handleCloseDialog}
            projectId={projectId}
          />
        )}
      </div>
    </PanelDetails>
  );
}
