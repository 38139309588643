import classNames from "classnames";
import React from "react";
import styles from "./tree.module.scss";

export default function TreeTitle(props: {
  isHighlighted?: boolean;
  children: React.ReactNode;
}) {
  const { isHighlighted, children } = props;

  return (
    <div
      className={classNames(
        styles.column_header,
        isHighlighted ? styles.highlighted : ""
      )}
    >
      {children}
    </div>
  );
}
