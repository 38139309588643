import { Icon } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { OpenableImage } from "../../../components";
import { usePartImage } from "../../pdm/hooks";
import { usePartProjectBom, useProject, useProjectImage } from "../hooks";
import styles from "./ProductImage.module.scss";

export default function ProductImage(props: {
  productId: number;
  height?: number;
  width?: number;
  noPlaceholder?: boolean;
  canPreview?: boolean;
  className?: string;
}) {
  const {
    productId,
    height = 128,
    width = 128,
    noPlaceholder,
    className,
    canPreview,
  } = props;
  const { data: product } = useProject(productId);
  const { data } = usePartProjectBom(productId, product?.lastDefinition.index);
  const { data: productImage } = useProjectImage(productId);
  const { data: partImage } = usePartImage(data?.part.id);
  const image = productImage || partImage;
  if (image) {
    const link = URL.createObjectURL(image);
    return (
      <>
        {canPreview ? (
          <OpenableImage
            imgUrl={link}
            alt={image.name}
            className={className}
            height={height}
            width={width}
          />
        ) : (
          <div
            className={classNames(styles.picture, className)}
            style={{ height: `${height}px`, width: `${width}px` }}
          >
            <img alt={image.name} src={link} />
          </div>
        )}
      </>
    );
  } else if (!noPlaceholder) {
    return (
      <div
        className={classNames(styles.placeholder, className)}
        style={{ height: `${height}px`, width: `${width}px` }}
      >
        <Icon icon="media" iconSize={50} />
      </div>
    );
  } else {
    return null;
  }
}
