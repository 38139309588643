import { PartType } from "@aletiq/types";
import { ProgressBar } from "@blueprintjs/core";
import React from "react";
import { useQueryClient } from "react-query";
import useApi from "../../../../../app/useApi";
import { useToaster } from "../../../../../hooks";
import { sortBy, useTranslations } from "../../../../../util";
import { pdmKeys } from "../../../hooks/queries";
import { PartState } from "../types";
import styles from "./useSubmitStandardParts.module.scss";

export default function useSubmitStandardParts(partStates: PartState[]) {
  const api = useApi();
  const toaster = useToaster();
  const tr = useTranslations();
  const queryClient = useQueryClient();

  return async () => {
    const specs = partStates.filter((p) => p.partInfo.action !== "ignore");
    const sortedSpecs = sortByPartType(specs, (spec) => spec.partInfo.type);

    let uploadedCount = 0;
    const creationErrors: string[] = [];
    const updateErrors: string[] = [];

    const reportUploadProgress = (isStart?: boolean) => {
      if (!isStart) {
        uploadedCount += 1;
      }

      const isInProgress = uploadedCount < specs.length;
      const intent = isInProgress ? "primary" : "success";
      const icon = isInProgress ? "info-sign" : "tick";

      toaster.show(
        {
          intent,
          icon,
          timeout: isInProgress ? 0 : 2000,
          message: (
            <>
              {tr.translate(
                isInProgress
                  ? "parts.dialog.drop-standard-parts.toaster.in-progress"
                  : "parts.dialog.drop-standard-parts.toaster.done"
              )}
              <ProgressBar
                intent={intent}
                value={uploadedCount / specs.length}
                stripes={false}
                className={styles.progress_bar}
              />
              <span className={styles.progress_message}>
                {uploadedCount}/{specs.length}
              </span>
            </>
          ),
        },
        "standard-part-upload-progress"
      );
    };

    //display initial upload progress (progress bar at 0)
    if (specs.length > 0) {
      reportUploadProgress(true);
    }

    for (const part of sortedSpecs) {
      if (part.partInfo.action === "create") {
        try {
          await api.pdm.createPart(
            {
              name: part.partInfo.name,
              description: "",
              isStandard: true,
              projects: [],
              revisionSpec: { name: "", description: "" },
              type: part.partInfo.type,
              format: part.partInfo.format,
            },
            part.file
          );
          reportUploadProgress();
        } catch (err) {
          creationErrors.push(part.file.name);
        }
      } else if (part.partInfo.action === "update" && part.partId) {
        try {
          await api.pdm.uploadCadFile(
            part.partId,
            { name: "", description: "" },
            part.file
          );
          reportUploadProgress();
        } catch (err) {
          updateErrors.push(part.partInfo.name);
        }
      }
    }

    if (creationErrors.length > 0 || updateErrors.length > 0) {
      toaster.show(
        {
          intent: "danger",
          icon: "warning-sign",
          timeout: 0,
          message: (
            <>
              {tr.translate("parts.dialog.drop-standard-parts.error")}
              <div className={styles.error_message}>
                {creationErrors.length > 0 && (
                  <>
                    {tr.translate(
                      "parts.dialog.drop-standard-parts.error.create"
                    )}
                    <ul>
                      {creationErrors.map((fileName) => (
                        <li key={fileName}>{fileName}</li>
                      ))}
                    </ul>
                  </>
                )}
                {updateErrors.length > 0 && (
                  <>
                    {tr.translate(
                      "parts.dialog.drop-standard-parts.error.update"
                    )}
                    <ul>
                      {updateErrors.map((fileName) => (
                        <li key={fileName}>{fileName}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </>
          ),
        },
        "standard-part-upload-progress"
      );
    }

    queryClient.invalidateQueries(pdmKeys.all);
  };
}

function sortByPartType<T>(values: T[], key: (value: T) => PartType) {
  return sortBy(values, key, (t1, t2) => {
    if (t1 === t2) {
      return 0;
    }
    if (t1 === "assembly") {
      return -1;
    }
    if (t2 === "assembly") {
      return 1;
    }
    return 0;
  });
}
