import { ProjectDefinition } from "@aletiq/types";
import React, { useState } from "react";
import { ActionBar, AlignRight } from "../../../../components";
import { CreateProjectDefinitionDialog } from "../../dialogs";
import { ProjectDefinitionActionBar } from "../ProjectDefinitionActionBar";
import CreateDefinitionButton from "./CreateDefinitionButton";

type ProjectNomenclatureActionBarDialog = "createDefinition";

export default function ProjectNomenclatureActionBar(props: {
  projectId: number;
  definitions: ProjectDefinition[];
  activeDefinition?: ProjectDefinition;
  isDraftIteration?: boolean;
  isDeleteDisabled?: boolean;
  hasComponents?: boolean;
  hasCreateDefinitionButton?: boolean;
  onActiveDefinitionChange: (idx: number) => void;
}) {
  const {
    projectId,
    definitions,
    activeDefinition,
    onActiveDefinitionChange,
    isDraftIteration,
    isDeleteDisabled,
    hasComponents,
    hasCreateDefinitionButton,
  } = props;

  const [dialog, setDialog] = useState<ProjectNomenclatureActionBarDialog>();

  const handleOpenDialog = (value: ProjectNomenclatureActionBarDialog) => () =>
    setDialog(value);

  const handleCloseDialog = () => setDialog(undefined);

  const handleCreateDefinitionDone = () => {
    onActiveDefinitionChange(definitions[0].index + 1);
    setDialog(undefined);
  };

  return (
    <ActionBar>
      {activeDefinition && (
        <ProjectDefinitionActionBar
          projectId={projectId}
          definitions={definitions}
          activeDefinition={activeDefinition}
          onActiveDefinitionChange={onActiveDefinitionChange}
          hasCreateDefinitionButton={hasCreateDefinitionButton}
          isDraftIteration={isDraftIteration}
          isDeleteDisabled={isDeleteDisabled}
          hasComponents={hasComponents}
        />
      )}
      <AlignRight />
      {hasCreateDefinitionButton && (
        <CreateDefinitionButton
          onClick={handleOpenDialog("createDefinition")}
        />
      )}

      {dialog === "createDefinition" && activeDefinition && (
        <CreateProjectDefinitionDialog
          projectId={projectId}
          onClose={handleCloseDialog}
          onSuccess={handleCreateDefinitionDone}
          definitions={definitions}
        />
      )}
    </ActionBar>
  );
}
