import { Document } from "@aletiq/types";
import { Spinner } from "@blueprintjs/core";
import React, { useState } from "react";
import { ActionBar, AlignRight, Button, Card } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { AddCitationsDialog } from "../../dialogs";
import {
  useDeleteDocumentCitation,
  useDocumentCitations,
  useDocumentCitedBy,
} from "../../hooks";
import CitationRow from "./CitationRow";
import styles from "./DocumentCitations.module.scss";

export default function DocumentCitations(props: { document: Document }) {
  const { document } = props;
  const tr = useTranslations();

  const [isCitationsOpen, setIsCitationsOpen] = useState(true);
  const [isCitedByOpen, setIsCitatedByOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const canCiteDocuments = useHasPermission("update:documents");

  const { data: citations, isLoading: isLoadingCitations } =
    useDocumentCitations(document.id);
  const { data: citedBy, isLoading: isLoadingCitedBy } = useDocumentCitedBy(
    document.id
  );
  const { mutate: removeCitation, isLoading: isRemovingLink } =
    useDeleteDocumentCitation();

  const handleRemoveCitation = (citedDoc: number) => () => {
    removeCitation({ citation: citedDoc, citedBy: document.id });
  };

  const handleFoldCitations = () => setIsCitationsOpen(!isCitationsOpen);
  const handleFoldCitedBy = () => setIsCitatedByOpen(!isCitedByOpen);
  const handleToggleDialog = () => setShowDialog(!showDialog);

  return (
    <>
      <ActionBar>
        <AlignRight />
        <Button
          icon="plus"
          intent="secondary"
          isDisabled={!canCiteDocuments}
          onClick={handleToggleDialog}
          isDense
        >
          {tr.translate("document.details.citations.action.add")}
        </Button>
      </ActionBar>
      <Card
        title={tr.translate("document.details.citations.cited-by", {
          document: <em className={styles.name}>{document.name}</em>,
        })}
        isFolded={!isCitedByOpen}
        onToggleFold={handleFoldCitedBy}
        className={styles.card}
      >
        {citedBy?.map((doc) => (
          <CitationRow key={doc} documentId={doc} />
        ))}
        {citedBy?.length === 0 && <em>{tr.translate("generic.label.none")}</em>}
        {isLoadingCitedBy && <Spinner />}
      </Card>

      <Card
        title={tr.translate("document.details.citations.cited", {
          document: <em className={styles.name}>{document.name}</em>,
        })}
        isFolded={!isCitationsOpen}
        onToggleFold={handleFoldCitations}
        className={styles.card}
      >
        {citations?.map((citedDoc) => (
          <CitationRow
            key={citedDoc}
            documentId={citedDoc}
            onRemove={handleRemoveCitation(citedDoc)}
            isRemoving={isRemovingLink}
          />
        ))}
        {citations?.length === 0 && (
          <em>{tr.translate("generic.label.none")}</em>
        )}
        {isLoadingCitations && <Spinner />}
      </Card>

      {showDialog && (
        <AddCitationsDialog
          document={document}
          onClose={handleToggleDialog}
          citedDocuments={citations || []}
        />
      )}
    </>
  );
}
