import { OperationTool } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import {
  Button,
  Card,
  Field,
  GenericStateTag,
  GenericStatusTag,
  HighlightedText,
  Menu,
  MenuItem,
  Popover2,
} from "../../../../components";
import { useNavigateTo } from "../../../../hooks";
import { ChangeStatus, isInString, useTranslations } from "../../../../util";
import { useProject } from "../../hooks";
import { useToolVersion } from "../hooks";
import styles from "./OperationToolEditor.module.scss";

export default function OperationToolView(props: {
  operationTool: OperationTool;
  status?: ChangeStatus;
  search: string;
}) {
  const { operationTool, status = "unchanged", search } = props;
  const tr = useTranslations();

  const { data: tool } = useProject(operationTool.tool);
  const version = useToolVersion(operationTool);

  const isToolVisible = isInString(tool?.name ?? "", search);

  const openToolPassports = useNavigateTo({
    path: `/project/${operationTool.tool}/passports`,
  });
  const openToolDocuments = useNavigateTo({
    path: `/project/${operationTool.tool}/documents`,
  });

  if (!isToolVisible) {
    return null;
  }

  return (
    <Card
      icon="wrench"
      title={<HighlightedText text={tool?.name} highlight={search} />}
      titleClassName={classNames(status === "deleted" && styles.tool_deleted)}
      headerActions={
        <>
          <GenericStatusTag status={status} />
          <Popover2
            content={
              <Menu isDense>
                <MenuItem
                  icon="id-number"
                  text={tr.translate(
                    "project.operation-bill.operation-tool.show-passports"
                  )}
                  onClick={openToolPassports}
                  isDense
                />
                <MenuItem
                  icon="document"
                  text={tr.translate(
                    "project.operation-bill.operation-tool.show-documents"
                  )}
                  onClick={openToolDocuments}
                  isDense
                />
              </Menu>
            }
          >
            <Button
              isDense
              icon="more"
              isDisabled={status === "deleted"}
              className={styles.menu_button}
            />
          </Popover2>
        </>
      }
      className={styles.tool_card}
    >
      <Field
        inline
        minWidth={180}
        label={tr.translate("generic.label.description")}
      >
        {tool?.description}
      </Field>
      <Field
        inline
        minWidth={180}
        label={tr.translate("project.operation-bill.operation-tool.version")}
      >
        {operationTool.version?.type === "last" &&
          tr.translateAsString(
            "project.operation-bill.operation-document.revision.last-value",
            { value: version?.version ?? "" }
          )}

        {operationTool.version?.type === "specific" && version && (
          <GenericStateTag
            name={version.version}
            state={version.isDraft ? "draft" : "approved"}
          />
        )}

        {!operationTool.version && tr.translate("generic.label.none.f")}
      </Field>
    </Card>
  );
}
