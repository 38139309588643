import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { processKeys } from "./queries";

export default function useProcess(processId: number | null) {
  const api = useApi();
  return useQuery(
    processKeys.byId(processId!),
    () => api.task.getProcess(processId!),
    { enabled: processId !== null }
  );
}
