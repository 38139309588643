import {
  Document,
  DocumentRevision,
  DocumentRevisionSpec,
} from "@aletiq/types";
import { removeFileExtension } from "../../../../util";
import { DocumentState } from "./state";

function makeDefaultSpec(): DocumentRevisionSpec {
  return {
    name: "",
    description: "",
    state: "draft",
  };
}

function makeSpec(revision: DocumentRevision): DocumentRevisionSpec {
  return {
    name: revision.revisionName ?? "",
    description: revision.description ?? "",
    state: "draft",
  };
}

function makeCreatedDocument(file: File): DocumentState {
  return {
    file,
    documentId: null,
    action: {
      action: "create",
      name: removeFileExtension(file.name),
      owner: null,
      revision: makeDefaultSpec(),
      properties: [],
    },
  };
}

function makeUpdatedDocument(file: File, document: Document): DocumentState {
  const lastRevision = document.revisions[0];
  return {
    file,
    documentId: document.id,
    action: {
      action: document.hasAccess ? "update" : "ignore",
      name: removeFileExtension(document.name),
      owner: document.user,
      properties: [],
      revision:
        lastRevision.state === "draft"
          ? makeSpec(lastRevision)
          : makeDefaultSpec(),
    },
  };
}

export default function groupDocumentFiles(
  documents: Record<string, Document | undefined>,
  files: File[]
): DocumentState[] {
  return files.map((file) => {
    const document = documents[file.name];
    if (!document) {
      return makeCreatedDocument(file);
    }
    return makeUpdatedDocument(file, document);
  });
}
