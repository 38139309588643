import { MenuSP } from "@aletiq/design-system";
import { OperationTool } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import {
  Button,
  GenericStateTag,
  MenuItem,
  Popover2,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { useProductVersions } from "../../hooks";
import { useToolVersion, useUpdateOperationTool } from "../hooks";
import styles from "./OperationToolEditor.module.scss";
export default function ToolVersionSelect(props: {
  projectId: number;
  billId: number;
  operationNumber: number;
  isDisabled?: boolean;
  tool: OperationTool;
}) {
  const { isDisabled, tool, projectId, billId, operationNumber } = props;
  const tr = useTranslations();

  const { data: versions = [] } = useProductVersions(tool.tool);
  const version = useToolVersion(tool);

  const { mutate: updateTool, isLoading: isUpdatingVersion } =
    useUpdateOperationTool(projectId, billId, operationNumber, tool.tool);

  const lastApprovedVersion = versions.find((v) => !v.isDraft);

  return (
    <Popover2
      position="bottom-left"
      disabled={isDisabled}
      content={
        <MenuSP isDense className={styles.menu}>
          {versions.map((version) => (
            <MenuItem
              isDense
              active={
                tool.version?.type === "specific" &&
                tool.version.version === version.id
              }
              disabled={isDisabled}
              text={
                <GenericStateTag
                  name={version.version}
                  state={version.isDraft ? "draft" : "approved"}
                />
              }
              onClick={() =>
                updateTool({
                  type: "specific",
                  version: version.id,
                })
              }
            />
          ))}
          <MenuItem
            isDense
            active={tool.version?.type === "last"}
            disabled={isDisabled || !lastApprovedVersion}
            text={
              <>
                <div>
                  {tr.translate(
                    "project.operation-bill.operation-tool.version.last"
                  )}
                </div>
                <div
                  className={classNames(
                    styles.revision_description,
                    styles.warn
                  )}
                >
                  {tr.translate(
                    "project.operation-bill.operation-document.revision.last.caption"
                  )}
                </div>
              </>
            }
            onClick={() =>
              updateTool({
                type: "last",
              })
            }
          />
          <MenuItem
            isDense
            disabled={isDisabled}
            text={tr.translate("generic.label.none.f")}
            onClick={() => updateTool(undefined)}
          />
        </MenuSP>
      }
    >
      <Button
        isDisabled={isDisabled}
        isLoading={isUpdatingVersion}
        isDense
        rightIcon="caret-down"
      >
        {tool.version?.type === "last" &&
          tr.translate(
            "project.operation-bill.operation-document.revision.last-value",
            { value: version?.version ?? "" }
          )}

        {tool.version?.type === "specific" && version && (
          <GenericStateTag
            name={version.version}
            state={version.isDraft ? "draft" : "approved"}
          />
        )}

        {!tool.version && tr.translate("generic.label.none.f")}
      </Button>
    </Popover2>
  );
}
