import {
  AlertPopoverBase,
  AlertPopoverContent,
  AlertPopoverHeader,
  Icon,
} from "@aletiq/design-system";
import { PropertyObject } from "@aletiq/types";
import { Spinner, Text } from "@blueprintjs/core";
import React, { ReactNode } from "react";
import { ColumnOptionConfig } from "../../hooks";
import { partitionWith, useTranslations } from "../../util";
import { PageLink } from "../page";
import styles from "./SelectColumns.module.scss";
import SelectMandatoryColumn from "./SelectMandatoryColumn";
import SelectOptionalColumn from "./SelectOptionalColumn";

export type ColumnOption<T> = {
  id: T;
  label: ReactNode;
  isMandatory?: boolean;
};

export default function SelectColumns<T extends string | number>(props: {
  config: ColumnOptionConfig<T> | undefined;
  options: ColumnOption<T>[];
  onToggleColumn: (column: T) => void;
  canEditDefaultColumns?: boolean;
  objectType?: PropertyObject;
  children: ReactNode;
}) {
  const {
    config,
    options,
    onToggleColumn,
    canEditDefaultColumns,
    children,
    objectType,
  } = props;

  const tr = useTranslations();

  const [mandatory, optional] = partitionWith(
    options,
    (o) => o.isMandatory ?? false
  );

  const isLoadingConfig = config === undefined;

  return (
    <AlertPopoverBase
      isDense
      content={
        <>
          <AlertPopoverHeader
            icon={"th"}
            isDense
            title={tr.translate("generic.action.edit-columns.title")}
          />

          <AlertPopoverContent>
            <span className={styles.description}>
              {tr.translate("generic.action.edit-columns.description")}
            </span>

            {isLoadingConfig && <Spinner />}

            {config && (
              <>
                {mandatory.map((column) => (
                  <SelectMandatoryColumn item={column} key={column.id} />
                ))}

                {optional.map((column) => (
                  <SelectOptionalColumn
                    key={column.id}
                    item={column}
                    config={config}
                    onToggleColumn={onToggleColumn}
                  />
                ))}
              </>
            )}

            {canEditDefaultColumns && (
              <>
                <PageLink
                  to={{
                    path: "/administration/attributes",
                    pageTab: objectType,
                  }}
                  className={styles.edit_default}
                >
                  <Text ellipsize tagName="span">
                    {tr.translate("generic.action.edit-columns.edit-default")}
                  </Text>
                  <Icon icon="share" intent="primary" />
                </PageLink>
              </>
            )}
          </AlertPopoverContent>
        </>
      }
    >
      {children}
    </AlertPopoverBase>
  );
}
