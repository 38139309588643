import { useHasPermission } from "../../../hooks";
import { useAssemblyComponents } from "../../pdm/hooks";
import { isComponentsComplete } from "../../pdm/services";
import {
  usePartProjectBom,
  useProjectBOM,
  useProjectDefinitionDocuments,
} from "../hooks";
import useListProjectDefinitions from "../hooks/useListProjectDefinitions";
import { diffParts } from "./compareDefinition";

export default function useProjectNomenclatureState(
  projectId: number,
  definitionIdx: number
) {
  const canUpdateProject = useHasPermission("update:projects");

  const { data: components = [] } = useProjectBOM(projectId, definitionIdx);

  const { data: partWithIteration } = usePartProjectBom(
    projectId,
    definitionIdx
  );
  const { data: definitions = [] } = useListProjectDefinitions(projectId);
  const part = partWithIteration?.part;
  const iteration = partWithIteration?.iteration;

  const { data: partComponents = [] } = useAssemblyComponents(
    part?.id ?? 0,
    part?.type === "assembly"
  );

  const { data: documents = [] } = useProjectDefinitionDocuments(
    projectId,
    definitionIdx
  );

  const activeDefinition = definitions.find(
    (definition) => definition.index === definitionIdx
  );

  const { data: previousPartIteration } = usePartProjectBom(
    projectId,
    definitionIdx - 1
  );

  const currentPartIterationId =
    part && iteration
      ? [{ part: part.id, iteration: iteration.number }]
      : undefined;

  const previousPartIterationId = previousPartIteration
    ? [
        {
          part: previousPartIteration.part.id,
          iteration: previousPartIteration.iteration.number,
        },
      ]
    : undefined;

  const partsDiff = diffParts(
    currentPartIterationId ?? [],
    previousPartIterationId ?? []
  );
  const hasCompleteBom =
    isComponentsComplete(partComponents) || part?.type === "part";
  const hasObsoleteComponents =
    part?.lastIteration.fileComponentLoaded &&
    part.lastIteration.components.some((c) => c.iterationState === "obsolete");
  const hasDraftDefinition = definitions.some(
    (definition) => definition.state === "draft"
  );

  const isValidatedDefinition = activeDefinition?.state === "approved";
  const isDraftDefinition = activeDefinition?.state === "draft";
  const isPartAnAssembly = part?.type === "assembly";
  const isDraftIteration = iteration?.state === "draft";

  const isApproveDisabled = isDraftIteration;
  const isDeleteDisabled = definitions.length <= 1;
  const hasAddComponents = !isValidatedDefinition;
  const isAddComponentsDisabled = !canUpdateProject || isValidatedDefinition;
  const hasCreateDefinitionButton = !hasDraftDefinition;
  const isEditProductBomDisabled = !canUpdateProject || !hasCompleteBom;
  const hasEditProductBom = isPartAnAssembly && !isValidatedDefinition;
  const isRevisionSelectDisabled = isValidatedDefinition;
  const hasLinkBomButton = part === undefined && isDraftDefinition;
  const hasDeletePart = !isValidatedDefinition;
  const hasComponents = components.length > 0;

  const draftDocuments = documents.filter(
    (d) => d.revision && d.revision.state === "draft"
  );

  const obsoleteDocuments = documents.filter(
    (d) => d.revision && d.revision.state === "obsolete"
  );

  return {
    part,
    iteration,
    definitions,
    activeDefinition,
    hasCompleteBom,
    hasObsoleteComponents,
    hasCreateDefinitionButton,
    hasAddComponents,
    hasEditProductBom,
    hasLinkBomButton,
    hasComponents,
    isDraftIteration,
    isRevisionSelectDisabled,
    isDeleteDisabled,
    isApproveDisabled,
    isPartAnAssembly,
    isAddComponentsDisabled,
    hasDeletePart,
    isEditProductBomDisabled,
    draftDocuments,
    obsoleteDocuments,
    partsDiff,
  };
}
