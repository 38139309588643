import { FileMeta } from "@aletiq/types";
import { useCallback } from "react";
import useApi from "../../../app/useApi";
import {
  useShowImage,
  useShowTextFile,
  useShowVideo,
} from "../../../components";
import { API_URL } from "../../../config";
import { useFilePreviewOverlay } from "../../../hooks";

export default function usePreviewPassportFile(passportId: number) {
  const api = useApi();

  const handleGetFilePreviewToken = useCallback(
    (fileId: number) =>
      api.passport.getPassportFilePreviewToken(passportId, fileId),
    [api, passportId]
  );

  const showPreview = useFilePreviewOverlay(handleGetFilePreviewToken);
  const showTextFile = useShowTextFile();
  const showImage = useShowImage();
  const showVideo = useShowVideo();

  return useCallback(
    async (file: FileMeta) => {
      if (file.type === "text/plain") {
        const token = await api.passport.getPassportFileDownloadToken(
          passportId,
          file.id
        );
        const response = await fetch(`${API_URL}${token}`);
        const content = await response.text();
        showTextFile(content);
        return;
      }

      if (file.type.startsWith("image/")) {
        const token = await api.passport.getPassportFileDownloadToken(
          passportId,
          file.id
        );
        const response = await fetch(`${API_URL}${token}`);
        const content = await response.blob();

        showImage(URL.createObjectURL(content));
        return;
      }

      if (file.type.startsWith("video/")) {
        const token = await api.passport.getPassportFileDownloadToken(
          passportId,
          file.id
        );
        const response = await fetch(`${API_URL}${token}`);
        const content = await response.blob();

        showVideo(URL.createObjectURL(content));
        return;
      }

      showPreview(file.id);
    },
    [showPreview, api, showTextFile, passportId, showImage, showVideo]
  );
}
