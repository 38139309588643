import { IconName } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  PropertyCard,
} from "../../../../components";
import { HandleClear, HandleFilter, useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { usePaginatedProjects } from "../../../project/hooks";
import AttributeCards from "../../AttributeCards";
import {
  CreationDatePropertyCard,
  LastUpdatePropertyCard,
  PropertyCardFilters,
  SelectDefaultDisplayedProperties,
} from "../common";
import { useCountProductsWithDescriptions, useProperties } from "../hooks";
import { PropertyFilters } from "../types";
import CreateProductPropertyDialog from "./Dialogs/CreateProductProperty";
import ProductPropertyCard from "./ProductPropertyCard";
import ProductPropertiesTarget from "./ProductPropertyTarget";

export default function ProductAttributes(props: {
  filters: PropertyFilters;
  handleFilter: HandleFilter<PropertyFilters>;
  handleClear: HandleClear<PropertyFilters>;
  openPopoverOnStart?: boolean;
}) {
  const { filters, handleClear, handleFilter, openPopoverOnStart } = props;
  const tr = useTranslations();

  const [showCreationDialog, setShowCreationDialog] = useState(false);

  const canCreateAttribute = useHasPermission("create:custom-attributes");
  const canEditDefaultDisplay = useHasPermission(
    "update:default-property-display"
  );

  const handleToggleDialog = () => {
    setShowCreationDialog(!showCreationDialog);
  };

  const { data: properties = [] } = useProperties("product");
  const { data: articleData } = usePaginatedProjects({
    limit: 10,
    projectType: "article",
  });
  const { data: toolData } = usePaginatedProjects({
    limit: 10,
    projectType: "tool",
  });
  const { data: articlesWithDescriptions = 0 } =
    useCountProductsWithDescriptions("article");
  const { data: toolsWithDescriptions = 0 } =
    useCountProductsWithDescriptions("tool");

  const articleCount = articleData?.count ?? 0;
  const toolCount = toolData?.count ?? 0;

  const productPropertyUses: { count: number; icon: IconName }[] = [
    {
      count: articleCount,
      icon: "projects",
    },
    { count: toolCount, icon: "wrench" },
  ];
  const descriptionPropertyUses: { count: number; icon: IconName }[] = [
    { count: articlesWithDescriptions, icon: "projects" },
    { count: toolsWithDescriptions, icon: "wrench" },
  ];

  return (
    <AttributeCards>
      <ActionBar>
        <PropertyCardFilters
          filters={filters}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
        <AlignRight />
        {canEditDefaultDisplay && (
          <SelectDefaultDisplayedProperties
            properties={properties}
            objectType="product"
            openOnStart={openPopoverOnStart}
          />
        )}
        <Button
          intent="secondary"
          icon="plus"
          isDisabled={!canCreateAttribute}
          onClick={handleToggleDialog}
          isDense
        >
          {tr.translate("admin.attribute.new.button")}
        </Button>
      </ActionBar>
      <PropertyCard
        type="string"
        filters={filters}
        label={tr.translateAsString("generic.label.description")}
        description={tr.translateAsString(
          "admin.attribute.products.description"
        )}
        usedIn={descriptionPropertyUses}
        target={<ProductPropertiesTarget />}
      />
      <CreationDatePropertyCard
        entity="product"
        filters={filters}
        usedIn={productPropertyUses}
        target={<ProductPropertiesTarget />}
      />
      <LastUpdatePropertyCard
        entity="product"
        filters={filters}
        usedIn={productPropertyUses}
        target={<ProductPropertiesTarget />}
      />
      {canCreateAttribute && showCreationDialog && (
        <CreateProductPropertyDialog onClose={handleToggleDialog} />
      )}
      {properties?.map((property) => (
        <ProductPropertyCard property={property} key={property.id} />
      ))}
    </AttributeCards>
  );
}
