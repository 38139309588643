import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { documentQueries } from "./queries";

export default function useDocumentCitedBy(documentId: number) {
  const api = useApi();
  return useQuery({
    ...documentQueries.citedBy(api, documentId),
  });
}
