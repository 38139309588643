import { Document, Property, PropertyValue } from "@aletiq/types";
import React from "react";
import { EditableOwner, OpenableImage } from "../../../components";
import { useHasPermission, useUsers } from "../../../hooks";
import { useProperties } from "../../administration/Attributes/hooks";
import { PropertyField } from "../../passport/PassportDetails/ConfigurationField";
import {
  useDocumentThumbnail,
  useSetDocumentOwner,
  useUpdateDocumentProperties,
} from "../hooks";
import styles from "./DocumentDetails.module.scss";

export default function DocumentProperties(props: { document: Document }) {
  const { document } = props;

  const { data: image } = useDocumentThumbnail(document.id);
  const { data: allUsers = [] } = useUsers();
  const documentOwnerUsers = allUsers.filter((u) =>
    u.permissions.includes("update:documents")
  );

  const canUpdateDocuments = useHasPermission("update:documents");

  const { data: properties = [] } = useProperties("document");
  const { mutate: setDocumentOwner, isLoading: isSettingOwner } =
    useSetDocumentOwner(document.id);
  const { mutate: updateProperties } = useUpdateDocumentProperties(document.id);

  const handleUpdatePropertyValue = (
    property: string,
    value: PropertyValue
  ) => {
    updateProperties([{ property, value }]);
  };

  if (!document || document.isDeleted) return null;
  return (
    <div className={styles.properties}>
      {image && (
        <OpenableImage
          imgUrl={URL.createObjectURL(image)}
          className={styles.img}
          alt={document.name}
        />
      )}

      <div className={styles.properties_inner}>
        <EditableOwner
          initialOwner={document.user}
          allowedOwners={documentOwnerUsers}
          onSubmit={setDocumentOwner}
          isSubmitting={isSettingOwner}
          isDisabled={!canUpdateDocuments}
          inline
        />
        {properties.map((property: Property) => (
          <PropertyField
            key={`property-${property.id}`}
            property={property}
            value={document.properties[property.name]}
            onEditValue={handleUpdatePropertyValue}
            canUpdate={canUpdateDocuments}
          />
        ))}
      </div>
    </div>
  );
}
