import React, { useMemo, useState } from "react";
import { useCurrentUser, useUsers } from "../../hooks";
import { filterByString, sortByString, useTranslations } from "../../util";
import FilterMenuItem from "./FilterMenuItem";
import FilterView from "./FilterView";

export default function WorkflowOwnerFilter(props: {
  selected: number[];
  myParticipationWorkflowSelected: boolean;
  onSelect: (values?: number[]) => void;
  onSelectMyParticipationWorkflow: (value: boolean) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const {
    selected,
    myParticipationWorkflowSelected,
    onSelectMyParticipationWorkflow,
    onSelect,
    onClear,
  } = props;
  const { data: users = [] } = useUsers();
  const { data: currentUser } = useCurrentUser();

  const handleMyParticipationWorkflow = () => {
    onSelectMyParticipationWorkflow(!myParticipationWorkflowSelected);
  };

  const handleMyWorkflow = () => {
    if (!currentUser) {
      return;
    }
    if (selected.includes(currentUser.id)) {
      onSelect(selected.filter((u) => u !== currentUser.id));
    } else {
      onSelect([...selected, currentUser.id]);
    }
  };

  const [search, setSearch] = useState("");

  const sortedUsers = useMemo(() => {
    const filteredUsers = filterByString(users, (u) => u.displayName, search);
    return sortByString(filteredUsers, (u) => u.displayName);
  }, [users, search]);

  return (
    <FilterView
      items={sortedUsers.map((u) => ({ id: u.id, text: u.displayName }))}
      selected={selected}
      all={users.map((u) => u.id)}
      placeholder={tr.translateAsString("filter.workflow-owner.placeholder")}
      onSelect={onSelect}
      onClear={onClear}
      search={search}
      onSearch={setSearch}
      hasSelectAll
      headItems={
        <>
          <FilterMenuItem
            onSelect={handleMyWorkflow}
            selected={currentUser ? selected.includes(currentUser.id) : false}
            text={tr.translate("workflow.filter-pill.owned")}
          />
          <FilterMenuItem
            onSelect={handleMyParticipationWorkflow}
            selected={myParticipationWorkflowSelected}
            text={tr.translate("workflow.filter-pill.participating")}
          />
        </>
      }
    />
  );
}
