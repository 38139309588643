import { AccessEntity } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../../app/useApi";
import { useToaster } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { documentKeys } from "../../../hooks/queries";

export default function useUpdateDocumentsAccess() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (accessRightByDocId: Record<number, AccessEntity[]>) => {
      for (const docKey in accessRightByDocId) {
        const docId = parseInt(docKey, 10);
        const accessRight = accessRightByDocId[docId];

        await api.document.updateDocumentAccess(docId, accessRight);
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(documentKeys.all);
      },
      onError: (error: Error) => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.documents.update-access", {
            errMsg: error.message,
          }),
        });
      },
    }
  );
}
