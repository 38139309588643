import { Process, Task } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import {
  StatusSelect,
  StatusTag,
  UserInitialsList,
} from "../../../../components";
import {
  useHasUpdateRightOnTask,
  useTaskHasLockDependecies,
  useWorkflowColor,
} from "../../hooks";
import styles from "./RowEntityProcessPanelView.module.scss";

export default function RowEntityProcessPanelView(props: {
  task: Task;
  workflow: Process;
  className?: string;
}) {
  const { task, workflow, className } = props;

  const hasLockedDeps = useTaskHasLockDependecies(task, workflow);
  const canUpdateStatus = useHasUpdateRightOnTask(task, workflow);
  const workflowColor = useWorkflowColor(workflow);

  return (
    <div
      className={classNames(styles.row, className)}
      key={task.id}
      style={{
        borderLeft: `8px solid ${workflowColor}`,
      }}
    >
      <div className={classNames(styles.cell, styles.task)}>
        <Text ellipsize>{task.title}</Text>
      </div>
      <div className={classNames(styles.cell, styles.assignee_col)}>
        <UserInitialsList users={task.owners} />
      </div>
      <div className={classNames(styles.cell, styles.status_col)}>
        {workflow.isArchived && (
          <StatusTag isDisabled isTaskLocked={hasLockedDeps} hasShorthandText>
            {task.status}
          </StatusTag>
        )}
        {!workflow.isArchived && (
          <StatusSelect
            hasShorthandText
            hasLockedDependencies={hasLockedDeps}
            hasUpdateRights={canUpdateStatus ?? false}
            task={task}
          />
        )}
      </div>
    </div>
  );
}
