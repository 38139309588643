import { Property, PropertyValue } from "@aletiq/types";
import React from "react";
import { EditablePropertyInputNumber } from "../../../../components";
import { useTranslations } from "../../../../util";

export function CustomNumberField(props: {
  property: Property;
  propertyValue?: { type: "number"; value: number };
  onSubmit: (value: PropertyValue) => void;
  canEdit: boolean;
  hasDescriptionTooltip?: boolean;
  search?: string;
  minWidth?: number;
}) {
  const {
    propertyValue,
    property,
    canEdit,
    onSubmit,
    hasDescriptionTooltip,
    search,
    minWidth,
  } = props;
  const tr = useTranslations();

  const handleSubmit = (value: number) => {
    if (value) {
      onSubmit({ type: "number", value });
    } else {
      onSubmit(null);
    }
  };

  return (
    <EditablePropertyInputNumber
      value={propertyValue?.value ?? 0}
      isEditable={canEdit}
      label={property.label}
      placeholder={tr.translateAsString("generic.action.modify-value", {
        value: property.label,
      })}
      emptyState={tr.translateAsString("generic.label.none")}
      tooltipText={hasDescriptionTooltip ? property.description : undefined}
      onSubmit={handleSubmit}
      search={search}
      minWidth={minWidth}
      inline
    />
  );
}
