import { Callout } from "@blueprintjs/core";
import React from "react";
import { Icon, ProjectName } from "../../../components";
import { useTranslations } from "../../../util";
import { useDocumentProjects } from "../hooks";

export default function LinkedProjectsCallout(props: { documentId: number }) {
  const { documentId } = props;
  const { data: documentProjects = [] } = useDocumentProjects(documentId);
  const tr = useTranslations();

  return (
    <React.Fragment>
      {documentProjects && documentProjects.length > 0 && (
        <Callout intent="danger">
          <strong>
            {tr.translate("storage.dialog.delete.linked-projects.warning")}
          </strong>
          <ul>
            {documentProjects?.map((project) => (
              <li key={project}>
                <Icon icon="projects" inline />
                <ProjectName projectId={project} />
              </li>
            ))}
          </ul>
          <p>{tr.translate("storage.dialog.delete.linked-projects.confirm")}</p>
        </Callout>
      )}
    </React.Fragment>
  );
}
