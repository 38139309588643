import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { AletiqPropertyOption } from "../../../../components";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { passportKeys } from "../../../passport/hooks/queries";

export default function useUpdateAndCreatePassportFileTypes() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (values: {
      newFileTypes: AletiqPropertyOption[];
      updatedFileTypes: AletiqPropertyOption[];
    }) => {
      const { newFileTypes, updatedFileTypes } = values;

      await api.passport.updatePassportFileTypes(
        updatedFileTypes.map((t) => ({
          id: t.value!,
          value: t.label,
          deleted: t.deleted || false,
        }))
      );

      for (const newType of newFileTypes) {
        await api.passport.addPassportFileType({
          value: newType.label,
          deleted: newType.deleted,
        });
      }
    },
    {
      onSettled: () =>
        queryClient.invalidateQueries(passportKeys.allFiletypes()),
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.passport.file-type.update"),
        });
      },
    }
  );
}
