import { CommentTagNotification, Entity, EntityId } from "@aletiq/types";
import React from "react";
import { User } from "../../../components";
import ActivityEntityLink from "../../../components/activities/links/ActivityEntityLink";
import { useTranslations } from "../../../util";
import { useDocument } from "../../documents/hooks";
import { usePassport } from "../../passport/hooks";
import { usePart } from "../../pdm/hooks";
import { useProject } from "../../project/hooks";
import { useProcess } from "../../tasks/hooks";
import NotificationCard from "../NotificationCard";

function useEntity(entityId: EntityId): Entity | undefined {
  const { data: document } = useDocument(
    entityId.type === "file" ? entityId.id : null
  );

  const { data: process } = useProcess(
    entityId.type === "process" ? entityId.id : null
  );

  const { data: passport } = usePassport(
    entityId.type === "passport" ? entityId.id : null
  );

  const { data: project } = useProject(
    entityId.type === "project" ? entityId.id : null
  );

  const { data: part } = usePart(entityId.type === "part" ? entityId.id : null);

  return document
    ? { type: "file", entity: document }
    : process
    ? { type: "process", entity: process }
    : passport
    ? { type: "passport", entity: passport }
    : project
    ? { type: "project", entity: project }
    : part
    ? { type: "part", entity: part }
    : undefined;
}

export default function CommentTagNotificationView(props: {
  notification: CommentTagNotification;
  isNew: boolean;
  onFollowLink: () => void;
}) {
  const {
    isNew,
    notification: {
      id,
      meta: {
        read,
        date,
        content: { creator, entity: entityId },
      },
    },
    onFollowLink,
  } = props;
  const tr = useTranslations();

  const entity = useEntity(entityId);

  return (
    <NotificationCard
      isRead={read}
      isNew={isNew}
      notificationId={id}
      date={date}
    >
      {tr.translate("notification.comment.tag", {
        user: <User userId={creator} size="small" inline />,
        entity: entity ? (
          <ActivityEntityLink entity={entity} onClick={onFollowLink} />
        ) : null,
      })}
    </NotificationCard>
  );
}
