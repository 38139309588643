import React from "react";
import {
  CreateTag,
  IgnoreTag,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  UpdateTag,
} from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { PartAction } from "../types";
import styles from "./PartActionSelect.module.scss";

export default function PartActionSelect(props: {
  onChangePartAction: (action: PartAction) => void;
  partId?: number | null;
  selectedAction?: PartAction;
  children?: React.ReactNode;
}) {
  const { children, onChangePartAction, selectedAction, partId } = props;

  const tr = useTranslations();

  return (
    <Popover2
      fill
      intent="primary"
      position="bottom-right"
      content={
        <Menu isDense>
          {/* existing parts actions */}
          {!selectedAction && (
            <MenuDivider
              isDense
              className={styles.first_menu_section}
              title={
                <span className={styles.menu_section}>
                  {tr.translate("part.dialog.drop-parts.action.existing")}
                </span>
              }
            />
          )}
          {(partId || !selectedAction) && (
            <MenuItem
              isDense
              intent="primary"
              active={selectedAction === "update"}
              onClick={() => onChangePartAction("update")}
              text={<UpdateTag />}
            />
          )}

          {/* new parts actions */}
          {!selectedAction && (
            <MenuDivider
              isDense
              className={styles.first_menu_section}
              title={
                <span className={styles.menu_section}>
                  {tr.translate("part.dialog.drop-parts.action.new")}
                </span>
              }
            />
          )}
          {(!partId || !selectedAction) && (
            <MenuItem
              isDense
              intent="primary"
              active={selectedAction === "create"}
              onClick={() => onChangePartAction("create")}
              text={<CreateTag />}
            />
          )}

          {/* actions for all parts */}
          {!selectedAction && (
            <MenuDivider
              isDense
              className={styles.first_menu_section}
              title={
                <span className={styles.menu_section}>
                  {tr.translate("part.dialog.drop-parts.action.all")}
                </span>
              }
            />
          )}
          <MenuItem
            isDense
            intent="primary"
            active={selectedAction === "ignore"}
            onClick={() => onChangePartAction("ignore")}
            text={<IgnoreTag />}
          />
        </Menu>
      }
    >
      {children}
    </Popover2>
  );
}
