import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { AletiqPropertyOption } from "../../../components";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { passportKeys } from "./queries";

export default function useUpdateAndCreatePassportState() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (values: {
      statesToAdd: AletiqPropertyOption[];
      statesToUpdate: AletiqPropertyOption[];
    }) => {
      const { statesToAdd, statesToUpdate } = values;
      const statesToAddParams = statesToAdd.map((state) => ({
        value: state.label,
        deleted: state.deleted,
      }));
      const statesToUpdateParams = statesToUpdate.map((state) => ({
        id: state.value ?? -1,
        param: {
          value: state.label,
          deleted: state.deleted,
        },
      }));

      for (const state of statesToAddParams) {
        await api.addPassportState(state);
      }
      for (const state of statesToUpdateParams) {
        await api.updatePassportState(state.id, state.param);
      }
    },
    {
      onSettled: () => queryClient.invalidateQueries(passportKeys.allStates()),
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.passport.state.update"),
        });
      },
    }
  );
}
