import { ProjectQueryParams, ProjectQueryResult } from "@aletiq/types";
import usePaginatedProjects from "./usePaginatedProjects";

export default function useArticles(
  props?: Omit<ProjectQueryParams, "projectType">
) {
  return usePaginatedProjects(
    { ...props, projectType: "article" },
    {
      keepPreviousData: false,
      select: (response: ProjectQueryResult) => response.list,
    }
  );
}
