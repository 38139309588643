import { Menu, MenuItem, Popover2 } from "@aletiq/design-system";
import { RevisionState } from "@aletiq/types";
import React, { useState } from "react";
import GenericStateTag, {
  iconFromState,
} from "../tags/GenericStateTag/GenericStateTag";
import ChangeRevisionDialog from "./ChangeRevisionDialog";
import useChangeRevisionTranslations from "./hooks/useChangeRevisionTranslations";
import { ChangeRevisionHandler, ObjectType } from "./types";

export default function ChangeRevisionMenuPopover(props: {
  state: RevisionState;
  next: RevisionState;
  previous: RevisionState;
  objectType: ObjectType;
  isPublished?: boolean;
  onChange: ChangeRevisionHandler;
}) {
  const { onChange, state, next, previous, objectType, isPublished } = props;

  const [dialog, setDialog] = useState<RevisionState>();

  const handleOpenDialog = (type: RevisionState) => () => {
    setDialog(type);
  };

  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  const { title: nextTitle } = useChangeRevisionTranslations(
    state,
    next,
    objectType,
    isPublished
  );
  const { title: previousTitle } = useChangeRevisionTranslations(
    state,
    previous,
    objectType,
    isPublished
  );

  return (
    <>
      <Popover2
        content={
          <Menu isDense>
            <MenuItem
              isDense
              icon={iconFromState(next)}
              text={nextTitle}
              onClick={handleOpenDialog(next)}
            />
            <MenuItem
              isDense
              icon={iconFromState(previous)}
              text={previousTitle}
              onClick={handleOpenDialog(previous)}
            />
          </Menu>
        }
      >
        <GenericStateTag state={state} hasInteraction />
      </Popover2>
      {dialog && (
        <ChangeRevisionDialog
          isPublished={isPublished}
          initial={{ state }}
          final={{ state: dialog }}
          onConfirm={onChange[dialog]}
          onClose={handleCloseDialog}
          objectType={objectType}
        />
      )}
    </>
  );
}
