import { ProjectField } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useUpdatePassportsConfigs() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (params: {
      passportsIds: number[];
      configSpec: ProjectField[];
      configValues: Record<number, number[]>;
    }) => {
      const { passportsIds, configSpec, configValues } = params;

      for (const configField of configSpec) {
        const selectedValues = configValues[configField.id] ?? [];

        for (const passportId of passportsIds) {
          await api.passport.setPassportConfiguration(
            passportId,
            configField.id,
            selectedValues
          );
        }
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(passportKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.passports.update-configs"),
        });
      },
    }
  );
}
