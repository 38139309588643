import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { documentQueries } from "./queries";

export default function useDocumentCitations(documentId: number) {
  const api = useApi();
  return useQuery({
    ...documentQueries.citations(api, documentId),
  });
}
