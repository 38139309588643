import React from "react";
import { Icon } from "../../../components";
import NameLink from "../../../components/link/NameLink";
import { useHasPermission } from "../../../hooks";
import { useProductVersions } from "../hooks";

export default function ProductVersionLink(props: {
  product: number;
  version: number;
  search?: string;
  hasIcon?: boolean;
}) {
  const { product, version, search, hasIcon } = props;

  const { data: versions = [] } = useProductVersions(product);
  const canUpdateProductVersions = useHasPermission("update:project-versions");

  const productVersion = versions.find((it) => it.id === version);

  return (
    <NameLink
      search={search}
      text={productVersion?.version || ""}
      isDisabled={false}
      location={{
        path: `/project/${props.product}/versions/${
          productVersion?.isDraft && canUpdateProductVersions
            ? "new"
            : productVersion?.id ?? ""
        }`,
      }}
      hasIcon={hasIcon}
      icon={<Icon icon="git-commit" inline intent="primary" />}
    />
  );
}
