import React from "react";
import useApi from "../../../app/useApi";
import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  RemoveLinkIcon,
} from "../../../components";
import {
  useCurrentUser,
  useDownloadQueue,
  useHasPermission,
} from "../../../hooks";
import { useTranslations } from "../../../util";
import { useDocument } from "../hooks";
import {
  DialogState,
  showDeleteDocument,
  showShareDocument,
  showUnlinkDocument,
} from "./DialogStateHandling";

export default function DocumentDetailsMenu(props: {
  documentId: number;
  projectId?: number;
  toggleEditName: () => void;
  setDialog: (dialog: DialogState) => void;
}) {
  const { documentId, projectId, toggleEditName, setDialog } = props;
  const tr = useTranslations();
  const api = useApi();

  const { data: document } = useDocument(documentId);
  const { data: currentUser } = useCurrentUser();
  const { handleDownload } = useDownloadQueue();

  const canUpdateDocuments = useHasPermission("update:documents");
  const canDeleteDocuments = useHasPermission("delete:documents");
  const canUpdateDocumentAccess =
    useHasPermission("update:all-access") ||
    (currentUser && currentUser?.id === document?.user);

  const onDownload = (revision?: number) =>
    handleDownload({
      fetchToken: () =>
        api.document.getDocumentDownloadToken(documentId, revision),
      forceDownload: true,
    });

  return (
    <Popover2
      content={
        <Menu intent="minimal" isDense>
          <MenuItem
            icon="download"
            onClick={() => onDownload()}
            text={tr.translate("generic.action.download")}
            isDense
          />
          {canUpdateDocuments && (
            <MenuItem
              icon="annotation"
              text={tr.translate("generic.action.rename")}
              onClick={toggleEditName}
              isDense
            />
          )}
          {projectId && canUpdateDocuments && (
            <MenuItem
              icon={<RemoveLinkIcon />}
              onClick={() => setDialog(showUnlinkDocument())}
              text={tr.translate("document.details.action.unlink")}
              isDense
            />
          )}
          {canUpdateDocumentAccess && (
            <MenuItem
              icon="people"
              onClick={() => setDialog(showShareDocument())}
              text={tr.translate("document.details.action.share")}
              isDense
            />
          )}
          <MenuDivider />
          {canDeleteDocuments && (
            <MenuItem
              icon="trash"
              onClick={() => setDialog(showDeleteDocument())}
              text={tr.translate("generic.action.delete")}
              isDense
            />
          )}
        </Menu>
      }
    >
      <Button icon="more" isDense />
    </Popover2>
  );
}
