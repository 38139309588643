import { Icon } from "@aletiq/design-system";
import { Passport } from "@aletiq/types";
import React from "react";
import { Translations } from "../../../util";

export default function passportLinkProps(
  tr: Translations,
  passport: Passport | undefined
) {
  const icon = (
    <Icon
      inline
      icon="id-number"
      intent={passport?.isDeleted ? "none" : "primary"}
    />
  );

  let tooltip = undefined;
  if (passport?.isDeleted) {
    tooltip = tr.translateAsString("link.deleted-object", {
      type: "passport",
    });
  }

  return {
    icon,
    tooltip,
    isDisabled: passport?.isDeleted ?? false,
    location: { panel: `passportId-${passport?.id}` },
    text: passport?.name ?? "",
  };
}
