import React from "react";
import {
  H2,
  SideTabs,
  TabItem,
  DataConnectionIcon,
  Menu,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { useHasPermission } from "../../../../hooks";
import styles from "./AdministrationTabs.styles.module.scss";
import { IconSize } from "@blueprintjs/core";

export default function AdministrationTabs() {
  const tr = useTranslations();

  const canUpdateNotifSettings = useHasPermission(
    "receive:email-notifications"
  );
  const canReadExports = useHasPermission("read:exports");
  const canReadIntegrationReports = useHasPermission(
    "read:integration-reports"
  );
  const canCreateUser = useHasPermission("create:users");
  const canReadPassportAttribute = useHasPermission("read:passport-attributes");

  return (
    <SideTabs className={styles.sideMenu}>
      <H2 className={styles.title}>{tr.translate("admin.path.title")}</H2>
      <Menu isDense className={styles.menu}>
        {canUpdateNotifSettings && (
          <TabItem
            text={tr.translateAsString("admin.notifications.title")}
            icon="notifications"
            url="/administration/notifications"
          />
        )}
        {canReadExports && (
          <TabItem
            text={tr.translateAsString("admin.exports.path.title")}
            icon="export"
            url="/administration/exports"
          />
        )}
        {canReadIntegrationReports && (
          <TabItem
            text={tr.translateAsString("admin.integration.title")}
            icon={<DataConnectionIcon size={IconSize.LARGE} />}
            url={"/administration/integration/report"}
          />
        )}
        {canCreateUser && (
          <TabItem
            text={tr.translateAsString("admin.users.title")}
            icon="user"
            url={"/administration/users"}
          />
        )}
        {canReadPassportAttribute && (
          <TabItem
            text={tr.translateAsString("admin.properties")}
            icon="properties"
            url="/administration/attributes"
          />
        )}
      </Menu>
    </SideTabs>
  );
}
