import { Dialog } from "@aletiq/design-system";
import React from "react";
import {
  GenericStateTagProps,
  iconFromState,
} from "../tags/GenericStateTag/GenericStateTag";
import ChangeRevisionContent from "./ChangeRevisionStateContent";
import useChangeRevisionTranslations from "./hooks/useChangeRevisionTranslations";
import { ObjectType } from "./types";

export default function ChangeRevisionDialog(props: {
  initial: GenericStateTagProps;
  final: GenericStateTagProps;
  isDisabled?: boolean;
  objectType: ObjectType;
  isPublished?: boolean;
  onConfirm?: () => void;
  onClose: () => void;
}) {
  const {
    isDisabled,
    objectType,
    initial,
    final,
    isPublished,
    onConfirm,
    onClose,
  } = props;

  const { title, description, callout } = useChangeRevisionTranslations(
    initial.state,
    final.state,
    objectType,
    isPublished
  );

  return (
    <Dialog
      isOpen
      title={title}
      icon={iconFromState(final.state)}
      onPrimaryClick={onConfirm}
      disablePrimary={isDisabled}
      onClose={onClose}
    >
      <ChangeRevisionContent
        callout={callout}
        description={description}
        initialProps={initial}
        finalProps={final}
      />
    </Dialog>
  );
}
