import { ProjectType } from "@aletiq/types";
import React from "react";
import { Dialog } from "../../../components";
import { useTranslations } from "../../../util";
import { useDeleteProducts } from "../hooks";

export default function DeleteProductsDialog(props: {
  products: number[];
  productType?: ProjectType;
  onClose: () => void;
}) {
  const { products, productType, onClose } = props;
  const tr = useTranslations();

  const { mutate: deletePassports, isLoading } = useDeleteProducts();

  const handleDelete = () => {
    deletePassports(products, {
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      icon="trash"
      intent="danger"
      onSecondaryClick={onClose}
      onPrimaryClick={handleDelete}
      submitting={isLoading}
      primaryText={tr.translateAsString("generic.action.delete")}
      secondaryText={tr.translateAsString("generic.action.cancel")}
      title={tr.translateAsString("product.dialog.delete.title", {
        count: products.length,
        type: productType,
      })}
    >
      {tr.translate("product.dialog.delete.subtitle", { type: productType })}
    </Dialog>
  );
}
