import React from "react";
import { useTranslations } from "../../../util";
import { useProject } from "../../project/hooks";
import { LinkEntitiesToProcessesDialog } from "../../tasks/dialogs";
import { ProcessCreationDialog } from "../../tasks/dialogs/ProcessCreationDialog";
import {
  ChangeParentVersionDialog,
  DeletePassportsDialog,
  UpdatePassportsConfigsDialog,
  UpdatePassportsDefinitionIndexDialog,
  UploadPassportFileDialog,
} from "../dialogs";
import EditPassportsPropertiesDialog from "../dialogs/EditPassportsPropertiesDialog";
import UpdatePassportsBillIndexDialog from "../dialogs/UpdatePassportsBillIndexDialog";
import { useSetPassportsParentVersion } from "../hooks";
import { ResolvedPassport } from "../types";
import { DialogType } from "./PassportBatchActionHeader";

export default function PassportBatchActionDialogs(props: {
  selectedPassports: ResolvedPassport[];
  dialog: DialogType;
  handleCloseDialog: () => void;
  handleCloseDeleteDialog: () => void;
}) {
  const {
    dialog,
    selectedPassports,
    handleCloseDialog,
    handleCloseDeleteDialog,
  } = props;
  const tr = useTranslations();

  const {
    mutate: updatePassportsParentVersions,
    isLoading: isUpdatingVersions,
  } = useSetPassportsParentVersion();

  const passportsEntities = selectedPassports.map(({ id }) => ({
    type: "passport" as const,
    id,
  }));

  const passportsIds = selectedPassports.map((p) => p.id);

  //use the parent product of the first selected project,
  //because the product is only relevant if all passports belong to the same product
  const parentProductId = selectedPassports[0]?.parentProjectId;
  const { data: parentProduct } = useProject(parentProductId);

  const handleSubmitUpdatedProductVersion = (versionId: number) => {
    updatePassportsParentVersions({ passportsIds, versionId });
  };

  return (
    <>
      {dialog === "create-workflow" && (
        <ProcessCreationDialog
          initialEntities={passportsEntities}
          onClose={handleCloseDialog}
        />
      )}
      {dialog === "link-workflow" && (
        <LinkEntitiesToProcessesDialog
          entities={passportsEntities}
          onClose={handleCloseDialog}
          title={tr.translateAsString("passport.dialogs.link.workflows.title", {
            count: passportsEntities.length,
          })}
          subtitle={tr.translate("passport.dialogs.link.workflows.subtitle")}
        />
      )}
      {dialog === "delete" && (
        <DeletePassportsDialog
          passports={passportsIds}
          onClose={handleCloseDeleteDialog}
        />
      )}
      {dialog === "update-version" && (
        <ChangeParentVersionDialog
          projectId={parentProductId}
          onSubmit={handleSubmitUpdatedProductVersion}
          submitting={isUpdatingVersions}
          onClose={handleCloseDialog}
        />
      )}
      {dialog === "update-bill-index" && (
        <UpdatePassportsBillIndexDialog
          articleId={parentProductId}
          onClose={handleCloseDialog}
          passportIds={passportsIds}
        />
      )}
      {dialog === "update-definition-index" && (
        <UpdatePassportsDefinitionIndexDialog
          passports={passportsIds}
          productId={parentProductId}
          onClose={handleCloseDialog}
        />
      )}
      {dialog === "update-config" && parentProduct && (
        <UpdatePassportsConfigsDialog
          passportsIds={passportsIds}
          parentProduct={parentProduct}
          onClose={handleCloseDialog}
        />
      )}
      {dialog === "upload-files" && (
        <UploadPassportFileDialog
          passportsIds={passportsIds}
          onClose={handleCloseDialog}
        />
      )}
      {dialog === "edit-property" && (
        <EditPassportsPropertiesDialog
          passports={selectedPassports}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}
