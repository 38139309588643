import { AccessEntity } from "@aletiq/types";
import { useMemo } from "react";

export default function useDefaultAccess(
  objectsIds: number[],
  objectsAccess: AccessEntity[][]
) {
  const { defaultUsers, defaultGroups } = useMemo(
    () =>
      objectsAccess.reduce(
        (
          acc: {
            defaultUsers: Record<number, number[]>;
            defaultGroups: Record<number, number[]>;
          },
          accesses,
          index
        ) => {
          const objectId = objectsIds[index];

          for (const access of accesses) {
            if (access.type === "user") {
              acc.defaultUsers[access.user] = [
                ...(acc.defaultUsers?.[access.user] ?? []),
                objectId,
              ];
            }

            if (access.type === "group") {
              acc.defaultGroups[access.group] = [
                ...(acc.defaultGroups?.[access.group] ?? []),
                objectId,
              ];
            }
          }

          return acc;
        },
        { defaultUsers: {}, defaultGroups: {} }
      ),
    [objectsIds, objectsAccess]
  );

  const defaultPublicEntities = objectsAccess
    .flatMap((d) => d)
    .filter((access) => access.type === "open").length;

  return { defaultGroups, defaultUsers, defaultPublicEntities };
}
