import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { pdmKeys } from "./queries";

export default function useUpdatePartOwner(partId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation<Response, Error, number>(
    (userId) => api.pdm.updatePartOwner(partId, userId),
    {
      onSettled: () => queryClient.invalidateQueries(pdmKeys.all),
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.part.update-owner"),
        });
      },
    }
  );
}
