import { Part } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { activityKeys } from "../../../activities/hooks/queries";
import { pdmKeys } from "../../hooks/queries";
import { FlattenedAssemblyTree } from "./assemblyComponentsToApprove";

export default function useSavePartRevisions() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (values: { assembly: Part; components: FlattenedAssemblyTree[] }) => {
      const { assembly, components } = values;

      for (const comp of components) {
        await api.pdm.savePartRevision(comp.component.part?.id!, {
          name: comp.component.part?.lastIteration.name || "",
          description: comp.component.part?.lastIteration.description || "",
        });
      }

      await api.pdm.savePartRevision(assembly.id, {
        name: assembly?.lastIteration.name || "",
        description: assembly?.lastIteration.description || "",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.part.revision.save"),
        });
      },
    }
  );
}
