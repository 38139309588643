import React from "react";
import { ColumnOption, SelectColumns } from "../../../../components";
import { ColumnOptionConfig } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { StandardPartColumn } from "../../hooks/useStandardPartTableColumns";

export default function SelectStandardPartColumn(props: {
  columnConfig: ColumnOptionConfig<StandardPartColumn>;
  onToggleColumn: (column: StandardPartColumn) => void;
  children: React.ReactNode;
}) {
  const { columnConfig, onToggleColumn, children } = props;
  const tr = useTranslations();

  const columnOptions: ColumnOption<StandardPartColumn>[] = [
    {
      id: "name",
      label: tr.translate("generic.label.name"),
      isMandatory: true,
    },
    {
      id: "description",
      label: tr.translate("generic.label.description"),
    },
    {
      id: "createdAt",
      label: tr.translate("generic.label.creation-date.title"),
    },
    {
      id: "updatedAt",
      label: tr.translate("generic.label.last-update.title"),
    },
    {
      id: "format",
      label: tr.translate("admin.attribute.parts.format"),
    },
  ];

  return (
    <SelectColumns
      config={columnConfig}
      options={columnOptions}
      onToggleColumn={onToggleColumn}
    >
      {children}
    </SelectColumns>
  );
}
