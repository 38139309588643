import { Dialog } from "@aletiq/design-system";
import { PropertyType } from "@aletiq/types";
import React, { useState } from "react";
import { EditProperty } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { useCreateCustomProperty } from "../../hooks";
import { ResolvedPropertyOption } from "../../hooks/useResolvedPropertyOptions";
import styles from "./Dialogs.module.scss";

export default function CreateDocumentPropertyDialog(props: {
  onClose: () => void;
}) {
  const { onClose } = props;
  const tr = useTranslations();
  const { mutate: createProperty, isLoading } =
    useCreateCustomProperty("document");

  const [propertyType, setPropertyType] = useState<PropertyType>("enum");
  const [options, setOptions] = useState<ResolvedPropertyOption[]>([]);
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");

  const isEnum = propertyType === "enum" || propertyType === "multi-enum";
  const canSubmit = label !== "" && ((isEnum && options.length > 0) || !isEnum);

  const onSubmit = () => {
    const property = {
      label,
      description,
      type: propertyType,
      options: isEnum ? options.filter((o) => !o.deleted) : undefined,
    };

    createProperty(property, {
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      icon="plus"
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("document.dialog.new.property")}
      onPrimaryClick={onSubmit}
      disablePrimary={!canSubmit}
      submitting={isLoading}
    >
      <EditProperty
        propertyType={propertyType}
        label={label}
        description={description}
        options={options.filter((o) => !o.deleted)}
        canEditPropertyType
        onEditDescription={setDescription}
        onEditLabel={setLabel}
        onEditOptions={setOptions}
        onEditPropertyType={setPropertyType}
        showUseCounts={false}
        icon="document"
      />
    </Dialog>
  );
}
