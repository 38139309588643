import { Document } from "@aletiq/types";
import { useQueries } from "react-query";
import useApi from "../../../../app/useApi";
import { documentQueries } from "../../../documents/hooks/queries";

export default function useDocumentsById(documentIds: number[]) {
  const api = useApi();
  const queries = useQueries(
    documentIds.map((documentId) => documentQueries.byId(api, documentId))
  );

  const result: Record<number, Document> = {};
  documentIds.forEach((id) => {
    const document = queries.find((q) => q.data && q.data.id === id);
    if (document && document.data) {
      result[id] = document.data;
    }
  });
  return result;
}
