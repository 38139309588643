import React from "react";
import { Icon, Tag } from "../../../../components";
import { useTranslations } from "../../../../util";
import { ComponentTree } from "../../AllParts/AllPartsType";

export default function SelectedComponentAction(props: {
  component: ComponentTree;
  currentUserId: number;
}) {
  const { component, currentUserId } = props;
  const tr = useTranslations();

  const isApproved = component.iteration?.state === "approved";
  const isNotSharedWithUser = component.part && !component.part.hasAccess;
  const isLockedByOtherUser =
    component.part?.lockOwner !== null &&
    component.part?.lockOwner !== currentUserId;

  if (isApproved || component.part?.isStandard) {
    return null;
  }

  return (
    <>
      {!isApproved && !isLockedByOtherUser && !isNotSharedWithUser && (
        <Tag isDense icon="tick" intent="success" unclickable>
          {tr.translate("part.dialog.approve-asm-revision.part.action.type", {
            action: "approve",
          })}
        </Tag>
      )}
      {!isApproved && (isLockedByOtherUser || isNotSharedWithUser) && (
        <>
          <Icon inline icon="warning-sign" intent="warning" />
          <span>
            {tr.translate("part.dialog.approve-asm-revision.part.action.type", {
              action: "warning",
            })}
          </span>
        </>
      )}
    </>
  );
}
