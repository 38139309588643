import { SortDirection } from "@aletiq/types";
import sortBy from "./sortBy";

export default function sortByDateString<T>(
  values: T[],
  key: (value: T) => string,
  order?: SortDirection
) {
  return sortBy(
    values,
    key,
    (k1, k2) => Date.parse(k1) - Date.parse(k2),
    order
  );
}
