import { PartType } from "@aletiq/types";
import React from "react";
import { toggle, useTranslations } from "../../util";
import FilterMenu from "./FilterMenu";
import FilterMenuItem from "./FilterMenuItem";
import FilterTemplate from "./FilterTemplate";

export default function StandardPartTypeFilter(props: {
  selected: PartType[];
  onSelect: (values?: PartType[]) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const { selected, onClear, onSelect } = props;

  const handleSelect = (value: PartType) => () =>
    onSelect(toggle(selected, value));

  return (
    <FilterTemplate
      count={selected.length}
      onClear={onClear}
      placeholder={tr.translateAsString("part.view.filter.label")}
      content={
        <FilterMenu>
          <FilterMenuItem
            selected={selected.includes("assembly")}
            onSelect={handleSelect("assembly")}
            text={tr.translate("part.view.filter.assembly")}
          />
          <FilterMenuItem
            selected={selected.includes("part")}
            onSelect={handleSelect("part")}
            text={tr.translate("part.view.filter.part")}
          />
        </FilterMenu>
      }
    />
  );
}
