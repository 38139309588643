import { ProcessUpdateSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { processKeys } from "./queries";

export default function useUpdateProcess() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (params: { workflow: ProcessUpdateSpec; workflowId: number }) => {
      const { workflow, workflowId } = params;
      await api.task.updateProcess(workflow, workflowId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(processKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}
