import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { isNotUndefined } from "../../../util";
import { passportKeys } from "./queries";

export default function useResolvedParentPassports(
  parentPassportIds: number[]
) {
  const api = useApi();
  const passportQueryResults = useQuery(
    passportKeys.usedIn(parentPassportIds),
    async () =>
      Promise.all(
        parentPassportIds.map((passportId) =>
          api.passport.getPassport(passportId)
        )
      )
  );
  return passportQueryResults.data?.filter(isNotUndefined) || [];
}
