import React, { createContext, ReactNode, useContext, useState } from "react";
import { TextFileOverlay } from "../preview";

export const ShowTextFileContext = createContext<ShowTextFile>(() => {});

type ShowTextFile = (token: string) => void;

export function useShowTextFile() {
  return useContext(ShowTextFileContext);
}

export function ShowTextFileProvider(props: { children?: ReactNode }) {
  const { children } = props;
  const [content, setContent] = useState<string | null>(null);

  return (
    <ShowTextFileContext.Provider value={setContent}>
      {children}
      {content !== null ? (
        <TextFileOverlay content={content} onClose={() => setContent(null)} />
      ) : null}
    </ShowTextFileContext.Provider>
  );
}
