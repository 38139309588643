import {
  Classes,
  Spinner,
  SpinnerSize,
  Tab,
  TabId,
  Text,
} from "@blueprintjs/core";
import classNames from "classnames";
import React, { useState } from "react";
import {
  ANALYTICS_OPENED_PASSPORT_DETAILS,
  useTrackEvent,
} from "../../../analytics";
import {
  Activities,
  AnchorButton,
  DeleteAlert,
  Divider,
  Icon,
  PanelDetails,
  Tabs,
  Tooltip,
} from "../../../components";
import { useHasPermission, useNavigate } from "../../../hooks";
import {
  DEFAULT_PASSPORT_TAB,
  isValidTabId,
  useTranslations,
} from "../../../util";
import EntityProcesses from "../../tasks/common/EntityProcesses";
import { useDeletePassport, usePassport } from "../hooks";
import styles from "./PassportDetails.module.scss";
import PassportDetailsProperties from "./PassportDetailsProperties";
import PassportFiles from "./PassportFiles";
import PassportNomenclature from "./PassportNomenclature";

export default function PassportDetails(props: {
  passportId: number;
  onClose: () => void;
  tab?: string;
}) {
  useTrackEvent(ANALYTICS_OPENED_PASSPORT_DETAILS);

  const { passportId, tab = DEFAULT_PASSPORT_TAB, onClose } = props;
  const tr = useTranslations();
  const navigate = useNavigate();

  const [deleteAlert, setDeleteAlert] = useState(false);

  const { data: passport, isLoading } = usePassport(passportId);
  const { mutate: deletePassport, isLoading: isDeleting } =
    useDeletePassport(passportId);
  const canDeletePassports = useHasPermission("delete:passports");

  const handleSetTab = (tabId: TabId) => {
    navigate({ panel: `passportId-${passportId}`, panelTab: tabId.toString() });
  };

  const handleDeletePassport = () => {
    deletePassport(undefined, { onSuccess: onClose });
  };

  return (
    <>
      <PanelDetails
        id={passportId}
        title={
          <div className={styles.flex}>
            <Icon icon="id-number" size={20} />
            <Text ellipsize>{passport?.name}</Text>
          </div>
        }
        additionalButtons={
          canDeletePassports && !passport?.isDeleted ? (
            <Tooltip
              position="top-left"
              content={tr.translateAsString(
                "passports.details.configuration.delete.passport"
              )}
            >
              <AnchorButton
                isDense
                icon="trash"
                isLoading={isDeleting}
                onClick={() => setDeleteAlert(true)}
              />
            </Tooltip>
          ) : undefined
        }
        navigateTooltip={tr.translateAsString(
          "passport.details.open.passport-space"
        )}
        canNavigateToList={false}
        navigateToList={() => {}}
      >
        {isLoading && (
          <div className={classNames(Classes.DRAWER_BODY, styles.drawer)}>
            <Spinner size={SpinnerSize.LARGE}></Spinner>
          </div>
        )}
        {passport && passport.isDeleted && (
          <div
            className={classNames(
              Classes.DRAWER_BODY,
              styles.drawer,
              styles.deleted
            )}
          >
            {tr.translate("passport.details.deleted")}
          </div>
        )}
        {passport && !passport.isDeleted && (
          <div className={classNames(Classes.DRAWER_BODY, styles.drawer)}>
            <PassportDetailsProperties passport={passport} />
            <Divider className={styles.divider} />
            <Tabs
              selectedTabId={
                isValidTabId(tab, "passport") ? tab : DEFAULT_PASSPORT_TAB
              }
              onChange={handleSetTab}
              className={styles.tabs}
              renderActiveTabPanelOnly
              center
              isDense
            >
              <Tab
                id="activities"
                title={tr.translate("part.details.activities")}
                panel={
                  <Activities entity={{ type: "passport", id: passportId }} />
                }
              />
              <Tab
                id="files"
                title={tr.translate("part.details.files")}
                panel={<PassportFiles passportId={passportId} />}
              />
              <Tab
                id="workflows"
                title={tr.translate("part.details.workflows")}
                panel={
                  <EntityProcesses
                    entityId={{ type: "passport", id: passportId }}
                  />
                }
              />
              <Tab
                id="nomenclature"
                title={tr.translate("passport.bom")}
                panel={
                  <PassportNomenclature
                    passportId={passport.id}
                    parentProjectId={passport.parentProject}
                    parentDefinitionIdx={passport.parentDefinition}
                  />
                }
              />
            </Tabs>
          </div>
        )}
      </PanelDetails>
      {deleteAlert && (
        <DeleteAlert
          elementName={`« ${passport?.name} »`}
          onDelete={() => handleDeletePassport()}
          onClose={() => setDeleteAlert(false)}
          isLoading={isDeleting}
        />
      )}
    </>
  );
}
