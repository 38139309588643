import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { pdmKeys } from "../../../pdm/hooks/queries";

export default function useCountPartsWithDescriptions() {
  const api = useApi();

  return useQuery(pdmKeys.countWithDescription(), () =>
    api.pdm.countPartsWithDescriptions()
  );
}
