// Properties

export const ANALYTICS_NOT_APPLICABLE = "N/A";

export const ANALYTICS_MUTATION_STATUS = "Status";
export const ANALYTICS_MUTATION_ERROR = "Error";
export const ANALYTICS_MUTATION_SUCCESS = "Success";

export const ANALYTICS_USER_FIRST_NAME = "First Name";
export const ANALYTICS_USER_LAST_NAME = "Last Name";
export const ANALYTICS_USER_EMAIL = "Email";
export const ANALYTICS_USER_ROLE = "Role";
export const ANALYTICS_USER_TENANT = "Tenant";
export const ANALYTICS_USER_INSTANCE = "Instance";

export const ANALYTICS_ACCOUNT_EMAIL = "Account Email";
export const ANALYTICS_ACCOUNT_ROLE = "Account Role";
export const ANALYTICS_ACCOUNT_PREVIOUS_ROLE = "Previous Role";
export const ANALYTICS_ACCOUNT_CURRENT_ROLE = "Current Role";

export const ANALYTICS_DOCUMENTS_COUNT = "Documents Count";
export const ANALYTICS_DOCUMENTS_PREVIEW_SOURCE = "Preview Source";
export const ANALYTICS_DOCUMENTS_PREVIEW_DETAILS = "Details";
export const ANALYTICS_DOCUMENTS_PREVIEW_TABLE = "Table";
export const ANALYTICS_DOCUMENTS_PREVIEW_ARTICLE = "Article";

export const ANALYTICS_PARTS_COUNT = "Parts Count";
export const ANALYTICS_PARTS_STANDARD_COUNT = "Standard Parts Count";

export const ANALYTICS_PRODUCT_COUNT = "Product Count";
export const ANALYTICS_PRODUCT_TYPE = "Product Type";

export const ANALYTICS_WORKFLOW_MODEL_NAME = "Model Name";
export const ANALYTICS_WORKFLOW_MODEL_ID = "Model ID";

// Events

export const ANALYTICS_ACCOUNT_CREATED = "Created Account";
export const ANALYTICS_ACCOUNT_UPDATED = "Updated Account";
export const ANALYTICS_ACCOUNT_DESACTIVATED = "Desactivated Account";

export const ANALYTICS_DOCUMENTS_CREATED = "Created Documents";
export const ANALYTICS_DOCUMENTS_UPDATED = "Updated Documents";
export const ANALYTICS_DOCUMENTS_IGNORED = "Ignored Documents";
export const ANALYTICS_DOCUMENTS_SHOWN = "Shown Documents";
export const ANALYTICS_DOCUMENTS_SIGNED = "Signed Documents";

export const ANALYTICS_PARTS_CREATED = "Created parts";
export const ANALYTICS_PARTS_UPDATED = "Updated parts";
export const ANALYTICS_PARTS_IGNORED = "Ignored parts";
export const ANALYTICS_PARTS_SHOWN = "Shown part";

export const ANALYTICS_PRODUCTS_CREATED = "Created products";

export const ANALYTICS_WORKFLOW_CREATED = "Created Workflow";
export const ANALYTICS_WORKFLOW_COMPLETED = "Completed Workflow";
export const ANALYTICS_WORKFLOW_ARCHIVED = "Archived Workflow";

export const ANALYTICS_TASK_COMPLETED = "Completed Task";

export const ANALYTICS_OPENED_DOCUMENT_DETAILS = "Opened Document Details";
export const ANALYTICS_OPENED_WORKFLOW_DETAILS = "Opened Workflow Details";
export const ANALYTICS_OPENED_PASSPORT_DETAILS = "Opened Passport Details";
export const ANALYTICS_OPENED_PART_DETAILS = "Opened Part Details";

export const ANALYTICS_VISITED_HELP_CENTER = "Visited Help Center";

export const ANALYTICS_VISITED_HOME = "Visited Home Page";
export const ANALYTICS_VISITED_USERS = "Visited Users Page";
export const ANALYTICS_VISITED_DOCUMENTS = "Visited Documents Page";
export const ANALYTICS_VISITED_PASSPORTS = "Visited Passports Page";
export const ANALYTICS_VISITED_PARTS = "Visited Parts Page";
export const ANALYTICS_VISITED_STANDARD_PARTS = "Visited Standard Parts Page";
export const ANALYTICS_VISITED_COPY_PRODUCT = "Visited Copy Product Page";
export const ANALYTICS_VISITED_PRODUCTS = "Visited Product Page";
export const ANALYTICS_VISITED_EXPORTS = "Visited Exports Page";
export const ANALYTICS_VISITED_WORKFLOWS = "Visited Workflows Page";
export const ANALYTICS_VISITED_ATTRIBUTES =
  "Visited Attribute Administration Page";
export const ANALYTICS_VISITED_INTEGRATION_REPORTS =
  "Visited Integration Reports Page";
export const ANALYTICS_VISITED_NOTIFICATION_SETTINGS =
  "Visited Notification Settings Page";
export const ANALYTICS_VISITED_PRODUCT_WORKFLOWS =
  "Visited Product Workflows Page";
export const ANALYTICS_VISITED_PRODUCT_OPERATION_DETAILS =
  "Visited Product Operation Details Page";
export const ANALYTICS_VISITED_PRODUCT_OPERATION_BILL =
  "Visited Product Operation Bill Page";
export const ANALYTICS_VISITED_PRODUCT_ARTICLES =
  "Visited Product Articles Page";
export const ANALYTICS_VISITED_PRODUCT_CONFIG = "Visited Product Config Page";
export const ANALYTICS_VISITED_PRODUCT_DOCUMENTS =
  "Visited Product Documents Page";
export const ANALYTICS_VISITED_PRODUCT_HOME = "Visited Product Home Page";
export const ANALYTICS_VISITED_PRODUCT_NOMENCLATURE =
  "Visited Product Nomenclature Page";
export const ANALYTICS_VISITED_PRODUCT_PARTS = "Visited Product Parts Page";
export const ANALYTICS_VISITED_PRODUCT_PASSPORTS =
  "Visited Product Passports Page";
export const ANALYTICS_VISITED_PRODUCT_TOOLING = "Visited Product Tooling Page";
export const ANALYTICS_VISITED_PRODUCT_VERSIONS =
  "Visited Product Versions Page";
export const ANALYTICS_VISITED_PRODUCT_NEW_VERSION =
  "Visited Product New Version Page";
export const ANALYTICS_VISITED_PRODUCT_VERSION_DETAILS =
  "Visited Product Version Details Page";
export const ANALYTICS_VISITED_SEARCH = "Visited Search Page";
export const ANALYTICS_VISITED_ARCHIVED_WORKFLOWS =
  "Visited Archived Workflows Page";
export const ANALYTICS_VISITED_WORKFLOW_ANALYSIS =
  "Visited Workflow Analysis Page";
export const ANALYTICS_VISITED_TASKS = "Visited Task Page";
export const ANALYTICS_VISITED_WORKFLOW_MODELS = "Visited Workflow Models Page";

export const ANALYTICS_OPENED_WORKFLOW_CREATION =
  "Opened Workflow Creation Dialog";

export function makeAnalyticsMutationStatus(error: unknown) {
  return error ? ANALYTICS_MUTATION_ERROR : ANALYTICS_MUTATION_SUCCESS;
}

export function makeAnalyticsOptional<T>(value?: T | undefined) {
  return value === undefined ? ANALYTICS_NOT_APPLICABLE : value;
}
