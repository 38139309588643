import { Export } from "@aletiq/types";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ANALYTICS_VISITED_EXPORTS, useTrackEvent } from "../../../analytics";
import useApi from "../../../app/useApi";
import {
  ActionBar,
  AlertPopover,
  AlignRight,
  Button,
  Card,
  HighlightedText,
  PageContents,
  PageTitle,
  SearchInput,
  Tooltip,
} from "../../../components";
import { useDownloadQueue } from "../../../hooks";
import { humanFileSize, isInString, useTranslations } from "../../../util";
import { AdministrationTabs } from "../common";
import { exportKeys } from "../hooks/queries";
import DateRange from "./DateRange";
import ExportDialog from "./ExportDialog";
import styles from "./Exports.module.scss";

type DialogType = "activate-exports" | "info";

export default function Exports() {
  const api = useApi();
  const tr = useTranslations();
  const queryClient = useQueryClient();
  const { handleDownload } = useDownloadQueue();
  useTrackEvent(ANALYTICS_VISITED_EXPORTS);

  const [dialog, setDialog] = useState<DialogType>();
  const [search, setSearch] = useState("");

  const { data: exports = [], isLoading } = useQuery<Export[]>(
    exportKeys.all,
    () => api.listExports(),
    { refetchInterval: 1000 }
  );

  const filteredExports = useMemo(
    () => exports.filter((e) => isInString(e.order.toString(), search)),
    [exports, search]
  );

  const { data: schedule } = useQuery<string | null>(
    exportKeys.schedule(),
    () => api.getExportSchedule(),
    { refetchInterval: 1000 }
  );

  const { mutate: setExportsEnabled, isLoading: mutating } = useMutation(
    (enabled: boolean) => api.http.put(`/exports/schedule?enabled=${enabled}`),
    { onSuccess: () => queryClient.invalidateQueries(exportKeys.all) }
  );
  const enableExports = useCallback(
    () => setExportsEnabled(true),
    [setExportsEnabled]
  );
  const disableExports = useCallback(
    () => setExportsEnabled(false),
    [setExportsEnabled]
  );

  const { mutate: onDownload, isLoading: downloadPending } = useMutation(
    async (params: { exportId: number; fileId: number }) => {
      const token = await api.getExportFileDownloadToken(
        params.exportId,
        params.fileId
      );
      handleDownload({
        fetchToken: () => Promise.resolve(token),
        forceDownload: true,
      });
    }
  );

  const handleOpenDialog = (dialog: DialogType) => () => setDialog(dialog);

  const handleCloseDialog = () => setDialog(undefined);

  return (
    <PageContents leftPanel={<AdministrationTabs />}>
      {dialog && (
        <ExportDialog
          type={dialog}
          onClose={handleCloseDialog}
          onSubmit={enableExports}
          previousExport={exports[0]?.requestTime ?? undefined}
        />
      )}
      <PageTitle icon="export">
        {tr.translate("admin.exports.path.title")}
      </PageTitle>
      <div className={styles.contents}>
        <ActionBar>
          <Tooltip
            position="top-left"
            content={tr.translate("admin.exports.search")}
          >
            <SearchInput isDense value={search} onChange={setSearch} />
          </Tooltip>
          <AlignRight />
          <Button
            isDense
            intent="secondary"
            icon="info-sign"
            onClick={handleOpenDialog("info")}
          />
          {schedule && (
            <AlertPopover
              isDense
              icon="disable"
              title={tr.translate("admin.exports.dialog.disable")}
              content={tr.translate("admin.exports.dialog.disable.message")}
              onPrimaryClick={disableExports}
            >
              <Button
                isDense
                intent="secondary"
                icon="disable"
                isLoading={mutating}
              >
                {tr.translate("admin.exports.dialog.disable")}
              </Button>
            </AlertPopover>
          )}
          {schedule === null && (
            <Button
              isDense
              intent="secondary"
              onClick={handleOpenDialog("activate-exports")}
              isLoading={mutating}
            >
              {tr.translate("admin.exports.dialog.enable")}
            </Button>
          )}
        </ActionBar>

        {isLoading ? (
          <Spinner />
        ) : (
          filteredExports.map((exportItem) => (
            <Card
              key={exportItem.id}
              icon="export"
              className={styles.export_card}
              title={tr.translate("admin.exports.export-name", {
                id: (
                  <HighlightedText
                    text={exportItem.order.toString()}
                    highlight={search}
                  />
                ),
              })}
            >
              <div className={styles.export_info}>
                <DateRange
                  from={exportItem.fromTime ?? undefined}
                  to={exportItem.startTime ?? exportItem.requestTime}
                />
                <span className={styles.expiration_date}>
                  {exportItem.startTime &&
                    tr.translate("admin.exports.export-expiration", {
                      date: exportExpirationDate(exportItem),
                    })}
                </span>
              </div>

              {exportItem.startTime && !exportItem.availableTime && (
                <div className={styles.pending_message}>
                  <Spinner size={SpinnerSize.SMALL} />
                  {tr.translateAsString("admin.exports.export-pending")}
                </div>
              )}

              {exportItem.files.length > 0 && (
                <div className={styles.archive_file_list}>
                  {exportItem.files.map((file) => (
                    <div key={file.id} className={styles.archive_file}>
                      {file.name}
                      <div className={styles.file_size}>
                        {humanFileSize(file.size)}
                      </div>
                      <AlignRight />
                      <Button
                        isDense
                        isLoading={downloadPending}
                        icon="download"
                        onClick={() =>
                          onDownload({
                            exportId: exportItem.id,
                            fileId: file.id,
                          })
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </Card>
          ))
        )}
      </div>
    </PageContents>
  );
}

function exportExpirationDate(exportItem: Export) {
  if (!exportItem.startTime) return undefined;

  const start = new Date(exportItem.startTime);
  const expiration = new Date(start);
  expiration.setUTCMonth(start.getUTCMonth() + 6);
  return expiration;
}
