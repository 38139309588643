import { Part } from "@aletiq/types";
import React from "react";
import { Dialog } from "../../../../components";
import { useCurrentUser } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useUnlockParts } from "../../hooks";
import styles from "./styles.module.scss";

export default function UnlocksPartDialog(props: {
  parts: Part[];
  onClose: () => void;
}) {
  const { parts, onClose } = props;
  const tr = useTranslations();

  const { data: user } = useCurrentUser();
  const { mutate: unlockParts, isLoading } = useUnlockParts();

  const partsToFree = parts
    .filter((part) => part.lockOwner === user?.id)
    .map((part) => part.id);

  const handleConfirm = () => {
    unlockParts(partsToFree, {
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      title={tr.translateAsString("part.dialog.unlock.parts.title", {
        nbr: partsToFree.length,
      })}
      className={styles.dialog}
      icon="unlock"
      onClose={onClose}
      onPrimaryClick={handleConfirm}
      onSecondaryClick={onClose}
      submitting={isLoading}
    >
      {tr.translate("part.dialog.unlock.parts.subtitle")}
    </Dialog>
  );
}
