import {
  Part,
  PartQueryFilterParams,
  PartQueryOrder,
  PartType,
} from "@aletiq/types";
import React, { useCallback, useEffect, useState } from "react";
import { ANALYTICS_PARTS_SHOWN, useAnalytics } from "../../../../analytics";
import { Table } from "../../../../components";
import {
  useColumnConfig,
  useDelayed,
  useFilter,
  useHasPermission,
  usePagination,
  useSortKey,
} from "../../../../hooks";
import useDragNDropFiles from "../../../../hooks/useDragNDropFiles";
import {
  ExpandTree,
  toggleExpandAt,
  TreePath,
  useTranslations,
} from "../../../../util";
import { EditPartBomDialog } from "../../../project/dialogs";
import {
  LinkPartDialog,
  PartCreationDialog,
  PartIterationViewer,
  UploadPartsWizard,
} from "../../dialogs";
import {
  usePaginatedParts,
  usePartRowOptions,
  usePartTableColumns,
} from "../../hooks";
import { defaultPartColumnConfig } from "../../hooks/usePartTableColumns";
import PartsActionBar from "../../PartsActionBar";
import usePartTree from "../usePartTree";
import styles from "./AllPartsListPaginated.module.scss";

type AllPartListPaginatedDialog =
  | { type: "upload"; files: File[] }
  | { type: "link"; projectId: number }
  | {
      type: "create";
      partType: PartType;
      projectId?: number;
      isStandard: boolean;
    }
  | {
      type: "view";
      part: Part;
    }
  | {
      type: "edit-bom";
      partId: number;
    };

export default function AllPartsListPaginated(props: {
  projectId?: number;
  allowStandard?: boolean;
}) {
  const { projectId, allowStandard } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const [dialog, setDialog] = useState<AllPartListPaginatedDialog>();

  const [expandTrees, setExpandTrees] = useState<ExpandTree[]>([]);
  const { sortState, setSortKey } = useSortKey<PartQueryOrder>(
    "updatedAt",
    "desc"
  );
  const { filter, handleFilter, handleClear } =
    useFilter<PartQueryFilterParams>({
      search: "",
      projects: projectId ? [projectId] : undefined,
      standard: allowStandard ? undefined : false,
    });

  const { config: columnConfig, handleToggleColumn } = useColumnConfig(
    defaultPartColumnConfig
  );

  const canCreateParts = useHasPermission("create:parts");
  const canUpdateParts = useHasPermission("update:parts");
  const canCreateWorkflows = useHasPermission("create:workflows");
  const pagination = usePagination();

  const { limit, selectedPage, resetSelectedPage } = pagination;

  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, resetSelectedPage]);

  const canSelectRows = canUpdateParts || canCreateWorkflows;

  const {
    data,
    isFetching: partsFetching,
    isLoading: partsLoading,
  } = usePaginatedParts({
    ...filter,
    offset: selectedPage * limit,
    limit,
    order: sortState.key,
    orderDirection: sortState.direction,
  });

  const isFetching = useDelayed(partsFetching);
  const parts = data?.list ?? [];
  const visibleParts = usePartTree(expandTrees, parts);

  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  const handleOpenPartViewer = useCallback(
    (part: Part) => {
      setDialog({ type: "view", part });
      analytics.track(ANALYTICS_PARTS_SHOWN);
    },
    [analytics]
  );

  const handleOpenLinkDialog = (projectId: number) => {
    setDialog({
      type: "link",
      projectId,
    });
  };

  const handleOpenCreateDialog = (type: PartType, isStandard: boolean) => {
    setDialog({
      type: "create",
      partType: type,
      projectId,
      isStandard,
    });
  };

  const { onDrop, onDragOver } = useDragNDropFiles((files) => {
    setDialog({
      type: "upload",
      files,
    });
  }, canCreateParts);

  const handleToggleExpand = (path: TreePath[]) => (expanded: boolean) =>
    setExpandTrees((t) => toggleExpandAt(t, path, expanded));

  const handleEditBom = (partId: number) => () => {
    setDialog({
      type: "edit-bom",
      partId,
    });
  };

  const columns = usePartTableColumns(
    handleToggleExpand,
    handleEditBom,
    handleOpenPartViewer,
    columnConfig
  );

  const { onOpen, renderBatchActionsHeader, isRowUnselectable, isRowActive } =
    usePartRowOptions(projectId);
  return (
    <div
      onDragOver={onDragOver}
      onDrop={onDrop}
      className={styles.table_wrapper}
    >
      <PartsActionBar
        filter={filter}
        handleFilter={handleFilter}
        handleClear={handleClear}
        withProjectFilter={!projectId}
        projectId={projectId}
        allowStandard={allowStandard}
        onCreatePart={handleOpenCreateDialog}
        onLinkPart={handleOpenLinkDialog}
        columnConfig={columnConfig}
        onToggleColumn={handleToggleColumn}
      />

      <Table
        columns={columns}
        data={visibleParts}
        noData={tr.translate(
          !filter.search || filter.search === ""
            ? "part.view.no-data"
            : "part.view.no-data.filtered"
        )}
        searchString={filter.search}
        paginationOptions={{
          itemCount: data?.count ?? 0,
          limit,
          selectedPage,
          onSetLimit: pagination.handleLimit,
          onSetPage: pagination.handleSelectPage,
        }}
        sortOptions={{ sortState, onSort: setSortKey }}
        renderBatchActionsHeader={
          canSelectRows ? renderBatchActionsHeader : undefined
        }
        openRowOptions={{ onOpen, rowCannotBeOpened: isRowUnselectable }}
        isRowUnselectable={isRowUnselectable}
        isRowActive={isRowActive}
        isFetching={isFetching || partsLoading}
      />

      {dialog?.type === "link" && (
        <LinkPartDialog
          projectId={dialog.projectId}
          onClose={handleCloseDialog}
        />
      )}

      {dialog?.type === "create" && (
        <PartCreationDialog
          projects={dialog.projectId ? [dialog.projectId] : []}
          type={dialog.partType}
          onClose={handleCloseDialog}
          isStandard={dialog.isStandard}
        />
      )}

      {dialog?.type === "upload" && (
        <UploadPartsWizard
          files={dialog.files}
          onClose={handleCloseDialog}
          projectId={projectId}
        />
      )}

      {dialog?.type === "view" && (
        <PartIterationViewer
          part={dialog.part}
          iteration={dialog.part.lastIteration.number}
          onClose={handleCloseDialog}
        />
      )}

      {dialog?.type === "edit-bom" && (
        <EditPartBomDialog partId={dialog.partId} onClose={handleCloseDialog} />
      )}
    </div>
  );
}
