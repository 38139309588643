import "@aletiq/design-system/index.css";
import React from "react";
import AppProviders from "./AppProviders";
import AppRouter from "./AppRouter";
import { ErrorBoundary } from "./ErrrorBoundary";
import LoginBoundary from "./LoginBoundary";

export default function App() {
  return (
    <AppProviders>
      <ErrorBoundary>
        <LoginBoundary>
          <AppRouter />
        </LoginBoundary>
      </ErrorBoundary>
    </AppProviders>
  );
}
