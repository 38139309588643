import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useDeletePassportComponent(passportId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    (componentId: number) =>
      api.passport.deletePassportComponent(passportId, componentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(passportKeys.allComponents(passportId));
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}
