import React from "react";
import { Button, Icon } from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./PartRow.module.scss";

export default function IncompleteBomRow(props: { onClick: () => void }) {
  const { onClick } = props;
  const tr = useTranslations();

  return (
    <>
      <Icon intent="warning" icon="warning-sign" inline />
      {tr.translate("part.details.incomplete.bom")}
      <Button
        isDense
        className={styles.edit_bom}
        onClick={onClick}
        icon="diagram-tree"
        intent="array_primary"
      >
        {tr.translate("part.details.modify.bom")}
      </Button>
    </>
  );
}
