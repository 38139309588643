import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { navigateFrom, PageTarget } from "../../util";

export default function useNavigate() {
  const history = useHistory();
  return useCallback(
    (path: PageTarget) => {
      navigateFrom(history, path);
    },
    [history]
  );
}
