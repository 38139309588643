import React, { useState } from "react";
import { Button } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useProjectsById } from "../../../project/hooks";
import { LinkProductsDialog } from "../../dialogs";
import { useDocumentProjects, useUpdateDocumentProjects } from "../../hooks";
import styles from "./LinkedProducts.module.scss";
import ProductListItem from "./ProductListItem";

export default function LinkedProducts(props: {
  documentId: number;
  canEdit?: boolean;
}) {
  const { documentId, canEdit } = props;
  const tr = useTranslations();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const { data: documentProjectsIds = [] } = useDocumentProjects(documentId);
  const { mutate: updateDocumentProject } =
    useUpdateDocumentProjects(documentId);

  const documentProjects = useProjectsById(documentProjectsIds);

  const handleUnlinkProduct = (productId: number) => {
    const updated = documentProjectsIds.filter((p) => p !== productId);
    updateDocumentProject(updated);
  };

  return (
    <React.Fragment>
      <Button
        icon="new-link"
        intent="secondary"
        className={styles.link_product_button}
        onClick={() => setDialogOpen(true)}
        isDisabled={!canEdit}
        isDense
      >
        {tr.translate("document.details.products.new.button")}
      </Button>
      <div>
        {documentProjects.map((project) => (
          <ProductListItem
            key={project.id}
            product={project}
            canEdit={canEdit}
            documentId={documentId}
            onDelete={() => handleUnlinkProduct(project.id)}
          />
        ))}
      </div>
      {isDialogOpen && (
        <LinkProductsDialog
          currentProducts={documentProjectsIds}
          onClose={() => setDialogOpen(false)}
          documentIds={[documentId]}
        />
      )}
    </React.Fragment>
  );
}
