import { DocumentQueryFilterParams, DocumentQueryOrder } from "@aletiq/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActionBar,
  DefaultFilters,
  Dialog,
  Table,
} from "../../../../components";
import {
  useDelayed,
  useFilter,
  usePagination,
  useSortKey,
} from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { ResolvedDocument } from "../../Documents/documentTypes";
import {
  useAddProductDocuments,
  useDefaultDocumentColumnConfig,
  useDocuments,
  useDocumentTableColumns,
  useResolvedDocuments,
} from "../../hooks";
import styles from "./LinkDocumentDialog.module.scss";

export default function LinkDocumentDialog(props: {
  productId: number;
  onClose: () => void;
  productDocs: ResolvedDocument[];
}) {
  const { productId, onClose, productDocs } = props;
  const tr = useTranslations();

  const [selectedDocs, setSelectedDocs] = useState<number[]>([]);
  const { sortState, setSortKey: onSort } =
    useSortKey<DocumentQueryOrder>("name");
  const { filter, handleFilter } = useFilter<DocumentQueryFilterParams>({
    search: "",
  });

  const pagination = usePagination();

  const { resetSelectedPage } = pagination;

  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, resetSelectedPage]);

  const {
    data: allDocuments,
    isLoading: documentsLoading,
    isFetching: documentsFetching,
  } = useDocuments({
    ...filter,
    order: sortState.key,
    orderDirection: sortState.direction,
    limit: pagination.limit,
    offset: pagination.selectedPage * pagination.limit,
  });

  const { documents, isLoading: documentsResolving } = useResolvedDocuments(
    allDocuments?.list
  );

  const { mutate: linkDocumentsToProduct, isLoading: isLinkingDocuments } =
    useAddProductDocuments(productId);

  const isLoading = documentsLoading || documentsResolving;
  const isFetching = useDelayed(documentsFetching);

  const availableDocs = useMemo(() => {
    const productDocIds = productDocs.map((d) => d.id);
    return documents.filter((doc) => !productDocIds.includes(doc.id));
  }, [documents, productDocs]);

  const handleSelect = useCallback(
    (docs: ResolvedDocument[]) => setSelectedDocs(docs.map((d) => d.id)),
    []
  );

  const handleSubmit = async () => {
    linkDocumentsToProduct(selectedDocs, { onSuccess: onClose });
  };

  const { config } = useDefaultDocumentColumnConfig();
  const columns = useDocumentTableColumns(config);

  return (
    <Dialog
      isOpen
      onClose={onClose}
      icon="new-link"
      title={tr.translateAsString("product.documents.link.title")}
      onPrimaryClick={handleSubmit}
      submitting={isLinkingDocuments}
      className={styles.dialog}
      enforceFocus={false}
    >
      <p className={styles.subtitle}>
        {tr.translate("product.documents.link.subtitle")}
      </p>
      <ActionBar>
        <DefaultFilters
          objectType="document"
          filters={filter}
          handleFilter={handleFilter}
          autoFocusSearch
        />
      </ActionBar>
      <Table
        className={styles.table}
        columns={columns}
        paginationOptions={{
          itemCount: allDocuments?.count ?? 0,
          limit: pagination.limit,
          selectedPage: pagination.selectedPage,
          onSetLimit: pagination.handleLimit,
          onSetPage: pagination.handleSelectPage,
        }}
        data={availableDocs}
        searchString={filter.search}
        onSelect={handleSelect}
        sortOptions={{
          sortState,
          onSort,
        }}
        isFetching={isFetching || isLoading}
      />
    </Dialog>
  );
}
