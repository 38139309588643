import {
  Part,
  Project,
  ProjectType,
  PropertyValue,
  PropertyValueUpdate,
} from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_MUTATION_STATUS,
  ANALYTICS_PRODUCTS_CREATED,
  ANALYTICS_PRODUCT_COUNT,
  ANALYTICS_PRODUCT_TYPE,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../analytics";
import {
  Callout,
  Dialog,
  EditCustomPropertyField,
  Field,
  Input,
} from "../../../components";
import PartLink from "../../../components/link/PartLink";
import { useNavigate } from "../../../hooks";
import { useTranslations } from "../../../util";
import { useProperties } from "../../administration/Attributes/hooks";
import { useCreateProduct } from "../hooks";
import styles from "./Dialog.module.scss";

export function ProjectCreationDialog(props: {
  onClose: () => void;
  onSuccess?: (project: Project) => void;
  type: ProjectType;
  part?: Part;
}) {
  const { onClose, onSuccess, type, part } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const [name, setName] = useState(part?.name || "");
  const [definition, setDefinition] = useState("");
  const [properties, setProperties] = useState<PropertyValueUpdate[]>([]);

  const hasInvalidName = name === "";
  const hasInvalidDefinition = definition === "";

  const { mutate: submitProduct, isLoading: submitting } =
    useCreateProduct(part);

  const { data: productProperties = [] } = useProperties("product");
  const visibleProperties = productProperties.filter(
    (p) => (p.group === "all" || p.group === type) && p.displayByDefault
  );

  const handleSetPropertyValue = (
    propertyName: string,
    value: PropertyValue
  ) => {
    if (properties.some((p) => p.property === propertyName)) {
      setProperties(
        properties.map((p) =>
          p.property === propertyName ? { ...p, value } : p
        )
      );
      return;
    }
    setProperties([...properties, { property: propertyName, value }]);
  };

  const handleSubmit = () => {
    const spec = {
      name,
      isTool: type === "tool",
      isArticle: type === "article",
      properties,
      description: null,
      definition: {
        name: definition,
      },
    };
    submitProduct(spec, {
      onSuccess: (project) => {
        onSuccess && onSuccess(project);
        onClose();
        if (!part) {
          navigate({ path: `/project/${project.id}` });
        }
      },
      onSettled: (_, error) => {
        analytics.track(ANALYTICS_PRODUCTS_CREATED, {
          [ANALYTICS_PRODUCT_COUNT]: 1,
          [ANALYTICS_PRODUCT_TYPE]: type,
          [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
        });
      },
    });
  };

  return (
    <Dialog
      icon={type === "tool" ? "wrench" : "projects"}
      title={tr.translateAsString("project.actions.create", { type })}
      isOpen
      onClose={onClose}
      submitting={submitting}
      disablePrimary={hasInvalidName || hasInvalidDefinition}
      onPrimaryClick={handleSubmit}
      style={{ width: "600px" }}
    >
      <div className={styles.dialog_contents}>
        <Field isMandatory label={tr.translateAsString("generic.label.name")}>
          <Input
            autoFocus
            placeholder={tr.translateAsString("generic.label.name")}
            value={name}
            fill
            onChange={setName}
          />
        </Field>
        <Field
          isMandatory
          label={tr.translate("project.definition.name.field-label")}
        >
          <Input
            fill
            removeClearButton
            placeholder={tr.translateAsString("generic.label.definition")}
            onChange={setDefinition}
            value={definition}
            intent="primary"
            autoFocus={false}
          />
        </Field>

        {visibleProperties.map((property) => (
          <Field key={property.id} label={property.label}>
            <EditCustomPropertyField
              isDense={false}
              property={property}
              propertyValue={
                properties.find((p) => p.property === property.name)?.value ??
                null
              }
              onEditValue={(value) =>
                handleSetPropertyValue(property.name, value)
              }
            />
          </Field>
        ))}

        {part && (
          <Callout
            icon="new-link"
            title={tr.translateAsString("project.actions.create.link.title", {
              type,
            })}
          >
            {tr.translate("project.actions.create.link.description", {
              type,
              part: <PartLink hasIcon part={part} />,
            })}
          </Callout>
        )}
      </div>
    </Dialog>
  );
}
