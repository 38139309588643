import { Part } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../util";
import NameLink from "./NameLink";
import partLinkProps from "./props/partLinkProps";

export default function PartLink(props: {
  part: Part | undefined;
  hasIcon?: boolean;
  className?: string;
  search?: string;
}) {
  const { className, part, hasIcon, search } = props;
  const tr = useTranslations();

  const linkProps = partLinkProps(tr, part);

  return (
    <NameLink
      className={className}
      hasIcon={hasIcon}
      search={search}
      {...linkProps}
    />
  );
}
