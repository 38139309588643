import { AletiqPropertyDisplay, PropertyObject } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { propertyKeys } from "./queries";

export default function useSetAletiqPropertyDefaultDisplay(
  objectType: PropertyObject
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    (displayInfo: AletiqPropertyDisplay) =>
      api.property.setAletiqPropertyDisplayByDefault(
        objectType,
        displayInfo.name,
        displayInfo.displayByDefault
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(propertyKeys.defaultDisplay(objectType)),
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
