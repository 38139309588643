import { Project } from "@aletiq/types";
import { useMemo } from "react";
import { useQueries } from "react-query";
import useApi from "../../../app/useApi";
import { isNotUndefined } from "../../../util";
import { projectQueries } from "./queries";

export default function useProjectById(projects: number[]): Project[] {
  const api = useApi();
  const projectsQueries = useQueries(
    projects.map((projectId) => projectQueries.byId(api, projectId))
  );

  return useMemo(
    () => projectsQueries.flatMap((res) => res.data).filter(isNotUndefined),
    [projectsQueries]
  );
}
