import {
  Project,
  ProjectQueryFilterParams,
  ProjectQueryOrder,
} from "@aletiq/types";
import React, { useCallback, useEffect, useState } from "react";
import { ActionBar, DefaultFilters, Dialog, Table } from "../../../components";
import { useFilter, usePagination, useSortKey } from "../../../hooks";
import { useTranslations } from "../../../util";
import { MoreProductFilters } from "../common";
import {
  useAddProjectComponents,
  useAvailableProjectComponents,
  usePaginatedProjects,
} from "../hooks";
import { ResolvedBOMEntry } from "../ProjectNomenclature/hooks/useProjectExpandableTree";
import styles from "./Dialog.module.scss";
import { useBomComponentsDialogColumns } from "./useBomComponentsDialogColumns";

export default function AddNomenclatureComponentsDialog(props: {
  projectId: number;
  definitionIdx: number;
  projectComponents: ResolvedBOMEntry[];
  onClose: () => void;
}) {
  const { projectId, definitionIdx, projectComponents, onClose } = props;
  const tr = useTranslations();

  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const resetSelectedProjects = useCallback(() => setSelectedProjects([]), []);

  const { filter, handleFilter, handleClear } =
    useFilter<ProjectQueryFilterParams>({
      search: "",
    });

  const { sortState, setSortKey: onSort } =
    useSortKey<ProjectQueryOrder>("name");
  const {
    limit,
    selectedPage,
    handleLimit,
    handleSelectPage,
    resetSelectedPage,
  } = usePagination({
    onChange: resetSelectedProjects,
  });

  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, resetSelectedPage]);

  const projectQueryParams = {
    ...filter,
    order: sortState.key,
    orderDirection: sortState.direction,
    limit,
    offset: selectedPage * limit,
  };

  const { data: projectResponse } = usePaginatedProjects(projectQueryParams);
  const { mutate: addComponents, isLoading: isLoadingProjects } =
    useAddProjectComponents(projectId, definitionIdx);

  const currentComponents = projectComponents.map((entry) => entry.entity);
  const { data: availableComponentProjects, isLoading } =
    useAvailableProjectComponents(
      projectId,
      currentComponents,
      projectQueryParams
    );
  const availableComponents = availableComponentProjects.map((p) => p.id);

  const handleSelect = useCallback(
    (project: Project) => {
      if (selectedProjects.find((p) => project.id === p.id)) {
        setSelectedProjects(
          selectedProjects.filter((p) => p.id !== project.id)
        );
      } else {
        setSelectedProjects([...selectedProjects, project]);
      }
    },
    [selectedProjects]
  );

  const handleSelectAll = useCallback(() => {
    if (
      selectedProjects.length === 0 ||
      selectedProjects.length !== availableComponentProjects.length
    ) {
      setSelectedProjects(availableComponentProjects);
    } else {
      setSelectedProjects([]);
    }
  }, [selectedProjects.length, availableComponentProjects]);

  const columns = useBomComponentsDialogColumns(
    selectedProjects,
    availableComponents,
    handleSelect,
    handleSelectAll
  );

  const handleSubmit = () => {
    addComponents(
      selectedProjects.map((product) => ({
        entity: product.id,
        quantity: 1,
        definition: product.lastDefinition.index,
      })),
      { onSettled: onClose }
    );
  };

  return (
    <Dialog
      isOpen
      icon="new-link"
      title={tr.translateAsString("project.dialog.components.add.title")}
      disablePrimary={selectedProjects.length === 0}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
      onClose={onClose}
      className={styles.dialog_with_filters}
    >
      <div className={styles.dialog_contents}>
        <p>{tr.translate("project.dialog.components.add.description")}</p>
        <ActionBar>
          <DefaultFilters
            objectType="product"
            filters={filter}
            handleFilter={handleFilter}
          />
          <MoreProductFilters
            filters={filter}
            handleFilter={handleFilter}
            handleClear={handleClear}
          />
        </ActionBar>
        <Table
          data={projectResponse?.list ?? []}
          className={styles.table}
          columns={columns}
          searchString={filter.search}
          sortOptions={{ sortState, onSort }}
          paginationOptions={{
            itemCount: projectResponse?.count ?? 0,
            limit,
            selectedPage,
            onSetLimit: handleLimit,
            onSetPage: handleSelectPage,
          }}
          isFetching={isLoading || isLoadingProjects}
        />
      </div>
    </Dialog>
  );
}
