// Local page state
export type PageState = {
  dialog?: { type: "upload-file" | "link-document" };
};

export const showCreateDocLink =
  () =>
  (state: PageState): PageState => ({
    ...state,
    dialog: { type: "link-document" },
  });

export const showUploadFile =
  () =>
  (state: PageState): PageState => ({
    ...state,
    dialog: { type: "upload-file" },
  });

export const close =
  () =>
  (state: PageState): PageState => ({
    ...state,
    dialog: undefined,
  });
