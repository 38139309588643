import React, { useState } from "react";
import { Dialog, Field, FileInput, Select } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useAddPassportFiles, usePassportFileTypes } from "../../hooks";
import styles from "./UploadPassportFileDialog.module.scss";

export default function UploadPassportFileDialog(props: {
  onClose: () => void;
  passportsIds: number[];
}) {
  const { onClose, passportsIds } = props;
  const tr = useTranslations();

  const [files, setFiles] = useState<File[]>([]);
  const [selectedType, setSelectedType] = useState<number>();

  const { data: fileTypes = [] } = usePassportFileTypes();
  const { mutate: addPassportFiles, isLoading: isUploadingFiles } =
    useAddPassportFiles(passportsIds);

  const handleSubmit = () => {
    files &&
      addPassportFiles(
        { files, fileType: selectedType },
        { onSettled: onClose }
      );
  };

  const handleFileInput = (ev: React.FormEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const files = ev.currentTarget.files;
    files && setFiles(Array.from(files));
  };

  const handleSetType = (type: number) => {
    if (type === -1) {
      setSelectedType(undefined);
    } else {
      setSelectedType(type);
    }
  };

  const fileTypeItems = [
    {
      key: -1,
      text: tr.translateAsString("generic.label.none"),
    },
    ...fileTypes.map((type) => ({
      key: type.id,
      text: type.value,
    })),
  ];

  const type = fileTypeItems.find((type) => type.key === selectedType);

  return (
    <Dialog
      title={tr.translateAsString("passport.dialog-upload.file.title")}
      icon="upload"
      className={styles.dialog}
      onClose={onClose}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={onClose}
      isOpen
      submitting={isUploadingFiles}
    >
      <p className={styles.description}>
        {tr.translate("passport.dialog-upload.file.description")}
      </p>
      <Field label={tr.translate("generic.file-input.placeholder-multi")}>
        <FileInput
          fill
          onInputChange={handleFileInput}
          inputProps={{ multiple: true }}
          files={files}
        />
      </Field>

      <Field label={tr.translate("passport.details.file.type")}>
        <Select
          filterable={false}
          activeItem={selectedType}
          items={fileTypeItems}
          onItemSelect={handleSetType}
          intent="outlined"
          isFill
        >
          {type?.text ?? tr.translateAsString("generic.label.none")}
        </Select>
      </Field>
    </Dialog>
  );
}
