import { Operation } from "@aletiq/types";
import { NonIdealState } from "@blueprintjs/core";
import React, { useState } from "react";
import { Button, Card } from "../../../../components";
import { useTranslations } from "../../../../util";
import { AddToolsDialog } from "../dialogs";
import styles from "./NoTool.module.scss";

export default function NoTool(props: {
  projectId: number;
  billId: number;
  operation: Operation;
  canAddTool?: boolean;
}) {
  const { projectId, billId, operation, canAddTool } = props;
  const tr = useTranslations();

  const [isAddingTools, setAddingTools] = useState(false);

  const handleToggleDialog = () => setAddingTools(!isAddingTools);

  return (
    <Card className={styles.card}>
      <NonIdealState
        icon={<img src="/assets/no-data.svg" height={80} alt="no data" />}
        description={
          <>
            {tr.translate("project.operation-bill.operation-tool.empty")}
            {canAddTool && (
              <Button
                isDense
                intent="primary"
                icon="plus"
                onClick={handleToggleDialog}
                className={styles.button}
              >
                {tr.translate("project.operation-bill.operation-tool.new")}
              </Button>
            )}
          </>
        }
      />
      {isAddingTools && canAddTool && (
        <AddToolsDialog
          projectId={projectId}
          bill={billId}
          operation={operation}
          onClose={handleToggleDialog}
        />
      )}
    </Card>
  );
}
