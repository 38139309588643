import {
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  SearchInput,
} from "@aletiq/design-system";
import { User } from "@aletiq/types";
import React, { ReactNode, useState } from "react";
import { useCurrentUser, useUsers } from "../../../hooks";
import { isInString, useTranslations } from "../../../util";
import UserInitial from "../../name/UserInitial";
import { default as UserComponent } from "../../User/User";
import styles from "./UserSelect.module.scss";

export default function UserSelect(props: {
  children?: ReactNode;
  isDisabled?: boolean;
  isDense?: boolean;
  onEditSelection: (selectedOwners: number[]) => void;
  users: number[];
  allowedUsers?: User[];
  additionalOptions?: JSX.Element;
}) {
  const {
    children,
    isDisabled,
    isDense,
    onEditSelection,
    users,
    allowedUsers,
    additionalOptions,
  } = props;
  const tr = useTranslations();

  const [search, setSearch] = useState("");

  const { data: currentUser } = useCurrentUser();
  const { data: allUsers = [] } = useUsers();

  const assignableUsers = allowedUsers || allUsers;
  const filteredUsers = assignableUsers.filter(
    (u) => isInString(u.displayName, search) && u.id !== currentUser?.id!
  );
  const isCurrentUserAssigned = users.includes(currentUser?.id!);
  const isNoUserAssigned = users.length === 0;

  const handleSelectUnselectUser =
    (userId: number, isAssigned: boolean) => () => {
      if (isAssigned) onEditSelection(users.filter((u) => u !== userId));
      else onEditSelection([...users, userId]);
    };

  const handleSelectCurrentUser = () => {
    if (!currentUser) return;
    if (isCurrentUserAssigned)
      onEditSelection(users.filter((u) => u !== currentUser.id));
    else onEditSelection([...users, currentUser.id]);
  };

  const handleSelectNone = () => {
    if (users.length !== 0) onEditSelection([]);
  };

  return (
    <Popover2
      minimal
      className={styles.owners}
      disabled={isDisabled}
      position="bottom-right"
      content={
        <div className={styles.content}>
          <SearchInput
            fill
            isDense={isDense}
            value={search}
            onChange={setSearch}
            intent="default"
            className={styles.search}
          />
          <Menu isDense intent="minimal">
            <MenuItem
              text={tr.translate("generic.label.none")}
              icon={isNoUserAssigned ? "tick" : "blank"}
              active={isNoUserAssigned}
              onClick={handleSelectNone}
              shouldDismissPopover={false}
              isDense={isDense}
            />
            <MenuItem
              text={
                <div className={styles.current_user}>
                  <UserInitial
                    userName={currentUser?.displayName || ""}
                    size="small"
                  />
                  <div>
                    {tr.translate("workflow.task.owner.me", {
                      name: currentUser?.displayName,
                    })}
                  </div>
                </div>
              }
              icon={isCurrentUserAssigned ? "tick" : "blank"}
              active={isCurrentUserAssigned}
              onClick={handleSelectCurrentUser}
              shouldDismissPopover={false}
              isDense={isDense}
            />
            {additionalOptions}
            <MenuDivider />
            {filteredUsers.map((user) => {
              const isAssigned = users.includes(user.id);
              return (
                <MenuItem
                  key={user.id}
                  text={
                    <UserComponent
                      userId={user.id}
                      search={search}
                      size="small"
                    />
                  }
                  icon={isAssigned ? "tick" : "blank"}
                  active={isAssigned}
                  onClick={handleSelectUnselectUser(user.id, isAssigned)}
                  shouldDismissPopover={false}
                  isDense={isDense}
                />
              );
            })}
          </Menu>
        </div>
      }
    >
      {children}
    </Popover2>
  );
}
