import { useState } from "react";

export type HandleFilter<F> = <T extends keyof F>(
  key: T
) => (value: F[T] | undefined) => void;

export type HandleClear<F> = (keys: (keyof F)[]) => () => void;

export default function useFilter<F>(initialValue: F) {
  const [filter, setFilter] = useState(initialValue);
  const handleFilter: HandleFilter<F> = (key) => (value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClear: HandleClear<F> = (keys) => () => {
    const newFilter = { ...filter };
    for (const key of keys) {
      delete newFilter[key];
    }
    setFilter(newFilter);
  };

  return { filter, handleFilter, handleClear };
}
