import { Project } from "@aletiq/types";
import { useQuery, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { projectQueries } from "./queries";

export default function useProject(projectId: number | null | undefined) {
  const api = useApi();
  const queryClient = useQueryClient();
  return useQuery({
    ...projectQueries.byId(api, projectId!),
    enabled: projectId !== null && projectId !== undefined && projectId !== 0,
    initialData: () => {
      const projects: Project[] = queryClient.getQueryData("project") ?? [];
      return projects.find((p) => p.id === projectId);
    },
  });
}
