import { ActiveDocument, ProjectVersion } from "@aletiq/types";
import { sortByString } from "../../../util";
import useProductVersions from "./useProductVersions";
import useProject from "./useProject";

export default function useFetchVersionDetails(projectId: number) {
  const { data: project } = useProject(projectId);
  const { data: versions = [] } = useProductVersions(projectId);

  const versionDraft: ProjectVersion | null = versions[0]?.isDraft
    ? versions[0]
    : null;

  const previousVersion: ProjectVersion | undefined = versions[0]?.isDraft
    ? versions[1]
    : versions[0];

  const name = versionDraft?.version ?? "";
  const description = versionDraft?.comment ?? "";

  const documents = diffDocuments(
    previousVersion?.documents ?? [],
    versionDraft?.documents ?? previousVersion?.documents ?? []
  );

  const documentsData: (ActiveDocument & {
    new: boolean;
    updated?: boolean;
    deleted?: boolean;
  })[] = sortByString(documents, (document) => document.description).map(
    (activeDocument) => {
      return {
        ...activeDocument,
        new: activeDocument.change === "added",
        deleted: activeDocument.change === "deleted",
        updated: activeDocument.change === "updated",
      };
    }
  );

  return {
    project,
    name,
    description,
    versionDraft,
    versions,
    documentsData,
  };
}

// Computes the differences between two sets of active documents. Does not handle duplicated documents
function diffDocuments(
  previous: ActiveDocument[],
  current: ActiveDocument[]
): ActiveDocumentWithChange[] {
  const documentsWithChanges: Record<number, ActiveDocumentWithChange> = {};

  // Documents will be considered as added if they are in current but not previous documents
  current.forEach((document) => {
    documentsWithChanges[document.document.id] = {
      ...document,
      change: "added",
    };
  });

  previous.forEach((previousDocument) => {
    const currentDocument = documentsWithChanges[previousDocument.document.id];
    // Document was not seen in current documents, it must have been deleted
    if (currentDocument === undefined) {
      documentsWithChanges[previousDocument.document.id] = {
        ...previousDocument,
        change: "deleted",
      };
    } else {
      // Document is both in previous and added therefore it is either updated or hasn't changed
      const updated =
        currentDocument.revision.type !== previousDocument.revision.type ||
        currentDocument.revision.revisionId !==
          previousDocument.revision.revisionId ||
        currentDocument.description !== previousDocument.description;

      documentsWithChanges[currentDocument.document.id] = {
        ...currentDocument,
        change: updated ? "updated" : null,
      };
    }
  });

  return Object.values(documentsWithChanges);
}

type ActiveDocumentWithChange = ActiveDocument & {
  change: "updated" | "deleted" | "added" | null;
};
