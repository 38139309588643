import { Part, PartIteration } from "@aletiq/types";
import React, { useState } from "react";
import { ANALYTICS_PARTS_SHOWN, useAnalytics } from "../../../../analytics";
import { Button } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { EditPartBomDialog } from "../../../project/dialogs";
import { PartIterationViewer } from "../../dialogs";
import ShowIterationBomDialog from "../../dialogs/ShowIterationBomDialog";
import useExportAStep from "../../hooks/useExportAsStep";
import usePartIterations from "../../hooks/usePartIterations";
import { isPartParent } from "../../services";
import IterationItem from "./IterationItem";
import styles from "./PartIterations.module.scss";

export type LockState = "current_user" | "other_user" | "not_locked";
export type DialogType =
  | { type: "edit-bom" }
  | { type: "show-bom"; iteration: number }
  | { type: "view"; iteration: number };

export default function PartIterations(props: {
  part: Part;
  onDownload: (revisionId: number) => void;
  onOpenUploadDialog: () => void;
  onOpenApproveAssemblyDialog: () => void;
  lockState: LockState;
}) {
  const {
    onOpenUploadDialog,
    onOpenApproveAssemblyDialog,
    part,
    lockState,
    onDownload,
  } = props;

  const tr = useTranslations();
  const analytics = useAnalytics();

  const canUpdateParts = useHasPermission("update:parts");
  const isLockedByOtherUser = lockState === "other_user";

  const [dialog, setDialog] = useState<DialogType>();

  const { data: iterations = [] } = usePartIterations(props.part.id);
  const { mutate: exportAsStep } = useExportAStep(part.id);

  const handleIterationDownload = (revisionId: number) => () => {
    onDownload(revisionId);
  };

  const handleCloseDialog = () => setDialog(undefined);

  const handleExportAsStep = (iterationId: number) => () => {
    exportAsStep(iterationId);
  };

  const handleView = (iteration: PartIteration) => () => {
    setDialog({ type: "view", iteration: iteration.number });
    analytics.track(ANALYTICS_PARTS_SHOWN);
  };

  const handleShowNomenclature = (iteration: PartIteration) => () => {
    setDialog({ type: "show-bom", iteration: iteration.number });
  };

  const handleCompleteNomenclature = () => {
    setDialog({ type: "edit-bom" });
  };

  const approvedIterationsNames = iterations
    .filter((i) => i.state !== "draft")
    .map((i) => i.name);
  const hasOtherRevisions = iterations.length > 1;

  return (
    <div className={styles.container}>
      {canUpdateParts && (
        <Button
          isDisabled={isLockedByOtherUser}
          icon="upload"
          intent="secondary"
          onClick={onOpenUploadDialog}
          isDense
        >
          {tr.translate("part.details.revisions.load-file")}
        </Button>
      )}
      <div className={styles.revision_list}>
        {iterations.map((iteration) => (
          <IterationItem
            isEditable={canUpdateParts}
            key={iteration.number}
            part={part}
            iteration={iteration}
            hasLock={isLockedByOtherUser}
            hasComponents={isPartParent(part.type)}
            hasOtherRevisions={hasOtherRevisions}
            onDownload={handleIterationDownload(iteration.number)}
            onView={handleView(iteration)}
            onShowNomenclature={handleShowNomenclature(iteration)}
            onExportAsStep={handleExportAsStep(iteration.number)}
            onCompleteNomenclature={handleCompleteNomenclature}
            onSaveAssemblyIteration={onOpenApproveAssemblyDialog}
            approvedIterationsNames={approvedIterationsNames}
          />
        ))}
      </div>
      {dialog?.type === "show-bom" && (
        <ShowIterationBomDialog
          partId={part.id}
          iterationId={dialog.iteration}
          onClose={handleCloseDialog}
        />
      )}
      {dialog?.type === "view" && (
        <PartIterationViewer
          part={part}
          iteration={dialog.iteration}
          onClose={handleCloseDialog}
        />
      )}
      {dialog?.type === "edit-bom" && (
        <EditPartBomDialog partId={part.id} onClose={handleCloseDialog} />
      )}
    </div>
  );
}
