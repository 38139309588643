import { Button } from "@aletiq/design-system";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { Row } from "react-table";
import { ExtendedColumn } from ".";
import { useTranslations } from "../../util";
import styles from "./Table.module.scss";

export default function TableRow<T extends Object>(props: {
  prepareRow: (row: Row<T>) => void;
  isRowActive?: (original: T) => boolean;
  row: Row<T>;
  openRowOptions?: {
    onOpen: (listItem: T) => void;
    rowCannotBeOpened?: (listItem: T) => boolean;
  };
  searchString: string | undefined;
  rowIndex: number;
}) {
  const {
    searchString,
    openRowOptions,
    prepareRow,
    row,
    rowIndex,
    isRowActive,
  } = props;
  const tr = useTranslations();

  const isActive = isRowActive && isRowActive(row.original);
  const handleOpenDetails = (listItem: T) => () => {
    openRowOptions && openRowOptions.onOpen(listItem);
  };

  prepareRow(row);
  const hasSelectionColumn = row.cells[0]?.column?.id === "selection";
  return (
    <div {...row.getRowProps()} className={styles.tr}>
      {row.cells.map((cell, index) => {
        let canOpenRow = !!openRowOptions;
        if (openRowOptions && openRowOptions.rowCannotBeOpened) {
          canOpenRow = !openRowOptions.rowCannotBeOpened(cell.row.original);
        }

        const isFirstCol =
          (index === 0 && !hasSelectionColumn) ||
          (index === 1 && hasSelectionColumn);

        const currentCol = cell.column as ExtendedColumn<T>;

        return (
          <div
            {...cell.getCellProps()}
            className={classNames(styles.td, {
              [styles.sub_row]: cell.row.depth > 0,
              [styles.first_row]: rowIndex === 0,
              [styles.active]: isActive,
            })}
          >
            <CellText fullWidth={currentCol && currentCol.fullWidthContent}>
              {cell.render("Cell", { searchString })}
            </CellText>

            {canOpenRow && isFirstCol && (
              <Button
                isDense
                intent="array_primary"
                className={styles.open_button}
                onClick={handleOpenDetails(cell.row.original)}
              >
                {tr.translate("documents.view.action.open")}
              </Button>
            )}

            {currentCol &&
              currentCol.hoverButton &&
              currentCol.hoverButton(row.original, styles.hover_button)}
          </div>
        );
      })}
    </div>
  );
}

export function CellText(props: { fullWidth?: boolean; children?: ReactNode }) {
  const { fullWidth, children } = props;

  const className = classNames(styles.cell_text, {
    [styles.full_width_content]: fullWidth,
  });

  return <div className={className}>{children}</div>;
}
