import { Spinner, SpinnerSize } from "@blueprintjs/core";
import React from "react";
import { Subtitle } from "../../../components";
import { useTranslations } from "../../../util";
import styles from "./NotFetchedRow.module.scss";

export default function NotFetchedRow() {
  const tr = useTranslations();
  return (
    <div className={styles.loading}>
      <Spinner size={SpinnerSize.SMALL} />
      <Subtitle text={tr.translateAsString("part.details.fetching.bom")} />
    </div>
  );
}
