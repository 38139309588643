import { DocumentQueryFilterParams } from "@aletiq/types";
import React, { useCallback, useState } from "react";
import {
  ANALYTICS_DOCUMENTS_PREVIEW_ARTICLE,
  ANALYTICS_DOCUMENTS_PREVIEW_SOURCE,
  ANALYTICS_DOCUMENTS_SHOWN,
  ANALYTICS_VISITED_PRODUCT_DOCUMENTS,
  useAnalytics,
  useTrackEvent,
} from "../../../analytics";
import { PageTitle } from "../../../components";
import { useColumnConfig, useFilter } from "../../../hooks";
import { useTranslations } from "../../../util";
import { LinkDocumentDialog, NewDocumentDialog } from "../../documents/dialogs";
import DocumentsActionBar from "../../documents/Documents/ActionBar";
import DocumentViews from "../../documents/Documents/DocumentViews";
import {
  close,
  PageState,
  showCreateDocLink,
  showUploadFile,
} from "../../documents/Documents/PageStateHandling";
import {
  useDefaultDocumentColumnConfig,
  useDocuments,
  useFileUpdate,
  useResolvedDocuments,
  useShowDocumentPreview,
} from "../../documents/hooks";
import { ProjectPage } from "../common";
import { useProject } from "../hooks";

export default function ProjectDocuments(props: { projectId: number }) {
  useFileUpdate();
  const tr = useTranslations();
  const analytics = useAnalytics();

  const { projectId } = props;
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_DOCUMENTS);

  const [pageState, setPageState] = useState<PageState>({});
  const { filter, handleFilter, handleClear } =
    useFilter<DocumentQueryFilterParams>({
      search: "",
    });

  const { data: project } = useProject(projectId);
  const { data: projectDocuments } = useDocuments({
    ...(project?.id && { projects: [project.id] }),
  });
  const { documents } = useResolvedDocuments(projectDocuments?.list);

  const showPreview = useShowDocumentPreview();

  const { config: defaultConfig } = useDefaultDocumentColumnConfig({
    showRevision: true,
  });
  const { config: columnConfig, handleToggleColumn } =
    useColumnConfig(defaultConfig);

  const handleShowFilePreview = useCallback(
    (documentId: number) => {
      showPreview({ documentId });
      analytics.track(ANALYTICS_DOCUMENTS_SHOWN, {
        [ANALYTICS_DOCUMENTS_PREVIEW_SOURCE]:
          ANALYTICS_DOCUMENTS_PREVIEW_ARTICLE,
      });
    },
    [showPreview, analytics]
  );

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.document")}
    >
      <PageTitle icon="document">
        {tr.translate("documents.view.menu.documents")}
      </PageTitle>
      <DocumentsActionBar
        filter={filter}
        handleFilter={handleFilter}
        handleClear={handleClear}
        onNewDocument={() => setPageState(showUploadFile())}
        onLinkDocuments={() => setPageState(showCreateDocLink())}
        onToggleColumn={handleToggleColumn}
        columnConfig={columnConfig}
      />
      <DocumentViews
        filter={filter}
        onShowFilePreview={handleShowFilePreview}
        projectId={projectId}
        columnConfig={columnConfig}
      />
      {pageState.dialog?.type === "upload-file" && (
        <NewDocumentDialog
          onClose={() => setPageState(close())}
          projectId={projectId}
        />
      )}
      {pageState.dialog?.type === "link-document" && (
        <LinkDocumentDialog
          productId={projectId}
          onClose={() => setPageState(close())}
          productDocs={documents}
        />
      )}
    </ProjectPage>
  );
}
