import { ProgressBar } from "@blueprintjs/core";
import React from "react";
import { useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { activityKeys } from "../../../activities/hooks/queries";
import { documentKeys } from "../../hooks/queries";
import styles from "./DocumentUploadWizard.module.scss";
import { DocumentState } from "./state";

export default function useCreateOrUpdateDocuments(
  projectId: number | null,
  documents: DocumentState[]
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return async (options?: { onSettled?: (error: unknown) => void }) => {
    let uploadedCount = 0;
    const docsToUpload = documents.filter((d) => d.action.action !== "ignore");
    const onSettled = options?.onSettled;
    try {
      for (const document of docsToUpload) {
        switch (document.action.action) {
          case "ignore":
            break;
          case "create":
            await api.document.createDocument(
              {
                name: document.action.name,
                owner: document.action.owner,
                type: null,
                category: null,
                projects: projectId ? [projectId] : [],
                revision: document.action.revision,
                properties: document.action.properties,
              },
              document.file
            );
            uploadedCount += 1;
            toaster.show(
              {
                intent: "primary",
                icon: "info-sign",
                message: (
                  <>
                    {tr.translate("toaster.documents.upload.in-progress")}
                    <ProgressBar
                      intent="primary"
                      value={uploadedCount / docsToUpload.length}
                      stripes={false}
                      className={styles.progress_bar}
                    />
                    <span className={styles.progress_message}>
                      {uploadedCount}/{docsToUpload.length}
                    </span>
                  </>
                ),
                timeout: 0,
              },
              "document-upload-progress"
            );
            break;
          case "update":
            if (document.documentId) {
              await api.document.updateDocument(
                document.documentId,
                document.action.revision,
                document.file
              );
              uploadedCount += 1;
              toaster.show(
                {
                  intent: "primary",
                  icon: "info-sign",
                  message: (
                    <>
                      {tr.translate("toaster.documents.upload.in-progress")}
                      <ProgressBar
                        intent="primary"
                        value={uploadedCount / docsToUpload.length}
                        stripes={false}
                        className={styles.progress_bar}
                      />
                      <span className={styles.progress_message}>
                        {uploadedCount}/{docsToUpload.length}
                      </span>
                    </>
                  ),
                  timeout: 0,
                },
                "document-upload-progress"
              );
            }
            break;
        }
      }
      toaster.show(
        {
          intent: "success",
          icon: "tick",
          message: (
            <>
              {tr.translate("toaster.documents.upload.done")}
              <ProgressBar
                intent="success"
                value={1}
                stripes={false}
                className={styles.progress_bar}
              />
              <span className={styles.progress_message}>
                {uploadedCount}/{docsToUpload.length}
              </span>
            </>
          ),
        },
        "document-upload-progress"
      );
      onSettled && onSettled(undefined);
    } catch (err) {
      const filesNotUploaded = docsToUpload
        .slice(uploadedCount - 1)
        .map((file) => file.action.name);
      toaster.show(
        {
          intent: "danger",
          icon: "warning-sign",
          message: (
            <>
              {tr.translate("toaster.documents.upload.error.title")}
              <div className={styles.error_message}>
                {tr.translate("toaster.documents.upload.error.description")}
                <ul>
                  {filesNotUploaded.map((fileName) => (
                    <li>{fileName}</li>
                  ))}
                </ul>
              </div>
            </>
          ),
        },
        "document-upload-progress"
      );
      onSettled && onSettled(err);
    } finally {
      queryClient.invalidateQueries(documentKeys.all);
      queryClient.invalidateQueries(activityKeys.all);
    }
  };
}
