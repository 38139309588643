import React from "react";
import { HighlightedText, Icon, Tooltip } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { NewVersionDoc } from "../../../hooks";
import styles from "./NewVersionDocuments.module.scss";

export default function NewVersionDocumentNameCell(props: {
  document: NewVersionDoc;
  search?: string;
}) {
  const { document, search } = props;
  const tr = useTranslations();

  const cellContent = (
    <>
      <Icon inline icon="document" />
      <HighlightedText text={document.name} highlight={search} />
    </>
  );

  if (!document.doc.hasAccess) {
    return (
      <Tooltip
        position="top-left"
        content={tr.translate("version.details.doc.private")}
      >
        <div className={styles.no_access}>{cellContent}</div>
      </Tooltip>
    );
  }

  return cellContent;
}
