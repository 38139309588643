import { SearchInput } from "@aletiq/design-system";
import React from "react";
import { HandleClear, HandleFilter } from "../../../../hooks";
import { PropertyFilters } from "../types";
import CreatorFilter from "./CreatorFilter";
import PropertyTypeFilter from "./PropertyTypeFilter";

export default function PoprertyCardFilters(props: {
  filters: PropertyFilters;
  handleFilter: HandleFilter<PropertyFilters>;
  handleClear: HandleClear<PropertyFilters>;
}) {
  const { filters, handleFilter, handleClear } = props;

  return (
    <>
      <SearchInput
        value={filters?.search ?? ""}
        onChange={handleFilter("search")}
        isDense
      />
      <CreatorFilter
        selected={filters.creator ?? []}
        onSelect={handleFilter("creator")}
        onSelectCreatedByAletiq={handleFilter("createdByAletiq")}
        onClear={handleClear(["creator", "createdByAletiq"])}
        selectCreatedByAletiq={filters.createdByAletiq ?? false}
      />
      <PropertyTypeFilter
        selected={filters.propertyType ?? []}
        onSelect={handleFilter("propertyType")}
        onClear={handleClear(["propertyType"])}
      />
    </>
  );
}
