import { ProjectField } from "@aletiq/types";
import React from "react";
import PassportConfigurationsInput from "./PassportConfigurationInput";

export default function PassportConfigurationsInputs(props: {
  configSpec: ProjectField[];
  config: Record<number, number[]>;
  onSetConfig: (fieldId: number, values: number[]) => void;
}) {
  const { configSpec, config, onSetConfig } = props;

  return (
    <>
      {configSpec.map((field: ProjectField) => (
        <PassportConfigurationsInput
          field={field}
          config={config}
          onSetConfig={onSetConfig}
        />
      ))}
    </>
  );
}
