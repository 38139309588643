import { Button, ButtonGroup } from "@aletiq/design-system";
import React from "react";
import styles from "../EditableProperty.module.scss";

export default function EditablePropertyButtonGroup(props: {
  onSubmit: () => void;
  onReset: () => void;
  isSubmitting?: boolean;
  isSubmitDisabled?: boolean;
}) {
  const { onSubmit, onReset, isSubmitting, isSubmitDisabled } = props;

  return (
    <ButtonGroup isDense className={styles.edit_buttons}>
      <Button
        isLoading={isSubmitting}
        isDisabled={isSubmitDisabled}
        icon="tick"
        isDense
        intent="success"
        onClick={onSubmit}
      />
      <Button icon="cross" isDense onClick={onReset} />
    </ButtonGroup>
  );
}
