import { CheckBox, Icon } from "@aletiq/design-system";
import cx from "classnames";
import React from "react";
import { PartIcon } from "../../../../components";
import PartTree from "../../common/PartTree";
import usePartTree from "../../hooks/usePartTree";
import styles from "./LockPartDialog.module.scss";

export default function PickComponentTree(props: {
  part: number;
  selected: number[];
  toggleSelected: (id: number) => void;
}) {
  const { part, selected, toggleSelected } = props;

  const { data: tree } = usePartTree(part);

  if (!tree) return null;

  return (
    <PartTree
      tree={tree}
      TreeNode={({ node, offset }) => {
        const isLocked = node.lockOwner !== null;
        return (
          <div className={styles.row}>
            <CheckBox
              inline
              isDisabled={isLocked || offset === 0}
              checked={selected.includes(node.id)}
              onClick={() => toggleSelected(node.id)}
            />
            <div
              style={{ marginLeft: `${offset * 20}px` }}
              className={cx({ [styles.disabled]: isLocked || offset === 0 })}
            >
              <PartIcon inline type={node.type} />
              {node.name}
              {isLocked && <Icon icon="lock" className={styles.icon} />}
            </div>
          </div>
        );
      }}
    />
  );
}
