import React from "react";
import useWorkflowLink from "./hooks/useWorkflowLink";
import NameLink from "./NameLink";

export default function WorkflowNameLink(props: {
  workflowId: number;
  hasIcon?: boolean;
  className?: string;
  search?: string;
}) {
  const { className, workflowId, hasIcon, search } = props;

  const linkProps = useWorkflowLink(workflowId);

  return (
    <NameLink
      className={className}
      hasIcon={hasIcon}
      search={search}
      {...linkProps}
    />
  );
}
