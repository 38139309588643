import { IconName } from "@blueprintjs/core";
import React, { useState } from "react";
import { PropertyCard } from "../../../../../components";
import { useHasPermission } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import useClients from "../../../../clients/hooks/useClients";
import { PropertyFilters } from "../../types";
import { EditPassportClientDialog } from "../Dialogs";
import PassportPropertyTarget from "./PassportPropertyTarget";

export default function PropertyCardClient(props: {
  filters?: PropertyFilters;
  usedIn: { count: number; icon: IconName }[];
}) {
  const { filters, usedIn } = props;
  const tr = useTranslations();

  const [showDialog, setShowDialog] = useState<"editClient" | undefined>();

  const { data: clients = [], isLoading } = useClients();

  const canEditProperty = useHasPermission("update:passport-attributes");

  const label = tr.translateAsString("admin.attribute.passports.client");
  const description = tr.translateAsString(
    "admin.attribute.passports.client.description"
  );

  const options = clients.map((client) => ({
    label: client.name,
    value: client.id,
    deleted: client.deleted,
    uses: client.uses,
  }));

  const handleOpenedDialog = (type?: "editClient") => () => {
    setShowDialog(type);
  };

  return (
    <>
      <PropertyCard
        label={label}
        type="enum"
        description={description}
        onEdit={handleOpenedDialog("editClient")}
        canEdit={canEditProperty}
        target={<PassportPropertyTarget target="all" />}
        filters={filters}
        usedIn={usedIn}
      />
      {/* Dialogs */}
      {!isLoading && showDialog === "editClient" && (
        <EditPassportClientDialog
          label={label}
          description={description}
          options={options}
          onClose={handleOpenedDialog(undefined)}
        />
      )}
    </>
  );
}
