import React from "react";
import { PageContents, PageTitle } from "../../../components";
import { useTranslations } from "../../../util";
import {
  ANALYTICS_VISITED_STANDARD_PARTS,
  useTrackEvent,
} from "../../../analytics";
import StandardPartsTable from "./StandardPartsTable";

export default function StandardParts() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_STANDARD_PARTS);

  return (
    <PageContents domTitle={tr.translateAsString("page.title.standard-parts")}>
      <PageTitle icon="cog">
        {tr.translate("sidebar.pdm.standard-parts")}
      </PageTitle>
      <StandardPartsTable />
    </PageContents>
  );
}
