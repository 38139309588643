import { Button, ButtonGroup } from "@aletiq/design-system";
import "draft-js/dist/Draft.css";
import React from "react";
import { useTranslations } from "../../util";
import CommentEditor from "./CommentEditor";
import styles from "./CommentEditor.module.scss";
import draftJsToMarkdown from "./DraftJSToMarkdown";
import mardownToDraftJs from "./MardownToDraftJs";
import mentionsFromDraftJs from "./mentionsFromDraftJs";

export default function EditComment(props: {
  initialValue: string;
  onSubmit: (comment: string, mentions: string[]) => void;
  onCancel: () => void;
}) {
  const { initialValue, onSubmit, onCancel } = props;
  const tr = useTranslations();
  const [editorState, setEditorState] = React.useState(() =>
    mardownToDraftJs(initialValue)
  );

  const handleSave = () => {
    const contentState = editorState.getCurrentContent();
    onSubmit(
      draftJsToMarkdown(contentState),
      mentionsFromDraftJs(contentState)
    );
  };

  return (
    <div className={styles.edit}>
      <CommentEditor
        editorState={editorState}
        setEditorState={setEditorState}
      />
      <ButtonGroup isDense>
        <Button isDense intent="tertiary" onClick={onCancel}>
          {tr.translate("generic.action.cancel")}
        </Button>
        <Button isDense icon="tick" onClick={handleSave}>
          {tr.translate("generic.action.save")}
        </Button>
      </ButtonGroup>
    </div>
  );
}
