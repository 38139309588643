const windowConfig: any = window["config" as any] || {};

export const API_URL = windowConfig.API_URL || process.env.REACT_APP_API_URL;

export const rootUrl = windowConfig.rootUrl || process.env.REACT_APP_ROOT_URL;

export const NODE_ENV = process.env.NODE_ENV || "development";

export const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID || "";

export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID || "";

export const AMPLITUDE_ID = process.env.REACT_APP_AMPLITUDE_ID || "";

export const MIXPANEL_PROJECT_TOKEN =
  process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || "";

export const MIXPANEL_API_HOST = process.env.REACT_APP_MIXPANEL_API_HOST || "";

export const WITH_FAVICON = process.env.REACT_APP_WITH_FAVICON
  ? process.env.REACT_APP_WITH_FAVICON === "true"
  : true;

export const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: rootUrl,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  ...windowConfig.auth0Config,
};
