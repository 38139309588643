import { CharacterMetadata, DraftInlineStyle } from "draft-js";
import immutable, { Iterable, List } from "immutable";

type StyleRange = [string, DraftInlineStyle];

const getEntityRanges = (
  text: string,
  charMetaList: List<CharacterMetadata>
): [string, StyleRange[]][] => {
  let charEntity = "";
  let prevCharEntity = "";
  const ranges = [] as [string, StyleRange[]][];
  let rangeStart = 0;

  for (let i = 0, len = text.length; i < len; i++) {
    prevCharEntity = charEntity;
    const meta = charMetaList.get(i);
    charEntity = meta ? meta.getEntity() : "";

    if (i > 0 && prevCharEntity !== "" && charEntity !== prevCharEntity) {
      const newEntity = [
        prevCharEntity,
        getStyleRanges(
          text.slice(rangeStart, i),
          charMetaList.slice(rangeStart, i)
        ),
      ] as [string, StyleRange[]];
      ranges.push(newEntity);
      rangeStart = i;
    }
  }
  const entity = [
    charEntity,
    getStyleRanges(text.slice(rangeStart), charMetaList.slice(rangeStart)),
  ] as [string, StyleRange[]];

  ranges.push(entity);
  return ranges;
};

const getStyleRanges = (
  text: string,
  charMetaList: Iterable<number, CharacterMetadata>
): StyleRange[] => {
  let charStyle = EMPTY_SET;
  let prevCharStyle = EMPTY_SET;
  const ranges = [] as StyleRange[];
  let rangeStart = 0;

  for (let i = 0, len = text.length; i < len; i++) {
    prevCharStyle = charStyle;
    const meta = charMetaList.get(i);
    charStyle = meta ? meta.getStyle() : EMPTY_SET;

    if (i > 0 && !immutable.is(charStyle, prevCharStyle)) {
      const newStyle = [text.slice(rangeStart, i), prevCharStyle] as StyleRange;
      ranges.push(newStyle);
      rangeStart = i;
    }
  }

  ranges.push([
    text.slice(rangeStart),
    charStyle as Immutable.OrderedSet<string>,
  ]);
  return ranges;
};

export const EMPTY_SET = immutable.OrderedSet();
export default getEntityRanges;
