import { User, UserRole } from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_ACCOUNT_CURRENT_ROLE,
  ANALYTICS_ACCOUNT_EMAIL,
  ANALYTICS_ACCOUNT_PREVIOUS_ROLE,
  ANALYTICS_ACCOUNT_UPDATED,
  ANALYTICS_MUTATION_STATUS,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../analytics";
import { AlignRight, Button, User as UserName } from "../../../components";
import { RoleMenu } from "./common";
import { ConfirmDeleteUserDialog, ModifySharedUserDialog } from "./dialogs";
import { useUpdateUserRole } from "./hooks";
import styles from "./Users.module.scss";

type DialogsName = "confirmDelete" | "modifySharedAccount";

export default function UserRow(props: { user: User; search: string }) {
  const { user, search } = props;
  const analytics = useAnalytics();

  const [openedDialog, setOpenedDialog] = useState<DialogsName | undefined>();

  const { mutate: updateUserRole } = useUpdateUserRole(user.id);
  const isShared = user.role === "shared";

  const handleUpdateStatus = (role: UserRole) => () => {
    const oldRole = user.role;
    updateUserRole(role, {
      onSettled: (_, error) => {
        analytics.track(ANALYTICS_ACCOUNT_UPDATED, {
          [ANALYTICS_ACCOUNT_EMAIL]: user.email,
          [ANALYTICS_ACCOUNT_PREVIOUS_ROLE]: oldRole,
          [ANALYTICS_ACCOUNT_CURRENT_ROLE]: role,
          [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
        });
      },
    });
  };

  const handleOpenedDialog = (type: DialogsName | undefined) => () => {
    setOpenedDialog(type);
  };

  return (
    <>
      <div className={styles.row}>
        <UserName userId={user.id} intent="default" search={search} />
        <AlignRight />
        <div className={styles.row_right}>
          {isShared && (
            <Button
              isDense
              icon="edit"
              onClick={handleOpenedDialog("modifySharedAccount")}
            />
          )}
          <RoleMenu
            role={user.role}
            updateStatus={handleUpdateStatus}
            onDeactivateAccount={handleOpenedDialog("confirmDelete")}
            isDeactivated={!user.isActive}
          />
        </div>
      </div>

      {openedDialog === "confirmDelete" && (
        <ConfirmDeleteUserDialog
          onClose={handleOpenedDialog(undefined)}
          user={user}
        />
      )}

      {openedDialog === "modifySharedAccount" && (
        <ModifySharedUserDialog
          onClose={handleOpenedDialog(undefined)}
          user={user}
        />
      )}
    </>
  );
}
