import { PartQueryFilterParams, PartQueryOrder, PartType } from "@aletiq/types";
import React, { useEffect, useState } from "react";
import { Table } from "../../../../components";
import {
  useColumnConfig,
  useDelayed,
  useFilter,
  usePagination,
  useSortKey,
} from "../../../../hooks";
import useDragNDropFiles from "../../../../hooks/useDragNDropFiles";
import {
  ExpandTree,
  toggleExpandAt,
  TreePath,
  useTranslations,
} from "../../../../util";
import { EditPartBomDialog } from "../../../project/dialogs";
import usePartTree from "../../AllParts/usePartTree";
import { PartCreationDialog } from "../../dialogs";
import UploadStandardPartsWizard from "../../dialogs/UploadStandardPartsWizard";
import {
  usePaginatedParts,
  usePartRowOptions,
  useStandardPartTableColumns,
} from "../../hooks";
import { defaultStandardPartColumnConfig } from "../../hooks/useStandardPartTableColumns";
import StandardPartsActionBar from "./StandardPartsActionBar";
import styles from "./StandardPartsTable.module.scss";

type StandardPartTableDialog =
  | { type: "upload"; files: File[] }
  | {
      type: "create";
      partType: PartType;
      projectId?: number;
    }
  | {
      type: "edit-bom";
      partId: number;
    };

export default function StandardPartsTable() {
  const tr = useTranslations();

  const [expandTrees, setExpandTrees] = useState<ExpandTree[]>([]);

  const { filter, handleFilter, handleClear } =
    useFilter<PartQueryFilterParams>({
      standard: true,
    });

  const { config: columnConfig, handleToggleColumn } = useColumnConfig(
    defaultStandardPartColumnConfig
  );

  const { sortState, setSortKey } = useSortKey<PartQueryOrder>(
    "updatedAt",
    "desc"
  );

  const [dialog, setDialog] = useState<StandardPartTableDialog>();

  const pagination = usePagination();
  const { limit, selectedPage, resetSelectedPage } = pagination;

  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, resetSelectedPage]);

  const offset = selectedPage * limit;

  const {
    data,
    isFetching: partsFetching,
    isLoading: partsLoading,
  } = usePaginatedParts({
    ...filter,
    offset,
    limit,
    order: sortState.key,
    orderDirection: sortState.direction,
  });

  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  const handleOpenCreateDialog = (partType: PartType) => {
    setDialog({
      type: "create",
      partType,
    });
  };

  const handleToggleExpand = (path: TreePath[]) => (expanded: boolean) =>
    setExpandTrees((t) => toggleExpandAt(t, path, expanded));

  const parts = data?.list ?? [];
  const visibleParts = usePartTree(expandTrees, parts);
  const isFetching = useDelayed(partsFetching);

  const { onDrop, onDragOver } = useDragNDropFiles((files) => {
    setDialog({
      type: "upload",
      files,
    });
  });

  const handleEditBom = (partId: number) => () => {
    setDialog({
      type: "edit-bom",
      partId,
    });
  };

  const columns = useStandardPartTableColumns(
    handleToggleExpand,
    handleEditBom,
    columnConfig
  );

  const { onOpen, isRowUnselectable: rowCannotBeOpened } = usePartRowOptions();

  return (
    <>
      <StandardPartsActionBar
        filter={filter}
        handleClear={handleClear}
        handleFilter={handleFilter}
        onCreatePart={handleOpenCreateDialog}
        columnConfig={columnConfig}
        onToggleColumn={handleToggleColumn}
      />
      <div
        className={styles.table_wrapper}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <Table
          columns={columns}
          data={visibleParts}
          noData={tr.translate(
            !filter.search || filter.search === ""
              ? "standard-parts.action.no-data"
              : "standard-parts.action.no-data.filtered"
          )}
          searchString={filter.search}
          paginationOptions={{
            itemCount: data?.count ?? 0,
            limit,
            selectedPage,
            onSetLimit: pagination.handleLimit,
            onSetPage: pagination.handleSelectPage,
          }}
          sortOptions={{ sortState, onSort: setSortKey }}
          openRowOptions={{ onOpen, rowCannotBeOpened }}
          isFetching={isFetching || partsLoading}
        />
      </div>

      {dialog?.type === "create" && (
        <PartCreationDialog
          type={dialog.partType}
          onClose={handleCloseDialog}
          isStandard
        />
      )}

      {dialog?.type === "upload" && (
        <UploadStandardPartsWizard
          files={dialog.files}
          onClose={handleCloseDialog}
        />
      )}

      {dialog?.type === "edit-bom" && (
        <EditPartBomDialog partId={dialog.partId} onClose={handleCloseDialog} />
      )}
    </>
  );
}
