import { CheckBox, Dialog, Field } from "@aletiq/design-system";
import { PropertyType } from "@aletiq/types";
import React, { useState } from "react";
import { EditProperty } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { useCreateCustomProperty } from "../../hooks";
import { ResolvedPropertyOption } from "../../hooks/useResolvedPropertyOptions";
import styles from "./Dialogs.module.scss";

export type Target = "all" | "tool" | "article";

export default function CreatePassportPropertyDialog(props: {
  onClose: () => void;
}) {
  const { onClose } = props;
  const tr = useTranslations();
  const { mutate: createProperty, isLoading } =
    useCreateCustomProperty("passport");

  const [propertyType, setPropertyType] = useState<PropertyType>("enum");
  const [options, setOptions] = useState<ResolvedPropertyOption[]>([]);
  const [label, setLabel] = useState("");
  const [targets, setTargets] = useState<Target[]>(["article"]);
  const [description, setDescription] = useState("");

  const isEnum = propertyType === "enum" || propertyType === "multi-enum";
  const canSubmit =
    label !== "" &&
    ((isEnum && options.length > 0) || !isEnum) &&
    targets.length > 0;

  const onSubmit = () => {
    const target =
      targets.includes("article") && targets.includes("tool")
        ? "all"
        : targets.includes("article")
        ? "article"
        : "tool";

    const property = {
      label,
      description,
      type: propertyType,
      group: target,
      options: isEnum ? options.filter((o) => !o.deleted) : undefined,
    };

    createProperty(property, {
      onSettled: () => {
        onClose();
      },
    });
  };

  const handleSetTargets = (target: Target) => () => {
    if (targets.includes(target)) {
      setTargets(targets.filter((t) => t !== target));
      return;
    }
    setTargets([...targets, target]);
  };

  return (
    <Dialog
      isOpen
      icon="plus"
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("passport.dialog.new.property")}
      onPrimaryClick={onSubmit}
      disablePrimary={!canSubmit}
      submitting={isLoading}
    >
      <EditProperty
        propertyType={propertyType}
        label={label}
        description={description}
        options={options.filter((o) => !o.deleted)}
        canEditPropertyType
        onEditDescription={setDescription}
        onEditLabel={setLabel}
        onEditOptions={setOptions}
        onEditPropertyType={setPropertyType}
        targetSelector={
          <Field label={tr.translate("admin.attribute.new.target")}>
            <div className={styles.targets}>
              <CheckBox
                label={tr.translateAsString(
                  "admin.attribute.target.articles-passports"
                )}
                checked={targets.includes("article")}
                onClick={handleSetTargets("article")}
              />

              <CheckBox
                label={tr.translateAsString(
                  "admin.attribute.target.tools-passports"
                )}
                checked={targets.includes("tool")}
                onClick={handleSetTargets("tool")}
              />
            </div>
          </Field>
        }
        showUseCounts={false}
        icon="id-number"
      />
    </Dialog>
  );
}
