import { ActiveDocument } from "@aletiq/types";
import React from "react";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Popover2,
  Tooltip,
} from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { NewVersionDoc } from "../../../hooks";
import styles from "./NewVersionDocuments.module.scss";

export default function ActionCell(props: {
  document: NewVersionDoc;
  onAddDocument: (doc: ActiveDocument) => void;
  onDownload: (documentId: number, revision: number) => void;
  onPreview: (value: { documentId: number; revisionId?: number }) => void;
  onRemove: (documentId: number, state: "confirmed" | "unconfirmed") => void;
}) {
  const { document, onAddDocument, onRemove, onDownload, onPreview } = props;
  const tr = useTranslations();

  const isDocInaccessible = !document.doc.hasAccess || document.doc.isDeleted;

  const handlePreview = () => {
    document.state === "confirmed" &&
      document.revision &&
      onPreview({ documentId: document.id, revisionId: document.revision.id });
  };

  const handleDownload = () => {
    document.state === "confirmed" &&
      document.revision &&
      onDownload(document.id, document.revision.id);
  };

  const handleAddDocument = () => {
    if (document.activeRevision) {
      onAddDocument({
        document: document.doc,
        revision: document.activeRevision,
        description: document.description,
      });
    }
  };

  const handleRemoveDocument = () => {
    onRemove(document.id, document.state);
  };

  return (
    <ButtonGroup isDense className={styles.cta}>
      {document.state === "unconfirmed" && (
        <>
          <Button
            isDense
            intent="success"
            icon="tick"
            isDisabled={!document.activeRevision}
            onClick={handleAddDocument}
          />
          <Button isDense icon="cross" onClick={handleRemoveDocument} />
        </>
      )}

      {document.state === "confirmed" && (
        <>
          <Tooltip content={tr.translate("generic.action.preview")}>
            <AnchorButton
              isDense
              icon="eye-open"
              onClick={handlePreview}
              isDisabled={
                isDocInaccessible || document.revision?.state === "obsolete"
              }
            />
          </Tooltip>
          <Tooltip content={tr.translate("generic.action.download")}>
            <AnchorButton
              isDense
              icon="download"
              onClick={handleDownload}
              isDisabled={isDocInaccessible}
            />
          </Tooltip>
        </>
      )}

      {document.state === "confirmed" && (
        <Popover2
          disabled={isDocInaccessible}
          content={
            <Menu isDense>
              {!document.deleted && (
                <MenuItem
                  isDense
                  text={tr.translateAsString("generic.action.delete")}
                  icon="trash"
                  onClick={handleRemoveDocument}
                />
              )}
              {document.deleted && (
                <MenuItem
                  isDense
                  text={tr.translateAsString("generic.action.restore")}
                  icon="reset"
                  onClick={handleAddDocument}
                />
              )}
            </Menu>
          }
        >
          <AnchorButton isDense icon="more" isDisabled={isDocInaccessible} />
        </Popover2>
      )}
    </ButtonGroup>
  );
}
