import { Document } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../util";
import NameLink from "./NameLink";
import documentLinkProps from "./props/documentLinkProps";

export default function DocumentLink(props: {
  document: Document | undefined;
  hasIcon?: boolean;
  className?: string;
  search?: string;
}) {
  const { className, document, hasIcon, search } = props;
  const tr = useTranslations();

  const linkProps = documentLinkProps(tr, document);

  return (
    <NameLink
      className={className}
      hasIcon={hasIcon}
      search={search}
      {...linkProps}
    />
  );
}
