import { Task, TaskModel } from "@aletiq/types";
import { Dispatch } from "react";
import TaskEditInfo, {
  makeDefaultTaskInfo,
  makeEditInfoFromModel,
  makeEditInfoFromTask,
  makeNewTaskOrder,
  makeTaskAfterDelete,
  removeTaskFromDeps,
  TaskEditorState,
} from "./taskEditUtils";

export type WorkflowEditorAction =
  | {
      type: "resetFromModel";
      tasks: TaskModel[];
      title: string;
      id?: number;
    }
  | {
      type: "resetFomWorkflow";
      tasks: Task[];
      title: string;
      id?: number;
    }
  | {
      type: "dragAndDrop";
      dragFrom: number;
      dragTo: number;
    }
  | {
      type: "editTitle";
      title: string;
    }
  | {
      type: "markForDeletion";
      id: number;
      order: number;
    }
  | {
      type: "taskEdited";
      task: TaskEditInfo;
    }
  | {
      type: "newTaskEdited";
      task: TaskEditInfo;
    }
  | {
      type: "createNewTask";
    }
  | {
      type: "removeNewTask";
      id: number;
    };

export type WorkflowEditorState = {
  tasks: TaskEditorState;
  title: string;
  id?: number;
  creator: number;
};

export function makeDispatchHandlers(dispatch: Dispatch<WorkflowEditorAction>) {
  return {
    handleEditTitle: (title: string) => {
      dispatch({
        type: "editTitle",
        title,
      });
    },

    handleDragAndDrop: (dragFrom: number, dragTo: number) => {
      dispatch({
        type: "dragAndDrop",
        dragTo,
        dragFrom,
      });
    },

    handleNewSpecEdited: (task: TaskEditInfo) => {
      dispatch({
        type: "newTaskEdited",
        task,
      });
    },

    handleTaskEdited: (task: TaskEditInfo) => {
      dispatch({
        type: "taskEdited",
        task,
      });
    },

    handleRemoveTask: (id: number) => {
      dispatch({
        type: "removeNewTask",
        id,
      });
    },

    handleMarkForDeletion: (id: number, order: number) => {
      dispatch({
        type: "markForDeletion",
        id,
        order,
      });
    },

    handleCreateTask: () => {
      dispatch({ type: "createNewTask" });
    },
  };
}

export function workflowEditorReducer(
  state: WorkflowEditorState,
  action: WorkflowEditorAction
): WorkflowEditorState {
  switch (action.type) {
    case "resetFromModel":
      return {
        ...state,
        tasks: {
          deleted: [],
          edited: action.tasks.map((task, idx) =>
            makeEditInfoFromModel(task, idx, state.creator)
          ),
          created: [],
        },
        title: action.title,
        id: action.id,
      };
    case "resetFomWorkflow":
      return {
        ...state,
        tasks: {
          deleted: [],
          edited: action.tasks.map(makeEditInfoFromTask),
          created: [],
        },
        title: action.title,
        id: action.id,
      };
    case "dragAndDrop":
      return {
        ...state,
        tasks: {
          ...state.tasks,
          edited: state.tasks.edited.map((t) => ({
            ...t,
            order: makeNewTaskOrder(t.order, action.dragFrom, action.dragTo),
          })),
          created: state.tasks.created.map((t) => ({
            ...t,
            order: makeNewTaskOrder(t.order, action.dragFrom, action.dragTo),
          })),
        },
      };
    case "editTitle":
      return {
        ...state,
        title: action.title,
      };
    case "markForDeletion":
      return {
        ...state,
        tasks: {
          deleted: [...state.tasks.deleted, action.id],
          edited: state.tasks.edited
            .filter((t) => t.id !== action.id)
            .map((t) => makeTaskAfterDelete(action.order, action.id, t)),
          created: state.tasks.created.map((t) =>
            removeTaskFromDeps(action.id, t)
          ),
        },
      };
    case "taskEdited":
      return {
        ...state,
        tasks: {
          ...state.tasks,
          edited: state.tasks.edited.map((t) =>
            t.id === action.task.id ? action.task : t
          ),
        },
      };
    case "newTaskEdited":
      return {
        ...state,
        tasks: {
          ...state.tasks,
          created: state.tasks.created.map((t) =>
            t.id === action.task.id ? action.task : t
          ),
        },
      };
    case "createNewTask":
      return {
        ...state,
        tasks: {
          ...state.tasks,
          created: [
            ...state.tasks.created,
            makeDefaultTaskInfo(
              -state.tasks.created.length - 1,
              state.tasks.edited.length + state.tasks.created.length + 1,
              state.id
            ),
          ],
        },
      };
    case "removeNewTask":
      return {
        ...state,
        tasks: {
          ...state.tasks,
          created: state.tasks.created
            .filter((t) => t.id !== action.id)
            .map((t) => removeTaskFromDeps(action.id, t)),
          edited: state.tasks.edited.map((t) =>
            removeTaskFromDeps(action.id, t)
          ),
        },
      };
    default:
      return state;
  }
}
