import { Document } from "@aletiq/types";
import { useQuery, UseQueryOptions } from "react-query";
import useApi from "../../../app/useApi";
import { documentQueries } from "./queries";

export default function useDocument<T = Document>(
  documentId: number | null,
  queryOptions?: UseQueryOptions<Document, unknown, T>
) {
  const api = useApi();
  return useQuery({
    ...documentQueries.byId(api, documentId!),
    enabled: documentId !== null,
    ...queryOptions,
  });
}
