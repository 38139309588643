import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@blueprintjs/core";
import React, { ReactElement, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ANALYTICS_USER_EMAIL,
  ANALYTICS_USER_FIRST_NAME,
  ANALYTICS_USER_INSTANCE,
  ANALYTICS_USER_LAST_NAME,
  ANALYTICS_USER_ROLE,
  ANALYTICS_USER_TENANT,
  useAnalytics,
} from "../analytics";
import { AccessDeniedCallout } from "../components/AccessDeniedCallout";
import { WITH_FAVICON } from "../config";
import { useCrispChat, useCurrentUser, useFavicon, useTenant } from "../hooks";
import styles from "./LoginBoundary.module.scss";

const SPINNER_SIZE = 98;

export default function LoginBoundary(props: { children: ReactElement }) {
  const { children } = props;
  const { isAuthenticated, loginWithRedirect, isLoading, error } = useAuth0();
  useCrispChat();
  useFavicon(WITH_FAVICON);
  const history = useHistory();

  const location = useLocation();
  useEffect(() => {
    if (!isLoading && !isAuthenticated && error === undefined) {
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
    }
  }, [isLoading, loginWithRedirect, isAuthenticated, location, error]);

  useEffect(() => {
    if (error) {
      history.replace(window.location.pathname);
    }
  }, [error, history]);

  useTrackUser(isAuthenticated);

  return isAuthenticated ? (
    children
  ) : error !== undefined ? (
    <AccessDeniedCallout />
  ) : (
    <div className={styles.loading_page_container}>
      <Spinner size={SPINNER_SIZE} />
    </div>
  );
}

function useTrackUser(enabled: boolean) {
  const { data: currentUser } = useCurrentUser(enabled);
  const { data: tenant } = useTenant(enabled);

  const analytics = useAnalytics();

  useEffect(() => {
    const userId = currentUser?.id;
    const tenantId = tenant?.id;
    const instanceId = window.location.hostname;
    if (userId && tenantId) {
      const distinctId = `${instanceId}:${tenantId.toString()}.${userId.toString()}`;
      analytics.identify(distinctId, {
        $name: currentUser?.displayName,
        [ANALYTICS_USER_FIRST_NAME]: currentUser?.firstName,
        [ANALYTICS_USER_LAST_NAME]: currentUser?.lastName,
        [ANALYTICS_USER_EMAIL]: currentUser?.email,
        [ANALYTICS_USER_ROLE]: currentUser?.role,
        [ANALYTICS_USER_TENANT]: tenant?.name,
        [ANALYTICS_USER_INSTANCE]: instanceId,
      });
    }
  }, [analytics, currentUser, tenant]);
}
