import { Part } from "@aletiq/types";
import React from "react";
import {
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Popover2,
} from "../../../../components";
import { RevisionPreviewButton } from "../../../../components/revisions";
import { useTranslations } from "../../../../util";

export default function IterationButtonGroup(props: {
  onView: () => void;
  onDownload: () => void;
  onExportAsStep: () => void;
  onCompleteNomenclature: () => void;
  onDelete: () => void;
  canView?: boolean;
  hasComponents?: boolean;
  hasCompleteBom?: boolean;
  part: Part;
  isDeleteDisabled?: boolean;
  isNomenclatureDisabled?: boolean;
  isEditable?: boolean;
}) {
  const {
    onView,
    onDownload,
    onExportAsStep,
    onCompleteNomenclature,
    onDelete,
    canView,
    hasComponents,
    hasCompleteBom,
    part,
    isDeleteDisabled,
    isNomenclatureDisabled,
    isEditable,
  } = props;

  const tr = useTranslations();

  return (
    <ButtonGroup isDense>
      {part.type !== "drawing" && (
        <RevisionPreviewButton onPreview={onView} isDisabled={!canView} />
      )}
      <Popover2
        content={
          <Menu isDense>
            {hasComponents && (
              <MenuItem
                isDense
                disabled={isNomenclatureDisabled}
                icon="diagram-tree"
                text={tr.translateAsString(
                  hasCompleteBom
                    ? "part.details.revisions.nomenclature"
                    : "part.details.modify.bom"
                )}
                onClick={onCompleteNomenclature}
              />
            )}
            <MenuItem
              isDense
              icon="download"
              onClick={() => onDownload()}
              text={tr.translateAsString(
                "part.details.revisions.nomenclature.download"
              )}
            />
            {part.type !== "drawing" && (
              <MenuItem
                isDense
                icon="export"
                onClick={onExportAsStep}
                text={tr.translate("part.details.revisions.export")}
              />
            )}
            {isEditable && (
              <MenuItem
                isDense
                icon="trash"
                disabled={isDeleteDisabled || !isEditable}
                onClick={onDelete}
                text={tr.translateAsString(
                  "document.details.revisions.delete-draft"
                )}
              />
            )}
          </Menu>
        }
      >
        <Button isDense icon="more" />
      </Popover2>
    </ButtonGroup>
  );
}
