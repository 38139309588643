import { PartQueryFilterParams, PartType } from "@aletiq/types";
import { Position } from "@blueprintjs/core";
import React from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  DateFilter,
  EditColumnButton,
  MoreFilters,
  OwnerFilter,
  PartOwnerFilter,
  PartTypesFilter,
  Popover2,
  ProjectFilter,
  SearchInput,
  Tooltip,
} from "../../../components";
import PartFormatFilter from "../../../components/filter/PartFormatFilter";
import {
  ColumnOptionConfig,
  HandleClear,
  HandleFilter,
  useHasPermission,
} from "../../../hooks";
import { useTranslations } from "../../../util";
import PartCreationMenu from "../common/PartCreationMenu";
import { PartColumn } from "../hooks/usePartTableColumns";
import styles from "./PartsActionBar.module.scss";
import SelectPartColumn from "./SelectPartColumn";

export default function PartsActionBar(props: {
  filter: PartQueryFilterParams;
  handleFilter: HandleFilter<PartQueryFilterParams>;
  handleClear: HandleClear<PartQueryFilterParams>;
  minimal?: boolean;
  projectId?: number;
  onCreatePart: (type: PartType, isStandard: boolean) => void;
  onLinkPart: (projectId: number) => void;
  withProjectFilter: boolean;
  allowStandard?: boolean;
  columnConfig: ColumnOptionConfig<PartColumn>;
  onToggleColumn: (column: PartColumn) => void;
}) {
  const {
    filter,
    minimal,
    projectId,
    withProjectFilter,
    onCreatePart,
    onLinkPart,
    handleFilter,
    handleClear,
    allowStandard,
    columnConfig,
    onToggleColumn,
  } = props;
  const tr = useTranslations();

  const canCreateParts = useHasPermission("create:parts");
  const canUpdateParts = useHasPermission("update:parts");

  return (
    <ActionBar>
      <Tooltip
        content={tr.translateAsString("part.view.tooltip-search")}
        position="top-left"
      >
        <SearchInput
          isDense
          placeholder={tr.translateAsString("generic.action.search")}
          value={filter?.search ?? ""}
          onChange={handleFilter("search")}
          className={styles.searchbar}
        />
      </Tooltip>
      <PartTypesFilter
        selected={filter?.partTypes ?? []}
        onSelect={handleFilter("partTypes")}
        onClear={handleClear(["partTypes"])}
      />
      <PartOwnerFilter
        selected={filter?.lockedOwners ?? []}
        noLockedOwnerSelected={filter?.emptyLockedOwner ?? false}
        onSelect={handleFilter("lockedOwners")}
        onSelectNoLockedOwner={handleFilter("emptyLockedOwner")}
        onClear={handleClear(["lockedOwners", "emptyLockedOwner"])}
      />
      {withProjectFilter && (
        <ProjectFilter
          selected={filter.projects ?? []}
          noProjectSelected={filter?.emptyProject ?? false}
          onSelect={handleFilter("projects")}
          onSelectNone={handleFilter("emptyProject")}
          onClear={handleClear(["projects", "emptyProject"])}
        />
      )}
      <MoreFilters
        filterValues={filter}
        additionalFilters={["createdAt", "updatedAt", "owners", "formats"]}
        onClear={handleClear}
      >
        <PartFormatFilter
          selected={filter.formats ?? []}
          onSelect={handleFilter("formats")}
          onClear={handleClear(["formats"])}
        />
        <OwnerFilter
          selected={filter.owners ?? []}
          onSelect={handleFilter("owners")}
          onClear={handleClear(["owners"])}
          withoutEmptyOwner
        />
        <DateFilter
          selected={filter?.createdAt}
          onSelect={handleFilter("createdAt")}
          label={tr.translateAsString("generic.label.creation-date.title")}
        />
        <DateFilter
          selected={filter?.updatedAt}
          onSelect={handleFilter("updatedAt")}
          label={tr.translateAsString("generic.label.last-update.title")}
        />
      </MoreFilters>
      <AlignRight />

      <SelectPartColumn
        columnConfig={columnConfig}
        onToggleColumn={onToggleColumn}
      >
        <EditColumnButton />
      </SelectPartColumn>

      {projectId && canUpdateParts && (
        <Button
          isDense
          icon="new-link"
          intent="secondary"
          onClick={() => onLinkPart(projectId)}
        >
          {!minimal && tr.translate("part.view.action.link")}
        </Button>
      )}
      {canCreateParts && (
        <Popover2
          intent="primary"
          position={Position.BOTTOM_RIGHT}
          content={
            <PartCreationMenu
              allowNormal
              allowDrawing
              allowStandard={allowStandard}
              onCreatePart={onCreatePart}
            />
          }
          minimal
        >
          <Button isDense icon="plus" intent="secondary">
            {!minimal && tr.translate("part.view.action.new")}
          </Button>
        </Popover2>
      )}
    </ActionBar>
  );
}
