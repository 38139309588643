import { Button } from "@aletiq/design-system";
import { TaskQueryFilterParams } from "@aletiq/types";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";
import { ANALYTICS_VISITED_TASKS, useTrackEvent } from "../../../analytics";
import {
  ActionBar,
  AlignRight,
  PageContents,
  PageTitle,
} from "../../../components";
import { useFilter, useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { ProcessCreationDialog } from "../dialogs/ProcessCreationDialog";
import { useTasks } from "../hooks";
import TaskFilters from "./TaskFilters";
import styles from "./Tasks.module.scss";
import TasksList from "./TasksList";

export default function Tasks() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_TASKS);

  const {
    filter: params,
    handleFilter,
    handleClear,
  } = useFilter<TaskQueryFilterParams>({
    emptyOwner: false,
    search: "",
  });
  const [createProcessDialog, setCreateProcessDialog] = useState(false);

  const { data: tasks = [], isLoading } = useTasks({
    ...params,
    workflowIsArchived: false,
  });

  const canCreateWorkflows = useHasPermission("create:workflows");

  const areTasksFiltered = useMemo(() => {
    for (const param in params) {
      if (param) return true;
    }
    return false;
  }, [params]);

  const handleProcessDialogCreation = (value: boolean) => () => {
    setCreateProcessDialog(value);
  };

  return (
    <PageContents domTitle={tr.translateAsString("page.title.task")}>
      <div className={styles.page}>
        <PageTitle icon="tick">
          {tr.translate("navbar.workflows.tasks")}
        </PageTitle>
        <ActionBar>
          <TaskFilters
            params={params}
            handleFilter={handleFilter}
            handleClear={handleClear}
          />
          <AlignRight />
          <Button
            intent="secondary"
            icon="plus"
            onClick={handleProcessDialogCreation(true)}
            isDisabled={!canCreateWorkflows}
            isDense
          >
            {tr.translate("task.action.new-process")}
          </Button>
        </ActionBar>
        <>
          {isLoading && <Spinner />}
          {tasks.length === 0 && !isLoading && (
            <NonIdealState
              icon={
                <img src="/assets/no-data.svg" height={80} alt="no tasks" />
              }
              description={tr.translate(
                areTasksFiltered
                  ? "tasklist.no-data.filtered"
                  : "tasklist.no-data"
              )}
            />
          )}
        </>
        {tasks.length > 0 && !isLoading && (
          <TasksList
            isLoading={isLoading}
            tasks={tasks}
            search={params.search ?? ""}
          />
        )}
      </div>
      {createProcessDialog && (
        <ProcessCreationDialog onClose={handleProcessDialogCreation(false)} />
      )}
    </PageContents>
  );
}
