import { CheckBox, H3, Icon, Tree } from "@aletiq/design-system";
import { TreeNodeInfo } from "@blueprintjs/core";
import React from "react";
import { AlignRight, PartIcon } from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./PartViewerBom.module.scss";
import { SceneComponent } from "./types";

export default function PartViewerBom(props: {
  components: SceneComponent[];
  onFoldComponent: (comp: SceneComponent) => void;
  onHideComponent: (comp: SceneComponent) => void;
  onSelectComponent: (comp: SceneComponent) => void;
  onClose: () => void;
}) {
  const {
    components,
    onFoldComponent,
    onHideComponent,
    onSelectComponent,
    onClose,
  } = props;
  const tr = useTranslations();

  const handleToggleComponentVisibility = (
    event: React.MouseEvent,
    component: SceneComponent
  ) => {
    // don't select / unselect component
    event.stopPropagation();
    onHideComponent(component);
  };

  const handleFoldComponent = (node: TreeNodeInfo) => {
    node.nodeData && onFoldComponent(node.nodeData as SceneComponent);
  };

  return (
    <div className={styles.bom}>
      <div className={styles.bom_header}>
        <H3>{tr.translate("part.dialog.view.tooltip.bom")}</H3>
        <AlignRight />
        <Icon
          icon="cross"
          clickable
          onClick={onClose}
          size={20}
          className={styles.cross}
        />
      </div>
      <Tree
        isDense
        contents={components.map((component) => ({
          id: component.id,
          label: (
            <div
              key={component.instanceName}
              className={styles.bom_item}
              style={{
                paddingLeft: `${
                  40 * component.depth -
                  (component.type === "assembly" ? 22 : 0)
                }px`,
              }}
            >
              <CheckBox
                isDense
                checked={component.isVisible}
                onClick={(event) =>
                  handleToggleComponentVisibility(event, component)
                }
                className={styles.checkbox}
              />
              <PartIcon
                type={component?.type || "part"}
                color="var(--blue70)"
                inline
              />
              {component.name}
            </div>
          ),
          hasCaret: component?.type === "assembly",
          isSelected: component.isSelected,
          isExpanded: component.isExpanded,
          disabled: !component.isVisible,
          nodeData: component,
        }))}
        onNodeClick={(node) =>
          onSelectComponent(node.nodeData as SceneComponent)
        }
        onNodeCollapse={handleFoldComponent}
        onNodeExpand={handleFoldComponent}
        className={styles.tree}
      />
    </div>
  );
}
