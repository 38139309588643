import { PassportField, ProjectField } from "@aletiq/types";
import React, { useState } from "react";
import { ConfigOptionIcon, Dialog } from "../../../../components";
import { compareArray, useTranslations } from "../../../../util";
import { PassportConfigurationsInput } from "../../common";
import { useSetPassportConfigs } from "../../hooks";
import styles from "./UpdatePassportConfigDialog.module.scss";

export default function UpdatePassportConfigDialog(props: {
  configSpec: ProjectField[];
  passportId: number;
  passportConfig: PassportField[];
  onClose: () => void;
}) {
  const { configSpec, passportId, passportConfig, onClose } = props;
  const tr = useTranslations();

  const { mutateAsync: setPassportConfigs, isLoading: passportConfigUpdating } =
    useSetPassportConfigs();

  const [config, setConfig] = useState<Record<number, number[]>>(
    passportFieldsToConfig(passportConfig)
  );

  const handleSaveChanges = async () => {
    const oldConfig = passportFieldsToConfig(passportConfig);

    const newConfig = passportConfig
      .filter((field) => !compareArray(config[field.id], oldConfig[field.id]))
      .map((field) => ({
        passportId,
        fieldId: field.id,
        values: config[field.id],
      }));

    setPassportConfigs(newConfig, {
      onSettled: () => {
        onClose();
      },
    });
  };

  const handleUpdateConfig = (fieldId: number, valueIds: number[]) => {
    setConfig({ ...config, [fieldId]: valueIds });
  };

  return (
    <Dialog
      isOpen
      className={styles.dialog}
      icon={<ConfigOptionIcon />}
      title={tr.translateAsString("passport.dialogs.update-config.title")}
      onClose={onClose}
      onSecondaryClick={onClose}
      onPrimaryClick={handleSaveChanges}
      disablePrimary={configSpec.length === 0}
      submitting={passportConfigUpdating}
    >
      <p>
        {configSpec.length > 0
          ? tr.translate("passport.dialogs.update-config.subtitle")
          : tr.translate("passport.dialogs.update-config.no-config")}
      </p>
      <PassportConfigurationsInput
        configSpec={configSpec}
        config={config}
        onSetConfig={handleUpdateConfig}
      />
    </Dialog>
  );
}

function passportFieldsToConfig(
  fields: PassportField[]
): Record<number, number[]> {
  const config: Record<number, number[]> = {};
  fields.forEach((field) => {
    if (field.values !== [])
      config[field.id] = field.values.map((value) => value.id);
  });

  return config;
}
