import { DocumentOperationQueryParams } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { documentKeys } from "./queries";

export default function useDocumentRelatedOperations(
  documentId: number,
  revisionId: number,
  params?: DocumentOperationQueryParams
) {
  const api = useApi();

  return useQuery(
    documentKeys.allOperationBills(documentId, revisionId, params),
    () =>
      api.document.listDocumentRelatedOperations(documentId, revisionId, params)
  );
}
