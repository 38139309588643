import React, { useState } from "react";
import {
  Callout,
  Dialog,
  Field,
  FileInput,
  Input,
  TextArea,
} from "../../../../components";
import { ALLOWED_PART_FILE_TYPES, useTranslations } from "../../../../util";
import { usePart, useUploadCadFile } from "../../hooks";
import styles from "./UploadDesignFileDialog.module.scss";

export default function UploadDesignFileDialog(props: {
  partId: number;
  onClose: () => void;
}) {
  const { partId, onClose } = props;
  const tr = useTranslations();

  const { data: part } = usePart(partId);
  const type = part?.type ?? "part";
  const currentName = part?.lastIteration?.file?.name ?? "";

  const fileExtensions = ALLOWED_PART_FILE_TYPES[type];

  const [file, setFile] = useState<File>();
  const [revisionName, setRevisionName] = useState("");
  const [revisionDescription, setRevisionDescription] = useState("");

  const handleFileInput = (ev: React.FormEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files;

    setFile(files?.[0]);
  };

  const resetState = () => {
    setFile(undefined);
  };

  const { mutate: uploadCadFile, isLoading, error } = useUploadCadFile();

  const handleSubmit = async () => {
    if (file) {
      uploadCadFile(
        {
          partId,
          revision: { name: revisionName, description: revisionDescription },
          file,
        },
        {
          onSuccess: () => {
            resetState();
            onClose();
          },
        }
      );
    }
  };

  const handleCancel = () => {
    onClose();
    resetState();
  };

  const haveDuplicateName = currentName && file?.name !== currentName;

  return (
    <Dialog
      isOpen
      icon="upload"
      title={tr.translateAsString("part.dialog.upload-design.title")}
      onClose={onClose}
      onSecondaryClick={handleCancel}
      disablePrimary={file === undefined}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
    >
      <div className={styles.container}>
        <p>{tr.translate("part.dialog.upload-design.subtitle")}</p>
        <Field
          className={styles.field}
          label={
            <p>
              {tr.translate("part.dialog.upload-design.file.label", {
                extensions: fileExtensions.join(", "),
              })}
            </p>
          }
        >
          <FileInput
            fill
            files={file ? [file] : undefined}
            onInputChange={handleFileInput}
          />
        </Field>

        {file && (
          <Callout
            intent={haveDuplicateName ? "warning" : "default"}
            icon={haveDuplicateName ? "warning-sign" : "info-sign"}
          >
            {haveDuplicateName &&
              tr.translate("part.dialog.upload-design.callout.warning")}
            {!haveDuplicateName &&
              currentName &&
              tr.translate("part.dialog.upload-design.callout.info.updated", {
                name: currentName,
              })}
            {!haveDuplicateName &&
              !currentName &&
              tr.translate("part.dialog.upload-design.callout.info.created", {
                name: file?.name || "",
              })}
          </Callout>
        )}

        {part?.lastIteration.state !== "draft" && (
          <>
            <Field
              className={styles.field}
              label={tr.translate("part.dialog.approve-revision.name.label")}
            >
              <Input
                fill
                value={revisionName}
                placeholder={part?.lastIteration.name}
                onChange={setRevisionName}
              />
            </Field>

            <Field
              className={styles.field}
              label={tr.translate(
                "part.dialog.approve-revision.description.label"
              )}
            >
              <TextArea
                fill
                value={revisionDescription}
                placeholder={tr.translateAsString(
                  "part.dialog.approve-revision.description.placeholder"
                )}
                onChange={setRevisionDescription}
                growVertically
              />
            </Field>
          </>
        )}
        {error && <Callout intent="danger">{(error as Error).message}</Callout>}
      </div>
    </Dialog>
  );
}
