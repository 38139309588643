import { HighlightedText } from "@aletiq/design-system";
import { MenuDivider } from "@blueprintjs/core";
import React, { useState } from "react";
import { usePassportFileTypes } from "../../features/passport/hooks";
import {
  filterByString,
  sortByString,
  toggle,
  useTranslations,
} from "../../util";
import FilterMenu from "./FilterMenu";
import FilterMenuItem from "./FilterMenuItem";
import FilterTemplate from "./FilterTemplate";

export default function PassportFileTypeFilter(props: {
  selected: number[];
  noTypeSelected: boolean;
  onSelect: (values?: number[]) => void;
  onSelectNoType: (value?: boolean) => void;
  onClear: () => void;
}) {
  const { selected, onClear, noTypeSelected, onSelectNoType, onSelect } = props;
  const tr = useTranslations();

  const [search, setSearch] = useState("");
  const { data: passportTypes = [] } = usePassportFileTypes();

  const handleSelect = (value: number) => () =>
    onSelect(toggle(selected, value));

  const handleSelectEmpty = () => {
    onSelectNoType(!noTypeSelected);
  };

  const sortedTypes = sortByString(passportTypes, (type) => type.value);

  const filteredTypes = filterByString(
    sortedTypes,
    (user) => user.value,
    search
  );

  return (
    <FilterTemplate
      count={noTypeSelected ? selected.length + 1 : selected.length}
      onClear={onClear}
      placeholder={tr.translateAsString("filter.document-type.placeholder")}
      content={
        <FilterMenu search={search} onSearchChange={setSearch}>
          <FilterMenuItem
            selected={noTypeSelected}
            onSelect={handleSelectEmpty}
            text={tr.translate("filter.none")}
          />
          <MenuDivider />
          {filteredTypes.map((item) => (
            <FilterMenuItem
              key={item.id}
              selected={selected.includes(item.id)}
              onSelect={handleSelect(item.id)}
              text={<HighlightedText text={item.value} highlight={search} />}
            />
          ))}
        </FilterMenu>
      }
    />
  );
}
