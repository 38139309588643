import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { passportKeys } from "./queries";

export default function useAddPassportFiles(passportsIds: number[]) {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (params: { files: File[]; fileType?: number }) => {
      const { files, fileType } = params;

      for (const file of files) {
        const fileId = await api.document.createFile(file);
        for (const passport of passportsIds) {
          await api.passport.addPassportFile(passport, { fileId, fileType });
        }
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(passportKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.passport.file.upload"),
        });
      },
    }
  );
}
