import { ApiActivity } from "@aletiq/types";
import React from "react";
import DocumentActivity from "./DocumentActivity";
import PartActivity from "./PartActivity";
import PassportActivity from "./PassportActivity";
import ProductActivity from "./ProductActivity";
import TaskActivity from "./TaskActivity";

export default function ActivityContent(props: { activity: ApiActivity }) {
  const { activity } = props;

  return (
    <>
      <DocumentActivity activity={activity} />
      <PartActivity activity={activity} />
      <PassportActivity activity={activity} />
      <ProductActivity activity={activity} />
      <TaskActivity activity={activity} />
    </>
  );
}
