import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { passportKeys } from "./queries";

export default function usePassportFileTypes() {
  const api = useApi();
  return useQuery(passportKeys.allFiletypes(), () =>
    api.passport.listPassportFileTypes()
  );
}
