import React from "react";
import { User } from "../../../../../components";
import { useUser } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import ShareDocumentsRow from "./ShareDocumentsRow";

export default function UserRow(props: {
  userId: number;
  count: number;
  total: number;
  onAddAll: () => void;
  onRemoveAll: () => void;
}) {
  const { userId, count, total, onAddAll, onRemoveAll } = props;
  const tr = useTranslations();

  const { data: user } = useUser(userId);

  return (
    <ShareDocumentsRow
      onAddAll={onAddAll}
      onRemoveAll={onRemoveAll}
      icon={<User onlyInitial userId={userId} />}
      title={user?.displayName ?? ""}
      count={count}
      total={total}
      deleteAlertText={tr.translateAsString(
        "document.dialog.shared-documents.remove-user"
      )}
    />
  );
}
