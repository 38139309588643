import { ApiActivity, EntityId } from "@aletiq/types";
import React from "react";
import InlineStatusTag from "../../features/tasks/status/InlineStatusTag";
import { useTranslations } from "../../util";
import { UserName } from "../name";
import ActivityValue from "./ActivityValue";
import { findEntity, findWorkflow } from "./findEntity";
import ActivityEntityLink from "./links/ActivityEntityLink";
import ActivityWorkflowLink from "./links/ActivityWorkflowLink";

export default function TaskActivity(props: { activity: ApiActivity }) {
  const { activity } = props;
  const tr = useTranslations();

  const workflowLink = (id: number) => {
    const workflow = findWorkflow(activity.entities, id);
    return workflow ? <ActivityWorkflowLink workflow={workflow} /> : null;
  };

  const entityLink = (id: EntityId) => {
    const entity = findEntity(activity.entities, id);
    return entity ? <ActivityEntityLink entity={entity} /> : null;
  };

  switch (activity.type) {
    case "workflow_created":
      return tr.translate("activity.workflow_created", {
        workflow: workflowLink(activity.metadata.workflow),
      });

    case "workflow_deleted":
      return tr.translate("activity.workflow_deleted", {
        workflow: workflowLink(activity.metadata.workflow),
      });
    case "task_created": {
      const workflow = findWorkflow(
        activity.entities,
        activity.metadata.workflow
      );
      const task = workflow?.tasks?.find(
        (t) => t.id === activity.metadata.task
      );

      return tr.translate("activity.task_created", {
        task: <ActivityValue>{task?.title ?? null}</ActivityValue>,
        workflow: workflowLink(activity.metadata.workflow),
      });
    }

    case "task_status_updated": {
      const workflow = findWorkflow(
        activity.entities,
        activity.metadata.workflow
      );
      const task = workflow?.tasks?.find(
        (t) => t.id === activity.metadata.task
      );
      return tr.translate("activity.task_status_updated", {
        task: <ActivityValue>{task?.title ?? null}</ActivityValue>,
        workflow: workflowLink(activity.metadata.workflow),
        status: <InlineStatusTag status={activity.metadata.status} />,
      });
    }

    case "workflow_owner_changed":
      return tr.translate("activity.workflow_owner_changed", {
        workflow: workflowLink(activity.metadata.workflow),
        owner: (
          <ActivityValue>
            <UserName userId={activity.metadata.owner} />
          </ActivityValue>
        ),
      });

    case "workflow_relation_added":
      return tr.translate("activity.workflow_relation_added", {
        workflow: workflowLink(activity.metadata.workflow),
        entity: entityLink(activity.metadata.entity),
      });

    case "workflow_relation_removed":
      return tr.translate("activity.workflow_relation_removed", {
        workflow: workflowLink(activity.metadata.workflow),
        entity: entityLink(activity.metadata.entity),
      });

    case "workflow_archived":
      return tr.translate("activity.workflow_archived", {
        workflow: workflowLink(activity.metadata.workflow),
      });

    case "workflow_restored":
      return tr.translate("activity.workflow_restored", {
        workflow: workflowLink(activity.metadata.workflow),
      });

    case "workflow_renamed":
      return tr.translate("activity.workflow_renamed", {
        workflow: workflowLink(activity.metadata.workflow),
      });

    default:
      return null;
  }
}
