import React from "react";
import { toggle, useTranslations } from "../../util";
import FilterMenu from "../filter/FilterMenu";
import FilterMenuItem from "../filter/FilterMenuItem";
import FilterTemplate from "../filter/FilterTemplate";

export default function ActivitiesFilter(props: {
  selected: FeedOption[];
  onSelect: (values?: FeedOption[]) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const { selected, onClear, onSelect } = props;

  const handleSelect = (value: FeedOption) => () =>
    onSelect(toggle(selected, value));

  return (
    <FilterTemplate
      count={selected.length}
      onClear={onClear}
      placeholder={tr.translateAsString("filter.document-type.placeholder")}
      intent="primary"
      content={
        <FilterMenu intent="default">
          {feedOptionIds.map((item) => (
            <FilterMenuItem
              intent="primary"
              key={item}
              selected={selected.includes(item)}
              onSelect={handleSelect(item)}
              text={tr.translateAsString(feedOptions[item]?.label)}
            />
          ))}
        </FilterMenu>
      }
    />
  );
}

const feedOptions: Record<FeedOption, { id: string; label: string }> = {
  comments: {
    id: "comments",
    label: "process.activities.filter.comment",
  },
  actions: {
    id: "actions",
    label: "process.activities.filter.actions",
  },
};

const feedOptionIds: FeedOption[] = ["comments", "actions"];

export type FeedOption = "comments" | "actions";
