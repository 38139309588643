import { useState } from "react";
import { useProcessModel } from "../tasksSlice";

export default function useProcessForm() {
  const [title, setTitle] = useState("");
  const [modelId, setModel] = useState<number | null>(null);
  const [titleState, setTitleState] = useState<"empty" | "custom" | "model">(
    "empty"
  );
  const { data: selectedModel } = useProcessModel(modelId);

  const effectiveTitle =
    titleState === "empty"
      ? ""
      : titleState === "custom"
      ? title
      : selectedModel?.nomenclature ?? "";

  const onUpdateTitle = (newTitle: string) => {
    setTitleState("custom");
    setTitle(newTitle);
  };

  const handleModelChange = (modelId: number | null) => {
    setModel(modelId);
    setTitleState((current) => {
      if (current === "empty") return "model";
      return current;
    });
  };

  return {
    title: effectiveTitle,
    modelId: modelId,
    setCustomTitle: onUpdateTitle,
    setModelId: handleModelChange,
    selectedModel,
  };
}
