import { EntityId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { processKeys } from "./queries";

export default function useAddProcessesRelations() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (processes: { id: number; entities: EntityId[] }[]) => {
      for (let i = 0; i < processes.length; i++) {
        const processId = processes[i].id;
        const entities = processes[i].entities;
        const entityAlreadyLinked = await api.task.getProcessRelations(
          processId
        );

        const entitiesToAdd = entities.filter(
          (entToAdd) =>
            !entityAlreadyLinked.some(
              (entLinked) =>
                entToAdd.type === entLinked.type && entToAdd.id === entLinked.id
            )
        );

        for (let j = 0; j < entitiesToAdd.length; j++) {
          await api.task.addProcessRelation(processId, entitiesToAdd[j]);
        }
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(processKeys.allRelations());
        queryClient.invalidateQueries(processKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.processes.relations.create"),
        });
      },
    }
  );
}
