import { Property, PropertyValue } from "@aletiq/types";
import React from "react";
import { EditablePropertyMultiSelect } from "../../../../components";
import { useTranslations } from "../../../../util";

export function CustomMultiSelectField(props: {
  property: Property;
  propertyValue?: { type: "multi-enum"; value: string[] };
  onSubmit: (value: PropertyValue) => void;
  canEdit: boolean;
  hasDescriptionTooltip?: boolean;
  search?: string;
  minWidth?: number;
}) {
  const {
    property,
    propertyValue,
    canEdit,
    onSubmit,
    search,
    hasDescriptionTooltip,
    minWidth,
  } = props;
  const tr = useTranslations();

  const handleSubmit = (values: string[]) => {
    onSubmit({ type: "multi-enum", value: values });
  };

  const items = property.options
    .filter((opt) => !opt.deleted)
    .map((opt) => ({
      key: opt.name ?? -1,
      text: opt.label,
    }));

  return (
    <EditablePropertyMultiSelect
      values={propertyValue?.value ?? []}
      items={items}
      isEditable={canEdit}
      label={property.label}
      placeholder={tr.translateAsString("generic.action.modify-value", {
        value: property.label,
      })}
      emptyState={tr.translateAsString("generic.label.none")}
      tooltipText={hasDescriptionTooltip ? property.description : undefined}
      search={search}
      onSubmit={handleSubmit}
      minWidth={minWidth}
      inline
    />
  );
}
