import { ButtonSP, ButtonSPProps } from "@aletiq/design-system";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";

export type TreeButtonProps = {
  content?: JSX.Element;
  onClick?: () => void;
  isDisabled?: boolean;
} & ButtonSPProps;

export default function TreeButton(props: TreeButtonProps) {
  const { content, ...restProps } = props;

  return (
    <>
      {content ? (
        <Popover2 content={content} minimal>
          <ButtonSP {...restProps} />
        </Popover2>
      ) : (
        <ButtonSP {...restProps} />
      )}
    </>
  );
}
