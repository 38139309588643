import React, { useState } from "react";
import { Dialog, Field, NumericInput } from "../../../components";
import { isNotUndefined, useTranslations } from "../../../util";
import { useUpdateProductTreeComponentQuantity } from "../hooks";
import { ProductTree } from "../ProjectNomenclature/hooks";
import styles from "./Dialog.module.scss";

export default function EditComponentQuantitiesDialog(props: {
  components: ProductTree[];
  onClose: () => void;
}) {
  const { components, onClose } = props;
  const tr = useTranslations();

  const [quantity, setQuantity] = useState(1);

  const { mutate: updateQuantities, isLoading } =
    useUpdateProductTreeComponentQuantity();

  const handleSubmit = () => {
    updateQuantities(
      components
        .map((c) =>
          c.type === "project"
            ? {
                productId: c.parent.productId,
                definitionIdx: c.parent.definitionIdx,
                componentId: c.entry.entity,
                quantity,
              }
            : undefined
        )
        .filter(isNotUndefined),
      { onSettled: onClose }
    );
  };

  return (
    <Dialog
      isOpen
      icon="edit"
      title={tr.translateAsString("project.dialog.components.edit-qty.title", {
        count: components.length,
      })}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
      onClose={onClose}
    >
      <div className={styles.dialog_contents}>
        <p>{tr.translate("project.dialog.components.edit-qty.description")}</p>
        <Field label={tr.translate("generic.label.quantity")}>
          <NumericInput
            value={quantity}
            onValueChange={setQuantity}
            min={1}
            isFill
          />
        </Field>
      </div>
    </Dialog>
  );
}
