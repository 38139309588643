import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { documentKeys } from "./queries";

export default function useUnlinkDocument(documentId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (projectId: number) => {
      const documentProjects = await api.document.listDocumentProjects(
        documentId
      );
      const updated = documentProjects.filter((p) => p !== projectId);
      return api.document.updateDocumentProjects(documentId, updated);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: (error: Error) => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.document.unlink", {
            errMsg: error.message,
          }),
        });
      },
    }
  );
}
