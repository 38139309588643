import { Button } from "@aletiq/design-system";
import { IconName } from "@blueprintjs/core";
import React, { useState } from "react";
import { ActionBar, AlignRight, PropertyCard } from "../../../../components";
import { HandleClear, HandleFilter, useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { usePaginatedPassports } from "../../../passport/hooks";
import AttributeCards from "../../AttributeCards";
import {
  CreationDatePropertyCard,
  LastUpdatePropertyCard,
  PropertyCardFilters,
} from "../common";
import { useProperties } from "../hooks";
import useCountPassportsWithDescriptions from "../hooks/useCountPassportsWithDescriptions";
import { PropertyFilters } from "../types";
import { ALETIQ_PASSPORT_PROPERTIES } from "./AletiqOwnedProperties";
import { CreatePassportPropertyDialog } from "./Dialogs";
import {
  PassportPropertyCard,
  PropertyCardClient,
  PropertyCardState,
} from "./PassportPropertyCard";
import PassportPropertyTarget from "./PassportPropertyCard/PassportPropertyTarget";

export default function ArticlePassportsAttributes(props: {
  filters: PropertyFilters;
  handleFilter: HandleFilter<PropertyFilters>;
  handleClear: HandleClear<PropertyFilters>;
}) {
  const { filters, handleClear, handleFilter } = props;
  const tr = useTranslations();

  const [showCreationDialog, setShowCreationDialog] = useState(false);

  const canCreateAttribute = useHasPermission("create:custom-attributes");

  const { data: properties } = useProperties("passport");
  const { data: passportData } = usePaginatedPassports({ limit: 1 });
  const { data: passportsWithDescriptions = 0 } =
    useCountPassportsWithDescriptions();

  const handleOpenCreationDialog = () => {
    setShowCreationDialog(true);
  };

  const handleCloseDialog = () => setShowCreationDialog(false);

  const passportCount = passportData?.count ?? 0;
  const commonPropertyUses: { count: number; icon: IconName }[] = [
    {
      count: passportCount,
      icon: "id-number",
    },
  ];

  return (
    <AttributeCards>
      <ActionBar>
        <PropertyCardFilters
          filters={filters}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
        <AlignRight />
        <Button
          intent="secondary"
          icon="plus"
          isDisabled={!canCreateAttribute}
          onClick={handleOpenCreationDialog}
          isDense
        >
          {tr.translate("admin.attribute.new.button")}
        </Button>
      </ActionBar>
      {ALETIQ_PASSPORT_PROPERTIES.map((property, index) => (
        <PropertyCard
          key={index}
          type={property.type}
          label={tr.translateAsString(property.label)}
          description={tr.translateAsString(property.description)}
          target={<PassportPropertyTarget target={property.target} />}
          filters={filters}
          usedIn={
            property.id === "description"
              ? [{ count: passportsWithDescriptions, icon: "id-number" }]
              : commonPropertyUses
          }
        />
      ))}
      <PropertyCardClient filters={filters} usedIn={commonPropertyUses} />
      <PropertyCardState filters={filters} usedIn={commonPropertyUses} />
      <CreationDatePropertyCard
        entity="passport"
        filters={filters}
        target={<PassportPropertyTarget target="all" />}
        usedIn={commonPropertyUses}
      />
      <LastUpdatePropertyCard
        entity="passport"
        filters={filters}
        target={<PassportPropertyTarget target="all" />}
        usedIn={commonPropertyUses}
      />
      {properties?.map((property) => (
        <PassportPropertyCard
          property={property}
          key={property.id}
          filters={filters}
        />
      ))}
      {showCreationDialog && (
        <CreatePassportPropertyDialog onClose={handleCloseDialog} />
      )}
    </AttributeCards>
  );
}
