import { Switch } from "@aletiq/design-system";
import React from "react";
import { ColumnOptionConfig } from "../../hooks";
import { AlignRight } from "../AlignRight";
import { ColumnOption } from "./SelectColumns";
import styles from "./SelectColumns.module.scss";

export default function SelectOptionalColumn<T extends string | number>(props: {
  item: ColumnOption<T>;
  config: ColumnOptionConfig<T>;
  onToggleColumn: (column: T) => void;
}) {
  const { item, config, onToggleColumn } = props;

  const handleToggleColumn = () => onToggleColumn(item.id);

  return (
    <div className={styles.row}>
      {item.label}
      <AlignRight />
      <Switch checked={config[item.id]} isDense onClick={handleToggleColumn} />
    </div>
  );
}
