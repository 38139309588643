import { Passport } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../util";
import NameLink from "./NameLink";
import passportLinkProps from "./props/passportLinkProps";

export default function PassportLink(props: {
  passport: Passport | undefined;
  hasIcon?: boolean;
  className?: string;
  search?: string;
  onClick?: () => void;
}) {
  const { className, passport, hasIcon, search, onClick } = props;
  const tr = useTranslations();
  const linkProps = passportLinkProps(tr, passport);

  return (
    <NameLink
      className={className}
      hasIcon={hasIcon}
      search={search}
      onClick={onClick}
      {...linkProps}
    />
  );
}
