import { Icon } from "@aletiq/design-system";
import React from "react";
import { Translations } from "../../../util";
import InlineProjectThumbnail from "../InlineProjectThumbnail";

export default function productLinkProps(
  tr: Translations,
  project:
    | { id: number; isTool: boolean; isDeleted: boolean; name: string }
    | undefined,
  hasThumbnail?: boolean
) {
  const projectType = project?.isTool ? "tool" : "article";
  const icon =
    hasThumbnail && project?.id ? (
      <InlineProjectThumbnail
        projectId={project.id}
        projectType={projectType}
      />
    ) : (
      <Icon
        inline
        icon={project?.isTool ? "wrench" : "projects"}
        intent={project?.isDeleted ? "none" : "primary"}
      />
    );

  let tooltip = undefined;
  if (project?.isDeleted) {
    tooltip = tr.translateAsString("link.deleted-object", { type: "product" });
  }

  return {
    icon,
    tooltip,
    isDisabled: project?.isDeleted ?? false,
    text: project?.name ?? "",
    location: { path: `/project/${project?.id}` },
  };
}
