import { ProjectDefinition } from "@aletiq/types";
import React, { useState } from "react";
import { Dialog, DialogHint, Field, Input } from "../../../components";
import { useTranslations } from "../../../util";
import { useUpdateProjectDefinition } from "../hooks";

export default function EditProjectDefinitionDialog(props: {
  projectId: number;
  definition: ProjectDefinition;
  onClose: () => void;
}) {
  const { projectId, definition, onClose } = props;
  const tr = useTranslations();

  const [name, setName] = useState(definition.name);

  const { isLoading, mutate } = useUpdateProjectDefinition(
    projectId,
    definition.index
  );

  const handleRename = () => {
    mutate(
      {
        ...definition,
        name,
      },
      {
        onSuccess: () => onClose(),
        onError: () => setName(definition.name),
      }
    );
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      title={tr.translateAsString("project.definition.edit.title")}
      icon="edit"
      submitting={isLoading}
      disablePrimary={name === ""}
      onPrimaryClick={handleRename}
    >
      <DialogHint>{tr.translate("project.definition.edit.caption")}</DialogHint>
      <Field
        isMandatory
        label={tr.translate("project.definition.name.field-label")}
      >
        <Input
          fill
          removeClearButton
          onChange={setName}
          value={name}
          intent="primary"
        />
      </Field>
    </Dialog>
  );
}
