import React from "react";
import { CellProps, TableExpandedToggleProps } from "react-table";
import { ExpandIcon, OffsetCell } from "../../../../components";
import ProductLink from "../../../../components/link/ProductLink";
import { ProductComponentTree } from "../../ProjectNomenclature/hooks";

export default function ProjectNameCell(
  props: CellProps<ProductComponentTree> & TableExpandedToggleProps
) {
  const {
    row: { depth, toggleRowExpanded, isExpanded, original: component },
  } = props;

  const handleExpandRow = () => {
    if (!canExpand) {
      return;
    }
    toggleRowExpanded();
  };

  const canExpand = component.subRows.length > 0;

  return (
    <OffsetCell depth={depth}>
      <ExpandIcon
        hasExpand={canExpand}
        isExpanded={isExpanded}
        onExpand={handleExpandRow}
      />
      <ProductLink
        product={component.product}
        hasIcon
        onClick={handleExpandRow}
      />
    </OffsetCell>
  );
}
