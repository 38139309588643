import { NonIdealState, Spinner, SpinnerSize, Tab } from "@blueprintjs/core";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { ANALYTICS_VISITED_SEARCH, useTrackEvent } from "../../analytics";
import { Callout, PageContents, PageTitle, Tabs } from "../../components";
import { useTranslations } from "../../util";
import DocumentList from "../documents/Documents/DocumentList";
import {
  useResolvedDocuments,
  useShowDocumentPreview,
} from "../documents/hooks";
import { useResolvedPassports } from "../passport/hooks";
import PassportList from "../passport/PassportList";
import AllPartsList from "../pdm/AllParts/AllPartsList";
import ProductList from "../project/ProductList";
import TasksList from "../tasks/Tasks/TasksList";
import { useSearch } from "./hooks";
import PassportFilesSearchList from "./PassportFilesSearchList";
import ProcessSearchList from "./ProcessSearchList";
import styles from "./Search.module.scss";
import { ResolvedSearchResult } from "./searchTypes";
import TabHeader from "./TabHeaderSearch";

export default function Search() {
  const tr = useTranslations();
  const match = useRouteMatch({
    path: "/search/:searchString",
  }) as { params?: { searchString?: string } } | null;
  const searchValue = decodeURIComponent(match?.params?.searchString ?? "");
  useTrackEvent(ANALYTICS_VISITED_SEARCH);

  const { data: searchResult, isLoading: isSearchLoading } =
    useSearch(searchValue);
  const { documents, isLoading: isDocumentLoading } = useResolvedDocuments(
    searchResult?.documents
  );
  const { data: passports, isLoading: isPassportLoading } =
    useResolvedPassports(searchResult?.passports || []);

  const resolvedSearchResult = {
    ...searchResult,
    passports,
    documents,
  };

  const isLoading = isPassportLoading || isSearchLoading || isDocumentLoading;

  return (
    <PageContents domTitle={tr.translateAsString("page.title.search")}>
      <div className={styles.page}>
        <PageTitle icon="search">
          {tr.translate("search.search.result-for", { search: searchValue })}
        </PageTitle>
        {!isLoading && searchResult && (
          <SearchInner
            key={searchValue}
            searchValue={searchValue}
            searchResult={resolvedSearchResult}
          />
        )}
        {isLoading && (
          <NonIdealState>
            <Spinner size={SpinnerSize.LARGE} />
          </NonIdealState>
        )}
      </div>
    </PageContents>
  );
}

function SearchInner(props: {
  searchValue: string;
  searchResult: ResolvedSearchResult;
}) {
  const { searchValue, searchResult } = props;
  const tr = useTranslations();

  const showDocumentPreview = useShowDocumentPreview();

  const getInitialTab = () => {
    if ((searchResult.projects?.length ?? 0) > 0) return "products";
    if ((searchResult.passports?.length ?? 0) > 0) return "passports";
    if ((searchResult.documents?.length ?? 0) > 0) return "document";
    if ((searchResult.parts?.length ?? 0) > 0) return "parts";
    if ((searchResult.processes?.length ?? 0) > 0) return "workflows";
    if ((searchResult.tasks?.length ?? 0) > 0) return "tasks";
    if ((searchResult.passportFiles?.length ?? 0) > 0) return "passport-files";
    return "projects";
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab);

  const resultTotal =
    (searchResult.tasks?.length ?? 0) +
    (searchResult.processes?.length ?? 0) +
    (searchResult.parts?.length ?? 0) +
    (searchResult.passports?.length ?? 0) +
    (searchResult.projects?.length ?? 0) +
    (searchResult.documents?.length ?? 0) +
    (searchResult.passportFiles?.length ?? 0);

  const hasMaxResult =
    searchResult.tasks?.length === 100 ||
    searchResult.processes?.length === 100 ||
    searchResult.parts?.length === 100 ||
    searchResult.passports?.length === 100 ||
    searchResult.projects?.length === 100 ||
    searchResult.documents?.length === 100 ||
    searchResult.passportFiles?.length === 100;

  if (resultTotal === 0) {
    return (
      <NonIdealState
        icon="search"
        title={tr.translate("search.search.empty-state", {
          search: searchValue,
        })}
      />
    );
  }

  return (
    <>
      {hasMaxResult ? (
        <Callout
          className={styles.callout}
          intent="warning"
          icon="info-sign"
          title={tr.translateAsString("search.search.partial-header")}
        >
          {tr.translate("search.search.partial-content")}
        </Callout>
      ) : null}
      <Tabs
        isDense
        renderActiveTabPanelOnly
        selectedTabId={selectedTab}
        onChange={(id: string) => setSelectedTab(id)}
        className={styles.tabs}
      >
        {/* Project */}
        <Tab
          id="products"
          panelClassName={styles.tab_panel}
          disabled={
            !searchResult.projects || searchResult.projects.length === 0
          }
          panel={
            <ProductList
              products={searchResult?.projects ?? []}
              searchString={searchValue}
            />
          }
        >
          <TabHeader
            icon="projects"
            text={tr.translate("search.tab.products", {
              length: searchResult?.projects?.length,
            })}
          />
        </Tab>
        {/* Passport */}
        <Tab
          id="passports"
          panelClassName={styles.tab_panel}
          disabled={
            !searchResult.passports || searchResult.passports.length === 0
          }
          panel={
            <PassportList
              filteredPassports={searchResult?.passports ?? []}
              search={searchValue}
            />
          }
        >
          <TabHeader
            icon="id-number"
            text={tr.translate("search.tab.passports", {
              length: searchResult?.passports?.length,
            })}
          />
        </Tab>
        {/* passport files */}
        <Tab
          id="passport-files"
          panelClassName={styles.tab_panel}
          disabled={
            !searchResult.passportFiles ||
            searchResult.passportFiles?.length === 0
          }
          panel={
            <PassportFilesSearchList
              files={searchResult?.passportFiles ?? []}
              search={searchValue}
            />
          }
        >
          <TabHeader
            icon="paperclip"
            text={tr.translate("search.tab.passport-files", {
              length: searchResult?.passportFiles?.length,
            })}
          />
        </Tab>
        {/* Document */}
        <Tab
          id="document"
          panelClassName={styles.tab_panel}
          disabled={
            !searchResult.documents || searchResult.documents.length === 0
          }
          panel={
            <DocumentList
              nodes={searchResult?.documents ?? []}
              searchString={searchValue}
              onShowFilePreview={(documentId: number) =>
                showDocumentPreview({ documentId })
              }
            />
          }
        >
          <TabHeader
            icon="document"
            text={tr.translate("search.tab.files", {
              length: searchResult?.documents?.length,
            })}
          />
        </Tab>
        {/* Parts */}
        <Tab
          id="parts"
          panelClassName={styles.tab_panel}
          disabled={!searchResult.parts || searchResult.parts.length === 0}
          panel={
            <AllPartsList
              search={searchValue}
              parts={searchResult.parts ?? []}
            />
          }
        >
          <TabHeader
            icon="cube"
            text={tr.translate("search.tab.parts", {
              length: searchResult?.parts?.length,
            })}
          />
        </Tab>
        {/* Process */}
        <Tab
          id="workflows"
          panelClassName={styles.tab_panel}
          disabled={
            !searchResult.processes || searchResult.processes.length === 0
          }
          panel={
            <ProcessSearchList
              processes={searchResult?.processes ?? []}
              searchString={searchValue}
            />
          }
        >
          <TabHeader
            icon="flow-linear"
            text={tr.translate("search.tab.workflows", {
              length: searchResult?.processes?.length,
            })}
          />
        </Tab>
        {/* TASKS */}
        <Tab
          id="tasks"
          panelClassName={styles.tab_panel}
          disabled={!searchResult.tasks || searchResult.tasks?.length === 0}
          panel={
            <TasksList tasks={searchResult?.tasks ?? []} search={searchValue} />
          }
        >
          <TabHeader
            icon="tick"
            text={tr.translate("search.tab.tasks", {
              length: searchResult?.tasks?.length,
            })}
          />
        </Tab>
      </Tabs>
    </>
  );
}
