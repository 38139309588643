import React from "react";
import { Card, PageTitle, Switch } from "../../../components";
import { useCurrentUser } from "../../../hooks";
import { useTranslations } from "../../../util";
import {
  ANALYTICS_VISITED_NOTIFICATION_SETTINGS,
  useTrackEvent,
} from "../../../analytics";
import { AdministrationPage } from "../common";
import { useUpdateNotificationSettings } from "../hooks";
import styles from "./NotificationSettings.module.scss";

export default function NotificationSettings() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_NOTIFICATION_SETTINGS);

  const { data: currentUser } = useCurrentUser();
  const { mutate: updateNotificationSettings } =
    useUpdateNotificationSettings();

  const handleUpdateNotificationSettings = () => {
    currentUser && updateNotificationSettings(!currentUser.allowEmailNotif);
  };

  return (
    <AdministrationPage>
      <PageTitle icon="notifications">
        {tr.translate("admin.notifications.title")}
      </PageTitle>
      <Card
        icon="envelope"
        title={tr.translate("admin.notifications.setting.title")}
        className={styles.container}
        headerActions={
          <Switch
            isDense
            checked={currentUser?.allowEmailNotif}
            onChange={handleUpdateNotificationSettings}
            leftPosition
          />
        }
      >
        {tr.translate("admin.notifications.setting.description")}
      </Card>
    </AdministrationPage>
  );
}
