import React from "react";
import { PageContents } from "../../../components";
import AdministrationTabs from "./AdministrationTabs";

export default function AdministrationPage(props: {
  children: React.ReactNode;
}) {
  const { children } = props;

  return (
    <PageContents leftPanel={<AdministrationTabs />}>{children}</PageContents>
  );
}
