import { ActiveDocumentRevision } from "@aletiq/types";
import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  DropdownButton,
  GenericStateTag,
  GenericStatusTag,
  HighlightedText,
  Menu,
  MenuItem,
  Popover2,
} from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { NewVersionDoc } from "../../../hooks";
import AutoUpdateTag from "../../AutoUpdateTag";
import styles from "./ActiveRevisionCell.module.scss";

export default function ActiveRevisionCell(props: {
  document: NewVersionDoc;
  search?: string;
  onChange: (revision: ActiveDocumentRevision) => void;
}) {
  const { document, search, onChange } = props;
  const tr = useTranslations();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedRevision, setSelectedRevision] = useState<
    ActiveDocumentRevision | undefined
  >(document.activeRevision);

  const handleToggleEdition = () => {
    setIsEditing(!isEditing);
  };

  const handleSetRevision = (revision: ActiveDocumentRevision) => () => {
    setSelectedRevision(revision);
    if (document.state === "unconfirmed") {
      onChange(revision);
    }
  };

  const handleSubmit = () => {
    selectedRevision && onChange(selectedRevision);
  };

  const selectedRevisionName = document.doc.revisions.find(
    (r) => r.id === selectedRevision?.revisionId
  )?.revisionName;

  const lastApprovedRevision = document.doc.revisions.find(
    (r) => r.state === "approved"
  );

  return (
    <>
      {/* directly show select for unconfirmed version docs */}
      {(isEditing || document.state === "unconfirmed") && (
        <div className={styles.editable}>
          <Popover2
            position="bottom-left"
            className={styles.dropdown}
            content={
              <Menu isDense>
                <MenuItem
                  isDense
                  disabled={!lastApprovedRevision}
                  active={selectedRevision?.type === "last-approved"}
                  onClick={handleSetRevision({
                    type: "last-approved",
                    revisionId: lastApprovedRevision?.id ?? 0,
                  })}
                  text={
                    <>
                      <div>
                        {tr.translate(
                          "project.operation-bill.operation-document.revision.last"
                        )}
                      </div>
                      <div className={styles.revision_description}>
                        {tr.translate(
                          "project.operation-bill.operation-document.revision.last.caption"
                        )}
                      </div>
                    </>
                  }
                />
                {document.doc.revisions.map((revision) => (
                  <MenuItem
                    isDense
                    key={revision.id}
                    active={
                      selectedRevision?.type === "user-defined" &&
                      revision.id === selectedRevision?.revisionId
                    }
                    onClick={handleSetRevision({
                      type: "user-defined",
                      revisionId: revision.id,
                    })}
                    text={
                      <GenericStateTag
                        name={revision.revisionName}
                        state={revision.state}
                      />
                    }
                  />
                ))}
              </Menu>
            }
          >
            <DropdownButton
              dense
              fill
              text={
                selectedRevision?.type === "last-approved"
                  ? tr.translate(
                      "project.operation-bill.operation-document.revision.last-value",
                      { value: selectedRevisionName }
                    )
                  : selectedRevisionName ?? tr.translate("generic.label.none.f")
              }
            />
          </Popover2>
          {document.state === "confirmed" && (
            <ButtonGroup isDense className={styles.button_group}>
              <Button
                isDense
                isDisabled={!selectedRevision}
                onClick={handleSubmit}
                icon="tick"
                intent="success"
              />
              <Button isDense icon="cross" onClick={handleToggleEdition} />
            </ButtonGroup>
          )}
        </div>
      )}

      {/* Show active revision with edit button on hover for confirmed version docs */}
      {!isEditing && document.state === "confirmed" && (
        <>
          <div className={styles.revision}>
            <HighlightedText text={document.revisionName} highlight={search} />
            {document.activeRevision.type === "last-approved" ? (
              <AutoUpdateTag />
            ) : (
              <GenericStatusTag
                status={
                  document.new
                    ? "new"
                    : document.updated
                    ? "updated"
                    : document.deleted
                    ? "deleted"
                    : "unchanged"
                }
              />
            )}
          </div>
          <Button
            isDense
            intent="array_primary"
            icon="edit"
            className={styles.hover_button}
            onClick={handleToggleEdition}
          />
        </>
      )}
    </>
  );
}
