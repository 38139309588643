import { Passport } from "@aletiq/types";
import React, { useState } from "react";
import {
  EditablePropertyButtonGroup,
  EditablePropertyTemplate,
} from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProject } from "../../../project/hooks";
import useProjectBills from "../../../project/Operations/hooks/useProjectBills";
import OperationBillSelect from "../../../project/Operations/OperationBillSelect";
import ArticleBillIndexLink from "../../../project/Version/ArticleBillIndexLink";
import { useSetPassportOperationBillIndex } from "../../hooks";

export default function BillIndexField(props: {
  passport: Passport;
  hasIcon?: boolean;
}) {
  const { passport, hasIcon = false } = props;
  const tr = useTranslations();

  const [isEditing, setDialogIsOpen] = useState(false);
  const [editValue, setEditValue] = useState<number | null>(null);

  const canUpdatePassports = useHasPermission("update:passports");
  const { data: parentProject } = useProject(passport?.parentProject ?? null);
  const { data: bills = [] } = useProjectBills(parentProject?.id ?? 0);

  const passportBill = bills.find((bill) => bill.id === passport?.articleBill);

  const editActive = bills.find((bill) => bill.id === editValue);

  const { mutate: setBillIndex } = useSetPassportOperationBillIndex();

  const handleOpenedDialog = () => {
    setDialogIsOpen((prevState) => !prevState);
    setEditValue(passport.articleBill);
  };

  const handleReset = () => {
    setDialogIsOpen(false);
    setEditValue(null);
  };

  const handleSubmit = () => {
    setBillIndex(
      { passportId: passport.id, billId: editValue },
      {
        onSettled: () => {
          setDialogIsOpen(false);
          setEditValue(null);
        },
      }
    );
  };

  return (
    <EditablePropertyTemplate
      label={tr.translateAsString("project.operation-bill.bill-index")}
      isEditable={canUpdatePassports}
      triggerEditMode={handleOpenedDialog}
      isInEditMode={isEditing}
      inline
    >
      {isEditing ? (
        <>
          <OperationBillSelect
            disabled={bills.length === 0}
            activeBill={editActive}
            bills={bills}
            onSelect={(billId) => setEditValue(billId)}
          />

          <EditablePropertyButtonGroup
            onSubmit={handleSubmit}
            onReset={handleReset}
          />
        </>
      ) : passportBill && parentProject ? (
        <ArticleBillIndexLink
          product={parentProject.id}
          billId={passportBill.id}
          hasIcon={hasIcon}
        />
      ) : (
        tr.translate("generic.label.none")
      )}
    </EditablePropertyTemplate>
  );
}
