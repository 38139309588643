import { Project, ProjectQueryFilterParams, ProjectType } from "@aletiq/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActionBar,
  DefaultFilters,
  Dialog,
  Table,
} from "../../../../components";
import FilterMenu from "../../../../components/filter/FilterMenu";
import FilterMenuItem from "../../../../components/filter/FilterMenuItem";
import FilterTemplate from "../../../../components/filter/FilterTemplate";
import { useFilter, usePagination, useSortKey } from "../../../../hooks";
import { unique, useTranslations } from "../../../../util";
import { MoreProductFilters } from "../../../project/common";
import {
  useDefaultProductColumnConfig,
  usePaginatedProjects,
  useProductColumns,
} from "../../../project/hooks";
import { useDocumentsProjects, useUpdateDocumentsProjects } from "../../hooks";
import styles from "./LinkProductsDialog.module.scss";

export default function LinkProductsDialog(props: {
  currentProducts: number[];
  documentIds: number[];
  onClose: () => void;
}) {
  const { currentProducts, documentIds, onClose } = props;
  const tr = useTranslations();

  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const {
    sortState: { key, direction },
    setSortKey,
  } = useSortKey<"name" | "description">("name");
  const {
    limit,
    selectedPage,
    handleLimit,
    handleSelectPage,
    resetSelectedPage,
  } = usePagination();

  const { filter, handleFilter, handleClear } =
    useFilter<ProjectQueryFilterParams>({
      search: "",
    });

  useEffect(() => {
    resetSelectedPage();
  }, [filter, key, direction, resetSelectedPage]);

  const { data: projectsResponse } = usePaginatedProjects({
    ...filter,
    order: key,
    orderDirection: direction,
    limit,
    offset: selectedPage * limit,
  });
  const { data: projectAlreadyLinked, isLoading: documentsProjectIsLoading } =
    useDocumentsProjects(documentIds);
  const { mutate: updateDocumentsProducts, isLoading } =
    useUpdateDocumentsProjects();

  const availableProjects = useMemo(() => {
    const projects: Project[] = projectsResponse?.list ?? [];
    return projects.filter((project) => !currentProducts.includes(project.id));
  }, [currentProducts, projectsResponse]);

  const totalProjects = projectsResponse?.count ?? 0;

  const { config, isLoading: isLoadingColumns } =
    useDefaultProductColumnConfig();
  const columns = useProductColumns({
    config,
  });

  const handleSelect = useCallback(
    (project: Project[]) => setSelectedProducts(project.map((p) => p.id)),
    []
  );

  const handleSubmit = async () => {
    const documentsToUpdate = projectAlreadyLinked.map((doc) => {
      return {
        id: doc.id,
        projects: unique([...doc.projects, ...selectedProducts]),
      };
    });

    updateDocumentsProducts(documentsToUpdate, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  const handleSort = (sortKey: keyof Project) => {
    if (sortKey === "name" || sortKey === "description") {
      setSortKey(sortKey);
    }
  };

  const handleSelectProductType = (productType: ProjectType) => () => {
    handleFilter("projectType")(productType);
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      icon="new-link"
      title={
        documentIds.length > 1
          ? tr.translateAsString("document.details.products.new.title.plural")
          : tr.translateAsString("document.details.products.new.title")
      }
      onPrimaryClick={handleSubmit}
      onSecondaryClick={onClose}
      className={styles.dialog}
      submitting={isLoading}
      disablePrimary={documentsProjectIsLoading}
    >
      <div className={styles.dialog_contents}>
        <p>{tr.translate("document.details.products.new.choose")}</p>

        <ActionBar>
          <DefaultFilters
            objectType="product"
            filters={filter}
            handleFilter={handleFilter}
          />
          <FilterTemplate
            count={!filter.projectType ? 0 : 1}
            onClear={handleClear(["projectType"])}
            placeholder={tr.translateAsString(
              "document.details.products.new.title.product-type"
            )}
            content={
              <FilterMenu>
                <FilterMenuItem
                  selected={filter.projectType === "article"}
                  onSelect={handleSelectProductType("article")}
                  text={tr.translate("project.details.type.value", {
                    type: "article",
                  })}
                />
                <FilterMenuItem
                  selected={filter.projectType === "tool"}
                  onSelect={handleSelectProductType("tool")}
                  text={tr.translate("project.details.type.value", {
                    type: "tool",
                  })}
                />
              </FilterMenu>
            }
          />
          <MoreProductFilters
            filters={filter}
            handleFilter={handleFilter}
            handleClear={handleClear}
          />
        </ActionBar>

        <Table
          columns={columns}
          data={availableProjects}
          searchString={filter.search}
          sortOptions={{
            sortState: { key, direction },
            onSort: handleSort,
          }}
          onSelect={handleSelect}
          className={styles.tab}
          paginationOptions={{
            itemCount: totalProjects,
            limit,
            selectedPage,
            onSetLimit: handleLimit,
            onSetPage: handleSelectPage,
          }}
          isFetching={isLoadingColumns}
        />
      </div>
    </Dialog>
  );
}
