import React, { useState } from "react";
import { Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useProductVersions } from "../../../project/hooks";
import {
  PassportVersionPicker,
  resolveChoice,
  VersionChoice,
} from "../../common";
import styles from "./ChangeParentVersionDialog.module.scss";

export default function ChangeParentVersionDialog(props: {
  onSubmit: (version: number) => void;
  projectId: number;
  submitting?: boolean;
  onClose: () => void;
}) {
  const { onSubmit, projectId, submitting, onClose } = props;
  const tr = useTranslations();

  const [versionChoice, setVersionChoice] = useState<VersionChoice>({
    option: "last_version",
  });

  const { data: versions = [] } = useProductVersions(projectId);
  const version = resolveChoice(versionChoice, versions);

  const handleSubmit = () => {
    version && onSubmit(version);
  };

  return (
    <Dialog
      isOpen
      icon="git-commit"
      onClose={onClose}
      submitting={submitting}
      title={tr.translateAsString(
        "passport.dialogs.change.parent.version.title"
      )}
      onPrimaryClick={handleSubmit}
      disablePrimary={!version}
      onSecondaryClick={onClose}
      className={styles.dialog}
    >
      <PassportVersionPicker
        pick={versionChoice}
        versions={versions}
        onChange={setVersionChoice}
      />
    </Dialog>
  );
}
