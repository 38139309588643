import { API } from "@aletiq/api";
import { Part } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { isPartParent } from "../services";
import { pdmKeys } from "./queries";

async function fetchSubTree(api: API, part: Part): Promise<CompTree> {
  if (isPartParent(part.type)) {
    const childrenParts = await api.pdm.listAssemblyComponents(part.id);
    const children = await Promise.all(
      childrenParts
        .filter((c) => c.part && !c.part.isStandard)
        .map((component) => fetchSubTree(api, component.part!))
    );
    return { node: part, children };
  } else {
    return { node: part };
  }
}

export default function usePartTree(partId: number) {
  const api = useApi();
  return useQuery(pdmKeys.iterationTree(partId), async () => {
    const part = await api.pdm.getPart(partId);
    return fetchSubTree(api, part);
  });
}

export type CompTree = {
  node: Part;
  children?: CompTree[];
};
