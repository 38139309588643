import { useMutation } from "react-query";
import useApi from "../../../../app/useApi";

export default function useUpdateUserPassword(userId: number) {
  const api = useApi();

  return useMutation(
    (password: string) => api.user.updateUserPassword(userId, password),
    { onSuccess: () => {} }
  );
}
