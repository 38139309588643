import { Operation } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { projectKeys } from "../../hooks/queries";

export default function useCopyOperations(projectId: number, billId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (operations: Operation[]) => {
      const docs = operations.flatMap((op) =>
        op.documents.map((d) => d.document)
      );
      await api.document.addProjectDocuments(projectId, docs);
      for (const operation of operations) {
        await api.operations.addProjectOperation(projectId, billId, {
          number: operation.number,
          name: operation.name,
          description: operation.description,
        });
        for (const document of operation.documents) {
          await api.operations.addProjectOperationDocument(
            projectId,
            billId,
            operation.number,
            document.document,
            document.revision.type === "specific" && document.revision.revision
              ? { type: "specific", revision: document.revision.revision.id }
              : { type: "last" }
          );
        }
        for (const tool of operation.tools) {
          await api.operations.addProjectOperationTool(
            projectId,
            billId,
            operation.number,
            tool.tool,
            tool.version
          );
        }
      }
    },
    {
      onSettled: () => {
        return queryClient.invalidateQueries(projectKeys.allBills);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
