import { API } from "@aletiq/api";
import {
  ProcessAnalysisFilter,
  ProcessQueryParams,
  TaskQueryParams,
} from "@aletiq/types";

export const taskKeys = {
  all: ["tasks"] as const,
  list: (params?: TaskQueryParams) => [...taskKeys.all, params ?? {}] as const,
  byId: (id: number) => [...taskKeys.all, id] as const,
};

export const processKeys = {
  all: ["processes"] as const,
  list: (params?: ProcessQueryParams) =>
    [...processKeys.all, params ?? {}] as const,
  byId: (id: number) => [...processKeys.all, id] as const,

  allRelations: () => [...processKeys.all, "process-relations"] as const,
  relationsById: (id: number) => [...processKeys.allRelations(), id] as const,

  allAnalysis: () => [...processKeys.all, "analysis"] as const,
  analysisFiltered: (filter: ProcessAnalysisFilter) =>
    [...processKeys.allAnalysis(), filter] as const,
  analysisRepartition: (filter: ProcessAnalysisFilter) =>
    [...processKeys.analysisFiltered(filter), "repartition"] as const,
  analysisResolution: (filter: ProcessAnalysisFilter) =>
    [...processKeys.analysisFiltered(filter), "resolution"] as const,
  analysisState: (filter: ProcessAnalysisFilter) =>
    [...processKeys.analysisFiltered(filter), "state"] as const,
  analysisVolume: (filter: ProcessAnalysisFilter) =>
    [...processKeys.analysisFiltered(filter), "volume"] as const,
};

export const processModelKeys = {
  all: ["processes-model"] as const,
};

export const processModelQueries = {
  all: (api: API) => ({
    queryKey: processModelKeys.all,
    queryFn: () => api.task.listProcessModels(),
  }),
};
