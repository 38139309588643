import { MAX_DROPPED_FILES, useTranslations } from "../../util";
import useToaster from "./useToaster";

export default function useTooManyFilesToaster() {
  const toaster = useToaster();
  const tr = useTranslations();

  return () => {
    toaster.show({
      intent: "danger",
      icon: "warning-sign",
      message: tr.translate("toaster.error.file.limit.exceeded", {
        count: MAX_DROPPED_FILES,
      }),
    });
  };
}
