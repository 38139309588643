import { Process } from "@aletiq/types";

//get workflows user participates in from a list of workflows
export default function useUserParticipatingWorkflows(
  userId: number,
  workflows: Process[]
): Process[] {
  return workflows.filter((w) =>
    w.tasks.some((t) => t.owners.includes(userId))
  );
}
