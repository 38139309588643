export const adminKeys = {
  all: ["admin"] as const,

  tenants: () => [...adminKeys.all, "tenants"] as const,

  allUsers: () => [...adminKeys.all, "users"] as const,
  usersByTenant: (id: number) => [...adminKeys.allUsers(), id] as const,

  userExports: () => [...adminKeys.all, "users-exports"] as const,
};
