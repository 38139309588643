import { Button, Field } from "@aletiq/design-system";
import { useTranslations } from "@aletiq/translation";
import { ProjectDefinition } from "@aletiq/types";
import React, { useState } from "react";
import { ProjectDefinitionActionMenu } from ".";
import { ActionBar, ChangeRevisionPopover } from "../../../../components";
import { useCurrentUser } from "../../../../hooks";
import {
  ApproveNomenclatureDialog,
  CreateProjectDefinitionDialog,
  DeleteProjectDefinitionDialog,
  EditProjectDefinitionDialog,
  ValidateProjectDefinitionDialog,
} from "../../dialogs/";
import { useDraftProjectDefinition } from "../../hooks";
import { ProjectDefinitionSelectMenu } from "../ProjectDefinitionSelectMenu";
import styles from "./ProjectDefinitionActionBar.module.scss";

type ProjectDefinitionActionBarDialog =
  | "createDefinition"
  | "editDefinition"
  | "approveDefinition"
  | "deleteDefinition";

export default function ProjectDefinitionActionBar(props: {
  projectId: number;
  definitions: ProjectDefinition[];
  activeDefinition: ProjectDefinition;
  isDraftIteration?: boolean;
  isDeleteDisabled?: boolean;
  hasCreateDefinitionButton?: boolean;
  hasComponents?: boolean;
  onActiveDefinitionChange: (idx: number) => void;
}) {
  const {
    projectId,
    definitions,
    activeDefinition,
    onActiveDefinitionChange,
    isDeleteDisabled,
    isDraftIteration,
    hasCreateDefinitionButton,
    hasComponents,
  } = props;

  const [dialog, setDialog] = useState<ProjectDefinitionActionBarDialog>();

  const { data: user } = useCurrentUser();
  const isAdminUser = user?.role === "administrator";

  const { mutate: draftProjectDefinition } = useDraftProjectDefinition(
    projectId,
    activeDefinition.index
  );

  const handleOpenDialog = (value: ProjectDefinitionActionBarDialog) => () =>
    setDialog(value);

  const handleCloseDialog = () => setDialog(undefined);

  const handleCreateDefinitionDone = () => {
    onActiveDefinitionChange(definitions[0].index + 1);
    setDialog(undefined);
  };

  const handleDeleteDefinitionDone = () => {
    onActiveDefinitionChange(activeDefinition.index - 1);
    setDialog(undefined);
  };

  const tr = useTranslations();

  return (
    <>
      <ActionBar>
        <Field label={tr.translateAsString("project.definition.index")}>
          <div className={styles.definition_menu}>
            {activeDefinition && (
              <ProjectDefinitionSelectMenu
                onNewDefinition={handleOpenDialog("createDefinition")}
                activeDefinition={activeDefinition}
                definitions={definitions}
                onSelect={onActiveDefinitionChange}
                hasCreateDefinitionButton={hasCreateDefinitionButton}
              />
            )}
            {activeDefinition.state === "draft" && (
              <ProjectDefinitionActionMenu
                isDeleteDisabled={isDeleteDisabled}
                onDelete={handleOpenDialog("deleteDefinition")}
                onRename={handleOpenDialog("editDefinition")}
                onValidate={handleOpenDialog("approveDefinition")}
              />
            )}

            {activeDefinition.state !== "draft" && isAdminUser && (
              <ChangeRevisionPopover
                initial={{ state: activeDefinition.state }}
                final={{
                  state: "draft",
                }}
                objectType={"definition"}
                onConfirm={draftProjectDefinition}
              >
                <Button icon={"edit"} isDense />
              </ChangeRevisionPopover>
            )}
          </div>
        </Field>
      </ActionBar>

      {dialog === "approveDefinition" && (
        <>
          {hasComponents && (
            <ApproveNomenclatureDialog
              projectId={projectId}
              definitionIdx={activeDefinition.index}
              onClose={handleCloseDialog}
            />
          )}
          {!hasComponents && (
            <ValidateProjectDefinitionDialog
              projectId={projectId}
              definitionIdx={activeDefinition.index}
              onClose={handleCloseDialog}
              isDraftIteration={isDraftIteration}
            />
          )}
        </>
      )}

      {dialog === "editDefinition" && activeDefinition && (
        <EditProjectDefinitionDialog
          projectId={projectId}
          definition={activeDefinition}
          onClose={handleCloseDialog}
        />
      )}

      {dialog === "createDefinition" && activeDefinition && (
        <CreateProjectDefinitionDialog
          projectId={projectId}
          onClose={handleCloseDialog}
          onSuccess={handleCreateDefinitionDone}
          definitions={definitions}
        />
      )}

      {dialog === "deleteDefinition" && activeDefinition && (
        <DeleteProjectDefinitionDialog
          projectId={projectId}
          definitionIdx={activeDefinition.index}
          onClose={handleCloseDialog}
          onSuccess={handleDeleteDefinitionDone}
        />
      )}
    </>
  );
}
