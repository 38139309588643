import React from "react";
import {
  AlertPopover,
  AlignRight,
  AnchorButton,
  Button,
  ButtonGroup,
  DocumentNameLink,
  RemoveLinkIcon,
  Tooltip,
} from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useShowDocumentPreview } from "../../hooks";
import styles from "./DocumentCitations.module.scss";

export default function CitationRow(props: {
  documentId: number;
  onRemove?: () => void;
  isRemoving?: boolean;
}) {
  const { documentId, onRemove, isRemoving } = props;
  const tr = useTranslations();

  const showDocumentPreview = useShowDocumentPreview();
  const canRemoveLink = useHasPermission("update:documents");

  const handleOpenPreview = (documentId: number) => () => {
    showDocumentPreview({ documentId });
  };

  return (
    <div className={styles.row}>
      <DocumentNameLink documentId={documentId} hasIcon />
      <AlignRight />
      <ButtonGroup>
        <Tooltip
          position="top"
          content={tr.translate("document.details.citations.action.preview")}
        >
          <Button
            isDense
            icon="eye-open"
            onClick={handleOpenPreview(documentId)}
          />
        </Tooltip>
        {onRemove && (
          <Tooltip
            position="top"
            content={tr.translate("document.details.citations.action.unlink")}
          >
            <AlertPopover
              isDense
              disabled={!canRemoveLink}
              icon={<RemoveLinkIcon />}
              submitting={isRemoving}
              onPrimaryClick={onRemove}
              title={tr.translate("document.details.citations.action.unlink")}
              content={tr.translate(
                "document.details.citations.action.unlink.description"
              )}
            >
              <AnchorButton
                isDense
                isDisabled={!canRemoveLink}
                icon={<RemoveLinkIcon />}
              />
            </AlertPopover>
          </Tooltip>
        )}
      </ButtonGroup>
    </div>
  );
}
