import { H1 } from "@aletiq/design-system";
import { Icon, MaybeElement } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import classNames from "classnames";
import React from "react";
import styles from "./PageTitle.module.scss";

export default function PageTitle(props: {
  children: React.ReactNode;
  icon?: IconName | MaybeElement;
  className?: string;
}) {
  const { children, icon, className } = props;

  return (
    <H1 className={classNames(styles.title, className)}>
      {icon && <Icon icon={icon} size={24} className={styles.icon} />}
      {children}
    </H1>
  );
}
