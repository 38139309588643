import React, { useState } from "react";
import { Card, ProjectNameLink } from "../../../../components";
import PassportLink from "../../../../components/link/PassportLink";
import { useTranslations } from "../../../../util";
import useResolvedParentPassports from "../../hooks/useResolvedParentPassport";
import styles from "./PassportNomenclature.module.scss";

export default function PassportUsedIn(props: {
  parentPassportsIds: number[];
}) {
  const { parentPassportsIds } = props;
  const tr = useTranslations();
  const [isOpen, setIsOpen] = useState(false);

  const parentPassports = useResolvedParentPassports(parentPassportsIds);

  const handleToggleFold = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Card
      title={tr.translateAsString("passport.bom.used-in", {
        length: parentPassports.length,
      })}
      isFolded={!isOpen}
      onToggleFold={handleToggleFold}
      className={styles.card}
    >
      {parentPassports.map((passport) => (
        <div className={styles.row} key={passport.id}>
          <ProjectNameLink
            hasThumbnail
            projectId={passport.parentProject}
            className={styles.product}
          />
          <PassportLink
            hasIcon
            passport={passport}
            className={styles.passportName}
          />
        </div>
      ))}
      {parentPassportsIds.length === 0 && (
        <p className={styles.no_parent}>
          {tr.translate("passport.bom.used-in.parent.no-parent")}
        </p>
      )}
    </Card>
  );
}
