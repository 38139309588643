import { Project, Property, PropertyValue } from "@aletiq/types";
import React, { useMemo } from "react";
import {
  DateWithSpaces,
  EditablePropertyInput,
  Field,
  GenericStateTag,
  HighlightedText,
} from "../../../components";
import { useHasPermission } from "../../../hooks";
import { isInString, useTranslations } from "../../../util";
import { useProperties } from "../../administration/Attributes/hooks";
import { PropertyField } from "../../passport/PassportDetails/ConfigurationField";
import {
  useUpdateProductDescription,
  useUpdateProductProperties,
} from "../hooks";
import styles from "./ProductProperties.module.scss";

export default function ProductProperties(props: {
  product: Project;
  search: string;
}) {
  const { product, search } = props;
  const tr = useTranslations();

  const { data: properties } = useProperties("product");
  const { mutate: updateProperties } = useUpdateProductProperties(product.id);
  const { mutate: updateDescription } = useUpdateProductDescription(product.id);
  const canUpdateProperties = useHasPermission("update:projects");

  const filteredProperties = useMemo(
    () =>
      properties?.filter((prop) => {
        const hasCorrectTarget =
          prop.group === "all" ||
          (product?.isArticle && prop.group === "article") ||
          (product?.isTool && prop.group === "tool");

        return (
          hasCorrectTarget &&
          (isInString(prop.label, search) ||
            prop.options.some((option) => isInString(option.label, search)))
        );
      }),
    [properties, search, product.isArticle, product.isTool]
  );

  const handleUpdatePropertyValue = (
    property: string,
    value: PropertyValue
  ) => {
    updateProperties([{ property, value }]);
  };

  const descriptionLabel = tr.translateAsString("generic.label.description");
  const creationTimeLabel = tr.translateAsString(
    "generic.label.creation-date.title"
  );
  const updateTimeLabel = tr.translateAsString(
    "generic.label.last-update.title"
  );

  const definitionIndexLabel = tr.translateAsString(
    "project.definition.index.last"
  );

  return (
    <div className={styles.properties}>
      {isInString(definitionIndexLabel, search) && (
        <Field
          inline
          className={styles.property}
          label={
            <HighlightedText text={definitionIndexLabel} highlight={search} />
          }
        >
          <GenericStateTag
            name={product.lastDefinition.name}
            state={product.lastDefinition.state}
          />
        </Field>
      )}

      {isInString(descriptionLabel, search) && (
        <EditablePropertyInput
          inline
          label={descriptionLabel}
          isEditable={canUpdateProperties}
          value={product.description ?? ""}
          onSubmit={updateDescription}
          search={search}
          placeholder=""
          emptyState=""
        />
      )}

      {isInString(creationTimeLabel, search) && (
        <Field
          inline
          className={styles.property}
          label={
            <HighlightedText text={creationTimeLabel} highlight={search} />
          }
        >
          <DateWithSpaces date={product.createdAt} />
        </Field>
      )}

      {isInString(updateTimeLabel, search) && (
        <Field
          inline
          className={styles.property}
          label={<HighlightedText text={updateTimeLabel} highlight={search} />}
        >
          <DateWithSpaces date={product.updatedAt} />
        </Field>
      )}

      {/* Custom product properties */}
      {filteredProperties?.map((property: Property) => (
        <PropertyField
          key={`property-${property.id}`}
          property={property}
          value={product.properties[property.name]}
          onEditValue={handleUpdatePropertyValue}
          canUpdate={canUpdateProperties}
          search={search}
          hasDescriptionTooltip
        />
      ))}
    </div>
  );
}
