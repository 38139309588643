import {
  BOMEntry,
  Project,
  ProjectDefinition,
  ProjectDefinitionId,
} from "@aletiq/types";
import { useMemo } from "react";
import { useQueries, useQueryClient, UseQueryResult } from "react-query";
import { QueryState } from "react-query/types/core/query";
import useApi from "../../../../app/useApi";
import {
  Diff,
  ExpandTree,
  findExpandedTrees,
  isNotUndefined,
  unique,
} from "../../../../util";
import { projectKeys, projectQueries } from "../../hooks/queries";
import { diffComponents } from "../compareDefinition";
import { ProductTree } from "./types";

export type ResolvedBOMEntry = {
  project: Project;
  definition: ProjectDefinition;
  diff: Diff<BOMEntry>;
} & Omit<BOMEntry, "definition">;

export function makeTree(
  allComponents: Record<
    number,
    Record<number, QueryState<BOMEntry[]> | undefined>
  >,
  allProjects: Record<number, QueryState<Project> | undefined>,
  allDefinitions: Record<
    number,
    Record<number, QueryState<ProjectDefinition> | undefined>
  >,
  component: ResolvedBOMEntry,
  parent: ProjectDefinitionId,
  tree: ExpandTree
): ProductTree {
  const { id, revision, subRows } = tree;
  const componentState = allComponents[id][revision];
  const componentPreviousState = allComponents[id][revision - 1];
  const componentBom = componentState?.data;
  const componentPreviousBom = componentPreviousState?.data;
  const componentsDiff = diffComponents(
    componentBom ?? [],
    componentPreviousBom ?? []
  );
  const makeTreeNode = (subRows: ProductTree[]) =>
    makeNode(component, parent, subRows);

  // When the component is not expanded
  if (!componentState) {
    return makeTreeNode([]);
  }

  // When the component has not been fetched yet
  if (!componentBom) {
    return makeTreeNode([{ type: "not-fetched", project: undefined }]);
  }

  const fullChildren = componentBom.map((c) => {
    const project = allProjects[c.entity]?.data;
    const definitions = allDefinitions[c.entity];
    if (project === undefined || definitions === undefined) {
      return undefined;
    }
    const definition = definitions[c.definition]?.data;
    if (definition === undefined) {
      return undefined;
    }

    const diff = componentsDiff[c.entity] ?? {};
    const resolved = { ...c, project, definition, diff };
    const componentTree = subRows.find(
      (child) => child.id === project.id && child.revision === definition.index
    );
    const parent = { productId: id, definitionIdx: revision };
    if (!componentTree) {
      return makeLeaf(resolved, parent);
    }
    return makeTree(
      allComponents,
      allProjects,
      allDefinitions,
      resolved,
      parent,
      componentTree
    );
  });

  if (fullChildren.length === 0) {
    return makeTreeNode([{ type: "empty", productId: id, project: undefined }]);
  }

  if (fullChildren.some((c) => c === undefined)) {
    return makeTreeNode([{ type: "not-fetched", project: undefined }]);
  }

  return makeTreeNode(fullChildren.filter(isNotUndefined));
}

export function makeProjectLeaf(
  entry: ResolvedBOMEntry,
  parent: ProjectDefinitionId
): ProductTree {
  return {
    type: "project",
    project: entry.project,
    entry,
    diff: entry.diff,
    parent,
    expanded: false,
    definition: entry.definition,
    subRows: [],
  };
}

function makeNode(
  entry: ResolvedBOMEntry,
  parent: ProjectDefinitionId,
  subRows: ProductTree[]
): ProductTree {
  return {
    type: "project",
    expanded: true,
    project: entry.project,
    parent,
    entry,
    diff: entry.diff,
    definition: entry.definition,
    subRows,
  };
}

function makeLeaf(
  entry: ResolvedBOMEntry,
  parent: ProjectDefinitionId
): ProductTree {
  return {
    type: "project",
    expanded: false,
    project: entry.project,
    parent,
    entry,
    diff: entry.diff,
    definition: entry.definition,
    subRows: [],
  };
}

export default function useProjectExpandableTree(
  expandTrees: ExpandTree[],
  components: ResolvedBOMEntry[],
  parent: ProjectDefinitionId
) {
  const api = useApi();
  const queryClient = useQueryClient();

  const componentsToFetch = useMemo(
    () =>
      findExpandedTrees(
        components.map((c) => c.project),
        expandTrees
      ),
    [components, expandTrees]
  );

  const bomResults = useQueries(
    componentsToFetch.map(({ id, revision }) =>
      projectQueries.bomEntities(api, id, revision)
    )
  ) as UseQueryResult<BOMEntry[]>[];

  useQueries(
    componentsToFetch.map(({ id, revision }) =>
      projectQueries.bomEntities(api, id, revision - 1)
    )
  ) as UseQueryResult<BOMEntry[]>[];

  const bomAllResults = useMemo(() => {
    const flattened = bomResults.flatMap((bom) => bom.data ?? []);
    const ids = unique(flattened.map((e) => e.entity));
    return flattened
      .filter((e) => ids.includes(e.entity))
      .flatMap((e) => [
        {
          id: e.entity,
          definition: e.definition,
        },
        {
          id: e.entity,
          definition: e.definition - 1,
        },
      ]);
  }, [bomResults]);

  useQueries(
    bomAllResults.map((e) => projectQueries.byId(api, e.id))
  ) as UseQueryResult<Project>[];

  useQueries(
    bomAllResults.map((e) => projectQueries.definition(api, e.id, e.definition))
  ) as UseQueryResult<ProjectDefinition>[];

  return useMemo(() => {
    const bomQueriesState: Record<
      number,
      Record<number, QueryState<BOMEntry[]> | undefined>
    > = {};
    const projectQueriesState: Record<number, QueryState<Project> | undefined> =
      {};

    const definitionQueriesState: Record<
      number,
      Record<number, QueryState<ProjectDefinition> | undefined>
    > = {};

    bomAllResults.forEach(({ id }) => {
      projectQueriesState[id] = queryClient.getQueryState(
        projectKeys.byId(id),
        {
          exact: true,
        }
      );
    });

    bomAllResults.forEach(({ id, definition }) => {
      definitionQueriesState[id] = {
        ...definitionQueriesState[id],
        [definition]: queryClient.getQueryState(
          projectKeys.definition(id, definition),
          {
            exact: true,
          }
        ),
      };
    });

    componentsToFetch.forEach(({ id, revision }) => {
      bomQueriesState[id] = {
        ...bomQueriesState[id],
        [revision]: queryClient.getQueryState(
          projectKeys.bomEntities(id, revision),
          {
            exact: true,
          }
        ),
        [revision - 1]: queryClient.getQueryState(
          projectKeys.bomEntities(id, revision - 1),
          {
            exact: true,
          }
        ),
      };
    });

    return components.map((component) => {
      const { project } = component;
      const expandTree = expandTrees.find((t) => t.id === project.id);

      if (!expandTree) {
        return makeProjectLeaf(component, parent);
      }
      return makeTree(
        bomQueriesState,
        projectQueriesState,
        definitionQueriesState,
        component,
        parent,
        expandTree
      );
    });
  }, [
    bomAllResults,
    componentsToFetch,
    components,
    queryClient,
    expandTrees,
    parent,
  ]);
}
