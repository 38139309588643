import {
  ButtonSP,
  Callout,
  MenuDivider,
  MenuItem,
  MenuSP,
  Popover2,
  SearchInputSP,
} from "@aletiq/design-system";
import { EntityId } from "@aletiq/types";
import React, { MouseEvent, useRef, useState } from "react";
import { useDocuments } from "../../features/documents/hooks";
import { usePaginatedPassports } from "../../features/passport/hooks";
import { useParts } from "../../features/pdm/hooks";
import {
  useArticles,
  useProjectsById,
  useTools,
} from "../../features/project/hooks";
import { useOnClickOutside } from "../../hooks";
import { useTranslations } from "../../util";
import styles from "./LinkEntities.module.scss";

type ObjectType = "passport" | "article" | "tool" | "process" | "part" | "file";

export default function LinkEntitiesSelect(props: {
  disabled?: boolean;
  entities: EntityId[];
  intent?: "primary" | "default";
  isDense?: boolean;
  isFill?: boolean;
  onResetRelation: () => void;
  onAddRelation: (entity: EntityId) => void;
  onDeleteRelation: (entity: EntityId) => void;
}) {
  const {
    intent = "primary",
    isDense,
    isFill,
    disabled,
    entities,
    onResetRelation,
    onAddRelation,
    onDeleteRelation,
  } = props;
  const tr = useTranslations();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const wrapperRef = useRef(null);
  const [activeType, setActiveType] = useState<ObjectType>("article");
  const [query, setQuery] = useState("");
  const lowerCaseQuery = query.toLocaleLowerCase();
  const queryPredicate = (p: { name: string }) =>
    query === "" || p.name.toLocaleLowerCase().includes(lowerCaseQuery);

  const selectedPredicate = (type: string) => (p: { id: number }) => {
    const entityType = type !== "article" && type !== "tool" ? type : "project";
    return !!entities.find((it) => it.type === entityType && it.id === p.id);
  };

  const { data: allArticles = [] } = useArticles({ search: query, limit: 100 });
  const { data: allTools = [] } = useTools({ search: query, limit: 100 });

  const articles = allArticles.map((p) => ({
    id: p.id,
    name: p.name,
  }));
  const tools = allTools.map((p) => ({ id: p.id, name: p.name }));

  const { data: allPassport } = usePaginatedPassports({
    search: query,
    limit: 100,
  });
  const passports =
    allPassport?.list?.map((p) => ({ id: p.id, name: p.name })) ?? [];

  const { data: partData } = useParts({ search: query, standard: false });
  const parts = (partData?.list ?? []).map((p) => ({ id: p.id, name: p.name }));

  const { data: docs } = useDocuments({ search: query, limit: 100 });
  const documents = docs?.list?.map((d) => ({ id: d.id, name: d.name })) ?? [];

  const handleTabClick = (type: ObjectType) => (ev: MouseEvent) => {
    ev.preventDefault();
    setActiveType(type);
  };

  const handleItemClick = (type: ObjectType) => (id: number) => {
    const entityType = type !== "article" && type !== "tool" ? type : "project";

    if (selectedPredicate(entityType)({ id })) {
      onDeleteRelation({ type: entityType, id });
    } else {
      onAddRelation({ type: entityType, id });
    }
  };

  let items: { id: number; name: string }[] = [];
  switch (activeType) {
    case "article":
      items = articles.filter(queryPredicate);
      break;
    case "tool":
      items = tools.filter(queryPredicate);
      break;
    case "passport":
      items = passports.filter(queryPredicate);
      break;
    case "part":
      items = parts;
      break;
    case "file":
      items = documents.filter(queryPredicate);
      break;
  }

  const projectsById = useProjectsById(
    entities?.filter((e) => e.type === "project").map((e) => e.id) ?? []
  );
  const workflowArticles = projectsById.filter((p) => p.isArticle);
  const workflowTools = projectsById.filter((p) => p.isTool);

  const handleOpenPopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const onClickOutside = () => {
    isPopoverOpen && setIsPopoverOpen(false);
  };
  useOnClickOutside(wrapperRef, onClickOutside);

  return (
    <div className={styles.popover} ref={wrapperRef}>
      <Popover2
        intent={intent}
        disabled={disabled}
        position="bottom-left"
        minimal
        isOpen={isPopoverOpen}
        usePortal={false}
        fill={isFill}
        content={
          <div className={styles.popoverContent}>
            <MenuSP className={styles.left_menu} isDense color={intent}>
              <MenuItem
                intent={intent}
                icon="projects"
                text={tr.translate("process.entities.link.projects.articles")}
                onClick={handleTabClick("article")}
                active={activeType === "article"}
                isDense
                hasNote
                labelElement={workflowArticles.length}
              />
              <MenuItem
                intent={intent}
                icon="wrench"
                text={tr.translate("process.entities.link.projects.tools")}
                onClick={handleTabClick("tool")}
                active={activeType === "tool"}
                isDense
                hasNote
                labelElement={workflowTools.length}
              />
              <MenuItem
                intent={intent}
                icon="id-number"
                text={tr.translate("process.entities.link.passports.passports")}
                onClick={handleTabClick("passport")}
                active={activeType === "passport"}
                isDense
                hasNote
                labelElement={lengthOfFilterByEntity(entities, "passport")}
              />
              <MenuItem
                intent={intent}
                icon="cube"
                text={tr.translate("process.entities.link.parts.parts")}
                onClick={handleTabClick("part")}
                active={activeType === "part"}
                isDense
                hasNote
                labelElement={lengthOfFilterByEntity(entities, "part")}
              />
              <MenuItem
                intent={intent}
                icon="document"
                text={tr.translate("passports.details.configuration.document")}
                onClick={handleTabClick("file")}
                active={activeType === "file"}
                isDense
                labelElement={lengthOfFilterByEntity(entities, "file")}
                hasNote
              />
              <MenuDivider intent={intent} className={styles.divider} />
              <MenuItem
                icon="trash"
                text={tr.translate("process.entities.link.resets")}
                onClick={onResetRelation}
                isDense
              />
            </MenuSP>
            <Panel
              query={query}
              setQuery={setQuery}
              intent={intent}
              items={items}
              itemSelected={selectedPredicate(activeType)}
              onItemClick={handleItemClick(activeType)}
            />
          </div>
        }
      >
        <ButtonSP
          isDisabled={disabled}
          view="outlined"
          color={intent}
          rightIcon="caret-down"
          onClick={handleOpenPopover}
          isFill={isFill}
          isDense={isDense}
        >
          {tr.translate("process.entities.link.to.object-counts", {
            length: entities.length,
          })}
        </ButtonSP>
      </Popover2>
    </div>
  );
}

function Panel(props: {
  intent: "primary" | "default";
  query: string;
  setQuery: (query: string) => void;
  items: { id: number; name: string }[];
  itemSelected: (item: { id: number; name: string }) => boolean;
  onItemClick: (id: number) => void;
}) {
  const { intent, query, setQuery, items, onItemClick, itemSelected } = props;

  const tr = useTranslations();

  const handleItemClick = (id: number) => (ev: MouseEvent) => {
    ev.preventDefault();
    onItemClick(id);
  };

  return (
    <div className={styles.panel}>
      <div className={styles.search}>
        <SearchInputSP
          fill
          autoFocus
          placeholder={tr.translateAsString(
            "process.entities.link.search.placeholder"
          )}
          value={query}
          onChange={setQuery}
          isDense
          color={intent}
        />
      </div>
      <MenuSP isDense color={intent} className={styles.menu}>
        {items.length === 0 ? (
          <MenuItem
            disabled
            text={tr.translate("process.entities.link.search.noresults")}
            isDense
          />
        ) : items.length > 100 ? (
          <Callout
            isDense
            title={tr.translateAsString(
              "process.entities.link.too-many-results.title"
            )}
            intent={intent}
            icon="info-sign"
            className={styles.callout}
          >
            <div>
              {tr.translate(
                "process.entities.link.too-many-results.description-1"
              )}
            </div>
            <div>
              {tr.translate(
                "process.entities.link.too-many-results.description-2"
              )}
            </div>
          </Callout>
        ) : (
          items.map((p) => (
            <MenuItem
              intent={intent}
              icon={itemSelected(p) ? "tick" : "blank"}
              key={p.id}
              text={p.name}
              onClick={handleItemClick(p.id)}
              isDense
            />
          ))
        )}
      </MenuSP>
    </div>
  );
}

const lengthOfFilterByEntity = (entities: EntityId[] = [], type: ObjectType) =>
  entities.filter((e) => e.type === type).length;
