import { Field, Input } from "@aletiq/design-system";
import { EntityId } from "@aletiq/types";
import React from "react";
import { LinkEntitiesSelect } from "../../../../components";
import { useTranslations } from "../../../../util";
import ProcessModelSelect from "../../dialogs/ProcessModelSelect";
import styles from "./WorkflowView.module.scss";

export default function WorkflowCreationView(props: {
  modelId: number | null;
  onModelChange: (modelId: number | null) => void;
  title: string;
  onTitleChange: (title: string) => void;
  entities: EntityId[];
  onAddRelation: (entityId: EntityId) => void;
  onDeleteRelation: (entityId: EntityId) => void;
  onResetRelation: () => void;
}) {
  const {
    modelId,
    onModelChange,
    title,
    onTitleChange,
    entities,
    onAddRelation,
    onDeleteRelation,
    onResetRelation,
  } = props;

  const tr = useTranslations();

  return (
    <div className={styles.creation_view}>
      <Field
        label={tr.translateAsString("task.dialog.new-process.model.label")}
      >
        <ProcessModelSelect onChange={onModelChange} selectedModel={modelId} />
      </Field>
      <Field label={tr.translate("task.dialog.new-process.title.label")}>
        <Input
          placeholder={tr.translateAsString(
            "task.dialog.new-process.title.placeholder"
          )}
          value={title}
          onChange={onTitleChange}
          fill
        />
      </Field>
      <Field label={tr.translate("task.dialog.new-process.link.label")}>
        <LinkEntitiesSelect
          entities={entities}
          onAddRelation={onAddRelation}
          onDeleteRelation={onDeleteRelation}
          onResetRelation={onResetRelation}
          isFill
        />
      </Field>
    </div>
  );
}
