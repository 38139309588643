import { useTranslations } from "../../../util";

export function DescriptionColumnHeader() {
  const tr = useTranslations();
  return tr.translate("generic.label.description");
}

export function CreatedAtHeader() {
  const tr = useTranslations();
  return tr.translate("generic.label.creation-date.title");
}

export function UpdatedAtHeader() {
  const tr = useTranslations();
  return tr.translate("generic.label.last-update.title");
}

export function NameHeader() {
  const tr = useTranslations();
  return tr.translate("generic.label.name");
}
