import { Classes, IconProps, IconSize } from "@blueprintjs/core";
import classNames from "classnames";
import * as React from "react";

export type CustomIconGenProps = CustomIconProps & {
  paths: string[];
  iconName: string;
  pixelGridSize?: number;
};

export type CustomIconProps = Omit<IconProps, "icon"> &
  Omit<React.HTMLAttributes<HTMLElement>, "title">;

export function CustomIcon(props: CustomIconGenProps) {
  const {
    className,
    color,
    htmlTitle,
    intent,
    size = IconSize.STANDARD,
    paths: pathStrings,
    title,
    tagName = "span",
    iconName = "",
    pixelGridSize = 16,
    ...htmlprops
  } = props;

  // render path elements, or nothing if icon name is unknown.
  const paths = renderSvgPaths(pathStrings);

  const classes = classNames(
    Classes.ICON,
    Classes.iconClass(iconName),
    Classes.intentClass(intent),
    className
  );
  const viewBox = `0 0 ${pixelGridSize} ${pixelGridSize}`;

  return React.createElement(
    tagName,
    {
      ...htmlprops,
      className: classes,
      title: htmlTitle,
    },
    <svg
      fill={color}
      data-icon={iconName}
      width={size}
      height={size}
      viewBox={viewBox}
    >
      {title && <desc>{title}</desc>}
      {paths}
    </svg>
  );
}

/** Render `<path>` elements for the given icon name. Returns `null` if name is unknown. */
function renderSvgPaths(pathStrings: string[]): JSX.Element[] | null {
  if (pathStrings == null) {
    return null;
  }
  return pathStrings.map((d, i) => <path key={i} d={d} fillRule="evenodd" />);
}
