import { PropertyOption } from "@aletiq/types";
import { AletiqPropertyOption } from "../../components";

export default function propertyOptionsDifference<
  T extends PropertyOption | AletiqPropertyOption
>(oldOptions: T[], newOptions: T[]) {
  const optionsToAdd: T[] = [];
  const optionsToUpdate: T[] = [];

  newOptions.forEach((opt) => {
    if (!opt.value) {
      optionsToAdd.push(opt);
      return;
    }

    const prevOption = oldOptions.find((o) => o.value === opt.value);
    if (opt.deleted !== prevOption?.deleted || opt.label !== prevOption.label) {
      optionsToUpdate.push(opt);
    }
  });

  return { optionsToAdd, optionsToUpdate };
}
