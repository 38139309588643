import { ProjectType } from "@aletiq/types";
import React from "react";
import { ColumnOption, SelectColumns } from "../../../../components";
import { ColumnOptionConfig } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import { PassportColumn } from "../../hooks/usePassportTableColumns";

export default function SelectPassportColumns(props: {
  columnConfig: ColumnOptionConfig<PassportColumn>;
  projectType?: ProjectType;
  onToggleColumn: (column: PassportColumn) => void;
  children: React.ReactNode;
}) {
  const { columnConfig, projectType, onToggleColumn, children } = props;
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("passport");

  const filteredProperties = properties.filter(
    (prop) => prop.group === "all" || prop.group === projectType
  );

  const columnOptions: ColumnOption<PassportColumn>[] = [
    {
      id: "name",
      label: tr.translate("generic.label.name"),
      isMandatory: true,
    },
    {
      id: "parentProjectId",
      label: tr.translate("passport.page.title.parent-product", {
        type: projectType,
      }),
    },
    {
      id: "parentProjectDefinition",
      label: tr.translate("passport.page.title.parent-definition", {
        type: projectType,
      }),
    },
    {
      id: "projectIterationName",
      label: tr.translate(
        projectType === "article"
          ? "passport.page.title.operation-bill-index"
          : "passport.page.title.product-version",
        { type: projectType }
      ),
    },
    { id: "stateName", label: tr.translate("passport.page.title.state") },
    { id: "clientName", label: tr.translate("passport.page.title.client") },
    { id: "config", label: tr.translate("passport.page.title.configs") },
    {
      id: "createdAt",
      label: tr.translate("generic.label.creation-date.title"),
    },
    {
      id: "updatedAt",
      label: tr.translate("generic.label.last-update.title"),
    },
    ...filteredProperties,
  ];

  return (
    <SelectColumns
      config={columnConfig}
      options={columnOptions}
      onToggleColumn={onToggleColumn}
    >
      {children}
    </SelectColumns>
  );
}
