import { DocumentSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { createDocument } from "../filesSlice";
import { documentKeys } from "./queries";

export default function useCreateDocument() {
  const api = useApi();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (values: { file: File; spec: DocumentSpec }) =>
      createDocument(api, dispatch, values.spec, values.file),
    {
      onSettled: () => {
        queryClient.invalidateQueries(activityKeys.all);
        queryClient.invalidateQueries(documentKeys.all);
      },
    }
  );
}
