import { Part, ProjectSpec, ProjectType } from "@aletiq/types";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { pdmKeys } from "../../pdm/hooks/queries";
import { projectKeys } from "./queries";

export default function useCreateAndLinkProjectsToParts(
  projectType: ProjectType,
  parts: Record<number, Part>
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (specs: Record<number, ProjectSpec>) => {
      for (const partId in specs) {
        const part = parts[partId];
        const project = await api.project.createProject(specs[partId]);
        await api.pdm.updatePartProjects(part.id, [
          ...part.projects,
          project.id,
        ]);
        await api.pdm.updatePartProjectBom(project.id, 1, {
          part: part.id,
          iteration: part.lastIteration.number,
        });
      }
    },
    {
      onSuccess: (_, specs) => {
        queryClient.invalidateQueries(projectKeys.all);
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
        toaster.show({
          intent: "success",
          icon: "tick",
          timeout: 2000,
          message: (
            <>
              {tr.translate("toaster.project.create.from-part.success.title")}
              <div
                style={{
                  color: "var(--color100)",
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {tr.translate("toaster.project.create.from-part.success.desc", {
                  count: Object.entries(specs).length,
                  type: projectType,
                })}
              </div>
            </>
          ),
        });
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.project.create.from-part.error"),
        });
      },
    }
  );
}
