import { useReducer } from "react";
import { workflowEditorReducer } from "../taskEditReducer";
import {} from "../taskEditUtils";

export default function useProcessEditorState(
  workflowCreator: number,
  id?: number
) {
  return useReducer(workflowEditorReducer, {
    creator: workflowCreator,
    tasks: {
      edited: [],
      created: [],
      deleted: [],
    },
    title: "",
    id,
  });
}
