import { Field, MenuSP } from "@aletiq/design-system";
import {
  Document,
  DocumentRevision,
  OperationDocument,
  OperationDocumentRevision,
  OperationDocumentRevisionSpec,
} from "@aletiq/types";
import classNames from "classnames";
import React, { useState } from "react";
import {
  AlertPopover,
  Button,
  ButtonGroup,
  Card,
  GenericStateTag,
  GenericStatusTag,
  HighlightedText,
  MenuItem,
  Popover2,
} from "../../../components";
import { useDocumentId, useNavigateTo } from "../../../hooks";
import { ChangeStatus, useTranslations } from "../../../util";
import { useDocument, useShowDocumentPreview } from "../../documents/hooks";
import useAddOperationDocument from "./hooks/useAddOperationDocument";
import useDownloadRevision from "./hooks/useDownloadRevision";
import { useRemoveDocument } from "./hooks/useRemoveDocument";
import useUpdateDocumentRevision from "./hooks/useUpdateDocumentRevision";
import styles from "./OperationDocumentEditorView.module.scss";
import VisibleDocumentProperties from "./VisibleDocumentProperties";
export default function OperationDocumentEditorView(props: {
  projectId: number;
  billId: number;
  operationNumber: number;
  operationDocument: OperationDocument;
  status?: ChangeStatus;
  search: string;
}) {
  const {
    projectId,
    billId,
    operationNumber,
    operationDocument,
    status = "unchanged",
    search,
  } = props;
  const tr = useTranslations();

  const [isDeleting, setDeleting] = useState(false);

  const { data: document } = useDocument(operationDocument.document);
  const documentInPanel = useDocumentId();
  const openDocumentDetails = useNavigateTo({
    panel: `documentId-${operationDocument.document}`,
  });

  const isDocumentVisible = document?.name.includes(search);

  const revision = operationDocument.revision.revision;

  const downloadRevision = useDownloadRevision();
  const { isLoading: updateRevisionLoading, mutate: updateRevision } =
    useUpdateDocumentRevision(
      projectId,
      billId,
      operationNumber,
      operationDocument.document
    );

  const { isLoading: removeDocumentLoading, mutate: removeDocument } =
    useRemoveDocument(projectId, billId, operationNumber, operationDocument);

  const { isLoading: restoreDocumentLoading, mutate: restoreDocument } =
    useAddOperationDocument(projectId, billId, operationNumber);

  const handleDownload = () => {
    if (!revision) {
      return;
    }
    downloadRevision(operationDocument.document, revision.id);
  };

  const showPreview = useShowDocumentPreview();
  const handleOpenPreview = () =>
    showPreview({
      documentId: operationDocument.document,
      revisionId: revision?.id,
    });

  const handleRestore = () => {
    document &&
      restoreDocument({
        ...operationDocument,
        revision:
          operationDocument.revision.type === "specific" &&
          operationDocument.revision.revision
            ? {
                type: "specific",
                revision: operationDocument.revision.revision.id,
              }
            : { type: "last" },
      });
  };
  const handleDelete = () => setDeleting(true);
  const handleCancelDelete = () => setDeleting(false);

  if (!isDocumentVisible) {
    return null;
  }

  return (
    <Card
      onClick={openDocumentDetails}
      isActive={documentInPanel === operationDocument.document}
      icon="document"
      title={<HighlightedText text={document?.name} highlight={search} />}
      titleClassName={classNames(
        status === "deleted" && styles.document_deleted
      )}
      headerActions={
        <>
          <GenericStatusTag status={status} />
          <ButtonGroup isDense className={styles.button_group}>
            <Button
              isDense
              icon="eye-open"
              isDisabled={status === "deleted"}
              onClick={handleOpenPreview}
            />
            <AlertPopover
              icon="trash"
              isDense
              title={tr.translate(
                "project.operation-bill.operation-document.remove"
              )}
              content={tr.translate(
                "project.operation-bill.operation-document.remove.confirm"
              )}
              onClose={handleCancelDelete}
              onPrimaryClick={() => removeDocument()}
              isOpen={isDeleting}
            >
              <Popover2
                content={
                  <MenuSP isDense>
                    <MenuItem
                      isDense
                      disabled={status === "deleted"}
                      icon="download"
                      text={tr.translate("generic.action.download")}
                      onClick={handleDownload}
                    />
                    {status === "deleted" ? (
                      <MenuItem
                        isDense
                        icon="reset"
                        text={tr.translate("generic.action.restore")}
                        onClick={handleRestore}
                      />
                    ) : (
                      <MenuItem
                        isDense
                        icon="trash"
                        text={tr.translate("generic.action.delete")}
                        onClick={handleDelete}
                      />
                    )}
                  </MenuSP>
                }
              >
                <Button
                  isLoading={
                    updateRevisionLoading ||
                    removeDocumentLoading ||
                    restoreDocumentLoading
                  }
                  isDense
                  icon="more"
                />
              </Popover2>
            </AlertPopover>
          </ButtonGroup>
        </>
      }
      className={styles.document_card}
    >
      {document && <VisibleDocumentProperties document={document} />}
      <Field
        inline
        minWidth={180}
        label={tr.translate("generic.label.revision")}
      >
        <DocumentRevisionSelect
          isDisabled={status === "deleted"}
          document={document}
          onChange={updateRevision}
          value={operationDocument.revision}
        />
      </Field>
    </Card>
  );
}

function DocumentRevisionSelect(props: {
  isDisabled?: boolean;
  document: Document | undefined;
  onChange: (rev: OperationDocumentRevisionSpec) => void;
  value: OperationDocumentRevision | undefined;
}) {
  const { isDisabled, document, onChange, value } = props;
  const tr = useTranslations();

  const revision = document && value ? value.revision : undefined;

  const hasValidatedRevision =
    document && document.revisions.find((r) => r.state === "approved");

  const handleStopPropagation = (event: React.MouseEvent) => {
    // don't open document details panel when clicking button
    event.stopPropagation();
  };

  return (
    <div onClick={handleStopPropagation}>
      <Popover2
        position="bottom-left"
        disabled={isDisabled}
        content={
          <MenuSP isDense className={styles.menu}>
            {document?.revisions.map((revision) => (
              <DocumentRevisionItem
                key={revision.id}
                revision={revision}
                onClick={() =>
                  onChange({
                    type: "specific",
                    revision: revision.id,
                  })
                }
              />
            ))}
            <LastRevisionItem
              disabled={!hasValidatedRevision}
              onClick={() =>
                onChange({
                  type: "last",
                })
              }
            />
          </MenuSP>
        }
      >
        <Button isDisabled={isDisabled} isDense rightIcon="caret-down">
          {value?.type === "last" ? (
            tr.translateAsString(
              "project.operation-bill.operation-document.revision.last-value",
              { value: revision?.revisionName ?? "" }
            )
          ) : revision ? (
            <GenericStateTag
              name={revision.revisionName}
              state={revision.state}
            />
          ) : (
            tr.translate("generic.label.none.f")
          )}
        </Button>
      </Popover2>
    </div>
  );
}

function LastRevisionItem(props: { disabled?: boolean; onClick: () => void }) {
  const { disabled = false, onClick } = props;
  const tr = useTranslations();
  return (
    <MenuItem
      isDense
      disabled={disabled}
      text={
        <div>
          <div>
            {tr.translate(
              "project.operation-bill.operation-document.revision.last"
            )}
          </div>
          <div className={classNames(styles.revision_description, styles.warn)}>
            {tr.translate(
              "project.operation-bill.operation-document.revision.last.caption"
            )}
          </div>
        </div>
      }
      onClick={onClick}
    />
  );
}

function DocumentRevisionItem(props: {
  revision: DocumentRevision;
  onClick: () => void;
}) {
  const { revision, onClick } = props;
  return (
    <MenuItem
      isDense
      key={revision.id}
      onClick={onClick}
      text={
        <GenericStateTag
          name={revision.revisionName}
          state={revision.state}
          description={revision.description}
        />
      }
    />
  );
}
