import { DocumentQueryFilterParams } from "@aletiq/types";
import React, { useCallback, useState } from "react";
import {
  ANALYTICS_DOCUMENTS_PREVIEW_SOURCE,
  ANALYTICS_DOCUMENTS_PREVIEW_TABLE,
  ANALYTICS_DOCUMENTS_SHOWN,
  ANALYTICS_VISITED_DOCUMENTS,
  useAnalytics,
  useTrackEvent,
} from "../../../analytics";
import { PageContents, PageTitle } from "../../../components";
import { useColumnConfig, useFilter } from "../../../hooks";
import { useTranslations } from "../../../util";
import { NewDocumentDialog } from "../dialogs";
import {
  useDefaultDocumentColumnConfig,
  useFileUpdate,
  useShowDocumentPreview,
} from "../hooks";
import ActionBar from "./ActionBar";
import DocumentViews from "./DocumentViews";
import { close, PageState, showUploadFile } from "./PageStateHandling";

export default function Documents() {
  useFileUpdate();
  const tr = useTranslations();
  const analytics = useAnalytics();
  useTrackEvent(ANALYTICS_VISITED_DOCUMENTS);
  const { filter, handleFilter, handleClear } =
    useFilter<DocumentQueryFilterParams>({
      search: "",
    });

  const [pageState, setPageState] = useState<PageState>({});

  const { config: defaultConfig } = useDefaultDocumentColumnConfig({
    showRevision: true,
  });

  const { config: columnConfig, handleToggleColumn } =
    useColumnConfig(defaultConfig);

  const showPreview = useShowDocumentPreview();

  const handleShowFilePreview = useCallback(
    (documentId: number) => {
      showPreview({ documentId });
      analytics.track(ANALYTICS_DOCUMENTS_SHOWN, {
        [ANALYTICS_DOCUMENTS_PREVIEW_SOURCE]: ANALYTICS_DOCUMENTS_PREVIEW_TABLE,
      });
    },
    [showPreview, analytics]
  );

  return (
    <PageContents domTitle={tr.translateAsString("page.title.document")}>
      <PageTitle icon="document">
        {tr.translateAsString("documents.view.title")}
      </PageTitle>
      <ActionBar
        filter={filter}
        handleFilter={handleFilter}
        handleClear={handleClear}
        onNewDocument={() => setPageState(showUploadFile())}
        columnConfig={columnConfig}
        onToggleColumn={handleToggleColumn}
      />
      <DocumentViews
        onShowFilePreview={handleShowFilePreview}
        filter={filter}
        columnConfig={columnConfig}
      />
      {pageState.dialog?.type === "upload-file" && (
        <NewDocumentDialog onClose={() => setPageState(close())} />
      )}
    </PageContents>
  );
}
