import { Field, HighlightedText, Tooltip } from "@aletiq/design-system";
import classNames from "classnames";
import React from "react";
import { EditablePropertyModifyButton } from "./buttons";
import styles from "./EditableProperty.module.scss";

export default function EditablePropertyTemplate(props: {
  label: string;
  isEditable: boolean;
  intent?: "primary" | "default";
  triggerEditMode: () => void;
  isInEditMode?: boolean;
  inline?: boolean;
  minWidth?: number;
  tooltipText?: string;
  search?: string;
  children: React.ReactNode;
}) {
  const {
    label,
    isEditable,
    intent = "default",
    triggerEditMode,
    inline = false,
    minWidth,
    isInEditMode = false,
    tooltipText,
    search,
    children,
  } = props;
  const isEditAvailable = isEditable && !isInEditMode;

  const EditablePropertyClassname = classNames(styles.EditableProperty, {
    [styles.EditableProperty__editable]: isEditAvailable,
    [styles.inline]: inline,
  });

  return (
    <Field
      className={EditablePropertyClassname}
      inline={inline}
      label={
        tooltipText && tooltipText !== "" ? (
          <Tooltip position="top" content={tooltipText}>
            <HighlightedText text={label} highlight={search} />
          </Tooltip>
        ) : (
          <HighlightedText text={label} highlight={search} />
        )
      }
      minWidth={minWidth}
    >
      <div className={styles.container}>
        <div className={styles.container__inner}>
          <div className={styles.edit_row}>{children}</div>
        </div>
        {isEditAvailable && (
          <EditablePropertyModifyButton
            intent={intent}
            onClick={triggerEditMode}
          />
        )}
      </div>
    </Field>
  );
}
