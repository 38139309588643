import { API } from "@aletiq/api";
import { PartComponent } from "@aletiq/types";
import { isComponentsComplete, isPartParent } from "../../../services";

export async function handleApproveSubComponents(
  api: API,
  partId: number,
  components: PartComponent[]
) {
  const draftComponents = components.filter(
    (c) => c.iteration && c.iteration.state === "draft"
  );

  if (draftComponents.length === 0) return true;
  else return approveSubComponents(api, draftComponents);
}

async function approveSubComponents(
  api: API,
  draftComponents: PartComponent[]
) {
  if (!isComponentsComplete(draftComponents)) return false;

  try {
    for (const component of draftComponents) {
      if (!component.part || !component.iteration) return false;

      if (component.iteration.state !== "draft") {
        continue;
      }

      //approve own subcomponents
      if (isPartParent(component.part.type)) {
        const subcomponents = await api.pdm.listAssemblyComponents(
          component.part.id
        );
        const draftSubComponents = subcomponents.filter(
          (c) => c.iteration && c.iteration.state === "draft"
        );
        const subCompApproved = await approveSubComponents(
          api,
          draftSubComponents
        );
        if (!subCompApproved) return false;
      }

      //approve component revision
      await api.pdm.savePartRevision(component.part.id, {
        name: component.iteration.name,
        description: component.iteration.description,
      });
    }
  } catch (_) {
    return false;
  }
  return true;
}
