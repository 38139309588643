import { Tag } from "@aletiq/design-system";
import { ApiActivity } from "@aletiq/types";
import React from "react";
import { useProperty } from "../../features/administration/Attributes/hooks";
import { PassportStateName } from "../../features/passport/common";
import { useProject } from "../../features/project/hooks";
import ProductVersionLink from "../../features/project/Version/ProductVersionLink";
import { useTranslations } from "../../util";
import { DateWithSpaces } from "../date";
import { ProjectName } from "../name";
import { UserInitialsList } from "../user-list";
import styles from "./Activities.module.scss";
import ActivityValue from "./ActivityValue";
import { findPassport } from "./findEntity";
import ActivityPassportLink from "./links/ActivityPassportLink";

export default function PassportActivity(props: { activity: ApiActivity }) {
  const { activity } = props;
  const tr = useTranslations();

  const passportLink = (id: number) => {
    const passport = findPassport(activity.entities, id);
    return passport ? <ActivityPassportLink passport={passport} /> : null;
  };

  switch (activity.type) {
    case "passport_created":
      return tr.translate("activity.passport_created", {
        passport: passportLink(activity.metadata.passport),
      });

    case "passport_deleted":
      return tr.translate("activity.passport_deleted", {
        passport: passportLink(activity.metadata.passport),
      });

    case "passport_product_version_updated": {
      if (activity.metadata.previous) {
        return tr.translate("activity.passport_product_version_updated", {
          passport: passportLink(activity.metadata.passport),
          product: (
            <ActivityValue>
              <ProjectName projectId={activity.metadata.product} />
            </ActivityValue>
          ),
          previous: (
            <ProductVersionLink
              product={activity.metadata.product}
              version={activity.metadata.previous}
            />
          ),
          version: (
            <ProductVersionLink
              product={activity.metadata.product}
              version={activity.metadata.version}
            />
          ),
        });
      }
      return tr.translate("activity.passport_product_version_updated.init", {
        passport: passportLink(activity.metadata.passport),
        product: (
          <ActivityValue>
            <ProjectName projectId={activity.metadata.product} />
          </ActivityValue>
        ),
        version: (
          <ProductVersionLink
            product={activity.metadata.product}
            version={activity.metadata.version}
          />
        ),
      });
    }

    case "passport_state_updated":
      return (
        <>
          {tr.translate("activity.passport_state_updated", {
            passport: passportLink(activity.metadata.passport),
            state: (
              <ActivityValue>
                <PassportStateName stateId={activity.metadata.state} />
              </ActivityValue>
            ),
          })}
        </>
      );

    case "passport_config_updated":
      return <PassportConfigUpdatedActivity activity={activity} />;

    case "passport_property_updated":
      return <PassportPropertyUpatedActivity activity={activity} />;

    case "passport_component_added":
    case "passport_component_removed":
      return <PassportComponentActivity activity={activity} />;
    default:
      return null;
  }
}

function PassportConfigUpdatedActivity(props: {
  activity: ApiActivity & { type: "passport_config_updated" };
}) {
  const { activity } = props;
  const tr = useTranslations();

  const passport = findPassport(activity.entities, activity.metadata.passport);

  const passportLink = passport ? (
    <ActivityPassportLink passport={passport} />
  ) : null;

  const { data: project } = useProject(passport?.parentProject || null);

  const configField = project?.options.find(
    (field) => field.id === activity.metadata.field
  );

  const getConfigValue = (valueId: number) =>
    configField?.values.find((value) => value.id === valueId);

  return (
    <>
      {tr.translate("activity.passport_config_updated", {
        passport: passportLink,
      })}
      <ul>
        <li>
          {tr.translate("activity.passport_config_updated.value", {
            field: configField?.name ?? "",
            value:
              activity.metadata.values.length === 0 ? (
                <ActivityValue>
                  {tr.translate("generic.label.none")}
                </ActivityValue>
              ) : (
                activity.metadata.values.map((valueId, index) => (
                  <Tag
                    key={index}
                    style={{
                      marginBottom: "2px",
                      marginRight: "2px",
                      backgroundColor: `var(--tag-color-${
                        (configField?.id ?? 0 % 10) + 1
                      })`,
                      color: "var(--grey100)",
                    }}
                    isDense
                    unclickable
                  >
                    {getConfigValue(valueId)?.name ?? ""}
                  </Tag>
                ))
              ),
          })}
        </li>
      </ul>
    </>
  );
}

function PassportComponentActivity(props: {
  activity: ApiActivity & {
    type: "passport_component_added" | "passport_component_removed";
  };
}) {
  const { activity } = props;
  const passport = findPassport(activity.entities, activity.metadata.passport);
  const componentPassport = findPassport(
    activity.entities,
    activity.metadata.component
  );

  const tr = useTranslations();

  if (activity.type === "passport_component_added") {
    return tr.translate("activity.passport_component_added", {
      component: componentPassport ? (
        <ActivityPassportLink passport={componentPassport} />
      ) : null,
      component_parent: (
        <ActivityValue>
          <ProjectName projectId={componentPassport?.parentProject ?? 0} />
        </ActivityValue>
      ),
      passport: passport ? <ActivityPassportLink passport={passport} /> : null,
      passport_parent: (
        <ActivityValue>
          <ProjectName projectId={passport?.parentProject ?? 0} />
        </ActivityValue>
      ),
    });
  }

  if (activity.type === "passport_component_removed") {
    return tr.translate("activity.passport_component_removed", {
      component: componentPassport ? (
        <ActivityPassportLink passport={componentPassport} />
      ) : null,
      component_parent: (
        <ActivityValue>
          <ProjectName projectId={componentPassport?.parentProject ?? 0} />
        </ActivityValue>
      ),
      passport: passport ? <ActivityPassportLink passport={passport} /> : null,
      passport_parent: (
        <ActivityValue>
          <ProjectName projectId={passport?.parentProject ?? 0} />
        </ActivityValue>
      ),
    });
  }

  return null;
}

function PassportPropertyUpatedActivity(props: {
  activity: ApiActivity & {
    type: "passport_property_updated";
  };
}) {
  const { activity } = props;

  const tr = useTranslations();
  const passport = findPassport(activity.entities, activity.metadata.passport);

  const { data: property } = useProperty(
    activity.metadata.property,
    "passport"
  );
  const options = property?.options ?? [];

  if (!activity.metadata.value) {
    return tr.translate("activity.entity_property_cleared", {
      entity: passport ? <ActivityPassportLink passport={passport} /> : null,
      property: property?.label,
    });
  }

  const getValueLabel = (value: string) =>
    options.find((option) => option.name === value)?.label ?? "";

  return (
    <>
      {tr.translate("activity.entity_property_updated", {
        entity: passport ? <ActivityPassportLink passport={passport} /> : null,
        property: property?.label,
        value:
          activity.metadata.value?.type === "multi-enum" ? (
            activity.metadata.value?.value?.map((val) => (
              <Tag isDense className={styles.tag} unclickable>
                {getValueLabel(val)}
              </Tag>
            ))
          ) : activity.metadata.value?.type === "enum" ? (
            <ActivityValue>
              {getValueLabel(activity.metadata?.value?.value ?? 0)}
            </ActivityValue>
          ) : activity.metadata.value?.type === "user" ? (
            <ActivityValue>
              <UserInitialsList
                users={activity.metadata.value?.value ?? []}
                type="small"
              />
            </ActivityValue>
          ) : activity.metadata.value?.type === "date" ? (
            <ActivityValue>
              <DateWithSpaces date={activity.metadata.value.value} />
            </ActivityValue>
          ) : (
            <ActivityValue>{activity.metadata.value?.value}</ActivityValue>
          ),
      })}
    </>
  );
}
