// src/isInString.ts
function isInString(value, search) {
  var _a;
  if (!search)
    return true;
  return ((_a = value == null ? void 0 : value.toLocaleLowerCase()) == null ? void 0 : _a.includes(search == null ? void 0 : search.toLocaleLowerCase())) ?? false;
}

// src/regexSanitize.ts
function regexSanitize(value) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
export {
  isInString,
  regexSanitize
};
