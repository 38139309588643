import { ActiveDocument, DocumentQueryFilterParams } from "@aletiq/types";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import {
  ActionBar,
  DefaultFilters,
  Field,
  PageTitle,
} from "../../../../components";
import { useDownloadQueue, useFilter } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useShowDocumentPreview } from "../../../documents/hooks";
import { useProductVersions, useVersionDocsFilter } from "../../hooks";
import styles from "./VersionDetails.module.scss";
import VersionDocuments from "./VersionDocuments";

export default function VersionDetails(props: {
  projectId: number;
  documents: ActiveDocument[];
  title: string;
}) {
  const { projectId, documents, title } = props;
  const tr = useTranslations();

  const { params } = useRouteMatch<{ version: string }>();
  const versionId = Number.parseInt(params.version || "");

  const { data: versions = [] } = useProductVersions(projectId);

  const version = versions.find((it) => it.id === versionId);

  const { handleDownload } = useDownloadQueue();

  const { filter, handleFilter } = useFilter<DocumentQueryFilterParams>({
    search: "",
  });
  const { filteredDocs } = useVersionDocsFilter(documents, filter);

  const handlePreview = (documentId: number, revisionId: number) => {
    showPreview({ documentId, revisionId });
  };

  const showPreview = useShowDocumentPreview();

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <div className={styles.version_description}>
        {version?.comment && (
          <Field label={tr.translate("generic.label.description")}>
            {version?.comment?.split("\n")?.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </Field>
        )}
      </div>
      <ActionBar>
        <DefaultFilters
          objectType="document"
          filters={filter}
          handleFilter={handleFilter}
        />
      </ActionBar>
      <VersionDocuments
        documents={filteredDocs}
        setPreview={handlePreview}
        onDownload={handleDownload}
        search={filter.search}
      />
    </>
  );
}
