import { API } from "@aletiq/api";
import { PartError, PartStage, PartState } from "../types";

export default async function handleExistingPart(
  api: API,
  partState: PartState
): Promise<PartError | void> {
  const stage: PartStage = "uploading";
  const { partId, file, partInfo } = partState;
  const name = file.name;
  if (!partId)
    return {
      name,
      stage,
      error: new Error("Missing part id"),
    };
  try {
    await api.pdm.uploadCadFile(partId, partInfo.revision, file);
  } catch (error) {
    return { name, partId, stage, error: error as Error };
  }
}
