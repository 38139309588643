import { HighlightedText } from "@aletiq/design-system";
import React from "react";
import {
  DateWithSpaces,
  StatusSelect,
  StatusTag,
  UserInitialsList,
  WorkflowNameLink,
} from "../../../components";
import { useCurrentUser, useHasPermission } from "../../../hooks";
import { useTasks } from "../hooks";
import { ResolvedTask } from "../hooks/useUserTasks";
import styles from "./Tasks.module.scss";

export default function Task(props: { task: ResolvedTask; search?: string }) {
  const { task, search = "" } = props;

  const { data: tasks = [] } = useTasks();
  const { data: currentUser } = useCurrentUser();
  const canUpdateAllWorkflows = useHasPermission("update:all-workflows");
  const canUpdateOwnTaskStatus = useHasPermission("update:own-task-status");

  const hasTaskStatusUpdateRights =
    canUpdateAllWorkflows ||
    task.process.owner === currentUser?.id ||
    (canUpdateOwnTaskStatus && task?.owners.includes(currentUser?.id!));

  const hasLockedDependencies = tasks
    .filter((otherTask) => task.dependencies.includes(otherTask.id))
    .some((dep) => dep.status !== "done");

  return (
    <tr key={task.id} className={styles.row}>
      <td>
        <HighlightedText text={task.title} highlight={search} />
      </td>
      <td>
        <WorkflowNameLink
          workflowId={task.process.id}
          search={search}
          hasIcon
        />
      </td>
      <td className={styles.owner_cell}>
        <UserInitialsList users={task.owners} fill />
      </td>
      <td className={styles.date_cell}>
        {task.dueDate && <DateWithSpaces date={task.dueDate} />}
      </td>
      <td className={styles.status_cell}>
        {task.process.isArchived && (
          <StatusTag
            isDisabled
            isTaskLocked={hasLockedDependencies}
            completionDate={task.completionDate}
          >
            {task.status}
          </StatusTag>
        )}
        {!task.process.isArchived && (
          <StatusSelect
            task={task}
            hasUpdateRights={hasTaskStatusUpdateRights}
            hasLockedDependencies={hasLockedDependencies}
          />
        )}
      </td>
    </tr>
  );
}
