import React from "react";
import { Route } from "react-router-dom";
import Attributes from "../features/administration/Attributes";
import Exports from "../features/administration/exports/Exports";
import { IntegrationsReport } from "../features/administration/IntegrationsReport";
import NotificationSettings from "../features/administration/NotificationSettings";
import { Users } from "../features/administration/Users";

export default function AdministrationRoutes() {
  return (
    <>
      <Route path="/administration/notifications">
        <NotificationSettings />
      </Route>
      <Route path="/administration/attributes">
        <Attributes />
      </Route>
      <Route path="/administration/integration/report">
        <IntegrationsReport />
      </Route>
      <Route path="/administration/exports">
        <Exports />
      </Route>
      <Route path="/administration/users">
        <Users />
      </Route>
    </>
  );
}
