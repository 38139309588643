import { Part } from "@aletiq/types";
import { useQuery, UseQueryOptions } from "react-query";
import useApi from "../../../app/useApi";
import { pdmQueries } from "./queries";

export default function usePart<T = Part>(
  partId: number | null | undefined,
  queryOptions?: UseQueryOptions<Part, unknown, T>
) {
  const api = useApi();
  return useQuery({
    ...pdmQueries.byId(api, partId!),
    enabled: partId !== null && partId !== undefined && partId !== 0,
    ...queryOptions,
  });
}
