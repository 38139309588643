import React from "react";
import { Icon } from "../../../../../components";
import styles from "./PartLockHeaderCell.module.scss";
import { useTranslations } from "../../../../../util";

export default function PartLockHeaderCell() {
  const tr = useTranslations();

  return (
    <div className={styles.lock_header}>
      <Icon icon="lock" inline className={styles.lock_icon} />
      <span>{tr.translate("part.details.modification")}</span>
    </div>
  );
}
