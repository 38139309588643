import React from "react";
import { useTranslations } from "../../../util";
import styles from "./AutoUpdateTag.module.scss";

export default function AutoUpdateTag() {
  const tr = useTranslations();
  return (
    <div className={styles.tag}>{tr.translate("version.tag.auto-update")}</div>
  );
}
