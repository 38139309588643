import { ProjectDefinitionId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { projectKeys } from "./queries";

export default function useValidateProjectsDefinitions() {
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const tr = useTranslations();

  return useMutation(
    async (definitions: ProjectDefinitionId[]) => {
      await Promise.all(
        definitions.map(({ definitionIdx, productId }) =>
          api.project.validateProjectDefinition(productId, definitionIdx)
        )
      );
    },
    {
      onSuccess: (_, definitions) => {
        definitions.forEach(({ productId }) => {
          queryClient.invalidateQueries(projectKeys.allDefinitions(productId));
        });
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.project.definition.validate"),
        });
      },
    }
  );
}
