import { DialogBase } from "@aletiq/design-system";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import styles from "./PreviewOverlay.module.scss";

export default function TextFileOverlay(props: {
  content: string;
  onClose: () => void;
}) {
  const { content, onClose } = props;
  return (
    <DialogBase isOpen onClose={onClose} className={styles.dialog}>
      <pre className={classNames(Classes.CODE_BLOCK, styles.dialog_contents)}>
        {content}
      </pre>
    </DialogBase>
  );
}
