import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useProjectDefinitionDocuments(
  projectId: number,
  definitionIdx: number
) {
  const api = useApi();

  return useQuery(
    projectKeys.allDocumentsDefinition(projectId, definitionIdx),
    () => api.project.listProjectDefinitionDocument(projectId, definitionIdx)
  );
}
