import React from "react";
import { GenericStateTag } from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./NewVersion.module.scss";

export default function VersionValidationError(props: {
  documentNames: string[];
}) {
  const { documentNames } = props;
  const tr = useTranslations();

  return (
    <>
      <h3>{tr.translate("version.new.toaster.doc.drafts.title")}</h3>
      <span className={styles.error_toast_msg}>
        {tr.translate("version.new.toaster.doc.drafts.msg", {
          draft: <GenericStateTag state="draft" />,
          obsolete: <GenericStateTag state="obsolete" />,
        })}
      </span>
      <ul className={styles.error_toast_msg}>
        {documentNames.map((docName) => (
          <li key={docName}>{docName}</li>
        ))}
      </ul>
    </>
  );
}
