import { API } from "@aletiq/api";
import {
  BOMEntry,
  PartComponent,
  PartWithIteration,
  ProjectWithDefinition,
} from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../../app/useApi";
import { makePathId } from "../../../../../util";
import { projectKeys } from "../../../hooks/queries";
import {
  makeEmptyNode,
  makeProductNode,
  ProductTree,
} from "../partProjectBomUtils";

async function resolveUnassignedProduct(
  api: API,
  component: BOMEntry,
  pathId: string
) {
  const product = await api.project.getProject(component.entity);

  return makeProductNode(null, product, makePathId(product.id, pathId), [], []);
}

async function resolvePartNode(
  api: API,
  expandedNodes: number[],
  productComponents: BOMEntry[],
  parentPath: string,
  partComponent: PartComponent
) {
  const { part, iteration } = partComponent;
  if (!part || !iteration) {
    return makeEmptyNode();
  }
  const partWithIteration = { part, iteration };

  const pathId = makePathId(part.id, parentPath);

  const partComponentProjects = await api.pdm.listPartProjectBomWithIteration(
    part.id,
    iteration.number
  );
  const productComponentEntries = productComponents.filter((p) =>
    partComponentProjects.includes(p.entity)
  );
  const matchingProducts = await Promise.all(
    productComponentEntries.map((e) => api.project.getProject(e.entity))
  );
  const linkedProducts = await Promise.all(
    partComponentProjects.map((id) => api.project.getProject(id))
  );
  const product = matchingProducts.length === 1 ? matchingProducts[0] : null;

  const productWithDefinition = product
    ? { product, definition: product.lastDefinition }
    : undefined;

  const components = expandedNodes.includes(part.id)
    ? await resolveSubComponents(
        api,
        expandedNodes,
        partWithIteration,
        productWithDefinition,
        pathId
      )
    : [];

  return makeProductNode(
    partWithIteration,
    product,
    pathId,
    components,
    linkedProducts
  );
}

async function resolveSubComponents(
  api: API,
  expandedNodes: number[],
  partWithIteration: PartWithIteration,
  productWithDefinition: ProjectWithDefinition | undefined,
  parentPath: string
): Promise<ProductTree[]> {
  const partComponents = await api.pdm.listAssemblyIterationComponents(
    partWithIteration.part.id,
    partWithIteration.iteration.number
  );
  const productComponents =
    productWithDefinition !== undefined
      ? await api.project.getProjectBOM(
          productWithDefinition.product.id,
          productWithDefinition.definition.index
        )
      : [];

  const partNodes = await Promise.all(
    partComponents.map((partComponent) =>
      resolvePartNode(
        api,
        expandedNodes,
        productComponents,
        parentPath,
        partComponent
      )
    )
  );

  const unassignedProducts = productComponents.filter(
    (c) => !partNodes.find((n) => n.product?.id === c.entity)
  );

  const productNodes = await Promise.all(
    unassignedProducts.map((bomEntry) =>
      resolveUnassignedProduct(api, bomEntry, parentPath)
    )
  );

  return [...partNodes, ...productNodes];
}

export default function usePartProjectBomTree(
  projectId: number,
  definitionIdx: number,
  expandedNodes: number[],
  enabled: boolean = true,
  onSettled: (data?: ProductTree, error?: unknown) => void
) {
  const api = useApi();
  return useQuery(
    projectKeys.bomPartTree(projectId, definitionIdx, expandedNodes),
    async (): Promise<ProductTree> => {
      const product = await api.project.getProject(projectId);
      const definition = await api.project.getProjectDefinition(
        projectId,
        definitionIdx
      );
      const productWithDefinition = { product, definition };
      const partWithIteration = await api.pdm.getPartProjectBom(
        projectId,
        definitionIdx
      );
      if (!partWithIteration) {
        return makeEmptyNode();
      }
      const pathId = makePathId(partWithIteration.part.id);
      const resolvedComponents = await resolveSubComponents(
        api,
        expandedNodes,
        partWithIteration,
        productWithDefinition,
        pathId
      );

      return makeProductNode(
        partWithIteration,
        product,
        pathId,
        resolvedComponents,
        []
      );
    },
    { enabled, keepPreviousData: true, onSettled }
  );
}
