import {
  AnchorButtonSP,
  Field,
  ItemSelect,
  Select,
} from "@aletiq/design-system";
import { PropertyType } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../util";
import { PropertyTypeLabel, PROPERTY_TYPES } from "../PropertyTypeLabel";
import styles from "./PropertyTypePicker.module.scss";

export default function PropertyTypePicker(props: {
  onSelect: (type: PropertyType) => void;
  type: PropertyType;
  isDisabled: boolean;
}) {
  const { type, isDisabled, onSelect } = props;
  const tr = useTranslations();

  const selectOptions: ItemSelect<PropertyType>[] = PROPERTY_TYPES.map(
    (type) => ({
      key: type.type,
      text: tr.translateAsString(type.label),
      icon: type.icon,
    })
  );

  return (
    <Field label={tr.translate("admin.attribute.common.type")}>
      <Select
        isFill
        items={selectOptions}
        activeItem={type}
        onItemSelect={onSelect}
        isDisabled={isDisabled}
        filterable={false}
        popoverProps={{ position: "bottom-right" }}
      >
        <AnchorButtonSP
          isDisabled={isDisabled}
          className={styles.select}
          rightIcon="caret-down"
          view="outlined"
          color="primary"
          text={<PropertyTypeLabel type={type} />}
        />
      </Select>
    </Field>
  );
}
