import { Input } from "@aletiq/design-system";
import React from "react";
import styles from "./Filter.module.scss";
import FilterTemplate from "./FilterTemplate";

export default function TextFilter(props: {
  value: string | undefined;
  label: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onClear: () => void;
}) {
  const { value = "", label, placeholder, onClear, onChange } = props;

  return (
    <FilterTemplate
      count={value !== "" ? 1 : 0}
      onClear={onClear}
      placeholder={label}
      content={
        <div className={styles.text_filter}>
          <Input
            isDense
            autoFocus
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={styles.search}
          />
        </div>
      }
    />
  );
}
