import { Dialog } from "@aletiq/design-system";
import { PropertyObject } from "@aletiq/types";
import { Icon } from "@blueprintjs/core";
import React from "react";
import { useTranslations } from "../../../../../util";
import { useDeleteCustomProperty } from "../../hooks";
import styles from "./Dialogs.module.scss";

export default function ConfirmDeletePropertyDialog(props: {
  onClose: () => void;
  propertyName: string;
  objectType: PropertyObject;
}) {
  const { propertyName, onClose, objectType } = props;
  const tr = useTranslations();

  const { mutate: deleteProperty, isLoading } = useDeleteCustomProperty(
    objectType,
    propertyName
  );

  const onSubmit = () => {
    deleteProperty(undefined, {
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      intent="danger"
      icon={<Icon icon="disable" intent="danger" />}
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("admin.attribute.disable.title")}
      onPrimaryClick={onSubmit}
      submitting={isLoading}
    >
      <div>
        {tr.translate("admin.attribute.disable.description", {
          type: objectType,
        })}
      </div>
    </Dialog>
  );
}
