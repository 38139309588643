import { NonIdealState, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import styles from "./tree.module.scss";

export default function TreeContent(props: {
  children: React.ReactNode;
  isFetching?: boolean;
}) {
  const { children, isFetching } = props;
  return (
    <div className={classNames(styles.tree, isFetching && styles.loading)}>
      {isFetching && (
        <div className={styles.spin_tile}>
          <NonIdealState>
            <Spinner />
          </NonIdealState>
        </div>
      )}
      <div className={styles.columns}>{children}</div>
    </div>
  );
}
