import { ProjectQueryFilterParams } from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_PRODUCT_TYPE,
  ANALYTICS_VISITED_PRODUCTS,
  useTrackEvent,
} from "../../analytics";
import { PageContents, PageTitle } from "../../components";
import { useColumnConfig, useFilter } from "../../hooks";
import { useTranslations } from "../../util";
import { ProjectActionBar } from "./common";
import { ProjectCreationDialog } from "./dialogs";
import { useDefaultProductColumnConfig, useProjectType } from "./hooks";
import PaginatedProjectList from "./PaginatedProjectList";

export default function Projects() {
  const projectType = useProjectType();
  const tr = useTranslations();

  useTrackEvent(ANALYTICS_VISITED_PRODUCTS, {
    [ANALYTICS_PRODUCT_TYPE]: projectType,
  });

  const [createProduct, setCreateProduct] = useState(false);
  const { config: defaultConfig } = useDefaultProductColumnConfig(projectType);
  const { config: columnConfig, handleToggleColumn } =
    useColumnConfig(defaultConfig);

  const { filter, handleFilter, handleClear } =
    useFilter<ProjectQueryFilterParams>({
      search: "",
    });

  return (
    <PageContents
      domTitle={tr.translateAsString("page.title.product", {
        type: projectType,
      })}
    >
      <PageTitle icon={projectType === "tool" ? "wrench" : "projects"}>
        {tr.translate("project.list.title", { type: projectType })}
      </PageTitle>
      <ProjectActionBar
        filter={filter}
        handleFilter={handleFilter}
        handleClear={handleClear}
        type={projectType}
        onCreateProduct={() => setCreateProduct(true)}
        columnConfig={columnConfig}
        onToggleColumn={handleToggleColumn}
      />
      <PaginatedProjectList
        projectType={projectType}
        filter={filter}
        onClickCreateProduct={() => setCreateProduct(true)}
        columnConfig={columnConfig}
      />
      {createProduct && (
        <ProjectCreationDialog
          onClose={() => setCreateProduct(false)}
          type={projectType}
        />
      )}
    </PageContents>
  );
}
