import React from "react";
import { PageTitle } from "../../../components";
import { useTranslations } from "../../../util";
import {
  ANALYTICS_VISITED_PRODUCT_PARTS,
  useTrackEvent,
} from "../../../analytics";
import AllPartsListPaginated from "../../pdm/AllParts/AllPartsListPaginated/AllPartsListPaginated";
import { ProjectPage } from "../common";
import styles from "./ProjectParts.module.scss";

export default function ProjectParts(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_PARTS);

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.part")}
    >
      <div className={styles.page}>
        <PageTitle icon="cube">{tr.translate("sidebar.pdm")}</PageTitle>
        <AllPartsListPaginated projectId={projectId} allowStandard />
      </div>
    </ProjectPage>
  );
}
