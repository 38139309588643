import { Dialog, Field, Input } from "@aletiq/design-system";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../app/useApi";
import { adminKeys } from "./hooks/queries";

export default function CreateTenantDialog(props: { onClose: () => void }) {
  const { onClose } = props;
  const [name, setName] = useState("");
  const api = useApi();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    () => api.http.post("/admin/tenants", JSON.stringify({ name: name })),
    {
      onSettled: () => {
        queryClient.invalidateQueries(adminKeys.tenants());
        onClose();
      },
    }
  );
  return (
    <Dialog
      isOpen
      onClose={onClose}
      title="Create a tenant"
      onPrimaryClick={() => mutate()}
      submitting={isLoading}
    >
      <Field label="Name">
        <Input value={name} onChange={setName} />
      </Field>
    </Dialog>
  );
}
