import { PropertyObject } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { propertyKeys } from "./queries";

export default function useCountPropertyUses(
  objectType: PropertyObject,
  propertyName: string
) {
  const api = useApi();
  return useQuery(propertyKeys.usedIn(objectType, propertyName), () =>
    api.property.countPropertyUses(objectType, propertyName)
  );
}
