import React, { useState } from "react";
import {
  useProjectDefinition,
  useProjectDefinitionDocuments,
} from "../../hooks";
import { diffDocuments } from "../compareDefinition";
import styles from "./ProjectDefinitionDocuments.module.scss";
import ProjectDefinitionDocumentsActionBar from "./ProjectDefinitionDocumentsActionBar";
import ProjectDefinitionDocumentsCardList from "./ProjectDefinitionDocumentsCardList";

export default function ProjectDefinitionDocumentsPanel(props: {
  projectId: number;
  definitionIdx: number;
}) {
  const { projectId, definitionIdx } = props;

  const [search, setSearch] = useState("");

  const { data: currentDocuments = [] } = useProjectDefinitionDocuments(
    projectId,
    definitionIdx
  );

  const { data: previousDocuments = [] } = useProjectDefinitionDocuments(
    projectId,
    definitionIdx - 1
  );

  const diff = diffDocuments(currentDocuments, previousDocuments);

  const { data: definition } = useProjectDefinition(projectId, definitionIdx);

  const hasEdition = definition && definition.state === "draft";

  return (
    <div className={styles.panel}>
      <ProjectDefinitionDocumentsActionBar
        hasEdition={hasEdition}
        projectId={projectId}
        definitionIdx={definitionIdx}
        search={search}
        onSearchChange={setSearch}
      />

      <ProjectDefinitionDocumentsCardList
        hasEdition={hasEdition}
        search={search.toLowerCase()}
        documents={currentDocuments}
        projectId={projectId}
        definitionIdx={definitionIdx}
        diff={diff}
      />
    </div>
  );
}
