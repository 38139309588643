import { Text } from "@blueprintjs/core";
import React, { Fragment } from "react";
import { Icon, IgnoreTag } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import styles from "./UploadedPartsList.module.scss";
import classNames from "classnames";

export default function UnuspportedFiles(props: { files: File[] }) {
  const { files } = props;
  const tr = useTranslations();

  return (
    <Fragment>
      {files.map((file) => (
        <tr key={file.name}>
          <td className={styles.tag_col}>
            <Text ellipsize className={styles.locked}>
              <Icon icon="warning-sign" inline />
              {tr.translate("part.dialog.drop-parts.unsupported")}
            </Text>
          </td>
          <td>
            <div className={classNames(styles.part_name, styles.ignored)}>
              <Icon inline icon="cube" />
              {file.name}
            </div>
          </td>
          <td className={styles.locked_action_col}>
            <IgnoreTag />
          </td>
          <td colSpan={2} />
        </tr>
      ))}
    </Fragment>
  );
}
