import { Input, ItemSelect, NumericInput, Select } from "@aletiq/design-system";
import { Property, PropertyValue } from "@aletiq/types";
import React from "react";
import PropertyValueMultiselect from "../../features/passport/common/PropertyValueMultiselect";
import { formatDate, toDate, useTranslations } from "../../util";
import { DateInput } from "../date";
import { EditableUserList } from "../user-list";

export default function EditCustomPropertyField(props: {
  property?: Property;
  propertyValue: PropertyValue;
  onEditValue: (value: PropertyValue) => void;
  isDense?: boolean;
  isDisabled?: boolean;
}) {
  const {
    property,
    propertyValue,
    onEditValue,
    isDense = false,
    isDisabled,
  } = props;
  const tr = useTranslations();

  const nullValue = {
    key: "",
    text: tr.translateAsString("generic.label.none"),
    hidden: false,
  };

  const currentValue = property?.options.find(
    (o) => o.value === propertyValue?.value
  );

  const selectedPropertyOptions: ItemSelect<string>[] =
    property && (property.type === "enum" || property.type === "multi-enum")
      ? [
          nullValue,
          ...property.options.map((o) => ({
            key: o.name,
            text: o.label,
          })),
        ]
      : [];

  const handleEnterTextValue = (value: string) => {
    if (property?.type === "string") {
      onEditValue({ type: "string", value });
    }
  };

  const handleEnterDateValue = (date: Date | null) => {
    if (property?.type === "date") {
      onEditValue(date ? { type: "date", value: formatDate(date) } : null);
    }
  };

  const handleEnterUserValue = (users: number[]) => {
    if (property?.type === "user") {
      onEditValue({ type: "user", value: users });
    }
  };

  const handleEnterNumericValue = (value: number) => {
    if (property?.type === "number") {
      onEditValue({ type: "number", value });
    }
  };

  const handleSelectValue = (value: string) => {
    if (property?.type === "enum") {
      onEditValue(value === "" ? null : { type: "enum", value });
    }
  };

  const handleSelectMultipleValues = (values: string[]) => {
    if (property?.type === "multi-enum") {
      onEditValue({ type: "multi-enum", value: values });
    }
  };

  return (
    <>
      {property?.type === "string" &&
        (propertyValue?.type === "string" || !propertyValue) && (
          <Input
            fill
            intent="primary"
            value={propertyValue?.value ?? ""}
            onChange={handleEnterTextValue}
            isDense={isDense}
            placeholder={property.description}
            disabled={isDisabled}
          />
        )}

      {property?.type === "date" &&
        (propertyValue?.type === "date" || !propertyValue) && (
          <DateInput
            addSpaces
            onChange={handleEnterDateValue}
            value={propertyValue?.value ? toDate(propertyValue.value) : null}
            intent="primary"
            isDense={isDense}
            disabled={isDisabled}
            fill
          />
        )}

      {property?.type === "number" &&
        (propertyValue?.type === "number" || !propertyValue) && (
          <NumericInput
            isFill
            intent="primary"
            value={propertyValue?.value}
            onValueChange={handleEnterNumericValue}
            placeholder={property.description}
            disabled={isDisabled}
            isDense={isDense}
          />
        )}

      {property?.type === "user" &&
        (propertyValue?.type === "user" || !propertyValue) && (
          <EditableUserList
            intent="primary"
            onEditSelection={handleEnterUserValue}
            users={propertyValue?.value ?? []}
            isDisabled={isDisabled}
            isDense={isDense}
          />
        )}

      {property?.type === "multi-enum" &&
        (propertyValue?.type === "multi-enum" || !propertyValue) && (
          <PropertyValueMultiselect
            options={selectedPropertyOptions}
            selectedValues={propertyValue?.value ?? []}
            onValuesChange={handleSelectMultipleValues}
            isDisabled={isDisabled}
            isDense={isDense}
            fill
          />
        )}

      {property?.type === "enum" &&
        (propertyValue?.type === "enum" || !propertyValue) && (
          <Select
            isFill
            filterable
            activeItem={propertyValue?.value}
            items={selectedPropertyOptions}
            onItemSelect={handleSelectValue}
            isDisabled={isDisabled}
            isDense={isDense}
            intent="outlined"
          >
            {currentValue?.label || tr.translateAsString("generic.label.none")}
          </Select>
        )}
    </>
  );
}
