import { ButtonSP, TagSP } from "@aletiq/design-system";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";
import styles from "./Filter.module.scss";
import { Intent } from "./FilterMenu";

export default function FilterTemplate(props: {
  disabled?: boolean;
  count: number;
  onClear: () => void;
  placeholder?: string;
  content: JSX.Element;
  intent?: Intent;
}) {
  const {
    intent = "primary",
    disabled,
    onClear,
    placeholder,
    content,
    count,
  } = props;
  const isSelected = count > 0;

  const handleClear = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    onClear();
  };

  return (
    <Popover2
      minimal
      disabled={disabled}
      placement={"bottom-start"}
      content={content}
      popoverClassName={styles.popover__inner}
    >
      <ButtonSP
        view="flat"
        color={intent}
        isDisabled={disabled}
        className={styles.filter_button}
        rightIcon="caret-down"
        isDense
      >
        {placeholder}
        {isSelected && (
          <TagSP
            unclickable
            isDense
            isRemovable
            color={intent}
            view="filled"
            onRemove={handleClear}
            className={styles.selectPart}
          >
            {count}
          </TagSP>
        )}
      </ButtonSP>
    </Popover2>
  );
}
