import { AccessEntity } from "@aletiq/types";
import { hasOpenAccess } from "../../util";

export default function useSortedAccess(accessEntities: AccessEntity[]) {
  const isPublic = hasOpenAccess(accessEntities);

  const { usersWithAccess, groupsWithAccess } = accessEntities.reduce(
    (
      acc: {
        usersWithAccess: number[];
        groupsWithAccess: number[];
      },
      access
    ) => {
      if (access.type === "group") acc.groupsWithAccess.push(access.group);
      if (access.type === "user") acc.usersWithAccess.push(access.user);
      return acc;
    },
    { usersWithAccess: [], groupsWithAccess: [] }
  );

  return {
    isPublic,
    usersWithAccess,
    groupsWithAccess,
    accessEntities,
  };
}
