import {
  AnchorButton,
  AnchorButtonProps,
  AnchorButtonSP,
} from "@aletiq/design-system";
import React from "react";

export default function BatchActionButton(
  props: {
    title: string;
  } & AnchorButtonProps
) {
  const { title, intent, ...buttonProps } = props;

  if (intent === "outlined") {
    return (
      <AnchorButtonSP isDense view="outlined" color="default" {...buttonProps}>
        {title}
      </AnchorButtonSP>
    );
  }

  return (
    <AnchorButton isDense {...buttonProps} intent="tertiary">
      {title}
    </AnchorButton>
  );
}
