import { DocumentRevision } from "@aletiq/types";
import { useCallback } from "react";
import useApi from "../../../app/useApi";
import {
  useShowImage,
  useShowTextFile,
  useShowVideo,
} from "../../../components";
import { useFilePreviewOverlay } from "../../../hooks";
import useDocumentPreviewToken from "./useDocumentPreviewToken";

export default function useShowDocumentPreview() {
  const api = useApi();

  const getPdfPreviewToken = useDocumentPreviewToken();

  const showPdfPreview = useFilePreviewOverlay(getPdfPreviewToken);
  const showTextFile = useShowTextFile();
  const showImage = useShowImage();
  const showVideo = useShowVideo();

  return useCallback(
    async (value: { documentId: number; revisionId?: number }) => {
      const document = await api.document.getDocument(value.documentId);
      let revision: DocumentRevision | undefined = document.revisions[0];
      if (value.revisionId) {
        revision = document.revisions.find((r) => r.id === value.revisionId);
      }
      if (!revision) {
        return;
      }

      const token = await api.document.getDocumentDownloadToken(
        value.documentId,
        value.revisionId
      );

      if (revision.file.type.startsWith("image/")) {
        const content = await api.document.getDocumentDownloadBlob(token);
        showImage(URL.createObjectURL(content));
        return;
      }

      if (revision.file.type === "text/plain") {
        const content = await api.document.getDocumentDownloadText(token);
        showTextFile(content);
        return;
      }

      if (revision.file.type.startsWith("video/")) {
        const content = await api.document.getDocumentDownloadBlob(token);
        showVideo(URL.createObjectURL(content));
        return;
      }

      showPdfPreview({ ...value, fileName: revision.file.name });
    },
    [showPdfPreview, api, showTextFile, showImage, showVideo]
  );
}
