import { Part } from "@aletiq/types";
import { Classes, Tab, TabId } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useState } from "react";
import {
  Activities,
  Button,
  DeleteAlert,
  Divider,
  PanelDetails,
  PanelRenamableTitle,
  PartIcon,
  Popover2,
  Tabs,
} from "../../../components";
import { useCurrentUser, useHasPermission, useNavigate } from "../../../hooks";
import { DEFAULT_PART_TAB, isValidTabId, useTranslations } from "../../../util";
import EntityProcesses from "../../tasks/common/EntityProcesses";
import { useDeletePart, useRenamePart, useWaitForPartDownload } from "../hooks";
import isPartParent from "../services/isPartParent";
import styles from "./PartDetails.module.scss";
import PartDialogs, { PartDialog } from "./PartDialogs";
import PartInfo from "./PartInfo";
import PartIterations from "./PartIterations";
import { PartMenu } from "./PartMenu";
import PartProducts from "./PartProducts";
import PartUsedIn from "./PartUsedIn/index";

export default function NormalPartDetails(props: {
  part: Part;
  onClose: () => void;
  tab?: string;
}) {
  const { part, onClose, tab = DEFAULT_PART_TAB } = props;
  const tr = useTranslations();

  const partId = part.id;
  const navigate = useNavigate();
  const downloadPart = useWaitForPartDownload(partId);

  const { data: currentUser } = useCurrentUser();
  const { mutate: deletePart } = useDeletePart();
  const { mutate: renamePart } = useRenamePart();

  const canUpdateParts = useHasPermission("update:parts");

  //state
  const [deleteAlert, setDeleteAlert] = useState<{
    onDelete: () => void;
    content: string;
  }>();
  const [isRenaming, setRenaming] = useState(false);
  const [dialog, setDialog] = useState<PartDialog | undefined>();

  const handleDownload = (revision?: number) => {
    downloadPart(revision);
  };

  // View
  const partIsLocked = part?.lockOwner !== null;

  const lockState = partIsLocked
    ? part?.lockOwner === currentUser?.id
      ? "current_user"
      : "other_user"
    : "not_locked";

  const submitNewName = (editedName: string) => {
    renamePart({ partId, name: editedName || "" });
    setRenaming(false);
  };

  const handleDelete = () => {
    deletePart(partId, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  const handleSetTab = (tabId: TabId) => {
    navigate({ panelTab: tabId.toString() });
  };

  return (
    <PanelDetails
      id={partId}
      title={
        <PanelRenamableTitle
          name={part.name}
          icon={<PartIcon type={part.type} iconSize={20} />}
          isRenaming={isRenaming}
          resetIsRenaming={() => {
            setRenaming(false);
          }}
          onSubmit={submitNewName}
        />
      }
      additionalButtons={
        <Popover2
          content={
            <PartMenu
              onDownloadArchive={() => handleDownload(undefined)}
              lockState={lockState}
              onOpenDialog={setDialog}
              onStartRenaming={() => {
                setRenaming(true);
              }}
              onDelete={() =>
                setDeleteAlert({
                  onDelete: handleDelete,
                  content: tr.translateAsString("part.action.delete.message"),
                })
              }
              hasComponents={part && isPartParent(part?.type)}
              hasDraftRevision={part.lastIteration.state === "draft"}
              partOwner={part.owner}
            />
          }
          minimal
          position="bottom-right"
        >
          <Button icon="more" isDense />
        </Popover2>
      }
      navigateTooltip={tr.translateAsString("part.details.open.cad-space")}
      canNavigateToList={false}
      navigateToList={() => {}}
    >
      <div className={classNames(Classes.DRAWER_BODY, styles.drawer)}>
        <PartInfo part={part} />
        <div className={styles.drawer_body}>
          <Divider className={styles.divider} />
          {
            <Tabs
              selectedTabId={isValidTabId(tab, "part") ? tab : DEFAULT_PART_TAB}
              onChange={handleSetTab}
              className={styles.tabs}
              renderActiveTabPanelOnly
              isDense
              center
            >
              <Tab
                id="activities"
                panel={<Activities entity={{ type: "part", id: partId }} />}
                className={styles.tab}
              >
                {tr.translate("part.details.activities")}
              </Tab>
              <Tab
                id="revisions"
                panel={
                  part && (
                    <PartIterations
                      onOpenUploadDialog={() => setDialog("upload-design")}
                      onOpenApproveAssemblyDialog={() =>
                        setDialog("save-assembly-iteration")
                      }
                      part={part}
                      onDownload={handleDownload}
                      lockState={lockState}
                    />
                  )
                }
                className={styles.tab}
              >
                {tr.translate("part.details.revisions")}
              </Tab>
              <Tab id="used-in" panel={part && <PartUsedIn partId={partId} />}>
                {tr.translate("part.details.used-in")}
              </Tab>
              <Tab
                id="workflows"
                panel={
                  <EntityProcesses
                    entityId={{
                      type: "part",
                      id: partId,
                    }}
                  />
                }
                className={styles.tab}
              >
                {tr.translate("part.details.workflows")}
              </Tab>
              <Tab
                id="products"
                panel={
                  <PartProducts
                    partId={partId}
                    part={part}
                    disableEdit={!canUpdateParts}
                  />
                }
                className={styles.tab}
              >
                {tr.translate("part.details.projects")}
              </Tab>
            </Tabs>
          }
        </div>
      </div>
      {deleteAlert && (
        <DeleteAlert
          elementName={part?.name}
          onDelete={deleteAlert.onDelete}
          onClose={() => setDeleteAlert(undefined)}
          message={deleteAlert.content}
        />
      )}
      <PartDialogs
        partId={partId}
        dialog={dialog}
        onClose={() => setDialog(undefined)}
      />
    </PanelDetails>
  );
}
