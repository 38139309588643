import { ProjectDefinitionDocumentSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useAddProjectDefinitionDocument(
  projectId: number,
  definitionIdx: number
) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (spec: ProjectDefinitionDocumentSpec) => {
      const document = await api.document.getDocument(spec.document);
      const lastRevisionId = document.revisions[0].id;
      await api.project.createProjectDefinitionDocument(
        projectId,
        definitionIdx,
        { ...spec, revision: spec.revision ?? lastRevisionId }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          projectKeys.allDocumentsDefinition(projectId, definitionIdx)
        );
      },
    }
  );
}
