import { UserExport } from "@aletiq/types";
import React, { useCallback, useMemo } from "react";
import { CellProps } from "react-table";
import {
  Button,
  DateWithSpaces,
  ExtendedColumn,
  PageContents,
  PageTitle,
  Table,
} from "../../components";
import { useTranslations } from "../../util";
import { useDownloadUserExport, useUserExports } from "./hooks";

export default function AletiqAdminUserExport() {
  const { data: userExports = [], isLoading } = useUserExports();
  const tr = useTranslations();

  const { mutate: downloadExport, isLoading: isDownloading } =
    useDownloadUserExport();

  const handleDownloadUserExport = useCallback(
    (fileId: number) => {
      downloadExport(fileId);
    },
    [downloadExport]
  );

  const columns: ExtendedColumn<UserExport>[] = useMemo(
    () => [
      {
        id: "createdAt",
        accessor: "createdAt",
        Header: "Export Date",
        Cell: (cell) => <DateWithSpaces date={cell.value} />,
      },
      {
        id: "file",
        accessor: "file",
        Header: "Export file name",
        Cell: (cell: CellProps<UserExport>) => <>{cell.value.name}</>,
        hoverButton: (userExport, className) => (
          <Button
            isDense
            intent="array_primary"
            onClick={() => handleDownloadUserExport(userExport.file.id)}
            className={className}
            isLoading={isDownloading}
          >
            {tr.translate("generic.action.download")}
          </Button>
        ),
      },
    ],
    [tr, handleDownloadUserExport, isDownloading]
  );

  return (
    <PageContents domTitle="Aletiq user exports">
      <PageTitle>List of all user exports</PageTitle>
      <Table columns={columns} isFetching={isLoading} data={userExports} />
    </PageContents>
  );
}
