import {
  AlertPopover,
  AnchorButton,
  ButtonGroup,
  Card,
  H3,
  HighlightedText,
  Icon,
  Tooltip,
} from "@aletiq/design-system";
import { ProcessModel } from "@aletiq/types";
import React, { useMemo } from "react";
import { UserInitialsList } from "../../../../components";
import { useTranslations } from "../../../../util";
import { TaskDependencyList, TaskTable } from "../../common";
import { useWorkflowColor } from "../../hooks";
import { findExistingCycle } from "../cycleDetection";
import CyclicModelWarning from "../CyclicModelWarning";
import styles from "./WorkflowModelView.module.scss";

export default function WorkflowModelView(props: {
  model: ProcessModel;
  handleDeleteModel: (model: number) => void;
  editModel: (modelId: number) => void;
  disableEdit: boolean;
  search?: string;
}) {
  const tr = useTranslations();
  const { model, handleDeleteModel, editModel, disableEdit, search } = props;

  const cyclicDependencies = useMemo(
    () =>
      findExistingCycle(
        model.taskModels.map((t) => t.dependencies.map((d) => d - 1))
      ).map((t) => t + 1),
    [model.taskModels]
  );

  const modelColor = useWorkflowColor(model);
  const creatorTranslation = tr.translateAsString(
    "workflow.model.task.owner.creator"
  );

  return (
    <div className={styles.container}>
      <H3 className={styles.model_header}>
        <Icon icon="duplicate" inline />
        <HighlightedText text={model.title} highlight={search} />
        <div>
          <ButtonGroup isDense>
            <Tooltip
              position="top"
              content={tr.translateAsString("workflow.model.action.edit")}
            >
              <AnchorButton
                icon="edit"
                onClick={() => editModel(model.id)}
                isDisabled={disableEdit}
                isDense
              />
            </Tooltip>
            <AlertPopover
              onPrimaryClick={() => handleDeleteModel(model.id)}
              icon="trash"
              disablePrimary={disableEdit}
              title={tr.translateAsString("workflow.model.action.delete")}
              content={tr.translate("workflow.model.action.delete.confirm")}
              isDense
            >
              <Tooltip
                position="top"
                isDisabled={disableEdit}
                content={tr.translateAsString("workflow.model.action.delete")}
              >
                <AnchorButton icon="trash" isDisabled={disableEdit} isDense />
              </Tooltip>
            </AlertPopover>
          </ButtonGroup>
        </div>
      </H3>
      <Card key={model.id} icon="flow-linear" title={model.nomenclature}>
        {cyclicDependencies.length > 0 && (
          <CyclicModelWarning className={styles.warning} />
        )}
        <TaskTable color={model.color}>
          <thead>
            <tr>
              <th>{tr.translate("tasklist.task")}</th>
              <th>{tr.translate("generic.label.description")}</th>
              <th>{tr.translate("tasklist.assignee")}</th>
              <th>{tr.translate("workflow.model.task.dependencies")}</th>
            </tr>
          </thead>
          <tbody>
            {model.taskModels.map((task) => {
              const ownerIds = task.owners.map((user) =>
                user.type === "specific" ? user.user : creatorTranslation
              );

              return (
                <tr key={task.id}>
                  <td>{task.title}</td>
                  <td>{task.description}</td>
                  <td>
                    <div className={styles.owners}>
                      <UserInitialsList users={ownerIds} type="small" />
                    </div>
                  </td>
                  <td className={styles.dependencies}>
                    <TaskDependencyList
                      task={task}
                      workflowColor={modelColor}
                      workflowTasks={model.taskModels}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TaskTable>
      </Card>
    </div>
  );
}
