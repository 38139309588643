import { UIColor, UIView } from "@aletiq/design-system";
import { Part, PartIteration } from "@aletiq/types";
import React from "react";
import {
  DropdownMenu,
  GenericStateTag,
  MenuItem,
} from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { usePartIterations } from "../../../../pdm/hooks";
import styles from "./RevisionSelectMenu.module.scss";

export default function RevisionSelectMenu(props: {
  isFilled?: boolean;
  isDisabled?: boolean;
  isDense?: boolean;
  view?: Exclude<UIView, "flat">;
  intent?: UIColor;
  part: Part;
  hasDescription?: boolean;
  onAssignRevision: (revision: PartIteration) => void;
  assignedRevision?: PartIteration;
}) {
  const tr = useTranslations();

  const {
    part,
    assignedRevision,
    onAssignRevision,
    isFilled,
    isDisabled,
    isDense,
    hasDescription = true,
    view,
    intent,
  } = props;

  const { data: revisions = [] } = usePartIterations(part.id);

  const handleAssignRevision = (revision: PartIteration) => () => {
    onAssignRevision(revision);
  };

  return (
    <DropdownMenu
      view={view}
      intent={intent}
      isFilled={isFilled}
      isDisabled={isDisabled}
      isDense={isDense}
      placeholder={tr.translateAsString(
        "project.dialog.link.part.bom.revision.hint"
      )}
      activeLabel={
        assignedRevision && (
          <GenericStateTag
            name={assignedRevision.name}
            state={assignedRevision.state}
          />
        )
      }
    >
      {revisions.map((revision) => (
        <MenuItem
          key={revision.number}
          text={
            <div className={styles.inner}>
              <GenericStateTag
                name={revision.name}
                state={revision.state}
                description={hasDescription ? revision.description : ""}
              />
            </div>
          }
          onClick={handleAssignRevision(revision)}
        />
      ))}
    </DropdownMenu>
  );
}
