import {
  Button,
  ButtonSP,
  Menu,
  MenuItem,
  Popover2,
  TagInput,
} from "@aletiq/design-system";
import { UserGroup } from "@aletiq/types";
import React, { useState } from "react";
import { useTenant, useUserGroups, useUsers } from "../../../../hooks";
import {
  handleStringInput,
  isInString,
  useTranslations,
} from "../../../../util";
import styles from "./GroupMultiselect.module.scss";

export default function GroupMultiselect(props: {
  groupsToIgnore: number[];
  isDocPublic: boolean;
  onAddGroups: (newGroups: number[]) => void;
  onAddOpenAccess: () => void;
  intent?: "primary" | "default";
  isDense?: boolean;
}) {
  const {
    groupsToIgnore,
    isDocPublic,
    onAddGroups,
    onAddOpenAccess,
    intent = "primary",
    isDense,
  } = props;
  const tr = useTranslations();

  const [filter, setFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [hasOpenAccess, setHasOpenAccess] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<UserGroup[]>([]);

  const { data: tenant } = useTenant();
  const { data: users = [] } = useUsers();
  const { data: groups = [] } = useUserGroups();

  const selectableGroups = groups.filter((g) => !groupsToIgnore.includes(g.id));
  const filteredGroups = selectableGroups.filter((g) =>
    isInString(g.name, filter)
  );

  const tenantName = tenant?.name ?? "";
  const selectedGroupsNames = selectedGroups.map((g) => g.name);

  const clearState = () => {
    setFilter("");
    setSelectedGroups([]);
    setHasOpenAccess(false);
  };

  const handleOpenMenu = () => setIsOpen(true);
  const handleCloseMenu = () => {
    setIsOpen(false);
    clearState();
  };

  const handleClear = () => clearState();

  const handleSelectGroup = (group: UserGroup) => () => {
    const userIsSelected = selectedGroups.some((g) => g.id === group.id);
    userIsSelected
      ? setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id))
      : setSelectedGroups([...selectedGroups, group]);
  };

  const handleRemoveTag = (groupName: string, index: number) => {
    if (index === selectedGroupsNames.length) setHasOpenAccess(false);
    else setSelectedGroups(selectedGroups.filter((g) => g.name !== groupName));
  };

  const handleToggleOpenAccess = () => {
    setHasOpenAccess(!hasOpenAccess);
  };

  const handleSubmit = () => {
    onAddGroups(selectedGroups.map((g) => g.id));
    if (hasOpenAccess) onAddOpenAccess();
    setIsOpen(false);
    clearState();
  };

  const isSelected = (groupId: number) =>
    selectedGroups.some((u) => u.id === groupId);

  return (
    <Popover2
      minimal
      isOpen={isOpen}
      onClose={handleCloseMenu}
      position="bottom-left"
      content={
        <div className={styles.groupSelect}>
          <div>
            <TagInput
              fill
              autoFocus
              intent={intent}
              placeholder={tr.translateAsString("generic.action.search")}
              leftIcon={selectedGroups.length === 0 ? "search" : undefined}
              values={[...selectedGroupsNames, hasOpenAccess && tenantName]}
              onRemove={(tag, index) => handleRemoveTag(tag as string, index)}
              inputProps={{
                value: filter,
                onChange: handleStringInput(setFilter),
                autoFocus: true,
              }}
              rightElement={
                <Button isDense icon="cross" onClick={handleClear} />
              }
            />
            <Button
              icon="tick"
              intent="success"
              onClick={handleSubmit}
              isDense={isDense}
            />
          </div>
          <Menu className={styles.list} isDense={isDense}>
            {!isDocPublic && (
              <MenuItem
                text={tenant?.name}
                shouldDismissPopover={false}
                icon={hasOpenAccess ? "tick" : "blank"}
                active={hasOpenAccess}
                onClick={handleToggleOpenAccess}
                labelElement={tr.translate(
                  "document.dialog.share.groups.usercount",
                  { count: users.length }
                )}
                isDense={isDense}
                intent={intent}
              />
            )}
            {filteredGroups.map((group) => (
              <MenuItem
                key={group.id}
                shouldDismissPopover={false}
                onClick={handleSelectGroup(group)}
                text={group.name}
                icon={isSelected(group.id) ? "tick" : "blank"}
                active={isSelected(group.id)}
                labelElement={
                  <span className={styles.user_count}>
                    {tr.translate("document.dialog.share.groups.usercount", {
                      count: group.users.length,
                    })}
                  </span>
                }
                isDense={isDense}
                intent={intent}
              />
            ))}
          </Menu>
        </div>
      }
    >
      <ButtonSP
        icon="plus"
        view="flat"
        active={isOpen}
        className={styles.addButton}
        onClick={handleOpenMenu}
        isDense={isDense}
        color={intent}
      >
        {tr.translate("document.dialog.share.button.add-groups")}
      </ButtonSP>
    </Popover2>
  );
}
