import React from "react";
import { useUserGroup } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { AlertPopover, Button, Icon } from "../../../../components";
import styles from "./ShareDocumentDialog.module.scss";

export default function GroupRow(props: {
  groupId: number;
  onRemove: (groupId: number) => void;
}) {
  const { groupId, onRemove } = props;
  const tr = useTranslations();

  const { data: group } = useUserGroup(groupId);
  const userCount = group?.users.length;

  const handleRemoveGroup = () => {
    onRemove(groupId);
  };

  return (
    <div className={styles.row}>
      <div className={styles.row__left}>
        <div className={styles.groupIcon}>
          <Icon icon="people" iconSize={18} />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{group?.name}</div>
          <div className={styles.info}>
            {tr.translate("document.dialog.share.groups.usercount", {
              count: userCount,
            })}
          </div>
        </div>
      </div>
      <div>
        <AlertPopover
          isDense
          content={tr.translate("document.dialog.share.popup.remove-group")}
          onPrimaryClick={handleRemoveGroup}
        >
          <Button intent="outlined">
            {tr.translate("document.dialog.share.button.remove")}
          </Button>
        </AlertPopover>
      </div>
    </div>
  );
}
