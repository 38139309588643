import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useSetPassportState() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { passportId: number; state: number | null }) => {
      const { passportId, state } = values;
      await api.passport.updatePassport(passportId, [
        { type: "state", value: state },
      ]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(passportKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}
