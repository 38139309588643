import { AnchorButton, Tooltip } from "@aletiq/design-system";
import { Property } from "@aletiq/types";
import React, { useState } from "react";
import { PropertyCard } from "../../../../../components";
import { useHasPermission } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { useCountPropertyUses } from "../../hooks";
import { ConfirmDeletePropertyDialog } from "../../PassportAttributes/Dialogs";
import { PropertyFilters } from "../../types";
import { EditDocumentPropertyDialog } from "../Dialogs";

type DialogType = "delete-property" | "edit-property";

export default function DocumentPropertyCard(props: {
  property: Property;
  filters?: PropertyFilters;
}) {
  const { property, filters } = props;
  const tr = useTranslations();

  const [dialog, setDialog] = useState<DialogType>();

  const { data: propertyUseCount = 0 } = useCountPropertyUses(
    "document",
    property.name
  );
  const canEditProperty = useHasPermission("update:custom-attributes");
  const canRemoveProperty = useHasPermission("update:custom-attributes");

  const handleOpenDialog = (type: DialogType) => () => {
    setDialog(type);
  };

  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  return (
    <>
      <PropertyCard
        label={property.label}
        type={property.type}
        description={property.description}
        creator={property.creator}
        onEdit={handleOpenDialog("edit-property")}
        canEdit={canEditProperty}
        filters={filters}
        usedIn={[
          {
            count: propertyUseCount,
            icon: "document",
          },
        ]}
        additionalButton={
          <Tooltip
            position="top"
            content={tr.translate("admin.attribute.passports.action.disable")}
          >
            <AnchorButton
              isDense
              icon="disable"
              isDisabled={!canRemoveProperty}
              onClick={handleOpenDialog("delete-property")}
            />
          </Tooltip>
        }
      />
      {dialog === "edit-property" && (
        <EditDocumentPropertyDialog
          property={property}
          onClose={handleCloseDialog}
        />
      )}
      {dialog === "delete-property" && (
        <ConfirmDeletePropertyDialog
          onClose={handleCloseDialog}
          propertyName={property.name}
          objectType="document"
        />
      )}
    </>
  );
}
