import React from "react";
import { AnchorButton, ButtonGroup, Tooltip } from "../../../../components";
import { useTranslations } from "../../../../util";
import { FilteredVersionDoc } from "../../hooks";
import styles from "./VersionDetails.module.scss";

export default function ActionCell(props: {
  document: FilteredVersionDoc;
  onPreview: (documentId: number, revisionId: number) => void;
  onDownload: (documentId: number, revision: number) => void;
}) {
  const { document, onPreview, onDownload } = props;
  const tr = useTranslations();

  const isDocInaccessible = !document.doc.hasAccess || document.doc.isDeleted;

  const handlePreview = () => {
    document.revision && onPreview(document.id, document.revision.id);
  };

  const handleDownload = () => {
    document.revision && onDownload(document.id, document.revision.id);
  };

  return (
    <ButtonGroup isDense className={styles.cta}>
      <Tooltip content={tr.translate("generic.action.preview")}>
        <AnchorButton
          isDense
          icon="eye-open"
          onClick={handlePreview}
          isDisabled={isDocInaccessible}
        />
      </Tooltip>
      <Tooltip content={tr.translate("generic.action.download")}>
        <AnchorButton
          isDense
          icon="download"
          onClick={handleDownload}
          isDisabled={isDocInaccessible}
        />
      </Tooltip>
    </ButtonGroup>
  );
}
