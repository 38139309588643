import { AlertPopover } from "@aletiq/design-system";
import React from "react";
import GenericStateTag, {
  GenericStateTagProps,
  iconFromState,
} from "../tags/GenericStateTag/GenericStateTag";
import ChangeRevisionContent from "./ChangeRevisionStateContent";
import useChangeRevisionTranslations from "./hooks/useChangeRevisionTranslations";
import { ObjectType } from "./types";

export default function ChangeRevisionPopover(props: {
  initial: GenericStateTagProps;
  final: GenericStateTagProps;
  isDisabled?: boolean;
  objectType: ObjectType;
  onConfirm?: () => void;
  isPublished?: boolean;
  children?: React.ReactNode;
}) {
  const {
    onConfirm,
    isDisabled,
    objectType,
    initial,
    final,
    isPublished,
    children,
  } = props;

  const { title, description, callout } = useChangeRevisionTranslations(
    initial.state,
    final.state,
    objectType,
    isPublished
  );

  return (
    <AlertPopover
      title={title}
      icon={iconFromState(final.state)}
      onPrimaryClick={onConfirm}
      disabled={isDisabled}
      isDense
      content={
        <ChangeRevisionContent
          callout={callout}
          description={description}
          initialProps={initial}
          finalProps={final}
        />
      }
    >
      {children ?? <GenericStateTag state={initial.state} hasInteraction />}
    </AlertPopover>
  );
}
