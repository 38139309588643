import { Part, PartQueryOrder } from "@aletiq/types";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import {
  customCreatedAtColumn,
  customDescriptionColumn,
  customUpdatedAtColumn,
  ExtendedColumn,
  ExtendedColumnRecord,
  PartSwIcon,
  User,
} from "../../../components";
import { ColumnOptionConfig } from "../../../hooks";
import { extractBy, TreePath, useTranslations } from "../../../util";
import { FullTree } from "../AllParts/AllPartsType";
import {
  PartLockHeaderCell,
  PartNameCell,
  PartRevisionCell,
} from "../AllParts/common";

export type PartColumn = PartQueryOrder;

export const defaultPartColumnConfig: ColumnOptionConfig<PartColumn> = {
  name: true,
  description: true,
  lastIterationName: true,
  lockOwnerName: true,
  ownerName: false,
  createdAt: false,
  updatedAt: false,
  format: false,
};

export const hasPart = (tree: FullTree) =>
  tree.type === "part" && tree.part !== undefined;

export const hasLockOwner = (tree: FullTree) =>
  tree.type === "part" &&
  tree.part?.lockOwner !== undefined &&
  tree.part?.lockOwner !== null;

export default function usePartTableColumns(
  onToggleExpand: (paths: TreePath[]) => (enabled: boolean) => void,
  onEditBom: (partId: number) => () => void,
  onViewPart: (part: Part) => void,
  config: ColumnOptionConfig<PartColumn> = defaultPartColumnConfig
): ExtendedColumn<FullTree, PartQueryOrder>[] {
  const tr = useTranslations();

  return useMemo(() => {
    const columns: ExtendedColumnRecord<FullTree, PartQueryOrder> = {
      name: {
        Header: tr.translate("part.details.name"),
        id: "name",
        accessor: (row: FullTree) =>
          row.type === "part" ? row.part?.name : row.type,
        fullWidthContent: true,
        Cell: (cellProps: CellProps<FullTree>) => (
          <PartNameCell
            {...cellProps}
            {...cellProps.row.getToggleRowExpandedProps()}
            onToggleExpand={onToggleExpand}
            onEditBom={onEditBom}
          />
        ),
      },
      description: customDescriptionColumn({
        id: "description",
        accessor: (row) => (row.type === "part" ? row.part?.description : ""),
      }),
      lastIterationName: {
        Header: tr.translate("part.details.last-revision"),
        id: "lastIterationName",
        accessor: (row: FullTree) =>
          hasPart(row) ? row.part?.lastIterationName : undefined,
        Cell: (cellProps: CellProps<FullTree>) => (
          <PartRevisionCell {...cellProps} onViewPart={onViewPart} />
        ),
        fullWidthContent: true,
      },
      lockOwnerName: {
        Header: <PartLockHeaderCell />,
        id: "lockOwnerName",
        accessor: (row: FullTree) =>
          hasPart(row) ? row.part?.lockOwnerName : undefined,
        Cell: (props: CellProps<FullTree>) =>
          hasLockOwner(props.row.original) ? (
            <User
              userId={props.row.original.part?.lockOwner ?? 0}
              size="small"
            />
          ) : null,
      },
      ownerName: {
        Header: tr.translate("admin.attribute.documents.owner"),
        id: "ownerName",
        accessor: (row: FullTree) =>
          hasPart(row) ? row.part?.owner : undefined,
        Cell: (props: CellProps<FullTree>) =>
          hasPart(props.row.original) ? (
            <User userId={props.row.original.part?.owner ?? 0} size="small" />
          ) : null,
      },
      createdAt: customCreatedAtColumn({
        id: "createdAt",
        accessor: (row) => (row.type === "part" ? row.part?.createdAt : ""),
      }),
      updatedAt: customUpdatedAtColumn({
        id: "updatedAt",
        accessor: (row) => (row.type === "part" ? row.part?.updatedAt : ""),
      }),
      format: {
        Header: tr.translateAsString("admin.attribute.parts.format"),
        accessor: (row: FullTree) =>
          hasPart(row) ? row.part?.format : undefined,
        id: "format",
        Cell: (cellProps: CellProps<FullTree>) =>
          hasPart(cellProps.row.original) ? (
            <>
              <PartSwIcon
                format={cellProps.row.original.part?.format}
                type={cellProps.row.original.part?.type}
              />
              {cellProps.row.original.part?.format}
            </>
          ) : null,
      },
    };
    return extractBy(columns, config);
  }, [tr, config, onToggleExpand, onEditBom, onViewPart]);
}
