import React from "react";
import { Icon, Tooltip } from "../../../components";
import NameLink from "../../../components/link/NameLink";
import { useTranslations } from "../../../util";
import { useProjectBills } from "../Operations/hooks";
import styles from "./ArticleBillIndexLink.module.scss";

export default function ArticleBillIndexLink(props: {
  hasIcon?: boolean;
  product: number;
  billId: number;
  onClick?: () => void;
}) {
  const { hasIcon, product, billId, onClick } = props;
  const tr = useTranslations();

  const { data: bills = [] } = useProjectBills(product);

  const bill = bills.find((bill) => bill.id === billId);
  const hasObsoleteDocs = bill?.operations
    .flatMap((op) => op.documents)
    .some((doc) => doc.revision.revision?.state === "obsolete");

  const billLink = (
    <NameLink
      hasIcon={hasIcon}
      text={bill?.index || ""}
      location={{
        path: `/project/${props.product}/operation-bills/${bill?.id}`,
      }}
      isDisabled={false}
      icon={<Icon icon="numbered-list" inline intent="primary" />}
      onClick={onClick}
    />
  );

  if (!hasObsoleteDocs) {
    return billLink;
  }

  return (
    <Tooltip
      position="right"
      content={tr.translate("project.operation-bill.bill-state.warning")}
    >
      <div className={styles.bill_link}>
        {billLink}
        <div className={styles.warning} />
      </div>
    </Tooltip>
  );
}
