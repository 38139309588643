import { Part, PartType } from "@aletiq/types";
import React, { useState } from "react";
import { PartSelectItem } from ".";
import { DropdownMenu, MenuDivider, Subtitle } from "../../../../../components";
import TooManyResultsCallout from "../../../../../components/callouts";
import { useTranslations } from "../../../../../util";
import { useParts } from "../../../../pdm/hooks";

export default function PartSelectMenu(props: {
  projectId: number;
  allowedTypes?: PartType[];
  assignedPart?: Part;
  onAssignPart: (part: Part, isLinked: boolean) => void;
}) {
  const { projectId, assignedPart, allowedTypes, onAssignPart } = props;

  const tr = useTranslations();

  const [search, setSearch] = useState("");

  const { data } = useParts({
    search,
    partTypes: allowedTypes,
    order: "name",
    orderDirection: "asc",
  });

  const { data: linkedData } = useParts({
    search,
    partTypes: allowedTypes,
    projects: [projectId],
  });

  const parts = data?.list ?? [];
  const linkedParts = linkedData?.list ?? [];
  const hasTooManyResults = (data?.count ?? 0) > (data?.list.length ?? 0);
  const otherParts = parts.filter((part) => !part.projects.includes(projectId));

  const handleAssignLinkedPart = (part: Part) => {
    onAssignPart(part, true);
  };

  const handleAssignOtherPart = (part: Part) => {
    onAssignPart(part, false);
  };
  return (
    <DropdownMenu
      isFilled
      placeholder={tr.translateAsString("project.dialog.link.part.bom.hint")}
      activeLabel={assignedPart?.name}
      hasFilter={true}
      search={search}
      onSearchChange={setSearch}
    >
      {hasTooManyResults && <TooManyResultsCallout />}

      {linkedParts.length > 0 && (
        <>
          <Subtitle
            text={tr.translateAsString(
              "project.dialog.link.part.bom.linked-part"
            )}
          />
          {linkedParts.map((part) => (
            <PartSelectItem
              part={part}
              onSelect={handleAssignLinkedPart}
              search={search}
            />
          ))}
          <MenuDivider />
        </>
      )}
      <Subtitle
        text={tr.translateAsString(
          "project.dialog.link.part.bom.additional-part"
        )}
      />
      {otherParts.map((part) => (
        <PartSelectItem
          part={part}
          onSelect={handleAssignOtherPart}
          search={search}
        />
      ))}
    </DropdownMenu>
  );
}
