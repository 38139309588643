import { PartIteration } from "@aletiq/types";
import React, { useState } from "react";
import { Dialog, Field, FileInput } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useMutationToaster, usePart, useUnlockPart } from "../../hooks";
import styles from "./UnlockPartDialog.module.scss";

export default function UnlockPartDialog(props: {
  partId: number;
  onClose: () => void;
}) {
  const { onClose, partId } = props;
  const tr = useTranslations();

  const { data: part } = usePart(partId);

  const { mutate: checkinPart, status: checkinStatus } = useUnlockPart();
  useMutationToaster(checkinStatus, {
    loading: tr.translateAsString("part.dialog.toaster.loading"),
    error: tr.translateAsString("part.dialog.toaster.error"),
    success: tr.translateAsString("part.dialog.toaster.failed"),
  });

  const [file, setFile] = useState<File | null>(null);

  const handleFileInput = (ev: React.FormEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files;

    setFile(files?.[0] ?? null);
  };

  const handleConfirm = () => {
    if (!part) return;

    if (file) {
      const spec = getNextIterationSpec(part.lastIteration);
      return checkinPart(
        { partId, revision: { spec, file } },
        { onSuccess: onClose }
      );
    }

    return checkinPart({ partId, revision: null }, { onSuccess: onClose });
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      onPrimaryClick={handleConfirm}
      onSecondaryClick={onClose}
      icon="unlock"
      title={tr.translateAsString("part.dialog.unlock.title")}
    >
      <p>{tr.translate("part.dialog.unlock.subtitle")}</p>
      <p>{tr.translate("part.dialog.unlock.upload.info")}</p>
      <Field
        className={styles.field}
        label={tr.translate("part.dialog.unlock.upload.label")}
      >
        <FileInput
          files={file ? [file] : undefined}
          onInputChange={handleFileInput}
          disabled={false}
        />
      </Field>
    </Dialog>
  );
}

function getNextIterationSpec(iteration: PartIteration) {
  // New iteration without name or description
  if (iteration.state !== "draft") {
    return { name: "", description: "" };
  }

  // Keep current iteration name and description
  return {
    name: iteration.name ?? "",
    description: iteration.description ?? "",
  };
}
