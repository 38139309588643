import { ToolingRequirement } from "@aletiq/types";
import React from "react";
import { Dialog } from "../../../components";
import { useTranslations } from "../../../util";
import { useDeleteToolingRequirements } from "../hooks";
import { ResolvedToolingRequirement } from "../ProjectTooling/ProjectTooling";

export default function DeleteToolingRequirementsDialog(props: {
  toolingRequirements: ToolingRequirement[] | ResolvedToolingRequirement[];
  onClose: () => void;
}) {
  const { toolingRequirements, onClose } = props;
  const tr = useTranslations();

  const { mutate: deleteTools, isLoading } = useDeleteToolingRequirements();

  const handleSubmit = () => {
    deleteTools(toolingRequirements, { onSettled: onClose });
  };

  return (
    <Dialog
      isOpen
      icon="trash"
      intent="danger"
      title={tr.translateAsString("project.dialog.tools.remove.title", {
        count: toolingRequirements.length,
      })}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
      onClose={onClose}
    >
      {tr.translate("project.dialog.tools.remove.description")}
    </Dialog>
  );
}
