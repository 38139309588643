import { UIColor, UIView } from "@aletiq/design-system";
import { Document, DocumentRevision } from "@aletiq/types";
import React from "react";
import {
  DropdownMenu,
  GenericStateTag,
  MenuItem,
} from "../../../../components";
import { useTranslations } from "../../../../util";

export default function ProjectDefinitionDocumentRevisionSelect(props: {
  isFilled?: boolean;
  isDisabled?: boolean;
  isDense?: boolean;
  view?: Exclude<UIView, "flat">;
  intent?: UIColor;
  document: Document;
  assignedRevision: DocumentRevision;
  onAssignRevision: (revision: DocumentRevision) => void;
}) {
  const {
    isFilled,
    isDisabled,
    isDense,
    view,
    intent,
    document,
    assignedRevision,
    onAssignRevision,
  } = props;

  const tr = useTranslations();

  const handleAssignRevision = (revision: DocumentRevision) => () => {
    onAssignRevision(revision);
  };

  const handleDropdownClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <DropdownMenu
      onClick={handleDropdownClick}
      view={view}
      intent={intent}
      isFilled={isFilled}
      isDisabled={isDisabled}
      isDense={isDense}
      placeholder={tr.translateAsString(
        "project.dialog.link.part.bom.revision.hint"
      )}
      activeLabel={
        assignedRevision && (
          <GenericStateTag
            name={assignedRevision.revisionName}
            state={assignedRevision.state}
          />
        )
      }
    >
      {document.revisions.map((revision) => (
        <MenuItem
          key={revision.id}
          text={
            <GenericStateTag
              name={revision.revisionName}
              state={revision.state}
              description={revision.description}
            />
          }
          onClick={handleAssignRevision(revision)}
        />
      ))}
    </DropdownMenu>
  );
}
