import { AnchorButton, CheckBox, Tooltip } from "@aletiq/design-system";
import { FileMeta } from "@aletiq/types";
import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import useApi from "../../../../app/useApi";
import { ActionBar, AlignRight, DeleteAlert } from "../../../../components";
import {
  useDownloadQueue,
  useFilter,
  useHasPermission,
} from "../../../../hooks";
import { sortByString, useTranslations } from "../../../../util";
import { UploadPassportFileDialog } from "../../dialogs";
import {
  useDeletePassportFile,
  usePassportFiles,
  usePassportFilesFilter,
  usePreviewPassportFile,
} from "../../hooks";
import PassportFileRow from "./PassportFileRow";
import styles from "./PassportFiles.module.scss";
import PassportFilesFilter from "./PassportFilesFilter";
import { Filter } from "./types";

export default function PassportFiles(props: { passportId: number }) {
  const { passportId } = props;
  const tr = useTranslations();
  const api = useApi();

  const [fileToRemove, setFileToRemove] = useState<FileMeta | null>(null);
  const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [isBuildingArchive, setIsBuldingArchive] = useState(false);
  const { filter, handleFilter, handleClear } = useFilter<Filter>({
    search: "",
  });

  const { data: files = [], isLoading: isLoadingFiles } =
    usePassportFiles(passportId);
  const { mutate: removeFile } = useDeletePassportFile(passportId);
  const canAttachPassportFiles = useHasPermission("attach:passport-files");

  const { handleDownload } = useDownloadQueue();

  //download
  const handleGetFileDownloadToken = (fileId: number) => () =>
    api.passport.getPassportFileDownloadToken(passportId, fileId);

  const handleDownloadFile = (fileId: number) =>
    handleDownload({
      fetchToken: handleGetFileDownloadToken(fileId),
      forceDownload: true,
    });

  const handleDownloadArchive = async () => {
    //check manually if download is in progress (cant use mutation)
    setIsBuldingArchive(true);
    await handleDownload({
      fetchToken: () =>
        api.passport.getFilePassportArchiveLink(passportId, selectedFiles),
      forceDownload: true,
    });
    setIsBuldingArchive(false);
  };

  //preview
  const showPreview = usePreviewPassportFile(passportId);

  const handleOpenPreview = (file: FileMeta) => {
    showPreview(file);
  };

  //selection
  const handleSelectFile = (fileId: number) => {
    selectedFiles.includes(fileId)
      ? setSelectedFiles((files) => files.filter((f) => f !== fileId))
      : setSelectedFiles([...selectedFiles, fileId]);
  };

  const handleToggleSelectAllFiles = () => {
    areAllFilesSelected
      ? setSelectedFiles([])
      : setSelectedFiles(filteredFiles.map((f) => f.file.id));
  };

  //file upload
  const handleShowUploadFileDialog = () => {
    setShowUploadFileDialog(!showUploadFileDialog);
  };

  //file removal
  const handleOpenRemoveFileDialog = (file: FileMeta) => {
    setFileToRemove(file);
  };

  const handleCloseRemoveFileDialog = () => {
    setFileToRemove(null);
  };

  const handleRemoveFile = () => {
    fileToRemove &&
      removeFile(fileToRemove.id, {
        onSettled: () => setFileToRemove(null),
      });
  };

  useEffect(() => setSelectedFiles([]), [filter]);

  const filteredFiles = usePassportFilesFilter(files, filter);
  const sortedFiles = sortByString(filteredFiles, (file) => file.file.name);

  const areAllFilesSelected =
    selectedFiles.length > 0 && filteredFiles.length === selectedFiles.length;
  const areSomeFilesSelected = !areAllFilesSelected && selectedFiles.length > 0;

  return (
    <div>
      <ActionBar>
        <CheckBox
          checked={areAllFilesSelected}
          indeterminate={areSomeFilesSelected}
          onChange={handleToggleSelectAllFiles}
          className={styles.actionbar_checkbox}
          isDense
          intent="primary"
        />
        <PassportFilesFilter
          filter={filter}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
        <AlignRight />
        <Tooltip
          position="top-right"
          content={tr.translateAsString("passport.details.upload.files")}
        >
          <AnchorButton
            icon="upload"
            intent="secondary"
            isDisabled={!canAttachPassportFiles}
            onClick={handleShowUploadFileDialog}
            isDense
          />
        </Tooltip>
        <Tooltip
          position="top-right"
          content={tr.translateAsString("passport.details.download.files")}
        >
          <AnchorButton
            icon="download"
            intent="secondary"
            className={styles.rightButtton}
            onClick={handleDownloadArchive}
            isDisabled={selectedFiles.length === 0}
            isLoading={isBuildingArchive}
            isDense
          />
        </Tooltip>
      </ActionBar>
      {filteredFiles.length > 0 && (
        <div className={styles.list}>
          {sortedFiles.map((passportFile) => {
            const isSelected = selectedFiles.includes(passportFile.file.id);
            return (
              <PassportFileRow
                key={passportFile.file.id}
                passportId={passportId}
                passportFile={passportFile}
                onPreviewFile={handleOpenPreview}
                onDownloadFile={handleDownloadFile}
                onRemoveFile={handleOpenRemoveFileDialog}
                onToggleSelect={handleSelectFile}
                isSelected={isSelected}
                search={filter.search}
              />
            );
          })}
        </div>
      )}

      {isLoadingFiles && <Spinner className={styles.empty_state} />}
      {filteredFiles.length === 0 && !isLoadingFiles && (
        <div className={styles.empty_state}>
          {tr.translate("passport.details.file.empty-state")}
        </div>
      )}

      {showUploadFileDialog && (
        <UploadPassportFileDialog
          onClose={handleShowUploadFileDialog}
          passportsIds={[passportId]}
        />
      )}
      {fileToRemove && (
        <DeleteAlert
          elementName={fileToRemove.name}
          onDelete={handleRemoveFile}
          onClose={handleCloseRemoveFileDialog}
        />
      )}
    </div>
  );
}
