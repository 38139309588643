import { Button } from "@aletiq/design-system";
import { useTranslations } from "@aletiq/translation";
import React from "react";
import styles from "../EditableProperty.module.scss";

type Intent = "primary" | "default";

export default function EditablePropertyModifyButton(props: {
  onClick: () => void;
  intent: Intent;
}) {
  const { intent, onClick } = props;

  const tr = useTranslations();

  return (
    <div className={styles.hover_button}>
      <Button
        isDense
        intent={intent === "primary" ? "array_primary" : "array_default"}
        onClick={onClick}
      >
        {tr.translate("generic.action.modify")}
      </Button>
    </div>
  );
}
