import { PartFormat, PartType } from "@aletiq/types";
import { Classes } from "@blueprintjs/core";
import React from "react";
import CatiaAssemblyIcon from "./CatiaAssemblyIcon";
import CatiaPartIcon from "./CatiaPartIcon";
import SwAssemblyIcon from "./SwAssemblyIcon";
import SwDrawingIcon from "./SwDrawingIcon";
import SwPartIcon from "./SwPartIcon";

export default function PartSwIcon(props: {
  type: PartType | undefined | null;
  format?: PartFormat | undefined | null;
}) {
  const { format, type } = props;
  if (format === "catia") {
    switch (type) {
      case "part":
        return <CatiaPartIcon className={Classes.TREE_NODE_ICON} />;
      default:
        return <CatiaAssemblyIcon className={Classes.TREE_NODE_ICON} />;
    }
  }

  switch (type) {
    case "part":
      return <SwPartIcon className={Classes.TREE_NODE_ICON} />;
    case "assembly":
      return <SwAssemblyIcon className={Classes.TREE_NODE_ICON} />;
    case "drawing":
      return <SwDrawingIcon className={Classes.TREE_NODE_ICON} />;
    default:
      return <SwPartIcon className={Classes.TREE_NODE_ICON} />;
  }
}
