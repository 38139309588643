import { Button, ButtonSP, MenuItem } from "@aletiq/design-system";
import { TaskModelOwner, User } from "@aletiq/types";
import classNames from "classnames";
import React, { useMemo } from "react";
import { UserInitial, UserInitialsList } from "../../../../components";
import UserSelect from "../../../../components/user-list/EditableUserList/UserSelect";
import { isNotUndefined, useTranslations } from "../../../../util";
import styles from "./TaskModelOwnerSelect.module.scss";

export default function TaskModelOwnerSelect(props: {
  selected: TaskModelOwner[];
  onEditSelection: (users: TaskModelOwner[]) => void;
  allowedTaskOwners: User[];
}) {
  const { onEditSelection, selected, allowedTaskOwners } = props;

  const tr = useTranslations();

  const isCreatorSelected = selected.some((u) => u.type === "creator");

  const handleClearOwners = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    onEditSelection([]);
  };

  const handleSelectSpecificUser = (selection: number[]) => {
    const specificUsers: TaskModelOwner[] = selection.map((user) => ({
      type: "specific",
      user,
    }));

    if (isCreatorSelected) {
      onEditSelection([{ type: "creator" }, ...specificUsers]);
      return;
    }
    onEditSelection(specificUsers);
  };

  const handleToggleCreatorSelection = () => {
    if (isCreatorSelected) {
      onEditSelection(selected.filter((user) => user.type !== "creator"));
      return;
    }
    onEditSelection([{ type: "creator" }, ...selected]);
  };

  const specificSelectedUsers = useMemo(
    () =>
      selected
        .map((user) => (user.type === "specific" ? user.user : undefined))
        .filter(isNotUndefined),
    [selected]
  );

  const creatorTranslation = tr.translateAsString(
    "workflow.model.task.owner.creator"
  );

  return (
    <div className={styles.owners}>
      <UserSelect
        users={specificSelectedUsers}
        onEditSelection={handleSelectSpecificUser}
        allowedUsers={allowedTaskOwners}
        additionalOptions={
          <MenuItem
            text={
              <>
                <UserInitial
                  size="small"
                  userName={creatorTranslation}
                  inline
                />
                {creatorTranslation}
              </>
            }
            icon={isCreatorSelected ? "tick" : "blank"}
            active={isCreatorSelected}
            onClick={handleToggleCreatorSelection}
            shouldDismissPopover={false}
            isDense
          />
        }
        isDense
      >
        <ButtonSP
          view="outlined"
          color="default"
          isDense
          isFill
          className={classNames(
            styles.button,
            selected.length === 0 && styles.no_users
          )}
        >
          <div className={styles.pseudo_input}>
            {selected.length > 0 ? (
              <UserInitialsList
                users={selected.map((user) =>
                  user.type === "specific" ? user.user : creatorTranslation
                )}
                fill
              />
            ) : (
              tr.translate("generic.label.none")
            )}
            <Button
              isDense
              isDisabled={selected.length === 0}
              icon="cross"
              onClick={handleClearOwners}
            />
          </div>
        </ButtonSP>
      </UserSelect>
    </div>
  );
}
