import classNames from "classnames";
import React, { Fragment } from "react";
import { useQuery } from "react-query";
import useApi from "../../../../../app/useApi";
import {
  ButtonSP,
  CreateTag,
  EditableText,
  GenericStatusTag,
  IgnoreTag,
  Input,
  PartIcon,
  UpdateTag,
  ValidateTag,
} from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { usePartIterations } from "../../../hooks";
import { pdmQueries } from "../../../hooks/queries";
import { PartInfo, PartState } from "../types";
import PartActionSelect from "./PartActionSelect";
import styles from "./UploadedPartsList.module.scss";

export default function ValidParts(props: {
  parts: PartState[];
  onUpdatePartState: (partState: PartState) => void;
  isDisabled: boolean;
  canApproveRevision: boolean;
}) {
  const { canApproveRevision, parts, onUpdatePartState, isDisabled } = props;

  return (
    <Fragment>
      {parts.map((partState, index) => (
        <ValidPartRow
          key={index}
          isDisabled={isDisabled}
          partId={partState.partId}
          partSpec={partState.partInfo}
          canApproveRevision={canApproveRevision}
          onUpdate={(partInfo) => onUpdatePartState({ ...partState, partInfo })}
        />
      ))}
    </Fragment>
  );
}

function ValidPartRow(props: {
  partSpec: PartInfo;
  partId: number | null;
  onUpdate: (action: PartInfo) => void;
  isDisabled: boolean;
  canApproveRevision: boolean;
}) {
  const { canApproveRevision, isDisabled, partSpec, partId, onUpdate } = props;
  const { name, action, revision } = partSpec;

  const api = useApi();
  const tr = useTranslations();

  const { data: iterations = [] } = usePartIterations(partId!);
  const lastIterationName = iterations.length > 0 ? iterations[0].name : "";

  const existingPartResult = useQuery(pdmQueries.byName(api, name));

  const isPartNameUsed =
    !isDisabled &&
    partSpec.action.type === "create" &&
    name &&
    existingPartResult.data &&
    existingPartResult.data.list[0] !== undefined;

  // for updated documents, is the revision name already used in previous approved revisions
  const isRevisionNameUsed =
    action.type === "update" &&
    !isDisabled &&
    revision.name !== "" &&
    iterations.some((i) => i.state !== "draft" && i.name === revision.name);

  return (
    <tr className={classNames(isRevisionNameUsed && styles.warning_row)}>
      <td className={styles.tag_col}>
        {!partId && partSpec.action.type !== "ignore" && (
          <GenericStatusTag status="new" />
        )}
      </td>
      <td className={classNames(isPartNameUsed && styles.part_name_used)}>
        <div
          className={classNames(
            styles.part_name,
            partSpec.action.type === "ignore" && styles.ignored
          )}
        >
          <PartIcon type={partSpec.type} inline />
          {partSpec.action.type !== "create" ? (
            partSpec.name
          ) : (
            <EditableText
              isFill
              value={name}
              intent={isPartNameUsed ? "danger" : undefined}
              onChange={(name) => onUpdate({ ...partSpec, name })}
              placeholder={tr.translateAsString(
                "part.dialog.drop-parts.part.name.placeholder"
              )}
            />
          )}
        </div>
        {isPartNameUsed && (
          <p>{tr.translate("part.dialog.drop-parts.part.name.in-use")}</p>
        )}
      </td>
      <td className={styles.action_col}>
        <PartActionSelect
          canUpdateDraft={!!partId}
          canApproveRevision={canApproveRevision}
          isDisabled={isDisabled}
          onChangePartAction={(action) => onUpdate({ ...partSpec, action })}
          selectedAction={action}
        >
          <ButtonSP
            isDense
            isFill
            view="flat"
            color="primary"
            rightIcon="caret-down"
          >
            {action.type === "create" && <CreateTag />}
            {action.type === "update" && <UpdateTag />}
            {action.type === "ignore" && <IgnoreTag />}
            {action.type !== "ignore" && action.approve && (
              <>
                {" & "}
                <ValidateTag />
              </>
            )}
          </ButtonSP>
        </PartActionSelect>
      </td>
      <td className={styles.revision_col}>
        <Input
          fill
          isDense
          value={partSpec.revision.name}
          onChange={(name) =>
            onUpdate({
              ...partSpec,
              revision: {
                ...partSpec.revision,
                name,
              },
            })
          }
          disabled={isDisabled || action.type === "ignore"}
          hasError={isRevisionNameUsed}
          errorHelperText={tr.translateAsString(
            "part.dialog.drop-parts.revision.name.in-use"
          )}
          placeholder={tr.translateAsString(
            "part.dialog.drop-parts.revision.name.placeholder",
            {
              action: action.type === "update" ? "update" : "create",
              name: lastIterationName,
            }
          )}
        />
      </td>
      <td className={styles.description_col}>
        <Input
          fill
          isDense
          value={partSpec.revision.description}
          onChange={(description) =>
            onUpdate({
              ...partSpec,
              revision: {
                ...partSpec.revision,
                description,
              },
            })
          }
          disabled={isDisabled || action.type === "ignore"}
          placeholder={tr.translateAsString(
            "part.dialog.drop-parts.revision.desc.placeholder"
          )}
        />
      </td>
    </tr>
  );
}
