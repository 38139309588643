import { FileAction, PropertyValueUpdate } from "@aletiq/types";
import React from "react";
import { useHasVisibleScrollbar } from "../../../../hooks";
import { useProperties } from "../../../administration/Attributes/hooks";
import FileActionTable from "../../common/FileActionTable";
import DocumentTableHeader from "./DocumentTableHeader";
import styles from "./DocumentUploadWizard.module.scss";
import FileRow from "./FileRow";
import { DocumentState, projectStatus, Status } from "./state";

export function UploadedDocumentsList(props: {
  state: DocumentState[];
  onUpdateDocument: (state: DocumentState) => void;
  creationsStatus: Status;
}) {
  const { state, onUpdateDocument, creationsStatus } = props;

  const { isIdle } = projectStatus(creationsStatus);

  const { data: properties = [] } = useProperties("document");

  const visibleProperties = properties.filter((p) => p.displayByDefault);

  const {
    verticalScrollbarSize,
    containerRef: tableBodyRef,
    isScrollbarVisible,
  } = useHasVisibleScrollbar();

  const handleUpdateManyIndexes = (index: string) => {
    state.forEach((doc) => {
      onUpdateDocument({
        ...doc,
        action: {
          ...doc.action,
          revision: { ...doc.action.revision, name: index },
        },
      });
    });
  };

  const handleUpdateManyProperties = (property: PropertyValueUpdate) => {
    state.forEach((doc) => {
      // attributes cannot be updated for existing documents, only newly created ones
      const canBeUpdated = doc.action.action === "create";
      const propertyIsSet = doc.action.properties.some(
        (p) => p.property === property.property
      );

      const properties = propertyIsSet
        ? doc.action.properties.map((p) =>
            p.property === property.property
              ? { ...p, value: property.value }
              : p
          )
        : [...doc.action.properties, property];

      if (canBeUpdated)
        onUpdateDocument({
          ...doc,
          action: {
            ...doc.action,
            properties,
          },
        });
    });
  };

  const handleUpdateManyActions = (
    newAction: FileAction,
    revisionState?: "draft" | "approved"
  ) => {
    state.forEach((doc) => {
      const canUpdateAction =
        newAction === "ignore" ||
        // new files can only be created or ignored (no doc to update)
        (newAction === "update" && doc.documentId) ||
        // existing documents can only be updated or ignored
        (newAction === "create" && !doc.documentId);

      if (canUpdateAction)
        onUpdateDocument({
          ...doc,
          action: {
            ...doc.action,
            action: newAction,
            revision: {
              ...doc.action.revision,
              state: revisionState || doc.action.revision.state,
            },
          },
        });
    });
  };

  return (
    <>
      <FileActionTable classname={styles.table_header}>
        <DocumentTableHeader
          submitting={!isIdle}
          visibleProperties={visibleProperties}
          onBatchUpdateIndex={handleUpdateManyIndexes}
          onBatchUpdateProperty={handleUpdateManyProperties}
          onBatchUpdateAction={handleUpdateManyActions}
          scrollbarSize={verticalScrollbarSize}
          isScrollbarVisible={isScrollbarVisible}
        />
      </FileActionTable>
      <div className={styles.table_wrapper} ref={tableBodyRef}>
        <FileActionTable classname={styles.table}>
          <tbody>
            {state.map((value, index) => (
              <FileRow
                key={value?.documentId ?? index}
                submitted={!isIdle}
                onUpdate={(action) => onUpdateDocument({ ...value, action })}
                spec={value.action}
                file={value.file}
                documentId={value.documentId}
                visibleProperties={visibleProperties}
              />
            ))}
          </tbody>
        </FileActionTable>
      </div>
    </>
  );
}
