import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { documentKeys } from "./queries";

export default function useUpdateDocumentProjects(docId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (projects: number[]) => {
      await api.document.updateDocumentProjects(docId, projects);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.document.link.projects"),
        });
      },
    }
  );
}
