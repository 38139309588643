import { EditorState } from "draft-js";
import MarkdownParser from "./MardownParser";
import { ParserOption } from "./types";

const mardownToDraftJs = (
  value: string,
  options?: ParserOption
): EditorState => {
  const parser = new MarkdownParser(options);
  return parser.parse(value);
};
export default mardownToDraftJs;
