import { PartType } from "@aletiq/types";
import cadex from "@cadexchanger/web-toolkit";
import React from "react";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  ExplodeIcon,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  Slider,
  Tooltip,
  ZoomToFitIcon,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./PartViewerMenu.module.scss";
import { ViewSettings } from "./types";

export default function PartViewerMenu(props: {
  settings: ViewSettings;
  onUpdateSettings: (newSettings: ViewSettings) => void;
  onToggleBom: () => void;
  onZoomToFit: () => void;
  partType: PartType;
}) {
  const { settings, onUpdateSettings, onZoomToFit, onToggleBom, partType } =
    props;
  const tr = useTranslations();

  const handleSetDisplayMode =
    (displayMode: cadex.ModelPrs_DisplayMode) => () => {
      onUpdateSettings({ ...settings, displayMode });
    };

  const handleSetProjection =
    (projection: cadex.ModelPrs_CameraProjectionType) => () => {
      onUpdateSettings({ ...settings, projection });
    };

  const handleSetExplodeFactor = (explodeFactor: number) => {
    onUpdateSettings({ ...settings, explodeFactor });
  };

  return (
    <ButtonGroup intent="secondary" className={styles.control_buttons}>
      <Tooltip
        content={tr.translate("part.dialog.view.tooltip.display-bom")}
        position="top"
      >
        <AnchorButton
          icon="diagram-tree"
          intent="secondary"
          onClick={onToggleBom}
          isDisabled={partType === "part"}
        />
      </Tooltip>
      {/* Fit 3D objects to viewer */}
      <Tooltip
        content={tr.translate("part.dialog.view.tooltip.center")}
        position="top"
      >
        <Button
          icon={<ZoomToFitIcon size={20} />}
          intent="secondary"
          onClick={onZoomToFit}
        />
      </Tooltip>

      {/* explode view */}
      <Popover2
        intent="primary"
        position="bottom-left"
        disabled={partType === "part"}
        content={
          <div className={styles.slider_wrapper}>
            <Slider
              value={settings.explodeFactor}
              onChange={handleSetExplodeFactor}
              min={0}
              max={100}
              stepSize={5}
              labelStepSize={25}
              labelRenderer={(val) => `${val}%`}
              className={styles.slider}
            />
          </div>
        }
      >
        <Tooltip
          content={tr.translate("part.dialog.view.tooltip.explode")}
          position="top"
        >
          <AnchorButton
            icon={<ExplodeIcon size={20} />}
            intent="secondary"
            isDisabled={partType === "part"}
          />
        </Tooltip>
      </Popover2>

      {/* Edit view settings */}
      <Popover2
        position="bottom-left"
        intent="primary"
        content={
          <Menu>
            <MenuItem
              text={tr.translate("part.dialog.view.display.settings.wireframe")}
              onClick={handleSetDisplayMode(
                cadex.ModelPrs_DisplayMode.Wireframe
              )}
              active={
                settings.displayMode === cadex.ModelPrs_DisplayMode.Wireframe
              }
              intent="primary"
            />
            <MenuItem
              text={tr.translate("part.dialog.view.display.settings.shaded")}
              onClick={handleSetDisplayMode(cadex.ModelPrs_DisplayMode.Shaded)}
              active={
                settings.displayMode === cadex.ModelPrs_DisplayMode.Shaded
              }
              intent="primary"
            />
            <MenuItem
              text={tr.translate(
                "part.dialog.view.display.settings.shaded-boundaries"
              )}
              onClick={handleSetDisplayMode(
                cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
              )}
              active={
                settings.displayMode ===
                cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
              }
              intent="primary"
            />
            <MenuDivider />
            <MenuItem
              text={tr.translate("part.dialog.view.display.settings.isometric")}
              onClick={handleSetProjection(
                cadex.ModelPrs_CameraProjectionType.Isometric
              )}
              active={
                settings.projection ===
                cadex.ModelPrs_CameraProjectionType.Isometric
              }
              intent="primary"
            />
            <MenuItem
              text={tr.translate(
                "part.dialog.view.display.settings.perspective"
              )}
              onClick={handleSetProjection(
                cadex.ModelPrs_CameraProjectionType.Perspective
              )}
              active={
                settings.projection ===
                cadex.ModelPrs_CameraProjectionType.Perspective
              }
              intent="primary"
            />
          </Menu>
        }
      >
        <Tooltip
          content={tr.translate("part.dialog.view.tooltip.display-settings")}
          position="top"
        >
          <Button icon="eye-open" intent="secondary" />
        </Tooltip>
      </Popover2>
    </ButtonGroup>
  );
}
