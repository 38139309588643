import {
  Button,
  ButtonGroup,
  ButtonSP,
  Menu,
  MenuItem,
  Popover2,
  TagInput,
} from "@aletiq/design-system";
import { User, UserRole } from "@aletiq/types";
import React, { useState } from "react";
import { useUsers } from "../../hooks";
import { handleStringInput, useTranslations } from "../../util";
import { default as UserName } from "../User/User";
import styles from "./UserMultiselect.module.scss";
export const USER_ROLE_TRAD: Record<UserRole, string> = {
  manager: "admin.users.page.type.manager",
  administrator: "admin.users.page.type.admin",
  viewer: "admin.users.page.type.viewer",
  shared: "admin.users.page.type.computer",
};

export default function UserMultiselect(props: {
  isSubmitting?: boolean;
  onSubmit: (newUsers: number[]) => void;
  usersToIgnore: number[];
  intent?: "default" | "primary";
  isDense?: boolean;
}) {
  const {
    isSubmitting,
    onSubmit,
    usersToIgnore,
    intent = "primary",
    isDense,
  } = props;
  const tr = useTranslations();

  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const { data: users = [] } = useUsers();
  const selectableUsers = users.filter((u) => !usersToIgnore.includes(u.id));
  const filteredUsers = selectableUsers.filter((u) =>
    u.displayName.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  );

  const selectedUsersNames = selectedUsers.map((u) => u.displayName);

  const isSelected = (userId: number) =>
    selectedUsers.some((u) => u.id === userId);

  const clearState = () => {
    setSelectedUsers([]);
    setFilter("");
  };

  const handleSelectUser = (user: User) => () => {
    const userIsSelected = selectedUsers.some((u) => u.id === user.id);
    userIsSelected
      ? setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))
      : setSelectedUsers([...selectedUsers, user]);
  };

  const handleRemoveUser = (userName: string) => {
    setSelectedUsers(selectedUsers.filter((u) => u.displayName !== userName));
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
    clearState();
  };

  const handleOpenMenu = () => setIsOpen(true);

  const handleSubmit = () => {
    onSubmit(selectedUsers.map((u) => u.id));
    setIsOpen(false);
    clearState();
  };

  const handleClear = () => clearState();

  const handleCancel = () => {
    clearState();
    setIsOpen(false);
  };

  return (
    <Popover2
      minimal
      isOpen={isOpen}
      onClose={handleCloseMenu}
      position="bottom-left"
      content={
        <div className={styles.userSelect}>
          <div>
            <TagInput
              fill
              autoFocus
              intent={intent}
              placeholder={tr.translateAsString("admin.groups.filter-users")}
              leftIcon={selectedUsers.length === 0 ? "search" : undefined}
              values={selectedUsersNames}
              onRemove={(tag) => handleRemoveUser(tag as string)}
              inputProps={{
                value: filter,
                onChange: handleStringInput(setFilter),
                autoFocus: true,
              }}
              rightElement={
                <Button isDense icon="cross" onClick={handleClear} />
              }
            />
            <ButtonGroup isDense={isDense}>
              <Button
                icon="tick"
                isDense={isDense}
                intent="success"
                onClick={handleSubmit}
                isLoading={isSubmitting}
              />
              <Button icon="cross" isDense={isDense} onClick={handleCancel} />
            </ButtonGroup>
          </div>
          <Menu isDense={isDense} className={styles.list}>
            {filteredUsers.map((user) => (
              <MenuItem
                shouldDismissPopover={false}
                key={user.id}
                onClick={handleSelectUser(user)}
                text={
                  <UserName userId={user.id} size="small" intent={intent} />
                }
                active={isSelected(user.id)}
                icon={isSelected(user.id) ? "tick" : "blank"}
                labelElement={
                  <span className={styles.user_count}>
                    {tr.translate(USER_ROLE_TRAD[user.role])}
                  </span>
                }
                isDense={isDense}
                intent={intent}
              />
            ))}
          </Menu>
        </div>
      }
    >
      <ButtonSP
        icon="plus"
        view="flat"
        color={intent}
        active={isOpen}
        className={styles.button}
        onClick={handleOpenMenu}
        isDense={isDense}
      >
        {tr.translate("admin.groups.button.add-users")}
      </ButtonSP>
    </Popover2>
  );
}
