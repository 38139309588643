import { Operation, Project, ProjectQueryOrder } from "@aletiq/types";
import React, { useCallback, useMemo, useState } from "react";
import { CellProps } from "react-table";
import {
  ActionBar,
  Dialog,
  ExtendedColumn,
  HighlightedText,
  Icon,
  SearchInput,
  Table,
} from "../../../../components";
import { useSort } from "../../../../hooks";
import { isInString, useTranslations } from "../../../../util";
import { useProjectsById, useProjectToolingRequirements } from "../../hooks";
import { useAddOperationTools } from "../hooks";
import styles from "./AddToolsDialog.module.scss";

export default function AddToolsDialog(props: {
  projectId: number;
  bill: number;
  operation: Operation;
  onClose: () => void;
}) {
  const { projectId, bill, operation, onClose } = props;
  const tr = useTranslations();

  const [search, setSearch] = useState("");

  const columns = useColumns();

  const { data: projectToolRequirements = [] } =
    useProjectToolingRequirements(projectId);

  const operationTools = operation.tools.map((t) => t.tool);

  //ids of tools not yet contained in the operation
  const availableToolsIds = projectToolRequirements
    .map((t) => t.tool)
    .filter((t) => !operationTools.includes(t));
  const tools = useProjectsById(availableToolsIds);

  const [selectedTools, setSelectedTools] = useState<number[]>([]);

  const { isLoading, mutate: addTools } = useAddOperationTools(
    projectId,
    bill,
    operation.number
  );

  const {
    sortKey: key,
    sortDirection: direction,
    onSortKeyChange: onSort,
    sortedList: sortedTools,
  } = useSort<Project, ProjectQueryOrder>(tools, "name");

  const filteredTools = useMemo(
    () =>
      sortedTools.filter(
        (t) =>
          !t.isDeleted &&
          (isInString(t.name, search) || isInString(t.description, search))
      ),
    [sortedTools, search]
  );

  const handleAddTools = async () => {
    addTools(selectedTools, { onSuccess: onClose });
  };

  const handleSelect = useCallback((documents: Project[]) => {
    setSelectedTools(documents.map((d) => d.id));
  }, []);

  return (
    <Dialog
      icon="plus"
      title={tr.translateAsString("project.operation-bill.add-tools.title")}
      isOpen={true}
      className={styles.form}
      onClose={onClose}
      submitting={isLoading}
      onPrimaryClick={handleAddTools}
    >
      <div className={styles.caption}>
        {tr.translate("project.operation-bill.add-tools.caption")}
      </div>
      <ActionBar>
        <SearchInput isDense autoFocus value={search} onChange={setSearch} />
      </ActionBar>
      <Table
        className={styles.table}
        columns={columns}
        data={filteredTools}
        searchString={search}
        onSelect={handleSelect}
        sortOptions={{
          sortState: { key, direction },
          onSort,
        }}
      />
    </Dialog>
  );
}

export function useColumns() {
  const tr = useTranslations();
  const columns: ExtendedColumn<Project, ProjectQueryOrder>[] = useMemo(
    () => [
      {
        Header: tr.translate("generic.label.name"),
        id: "name",
        accessor: "name",
        Cell: (cellProps: CellProps<Project>) => (
          <>
            <Icon icon="wrench" inline />
            <HighlightedText
              text={cellProps.value}
              highlight={cellProps.searchString}
            />
          </>
        ),
      },
      {
        Header: tr.translate("generic.label.description"),
        id: "description",
        accessor: "description",
      },
    ],
    [tr]
  );

  return columns;
}
