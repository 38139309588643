import { CheckBox } from "@aletiq/design-system";
import React from "react";
import { TableToggleRowsSelectedProps } from "react-table";
import styles from "./Table.module.scss";

export default function RowCheckbox(props: TableToggleRowsSelectedProps) {
  const { checked, onChange, indeterminate } = props;

  return (
    <CheckBox
      isDense
      onChange={onChange as any}
      checked={checked}
      indeterminate={indeterminate}
      intent="default"
      className={styles.checkbox}
    />
  );
}
