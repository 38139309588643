import {
  DocumentQueryFilterParams,
  DocumentQueryOrder,
  EntityId,
} from "@aletiq/types";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  ActionBar,
  DefaultFilters,
  Dialog,
  Table,
} from "../../../../components";
import {
  useDelayed,
  useFilter,
  usePagination,
  useSortKey,
} from "../../../../hooks";
import { ResolvedDocument } from "../../../documents/Documents/documentTypes";
import {
  useAddProductsDocuments,
  useDefaultDocumentColumnConfig,
  useDocuments,
  useDocumentTableColumns,
  useResolvedDocuments,
} from "../../../documents/hooks";
import styles from "./LinkProductsToDocumentsDialog.module.scss";

export default function LinkProductsToDocumentsDialog(props: {
  entities: EntityId[];
  onClose: () => void;
  title: string;
  subtitle?: ReactNode;
}) {
  const { entities, onClose, title, subtitle } = props;

  const [selectedDocs, setSelectedDocs] = useState<number[]>([]);
  const { sortState, setSortKey: onSort } =
    useSortKey<DocumentQueryOrder>("name");
  const { filter, handleFilter } = useFilter<DocumentQueryFilterParams>({
    search: "",
  });

  const pagination = usePagination();

  const { resetSelectedPage } = pagination;

  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, resetSelectedPage]);

  const {
    data: allDocuments,
    isLoading: documentsLoading,
    isFetching: documentsFetching,
  } = useDocuments({
    ...filter,
    order: sortState.key,
    orderDirection: sortState.direction,
    limit: pagination.limit,
    offset: pagination.selectedPage * pagination.limit,
  });

  const { documents, isLoading: documentsResolving } = useResolvedDocuments(
    allDocuments?.list
  );

  const { mutate: linkDocumentsToProducts, isLoading: isLinkingDocuments } =
    useAddProductsDocuments(entities);

  const isLoading = documentsLoading || documentsResolving;
  const isFetching = useDelayed(documentsFetching);

  const handleSelect = useCallback(
    (docs: ResolvedDocument[]) => setSelectedDocs(docs.map((d) => d.id)),
    []
  );

  const handleSubmit = async () => {
    linkDocumentsToProducts(selectedDocs, { onSuccess: onClose });
  };

  const { config } = useDefaultDocumentColumnConfig();
  const columns = useDocumentTableColumns(config);

  return (
    <Dialog
      isOpen
      onClose={onClose}
      icon="new-link"
      title={title}
      onPrimaryClick={handleSubmit}
      submitting={isLinkingDocuments}
      className={styles.dialog}
      enforceFocus={false}
    >
      <div className={styles.content}>
        <p className={styles.subtitle}>{subtitle}</p>
        <ActionBar>
          <DefaultFilters
            objectType="document"
            filters={filter}
            handleFilter={handleFilter}
            autoFocusSearch
          />
        </ActionBar>
        <Table
          columns={columns}
          paginationOptions={{
            itemCount: allDocuments?.count ?? 0,
            limit: pagination.limit,
            selectedPage: pagination.selectedPage,
            onSetLimit: pagination.handleLimit,
            onSetPage: pagination.handleSelectPage,
          }}
          data={documents}
          searchString={filter.search}
          onSelect={handleSelect}
          sortOptions={{
            sortState,
            onSort,
          }}
          isFetching={isFetching || isLoading}
        />
      </div>
    </Dialog>
  );
}
