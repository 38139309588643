import React from "react";
import { useTranslations } from "../../../../util";
import { AlertPopover, Button, User } from "../../../../components";
import styles from "./ShareDocumentDialog.module.scss";

export default function UserRow(props: {
  userId: number;
  onRemove: (userId: number) => void;
}) {
  const { userId, onRemove } = props;
  const tr = useTranslations();

  const handleRemoveUser = () => {
    onRemove(userId);
  };

  return (
    <div className={styles.row}>
      <div className={styles.row__left}>
        <User size="default" userId={userId} />
      </div>
      <div>
        <AlertPopover
          isDense
          content={tr.translate("document.dialog.share.popup.remove-user")}
          onPrimaryClick={handleRemoveUser}
        >
          <Button intent="outlined">
            {tr.translate("document.dialog.share.button.remove")}
          </Button>
        </AlertPopover>
      </div>
    </div>
  );
}
