import {
  AnchorButton,
  ButtonGroup,
  Card,
  Field,
  HighlightedText,
  Icon,
  Tooltip,
} from "@aletiq/design-system";
import { PropertyType } from "@aletiq/types";
import { IconName } from "@blueprintjs/core";
import React, { ReactElement } from "react";
import { PropertyFilters } from "../../features/administration/Attributes/types";
import { isInString, useTranslations } from "../../util";
import { SingleSelectionIcon } from "../icons";
import { UserName } from "../name";
import styles from "./PropertyCard.module.scss";

export default function PropertyCard(props: {
  // property info
  label: string;
  type: PropertyType;
  description: string;
  creator?: number;
  target?: JSX.Element;
  onEdit?: () => void;
  canEdit?: boolean;
  usedIn?: { count: number; icon: IconName }[];
  // display prop
  additionalButton?: ReactElement;
  customIcon?: IconName | JSX.Element;
  filters?: PropertyFilters;
}) {
  const {
    label,
    type,
    creator,
    target,
    description,
    additionalButton,
    onEdit,
    canEdit,
    customIcon,
    filters,
    usedIn,
  } = props;

  const tr = useTranslations();

  const propertyTypeIcon: IconName | JSX.Element =
    customIcon || getPropertyTypeIcon(type);

  const hasFilteredLabel = () => isInString(label, filters?.search);

  const hasFilteredCreator = () =>
    (filters?.createdByAletiq && !creator) ||
    (creator && filters?.creator && filters.creator.includes(creator));

  const hasFilteredType = () =>
    filters?.propertyType && filters.propertyType.includes(type);

  if (filters?.search && !hasFilteredLabel()) {
    return null;
  }

  if ((filters?.creator || filters?.createdByAletiq) && !hasFilteredCreator()) {
    return null;
  }

  if (filters?.propertyType && !hasFilteredType()) {
    return null;
  }

  return (
    <Card
      title={<HighlightedText text={label} highlight={filters?.search} />}
      icon={propertyTypeIcon}
      headerActions={
        <>
          {usedIn && (
            <div className={styles.used_in}>
              {tr.translate("admin.attribute.used-in")}
              {usedIn.map((use, id) => (
                <>
                  <Icon icon={use.icon} inline key={id} />
                  {use.count}
                </>
              ))}
            </div>
          )}
          <ButtonGroup isDense>
            {additionalButton}
            <Tooltip
              position="top"
              content={tr.translate(
                !canEdit
                  ? "admin.attribute.aletiq.property.tooltip"
                  : "generic.action.modify"
              )}
            >
              <AnchorButton
                isDense
                icon="edit"
                onClick={onEdit}
                isDisabled={!canEdit}
              />
            </Tooltip>
          </ButtonGroup>
        </>
      }
    >
      <Field
        inline
        minWidth={150}
        label={tr.translate("generic.label.description")}
        className={styles.attribute_label}
      >
        <HighlightedText text={description} highlight={filters?.search} />
      </Field>
      <Field
        inline
        minWidth={150}
        label={tr.translate("admin.attribute.creator")}
        className={styles.attribute_label}
      >
        {creator ? <UserName userId={creator} /> : "Aletiq"}
      </Field>
      {target && (
        <Field
          inline
          minWidth={150}
          label={tr.translate("admin.attribute.target")}
          className={styles.target_field}
        >
          {target}
        </Field>
      )}
    </Card>
  );
}

export function getPropertyTypeIcon(type: PropertyType) {
  switch (type) {
    case "multi-enum":
      return "properties";
    case "enum":
      return <SingleSelectionIcon />;
    case "string":
      return "align-left";
    case "number":
      return "numerical";
    case "user":
      return "user";
    case "date":
      return "calendar";
  }
}
