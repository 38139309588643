import { Callout } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../util";

export default function TooManyResultsCallout() {
  const tr = useTranslations();
  return (
    <Callout
      isDense
      icon="info-sign"
      title={tr.translateAsString("filter.search.too-many-results")}
    >
      {tr.translateAsString("filter.search.too-many-results.hint")}
    </Callout>
  );
}
