import { ProjectDefinition } from "@aletiq/types";
import React from "react";
import {
  DropdownMenu,
  GenericStateTag,
  MenuItem,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./ProjectDefinitionSelectMenu.module.scss";

export default function ProjectDefinitionMenu(props: {
  isDense?: boolean;
  disabled?: boolean;
  onNewDefinition?: () => void;
  activeDefinition: ProjectDefinition;
  definitions: ProjectDefinition[];
  hasCreateDefinitionButton?: boolean;
  onSelect: (definition: number) => void;
}) {
  const {
    isDense = true,
    disabled = false,
    onNewDefinition,
    activeDefinition,
    hasCreateDefinitionButton,
    definitions,
    onSelect,
  } = props;
  const tr = useTranslations();

  const handleSelect = (definition: number) => () => onSelect(definition);

  return (
    <DropdownMenu
      isDense={isDense}
      isDisabled={disabled}
      placeholder={tr.translateAsString("generic.label.none")}
      activeLabel={
        <GenericStateTag
          name={activeDefinition.name}
          state={activeDefinition.state}
        />
      }
    >
      {hasCreateDefinitionButton && onNewDefinition ? (
        <MenuItem
          isDense={isDense}
          intent="primary"
          icon="plus"
          text={tr.translateAsString("project.operation-bill.new-draft")}
          onClick={onNewDefinition}
        />
      ) : null}

      {definitions.map((definition) => {
        return (
          <MenuItem
            isDense={isDense}
            key={definition.index}
            className={styles.definition_menu_item}
            text={
              <GenericStateTag
                name={definition.name}
                state={definition.state}
              />
            }
            onClick={handleSelect(definition.index)}
          />
        );
      })}
    </DropdownMenu>
  );
}
