import React from "react";
import { HighlightedText, Icon, Tooltip } from "../../../../components";
import { useTranslations } from "../../../../util";
import { FilteredVersionDoc } from "../../hooks";
import styles from "./VersionDetails.module.scss";

export default function VersionDocNameCell(props: {
  document: FilteredVersionDoc;
  search?: string;
}) {
  const { document, search } = props;
  const tr = useTranslations();

  const isDocInaccessible = !document.doc.hasAccess || document.doc.isDeleted;

  const tooltipText = tr.translate(
    !document.doc.hasAccess
      ? "version.details.doc.private"
      : "version.details.doc.deleted"
  );

  const cellContent = (
    <>
      <Icon inline icon="document" />
      <HighlightedText text={document.name} highlight={search} />
    </>
  );

  if (isDocInaccessible) {
    return (
      <Tooltip position="top-left" content={tooltipText}>
        <div className={styles.no_access}>{cellContent}</div>
      </Tooltip>
    );
  }

  return cellContent;
}
