import Chart from "chart.js/auto";
import classnames from "classnames";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useTranslations } from "../../../../../util";
import styles from "./ModelAnalysisTooltip.module.scss";

export function RepartitionTooltip(props: {
  data: Chart.Legend[];
  defaultStyle: CSSProperties;
}) {
  const { data, defaultStyle } = props;

  // Create title
  const labels =
    data?.map((d: Chart.Legend) => d?.dataset?.label || d?.label)?.join(", ") ??
    "";

  const title = `${labels}: ${data?.[0].raw}`;

  return (
    <ModelAnalysisTooltip
      title={title}
      defaultStyle={defaultStyle}
      position="default"
    />
  );
}

export function mkResolutionTooltip(abscissaLabels: string[]) {
  return function (props: {
    data: Chart.Legend[];
    defaultStyle: CSSProperties;
  }) {
    const { data, defaultStyle } = props;
    const tr = useTranslations();

    // Create title
    const labels =
      data
        ?.map((d: Chart.Legend) => d?.dataset?.label || d?.label)
        ?.join(", ") ?? "";

    const duration = data?.[0].raw;
    const days = Math.floor(duration);
    const dayRem = duration - days;
    const hours = Math.round(dayRem * 24);

    const title = tr.translateAsString("analysis.workflow.datapoint.tooltip", {
      labels,
      days,
      hours,
    });

    // Get Date
    const indexFrom = data?.[0]?.parsed?.x ?? 0;
    const subtitle = makeSubtitle(abscissaLabels, indexFrom);

    return (
      <ModelAnalysisTooltip
        title={title}
        subtitle={subtitle}
        defaultStyle={defaultStyle}
        position="top"
      />
    );
  };
}

export function mkVolumeTooltip(abscissaLabels: string[]) {
  return function (props: {
    data: Chart.Legend[];
    defaultStyle: CSSProperties;
  }) {
    const { data, defaultStyle } = props;

    // Create title
    const labels =
      data
        ?.map((d: Chart.Legend) => d?.dataset?.label || d?.label)
        ?.join(", ") ?? "";

    let title = `${labels}: ${data?.[0].raw}`;

    title = title.replace(/\([^)]*\)/i, "");

    const indexFrom = data?.[0]?.parsed?.x ?? 0;

    const subtitle = makeSubtitle(abscissaLabels, indexFrom);

    return (
      <ModelAnalysisTooltip
        title={title}
        subtitle={subtitle}
        defaultStyle={defaultStyle}
        position="top"
      />
    );
  };
}

function makeSubtitle(abscissaLabels: string[], indexFrom: number) {
  const from = abscissaLabels[indexFrom];
  let to = abscissaLabels[indexFrom + 1];
  if (to === undefined) {
    const today = new Date();
    to = today.toLocaleString(navigator.language, {
      day: "numeric",
      month: "short",
    });
  }

  const subtitle = `${from} - ${to}`;
  return subtitle;
}

export default function ModelAnalysisTooltip(props: {
  title: string;
  subtitle?: string;
  defaultStyle: CSSProperties;
  position: "top" | "default";
}) {
  const { title, subtitle, defaultStyle, position } = props;
  const [offsetTop, setOffsetTop] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const tooltipClassName = classnames(styles.grah_tooltip_container, {
    [styles.top]: position === "top",
  });

  useEffect(() => {
    position === "top" &&
      setOffsetTop(ref?.current?.getBoundingClientRect()?.height ?? 0);
  }, [ref, position]);

  const top =
    typeof defaultStyle?.top === "string"
      ? parseInt(defaultStyle.top)
      : defaultStyle?.top ?? 0;

  const style = {
    ...defaultStyle,
    top: position === "top" ? top - offsetTop - 20 : top,
  };

  return (
    <div className={tooltipClassName} ref={ref} style={style}>
      <div>
        <div className={styles.title}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  );
}
