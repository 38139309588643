import React from "react";
import { EditablePropertySingleSelect } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import useClients from "../../../clients/hooks/useClients";
import { usePassport, useSetPassportClient } from "../../hooks";

export default function ClientField(props: { passportId: number }) {
  const { passportId } = props;
  const tr = useTranslations();

  const { data: passport } = usePassport(passportId);
  const { data: clients = [] } = useClients();

  const canUpdatePassports = useHasPermission("update:passports");

  const { mutate: setPassportClient } = useSetPassportClient();

  const handleSubmitClient = (client: number) => {
    if (client === -1) {
      setPassportClient({ passportId, client: null });
    } else {
      setPassportClient({ passportId, client });
    }
  };

  const itemStates = [
    {
      key: -1,
      text: tr.translateAsString("generic.label.none"),
    },
    ...clients.map((client) => ({
      key: client.id,
      text: client.name,
    })),
  ];

  return (
    <EditablePropertySingleSelect
      value={passport?.client ?? -1}
      items={itemStates}
      isEditable={canUpdatePassports}
      label={tr.translateAsString("product.passports.client")}
      placeholder={tr.translateAsString("generic.action.modify")}
      emptyState={tr.translateAsString("generic.label.undefined")}
      onSubmit={handleSubmitClient}
      inline
    />
  );
}
