import { ButtonSP } from "@aletiq/design-system";
import { EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import React from "react";
import { useTranslations } from "../../util";
import CommentEditor from "./CommentEditor";
import styles from "./CommentEditor.module.scss";
import draftJsToMarkdown from "./DraftJSToMarkdown";
import mentionsFromDraftJs from "./mentionsFromDraftJs";

export default function NewComment(props: {
  onSubmit: (comment: string, mentions: string[]) => void;
}) {
  const tr = useTranslations();
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  function post() {
    const contentState = editorState.getCurrentContent();

    props.onSubmit(
      draftJsToMarkdown(contentState),
      mentionsFromDraftJs(contentState)
    );
    setEditorState(EditorState.createEmpty());
  }

  return (
    <div className={styles.new}>
      <CommentEditor
        editorState={editorState}
        setEditorState={setEditorState}
      />
      <ButtonSP
        isDense
        icon="tick"
        view="outlined"
        color="default"
        onClick={post}
        isDisabled={!editorState.getCurrentContent().hasText()}
      >
        {tr.translate("generic.action.post")}
      </ButtonSP>
    </div>
  );
}
