import { Tag } from "@aletiq/design-system";
import { TaskStatus } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../util";
import styles from "./InlineStatusTag.module.scss";
import { statusToIntent, statusToText } from "./util";

export default function InlineStatusTag(props: { status: TaskStatus }) {
  const { status } = props;
  const tr = useTranslations();

  return (
    <Tag
      intent={statusToIntent(status)}
      isDense
      isRound={false}
      unclickable
      className={styles.tag}
    >
      {statusToText(tr, status)}
    </Tag>
  );
}
