import { Entity } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import DocumentLink from "../link/DocumentLink";
import PartLink from "../link/PartLink";
import PassportLink from "../link/PassportLink";
import ProductLink from "../link/ProductLink";
import WorkflowLink from "../link/WorkflowLink";
import styles from "./EntityLink.module.scss";

export default function EntityLink(props: {
  entity: Entity;
  search?: string;
  inline?: boolean;
}) {
  const { entity, search = "", inline = false } = props;

  if (entity === undefined) {
    return <></>;
  }

  const className = classNames({
    [styles.inline]: inline,
  });

  return (
    <div className={className}>
      {entity.type === "passport" && (
        <PassportLink
          passport={entity.entity}
          hasIcon={!inline}
          search={search}
          className={className}
        />
      )}
      {entity.type === "project" && (
        <ProductLink
          product={entity.entity}
          hasIcon={!inline}
          search={search}
          className={className}
        />
      )}
      {entity.type === "file" && (
        <DocumentLink
          document={entity.entity}
          hasIcon={!inline}
          search={search}
          className={className}
        />
      )}
      {entity.type === "process" && (
        <WorkflowLink
          workflow={entity.entity}
          hasIcon={!inline}
          search={search}
          className={className}
        />
      )}
      {entity.type === "part" && (
        <PartLink
          part={entity.entity}
          hasIcon={!inline}
          search={search}
          className={className}
        />
      )}
    </div>
  );
}
