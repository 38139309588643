import { Part, PartQueryFilterParams, PartQueryOrder } from "@aletiq/types";
import React, { useCallback, useMemo, useState } from "react";
import { CellProps } from "react-table";
import {
  ActionBar,
  Dialog,
  ExtendedColumn,
  HighlightedText,
  PartIcon,
  PartTypesFilter,
  SearchInput,
  Table,
} from "../../../../components";
import {
  useDelayed,
  useFilter,
  usePagination,
  useSortKey,
} from "../../../../hooks";
import { useTranslations } from "../../../../util";
import {
  usePaginatedParts,
  useParts,
  useUpdatePartsProjects,
} from "../../hooks";
import { PartColumn } from "../../hooks/usePartTableColumns";
import styles from "./LinkPartDialog.module.scss";

export default function LinkPartDialog(props: {
  projectId: number;
  onClose: () => void;
}) {
  const { projectId, onClose } = props;
  const tr = useTranslations();

  const [selectedParts, setSelectedParts] = useState<number[]>([]);
  const { filter, handleFilter, handleClear } =
    useFilter<PartQueryFilterParams>({
      search: "",
    });
  const pagination = usePagination();
  const { limit, selectedPage } = pagination;

  const { sortState, setSortKey: onSort } = useSortKey<PartQueryOrder>("name");

  const {
    data: allPartsData,
    isLoading: partsLoading,
    isFetching: partsFetching,
  } = usePaginatedParts({
    ...filter,
    offset: selectedPage * limit,
    limit,
    order: sortState.key,
    orderDirection: sortState.direction,
  });

  const {
    data: projectPartsData,
    isLoading: projectPartsLoading,
    isFetching: projectPartsFetching,
  } = useParts({
    projects: [projectId],
  });

  const isLoading = partsLoading || projectPartsLoading;
  const isFetching = useDelayed(partsFetching || projectPartsFetching);
  const allParts = useMemo(
    () => allPartsData?.list ?? [],
    [allPartsData?.list]
  );

  const projectParts = projectPartsData?.list;

  const availableParts = useMemo(() => {
    const projectPartIds = projectParts?.map((p) => p.id) ?? [];
    return allParts.filter((p) => !projectPartIds.includes(p.id));
  }, [allParts, projectParts]);

  const { mutate: updatePartsProjects, isLoading: submitting } =
    useUpdatePartsProjects();

  const handleSelect = useCallback(
    (parts: Part[]) => setSelectedParts(parts.map((p) => p.id)),
    []
  );

  const handleSubmit = () => {
    const updates = [];
    for (const partId of selectedParts) {
      const part = allParts.find((part) => part.id === partId);
      if (part) {
        updates.push({
          partId: partId,
          projects: [...part.projects, projectId],
        });
      }
    }

    updatePartsProjects(updates, { onSettled: () => onClose() });
  };

  const columns: ExtendedColumn<Part, PartColumn>[] = useMemo(
    () => [
      {
        accessor: "name",
        Header: tr.translate("generic.label.name"),
        Cell: (cellProps: CellProps<Part>) => (
          <>
            <PartIcon
              isStandard={cellProps.row.original.isStandard}
              type={cellProps.row.original.type}
              inline
            />
            <HighlightedText
              text={cellProps.row.original.name}
              highlight={cellProps.searchString}
            />
          </>
        ),
      },
      {
        Header: tr.translate("part.details.description"),
        id: "description",
        accessor: (row: Part) =>
          row.type === "part" ? row?.description : undefined,
      },
    ],
    [tr]
  );

  return (
    <Dialog
      isOpen
      onClose={onClose}
      icon="new-link"
      title={tr.translateAsString("project.dialog.link.part.title")}
      onPrimaryClick={handleSubmit}
      submitting={submitting}
      className={styles.dialog}
    >
      <p>{tr.translate("project.dialog.link.part.subtitle")}</p>

      <ActionBar>
        <SearchInput
          isDense
          autoFocus
          placeholder={tr.translateAsString("generic.action.search")}
          value={filter.search ?? ""}
          onChange={handleFilter("search")}
        />
        <PartTypesFilter
          selected={filter.partTypes ?? []}
          onSelect={handleFilter("partTypes")}
          onClear={handleClear(["partTypes"])}
        />
      </ActionBar>

      <Table
        data={availableParts}
        columns={columns}
        className={styles.table}
        searchString={filter.search}
        paginationOptions={{
          itemCount: availableParts?.length ?? 0,
          limit,
          selectedPage,
          onSetLimit: pagination.handleLimit,
          onSetPage: pagination.handleSelectPage,
        }}
        onSelect={handleSelect}
        sortOptions={{
          sortState,
          onSort,
        }}
        isFetching={isFetching || isLoading}
      />
    </Dialog>
  );
}
