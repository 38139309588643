import { Part, PartComponent } from "@aletiq/types";
import { useMemo } from "react";
import { useQueries, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { ExpandTree, findExpandedTrees } from "../../../util/tree/expansion";
import { isComponentsComplete } from "../services";
import { FullTree } from "./AllPartsType";

// We redefine QueryState because the type is not exported by react-query
type QueryState<T> = { data?: T };

export function makeTree(
  allComponents: Record<
    number,
    Record<number, QueryState<PartComponent[]> | undefined>
  >,
  part: Part,
  tree: ExpandTree
): FullTree {
  const { id, revision, subRows } = tree;
  const componentState = allComponents[id][revision];
  const componentParts = componentState?.data;
  const makeTreeNode = (subRows: FullTree[]) => makeNode(part, subRows);

  // When the component is not expanded
  if (!componentState) {
    return makeTreeNode([]);
  }

  // When the component has not been fetched yet
  if (!componentParts) {
    return makeTreeNode([{ type: "not-fetched", part: undefined }]);
  }

  // When the component has been fetched but the BOM is under analysis
  if (!part.lastIteration?.fileComponentLoaded) {
    return makeTreeNode([{ type: "not-loaded", partId: id, part: undefined }]);
  }

  // When some components are missing
  if (!isComponentsComplete(componentParts)) {
    return makeTreeNode([{ type: "incomplete", partId: id, part: undefined }]);
  }

  const fullChildren = componentParts
    .map((c) => {
      const componentTree = subRows.find(
        (child) =>
          child.id === c.part?.id && child.revision === c.iteration?.number
      );
      if (!componentTree || c.part === null) {
        return makeLeaf(c);
      }
      return makeTree(allComponents, c.part, componentTree);
    })
    .filter((t) => (t.type === "part" && !t.isIgnored) || t.type !== "part");

  // When the component is an assembly but has not children
  if (fullChildren.length === 0) {
    return makeTreeNode([{ type: "empty", partId: id, part: undefined }]);
  }
  return makeTreeNode(fullChildren);
}

export function makePartLeaf(part: Part): FullTree {
  return {
    type: "part",
    name: part.lastIteration.file?.name ?? "",
    expanded: false,
    part,
    isIgnored: false,
    iteration: part.lastIteration,
    subRows: [],
  };
}

function makeNode(part: Part, subRows: FullTree[]): FullTree {
  return {
    type: "part",
    name: part.lastIteration.file?.name ?? "",
    expanded: true,
    iteration: part.lastIteration,
    part,
    isIgnored: false,
    subRows,
  };
}

function makeLeaf(component: PartComponent): FullTree {
  return {
    type: "part",
    name: component.part?.lastIteration.file?.name ?? "",
    expanded: false,
    part: component.part,
    isIgnored: component.isIgnored,
    iteration: component.iteration,
    subRows: [],
  };
}

export default function usePartTree(
  expandTrees: ExpandTree[],
  parts: Part[]
): FullTree[] {
  const api = useApi();
  const queryClient = useQueryClient();

  const componentsToFetch = useMemo(
    () => findExpandedTrees(parts, expandTrees),
    [parts, expandTrees]
  );

  // Fetch the components associated to each part
  const queriesResult = useQueries(
    componentsToFetch.map(({ id, revision }) => ({
      queryKey: ["parts", id, "iterations", revision, "components"],
      queryFn: () => api.pdm.listAssemblyIterationComponents(id, revision),
    }))
  );

  // Memoized tree construction for performance
  return useMemo(
    () => {
      const componentQueriesState: Record<
        number,
        Record<number, QueryState<PartComponent[]> | undefined>
      > = {};

      // Retrieve the state of each query above
      componentsToFetch.forEach(({ id, revision }) => {
        componentQueriesState[id] = {
          [revision]: queryClient.getQueryState(
            ["parts", id, "iterations", revision, "components"],
            {
              exact: true,
            }
          ),
        };
      });

      // Return the visible parts and their associated tree if there is any
      return parts.map((part) => {
        const expandTree = expandTrees.find((t) => t.id === part.id);
        if (!expandTree) {
          return makePartLeaf(part);
        }
        return makeTree(componentQueriesState, part, expandTree);
      });
    },
    // Ensuring that the memo is updated when the query result changes
    // This is because queryClient is a mutable object
    // eslint-disable-next-line
    [expandTrees, parts, componentsToFetch, queryClient, queriesResult]
  );
}
