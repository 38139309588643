import React, { createContext, ReactNode, useContext, useState } from "react";
import { ImageFileOverlay } from "../preview";

export const ShowImgFileContext = createContext<ShowImgFile>(() => {});

type ShowImgFile = (token: string) => void;

export function useShowImage() {
  return useContext(ShowImgFileContext);
}

export function ShowImgFileProvider(props: { children?: ReactNode }) {
  const { children } = props;
  const [content, setContent] = useState<string | null>(null);

  return (
    <ShowImgFileContext.Provider value={setContent}>
      {children}
      {content !== null ? (
        <ImageFileOverlay content={content} onClose={() => setContent(null)} />
      ) : null}
    </ShowImgFileContext.Provider>
  );
}
