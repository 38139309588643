import React, { useState } from "react";
import { ActionBar, PropertyCard } from "../../../../components";
import { HandleClear, HandleFilter, useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import usePassportFileTypes from "../../../passport/hooks/usePassportFileTypes";
import AttributeCards from "../../AttributeCards";
import { PropertyCardFilters } from "../common";
import { PropertyFilters } from "../types";
import EditPassportFileTypeDialog from "./EditPassportFileTypeDialog";

export default function PassportFileTypeCard(props: {
  filters: PropertyFilters;
  handleFilter: HandleFilter<PropertyFilters>;
  handleClear: HandleClear<PropertyFilters>;
}) {
  const { filters, handleClear, handleFilter } = props;
  const tr = useTranslations();

  const [isEditing, setIsEditing] = useState(false);

  const { data: passportFileTypes = [] } = usePassportFileTypes();
  const canUpdateFileTypes = useHasPermission("update:passport-attributes");
  const canAddFileTypes = useHasPermission("create:passport-attributes");

  const handleToggleEdition = () => {
    setIsEditing(!isEditing);
  };

  const label = tr.translateAsString("admin.attribute.passports.files.type");
  const description = tr.translateAsString(
    "admin.attribute.passports.files.type.description"
  );
  const options = passportFileTypes.map((fileType) => ({
    label: fileType.value,
    value: fileType.id,
    deleted: fileType.deleted,
    uses: fileType.uses,
  }));

  return (
    <AttributeCards>
      <ActionBar>
        <PropertyCardFilters
          filters={filters}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
      </ActionBar>
      <PropertyCard
        label={label}
        type="enum"
        filters={filters}
        description={description}
        onEdit={handleToggleEdition}
        canEdit={canUpdateFileTypes && canAddFileTypes}
      />
      {/* Dialogs */}
      {isEditing && (
        <EditPassportFileTypeDialog
          label={label}
          description={description}
          options={options}
          onClose={handleToggleEdition}
        />
      )}
    </AttributeCards>
  );
}
