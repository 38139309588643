import { Tag } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../util";

export function UpdateTag() {
  const tr = useTranslations();

  return (
    <Tag isDense unclickable icon="refresh" isRound={false}>
      {tr.translate("storage.dialog.upload-file.action.update")}
    </Tag>
  );
}

export function CreateTag() {
  const tr = useTranslations();

  return (
    <Tag isDense unclickable intent="primary" icon="plus" isRound={false}>
      {tr.translate("storage.dialog.upload-file.action.create")}
    </Tag>
  );
}

export function ValidateTag() {
  const tr = useTranslations();

  return (
    <Tag isDense unclickable intent="success" icon="tick" isRound={false}>
      {tr.translate("storage.dialog.upload-file.action.approve")}
    </Tag>
  );
}

export function IgnoreTag() {
  const tr = useTranslations();

  return (
    <Tag isDense unclickable intent="danger" icon="cross" isRound={false}>
      {tr.translate("storage.dialog.upload-file.action.ignore")}
    </Tag>
  );
}

export function LinkTag() {
  const tr = useTranslations();

  return (
    <Tag isDense unclickable intent="primary" icon="new-link" isRound={false}>
      {tr.translate("part.dialog.create-link-projects.action.link")}
    </Tag>
  );
}
