import { AnchorButton, Tooltip } from "@aletiq/design-system";
import { IconName } from "@blueprintjs/core";
import React from "react";

export type ButtonIntent = "primary" | "success" | "danger";

export default function WorkflowHeaderButton(props: {
  intent?: ButtonIntent;
  icon: IconName;
  onClick?: () => void;
  tooltipText: string | JSX.Element;
  isDisabled?: boolean;
  isLoading?: boolean;
}) {
  const { isDisabled, intent, icon, isLoading, tooltipText, onClick } = props;

  return (
    <Tooltip content={tooltipText} position="top">
      <AnchorButton
        isDense
        intent={intent}
        onClick={onClick}
        isLoading={isLoading}
        isDisabled={isDisabled}
        icon={icon}
      />
    </Tooltip>
  );
}
