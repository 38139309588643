import { MenuSP, SearchInput } from "@aletiq/design-system";
import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./Filter.module.scss";

export type Intent = "primary" | "default";

export default function FilterMenu(props: {
  className?: string;
  contentClassName?: string;
  search?: string;
  onSearchChange?: (value: string) => void;
  children?: ReactNode;
  intent?: Intent;
  autoFocus?: boolean;
}) {
  const {
    autoFocus = true,
    className,
    contentClassName,
    intent = "primary",
    search,
    onSearchChange,
    children,
  } = props;

  const hasSearch = search !== undefined && onSearchChange !== undefined;
  return (
    <MenuSP
      color={intent}
      view="raised"
      isDense
      className={classNames(styles.menu, className)}
    >
      {hasSearch && (
        <SearchInput
          autoFocus={autoFocus}
          fill
          value={search}
          onChange={onSearchChange}
          className={styles.popover__search}
          intent={intent}
          isDense
        />
      )}
      <div
        className={classNames(
          styles.popover__searchContent,
          !hasSearch && styles.no_search,
          contentClassName
        )}
      >
        {children}
      </div>
    </MenuSP>
  );
}
