import React, { ReactNode } from "react";
import { Dialog } from "../../../components";
import { useTranslations } from "../../../util";
import { useDeletePassports } from "../hooks";

export default function DeletePassportsDialog(props: {
  passports: number[];
  elementName?: ReactNode;
  onClose: () => void;
}) {
  const { passports, onClose } = props;
  const tr = useTranslations();

  const { mutate: deletePassports } = useDeletePassports();

  const handleDelete = () => {
    deletePassports(passports, {
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      onSecondaryClick={onClose}
      onPrimaryClick={handleDelete}
      intent="danger"
      icon="trash"
      primaryText={tr.translateAsString("generic.action.delete")}
      secondaryText={tr.translateAsString("generic.action.cancel")}
      title={tr.translateAsString("passport.dialogs.delete.passports.title", {
        nbr: passports.length,
      })}
    >
      <div>{tr.translate("passport.dialogs.delete.passports.subtitle")}</div>
    </Dialog>
  );
}
