import React from "react";
import useProductLink from "./hooks/useProductLink";
import NameLink from "./NameLink";

export default function ProjectNameLink(props: {
  projectId: number;
  hasIcon?: boolean;
  hasThumbnail?: boolean;
  className?: string;
  search?: string;
}) {
  const { className, projectId, hasIcon, hasThumbnail, search } = props;

  const linkProps = useProductLink(projectId, hasThumbnail);

  return (
    <NameLink
      className={className}
      hasIcon={hasIcon || hasThumbnail}
      search={search}
      {...linkProps}
    />
  );
}
