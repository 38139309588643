import { AccessEntity, GroupAccess, UserAccess } from "@aletiq/types";
import { Classes } from "@blueprintjs/core";
import React from "react";
import { Dialog, Divider, UserMultiselect } from "../../../../components";
import { useTranslations } from "../../../../util";
import GroupMultiselect from "./GroupMultiselect";
import GroupRow from "./GroupRow";
import { useEditedAccess } from "./hooks";
import OpenAccessRow from "./OpenAccessRow";
import OwnerRow from "./OwnerRow";
import styles from "./ShareDocumentDialog.module.scss";
import UserRow from "./UserRow";

export default function EditLoadedEntityAccess(props: {
  initialOwner: number;
  groupsWithAccess: number[];
  usersWithAccess: number[];
  isPublic: boolean;
  onClose: () => void;
  onSubmit: (newAccess: AccessEntity[], newOwner?: number) => void;
  isSubmitting?: boolean;
  title?: string;
}) {
  const {
    initialOwner,
    groupsWithAccess,
    isPublic,
    onClose,
    onSubmit,
    usersWithAccess,
    isSubmitting,
    title,
  } = props;

  const tr = useTranslations();

  const {
    editedGroups = [],
    editedOpenAccess,
    editedOwner,
    editedUsers = [],
    handleAddGroups,
    handleAddUsers,
    handleEditOwner,
    handleRemoveGroup,
    handleRemoveUser,
    handleSetOpenAccess,
  } = useEditedAccess(
    initialOwner,
    isPublic,
    usersWithAccess,
    groupsWithAccess
  );

  const handleSubmit = () => {
    const newOwner = editedOwner !== initialOwner ? editedOwner : undefined;
    let newAccessRights: AccessEntity[] = [
      ...editedUsers.map((user) => ({ type: "user", user } as UserAccess)),
      ...editedGroups.map((group) => ({ type: "group", group } as GroupAccess)),
    ];
    if (editedOpenAccess)
      newAccessRights = [{ type: "open" }, ...newAccessRights];

    onSubmit(newAccessRights, newOwner);
  };

  const handleAddOpenAccess = () => handleSetOpenAccess(true);

  const handleRemoveOpenAccess = () => handleSetOpenAccess(false);

  return (
    <Dialog
      isOpen
      enforceFocus={false}
      icon="people"
      onClose={onClose}
      title={title}
      className={styles.dialog}
      onSecondaryClick={onClose}
      submitting={isSubmitting}
      onPrimaryClick={handleSubmit}
    >
      <div className={styles.innerDialog}>
        <div className={Classes.DIALOG_BODY}>
          <div className={styles.array}>
            <div className={styles.array__header}>
              {tr.translate("generic.label.owner")}
            </div>
            <Divider intent="primary" className={styles.divider} />
            {editedOwner && (
              <OwnerRow userId={editedOwner} onSetOwner={handleEditOwner} />
            )}
          </div>
          <div className={styles.array}>
            <div className={styles.array__header}>
              {tr.translate("document.dialog.share.groups")}
            </div>
            <Divider intent="primary" className={styles.divider} />
            {editedOpenAccess && (
              <OpenAccessRow onRemove={handleRemoveOpenAccess} />
            )}
            {editedGroups.map((groupId) => (
              <GroupRow
                key={groupId}
                groupId={groupId}
                onRemove={handleRemoveGroup}
              />
            ))}
            <GroupMultiselect
              isDocPublic={editedOpenAccess}
              onAddGroups={handleAddGroups}
              onAddOpenAccess={handleAddOpenAccess}
              groupsToIgnore={editedGroups}
            />
          </div>
          <div className={styles.array}>
            <div className={styles.array__header}>
              {tr.translate("document.dialog.share.other-users")}
            </div>
            <Divider intent="primary" className={styles.divider} />
            {editedUsers.map((userId) => (
              <UserRow
                key={userId}
                userId={userId}
                onRemove={handleRemoveUser}
              />
            ))}
            <UserMultiselect
              onSubmit={handleAddUsers}
              usersToIgnore={editedUsers}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
