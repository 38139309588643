import { Task } from "@aletiq/types";
import classNames from "classnames";
import React, { Fragment, useMemo, useState } from "react";
import { useUsers } from "../../../../../hooks";
import WorkflowTaskTable from "../../WorkflowTaskTable";
import TaskEditInfo from "../taskEditUtils";
import TaskRow from "../TaskRow";
import TaskRowEditor from "../TaskRowEditor";
import styles from "../WorkflowView.module.scss";

export default function WorkflowEditorTaskTable(props: {
  workflowColor: string;
  tasks: Task[];
  onTaskEdited: (editedSpecInfo: TaskEditInfo) => void;
  onMarkForDeletion: (taskId: number, taskOrder: number) => void;
  onRemoveNewTask: (id: number) => void;
  onNewSpecEdited: (newSpecInfo: TaskEditInfo) => void;
  onDragAndDrop: (dragFrom: number, dragTo: number) => void;
}) {
  const {
    workflowColor,
    tasks,
    onTaskEdited,
    onMarkForDeletion,
    onRemoveNewTask,
    onNewSpecEdited,
    onDragAndDrop,
  } = props;

  const [draggedItem, setDraggedItem] = useState<number | undefined>();
  const [dragTarget, setDraggedTarget] = useState<
    { order: number; posY: number } | undefined
  >();

  const { data: allUsers = [] } = useUsers();
  const allowedTaskOwners = useMemo(
    () =>
      allUsers.filter((u) => u.permissions.includes("update:own-task-status")),
    [allUsers]
  );

  const hasUnresolvedDeps = (task: Task) =>
    tasks
      .filter((t) => task.dependencies.includes(t.id))
      .some((t) => t.status !== "done");

  const handleDrop = (ev: React.DragEvent) => {
    ev.preventDefault();
    if (
      dragTarget !== undefined &&
      draggedItem !== undefined &&
      dragTarget.order !== draggedItem
    ) {
      if (dragTarget.order > draggedItem)
        onDragAndDrop(draggedItem, dragTarget.order - 1);
      else onDragAndDrop(draggedItem, dragTarget.order);
    }
    setDraggedTarget(undefined);
    setDraggedItem(undefined);
  };

  const handleDragLeave = (ev: React.DragEvent, order: number) => {
    ev.preventDefault();
    // if item is dragged below the last list item, then set target order to list length
    if (order === tasks.length && dragTarget && ev.clientY > dragTarget?.posY) {
      setDraggedTarget({
        order: tasks.length + 1,
        posY: ev.clientY,
      });
      //only update drag target if order has changed
    } else if (!dragTarget || dragTarget.order !== order) {
      setDraggedTarget({ order: order, posY: ev.clientY });
    }
  };

  const ignoreDefaultEvent = (ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  return (
    <WorkflowTaskTable color={workflowColor} isBeingEdited>
      {tasks.map((task, index) => {
        const canUpdateTask = task.status === "not_started";
        const hasLockedDeps = hasUnresolvedDeps(task);
        const isLastTask = task.order === tasks.length;
        const isDragged = index === draggedItem;
        const isDraggedToEnd = dragTarget?.order === tasks.length + 1;

        return (
          <Fragment key={task.id}>
            {draggedItem !== undefined &&
              task.order === dragTarget?.order &&
              !isDraggedToEnd && <DnDPlaceholder />}

            <tr
              draggable
              onDragEnter={ignoreDefaultEvent}
              onDragOver={ignoreDefaultEvent}
              onDragStart={() => setDraggedItem(task.order)}
              onDragLeave={(ev) => handleDragLeave(ev, task.order)}
              onDragEnd={handleDrop}
              className={classNames(styles.selected)}
            >
              {isDragged || !canUpdateTask ? (
                <TaskRow
                  task={task}
                  hasStatusUpdateRights
                  hasLockedDependencies={hasLockedDeps}
                  workflowTasks={tasks}
                  workflowColor={workflowColor}
                  isWorkflowBeingEdited
                />
              ) : task.id <= 0 ? (
                <TaskRowEditor
                  task={task}
                  onDelete={onRemoveNewTask}
                  onTaskEdit={onNewSpecEdited}
                  workflowTasks={tasks}
                  workflowColor={workflowColor}
                  hasLockedDependencies={false}
                  allowedTaskOwners={allowedTaskOwners}
                  isNewTask
                />
              ) : (
                <TaskRowEditor
                  task={task}
                  hasLockedDependencies={hasLockedDeps}
                  onTaskEdit={onTaskEdited}
                  onDelete={onMarkForDeletion}
                  workflowTasks={tasks}
                  workflowColor={workflowColor}
                  allowedTaskOwners={allowedTaskOwners}
                />
              )}
            </tr>

            {draggedItem !== undefined && isLastTask && isDraggedToEnd && (
              <DnDPlaceholder />
            )}
          </Fragment>
        );
      })}
    </WorkflowTaskTable>
  );
}

const DnDPlaceholder = React.memo(() => {
  return (
    <tr className={styles.drop_placeholder}>
      <td colSpan={6} />
    </tr>
  );
});
