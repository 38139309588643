import { Icon } from "@aletiq/design-system";
import { Task } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React, { Fragment } from "react";
import {
  DateWithSpaces,
  StatusSelect,
  UserInitialsList,
} from "../../../../../components";
import TaskDependencyList from "../../TaskDependencyList";
import styles from "./TaskRow.module.scss";

const TaskRow = React.memo(
  (props: {
    hasLockedDependencies: boolean;
    hasStatusUpdateRights: boolean;
    isWorkflowBeingEdited?: boolean;
    task: Task;
    workflowTasks: Task[];
    workflowColor: string;
  }) => {
    const {
      hasLockedDependencies,
      hasStatusUpdateRights,
      isWorkflowBeingEdited,
      workflowTasks,
      workflowColor,
      task,
    } = props;

    return (
      <Fragment>
        <td className={styles.name_cell}>
          {isWorkflowBeingEdited ? (
            <>
              <Icon icon="drag-handle-vertical" className={styles.drag_icon} />
              <span
                className={styles.name_wrapper}
                style={{
                  borderLeft: `6px solid ${workflowColor}`,
                }}
              >
                {task.title}
              </span>
            </>
          ) : (
            <Text ellipsize>{task.title}</Text>
          )}
        </td>
        <td>
          <div className={styles.owners}>
            <UserInitialsList users={task.owners} fill />
          </div>
        </td>
        <td>{task.dueDate && <DateWithSpaces date={task.dueDate} />}</td>
        <td>
          <TaskDependencyList
            task={task}
            workflowTasks={workflowTasks}
            workflowColor={workflowColor}
          />
        </td>
        <td>
          <StatusSelect
            hasUpdateRights={hasStatusUpdateRights}
            hasLockedDependencies={hasLockedDependencies}
            task={task}
          />
        </td>
        {isWorkflowBeingEdited && <td />}
      </Fragment>
    );
  }
);

export default TaskRow;
