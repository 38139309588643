import { TaskModel } from "@aletiq/types";

export const handleDropTask = (
  dragTo: number,
  dragFrom: number,
  tasks: TaskModel[]
) => {
  const taskToMove = tasks[dragFrom];

  const withoutTask = [
    ...tasks.slice(0, dragFrom),
    ...tasks.slice(dragFrom + 1, tasks.length),
  ];

  const addedTask = [
    ...withoutTask.slice(0, dragTo),
    taskToMove,
    ...withoutTask.slice(dragTo, withoutTask.length),
  ];

  const updateId = (id: number) => {
    if (id === dragFrom) return dragTo;
    if (id < dragFrom && id < dragTo) return id;
    if (id < dragFrom && id >= dragTo) return id + 1;
    if (id > dragFrom && id > dragTo) return id;
    if (id > dragFrom && id <= dragTo) return id - 1;
    // eslint-disable-next-line no-console
    console.log(`Invalid index: ${id}`);
    return 0;
  };

  return addedTask.map((t) => ({
    ...t,
    id: updateId(t.id - 1) + 1,
    dependencies: t.dependencies.map((id) => updateId(id - 1) + 1),
  }));
};
