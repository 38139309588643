import { PassportQueryFilterParams } from "@aletiq/types";
import React, { useMemo } from "react";
import {
  ANALYTICS_VISITED_PRODUCT_PASSPORTS,
  useTrackEvent,
} from "../../../analytics";
import { PageTitle } from "../../../components";
import { useColumnConfig, useFilter } from "../../../hooks";
import { useTranslations } from "../../../util";
import { PassportActionBar } from "../../passport/common";
import {
  defaultPassportColumnConfig,
  PassportColumn,
} from "../../passport/hooks/usePassportTableColumns";
import PaginatedPassportList from "../../passport/PaginatedPassportList";
import { ProjectPage } from "../common";
import { useProject } from "../hooks";

export default function ProjectPassports(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_PASSPORTS);

  const { filter, handleFilter, handleClear } =
    useFilter<PassportQueryFilterParams>({
      search: "",
      projects: [projectId],
    });

  const defaultConfig = useMemo(
    () => ({ ...defaultPassportColumnConfig, config: true }),
    []
  );

  const { config: columnConfig, handleToggleColumn } =
    useColumnConfig<PassportColumn>(defaultConfig);

  const { data: project } = useProject(projectId);
  const projectType = project?.isTool ? "tool" : "article";

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.passport")}
    >
      <PageTitle icon="id-number">
        {tr.translate("sidebar.passports")}
      </PageTitle>
      <PassportActionBar
        filter={filter}
        handleClear={handleClear}
        handleFilter={handleFilter}
        projectId={projectId}
        projectType={projectType}
        columnConfig={columnConfig}
        onToggleColumn={handleToggleColumn}
      />
      <PaginatedPassportList
        filter={filter}
        projectId={projectId}
        projectType={projectType}
        columnConfig={columnConfig}
      />
    </ProjectPage>
  );
}
