import {
  AlertPopover,
  Button,
  Card,
  CardList,
  EditableText,
  Field,
  HighlightedText,
  Menu,
  MenuItem,
  Popover2,
  SearchInput,
  Tooltip,
} from "@aletiq/design-system";
import { Document, Operation, OperationBill } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  ActionBar,
  AlignRight,
  GenericStateTag,
  GenericStatusTag,
  ProjectName,
} from "../../../components";
import { useNavigate, useToaster } from "../../../hooks";
import { Diff, isInString, useTranslations } from "../../../util";
import BillActionsMenu from "./BillActionsMenu";
import CopyOperationsForm from "./CopyOperationsForm";
import { AddOperationsForm, ImportOperationsForm } from "./dialogs";
import {
  useDeleteOperation,
  useDocumentsById,
  useToolsWithDraftVersions,
  useUpdateBillDescription,
} from "./hooks";
import OperationBillSelect from "./OperationBillSelect";
import OperationItemsCount from "./OperationItemsCount";
import PageContent from "./PageContent";
import styles from "./ProjectOperationBill.module.scss";
import ValidateBill from "./ValidateBill";

export default function ProjectOperationBillEditor(props: {
  projectId: number;
  activeBill: OperationBill;
  diff: Record<number, Diff<Operation>>;
  bills: OperationBill[];
}) {
  const { projectId, activeBill, diff, bills } = props;
  const tr = useTranslations();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [isValidating, setValidating] = useState(false);
  const [isAddingOperations, setAddingOperations] = useState(false);
  const [isImportingOperations, setImportingOperations] = useState(false);
  const [isCopyingOperations, setCopyingOperations] = useState(false);

  const { isLoading: updateDescriptionLoading, mutate: updateDescription } =
    useUpdateBillDescription(projectId, activeBill);

  const filteredOperations = activeBill.operations.filter(
    (op) =>
      isInString(op.name, search) || isInString(op.number.toString(), search)
  );

  const handleShowOperation = (number: number) => () => {
    navigate({
      path: `/project/${projectId}/operation-bills/${activeBill?.id}/operations/${number}`,
    });
  };

  const handleShowBill = (bill: number) => {
    navigate({
      path: `/project/${projectId}/operation-bills/${bill}`,
    });
  };

  const handleUpdateDescription = (description: string) => {
    updateDescription(description);
  };

  const documentIds = activeBill.operations.flatMap((op) =>
    op.documents.map((d) => d.document)
  );
  const documentsById = useDocumentsById(documentIds);
  const toolsWithDraftVersions = useToolsWithDraftVersions(
    activeBill.operations.flatMap((op) => op.tools)
  );

  const draftAndObsoleteDocs = activeBill.operations.flatMap((op) =>
    op.documents.flatMap((operationDocument) => {
      const document: Document | undefined =
        documentsById[operationDocument.document];
      if (!document) {
        return [];
      }

      const revision = document
        ? operationDocument.revision.revision
        : undefined;
      if (
        !revision ||
        revision.state === "draft" ||
        revision.state === "obsolete"
      ) {
        return [document];
      }

      return [];
    })
  );

  const toaster = useToaster();
  const handleValidate = () => {
    if (
      draftAndObsoleteDocs.length === 0 &&
      toolsWithDraftVersions.length === 0
    ) {
      setValidating(true);
    } else {
      toaster.show({
        icon: "warning-sign",
        intent: "danger",
        message: (
          <div>
            <div>
              {tr.translate(
                "project.operation-bill.validate.draft-warning.title"
              )}
            </div>
            <div className={styles.toaster_content}>
              <span>
                {tr.translate(
                  "project.operation-bill.validate.draft-warning.caption",
                  {
                    draft: <GenericStateTag state="draft" />,
                    obsolete: <GenericStateTag state="obsolete" />,
                  }
                )}
              </span>
              <ul>
                {draftAndObsoleteDocs.map((d) => (
                  <li key={d.id}>{d.name}</li>
                ))}
                {toolsWithDraftVersions.map((toolId) => (
                  <li>
                    <ProjectName projectId={toolId} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ),
      });
    }
  };

  return (
    <PageContent>
      <Field label={tr.translate("project.operation-bill.bill-index")}>
        <ActionBar>
          <OperationBillSelect
            activeBill={activeBill}
            bills={bills}
            onSelect={handleShowBill}
          />
          <BillActionsMenu
            projectId={projectId}
            bills={bills}
            activeBill={activeBill}
            onValidate={handleValidate}
          />
        </ActionBar>
      </Field>
      <Field label={tr.translate("generic.label.description")}>
        <EditableText
          key={activeBill.description}
          className={styles.description_editor}
          multiline
          defaultValue={activeBill.description}
          onConfirm={handleUpdateDescription}
          disabled={updateDescriptionLoading}
        />
      </Field>
      <ActionBar>
        <Tooltip
          position="top"
          content={tr.translate("project.operation-bill.search-operations")}
        >
          <SearchInput isDense value={search} onChange={setSearch} />
        </Tooltip>
        <AlignRight />
        <Popover2
          content={
            <Menu isDense>
              <MenuItem
                isDense
                icon="plus"
                text={tr.translateAsString(
                  "project.operation-bill.add-operations.title"
                )}
                onClick={() => setAddingOperations(true)}
              />
              <MenuItem
                isDense
                icon="duplicate"
                text={tr.translateAsString(
                  "project.operation-bill.copy-operations.title"
                )}
                onClick={() => setCopyingOperations(true)}
              />
              <MenuItem
                isDense
                icon="panel-table"
                text={tr.translateAsString(
                  "project.operation-bill.import-operations.title"
                )}
                onClick={() => setImportingOperations(true)}
              />
            </Menu>
          }
        >
          <Button isDense intent="secondary" icon="plus">
            {tr.translate("project.operation-bill.add-operations")}
          </Button>
        </Popover2>
      </ActionBar>
      <Field>
        <CardList>
          {filteredOperations.map((operation) => (
            <BillEditorOperation
              key={operation.number}
              projectId={projectId}
              billId={activeBill.id}
              operation={operation}
              diff={diff[operation.number] ?? {}}
              handleShowOperation={handleShowOperation}
              search={search}
            />
          ))}
        </CardList>
      </Field>
      {isValidating ? (
        <ValidateBill
          projectId={projectId}
          billId={activeBill.id}
          onClose={() => setValidating(false)}
        />
      ) : null}
      {isAddingOperations ? (
        <AddOperationsForm
          projectId={projectId}
          bill={activeBill}
          onClose={() => setAddingOperations(false)}
          onSuccess={() => setAddingOperations(false)}
        />
      ) : null}
      {isImportingOperations ? (
        <ImportOperationsForm
          projectId={projectId}
          bill={activeBill}
          onClose={() => setImportingOperations(false)}
        />
      ) : null}
      {isCopyingOperations ? (
        <CopyOperationsForm
          projectId={projectId}
          billId={activeBill.id}
          onClose={() => setCopyingOperations(false)}
        />
      ) : null}
    </PageContent>
  );
}

function BillEditorOperation(props: {
  projectId: number;
  billId: number;
  operation: Operation;
  diff: Diff<Operation>;
  handleShowOperation: (number: number) => () => void;
  search: string;
}) {
  const { projectId, billId, operation, diff, handleShowOperation, search } =
    props;
  const tr = useTranslations();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const { isLoading: deleteOperationLoading, mutate: deleteOperation } =
    useDeleteOperation(projectId, billId, operation);

  const handleDeleteOperation = () => {
    deleteOperation();
    setPopoverOpen(false);
  };

  const handleOpenAlertPopover = () => {
    setPopoverOpen(true);
  };

  const handleCloseAlertPopover = () => {
    setPopoverOpen(false);
  };

  return (
    <Card
      onClick={handleShowOperation(operation.number)}
      title={
        <Text ellipsize className={styles.operation_name}>
          <HighlightedText
            text={operation.number.toString()}
            highlight={search}
          />
          {" - "}
          <HighlightedText text={operation.name} highlight={search} />
        </Text>
      }
      headerActions={
        <>
          <GenericStatusTag status={diff.status} />
          <OperationItemsCount
            toolCount={operation.tools.length}
            documentCount={operation.documents.length}
            hasObsoleteDocs={operation.documents.some(
              (doc) => doc.revision.revision?.state === "obsolete"
            )}
          />
          <AlertPopover
            isOpen={popoverOpen}
            position="bottom-right"
            icon="trash"
            title={tr.translateAsString(
              "project.operation-bill.delete-operation"
            )}
            content={tr.translateAsString(
              "project.operation-bill.delete-operation.confirm"
            )}
            onPrimaryClick={handleDeleteOperation}
            isDense
            onClose={handleCloseAlertPopover}
          >
            <Button
              isDense
              icon="trash"
              onClick={handleOpenAlertPopover}
              isLoading={deleteOperationLoading}
              className={styles.delete_button}
            />
          </AlertPopover>
        </>
      }
      className={styles.draft_operation}
    />
  );
}
