import { Icon, Tooltip } from "@aletiq/design-system";
import { EntityId } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import { useTranslations } from "../../../../util";
import { useProjectsById } from "../../../project/hooks";
import WorkflowDocumentCount from "./WorflowDocumentCount";
import styles from "./WorkflowCardHeader.module.scss";
import WorkflowPartCount from "./WorkflowPartCount";
import WorkflowPassportCount from "./WorkflowPassportCount";

const WorkflowEntityCounts = React.memo(
  (props: {
    workflowEntities: EntityId[];
    isWorkflowBeingEdited?: boolean;
  }) => {
    const { workflowEntities, isWorkflowBeingEdited } = props;
    const tr = useTranslations();

    const workflowParts = workflowEntities?.filter((e) => e.type === "part");
    const workflowDocs = workflowEntities?.filter((e) => e.type === "file");
    const workflowProjects = workflowEntities
      ?.filter((e) => e.type === "project")
      .map((e) => e.id);
    const workflowPassports = workflowEntities?.filter(
      (e) => e.type === "passport"
    );

    const projectsById = useProjectsById(workflowProjects);
    const workflowArticles = projectsById.filter((p) => p.isArticle);
    const workflowTools = projectsById.filter((p) => p.isTool);

    const getClassname = (itemCount: number) =>
      itemCount > 0 ? styles.counter : styles.empty_counter;

    return (
      <div
        className={classNames(
          styles.entities,
          isWorkflowBeingEdited && styles.edited
        )}
      >
        <span>{tr.translate("workflow.details.linked-to")}</span>
        {/* Articles */}
        <Tooltip
          isDisabled={workflowArticles.length === 0}
          content={
            <>
              {workflowArticles.map((article) => (
                <div key={article.id}>{article.name}</div>
              ))}
            </>
          }
        >
          <span className={getClassname(workflowArticles.length)}>
            <Icon icon="projects" inline />
            {workflowArticles.length}
          </span>
        </Tooltip>

        {/* Tools */}
        <Tooltip
          isDisabled={workflowTools.length === 0}
          content={
            <>
              {workflowTools.map((tool) => (
                <div key={tool.id}>{tool.name}</div>
              ))}
            </>
          }
        >
          <span className={getClassname(workflowTools.length)}>
            <Icon icon="wrench" inline />
            {workflowTools.length}
          </span>
        </Tooltip>

        <WorkflowPassportCount workflowPassports={workflowPassports} />
        <WorkflowPartCount workflowParts={workflowParts} />
        <WorkflowDocumentCount workflowDocs={workflowDocs} />
      </div>
    );
  }
);

export default WorkflowEntityCounts;
