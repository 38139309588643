import { AccessEntity } from "@aletiq/types";
import { Classes, Spinner } from "@blueprintjs/core";
import React from "react";
import { Dialog } from "../../../../components";
import { useSortedAccess } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import EditLoadedEntityAccess from "../../../documents/dialogs/ShareDocumentDialog/EditLoadedEntityAccess";
import { usePart, usePartAccess, useUpdatePartAccess } from "../../hooks";
import styles from "./SharePartDialog.module.scss";

export default function SharePartDialog(props: {
  partId: number;
  onClose: () => void;
}) {
  const { partId, onClose } = props;
  const tr = useTranslations();

  const { data: part } = usePart(partId);
  const { data: accessEntities = [], isLoading } = usePartAccess(partId);
  const { isPublic, usersWithAccess, groupsWithAccess } =
    useSortedAccess(accessEntities);

  const { mutate: editPartAccess, isLoading: isSubmitting } =
    useUpdatePartAccess(partId);

  const handleSubmit = (newAccessRights: AccessEntity[], newOwner?: number) => {
    editPartAccess({ newAccessRights, newOwner }, { onSettled: onClose });
  };

  const dialogTitle = tr.translateAsString("part.dialog.share-part.title");

  return (
    <>
      {isLoading && (
        <Dialog
          isOpen
          icon="people"
          onClose={onClose}
          title={dialogTitle}
          className={styles.dialog}
          disablePrimary
          onSecondaryClick={onClose}
        >
          <div className={Classes.DIALOG_BODY}>
            <Spinner />
          </div>
        </Dialog>
      )}
      {!isLoading && part && (
        <EditLoadedEntityAccess
          initialOwner={part.owner}
          groupsWithAccess={groupsWithAccess}
          usersWithAccess={usersWithAccess}
          onClose={onClose}
          onSubmit={handleSubmit}
          isPublic={isPublic}
          isSubmitting={isSubmitting}
          title={dialogTitle}
        />
      )}
    </>
  );
}
