import { Document } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import groupDocumentFiles from "../dialogs/DocumentsUploadWizard/groupDocumentFiles";
import { documentKeys } from "./queries";

export default function useGroupDocumentFiles(files: File[]) {
  const api = useApi();
  return useQuery(
    documentKeys.byFilenames(files.map((f) => f.name)),
    async () => {
      const docsByName: Record<string, Document | undefined> = {};
      for (const file of files) {
        const document = await api.document.getDocumentByFileName(file.name);
        docsByName[file.name] = document;
      }
      return {
        files: groupDocumentFiles(docsByName, files),
      };
    }
  );
}
