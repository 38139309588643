import { TaskStatusFilterValue } from "@aletiq/types";
import { Icon } from "@blueprintjs/core";
import React, { ReactElement } from "react";
import { toggle, useTranslations } from "../../util";
import FilterMenu from "./FilterMenu";
import FilterMenuItem from "./FilterMenuItem";
import FilterTemplate from "./FilterTemplate";

export default function TaskStatusFilter(props: {
  selected: TaskStatusFilterValue[];
  onSelectStatus: (TaskStatus?: TaskStatusFilterValue[]) => void;
}) {
  const tr = useTranslations();
  const { selected, onSelectStatus } = props;

  const taskStatus: {
    value: TaskStatusFilterValue;
    name: string;
    icon?: ReactElement;
  }[] = [
    {
      value: "not_started_unlocked",
      name: tr.translateAsString("filter.status.waiting"),
      icon: <Icon icon="unlock" />,
    },
    {
      value: "not_started_locked",
      name: tr.translateAsString("filter.status.waiting"),
      icon: <Icon icon="lock" />,
    },
    {
      value: "started",
      name: tr.translateAsString("filter.status.in-progress"),
    },
    {
      value: "done",
      name: tr.translateAsString("filter.status.ended"),
    },
  ];

  const handleSelect = (value: TaskStatusFilterValue) => () =>
    onSelectStatus(toggle(selected, value));

  const onClear = () => {
    onSelectStatus();
  };

  return (
    <FilterTemplate
      count={selected.length}
      onClear={onClear}
      placeholder={tr.translateAsString("filter.status.placeholder")}
      content={
        <FilterMenu>
          {taskStatus.map((item) => (
            <FilterMenuItem
              key={item.value}
              selected={selected.includes(item.value)}
              onSelect={handleSelect(item.value)}
              text={item.name}
              labelElement={item.icon}
            />
          ))}
        </FilterMenu>
      }
    />
  );
}
