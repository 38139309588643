import React from "react";
import { useTranslations } from "../../../util";
import TreeButton, { TreeButtonProps } from "./TreeButton";

export default function CreateButton(props: TreeButtonProps) {
  const tr = useTranslations();

  return (
    <TreeButton
      {...props}
      isDense
      icon="plus"
      view="flat"
      color="primary"
      text={tr.translate("generic.action.create")}
    />
  );
}
