import { Button, Menu, MenuItem, Tooltip } from "@aletiq/design-system";
import { ApiActivity, Comment, EntityId } from "@aletiq/types";
import classNames from "classnames";
import React, { useState } from "react";
import useEntityActivities from "../../features/activities/hooks/useEntityActivities";
import {
  useCreateComment,
  useDeleteComment,
  useEntityComments,
  useUpdateComment,
} from "../../features/comments/hooks";
import { useCurrentUser } from "../../hooks";
import { sortByDateString, useTranslations } from "../../util";
import DeleteAlert from "../alert/DeleteAlert";
import AlignRight from "../AlignRight/AlignRight";
import CleanerReactMarkdown from "../CleanerReactMarkdown/CleanerReactMarkdown";
import EditComment from "../Comment/EditComment";
import NewComment from "../Comment/NewComment";
import styles from "./Activities.module.scss";
import ActivitiesFilter, { FeedOption } from "./ActivitiesFilter";
import ActivityContent from "./ActivityContent";
import FeedCard from "./FeedCard";
import useExportActivties from "./useExportActivities";

export default function Activities(props: {
  entity: EntityId;
  className?: string;
}) {
  const { entity, className } = props;
  const tr = useTranslations();

  const { data: { list: comments = [] } = {} } = useEntityComments(entity);
  const { data: { list: activities = [] } = {} } = useEntityActivities(entity);
  const { mutate: postComment } = useCreateComment(entity);
  const { mutate: updateComment } = useUpdateComment(entity);
  const { mutate: deleteComment } = useDeleteComment(entity);

  const handlePost = (content: string, mentions: string[]) => {
    postComment({ content, mentions });
  };

  const { data: currentUser } = useCurrentUser();

  const activityElements = activities.map((activity) => ({
    date: activity.time,
    activity,
    comment: undefined,
  }));

  const commentElements = comments.map((comment) => ({
    date: comment.date,
    comment,
    activity: undefined,
  }));

  const [filter, setFilter] = useState<FeedOption[]>([]);

  const handleSetFilter = (options?: FeedOption[]) => {
    setFilter(options ?? []);
  };

  const handleResetFilter = () => {
    setFilter([]);
  };

  let elements: { date: string; activity?: ApiActivity; comment?: Comment }[] =
    [];
  if (filter.length === 0) {
    elements = [...activityElements, ...commentElements];
  }
  if (filter.includes("actions")) {
    elements = [...elements, ...activityElements];
  }
  if (filter.includes("comments")) {
    elements = [...elements, ...commentElements];
  }

  const sortedElements = sortByDateString(
    elements,
    (element) => element.date,
    "desc"
  );

  const [editedComment, setEditedComment] = useState<string | null>(null);
  const [showDeleteCommentDialog, setShowDeleteCommentDialog] = useState<
    number | null
  >(null);

  const { mutate: exportCsv } = useExportActivties(entity);

  return (
    <div className={classNames(styles.activities, className)}>
      <div className={styles.activities__header}>
        <ActivitiesFilter
          selected={filter}
          onSelect={handleSetFilter}
          onClear={handleResetFilter}
        />
        <AlignRight />
        <Tooltip
          content={tr.translate("activity.export.activites")}
          position="top-right"
        >
          <Button
            isDense
            icon="th-derived"
            intent="secondary"
            onClick={() => exportCsv()}
          />
        </Tooltip>
      </div>
      {(filter.includes("comments") || filter.length === 0) && (
        <NewComment onSubmit={handlePost} />
      )}
      <div className={styles.container}>
        {sortedElements.map((element, idx) => (
          <>
            {element.date === editedComment && element.comment && (
              <EditComment
                initialValue={element.comment.content}
                onSubmit={(content, mentions) => {
                  element.comment &&
                    updateComment({
                      commentId: element.comment.id,
                      content,
                      mentions,
                    });
                  setEditedComment(null);
                }}
                onCancel={() => setEditedComment(null)}
              />
            )}

            {element.comment && element.comment.deleteDate === null && (
              <FeedCard
                key={idx}
                user={element.comment.user}
                date={element.date}
                menu={
                  currentUser?.id === element.comment.user ? (
                    <Menu isDense intent="minimal">
                      <MenuItem
                        icon="edit"
                        text={tr.translateAsString("generic.action.modify")}
                        onClick={() => setEditedComment(element.date)}
                        isDense
                      />
                      <MenuItem
                        icon="trash"
                        text={tr.translateAsString("generic.action.delete")}
                        onClick={() =>
                          element.comment &&
                          setShowDeleteCommentDialog(element.comment.id)
                        }
                        isDense
                      />
                    </Menu>
                  ) : undefined
                }
              >
                <CleanerReactMarkdown source={element.comment.content} />
              </FeedCard>
            )}

            {element.activity && (
              <FeedCard
                key={idx}
                user={element.activity.user}
                date={element.date}
              >
                <ActivityContent activity={element.activity} />
              </FeedCard>
            )}
          </>
        ))}
      </div>
      {showDeleteCommentDialog && (
        <DeleteAlert
          elementName={tr.translateAsString(
            "activity.delete.alert.this.comment"
          )}
          onDelete={() => deleteComment(showDeleteCommentDialog)}
          onClose={() => setShowDeleteCommentDialog(null)}
        />
      )}
    </div>
  );
}
