import React from "react";
import { useTranslations } from "../../../../util";
import { Icon } from "../../../../components";
import styles from "./EmptyWorkflow.module.scss";

export default function EmptyWorkflow() {
  const tr = useTranslations();

  return (
    <div className={styles.container}>
      <Icon icon="info-sign" inline />
      {tr.translate("tasklist.empty-placeholder.description")}
    </div>
  );
}
