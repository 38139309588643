import { Icon, Tooltip } from "@aletiq/design-system";
import { EntityId } from "@aletiq/types";
import React from "react";
import { usePart } from "../../../pdm/hooks";
import styles from "./WorkflowCardHeader.module.scss";

export default function WorkflowPartCount(props: {
  workflowParts: EntityId[];
}) {
  const { workflowParts } = props;
  const partCount = workflowParts.length;

  return (
    <Tooltip
      isDisabled={partCount === 0}
      content={
        <>
          {workflowParts.map((entity) => (
            <div>
              <PartName partId={entity.id} />
            </div>
          ))}
        </>
      }
    >
      <span className={partCount > 0 ? styles.counter : styles.empty_counter}>
        <Icon icon="cube" inline />
        {partCount}
      </span>
    </Tooltip>
  );
}

function PartName(props: { partId: number }) {
  const { data: part } = usePart(props.partId, {
    refetchOnMount: false,
  });
  return <>{part?.name}</>;
}
