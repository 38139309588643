import { Part } from "@aletiq/types";
import classNames from "classnames";
import React, { Fragment } from "react";
import { Icon, IgnoreTag, PartIcon } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import styles from "./UploadedPartsList.module.scss";

export default function NonStandardParts(props: { parts: Part[] }) {
  const { parts } = props;
  const tr = useTranslations();

  return (
    <Fragment>
      {parts.map((part) => (
        <tr key={part.id}>
          <td className={styles.tag_col}>
            <div className={styles.invalid}>
              <Icon icon="warning-sign" inline />
              {tr.translate(
                "parts.dialog.drop-standard-parts.info.non-standard"
              )}
            </div>
          </td>
          <td>
            <div className={classNames(styles.part_name, styles.ignored)}>
              <PartIcon type={part.type} inline />
              {part.name}
            </div>
          </td>
          <td className={classNames(styles.action_col, styles.invalid)}>
            <IgnoreTag />
          </td>
        </tr>
      ))}
    </Fragment>
  );
}
