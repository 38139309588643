import { UserSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { userKeys } from "../../hooks/queries";

export default function useCreateUser() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation((values: UserSpec) => api.user.createUser(values), {
    onSuccess: () => queryClient.invalidateQueries(userKeys.all),
    onError: () => {
      toaster.show({
        intent: "danger",
        icon: "warning-sign",
        timeout: 2000,
        message: tr.translate("toaster.error.generic"),
      });
    },
  });
}
