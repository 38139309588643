import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { passportKeys } from "./queries";

export default function useSetPassportDescription() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (values: { passportId: number; description: string }) => {
      const { passportId, description } = values;
      return api.passport.updatePassport(passportId, [
        { type: "description", value: description },
      ]);
    },
    { onSuccess: () => queryClient.invalidateQueries(passportKeys.all) }
  );
}
