import React from "react";
import { Tag } from "../../../../components";
import { useTranslations } from "../../../../util";
import { ProductComponentTree } from "../../ProjectNomenclature/hooks";
import { checkProductComponentNode } from "./useComponentsToApprove";

export default function SelectedComponentAction(props: {
  tree: ProductComponentTree;
}) {
  const { tree } = props;

  const tr = useTranslations();

  const { canApprove } = checkProductComponentNode(tree);
  const isApproved = tree.definition.state === "approved";
  const action = canApprove ? "approve" : "warning";

  return (
    <>
      {!isApproved && (
        <>
          {action === "approve" && (
            <Tag isDense icon="tick" intent="success" unclickable>
              {tr.translate("project.definition.validate.action.type", {
                action,
              })}
            </Tag>
          )}
          {action === "warning" && (
            <Tag isDense icon="error" unclickable>
              {tr.translate("project.definition.validate.action.type", {
                action,
              })}
            </Tag>
          )}
        </>
      )}
    </>
  );
}
