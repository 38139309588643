import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactNode, useContext, useMemo } from "react";
import { API, HTTPClient } from "@aletiq/api";
import { API_URL } from "../config";

export const ApiContext = React.createContext<API | null>(null);

export default function useApi() {
  const api = useContext(ApiContext);
  if (api == null) {
    throw new Error("API context missing");
  }
  return api;
}

export function ApiProvider(props: { children?: ReactNode }) {
  const auth0 = useAuth0();
  const api = useMemo(() => new API(new HTTPClient(auth0, API_URL)), [auth0]);

  return (
    <ApiContext.Provider value={api}>{props.children}</ApiContext.Provider>
  );
}
