import { Icon } from "@aletiq/design-system";
import classNames from "classnames";
import React from "react";
import { useShowImage } from "../provider";
import styles from "./OpenableImage.module.scss";

export default function OpenableImage(props: {
  imgUrl: string;
  width?: number;
  height?: number;
  className?: string;
  alt?: string;
}) {
  const {
    imgUrl,
    width = 160,
    height = 160,
    className,
    alt = "product img",
  } = props;

  const openImagePreview = useShowImage();

  const handleClickPreview = () => {
    openImagePreview(imgUrl);
  };

  return (
    <div
      onClick={handleClickPreview}
      className={classNames(styles.picture, styles.overlay, className)}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <img alt={alt} className={styles.picture} src={imgUrl} />
      <Icon icon="search" size={30} />
    </div>
  );
}
