import { User } from "@aletiq/types";
import React from "react";
import {
  ANALYTICS_ACCOUNT_DESACTIVATED,
  ANALYTICS_ACCOUNT_EMAIL,
  ANALYTICS_ACCOUNT_ROLE,
  ANALYTICS_MUTATION_STATUS,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../../analytics";
import { Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useDeactivateUser } from "../hooks";
import styles from "./Dialogs.module.scss";

export function ConfirmDeleteUserDialog(props: {
  onClose: () => void;
  user: User;
}) {
  const { onClose, user } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const { mutate: deactivateUser, isLoading } = useDeactivateUser(user.id);

  const onSubmit = async () => {
    deactivateUser(undefined, {
      onSuccess: () => {
        onClose();
      },
      onSettled: (_, error) => {
        analytics.track(ANALYTICS_ACCOUNT_DESACTIVATED, {
          [ANALYTICS_ACCOUNT_EMAIL]: user.email,
          [ANALYTICS_ACCOUNT_ROLE]: user.role,
          [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
        });
      },
    });
  };

  return (
    <Dialog
      isOpen
      intent="danger"
      icon="disable"
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("admin.users.dialog.deactivate-user.title")}
      onPrimaryClick={onSubmit}
      submitting={isLoading}
    >
      <div className={styles.dialog__inner}>
        {tr.translate("admin.users.dialog.deactivate-user.description")}
      </div>
    </Dialog>
  );
}
