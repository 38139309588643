import { useQueries } from "react-query";
import useApi from "../../../app/useApi";
import { isNotUndefined } from "../../../util";
import { pdmQueries } from "./queries";

export default function usePartsAccess(partsIds: number[]) {
  const api = useApi();
  const accessQueries = useQueries(
    partsIds.map((partId) => pdmQueries.access(api, partId))
  );

  const isLoading = accessQueries.some((query) => query.isLoading);

  const partsAccess = accessQueries.map((r) => r.data).filter(isNotUndefined);

  return {
    isLoading,
    data: partsAccess,
  };
}
