import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { passportKeys } from "./queries";

export default function usePassportFiles(passportId: number) {
  const api = useApi();

  return useQuery(passportKeys.allFiles(passportId), () =>
    api.passport.listPassportFiles(passportId)
  );
}
