import React, { useMemo, useState } from "react";
import { useCurrentUser, useUsers } from "../../hooks";
import {
  filterByString,
  sortByString,
  toggle,
  useTranslations,
} from "../../util";
import FilterMenuItem from "./FilterMenuItem";
import FilterView from "./FilterView";

export default function OwnerFilter(props: {
  selected: number[];
  noOwnerSelected?: boolean;
  withoutEmptyOwner?: boolean;
  placeholder?: string;
  onSelect: (values?: number[]) => void;
  onSelectNoOwner?: (value?: boolean) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const {
    selected,
    noOwnerSelected,
    withoutEmptyOwner = false,
    placeholder,
    onClear,
    onSelectNoOwner,
    onSelect,
  } = props;
  const { data: currentUser } = useCurrentUser();
  const { data: users = [] } = useUsers();

  const [search, setSearch] = useState("");

  const handleSelectMe = () => {
    onSelect(toggle(selected, currentUser?.id ?? -1));
  };

  const sortedUsers = useMemo(() => {
    const filteredUsers = filterByString(users, (u) => u.displayName, search);
    return sortByString(filteredUsers, (u) => u.displayName);
  }, [users, search]);

  return (
    <FilterView
      items={sortedUsers.map((u) => ({ id: u.id, text: u.displayName }))}
      selected={selected}
      all={users.map((u) => u.id)}
      placeholder={placeholder ?? tr.translateAsString("generic.label.owner")}
      noSelection={noOwnerSelected}
      onSelect={onSelect}
      onClear={onClear}
      search={search}
      onSearch={setSearch}
      hasSelectAll
      onSelectNone={withoutEmptyOwner ? undefined : onSelectNoOwner}
      headItems={
        <FilterMenuItem
          selected={selected.includes(currentUser?.id ?? -1)}
          onSelect={handleSelectMe}
          text={tr.translate("filter.owner.me", {
            name: currentUser?.displayName ?? "",
          })}
        />
      }
    />
  );
}
