import { ProjectField } from "@aletiq/types";
import React from "react";
import { Button, Card, ConfigOptionIcon, Field } from "../../../../components";
import { useTranslations } from "../../../../util";
import { TagPassportConfiguration } from "../../../passport/common";
import ConfigFieldType from "../ConfigFieldType";
import styles from "./ConfigOptionCard.module.scss";

export default function ConfigOptionCard(props: {
  field: ProjectField;
  isDisabled: boolean;
  onClickEdit: () => void;
}): JSX.Element {
  const { field, isDisabled, onClickEdit } = props;
  const tr = useTranslations();

  return (
    <Card
      icon={<ConfigOptionIcon />}
      title={field.name}
      headerActions={
        <>
          <div className={styles.config_type}>
            <ConfigFieldType allowMultipleValue={field.allowMultiValues} />
          </div>
          <Button
            isDense
            icon="edit"
            isDisabled={isDisabled}
            onClick={onClickEdit}
          />
        </>
      }
    >
      <div className={styles.body}>
        <Field label={tr.translate("generic.label.description")}>
          {field.description}
        </Field>
        <Field label={tr.translate("product.config.values")}>
          <div className={styles.values}>
            {field.values.map((optionValue) =>
              optionValue.isDeleted ? null : (
                <TagPassportConfiguration
                  key={optionValue.id}
                  fieldId={field.id}
                  fieldValue={optionValue}
                />
              )
            )}
          </div>
        </Field>
      </div>
    </Card>
  );
}
