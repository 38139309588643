import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { passportKeys } from "./queries";

export default function useSetPassportClient() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { passportId: number; client: number | null }) => {
      const { passportId, client } = values;
      await api.passport.updatePassport(passportId, [
        { type: "client", value: client },
      ]);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(passportKeys.all),
    }
  );
}
