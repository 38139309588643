import {
  DraftEntityMutability,
  DraftEntityType,
  DraftInlineStyleType,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from "draft-js";
import { assign, defaultOptions } from "./helper";
import { ParserOption, TokenLink } from "./types";

/**
 * Inline-Level Grammar
 */

/*eslint no-useless-escape: 0*/
/*eslint no-cond-assign: 0*/
/*eslint no-control-regex: 0*/
type InlineBasic = {
  escape: RegExp;
  link: RegExp;
  mention: RegExp;
  reflink: RegExp;
  nolink: RegExp;
  strong: RegExp;
  underline: RegExp;
  em: RegExp;
  br: RegExp;
  del: RegExp;
  text: RegExp;
  inside?: RegExp;
  href?: RegExp;
  _inside?: RegExp;
  _href?: RegExp;
};

type Inline = InlineBasic & {
  pedantic?: InlineBasic;
  normal?: InlineBasic;
  gfm?: InlineBasic;
  breaks?: InlineBasic;
};

const inline: Inline = {
  escape: /^\\([\\`*{}\[\]()#+\-.!_>])/,
  mention: /\[([^\]]*)\]\((aletiq)-(user)\)/,
  link: /\[([^\]]*)\]\(([^)]*)\)/,
  reflink: /^!?\[(inside)\]\s*\[([^\]]*)\]/,
  nolink: /^!?\[((?:\[[^\]]*\]|[^\[\]])*)\]/,
  strong: /\*\*[^\*]*\*\*/g,
  underline: /\+\+[^\+]*\+\+/g,
  em: /\*[^\*]*\*|_[^_]*_/g,
  br: /^ {2,}\n(?!\s*$)/,
  del: /(?<!a)\~\~[^\~]*\~\~(?!\])/g,
  text: /^[\s\S]+?(?=[\\<!\[_*`]| {2,}\n|$)/,
};

/**
 * Inline Lexer & Compiler
 */

export class InlineLexer {
  options: ParserOption;
  links: any;
  rules: InlineBasic;
  inLink: boolean;
  keyEntity: number;

  constructor(links: TokenLink, options: ParserOption) {
    this.options = assign({}, options || defaultOptions);
    this.links = links;
    this.rules = assign({}, inline);
    this.inLink = false;
    this.keyEntity = 0;

    if (!this.links) {
      throw new Error("Tokens array requires a `links` property.");
    }
  }

  /**
   * Lexing/Compiling
   */

  parse = (
    src: string
  ): {
    text: string;
    inlineStyleRanges: RawDraftInlineStyleRange[];
    entityRanges: RawDraftEntityRange[];
    entityMap: { [key: string]: RawDraftEntity<{ [key: string]: any }> } | {};
  } => {
    let text = "";
    const inlineStyleRanges: RawDraftInlineStyleRange[] = [];
    const entityRanges = [];
    const entityMap: Record<
      number,
      {
        type: DraftEntityType;
        mutability: DraftEntityMutability;
        data?: Object;
      }
    > = {};
    let cap;

    const styleConverter: Record<string, DraftInlineStyleType> = {
      underline: "UNDERLINE",
      bold: "BOLD",
      strike: "STRIKETHROUGH",
      italic: "ITALIC",
      code: "CODE",
    };

    const updateExistingStyle = (
      halfKeySize: number,
      offset: number,
      length: number
    ) => {
      inlineStyleRanges.forEach((style) => {
        // New style is fully before existing
        if (style.offset >= offset + length) {
          style.offset -= 2 * halfKeySize;
        }
        // Old style contains new style opening mark
        else if (
          style.offset >= offset &&
          style.offset + style.length < offset + length
        ) {
          style.length -= halfKeySize;
        }
        // New style contains old style
        else if (
          style.offset >= offset &&
          style.offset + style.length <= offset + length
        ) {
          style.offset -= halfKeySize;
        }
        // Old style contains new style
        else if (
          style.offset <= offset &&
          style.offset + style.length >= offset + length
        ) {
          style.length -= 2 * halfKeySize;
        }
        // Old style contains new style closing mark
        else if (style.offset <= offset && style.offset + length >= offset) {
          style.length -= halfKeySize;
        }
      });
    };

    const getDetails = (
      cap: RegExpExecArray
    ): { length: number; offset: number } => {
      return { length: cap[0].length, offset: cap.index };
    };

    while (src) {
      // escape
      if ((cap = this.rules.escape.exec(src))) {
        src = src.substring(cap[0].length);

        text += cap[1];
        continue;
      }

      // strong
      if ((cap = this.rules.strong.exec(src))) {
        const { length, offset } = getDetails(cap);

        updateExistingStyle(2, offset, length);
        inlineStyleRanges.push({
          length: length - 4,
          offset: offset,
          style: styleConverter.bold,
        });

        src =
          cap.input.slice(0, offset) +
          cap.input.slice(offset + 2, offset + length - 2) +
          cap.input.slice(offset + length);
        continue;
      }

      // underline
      if ((cap = this.rules.underline.exec(src))) {
        const { length, offset } = getDetails(cap);

        updateExistingStyle(1, offset, length);
        inlineStyleRanges.push({
          length: length - 2,
          offset: offset,
          style: styleConverter.underline,
        });

        src =
          cap.input.slice(0, offset) +
          cap.input.slice(offset + 1, offset + length - 1) +
          cap.input.slice(offset + length);
        continue;
      }

      // em
      if ((cap = this.rules.em.exec(src))) {
        const { length, offset } = getDetails(cap);

        updateExistingStyle(1, offset, length);
        inlineStyleRanges.push({
          length: length - 2,
          offset: offset,
          style: styleConverter.italic,
        });

        src =
          cap.input.slice(0, offset) +
          cap.input.slice(offset + 1, offset + length - 1) +
          cap.input.slice(offset + length);
        continue;
      }

      // br
      if ((cap = this.rules.br.exec(src))) {
        src = src.substring(cap[0].length);

        text += "\n";
        continue;
      }

      // del (gfm)
      if ((cap = this.rules.del.exec(src))) {
        const { length, offset } = getDetails(cap);

        updateExistingStyle(2, offset, length);

        inlineStyleRanges.push({
          length: length - 4,
          offset,
          style: styleConverter.strike,
        });
        src =
          cap.input.slice(0, offset) +
          cap.input.slice(offset + 2, offset + length - 2) +
          cap.input.slice(offset + length);

        continue;
      }

      if ((cap = this.rules.mention.exec(src))) {
        const { offset } = getDetails(cap);
        const totalLength = cap[0].length;
        const newLength = cap[1].length;

        src =
          src.slice(0, offset) +
          cap[1] +
          src.slice(offset + totalLength, src.length);
        entityRanges.push({
          length: cap[1].length,
          offset,
          key: this.keyEntity,
        });
        entityMap[this.keyEntity] = {
          type: "mention",
          mutability: "SEGMENTED",
          data: {
            mention: {
              id: parseInt(cap[1], 10),
              type: cap[3],
            },
          },
        };

        inlineStyleRanges.forEach((style) => {
          if (style.offset > offset + totalLength) {
            style.offset -= totalLength - newLength;
          }
        });
        this.keyEntity++;
        continue;
      } // link

      if ((cap = this.rules.link.exec(src))) {
        const { offset } = getDetails(cap);
        const totalLength = cap[0].length;
        const newLength = cap[1].length;

        src =
          src.slice(0, offset) +
          cap[1] +
          src.slice(offset + totalLength, src.length);
        entityRanges.push({ length: newLength, offset, key: this.keyEntity });
        entityMap[this.keyEntity] = {
          type: "LINK",
          mutability: "IMMUTABLE",
          data: { url: cap[2] },
        };

        this.keyEntity++;
        continue;
      } // reflink, nolink

      if ((cap = this.rules.text.exec(src))) {
        src = src.substring(cap[0].length);
        text += cap[0];
        continue;
      }

      if (src) {
        throw new Error(`Infinite loop on byte: ${src.charCodeAt(0)}`);
      }
    }

    return {
      text,
      inlineStyleRanges,
      entityRanges,
      entityMap,
    };
  };
}
