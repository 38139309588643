import React from "react";
import { Divider } from "../../../../components";
import { useTranslations } from "../../../../util";
import { usePassportBom, usePassportUsedIn } from "../../hooks";
import PassportComponents from "./PassportComponents";
import styles from "./PassportNomenclature.module.scss";
import PassportUsedIn from "./PassportUsedIn";

export default function PassportNomenclature(props: {
  passportId: number;
  parentProjectId: number;
  parentDefinitionIdx: number;
}) {
  const { passportId, parentProjectId, parentDefinitionIdx } = props;
  const tr = useTranslations();

  const { data: usedIn = [] } = usePassportUsedIn(passportId);
  const { passportBomComponents, hasConflictWithParentBom } = usePassportBom(
    passportId,
    parentProjectId,
    parentDefinitionIdx
  );

  return (
    <React.Fragment>
      <div className={styles.header}>
        <div className={styles.row}>
          <div className={styles.product}>
            {tr.translate("passport.bom.components.product")}
          </div>
          <Divider isVertical className={styles.divider} />
          <div className={styles.passeport}>
            {tr.translate("passport.bom.components.passport")}
          </div>
        </div>
      </div>
      <PassportUsedIn parentPassportsIds={usedIn} />
      <PassportComponents
        passportId={passportId}
        passportBomComponents={passportBomComponents}
        hasConflictWithParentBom={hasConflictWithParentBom}
      />
    </React.Fragment>
  );
}
