import { useState, useEffect, useMemo } from "react";

const canUseIntersectionObserver = window.IntersectionObserver !== undefined;

/**
 * @name useHandleOnScreenVisible
 * @property {IntersectionObserverInit} options: initialisation object to observer
 * @property {Element} root: Element parent in hierarchy of the element you want to watch
 * @property {Element} rootMargin: Margin of the root component added in the calculation,
 * usefull to trigger the function before really showing on screen
 * @property {Number} threshold: the percentage of the visibility of the target
 * before the function will be executed, 0 -> one pixel is visible 1 -> all the target is visible
 * @property {function} onEnterScreen- function to apply when the componet will be visible in screen
 * @description hook use to trigger a function when a div are or will be visible in screen
 * @return {function} containerRef - the ref setter to apply to the div you need to watch
 *
 * @example
 *  Component = (props) => {
 *    const containerRef = useHandleOnScreenVisible(
 *      {
 *        root: document.getElementsByTagName("body")[0],
 *        rootMargin: "400px",
 *        threshold: 0,
 *      },
 *      () => {
 *        setState()
 *      }
 *    );
 *  }
 */
export default function useHandleOnScreenVisible(
  options: IntersectionObserverInit,
  onEnterScreen: () => void
) {
  const [containerElement, containerRef] = useState<HTMLElement | null>(null);

  const observer = useMemo(
    () =>
      new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        const entry = entries[0];

        if (!entry) {
          return;
        }

        if (entry.isIntersecting) {
          onEnterScreen();
        }
      }, options),
    [onEnterScreen, options]
  );

  useEffect(() => {
    if (containerElement) {
      observer.observe(containerElement);
    }

    return () => {
      observer.disconnect();
    };
  }, [containerElement, observer]);

  if (!canUseIntersectionObserver) {
    return undefined;
  }

  return containerRef;
}
