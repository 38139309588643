import { ButtonGroup } from "@aletiq/design-system";
import { Process } from "@aletiq/types";
import React from "react";
import {
  ANALYTICS_MUTATION_STATUS,
  ANALYTICS_WORKFLOW_ARCHIVED,
  ANALYTICS_WORKFLOW_MODEL_ID,
  ANALYTICS_WORKFLOW_MODEL_NAME,
  makeAnalyticsMutationStatus,
  makeAnalyticsOptional,
  useAnalytics,
} from "../../../../analytics";
import { useTranslations } from "../../../../util";
import { useArchiveProcess, useRestoreProcess } from "../../hooks";
import { useProcessModel } from "../../tasksSlice";
import WorkflowEntityCounts from "./WorkflowEntityCounts";
import WorkflowHeaderButton from "./WorkflowHeaderButton";

export default function WorkflowCardHeader(props: {
  workflow: Process;
  disableEdit?: boolean;
  isArchived?: boolean;
  isBeingEdited?: boolean;
  isFolded?: boolean;
  isSavingChanges?: boolean;
  onDiscardChanges?: () => void;
  onSaveChanges?: () => void;
  onStartEdit?: () => void;
  search?: string;
}) {
  const {
    disableEdit,
    isArchived,
    isBeingEdited,
    isFolded,
    isSavingChanges,
    onDiscardChanges,
    onSaveChanges,
    onStartEdit,
    workflow,
  } = props;

  const tr = useTranslations();
  const analytics = useAnalytics();

  const { data: model } = useProcessModel(workflow.model ?? 0);
  const { mutate: archiveProcess } = useArchiveProcess();
  const { mutate: restoreProcess } = useRestoreProcess();

  const handleDiscardChanges = () => {
    onDiscardChanges && onDiscardChanges();
  };

  const handleSaveChanges = () => {
    onSaveChanges && onSaveChanges();
  };

  const handleRestoreProcess = (id: number) => () => {
    restoreProcess(id);
  };

  const handleArchiveProcess = (id: number) => () => {
    archiveProcess(id, {
      onSettled: (_, error) => {
        analytics.track(ANALYTICS_WORKFLOW_ARCHIVED, {
          [ANALYTICS_WORKFLOW_MODEL_ID]: makeAnalyticsOptional(model?.id),
          [ANALYTICS_WORKFLOW_MODEL_NAME]: makeAnalyticsOptional(model?.title),
          [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
        });
      },
    });
  };

  const handleStartEdit = () => {
    onStartEdit && onStartEdit();
  };

  return (
    <>
      {workflow.relations.length > 0 && (
        <WorkflowEntityCounts
          workflowEntities={workflow.relations}
          isWorkflowBeingEdited={isBeingEdited}
        />
      )}

      <ButtonGroup>
        {isBeingEdited ? (
          <React.Fragment>
            <WorkflowHeaderButton
              icon="tick"
              intent="success"
              tooltipText={tr.translateAsString("task.action.edit.save")}
              isDisabled={disableEdit || isFolded}
              onClick={handleSaveChanges}
              isLoading={isSavingChanges}
            />
            <WorkflowHeaderButton
              isDisabled={disableEdit || isFolded}
              icon="cross"
              onClick={handleDiscardChanges}
              tooltipText={tr.translateAsString("task.action.edit.cancel")}
            />
          </React.Fragment>
        ) : !isArchived ? (
          <WorkflowHeaderButton
            isDisabled={disableEdit}
            icon="edit"
            onClick={handleStartEdit}
            tooltipText={tr.translateAsString("task.action.edit")}
          />
        ) : null}

        {isArchived ? (
          <WorkflowHeaderButton
            icon="unarchive"
            isDisabled={disableEdit}
            tooltipText={tr.translateAsString("task.action.unarchive")}
            onClick={handleRestoreProcess(workflow.id)}
          />
        ) : (
          <WorkflowHeaderButton
            icon="archive"
            isDisabled={disableEdit}
            tooltipText={tr.translateAsString("task.action.archive")}
            onClick={handleArchiveProcess(workflow.id)}
          />
        )}
      </ButtonGroup>
    </>
  );
}
