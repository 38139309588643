import { Tag, Tooltip } from "@aletiq/design-system";
import classNames from "classnames";
import React from "react";
import { DEFAULT_MAX_DISPLAYED } from "../../../util";
import { UserInitial, UserName } from "../../name";
import User from "../../User/User";
import styles from "./UserInitialsList.module.scss";

/*
 ** Initials of [maxDisplayed] users are rendered with tooltips
 ** others users are listed in a tooltip behind a "+" sign
 */
const UserInitialsList = React.memo(
  (props: {
    users: (number | string)[];
    maxDisplayed?: number;
    type?: "dense" | "small";
    fill?: boolean;
  }) => {
    const {
      users,
      maxDisplayed = DEFAULT_MAX_DISPLAYED,
      type = "small",
      fill,
    } = props;

    const displayedUsers =
      users.length > maxDisplayed ? users.slice(0, maxDisplayed) : users;

    const hiddenUsers =
      users.length > maxDisplayed ? users.slice(maxDisplayed) : [];

    return (
      <span
        className={classNames(styles.list_wrapper, { [styles.fill]: fill })}
      >
        <span
          className={classNames(
            styles.visible_users,
            users.length === 1 && styles.only_user
          )}
        >
          {displayedUsers.map((user, index) =>
            typeof user === "number" ? (
              <Tooltip content={<UserName userId={user} />} key={user}>
                <User
                  onlyInitial={users.length > 1}
                  key={user}
                  userId={user}
                  size={type}
                  intent="default"
                  className={classNames({
                    [styles.initial]: index !== 0,
                    [styles.small]: type === "small",
                    [styles.dense]: type === "dense",
                  })}
                />
              </Tooltip>
            ) : (
              <Tooltip content={user} key={user}>
                <UserInitial
                  userName={user}
                  size={type}
                  className={classNames({
                    [styles.initial]: index !== 0,
                    [styles.small]: type === "small",
                    [styles.dense]: type === "dense",
                  })}
                />
                {users.length <= 1 && user}
              </Tooltip>
            )
          )}
        </span>

        {hiddenUsers.length > 0 && (
          <span className={styles.hidden_users}>
            <Tooltip
              content={
                <>
                  {hiddenUsers.map((user) => (
                    <div key={user}>
                      {typeof user === "number" ? (
                        <UserName userId={user} />
                      ) : (
                        user
                      )}
                    </div>
                  ))}
                </>
              }
            >
              <Tag isDense>+{hiddenUsers.length}</Tag>
            </Tooltip>
          </span>
        )}
      </span>
    );
  }
);

export default UserInitialsList;
