import classNames from "classnames";
import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  assignPageLocation,
  encodePageLocation,
  pageLocationEquals,
  PageTarget,
  parsePageLocation,
} from "../../util";
import styles from "./PageLink.module.scss";

export default function PageLink(props: {
  to: PageTarget;
  children?: ReactNode;
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
}) {
  const { to, children, className, isDisabled, onClick } = props;

  const location = parsePageLocation(useLocation());

  const nextLocation = assignPageLocation(location, to);

  const noop = pageLocationEquals(location, nextLocation);

  const linkClassNames = classNames(
    className,
    styles.link,
    isDisabled && styles.disabled
  );

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    onClick && onClick();
  };

  if (isDisabled) return <div className={linkClassNames}>{children}</div>;
  else
    return (
      <Link
        to={encodePageLocation(nextLocation)}
        onClick={handleClick}
        replace={noop}
        className={linkClassNames}
      >
        {children}
      </Link>
    );
}
