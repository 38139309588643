import { Property } from "@aletiq/types";
import classNames from "classnames";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import {
  defaultCreatedAtColumn,
  defaultPropertyColumn,
  defaultUpdatedAtColumn,
  ExtendedColumn,
  GenericStatusTag,
  HighlightedText,
} from "../../../../components";
import { ColumnOptionConfig } from "../../../../hooks";
import {
  extractBy,
  indexBy,
  mapRecord,
  useTranslations,
} from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import { FilteredVersionDoc } from "../../hooks";
import AutoUpdateTag from "../AutoUpdateTag";
import { VersionDocColumn } from "../useVersionDocsColumnConfig";
import ActionCell from "./ActionCell";
import styles from "./VersionDetails.module.scss";
import VersionDocNameCell from "./VersionDocNameCell";

export default function useVersionDocsColumns(
  onDownload: (docId: number, revision: number) => void,
  onPreview: (documentId: number, revisionId: number) => void,
  config: ColumnOptionConfig<VersionDocColumn> = {} as ColumnOptionConfig<VersionDocColumn>
): ExtendedColumn<FilteredVersionDoc, keyof FilteredVersionDoc>[] {
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("document");

  const propertyRecord: Record<VersionDocColumn, Property> = indexBy(
    properties,
    "id"
  );

  return useMemo(() => {
    const columns: Record<
      VersionDocColumn,
      ExtendedColumn<FilteredVersionDoc, keyof FilteredVersionDoc>
    > = {
      name: {
        accessor: "name",
        Header: tr.translate("generic.label.name"),
        fullWidthContent: true,
        Cell: (cell: CellProps<FilteredVersionDoc>) => (
          <VersionDocNameCell
            document={cell.row.original}
            search={cell.searchString}
          />
        ),
      },
      revisionName: {
        accessor: "revisionName",
        canSort: false,
        Header: tr.translate("version.details.revision.active"),
        fullWidthContent: true,
        Cell: (props: CellProps<FilteredVersionDoc>) => {
          const document = props.row.original;
          const isDocInaccessible =
            document.doc.isDeleted || !document.doc.hasAccess;

          return (
            <div
              className={classNames(
                styles.revision,
                isDocInaccessible && styles.no_access
              )}
            >
              <HighlightedText
                text={document.revisionName}
                highlight={props.searchString}
                className={styles.revision_name}
              />
              {document.activeRevision.type === "last-approved" ? (
                <AutoUpdateTag />
              ) : (
                <GenericStatusTag
                  status={
                    document.new
                      ? "new"
                      : document.updated
                      ? "updated"
                      : document.deleted
                      ? "deleted"
                      : "unchanged"
                  }
                />
              )}
            </div>
          );
        },
      },
      description: {
        id: "description",
        accessor: "description",
        Header: tr.translate("version.details.operations"),
      },
      createdAt: defaultCreatedAtColumn(),
      updatedAt: defaultUpdatedAtColumn(),
      action: {
        id: "action",
        width: 85,
        minWidth: 85,
        canSort: false,
        disableResizing: true,
        Cell: (cell: CellProps<FilteredVersionDoc>) => (
          <ActionCell
            document={cell.row.original}
            onDownload={onDownload}
            onPreview={onPreview}
          />
        ),
      },
    };

    const propertyColumns: Record<
      VersionDocColumn,
      ExtendedColumn<FilteredVersionDoc, keyof FilteredVersionDoc>
    > = mapRecord(propertyRecord, (prop) => defaultPropertyColumn(prop));

    return [
      ...extractBy(columns, config),
      ...extractBy(propertyColumns, config),
      ...extractBy(columns, {
        action: true,
      }),
    ];
  }, [tr, config, propertyRecord, onDownload, onPreview]);
}
