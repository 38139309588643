import { DocumentCitation } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { documentKeys } from "./queries";

export default function useDeleteDocumentCitation() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (citation: DocumentCitation) =>
      api.document.deleteDocumentCitation(citation),
    {
      onSuccess: (_, { citation, citedBy }) => {
        queryClient.invalidateQueries(documentKeys.citations(citedBy));
        queryClient.invalidateQueries(documentKeys.citedBy(citation));
      },
    }
  );
}
