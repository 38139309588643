import { default as React } from "react";
import { Button, ButtonGroup } from "../../../../components";

export default function ProjectDefinitionDocumentCardValidatedHeaderAction(props: {
  onOpenPreview: () => void;
  onDownload: () => void;
}) {
  const { onOpenPreview, onDownload } = props;

  return (
    <ButtonGroup isDense>
      <Button isDense icon="eye-open" onClick={onOpenPreview} />
      <Button isDense icon="download" onClick={onDownload} />
    </ButtonGroup>
  );
}
