import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import React, { Ref } from "react";
import styles from "./Tile.module.scss";

const Tile = React.forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    ref: Ref<HTMLDivElement>
  ) => (
    <div
      {...props}
      ref={ref}
      className={classNames(Classes.ELEVATION_1, styles.tile, props.className)}
    >
      {props.children}
    </div>
  )
);

export default Tile;
