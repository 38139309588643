import { NonIdealState } from "@blueprintjs/core";
import React, { useState } from "react";
import { Button, Card } from "../../../components";
import { useTranslations } from "../../../util";
import AddDocumentsForm from "./AddDocumentsForm";
import { useAddOperationDocuments } from "./hooks";
import styles from "./NoDocument.module.scss";

export default function NoDocument(props: {
  projectId: number;
  billId: number;
  operationNumber: number;
  canAddDocument?: boolean;
}) {
  const { projectId, billId, operationNumber, canAddDocument } = props;
  const tr = useTranslations();

  const [isAddingDocuments, setAddingDocuments] = useState(false);

  const handleToggleDialog = () => setAddingDocuments(!isAddingDocuments);

  const { isLoading, mutate: handleSubmitAddDocuments } =
    useAddOperationDocuments(projectId, billId, operationNumber);

  return (
    <Card className={styles.card}>
      <NonIdealState
        icon={<img src="/assets/no-data.svg" height={80} alt="no data" />}
        description={
          <>
            {tr.translate("project.operation-bill.operation-document.empty")}
            {canAddDocument && (
              <Button
                isDense
                intent="primary"
                icon="plus"
                onClick={handleToggleDialog}
                className={styles.button}
              >
                {tr.translate("project.operation-bill.operation-document.new")}
              </Button>
            )}
          </>
        }
      />
      {isAddingDocuments && canAddDocument && (
        <AddDocumentsForm
          title={tr.translateAsString(
            "project.operation-bill.add-documents.title"
          )}
          caption={tr.translateAsString(
            "project.operation-bill.add-documents.caption"
          )}
          onSubmit={handleSubmitAddDocuments}
          projectId={projectId}
          onClose={handleToggleDialog}
          onSuccess={handleToggleDialog}
          isSubmitting={isLoading}
        />
      )}
    </Card>
  );
}
