import { useQuery } from "react-query";
import useApi from "../../app/useApi";
import { projectKeys } from "../../features/project/hooks/queries";

export default function useToolingArticles(productId: number) {
  const api = useApi();
  return useQuery(projectKeys.toolingArticlesById(productId), () =>
    api.project.listToolingArticles(productId)
  );
}
