import { Document } from "@aletiq/types";
import { useMemo } from "react";
import { useUsers } from "../../../hooks";
import { indexBy } from "../../../util";
import { ResolvedDocument } from "../Documents/documentTypes";

export default function useResolvedDocuments(
  documentsToResolve: Document[] | undefined
): {
  documents: ResolvedDocument[];
  isLoading: boolean;
} {
  const { data: users = [] } = useUsers(false, true);

  const documents: ResolvedDocument[] = useMemo(() => {
    const usersById = indexBy(users, "id");

    if (documentsToResolve === undefined) {
      return [];
    }

    return documentsToResolve.map((doc) => {
      const lastRevision = doc.revisions[0];

      return {
        id: doc.id,
        name: doc.name,
        user: doc.user,
        fileName: doc.revisions?.[0]?.file.name,
        lastRevisionName: lastRevision.revisionName,
        lastRevisionState: lastRevision.state,
        lastRevisionDescription: lastRevision.description,
        hasAccess: doc.hasAccess,
        createdAt: doc.createdAt,
        updatedAt: doc.updatedAt,
        properties: doc.properties,
        ownerName: usersById[doc.user].displayName,
      };
    });
  }, [documentsToResolve, users]);

  return {
    documents,
    isLoading: false,
  };
}
