import { Project, ProjectType } from "@aletiq/types";
import React, { useState } from "react";
import {
  AssignButton,
  CreateButton,
  DeleteButton,
  SelectButton,
  TreeButtonGroup,
} from "../../../../components/tree/";
import { useHasPermission } from "../../../../hooks";
import { partitionWith, unique } from "../../../../util";
import { usePart } from "../../../pdm/hooks";
import ProductCreationMenu from "../../common/ProductCreationMenu";
import {
  useAvailableProjectComponents,
  usePartProjectBomProjects,
  useProjectsById,
} from "../../hooks";
import { ProductTree } from "./partProjectBomUtils";
import ProductSelectMenu from "./ProductSelectMenu";

export default function ProductTreeActionBar(props: {
  parentId: number;
  tree: ProductTree;
  componentType?: ProjectType;
  isMoving: boolean;
  isKnown: boolean;
  onAssign: (product: Project) => void;
  onCreate: (type: ProjectType) => void;
  onMove: () => void;
  onDelete: () => void;
}) {
  const {
    parentId,
    tree,
    componentType,
    isMoving,
    isKnown,
    onAssign,
    onCreate,
    onMove,
    onDelete,
  } = props;

  const canCreateProjects = useHasPermission("create:projects");

  const [search, setSearch] = useState("");

  return (
    <>
      {isMoving && <AssignButton onClick={onMove} />}
      {!isMoving && (
        <TreeButtonGroup>
          {isKnown && <DeleteButton onClick={onDelete} />}
          {!isKnown && (
            <>
              <SelectButton
                content={
                  <ProductSelectMenuData
                    tree={tree}
                    search={search}
                    parentId={parentId}
                    componentType={componentType}
                    onAssign={onAssign}
                    onSearchChange={setSearch}
                  />
                }
              />
              <CreateButton
                isDisabled={!canCreateProjects}
                content={<ProductCreationMenu onCreateProduct={onCreate} />}
              />
            </>
          )}
        </TreeButtonGroup>
      )}
    </>
  );
}

function ProductSelectMenuData(props: {
  parentId: number;
  tree: ProductTree;
  componentType?: ProjectType;
  onAssign: (product: Project) => void;
  search: string;
  onSearchChange: (search: string) => void;
}) {
  const { parentId, tree, componentType, onAssign, search, onSearchChange } =
    props;
  const {
    data: availableProducts,
    removed: unavailableProductIds,
    hasTooMany,
  } = useAvailableProjectComponents(parentId, [], {
    search,
    projectType: componentType,
    limit: 100,
    offset: 0,
    order: "name",
    orderDirection: "asc",
  });

  const { data: part } = usePart(tree.part!.id);

  const availableProductIds = availableProducts.map((p) => p.id);
  const { data: linkedProductIds = [] } = usePartProjectBomProjects(
    tree.part!.id
  );
  const libraryProductIds = part?.projects ?? [];
  const allAvailableIds = unique([
    ...availableProductIds,
    ...libraryProductIds.filter((id) => !unavailableProductIds.includes(id)),
    ...linkedProductIds.filter((id) => !unavailableProductIds.includes(id)),
  ]);
  const [availableLinkedIds, availableLibraryIds, availableOtherIds] =
    partitionWith(
      allAvailableIds,
      (id) => linkedProductIds.includes(id),
      (id) => libraryProductIds.includes(id)
    );

  const otherProducts = availableProducts.filter((p) =>
    availableOtherIds.includes(p.id)
  );
  const libraryProducts = useProjectsById(availableLibraryIds);
  const linkedProducts = useProjectsById(availableLinkedIds);

  return (
    <ProductSelectMenu
      search={search}
      linkedProducts={linkedProducts}
      libraryProducts={libraryProducts}
      otherProducts={otherProducts}
      onAssign={onAssign}
      onSearchChange={onSearchChange}
      hasTooManyResults={hasTooMany}
    />
  );
}
