import React from "react";
import { Dialog } from "../../../components";
import { useTranslations } from "../../../util";
import { useDeleteDocumentDraft } from "../hooks";

export default function DeleteDocumentDraftDialog(props: {
  documentId: number;
  revisionId: number;
  onClose: () => void;
}) {
  const { documentId, revisionId, onClose } = props;
  const tr = useTranslations();

  const { mutate: deleteDocDraftRevision } = useDeleteDocumentDraft(documentId);

  const handleSubmit = () => {
    deleteDocDraftRevision(revisionId);
    onClose();
  };

  return (
    <Dialog
      isOpen
      icon="trash"
      intent="danger"
      onClose={onClose}
      title={tr.translateAsString("document.dialog.delete-draft.title")}
      onPrimaryClick={handleSubmit}
    >
      {tr.translate("document.dialog.delete-draft.description")}
    </Dialog>
  );
}
