import { OptionSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { projectKeys } from "./queries";

export default function useAddProjectOptionField() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (values: { projectId: number; spec: OptionSpec }) => {
      const { projectId, spec } = values;
      const { name, description, isMandatory, allowMultiValues } = spec;

      const optionId = await api.project.addProjectOptionField(projectId, {
        name,
        description,
        isMandatory,
        allowMultiValues,
      });

      await api.project.updateProjectOptionField(
        projectId,
        optionId,
        spec.values.map((v) => ({
          type: "addValue",
          value: { ...v, isDeleted: false },
        }))
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.project.config.create"),
        });
      },
    }
  );
}
