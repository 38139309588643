import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useUpdateProjectDefinitionDocument(
  projectId: number,
  definitionIdx: number,
  documentId: number
) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (revision: number) => {
      await api.project.updateProjectDefinitionDocument(
        projectId,
        definitionIdx,
        documentId,
        revision
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          projectKeys.allDocumentsDefinition(projectId, definitionIdx)
        );
      },
    }
  );
}
