import { AnchorButtonSP, Tooltip, UIColor } from "@aletiq/design-system";
import { FormattedDate } from "@aletiq/translation";
import cx from "classnames";
import React from "react";
import { toDate, useTranslations } from "../../util";
import { DateWithSpaces } from "../date";
import styles from "./StatusTag.module.scss";
import { statusToText } from "./util";

export default function StatusTag(props: {
  children: "not_started" | "started" | "done";
  completionDate?: string | null;
  isDense?: boolean;
  isTaskLocked?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  hasShorthandText?: boolean;
}) {
  const {
    children,
    completionDate,
    isDisabled,
    isLoading,
    isDense = true,
    isTaskLocked,
    hasShorthandText,
  } = props;
  const tr = useTranslations();

  const taskButtonIcon =
    children === "done" && !hasShorthandText
      ? "tick"
      : isTaskLocked
      ? "lock"
      : isDisabled
      ? undefined
      : "caret-down";

  const taskButtonClassName = cx({
    [styles.small_button]: hasShorthandText,
    [styles.large_button]: !hasShorthandText,
  });

  const displayCompletionDate =
    children === "done" && completionDate && !hasShorthandText;

  const button = (
    <AnchorButtonSP
      isDisabled={isDisabled}
      rightIcon={taskButtonIcon}
      className={taskButtonClassName}
      view={isDisabled ? "smooth" : "filled"}
      color={COLOR_MAP[children]}
      loading={isLoading}
      isDense={isDense}
      isFill={!hasShorthandText}
    >
      {displayCompletionDate && completionDate ? (
        <DateWithSpaces date={completionDate} />
      ) : (
        statusToText(tr, children, hasShorthandText)
      )}
    </AnchorButtonSP>
  );

  return hasShorthandText ? (
    <Tooltip
      content={
        <>
          {children === "done" && completionDate
            ? tr.translate("taskstatus.done.on", {
                date: <FormattedDate value={toDate(completionDate)} />,
              })
            : statusToText(tr, children)}
        </>
      }
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
}

const COLOR_MAP: Record<"not_started" | "started" | "done", UIColor> = {
  not_started: "danger",
  started: "warning",
  done: "success",
};
