import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { userKeys } from "../../hooks/queries";

export default function useUpdateShareUser(userId: number) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { email: string; name: string }) => {
      await api.user.updateUserEmail(userId, values.email);
      await api.user.updateUserName(userId, values.name);
    },
    { onSuccess: () => queryClient.invalidateQueries(userKeys.all) }
  );
}
