import { Part, ProjectSpec, ProjectType } from "@aletiq/types";
import React, { useMemo, useState } from "react";
import {
  ANALYTICS_MUTATION_STATUS,
  ANALYTICS_PRODUCTS_CREATED,
  ANALYTICS_PRODUCT_COUNT,
  ANALYTICS_PRODUCT_TYPE,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../../analytics";
import { Dialog } from "../../../../components";
import { useHasVisibleScrollbar } from "../../../../hooks";
import { indexBy, useTranslations } from "../../../../util";
import FileActionTable from "../../../documents/common/FileActionTable";
import {
  useCreateAndLinkProjectsToParts,
  useProjectsByName,
} from "../../../project/hooks";
import styles from "./CreateAndLinkProjectsDialog.module.scss";
import PartProjectRow from "./PartProjectRow";

export type PartAction = "create" | "ignore";

export type ProjectActionSpec = ProjectSpec & {
  action: PartAction;
  partId: number;
};

export default function CreateAndLinkProjectsDialog(props: {
  onClose: () => void;
  projectType: ProjectType;
  parts: Part[];
}) {
  const { parts, projectType, onClose } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const partsById = useMemo(() => indexBy(parts, "id"), [parts]);
  const [specs, setSpecs] = useState<ProjectActionSpec[]>(
    parts.map((part) => ({
      partId: part.id,
      name: part.name,
      description: "",
      isTool: projectType === "tool",
      isArticle: projectType === "article",
      action: "create",
      definition: {
        name: "",
      },
    }))
  );

  const existingProjects = useProjectsByName(specs.map((p) => p.name));
  const { mutate: createAndLinkProjects, isLoading } =
    useCreateAndLinkProjectsToParts(projectType, partsById);

  const {
    verticalScrollbarSize,
    containerRef: tableBodyRef,
    isScrollbarVisible,
  } = useHasVisibleScrollbar();

  const handleEditName = (partId: number) => (name: string) => {
    setSpecs(
      specs.map((spec) => (spec.partId === partId ? { ...spec, name } : spec))
    );
  };

  const handleEditDescription = (partId: number) => (description: string) => {
    setSpecs(
      specs.map((spec) =>
        spec.partId === partId ? { ...spec, description } : spec
      )
    );
  };

  const handleSetAction = (action: PartAction, partId: number) => {
    setSpecs(
      specs.map((spec) => (spec.partId === partId ? { ...spec, action } : spec))
    );
  };

  const handleSubmit = () => {
    const createdSpecs = specs.filter(({ action }) => action !== "ignore");
    const specsById = indexBy(createdSpecs, "partId");
    createAndLinkProjects(specsById, {
      onSettled: (_, error) => {
        if (createdSpecs.length > 0) {
          analytics.track(ANALYTICS_PRODUCTS_CREATED, {
            [ANALYTICS_PRODUCT_COUNT]: createdSpecs.length,
            [ANALYTICS_PRODUCT_TYPE]: projectType,
            [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
          });
        }

        onClose?.();
      },
    });
  };

  // disable submit button if a project name is empty already exists
  const isSubmitDisabled = specs.some(
    (spec) =>
      spec.action !== "ignore" &&
      (spec.name === "" || existingProjects[spec.name] !== undefined)
  );

  return (
    <Dialog
      isOpen
      isFullPage
      onClose={onClose}
      icon={projectType === "tool" ? "wrench" : "projects"}
      title={tr.translateAsString("part.dialog.create-link-projects.title", {
        type: projectType,
      })}
      className={styles.dialog}
      disablePrimary={isSubmitDisabled}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
    >
      <p>
        {tr.translate("part.dialog.create-link-projects.description", {
          type: projectType,
        })}
      </p>
      <FileActionTable classname={styles.table_header}>
        <thead>
          <tr>
            <th>
              {tr.translate("part.dialog.create-link-projects.project.name", {
                type: projectType,
              })}
            </th>
            <th className={styles.project_desc}>
              {tr.translate("part.dialog.create-link-projects.project.desc", {
                type: projectType,
              })}
            </th>
            <th className={styles.action}>
              {tr.translate("part.dialog.create-link-projects.action")}
            </th>
            <th className={styles.part_name}>
              {tr.translate("part.dialog.create-link-projects.part")}
            </th>
            <th className={styles.already_linked}>
              {tr.translate("part.dialog.create-link-projects.already-linked")}
            </th>
            {isScrollbarVisible && (
              <th
                style={{
                  width: `${verticalScrollbarSize}px`,
                  borderLeft: "none",
                }}
              />
            )}
          </tr>
        </thead>
      </FileActionTable>
      <div className={styles.table_wrapper} ref={tableBodyRef}>
        <FileActionTable classname={styles.table}>
          <tbody>
            {specs.map((spec) => (
              <PartProjectRow
                key={spec.partId}
                spec={spec}
                part={partsById[spec.partId]}
                projectType={projectType}
                onEditName={handleEditName}
                onEditDescription={handleEditDescription}
                onEditAction={handleSetAction}
                hasDuplicateName={existingProjects[spec.name] !== undefined}
              />
            ))}
          </tbody>
        </FileActionTable>
      </div>
    </Dialog>
  );
}
