import React, { ReactNode } from "react";
import styles from "./PanelDetails.module.scss";

export default function Panel(props: { children: ReactNode; width?: string }) {
  return (
    <div
      className={styles.panel}
      style={{
        height: "100%",
        width: props.width ?? "500px",
        flex: "none",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.children}
    </div>
  );
}
