import { AmplitudeClient } from "amplitude-js";
import { OverridedMixpanel } from "mixpanel-browser";

export type Analytics = {
  track: (event: string, data?: any) => void;
  identify: (userId: string, properties?: any) => void;
};

export const defaultAnalytics = (): Analytics => ({
  track: () => {},
  identify: () => {},
});

export const amplitudeAnalytics = (amplitude: AmplitudeClient): Analytics => ({
  track: (event: string, data?: any) => amplitude.logEvent(event, data),
  identify: (userId: string, properties?: any) => {
    amplitude.setUserId(userId);
    amplitude.setUserProperties(properties);
  },
});

export const mixpanelAnalytics = (mixpanel: OverridedMixpanel): Analytics => ({
  track: (event: string, data?: any) => mixpanel.track(event, data),
  identify: (userId: string, properties?: any) => {
    mixpanel.identify(userId);
    mixpanel.people.set(properties);
  },
});
