import { ProjectType } from "@aletiq/types";
import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactNode, useState } from "react";
import { rootUrl } from "../../config";
import DocumentDetails from "../../features/documents/DocumentDetails";
import PassportDetails from "../../features/passport/PassportDetails";
import PartDetails from "../../features/pdm/PartDetails";
import { ProjectCreationDialog } from "../../features/project/dialogs/ProjectCreationDialog";
import { ProcessCreationDialog } from "../../features/tasks/dialogs";
import WorkflowDetails from "../../features/tasks/WorkflowDetails";
import { useDetailsPanel, useNavigateTo } from "../../hooks";
import NavBar from "../nav-bar/NavBar";
import styles from "./Page.module.scss";

type Dialog =
  | { dialog: "create-workflow" }
  | { dialog: "create-project"; projectType: ProjectType };

export default function Page(props: { children?: ReactNode }) {
  const { children } = props;
  const auth0 = useAuth0();
  const handleClosePanel = useNavigateTo({ panel: null });

  const [dialog, setDialog] = useState<Dialog>();

  const details = useDetailsPanel();

  const handleCloseDialog = () => setDialog(undefined);

  let detailsPanel;
  switch (details?.type) {
    case "passport": {
      detailsPanel = (
        <PassportDetails
          passportId={details.id}
          onClose={handleClosePanel}
          tab={details.tab}
        />
      );
      break;
    }
    case "workflow": {
      detailsPanel = (
        <WorkflowDetails
          workflowId={details.id}
          onClose={handleClosePanel}
          tab={details.tab}
        />
      );
      break;
    }
    case "part": {
      detailsPanel = (
        <PartDetails
          partId={details.id}
          onClose={handleClosePanel}
          tab={details.tab}
        />
      );
      break;
    }
    case "document": {
      detailsPanel = (
        <DocumentDetails
          documentId={details.id}
          onClose={handleClosePanel}
          tab={details.tab}
        />
      );
      break;
    }
  }

  return (
    <div className={styles.container}>
      <NavBar
        logout={() => auth0.logout({ returnTo: rootUrl })}
        onCreateProject={(projectType) =>
          setDialog({ dialog: "create-project", projectType })
        }
        onCreateWorkflow={() => setDialog({ dialog: "create-workflow" })}
      />
      {dialog?.dialog === "create-project" && (
        <ProjectCreationDialog
          onClose={handleCloseDialog}
          type={dialog.projectType}
        />
      )}
      {dialog?.dialog === "create-workflow" && (
        <ProcessCreationDialog onClose={handleCloseDialog} />
      )}
      <div className={styles.content}>
        <div className={styles.main}>{children}</div>
        {detailsPanel && <div className={styles.panel}>{detailsPanel}</div>}
      </div>
    </div>
  );
}
