import { Button, ButtonGroup, NumericInput } from "@aletiq/design-system";
import React, { useState } from "react";
import { readFloat, truncateFloat } from "../../util/";
import styles from "./EditableCell.module.scss";

export default function EditableNumberCell(props: {
  value: number;
  onConfirmNewValue: (newValue: number) => void;
  allowNumericCharactersOnly?: boolean;
  digits?: number;
}) {
  const {
    value,
    onConfirmNewValue,
    allowNumericCharactersOnly,
    digits = 4,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value.toString());

  const handleValueChange = (_: number, valueAsString: string) => {
    setEditedValue(valueAsString);
  };

  const handleSubmitNewValue = () => {
    let numberEditedValue = readFloat(editedValue);
    if (numberEditedValue !== value && numberEditedValue !== null) {
      numberEditedValue = truncateFloat(numberEditedValue, digits);
      onConfirmNewValue(numberEditedValue);
    }
    setIsEditing(false);
  };

  const handleToggleEdition = () => {
    setIsEditing(!isEditing);
  };

  return (
    <>
      {isEditing ? (
        <div className={styles.editable}>
          <NumericInput
            allowNumericCharactersOnly={allowNumericCharactersOnly}
            autoFocus
            value={editedValue}
            className={styles.input}
            onValueChange={handleValueChange}
            isFill
            isDense
          />
          <ButtonGroup>
            <Button
              isDense
              intent="success"
              icon="tick"
              onClick={handleSubmitNewValue}
            />
            <Button isDense icon="cross" onClick={handleToggleEdition} />
          </ButtonGroup>
        </div>
      ) : (
        <>
          {value}
          <Button
            isDense
            intent="array_primary"
            icon="edit"
            className={styles.hover_button}
            onClick={handleToggleEdition}
          />
        </>
      )}
    </>
  );
}
