import { Field } from "@aletiq/design-system";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../app/useApi";
import { Dialog, Input, Select } from "../../components";
import { adminKeys } from "./hooks/queries";
export default function CreateUserDialog(props: {
  onClose: () => void;
  tenant: number;
}) {
  const { tenant, onClose } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const api = useApi();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    () =>
      api.http.post(
        `/admin/users?tenant=${tenant}`,
        JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          displayName: firstName + " " + lastName,
          role: role,
          email: email,
          password: password,
        })
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries(adminKeys.allUsers());
        onClose();
      },
    }
  );
  return (
    <Dialog
      isOpen
      onClose={onClose}
      title="Create a new User"
      onPrimaryClick={() => mutate()}
      submitting={isLoading}
    >
      <Field label="Firstname">
        <Input value={firstName} onChange={setFirstName} />
      </Field>
      <Field label="Lastname">
        <Input value={lastName} onChange={setLastName} />
      </Field>
      <Field label="Email">
        <Input type="email" value={email} onChange={setEmail} />
      </Field>
      <Field label="Password">
        <Input type="password" value={password} onChange={setPassword} />
      </Field>
      <Field label="Role">
        <Select
          isDense
          items={[
            { key: "administrator", text: "administrator" },
            { key: "manager", text: "manager" },
            { key: "viewer", text: "viewer" },
            { key: "shared", text: "shared" },
          ]}
          onItemSelect={(value) => setRole(value)}
        >
          {role ?? "role"}
        </Select>
      </Field>
    </Dialog>
  );
}
