import { PassportSpec, Project } from "@aletiq/types";
import React, { useState } from "react";
import { Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useCreatePassports } from "../../hooks";
import styles from "./PassportCreationDialog.module.scss";
import PassportCreationForm from "./PassportCreationForm";

export default function PassportCreationDialog(props: {
  product: Project;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { isOpen, onClose, product } = props;
  const tr = useTranslations();

  const { mutate: createPassports, isLoading } = useCreatePassports();

  const getDefaultPassportSpec = (): PassportSpec => {
    const defaultConfig: Record<number, number[]> = {};
    product.options.forEach((field) => {
      const value = field.values.find((value) => value.isDefault);
      if (value) defaultConfig[field.id] = [value.id];
      else defaultConfig[field.id] = [];
    });

    return {
      name: "",
      description: "",
      parent: product.id,
      parentDefinition: product.lastDefinition.index,
      config: defaultConfig,
      projectIteration: null,
      articleBill: null,
    };
  };

  const [passportSpec, setPassportSpec] = useState<PassportSpec>(
    getDefaultPassportSpec()
  );

  const handleSubmit = () => {
    createPassports(passportSpec, { onSettled: onClose });
  };

  return (
    <Dialog
      title={tr.translateAsString("dialog.new-passport.title", {
        product: product.name,
      })}
      icon="plus"
      isOpen={isOpen}
      onClose={onClose}
      onSecondaryClick={onClose}
      onPrimaryClick={handleSubmit}
      className={styles.dialog}
      submitting={isLoading}
    >
      <PassportCreationForm
        passportSpec={passportSpec}
        product={product}
        onEditSpec={setPassportSpec}
      />
    </Dialog>
  );
}
