import { IconName } from "@blueprintjs/core";
import React from "react";
import { ActionBar, PropertyCard } from "../../../components";
import { HandleClear, HandleFilter, useUsers } from "../../../hooks";
import { useTranslations } from "../../../util";
import { useParts } from "../../pdm/hooks";
import AttributeCards from "../AttributeCards";
import {
  CreationDatePropertyCard,
  LastUpdatePropertyCard,
  PropertyCardFilters,
} from "./common";
import { useCountPartsWithDescriptions } from "./hooks";
import { PropertyFilters } from "./types";

export default function PartAttributes(props: {
  filters: PropertyFilters;
  handleFilter: HandleFilter<PropertyFilters>;
  handleClear: HandleClear<PropertyFilters>;
}) {
  const { filters, handleClear, handleFilter } = props;
  const tr = useTranslations();

  const { data: parts } = useParts();
  const { data: users = [] } = useUsers();
  const { data: partsWithDescription = 0 } = useCountPartsWithDescriptions();
  const { data: lockedParts } = useParts({
    limit: 1,
    lockedOwners: users.map((u) => u.id),
  });

  const partCount = parts?.count || 0;
  const lockedPartCount = lockedParts?.count || 0;
  const partPropertyUses: { count: number; icon: IconName }[] = [
    {
      count: partCount,
      icon: "cube",
    },
  ];

  return (
    <AttributeCards>
      <ActionBar>
        <PropertyCardFilters
          filters={filters}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
      </ActionBar>
      <PropertyCard
        type="enum"
        filters={filters}
        label={tr.translateAsString("admin.attribute.parts.format")}
        description={tr.translateAsString(
          "admin.attribute.parts.format.description"
        )}
        usedIn={partPropertyUses}
      />
      <PropertyCard
        type="user"
        filters={filters}
        label={tr.translateAsString("admin.attribute.parts.owner")}
        description={tr.translateAsString(
          "admin.attribute.parts.owner.description"
        )}
        usedIn={partPropertyUses}
      />
      <PropertyCard
        type="string"
        filters={filters}
        label={tr.translateAsString("generic.label.description")}
        description={tr.translateAsString("admin.attribute.parts.description")}
        usedIn={[{ count: partsWithDescription, icon: "cube" }]}
      />
      <PropertyCard
        type="user"
        filters={filters}
        label={tr.translateAsString("admin.attribute.parts.edition")}
        description={tr.translateAsString(
          "admin.attribute.parts.edition.description"
        )}
        usedIn={[{ count: lockedPartCount, icon: "cube" }]}
      />
      <CreationDatePropertyCard
        entity="part"
        filters={filters}
        usedIn={partPropertyUses}
      />
      <LastUpdatePropertyCard
        entity="part"
        filters={filters}
        usedIn={partPropertyUses}
      />
    </AttributeCards>
  );
}
