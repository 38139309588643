import React from "react";
import {
  ButtonSP,
  CreateTag,
  IgnoreTag,
  LinkTag,
  Menu,
  MenuItem,
  Popover2,
} from "../../../../components";
import { PartAction } from "./CreateAndLinkProjectsDialog";

export default function PartActionSelect(props: {
  action: PartAction;
  partId: number;
  onChange: (action: PartAction, partId: number) => void;
}) {
  const { action, partId, onChange } = props;

  const handleSetAction = (action: PartAction) => () =>
    onChange(action, partId);

  return (
    <Popover2
      fill
      intent="primary"
      position="bottom-right"
      content={
        <Menu isDense>
          <MenuItem
            isDense
            intent="primary"
            active={action === "create"}
            onClick={handleSetAction("create")}
            text={
              <>
                <CreateTag />
                {" & "}
                <LinkTag />
              </>
            }
          />
          <MenuItem
            isDense
            intent="primary"
            active={action === "ignore"}
            onClick={handleSetAction("ignore")}
            text={<IgnoreTag />}
          />
        </Menu>
      }
    >
      <ButtonSP
        isFill
        view="flat"
        color="primary"
        rightIcon="caret-down"
        isDense
      >
        {action === "create" ? (
          <>
            <CreateTag />
            {" & "}
            <LinkTag />
          </>
        ) : (
          <IgnoreTag />
        )}
      </ButtonSP>
    </Popover2>
  );
}
