import { OperationBill } from "@aletiq/types";
import React, { useState } from "react";
import { Dialog, DialogHint, Field, Input } from "../../../../components";
import { useNavigate } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import useCreateBill from "../hooks/useCreateBill";

export default function CreateBill(props: {
  projectId: number;
  branchId?: number;
  previousBills?: OperationBill[];
  onClose: () => void;
}) {
  const { projectId, onClose, previousBills = [], branchId } = props;
  const tr = useTranslations();

  const [value, setValue] = useState("");

  const { isLoading, mutate } = useCreateBill(projectId);
  const navigate = useNavigate();

  // last bill in this bill branch
  const lastBill = previousBills.find((b) => b.branch === branchId);

  const handleCreate = () => {
    mutate(
      {
        index: value,
        description: lastBill?.description || "",
        branch: branchId,
      },
      {
        onSuccess: (newBillId: number) => {
          navigate({
            path: `/project/${projectId}/operation-bills/${newBillId}`,
          });
          onClose();
        },
      }
    );
  };

  const isCreatingBranch = !branchId;
  const isNameUsedInBranch =
    !isCreatingBranch &&
    previousBills.some((b) => b.branch === branchId && b.index === value);

  return (
    <Dialog
      isOpen
      onClose={onClose}
      title={tr.translateAsString("project.operation-bill.new-draft.title", {
        type: isCreatingBranch ? "bill" : "index",
      })}
      icon={isCreatingBranch ? "git-branch" : "git-commit"}
      submitting={isLoading}
      disablePrimary={!value || value === "" || isNameUsedInBranch}
      onPrimaryClick={handleCreate}
    >
      <DialogHint>
        {tr.translate("project.operation-bill.new-draft.caption")}
      </DialogHint>
      <Field
        isMandatory
        label={tr.translate("project.operation-bill.bill-index.field-label")}
      >
        <Input
          fill
          removeClearButton
          placeholder={
            lastBill?.index
              ? tr.translateAsString(
                  "project.operation-bill.bill-index.field-label.placeholder",
                  { last: lastBill.index }
                )
              : ""
          }
          hasError={isNameUsedInBranch}
          errorHelperText={tr.translateAsString(
            "project.operation-bill.bill-index.field-label.warning"
          )}
          onChange={setValue}
          value={value}
          intent="primary"
        />
      </Field>
    </Dialog>
  );
}
