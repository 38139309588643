import { Icon } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { Tooltip } from "../../../components";
import { useTranslations } from "../../../util";
import styles from "./OperationItemsCount.module.scss";

export default function OperationItemsCount(props: {
  documentCount: number;
  toolCount: number;
  hasObsoleteDocs?: boolean;
}) {
  const { documentCount, toolCount, hasObsoleteDocs } = props;
  const tr = useTranslations();
  return (
    <div className={styles.counts}>
      <Tooltip
        position="top"
        isDisabled={!hasObsoleteDocs}
        content={tr.translate(
          "project.operation-bill.operation-document.obsolete"
        )}
      >
        <div
          className={classNames(
            styles.counts,
            hasObsoleteDocs && styles.document_count
          )}
        >
          <Icon icon="document" />
          {documentCount}
          {hasObsoleteDocs && <div className={styles.warning} />}
        </div>
      </Tooltip>

      <Icon icon="wrench" />
      {toolCount}
    </div>
  );
}
