import {
  Button,
  ButtonSP,
  Callout,
  Dialog,
  EditableText,
  Field,
  Input,
  ItemSelect,
  Radio,
  Select,
  Switch,
  TextArea,
} from "@aletiq/design-system";
import { ProjectField } from "@aletiq/types";
import React, { useState } from "react";
import { ListTable } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useUpdateProjectOptionField } from "../../hooks";
import useFieldState from "../../ProjectConfig/useFieldState";
import styles from "./EditConfigDialog.module.scss";
type ConfigType = "multi" | "single";

export default function EditConfigDialog(props: {
  projectId: number;
  configField: ProjectField;
  onClose: () => void;
}) {
  const { projectId, configField, onClose } = props;
  const tr = useTranslations();

  const [name, setName] = useState(configField.name);
  const [description, setDescription] = useState(configField.description);
  const [type, setType] = useState<ConfigType>(
    configField.allowMultiValues ? "multi" : "single"
  );
  const { values, onUpdate, updates } = useFieldState(configField.values);

  const { mutate: updateConfigField, isLoading } =
    useUpdateProjectOptionField();

  const disableSubmit =
    name === "" || values.some((value) => value.name === "");
  const removedMultipleValues =
    type === "single" && configField.allowMultiValues;
  const hasNewValues = values.some((value) => value.isNew);

  const configTypes: ItemSelect<ConfigType>[] = [
    {
      key: "single",
      text: tr.translateAsString("product.config.type.select", {
        type: "single",
      }),
    },
    {
      key: "multi",
      text: tr.translateAsString("product.config.type.select", {
        type: "multi",
      }),
    },
  ];

  const handleSubmit = () => {
    //compare new to initial values
    const finalUpdates = updates;
    if (name !== configField.name) {
      finalUpdates.push({ type: "name", value: name });
    }
    if (description !== configField.description) {
      finalUpdates.push({ type: "description", value: description });
    }

    const allowMultiValues = type === "multi";
    if (allowMultiValues !== configField.allowMultiValues) {
      finalUpdates.push({
        type: "allowMultiValues",
        value: allowMultiValues,
      });
    }
    updateConfigField(
      {
        projectId: projectId,
        fieldId: configField.id,
        updates: finalUpdates,
      },
      { onSettled: onClose }
    );
  };

  const handleUpdateValueName = (value: number, name: string) => {
    onUpdate({ type: "valueName", value, name });
  };

  const handleSetDefaultValue = (isDefault: boolean, valueId: number) => {
    //if option is already default, set default as none
    onUpdate({
      type: "default",
      value: isDefault ? null : valueId,
    });
  };

  const handleDisableEnableValue = (value: number) => {
    onUpdate({
      type: "deleteValue",
      value,
    });
  };

  const handleAddValue = () =>
    onUpdate({
      type: "addValue",
      value: { name: "", isDefault: false, isDeleted: false },
    });

  const handleDeleteNewValue = (value: number) => {
    onUpdate({
      type: "deleteValue",
      value,
    });
  };

  return (
    <Dialog
      isOpen
      icon="edit"
      title={tr.translateAsString("project.dialog.config.edit.title")}
      onPrimaryClick={handleSubmit}
      disablePrimary={disableSubmit}
      submitting={isLoading}
      onClose={onClose}
    >
      <div className={styles.flex}>
        <Field
          label={tr.translateAsString("generic.label.name")}
          className={styles.input}
        >
          <Input
            fill
            value={name}
            onChange={setName}
            placeholder={tr.translateAsString(
              "project.dialog.config.name.placeholder"
            )}
          />
        </Field>

        <Field
          label={tr.translateAsString("project.dialog.config.type")}
          className={styles.select}
        >
          <Select
            isFill
            intent="outlined"
            items={configTypes}
            onItemSelect={setType}
            activeItem={type}
          >
            {tr.translateAsString("product.config.type.select", { type })}
          </Select>
        </Field>
      </div>

      {removedMultipleValues && (
        <Callout
          intent="warning"
          icon="warning-sign"
          className={styles.callout}
        >
          {tr.translate("project.dialog.config.edit.warning")}
        </Callout>
      )}

      <Field label={tr.translateAsString("generic.label.description")}>
        <TextArea
          fill
          value={description}
          onChange={setDescription}
          placeholder={tr.translateAsString(
            "project.dialog.config.desc.placeholder"
          )}
        />
      </Field>

      <ListTable
        columns={
          <tr>
            <th className={styles.active}>
              {tr.translate("project.dialog.config.active")}
            </th>
            <th>{tr.translate("project.dialog.config.value")}</th>
            <th className={styles.default}>
              {tr.translate("project.dialog.config.default")}
            </th>
            {hasNewValues && <th className={styles.delete} />}
          </tr>
        }
      >
        {values.map((value) => (
          <tr key={value.id}>
            <td>
              <Switch
                isDense
                isDisabled={value.isNew}
                checked={!value.isDeleted}
                onClick={() => handleDisableEnableValue(value.id)}
              />
            </td>
            <td>
              <EditableText
                value={value.name}
                onChange={(newName) => handleUpdateValueName(value.id, newName)}
                placeholder={tr.translateAsString(
                  "project.dialog.config.value.name.placeholder"
                )}
                isFill
              />
            </td>
            <td>
              <Radio
                isDense
                checked={value.isDefault}
                onClick={() => handleSetDefaultValue(value.isDefault, value.id)}
              />
            </td>
            {hasNewValues && (
              <td>
                {value.isNew && (
                  <Button
                    isDense
                    icon="trash"
                    onClick={() => handleDeleteNewValue(value.id)}
                  />
                )}
              </td>
            )}
          </tr>
        ))}
      </ListTable>

      <ButtonSP
        icon="plus"
        view="flat"
        color="primary"
        onClick={handleAddValue}
      >
        {tr.translate("project.dialog.config.add-value")}
      </ButtonSP>
    </Dialog>
  );
}
