import { ProjectVersion } from "@aletiq/types";
import { RadioGroup } from "@blueprintjs/core";
import React, { Fragment } from "react";
import { Field, Radio, Select } from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./PassportVersionPicker.module.scss";

export type VersionChoice =
  | { option: "last_version" }
  | { option: "pick"; version: number | null };

export function resolveChoice(pick: VersionChoice, versions: ProjectVersion[]) {
  return pick.option === "pick" ? pick.version : versions[0]?.id;
}

export function PassportVersionPicker(props: {
  pick: VersionChoice;
  versions: ProjectVersion[];
  onChange: (choice: VersionChoice) => void;
}) {
  const { pick, versions, onChange } = props;
  const tr = useTranslations();

  const lastVersion = versions[0];

  const handleOptionChange = (option: "last_version" | "pick") => () => {
    const choice: VersionChoice | null =
      option === "last_version"
        ? { option: "last_version" }
        : option === "pick"
        ? { option: "pick", version: null }
        : null;

    if (!choice) {
      return;
    }

    onChange(choice);
  };

  const handleChangePick = (version: number) => {
    onChange({ option: "pick", version });
  };

  const versionsItems = versions.map((version) => ({
    key: version.id,
    text: version.version,
  }));

  const versionSelected = versions.find(
    (version) => pick.option === "pick" && version.id === pick.version
  );

  return (
    <Fragment>
      {lastVersion ? (
        <Fragment>
          <RadioGroup onChange={() => {}}>
            <Radio
              onClick={handleOptionChange("last_version")}
              checked={pick.option === "last_version"}
              label={tr.translateAsString(
                "passport.version.pick.version.radio.label.last",
                {
                  version: lastVersion?.version,
                }
              )}
              value="last_version"
              isDense
              className={styles.checkbox}
            />
            <Radio
              onClick={handleOptionChange("pick")}
              checked={pick.option === "pick"}
              label={tr.translateAsString(
                "passport.version.pick.version.radio.label.manual"
              )}
              isDense
              value="pick"
              className={styles.checkbox}
            />
          </RadioGroup>
          {pick.option === "pick" && (
            <Field
              label={tr.translate(
                "passport.version.pick.version.manual.selection"
              )}
              className={styles.versionPicker}
            >
              <Select
                filterable
                items={versionsItems}
                onItemSelect={handleChangePick}
                activeItem={pick.version ?? undefined}
                intent="outlined"
                isFill
              >
                {versionSelected?.version ??
                  tr.translateAsString("generic.label.none.f")}
              </Select>
            </Field>
          )}
        </Fragment>
      ) : (
        <p>{tr.translate("passport.version.pick.version.empty-state")}</p>
      )}
    </Fragment>
  );
}
