import { Button } from "@aletiq/design-system";
import { EntityId, EntityType, Process } from "@aletiq/types";
import React, { useState } from "react";
import { LinkEntitiesSelect } from "../../../../components";
import {
  sortByDateString,
  sortByString,
  useTranslations,
} from "../../../../util";
import { useShowDocumentPreview } from "../../../documents/hooks";
import { usePassports } from "../../../passport/hooks";
import { usePartsById } from "../../../pdm/hooks";
import { useProjectsById } from "../../../project/hooks";
import useDocumentsById from "../../../project/Operations/hooks/useDocumentsById";
import {
  useAddProcessRelation,
  useDeleteProcessRelation,
  useDeleteWorkflowRelations,
} from "../../hooks";
import WorkflowDocument from "./WorkflowDocument";
import styles from "./WorkflowEntities.module.scss";
import {
  WorkflowPart,
  WorkflowPassport,
  WorkflowProject,
} from "./WorkflowEntity";

export default function WorkflowEntities(props: {
  workflow: Process;
  disableEdit: boolean;
}) {
  const { workflow, disableEdit } = props;
  const tr = useTranslations();

  const [unfoldedDocs, setUnfoldedDocs] = useState<number[]>([]);

  const workflowDocsIds = getEntitiesByType(workflow.relations, "file");
  const workflowDocs = useDocumentsById(workflowDocsIds);
  const areAllDocsOpen = unfoldedDocs.length === workflowDocsIds.length;

  const processProjects = getEntitiesByType(workflow.relations, "project");
  const products = useProjectsById(processProjects);
  const sortedArticles = sortByString(products, (p) => p.name);

  const { data: allPassports = [] } = usePassports();
  const processPassports = getEntitiesByType(workflow.relations, "passport");
  const sortedPassports = allPassports.filter((p) =>
    processPassports.includes(p.id)
  );

  const workflowPartsIds = getEntitiesByType(workflow.relations, "part");
  const workflowParts = usePartsById(workflowPartsIds);

  //sort by type then by name
  const sortedByName = sortByString(workflowParts, (part) => part.name);
  const sortedParts = sortByDateString(sortedByName, (part) => part.type);

  const showDocumentPreview = useShowDocumentPreview();

  const { mutate: addProcessRelation } = useAddProcessRelation();
  const handleAddRelation = (entity: EntityId) =>
    addProcessRelation({ process: workflow.id, entity });

  const { mutate: deleteProcessRelation } = useDeleteProcessRelation();
  const handleDeleteRelation = (entity: EntityId) =>
    deleteProcessRelation({ process: workflow.id, entity });

  const { mutate: deleteAllProcessRelation } = useDeleteWorkflowRelations(
    workflow.id
  );

  const handleDetachAll = () => {
    deleteAllProcessRelation(workflow.relations);
  };

  const handleDetach = (type: EntityType, entity: { id: number }) => () => {
    handleDeleteRelation({ type, id: entity.id });
  };

  const handleToggleFoldDocument = (documentId: number) => () => {
    if (unfoldedDocs.includes(documentId)) {
      setUnfoldedDocs(unfoldedDocs.filter((id) => id !== documentId));
    } else {
      setUnfoldedDocs([...unfoldedDocs, documentId]);
    }
  };

  const handleToggleFoldAllDocuments = () => {
    setUnfoldedDocs(areAllDocsOpen ? [] : workflowDocsIds);
  };

  return (
    <div className={styles.tab}>
      <div className={styles.multi_select_wrapper}>
        <LinkEntitiesSelect
          disabled={disableEdit}
          entities={workflow.relations}
          onAddRelation={handleAddRelation}
          onDeleteRelation={handleDeleteRelation}
          isDense
          intent="primary"
          onResetRelation={handleDetachAll}
        />
      </div>

      {sortedArticles.length > 0 && (
        <div className={styles.entities}>
          <div className={styles.entityTitle}>
            {tr.translate("generic.label.products")}
          </div>
          {sortedArticles.map((project) => (
            <WorkflowProject
              key={`project-${project.id}`}
              project={project}
              onDetach={handleDetach("project", project)}
              disableEdit={disableEdit}
            />
          ))}
        </div>
      )}

      {processPassports.length > 0 && (
        <div className={styles.entities}>
          <div className={styles.entityTitle}>
            {tr.translate("generic.label.passports")}
          </div>
          {sortedPassports.map((passport) => (
            <WorkflowPassport
              key={`passport-${passport.id}`}
              passport={passport}
              onDetach={handleDetach("passport", passport)}
              disableEdit={disableEdit}
            />
          ))}
        </div>
      )}

      {workflowParts.length > 0 && (
        <div className={styles.entities}>
          <div className={styles.entityTitle}>
            {tr.translate("generic.label.parts")}
          </div>
          {sortedParts.map((part) => (
            <WorkflowPart
              key={`part-${part.id}}`}
              part={part}
              onDetach={handleDetach("part", part)}
              disableEdit={disableEdit}
            />
          ))}
        </div>
      )}

      {workflowDocsIds.length > 0 && (
        <div className={styles.entities}>
          <div className={styles.entityTitle}>
            <Button
              isDense
              icon={areAllDocsOpen ? "collapse-all" : "expand-all"}
              onClick={handleToggleFoldAllDocuments}
              className={styles.toggle_button}
            />
            {tr.translate("generic.label.documents")}
          </div>
          {workflowDocsIds.map((docId) => {
            const doc = workflowDocs[docId];

            if (!doc) {
              return null;
            }

            return (
              <WorkflowDocument
                key={`doc-${docId}`}
                document={doc}
                onDetach={handleDetach("file", { id: docId })}
                disableEdit={disableEdit}
                onShowPreview={showDocumentPreview}
                isFolded={!unfoldedDocs.includes(docId)}
                onToggleFold={handleToggleFoldDocument(docId)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

const getEntitiesByType = (entities: EntityId[], type: EntityType) =>
  entities.filter((entity) => entity.type === type).map((e) => e.id);
