import { AnchorButton, Tooltip } from "@aletiq/design-system";
import { Property } from "@aletiq/types";
import React, { useState } from "react";
import { PropertyCard } from "../../../../../components";
import { useHasPermission } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { useCountPropertyUses } from "../../hooks";
import { PropertyFilters } from "../../types";
import {
  ConfirmDeletePropertyDialog,
  EditPassportPropertyDialog,
} from "../Dialogs";
import PassportPropertyTarget from "./PassportPropertyTarget";

type DialogsName = "deleteAttribute" | "editAttribute";

export default function PassportPropertyCard(props: {
  property: Property;
  filters?: PropertyFilters;
}) {
  const { property, filters } = props;
  const tr = useTranslations();

  const [showDialog, setShowDialog] = useState<DialogsName | undefined>();

  const { data: propertyUseCount = 0 } = useCountPropertyUses(
    "passport",
    property.name
  );
  const canEditProperty = useHasPermission("update:custom-attributes");
  const canRemoveProperty = useHasPermission("update:custom-attributes");

  const handleOpenedDialog = (type?: DialogsName) => () => {
    setShowDialog(type);
  };

  return (
    <>
      <PropertyCard
        label={property.label}
        type={property.type}
        description={property.description}
        creator={property.creator}
        onEdit={handleOpenedDialog("editAttribute")}
        canEdit={canEditProperty}
        filters={filters}
        target={<PassportPropertyTarget target={property.group} />}
        usedIn={[{ count: propertyUseCount, icon: "id-number" }]}
        additionalButton={
          <Tooltip
            position="top"
            content={tr.translate("admin.attribute.passports.action.disable")}
          >
            <AnchorButton
              isDense
              icon="disable"
              isDisabled={!canRemoveProperty}
              onClick={handleOpenedDialog("deleteAttribute")}
            />
          </Tooltip>
        }
      />
      {/* Dialogs */}
      {showDialog === "editAttribute" && (
        <EditPassportPropertyDialog
          property={property}
          onClose={handleOpenedDialog(undefined)}
        />
      )}
      {showDialog === "deleteAttribute" && (
        <ConfirmDeletePropertyDialog
          onClose={handleOpenedDialog(undefined)}
          propertyName={property.name}
          objectType="passport"
        />
      )}
    </>
  );
}
