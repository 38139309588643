import { RevisionState } from ".";

export function previousState(current: RevisionState): RevisionState | null {
  switch (current) {
    case "draft":
      return null;
    case "approved":
      return "draft";
    case "obsolete":
      return "approved";
  }
}
