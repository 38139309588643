import { SearchInput, Tooltip } from "@aletiq/design-system";
import {
  CustomFilterParams,
  DateRangeParams,
  PropertyObject,
  SearchQueryParams,
} from "@aletiq/types";
import React from "react";
import {
  useAletiqPropertiesDefaultDisplay,
  useProperties,
} from "../../features/administration/Attributes/hooks";
import { HandleFilter, useCustomFilters } from "../../hooks";
import { useTranslations } from "../../util";
import CustomPropertyFilter from "./CustomPropertyFilter";
import DateFilter from "./DateFilter";

export default function DefaultFilters<
  T extends SearchQueryParams & DateRangeParams & CustomFilterParams
>(props: {
  objectType: PropertyObject;
  filters: T;
  handleFilter: HandleFilter<T>;
  autoFocusSearch?: boolean;
}) {
  const {
    objectType,
    filters: objectFilters,
    handleFilter,
    autoFocusSearch,
  } = props;

  const tr = useTranslations();

  const { data: customProperties = [] } = useProperties(objectType);
  const { data: aletiqProperties = [] } =
    useAletiqPropertiesDefaultDisplay(objectType);

  const { setCustomFilter, clearCustomFilter } = useCustomFilters(
    objectFilters,
    handleFilter
  );

  return (
    <>
      <Tooltip
        content={tr.translate("generic.action.search")}
        position="top-left"
      >
        <SearchInput
          isDense
          autoFocus={autoFocusSearch}
          value={objectFilters.search ?? ""}
          onChange={handleFilter("search")}
        />
      </Tooltip>
      {customProperties.map((property) =>
        property.displayByDefault ? (
          <CustomPropertyFilter
            key={property.id}
            property={property}
            value={objectFilters.filters?.find((p) => p.id === property.id)}
            onClear={clearCustomFilter}
            onSetValue={setCustomFilter}
          />
        ) : null
      )}

      {aletiqProperties.some(
        (p) => p.name === "created_at" && p.displayByDefault
      ) && (
        <DateFilter
          selected={objectFilters?.createdAt}
          onSelect={handleFilter("createdAt")}
          label={tr.translateAsString("generic.label.creation-date.title")}
        />
      )}

      {aletiqProperties.some(
        (p) => p.name === "updated_at" && p.displayByDefault
      ) && (
        <DateFilter
          selected={objectFilters?.updatedAt}
          onSelect={handleFilter("updatedAt")}
          label={tr.translateAsString("generic.label.last-update.title")}
        />
      )}
    </>
  );
}
