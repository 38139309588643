import React, { useState } from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  SearchInput,
  Tooltip,
} from "../../../../components";
import { useToaster, useUserGroups } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useCreateUserGroup } from "../hooks";
import GroupListItem from "./GroupListItem";

export default function GroupList() {
  const [search, setSearch] = useState("");
  const [foldState, setfoldState] = useState<number[]>([]);

  const tr = useTranslations();
  const toaster = useToaster();

  const { data: userGroups = [] } = useUserGroups();

  const { mutate: createUserGroup, isLoading: isCreatingGroup } =
    useCreateUserGroup();

  const isAllFolded = foldState.length >= userGroups.length;

  const handleToggleFoldAll = () => {
    setfoldState(isAllFolded ? [] : userGroups.map((w) => w.id));
  };

  const handleToggleFoldGroup = (groupId: number) => () => {
    const isFolded = foldState.includes(groupId);
    if (isFolded) {
      setfoldState(foldState.filter((id) => id !== groupId));
    } else {
      setfoldState([...foldState, groupId]);
    }
  };

  const handleCreateNewGroup = () => {
    createUserGroup(
      { name: "", users: [] },
      {
        onError: (err) => {
          toaster.show({
            intent: "danger",
            icon: "warning-sign",
            message: tr.translate("admin.groups.toaster.error.group-creation", {
              errorMsg: err.message,
            }),
          });
        },
      }
    );
  };

  const groupNames = userGroups.map((g) => g.name);

  return (
    <>
      <ActionBar>
        <Button
          isDense
          icon={isAllFolded ? "expand-all" : "collapse-all"}
          onClick={handleToggleFoldAll}
        />
        <Tooltip
          content={tr.translateAsString("admin.groups.search")}
          position="top-left"
        >
          <SearchInput isDense value={search} onChange={setSearch} />
        </Tooltip>
        <AlignRight />
        <Button
          isDense
          icon="plus"
          intent="secondary"
          onClick={handleCreateNewGroup}
          isLoading={isCreatingGroup}
        >
          {tr.translate("admin.groups.button.new")}
        </Button>
      </ActionBar>
      {userGroups.map((group) => {
        const isFolded = foldState.includes(group.id);
        return (
          <GroupListItem
            key={group.id}
            group={group}
            groupNames={groupNames}
            isFolded={isFolded}
            search={search}
            onToggleFold={handleToggleFoldGroup(group.id)}
          />
        );
      })}
    </>
  );
}
