import React from "react";
import { CustomIcon, CustomIconProps } from "./CustomIcon";

const paths = [
  "M15 3m-3.5 6a2.5 2.5 0 00-2.45 2h-4.1a2.5 2.5 0 100 1h4.1a2.5 2.5 0 102.45-3zM12.89 8.11m1.02-4.61l1.79-1.79c.19-.18.3-.43.3-.71a1.003 1.003 0 00-1.71-.71l-1.79 1.8L10.71.3A.965.965 0 0010 0a1.003 1.003 0 00-.71 1.71l1.79 1.79-1.79 1.79a1.003 1.003 0 001.42 1.42l1.79-1.79 1.79 1.79a1.003 1.003 0 001.42-1.42l-1.8-1.79z",
];

export default function RemoveLinkIcon(props: CustomIconProps) {
  return <CustomIcon paths={paths} iconName="remove-link" {...props} />;
}
