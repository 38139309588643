import { DialogBase } from "@aletiq/design-system";
import React from "react";
import styles from "./PreviewOverlay.module.scss";

export default function VideoFileOverlay(props: {
  content: string;
  onClose: () => void;
}) {
  const { content, onClose } = props;

  return (
    <DialogBase className={styles.dialog} isOpen onClose={onClose}>
      <pre className={styles.dialog_contents}>
        <video src={content} controls />
      </pre>
    </DialogBase>
  );
}
