import { ProjectDefinition } from "@aletiq/types";
import React, { useState } from "react";
import {
  ActionBar,
  AlignRight,
  EditColumnButton,
  SearchInput,
} from "../../../../components";
import { ColumnOptionConfig } from "../../../../hooks";
import {
  AddNomenclatureComponentsDialog,
  EditProductBomDialog,
  LinkNomenclatureDialog,
} from "../../dialogs";
import { ResolvedBOMEntry } from "../hooks/useProjectExpandableTree";
import { AddBomComponentsButton, LinkPartBomButton } from "./buttons";
import EditProductBomButton from "./buttons/EditProductBomButton";
import ProjectComponentSelect, {
  ProjectComponentsSelection,
} from "./ProjectComponentSelect";
import SelectProjectComponentsColumns from "./SelectProjectComponentsColumns";
import { ProductComponentsColumn } from "./useProjectComponentsColumns";

type ProjectComponentsActionBarDialog =
  | "addComponents"
  | "editProductBom"
  | "linkBom";

export default function ProjectComponentsActionBar(props: {
  search?: string;
  selected?: ProjectComponentsSelection;
  onSearchChange?: (search: string) => void;
  onSelectChange?: (select: ProjectComponentsSelection) => void;
  projectId: number;
  definitionIdx: number;
  activeDefinition?: ProjectDefinition;
  components: ResolvedBOMEntry[];
  hasAddComponents?: boolean;
  hasEditProductBom?: boolean;
  hasLinkBomButton?: boolean;
  isAddComponentsDisabled?: boolean;
  isEditProductBomDisabled?: boolean;
  columnConfig: ColumnOptionConfig<ProductComponentsColumn>;
  onToggleColumn: (column: ProductComponentsColumn | number) => void;
}) {
  const {
    search,
    selected,
    onSearchChange,
    onSelectChange,
    projectId,
    definitionIdx,
    activeDefinition,
    components,
    hasAddComponents,
    hasEditProductBom,
    hasLinkBomButton,
    isAddComponentsDisabled,
    isEditProductBomDisabled,
    columnConfig,
    onToggleColumn,
  } = props;

  const [dialog, setDialog] = useState<ProjectComponentsActionBarDialog>();

  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  const handleOpenDialog =
    (dialogType: ProjectComponentsActionBarDialog) => () => {
      setDialog(dialogType);
    };

  return (
    <ActionBar>
      {selected && onSelectChange && (
        <ProjectComponentSelect
          selected={selected}
          onSelectChange={onSelectChange}
        />
      )}

      {search !== undefined && onSearchChange !== undefined && (
        <SearchInput
          isDense
          intent="primary"
          value={search}
          onChange={onSearchChange}
        />
      )}
      <AlignRight />

      {selected === "uses" && (
        <>
          <SelectProjectComponentsColumns
            columnConfig={columnConfig}
            onToggleColumn={onToggleColumn}
          >
            <EditColumnButton />
          </SelectProjectComponentsColumns>

          {hasAddComponents && (
            <AddBomComponentsButton
              isDisabled={isAddComponentsDisabled}
              onClick={handleOpenDialog("addComponents")}
            />
          )}
          {hasEditProductBom && (
            <EditProductBomButton
              isDisabled={isEditProductBomDisabled}
              onClick={handleOpenDialog("editProductBom")}
            />
          )}

          {hasLinkBomButton && (
            <LinkPartBomButton onClick={handleOpenDialog("linkBom")} />
          )}
        </>
      )}
      {dialog === "addComponents" && (
        <AddNomenclatureComponentsDialog
          projectId={projectId}
          definitionIdx={definitionIdx}
          projectComponents={components}
          onClose={handleCloseDialog}
        />
      )}

      {dialog === "editProductBom" && (
        <EditProductBomDialog
          projectId={projectId}
          definitionIdx={definitionIdx}
          onClose={handleCloseDialog}
        />
      )}

      {dialog === "linkBom" && activeDefinition && (
        <LinkNomenclatureDialog
          onClose={handleCloseDialog}
          projectId={projectId}
          definitionIdx={activeDefinition.index}
        />
      )}
    </ActionBar>
  );
}
