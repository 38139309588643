import React from "react";
import { Dialog, PartNameLink, ProjectNameLink } from "../../../../components";
import { useTranslations } from "../../../../util";
import { PartProductBomMutation } from "./partProjectBomUtils";

export function AssignNomenclatureDialog(props: {
  assignation: PartProductBomMutation;
  onAssign: () => void;
  onClose: () => void;
}) {
  const { assignation, onAssign, onClose } = props;

  const tr = useTranslations();

  const handleSubmit = () => onAssign();

  return (
    <Dialog
      isOpen
      icon="new-link"
      title={tr.translateAsString("project.actions.assign.bom.title")}
      onPrimaryClick={handleSubmit}
      onClose={onClose}
    >
      <p>
        {tr.translate("project.actions.assign.bom.description", {
          part: <PartNameLink hasIcon partId={assignation.part.part} />,
          product: (
            <ProjectNameLink
              hasIcon
              projectId={assignation.product.productId}
            />
          ),
        })}
      </p>

      <p>{tr.translate("project.actions.assign.bom.details")}</p>
    </Dialog>
  );
}
