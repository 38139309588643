import { Document, DocumentRevisionSpec } from "@aletiq/types";
import React from "react";
import {
  ANALYTICS_DOCUMENTS_COUNT,
  ANALYTICS_DOCUMENTS_UPDATED,
  ANALYTICS_MUTATION_STATUS,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../analytics";
import { DeleteAlert } from "../../../components";
import { useTranslations } from "../../../util";
import ShareDocumentDialog from "../dialogs/ShareDocumentDialog";
import UploadRevisionDialog from "../dialogs/UploadRevisionDialog";
import {
  useDeleteDocument,
  useUnlinkDocument,
  useUpdateDocument,
} from "../hooks";
import { DialogState } from "./DialogStateHandling";
import LinkedProjectsCallout from "./LinkedProjectsCallout";

export default function DocumentDetailsDialogs(props: {
  dialog: DialogState;
  document: Document;
  onCloseDialog: () => void;
  projectId?: number;
}) {
  const { document, dialog, onCloseDialog, projectId } = props;

  const tr = useTranslations();
  const analytics = useAnalytics();

  const { mutate: updateDocument } = useUpdateDocument(document.id);

  const { mutate: deleteDocument, isLoading: isDeleting } = useDeleteDocument(
    document.id
  );
  const { mutate: unlinkProjectFromDocument } = useUnlinkDocument(document.id);

  const handleDeleteDocument = () => {
    deleteDocument(undefined, {
      onSuccess: onCloseDialog,
    });
  };

  const handleUpdateDocument = (
    revision: DocumentRevisionSpec | null,
    file: File
  ) => {
    updateDocument(
      { file, revision },
      {
        onSettled: (_, error) => {
          analytics.track(ANALYTICS_DOCUMENTS_UPDATED, {
            [ANALYTICS_DOCUMENTS_COUNT]: 1,
            [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
          });
        },
      }
    );
  };

  const handleUnlinkDocument = () => {
    projectId && unlinkProjectFromDocument(projectId);
  };

  switch (dialog.type) {
    case "delete-document":
      return (
        <DeleteAlert
          elementName={document.name}
          children={<LinkedProjectsCallout documentId={document.id} />}
          onDelete={handleDeleteDocument}
          onClose={onCloseDialog}
          isLoading={isDeleting}
        />
      );

    case "unlink-document":
      return (
        <DeleteAlert
          elementName={document.name}
          message={tr.translateAsString("storage.dialog.unlink")}
          onDelete={handleUnlinkDocument}
          onClose={onCloseDialog}
        />
      );

    case "upload-revision":
      return (
        <UploadRevisionDialog
          isOpen
          documentId={document.id}
          onDiscard={onCloseDialog}
          onSave={handleUpdateDocument}
        />
      );

    case "share-document":
      return (
        <ShareDocumentDialog document={document} onClose={onCloseDialog} />
      );

    default:
      return null;
  }
}
