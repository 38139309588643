import React from "react";
import { Dialog, DialogHint, StateTagTransition } from "../../../components";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import {
  useProjectDefinitionDocuments,
  useValidateProjectDefinition,
} from "../hooks";
import { checkProductDefinitionDocuments } from "./ApproveNomenclatureDialog/useComponentsToApprove";
import ProjectDefinitionValidationErrorMessage from "./ProjectDefinitionValidationErrorMessage";

export default function ValidateProjectDefinitionDialog(props: {
  projectId: number;
  definitionIdx: number;
  isDraftIteration?: boolean;
  onClose: () => void;
}) {
  const { projectId, definitionIdx, onClose, isDraftIteration } = props;
  const tr = useTranslations();
  const toaster = useToaster();

  const { data: documents = [] } = useProjectDefinitionDocuments(
    projectId,
    definitionIdx
  );

  const {
    draftDocuments,
    obsoleteDocuments,
    hasDraftDocuments,
    hasObsoleteDocuments,
  } = checkProductDefinitionDocuments(documents);

  const { isLoading, mutate: validateProjectDefinition } =
    useValidateProjectDefinition(projectId, definitionIdx);

  const handleValidate = () => {
    if (hasDraftDocuments || hasObsoleteDocuments || isDraftIteration) {
      toaster.show({
        intent: "danger",
        icon: "warning-sign",
        timeout: 2000,
        message: (
          <ProjectDefinitionValidationErrorMessage
            draftDocumentDefinitions={draftDocuments}
            obsoleteDocumentDefinitions={obsoleteDocuments}
            isDraftIteration={isDraftIteration}
          />
        ),
      });
      onClose();
      return;
    }

    validateProjectDefinition(undefined, { onSuccess: onClose });
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      title={tr.translateAsString("project.definition.validate.title")}
      icon="tick"
      submitting={isLoading}
      primaryText={tr.translateAsString("generic.action.save")}
      onPrimaryClick={handleValidate}
    >
      <DialogHint>
        {tr.translate("project.definition.validate.caption")}
      </DialogHint>

      <StateTagTransition initialProps={{ state: "draft" }} />
    </Dialog>
  );
}
