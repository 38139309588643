import { Document } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../util";
import documentLinkProps from "../../link/props/documentLinkProps";
import ActivityLink from "./ActivityLink";

export default function ActivityDocumentLink(props: {
  document: Document;
  onClick?: () => void;
}) {
  const { document, onClick } = props;
  const tr = useTranslations();

  const linkProps = documentLinkProps(tr, document);

  return <ActivityLink {...linkProps} onClick={onClick} />;
}
