import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CopyProjectPage from "../features/project/CopyProjectPage";
import OperationDetailsPage from "../features/project/Operations/OperationDetailsPage";
import ProjectOperationBillPage from "../features/project/Operations/ProjectOperationBillPage";
import ProjectArticles from "../features/project/ProjectArticles";
import ProjectConfig from "../features/project/ProjectConfig";
import ProjectDocuments from "../features/project/ProjectDocuments";
import ProjectHome from "../features/project/ProjectHome";
import ProjectNomenclature from "../features/project/ProjectNomenclature";
import ProjectParts from "../features/project/ProjectParts";
import ProjectPassports from "../features/project/ProjectPassports";
import ProjectTooling from "../features/project/ProjectTooling";
import ProjectWorkflows from "../features/project/ProjectWorkflows";
import NewVersionPage from "../features/project/Version/NewVersion/NewVersionPage";
import ProjectVersions from "../features/project/Version/ProjectVersions";
import VersionDetailsPage from "../features/project/Version/VersionDetails/VersionDetailsPage";

export function ProjectRoutes() {
  const match = useRouteMatch({
    path: "/project/:activeProject",
  }) as { params?: { activeProject?: string } } | null;

  const projectId = parseInt(match?.params?.activeProject ?? "0", 10);
  const baseUrl = `/project/${projectId}`;

  return (
    <Switch>
      <Route exact path={baseUrl}>
        <ProjectHome projectId={projectId} />
      </Route>
      <Route exact path={baseUrl + "/copy"}>
        <CopyProjectPage projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/workflows"}>
        <ProjectWorkflows projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/cad"} exact>
        <ProjectParts projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/versions"} exact>
        <ProjectVersions projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/versions/new"}>
        <NewVersionPage projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/versions/:version"}>
        <VersionDetailsPage projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/documents"}>
        <ProjectDocuments projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/nomenclature"} exact>
        <ProjectNomenclature projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/nomenclature/:definition"} exact>
        <ProjectNomenclature projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/operation-bills"} exact>
        <ProjectOperationBillPage projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/operation-bills/:bill"} exact>
        <ProjectOperationBillPage projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/operation-bills/:bill/operations/:operation"}>
        <OperationDetailsPage projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/config"}>
        <ProjectConfig projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/tooling"}>
        <ProjectTooling projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/articles"}>
        <ProjectArticles projectId={projectId} />
      </Route>
      <Route path={baseUrl + "/passports"}>
        <ProjectPassports projectId={projectId} />
      </Route>
    </Switch>
  );
}
