import { RevisionState } from "@aletiq/types";
import React from "react";
import { nextState, previousState } from "../../util";
import ChangeRevisionMenuPopover from "./ChangeRevisionMenuPopover";
import ChangeRevisionPopover from "./ChangeRevisionPopover";
import { ChangeRevisionHandler, ObjectType } from "./types";

export default function ChangeRevisionAdminButton(props: {
  onChange: ChangeRevisionHandler;
  state: RevisionState;
  objectType: ObjectType;
  isPublished?: boolean;
}) {
  const { onChange, state, objectType, isPublished } = props;

  const previous = previousState(state);
  const next = nextState(state);

  if (!previous || !next) {
    const final = (next ?? previous)!;
    return (
      <ChangeRevisionPopover
        isPublished={isPublished}
        initial={{ state }}
        final={{ state: final }}
        onConfirm={onChange[final]}
        objectType={objectType}
      />
    );
  }

  return (
    <ChangeRevisionMenuPopover
      isPublished={isPublished}
      state={state}
      next={next}
      previous={previous}
      objectType={objectType}
      onChange={onChange}
    />
  );
}
