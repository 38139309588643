import { ProjectType } from "@aletiq/types";
import React from "react";
import { ColumnOption, SelectColumns } from "../../../components";
import { ColumnOptionConfig, useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { useProperties } from "../../administration/Attributes/hooks";
import { ProductColumn } from "../hooks/useProductColumns";

export default function SelectProductColumns(props: {
  columnConfig: ColumnOptionConfig<ProductColumn>;
  projectType?: ProjectType;
  onToggleColumn: (column: ProductColumn) => void;
  children: React.ReactNode;
}) {
  const { columnConfig, projectType, onToggleColumn, children } = props;
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("product");
  const canEditDefaultColumns = useHasPermission(
    "update:default-property-display"
  );

  const filteredProperties = properties.filter(
    (prop) => prop.group === "all" || prop.group === projectType
  );

  const columnOptions: ColumnOption<ProductColumn>[] = [
    {
      id: "name",
      label: tr.translate("generic.label.name"),
      isMandatory: true,
    },
    {
      id: "description",
      label: tr.translate("generic.label.description"),
    },
    {
      id: "createdAt",
      label: tr.translate("generic.label.creation-date.title"),
    },
    {
      id: "updatedAt",
      label: tr.translate("generic.label.last-update.title"),
    },
    ...filteredProperties,
  ];

  return (
    <SelectColumns
      config={columnConfig}
      options={columnOptions}
      onToggleColumn={onToggleColumn}
      canEditDefaultColumns={canEditDefaultColumns}
      objectType="product"
    >
      {children}
    </SelectColumns>
  );
}
