import React, { ReactElement, ReactNode } from "react";
import { useSetPageTitle } from "../../hooks";
import styles from "./PageContents.module.scss";

export default function PageContents(props: {
  leftPanel?: ReactElement;
  children?: ReactNode;
  domTitle?: string;
}) {
  const { leftPanel, children, domTitle = "" } = props;

  useSetPageTitle(domTitle);

  return (
    <div className={styles.content}>
      {leftPanel && <div className={styles.panel}>{leftPanel}</div>}
      <div className={styles.main}>{children}</div>
    </div>
  );
}
