import { Diff } from ".";

export function someChanges<K extends keyof any, T>(
  collection: Record<K, Diff<T>>
) {
  for (const key in collection) {
    if (collection[key].status !== "unchanged") {
      return true;
    }
  }
}
