import { HighlightedText } from "@aletiq/design-system";
import { Project, ProjectVersion } from "@aletiq/types";
import { MenuDivider } from "@blueprintjs/core";
import React, { useState } from "react";
import { useQueries } from "react-query";
import useApi from "../../app/useApi";
import {
  projectKeys,
  projectQueries,
} from "../../features/project/hooks/queries";
import { toggle, useTranslations } from "../../util";
import styles from "./Filter.module.scss";
import FilterMenu from "./FilterMenu";
import FilterMenuItem from "./FilterMenuItem";
import FilterTemplate from "./FilterTemplate";

type ProjectVersionMap = Record<number, ProjectVersion[]>;
type ProjectMap = Record<number, Project>;

export default function ProjectVersionFilter(props: {
  disabled?: boolean;
  selected?: number[];
  projects?: number[];
  noProjectVersionSelected: boolean;
  onSelect: (values: number[]) => void;
  onSelectNoProjectVersion: (value?: boolean) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const {
    disabled,
    selected = [],
    noProjectVersionSelected,
    projects = [],
    onClear,
    onSelectNoProjectVersion,
    onSelect,
  } = props;
  const [search, setSearch] = useState("");
  const api = useApi();

  const projectsDetails = useQueries(
    projects.map((id) => projectQueries.byId(api, id))
  );

  const projectById = projectsDetails.reduce((acc: ProjectMap, res) => {
    if (res.data === undefined) {
      return (acc = []);
    }

    acc[res.data.id] = res.data;
    return acc;
  }, {} as ProjectMap);

  const projectsVersionQuery = useQueries(
    projects.map((id) => ({
      queryKey: projectKeys.allVersions(id),
      queryFn: () => api.project.getProjectVersions(id),
    }))
  );

  const projectVersions = projectsVersionQuery.reduce(
    (acc: ProjectVersionMap, res) => {
      if (res.data === undefined) {
        return acc;
      }
      if (res.data.length > 0) {
        const projectId = res.data[0].project;
        acc[projectId] = res.data;
      }
      return acc;
    },
    {} as ProjectVersionMap
  );

  const handleSelect = (value: number) => () =>
    onSelect(toggle(selected, value));

  const handleSelectEmpty = () => {
    onSelectNoProjectVersion(!noProjectVersionSelected);
  };

  const filteredProjectVersion = Object.keys(projectVersions).reduce(
    (acc: ProjectVersionMap, keyStringified) => {
      const key = parseInt(keyStringified, 10);
      const value = projectVersions[key];
      if (search === "") {
        acc[key] = value;
        return acc;
      }

      if (
        projectById[key] &&
        projectById[key].name
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
      ) {
        acc[key] = value;
        return acc;
      }

      const validVersions = value.filter((version) =>
        version.version.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      if (validVersions.length > 0) {
        acc[key] = validVersions;
      }

      return acc;
    },
    {}
  );

  return (
    <FilterTemplate
      disabled={disabled}
      count={noProjectVersionSelected ? selected.length + 1 : selected.length}
      onClear={onClear}
      placeholder={tr.translateAsString("filter.project-version.placeholder")}
      content={
        <FilterMenu search={search} onSearchChange={setSearch}>
          <FilterMenuItem
            selected={noProjectVersionSelected}
            onSelect={handleSelectEmpty}
            text={tr.translate("filter.none")}
          />
          <MenuDivider />
          {Object.keys(projectById).map((keyStringified) => {
            const key = parseInt(keyStringified, 10);

            return (
              <React.Fragment key={key}>
                <div className={styles.textSeparator}>
                  <HighlightedText
                    text={projectById[key]?.name ?? ""}
                    highlight={search}
                  />
                </div>
                {filteredProjectVersion[key] &&
                  filteredProjectVersion[key].map((item) => (
                    <FilterMenuItem
                      key={item.id}
                      selected={selected.includes(item.id)}
                      onSelect={handleSelect(item.id)}
                      text={
                        <HighlightedText
                          text={item.version}
                          highlight={search}
                        />
                      }
                    />
                  ))}
              </React.Fragment>
            );
          })}
        </FilterMenu>
      }
    />
  );
}
