import { RevisionState } from "@aletiq/types";
import { useTranslations } from "../../../util";
import { ObjectType } from "../types";

export default function useChangeRevisionTranslations(
  initial: RevisionState,
  final: RevisionState,
  objectType: ObjectType,
  isPublished?: boolean
) {
  const tr = useTranslations();
  const values = {
    type: objectType,
  };

  switch (initial) {
    case "draft":
      return {
        title: tr.translateAsString("revisions.approve.title"),
        description: tr.translateAsString(
          "revisions.approve.description",
          values
        ),
      };

    case "approved":
      switch (final) {
        case "draft":
          return {
            title: tr.translateAsString("revisions.revert-draft.title"),
            description: tr.translateAsString(
              "revisions.revert-draft.description",
              values
            ),
            callout: isPublished
              ? {
                  title: tr.translateAsString(
                    "revisions.revert-draft.callout.title"
                  ),
                  description: tr.translateAsString(
                    "revisions.revert-draft.callout.description"
                  ),
                }
              : undefined,
          };
        default:
          return {
            title: tr.translateAsString("revisions.mark-obsolete.title"),
            description: tr.translateAsString(
              "revisions.mark-obsolete.description",
              values
            ),
          };
      }
    case "obsolete": {
      return {
        title: tr.translateAsString("revisions.revert-approve.title"),
        description: tr.translateAsString(
          "revisions.revert-approve.description",
          values
        ),
      };
    }
  }
}
