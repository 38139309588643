import { isNotUndefined } from "../../../util";
import useProjectBills from "../../project/Operations/hooks/useProjectBills";
import useDocument from "./useDocument";

//operation bill a given doc is used in
export type BillUsedIn = {
  id: number;
  index: string;
  revisionNames: string[];
};

export default function useOperationBillsUsedIn(
  documentId: number,
  projectId: number
): BillUsedIn[] {
  const { data: bills = [] } = useProjectBills(projectId);
  const { data: document } = useDocument(documentId);

  const docRevisions = document?.revisions ?? [];
  const lastApproved = docRevisions.find((r) => r.state === "approved");
  const billsUsedIn = bills.filter((b) =>
    b.operations.some((op) =>
      op.documents.some((d) => d.document === documentId)
    )
  );

  return billsUsedIn.map((bill) => {
    const revisionsIds = bill.operations
      .flatMap((op) => op.documents)
      .filter((d) => d.document === documentId)
      .map((d) => d.revision);

    //filter out duplicate revisions (revisions used in several operations)
    const filteredRevisionsIds = Array.from(new Set(revisionsIds));

    const revisionNames = filteredRevisionsIds
      .map((r) => {
        if (r.type === "specific") {
          const revisionUsed = docRevisions.find(
            (rev) => rev.id === r.revision?.id
          );

          if (!revisionUsed) {
            return undefined;
          }
          return revisionUsed.revisionName;
        }

        if (r.type === "last" && lastApproved) {
          return lastApproved.revisionName;
        }

        // doc revision is defined as "last-approved" in operation
        // but there is no last approved revsion
        return undefined;
      })
      .filter(isNotUndefined);

    return {
      id: bill.id,
      index: bill.index,
      revisionNames,
    };
  });
}
