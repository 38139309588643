import { SearchInput, Tooltip } from "@aletiq/design-system";
import { TaskQueryFilterParams } from "@aletiq/types";
import React from "react";
import {
  MoreFilters,
  OwnerFilter,
  TaskDateFilter,
  TaskStatusFilter,
  WorkflowFilter,
  WorkflowModelFilter,
} from "../../../components";
import { HandleClear, HandleFilter } from "../../../hooks";
import { useTranslations } from "../../../util";
import styles from "./Tasks.module.scss";

export default function TaskFilters(props: {
  params: TaskQueryFilterParams;
  handleFilter: HandleFilter<TaskQueryFilterParams>;
  handleClear: HandleClear<TaskQueryFilterParams>;
}) {
  const { params, handleFilter, handleClear } = props;
  const tr = useTranslations();

  return (
    <>
      <Tooltip
        content={tr.translateAsString("task.filter.tooltip-search")}
        position="top"
      >
        <SearchInput
          value={params?.search ?? ""}
          onChange={handleFilter("search")}
          className={styles.searchbar}
          isDense
        />
      </Tooltip>
      <WorkflowModelFilter
        selected={params.models ?? []}
        onSelect={handleFilter("models")}
        onSelectNoModel={handleFilter("emptyModel")}
        onClear={handleClear(["models", "emptyModel"])}
        noModelSelected={params.emptyModel}
      />
      <TaskStatusFilter
        selected={params?.taskStatuses ?? []}
        onSelectStatus={handleFilter("taskStatuses")}
      />
      <OwnerFilter
        selected={params?.owners ?? []}
        onSelect={handleFilter("owners")}
        onSelectNoOwner={handleFilter("emptyOwner")}
        onClear={handleClear(["owners", "emptyOwner"])}
        noOwnerSelected={params?.emptyOwner ?? false}
        placeholder={tr.translateAsString("filter.owner.placeholder")}
      />
      <MoreFilters
        onClear={handleClear}
        filterValues={params}
        additionalFilters={["workflows", "from", "to"]}
      >
        <WorkflowFilter
          selected={params?.workflows ?? []}
          onSelect={handleFilter("workflows")}
          activeWorkflowsOnly
        />
        <TaskDateFilter
          selected={{ to: params.to, from: params.from }}
          onSelect={(timeFrame) => {
            handleFilter("from")(timeFrame?.from);
            handleFilter("to")(timeFrame?.to);
          }}
        />
      </MoreFilters>
    </>
  );
}
