import { Icon } from "@aletiq/design-system";
import React from "react";
import TreeButton, { TreeButtonProps } from "./TreeButton";

export default function DeleteButton(props: TreeButtonProps) {
  return (
    <TreeButton
      {...props}
      isDense
      color="primary"
      view="flat"
      icon={<Icon icon="trash" color="var(--blue70)" />}
    />
  );
}
