import amp from "amplitude-js";
import mp from "mixpanel-browser";
import React, { createContext, ReactNode, useMemo } from "react";
import {
  AMPLITUDE_ID,
  MIXPANEL_API_HOST,
  MIXPANEL_PROJECT_TOKEN,
  NODE_ENV,
} from "../config";
import {
  amplitudeAnalytics,
  defaultAnalytics,
  mixpanelAnalytics,
} from "./types";

export const AnalyticsContext = createContext(defaultAnalytics());

export function AmplitudeAnalyticsProvider(props: { children?: ReactNode }) {
  const { children } = props;

  const analytics = useMemo(() => {
    if (AMPLITUDE_ID === "") {
      return defaultAnalytics();
    }

    const amplitude = amp.getInstance();
    amplitude.init(AMPLITUDE_ID);

    return amplitudeAnalytics(amplitude);
  }, []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function MixpanelAnalyticsProvider(props: { children?: ReactNode }) {
  const { children } = props;

  const analytics = useMemo(() => {
    if (MIXPANEL_PROJECT_TOKEN === "") {
      return defaultAnalytics();
    }

    mp.init(MIXPANEL_PROJECT_TOKEN, {
      debug: NODE_ENV === "development",
      api_host: MIXPANEL_API_HOST || undefined,
    });

    return mixpanelAnalytics(mp);
  }, []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}
