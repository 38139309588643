import React, { useMemo, useState } from "react";
import {
  BatchActionButton,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
  RemoveLinkIcon,
  Tooltip,
} from "../../../../../components";
import { useCurrentUser, useHasPermission } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { FullTree } from "../../AllPartsType";
import styles from "../AllPartsListPaginated.module.scss";
import AllPartsHeaderBatchActionDialogs from "./AllPartHeaderBatchActionDialogs";

export type DialogType =
  | "link-project"
  | "unlink-project"
  | "lock-parts"
  | "unlock-parts"
  | "delete"
  | "link-workflow"
  | "create-workflow"
  | "create-articles"
  | "create-tools"
  | "share-parts"
  | null;

export default function AllPartHeaderBatchActions(props: {
  selectedPartTrees: FullTree[];
  toggleAllRowsSelected: (value?: boolean | undefined) => void;
  projectId?: number;
}) {
  const { toggleAllRowsSelected, selectedPartTrees, projectId } = props;
  const tr = useTranslations();

  const selectedParts = useMemo(
    () =>
      selectedPartTrees
        .filter((p) => p !== undefined && p !== null)
        .map((t) => t.part!),
    [selectedPartTrees]
  );

  const { data: user } = useCurrentUser();
  const [openedDialog, setOpenedDialog] = useState<DialogType>(null);

  const canUpdateParts = useHasPermission("update:parts");
  const canDeleteParts = useHasPermission("delete:parts");
  const canCreateWorkflows = useHasPermission("create:workflows");

  const partHasOtherOwner = selectedParts.some(
    (part) => part.lockOwner && user && part.lockOwner !== user.id
  );

  const handleDialog = (dialog: DialogType) => () => {
    setOpenedDialog(dialog);
  };

  const handleCloseDialog = () => {
    setOpenedDialog(null);
  };

  const handleCloseDialogResetSelection = () => {
    toggleAllRowsSelected(false);
    setOpenedDialog(null);
  };

  return (
    <>
      <div className={styles.subtitle}>
        {tr.translate("part.details.part-selected", {
          nbr: selectedParts.length,
        })}
      </div>
      {canUpdateParts && (
        <>
          <Tooltip
            content={tr.translate(
              partHasOtherOwner
                ? "part.details.lock-parts.tooltip.disabled"
                : "part.details.lock-parts.tooltip"
            )}
            position="top"
          >
            <BatchActionButton
              title={tr.translateAsString("part.details.lock-parts")}
              onClick={handleDialog("lock-parts")}
              isDisabled={partHasOtherOwner}
              icon="lock"
            />
          </Tooltip>
          <Tooltip
            content={tr.translate(
              partHasOtherOwner
                ? "part.details.lock-parts.tooltip.disabled"
                : "part.details.unlock-parts.tooltip"
            )}
            position="top"
          >
            <BatchActionButton
              title={tr.translateAsString("part.details.unlock-parts")}
              onClick={handleDialog("unlock-parts")}
              isDisabled={partHasOtherOwner}
              icon="unlock"
            />
          </Tooltip>
        </>
      )}
      <Tooltip
        content={tr.translate(
          "part.details.link-part.existing-project.tooltip"
        )}
        position="top"
      >
        <BatchActionButton
          title={tr.translateAsString(
            "part.details.link-part.existing-project"
          )}
          icon="new-link"
          onClick={handleDialog("link-project")}
        />
      </Tooltip>
      <Tooltip
        content={tr.translate("part.details.link-part.new-project.tooltip")}
        position="top"
      >
        <Popover2
          position="bottom-right"
          content={
            <Menu isDense>
              <MenuItem
                isDense
                icon="projects"
                text={tr.translate(
                  "part.details.link-part.new-project.article"
                )}
                onClick={handleDialog("create-articles")}
              />
              <MenuItem
                isDense
                icon="wrench"
                text={tr.translate("part.details.link-part.new-project.tool")}
                onClick={handleDialog("create-tools")}
              />
            </Menu>
          }
        >
          <BatchActionButton
            title={tr.translateAsString("part.details.link-part.new-project")}
            icon="plus"
          />
        </Popover2>
      </Tooltip>
      <Popover2
        minimal
        position="bottom-right"
        content={
          <Menu isDense>
            {projectId && (
              <MenuItem
                isDense
                icon={<RemoveLinkIcon />}
                text={tr.translate("document.page.action.unlink")}
                onClick={handleDialog("unlink-project")}
              />
            )}
            {canCreateWorkflows && (
              <MenuItem
                isDense
                text={tr.translate("part.details.create-workflow")}
                icon="plus"
                onClick={handleDialog("create-workflow")}
              />
            )}
            <MenuItem
              isDense
              text={tr.translate("part.details.link-workflow")}
              icon="new-link"
              onClick={handleDialog("link-workflow")}
            />
            {canUpdateParts && (
              <MenuItem
                isDense
                text={tr.translate("part.action.share")}
                icon="people"
                onClick={handleDialog("share-parts")}
              />
            )}
            {canDeleteParts && (
              <>
                <MenuDivider isDense />
                <MenuItem
                  isDense
                  text={tr.translate("part.details.delete")}
                  icon="trash"
                  onClick={handleDialog("delete")}
                />
              </>
            )}
          </Menu>
        }
      >
        <BatchActionButton
          intent="outlined"
          title={tr.translateAsString("part.details.plus")}
          rightIcon="caret-down"
        />
      </Popover2>

      <AllPartsHeaderBatchActionDialogs
        parts={selectedParts}
        projectId={projectId}
        openedDialog={openedDialog}
        handleCloseDialog={handleCloseDialog}
        handleCloseDialogResetSelection={handleCloseDialogResetSelection}
      />
    </>
  );
}
