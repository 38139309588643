import { Part, PartIteration } from "@aletiq/types";
import React, { useState } from "react";
import { ANALYTICS_PARTS_SHOWN, useAnalytics } from "../../../../analytics";
import { AnchorButton, Button, ButtonGroup } from "../../../../components";
import { PartIterationViewer } from "../../../pdm/dialogs";
import { usePartIterationViewFiles } from "../../../pdm/hooks";
import { EditPartBomDialog } from "../../dialogs";
import DeleteNomenclaturePopover from "./DeleteNomenclaturePopover";

type ProjectActionBarDialog = "editPartBom" | "viewPart";

export default function ProjectPartActionBar(props: {
  projectId: number;
  definitionIdx: number;
  part: Part;
  iteration: PartIteration;
  hasDeletePart?: boolean;
  isDeletePartDisabled?: boolean;
}) {
  const {
    part,
    iteration,
    projectId,
    definitionIdx,
    hasDeletePart,
    isDeletePartDisabled,
  } = props;

  const analytics = useAnalytics();

  const [dialog, setDialog] = useState<ProjectActionBarDialog>();

  const { data: viewFiles = [] } = usePartIterationViewFiles(
    part.id,
    iteration.number
  );

  const handleOpenDialog = (value: ProjectActionBarDialog) => () =>
    setDialog(value);

  const handleCloseDialog = () => setDialog(undefined);

  const handleOnViewPart = () => {
    analytics.track(ANALYTICS_PARTS_SHOWN);
    setDialog("viewPart");
  };

  const canView = viewFiles.length > 0;

  return (
    <ButtonGroup>
      {part.type !== "drawing" && (
        <AnchorButton
          isDense
          icon="eye-open"
          onClick={handleOnViewPart}
          isDisabled={!canView}
        />
      )}
      {part.type === "assembly" && (
        <Button
          isDense
          icon="diagram-tree"
          onClick={handleOpenDialog("editPartBom")}
          isDisabled={part.type !== "assembly"}
        />
      )}
      {hasDeletePart && (
        <DeleteNomenclaturePopover
          projectId={projectId}
          definitionIdx={definitionIdx}
        >
          <Button isDense icon="trash" isDisabled={isDeletePartDisabled} />
        </DeleteNomenclaturePopover>
      )}

      {dialog === "editPartBom" && part && iteration && (
        <EditPartBomDialog
          iteration={iteration.number}
          partId={part.id}
          onClose={handleCloseDialog}
        />
      )}

      {dialog === "viewPart" && part && iteration && (
        <PartIterationViewer
          part={part}
          iteration={iteration.number}
          onClose={handleCloseDialog}
        />
      )}
    </ButtonGroup>
  );
}
