import { OperationTool, ProjectVersion } from "@aletiq/types";
import { useMemo } from "react";
import { useQueries } from "react-query";
import useApi from "../../../../app/useApi";
import { projectKeys } from "../../hooks/queries";

export default function useToolsWithDraftVersions(opTools: OperationTool[]) {
  const api = useApi();

  //filter out operation tools where no version is defined
  const opToolsWithVersion = opTools.filter((t) => !!t.version);

  const versionsQueries = useQueries(
    opToolsWithVersion.map((t) => ({
      queryKey: projectKeys.version(t.tool, t.version?.type ?? ""),
      queryFn: () => api.project.getProjectVersions(t.tool),
    }))
  );

  const versionsByToolId = useMemo(() => {
    const versionsByToolId: Record<number, ProjectVersion[]> = {};
    versionsQueries.forEach((response) => {
      if (response.data && response.data.length > 0) {
        const toolId = response?.data[0]?.project;
        versionsByToolId[toolId] = response.data;
      }
    });
    return versionsByToolId;
  }, [versionsQueries]);

  //return ids of tools whose selected versions are drafts
  const toolDraftVersions = useMemo(
    () =>
      opToolsWithVersion
        .filter((t) => {
          const toolVersions = versionsByToolId[t.tool];

          if (!toolVersions) {
            return true;
          }

          const version = toolVersions.find((v) =>
            t.version?.type === "specific"
              ? v.id === t.version.version
              : !v.isDraft
          );

          if (!version || version.isDraft) {
            return true;
          }

          return false;
        })
        .map((t) => t.tool),
    [opToolsWithVersion, versionsByToolId]
  );

  return toolDraftVersions;
}
