import { PartType } from "@aletiq/types";

export default function partTypeFromFileName(
  fileName: string
): PartType | null {
  const nameParts = fileName.split(".");
  const extension = nameParts[nameParts.length - 1].toLocaleLowerCase();
  switch (extension) {
    case "catpart":
    case "sldprt": {
      return "part";
    }
    case "catproduct":
    case "sldasm": {
      return "assembly";
    }
    case "slddrw": {
      return "drawing";
    }
    default: {
      return null;
    }
  }
}
