export function unique<T>(arr: T[]): T[] {
  const seen = new Set();
  return arr.filter((it) => {
    if (seen.has(it)) {
      return false;
    }

    seen.add(it);
    return true;
  });
}
