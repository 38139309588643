import {
  Button,
  ButtonGroup,
  CheckBox,
  HighlightedText,
  Icon,
  Menu,
  MenuItem,
  Popover2,
  Tooltip,
} from "@aletiq/design-system";
import { FileMeta } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React from "react";
import { AlignRight } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { usePassportFileTypes, useSetPassportFileType } from "../../hooks";
import styles from "./PassportFiles.module.scss";
import { ResolvedPassportFile } from "./types";

export default function PassportFileRow(props: {
  passportId: number;
  passportFile: ResolvedPassportFile;
  onPreviewFile: (file: FileMeta) => void;
  onDownloadFile: (fileId: number) => void;
  onRemoveFile: (file: FileMeta) => void;
  isSelected: boolean;
  onToggleSelect: (fileId: number) => void;
  search?: string;
}) {
  const {
    passportId,
    passportFile: { file, type },
    onDownloadFile,
    onPreviewFile,
    onRemoveFile,
    onToggleSelect,
    isSelected,
    search,
  } = props;
  const tr = useTranslations();

  const { data: fileTypes = [] } = usePassportFileTypes();
  const canRemovePassportFiles = useHasPermission("update:passports");
  const canUpdatePassportFiles = useHasPermission("attach:passport-files");

  const { mutate: setFileType, isLoading } = useSetPassportFileType(
    passportId,
    file.id
  );

  const handleDownloadFile = () => {
    onDownloadFile(file.id);
  };

  const handlePreviewFile = () => {
    onPreviewFile(file);
  };

  const handleRemoveFile = () => {
    onRemoveFile(file);
  };

  const handleSetFileType = (fileType: number | null) => () => {
    setFileType(fileType);
  };

  const handleClickCheckbox = () => {
    onToggleSelect(file.id);
  };

  return (
    <div className={styles.row}>
      <div className={styles.row_top}>
        <CheckBox
          className={styles.checkbox}
          checked={isSelected}
          onChange={handleClickCheckbox}
          intent="default"
          isDense
        />
        <Icon inline icon="paperclip" />
        <Text ellipsize className={styles.file_name}>
          <HighlightedText text={file.name} highlight={search} />
        </Text>
        <AlignRight />
        <ButtonGroup>
          <Tooltip content={tr.translate("generic.action.visualize")}>
            <Button isDense icon="eye-open" onClick={handlePreviewFile} />
          </Tooltip>
          <Popover2
            minimal
            position="bottom-right"
            content={
              <Menu intent="minimal" isDense>
                <MenuItem
                  icon="download"
                  text={tr.translate("generic.action.download")}
                  onClick={handleDownloadFile}
                  isDense
                />
                <MenuItem
                  icon="trash"
                  text={tr.translate("generic.action.delete")}
                  onClick={handleRemoveFile}
                  disabled={!canRemovePassportFiles}
                  isDense
                />
              </Menu>
            }
          >
            <Tooltip
              content={tr.translate("passport.details.file.more-actions")}
            >
              <Button isDense icon="more" />
            </Tooltip>
          </Popover2>
        </ButtonGroup>
      </div>
      <div className={styles.row_bottom}>
        <span className={styles.label}>
          {tr.translate("passport.details.file.type")}
        </span>
        {canUpdatePassportFiles && (
          <Popover2
            minimal
            position="bottom-right"
            content={
              <Menu intent="minimal" isDense>
                <MenuItem
                  active={!type}
                  text={tr.translate("generic.label.none")}
                  onClick={handleSetFileType(null)}
                  isDense
                />
                {fileTypes.map((fileType) => (
                  <MenuItem
                    key={fileType.id}
                    active={fileType.id === type?.id}
                    text={fileType.value}
                    onClick={handleSetFileType(fileType.id)}
                    isDense
                  />
                ))}
              </Menu>
            }
          >
            <Button isDense rightIcon="caret-down" isLoading={isLoading}>
              {type ? (
                <HighlightedText text={type?.value} highlight={search} />
              ) : (
                tr.translate("generic.label.none")
              )}
            </Button>
          </Popover2>
        )}
        {!canUpdatePassportFiles && type && (
          <HighlightedText text={type?.value} highlight={search} />
        )}
        {!canUpdatePassportFiles && !type && tr.translate("generic.label.none")}
      </div>
    </div>
  );
}
