import { Property } from "@aletiq/types";
import React, { useState } from "react";
import { AnchorButton, PropertyCard, Tooltip } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useCountPropertyUses } from "../hooks";
import { ConfirmDeletePropertyDialog } from "../PassportAttributes/Dialogs";
import { PropertyFilters } from "../types";
import EditProductPropertyDialog from "./Dialogs/EditProductProperty";
import ProductPropertyTarget from "./ProductPropertyTarget";

type DialogType = "delete-property" | "edit-property";

export default function PassportPropertyCard(props: {
  property: Property;
  filters?: PropertyFilters;
}) {
  const { property, filters } = props;
  const tr = useTranslations();

  const [dialog, setDialog] = useState<DialogType>();

  const { data: propertyUseCount = 0 } = useCountPropertyUses(
    "product",
    property.name
  );
  const canEditProperty = useHasPermission("update:custom-attributes");
  const canRemoveProperty = useHasPermission("update:custom-attributes");

  const handleOpenDialog = (type?: DialogType) => () => {
    setDialog(type);
  };

  return (
    <>
      <PropertyCard
        label={property.label}
        type={property.type}
        description={property.description}
        creator={property.creator}
        onEdit={handleOpenDialog("edit-property")}
        canEdit={canEditProperty}
        filters={filters}
        target={<ProductPropertyTarget target={property.group} />}
        usedIn={[
          {
            count: propertyUseCount,
            icon: property.group === "tool" ? "wrench" : "projects",
          },
        ]}
        additionalButton={
          <Tooltip
            position="top"
            content={tr.translate("admin.attribute.passports.action.disable")}
          >
            <AnchorButton
              isDense
              icon="disable"
              isDisabled={!canRemoveProperty}
              onClick={handleOpenDialog("delete-property")}
            />
          </Tooltip>
        }
      />
      {dialog === "edit-property" && (
        <EditProductPropertyDialog
          property={property}
          onClose={handleOpenDialog(undefined)}
        />
      )}
      {dialog === "delete-property" && (
        <ConfirmDeletePropertyDialog
          onClose={handleOpenDialog(undefined)}
          propertyName={property.name}
          objectType="product"
        />
      )}
    </>
  );
}
