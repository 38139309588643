import { Icon } from "@blueprintjs/core";
import React from "react";
import { useUserGroup } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import ShareDocumentsRow from "./ShareDocumentsRow";

export default function GroupRow(props: {
  groupId: number;
  count: number;
  total: number;
  onAddAll: () => void;
  onRemoveAll: () => void;
}) {
  const { groupId, count, total, onAddAll, onRemoveAll } = props;
  const tr = useTranslations();

  const { data: group } = useUserGroup(groupId);

  return (
    <ShareDocumentsRow
      onAddAll={onAddAll}
      onRemoveAll={onRemoveAll}
      icon={<Icon icon="people" iconSize={20} />}
      title={group?.name ?? ""}
      count={count}
      total={total}
      deleteAlertText={tr.translateAsString(
        "document.dialog.shared-documents.remove-group"
      )}
    />
  );
}
