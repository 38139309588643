import { UserGroupSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { userKeys } from "../../hooks/queries";

export default function useUpdateUserGroup() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<Response, Error, { groupId: number; spec: UserGroupSpec }>(
    ({ groupId, spec }) => api.user.updateUserGroup(groupId, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userKeys.groups);
      },
    }
  );
}
