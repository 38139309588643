import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { pdmKeys } from "./queries";

export default function useAssemblyComponents(
  assemblyId: number | null | undefined,
  enabled: boolean = true
) {
  const api = useApi();
  return useQuery(
    pdmKeys.iterationComponents(assemblyId!),
    () => api.pdm.listAssemblyComponents(assemblyId!),
    {
      enabled:
        enabled &&
        assemblyId !== null &&
        assemblyId !== undefined &&
        assemblyId !== 0,
    }
  );
}
