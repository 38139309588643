import React, { useState } from "react";
import ModelAnalysisHeader from "./ModelAnalysisHeader";
import ModelAnalysisResolution from "./ModelAnalysisResolution";
import ModelAnalysisVolume from "./ModelAnalysisVolume";
import { Period } from "@aletiq/types";
import styles from "./ModelAnalysis.module.scss";
import ModelStateList from "./ModelStateList";

export default function ModelAnalysis(props: { defaultModels: number[] }) {
  const { defaultModels } = props;

  const [period, setPeriod] = useState<Period>("month");
  const [models, setModels] = useState<number[]>(defaultModels);

  const handlePeriodChange = (value: Period) => {
    value !== null && setPeriod(value);
  };

  const handleModelSelect = (models: number[]) => {
    setModels([...models]);
  };

  return (
    <>
      <ModelAnalysisHeader
        selectedPeriod={period}
        selectedModels={models}
        onPeriodChange={handlePeriodChange}
        onModelSelect={handleModelSelect}
      />
      <div className={styles.grid}>
        <ModelAnalysisVolume period={period} selectedModels={models} />
        <ModelAnalysisResolution period={period} selectedModels={models} />
        <ModelStateList selectedModels={models} />
      </div>
    </>
  );
}
