import { DocumentRevision } from "@aletiq/types";
import React from "react";
import useApi from "../../../../app/useApi";
import { Button } from "../../../../components";
import { useCurrentUser, useDownloadQueue } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useDocument } from "../../hooks";
import RevisionItem from "./RevisionItem";
import styles from "./Revisions.module.scss";

export default function Revisions(props: {
  documentId: number;
  onShowFile: (revisionId: number) => void;
  onUpload: () => void;
  disableEdit?: boolean;
}) {
  const { documentId, onShowFile, onUpload, disableEdit } = props;
  const tr = useTranslations();
  const { handleDownload } = useDownloadQueue();
  const api = useApi();

  const { data: document } = useDocument(documentId);
  const revisions = document?.revisions ?? [];

  const closedRevisionsNames = revisions
    .filter((r) => r.state !== "draft")
    .map((r) => r.revisionName);

  const hasOtherRevisions = revisions.length > 1;
  const isLastRevision = (revision: DocumentRevision) =>
    revision.id === revisions[0].id;

  const { data: user } = useCurrentUser();
  const isAdminUser = user?.role === "administrator";

  const onDownload = (revision?: number) =>
    handleDownload({
      fetchToken: () =>
        api.document.getDocumentDownloadToken(documentId, revision),
      forceDownload: true,
    });

  const onDownloadStamped = (revision?: number) =>
    handleDownload({
      fetchToken: () =>
        api.document.getDocumentPreviewToken(documentId, revision),
      forceDownload: true,
    });

  return (
    <>
      <Button
        intent="secondary"
        text={tr.translate("document.details.revisions.load-file")}
        icon="upload"
        className={styles.load_file}
        onClick={onUpload}
        isDisabled={disableEdit}
        isDense
      />
      {document && (
        <div className={styles.revision_list} key={documentId}>
          {revisions.map((revision) => (
            <RevisionItem
              key={revision.id}
              documentId={documentId}
              documentName={document.name}
              revision={revision}
              closedRevisionsNames={closedRevisionsNames}
              onShowFile={onShowFile}
              onDownload={onDownload}
              onDownloadStamped={onDownloadStamped}
              isEditable={!disableEdit}
              isAdminUser={isAdminUser}
              isDeleteDisabled={!hasOtherRevisions || !isLastRevision(revision)}
            />
          ))}
        </div>
      )}
    </>
  );
}
