import { Project } from "@aletiq/types";
import React, { ChangeEvent, useState } from "react";
import {
  Button,
  DeleteAlert,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
} from "../../../../components";
import { useHasPermission, useNavigate } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { PassportCreationDialog } from "../../../passport/dialogs";
import { ConvertProductDialog, RenameProjectDialog } from "../../dialogs";
import { useDeleteProject, useSetProductImage } from "../../hooks";
import styles from "./ProjectMenu.module.scss";

type Dialog = "rename" | "delete" | "create-passports" | "convert";

export default function ProjectMenu(props: { project: Project }) {
  const { project } = props;
  const tr = useTranslations();
  const navigate = useNavigate();

  const [dialog, setDialog] = useState<Dialog>();

  const canDeleteProject = useHasPermission("delete:projects");
  const canUpdateProject = useHasPermission("update:projects");
  const canCreatePassports = useHasPermission("create:passports");
  const { mutate: setProductImage } = useSetProductImage(project.id);
  const { mutate: deleteProject, isLoading: isDeletingProject } =
    useDeleteProject(project.id);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleOpenDialog = (dialogType: Dialog) => () => {
    setDialog(dialogType);
  };

  const handleCloseDialog = () => setDialog(undefined);

  const handleDeleteProduct = () => deleteProject();

  const handleClickAddImage = () => {
    fileInputRef.current && fileInputRef.current?.click();
  };

  const handleSubmitImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      canUpdateProject &&
      event.target.files &&
      event.target.files.length === 1
    )
      setProductImage(event.target.files[0]);
  };

  const handleDuplicateProject = () => {
    navigate({ path: `/project/${project.id}/copy` });
  };

  const hiddenFileInput = (
    <input
      type="file"
      accept="image/*"
      ref={fileInputRef}
      className={styles.hidden}
      onChange={handleSubmitImage}
    />
  );

  const type = project.isArticle ? "article" : "tool";

  return (
    <>
      <Popover2
        content={
          <Menu intent="minimal" isDense>
            <MenuItem
              isDense
              icon="edit"
              text={tr.translate("generic.action.rename")}
              disabled={!canUpdateProject}
              onClick={handleOpenDialog("rename")}
            />
            <MenuItem
              isDense
              icon="plus"
              text={tr.translate("project.actions.create-passports")}
              disabled={!canCreatePassports}
              onClick={handleOpenDialog("create-passports")}
            />
            <MenuItem
              isDense
              icon="media"
              text={tr.translate("project.actions.add-image")}
              disabled={!canUpdateProject}
              onClick={handleClickAddImage}
            />
            <MenuItem
              isDense
              icon="duplicate"
              text={tr.translate("project.actions.duplicate", {
                type,
              })}
              onClick={handleDuplicateProject}
            />
            <MenuItem
              isDense
              icon="changes"
              text={tr.translate("project.actions.convert", {
                type,
              })}
              disabled={!canUpdateProject}
              onClick={handleOpenDialog("convert")}
            />
            <MenuDivider />
            <MenuItem
              isDense
              icon="trash"
              text={tr.translate("project.actions.delete", {
                type,
              })}
              disabled={!canDeleteProject}
              onClick={handleOpenDialog("delete")}
            />
          </Menu>
        }
      >
        <Button icon="more" isDense />
      </Popover2>

      {canUpdateProject && hiddenFileInput}

      {dialog === "delete" && (
        <DeleteAlert
          elementName={project?.name}
          onDelete={handleDeleteProduct}
          onClose={handleCloseDialog}
          isLoading={isDeletingProject}
        />
      )}

      {dialog === "create-passports" && (
        <PassportCreationDialog
          isOpen
          product={project}
          onClose={handleCloseDialog}
        />
      )}

      {dialog === "rename" && (
        <RenameProjectDialog project={project} onClose={handleCloseDialog} />
      )}

      {dialog === "convert" && (
        <ConvertProductDialog onClose={handleCloseDialog} product={project} />
      )}
    </>
  );
}
