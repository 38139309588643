import { EntityId } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { commentKeys } from "./queries";

export default function useEntityComments(entityId: EntityId) {
  const api = useApi();
  return useQuery(commentKeys.byEntity(entityId), () =>
    api.comment.getEntityComments(entityId)
  );
}
