import {
  BOMEntry,
  PartIterationId,
  ProjectDefinitionDocument,
} from "@aletiq/types";
import { Diff, diffBy, diffThese, These } from "../../../util";

function diffDocument(documents: These<ProjectDefinitionDocument>) {
  return diffThese(
    documents,
    (current, previous) => current.revision?.id !== previous.revision?.id
  );
}

function diffPart(parts: These<PartIterationId>) {
  return diffThese(
    parts,
    (current, previous) => current.iteration !== previous.iteration
  );
}

function diffComponent(component: These<BOMEntry>) {
  return diffThese(
    component,
    (current, previous) => current.definition !== previous.definition
  );
}

export function diffDocuments(
  first: ProjectDefinitionDocument[],
  second: ProjectDefinitionDocument[]
): Record<number, Diff<ProjectDefinitionDocument>> {
  return diffBy(first, second, "document", diffDocument);
}

export function diffParts(
  first: PartIterationId[],
  second: PartIterationId[]
): Record<number, Diff<PartIterationId>> {
  return diffBy(first, second, "part", diffPart);
}

export function diffComponents(
  first: BOMEntry[],
  second: BOMEntry[]
): Record<number, Diff<BOMEntry>> {
  return diffBy(first, second, "entity", diffComponent);
}
