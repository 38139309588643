export type RevisionState = "draft" | "approved" | "obsolete";

export function nextState(current: RevisionState): RevisionState | null {
  switch (current) {
    case "draft":
      return "approved";
    case "approved":
      return "obsolete";
    case "obsolete":
      return null;
  }
}
