import { useInfiniteQuery } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";

export default function useInfiniteActivities() {
  const api = useApi();

  return useInfiniteQuery(
    activityKeys.all,
    async ({ pageParam = undefined }) => api.activity.getActivities(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.cursor ?? undefined,
      keepPreviousData: true,
    }
  );
}
