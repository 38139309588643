import React from "react";
import { CellProps, TableExpandedToggleProps } from "react-table";
import {
  ExpandIcon,
  HighlightedText,
  PartIcon,
  Tooltip,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { ComponentTree } from "../../AllParts/AllPartsType";
import { isPartParent } from "../../services";
import styles from "./ApproveAssemblyIterationDialog.module.scss";

export default function ComponentNameCell(
  props: CellProps<ComponentTree> & TableExpandedToggleProps
) {
  const {
    row: { depth, toggleRowExpanded, isExpanded, original: component },
    isStandard,
  } = props;
  const tr = useTranslations();

  const canExpand = isPartParent(component.part?.type ?? "part");

  const handleExpandRow = () => {
    if (!canExpand) {
      return;
    }
    toggleRowExpanded();
  };

  const isPartPrivate = component.part && !component.part.hasAccess;

  const cellContents = (
    <>
      <ExpandIcon
        hasExpand={canExpand}
        isExpanded={isExpanded}
        onExpand={handleExpandRow}
      />
      <PartIcon inline type={component.part?.type} isStandard={isStandard} />
      <HighlightedText
        text={component.part?.name}
        highlight={props.searchString}
      />
    </>
  );

  if (isPartPrivate) {
    return (
      <div
        style={{ marginLeft: `${depth * 23}px` }}
        className={styles.private_part}
      >
        <Tooltip content={tr.translate("part.details.private")} position="top">
          {cellContents}
        </Tooltip>
      </div>
    );
  }

  return <div style={{ marginLeft: `${depth * 23}px` }}>{cellContents}</div>;
}
