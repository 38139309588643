import { User } from "@aletiq/types";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useRouteMatch } from "react-router-dom";
import useApi from "../../app/useApi";
import {
  ActionBar,
  AlignRight,
  Button,
  ExtendedColumn,
  PageContents,
  PageTitle,
  Table,
} from "../../components";
import CreateUserDialog from "./CreateUserDialog";
import { useTenants } from "./hooks";
import { adminKeys } from "./hooks/queries";

const columns: ExtendedColumn<User>[] = [
  {
    id: "id",
    Header: "Id",
    accessor: "id",
  },
  { id: "name", Header: "Name", accessor: "displayName", canSort: false },
  { id: "role", Header: "Role", accessor: "role", canSort: false },
  { id: "email", Header: "Email", accessor: "email", canSort: false },
];

export default function AletiqAdminTenantUsers() {
  const api = useApi();
  const match = useRouteMatch<{ tenantId: string }>();
  const tenantId = Number.parseInt(match.params.tenantId);

  const { data: tenants } = useTenants();
  const tenant = tenants?.find((t) => t.id === tenantId);
  const { data, isLoading } = useQuery(adminKeys.usersByTenant(tenantId), () =>
    api.http.getJson(`/admin/users?tenant=${tenantId}`)
  );

  const [dialog, setDialog] = useState<"create" | null>(null);

  return (
    <PageContents domTitle={"Aletiq Users"}>
      <PageTitle>List of all users of tenant "{tenant?.name}"</PageTitle>
      <ActionBar>
        <AlignRight />
        <Button
          isDense
          icon="plus"
          intent="secondary"
          onClick={() => setDialog("create")}
        >
          Create a user
        </Button>
      </ActionBar>
      <Table columns={columns} isFetching={isLoading} data={data ?? []}></Table>

      {dialog ? (
        <CreateUserDialog tenant={tenantId} onClose={() => setDialog(null)} />
      ) : null}
    </PageContents>
  );
}
