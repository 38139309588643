import { ItemSelect, MultiSelectSP } from "@aletiq/design-system";
import React from "react";

export default function PropertyValueMultiselect(props: {
  options: ItemSelect<string>[];
  selectedValues: string[];
  onValuesChange: (values: string[]) => void;
  fill?: boolean;
  isDense?: boolean;
  isDisabled?: boolean;
}) {
  const { options, selectedValues, onValuesChange, fill, isDense, isDisabled } =
    props;

  const enabledOptions = options.filter((option) => !option.hidden);

  const addValue = (item: string) => {
    if (selectedValues.includes(item)) {
      onValuesChange(selectedValues.filter((key) => item !== key));
    } else {
      onValuesChange([...selectedValues, item]);
    }
  };

  const removeValue = (item: string) => {
    onValuesChange(selectedValues.filter((key) => item !== key));
  };

  return (
    <MultiSelectSP
      fill={fill}
      selectedItems={selectedValues}
      items={enabledOptions}
      handleAddTag={addValue}
      handleRemoveTag={removeValue}
      intent="primary"
      isDense={isDense}
      isDisabled={isDisabled}
    />
  );
}
