import { Part } from "@aletiq/types";
import React, { useState } from "react";
import { CellProps } from "react-table";
import { Button, GenericStateTag } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import { usePartIterationViewFiles } from "../../../hooks";
import partRevisionNotification from "../../../partRevisionNotification";
import { isComponentsComplete, isPartParent } from "../../../services";
import { FullTree } from "../../AllPartsType";
import styles from "./PartRevisionCell.module.scss";

export default function PartRevisionCell(
  props: CellProps<FullTree> & { onViewPart: (part: Part) => void }
) {
  const { onViewPart } = props;
  const [wasHovered, setWasHovered] = useState(false);

  const part =
    props.row.original.type === "part" ? props.row.original.part : undefined;

  const isIncomplete =
    isPartParent(part?.type ?? "part") &&
    !isComponentsComplete(part?.lastIteration.components ?? []);

  const hasObsoleteComponents = part?.lastIteration.components.some(
    (c) => c.iterationState === "obsolete"
  );

  const notification = partRevisionNotification({
    isIncomplete,
    hasObsoleteComponents,
  });

  const handleEnterCell = (event: React.MouseEvent) => {
    event.preventDefault();
    !wasHovered && setWasHovered(true);
  };

  return (
    <div className={styles.cell} onMouseEnter={handleEnterCell}>
      {part?.lastIteration && !part.isStandard && (
        <GenericStateTag
          name={part.lastIterationName}
          searchString={props.searchString}
          state={part.lastIteration.state}
          notification={notification}
        />
      )}
      {wasHovered && part && (
        <ViewPartButton
          part={part}
          className={styles.hover_button}
          onViewPart={onViewPart}
        />
      )}
    </div>
  );
}

// Only show the button (and do the necessary query)
// if the cell has been hovered at least once
export function ViewPartButton(props: {
  part: Part;
  className?: string;
  onViewPart: (part: Part) => void;
}) {
  const { part, className, onViewPart } = props;
  const tr = useTranslations();

  const { data: viewFiles = [] } = usePartIterationViewFiles(
    part.id,
    part.lastIteration.number
  );

  return (
    <Button
      isDense
      intent="array_primary"
      text={tr.translate("generic.action.visualize")}
      onClick={() => onViewPart(part)}
      isDisabled={viewFiles.length === 0}
      className={className}
    />
  );
}
