import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useListProjectDefinitions(projectId: number) {
  const api = useApi();
  return useQuery(projectKeys.allDefinitions(projectId), () =>
    api.project.listProjectDefinitions(projectId)
  );
}
