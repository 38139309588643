import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { projectKeys } from "./queries";

export default function useDeleteToolingRequirement() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation<void, Error, { articleId: number; requirementId: number }>(
    ({ articleId, requirementId }) =>
      api.project.deleteToolingRequirement(articleId, requirementId),
    {
      onSuccess: (_, { articleId }) => {
        queryClient.invalidateQueries(
          projectKeys.toolingRequirementById(articleId)
        );
        queryClient.invalidateQueries(projectKeys.toolingArticles);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.product.tool.delete"),
        });
      },
    }
  );
}
