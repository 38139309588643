import React from "react";
import EntityProcesses from "../../tasks/common/EntityProcesses";

export default function Processes(props: {
  documentId: number;
  disableEdit?: boolean;
}) {
  const { documentId } = props;

  return (
    <EntityProcesses
      entityId={{
        type: "file",
        id: documentId,
      }}
    />
  );
}
