import { ActiveDocument, ProjectVersionAction } from "@aletiq/types";

export const addChangeStatus =
  (
    previousDocuments: (ActiveDocument & { new?: boolean; updated?: boolean })[]
  ) =>
  (changes: ProjectVersionAction[]) => {
    let documents = previousDocuments;

    changes.forEach((change) => {
      switch (change.type) {
        case "activateDocument": {
          documents = documents.map((doc) =>
            doc.document.id === change.value.document
              ? { ...doc, new: true }
              : doc
          );
          break;
        }
        case "updateDocument": {
          documents = documents.map((doc) =>
            doc.document.id === change.value.document
              ? { ...doc, updated: true }
              : doc
          );

          break;
        }
        case "updateDocumentDescription": {
          documents = documents.map((doc) =>
            doc.document.id === change.value.document
              ? { ...doc, updated: true }
              : doc
          );
          break;
        }
        case "deactivateDocument": {
          break;
        }
      }
    });

    return documents;
  };

export const computeDocumentChanges = (
  previousDocuments: ActiveDocument[],
  newDocuments: ActiveDocument[]
): ProjectVersionAction[] => {
  const previousIds = previousDocuments.map((doc) => doc.document.id);
  const newIds = newDocuments.map((doc) => doc.document.id);

  const deletes: ProjectVersionAction[] = previousIds
    .filter((id) => !newIds.includes(id))
    .map((id) => ({ type: "deactivateDocument", value: { document: id } }));

  const inserts: ProjectVersionAction[] = newDocuments
    .filter((doc) => !previousIds.includes(doc.document.id))
    .map((doc) => ({
      type: "activateDocument",
      value: {
        document: doc.document.id,
        revision: doc.revision,
        description: doc.description,
      },
    }));

  const updates: ProjectVersionAction[] = newDocuments.flatMap((doc) => {
    const oldDoc = previousDocuments.find(
      (old) => old.document.id === doc.document.id
    );

    if (!oldDoc) return [];
    const changes: ProjectVersionAction[] = [];
    if (oldDoc.description !== doc.description)
      changes.push({
        type: "updateDocumentDescription",
        value: { document: doc.document.id, description: doc.description },
      });
    if (oldDoc.revision.revisionId !== doc.revision.revisionId)
      changes.push({
        type: "updateDocument",
        value: { document: doc.document.id, revision: doc.revision },
      });

    return changes;
  });

  return inserts.concat(updates, deletes);
};
