import React from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { DeletePartErrorToaster } from "../PartDetails/Toaster";
import { pdmKeys } from "./queries";

export default function useDeletePart() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  return useMutation((partId: number) => api.pdm.deletePart(partId), {
    onSuccess: () => {
      queryClient.invalidateQueries(pdmKeys.all);
    },
    onError: (error, variables: number) => {
      toaster.show({
        intent: "danger",
        icon: "warning-sign",
        message: <DeletePartErrorToaster partId={variables} />,
        timeout: 2000,
      });
    },
  });
}
