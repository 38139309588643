import { ButtonSP, ButtonSPProps } from "@aletiq/design-system";
import classNames from "classnames";
import React from "react";
import styles from "./NavigationButton.module.scss";

export default function NavigationButton(
  props: {
    path?: string | string[];
  } & ButtonSPProps
) {
  const { className, ...restProps } = props;

  const buttonClassName = classNames(styles.button, className);

  return <ButtonSP className={buttonClassName} view="smooth" {...restProps} />;
}
