import {
  ButtonSP,
  DropdownButton,
  Field,
  FieldLabel,
  Input,
  MultistepDialog,
  Select,
  Switch,
} from "@aletiq/design-system";
import { OperationBill } from "@aletiq/types";
import { Icon } from "@blueprintjs/core";
import classNames from "classnames";
import Papa, { ParseResult } from "papaparse";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import Color from "../../../../styles/color.module.scss";
import { isNotUndefined, useTranslations } from "../../../../util";
import useAddOperations from "../hooks/useAddOperations";
import styles from "./ImportOperationsForm.module.scss";

export default function ImportOperationsForm(props: {
  projectId: number;
  bill: OperationBill;
  onClose: () => void;
}) {
  const { projectId, bill, onClose } = props;
  const tr = useTranslations();

  const [file, setFile] = useState<File | null>(null);

  const [ignoreHeaders, setIgnoreHeaders] = useState(false);
  const [result, setResult] = useState<ParseResult<string[]> | null>(null);
  const [specifiedDelimiter, setSpecifiedDelimiter] = useState<string | null>(
    null
  );

  const [numberColumn, setNumberColumn] = useState<number | null>(null);
  const [nameColumn, setNameColumn] = useState<number | null>(null);
  const [descriptionColumn, setDescriptionColumn] = useState<number | null>(
    null
  );

  const headers = result?.data?.[0] ?? [];

  useEffect(() => {
    if (file) {
      Papa.parse<string[]>(file, {
        skipEmptyLines: true,
        delimiter: specifiedDelimiter ?? undefined,
        complete: (result) => setResult(result),
      });
    }
  }, [file, specifiedDelimiter]);

  const { mutate: addOperation, isLoading } = useAddOperations(projectId, bill);

  const data = result?.data?.slice(ignoreHeaders ? 0 : 1) ?? [];
  const isNumberColumnValid =
    numberColumn !== null
      ? data.every((arr) => {
          const number = Number.parseInt(arr[numberColumn]);
          return !Number.isNaN(number);
        })
      : undefined;

  const isPrimaryDisabled = (step: number) => {
    return step === 1 && file === null;
  };

  const handleSubmit = () => {
    const operations = data
      .map((arr) => {
        const number =
          numberColumn !== null ? Number.parseInt(arr[numberColumn]) : null;
        const name = nameColumn !== null ? arr[nameColumn] : "";
        const description =
          descriptionColumn !== null ? arr[descriptionColumn] : "";
        if (number !== null && !Number.isNaN(number)) {
          return { number, name, description };
        }
        return undefined;
      })
      .filter(isNotUndefined);

    addOperation(operations, { onSettled: () => onClose() });
  };

  return (
    <MultistepDialog
      isOpen
      icon="panel-table"
      title={tr.translateAsString(
        "project.operation-bill.import-operations.title"
      )}
      onClose={onClose}
      className={styles.form}
      nextButtonProps={{}}
      submitButtonProps={{
        onClick: handleSubmit,
        isDisabled: isPrimaryDisabled,
        isLoading,
      }}
      panels={[
        {
          id: "select-file",
          title: tr.translateAsString(
            "project.operation-bill.import-operations.select-file.title"
          ),
          panel: <SelectFile file={file} setFile={setFile} />,
        },
        {
          id: "configure",
          title: tr.translateAsString(
            "project.operation-bill.import-operations.configure.title"
          ),
          panel: (
            <SelectColumns
              numberColumn={numberColumn}
              setNumberColumn={setNumberColumn}
              isNumberColumnValid={isNumberColumnValid}
              nameColumn={nameColumn}
              setNameColumn={setNameColumn}
              descriptionColumn={descriptionColumn}
              setDescriptionColumn={setDescriptionColumn}
              ignoreHeaders={ignoreHeaders}
              headers={headers}
              toggleIgnoreHeaders={() => setIgnoreHeaders((v) => !v)}
              delimiter={
                specifiedDelimiter === null
                  ? result?.meta.delimiter ?? ","
                  : specifiedDelimiter
              }
              setSpecifiedDelimiter={(value) => setSpecifiedDelimiter(value)}
            />
          ),
        },
      ]}
    />
  );
}

function SelectFile(props: {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}) {
  const { file, setFile } = props;
  const tr = useTranslations();
  return (
    <div className={styles.form_content_section}>
      <div>
        {tr.translate(
          "project.operation-bill.import-operations.select-file.caption"
        )}
      </div>
      <div className={styles.file_input_container}>
        <FileInput value={file} onChange={(value) => setFile(value)} />
      </div>
    </div>
  );
}

function SelectColumns(props: {
  numberColumn: number | null;
  setNumberColumn: (index: number | null) => void;
  isNumberColumnValid?: boolean;
  nameColumn: number | null;
  setNameColumn: (index: number | null) => void;
  descriptionColumn: number | null;
  setDescriptionColumn: (index: number | null) => void;
  ignoreHeaders: boolean;
  headers: string[];
  toggleIgnoreHeaders: () => void;
  delimiter: string;
  setSpecifiedDelimiter: (delimiter: string) => void;
}) {
  const {
    numberColumn,
    setNumberColumn,
    isNumberColumnValid,
    nameColumn,
    setNameColumn,
    descriptionColumn,
    setDescriptionColumn,
    ignoreHeaders,
    headers,
    toggleIgnoreHeaders,
    delimiter,
    setSpecifiedDelimiter,
  } = props;
  const tr = useTranslations();
  return (
    <div className={styles.form_content}>
      <FormSection
        label={tr.translateAsString(
          "project.operation-bill.import-operations.configure.select-columns"
        )}
      >
        <ColumnSelectRow
          label={tr.translateAsString(
            "project.operation-bill.import-operations.configure.operation-number"
          )}
          error={
            isNumberColumnValid !== undefined && !isNumberColumnValid
              ? tr.translateAsString(
                  "project.operation-bill.import-operations.configure.operation-number.invalid"
                )
              : undefined
          }
          action={
            <ColumnSelect
              value={numberColumn}
              onChange={setNumberColumn}
              ignoreHeaders={ignoreHeaders}
              headers={headers}
            />
          }
        />
        <ColumnSelectRow
          label={tr.translateAsString(
            "project.operation-bill.import-operations.configure.operation-name"
          )}
          action={
            <ColumnSelect
              value={nameColumn}
              onChange={setNameColumn}
              ignoreHeaders={ignoreHeaders}
              headers={headers}
            />
          }
        />
        <ColumnSelectRow
          label={tr.translateAsString(
            "project.operation-bill.import-operations.configure.operation-description"
          )}
          action={
            <ColumnSelect
              value={descriptionColumn}
              onChange={setDescriptionColumn}
              ignoreHeaders={ignoreHeaders}
              headers={headers}
            />
          }
        />
      </FormSection>
      <FormSection label="Options">
        <div className={styles.form_options}>
          <Switch
            autoFocus={false}
            checked={!ignoreHeaders}
            className={styles.switch}
            onChange={() => toggleIgnoreHeaders()}
            label={tr.translateAsString(
              "project.operation-bill.import-operations.configure.include-headers"
            )}
            intent="primary"
          />
          <Field
            label={tr.translateAsString(
              "project.operation-bill.import-operations.configure.separator"
            )}
            className={Color["primary"]}
          >
            <Input
              value={delimiter}
              autoFocus={false}
              onChange={(value) => setSpecifiedDelimiter(value)}
            />
          </Field>
        </div>
      </FormSection>
    </div>
  );
}

function FormSection(props: { label: string; children?: ReactNode }) {
  const { label, children } = props;
  return (
    <div>
      <div className={styles.form_section_header}>{label}</div>
      <div className={styles.form_content_section}>{children}</div>
    </div>
  );
}

function ColumnSelectRow(props: {
  label: string;
  error?: string;
  action: ReactNode;
}) {
  const { label, action, error } = props;
  return (
    <div>
      <div className={styles.select_row}>
        <div className={styles.select_row_label}>{label}</div>
        <Icon icon="chevron-right" className={styles.form_icon} />
        {action}
      </div>
      {error ? (
        <FieldLabel className={Color["danger"]}>{error}</FieldLabel>
      ) : null}
    </div>
  );
}

function ColumnSelect(props: {
  onChange: (index: number | null) => void;
  ignoreHeaders: boolean;
  headers: string[];
  value: number | null;
}) {
  const { onChange, ignoreHeaders, headers, value } = props;
  const tr = useTranslations();
  const ignoreItem = {
    key: -1,
    text: tr.translateAsString(
      "project.operation-bill.import-operations.configure.select-columns.ignore"
    ),
  };
  const items = ignoreHeaders
    ? headers.map((_, idx) => ({
        key: idx,
        text: tr.translateAsString(
          "project.operation-bill.import-operations.configure.select-columns.column-number",
          { number: idx + 1 }
        ),
      }))
    : headers.map((v, idx) => ({
        key: idx,
        text: v,
        labelElement: tr.translateAsString(
          "project.operation-bill.import-operations.configure.select-columns.column-number",
          { number: idx + 1 }
        ),
      }));

  const handleSelect = (index: number) => {
    if (index === -1) {
      return onChange(null);
    }
    return onChange(index);
  };

  return (
    <Select
      filterable
      onItemSelect={handleSelect}
      items={[ignoreItem, ...items]}
    >
      <DropdownButton
        className={styles.select_row_action}
        color="primary"
        text={
          value !== null
            ? ignoreHeaders
              ? tr.translateAsString(
                  "project.operation-bill.import-operations.configure.select-columns.column-number",
                  { number: value + 1 }
                )
              : headers[value]
            : tr.translateAsString(
                "project.operation-bill.import-operations.configure.select-columns.button"
              )
        }
        view="outlined"
      />
    </Select>
  );
}

function FileInput(props: {
  value: File | null;
  onChange: (value: File | null) => void;
}) {
  const { value, onChange } = props;
  const tr = useTranslations();
  const fileInputRef = useRef<HTMLInputElement>(null);

  if (value !== null) {
    return (
      <div className={classNames(styles.file_input)}>
        <ButtonSP
          isDense
          view="flat"
          color="danger"
          icon="cross"
          className={styles.file_input_reset}
          onClick={() => onChange(null)}
        />
        <Icon icon={"blank"} size={24} className={styles.file_input_icon} />
        <div className={styles.file_input_text}>{value.name}</div>
      </div>
    );
  }
  return (
    <div
      className={classNames(styles.file_input, styles.actionnable)}
      {...dropFileProps(onChange)}
      onClick={() => fileInputRef.current?.click()}
    >
      <Icon
        icon={value != null ? "blank" : "plus"}
        size={24}
        className={styles.file_input_icon}
      />
      <div className={styles.file_input_text}>
        {tr.translate(
          "project.operation-bill.import-operations.select-file.button"
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        onChange={(ev) => {
          ev.currentTarget.files?.[0] && onChange(ev.currentTarget.files[0]);
        }}
        style={{ display: "none" }}
      />
    </div>
  );
}

function dropFileProps(onDropFile: (file: File) => void) {
  return {
    onDrop: (ev: React.DragEvent) => {
      ev.preventDefault();
      const file = ev.dataTransfer.files[0];
      file && onDropFile(file);
    },
    onDragEnter: (ev: React.DragEvent) => {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "copy";
    },
    onDragOver: (ev: React.DragEvent) => {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "copy";
    },
  };
}
