import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { integrationKeys } from "./queries";

export default function useIntegrationsErrorList() {
  const api = useApi();
  return useQuery(integrationKeys.allReports(), () =>
    api.integration.getIntegrationsReports()
  );
}
