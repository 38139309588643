import {
  CheckBox,
  Divider,
  Field,
  Icon,
  Input,
  Tag,
  TextArea,
} from "@aletiq/design-system";
import { PassportSpec, Project } from "@aletiq/types";
import { H4 } from "@blueprintjs/core";
import React from "react";
import { Subtitle } from "../../../../components";
import { useTranslations } from "../../../../util";
import { ProductImage } from "../../../project/common";
import { useProductVersions } from "../../../project/hooks";
import useListProjectDefinitions from "../../../project/hooks/useListProjectDefinitions";
import useProjectBills from "../../../project/Operations/hooks/useProjectBills";
import OperationBillSelect from "../../../project/Operations/OperationBillSelect";
import { ProjectDefinitionSelectMenu } from "../../../project/ProjectNomenclature/ProjectDefinitionSelectMenu";
import ProcessModelSelect from "../../../tasks/dialogs/ProcessModelSelect";
import { useProcessModels } from "../../../tasks/tasksSlice";
import { PassportConfigurationsInput } from "../../common";
import {
  PassportVersionPicker,
  resolveChoice,
  VersionChoice,
} from "../../common/PassportVersionPicker";
import styles from "./PassportCreationDialog.module.scss";

export default function PassportCreationForm(props: {
  passportSpec: PassportSpec;
  product: Project;
  onEditSpec: (passport: PassportSpec) => void;
}) {
  const { passportSpec, product, onEditSpec } = props;
  const tr = useTranslations();

  const { data: versions = [] } = useProductVersions(product.id);
  const { data: models = [] } = useProcessModels();

  const names = passportSpec.name
    .split(",")
    .map((name) => name.trim())
    .filter((name) => name !== "");

  const versionChoice: VersionChoice =
    versions[0] && passportSpec.projectIteration !== versions[0].id
      ? { option: "pick", version: passportSpec.projectIteration || null }
      : { option: "last_version" };

  const { data: bills = [] } = useProjectBills(product.id);
  const bill = bills.find((b) => b.id === passportSpec.articleBill);

  const { data: definitions = [] } = useListProjectDefinitions(product.id);
  const definition = definitions.find(
    (d) => d.index === passportSpec.parentDefinition
  );

  const handleSetDescription = (description: string) => {
    onEditSpec({ ...passportSpec, description });
  };

  const handleSetNames = (names: string) => {
    onEditSpec({ ...passportSpec, name: names });
  };

  const handleSetVersion = (versionChoice: VersionChoice) => {
    onEditSpec({
      ...passportSpec,
      projectIteration: resolveChoice(versionChoice, versions),
    });
  };

  const handleSetConfig = (fieldId: number, valueIds: number[]) => {
    const newConfig = { ...passportSpec.config, [fieldId]: valueIds };
    onEditSpec({ ...passportSpec, config: newConfig });
  };

  const handleToggleCreateProcess = () => {
    if (passportSpec.process) {
      onEditSpec({ ...passportSpec, process: undefined });
    } else {
      onEditSpec({
        ...passportSpec,
        process: { title: "", model: null },
      });
    }
  };

  const handleSetProcessModel = (modelId: number | null) => {
    if (!passportSpec.process) {
      return;
    }

    const model = models.find((m) => m.id === modelId);

    if (!model) {
      return;
    }

    const hasCustomTitle =
      passportSpec.process.title !== "" &&
      passportSpec.process.title !== model.nomenclature;

    onEditSpec({
      ...passportSpec,
      process: {
        title: hasCustomTitle ? passportSpec.process.title : model.nomenclature,
        model: modelId,
      },
    });
  };

  const handleSetProcessName = (title: string) => {
    onEditSpec({
      ...passportSpec,
      process: {
        model: passportSpec.process?.model ?? null,
        title,
      },
    });
  };

  const handleSetBill = (billId: number | null) => {
    onEditSpec({ ...passportSpec, articleBill: billId });
  };

  const handleSetDefinition = (definitionIdx: number) => {
    onEditSpec({
      ...passportSpec,
      parentDefinition: definitionIdx,
    });
  };

  return (
    <>
      <div className={styles.name_version_wrapper}>
        <ProductImage productId={product.id} className={styles.img} />
        <div>
          <Field
            label={
              <span className={styles.label}>
                {tr.translate("dialog.new-passport.name.label")}
              </span>
            }
          >
            <TextArea
              isFixed
              autoFocus
              fill
              isDense
              value={passportSpec.name}
              onChange={handleSetNames}
              className={styles.text_area}
            />

            {names.length === 0 ? (
              <span className={styles.caption}>
                {tr.translate("dialog.new-passport.name.info")}
              </span>
            ) : names.length === 1 ? (
              <span className={styles.caption}>
                {tr.translate("dialog.new-passport.name.passport-created", {
                  tag: (
                    <Tag isDense unclickable>
                      {names[0]}
                    </Tag>
                  ),
                })}
              </span>
            ) : (
              <span className={styles.caption}>
                {tr.translate("dialog.new-passport.name.passports-created", {
                  length: names.length,
                  tags: names.map((name, index) => (
                    <Tag isDense unclickable key={index} className={styles.tag}>
                      {name}
                    </Tag>
                  )),
                })}
              </span>
            )}
          </Field>
        </div>
      </div>
      <div className={styles.bill_definition_row}>
        <Field
          label={
            <Subtitle text={tr.translateAsString("project.definition.index")} />
          }
        >
          {definition && (
            <ProjectDefinitionSelectMenu
              isDense={false}
              activeDefinition={definition}
              definitions={definitions}
              onSelect={handleSetDefinition}
            />
          )}
        </Field>
        {product.isTool ? (
          <Field>
            <PassportVersionPicker
              pick={versionChoice}
              versions={versions}
              onChange={handleSetVersion}
            />
          </Field>
        ) : (
          <Field
            label={
              <Subtitle
                text={tr.translateAsString("project.operation-bill.bill-index")}
              />
            }
          >
            <OperationBillSelect
              isDense={false}
              disabled={bills.length === 0}
              activeBill={bill}
              bills={bills}
              onSelect={handleSetBill}
            />
          </Field>
        )}
      </div>
      <Divider intent={"primary"} />
      <Field
        label={
          <span className={styles.label}>
            {tr.translateAsString("generic.label.description")}
          </span>
        }
      >
        <Input
          fill
          autoFocus={false}
          placeholder={tr.translateAsString("generic.label.description")}
          value={passportSpec.description}
          onChange={handleSetDescription}
        />
      </Field>
      <PassportConfigurationsInput
        configSpec={product.options}
        config={passportSpec.config}
        onSetConfig={handleSetConfig}
      />
      <Divider intent="primary" />

      <H4 className={styles.workflow_title}>
        <Icon icon="numbered-list" />
        {tr.translate("dialog.new-passport.workflow")}
      </H4>

      <CheckBox
        fill
        checked={!!passportSpec.process}
        onChange={handleToggleCreateProcess}
        label={tr.translateAsString("dialog.new-passport.create.workflow")}
        className={styles.checkbox}
      />

      {passportSpec.process && (
        <Field
          label={
            <span className={styles.label}>
              {tr.translate("dialog.new-passport.create.process.label")}
            </span>
          }
        >
          <ProcessModelSelect
            onChange={handleSetProcessModel}
            selectedModel={passportSpec.process.model ?? null}
          />
        </Field>
      )}

      {passportSpec.process && (
        <Field
          label={
            <span className={styles.label}>
              {tr.translate("dialog.new-passport.create.title.label")}
            </span>
          }
        >
          <Input
            fill
            placeholder={tr.translateAsString(
              "dialog.new-passport.create.title.placeholder"
            )}
            value={passportSpec.process.title}
            onChange={handleSetProcessName}
          />
        </Field>
      )}
    </>
  );
}
