import { Entity } from "@aletiq/types";
import { Card, NonIdealState, Spinner, SpinnerSize } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  AlignRight,
  Button,
  EntityLink,
  H3,
  ItemSelect,
  SearchInput,
  Select,
} from "../../../components";
import { isInString, sortByNumber, useTranslations } from "../../../util";
import useMyEntity from "../hooks/useMyEntity";
import styles from "./HomeShortcuts.module.scss";

const ENTITIES_SORT_TABLES: Record<string, number> = {
  passport: 6,
  project: 1,
  file: 2,
  part: 3,
  assembly: 4,
  drawing: 5,
};

type EntityType = "all" | "passport" | "article" | "tool" | "part" | "file";

function sortByEntityType<T>(array: T[], key: (value: T) => string) {
  return sortByNumber(
    array,
    (value) => ENTITIES_SORT_TABLES[key(value)] ?? Number.MAX_VALUE
  );
}

export default function HomeShortcuts(props: { className?: string }) {
  const { className } = props;
  const tr = useTranslations();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<EntityType>("all");

  const { data: entitiesFetched = [], isLoading } = useMyEntity();

  const selectOptions: ItemSelect<EntityType>[] = [
    {
      key: "all",
      text: tr.translateAsString("home.shortcuts.filter.all"),
    },
    {
      key: "passport",
      text: tr.translateAsString("home.shortcuts.filter.passports"),
    },
    {
      key: "article",
      text: tr.translateAsString("home.shortcuts.filter.articles"),
    },
    {
      key: "tool",
      text: tr.translateAsString("home.shortcuts.filter.tools"),
    },
    {
      key: "part",
      text: tr.translateAsString("home.shortcuts.filter.parts"),
    },
    {
      key: "file",
      text: tr.translateAsString("home.shortcuts.filter.documents"),
    },
  ];

  const selectedOptionText = selectOptions.find(
    (option) => option.key === filter
  )?.text;

  const entities = filterEntities(entitiesFetched, search, filter);
  const sortedEntities = sortByEntityType(entities, (entity) => entity.type);

  return (
    <div className={className}>
      <div className={styles.shortcuts_header}>
        <H3>{tr.translate("home.shortcuts.title")}</H3>
        <AlignRight />
        <Select
          items={selectOptions}
          onItemSelect={setFilter}
          activeItem={filter}
          intent="outlined"
          isDense
        >
          <Button intent="outlined" rightIcon="caret-down" isDense>
            {selectedOptionText || tr.translate("home.shortcuts.filter.all")}
          </Button>
        </Select>
        <SearchInput
          value={search}
          onChange={setSearch}
          className={styles.search}
          isDense
        />
      </div>
      <Card className={styles.card}>
        {isLoading && <Spinner size={SpinnerSize.SMALL} />}
        {!isLoading &&
          sortedEntities.map((entity) => (
            <div
              className={styles.row}
              key={`${entity.type}-${entity.entity.id}`}
            >
              <EntityLink entity={entity} search={search} />
            </div>
          ))}
        {!isLoading && sortedEntities.length === 0 && (
          <NonIdealState
            icon={<img src="/assets/no-data.svg" height={80} alt="no-data" />}
            description={tr.translateAsString(
              search === ""
                ? "home.shortcuts.none"
                : "home.shortcuts.none.filtered"
            )}
          />
        )}
      </Card>
    </div>
  );
}

function entityName(entity: Entity): string {
  if (entity.type === "process") {
    return entity.entity.title;
  } else if (entity.type === "passport") {
    return entity.entity.name;
  } else if (entity.type === "project") {
    return entity.entity.name;
  } else if (entity.type === "file") {
    return entity.entity.name;
  } else if (entity.type === "part") {
    return entity.entity.name;
  }
  return "";
}

const filterEntities = (
  entities: Entity[],
  search: string,
  type: EntityType
): Entity[] => {
  const predicate = (entity: Entity) => {
    if (type === "all") {
      return isInString(entityName(entity), search);
    }
    if (type === "article") {
      return (
        entity.type === "project" &&
        isInString(entityName(entity), search) &&
        entity.entity.isArticle
      );
    }
    if (type === "tool") {
      return (
        entity.type === "project" &&
        isInString(entityName(entity), search) &&
        entity.entity.isTool
      );
    }
    return entity.type === type && isInString(entityName(entity), search);
  };

  return entities.filter(predicate);
};
