import { useQuery } from "react-query";
import useApi from "../../app/useApi";
import { userKeys } from "../../features/administration/hooks/queries";

export default function useUsers(
  withEmail: boolean = false,
  withDeactivated: boolean = false
) {
  const api = useApi();
  return useQuery(userKeys.list(withEmail, withDeactivated), () =>
    api.auth.listUsers(withEmail, withDeactivated)
  );
}
