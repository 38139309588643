import React from "react";
import { ColumnOption, SelectColumns } from "../../../../components";
import { ColumnOptionConfig } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import { ProductComponentsColumn } from "./useProjectComponentsColumns";

export default function SelectProjectComponentsColumns(props: {
  columnConfig: ColumnOptionConfig<ProductComponentsColumn>;
  onToggleColumn: (column: ProductComponentsColumn) => void;
  children: React.ReactNode;
}) {
  const { columnConfig, onToggleColumn, children } = props;
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("product");

  const columnOptions: ColumnOption<ProductComponentsColumn>[] = [
    {
      id: "projectName",
      label: tr.translate("generic.label.name"),
      isMandatory: true,
    },
    {
      id: "definition",
      label: tr.translate("project.definition.index"),
    },
    {
      id: "quantity",
      label: tr.translate("generic.label.quantity"),
    },
    {
      id: "description",
      label: tr.translate("generic.label.description"),
    },
    {
      id: "createdAt",
      label: tr.translate("generic.label.creation-date.title"),
    },
    {
      id: "updatedAt",
      label: tr.translate("generic.label.last-update.title"),
    },
    {
      id: "status",
      label: tr.translate("generic.label.status"),
    },
    ...properties,
  ];

  return (
    <SelectColumns
      config={columnConfig}
      options={columnOptions}
      onToggleColumn={onToggleColumn}
      objectType="product"
    >
      {children}
    </SelectColumns>
  );
}
