import { EntityId, ProcessSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../../app/useApi";
import { useToaster } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { activityKeys } from "../../../../activities/hooks/queries";
import { notificationKeys } from "../../../../notifications/hooks/queries";
import { processKeys, taskKeys } from "../../../hooks/queries";
import { makeIdentifiedTasks, TaskEditorState } from "../taskEditUtils";

export default function useCreateWorkflowAndTasks() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (params: {
      workflow: ProcessSpec;
      tasks: TaskEditorState;
      entities: EntityId[];
    }) => {
      const { workflow: spec, tasks, entities } = params;

      const tasksInfo = [...tasks.created, ...tasks.edited];

      const workflowId = await api.task.createProcess({
        ...spec,
        model: null,
      });

      for (const entity of entities) {
        await api.task.addProcessRelation(workflowId, entity);
      }

      const idMap: Record<number, number> = {};
      for (const task of tasksInfo) {
        const newId = await api.task.createTask(
          { ...task.spec, owners: [] },
          workflowId
        );
        idMap[task.id] = newId;
      }

      const identifiedTasks = makeIdentifiedTasks(idMap, tasksInfo);
      await api.task.updateProcessTasks(workflowId, identifiedTasks);

      await api.task.updateProcess(
        {
          model: spec.model,
        },
        workflowId
      );

      return workflowId;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(processKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
        queryClient.invalidateQueries(notificationKeys.count());
        queryClient.invalidateQueries(taskKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          message: tr.translate("toaster.error.workflow.update"),
        });
      },
    }
  );
}
