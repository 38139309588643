import { BOMEntry, Project, ProjectDefinition } from "@aletiq/types";
import { useMemo } from "react";
import { useColumnConfig } from "../../../../hooks";
import { Diff, isInString } from "../../../../util";
import { useProjectBOM } from "../../hooks";
import useProjectBOMDefinitions from "../../hooks/useProjectBOMDefinitions";
import useProjectRecord from "../../hooks/useProjectsRecord";
import { diffComponents } from "../compareDefinition";
import { DEFAULT_PRODUCT_COMPONENTS_COLUMNS } from "./useProjectComponentsColumns";

export type ResolvedComponent = {
  project: Project;
  definition: ProjectDefinition;
  projectName: string;
  diff: Diff<BOMEntry>;
  description: string;
  updatedAt: string;
  createdAt: string;
} & Omit<BOMEntry, "definition">;

export default function useProjectComponentsState(
  projectId: number,
  definitionIdx: number,
  search: string
) {
  const { data: components = [], isLoading: isBOMLoading } = useProjectBOM(
    projectId,
    definitionIdx
  );

  const { data: previousComponents = [] } = useProjectBOM(
    projectId,
    definitionIdx - 1
  );

  const componentsDiff = diffComponents(components, previousComponents);

  const { data: definitions, isLoading: isDefinitionsLoading } =
    useProjectBOMDefinitions(components);

  const { data: projects, isLoading: isProjectLoading } = useProjectRecord(
    components.map((c) => c.entity)
  );
  const filteredComponents: ResolvedComponent[] = useMemo(
    () =>
      projects !== undefined && definitions !== undefined
        ? components
            .map((c) => ({
              ...c,
              definition: definitions[c.entity][c.definition],
              project: projects[c.entity],
              projectName: projects[c.entity].name,
              diff: componentsDiff[c.entity],
              description: projects[c.entity].description,
              createdAt: projects[c.entity].createdAt,
              updatedAt: projects[c.entity].updatedAt,
            }))
            .filter((c) => isInString(c.projectName, search))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projects, search]
  );

  const { config: columnConfig, handleToggleColumn } = useColumnConfig(
    DEFAULT_PRODUCT_COMPONENTS_COLUMNS
  );

  return {
    components: filteredComponents,
    isLoading: isBOMLoading || isProjectLoading || isDefinitionsLoading,
    totalComponents: components.length,
    columnConfig,
    onToggleColumn: handleToggleColumn,
  };
}
