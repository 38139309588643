import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { documentKeys } from "./queries";

export default function useDocumentRevisionSignatures(
  documentId: number | undefined,
  revisionId: number | undefined
) {
  const api = useApi();
  return useQuery(
    documentKeys.revisionSignatures(documentId!, revisionId!),
    () => api.document.getDocumentRevisionSignatures(documentId!, revisionId!),
    { enabled: documentId !== undefined && revisionId !== undefined }
  );
}
