import Chart from "chart.js/auto";
import React from "react";
import { Line } from "react-chartjs-2";
import { Period } from "@aletiq/types";
import {
  createCustomGraphTooltip,
  graphGrey10,
  graphGrey100,
  graphGrey50,
  indexBy,
  sum,
  useTranslations,
} from "../../../../util";
import { useProcessModels } from "../../tasksSlice";
import ModelAnalysisCard from "../common/ModelAnalysisCard";
import { mkResolutionTooltip } from "../common/ModelAnalysisTooltip/ModelAnalysisTooltip";
import { useAnalysisModelsResolution } from "../hooks";
import {
  addOpacityToHexColor,
  getAbscissaLabels,
  resetOpactiyForHexColor,
} from "../util";

export default function ModelAnalysisResolution(props: {
  selectedModels: number[];
  period: Period;
}) {
  const { selectedModels, period } = props;
  const tr = useTranslations();

  const { data: models = [] } = useProcessModels();
  const { data: resolutionDataByModel, isLoading } =
    useAnalysisModelsResolution({
      selectedModels,
      period,
    });

  const abscissaLabel = getAbscissaLabels(period);
  const modelById = indexBy(models, "id");

  const data = {
    labels: abscissaLabel,
    datasets: selectedModels.map((model) => ({
      label: modelById[model].title,
      data: resolutionDataByModel?.[model].map((seconds) =>
        seconds ? seconds / (3600 * 24) : undefined
      ),
      backgroundColor: modelById[model]?.color ?? "",
      borderColor: modelById[model]?.color ?? "",
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // needed to set custom canvas sizing
    datasets: {
      line: {
        cubicInterpolationMode: "monotone", // Smooth line
        pointRadius: 0,
        pointHitRadius: 10,
        borderWidth: 2,
        spanGaps: true,
      },
    },
    plugins: {
      legend: {
        align: "start",
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 15,
          padding: 24,
          color: graphGrey100,
          font: {
            size: 14,
          },
        },
        onClick: () => {}, // override the default behavior
        onHover: (
          _: React.MouseEvent,
          item: { datasetIndex: number },
          legend: Chart.Legend
        ) => {
          legend.chart.data.datasets.forEach((set: any, index: number) => {
            const color = addOpacityToHexColor(
              set.backgroundColor,
              index === item.datasetIndex
            );

            set.backgroundColor = color;
            set.borderColor = color;
          });

          legend.chart.update();
        },
        onLeave: (
          _: React.MouseEvent,
          __: { index: number },
          legend: Chart.Legend
        ) => {
          legend.chart.data.datasets.forEach((set: any) => {
            const color = resetOpactiyForHexColor(set.backgroundColor);

            set.backgroundColor = color;
            set.borderColor = color;
          });
          legend.chart.update();
        },
      },
      tooltip: {
        enabled: false,
        mode: "nearest",
        xAlign: "center",
        yAlign: "top",
        external: createCustomGraphTooltip({
          graphId: "resolution",
          tooltipComponent: mkResolutionTooltip(abscissaLabel),
        }),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        color: graphGrey50,
        grid: {
          drawBorder: false,
          color: graphGrey10,
        },
        title: {
          display: true,
          text: tr.translateAsString("analysis.workflow.days"),
          align: "end",
          color: graphGrey50,
        },
      },
      x: {
        color: graphGrey50,
        grid: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: "Date",
        },
      },
    },
  };

  let isEmptyState = !isLoading;
  for (const modelId of selectedModels) {
    isEmptyState =
      isEmptyState && sum(resolutionDataByModel?.[modelId] ?? []) === 0;
  }

  return (
    <ModelAnalysisCard
      icon="time"
      title={tr.translateAsString("analysis.workflow.duration")}
      tooltipText={tr.translateAsString("analysis.workflow.duration.tooltip")}
      isLoading={isLoading}
      isEmptyState={isEmptyState}
    >
      <Line data={data} options={options} type="line" />
    </ModelAnalysisCard>
  );
}
