import React from "react";
import { PageContents, PageTitle } from "../../../components";
import { useTranslations } from "../../../util";
import {
  ANALYTICS_VISITED_WORKFLOW_MODELS,
  useTrackEvent,
} from "../../../analytics";
import WorkflowModelList from "./WorkflowModelList";

export default function ProcessModels() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_WORKFLOW_MODELS);

  return (
    <PageContents domTitle={tr.translateAsString("page.title.models")}>
      <PageTitle icon="layers">
        {tr.translate("navbar.workflows.models")}
      </PageTitle>
      <WorkflowModelList />
    </PageContents>
  );
}
