import {
  AnchorButton,
  EditableText,
  Icon,
  Tooltip,
} from "@aletiq/design-system";
import { Task, User } from "@aletiq/types";
import React, { Fragment } from "react";
import {
  DateInput,
  EditableUserList,
  StatusTag,
} from "../../../../../components";
import { formatDate, toDate, useTranslations } from "../../../../../util";
import TaskDependencyMultiSelect from "../../TaskDependencyMultiSelect";
import TaskEditInfo from "../taskEditUtils";
import styles from "./TaskRowEditor.module.scss";

const TaskRowEditor = React.memo(
  (props: {
    hasLockedDependencies: boolean;
    onDelete: (task: number, order: number) => void;
    onTaskEdit: (specInfo: TaskEditInfo) => void;
    workflowTasks: Task[];
    workflowColor: string;
    task: Task;
    isNewTask?: boolean;
    allowedTaskOwners: User[];
  }) => {
    const {
      onDelete,
      onTaskEdit,
      workflowTasks,
      workflowColor,
      isNewTask,
      task,
      allowedTaskOwners,
    } = props;

    const tr = useTranslations();

    const today = new Date();
    const otherTasks = workflowTasks.filter((t) => t.id !== task.id);

    const editedTask = {
      id: task.id,
      spec: {
        id: task.id,

        title: task.title,
        description: task.description,
        dueDate: task.dueDate,
        owners: task.owners,
      },
      deps: task.dependencies,
      order: task.order,
    };

    const handleEditTaskOwners = (owners: number[]) => {
      onTaskEdit({ ...editedTask, spec: { ...editedTask.spec, owners } });
    };

    const handleEditDueDate = (newDate: Date | null) => {
      onTaskEdit({
        ...editedTask,
        spec: {
          ...editedTask.spec,
          dueDate: newDate ? formatDate(newDate) : null,
        },
      });
    };

    const handleEditTaskTitle = (title: string) => {
      onTaskEdit({
        ...editedTask,
        spec: { ...editedTask.spec, title },
      });
    };

    const handleEditDependencies = (deps: number[]) => {
      onTaskEdit({
        ...editedTask,
        deps,
      });
    };

    return (
      <Fragment>
        <td className={styles.name_cell}>
          <Icon icon="drag-handle-vertical" className={styles.drag_icon} />
          <span
            className={styles.name_wrapper}
            style={{
              borderLeft: `6px solid ${workflowColor}`,
            }}
          >
            <EditableText
              isFill
              value={editedTask.spec.title}
              onChange={handleEditTaskTitle}
              placeholder={tr.translateAsString(
                "task.dialog.new-task.title.label"
              )}
            />
          </span>
        </td>
        <td>
          <EditableUserList
            users={task.owners}
            onEditSelection={handleEditTaskOwners}
            className={styles.owners_cell}
            allowedUsers={allowedTaskOwners}
          />
        </td>
        <td>
          <DateInput
            isDense
            addSpaces
            minDate={today}
            onChange={handleEditDueDate}
            value={
              editedTask.spec.dueDate ? toDate(editedTask.spec.dueDate) : null
            }
          />
        </td>
        <td>
          <TaskDependencyMultiSelect
            task={task}
            workflowTasks={otherTasks}
            onDepSelected={handleEditDependencies}
            workflowColor={workflowColor}
          />
        </td>
        <td>
          {!isNewTask && (
            <StatusTag isDense isDisabled>
              {task.status}
            </StatusTag>
          )}
        </td>
        <td>
          <div className={styles.actions_wrapper}>
            <Tooltip content={tr.translateAsString("generic.action.delete")}>
              <AnchorButton
                isDense
                icon="trash"
                onClick={() => onDelete(editedTask.id, task.order)}
              />
            </Tooltip>
          </div>
        </td>
      </Fragment>
    );
  }
);

export default TaskRowEditor;
