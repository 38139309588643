import {
  Button,
  ButtonSP,
  Card,
  EditableText,
  Icon,
  Tooltip,
} from "@aletiq/design-system";
import { ProcessModel, ProcessModelSpec, TaskModelOwner } from "@aletiq/types";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { Subtitle } from "../../../../components";
import { useUsers } from "../../../../hooks";
import { purple, useTranslations } from "../../../../util";
import { TaskDependencyMultiSelect, TaskTable } from "../../common";
import { useWorkflowColor } from "../../hooks";
import { findExistingCycle } from "../cycleDetection";
import CyclicModelWarning from "../CyclicModelWarning";
import styles from "./Editor.module.scss";
import { handleDropTask } from "./handleDragNDrop";
import ModelColorSelect from "./ModelColorSelect";
import ModelEditorHeader from "./ModelEditorHeader";
import TaskModelOwnerSelect from "./TaskModelOwnerSelect";
export default function Editor(props: {
  newProcess?: boolean;
  model?: ProcessModel;
  onSave: (model: ProcessModelSpec) => void;
  onDiscard: () => void;
}) {
  const { newProcess, model, onSave, onDiscard } = props;
  const tr = useTranslations();

  const [validationFailed, setValidationFailed] = useState(false);
  const [liveModel, setLiveModel_] = useState<ProcessModelSpec>(() => {
    if (!model) return emptyModel;
    if (model.taskModels.length === 0)
      return { ...model, taskModels: emptyModel.taskModels };
    return model;
  });
  const [draggedItem, setDraggedItem] = useState<number | undefined>();
  const [dragTarget, setDraggedTarget] = useState<
    { order: number; posY: number } | undefined
  >();

  const { data: allUsers = [] } = useUsers();
  const allowedTaskOwners = useMemo(
    () =>
      allUsers.filter((u) => u.permissions.includes("update:own-task-status")),
    [allUsers]
  );

  const setLiveModel = (
    update: ((current: ProcessModelSpec) => ProcessModelSpec) | ProcessModelSpec
  ) => {
    setValidationFailed(false);
    setLiveModel_(update);
  };

  //check dependencies to avoid tasks that depend on each other
  const cyclicDependencies = useMemo(
    () =>
      findExistingCycle(
        liveModel.taskModels.map((t) => t.dependencies.map((d) => d - 1))
      ).map((t) => t + 1),
    [liveModel.taskModels]
  );

  const handleSave = () => {
    const isInvalid =
      cyclicDependencies.length > 0 ||
      liveModel.title === "" ||
      liveModel.taskModels.some((t) => t.title === "");
    if (isInvalid) return setValidationFailed(true);
    onSave(liveModel);
  };

  const updateTitle = (title: string) =>
    setLiveModel((model) => ({ ...model, title }));

  const updateProcessTitle = (nomenclature: string) => {
    setLiveModel((model) => ({ ...model, nomenclature }));
  };

  const updateTaskTitle = (taskIndex: number, title: string) => {
    setLiveModel((model) => {
      const newTasks = [...model.taskModels];

      newTasks[taskIndex] = { ...newTasks[taskIndex], title: title };
      return {
        ...model,
        taskModels: newTasks,
      };
    });
  };

  const updateTaskDescription = (taskIndex: number, description: string) => {
    setLiveModel((model) => {
      const newTasks = [...model.taskModels];

      newTasks[taskIndex] = {
        ...newTasks[taskIndex],
        description: description,
      };

      return {
        ...model,
        taskModels: newTasks,
      };
    });
  };

  const updateTaskAssignee = (
    taskIndex: number,
    assignedUsers: TaskModelOwner[]
  ) => {
    setLiveModel((model) => {
      const newTasks = [...model.taskModels];

      newTasks[taskIndex] = { ...newTasks[taskIndex], owners: assignedUsers };

      return {
        ...model,
        taskModels: newTasks,
      };
    });
  };

  const handleSetDependencies = (taskId: number, dependencies: number[]) =>
    setLiveModel((model) => ({
      ...model,
      taskModels: model.taskModels.map((task) => {
        if (task.id !== taskId) return task;
        return {
          ...task,
          dependencies,
        };
      }),
    }));

  const addTask = () => {
    const model = liveModel;
    const lastId = Math.max(...model.taskModels.map((model) => model.id));
    const nextId = lastId < 1 ? 1 : lastId + 1;
    setLiveModel({
      ...model,
      taskModels: [
        ...model.taskModels,
        {
          id: nextId,
          title: "",
          description: "",
          owners: [],
          dependencies: [],
        },
      ],
    });
  };

  const deleteTask = (id: number) => () => {
    const model = liveModel;

    const updateId = (taskId: number) => (taskId < id ? taskId : taskId - 1);

    setLiveModel({
      ...model,
      taskModels: model.taskModels.flatMap((task) => {
        if (task.id === id) return [];
        else {
          return [
            {
              ...task,
              id: updateId(task.id),
              dependencies: task.dependencies
                .filter((dep) => dep !== id)
                .map(updateId),
            },
          ];
        }
      }),
    });
  };

  const handleDrop = () => {
    if (
      dragTarget !== undefined &&
      draggedItem !== undefined &&
      dragTarget.order !== draggedItem
    ) {
      setLiveModel_({
        ...liveModel,
        taskModels: handleDropTask(
          dragTarget.order,
          draggedItem,
          liveModel.taskModels
        ),
      });
    }
    setDraggedTarget(undefined);
    setDraggedItem(undefined);
  };

  const handleDragLeave = (ev: React.DragEvent, order: number) => {
    //if item is dragged below the last list item, then set target order to list length
    if (
      order === liveModel.taskModels.length - 1 &&
      dragTarget &&
      ev.clientY > dragTarget?.posY
    ) {
      setDraggedTarget({
        order: liveModel.taskModels.length,
        posY: ev.clientY,
      });
    } else if (!dragTarget || dragTarget?.order !== order) {
      setDraggedTarget({ order: order, posY: ev.clientY });
    }
  };

  const handleUpdateColor = (color: string) => {
    setLiveModel_({ ...liveModel, color });
  };

  const defaultModelColor = useWorkflowColor(model);
  const modelColor =
    liveModel.color !== "" ? liveModel.color : defaultModelColor;

  return (
    <div className={styles.container}>
      <ModelEditorHeader
        liveModel={liveModel}
        onEditTitle={updateTitle}
        onSaveChanges={handleSave}
        onDiscardChange={onDiscard}
        validationFailed={validationFailed}
        disableSubmit={cyclicDependencies.length > 0}
      />
      <Card
        icon="flow-linear"
        title={
          <EditableText
            placeholder={tr.translateAsString("workflow.model.workflow-title")}
            value={liveModel.nomenclature}
            onChange={updateProcessTitle}
            className={styles.model_nomenclature}
          />
        }
        headerActions={
          <>
            <Subtitle text={tr.translateAsString("model.color.edit")} />
            <ModelColorSelect
              colorHex={liveModel.color}
              className={styles.color_selector}
              onSetColor={handleUpdateColor}
            />
          </>
        }
      >
        <div
          onDragEnter={(ev) => ev.preventDefault()}
          onDragOver={(ev) => ev.preventDefault()}
        >
          {cyclicDependencies.length > 0 && (
            <CyclicModelWarning className={styles.warning} />
          )}
          <TaskTable color={modelColor}>
            <thead>
              <tr>
                <th>{tr.translate("tasklist.task")}</th>
                <th>{tr.translate("generic.label.description")}</th>
                <th>{tr.translate("tasklist.assignee")}</th>
                <th>{tr.translate("workflow.model.task.dependencies")}</th>
                <th className={styles.action_col} />
              </tr>
            </thead>
            <tbody
              className={classNames(
                styles.task_table,
                newProcess && styles.new_process
              )}
            >
              {liveModel.taskModels.map((task, index) => (
                <React.Fragment key={task.id}>
                  {index === dragTarget?.order &&
                    dragTarget.order < liveModel.taskModels.length && (
                      <DnDPlaceholder columns={5} />
                    )}
                  <tr
                    draggable
                    onDragStart={() => setDraggedItem(index)}
                    onDragLeave={(ev) => handleDragLeave(ev, index)}
                    onDragEnd={handleDrop}
                  >
                    <td className={styles.name_cell}>
                      <Icon
                        icon="drag-handle-vertical"
                        className={styles.drag_icon}
                      />
                      <span
                        className={styles.name_wrapper}
                        style={{
                          borderLeft: `6px solid ${modelColor} `,
                        }}
                      >
                        <EditableText
                          intent={
                            validationFailed && task.title === ""
                              ? "danger"
                              : undefined
                          }
                          placeholder={
                            validationFailed
                              ? tr.translateAsString(
                                  "workflow.model.title.mandatory"
                                )
                              : tr.translateAsString(
                                  "workflow.model.task.title.edit"
                                )
                          }
                          value={task.title}
                          onChange={(value) => updateTaskTitle(index, value)}
                          isFill
                        />
                      </span>
                    </td>

                    <td>
                      <EditableText
                        isFill
                        value={task.description}
                        onChange={(value) =>
                          updateTaskDescription(index, value)
                        }
                      />
                    </td>
                    <td>
                      <TaskModelOwnerSelect
                        selected={task.owners}
                        onEditSelection={(users) =>
                          updateTaskAssignee(index, users)
                        }
                        allowedTaskOwners={allowedTaskOwners}
                      />
                    </td>
                    <td>
                      <TaskDependencyMultiSelect
                        task={task}
                        onDepSelected={(deps) =>
                          handleSetDependencies(task.id, deps)
                        }
                        workflowTasks={liveModel.taskModels}
                        workflowColor={modelColor}
                      />
                    </td>
                    <td className={styles.action_col}>
                      <div>
                        <Tooltip
                          content={tr.translateAsString(
                            "generic.action.delete"
                          )}
                        >
                          <Button
                            isDense
                            icon="trash"
                            onClick={deleteTask(task.id)}
                          />
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                  {index === liveModel.taskModels.length - 1 &&
                    dragTarget?.order === liveModel.taskModels.length && (
                      <DnDPlaceholder columns={5} />
                    )}
                </React.Fragment>
              ))}
            </tbody>
          </TaskTable>
          <ButtonSP
            icon="plus"
            view="flat"
            color="primary"
            onClick={addTask}
            className={styles.new_task}
            isDense
          >
            {tr.translate("workflow.model.task.title.new")}
          </ButtonSP>
        </div>
      </Card>
    </div>
  );
}

export const emptyModel: ProcessModelSpec = {
  title: "",
  nomenclature: "",
  color: purple,
  taskModels: [
    { id: 1, title: "", description: "", owners: [], dependencies: [] },
  ],
};

const DnDPlaceholder = (props: { columns: number }) => {
  return (
    <tr className={styles.drop_placeholder}>
      <td colSpan={props.columns} />
    </tr>
  );
};
