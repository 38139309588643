import { Part, PartType } from "@aletiq/types";
import { TreeNodeInfo } from "@blueprintjs/core";
import React from "react";
import { PartIcon, Tooltip, Tree } from "../../../../components";
import TreeItemName from "../../../../components/name/TreeItemName";
import { useTranslations } from "../../../../util";
import { isPartParent, partTypeFromFileName } from "../../../pdm/services";
import styles from "./EditPartBomDialog.module.scss";
import {
  PartBomAddition,
  PartBomDeletion,
  PartBomIgnore,
  PartTree,
} from "./partBomUtils";
import PartTreeActionBar from "./PartTreeActionBar";

export default function EditPartTree(props: {
  tree: PartTree;
  fetchingPathId?: string | undefined;
  isExpanded: (pathId: string) => boolean;
  onExpand: (pathId: string) => void;
  onToggleExpandNode: (node: TreeNodeInfo<{ onExpand?: () => void }>) => void;
  onAssign: (pathId: string, addition: PartBomAddition) => void;
  onDelete: (pathId: string, deletion: PartBomDeletion) => void;
  onIgnore: (pathId: string, ignore: PartBomIgnore) => void;
  onCreate: (
    pathId: string,
    type: PartType,
    isStandard: boolean,
    parentId: number,
    name: string
  ) => void;
}) {
  const {
    tree,
    fetchingPathId,
    isExpanded,
    onExpand,
    onToggleExpandNode,
    onAssign,
    onDelete,
    onCreate,
    onIgnore,
  } = props;

  const tr = useTranslations();

  function makePartNodeInfos(
    tree: PartTree,
    parent: PartTree,
    isEditable: boolean = true
  ): TreeNodeInfo<{ onExpand?: () => void }> {
    const { name, part, pathId, isIgnored } = tree;
    const parentId = parent.part!.id;
    const type = partTypeFromFileName(name ?? "") || "part";
    const isKnown = part !== null;
    const isApprovedAssembly = part?.isApproved && part.type === "assembly";
    const isEditableChild = isEditable && !isApprovedAssembly;
    const isFetching = fetchingPathId === pathId;
    const handleAssign = (assignedPart: Part) => {
      const addition = {
        parentId,
        partId: assignedPart.id,
        name,
        iteration: assignedPart.lastIteration.number,
      };
      onAssign(pathId, addition);
    };

    const handleCreate = (type: PartType, isStandard: boolean) => {
      onCreate(pathId, type, isStandard, parentId, name);
    };

    const handleDelete = () => {
      const deletion = {
        parentId,
        partId: part!.id,
        name,
      };
      onDelete(pathId, deletion);
    };

    const handleIgnore = () => {
      const ignore = {
        parentId,
        name,
        isIgnored: true,
      };
      onIgnore(pathId, ignore);
    };

    const handleRestore = () => {
      const ignore = {
        parentId,
        name,
        isIgnored: false,
      };
      onIgnore(pathId, ignore);
    };

    return {
      className: styles.selectable,
      hasCaret: isPartParent(partTypeFromFileName(name) ?? "part") && isKnown,
      id: pathId,
      label: (
        <Tooltip
          content={
            isEditableChild ? "" : tr.translate("cad.bom.edition.disabled")
          }
        >
          <TreeItemName
            name={part?.name}
            isIgnored={isIgnored}
            isLoading={isFetching}
          />
        </Tooltip>
      ),
      isExpanded: isExpanded(pathId),
      nodeData: {
        onExpand: () => onExpand(pathId),
      },
      icon: !isFetching && (
        <PartIcon
          inline
          color="var(--blue100)"
          isStandard={part?.isStandard}
          type={part?.type}
        />
      ),
      childNodes: tree.components.map((t) =>
        makePartNodeInfos(t, tree, isEditableChild)
      ),
      secondaryLabel: (
        <PartTreeActionBar
          parentId={parentId}
          isEditable={isEditable}
          isKnown={isKnown}
          isIgnored={isIgnored}
          type={type}
          onAssign={handleAssign}
          onCreate={handleCreate}
          onDelete={handleDelete}
          onIgnore={handleIgnore}
          onRestore={handleRestore}
        />
      ),
    };
  }

  return (
    <Tree
      onNodeExpand={onToggleExpandNode}
      onNodeCollapse={onToggleExpandNode}
      contents={[
        {
          isExpanded: true,
          hasCaret: false,
          id: tree.pathId,
          label: <TreeItemName name={tree.part?.name} />,
          icon: (
            <PartIcon
              inline
              color="var(--blue100)"
              isStandard={tree.part!.isStandard}
              type={tree.part!.type}
            />
          ),
          childNodes: tree.components.map((t) => makePartNodeInfos(t, tree)),
        },
      ]}
    />
  );
}
