import React from "react";
import { Dialog, RemoveLinkIcon } from "../../../components";
import { useTranslations } from "../../../util";
import { useProject } from "../../project/hooks";
import { useDocumentsProjects, useUpdateDocumentsProjects } from "../hooks";

export default function UnlinkProjectDialog(props: {
  projectId: number;
  documentsIds: number[];
  onClose: () => void;
}) {
  const { projectId, documentsIds, onClose } = props;
  const tr = useTranslations();

  const { data: project } = useProject(projectId);
  const { data: documentsProjects, isLoading } =
    useDocumentsProjects(documentsIds);
  const { mutate: updateDocumentsProjects, isLoading: isSubmitting } =
    useUpdateDocumentsProjects();

  const handleSubmit = () => {
    const updatedProjects = documentsProjects.map(({ id, projects }) => {
      return {
        id,
        projects: projects.filter((p) => p !== projectId),
      };
    });

    updateDocumentsProjects(updatedProjects, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      icon={<RemoveLinkIcon />}
      title={tr.translateAsString("document.dialog.unlink-project.title")}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={onClose}
      submitting={isSubmitting}
      disablePrimary={isLoading}
    >
      {tr.translate("document.dialog.unlink-project.description", {
        count: documentsIds.length,
        project: project?.name,
      })}
    </Dialog>
  );
}
