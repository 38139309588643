import { useCurrentUser, useHasPermission } from "../../../hooks";
import useProcess from "./useProcess";

const useHasUpdateRightOnProcess = (workflowId?: number) => {
  const { data: workflow } = useProcess(workflowId ?? null);
  const { data: currentUser } = useCurrentUser();

  const canUpdateAllWorkflows = useHasPermission("update:all-workflows");
  const canUpdateOwnWorkflows = useHasPermission("update:own-workflows");
  return (
    (workflow?.owner === currentUser?.id && canUpdateOwnWorkflows) ||
    canUpdateAllWorkflows
  );
};

export default useHasUpdateRightOnProcess;
