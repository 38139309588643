import { Part } from "@aletiq/types";
import React from "react";
import { useHasVisibleScrollbar } from "../../../../../hooks";
import FileActionTable from "../../../../documents/common/FileActionTable";
import { PartAction, PartState } from "../types";
import NonStandardParts from "./NonStandardParts";
import PartTableHeader from "./PartTableHeader";
import UnuspportedFiles from "./UnsupportedFiles";
import styles from "./UploadedPartsList.module.scss";
import ValidParts from "./ValidParts";

export default function UploadedPartsList(props: {
  onUpdatePartStates: (states: PartState[]) => void;
  partStates: PartState[];
  nonStandardParts: Part[];
  unsupportedFiles: File[];
}) {
  const { onUpdatePartStates, partStates, nonStandardParts, unsupportedFiles } =
    props;

  const {
    verticalScrollbarSize,
    containerRef: tableBodyRef,
    isScrollbarVisible,
  } = useHasVisibleScrollbar();

  const handleBatchUpdateFileAction = (action: PartAction) =>
    onUpdatePartStates(
      partStates.map((state) => {
        const canUpdateAction =
          action === "ignore" ||
          // new files can only be created or ignored (no part to update)
          (action === "update" && state.partId) ||
          // existing parts can only be updated or ignored
          (action === "create" && !state.partId);

        return canUpdateAction
          ? {
              ...state,
              partInfo: {
                ...state.partInfo,
                action,
              },
            }
          : state;
      })
    );

  const handleUpdatePartState = (newState: PartState) =>
    onUpdatePartStates(
      partStates.map((state) =>
        state.file.name === newState.file.name ? newState : state
      )
    );

  return (
    <>
      <FileActionTable classname={styles.table_header}>
        <PartTableHeader
          onBatchUpdateAction={handleBatchUpdateFileAction}
          isScrollbarVisible={isScrollbarVisible}
          scrollbarSize={verticalScrollbarSize}
        />
      </FileActionTable>
      <div className={styles.table_wrapper} ref={tableBodyRef}>
        <FileActionTable classname={styles.table}>
          <tbody>
            <ValidParts
              parts={partStates}
              onUpdatePartState={handleUpdatePartState}
            />
            <NonStandardParts parts={nonStandardParts} />
            <UnuspportedFiles files={unsupportedFiles} />
          </tbody>
        </FileActionTable>
      </div>
    </>
  );
}
