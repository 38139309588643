import { useEffect, useMemo, useState } from "react";

// ResizeObserver is a global browser API (not-available in IE)
export declare class ResizeObserver {
  constructor(obs: (entries: { target: Element }[]) => void);
  observe(target: Element): void;
  unobserve(targe: Element): void;
}

/**
 * @name useHasVisibleScrollbar
 * @description hook use to get if a scrollbar is visible on a component and their size
 * @return {function} containerRef - the ref setter to apply to the div you need to watch
 * @return {boolean} isScrollbarVisible - A boolean checking presence of at minimun one scrollbar
 * @return {number} verticalScrollbarSize - The size of the vertical (left) scrollbar
 * @return {number} horizontalScrollbarSize - The size of the horizontal (bottom) scrollbar
 *
 * @example
 *  Component = (props) => {
 *    const { containerRef, isScrollbarVisible, verticalScrollbarSize, } = useHasVisibleScrollbar();
 *
 *    return (
 *      <div id="am-i-scrollalbe" useRef={containerRef}>
 *      </div>
 *    )
 *  }
 */
export default function useHasVisibleScrollbar() {
  const [containerElement, containerRef] = useState<HTMLDivElement | null>(
    null
  );
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);
  const [verticalScrollbarSize, setverticalScrollbarSize] = useState(0);
  const [horizontalScrollbarSize, setHorizontalScrollbarSize] = useState(0);

  const obs = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const container = entries[0]?.target;
        const boundingRect = container?.getBoundingClientRect() ?? {
          height: 0,
          width: 0,
        };

        // The bounding rect compute the height and width INCLUDING the scrollbar
        // clientWidth and clientHeight are computed WITHOUT the scrollbar
        const scrollbarVerticalSize =
          boundingRect.width - (container?.clientWidth ?? 0);
        const scrollbarHorizontalSize =
          boundingRect.height - (container?.clientHeight ?? 0);

        setIsScrollbarVisible(
          scrollbarHorizontalSize + scrollbarVerticalSize > 1
        );
        setverticalScrollbarSize(scrollbarVerticalSize);
        setHorizontalScrollbarSize(scrollbarHorizontalSize);

        return;
      }),
    []
  );

  useEffect(() => {
    if (containerElement) {
      obs.observe(containerElement);

      return () => obs.unobserve(containerElement);
    }
  }, [containerElement, obs]);

  return {
    containerRef,
    isScrollbarVisible,
    verticalScrollbarSize,
    horizontalScrollbarSize,
  };
}
