import { DocumentQueryParams } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { documentKeys } from "./queries";

export default function useDocuments(params?: DocumentQueryParams) {
  const api = useApi();
  return useQuery(
    documentKeys.list(params),
    () => api.document.listDocuments(params),
    {
      keepPreviousData: true,
    }
  );
}
