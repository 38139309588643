import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Page } from "../components";
import Documents from "../features/documents/Documents";
import Home from "../features/home/Home";
import Passports from "../features/passport/Passports";
import AllParts from "../features/pdm/AllParts";
import StandardParts from "../features/pdm/StandardParts";
import Projects from "../features/project/Projects";
import Search from "../features/search/Search";
import ArchivedWorkflows from "../features/tasks/ArchivedWorkflowPage";
import ModelAnalysis from "../features/tasks/ModelAnalysis";
import { Tasks } from "../features/tasks/Tasks";
import Workflows from "../features/tasks/WorkflowsPage";
import WorkflowModels from "../features/tasks/WorklowModels";
import AdministrationRoutes from "./AdministrationRoutes";
import AletiqAdminRoutes from "./AletiqAdminRoutes";
import { ProjectRoutes } from "./ProjectRoutes";

export default function AppRouter() {
  return (
    <Page>
      <Switch>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/tasks">
          <Tasks />
        </Route>
        <Route path="/workflows" exact>
          <Workflows />
        </Route>
        <Route path="/workflows/analysis">
          <ModelAnalysis />
        </Route>
        <Route path="/workflows/archived" exact>
          <ArchivedWorkflows />
        </Route>
        <Route path="/cad" exact>
          <AllParts />
        </Route>
        <Route path="/cad/standard" exact>
          <StandardParts />
        </Route>
        <Route path="/models">
          <WorkflowModels />
        </Route>
        <Route exact path="/project/">
          <Projects />
        </Route>
        <Route path="/project/:activeProject">
          <ProjectRoutes />
        </Route>
        <Route path="/passport/">
          <Passports />
        </Route>
        <Route exact path="/documents">
          <Documents />
        </Route>
        <Route path="/administration">
          <AdministrationRoutes />
        </Route>
        <Route path="/search/:searchString">
          <Search />
        </Route>
        <Route path="/aletiq-admin">
          <AletiqAdminRoutes />
        </Route>
        <Redirect to={{ pathname: "/home" }} />
      </Switch>
    </Page>
  );
}
