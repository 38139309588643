import React from "react";
import { CustomIcon, CustomIconProps } from "./CustomIcon";

const paths = [
  "M13.844 9.332L13.736 9.656C13.652 9.98 13.532 10.304 13.376 10.604C13.34 10.688 13.292 10.772 13.244 10.856L13.244 10.856C13.064 11.18 12.86 11.48 12.632 11.768C12.614 11.786 12.596 11.807 12.578 11.828C12.56 11.849 12.542 11.87 12.524 11.888C11.828 12.716 10.916 13.328 9.86 13.676L9.872 13.688C9.80638 13.7172 9.73191 13.733 9.65665 13.7491C9.60803 13.7594 9.55909 13.7699 9.512 13.784C9.464 13.796 9.416 13.8093 9.36799 13.8227C9.272 13.8493 9.176 13.876 9.08 13.892C8.732 13.952 8.372 14 8 14C4.688 14 2 11.312 2 8C2 4.688 4.688 2 8 2C8.372 2 8.732 2.048 9.08 2.108C9.184 2.124 9.27733 2.15067 9.37067 2.17733C9.41733 2.19067 9.464 2.204 9.512 2.216C9.572 2.234 9.632 2.249 9.692 2.264C9.752 2.279 9.812 2.294 9.872 2.312L9.86 2.324C10.496 2.528 11.084 2.84 11.6 3.224C11.948 3.488 12.248 3.788 12.536 4.112C12.572 4.148 12.608 4.196 12.632 4.232C12.872 4.52 13.076 4.82 13.244 5.144L13.388 5.396C13.532 5.696 13.64 6.02 13.736 6.344L13.844 6.668C13.94 7.1 14 7.544 14 8C14 8.456 13.94 8.9 13.844 9.332ZM11.5 6C11.78 6 12 6.22 12 6.5C12 6.63 11.95 6.74 11.87 6.83L8.37 10.83C8.28 10.93 8.15 11 8 11C7.85 11 7.72 10.93 7.63 10.83L4.13 6.83C4.05 6.74 4 6.63 4 6.5C4 6.22 4.22 6 4.5 6H11.5Z",
];

export default function SingleSelectionIcon(props: CustomIconProps) {
  return <CustomIcon paths={paths} iconName="single-selection" {...props} />;
}
