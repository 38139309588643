import { PropertyType } from "@aletiq/types";
import { Target } from "./Dialogs/CreatePassportPropertyDialog";

export const ALETIQ_PASSPORT_PROPERTIES: {
  id: string;
  label: string;
  description: string;
  type: PropertyType;
  target: Target;
}[] = [
  {
    id: "description",
    label: "generic.label.description",
    description: "admin.attribute.passports.description",
    type: "string",
    target: "all",
  },
  {
    id: "product",
    label: "admin.attribute.passports.product",
    description: "admin.attribute.passports.product.description",
    type: "enum",
    target: "all",
  },
  {
    id: "configuration",
    label: "admin.attribute.passports.config",
    description: "admin.attribute.passports.config.description",
    type: "multi-enum",
    target: "all",
  },
  {
    id: "version",
    label: "admin.attribute.passports.version",
    description: "admin.attribute.passports.version.description",
    type: "enum",
    target: "tool",
  },
];
