import { ProgressBar } from "@blueprintjs/core";
import React from "react";
import { useQueryClient } from "react-query";
import useApi from "../../../../../app/useApi";
import { useToaster } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { activityKeys } from "../../../../activities/hooks/queries";
import { pdmKeys } from "../../../hooks/queries";
import { isPartParent } from "../../../services";
import { PartError, PartState, StandardPartState } from "../types";
import handleExistingPart from "./handleExistingPart";
import handleNewPart from "./handleNewPart";
import handleValidate from "./handleValidate";
import styles from "./Toaster.module.scss";

export default function useSubmitParts(
  partStates: PartState[],
  standardStates: StandardPartState[],
  projectId?: number
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return async () => {
    const componentStates = partStates.filter(
      (s) => !isPartParent(s.partInfo.type)
    );
    const parentStates = partStates.filter((s) =>
      isPartParent(s.partInfo.type)
    );

    const createdParts: Record<string, number> = {};

    const handlePartState = async (
      part: PartState
    ): Promise<PartError | void> => {
      //update parts
      if (part.partInfo.action.type === "update") {
        return await handleExistingPart(api, part);
      }

      //create new parts & store partIds of newly created parts
      if (part.partInfo.action.type === "create") {
        const partResult = await handleNewPart(
          api,
          part,
          projectId ? [projectId] : []
        );
        if (typeof partResult === "number") {
          createdParts[part.partInfo.name] = partResult;
        }
      }
    };

    const uploadTotal =
      partStates.filter((s) => s.partInfo.action.type !== "ignore").length +
      standardStates.filter((p) => p.action === "update").length;

    let uploadedCount = 0;

    const reportUploadProgress = (isStart?: boolean) => {
      if (!isStart) {
        uploadedCount += 1;
      }

      const isInProgress = uploadedCount < uploadTotal;
      const intent = isInProgress ? "primary" : "success";
      const icon = isInProgress ? "info-sign" : "tick";

      toaster.show(
        {
          intent,
          icon,
          timeout: isInProgress ? 0 : 2000,
          message: (
            <>
              {tr.translate(
                isInProgress
                  ? "part.toaster.upload.in-progress"
                  : "part.toaster.upload.done"
              )}
              <ProgressBar
                intent={intent}
                value={uploadedCount / uploadTotal}
                stripes={false}
                className={styles.progress_bar}
              />
              <span className={styles.progress_message}>
                {uploadedCount}/{uploadTotal}
              </span>
            </>
          ),
        },
        "part-upload-progress"
      );
    };

    const standardPartsErrrors: PartError[] = [];
    for (const standard of standardStates) {
      if (standard.action === "update") {
        try {
          await api.pdm.uploadCadFile(
            standard.partId,
            { name: "", description: "" },
            standard.file
          );
          reportUploadProgress();
        } catch (e) {
          standardPartsErrrors.push({
            stage: "uploading",
            name: standard.name,
            partId: standard.partId,
            error: e as Error,
          });
        }
      }
    }

    // display upload progress bar at 0
    uploadTotal > 0 && reportUploadProgress(true);

    // Update parts
    const componentErrors: PartError[] = [];
    for (let i = 0; i < componentStates.length; i++) {
      const state = componentStates[i];
      const error = await handlePartState(state);
      if (error) componentErrors.push(error);
      reportUploadProgress();
    }

    // Update assemblies
    const parentErrors: PartError[] = [];
    for (let i = 0; i < parentStates.length; i++) {
      const state = parentStates[i];
      const error = await handlePartState(state);
      if (error) componentErrors.push(error);
      reportUploadProgress();
    }

    //invalidate parts queries & check for errors
    if (componentErrors.length > 0 || parentErrors.length > 0) {
      queryClient.invalidateQueries(pdmKeys.all);
      queryClient.invalidateQueries(activityKeys.all);

      const uploadErrors = [
        ...standardPartsErrrors,
        ...componentErrors,
        ...parentErrors,
      ];
      if (uploadErrors.length > 0) {
        toaster.show(
          {
            intent: "danger",
            icon: "warning-sign",
            timeout: 0,
            message: (
              <>
                {tr.translate("part.toaster.upload.error")}
                <div className={styles.error_message}>
                  {tr.translate("part.toaster.error.uploading")}
                  <ul>
                    {uploadErrors.map((part) => (
                      <li key={part.partId}>{part.name}</li>
                    ))}
                  </ul>
                </div>
              </>
            ),
          },
          "part-upload-progress"
        );
        return;
      }
    }

    // Validate parts
    const validateTotal = partStates.filter(
      (s) => s.partInfo.action.type !== "ignore" && s.partInfo.action.approve
    ).length;

    let validateCount = 0;

    const reportValidateProgress = (isStart?: boolean) => {
      if (!isStart) {
        validateCount += 1;
      }

      const isInProgress = validateCount < validateTotal;
      const intent = isInProgress ? "primary" : "success";
      const icon = isInProgress ? "info-sign" : "tick";
      toaster.show(
        {
          intent,
          icon,
          timeout: isInProgress ? 0 : 2000,
          message: (
            <>
              {tr.translate(
                isInProgress
                  ? "part.toaster.validate.in-progress"
                  : "part.toaster.validate.done"
              )}
              <ProgressBar
                intent={intent}
                value={validateCount / validateTotal}
                stripes={false}
                className={styles.progress_bar}
              />
              <span className={styles.progress_message}>
                {validateCount}/{validateTotal}
              </span>
            </>
          ),
        },
        "part-validation-progress"
      );
    };

    // display validation progress bar at 0
    validateTotal > 0 && reportValidateProgress(true);

    //validate components
    const componentsValidationErrors: PartError[] = [];
    for (let i = 0; i < componentStates.length; i++) {
      const state = componentStates[i];
      const partId = state.partId || createdParts[state.partInfo.name];
      const error = await handleValidate(api, { ...state, partId });
      if (error) {
        componentsValidationErrors.push(error);
      }
      if (state.partInfo.action.approve) {
        reportValidateProgress();
      }
    }

    // Validate parents
    const parentValidationErrors = [];
    for (let i = 0; i < parentStates.length; i++) {
      const state = parentStates[i];
      const partId = state.partId || createdParts[state.partInfo.name];
      const error = await handleValidate(api, { ...state, partId });
      if (error) {
        parentValidationErrors.push(error);
      }
      if (state.partInfo.action.approve) {
        reportValidateProgress();
      }
    }

    queryClient.invalidateQueries(pdmKeys.all);
    queryClient.invalidateQueries(activityKeys.all);

    //check for errors
    const validationErrors = [
      ...componentsValidationErrors,
      ...parentValidationErrors,
    ];
    if (validationErrors.length > 0) {
      toaster.show(
        {
          intent: "danger",
          icon: "warning-sign",
          timeout: 0,
          message: (
            <>
              {tr.translate("part.toaster.validate.error")}
              <div className={styles.error_message}>
                {tr.translate("part.toaster.error.saving-revision")}
                <ul>
                  {validationErrors.map((part) => (
                    <li key={part.partId}>{part.name}</li>
                  ))}
                </ul>
              </div>
            </>
          ),
        },
        "part-validation-progress"
      );
    }
  };
}
