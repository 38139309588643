import {
  Button,
  H3,
  ItemSelect,
  SearchInput,
  Select,
} from "@aletiq/design-system";
import { LockedStateFilter, TaskStatusFilterValue } from "@aletiq/types";
import { Card, NonIdealState } from "@blueprintjs/core";
import React, { useState } from "react";
import { AlignRight } from "../../../components";
import { useHasVisibleScrollbar } from "../../../hooks";
import { useTranslations } from "../../../util";
import TaskTable from "../common/TaskTable";
import useUserTasks, { ResolvedTask } from "../hooks/useUserTasks";
import styles from "./UserTask.module.scss";
import UserTaskRow from "./UserTaskRow";

const stateFilters: Record<LockedStateFilter, TaskStatusFilterValue[]> = {
  all: ["not_started_locked", "not_started_unlocked"],
  locked: ["not_started_locked"],
  unlocked: ["not_started_unlocked"],
};

export default function UserTasks(props: { className?: string }) {
  const { className } = props;
  const tr = useTranslations();

  const [searchString, setSearchString] = useState("");
  const [statusFilter, setStatusFilter] = useState<LockedStateFilter>("all");

  const unlockedTasks = useUserTasks(["not_started_unlocked"]);
  const lockedTasks = useUserTasks(["not_started_locked"]);
  const tasks = useUserTasks(stateFilters[statusFilter]);

  const {
    containerRef: bodyTableRef,
    isScrollbarVisible,
    verticalScrollbarSize,
  } = useHasVisibleScrollbar();

  const selectOptions: ItemSelect<LockedStateFilter>[] = [
    {
      key: "all",
      text: tr.translateAsString("tasks.filter.option.all", {
        count: unlockedTasks.length + lockedTasks.length,
      }),
    },
    {
      key: "unlocked",
      text: tr.translateAsString("tasks.filter.option.unlock.tasks", {
        count: unlockedTasks.length,
      }),
    },
    {
      key: "locked",
      text: tr.translateAsString("tasks.filter.option.lock.tasks", {
        count: lockedTasks.length,
      }),
    },
  ];

  const filterTasks = (task: ResolvedTask) => {
    if (searchString === "") return true;
    if (
      task.title.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    )
      return true;
    if (
      task.process.title
        .toLocaleLowerCase()
        .includes(searchString.toLocaleLowerCase())
    )
      return true;

    return false;
  };

  const filteredTasks = tasks.filter((task) => filterTasks(task));

  const processes: number[] = [];
  const tasksByProcess: Record<number, ResolvedTask[]> = {};

  filteredTasks.forEach((task) => {
    if (!processes.includes(task.process.id)) processes.push(task.process.id);
    if (!tasksByProcess[task.process.id]) tasksByProcess[task.process.id] = [];
    tasksByProcess[task.process.id].push(task);
  });

  const handleStatusChange = (value: LockedStateFilter) => {
    setStatusFilter(value);
  };

  const selectedOptionText = selectOptions.find(
    (option) => option.key === statusFilter
  )?.text;

  return (
    <div className={className}>
      <div className={styles.action_wrapper}>
        <H3>{tr.translate("home.tasks.title")}</H3>
        <AlignRight />
        <Select
          items={selectOptions}
          onItemSelect={handleStatusChange}
          activeItem={statusFilter}
          intent="outlined"
          isDense
        >
          <Button intent="outlined" rightIcon="caret-down" isDense>
            {selectedOptionText || tr.translate("tasks.filter.option.all")}
          </Button>
        </Select>
        <SearchInput
          value={searchString}
          onChange={setSearchString}
          className={styles.search}
          isDense
        />
      </div>

      <Card className={styles.card}>
        {filteredTasks.length === 0 ? (
          <EmptyTasks search={searchString} />
        ) : (
          <>
            <TaskTable>
              <thead>
                <tr>
                  <th>{tr.translate("tasklist.task")}</th>
                  <th>{tr.translate("tasklist.process")}</th>
                  <th className={styles.date_cell}>
                    {tr.translate("tasklist.duedate")}
                  </th>
                  <th className={styles.status_cell}>
                    {tr.translate("tasklist.status")}
                  </th>
                  {isScrollbarVisible && (
                    <th
                      style={{ width: verticalScrollbarSize }}
                      className={styles.filler}
                    />
                  )}
                </tr>
              </thead>
            </TaskTable>
            <div className={styles.scroll} ref={bodyTableRef}>
              <TaskTable>
                <tbody>
                  {filteredTasks.map((task) => (
                    <UserTaskRow
                      key={task.id}
                      task={task}
                      search={searchString}
                    />
                  ))}
                </tbody>
              </TaskTable>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}

function EmptyTasks(props: { search: string }) {
  const { search } = props;
  const tr = useTranslations();

  return (
    <div style={{ position: "relative", height: "200px" }}>
      <NonIdealState
        icon={
          <img
            src={search === "" ? "/assets/no-tasks.svg" : "/assets/no-data.svg"}
            height={108}
            alt=""
          />
        }
        description={tr.translate(
          search === ""
            ? "home.tasks.list.empty-state"
            : "home.tasks.list.empty-state.filtered"
        )}
      />
    </div>
  );
}
