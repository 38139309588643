import {
  Button,
  Field,
  ItemSelect,
  Select,
  Tooltip,
} from "@aletiq/design-system";
import { Period } from "@aletiq/types";
import React from "react";
import { ActionBar, WorkflowModelFilter } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useExportWorkflows } from "../hooks";
import styles from "./ModelAnalysisHeader.module.scss";

export default function ModelAnalysisHeader(props: {
  selectedPeriod: Period;
  selectedModels: number[];
  onPeriodChange: (value: Period) => void;
  onModelSelect: (value: number[]) => void;
}) {
  const { selectedPeriod, selectedModels, onPeriodChange, onModelSelect } =
    props;
  const tr = useTranslations();

  const { mutate: exportAsCsv } = useExportWorkflows();

  const timeframes: ItemSelect<Period>[] = [
    {
      key: "month",
      text: tr.translateAsString("analysis.filter.month"),
    },
    {
      key: "quarter",
      text: tr.translateAsString("analysis.filter.quarter"),
    },
    {
      key: "semester",
      text: tr.translateAsString("analysis.filter.semester"),
    },
    {
      key: "year",
      text: tr.translateAsString("analysis.filter.year"),
    },
  ];

  const handleClearModelFilter = () => onModelSelect([]);

  const handleSelectModel = (models: number[] | undefined) => {
    onModelSelect(models ?? []);
  };

  const handleExportWorkflows = () => exportAsCsv();

  return (
    <ActionBar>
      <Field
        label={tr.translate("analysis.header.workflow.model")}
        className={styles.models_filter}
      >
        <WorkflowModelFilter
          selected={selectedModels}
          onSelect={handleSelectModel}
          onClear={handleClearModelFilter}
        />
      </Field>
      <Field
        label={tr.translate("analysis.header.period")}
        className={styles.models_filter}
      >
        <Select
          isDense
          intent="outlined"
          items={timeframes}
          activeItem={selectedPeriod}
          onItemSelect={onPeriodChange}
        >
          {tr.translateAsString(`analysis.filter.${selectedPeriod}`)}
        </Select>
      </Field>
      <Tooltip content={tr.translate("analysis.header.export")} position="top">
        <Button
          isDense
          icon="th-derived"
          intent="secondary"
          onClick={handleExportWorkflows}
          className={styles.export_button}
        />
      </Tooltip>
    </ActionBar>
  );
}
