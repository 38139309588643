import { PropertyObject } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { propertyKeys } from "./queries";

export default function useAletiqPropertiesDefaultDisplay(
  objectType: PropertyObject
) {
  const api = useApi();
  return useQuery(propertyKeys.defaultDisplay(objectType), () =>
    api.property.getAletiqPropertiesDefaultDisplay(objectType)
  );
}
