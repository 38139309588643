import { OperationBill } from "@aletiq/types";
import React, { useState } from "react";
import {
  AlertPopover,
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
} from "../../../components";
import { useNavigateTo } from "../../../hooks";
import { useTranslations } from "../../../util";
import { CreateBill } from "./dialogs";
import EditBillIndex from "./EditBillIndex";
import { useDeleteBill } from "./hooks";

type DialogType =
  | "delete-bill"
  | "rename-bill"
  | "create-bill-index"
  | "create-branch";

export default function BillActionsMenu(props: {
  projectId: number;
  activeBill: OperationBill;
  bills: OperationBill[];
  onValidate?: () => void;
}) {
  const { activeBill, bills, projectId, onValidate } = props;
  const tr = useTranslations();

  const [dialog, setDialog] = useState<DialogType>();

  const navigateToBillsIndex = useNavigateTo({
    path: `/project/${projectId}/operation-bills`,
  });
  const { isLoading: isDeletingBill, mutate: deleteBill } = useDeleteBill(
    projectId,
    activeBill
  );

  const handleDeleteBill = () => {
    deleteBill(undefined, {
      onSuccess: navigateToBillsIndex,
    });
  };

  const handleOpenDialog = (dialogType: DialogType) => () => {
    setDialog(dialogType);
  };

  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  return (
    <>
      <AlertPopover
        isOpen={dialog === "delete-bill"}
        position="bottom-right"
        icon="trash"
        title={tr.translate("project.operation-bill.delete-index")}
        content={tr.translate("project.operation-bill.delete-index.confirm")}
        onClose={handleCloseDialog}
        onPrimaryClick={handleDeleteBill}
        isDense
      >
        <Popover2
          content={
            <Menu isDense>
              {activeBill.state === "draft" && (
                <>
                  <MenuItem
                    isDense
                    icon="tick"
                    text={tr.translate("project.operation-bill.validate")}
                    onClick={onValidate}
                  />
                  <MenuItem
                    isDense
                    icon="edit"
                    text={tr.translate("generic.action.rename")}
                    onClick={handleOpenDialog("rename-bill")}
                  />
                  <MenuItem
                    isDense
                    icon="trash"
                    text={tr.translate("generic.action.delete")}
                    onClick={handleOpenDialog("delete-bill")}
                  />
                  <MenuDivider />
                </>
              )}
              <MenuItem
                isDense
                icon="git-commit"
                text={tr.translate("project.operation-bill.create-index")}
                onClick={handleOpenDialog("create-bill-index")}
              />
              <MenuItem
                isDense
                icon="git-branch"
                text={tr.translate("project.operation-bill.create-branch")}
                onClick={handleOpenDialog("create-branch")}
              />
            </Menu>
          }
        >
          <Button icon="more" isDense isLoading={isDeletingBill} />
        </Popover2>
      </AlertPopover>

      {dialog === "rename-bill" ? (
        <EditBillIndex
          projectId={projectId}
          billId={activeBill.id}
          value={activeBill.index}
          onClose={handleCloseDialog}
        />
      ) : null}

      {(dialog === "create-bill-index" || dialog === "create-branch") && (
        <CreateBill
          projectId={projectId}
          branchId={
            dialog === "create-bill-index" ? activeBill.branch : undefined
          }
          onClose={handleCloseDialog}
          previousBills={bills}
        />
      )}
    </>
  );
}
