import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useSetPassportOperationBillIndex() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (values: { passportId: number; billId: number | null }) => {
      const { passportId, billId } = values;
      await api.passport.updatePassport(passportId, [
        {
          type: "articleBill",
          value: billId,
        },
      ]);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(passportKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
