import { EntityId, Project, ProjectType } from "@aletiq/types";
import React, { useMemo, useState } from "react";
import {
  BatchActionButton,
  Menu,
  MenuDivider,
  MenuItem,
  Popover2,
} from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import {
  LinkEntitiesToProcessesDialog,
  ProcessCreationDialog,
} from "../../../tasks/dialogs";
import {
  DeleteProductsDialog,
  EditProductsPropertiesDialog,
  LinkProductsToDocumentsDialog,
} from "../../dialogs";
import styles from "./ProductBatchActionHeader.module.scss";

export type DialogType =
  | "create-workflow"
  | "delete"
  | "edit-property"
  | "link-workflow"
  | "link-document"
  | null;

export default function AllPassportsHeaderBatchAction(props: {
  selectedProducts: Project[];
  toggleAllRowsSelected: (value?: boolean | undefined) => void;
  productType?: ProjectType;
}) {
  const { toggleAllRowsSelected, selectedProducts, productType } = props;
  const tr = useTranslations();

  const [dialog, setDialog] = useState<DialogType>(null);

  const canDeleteProducts = useHasPermission("delete:projects");
  const canUpdateProducts = useHasPermission("update:projects");

  const productEntities: EntityId[] = useMemo(
    () =>
      selectedProducts.map(({ id }) => ({
        type: "project",
        id,
      })),
    [selectedProducts]
  );

  const handleDialog = (dialog: DialogType) => () => {
    setDialog(dialog);
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleDialogResetSelection = () => {
    toggleAllRowsSelected(false);
    setDialog(null);
  };

  return (
    <>
      <div className={styles.subtitle}>
        {tr.translate("passport.page.selected", {
          count: selectedProducts.length,
        })}
      </div>
      {canUpdateProducts && (
        <>
          <BatchActionButton
            title={tr.translateAsString("passport.page.action.edit-property")}
            icon="edit"
            onClick={handleDialog("edit-property")}
          />
          <BatchActionButton
            title={tr.translateAsString("passport.page.action.link-documents")}
            icon="new-link"
            onClick={handleDialog("link-document")}
          />

          <Popover2
            minimal
            position="bottom-right"
            content={
              <Menu isDense intent="minimal">
                <MenuItem
                  isDense
                  text={tr.translateAsString(
                    "passport.page.action.create-workflow"
                  )}
                  icon="plus"
                  onClick={handleDialog("create-workflow")}
                />
                <MenuItem
                  isDense
                  text={tr.translateAsString(
                    "passport.page.action.link-workflow"
                  )}
                  icon="new-link"
                  onClick={handleDialog("link-workflow")}
                />
                {canDeleteProducts && (
                  <>
                    <MenuDivider />
                    <MenuItem
                      isDense
                      text={tr.translate("generic.action.delete")}
                      icon="trash"
                      onClick={handleDialog("delete")}
                    />
                  </>
                )}
              </Menu>
            }
          >
            <BatchActionButton
              intent="outlined"
              rightIcon="caret-down"
              title={tr.translateAsString("part.details.plus")}
            />
          </Popover2>
        </>
      )}

      {dialog === "create-workflow" && (
        <ProcessCreationDialog
          initialEntities={productEntities}
          onClose={handleCloseDialog}
        />
      )}

      {dialog === "link-workflow" && (
        <LinkEntitiesToProcessesDialog
          entities={productEntities}
          onClose={handleCloseDialog}
          title={tr.translateAsString("product.dialog.link.workflows.title", {
            count: productEntities.length,
            type: productType,
          })}
          subtitle={tr.translate("product.dialog.link.workflows.subtitle", {
            type: productType,
          })}
        />
      )}

      {dialog === "link-document" && (
        <LinkProductsToDocumentsDialog
          entities={productEntities}
          onClose={handleCloseDialog}
          title={tr.translateAsString("product.dialog.link.documents.title", {
            count: productEntities.length,
            type: productType,
          })}
          subtitle={tr.translate("product.dialog.link.documents.subtitle", {
            type: productType,
          })}
        />
      )}

      {dialog === "delete" && (
        <DeleteProductsDialog
          products={selectedProducts.map((p) => p.id)}
          productType={productType}
          onClose={handleDialogResetSelection}
        />
      )}

      {dialog === "edit-property" && (
        <EditProductsPropertiesDialog
          products={selectedProducts}
          productType={productType}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}
