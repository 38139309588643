import { ParserOption } from "./types";
import { Parser } from "./parser";
import { Lexer } from "./lexer";
import { assign, defaultOptions } from "./helper";
import { ContentState, EditorState } from "draft-js";

class MarkdownParser {
  constructor(options?: ParserOption) {
    this.options = assign({}, defaultOptions, options);
  }

  options: ParserOption;

  parse = (src: string): EditorState => {
    let state = null;
    const parser = new Parser(this.options);
    const lexer = new Lexer(this.options);

    try {
      state = parser.parse(lexer.parse(src));
    } catch (e) {
      if (this.options.silent) {
        return EditorState.createWithContent(
          ContentState.createFromText("Sorry an error occured")
        );
      } else {
        throw e;
      }
    }

    return state;
  };
}

export default MarkdownParser;
