import { UserRole } from "@aletiq/types";
import React from "react";
import { USER_ROLE_WITH_TRANSLATION } from "../../features/administration/Users/common";
import { toggle, useTranslations } from "../../util";
import FilterMenu from "./FilterMenu";
import FilterMenuItem from "./FilterMenuItem";
import FilterTemplate from "./FilterTemplate";

export default function UserTypeFilter(props: {
  selected: UserRole[];
  onSelect: (values: UserRole[]) => void;
  onClear: () => void;
}) {
  const tr = useTranslations();
  const { selected, onClear, onSelect } = props;

  const handleSelect = (value: UserRole) => () =>
    onSelect(toggle(selected, value));

  return (
    <FilterTemplate
      count={selected.length}
      onClear={onClear}
      placeholder={tr.translateAsString("admin.users.option.account-type")}
      content={
        <FilterMenu>
          {USER_ROLE_WITH_TRANSLATION.map((item) => (
            <FilterMenuItem
              key={item.key}
              selected={selected?.includes(item.key)}
              onSelect={handleSelect(item.key)}
              text={tr.translate(item.label)}
            />
          ))}
        </FilterMenu>
      }
    />
  );
}
