import { ToolingRequirement } from "@aletiq/types";
import React, { useState } from "react";
import { Dialog, Field, Input, NumericInput } from "../../../components";
import { useTranslations } from "../../../util";
import { useUpdateToolingRequirements } from "../hooks";
import { ResolvedToolingRequirement } from "../ProjectTooling/ProjectTooling";
import styles from "./Dialog.module.scss";

export default function EditToolingRequirementsDialog(props: {
  toolingRequirements: (ToolingRequirement | ResolvedToolingRequirement)[];
  onClose: () => void;
}) {
  const { toolingRequirements, onClose } = props;
  const tr = useTranslations();

  const [quantity, setQuantity] = useState(1);
  const [comment, setComment] = useState("");

  const { mutate: editTools, isLoading } = useUpdateToolingRequirements();

  const handleSubmit = () => {
    const editedTools = toolingRequirements.map((requirement) => ({
      ...requirement,
      quantity,
      comment,
    }));
    editTools(editedTools, { onSettled: onClose });
  };

  return (
    <Dialog
      isOpen
      icon="edit"
      title={tr.translateAsString("project.dialog.tools.edit.title", {
        count: toolingRequirements.length,
      })}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
      onClose={onClose}
    >
      <div className={styles.dialog_contents}>
        <p>{tr.translate("project.dialog.tools.edit.description")}</p>
        <Field label={tr.translate("generic.label.quantity")}>
          <NumericInput
            value={quantity}
            onValueChange={setQuantity}
            min={1}
            isFill
          />
        </Field>
        <Field label={tr.translate("generic.label.comment")}>
          <Input value={comment} onChange={setComment} fill />
        </Field>
      </div>
    </Dialog>
  );
}
