import React from "react";
import { PageContents, PageTitle } from "../../../components";
import { useTranslations } from "../../../util";
import { ANALYTICS_VISITED_PARTS, useTrackEvent } from "../../../analytics";
import AllPartsListPaginated from "./AllPartsListPaginated/AllPartsListPaginated";

export default function AllParts() {
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PARTS);

  return (
    <PageContents domTitle={tr.translateAsString("page.title.part")}>
      <PageTitle icon="cube">{tr.translate("sidebar.pdm")}</PageTitle>
      <AllPartsListPaginated />
    </PageContents>
  );
}
