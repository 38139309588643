import { AccessEntity } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { pdmKeys } from "./queries";

export default function useUpdatePartsAccesses() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (accessRightByPartId: Record<number, AccessEntity[]>) => {
      for (const partKey in accessRightByPartId) {
        const partId = parseInt(partKey, 10);
        const accessRight = accessRightByPartId[partId];
        await api.pdm.updatePartAccess(partId, accessRight);
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(pdmKeys.all);
      },
      onError: (error: Error) => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic", {
            errMsg: error.message,
          }),
        });
      },
    }
  );
}
