import { usePassport } from "../../../features/passport/hooks";
import { useTranslations } from "../../../util";
import passportLinkProps from "../props/passportLinkProps";

export default function usePassportLink(passportId: number) {
  const tr = useTranslations();
  const { data: passport } = usePassport(passportId);

  return passportLinkProps(tr, passport);
}
