import { Part, ProjectType } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import {
  Icon,
  Input,
  PartIcon,
  ProjectName,
  Tooltip,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { PartAction, ProjectActionSpec } from "./CreateAndLinkProjectsDialog";
import styles from "./CreateAndLinkProjectsDialog.module.scss";
import PartActionSelect from "./PartActionSelect";

export default function PartProjectRow(props: {
  spec: ProjectActionSpec;
  part: Part;
  projectType: ProjectType;
  onEditName: (partId: number) => (name: string) => void;
  onEditDescription: (partId: number) => (description: string) => void;
  onEditAction: (action: PartAction, partId: number) => void;
  hasDuplicateName: boolean;
}) {
  const {
    spec,
    part,
    projectType,
    onEditDescription,
    onEditName,
    onEditAction,
    hasDuplicateName,
  } = props;
  const tr = useTranslations();

  return (
    <tr className={classNames(hasDuplicateName && styles.duplicate_name_row)}>
      <td>
        <Input
          isDense
          fill
          value={spec.name}
          disabled={spec.action === "ignore"}
          onChange={onEditName(part.id)}
          placeholder={
            tr.translateAsString(
              "part.dialog.create-link-projects.project.name",
              {
                type: projectType,
              }
            ) + "..."
          }
          hasError={hasDuplicateName}
          errorHelperText={tr.translateAsString(
            "part.dialog.create-link-projects.name-exists"
          )}
        />
      </td>
      <td className={styles.project_desc}>
        <Input
          isDense
          fill
          value={spec.description ?? ""}
          disabled={spec.action === "ignore"}
          onChange={onEditDescription(part.id)}
          placeholder={
            tr.translateAsString(
              "part.dialog.create-link-projects.project.desc",
              {
                type: projectType,
              }
            ) + "..."
          }
        />
      </td>
      <td className={styles.action}>
        <PartActionSelect
          partId={part.id}
          action={spec.action}
          onChange={onEditAction}
        />
      </td>
      <td className={styles.part_name}>
        <PartIcon
          type={part.type}
          color="var(--blue80)"
          isStandard={part.isStandard}
          inline
        />
        {part.name}
      </td>
      <td className={classNames(styles.already_linked, styles.product_count)}>
        {part.projects.length > 0 && (
          <Tooltip
            position="top"
            content={
              <>
                {part.projects.map((projectId) => (
                  <div>
                    <ProjectName projectId={projectId} />
                  </div>
                ))}
              </>
            }
          >
            <>
              <Icon inline icon="projects" />
              {part.projects.length}
            </>
          </Tooltip>
        )}
      </td>
    </tr>
  );
}
