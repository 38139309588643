import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import {
  ANALYTICS_PARTS_COUNT,
  ANALYTICS_PARTS_CREATED,
  ANALYTICS_PARTS_IGNORED,
  ANALYTICS_PARTS_STANDARD_COUNT,
  ANALYTICS_PARTS_UPDATED,
  useAnalytics,
} from "../../../../analytics";
import useApi from "../../../../app/useApi";
import { Dialog } from "../../../../components";
import { partitionWith, useTranslations } from "../../../../util";
import groupPartFiles from "./services/groupPartFiles";
import useSubmitStandardParts from "./services/useSubmitStandardParts";
import { GroupedPartFiles } from "./types";
import UploadedPartsList from "./UploadedPartsList";

export default function UploadStandardPartsWizard(props: {
  files: File[];
  onClose: () => void;
}) {
  const { files, onClose } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const [grouped, setGrouped] = useState<GroupedPartFiles | undefined>();

  const api = useApi();
  useEffect(() => {
    groupPartFiles(api, files).then((grouped) => setGrouped(grouped));
  }, [files, api]);

  const someEmptyNames = (grouped?.valid ?? []).some((p) => !p.partInfo.name);

  const submitStandardParts = useSubmitStandardParts(grouped?.valid ?? []);

  const handleSubmit = async () => {
    const validParts = grouped?.valid ?? [];
    const nonStandardParts = grouped?.nonStandard ?? [];

    const [createdParts, updatedParts, ignoredParts] = partitionWith(
      validParts,
      (p) => p.partInfo.action === "create",
      (p) => p.partInfo.action === "update",
      (p) => p.partInfo.action === "ignore"
    );

    if (createdParts.length > 0) {
      analytics.track(ANALYTICS_PARTS_CREATED, {
        [ANALYTICS_PARTS_STANDARD_COUNT]: createdParts.length,
      });
    }

    if (updatedParts.length > 0) {
      analytics.track(ANALYTICS_PARTS_UPDATED, {
        [ANALYTICS_PARTS_STANDARD_COUNT]: updatedParts.length,
      });
    }

    if (ignoredParts.length > 0 || nonStandardParts.length > 0) {
      analytics.track(ANALYTICS_PARTS_IGNORED, {
        [ANALYTICS_PARTS_COUNT]: nonStandardParts.length,
        [ANALYTICS_PARTS_STANDARD_COUNT]: ignoredParts.length,
      });
    }

    submitStandardParts();
    onClose();
  };

  return (
    <Dialog
      isOpen
      isFullPage
      icon="upload"
      title={tr.translateAsString("parts.dialog.drop-standard-parts.title")}
      onClose={onClose}
      onSecondaryClick={onClose}
      onPrimaryClick={handleSubmit}
      disablePrimary={someEmptyNames}
    >
      {!grouped && <Spinner />}
      {grouped && (
        <>
          {tr.translate("part.dialog.drop-parts.description")}
          <UploadedPartsList
            partStates={grouped.valid}
            unsupportedFiles={grouped.unsupported}
            nonStandardParts={grouped.nonStandard}
            onUpdatePartStates={(states) =>
              setGrouped((current) =>
                current ? { ...current, valid: states } : undefined
              )
            }
          />
        </>
      )}
    </Dialog>
  );
}
