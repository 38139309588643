import { Select } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../../util";
import { useProcessModel, useProcessModels } from "../tasksSlice";

export default function ProcessModelSelect(props: {
  disabled?: boolean;
  onChange: (modelId: number | null) => void;
  selectedModel: number | null;
}) {
  const { onChange, selectedModel, disabled } = props;
  const tr = useTranslations();

  const { data: models = [] } = useProcessModels();
  const { data: model } = useProcessModel(selectedModel);
  const modelItems = [
    {
      key: -1,
      text: tr.translateAsString("generic.label.none"),
    },
    ...models.map((m) => ({
      key: m.id,
      text: m.title,
    })),
  ];

  const handleChange = (value: number) => {
    if (value === -1) {
      onChange(null);
    } else {
      onChange(value);
    }
  };

  return (
    <Select
      isDisabled={disabled}
      filterable
      activeItem={selectedModel ?? undefined}
      items={modelItems}
      onItemSelect={handleChange}
      intent="outlined"
      isFill
    >
      {model?.title ?? tr.translateAsString("generic.label.none")}
    </Select>
  );
}
