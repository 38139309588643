import {
  DialogBase,
  DialogContent,
  DialogFooter,
  Field,
} from "@aletiq/design-system";
import { Operation } from "@aletiq/types";
import React, { useState } from "react";
import { Input } from "../../../components";
import { useTranslations } from "../../../util";
import { useUpdateOperation } from "./hooks/useUpdateOperation";
export default function RenameOperationForm(props: {
  projectId: number;
  bill: number;
  operation: Operation;
  onClose: () => void;
}) {
  const { projectId, bill, operation, onClose } = props;
  const tr = useTranslations();

  const [editedNumber, setEditedNumber] = useState(operation.number.toString());
  const [editedName, setEditedName] = useState(operation.name);

  const { mutate: updateOperation, isLoading } = useUpdateOperation(
    projectId,
    bill,
    operation.number
  );

  const isEditedNumberValid = !Number.isNaN(Number.parseInt(editedNumber, 10));

  const handleSubmit = () => {
    if (!isEditedNumberValid) {
      return;
    }
    updateOperation(
      {
        number: Number.parseInt(editedNumber),
        name: editedName,
        description: operation.description,
      },
      { onSettled: onClose }
    );
  };

  return (
    <DialogBase style={{ width: 240 }} isDense isOpen onClose={onClose}>
      <DialogContent>
        <Field
          label={tr.translate(
            "project.operation-bill.add-operations.number-label"
          )}
        >
          <Input
            errorHelperText={tr.translateAsString(
              "project.operation-bill.add-operations.number-error-caption"
            )}
            hasError={!isEditedNumberValid}
            isDense
            onChange={setEditedNumber}
            value={editedNumber}
          />
        </Field>
        <Field label={tr.translate("generic.label.name")}>
          <Input isDense onChange={setEditedName} value={editedName} />
        </Field>
      </DialogContent>
      <DialogFooter
        isDense
        primaryButtonProps={{
          text: tr.translateAsString("generic.action.save"),
          onClick: handleSubmit,
          isDisabled: !isEditedNumberValid,
          isLoading,
        }}
        secondaryButtonProps={{
          onClick: onClose,
        }}
      />
    </DialogBase>
  );
}
