import { Passport } from "@aletiq/types";
import React, { useState } from "react";
import { EditablePropertyTemplate } from "../../../../components";
import { EditablePropertyButtonGroup } from "../../../../components/editable-property/buttons";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProjectDefinition } from "../../../project/hooks";
import useListProjectDefinitions from "../../../project/hooks/useListProjectDefinitions";
import ProjectDefinitionLink from "../../../project/ProjectDefinitionLink";
import { ProjectDefinitionSelectMenu } from "../../../project/ProjectNomenclature/ProjectDefinitionSelectMenu";
import { useUpdatePassport } from "../../hooks";

export default function DefinitionIndexField(props: {
  passport: Passport;
  hasIcon?: boolean;
}) {
  const { passport, hasIcon } = props;

  const tr = useTranslations();

  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(passport.parentDefinition);

  const canUpdatePassports = useHasPermission("update:passports");

  const { data: definitions } = useListProjectDefinitions(
    passport.parentProject
  );

  const { data: activeDefinition } = useProjectDefinition(
    passport.parentProject,
    editValue
  );

  const { mutate: updatePassport } = useUpdatePassport(passport.id);

  const toggleIsEditing = () => {
    setIsEditing((e) => !e);
  };

  const handleSubmit = () => {
    updatePassport(
      [
        {
          type: "definition",
          value: editValue,
        },
      ],
      {
        onSettled: () => {
          setIsEditing(false);
        },
      }
    );
  };

  return (
    <EditablePropertyTemplate
      label={tr.translateAsString("project.definition.index")}
      isEditable={canUpdatePassports}
      triggerEditMode={toggleIsEditing}
      isInEditMode={isEditing}
      inline
    >
      {isEditing ? (
        <>
          {definitions && activeDefinition && (
            <ProjectDefinitionSelectMenu
              activeDefinition={activeDefinition}
              definitions={definitions}
              onSelect={(definition) => setEditValue(definition)}
            />
          )}

          <EditablePropertyButtonGroup
            onSubmit={handleSubmit}
            onReset={toggleIsEditing}
          />
        </>
      ) : (
        <ProjectDefinitionLink
          hasIcon={hasIcon}
          projectId={passport.parentProject}
          definitionIdx={passport.parentDefinition}
        />
      )}
    </EditablePropertyTemplate>
  );
}
