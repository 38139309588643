import React from "react";
import { ColumnOption, SelectColumns } from "../../../components";
import { ColumnOptionConfig } from "../../../hooks";
import { useTranslations } from "../../../util";
import { PartColumn } from "../hooks/usePartTableColumns";

export default function SelectPartColumn(props: {
  columnConfig: ColumnOptionConfig<PartColumn>;
  onToggleColumn: (column: PartColumn) => void;
  children: React.ReactNode;
}) {
  const { columnConfig, onToggleColumn, children } = props;
  const tr = useTranslations();

  const columnOptions: ColumnOption<PartColumn>[] = [
    {
      id: "name",
      label: tr.translate("generic.label.name"),
      isMandatory: true,
    },
    {
      id: "description",
      label: tr.translate("generic.label.description"),
    },
    {
      id: "lastIterationName",
      label: tr.translate("part.details.last-revision"),
    },
    {
      id: "lockOwnerName",
      label: tr.translate("part.details.modification"),
    },
    {
      id: "ownerName",
      label: tr.translate("admin.attribute.documents.owner"),
    },
    {
      id: "createdAt",
      label: tr.translate("generic.label.creation-date.title"),
    },
    {
      id: "updatedAt",
      label: tr.translate("generic.label.last-update.title"),
    },
    {
      id: "format",
      label: tr.translate("admin.attribute.parts.format"),
    },
  ];

  return (
    <SelectColumns
      config={columnConfig}
      options={columnOptions}
      onToggleColumn={onToggleColumn}
    >
      {children}
    </SelectColumns>
  );
}
