import { DropdownSP, Menu, UIColor, UIView } from "@aletiq/design-system";
import { MaybeElement } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import FilterMenu from "../filter/FilterMenu";
import styles from "./DropdownMenu.module.scss";

export type DropdownMenuProps = {
  isDense?: boolean;
  isDisabled?: boolean;
  isFilled?: boolean;
  hasFilter?: boolean;
  search?: string;
  onSearchChange?: (search: string) => void;
  placeholder?: string;
  activeLabel?: string | MaybeElement;
  view?: Exclude<UIView, "flat">;
  intent?: UIColor;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
};

export default function DropdownMenu(props: DropdownMenuProps) {
  const {
    isDense,
    isDisabled,
    isFilled,
    hasFilter,
    placeholder,
    activeLabel,
    view = "outlined",
    intent = "primary",
    children,
    search,
    onSearchChange,
    onClick,
  } = props;

  return (
    <div onClick={onClick}>
      <DropdownSP
        className={classNames(
          activeLabel ? undefined : styles.placeholder,
          styles.dropdown
        )}
        fill={isFilled}
        dense={isDense}
        disabled={isDisabled}
        view={view}
        intent={intent}
        placeholder={placeholder}
        text={activeLabel ? activeLabel : placeholder}
        content={
          hasFilter ? (
            <FilterMenu
              search={search}
              onSearchChange={onSearchChange}
              contentClassName={styles.menu}
            >
              {children}
            </FilterMenu>
          ) : (
            <Menu isDense={isDense}>{children}</Menu>
          )
        }
      />
    </div>
  );
}
