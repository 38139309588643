import { ProjectQueryFilterParams, ProjectType } from "@aletiq/types";
import React from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  ButtonSP,
  DefaultFilters,
  Tooltip,
} from "../../../../components";
import { HandleClear, HandleFilter, useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { ProductColumn } from "../../hooks/useProductColumns";
import MoreProductFilters from "../MoreProductFilters";
import SelectProductColumns from "../SelectProductColumns";

export default function ProjectActionBar(props: {
  type: ProjectType;
  filter: ProjectQueryFilterParams;
  handleFilter: HandleFilter<ProjectQueryFilterParams>;
  handleClear: HandleClear<ProjectQueryFilterParams>;
  onCreateProduct: () => void;
  columnConfig: Record<ProductColumn | number, boolean>;
  onToggleColumn: (column: ProductColumn | number) => void;
}) {
  const {
    filter: productFilter,
    handleFilter,
    handleClear,
    onCreateProduct,
    type,
    columnConfig,
    onToggleColumn,
  } = props;

  const tr = useTranslations();
  const canCreateProjects = useHasPermission("create:projects");

  return (
    <ActionBar>
      <DefaultFilters
        objectType="product"
        filters={productFilter}
        handleFilter={handleFilter}
      />
      <MoreProductFilters
        filters={productFilter}
        handleFilter={handleFilter}
        handleClear={handleClear}
      />
      <AlignRight />
      <SelectProductColumns
        columnConfig={columnConfig}
        onToggleColumn={onToggleColumn}
        projectType={type}
      >
        <Tooltip
          content={tr.translate("generic.action.edit-columns.title")}
          position="top"
        >
          <ButtonSP isDense icon="th" view="flat" color="primary" />
        </Tooltip>
      </SelectProductColumns>
      <Button
        isDense
        icon="plus"
        intent="secondary"
        isDisabled={!canCreateProjects}
        onClick={() => onCreateProduct()}
      >
        {tr.translate("project.actions.create", { type })}
      </Button>
    </ActionBar>
  );
}
