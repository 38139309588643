import { RevisionSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { pdmKeys } from "./queries";

export default function useUpdatePartRevisionSpec() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { partId: number; spec: RevisionSpec }) => {
      const { partId, spec } = values;
      await api.pdm.updateDraftRevisionSpec(partId, spec);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pdmKeys.all);
      },
    }
  );
}
