import { EntityId } from "@aletiq/types";
import React, { useState } from "react";
import {
  AlignRight,
  AnchorButton,
  ItemSelect,
  Select,
  Tooltip,
} from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { ProcessCreationDialog } from "../../dialogs/ProcessCreationDialog";
import { useAddProcessRelation, useProcesses } from "../../hooks";
import EntityProcessView from "../EntityProcessView";
import HeaderEntityProcessPanel from "../HeaderEntityProcessPanel/HeaderEntityProcessPanel";
import styles from "./EntityProcesses.module.scss";

export type Filter = "archived" | "active";

export default function EntityProcesses(props: { entityId: EntityId }) {
  const { entityId } = props;
  const tr = useTranslations();

  const [createProcessDialog, setCreateProcessDialog] = useState(false);
  const [filter, setFilter] = useState<Filter>("active");

  const { data: processes = [] } = useProcesses();
  const { data: entityProcesses = [] } = useProcesses({ relation: entityId });
  const { mutate: addProcessRelation } = useAddProcessRelation();
  const canLinkWorkflows = useHasPermission("link:workflows");

  const availableProcesses = processes
    .filter(
      (p1) => !p1.isArchived && !entityProcesses.some((p2) => p1.id === p2.id)
    )
    .map((process) => ({
      key: process.id,
      text: process.title,
    }));

  const activeEntityProcesses = entityProcesses.filter((p) => !p.isArchived);
  const archivedEntityProcesses = entityProcesses.filter((p) => p.isArchived);
  const filteredProcesses =
    filter === "active" ? activeEntityProcesses : archivedEntityProcesses;

  const options: ItemSelect<Filter>[] = [
    {
      key: "active",
      text: tr.translateAsString("entity.workflow.pill.active", {
        count: activeEntityProcesses.length,
      }),
    },
    {
      key: "archived",
      text: tr.translateAsString("entity.workflow.pill.archived", {
        count: archivedEntityProcesses.length,
      }),
    },
  ];

  const onAttach = (processId: number) =>
    addProcessRelation({ process: processId, entity: entityId });

  return (
    <div>
      <div className={styles.toolbar}>
        <Select
          intent="outlined"
          items={options}
          onItemSelect={(filter) => setFilter(filter)}
          activeItem={filter}
          isDense
        >
          {options.find((opt) => opt.key === filter)?.text ?? ""}
        </Select>
        <AlignRight />
        <Tooltip
          position="top-right"
          content={tr.translateAsString("entity.workflow.new")}
        >
          <AnchorButton
            icon="plus"
            intent="primary"
            onClick={() => setCreateProcessDialog(true)}
            isDisabled={!canLinkWorkflows}
            isDense
          />
        </Tooltip>
        <Select
          filterable
          autoFocus
          items={availableProcesses}
          onItemSelect={(processId) => onAttach(processId)}
          isDense
          isDisabled={!canLinkWorkflows}
        >
          <Tooltip
            position="top-right"
            content={tr.translateAsString("entity.workflow.link")}
          >
            <AnchorButton
              icon="new-link"
              intent="secondary"
              isDisabled={!canLinkWorkflows}
              isDense
            />
          </Tooltip>
        </Select>
      </div>

      {createProcessDialog && (
        <ProcessCreationDialog
          initialEntities={[entityId]}
          onClose={() => setCreateProcessDialog(false)}
        />
      )}

      <HeaderEntityProcessPanel />
      <div>
        {filteredProcesses.map((process) => (
          <EntityProcessView
            key={process.id}
            workflow={process}
            workflowTasks={process.tasks}
            entityId={entityId}
          />
        ))}
      </div>
    </div>
  );
}
