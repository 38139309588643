import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { pdmKeys } from "../../pdm/hooks/queries";

export default function usePartProjectBomProjects(partId: number) {
  const api = useApi();
  return useQuery(pdmKeys.projectBom(partId), () =>
    api.pdm.listPartProjectBom(partId)
  );
}
