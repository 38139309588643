import { Icon } from "@aletiq/design-system";
import { ProjectType } from "@aletiq/types";
import { IconName, Intent } from "@blueprintjs/core";
import React from "react";

type ProductIconMap = Record<ProjectType, IconName>;

export const productIcon: ProductIconMap = {
  tool: "wrench",
  article: "projects",
};

export default function ProductIcon(props: {
  type?: ProjectType;
  inline?: boolean;
  iconSize?: number;
  intent?: Intent;
  color?: string;
  className?: string;
}) {
  const { className, type, inline, intent, iconSize, color } = props;

  if (!type) return <></>;

  const icon = productIcon[type] ?? "blank";

  return (
    <Icon
      intent={intent}
      className={className}
      icon={icon}
      iconSize={iconSize}
      color={color}
      inline={inline}
    />
  );
}
