import { Period } from "@aletiq/types";

export const getAbscissaLabels = (period: Period) =>
  new Array(X_LABELS_LENGTH[period])
    .fill("")
    .map((_, index) => getLocalyzeMondayFromIndex(index))
    .reverse();

const getLocalyzeMondayFromIndex = (weekOffset: number) => {
  const today = new Date();
  const dayFromMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
  const dayOfMonth = today.getUTCDate();

  const lastMonday = new Date(today);
  lastMonday.setDate(dayOfMonth - dayFromMonday);

  const monday = new Date(today);
  monday.setDate(lastMonday.getUTCDate() - weekOffset * 7);

  return monday.toLocaleString(navigator.language, {
    day: "numeric",
    month: "short",
  });
};

const X_LABELS_LENGTH: Record<Period, number> = {
  month: 5,
  quarter: 14,
  semester: 27,
  year: 55,
};
