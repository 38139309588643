import { useQuery } from "react-query";
import useApi from "../../../../app/useApi";
import { passportKeys } from "../../../passport/hooks/queries";

export default function useCountPassportsWithDescriptions() {
  const api = useApi();

  return useQuery(passportKeys.countWithDescription(), () =>
    api.passport.countPassportsWithDescriptions()
  );
}
