import { HighlightedText, UIColor } from "@aletiq/design-system";
import cx from "classnames";
import React from "react";
import { useUser } from "../../hooks";
import Color from "../../styles/color.module.scss";
import UserInitial from "../name/UserInitial";
import styles from "./User.module.scss";

type Intent = "primary" | "default";

export default function User(props: {
  intent?: Intent;
  userId: number;
  size?: "default" | "dense" | "small";
  className?: string;
  search?: string;
  onlyInitial?: boolean;
  inline?: boolean;
}) {
  const {
    size = "default",
    userId,
    onlyInitial,
    intent = "primary",
    className,
    search,
    inline,
  } = props;

  const { data: user } = useUser(userId, {
    withEmail: size === "default",
    withDeactivated: true,
  });

  return (
    <div
      className={cx(
        styles.wrapper,
        Color[MAP_COLOR[intent]],
        styles[intent],
        inline && styles.inline,
        className
      )}
    >
      <UserInitial
        userName={user?.displayName || ""}
        size={size}
        inline={inline}
      />
      {!onlyInitial && size === "default" && (
        <div className={styles.full}>
          <div className={styles.name}>
            <HighlightedText text={user?.displayName} highlight={search} />
          </div>
          <HighlightedText
            text={user?.email}
            highlight={search}
            className={styles.email}
          />
        </div>
      )}
      {!onlyInitial && (size === "dense" || size === "small") && (
        <HighlightedText
          text={user?.displayName}
          highlight={search}
          className={styles.name}
        />
      )}
    </div>
  );
}

const MAP_COLOR: Record<Intent, UIColor> = {
  primary: "primary",
  default: "default",
};
