import { H3, Icon, Tag } from "@aletiq/design-system";
import { useIntl } from "@aletiq/translation";
import { Spinner } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { ExtendedColumn, Table, User } from "../../../../components";
import { useNavigate, useSort } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProcesses } from "../../hooks";
import { statusToIntent } from "../../status/util";
import styles from "./ModelStateList.module.scss";
import { ResolvedWorkflow, useResolvedWorkflows } from "./useResolvedWorkflows";
import { WorkflowsStatesSummary } from "./WorkflowsStatesSummary";

export default function ModelStateList(props: { selectedModels: number[] }) {
  const { selectedModels } = props;
  const tr = useTranslations();

  const intl = useIntl();
  const navigate = useNavigate();

  const { data: workflows = [], isLoading } = useProcesses();
  const filteredWorkflows = workflows.filter(
    (w) => !w.isArchived && w.model && selectedModels.includes(w.model)
  );
  const { workflows: resolvedWorkflows, isLoading: isResolvingWorkflows } =
    useResolvedWorkflows(filteredWorkflows);

  const handleOpenWorkflow = (workflow: ResolvedWorkflow) => {
    navigate({
      panel: `workflowId-${workflow.id}`,
    });
  };

  const columns: ExtendedColumn<ResolvedWorkflow>[] = useMemo(
    () => [
      {
        id: "title",
        accessor: "title",
        width: 400,
        Header: tr.translate("analysis.state.list.name"),
        Cell: (cell: CellProps<ResolvedWorkflow>) => (
          <>
            <Icon icon="flow-linear" inline />
            {cell.value}
          </>
        ),
      },
      {
        id: "status",
        accessor: "status",
        width: 150,
        Header: tr.translate("analysis.state.list.status"),
        Cell: (cell: CellProps<ResolvedWorkflow>) => (
          <Tag
            unclickable
            isRound={false}
            intent={statusToIntent(cell.value)}
            isDense
          >
            {tr.translate("analysis.state.list.status.value", {
              status: cell.value,
            })}
          </Tag>
        ),
      },
      {
        id: "doneTaskCount",
        accessor: "doneTaskCount",
        width: 150,
        Header: tr.translate("analysis.state.list.tasks.done"),
        Cell: (cell: CellProps<ResolvedWorkflow>) => (
          <>{cell.value > 0 ? cell.value : "-"}</>
        ),
      },
      {
        id: "taskCount",
        accessor: "taskCount",
        width: 150,
        Header: tr.translate("analysis.state.list.tasks.total"),
      },
      {
        id: "progress",
        accessor: "progress",
        width: 150,
        Header: tr.translate("analysis.state.list.progress"),
        Cell: (cell: CellProps<ResolvedWorkflow>) => (
          <>
            {cell.value > 0
              ? intl.formatNumber(cell.value, {
                  style: "percent",
                })
              : "-"}
          </>
        ),
      },
      {
        id: "ownerName",
        accessor: "ownerName",
        width: 150,
        Header: tr.translate("analysis.state.list.owner"),
        Cell: (cell: CellProps<ResolvedWorkflow>) => (
          <User size="small" userId={cell.row.original.owner} />
        ),
      },
    ],
    [tr, intl]
  );

  const {
    sortKey: key,
    sortDirection: direction,
    onSortKeyChange: onSort,
    sortedList: sortedWorkflows,
  } = useSort<ResolvedWorkflow>(resolvedWorkflows, "title");

  return (
    <div className={styles.container}>
      <H3 className={styles.title}>
        <Icon inline icon="flow-linear" />
        {tr.translate("analysis.state.title")}
      </H3>
      {isLoading || isResolvingWorkflows ? (
        <Spinner />
      ) : (
        <>
          <Table
            columns={columns}
            data={sortedWorkflows}
            sortOptions={{ sortState: { key, direction }, onSort }}
            openRowOptions={{ onOpen: handleOpenWorkflow }}
          />
          <WorkflowsStatesSummary workflows={sortedWorkflows} />
        </>
      )}
    </div>
  );
}
