import {
  Project,
  ProjectQueryFilterParams,
  ProjectQueryOrder,
  ProjectType,
} from "@aletiq/types";
import React, { useEffect } from "react";
import { Button, Table } from "../../components";
import {
  useDelayed,
  useHasPermission,
  usePagination,
  useSortKey,
} from "../../hooks";
import { useTranslations } from "../../util";
import { ProductBatchActionHeader } from "./common";
import { usePaginatedProjects, useProductColumns } from "./hooks";
import { ProductColumn } from "./hooks/useProductColumns";
import styles from "./Projects.module.scss";

export default function PaginatedProjectList(props: {
  filter: ProjectQueryFilterParams;
  projectType: ProjectType;
  onClickCreateProduct: () => void;
  columnConfig: Record<ProductColumn, boolean>;
}) {
  const { filter, projectType, onClickCreateProduct, columnConfig } = props;
  const tr = useTranslations();

  const { sortState, setSortKey } = useSortKey<ProjectQueryOrder>(
    "updatedAt",
    "desc"
  );

  const {
    limit,
    selectedPage,
    handleLimit,
    handleSelectPage,
    resetSelectedPage,
  } = usePagination();

  useEffect(() => {
    resetSelectedPage();
  }, [projectType, sortState, filter, resetSelectedPage]);

  const canSelectRows = useHasPermission("update:projects");
  const canCreateProjects = useHasPermission("create:projects");

  const {
    data: projectsResponse,
    isLoading: projectsLoading,
    isFetching: projectsFetching,
  } = usePaginatedProjects({
    projectType,
    limit,
    offset: selectedPage * limit,
    order: sortState.key,
    orderDirection: sortState.direction,
    properties: true,
    ...filter,
  });
  const projects = projectsResponse?.list ?? [];
  const projectCount = projectsResponse?.count ?? 0;

  const columns = useProductColumns({
    productType: projectType,
    config: columnConfig,
  });
  const isFetching = useDelayed(projectsFetching);

  const renderBatchActionsHeader = (
    selectedProducts: Project[],
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => (
    <ProductBatchActionHeader
      selectedProducts={selectedProducts}
      toggleAllRowsSelected={toggleAllRowsSelected}
      productType={projectType}
    />
  );

  return (
    <Table
      searchString={filter.search}
      columns={columns}
      data={projects}
      noData={
        <div className={styles.no_data}>
          {tr.translate(
            filter.search === ""
              ? "project.list.no-data"
              : "project.list.no-data.filtered",
            { type: projectType }
          )}
          <Button
            isDense
            isDisabled={!canCreateProjects}
            onClick={onClickCreateProduct}
            icon="plus"
            intent="primary"
            className={styles.create_button}
          >
            {tr.translate("project.actions.create", { type: projectType })}
          </Button>
        </div>
      }
      sortOptions={{
        sortState,
        onSort: setSortKey,
      }}
      paginationOptions={{
        itemCount: projectCount,
        limit,
        selectedPage,
        onSetLimit: handleLimit,
        onSetPage: handleSelectPage,
      }}
      renderBatchActionsHeader={
        canSelectRows ? renderBatchActionsHeader : undefined
      }
      isFetching={isFetching || projectsLoading}
    />
  );
}
