import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useSetPassportsParentVersion() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (params: { passportsIds: number[]; versionId: number }) => {
      const { passportsIds, versionId } = params;
      for (const passportId of passportsIds) {
        await api.passport.updatePassport(passportId, [
          {
            type: "projectIteration",
            value: versionId,
          },
        ]);
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(passportKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.passports.update-version"),
        });
      },
    }
  );
}
