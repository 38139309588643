import { PassportComponent } from "@aletiq/types";
import { useMemo } from "react";
import { indexBy } from "../../../util";
import { useProjectBOM } from "../../project/hooks";
import usePassportComponents from "./usePassportComponents";
import usePassports from "./usePassports";

export default function usePassportBom(
  passportId: number,
  parentProjectId: number,
  parentDefinitionIdx: number
) {
  const { data: passportComponents = [] } = usePassportComponents(passportId);
  const { data: parentComponents = [] } = useProjectBOM(
    parentProjectId,
    parentDefinitionIdx
  );
  const { data: passports = [] } = usePassports();

  const passportById = indexBy(passports, "id");
  const bomComponents = passportComponents.reduce<
    Record<number, PassportComponent[]>
  >((acc, bomComponent) => {
    const parentProject =
      passportById[bomComponent.passport]?.parentProject ?? -1;

    return {
      ...acc,
      [parentProject]: [...(acc[parentProject] ?? []), bomComponent],
    };
  }, {});

  const passportBomComponents: {
    product: number;
    component: PassportComponent | null;
  }[] = useMemo(() => {
    return parentComponents.flatMap((parentComp) => {
      const comps = bomComponents[parentComp.entity] ?? [];

      const assigned = comps.map((comp) => ({
        product: parentComp.entity,
        component: comp,
      }));

      // Handle case where quantity was lowered and assigned is greater than quantity
      const unassignedCount = Math.ceil(
        Math.max(parentComp.quantity - assigned.length, 0)
      );
      const unassigned = new Array(unassignedCount).fill({
        product: parentComp.entity,
        component: null,
      });
      return [...assigned, ...unassigned];
    });
  }, [parentComponents, bomComponents]);

  const hasConflictWithParentBom =
    passportBomComponents.length !==
    parentComponents
      .map((c) => Math.ceil(c.quantity))
      .reduce((acc, inc) => acc + inc, 0);

  return { passportBomComponents, hasConflictWithParentBom };
}
