import { EditableText } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../util";
import styles from "./Revisions.module.scss";

export default function RevisionEditableName(props: {
  isEditable?: boolean;
  isAlreadyUsed: boolean;
  value: string;
  onChange: (value: string) => void;
  onConfirm: (value: string) => void;
}) {
  const {
    isAlreadyUsed,
    value,
    isEditable = true,
    onChange,
    onConfirm,
  } = props;

  const tr = useTranslations();

  return (
    <div>
      {isEditable && (
        <EditableText
          intent={isAlreadyUsed ? "danger" : undefined}
          className={
            isAlreadyUsed ? styles.duplicate_name : styles.revision_name
          }
          value={value}
          onChange={onChange}
          onConfirm={onConfirm}
        />
      )}

      {!isEditable && <span className={styles.revision_name}>{value}</span>}

      {isAlreadyUsed && (
        <span className={styles.duplicate_name}>
          <em>{tr.translate("document.details.revisions.name.in-use")}</em>
        </span>
      )}
    </div>
  );
}
