import { UserRole } from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_ACCOUNT_CREATED,
  ANALYTICS_ACCOUNT_EMAIL,
  ANALYTICS_ACCOUNT_ROLE,
  ANALYTICS_MUTATION_STATUS,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../../analytics";
import {
  Dialog,
  Field,
  Input,
  ItemSelect,
  Select,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import {
  EmailInput,
  PasswordCreator,
  USER_ROLE_WITH_TRANSLATION,
} from "../common";
import { useCreateUser } from "../hooks";
import styles from "./Dialogs.module.scss";

export function CreateUserDialog(props: { onClose: () => void }) {
  const { onClose } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const [role, setRole] = useState<UserRole>("viewer");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [sharedUserName, setSharedUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const { mutate: createUser, isLoading } = useCreateUser();

  const isCreatingShared = role === "shared";
  const hasName =
    (isCreatingShared && sharedUserName !== "") ||
    (!isCreatingShared && firstName !== "" && lastName !== "");
  const canSubmit =
    hasName && isEmailValid && passwordIsValid && password === confirmPassword;

  const selectItems: ItemSelect<UserRole>[] = USER_ROLE_WITH_TRANSLATION.map(
    ({ key, label }) => ({ key, text: tr.translateAsString(label) })
  );

  const handlePasswordChange = (value: string, passwordIsValid: boolean) => {
    setPassword(value);
    setPasswordIsValid(passwordIsValid);
  };

  const handleEmailChange = (value: string, isEmailValidProps: boolean) => {
    setEmail(value);
    setIsEmailValid(isEmailValidProps);
  };

  const onSubmit = () => {
    const user = isCreatingShared
      ? {
          displayName: sharedUserName,
          email,
          role,
          password,
        }
      : {
          displayName: `${firstName} ${lastName}`,
          firstName: firstName,
          lastName: lastName,
          email,
          role,
          password,
        };

    createUser(user, {
      onSettled: (_, error) => {
        analytics.track(ANALYTICS_ACCOUNT_CREATED, {
          [ANALYTICS_ACCOUNT_EMAIL]: user.email,
          [ANALYTICS_ACCOUNT_ROLE]: user.role,
          [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
        });
        onClose();
      },
    });
  };

  return (
    <Dialog
      isOpen
      icon="plus"
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("admin.users.dialog.add-user.title")}
      onPrimaryClick={onSubmit}
      disablePrimary={!canSubmit}
      submitting={isLoading}
    >
      <div className={styles.dialog__inner}>
        <div className={styles.flex}>
          <Field
            label={tr.translate("admin.users.dialog.add-user.role")}
            className={styles.flexItem}
          >
            <Select
              isFill
              intent="outlined"
              items={selectItems}
              activeItem={role}
              onItemSelect={setRole}
            >
              {selectItems.find((item) => item.key === role)?.text ??
                tr.translateAsString("admin.users.page.role.placeholder")}
            </Select>
          </Field>
          <div className={styles.filler} />
        </div>
        {!isCreatingShared && (
          <div className={styles.flex}>
            <Field
              label={tr.translate(
                "admin.users.dialog.add-named-user.firstname"
              )}
              className={styles.flexItem}
              isMandatory
            >
              <Input
                autoFocus
                placeholder={
                  tr.translateAsString(
                    "admin.users.dialog.add-named-user.firstname"
                  ) + "..."
                }
                value={firstName}
                onChange={setFirstname}
              />
            </Field>
            <Field
              label={tr.translateAsString(
                "admin.users.dialog.add-named-user.lastname"
              )}
              className={styles.flexItem}
              isMandatory
            >
              <Input
                placeholder={
                  tr.translateAsString(
                    "admin.users.dialog.add-named-user.lastname"
                  ) + "..."
                }
                value={lastName}
                onChange={setLastname}
                autoFocus={false}
              />
            </Field>
          </div>
        )}

        {isCreatingShared && (
          <Field
            label={tr.translate("admin.users.dialog.add-shared-user.name")}
            isMandatory
          >
            <Input
              fill
              placeholder={tr.translateAsString("generic.label.name") + "..."}
              value={sharedUserName}
              onChange={setSharedUserName}
            />
          </Field>
        )}

        <EmailInput
          label={tr.translateAsString(
            "admin.users.dialog.add-shared-user.email"
          )}
          placeholder={tr.translateAsString(
            "admin.users.dialog.add-user.email.placeholder"
          )}
          helperText={
            isCreatingShared
              ? tr.translateAsString("admin.users.dialog.add-named-user.email")
              : undefined
          }
          value={email}
          setValue={handleEmailChange}
          containerClassName={styles.spaceTop}
          autoFocus={false}
          isMandatory
        />
        <PasswordCreator
          value={password}
          valueConfirm={confirmPassword}
          onSetValue={handlePasswordChange}
          onSetConfirmValue={setConfirmPassword}
          containerClassName={styles.spaceTop}
          isMandatory
        />
      </div>
    </Dialog>
  );
}
