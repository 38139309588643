import { Process, Task } from "@aletiq/types";
import { useCurrentUser, useHasPermission } from "../../../hooks";

const useHasUpdateRightOnTask = (task: Task, workflow: Process) => {
  const { data: currentUser } = useCurrentUser();

  const canUpdateAllWorkflows = useHasPermission("update:all-workflows");
  const canUpdateOwnWorkflows = useHasPermission("update:own-workflows");
  const canUpdateOwnTaskStatus = useHasPermission("update:own-task-status");

  const isTaskOwner = task.owners.includes(currentUser?.id!);
  const isWorkflowOwner = currentUser?.id === workflow.owner;
  return (
    canUpdateAllWorkflows ||
    (isWorkflowOwner && canUpdateOwnWorkflows) ||
    (isTaskOwner && canUpdateOwnTaskStatus)
  );
};

export default useHasUpdateRightOnTask;
