import { DocumentCitation } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { documentKeys } from "./queries";

export default function useAddDocumentCitations() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (citations: DocumentCitation[]) =>
      api.document.addDocumentCitations(citations),
    {
      onSuccess: (_, citations) => {
        for (const { citation, citedBy } of citations) {
          queryClient.invalidateQueries(documentKeys.citations(citedBy));
          queryClient.invalidateQueries(documentKeys.citedBy(citation));
        }
      },
    }
  );
}
