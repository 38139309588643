import { EntityId, Process, Task } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";
import {
  AlertPopover,
  AnchorButton,
  Icon,
  RemoveLinkIcon,
  Tooltip,
} from "../../../../components";
import WorkflowLink from "../../../../components/link/WorkflowLink";
import { useHasPermission } from "../../../../hooks";
import { sortByNumber, useTranslations } from "../../../../util";
import { useDeleteProcessRelation } from "../../hooks";
import RowEntityProcessPanelView from "../RowEntityProcessPanelView/RowEntityProcessPanelView";
import styles from "./EntityProcessView.module.scss";

export default function EntityProcessView(props: {
  workflow: Process;
  workflowTasks: Task[];
  entityId: EntityId;
}) {
  const { workflow, workflowTasks, entityId } = props;
  const tr = useTranslations();

  const canLinkWorkflows = useHasPermission("link:workflows");

  const [isFolded, setIsFolded] = useState<boolean>(true);

  const { mutate: deleteWorkflowLink, isLoading: isRemovingLink } =
    useDeleteProcessRelation();

  const handleToggleFold = () => setIsFolded((isFolded) => !isFolded);

  const handleUnlinkWorkflow = () => {
    deleteWorkflowLink({ process: workflow.id, entity: entityId });
  };

  const sortedTasks = useMemo(
    () => sortByNumber(workflowTasks, (task) => task.order),
    [workflowTasks]
  );

  return (
    <div className={styles.workflow}>
      <div className={styles.header}>
        <div className={styles.flex}>
          <Icon
            inline
            icon={isFolded ? "chevron-down" : "chevron-right"}
            className={styles.fold_icon}
            onClick={handleToggleFold}
          />
          <Text ellipsize className={styles.title}>
            <WorkflowLink workflow={workflow} />
          </Text>
        </div>
        <AlertPopover
          icon={<RemoveLinkIcon />}
          title={tr.translateAsString("workflow.dialog.remove.link.title")}
          content={tr.translateAsString(
            "workflow.dialog.remove.link.description"
          )}
          onPrimaryClick={handleUnlinkWorkflow}
          isDense
        >
          <Tooltip
            position="top-right"
            content={tr.translateAsString(
              "tasks.process.delete.relation.workflow"
            )}
            className={styles.menu_target}
          >
            <AnchorButton
              isDense
              icon={<RemoveLinkIcon />}
              isLoading={isRemovingLink}
              isDisabled={!canLinkWorkflows}
            />
          </Tooltip>
        </AlertPopover>
      </div>

      {isFolded && workflowTasks.length > 0 && (
        <div className={styles.table}>
          {sortedTasks.map((task) => (
            <RowEntityProcessPanelView
              key={task.id}
              task={task}
              workflow={workflow}
              className={styles.row}
            />
          ))}
        </div>
      )}
    </div>
  );
}
