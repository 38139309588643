import { Part } from "@aletiq/types";
import { Classes, Tab } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useState } from "react";
import useApi from "../../../app/useApi";
import {
  Activities,
  Button,
  DeleteAlert,
  Divider,
  PanelDetails,
  PanelRenamableTitle,
  PartIcon,
  Popover2,
  Tabs,
} from "../../../components";
import {
  useCurrentUser,
  useDownloadQueue,
  useHasPermission,
  useNavigate,
  useNavigateTo,
} from "../../../hooks";
import {
  DEFAULT_STANDARD_PART_TAB,
  isValidTabId,
  useTranslations,
} from "../../../util";
import UploadStandardFileDialog from "../dialogs/UploadStandardFileDialog";
import { useDeletePart, useRenamePart } from "../hooks";
import PartProducts from "../PartDetails/PartProducts";
import PartUsedIn from "../PartDetails/PartUsedIn";
import isPartParent from "../services/isPartParent";
import styles from "./StandardPartDetails.module.scss";
import { StandardPartMenu } from "./StandardPartMenu";
import StandardPartProperties from "./StandardPartProperties";

type TabId = "activities" | "used-in";

export default function StandardPartDetails(props: {
  part: Part;
  onClose: () => void;
  tab?: string;
}) {
  const { part, onClose, tab = DEFAULT_STANDARD_PART_TAB } = props;
  const partId = part.id;
  const tr = useTranslations();
  const api = useApi();
  const navigate = useNavigate();
  const canUpdateParts = useHasPermission("update:parts");

  const { handleDownload } = useDownloadQueue();
  const { data: currentUser } = useCurrentUser();
  const { mutate: deletePart } = useDeletePart();
  const { mutate: renamePart } = useRenamePart();

  //state
  const [deleteAlert, setDeleteAlert] = useState<{
    onDelete: () => void;
    content: string;
  }>();
  const [isRenaming, setRenaming] = useState(false);
  const [editedName, setEditedName] = useState<string>("");
  const [dialog, setDialog] = useState<"upload-design" | undefined>();

  const handleDownloadArchive = async () => {
    handleDownload({
      fetchToken: () => api.pdm.getPartCadFileLink(partId),
      forceDownload: true,
    });
  };

  // View
  const partIsLocked = part.lockOwner !== null;

  const lockState = partIsLocked
    ? part.lockOwner === currentUser?.id
      ? "current_user"
      : "other_user"
    : "not_locked";

  const submitNewName = () => {
    renamePart({ partId, name: editedName || "" });
    setRenaming(false);
  };

  const handleDelete = () => {
    deletePart(partId, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  const onBomEdition = useNavigateTo({ path: `/cad/${partId}/bom` });

  const handleSetTab = (tabId: TabId) => {
    navigate({ panelTab: tabId.toString() });
  };

  return (
    <PanelDetails
      id={partId}
      title={
        <PanelRenamableTitle
          name={part.name}
          icon={<PartIcon isStandard type={part.type} iconSize={20} />}
          isRenaming={isRenaming}
          resetIsRenaming={() => {
            setRenaming(false);
          }}
          onSubmit={submitNewName}
        />
      }
      additionalButtons={
        <Popover2
          content={
            <StandardPartMenu
              onDownloadArchive={handleDownloadArchive}
              lockState={lockState}
              onStartRenaming={() => {
                setEditedName(part.name ?? "");
                setRenaming(true);
              }}
              onDelete={() =>
                setDeleteAlert({
                  onDelete: handleDelete,
                  content: tr.translateAsString("part.action.delete.message"),
                })
              }
              onBomEdition={onBomEdition}
              hasComponents={part && isPartParent(part.type)}
            />
          }
          minimal
          position="bottom-right"
        >
          <Button icon="more" isDense />
        </Popover2>
      }
      navigateTooltip={tr.translateAsString("part.details.open.cad-space")}
      canNavigateToList={false}
      navigateToList={() => {}}
    >
      <div className={classNames(Classes.DRAWER_BODY, styles.drawer_body)}>
        <StandardPartProperties part={part} />
        <Divider className={styles.divider} />
        <Tabs
          selectedTabId={
            isValidTabId(tab, "standard-part") ? tab : DEFAULT_STANDARD_PART_TAB
          }
          onChange={handleSetTab}
          center
          isDense
        >
          <Tab
            id="activities"
            panel={<Activities entity={{ type: "part", id: partId }} />}
          >
            {tr.translate("part.details.activities")}
          </Tab>
          <Tab id="used-in" panel={part && <PartUsedIn partId={partId} />}>
            {tr.translate("part.details.used-in")}
          </Tab>
          <Tab
            id="products"
            panel={
              <PartProducts
                partId={partId}
                part={part}
                disableEdit={!canUpdateParts}
              />
            }
            className={styles.tab}
          >
            {tr.translate("part.details.projects")}
          </Tab>
        </Tabs>
      </div>
      {deleteAlert && (
        <DeleteAlert
          elementName={""}
          onDelete={deleteAlert.onDelete}
          onClose={() => setDeleteAlert(undefined)}
          message={deleteAlert.content}
        />
      )}
      {dialog === "upload-design" && (
        <UploadStandardFileDialog
          partId={partId}
          onClose={() => setDialog(undefined)}
        />
      )}
    </PanelDetails>
  );
}
