import React from "react";
import { Menu, MenuDivider, MenuItem } from "../../../components";
import { useCurrentUser, useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { PartDialog } from "./PartDialogs";
import { LockState } from "./PartIterations";

export function PartMenu(props: {
  lockState: LockState;
  onDelete: () => void;
  onDownloadArchive: () => void;
  onOpenDialog: (dialog: PartDialog) => void;
  onStartRenaming: () => void;
  hasComponents?: boolean;
  hasDraftRevision?: boolean;
  partOwner: number;
}) {
  const {
    onDownloadArchive,
    lockState,
    onOpenDialog,
    onStartRenaming,
    onDelete,
    hasComponents = false,
    hasDraftRevision,
    partOwner,
  } = props;

  const tr = useTranslations();

  const { data: currentUser } = useCurrentUser();

  const canUpdateParts = useHasPermission("update:parts");
  const canDeleteParts = useHasPermission("delete:parts");
  const canUpdatePartAccess =
    useHasPermission("update:all-access") ||
    (currentUser && currentUser?.id === partOwner);

  const isLocked = lockState !== "not_locked";
  const isLockedByCurrentUser = lockState === "current_user";
  const isLockedByOtherUser = lockState === "other_user";

  return (
    <Menu intent="minimal" isDense>
      <MenuItem
        disabled={isLocked || !canUpdateParts}
        icon="lock"
        text={tr.translateAsString("part.action.lock")}
        onClick={() => onOpenDialog("lock-part")}
        isDense
      />
      <MenuItem
        disabled={!isLockedByCurrentUser || !canUpdateParts}
        icon="unlock"
        text={tr.translateAsString("part.action.unlock")}
        onClick={() => onOpenDialog("unlock-part")}
        isDense
      />
      <MenuDivider />
      <MenuItem
        icon="annotation"
        disabled={!canUpdateParts}
        text={tr.translateAsString("generic.action.rename")}
        onClick={onStartRenaming}
        isDense
      />
      <MenuItem
        icon="diagram-tree"
        disabled={
          !hasComponents ||
          !canUpdateParts ||
          !hasDraftRevision ||
          isLockedByOtherUser
        }
        text={tr.translateAsString("part.details.modify.bom")}
        onClick={() => onOpenDialog("edit-bom")}
        isDense
      />
      <MenuItem
        icon="people"
        text={tr.translate("part.action.share")}
        onClick={() => onOpenDialog("edit-access")}
        disabled={!canUpdatePartAccess}
        isDense
      />
      <MenuDivider />
      <MenuItem
        icon="download"
        text={tr.translateAsString("part.action.download")}
        onClick={onDownloadArchive}
        isDense
      />
      <MenuItem
        disabled={isLockedByOtherUser || !canDeleteParts}
        icon="trash"
        text={tr.translateAsString("generic.action.delete")}
        onClick={onDelete}
        isDense
      />
    </Menu>
  );
}
