import { PartComponent } from "@aletiq/types";
import { sortByNumber } from "../../../../util";
import { ComponentTree } from "../../AllParts/AllPartsType";

export type FlattenedAssemblyTree = {
  component: PartComponent;
  depth: number;
};

export default function assemblyComponentsToApprove(
  assemblyComponents: ComponentTree[],
  currentUserId: number
) {
  // flatten the assembly on components width drafts revisions,
  // to look for revisions to approve first and locked draft that block approval
  function flatten(components: ComponentTree[], depth: number) {
    let flattened: FlattenedAssemblyTree[] = [];

    components.forEach((component: ComponentTree) => {
      const { name, part, iteration, isIgnored } = component;
      const isDraft = component?.iteration?.state === "draft";

      if (isDraft && component.subRows && component.subRows.length > 0) {
        flattened = [
          ...flattened,
          {
            component: { name, part, iteration, isIgnored },
            depth,
          },
          ...flatten(component.subRows, depth + 1),
        ];
      } else if (isDraft) {
        flattened = [
          ...flattened,
          { component: { name, part, iteration, isIgnored }, depth },
        ];
      }
    });
    return flattened;
  }

  const flattenedTree = flatten(assemblyComponents, 1);

  const hasLockedComponentDrafts = flattenedTree.some(
    (c) =>
      !c.component.part?.isStandard &&
      c.component.iteration?.state === "draft" &&
      c.component.part?.lockOwner !== null &&
      c.component.part?.lockOwner !== currentUserId
  );

  //sub components need approval before assembly can be approved
  //they have to be approved starting from the deepest tree level
  const componentsToApprove = flattenedTree.filter(
    (c) =>
      !c.component.part?.isStandard &&
      c.component.iteration?.state === "draft" &&
      (!c.component.part?.lockOwner ||
        c.component.part.lockOwner === currentUserId)
  );

  const sortedComponents = sortByNumber(
    componentsToApprove,
    (component) => -component.depth
  );

  return {
    hasLockedComponentDrafts,
    componentsToApprove: sortedComponents,
  };
}
