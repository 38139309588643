import {
  Operation,
  OperationBill,
  OperationDocument,
  OperationTool,
} from "@aletiq/types";
import { Tab } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  Card,
  CardList,
  EditableText,
  Field,
  GenericStatusTag,
  Icon,
  Menu,
  MenuItem,
  Popover2,
  SearchInput,
  Tabs,
  Tooltip,
} from "../../../components";
import { useNavigate } from "../../../hooks";
import { Diff, useTranslations } from "../../../util";
import AddDocumentsForm from "./AddDocumentsForm";
import { AddToolsDialog } from "./dialogs";
import { useAddOperationDocuments, useUpdateOperation } from "./hooks";
import NoDocument from "./NoDocument";
import OperationBillSelect from "./OperationBillSelect";
import styles from "./OperationDetailsEditor.module.scss";
import OperationDocumentEditorView from "./OperationDocumentEditorView";
import OperationItemsCount from "./OperationItemsCount";
import NoTool from "./OperationTool/NoTool";
import OperationToolEditor from "./OperationTool/OperationToolEditor";
import PageContent from "./PageContent";
import RenameOperationForm from "./RenameOperationForm";

type OperationDialog = "rename" | "add-tools" | "add-documents";

export default function OperationDetailsEditor(props: {
  projectId: number;
  bill: OperationBill;
  bills: OperationBill[];
  diff: Diff<Operation>;
  documentsDiff: Record<number, Diff<OperationDocument>>;
  toolsDiff: Record<number, Diff<OperationTool>>;
  operation: Operation;
}) {
  const { projectId, bill, bills, diff, documentsDiff, toolsDiff, operation } =
    props;
  const tr = useTranslations();
  const navigate = useNavigate();

  const handleShowBillOperation = (billId: number) =>
    navigate({
      path: `/project/${projectId}/operation-bills/${billId}/operations/${operation.number}`,
    });

  const handleCloseDialog = () => setDialog(undefined);

  const [dialog, setDialog] = useState<OperationDialog>();
  const [search, setSearch] = useState("");

  const { isLoading, mutate: handleSubmitAddDocuments } =
    useAddOperationDocuments(projectId, bill.id, operation.number);
  const { isLoading: isUpdatingOperation, mutate: updateOperation } =
    useUpdateOperation(projectId, bill.id, operation.number);

  const handleUpdateDescription = (description: string) => {
    updateOperation({
      name: operation.name,
      number: operation.number,
      description,
    });
  };

  return (
    <PageContent>
      <Field label={tr.translate("project.operation-bill.bill-index")}>
        <OperationBillSelect
          activeBill={bill}
          bills={bills}
          onSelect={handleShowBillOperation}
        />
      </Field>
      <ActionBar>
        <Tooltip
          position="top"
          content={tr.translate("project.operation-bill.search-documents")}
        >
          <SearchInput isDense value={search} onChange={setSearch} />
        </Tooltip>
        <AlignRight />
        <Popover2
          content={
            <Menu isDense>
              <MenuItem
                isDense
                icon="document"
                onClick={() => setDialog("add-documents")}
                text={tr.translate("project.operation-bill.add", {
                  type: "document",
                })}
              />
              <MenuItem
                isDense
                icon="wrench"
                onClick={() => setDialog("add-tools")}
                text={tr.translate("project.operation-bill.add", {
                  type: "tool",
                })}
              />
            </Menu>
          }
        >
          <Button isDense icon="plus" intent="secondary">
            {tr.translate("project.operation-bill.add", { type: "any" })}
          </Button>
        </Popover2>
      </ActionBar>
      <Card
        title={
          <>
            {operation.number} - {operation.name}
            <Button isDense icon="edit" onClick={() => setDialog("rename")} />
          </>
        }
        titleClassName={styles.card_title}
        headerActions={
          <>
            <GenericStatusTag status={diff.status} />
            <OperationItemsCount
              documentCount={operation.documents.length}
              toolCount={operation.tools.length}
            />
          </>
        }
      >
        <Field label={tr.translate("generic.label.description")}>
          <EditableText
            multiline
            defaultValue={operation.description}
            onConfirm={handleUpdateDescription}
            disabled={isUpdatingOperation}
          />
        </Field>
        <Tabs defaultSelectedTabId="documents" isDense>
          <Tab
            id="documents"
            title={
              <>
                <Icon icon="document" inline />
                {tr.translate("project.operation-bill.tab.documents", {
                  count: operation.documents.length,
                })}
              </>
            }
            panel={
              <CardList>
                {operation.documents.map((operationDoc) => {
                  const changeStatus =
                    documentsDiff[operationDoc.document].status;
                  return (
                    <OperationDocumentEditorView
                      key={operationDoc.document}
                      projectId={projectId}
                      billId={bill.id}
                      operationNumber={operation.number}
                      operationDocument={operationDoc}
                      status={changeStatus}
                      search={search}
                    />
                  );
                })}
                {operation.documents.length === 0 && (
                  <NoDocument
                    projectId={projectId}
                    billId={bill.id}
                    operationNumber={operation.number}
                    canAddDocument
                  />
                )}
              </CardList>
            }
          />
          <Tab
            id="tools"
            title={
              <>
                <Icon icon="wrench" inline />
                {tr.translate("project.operation-bill.tab.tools", {
                  count: operation.tools.length,
                })}
              </>
            }
            panel={
              <CardList>
                {operation.tools.map((operationTool) => {
                  const changeStatus = toolsDiff[operationTool.tool].status;
                  return (
                    <OperationToolEditor
                      key={operationTool.tool}
                      operationTool={operationTool}
                      status={changeStatus}
                      search={search}
                      projectId={projectId}
                      billId={bill.id}
                      operationNumber={operation.number}
                    />
                  );
                })}
                {operation.tools.length === 0 && (
                  <NoTool
                    projectId={projectId}
                    billId={bill.id}
                    operation={operation}
                    canAddTool
                  />
                )}
              </CardList>
            }
          />
        </Tabs>
      </Card>

      {dialog === "add-documents" ? (
        <AddDocumentsForm
          title={tr.translateAsString(
            "project.operation-bill.add-documents.title"
          )}
          caption={tr.translateAsString(
            "project.operation-bill.add-documents.caption"
          )}
          onSubmit={handleSubmitAddDocuments}
          projectId={projectId}
          onClose={handleCloseDialog}
          onSuccess={handleCloseDialog}
          isSubmitting={isLoading}
        />
      ) : dialog === "rename" ? (
        <RenameOperationForm
          projectId={projectId}
          bill={bill.id}
          operation={operation}
          onClose={handleCloseDialog}
        />
      ) : dialog === "add-tools" ? (
        <AddToolsDialog
          projectId={projectId}
          bill={bill.id}
          operation={operation}
          onClose={handleCloseDialog}
        />
      ) : null}
    </PageContent>
  );
}
