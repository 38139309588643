import { Property, PropertyValue } from "@aletiq/types";
import React from "react";
import { EditablePropertyDate } from "../../../../components";
import { formatDate, toDate, useTranslations } from "../../../../util";

export default function CustomDateField(props: {
  property: Property;
  propertyValue?: { type: "date"; value: string };
  onSubmit: (value: PropertyValue) => void;
  isSubmitting?: boolean;
  canEdit: boolean;
  search?: string;
  hasDescriptionTooltip?: boolean;
  minWidth?: number;
}) {
  const {
    propertyValue,
    property,
    canEdit,
    onSubmit,
    hasDescriptionTooltip,
    search,
    minWidth,
  } = props;
  const tr = useTranslations();

  const handleSubmit = (date: Date | null) => {
    if (date) {
      onSubmit({ type: "date", value: formatDate(date) });
    } else {
      onSubmit(null);
    }
  };

  return (
    <EditablePropertyDate
      value={propertyValue?.value ? toDate(propertyValue.value) : null}
      isEditable={canEdit}
      onSubmit={handleSubmit}
      inline
      label={property.label}
      emptyState={tr.translateAsString("generic.label.none")}
      tooltipText={hasDescriptionTooltip ? property.description : undefined}
      search={search}
      minWidth={minWidth}
    />
  );
}
