import React from "react";
import { Route } from "react-router-dom";
import AletiqAdminTenants from "../features/aletiq-admin/AletiqAdminTenants";
import AletiqAdminTenantUsers from "../features/aletiq-admin/AletiqAdminTenantUsers";
import AletiqAdminUserExport from "../features/aletiq-admin/AletiqAdminUserExports";

export default function AletiqAdminRoutes() {
  return (
    <>
      <Route path="/aletiq-admin/user-exports">
        <AletiqAdminUserExport />
      </Route>
      <Route path="/aletiq-admin/tenants">
        <AletiqAdminTenants />
      </Route>
      <Route path="/aletiq-admin/:tenantId/users">
        <AletiqAdminTenantUsers />
      </Route>
    </>
  );
}
