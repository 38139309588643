import { OperationSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { projectKeys } from "../../hooks/queries";

export function useUpdateOperation(
  projectId: number,
  bill: number,
  operation: number
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    (spec: OperationSpec) =>
      api.operations.updateProjectOperation(projectId, bill, operation, spec),
    {
      onSettled: () => {
        return queryClient.invalidateQueries(projectKeys.allBills);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
