import { TaskStatus } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { notificationKeys } from "../../notifications/hooks/queries";
import { processKeys, taskKeys } from "./queries";

type StatusUpdate = { task: number; title: string; status: TaskStatus };

export default function useUpdateTaskStatus() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    (update: StatusUpdate) =>
      api.task.updateTaskStatus(update.task, update.status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(processKeys.all);
        queryClient.invalidateQueries(taskKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
        queryClient.invalidateQueries(notificationKeys.count());
      },
      onError: (_, update) => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.task.update.status", {
            title: update.title,
          }),
        });
      },
    }
  );
}
