import { Spinner, SpinnerSize } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { PageContents } from "../../components";
import { useNavigate } from "../../hooks";
import { useTranslations } from "../../util";
import { ANALYTICS_VISITED_COPY_PRODUCT, useTrackEvent } from "../../analytics";
import styles from "./CopyProjectPage.module.scss";
import { useProject } from "./hooks";
import useDuplicateProject from "./hooks/useDuplicateProject";

export default function CopyProjectPage(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  const navigate = useNavigate();
  useTrackEvent(ANALYTICS_VISITED_COPY_PRODUCT);

  const { data: project } = useProject(projectId);
  const { mutate: duplicateProject } = useDuplicateProject();

  useEffect(() => {
    duplicateProject(projectId, {
      onSuccess: (copyId) => navigate({ path: `/project/${copyId}` }),
    });
  }, [navigate, duplicateProject, projectId]);

  return (
    <PageContents>
      <div className={styles.content}>
        <Spinner size={SpinnerSize.LARGE} />
        {project
          ? tr.translate("project.actions.duplicate.message", {
              type: project.isArticle ? "article" : "tool",
            })
          : null}
      </div>
    </PageContents>
  );
}
