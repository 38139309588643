import { useCallback, useState } from "react";

export default function useEditedAccess(
  owner: number,
  isPublic: boolean,
  usersWithAccess: number[],
  groupsWithAccess: number[]
) {
  const { editedValue: editedOpenAccess, setValue: handleSetOpenAccess } =
    useEditedValue(isPublic);

  const { editedValue: editedOwner, setValue: handleEditOwner } =
    useEditedValue(owner);

  const {
    editedArray: editedGroups,
    addValues: handleAddGroups,
    removeValue: handleRemoveGroup,
  } = useEditedArray(groupsWithAccess);

  const {
    editedArray: editedUsers,
    addValues: handleAddUsers,
    removeValue: handleRemoveUser,
  } = useEditedArray(usersWithAccess);

  return {
    editedGroups,
    editedOpenAccess,
    editedOwner,
    editedUsers,
    handleAddGroups,
    handleAddUsers,
    handleEditOwner,
    handleRemoveGroup,
    handleRemoveUser,
    handleSetOpenAccess,
  };
}

type ArrayAction =
  | { type: "add"; value: number }
  | { type: "remove"; value: number };

export function useEditedArray(array: number[]) {
  const [actions, setActions] = useState<ArrayAction[]>([]);

  const addValues = useCallback((values: number[]) => {
    setActions((actions) => [
      ...actions,
      ...values.map<ArrayAction>((value) => ({ type: "add", value })),
    ]);
  }, []);

  const removeValue = useCallback((value: number) => {
    setActions((actions) => [...actions, { type: "remove", value: value }]);
  }, []);

  const editedArray = actions.reduce(applyArrayAction, array);

  return { editedArray, addValues, removeValue };
}

export function useEditedValue<T>(value: T) {
  const [modifiedValue, setModifiedValue] = useState<T | undefined>();

  const setValue = useCallback((value: T) => setModifiedValue(value), []);
  const editedValue = modifiedValue ?? value;

  return { setValue, editedValue, isEdited: editedValue !== undefined };
}

function applyArrayAction(array: number[], action: ArrayAction): number[] {
  if (action.type === "add" && !array.includes(action.value)) {
    return [...array, action.value];
  }

  if (action.type === "remove" && array.includes(action.value)) {
    return array.filter((value) => value !== action.value);
  }

  return array;
}
