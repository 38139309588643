import { useMutation } from "react-query";
import useApi from "../../../../app/useApi";
import React from "react";
import { useDownloadQueue, useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { ProgressBar } from "@blueprintjs/core";

export default function useExportWorkflows() {
  const api = useApi();
  const tr = useTranslations();
  const toaster = useToaster();
  const { handleFile } = useDownloadQueue();

  return useMutation(
    async () => {
      toaster.show(
        {
          intent: "primary",
          icon: "info-sign",
          timeout: 0,
          message: (
            <>
              <div>
                {tr.translate("toaster.object.export.in-progress", {
                  type: "workflow",
                })}
              </div>
              <ProgressBar intent="primary" animate />
            </>
          ),
        },
        "workflow-export"
      );
      return api.task.exportWorkflows();
    },
    {
      onSuccess: (workflowExport) => {
        // download workflow export as a CSV file
        handleFile(
          new File([workflowExport], "workflows.csv", {
            type: "text/csv",
          })
        );
        toaster.show(
          {
            intent: "success",
            icon: "tick",
            message: tr.translate("toaster.object.export.success", {
              type: "workflow",
            }),
          },
          "workflow-export"
        );
      },
      onError: () => {
        toaster.show(
          {
            intent: "danger",
            icon: "warning-sign",
            message: tr.translate("toaster.object.export.error", {
              type: "workflow",
            }),
          },
          "workflow-export"
        );
      },
    }
  );
}
