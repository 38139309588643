import React from "react";
import { CustomIcon, CustomIconProps } from "./CustomIcon";

const paths = [
  "M0 8.5C0 6.57 1.57 5 3.5 5C5.43 5 7 6.57 7 8.5C7 10.43 5.43 12 3.5 12C1.57 12 0 10.43 0 8.5ZM9 8.5C9 6.57 10.57 5 12.5 5C14.43 5 16 6.57 16 8.5C16 10.43 14.43 12 12.5 12C10.57 12 9 10.43 9 8.5ZM12.5 11C13.8807 11 15 9.88071 15 8.5C15 7.11929 13.8807 6 12.5 6C11.1193 6 10 7.11929 10 8.5C10 9.88071 11.1193 11 12.5 11Z",
];

export default function ConfigOptionIcon(props: CustomIconProps) {
  return <CustomIcon paths={paths} iconName="config-option" {...props} />;
}
