import { PartWithIteration, ProjectDefinitionDocument } from "@aletiq/types";
import { useMemo } from "react";
import {
  ProductComponentNode,
  ProductComponentTree,
} from "../../ProjectNomenclature/hooks";

export function checkPartIteration(part: Partial<PartWithIteration>) {
  const { iteration } = part;
  const hasPart = iteration !== undefined;
  const isPartIterationApproved = iteration?.state === "approved";
  const hasValidPart = isPartIterationApproved || !hasPart;

  return { hasValidPart };
}

export function checkProductDefinitionDocuments(
  documents: ProjectDefinitionDocument[]
) {
  const draftDocuments = documents.filter((d) => d.revision?.state === "draft");
  const obsoleteDocuments = documents.filter(
    (d) => d.revision?.state === "obsolete"
  );
  const hasDraftDocuments = draftDocuments.length > 0;
  const hasObsoleteDocuments = obsoleteDocuments.length > 0;
  const hasDraftOrObsoleteDocuments = hasDraftDocuments || hasObsoleteDocuments;

  return {
    hasDraftDocuments,
    hasObsoleteDocuments,
    hasDraftOrObsoleteDocuments,
    draftDocuments,
    obsoleteDocuments,
  };
}

export function checkProductComponentNode(component: ProductComponentNode) {
  const { documents } = component;
  const { hasValidPart } = checkPartIteration(component);
  const checkDocuments = checkProductDefinitionDocuments(documents);
  const { hasDraftOrObsoleteDocuments } = checkDocuments;
  const canApprove = hasValidPart && !hasDraftOrObsoleteDocuments;

  return {
    hasValidPart,
    ...checkDocuments,
    canApprove,
  };
}

function collectComponentsToApprove(
  tree: ProductComponentTree
): ProductComponentNode[] {
  const isApproved = tree.definition.state === "approved";

  if (isApproved) {
    return [];
  }
  return [tree, ...tree.subRows.flatMap((t) => collectComponentsToApprove(t))];
}

export default function useComponentsToApprove(tree?: ProductComponentTree): {
  components: ProductComponentNode[];
  canApprove: boolean;
} {
  return useMemo(() => {
    if (!tree) {
      return {
        components: [],
        canApprove: false,
      };
    }
    const components = collectComponentsToApprove(tree);
    const canApprove = components.reduce((acc: boolean, component) => {
      return acc && checkProductComponentNode(component).canApprove;
    }, true);
    return {
      components,
      canApprove,
    };
  }, [tree]);
}
