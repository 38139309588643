// dialog state for document details panel
export type DialogState = {
  type:
    | "unlink-document"
    | "delete-document"
    | "upload-revision"
    | "share-document"
    | "none";
};

export const showDeleteDocument = (): DialogState => ({
  type: "delete-document",
});

export const showUnlinkDocument = (): DialogState => ({
  type: "unlink-document",
});

export const showUploadDocRevision = (): DialogState => ({
  type: "upload-revision",
});

export const showShareDocument = (): DialogState => ({
  type: "share-document",
});
