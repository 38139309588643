import React from "react";
import { CustomIcon, CustomIconProps } from "./CustomIcon";

const paths = [
  "M15.7387 10.2019L16.0001 9.14114L15.0696 8.72917L15.002 8.28582L15.768 7.61503L15.2032 6.68088L14.2549 7.04791L13.8934 6.78221L13.9611 5.76553L12.9013 5.50361L12.4895 6.43588L12.0461 6.50361L11.376 5.73833L10.4419 6.30308L10.8082 7.2498L10.5425 7.61125L9.52872 7.54342L9.26709 8.60422L10.1979 9.01634L10.2654 9.45959L9.49933 10.1302L10.064 11.0646L11.0124 10.6976L11.3739 10.9633L11.3062 11.9797L12.366 12.2417L12.7778 11.3096L13.2213 11.2418L13.8912 12.007L14.8256 11.4422L14.4592 10.4957L14.725 10.1343L15.7387 10.2019ZM13.3532 10.0631C12.6958 10.4598 11.8408 10.2491 11.4431 9.59225C11.0464 8.93489 11.2574 8.07991 11.9141 7.68228C12.5715 7.28553 13.4265 7.49644 13.8241 8.15307C14.2207 8.81043 14.01 9.66551 13.3532 10.0631Z",
  "M8.46739 8.81273L7.87346 7.41088L8.25479 6.85646L9.77784 6.90984L10.1198 5.30722L8.70521 4.73365L8.58293 4.07207L9.69969 3.03023L8.80897 1.65627L7.40451 2.25137L6.84999 1.86999L6.90327 0.342665L5.30221 2.19345e-05L4.72875 1.41719L4.06699 1.53966L3.02644 0.424071L1.65239 1.31479L2.2465 2.71668L1.86512 3.27125L0.342064 3.21762L0 4.82034L1.41475 5.39396L1.53695 6.05554L0.420132 7.09733L1.31091 8.47125L2.71536 7.87624L3.26989 8.25757L3.21657 9.7848L4.81773 10.1274L5.39113 8.71033L6.0528 8.5879L7.09343 9.70344L8.46739 8.81273ZM6.19491 6.81462C5.22822 7.44036 3.93615 7.16468 3.30903 6.19876C2.68322 5.23202 2.95913 3.94 3.92491 3.31299C4.89173 2.68715 6.18371 2.96293 6.81076 3.92875C7.43656 4.89559 7.16092 6.18747 6.19491 6.81462Z",
  "M10.1508 12.7999L10.6587 12.321L10.2482 11.6951L9.60757 11.9706L9.35307 11.7975L9.37363 11.0992L8.64093 10.9465L8.38221 11.5957L8.08001 11.6534L7.60161 11.1459L6.97562 11.5565L7.25063 12.1959L7.07758 12.4504L6.3812 12.4295L6.22876 13.1631L6.87686 13.422L6.93441 13.7241L6.42638 14.2032L6.837 14.8291L7.47754 14.5535L7.73208 14.7265L7.71143 15.4247L8.44437 15.5776L8.70299 14.9283L9.00515 14.8707L9.48355 15.3782L10.1096 14.9677L9.83453 14.3282L10.0076 14.0738L10.7042 14.0943L10.8564 13.361L10.2082 13.1021L10.1508 12.7999ZM9.06575 14.0597C8.62532 14.3482 8.03404 14.2253 7.74498 13.7852C7.45649 13.3447 7.5794 12.7534 8.01935 12.4644C8.45984 12.1759 9.05126 12.2988 9.34032 12.7388C9.62881 13.1794 9.5059 13.7706 9.06575 14.0597Z",
];

export default function Gears(props: CustomIconProps) {
  return <CustomIcon paths={paths} iconName="gears" {...props} />;
}
