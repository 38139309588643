import React, { useState } from "react";
import { Callout, Dialog, Field, FileInput } from "../../../components";
import { ALLOWED_PART_FILE_TYPES, useTranslations } from "../../../util";
import { usePart, useUploadCadFile } from "../hooks";

export default function UploadStandardFileDialog(props: {
  partId: number;
  onClose: () => void;
}) {
  const { partId, onClose } = props;
  const tr = useTranslations();

  const { data: part } = usePart(partId);
  const type = part?.type ?? "part";

  const fileExtensions = ALLOWED_PART_FILE_TYPES[type];

  const [file, setFile] = useState<File>();

  const handleFileInput = (ev: React.FormEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files;

    setFile(files?.[0]);
  };

  const { mutate: uploadCadFile, isLoading, error } = useUploadCadFile();

  const handleSubmit = async () => {
    if (file) {
      uploadCadFile(
        {
          partId,
          revision: { name: "", description: "" },
          file,
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    }
  };

  return (
    <Dialog
      isOpen
      icon="refresh"
      onClose={onClose}
      onSecondaryClick={onClose}
      disablePrimary={file === undefined}
      onPrimaryClick={() => handleSubmit()}
      submitting={isLoading}
      title={tr.translateAsString("part.dialog.upload-standard-file.title")}
    >
      <>
        <p>{tr.translate("part.dialog.upload-standard-file.info")}</p>
        <Field
          label={tr.translateAsString("part.dialog.upload-design.file.label", {
            extensions: fileExtensions.join(", "),
          })}
        >
          <FileInput
            fill
            files={file ? [file] : undefined}
            onInputChange={handleFileInput}
          />
        </Field>

        {error && <Callout intent="danger">{(error as Error).message}</Callout>}
      </>
    </Dialog>
  );
}
