import {
  ContentBlock,
  ContentState,
  convertToRaw,
  DraftBlockType,
} from "draft-js";
import { draftJsObject } from "./DraftJsObject";
import getEntityRanges from "./getEntityRanges";

const BOLD = draftJsObject.INLINE_STYLE.BOLD;
const CODE = draftJsObject.INLINE_STYLE.CODE;
const ITALIC = draftJsObject.INLINE_STYLE.ITALIC;
const STRIKETHROUGH = draftJsObject.INLINE_STYLE.STRIKETHROUGH;
const UNDERLINE = draftJsObject.INLINE_STYLE.UNDERLINE;
const CODE_INDENT = "    ";

const defaultOptions = {
  gfm: false,
};

export type Options = { gfm: boolean };

export class MarkupGenerator {
  constructor(content: ContentState, options?: Options) {
    const blocks = content.getBlockMap().toArray() as unknown as ContentBlock[];
    this.contentState = content;
    this.blocks = blocks;
    this.currentBlock = 0;
    this.output = "";
    this.totalBlocks = blocks.length;
    this.listItemCounts = {};
    this.options = options || defaultOptions;
  }

  contentState: ContentState;
  blocks: ContentBlock[];
  output: string;
  currentBlock: number;
  totalBlocks: number;
  listItemCounts: Record<number, number>;
  options: Options;

  generate = () => {
    while (this.currentBlock < this.totalBlocks) {
      this.processBlock();
    }
    return this.output;
  };

  processBlock = () => {
    const block = this.blocks[this.currentBlock];
    const blockType = block.getType();

    switch (blockType) {
      case draftJsObject.BLOCK_TYPE.HEADER_ONE: {
        this.insertLineBreaks(1);
        this.output += "# " + this.renderBlockContent(block) + "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.HEADER_TWO: {
        this.insertLineBreaks(1);
        this.output += "## " + this.renderBlockContent(block) + "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.HEADER_THREE: {
        this.insertLineBreaks(1);
        this.output += "### " + this.renderBlockContent(block) + "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.HEADER_FOUR: {
        this.insertLineBreaks(1);
        this.output += "#### " + this.renderBlockContent(block) + "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.HEADER_FIVE: {
        this.insertLineBreaks(1);
        this.output += "##### " + this.renderBlockContent(block) + "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.HEADER_SIX: {
        this.insertLineBreaks(1);
        this.output += "###### " + this.renderBlockContent(block) + "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.UNORDERED_LIST_ITEM: {
        const blockDepth = block.getDepth();
        const lastBlock = this.getLastBlock();
        const lastBlockType = lastBlock ? lastBlock.getType() : null;
        const lastBlockDepth =
          lastBlock && canHaveDepth(lastBlockType)
            ? lastBlock.getDepth()
            : null;

        if (lastBlockType !== blockType && lastBlockDepth !== blockDepth - 1) {
          this.insertLineBreaks(1); // Insert an additional line break if following opposite list type.

          if (lastBlockType === draftJsObject.BLOCK_TYPE.ORDERED_LIST_ITEM) {
            this.insertLineBreaks(1);
          }
        }

        const indent = " ".repeat(blockDepth * 4);
        this.output += indent + "- " + this.renderBlockContent(block) + "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.ORDERED_LIST_ITEM: {
        const _blockDepth = block.getDepth();

        const _lastBlock = this.getLastBlock();

        const _lastBlockType = _lastBlock ? _lastBlock.getType() : null;

        const _lastBlockDepth =
          _lastBlock && canHaveDepth(_lastBlockType)
            ? _lastBlock.getDepth()
            : null;

        if (
          _lastBlockType !== blockType &&
          _lastBlockDepth !== _blockDepth - 1
        ) {
          this.insertLineBreaks(1); // Insert an additional line break if following opposite list type.

          if (_lastBlockType === draftJsObject.BLOCK_TYPE.UNORDERED_LIST_ITEM) {
            this.insertLineBreaks(1);
          }
        }

        const _indent = " ".repeat(_blockDepth * 4); // TODO: figure out what to do with two-digit numbers

        const count = this.getListItemCount(block) % 10;
        this.output +=
          _indent +
          "".concat(`${count}. `) +
          this.renderBlockContent(block) +
          "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.BLOCKQUOTE: {
        this.insertLineBreaks(1);
        this.output += " > " + this.renderBlockContent(block) + "\n";
        break;
      }

      case draftJsObject.BLOCK_TYPE.CODE: {
        this.insertLineBreaks(1);

        if (this.options.gfm) {
          const language =
            block.getData() && block.getData().get("language")
              ? block.getData().get("language")
              : "";
          this.output += "```".concat(language, "\n");
          this.output += this.renderBlockContent(block) + "\n";
          this.output += "```\n";
        } else {
          this.output += CODE_INDENT + this.renderBlockContent(block) + "\n";
        }
        break;
      }

      default: {
        this.insertLineBreaks(1);
        this.output += this.renderBlockContent(block) + "\n";
        break;
      }
    }

    this.currentBlock += 1;
  };

  getLastBlock = (): ContentBlock => {
    return this.blocks[this.currentBlock - 1];
  };

  getNextBlock = (): ContentBlock => {
    return this.blocks[this.currentBlock + 1];
  };

  getListItemCount = (block: ContentBlock): number => {
    const blockType = block.getType();
    const blockDepth = block.getDepth(); // To decide if we need to start over we need to backtrack (skipping list
    // items that are of greater depth)

    let index = this.currentBlock - 1;
    let prevBlock = this.blocks[index];

    while (
      prevBlock &&
      canHaveDepth(prevBlock.getType()) &&
      prevBlock.getDepth() > blockDepth
    ) {
      index -= 1;
      prevBlock = this.blocks[index];
    }

    if (
      !prevBlock ||
      prevBlock.getType() !== blockType ||
      prevBlock.getDepth() !== blockDepth
    ) {
      this.listItemCounts[blockDepth] = 0;
    }

    return (this.listItemCounts[blockDepth] =
      this.listItemCounts[blockDepth] + 1);
  };

  insertLineBreaks = (nbLineBreak: number) => {
    if (this.currentBlock > 0) {
      for (let i = 0; i < nbLineBreak; i++) {
        this.output += "\n";
      }
    }
  };

  renderBlockContent(block: ContentBlock): string {
    const blockType = block.getType();
    const text = block.getText();

    if (text === "") {
      // Prevent element collapse if completely empty.
      // LIBTODO: Replace with constant.
      return "\u200B";
    }

    const charMetaList = block.getCharacterList();
    const entityPieces = getEntityRanges(text, charMetaList);
    return entityPieces
      .map((entityToMap) => {
        const entityKey = entityToMap[0];
        const stylePieces = entityToMap[1];

        let content = stylePieces
          .map((stylePiece) => {
            const text = stylePiece[0];
            const style = stylePiece[1];
            // Don't allow empty inline elements.
            if (!text) {
              return "";
            }

            let content = text; // Don't encode any text inside a code block.

            if (blockType === draftJsObject.BLOCK_TYPE.CODE) {
              return content;
            } // NOTE: We attempt some basic character escaping here, although
            // I don't know if escape sequences are really valid in markdown,
            // there's not a canonical spec to lean on.

            if (style.has(CODE)) {
              return "`" + encodeCode(content) + "`";
            }

            content = encodeContent(text);

            if (style.has(BOLD)) {
              content = "**".concat(content, "**");
            }

            if (style.has(UNDERLINE)) {
              // LIBTODO: encode `+`?
              content = "++".concat(content, "++");
            }

            if (style.has(ITALIC)) {
              content = "*".concat(content, "*");
            }

            if (style.has(STRIKETHROUGH)) {
              // LIBTODO: encode `~`?
              content = "~~".concat(content, "~~");
            }

            return content;
          })
          .join("");
        const entity = entityKey
          ? this.contentState.getEntity(entityKey)
          : null;

        if (entity != null) {
          const data = entity.getData();
          switch (entity.getType()) {
            case draftJsObject.ENTITY_TYPE.LINK: {
              const url = data.href || data.url || "";
              const title = data.title
                ? ' "'.concat(escapeTitle(data.title), '"')
                : "";
              return "["
                .concat(content, "](")
                .concat(encodeURL(url))
                .concat(title, ")");
            }
            case draftJsObject.ENTITY_TYPE.IMAGE: {
              const src = data.src || "";
              const alt = data.alt ? "".concat(escapeTitle(data.alt)) : "";
              return "![".concat(alt, "](").concat(encodeURL(src), ")");
            }
            case draftJsObject.ENTITY_TYPE.EMBED:
              return entity.getData().url || content;
          }
        }

        const entityRanges = convertToRaw(this.contentState).blocks[
          this.currentBlock
        ].entityRanges;
        const entityMap = convertToRaw(this.contentState).entityMap;
        entityRanges.forEach((entityRange) => {
          const toReplace = new RegExp(
            text.slice(
              entityRange.offset,
              entityRange.offset + entityRange.length
            )
          );
          const entityDetails = entityMap[entityRange.key];
          content = content.replace(
            toReplace,
            `[${entityDetails.data.mention.id}](aletiq-user)`
          );
        });
        return content;
      })
      .join("");
  }
}

const canHaveDepth = (blockType: DraftBlockType | null): boolean => {
  switch (blockType) {
    case draftJsObject.BLOCK_TYPE.UNORDERED_LIST_ITEM:
    case draftJsObject.BLOCK_TYPE.ORDERED_LIST_ITEM:
      return true;

    default:
      return false;
  }
};

const encodeContent = (text: string): string => {
  return text.replace(/[*_`]/g, "\\$&");
};

const encodeCode = (text: string): string => {
  return text.replace(/`/g, "\\`");
}; // Encode chars that would normally be allowed in a URL but would conflict with
// our markdown syntax: `[foo](http://foo/)`

const LINK_CHARACTER_REPLACEMENTS: Record<string, string> = {
  "(": "%28",
  ")": "%29",
};

const encodeURL = (url: string): string => {
  return url.replace(/[()]/g, (_char) => {
    return LINK_CHARACTER_REPLACEMENTS[_char];
  });
}; // Escape quotes using backslash.

const escapeTitle = (text: string): string => {
  return text.replace(/"/g, '\\"');
};
