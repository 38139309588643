import React from "react";
import Panel from "../panel/Panel";
import PanelDetailsHeader from "./PanelDetailsHeader";

export default function PanelDetails(props: {
  id: number;
  title: React.ReactNode;
  additionalButtons: React.ReactNode;
  navigateTooltip: string;
  canNavigateToList: boolean;
  navigateToList: () => void;
  children: React.ReactNode;
}) {
  const {
    id,
    title,
    additionalButtons,
    navigateTooltip,
    canNavigateToList,
    navigateToList,
    children,
  } = props;

  return (
    <Panel key={id}>
      <PanelDetailsHeader
        title={title}
        additionalButtons={additionalButtons}
        navigateTooltip={navigateTooltip}
        canNavigateToList={canNavigateToList}
        navigateToList={navigateToList}
      />
      {children}
    </Panel>
  );
}
