import React, { useMemo } from "react";
import { Dialog } from "../../../components";
import { useTranslations } from "../../../util";
import styles from "./ExportDialog.module.scss";
import DateRange from "./DateRange";

export default function ExportDialog(props: {
  type: "activate-exports" | "info";
  onClose: () => void;
  onSubmit: () => void;
  previousExport?: string;
}) {
  const { onClose, onSubmit, previousExport, type } = props;
  const tr = useTranslations();

  const isActivatingExports = type === "activate-exports";
  const now = useMemo(() => new Date(), []);
  const previous = useMemo(
    () => (previousExport ? new Date(previousExport) : undefined),
    [previousExport]
  );

  const nextOccurence = useMemo(() => {
    if (previous === undefined) return undefined;
    const next = new Date(previous);
    next.setUTCMonth(previous.getUTCMonth() + 1);
    // Overflow
    if (next.getUTCDate() !== previous.getUTCDate()) {
      next.setUTCDate(0); // This will set the date to the last day of the previous month
    }
    return next;
  }, [previous]);

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Dialog
      isOpen
      icon={isActivatingExports ? "info-sign" : "help"}
      title={tr.translateAsString(
        isActivatingExports
          ? "admin.exports.dialog.enable"
          : "admin.exports.dialog.info"
      )}
      onPrimaryClick={isActivatingExports ? handleSubmit : onClose}
      primaryText={tr.translateAsString(
        isActivatingExports
          ? "generic.action.submit"
          : "admin.exports.dialog.understood"
      )}
      onClose={onClose}
    >
      {isActivatingExports && (
        <span className={styles.subtitle}>
          {tr.translateAsString("admin.exports.dialog.info-header")}
        </span>
      )}
      <ul className={styles.list}>
        <li>{tr.translateAsString("admin.exports.dialog.info-1")}</li>
        <li>{tr.translateAsString("admin.exports.dialog.info-2")}</li>
        <li>{tr.translateAsString("admin.exports.dialog.info-3")}</li>
      </ul>

      {isActivatingExports && (
        <>
          {!previousExport &&
            tr.translate("admin.exports.dialog.enable.next-export.first")}
          {previous && nextOccurence && nextOccurence > now && (
            <>
              <div className={styles.next_occurence}>
                {tr.translate(
                  "admin.exports.dialog.enable.next-export.resume",
                  {
                    date: nextOccurence,
                  }
                )}
              </div>
              <DateRange
                from={previousExport}
                to={nextOccurence.toDateString()}
              />
            </>
          )}
          {previous && nextOccurence && nextOccurence < now && (
            <>
              <div className={styles.next_occurence}>
                {tr.translate(
                  "admin.exports.dialog.enable.next-export.immediate"
                )}
              </div>
              <DateRange from={previousExport} to={now.toDateString()} />
            </>
          )}
        </>
      )}
    </Dialog>
  );
}
