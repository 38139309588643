export function indexBy<
  T extends { [key in K]: V },
  K extends keyof any,
  V extends keyof any
>(collection: T[], key: K): Record<V, T>;
export function indexBy<T, V extends keyof any>(
  collection: T[],
  key: (value: T, index: number, array: T[]) => V
): Record<V, T> {
  const groupByObject = {} as Record<V, T>;
  const keyfn = typeof key === "function" ? key : (v: T) => v[key];

  collection.forEach((value: T, index: number, array: T[]) => {
    groupByObject[keyfn(value, index, array)] = value;
  });

  return groupByObject;
}
