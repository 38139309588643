import React from "react";
import { ProjectComponentsActionBar, ProjectComponentsTable } from ".";
import { ProjectPartCard } from "../ProjectPart";
import { ProjectUsedInTable, useProjectUsedInState } from "../ProjectUsedIn";
import useProjectNomenclatureState from "../useProjectNomenclatureState";
import { ProjectComponentsSelection } from "./ProjectComponentSelect";
import useProjectComponentsState from "./useProjectComponentsState";

export default function ProjectComponentsPanel(props: {
  projectId: number;
  definitionIdx: number;
  search?: string;
  selected?: ProjectComponentsSelection;
  onSearchChange?: (search: string) => void;
  onSelectChange?: (select: ProjectComponentsSelection) => void;
}) {
  const {
    selected,
    projectId,
    definitionIdx,
    search,
    onSearchChange,
    onSelectChange,
  } = props;

  const projectNomenclatureState = useProjectNomenclatureState(
    projectId,
    definitionIdx
  );

  const componentsPanelState = useProjectComponentsState(
    projectId,
    definitionIdx,
    search ?? ""
  );

  const { part, iteration, partsDiff } = projectNomenclatureState;

  const { components, isLoading, columnConfig } = componentsPanelState;

  const { isAddComponentsDisabled } = projectNomenclatureState;

  const usedInPanelState = useProjectUsedInState(projectId, search ?? "");

  return (
    <>
      {part && iteration && (
        <ProjectPartCard
          projectId={projectId}
          definitionIdx={definitionIdx}
          {...projectNomenclatureState}
          part={part}
          diff={partsDiff}
          iteration={iteration}
        />
      )}

      <ProjectComponentsActionBar
        selected={selected}
        search={search}
        onSearchChange={onSearchChange}
        onSelectChange={onSelectChange}
        projectId={projectId}
        definitionIdx={definitionIdx}
        {...projectNomenclatureState}
        {...componentsPanelState}
      />

      {selected === "uses" && (
        <ProjectComponentsTable
          projectId={projectId}
          definitionIdx={definitionIdx}
          components={components}
          search={search}
          isLoading={isLoading}
          canAddComponent={!isAddComponentsDisabled}
          canEditComponent={!isAddComponentsDisabled}
          canDeleteComponent={!isAddComponentsDisabled}
          columnConfig={columnConfig}
        />
      )}

      {selected === "used-in" && (
        <ProjectUsedInTable
          projectId={projectId}
          search={search}
          {...usedInPanelState}
        />
      )}
    </>
  );
}
