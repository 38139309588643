import { Project } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../util";
import productLinkProps from "../../link/props/productLinkProps";
import ActivityLink from "./ActivityLink";

export function ActivityProductLink(props: {
  product: Project;
  onClick?: () => void;
}) {
  const { product, onClick } = props;
  const tr = useTranslations();

  const linkProps = productLinkProps(tr, product, false);

  return <ActivityLink {...linkProps} onClick={onClick} />;
}
