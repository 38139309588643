import { PassportFileSearchResult } from "@aletiq/types";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { Button, ExtendedColumn, Table } from "../../components";
import PassportLink from "../../components/link/PassportLink";
import { useSortKey } from "../../hooks";
import { sortByString, useTranslations } from "../../util";
import { usePreviewPassportFile } from "../passport/hooks";
import { useAccessSearchResult } from "./hooks";

export default function PassportFilesSearchList(props: {
  files: PassportFileSearchResult[];
  search: string;
}) {
  const { files, search } = props;
  const tr = useTranslations();

  const { sortState, setSortKey: onSort } = useSortKey<
    "file" | "type" | "passport"
  >("file");

  const { mutate: accessSearchResult } = useAccessSearchResult();

  const sortedFiles = useMemo(
    () =>
      sortByString(
        files,
        (pf) => {
          switch (sortState.key) {
            case "file":
              return pf.file.name;
            case "type":
              return pf.type?.value ?? "";
            case "passport":
              return pf.passport.name;
          }
        },
        sortState.direction
      ),
    [sortState, files]
  );

  const columns: ExtendedColumn<PassportFileSearchResult>[] = useMemo(
    () => [
      {
        Header: tr.translate("generic.label.name"),
        id: "file",
        accessor: (pf) => pf.file.name,
        hoverButton: (pf: PassportFileSearchResult, className?: string) => (
          <ShowPreviewButton passportFile={pf} className={className} />
        ),
      },
      {
        Header: tr.translate("search.tab.passport-files.type"),
        id: "type",
        accessor: (pf) => pf.type?.value,
      },
      {
        Header: tr.translate("search.tab.passport-files.passport"),
        id: "passport",
        accessor: "passport",
        Cell: (cell: CellProps<PassportFileSearchResult>) => (
          <PassportLink
            passport={cell.row.original.passport}
            onClick={() =>
              accessSearchResult({
                type: "passport",
                id: cell.row.original.passport.id,
              })
            }
            hasIcon
          />
        ),
      },
    ],
    [tr, accessSearchResult]
  );

  return (
    <Table
      columns={columns}
      data={sortedFiles}
      searchString={search}
      sortOptions={{
        sortState,
        onSort,
      }}
    />
  );
}

function ShowPreviewButton(props: {
  passportFile: PassportFileSearchResult;
  className?: string;
}) {
  const { passportFile, className } = props;
  const tr = useTranslations();

  const showPreview = usePreviewPassportFile(passportFile.passport.id);

  const handleOpenPreview = () => showPreview(passportFile.file);

  return (
    <Button
      isDense
      intent="array_primary"
      onClick={handleOpenPreview}
      className={className}
    >
      {tr.translate("documents.view.action.preview")}
    </Button>
  );
}
