import { Field } from "@aletiq/design-system";
import { Process } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React from "react";
import { EditableOwner } from "../../../components";
import { useUsers } from "../../../hooks";
import { useTranslations } from "../../../util";
import WorkflowModelName from "../common/WorkflowModelName";
import {
  useHasUpdateRightOnWorkflow,
  useUpdateProcess,
  useWorkflowStatus,
} from "../hooks";
import styles from "./WorkflowDetails.module.scss";

export default function WorkflowProperties(props: { workflow: Process }) {
  const { workflow } = props;
  const tr = useTranslations();

  const { data: users = [] } = useUsers();
  const workflowStatus = useWorkflowStatus(workflow);
  const canUpdate = useHasUpdateRightOnWorkflow(workflow.id);

  const { mutate: saveProcess, isLoading: isUpdating } = useUpdateProcess();

  const managers = users.filter((user) =>
    user.permissions.includes("update:own-workflows")
  );

  const handleAssignOwner = (newOwner: number) => {
    if (workflow)
      saveProcess({
        workflow: { owner: newOwner },
        workflowId: workflow.id,
      });
  };

  return (
    <>
      <Field
        inline
        label={tr.translate("workflow.details.model")}
        className={styles.property}
      >
        <div className={styles.model}>
          {workflow?.model ? (
            <Text ellipsize>
              <WorkflowModelName modelId={workflow?.model} />
            </Text>
          ) : (
            tr.translate("generic.label.none")
          )}
        </div>
      </Field>
      <EditableOwner
        initialOwner={workflow.owner}
        allowedOwners={managers}
        isDisabled={!canUpdate}
        onSubmit={handleAssignOwner}
        isSubmitting={isUpdating}
        inline
      />
      <Field
        inline
        label={tr.translate("tasklist.status")}
        className={styles.property}
      >
        <div className={styles.status_wrapper}>
          {tr.translate("workflow.details.status", {
            status: workflowStatus,
          })}
        </div>
      </Field>
    </>
  );
}
