import React, { createContext, ReactNode, useContext, useState } from "react";
import { VideoFileOverlay } from "../preview";

export const ShowVideoFileContext = createContext<ShowVideoFile>(() => {});

type ShowVideoFile = (token: string) => void;

export function useShowVideo() {
  return useContext(ShowVideoFileContext);
}

export function ShowVideoFileProvider(props: { children?: ReactNode }) {
  const { children } = props;
  const [content, setContent] = useState<string | null>(null);

  return (
    <ShowVideoFileContext.Provider value={setContent}>
      {children}
      {content !== null ? (
        <VideoFileOverlay content={content} onClose={() => setContent(null)} />
      ) : null}
    </ShowVideoFileContext.Provider>
  );
}
