import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { pdmKeys } from "./queries";

export default function useUpdatePartsProjects() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (parts: { partId: number; projects: number[] }[]) => {
      for (let i = 0; i < parts.length; i++) {
        await api.pdm.updatePartProjects(parts[i].partId, parts[i].projects);
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.parts.projects.update"),
        });
      },
    }
  );
}
