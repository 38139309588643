import { Icon, Tooltip } from "@aletiq/design-system";
import { EntityId } from "@aletiq/types";
import React from "react";
import { useDocument } from "../../../documents/hooks";
import styles from "./WorkflowCardHeader.module.scss";

export default function WorkflowDocumentCount(props: {
  workflowDocs: EntityId[];
}) {
  const { workflowDocs } = props;
  const workflowCount = workflowDocs.length;

  return (
    <Tooltip
      isDisabled={workflowCount === 0}
      content={
        <>
          {workflowDocs.map((entity) => (
            <div key={entity.id}>
              <DocumentName documentId={entity.id} />
            </div>
          ))}
        </>
      }
    >
      <span
        className={workflowCount > 0 ? styles.counter : styles.empty_counter}
      >
        <Icon icon="document" inline />
        {workflowCount}
      </span>
    </Tooltip>
  );
}

function DocumentName(props: { documentId: number }) {
  const { data: document } = useDocument(props.documentId, {
    refetchOnMount: false,
  });
  return <>{document?.name}</>;
}
