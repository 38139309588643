import { Document } from "@aletiq/types";
import React, { useEffect, useState } from "react";
import useApi from "../../../../app/useApi";
import useDocumentPreviewToken from "../../hooks/useDocumentPreviewToken";

const EmbeddedPdfPreview = React.memo(
  (props: { document: Document; className?: string }) => {
    const { document, className } = props;

    const api = useApi();
    const getPdfPreviewToken = useDocumentPreviewToken();
    const [previewContents, setPreviewContents] = useState<Blob>();

    useEffect(() => {
      const lastRevision = document.revisions[0];
      const fileName = lastRevision.file.name;

      getPdfPreviewToken({
        documentId: document.id,
        fileName,
      }).then(async (token) => {
        const blob = await api.document.getDocumentDownloadBlob(token);
        setPreviewContents(blob);
      });
    }, [document, getPdfPreviewToken, api.document]);

    if (previewContents) {
      return (
        <object
          width="100%"
          height="100%"
          title={document.name}
          data={URL.createObjectURL(previewContents)}
          className={className}
        >
          {document.name}
        </object>
      );
    } else {
      return null;
    }
  }
);

export default EmbeddedPdfPreview;
