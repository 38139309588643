import { ItemSelect, Select } from "@aletiq/design-system";
import React, { Key, useState } from "react";
import { EditablePropertyButtonGroup } from "./buttons";
import EditablePropertyTemplate from "./EditablePropertyTemplate";

function EditablePropertySingleSelect<T extends Key>(props: {
  label: string;
  isEditable: boolean;
  intent?: "primary" | "default";
  value: T;
  items: ItemSelect<T>[];
  placeholder: string;
  emptyState: string;
  inline?: boolean;
  tooltipText?: string;
  search?: string;
  onSubmit: (value: T) => void;
  minWidth?: number;
}) {
  const {
    label,
    isEditable,
    intent = "default",
    value: initialValue,
    items,
    placeholder,
    inline,
    emptyState,
    onSubmit,
    tooltipText,
    search,
    minWidth,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState(initialValue);

  const handleToggleEditMode = () => {
    setEditedValue(initialValue);
    setEditMode(!editMode);
  };

  const handleSubmit = () => {
    onSubmit(editedValue);
    setEditMode(false);
  };

  const valueItem = items?.find((val) => val.key === initialValue);
  const editedItem = items?.find((val) => val.key === editedValue);

  return (
    <EditablePropertyTemplate
      intent={intent}
      isEditable={isEditable}
      label={label}
      triggerEditMode={handleToggleEditMode}
      isInEditMode={editMode}
      inline={inline}
      tooltipText={tooltipText}
      search={search}
      minWidth={minWidth}
    >
      {!editMode && <div>{valueItem?.text || emptyState}</div>}
      {editMode && (
        <>
          <Select
            isFill
            popoverProps={{
              fill: true,
              targetProps: { onClick: (ev) => ev.stopPropagation() },
              minimal: true,
              position: "bottom-right",
            }}
            filterable
            activeItem={editedValue}
            items={items}
            onItemSelect={setEditedValue}
            intent={intent === "primary" ? "outlined" : "default"}
            isDense
          >
            {editedItem?.text || placeholder}
          </Select>
          <EditablePropertyButtonGroup
            onSubmit={handleSubmit}
            onReset={handleToggleEditMode}
          />
        </>
      )}
    </EditablePropertyTemplate>
  );
}

export default EditablePropertySingleSelect;
