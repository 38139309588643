import React from "react";
import { useNavigate, usePartId } from "../../../hooks";
import AllPartHeaderBatchActions from "../AllParts/AllPartsListPaginated/AllPartHeaderBatchAction";
import { FullTree } from "../AllParts/AllPartsType";

export default function usePartRowOptions(projectId?: number) {
  const navigate = useNavigate();
  const activePartId = usePartId();

  const isRowUnselectable = (partTree: FullTree) =>
    //row is loading / incomplete / empty
    partTree.type !== "part"
      ? true
      : //part is not shared with user
      partTree.part && !partTree.part.hasAccess
      ? true
      : false;

  const isRowActive = (partTree: FullTree) =>
    partTree.type === "part" && partTree.part?.id === activePartId;

  const handleOpenPartPanel = (partTree: FullTree) => {
    if (partTree.type !== "part") {
      return;
    }
    navigate({
      panel: `partId-${partTree.part?.id}`,
    });
  };

  const renderBatchActionsHeader = (
    selectedPartTrees: FullTree[],
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => (
    <AllPartHeaderBatchActions
      selectedPartTrees={selectedPartTrees}
      toggleAllRowsSelected={toggleAllRowsSelected}
      projectId={projectId}
    />
  );

  return {
    isRowActive,
    isRowUnselectable,
    onOpen: handleOpenPartPanel,
    renderBatchActionsHeader,
  };
}
