import { PassportSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { processKeys, taskKeys } from "../../tasks/hooks/queries";
import { passportKeys } from "./queries";

export default function useCreatePassports() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (passportSpec: PassportSpec) => {
      const names = passportSpec.name
        .split(",")
        .map((name) => name.trim())
        .filter((name) => name !== "");

      for (const name of names) {
        const createdPassport = await api.passport.createPassport({
          ...passportSpec,
          name,
        });
        if (passportSpec.process && createdPassport) {
          const processId = await api.task.createProcess(passportSpec.process);
          await api.task.addProcessRelation(processId, {
            type: "passport",
            id: createdPassport.id,
          });
        }
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(passportKeys.all);
        queryClient.invalidateQueries(processKeys.all);
        queryClient.invalidateQueries(taskKeys.all);
        queryClient.invalidateQueries(processKeys.allRelations());
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
