import { ProjectDefinitionSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { projectKeys } from "./queries";

export default function useCreateProjectDefinition(projectId: number) {
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const tr = useTranslations();

  return useMutation(
    async (spec: ProjectDefinitionSpec) => {
      await api.project.createProjectDefinition(projectId, spec);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.allDefinitions(projectId));
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
