import { Spinner } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import {
  Dialog,
  ExtendedColumn,
  GenericStateTag,
  Table,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { ComponentTree } from "../../AllParts/AllPartsType";
import { usePartIteration } from "../../hooks";
import partRevisionNotification from "../../partRevisionNotification";
import { isComponentsComplete, isPartParent } from "../../services";
import ComponentNameCell from "../ApproveAssemblyIterationDialog/ComponentNameCell";
import useAssemblyTree from "../ApproveAssemblyIterationDialog/useAssemblyTree";
import styles from "./ShowIterationBomDialog.module.scss";

export default function ShowIterationBomDialog(props: {
  partId: number;
  iterationId: number;
  onClose: () => void;
}) {
  const { partId, iterationId, onClose } = props;
  const tr = useTranslations();

  const iteration = usePartIteration(partId, iterationId);
  const { data: assemblyTree, isLoading } = useAssemblyTree(
    partId,
    iterationId
  );

  const tableData = useMemo(
    () => (assemblyTree ? [assemblyTree] : []),
    [assemblyTree]
  );

  const columns: ExtendedColumn<ComponentTree>[] = useMemo(
    () => [
      {
        accessor: "name",
        Header: tr.translate("part.dialog.show-nomenclature.part.name"),
        Cell: (cell: CellProps<ComponentTree>) => (
          <ComponentNameCell
            {...cell}
            {...cell.row.getToggleRowExpandedProps()}
          />
        ),
      },
      {
        id: "iteration",
        accessor: (c: ComponentTree) => c.iteration?.name,
        Header: tr.translate("part.dialog.show-nomenclature.part.revision"),
        Cell: (cell: CellProps<ComponentTree>) => {
          const component = cell.row.original;
          const isIncomplete =
            component.part && component.subRows
              ? isPartParent(component.part?.type) &&
                !isComponentsComplete(component.subRows ?? [])
              : false;

          const hasObsoleteComponents = component.subRows?.some(
            (c) => c.iteration?.state === "obsolete"
          );

          const notification = partRevisionNotification({
            isIncomplete,
            hasObsoleteComponents,
          });

          return (
            <GenericStateTag
              name={cell.value}
              state={component.iteration?.state ?? "draft"}
              notification={notification}
            />
          );
        },
      },
    ],
    [tr]
  );

  return (
    <Dialog
      isOpen
      onClose={onClose}
      onPrimaryClick={onClose}
      icon="diagram-tree"
      className={styles.dialog}
      title={tr.translateAsString("part.dialog.show-nomenclature.title", {
        name: iteration?.name || "",
      })}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          data={tableData}
          columns={columns}
          className={styles.table}
          initialState={{ expandAll: true }}
        />
      )}
    </Dialog>
  );
}
