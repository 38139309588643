import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useSetPassportParentVersion() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { passportId: number; versionId: number }) => {
      const { passportId, versionId } = values;
      await api.passport.updatePassport(passportId, [
        {
          type: "projectIteration",
          value: versionId,
        },
      ]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(passportKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}
