import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { pdmQueries } from "./queries";

export default function useAssemblyIterationComponents(
  assemblyId: number,
  iteration: number,
  enabled?: boolean
) {
  const api = useApi();
  return useQuery({
    ...pdmQueries.iterationComponents(api, assemblyId, iteration),
    enabled,
  });
}
