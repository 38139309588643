import { API } from "@aletiq/api";
import { ProjectQueryParams, ProjectType } from "@aletiq/types";

export const projectKeys = {
  all: ["projects"] as const,
  list: (params?: ProjectQueryParams) =>
    [...projectKeys.all, params ?? {}] as const,
  byId: (id: number) => [...projectKeys.all, id] as const,
  byName: (name: string) => [...projectKeys.all, name] as const,
  image: (id: number) => [...projectKeys.byId(id), "image"] as const,

  ancestors: (id: number) => [...projectKeys.byId(id), "ancestors"] as const,
  countWithDescription: (type?: ProjectType) =>
    [...projectKeys.all, "products-with-descriptions", type ?? ""] as const,

  allVersions: (id: number) => [...projectKeys.byId(id), "versions"] as const,
  version: (id: number, type: string) =>
    [...projectKeys.allVersions(id), type] as const,

  allDefinitions: (id: number) =>
    [...projectKeys.byId(id), "definitions"] as const,
  definition: (id: number, rev: number) =>
    [...projectKeys.allDefinitions(id), rev] as const,

  allDocumentsDefinition: (id: number, rev: number) =>
    [...projectKeys.definition(id, rev), "documents"] as const,
  documentDefinition: (
    projectId: number,
    definitionIdx: number,
    documentId: number
  ) =>
    [
      ...projectKeys.allDocumentsDefinition(projectId, definitionIdx),
      documentId,
    ] as const,

  allBom: (id: number, rev: number) =>
    [...projectKeys.definition(id, rev), "bom"] as const,
  bomEntities: (id: number, rev: number) =>
    [...projectKeys.allBom(id, rev), "entities"] as const,
  bomPart: (id: number, rev: number) =>
    [...projectKeys.allBom(id, rev), "part"] as const,
  bomPartTree: (id: number, rev: number, nodes: number[]) =>
    [...projectKeys.allBom(id, rev), "tree", nodes] as const,
  bomUsedIn: (id: number, params?: ProjectQueryParams) =>
    [...projectKeys.byId(id), "bom", "used-in", params ?? {}] as const,

  toolingRequirement: ["tooling-requirement"] as const,
  toolingRequirementById: (id: number) =>
    [...projectKeys.toolingRequirement, id] as const,

  toolingArticles: ["tooling-articles"] as const,
  toolingArticlesById: (id: number) =>
    [...projectKeys.toolingArticles, id] as const,

  allBills: ["operation-bills"] as const,
  billsById: (id: number) => [...projectKeys.allBills, id] as const,
};

export const projectQueries = {
  byId: (api: API, id: number) => ({
    queryKey: projectKeys.byId(id),
    queryFn: () => api.project.getProject(id),
  }),

  bomEntities: (api: API, id: number, rev: number) => ({
    queryKey: projectKeys.bomEntities(id, rev),
    queryFn: () => api.project.getProjectBOM(id, rev),
  }),

  bomPart: (api: API, id: number, rev: number) => ({
    queryKey: projectKeys.bomPart(id, rev),
    queryFn: () => api.pdm.getPartProjectBom(id, rev),
  }),

  definition: (api: API, id: number, rev: number) => ({
    queryKey: projectKeys.definition(id, rev),
    queryFn: () => api.project.getProjectDefinition(id, rev),
  }),
};
