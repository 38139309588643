import {
  VALID_DOC_TABS,
  VALID_PART_TABS,
  VALID_PASSPORT_TABS,
  VALID_STANDARD_PART_TABS,
  VALID_WORKFLOW_TABS,
} from "../constants";

export function isValidTabId(
  tabId: string | null | undefined,
  panelType: "document" | "part" | "passport" | "standard-part" | "workflow"
) {
  if (!tabId) return false;
  switch (panelType) {
    case "document":
      return VALID_DOC_TABS.includes(tabId);
    case "part":
      return VALID_PART_TABS.includes(tabId);
    case "standard-part":
      return VALID_STANDARD_PART_TABS.includes(tabId);
    case "passport":
      return VALID_PASSPORT_TABS.includes(tabId);
    case "workflow":
      return VALID_WORKFLOW_TABS.includes(tabId);
  }
}
