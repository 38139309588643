import { DocumentRevision, ProjectDefinitionDocument } from "@aletiq/types";
import React from "react";
import {
  Card,
  Field,
  GenericStateTag,
  GenericStatusTag,
  HighlightedText,
} from "../../../../components";
import { useNavigateTo } from "../../../../hooks";
import { Diff, useTranslations } from "../../../../util";
import { useDocument, useShowDocumentPreview } from "../../../documents/hooks";
import {
  useDeleteProjectDefinitionDocument,
  useUpdateProjectDefinitionDocument,
} from "../../hooks";
import { useDownloadRevision } from "../../Operations/hooks";
import VisibleDocumentProperties from "../../Operations/VisibleDocumentProperties";
import ProjectDefinitionDocumentDraftCardHeaderAction from "./ProjectDefinitionDocumentCardDraftHeaderAction";
import ProjectDefinitionDocumentCardValidatedHeaderAction from "./ProjectDefinitionDocumentCardValidatedHeaderAction";
import ProjectDefinitionDocumentRevisionSelect from "./ProjectDefinitionDocumentRevisionSelect";

export default function ProjectDefinitionDocumentCard(props: {
  search: string;
  projectId: number;
  definitionIdx: number;
  document: ProjectDefinitionDocument;
  hasEdition?: boolean;
  diff: Diff<ProjectDefinitionDocument>;
}) {
  const {
    hasEdition,
    search,
    document: projectDefinitionDocument,
    projectId,
    definitionIdx,
    diff,
  } = props;

  const tr = useTranslations();

  const { data: document } = useDocument(projectDefinitionDocument.document);
  const { revision } = projectDefinitionDocument;

  const openDocumentDetails = useNavigateTo({
    panel: `documentId-${projectDefinitionDocument.document}`,
  });

  const { mutate: updateDocumentDefinition } =
    useUpdateProjectDefinitionDocument(
      projectId,
      definitionIdx,
      projectDefinitionDocument.document
    );

  const { mutate: deleteDocumentDefinition } =
    useDeleteProjectDefinitionDocument(
      projectId,
      definitionIdx,
      projectDefinitionDocument.document
    );

  const handleAssignRevision = (revision: DocumentRevision) => {
    updateDocumentDefinition(revision.id);
  };

  const showPreview = useShowDocumentPreview();

  const downloadRevision = useDownloadRevision();

  const handleOpenPreview = () =>
    showPreview({
      documentId: projectDefinitionDocument.document,
      revisionId: revision?.id,
    });

  const handleDownload = () => {
    if (!revision) {
      return;
    }
    downloadRevision(projectDefinitionDocument.document, revision.id);
  };

  const handleDelete = () => {
    deleteDocumentDefinition();
  };

  const isDocumentVisible = document?.name.toLowerCase().includes(search);

  return (
    <>
      {document && isDocumentVisible && (
        <Card
          onClick={openDocumentDetails}
          icon="document"
          title={<HighlightedText text={document?.name} highlight={search} />}
          headerActions={
            <>
              <GenericStatusTag
                status={definitionIdx > 1 ? diff.status : undefined}
              />

              {hasEdition ? (
                <ProjectDefinitionDocumentDraftCardHeaderAction
                  onOpenPreview={handleOpenPreview}
                  onDelete={handleDelete}
                  onDownload={handleDownload}
                />
              ) : (
                <ProjectDefinitionDocumentCardValidatedHeaderAction
                  onOpenPreview={handleOpenPreview}
                  onDownload={handleDownload}
                />
              )}
            </>
          }
        >
          <Field
            inline
            minWidth={200}
            label={tr.translateAsString("generic.label.revision")}
          >
            {revision && (
              <>
                {hasEdition ? (
                  <ProjectDefinitionDocumentRevisionSelect
                    isDisabled={!hasEdition}
                    isDense
                    view={"smooth"}
                    intent={"default"}
                    document={document}
                    assignedRevision={revision}
                    onAssignRevision={handleAssignRevision}
                  />
                ) : (
                  <GenericStateTag
                    name={revision.revisionName}
                    state={revision.state}
                  />
                )}
              </>
            )}
          </Field>

          <VisibleDocumentProperties document={document} />
        </Card>
      )}
    </>
  );
}
