import { Passport, ProjectFieldValue } from "@aletiq/types";
import React, { useState } from "react";
import { EditablePropertyTemplate } from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { sortByNumber, useTranslations } from "../../../../util";
import { useProject } from "../../../project/hooks";
import { TagPassportConfiguration } from "../../common";
import { UpdatePassportConfigDialog } from "../../dialogs";
import styles from "./ConfigurationField.module.scss";

export default function PassportConfigurationField(props: {
  passport: Passport;
}) {
  const { passport } = props;
  const tr = useTranslations();

  const [editMode, setEditMode] = useState(false);

  const { data: parentProject } = useProject(passport?.parentProject ?? null);
  const canUpdatePassports = useHasPermission("update:passports");

  const handleTriggerEditMode = () => {
    setEditMode(true);
  };

  const reset = () => {
    setEditMode(false);
  };

  const passportConfig: Record<number, ProjectFieldValue[]> = sortByNumber(
    passport.config,
    (config) => config.id
  )
    .filter((field) => field.values !== [])
    .reduce((acc, config) => {
      acc[config.id] = config.values;
      return acc;
    }, {} as Record<number, ProjectFieldValue[]>);

  return (
    <EditablePropertyTemplate
      label={tr.translateAsString("generic.label.configuration")}
      isEditable={canUpdatePassports}
      triggerEditMode={handleTriggerEditMode}
      isInEditMode={editMode}
      inline
    >
      <TagList config={passportConfig} />
      {editMode && (
        <UpdatePassportConfigDialog
          passportId={passport.id}
          passportConfig={passport?.config || []}
          configSpec={parentProject?.options || []}
          onClose={reset}
        />
      )}
    </EditablePropertyTemplate>
  );
}

const TagList = (props: { config: Record<number, ProjectFieldValue[]> }) => {
  const { config } = props;
  const tr = useTranslations();

  return (
    <div className={styles.row_tag}>
      {Object.keys(config).length > 0 &&
        Object.entries(config).map(([fieldId, tags]) =>
          tags.map((tag, index) => (
            <TagPassportConfiguration
              key={index}
              fieldId={parseInt(fieldId, 10)}
              fieldValue={tag}
            />
          ))
        )}
      {Object.keys(config).length === 0 && tr.translate("generic.label.none")}
    </div>
  );
};
