import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { searchKeys } from "./queries";

export default function useSearchHistory(search: string) {
  const api = useApi();
  return useQuery(
    searchKeys.history(search),
    () => api.search.getSearchHistory(search),
    { keepPreviousData: true }
  );
}
