import { IntlProvider } from "@aletiq/translation";
import React, { useEffect, useState } from "react";
import getTranslationJson from "../util/constants/getTranslationJson";

export function Intl(props: { lang: string; children: React.ReactNode }) {
  const [messages, setMessages] = useState();
  useEffect(() => {
    (async function () {
      const messages = await getTranslationJson(props.lang);
      setMessages(messages);
    })();
  }, [props.lang]);
  return (
    <IntlProvider locale={props.lang} messages={messages}>
      {messages && props.children}
    </IntlProvider>
  );
}
