import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { pdmKeys } from "./queries";

export default function usePartImage(partId: number | undefined | null) {
  const api = useApi();
  return useQuery(pdmKeys.image(partId!), () => api.pdm.getPartImage(partId!), {
    enabled: !!partId,
  });
}
