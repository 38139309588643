import React from "react";
import { Divider } from "../../../../components";
import { useTranslations } from "../../../../util";
import usePartUsedIn from "../../hooks/usePartUsedIn";
import PartUsedInRow from "./PartUsedInRow";
import styles from "./PartUsedIn.module.scss";

export default function PartUsedIn(props: { partId: number }) {
  const { partId } = props;
  const tr = useTranslations();

  const { data: usedIn = [] } = usePartUsedIn(partId);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.name}>
          {tr.translate("part.details.used-in.name")}
        </div>
        <div className={styles.dividerColumn}>
          <Divider isVertical className={styles.divider} />
        </div>
        <div className={styles.revision}>
          {tr.translate("part.details.revisions")}
        </div>
      </div>
      {usedIn.map((part) => (
        <PartUsedInRow
          key={part.part.id}
          partId={part.part.id}
          iterations={part.iterations}
        />
      ))}
    </div>
  );
}
