import {
  ActiveDocument,
  ActiveDocumentRevision,
  Document,
  DocumentQueryFilterParams,
  DocumentRevision,
  PropertyFilter,
  PropertyValue,
} from "@aletiq/types";
import { useMemo } from "react";
import { isInString, toDate } from "../../../util";

export type NewVersionDoc =
  | (FilteredVersionDoc & { state: "confirmed" })
  | (UnconfirmedVersionDoc & { state: "unconfirmed" });

export type FilteredVersionDoc = {
  id: number;
  name: string;
  comment: string;
  revisionName?: string;
  description: string;
  activeRevision: ActiveDocumentRevision;
  new?: boolean;
  updated?: boolean;
  deleted?: boolean;
  doc: Document;
  createdAt: string;
  updatedAt: string;
  properties: Record<number, PropertyValue>;
  revision?: DocumentRevision;
};

export type UnconfirmedVersionDoc = {
  id: number;
  name: string;
  comment: string;
  revisionName?: string;
  description: string;
  activeRevision?: ActiveDocumentRevision;
  doc: Document;
  properties: Record<number, PropertyValue>;
  createdAt: string;
  updatedAt: string;
};

export type ResolvedVersionDocument = {
  doc: Document;
  revisionInfo: DocumentRevision | undefined;
  name: string | undefined;
  revision: ActiveDocumentRevision;
  description: string;
  new?: boolean | undefined;
  updated?: boolean | undefined;
  deleted?: boolean | undefined;
};

export function useVersionDocsFilter(
  versionDocs: (ActiveDocument & {
    new?: boolean;
    updated?: boolean;
    deleted?: boolean;
  })[],
  filter: DocumentQueryFilterParams
): {
  allVersionDocs: ResolvedVersionDocument[];
  filteredDocs: FilteredVersionDoc[];
} {
  return useMemo(() => {
    const resolvedVersionDocs = versionDocs.map((activeDoc) => {
      const doc = activeDoc.document;
      const revisionInfo = doc.revisions.find(
        (r) => r.id === activeDoc.revision.revisionId
      );
      const name = doc.name;

      return {
        ...activeDoc,
        doc,
        revisionInfo,
        name,
      };
    });

    const filteredDocs = resolvedVersionDocs.filter(documentPredicate(filter));

    return {
      allVersionDocs: resolvedVersionDocs,
      filteredDocs: filteredDocs.map((document) => ({
        id: document.doc.id,
        name: document.name ?? "",
        comment: document.description,
        revision: document.revisionInfo,
        activeRevision: document.revision,
        revisionName: document.revisionInfo?.revisionName ?? "",
        description: document.description,
        new: document.new,
        updated: document.updated,
        deleted: document.deleted,
        doc: document.doc,
        confirmed: true,
        properties: document.doc.properties,
        createdAt: document.doc.createdAt,
        updatedAt: document.doc.updatedAt,
      })),
    };
  }, [filter, versionDocs]);
}

function documentPredicate(filter: DocumentQueryFilterParams) {
  return (activeDoc: ResolvedVersionDocument): boolean => {
    const { search, filters = [], createdAt, updatedAt } = filter;

    if (
      createdAt &&
      !hasValidDate(createdAt.from, createdAt.to, activeDoc.doc.createdAt)
    ) {
      return false;
    }

    if (
      updatedAt &&
      !hasValidDate(updatedAt.from, updatedAt.to, activeDoc.doc.updatedAt)
    ) {
      return false;
    }

    for (const propertyFilter of filters) {
      const docValue = activeDoc.doc.properties[propertyFilter.id];
      if (!hasValidPropertyValue(propertyFilter, docValue)) {
        return false;
      }
    }

    return matchSearchString(
      search ?? "",
      activeDoc.description,
      activeDoc.name ?? "",
      activeDoc.revisionInfo?.revisionName ?? ""
    );
  };
}

function hasValidPropertyValue(
  filter: PropertyFilter,
  docValue?: PropertyValue
) {
  if (!docValue) {
    return false;
  }

  if (filter.type === "string" && docValue.type === "string") {
    return isInString(docValue.value, filter.value);
  }

  if (filter.type === "number" && docValue.type === "number") {
    return filter.value === docValue.value;
  }

  if (filter.type === "enum" && docValue.type === "enum") {
    return filter.values.includes(docValue.value);
  }

  if (filter.type === "enum" && docValue.type === "multi-enum") {
    return docValue.value.some((val) => filter.values.includes(val));
  }

  if (filter.type === "user" && docValue.type === "user") {
    return docValue.value.some((val) => filter.values.includes(val));
  }

  if (filter.type === "date" && docValue.type === "date") {
    return hasValidDate(filter.from, filter.to, docValue.value);
  }

  return false;
}

function hasValidDate(
  filterFrom: string,
  filterTo: string,
  docDateString: string
) {
  const docDate = toDate(docDateString);
  const from = toDate(filterFrom);
  const to = toDate(filterTo);
  // beginning of next day
  to.setDate(to.getDate() + 1);

  return docDate >= from && docDate < to;
}

const matchSearchString = (
  search: string,
  ...fields: (string | undefined)[]
) => {
  if (!search) return true;
  const searched = search.toLocaleLowerCase();
  for (const field of fields) {
    if (field && field.toLocaleLowerCase().includes(searched)) return true;
  }
  return false;
};
