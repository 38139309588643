import { useMutation } from "react-query";
import useApi from "../../../app/useApi";
import { useDownloadQueue } from "../../../hooks";

export default function useDownloadUserExport() {
  const api = useApi();
  const { handleDownload } = useDownloadQueue();

  return useMutation(async (fileId: number) => {
    handleDownload({
      fetchToken: () => api.http.getJson(`/admin/users-exports/${fileId}`),
      forceDownload: true,
    });
  });
}
