import { useInfiniteQuery } from "react-query";
import useApi from "../../../app/useApi";
import { commentKeys } from "../../comments/hooks/queries";

export default function useInfiniteComments() {
  const api = useApi();

  return useInfiniteQuery(
    commentKeys.all,
    async ({ pageParam = undefined }) => api.comment.getComments(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.cursor ?? undefined,
      keepPreviousData: true,
    }
  );
}
