import {
  Button,
  ButtonGroup,
  Card,
  Menu,
  MenuItem,
  Popover2,
  Tooltip,
} from "@aletiq/design-system";
import { Document } from "@aletiq/types";
import React, { useCallback, useMemo, useState } from "react";
import {
  ANALYTICS_DOCUMENTS_PREVIEW_DETAILS,
  ANALYTICS_DOCUMENTS_PREVIEW_SOURCE,
  ANALYTICS_DOCUMENTS_SHOWN,
  useAnalytics,
} from "../../../../analytics";
import {
  AlignRight,
  DocumentNameLink,
  GenericStateTag,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { DocumentSigningDialog } from "../../../documents/dialogs";
import SignaturesTooltip from "../../../documents/DocumentDetails/Revisions/SignaturesTooltip";
import {
  useDocumentRevisionSignatures,
  useShowDocumentPreview,
} from "../../../documents/hooks";
import styles from "./WorkflowEntities.module.scss";
import { UnlinkMenuItem } from "./WorkflowEntity";

export default function WorkflowDocument(props: {
  document: Document;
  disableEdit: boolean;
  isFolded: boolean;
  onToggleFold: () => void;
  onDetach: () => void;
  onShowPreview: (value: { documentId: number; revisionId?: number }) => void;
}) {
  const {
    document,
    disableEdit,
    onDetach,
    onShowPreview,
    isFolded,
    onToggleFold,
  } = props;

  const tr = useTranslations();
  const analytics = useAnalytics();

  const [dialog, setDialog] = useState<"sign">();

  const lastRevision = useMemo(() => document.revisions[0], [document]);

  const handleShowPreview = () => {
    document && onShowPreview({ documentId: document.id });
  };

  const handleOpenDialog = () => {
    setDialog("sign");
  };

  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  const showPreview = useShowDocumentPreview();

  const { data: signatures = [] } = useDocumentRevisionSignatures(
    document.id,
    lastRevision.id
  );

  const handleShowFilePreview = useCallback(() => {
    showPreview({
      documentId: document.id,
      revisionId: lastRevision.id,
    });
    analytics.track(ANALYTICS_DOCUMENTS_SHOWN, {
      [ANALYTICS_DOCUMENTS_PREVIEW_SOURCE]: ANALYTICS_DOCUMENTS_PREVIEW_DETAILS,
    });
  }, [document, lastRevision, showPreview, analytics]);

  return (
    <>
      <Card
        isFolded={isFolded}
        onToggleFold={onToggleFold}
        className={styles.entity_list}
        title={<DocumentNameLink hasIcon documentId={document.id} />}
        headerActions={
          <ButtonGroup isDense>
            <Button onClick={handleShowPreview} isDense icon="eye-open" />

            <Popover2
              content={
                <Menu>
                  <UnlinkMenuItem
                    onClick={onDetach}
                    isDisabled={disableEdit || !document.hasAccess}
                  />
                  <MenuItem
                    disabled={lastRevision.state === "approved"}
                    isDense
                    icon="draw"
                    onClick={handleOpenDialog}
                    text={
                      <Tooltip
                        isDisabled={lastRevision.state !== "approved"}
                        position="top-right"
                        content={tr.translate(
                          "workflow.entities.visualize-document.tooltip"
                        )}
                      >
                        {tr.translate(
                          "document.details.revisions.sign-document"
                        )}
                      </Tooltip>
                    }
                  />
                </Menu>
              }
            >
              <Button isDense icon="more" />
            </Popover2>
          </ButtonGroup>
        }
      >
        <div className={styles.entity_document}>
          <span className={styles.revision_info}>
            {tr.translate("workflow.entities.document.last-revision")}
          </span>
          <GenericStateTag
            name={lastRevision.revisionName}
            state={lastRevision.state}
          />
          <AlignRight />
          <SignaturesTooltip signatures={signatures} />
        </div>
      </Card>
      {dialog === "sign" && (
        <DocumentSigningDialog
          documentId={document.id}
          documentName={document.name}
          revisionId={lastRevision.id}
          onClose={handleCloseDialog}
          entry={lastRevision}
          onShowFile={handleShowFilePreview}
        />
      )}
    </>
  );
}
