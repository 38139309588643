import { Button, Popover2 } from "@aletiq/design-system";
import { FormattedDate, FormattedRelativeTime } from "@aletiq/translation";
import classname from "classnames";
import React, { ReactElement, ReactNode } from "react";
import { selectUnit } from "../../util";
import User from "../User/User";
import styles from "./FeedCard.module.scss";

export default function FeedCard(props: {
  user: number;
  date: string;
  children?: ReactNode;
  menu?: ReactElement;
  seen?: boolean;
}) {
  const { user, date, children, menu, seen } = props;
  const { value, unit } = selectUnit(new Date(date));

  return (
    <div
      className={classname(styles.card, {
        [styles.seen]: seen,
      })}
    >
      <div className={styles.header}>
        <User
          className={styles.user}
          intent="default"
          size="small"
          userId={user}
        />
        <span className={styles.date}>
          <FormattedDate value={date} />
          {" - "}
          <FormattedRelativeTime value={value} unit={unit} />
        </span>
        {menu && (
          <div className={styles.header_button}>
            <Popover2 content={menu}>
              <Button isDense icon="more" />
            </Popover2>
          </div>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
