import { Part } from "@aletiq/types";
import React from "react";
import { CompTree } from "../hooks/usePartTree";

export default function PartTree(props: {
  tree: CompTree;
  offset?: number;
  TreeNode: (props: { node: Part; offset: number }) => React.ReactElement;
}) {
  const { tree, offset = 0, TreeNode } = props;
  const { node, children } = tree;
  return (
    <div>
      <TreeNode node={node} offset={offset} />
      <div>
        {children &&
          children.map((subtree) => (
            <PartTree tree={subtree} offset={offset + 1} TreeNode={TreeNode} />
          ))}
      </div>
    </div>
  );
}
