import { DocumentQueryFilterParams } from "@aletiq/types";
import React, { useMemo } from "react";
import {
  CustomPropertyFilter,
  DateFilter,
  MoreFilters,
  OwnerFilter,
} from "../../../components";
import { HandleClear, HandleFilter, useCustomFilters } from "../../../hooks";
import { useTranslations } from "../../../util";
import {
  useAletiqPropertiesDefaultDisplay,
  useProperties,
} from "../../administration/Attributes/hooks";

export default function MoreDocumentFilters(props: {
  filters: DocumentQueryFilterParams;
  handleFilter: HandleFilter<DocumentQueryFilterParams>;
  handleClear: HandleClear<DocumentQueryFilterParams>;
}) {
  const { filters: documentFilters, handleFilter, handleClear } = props;

  const tr = useTranslations();

  const { data: customProperties = [] } = useProperties("document");
  const { data: aletiqProperties = [] } =
    useAletiqPropertiesDefaultDisplay("document");

  const { setCustomFilter, clearCustomFilter } = useCustomFilters(
    documentFilters,
    handleFilter
  );

  const additionalFilters = useMemo(() => {
    const filtersHiddenByDefault: (keyof DocumentQueryFilterParams)[] = [
      "owners",
      "filters",
    ];

    if (
      aletiqProperties.some(
        (p) => p.name === "created_at" && !p.displayByDefault
      )
    ) {
      filtersHiddenByDefault.push("createdAt");
    }
    if (
      aletiqProperties.some(
        (p) => p.name === "updated_at" && !p.displayByDefault
      )
    ) {
      filtersHiddenByDefault.push("updatedAt");
    }

    return filtersHiddenByDefault;
  }, [aletiqProperties]);

  return (
    <MoreFilters
      filterValues={documentFilters}
      additionalFilters={additionalFilters}
      onClear={handleClear}
      customProperties={customProperties}
    >
      <OwnerFilter
        selected={documentFilters.owners ?? []}
        onSelect={handleFilter("owners")}
        onClear={handleClear(["owners"])}
        withoutEmptyOwner
      />
      {additionalFilters.includes("createdAt") && (
        <DateFilter
          selected={documentFilters?.createdAt}
          onSelect={handleFilter("createdAt")}
          label={tr.translateAsString("generic.label.creation-date.title")}
        />
      )}

      {additionalFilters.includes("updatedAt") && (
        <DateFilter
          selected={documentFilters?.updatedAt}
          onSelect={handleFilter("updatedAt")}
          label={tr.translateAsString("generic.label.last-update.title")}
        />
      )}
      {/* Only display properties that are not displayed by default */}
      {customProperties.map((property) =>
        property.displayByDefault ? null : (
          <CustomPropertyFilter
            key={property.id}
            property={property}
            value={documentFilters.filters?.find((p) => p.id === property.id)}
            onClear={clearCustomFilter}
            onSetValue={setCustomFilter}
          />
        )
      )}
    </MoreFilters>
  );
}
