import { Icon } from "@aletiq/design-system";
import React from "react";
import { nextState } from "../../../util";
import GenericStateTag, {
  GenericStateTagProps,
} from "../GenericStateTag/GenericStateTag";
import styles from "./StateTagTransition.module.scss";

export default function StateTagTransition(props: {
  initialProps: GenericStateTagProps;
  finalProps?: GenericStateTagProps;
  arrowSize?: number;
}) {
  const { initialProps, finalProps, arrowSize = 20 } = props;
  const nextProps = finalProps
    ? finalProps
    : { state: nextState(initialProps.state) ?? "draft" };

  return (
    <div className={styles.transition_state}>
      <GenericStateTag {...initialProps} />
      <Icon icon="arrow-right" size={arrowSize} />
      <GenericStateTag {...nextProps} />
    </div>
  );
}
