var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// src/index.ts
import {
  FormattedDate,
  FormattedMessage,
  FormattedRelativeTime,
  IntlProvider,
  useIntl as useIntl2
} from "react-intl";

// src/dateLocalizationHelper.ts
import { LocaleUtils as defaultLocaleUtils } from "react-day-picker";
var WEEK_DAYS_LONG = {
  en: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  fr: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
};
var WEEK_DAYS_SHORT = {
  en: ["S", "M", "T", "W", "T", "F", "S"],
  fr: ["D", "L", "M", "M", "J", "V", "S"]
};
var MONTHS = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  fr: [
    "Janvier",
    "F\xE9vrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Ao\xFBt",
    "Septembre",
    "Octobre",
    "Novembre",
    "D\xE9cembre"
  ]
};
var formatDay = (d, locale = "en") => {
  const weekDaysLong = (WEEK_DAYS_LONG == null ? void 0 : WEEK_DAYS_LONG[locale.slice(0, 2)]) ?? [];
  const months = (MONTHS == null ? void 0 : MONTHS[locale.slice(0, 2)]) ?? [];
  return `${weekDaysLong[d.getDay()]}, ${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`;
};
var formatMonthTitle = (d, locale = "en") => {
  const months = MONTHS[locale.slice(0, 2)];
  return `${months[d.getMonth()]} ${d.getFullYear()}`;
};
var formatWeekdayShort = (i, locale) => {
  var _a;
  return ((_a = WEEK_DAYS_SHORT == null ? void 0 : WEEK_DAYS_SHORT[locale.slice(0, 2)]) == null ? void 0 : _a[i]) ?? "";
};
var formatWeekdayLong = (i, locale) => {
  var _a;
  return ((_a = WEEK_DAYS_LONG == null ? void 0 : WEEK_DAYS_LONG[locale.slice(0, 2)]) == null ? void 0 : _a[i]) ?? "";
};
var getFirstDayOfWeek = (locale) => locale.includes("en") ? 0 : 1;
var getMonths = (locale) => locale ? MONTHS[locale.slice(0, 2)] : MONTHS.en;
var dateLocalizationHelper = __spreadProps(__spreadValues({}, defaultLocaleUtils), {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths
});

// src/translations.tsx
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
var makeTranslations = (intl) => {
  const translate = (id = "helper.missing-translation", values = {}) => {
    const defaultValue = {
      em: (chunks) => /* @__PURE__ */ React.createElement("em", null, chunks),
      strong: (chunks) => /* @__PURE__ */ React.createElement("strong", null, chunks),
      b: (chunks) => /* @__PURE__ */ React.createElement("strong", null, chunks)
    };
    const _values = __spreadValues(__spreadValues({}, defaultValue), values);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, React.Children.toArray(intl.formatMessage({ id }, _values)));
  };
  const _intl = {
    intl,
    translate,
    translateAsString: (id, values) => intl.formatMessage({
      id
    }, values)
  };
  return _intl;
};
function useTranslations() {
  const intl = useIntl();
  return useMemo(() => makeTranslations(intl), [intl]);
}
export {
  FormattedDate,
  FormattedMessage,
  FormattedRelativeTime,
  IntlProvider,
  dateLocalizationHelper,
  useIntl2 as useIntl,
  useTranslations
};
