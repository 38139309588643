import { Part } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../util";
import partLinkProps from "../../link/props/partLinkProps";
import ActivityLink from "./ActivityLink";

export default function ActivityPartLink(props: {
  part: Part;
  onClick?: () => void;
}) {
  const { part, onClick } = props;
  const tr = useTranslations();

  const linkProps = partLinkProps(tr, part);

  return <ActivityLink {...linkProps} onClick={onClick} />;
}
