import { Icon } from "@aletiq/design-system";
import { SortState } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import { HeaderGroup } from "react-table";
import styles from "./Table.module.scss";

export default function TableHeader<
  T extends Object,
  U extends string | number = Extract<keyof T, string>
>(props: {
  headerGroup: HeaderGroup<T>;
  selectedItems: T[];
  toggleAllRowsSelected: (value?: boolean | undefined) => void;
  sortState?: SortState<U>;
  onSort: (key: U) => void;
  renderBatchActionsHeader?: (
    selectedItems: T[],
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => JSX.Element;
  hasVisibleScrollbar: boolean;
  scrollbarSize: number;
}) {
  const {
    headerGroup,
    selectedItems,
    renderBatchActionsHeader,
    hasVisibleScrollbar,
    scrollbarSize,
    toggleAllRowsSelected,
    sortState,
    onSort,
  } = props;

  const colCount = headerGroup.headers.length;
  const selectionColExists =
    colCount > 0 && headerGroup.headers[0].id === "selection";
  const isLastColSorted =
    sortState && headerGroup.headers[colCount - 1].id === sortState.key;
  const showActionHeader =
    selectedItems.length > 0 && renderBatchActionsHeader && selectionColExists;

  const handleSort = (column: HeaderGroup<T>) => {
    column.canSort && onSort(column.id as U);
  };

  return (
    <div {...headerGroup.getHeaderGroupProps()} className={styles.tr}>
      {headerGroup.headers.map((column, index) => {
        const isLastCol = index === colCount - 1;
        const isSorted = sortState && column.id === sortState.key;
        const sortIcon =
          (sortState && !isSorted) || sortState?.direction === "asc"
            ? "caret-down"
            : "caret-up";

        if (showActionHeader && index > 0) {
          return null;
        }

        if (showActionHeader && index === 0) {
          return (
            <div
              {...column.getHeaderProps()}
              className={classNames(styles.th, styles.action_header)}
              onClick={() => onSort(column.id as U)}
              key={column.id}
            >
              {column.render("Header")}
              {renderBatchActionsHeader &&
                renderBatchActionsHeader(selectedItems, toggleAllRowsSelected)}
            </div>
          );
        }

        // render normal column headers
        return (
          <div
            {...column.getHeaderProps()}
            className={classNames(styles.th, {
              [styles.isSorted]: isSorted,
              [styles.isSortable]: column.canSort,
            })}
          >
            <div
              className={styles.column_title_wrapper}
              onClick={() => handleSort(column)}
              key={column.id}
            >
              <div className={styles.column_title}>
                {column.render("Header")}
                {sortState && column.canSort && (
                  <Icon icon={sortIcon} className={styles.sort_icon} />
                )}
              </div>
            </div>

            {!column.disableResizing && (
              <div
                {...column.getResizerProps()}
                className={styles.resizer_wrapper}
              >
                <div
                  className={classNames(styles.resizer_bar, {
                    [styles.invisible]: isLastCol,
                  })}
                />
              </div>
            )}
          </div>
        );
      })}

      {/* add filler if the table body has a scrollbar */}
      {hasVisibleScrollbar && (
        <div
          style={{ width: `${scrollbarSize}px` }}
          className={classNames(styles.th_filler, {
            [styles.action_header]: showActionHeader,
            [styles.isSorted]: isLastColSorted,
          })}
        />
      )}
    </div>
  );
}
