import { unique } from "../array";

export function makePathId(id?: number, pathId?: string) {
  return pathId ? `${pathId}.${id ?? 0}` : `${id}`;
}

export function makeParentNumberId(pathId: string) {
  return parseInt(pathId.split(".").slice(-2)[0]);
}

export function makeParentId(pathId: string) {
  return pathId.split(".").slice(0, -1).join(".");
}

export function makeNumberIds(pathId: string) {
  return pathId.split(".").map((id) => parseInt(id));
}

export function makeFlatIds(pathIds: string[]) {
  return unique(pathIds.flatMap(makeNumberIds));
}

export function hasParent(pathId: string) {
  return pathId.split(".").length > 1;
}

export function togglePathId(pathId: string, ids: string[]) {
  return ids.includes(pathId)
    ? ids.filter((p) => p !== pathId)
    : [...ids, pathId];
}
