import {
  AlertPopover,
  AnchorButton,
  ButtonGroup,
  Card,
  MenuItem,
  Tooltip,
} from "@aletiq/design-system";
import { Part, Passport, Project } from "@aletiq/types";
import React, { useState } from "react";
import {
  PartNameLink,
  PassportNameLink,
  ProjectNameLink,
  RemoveLinkIcon,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./WorkflowEntities.module.scss";

export function WorkflowPart(props: {
  part: Part;
  disableEdit: boolean;
  onDetach: () => void;
}) {
  const { part, disableEdit, onDetach } = props;
  return (
    <WorkflowEntity
      title={<PartNameLink hasIcon partId={part.id} />}
      actionButtons={
        <UnlinkButton onClick={onDetach} isDisabled={disableEdit} />
      }
    />
  );
}

export function WorkflowProject(props: {
  project: Project;
  disableEdit: boolean;
  onDetach: () => void;
}) {
  const { project, disableEdit, onDetach } = props;
  return (
    <WorkflowEntity
      title={<ProjectNameLink hasIcon projectId={project.id} />}
      actionButtons={
        <UnlinkButton onClick={onDetach} isDisabled={disableEdit} />
      }
    />
  );
}

export function WorkflowPassport(props: {
  passport: Passport;
  disableEdit: boolean;
  onDetach: () => void;
}) {
  const { passport, disableEdit, onDetach } = props;
  return (
    <WorkflowEntity
      title={<PassportNameLink hasIcon passportId={passport.id} />}
      actionButtons={
        <UnlinkButton onClick={onDetach} isDisabled={disableEdit} />
      }
    />
  );
}

const WorkflowEntity = (props: {
  children?: React.ReactNode;
  title: JSX.Element;
  actionButtons?: React.ReactNode;
  hasFold?: boolean;
}) => {
  const { title, children, hasFold, actionButtons } = props;
  const [isFolded, setIsFolded] = useState(true);

  const handleToggleFold = () => {
    setIsFolded(!isFolded);
  };

  return (
    <Card
      isFolded={isFolded}
      onToggleFold={hasFold ? handleToggleFold : undefined}
      className={styles.entity_list}
      title={title}
      headerActions={<ButtonGroup isDense>{actionButtons}</ButtonGroup>}
    >
      {children}
    </Card>
  );
};

const UnlinkButton = (props: { isDisabled: boolean; onClick: () => void }) => {
  const { isDisabled, onClick } = props;

  const tr = useTranslations();

  return (
    <UnlinkPopover isDisabled={isDisabled} onClick={onClick}>
      <Tooltip
        position="top-right"
        content={tr.translate("generic.action.unlink")}
      >
        <AnchorButton
          isDense
          isDisabled={isDisabled}
          icon={<RemoveLinkIcon />}
        />
      </Tooltip>
    </UnlinkPopover>
  );
};

export const UnlinkMenuItem = (props: {
  isDisabled: boolean;
  onClick: () => void;
}) => {
  const { isDisabled, onClick } = props;

  const tr = useTranslations();

  return (
    <UnlinkPopover isDisabled={isDisabled} onClick={onClick}>
      <MenuItem
        isDense
        disabled={isDisabled}
        shouldDismissPopover={false}
        icon={<RemoveLinkIcon />}
        className={styles.unlink_menu_item}
        text={tr.translate("generic.action.unlink")}
      />
    </UnlinkPopover>
  );
};

const UnlinkPopover = (props: {
  isDisabled: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => {
  const { isDisabled, onClick, children } = props;

  const tr = useTranslations();

  return (
    <AlertPopover
      fill
      disabled={isDisabled}
      icon={<RemoveLinkIcon />}
      title={tr.translateAsString("workflow.dialog.remove.link.title")}
      content={tr.translateAsString("workflow.dialog.remove.link.description")}
      onPrimaryClick={onClick}
      isDense
    >
      {children}
    </AlertPopover>
  );
};
