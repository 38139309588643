import { H3 } from "@aletiq/design-system";
import { useIntl } from "@aletiq/translation";
import classNames from "classnames";
import React from "react";
import { useTranslations } from "../../../../util";
import { ResolvedWorkflow } from "./useResolvedWorkflows";
import styles from "./WorkflowStateSummary.module.scss";

export function WorkflowsStatesSummary(props: {
  workflows: ResolvedWorkflow[];
}) {
  const { workflows } = props;
  const tr = useTranslations();
  const intl = useIntl();

  const ownerCount = new Set(workflows.map((w) => w.owner)).size;
  const taskCount = workflows.reduce((total, w) => total + w.taskCount, 0);
  const finishedTasksCount = workflows.reduce(
    (total, w) => total + w.doneTaskCount,
    0
  );

  const finishedRatio =
    workflows.filter((w) => w.status === "done").length / workflows.length;
  const startedRatio =
    workflows.filter((w) => w.status === "started").length / workflows.length;

  return (
    <div className={styles.summary}>
      <div className={classNames(styles.count_wrapper, styles.workflow_count)}>
        {tr.translate("analysis.state.counts.workflows", {
          count: <H3 className={styles.count}>{workflows.length}</H3>,
          countType: workflows.length > 1 ? "several" : "single",
        })}
      </div>
      <div className={styles.bar}>
        <div className={styles.not_started} />
        <div
          className={styles.started}
          style={{
            width: `${Math.round(startedRatio * 100)}%`,
          }}
        />
        <div
          className={styles.done}
          style={{
            width: `${Math.round(finishedRatio * 100)}%`,
          }}
        />
      </div>
      <div className={styles.count_wrapper}>
        {tr.translate("analysis.state.counts.tasks.done", {
          count: <H3 className={styles.count}>{finishedTasksCount}</H3>,
          countType: finishedTasksCount > 1 ? "several" : "single",
        })}
      </div>
      <div className={styles.count_wrapper}>
        {tr.translate("analysis.state.counts.tasks.total", {
          count: <H3 className={styles.count}>{taskCount}</H3>,
          countType: taskCount > 1 ? "several" : "single",
        })}
      </div>
      <H3 className={classNames(styles.count, styles.count_wrapper)}>
        {intl.formatNumber(finishedTasksCount / taskCount, {
          style: "percent",
        })}
      </H3>
      <div className={styles.count_wrapper}>
        {tr.translate("analysis.state.counts.owners", {
          count: <H3 className={styles.count}>{ownerCount}</H3>,
          countType: ownerCount > 1 ? "several" : "single",
        })}
      </div>
    </div>
  );
}
