import { ButtonGroup } from "@aletiq/design-system";
import React from "react";
import styles from "../tree.module.scss";

export default function TreeButtonGroup(props: {
  children?: React.ReactNode | string;
}) {
  const { children } = props;
  return (
    <ButtonGroup isDense className={styles.button_group}>
      {children}
    </ButtonGroup>
  );
}
