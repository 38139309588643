import { PartQueryParams } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { pdmKeys } from "./queries";

export default function useParts(params?: PartQueryParams) {
  return usePaginatedParts({ ...params, limit: 100 });
}

export function usePaginatedParts(params?: PartQueryParams) {
  const api = useApi();
  return useQuery(pdmKeys.list(params), () => api.pdm.listPartsPage(params), {
    keepPreviousData: true,
  });
}
