import { Part } from "@aletiq/types";
import React from "react";
import { HighlightedText, MenuItem } from "../../../../components";

export default function PartSelectItem(props: {
  part: Part;
  onSelect: (part: Part) => void;
  isSelected?: boolean;
  search: string;
}) {
  const { part, onSelect, isSelected, search } = props;
  return (
    <MenuItem
      onClick={() => onSelect(part)}
      text={<HighlightedText text={part.name} highlight={search} />}
      active={isSelected}
      isDense
    />
  );
}
