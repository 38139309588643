import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { integrationKeys } from "./queries";

export default function useUpdateIntegrationError() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (value: { id: number; isTreated: boolean }) => {
      return await api.integration.updateReportTreatedStatus(
        value.id,
        value.isTreated
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(integrationKeys.allReports());
      },
    }
  );
}
