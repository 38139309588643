import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useDeletePassports(passportId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(() => api.passport.deletePassport(passportId), {
    onSettled: () => {
      queryClient.invalidateQueries(passportKeys.all);
      queryClient.invalidateQueries(activityKeys.all);
    },
    onError: () => {
      toaster.show({
        intent: "danger",
        icon: "warning-sign",
        timeout: 2000,
        message: tr.translate("toaster.error.passport.delete"),
      });
    },
  });
}
