import { DocumentRevisionSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { updateDocument } from "../filesSlice";
import { documentKeys } from "./queries";

export default function useUpdateDocument(documentId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    (values: { file: File; revision: DocumentRevisionSpec | null }) =>
      updateDocument(api, dispatch, documentId, values.revision, values.file),
    {
      onSettled: () => {
        queryClient.invalidateQueries(documentKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}
