import { Badge, MenuSP, Popover2 } from "@aletiq/design-system";
import { ProjectType } from "@aletiq/types";
import { LogoutOptions } from "@auth0/auth0-react";
import { Icon } from "@blueprintjs/core";
import React, { Fragment, ReactNode, useState } from "react";
import { ANALYTICS_VISITED_HELP_CENTER, useAnalytics } from "../../analytics";
import {
  useAcknowledgeNotifications,
  useNotificationCount,
} from "../../features/notifications/hooks";
import Notifications from "../../features/notifications/Notifications";
import {
  useAcknowledgeAppUpdates,
  useCurrentUser,
  useHasPermission,
  useNavigateTo,
  useTenant,
} from "../../hooks";
import { formatMail, useTranslations } from "../../util";
import MenuDivider from "./MenuDivider";
import styles from "./NavBar.module.scss";
import NavigationButton from "./NavigationButton";
import { NavigationMenu, NavigationMenuItem } from "./NavigationMenu";
import SearchInput from "./SearchInput";
import UserMenu from "./UserMenu";

const SUPPORT_MAIL_EMAIL = "support@aletiq.com";
const SUPPORT_MAIL_TITLE = "Signalement d'un problème / Demande d'évolution";
const SUPPORT_MAIL_BODY =
  "Décrire ici le problème rencontré ou l'évolution souhaitée sur l'application. Notre équipe vous recontactera rapidement pour donner suite à votre demande.";
const HELP_CENTER_URL =
  "https://www.notion.so/aletiq/Ressources-utilisateurs-6dcbf675d349414da38cffc5d48ab8c0";
const UPDATES_NOTES_URL =
  "https://aletiq.notion.site/Mises-jour-Aletiq-57de72ca00e840bb9c500bbbb900c340";

export default function NavBar(props: {
  logout: (options?: LogoutOptions | undefined) => void;
  onCreateProject: (type: ProjectType) => void;
  onCreateWorkflow: () => void;
}) {
  const { logout } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const [showNotifs, setShowNotifs] = useState(false);

  const { data: newNotificationCount = 0 } = useNotificationCount();
  const { data: tenant } = useTenant();
  const currentTenant = tenant?.name ?? "";

  const { data: currentUser, isLoading: loadingUser } = useCurrentUser();
  const canCreateProjects = useHasPermission("create:projects");
  const canCreateWorkflows = useHasPermission("create:workflows");

  const canUpdateNotificationSettings = useHasPermission(
    "receive:email-notifications"
  );
  const canCreatePassportAttributes = useHasPermission(
    "create:passport-attributes"
  );
  const canCreateCustomAttributes = useHasPermission(
    "create:custom-attributes"
  );
  const canReadExports = useHasPermission("read:exports");
  const canReadIntegrationReports = useHasPermission(
    "read:integration-reports"
  );
  const canCreateUser = useHasPermission("create:users");

  const canAccessAnalysisWorkflow = useHasPermission("read:workflow-analyis");

  const handleHomeNavigation = useNavigateTo({ path: "/home" });
  const handleAdminNavigation = useNavigateTo({
    path: "/administration/notifications",
  });
  const { mutate: acknowledgeNotifications } = useAcknowledgeNotifications();
  const { mutate: acknowledgeUpdates } = useAcknowledgeAppUpdates();

  const handleCloseNotifications = () => {
    setShowNotifs(false);
    acknowledgeNotifications();
  };

  const handleOpenAppUpdates = () => {
    acknowledgeUpdates();
  };

  const handleToggleNotifications = () => {
    setShowNotifs(!showNotifs);
  };

  const handleOpenHelpCenter = () => {
    analytics.track(ANALYTICS_VISITED_HELP_CENTER);
  };

  return (
    <div className={styles.container}>
      <NavigationButton onClick={handleHomeNavigation}>
        <img
          className={styles.logo}
          alt="Aletiq"
          src="/assets/logo_aletiq_blanc_1701.png"
        />
      </NavigationButton>
      <NavigationMenu label={tr.translateAsString("navbar.projects.articles")}>
        <NavigationMenuItem
          icon="projects"
          text={tr.translate("navbar.projects.articles")}
          path="/project"
          search="type=article"
        />
        <NavigationMenuItem
          icon="id-number"
          text={tr.translate("navbar.passports")}
          path="/passport"
          search="type=article"
        />
        {canCreateProjects && (
          <Fragment>
            <MenuDivider title={tr.translateAsString("navbar.actions")} />
            <NavigationMenuItem
              icon="plus"
              text={tr.translate("navbar.projects.create.article")}
              onClick={() => props.onCreateProject("article")}
            />
          </Fragment>
        )}
      </NavigationMenu>
      <NavigationMenu label={tr.translateAsString("navbar.projects.tools")}>
        <NavigationMenuItem
          icon="wrench"
          text={tr.translate("navbar.projects.tools")}
          path="/project"
          search="type=tool"
        />
        <NavigationMenuItem
          icon="id-number"
          text={tr.translate("navbar.passports")}
          path="/passport"
          search="type=tool"
        />
        {canCreateProjects && (
          <Fragment>
            <MenuDivider title={tr.translateAsString("navbar.actions")} />
            <NavigationMenuItem
              icon="plus"
              text={tr.translate("navbar.projects.create.tool")}
              onClick={() => props.onCreateProject("tool")}
            />
          </Fragment>
        )}
      </NavigationMenu>

      <NavigationMenu
        label={tr.translateAsString("navbar.workflows")}
        path={["/workflows", "/tasks", "/models", "workflows/analysis"]}
      >
        <NavigationMenuItem
          icon="flow-linear"
          text={tr.translate("navbar.workflows.all")}
          path="/workflows"
        />
        <NavigationMenuItem
          icon="tick"
          text={tr.translate("navbar.workflows.tasks")}
          path="/tasks"
        />
        <MenuDivider />
        {canAccessAnalysisWorkflow && (
          <NavigationMenuItem
            icon="chart"
            text={tr.translate("navbar.workflows.analysis")}
            path="/workflows/analysis"
          />
        )}
        <NavigationMenuItem
          icon="duplicate"
          text={tr.translate("navbar.workflows.models")}
          path="/models"
        />
        <NavigationMenuItem
          icon="box"
          text={tr.translate("navbar.workflows.archives")}
          path="/workflows/archived"
        />
        {canCreateWorkflows && (
          <Fragment>
            <MenuDivider title={tr.translateAsString("navbar.actions")} />
            <NavigationMenuItem
              icon="plus"
              text={tr.translate("navbar.workflows.create")}
              onClick={props.onCreateWorkflow}
            />
          </Fragment>
        )}
      </NavigationMenu>
      <NavigationMenu
        label={tr.translateAsString("navbar.files")}
        path={["/cad", "/cad/standard"]}
      >
        <NavigationMenuItem
          icon="document"
          text={tr.translate("sidebar.documents")}
          path="/documents"
        />
        <MenuDivider />
        <NavigationMenuItem
          icon="cube"
          text={tr.translate("sidebar.pdm.parts")}
          path="/cad"
        />
        <NavigationMenuItem
          icon="cog"
          text={tr.translate("sidebar.pdm.standard-parts")}
          path="/cad/standard"
        />
      </NavigationMenu>
      <div className={styles.align_right} />
      <SearchInput />
      {(canUpdateNotificationSettings ||
        canCreatePassportAttributes ||
        canCreateCustomAttributes ||
        canReadExports ||
        canReadIntegrationReports ||
        canCreateUser) && (
        <NavigationButton
          icon={<Icon icon="settings" size={25} color="white" />}
          onClick={handleAdminNavigation}
        />
      )}
      <Popover2
        content={
          <MenuSP view="raised" color="primary">
            <NavigationMenuItem
              text={tr.translate("navbar.help.access")}
              icon="lifesaver"
              href={HELP_CENTER_URL}
              openNewPage
              onClick={handleOpenHelpCenter}
            />
            <NavigationMenuItem
              text={tr.translate("navbar.help.email")}
              icon="envelope"
              href={formatMail(
                SUPPORT_MAIL_EMAIL,
                SUPPORT_MAIL_TITLE,
                SUPPORT_MAIL_BODY
              )}
            />
            <NavigationMenuItem
              text={
                <>
                  {tr.translate("navbar.help.updates")}
                  {!currentUser?.openedUpdate && !loadingUser && (
                    <Badge
                      isDense
                      intent="danger"
                      Component="span"
                      label=""
                      className={styles.badge}
                    />
                  )}
                </>
              }
              icon="clean"
              href={UPDATES_NOTES_URL}
              onClick={handleOpenAppUpdates}
              openNewPage
            />
          </MenuSP>
        }
        position="bottom-right"
        offset={[0, 9]}
        minimal
      >
        <ButtonIndicator enabled={!currentUser?.openedUpdate}>
          <NavigationButton
            icon={<Icon icon="help" size={25} color="white" />}
          />
        </ButtonIndicator>
      </Popover2>

      <Popover2
        minimal
        position="bottom-right"
        isOpen={showNotifs}
        onClose={handleCloseNotifications}
        content={
          <Notifications
            className={styles.menu}
            newNotificationCount={newNotificationCount}
            onClose={handleCloseNotifications}
          />
        }
        offset={[0, 9]}
      >
        <ButtonIndicator
          enabled={newNotificationCount !== 0}
          count={newNotificationCount}
        >
          <NavigationButton
            icon={<Icon icon="notifications" size={25} color="white" />}
            onClick={handleToggleNotifications}
          />
        </ButtonIndicator>
      </Popover2>
      <UserMenu
        user={currentUser?.displayName ?? ""}
        tenant={currentTenant}
        onLogout={logout}
      />
    </div>
  );
}

function ButtonIndicator(props: {
  enabled: boolean;
  count?: number;
  children: ReactNode;
}) {
  const { enabled, count, children } = props;
  if (!enabled) return <>{children}</>;

  return (
    <div className={styles.button_indicator_wrapper}>
      {children}
      <div className={styles.button_indicator_indicator}>{count}</div>
    </div>
  );
}
