import readFSEntries from "./readFSEntries";

export default async function getDataTransferFiles(
  entries: DataTransferItemList
) {
  let files: File[] = [];
  let webkitEntries: any[] = [];
  for (let i = 0; i < entries.length; i++) {
    if (typeof entries[i].webkitGetAsEntry === "function") {
      const entry = entries[i].webkitGetAsEntry();
      webkitEntries = [...webkitEntries, entry];
    } else if (entries[i].kind === "file") {
      files = [...files, entries[i].getAsFile()!];
    }
  }

  for (let i = 0; i < webkitEntries.length; i++) {
    const entry = webkitEntries[i];
    const newFiles = await readFSEntries(entry);
    files = [...files, ...newFiles];
  }
  return files;
}
