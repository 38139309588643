import { PropertyObject } from "@aletiq/types";

export const propertyKeys = {
  all: ["property"] as const,
  byObjectType: (objectType: PropertyObject) => {
    const objectTypeTypeKeys = [...propertyKeys.all, objectType] as const;
    if (objectType === "product") {
      return [...objectTypeTypeKeys, "projects"] as const;
    }
    if (objectType === "passport") {
      return [...objectTypeTypeKeys, "passports"] as const;
    }
    return objectTypeTypeKeys;
  },
  byNameId: (objectType: PropertyObject, nameId: string) =>
    [...propertyKeys.byObjectType(objectType), nameId] as const,
  defaultDisplay: (objectType: PropertyObject) =>
    [...propertyKeys.byObjectType(objectType), "default-display"] as const,
  usedIn: (objectType: PropertyObject, nameId: string) =>
    [...propertyKeys.byNameId(objectType, nameId), "property-used-in"] as const,
  optionUsedIn: (
    objectType: PropertyObject,
    propertyNameId: string,
    options: number[]
  ) =>
    [
      ...propertyKeys.byNameId(objectType, propertyNameId),
      "property-option-used-in",
      options,
    ] as const,
};
