import { OperationBill } from "@aletiq/types";
import { useQuery, UseQueryResult } from "react-query";
import useApi from "../../../../app/useApi";
import { projectKeys } from "../../hooks/queries";

export default function useProjectBills(
  projectId: number
): UseQueryResult<OperationBill[]> {
  const api = useApi();
  return useQuery(projectKeys.billsById(projectId), () =>
    api.operations.listProjectOperations(projectId)
  );
}
