import { Spinner, SpinnerSize } from "@blueprintjs/core";
import React from "react";
import { useTranslations } from "../../util";
import { Subtitle } from "../Subtitle";
import styles from "./TreeItemLoading.module.scss";

export default function TreeItemLoading() {
  const tr = useTranslations();

  return (
    <div className={styles.spinner}>
      <Spinner size={SpinnerSize.SMALL} />
      &nbsp;
      <Subtitle text={tr.translateAsString("part.details.fetching.bom")} />
    </div>
  );
}
