import React from "react";
import { useTranslations } from "../../../util";
import TreeButton, { TreeButtonProps } from "./TreeButton";

export default function IgnoreButton(props: TreeButtonProps) {
  const tr = useTranslations();

  return (
    <TreeButton
      {...props}
      isDense
      color="primary"
      view="flat"
      icon={"disable"}
      text={tr.translateAsString("generic.action.ignore")}
    />
  );
}
