import React from "react";
import { Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";

export default function DeleteNomenclatureDialog(props: {
  onDelete: () => void;
  onClose: () => void;
}) {
  const { onDelete, onClose } = props;

  const tr = useTranslations();

  const handleSubmit = () => onDelete();

  return (
    <Dialog
      isOpen
      isDense
      intent="danger"
      icon="trash"
      title={tr.translateAsString("project.dialog.delete.part.bom.title")}
      onPrimaryClick={handleSubmit}
      onClose={onClose}
    >
      {tr.translate("project.dialog.delete.part.bom.subtitle")}
    </Dialog>
  );
}
