import { useEffect } from "react";
import { CRISP_WEBSITE_ID } from "../../config";

export function useCrispChat() {
  useEffect(() => {
    if (CRISP_WEBSITE_ID && CRISP_WEBSITE_ID !== "") {
      // Include the Crisp code here, without the <script></script> tags
      (window as any)["$crisp"] = [];
      (window as any)["CRISP_WEBSITE_ID"] = CRISP_WEBSITE_ID;

      (function () {
        const d = document;
        const s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = true;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }
  }, []);
}
