import { Part, PartFormat, PartIteration, PartType } from "@aletiq/types";

export type PartTree = {
  name: string;
  part: null | {
    id: number;
    type: PartType;
    isStandard: boolean;
    name: string;
    iteration: number;
    isApproved: boolean;
    format: PartFormat;
  };
  isIgnored: boolean;
  pathId: string;
  components: PartTree[];
};

export type PartBomAddition = {
  parentId: number;
  partId: number;
  name: string;
  iteration: number;
};

export type PartBomDeletion = {
  parentId: number;
  partId: number;
  name: string;
};

export type PartBomIgnore = {
  parentId: number;
  name: string;
  isIgnored: boolean;
};

export type PartMutationList = {
  additions: PartBomAddition[];
  deletions: PartBomDeletion[];
  ignores: PartBomIgnore[];
};

export type PartMutation = {
  addition?: PartBomAddition;
  deletion?: PartBomDeletion;
  ignore?: PartBomIgnore;
};

export function makeEmptyNode() {
  return {
    name: "",
    part: null,
    pathId: "",
    isIgnored: false,
    components: [],
  };
}

export function makePartNode(
  name: string,
  part: Part | null | undefined,
  iteration: PartIteration | null | undefined,
  isIgnored: boolean,
  pathId: string,
  components: PartTree[]
) {
  return {
    name,
    part:
      part !== null && part !== undefined
        ? {
            id: part.id,
            name: part.name,
            type: part.type,
            isStandard: part.isStandard,
            iteration: iteration?.number ?? part.lastIteration?.number,
            isApproved: iteration?.state === "approved",
            format: part.format,
          }
        : null,
    isIgnored,
    pathId,
    components,
  };
}
