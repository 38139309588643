import { useQuery } from "react-query";
import useApi from "../../app/useApi";
import { userKeys } from "../../features/administration/hooks/queries";

export default function useCurrentUser(enabled: boolean = true) {
  const api = useApi();
  return useQuery(userKeys.current(), () => api.auth.getCurrentUser(), {
    enabled: enabled,
  });
}
