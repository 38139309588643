import {
  ContentBlock,
  convertFromRaw,
  DraftBlockType,
  EditorState,
  RawDraftContentBlock,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from "draft-js";
import { assign, defaultOptions } from "./helper";
import { InlineLexer } from "./inlineLexer";
import { ParserOption, Token, TokenLink } from "./types";

/**
 * Parsing & Compiling
 */

export class Parser {
  tokens: Token[];
  token?: Token;
  options: ParserOption;
  inline: InlineLexer;
  entityMap: { [key: string]: RawDraftEntity<{ [key: string]: any }> };

  constructor(options: ParserOption) {
    this.tokens = [];
    this.token = undefined;
    this.options = assign({}, options || defaultOptions);
    this.inline = new InlineLexer({}, options);
    this.entityMap = {};
  }

  /**
   * Parse Loop
   */
  parse = (src: { tokens: Token[]; tokensLinks: TokenLink }): EditorState => {
    this.inline = new InlineLexer(src.tokensLinks, this.options);
    this.tokens = src.tokens.slice().reverse();
    const blocks = [] as RawDraftContentBlock[];

    while (this.next()) {
      blocks.push(this.tok());
    }
    const out = EditorState.createWithContent(
      convertFromRaw({ blocks, entityMap: this.entityMap })
    );

    return out;
  };
  /**
   * Next Token
   */
  next = () => {
    return (this.token = this.tokens.pop());
  };
  /**
   * Preview Next Token
   */
  peek = () => {
    return this.tokens[this.tokens.length - 1] || 0;
  };
  /**
   * Parse Text Tokens
   */
  parseText = () => {
    let body = this.token?.text ?? "" ?? "";

    while (this.peek().type === "text") {
      body += `\n${this.next()?.text}`;
    }

    return this.inline.parse(body);
  };
  /**
   * Parse Current Token
   */
  tok = (): RawDraftContentBlock => {
    switch (this.token?.type) {
      case "space": {
        return createStandardContentBlock({ text: "", type: "unstyled" });
      }

      case "heading": {
        const { text, inlineStyleRanges, entityRanges, entityMap } =
          this.inline.parse(this.token?.text ?? "");
        const numberToEnglish: Record<number, string> = {
          1: "one",
          2: "two",
          3: "three",
          4: "four",
          5: "five",
          6: "six",
        };
        this.entityMap = { ...this.entityMap, ...entityMap };
        return createStandardContentBlock({
          text,
          inlineStyleRanges,
          entityRanges,
          type: `header-${numberToEnglish[this.token?.depth ?? 1]}`,
        });
      }

      case "code": {
        return createStandardContentBlock({
          text: this.token?.text ?? "",
          type: "code-block",
        });
      }

      case "blockquote": {
        const { text, inlineStyleRanges, entityRanges, entityMap } =
          this.inline.parse(this.token?.text ?? "");
        this.entityMap = { ...this.entityMap, ...entityMap };
        return createStandardContentBlock({
          text,
          inlineStyleRanges,
          entityRanges,
          type: "blockquote",
        });
      }

      case "orderedList": {
        const { text, inlineStyleRanges, entityRanges, entityMap } =
          this.inline.parse(this.token?.text ?? "");
        this.entityMap = { ...this.entityMap, ...entityMap };
        return createStandardContentBlock({
          text,
          type: "ordered-list-item",
          inlineStyleRanges,
          entityRanges,
        });
      }

      case "unOrderedList": {
        const { text, inlineStyleRanges, entityRanges, entityMap } =
          this.inline.parse(this.token?.text ?? "");
        this.entityMap = { ...this.entityMap, ...entityMap };
        return createStandardContentBlock({
          text,
          type: "unordered-list-item",
          inlineStyleRanges,
          entityRanges,
        });
      }

      case "paragraph": {
        const { text, inlineStyleRanges, entityRanges, entityMap } =
          this.inline.parse(this.token?.text ?? "");
        this.entityMap = { ...this.entityMap, ...entityMap };
        return createStandardContentBlock({
          text,
          type: "paragraph",
          inlineStyleRanges,
          entityRanges,
        });
      }

      case "text": {
        const { text, inlineStyleRanges, entityRanges, entityMap } =
          this.parseText();
        this.entityMap = { ...this.entityMap, ...entityMap };
        return createStandardContentBlock({
          text,
          type: "unstyled",
          inlineStyleRanges,
          entityRanges,
        });
      }
      default:
        return createStandardContentBlock({ text: "", type: "unstyled" });
    }
  };
}

const createStandardContentBlock = (value: {
  text: string;
  type: DraftBlockType;
  depth?: number;
  inlineStyleRanges?: RawDraftInlineStyleRange[];
  entityRanges?: RawDraftEntityRange[];
}): RawDraftContentBlock => {
  const keyGen = new ContentBlock();

  const { type, text, inlineStyleRanges, depth, entityRanges } = value;

  return {
    key: keyGen.getKey(),
    type,
    text,
    depth: depth ?? 0,
    inlineStyleRanges: inlineStyleRanges ?? [],
    entityRanges: entityRanges ?? [],
  };
};
