import { PartIterationId } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { pdmKeys } from "./queries";

export default function usePartUsedIn(partId: number) {
  const api = useApi();
  return useQuery(pdmKeys.usedIn(partId), async () => {
    const usedIns = await api.pdm.getPartUsedIn(partId);
    const partIds: number[] = collectUniquePartIds(usedIns);
    const partIterationMap: Record<number, number[]> = groupByPartId(usedIns);
    return Promise.all(
      partIds.map(async (partId) => ({
        part: await api.pdm.getPart(partId),
        iterations: partIterationMap[partId] ?? [],
      }))
    );
  });
}

function groupByPartId(usedIns: PartIterationId[]) {
  const partIterationMap: Record<number, number[]> = {};
  usedIns.forEach((iterationId) => {
    let iterations = partIterationMap[iterationId.part];
    if (!iterations) {
      iterations = [];
      partIterationMap[iterationId.part] = iterations;
    }
    iterations.push(iterationId.iteration);
  });
  return partIterationMap;
}

function collectUniquePartIds(usedIns: PartIterationId[]) {
  const partIds: number[] = [];
  usedIns.forEach((iterationId) => {
    if (!partIds.includes(iterationId.part)) partIds.push(iterationId.part);
  });
  return partIds;
}
