import { Spinner } from "@blueprintjs/core";
import React from "react";
import { Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useDocumentsAccess } from "../../hooks";
import {
  useDefaultAccess,
  useReducerAccess,
  useUpdateDocumentsAccess,
} from "./hooks";
import mergeStateAndEntityAccess from "./mergeStateAndEntityAccess";
import ShareDocumentDialogSelection from "./ShareEntitiesDialogSelection";
import styles from "./ShareDocumentsDialog.module.scss";

export default function ShareDocumentsDialog(props: {
  documentIds: number[];
  onClose: () => void;
}) {
  const { documentIds, onClose } = props;
  const tr = useTranslations();

  const { data: documentsAccess, isLoading } = useDocumentsAccess(documentIds);
  const { mutate: updateDocumentsAccessRight, isLoading: isSubmitting } =
    useUpdateDocumentsAccess();
  const [accessState, dispatch] = useReducerAccess();

  const handleSubmit = () => {
    const newDocumentsAccesses = mergeStateAndEntityAccess(
      documentIds,
      documentsAccess,
      accessState
    );
    updateDocumentsAccessRight(newDocumentsAccesses, {
      onSettled: () => {
        onClose();
      },
    });
  };

  const { defaultUsers, defaultGroups } = useDefaultAccess(
    documentIds,
    documentsAccess
  );

  const defaultPublicDocuments = documentsAccess
    .flatMap((d) => d)
    .filter((access) => access.type === "open").length;

  return (
    <Dialog
      isOpen
      onClose={onClose}
      icon="people"
      title={tr.translateAsString("document.dialog.shared-documents.title")}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={onClose}
      className={styles.dialog}
      submitting={isSubmitting}
      enforceFocus={false}
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <ShareDocumentDialogSelection
          total={documentIds.length}
          defaultPublicDocuments={defaultPublicDocuments}
          defaultUsers={defaultUsers}
          defaultGroups={defaultGroups}
          accessState={accessState}
          accessDispatch={dispatch}
        />
      )}
    </Dialog>
  );
}
