import { Field } from "@aletiq/design-system";
import { Part, PartIteration } from "@aletiq/types";
import React, { useState } from "react";
import { Callout, Dialog } from "../../../../components";
import PartLink from "../../../../components/link/PartLink";
import { useTranslations } from "../../../../util";
import { useUpdatePartProjectBom } from "../../hooks";
import styles from "./LinkNomenclatureDialog.module.scss";
import { PartSelectMenu } from "./PartSelectMenu";
import RevisionSelectMenu from "./RevisionSelectMenu/RevisionSelectMenu";

export default function LinkNomenclatureDialog(props: {
  projectId: number;
  definitionIdx: number;
  onClose: () => void;
}) {
  const { projectId, definitionIdx, onClose } = props;

  const tr = useTranslations();

  const [part, setPart] = useState<Part | undefined>();
  const [revision, setRevision] = useState<PartIteration | undefined>();
  const [isLinked, setIsLinked] = useState<boolean | undefined>();

  const { mutate: updatePartProjectBom, isLoading } = useUpdatePartProjectBom(
    projectId,
    definitionIdx
  );

  const handleSubmit = () => {
    if (!part || !revision) {
      return;
    }
    updatePartProjectBom(
      { part: part.id, iteration: revision.number },
      { onSettled: onClose }
    );
  };

  const handlePartAssign = (part: Part, isLinked: boolean) => {
    setPart(part);
    setIsLinked(isLinked);
    setRevision(undefined);
  };

  const handleRevisionAssign = (revision: PartIteration) => {
    setRevision(revision);
  };

  return (
    <Dialog
      isOpen
      icon="cube-add"
      title={tr.translateAsString("project.dialog.link.part.bom.title")}
      onClose={onClose}
      onPrimaryClick={handleSubmit}
      submitting={isLoading}
      disablePrimary={part === undefined || revision === undefined}
    >
      <p>{tr.translate("project.dialog.link.part.bom.subtitle")}</p>
      <div>
        <Field isMandatory label={tr.translateAsString("generic.label.part")}>
          <PartSelectMenu
            onAssignPart={handlePartAssign}
            assignedPart={part}
            projectId={projectId}
            allowedTypes={["assembly", "part"]}
          />
        </Field>

        {part && (
          <Field
            isMandatory
            label={tr.translateAsString("generic.label.revision")}
          >
            <RevisionSelectMenu
              isFilled
              onAssignRevision={handleRevisionAssign}
              assignedRevision={revision}
              part={part}
            />
          </Field>
        )}
      </div>
      {isLinked === false && (
        <Callout
          className={styles.link_callout}
          icon="new-link"
          title={tr.translateAsString("project.actions.create.link.title", {
            type: "article",
          })}
        >
          {tr.translate("project.actions.create.link.description", {
            type: "article",
            part: <PartLink hasIcon part={part} />,
          })}
        </Callout>
      )}
    </Dialog>
  );
}
