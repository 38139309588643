import {
  AnchorButton,
  ButtonGroup,
  EditableText,
  H3,
  Icon,
  Tooltip,
} from "@aletiq/design-system";
import { ProcessModelSpec } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../../util";
import styles from "./Editor.module.scss";

export default function ModelEditorHeader(props: {
  liveModel: ProcessModelSpec;
  onEditTitle: (title: string) => void;
  onSaveChanges: () => void;
  onDiscardChange: () => void;
  validationFailed: boolean;
  disableSubmit: boolean;
}) {
  const tr = useTranslations();

  const {
    liveModel,
    onEditTitle,
    onDiscardChange,
    onSaveChanges,
    validationFailed,
    disableSubmit,
  } = props;

  return (
    <H3 className={styles.model_header}>
      <Icon inline icon="duplicate" />
      <EditableText
        placeholder={
          validationFailed
            ? tr.translateAsString("workflow.model.title.mandatory")
            : tr.translateAsString("workflow.model.title.edit")
        }
        intent={
          validationFailed && liveModel.title === "" ? "danger" : undefined
        }
        value={liveModel.title}
        onChange={onEditTitle}
      />
      <ButtonGroup isDense>
        <Tooltip
          content={tr.translateAsString("generic.action.save")}
          isDisabled={disableSubmit}
        >
          <AnchorButton
            isDense
            icon="tick"
            intent="success"
            onClick={onSaveChanges}
            isDisabled={disableSubmit}
          />
        </Tooltip>
        <Tooltip content={tr.translateAsString("generic.action.cancel")}>
          <AnchorButton isDense icon="cross" onClick={onDiscardChange} />
        </Tooltip>
      </ButtonGroup>
    </H3>
  );
}
