import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { Button, Table } from "../../../../components";
import { ColumnOptionConfig, useSort } from "../../../../hooks";
import {
  ExpandTree,
  toggleExpandAt,
  TreePath,
  useTranslations,
} from "../../../../util";
import { AddNomenclatureComponentsDialog } from "../../dialogs";
import { ProductTree, useProjectExpandableTree } from "../hooks";
import ComponentsBatchActionHeader from "./ComponentsBatchActionHeader";
import styles from "./ProjectComponents.module.scss";
import useProjectComponentsColumns, {
  ProductComponentsColumn,
  ProductComponentSortableColumn,
} from "./useProjectComponentsColumns";
import { ResolvedComponent } from "./useProjectComponentsState";

export default function ProjectComponentsTable(props: {
  projectId: number;
  definitionIdx: number;
  components: ResolvedComponent[];
  search?: string;
  canAddComponent?: boolean;
  canEditComponent?: boolean;
  canDeleteComponent?: boolean;
  columnConfig: ColumnOptionConfig<ProductComponentsColumn>;
  isLoading?: boolean;
}) {
  const {
    projectId,
    definitionIdx,
    components,
    search,
    canEditComponent,
    canDeleteComponent,
    canAddComponent,
    isLoading,
    columnConfig,
  } = props;
  const tr = useTranslations();

  const [dialog, setDialog] = useState(false);
  const [expandTrees, setExpandTrees] = useState<ExpandTree[]>([]);

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const handleOpenDialog = () => {
    setDialog(true);
  };

  const {
    sortKey,
    sortDirection,
    onSortKeyChange,
    sortedList: sortedComponents,
  } = useSort<ResolvedComponent, ProductComponentSortableColumn>(
    components,
    "projectName"
  );
  const visibleProjects = useProjectExpandableTree(
    expandTrees,
    sortedComponents,
    {
      productId: projectId,
      definitionIdx,
    }
  );

  const visibleMemoProjects = useDeepCompareMemoize(visibleProjects);

  const handleToggleExpand = useMemo(
    () => (path: TreePath[]) => (expanded: boolean) =>
      setExpandTrees((t) => toggleExpandAt(t, path, expanded)),
    []
  );

  useEffect(() => setExpandTrees([]), [definitionIdx]);

  const columns = useProjectComponentsColumns({
    config: columnConfig,
    canEditQuantity: canEditComponent,
    canDelete: canDeleteComponent,
    onToggleExpand: handleToggleExpand,
    definitionIdx,
  });

  const handleRenderBatchActionsHeader =
    canEditComponent || canDeleteComponent
      ? (selectedComponents: ProductTree[]) => (
          <ComponentsBatchActionHeader
            selectedComponents={selectedComponents}
            canEditComponent={canEditComponent}
            canDeleteComponent={canDeleteComponent}
          />
        )
      : undefined;

  const isRowUnselectable = useCallback(
    (productTree: ProductTree) =>
      //row is loading / incomplete / empty
      productTree.type !== "project",
    []
  );

  return (
    <>
      <Table
        isFetching={isLoading}
        columns={columns}
        data={visibleMemoProjects ?? []}
        searchString={search}
        renderBatchActionsHeader={handleRenderBatchActionsHeader}
        isRowUnselectable={isRowUnselectable}
        sortOptions={{
          sortState: {
            key: sortKey,
            direction: sortDirection,
          },
          onSort: onSortKeyChange,
        }}
        noData={
          <div className={styles.no_data}>
            {tr.translate(
              search === ""
                ? "product.bom.components.no-data"
                : "product.bom.components.no-data.filtered"
            )}
            <Button
              isDense
              isDisabled={!canAddComponent}
              onClick={handleOpenDialog}
              icon="new-link"
              intent="primary"
              className={styles.create_button}
            >
              {tr.translate("product.bom.components.new")}
            </Button>
          </div>
        }
      />

      {dialog && (
        <AddNomenclatureComponentsDialog
          projectId={projectId}
          definitionIdx={definitionIdx}
          projectComponents={components}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}
