var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// src/activities.ts
var ActivityClient = class {
  constructor(http) {
    this.http = http;
  }
  async getActivities(timeStamp = "") {
    return this.http.getJson(timeStamp === "" ? "/activity" : `/activity?cursor=${encodeURIComponent(timeStamp)}`);
  }
  async getEntityActivities(entityId) {
    return this.http.getJson(`/activity?id=${encodeURIComponent(JSON.stringify(entityId.id))}&type=${entityId.type}`);
  }
  async exportActivities(entity) {
    return this.http.get(`/activities/export?type=${entity.type}&id=${entity.id}`).then((data) => data.blob());
  }
};

// src/getQueryUrlFromObject.ts
var getQueryUrlFromObject = (params) => {
  const query = Object.keys(params).filter((key) => {
    const value = params[key];
    if (Array.isArray(value) && value.length === 0) {
      return false;
    }
    return value !== "" && value !== void 0 && value !== null;
  }).map((key) => {
    const value = params[key];
    const type = typeof params[key];
    if (type === "boolean" || type === "number" || type === "string") {
      return { key, value: encodeURIComponent(value) };
    }
    if (Array.isArray(value) && value.length > 0 && (typeof value[0] === "string" || typeof value[0] === "number")) {
      return { key, value: encodeURIComponent(value.join(",")) };
    }
    return { key, value: encodeURIComponent(JSON.stringify(value)) };
  }).map(({ key, value }) => `${key}=${value}`).join("&");
  return query ? `?${query}` : "";
};

// src/auth.ts
var AuthClient = class {
  constructor(http) {
    this.http = http;
  }
  async getCurrentUser() {
    return this.http.getJson("/users/current");
  }
  async listUsers(withEmail = false, withDeactivated = false) {
    const queryString = getQueryUrlFromObject({
      email: withEmail,
      deactivated: withDeactivated
    });
    return await this.http.getJson(`/users${queryString}`);
  }
  async createUser(spec) {
    const r = await this.http.post("/users", JSON.stringify(spec));
    return r.json();
  }
  async setUserName(userId, name) {
    await this.http.post(`/users/${userId}/name`, JSON.stringify(name));
  }
  async setUserRole(userId, role) {
    await this.http.post(`/users/${userId}/role`, JSON.stringify(role));
  }
  async setUserEmail(userId, email) {
    await this.http.post(`/users/${userId}/email`, JSON.stringify(email));
  }
  async setUserPassword(userId, password) {
    await this.http.post(`/users/${userId}/password`, JSON.stringify(password));
  }
};

// src/comments.ts
var CommentClient = class {
  constructor(http) {
    this.http = http;
  }
  async getComments(timeStamp = "") {
    return this.http.getJson(timeStamp === "" ? "/comments" : `/comments?cursor=${encodeURIComponent(timeStamp)}`);
  }
  async getEntityComments(entityId) {
    return this.http.getJson(`/comments?id=${encodeURIComponent(JSON.stringify(entityId.id))}&type=${entityId.type}`);
  }
  async createComment(entityId, content, mentions) {
    await this.http.post("/comments", JSON.stringify({
      entity: entityId,
      content,
      mentions
    }));
  }
  async updateComment(commentId, content, mentions) {
    await this.http.put(`/comments/${commentId}`, JSON.stringify({ content, mentions }));
  }
  async deleteComment(commentId) {
    await this.http.delete(`/comments/${commentId}`);
  }
};

// src/helpers.ts
function catchNotFound(error) {
  if (error.status === 404) {
    return Promise.resolve(void 0);
  }
  return Promise.reject(error);
}
var APIError = class extends Error {
  constructor(reason, status, method, url2) {
    super(`${method} ${url2} : ${reason}`);
    this.status = status;
  }
  static async responseOrError(response, method, url2) {
    if (response.ok) {
      return response;
    }
    const reason = await response.text();
    throw new APIError(reason, response.status, method, url2);
  }
};
function url(strings, ...values) {
  return String.raw(strings, ...values.map((v) => encodeURIComponent(v)));
}
var HTTPClient = class {
  constructor(auth0, apiUrl) {
    this.auth0 = auth0;
    this.apiUrl = apiUrl;
  }
  async getConfig() {
    if (this.auth0.isAuthenticated) {
      const token = await this.auth0.getAccessTokenSilently();
      return accessConfig(token);
    }
    throw new Error("User not authenticated");
  }
  async get(path) {
    const config = await this.getConfig();
    return getUrl(this.apiUrl, config, path);
  }
  async getJson(path) {
    return this.get(path).then((resp) => resp.json());
  }
  async post(path, body, headers) {
    const method = "POST";
    const config = await this.getConfig();
    const response = await fetch(`${this.apiUrl}${path}`, __spreadProps(__spreadValues({}, config), {
      headers: __spreadValues(__spreadValues({}, config.headers), headers),
      body,
      method
    }));
    return await APIError.responseOrError(response, method, path);
  }
  async put(path, body) {
    const method = "PUT";
    const config = await this.getConfig();
    const response = await fetch(`${this.apiUrl}${path}`, __spreadProps(__spreadValues({}, config), {
      body,
      method
    }));
    return await APIError.responseOrError(response, method, path);
  }
  async delete(path, body) {
    const method = "DELETE";
    const config = await this.getConfig();
    const response = await fetch(`${this.apiUrl}${path}`, __spreadProps(__spreadValues({}, config), {
      body,
      method
    }));
    return await APIError.responseOrError(response, method, path);
  }
  async openXhr(method, path) {
    const config = await this.getConfig();
    const xhr = new XMLHttpRequest();
    xhr.open(method, `${this.apiUrl}${path}`);
    Object.entries(config.headers).forEach(([key, value]) => xhr.setRequestHeader(key, value));
    return xhr;
  }
};
async function getUrl(apiUrl, config, urlPath) {
  const response = await fetch(`${apiUrl}${urlPath}`, config);
  return await APIError.responseOrError(response, "GET", urlPath);
}
function accessConfig(accessToken) {
  return { headers: { Authorization: "Bearer " + accessToken } };
}

// src/documents.ts
var DocumentClient = class {
  constructor(http) {
    this.http = http;
  }
  async createFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post("/files", formData).then((resp) => resp.json());
  }
  async createDocument(spec, content, onProgress) {
    const formData = new FormData();
    formData.append("spec", JSON.stringify(spec));
    formData.append("file", content);
    const xhr = await this.http.openXhr("POST", `/documents`);
    if (onProgress) {
      xhr.upload.onprogress = (ev) => onProgress(ev.loaded / ev.total);
    }
    const promise = new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          return resolve(response);
        }
        reject();
      };
      xhr.onerror = () => {
        reject();
      };
    });
    xhr.send(formData);
    return promise;
  }
  async updateDocument(documentId, revisionSpec, file, onProgress) {
    const formData = new FormData();
    formData.append("metadata", JSON.stringify(revisionSpec));
    formData.append("file", file);
    const xhr = await this.http.openXhr("POST", `/documents/${documentId}/content`);
    xhr.responseType = "text";
    if (onProgress) {
      xhr.upload.onprogress = (ev) => onProgress(ev.loaded / ev.total);
    }
    const promise = new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status === 200) {
          return resolve();
        }
        reject();
      };
      xhr.onerror = () => {
        reject();
      };
    });
    xhr.send(formData);
    return promise;
  }
  updateDocumentRevisionSpec(documentId, revisionId, spec) {
    return this.http.put(`/documents/${documentId}/history/${revisionId}/state`, JSON.stringify(spec));
  }
  async getDocumentDownloadToken(documentId, revision, fileName) {
    const token = this.http.getJson(revision ? `/documents/${documentId}/revisions/${revision}/file` : `/documents/${documentId}/file`);
    if (fileName) {
      return `${token}/${fileName}`;
    }
    return token;
  }
  async getDocumentPreviewToken(documentId, revision, fileName) {
    const token = await this.http.getJson(revision ? `/documents/${documentId}/revisions/${revision}/preview` : `/documents/${documentId}/preview`);
    return `${token}/${fileName}`;
  }
  async getDocumentThumbnail(documentId) {
    try {
      const link = await this.http.getJson(url`/documents/${documentId}/thumbnail`);
      if (!link) {
        return void 0;
      }
      const response = await this.http.get(link);
      return await response.blob();
    } catch (err) {
      return void 0;
    }
  }
  async getDocumentDownloadBlob(token) {
    const response = await this.http.get(token);
    return await response.blob();
  }
  async getDocumentDownloadText(token) {
    const response = await this.http.get(token);
    return await response.text();
  }
  async renameDocument(document, newName) {
    await this.http.put(`/documents/${document}/name`, JSON.stringify(newName));
  }
  async setDocumentOwner(document, user) {
    await this.http.put(`/documents/${document}/owner`, JSON.stringify(user));
  }
  async listDocuments(params = {}) {
    const qs = getQueryUrlFromObject(params);
    return this.http.getJson(`/documents${qs}`);
  }
  async getDocument(documentId) {
    return this.http.getJson(`/documents/${documentId}`);
  }
  async getDocumentByFileName(fileName) {
    const encoded = encodeURIComponent(fileName);
    return this.http.getJson(`/documents/by-file-name/${encoded}`).catch(catchNotFound);
  }
  async getDocumentRevisionSignatures(documentId, revisionId) {
    return await this.http.getJson(`/documents/${documentId}/history/${revisionId}/signatures`);
  }
  async listDocumentRelatedDefinitions(documentId, revisionId, params = {}) {
    const qs = getQueryUrlFromObject(params);
    return await this.http.getJson(`/documents/${documentId}/revisions/${revisionId}/definitions${qs}`);
  }
  async listDocumentRelatedOperations(documentId, revisionId, params = {}) {
    const qs = getQueryUrlFromObject(params);
    return await this.http.getJson(`/documents/${documentId}/revisions/${revisionId}/operations${qs}`);
  }
  async signDocumentRevision(documentId, revisionId, role) {
    await this.http.post(`/documents/${documentId}/history/${revisionId}/signatures`, JSON.stringify(role));
  }
  async deleteDocument(documentId) {
    return this.http.delete(`/documents/${documentId}/`);
  }
  async deleteDocumentDraftRevision(documentId, revisionId) {
    return this.http.delete(`/documents/${documentId}/history/${revisionId}`);
  }
  async listDocumentProjects(document) {
    return this.http.getJson(`/documents/${document}/projects`);
  }
  async updateDocumentProjects(document, projects) {
    await this.http.put(`/documents/${document}/projects`, JSON.stringify(projects));
  }
  async addProjectDocuments(projectId, docs) {
    await this.http.post(`/projects/${projectId}/documents`, JSON.stringify(docs));
  }
  async getDocumentAccess(documentId) {
    return this.http.getJson(`/documents/${documentId}/access`);
  }
  async updateDocumentAccess(documentId, newAccess) {
    return this.http.put(`/documents/${documentId}/access`, JSON.stringify(newAccess));
  }
  async downloadArchiveByDocumentIds(documentIds) {
    const queryString = getQueryUrlFromObject({ documentIds });
    return this.http.getJson(`/documents/files/export${queryString}`);
  }
  async updateDocumentProperties(documentId, propertyUpdates) {
    return this.http.put(`/documents/${documentId}/properties`, JSON.stringify(propertyUpdates));
  }
  async exportDocumentList() {
    return this.http.get("/documents/export").then((response) => response.blob());
  }
  async listDocumentCitations(documentId) {
    return this.http.getJson(`/documents/${documentId}/citations`);
  }
  async listDocumentCitedBy(documentId) {
    return this.http.getJson(`/documents/${documentId}/citations/used-in`);
  }
  async addDocumentCitations(citations) {
    await this.http.post(`/documents/citations`, JSON.stringify(citations));
  }
  async deleteDocumentCitation(citation) {
    await this.http.delete(`/documents/citations`, JSON.stringify(citation));
  }
};

// src/integration.ts
var IntegrationClient = class {
  constructor(http) {
    this.http = http;
  }
  async getIntegrationsReports() {
    return this.http.getJson("/integrations/reports");
  }
  async updateReportTreatedStatus(id, isTreated) {
    return this.http.put(`/integrations/reports/${id}/treated?treated=${isTreated}`);
  }
};

// src/operations.ts
var OperationClient = class {
  constructor(http) {
    this.http = http;
  }
  async listProjectOperations(projectId) {
    return await this.http.getJson(url`/projects/${projectId}/operation-bills`);
  }
  async createProjectOperationBill(projectId, spec) {
    const resp = await this.http.post(url`/projects/${projectId}/operation-bills`, JSON.stringify(spec));
    return await resp.json();
  }
  async deleteProjectOperationBill(projectId, billId) {
    await this.http.delete(url`/projects/${projectId}/operation-bills/${billId}`);
  }
  async validateProjectOperationBill(projectId, billId) {
    await this.http.post(url`/projects/${projectId}/operation-bills/${billId}/validate`);
  }
  async draftProjectOperationBill(projectId, billId) {
    await this.http.post(url`/projects/${projectId}/operation-bills/${billId}/draft`);
  }
  async renameProjectOperationBill(projectId, billId, name) {
    await this.http.put(url`/projects/${projectId}/operation-bills/${billId}/index`, JSON.stringify(name));
  }
  async updateProjectOperationBillDescription(projectId, billId, description) {
    await this.http.put(url`/projects/${projectId}/operation-bills/${billId}/description`, JSON.stringify(description));
  }
  async addProjectOperation(projectId, billId, spec) {
    await this.http.post(url`/projects/${projectId}/operation-bills/${billId}/operations`, JSON.stringify(spec));
  }
  async deleteProjectOperation(projectId, billId, operationNumber) {
    await this.http.delete(url`/projects/${projectId}/operation-bills/${billId}/operations/${operationNumber}`);
  }
  async updateProjectOperation(projectId, billId, oldOperationNumber, spec) {
    await this.http.post(url`/projects/${projectId}/operation-bills/${billId}/operations/${oldOperationNumber}`, JSON.stringify(spec));
  }
  async addProjectOperationDocument(projectId, billId, operationNumber, documentId, revision) {
    await this.http.post(url`/projects/${projectId}/operation-bills/${billId}/operations/${operationNumber}/documents`, JSON.stringify({ id: documentId, revision }));
  }
  async updateProjectOperationDocument(projectId, billId, operationNumber, documentId, revision) {
    await this.http.put(url`/projects/${projectId}/operation-bills/${billId}/operations/${operationNumber}/documents/${documentId}`, JSON.stringify(revision));
  }
  async deleteProjectOperationDocument(projectId, billId, operationNumber, documentId) {
    await this.http.delete(url`/projects/${projectId}/operation-bills/${billId}/operations/${operationNumber}/documents/${documentId}`);
  }
  async addProjectOperationTool(projectId, billId, operationNumber, toolId, version) {
    await this.http.post(url`/projects/${projectId}/operation-bills/${billId}/operations/${operationNumber}/tools`, JSON.stringify({ id: toolId, version }));
  }
  async updateProjectOperationTool(projectId, billId, operationNumber, toolId, version) {
    await this.http.put(url`/projects/${projectId}/operation-bills/${billId}/operations/${operationNumber}/tools/${toolId}`, JSON.stringify(version));
  }
  async deleteProjectOperationTool(projectId, billId, operationNumber, toolId) {
    await this.http.delete(url`/projects/${projectId}/operation-bills/${billId}/operations/${operationNumber}/tools/${toolId}`);
  }
};

// src/passports.ts
var PassportClient = class {
  constructor(http) {
    this.getPassport = (passport) => this.http.getJson(`/passports/${passport}`);
    this.getPassportFileDownloadToken = (passportId, fileId) => this.http.getJson(`/passports/${passportId}/files/${fileId}`);
    this.getPassportFilePreviewToken = (passportId, fileId) => this.http.getJson(`/passports/${passportId}/files/${fileId}/preview`);
    this.setPassportConfiguration = (passport, fieldId, valueIds) => this.http.put(`/passports/${passport}/configuration/${fieldId}`, JSON.stringify(valueIds));
    this.http = http;
  }
  async getPassports(query) {
    const qs = getQueryUrlFromObject(query);
    return this.http.getJson(`/passports${qs}`);
  }
  async listPassportsPage(queryParams = {}) {
    const qs = getQueryUrlFromObject(queryParams);
    return this.http.getJson(`/passports${qs}`);
  }
  async createPassport(passport) {
    const {
      name,
      description,
      parent,
      projectIteration,
      parentDefinition,
      config: configSpec,
      articleBill
    } = passport;
    const entries = Object.entries(configSpec).filter(([, v]) => !(v === null)).map(([key, value]) => [Number.parseInt(key), value]);
    return this.http.post(`/passports`, JSON.stringify({
      name,
      description,
      parent,
      projectIteration,
      parentDefinition,
      config: entries,
      articleBill
    })).then((resp) => resp.json());
  }
  async deletePassport(passportId) {
    return this.http.delete(`/passports/${passportId}`);
  }
  async updatePassport(passportId, updates) {
    return this.http.put(`/passports/${passportId}`, JSON.stringify(updates));
  }
  async listPassportComponents(passport) {
    return this.http.getJson(`/passports/${passport}/components`);
  }
  async listPassportUsedIn(passportId) {
    return this.http.getJson(`/passports/${passportId}/used-in`);
  }
  async addPassportComponent(passport, componentPassport) {
    const response = await this.http.post(`/passports/${passport}/components`, JSON.stringify(componentPassport));
    return response.json();
  }
  async updatePassportComponent(passport, component, componentPassport) {
    const response = await this.http.put(`/passports/${passport}/components/${component}`, JSON.stringify(componentPassport));
    return response.json();
  }
  async deletePassportComponent(passport, component) {
    const response = await this.http.delete(`/passports/${passport}/components/${component}`);
    return response.json();
  }
  async listPassportStates() {
    return this.http.getJson(`/passport_states`);
  }
  async listPassportFiles(passportId) {
    return this.http.getJson(`/passports/${passportId}/files`);
  }
  async addPassportFile(passportId, passportFile) {
    return this.http.post(`/passports/${passportId}/files`, JSON.stringify(passportFile));
  }
  async removePassportFile(passportId, fileId) {
    return this.http.delete(`/passports/${passportId}/files`, JSON.stringify(fileId));
  }
  async getFilePassportArchiveLink(passportId, selectedFiles) {
    const queryParams = {
      files: selectedFiles
    };
    const queryString = getQueryUrlFromObject(queryParams);
    return this.http.getJson(`/passports/${passportId}/archive${queryString}`);
  }
  async listPassportFileTypes() {
    return this.http.getJson(`/passports/files/types`);
  }
  async addPassportFileType(spec) {
    return this.http.post("/passports/files/types", JSON.stringify(spec));
  }
  async updatePassportFileTypes(updatedTypes) {
    return this.http.put("/passports/files/types", JSON.stringify(updatedTypes));
  }
  async setPassportFileType(passportId, fileId, typeId) {
    return this.http.put(`/passports/${passportId}/files/${fileId}/type`, JSON.stringify(typeId));
  }
  async countPassportsWithDescriptions() {
    return this.http.getJson("/passports/description/count");
  }
};

// src/pdm.ts
var PDMClient = class {
  constructor(http) {
    this.http = http;
  }
  async listParts() {
    return this.http.getJson("/parts?limit=100");
  }
  async listPartsPage(params = {}) {
    const qs = getQueryUrlFromObject(params);
    return this.http.getJson(`/parts${qs}`);
  }
  async getPart(partId) {
    return this.http.getJson(url`/parts/${partId}`);
  }
  async createPart(spec, file) {
    const req = new FormData();
    req.set("spec", JSON.stringify(spec));
    req.set("file", file, file.name);
    const response = await this.http.post("/parts", req);
    return response.json();
  }
  async deletePart(partId) {
    await this.http.delete(url`/parts/${partId}`);
  }
  async getPartProjectBom(projectId, definitionIdx) {
    return this.http.getJson(url`/projects/${projectId}/definitions/${definitionIdx}/bom/part`).catch(catchNotFound);
  }
  async updatePartProjectBom(projectId, definitionIdx, spec) {
    await this.http.put(url`/projects/${projectId}/definitions/${definitionIdx}/bom/part`, JSON.stringify(spec));
  }
  async deletePartProjectBom(projectId, definitionIdx) {
    await this.http.delete(url`/projects/${projectId}/definitions/${definitionIdx}/bom/part`);
  }
  async listPartProjectBom(partId) {
    return this.http.getJson(url`/parts/${partId}/projects/bom`);
  }
  async listPartProjectBomWithIteration(partId, iterationId) {
    return this.http.getJson(url`/parts/${partId}/iterations/${iterationId}/projects/bom`);
  }
  async deletePartDraftRevision(partId, iterationId) {
    await this.http.delete(url`/parts/${partId}/iterations/${iterationId}`);
  }
  async listPartIterations(partId) {
    return this.http.getJson(url`/parts/${partId}/iterations`);
  }
  async checkoutPart(partId) {
    await this.http.post(url`/parts/${partId}/check-out`);
  }
  async checkinPart(partId) {
    await this.http.post(url`/parts/${partId}/check-in`);
  }
  async savePartRevision(partId, revisionSpec) {
    await this.http.post(url`/parts/${partId}/save`, JSON.stringify(revisionSpec));
  }
  async makePartRevisionObsolete(partId, iterationId) {
    await this.http.post(url`/parts/${partId}/iterations/${iterationId}/obsolete`);
  }
  async updateDraftRevisionSpec(partId, revisionSpec) {
    await this.http.put(url`/parts/${partId}/iterations/draft/state`, JSON.stringify(revisionSpec));
  }
  async updateIgnorePartComponent(assemblyId, name, isIgnored) {
    await this.http.put(url`/parts/${assemblyId}/components/${name}/ignored`, isIgnored.toString());
  }
  async registerPartComponent(assemblyId, component) {
    await this.http.post(url`/parts/${assemblyId}/components`, JSON.stringify(component));
  }
  async unregisterPartComponent(assemblyId, componentName) {
    await this.http.delete(url`/parts/${assemblyId}/components/${componentName}`);
  }
  async listAssemblyComponents(assemblyId) {
    return this.http.getJson(url`/parts/${assemblyId}/components`);
  }
  async listAssemblyIterationComponents(assemblyId, iteration) {
    return this.http.getJson(url`/parts/${assemblyId}/iterations/${iteration}/components`);
  }
  async uploadCadFile(partId, spec, file) {
    const req = new FormData();
    req.set("spec", JSON.stringify(spec));
    req.set("file", file, file.name);
    await this.http.post(url`/parts/${partId}/cad`, req);
  }
  async getPartCadFileLink(partId) {
    return this.http.getJson(url`/parts/${partId}/file`);
  }
  async getPartIterationCadFileLink(partId, iteration) {
    return this.http.getJson(url`/parts/${partId}/iterations/${iteration}/file`);
  }
  async startPartIterationStepExport(partId, iteration, exportMode) {
    return this.http.getJson(url`/parts/${partId}/iterations/${iteration}/step/${exportMode}`);
  }
  async getPartIterationCdxfbFileLink(partId, iteration, fileId) {
    return this.http.getJson(url`/parts/${partId}/iterations/${iteration}/view/files/${fileId}`);
  }
  async getPartIterationWebViewFiles(partId, iteration) {
    return this.http.getJson(url`/parts/${partId}/iterations/${iteration}/view/files`);
  }
  async deletePartCadFile(partId) {
    await this.http.delete(url`/parts/${partId}/cad`);
  }
  async getPartUsedIn(partId) {
    return this.http.getJson(url`/parts/${partId}/used-in`);
  }
  async updatePartDescription(partId, description) {
    return this.http.put(url`/parts/${partId}/description`, JSON.stringify(description));
  }
  async renamePart(partId, name) {
    return this.http.put(url`/parts/${partId}/name`, JSON.stringify(name));
  }
  async updatePartOwner(partId, userId) {
    return this.http.put(url`/parts/${partId}/owner`, JSON.stringify(userId));
  }
  async getPartPreviewLink(partId) {
    try {
      const res = await this.http.getJson(url`/parts/${partId}/preview`);
      return res;
    } catch (err) {
      return null;
    }
  }
  async getPartImage(partId) {
    try {
      const link = await this.http.getJson(url`/parts/${partId}/preview`);
      if (!link) {
        return void 0;
      }
      const response = await this.http.get(link);
      return await response.blob();
    } catch (err) {
      return void 0;
    }
  }
  async updatePartProjects(partId, projects) {
    return this.http.put(url`/parts/${partId}/projects`, JSON.stringify(projects));
  }
  async getPartAccess(partId) {
    return this.http.getJson(`/parts/${partId}/access`);
  }
  async updatePartAccess(partId, newAccess) {
    return this.http.put(`/parts/${partId}/access`, JSON.stringify(newAccess));
  }
  async countPartsWithDescriptions() {
    return this.http.getJson("/parts/description/count");
  }
};

// src/projects.ts
var ProjectClient = class {
  constructor(http) {
    this.http = http;
  }
  async getProjects(params = {}) {
    const qs = getQueryUrlFromObject(params);
    return this.http.getJson(`/projects${qs}`);
  }
  async createProjectVersion(project, spec) {
    return this.http.post(`/projects/${project}/iterations`, JSON.stringify({
      actions: spec.actions,
      version: spec.name,
      comment: spec.comment,
      isDraft: spec.isDraft
    })).then((resp) => resp.json());
  }
  async updateProjectVersion(projectId, versionId, spec) {
    return this.http.post(`/projects/${projectId}/iterations/${versionId}`, JSON.stringify({
      actions: spec.actions,
      version: spec.name,
      comment: spec.comment,
      isDraft: spec.isDraft
    })).then((resp) => resp.json());
  }
  async deleteProjectVersion(projectId, versionId) {
    return this.http.delete(`/projects/${projectId}/iterations/${versionId}`).then((resp) => resp.json());
  }
  async getProjectVersions(project) {
    return this.http.getJson(`/projects/${project}/iterations`);
  }
  async getProject(project) {
    return this.http.getJson(`/projects/${project}`);
  }
  async getProjectByName(name) {
    return this.http.getJson(`/projects/by-name/${encodeURIComponent(name)}`);
  }
  async addProjectOptionField(project, field) {
    const response = await this.http.post(`/projects/${project}/options`, JSON.stringify(field));
    return response.json();
  }
  async updateProjectOptionField(project, field, updates) {
    return this.http.put(`/projects/${project}/options/${field}`, JSON.stringify(updates));
  }
  async updateProductProperties(productId, propertyUpdates) {
    return this.http.put(`/projects/${productId}/properties`, JSON.stringify(propertyUpdates));
  }
  async createProject(spec) {
    return this.http.post(`/projects`, JSON.stringify(spec)).then((resp) => resp.json());
  }
  async deleteProject(projectId) {
    return this.http.delete(`/projects/${projectId}`);
  }
  async setProjectDescription(projectId, description) {
    return this.http.put(`/projects/${projectId}/description`, JSON.stringify(description));
  }
  async setProjectName(projectId, name) {
    return this.http.put(`/projects/${projectId}/name`, JSON.stringify(name));
  }
  async setProjectImage(projectId, image) {
    const formData = new FormData();
    formData.append("file", image);
    return this.http.put(`/projects/${projectId}/image`, formData);
  }
  async setProjectType(projectId, projectType) {
    return this.http.put(`/projects/${projectId}/type`, JSON.stringify(projectType));
  }
  async getProjectImage(projectId) {
    try {
      const response = await this.http.get(`/projects/${projectId}/image`);
      return await response.blob();
    } catch (err) {
      return void 0;
    }
  }
  async getProjectDefinition(projectId, definitionIdx) {
    return this.http.getJson(url`/projects/${projectId}/definitions/${definitionIdx}`);
  }
  async listProjectDefinitions(projectId) {
    return this.http.getJson(url`/projects/${projectId}/definitions/`);
  }
  async updateProjectDefinition(projectId, definitionIdx, spec) {
    await this.http.put(url`/projects/${projectId}/definitions/${definitionIdx}`, JSON.stringify(spec));
  }
  async validateProjectDefinition(projectId, definitionIdx) {
    await this.http.post(url`/projects/${projectId}/definitions/${definitionIdx}/validate`);
  }
  async draftProjectDefinition(projectId, definitionIdx) {
    await this.http.post(url`/projects/${projectId}/definitions/${definitionIdx}/draft`);
  }
  async createProjectDefinition(projectId, spec) {
    await this.http.post(url`/projects/${projectId}/definitions/`, JSON.stringify(spec));
  }
  async deleteProjectDefinition(projectId, definitionIdx) {
    await this.http.delete(url`/projects/${projectId}/definitions/${definitionIdx}`);
  }
  async listProjectDefinitionDocument(projectId, definitionIdx) {
    return this.http.getJson(url`/projects/${projectId}/definitions/${definitionIdx}/documents`);
  }
  async createProjectDefinitionDocument(projectId, definitionIdx, spec) {
    await this.http.post(url`/projects/${projectId}/definitions/${definitionIdx}/documents`, JSON.stringify(spec));
  }
  async updateProjectDefinitionDocument(projectId, definitionIdx, documentId, revision) {
    await this.http.put(url`/projects/${projectId}/definitions/${definitionIdx}/documents/${documentId}`, JSON.stringify(revision));
  }
  async deleteProjectDefinitionDocument(projectId, definitionIdx, documentId) {
    await this.http.delete(url`/projects/${projectId}/definitions/${definitionIdx}/documents/${documentId}`);
  }
  async getProjectBOM(projectId, definitionIdx) {
    return this.http.getJson(url`/projects/${projectId}/definitions/${definitionIdx}/bom/entities`);
  }
  async getProjectUsedIn(projectId, queryParams) {
    const queryString = getQueryUrlFromObject(queryParams);
    return this.http.getJson(`/projects/${projectId}/used-in${queryString}`);
  }
  async addBOMEntry(projectId, definitionIdx, entry) {
    return this.http.post(url`/projects/${projectId}/definitions/${definitionIdx}/bom/entities`, JSON.stringify(entry));
  }
  async updateBOMEntry(projectId, definitionIdx, entityId, spec) {
    return this.http.put(url`/projects/${projectId}/definitions/${definitionIdx}/bom/entities/${entityId}`, JSON.stringify(spec));
  }
  async deleteBOMEntry(projectId, definitionIdx, entityId) {
    return this.http.delete(url`/projects/${projectId}/definitions/${definitionIdx}/bom/entities/${entityId}`);
  }
  async listToolingRequirements(projectId) {
    return this.http.getJson(`/projects/${projectId}/tools`);
  }
  async listToolingArticles(projectId) {
    return this.http.getJson(`/projects/${projectId}/articles`);
  }
  async addToolingRequirement(projectId, spec) {
    return this.http.post(`/projects/${projectId}/tools`, JSON.stringify(spec)).then((r) => r.json());
  }
  async updateToolingRequirement(projectId, requirementId, spec) {
    await this.http.put(`/projects/${projectId}/tools/${requirementId}`, JSON.stringify(spec));
  }
  async deleteToolingRequirement(projectId, requirementId) {
    await this.http.delete(`/projects/${projectId}/tools/${requirementId}`);
  }
  async countProductsWithDescriptions(projectType) {
    const queryString = getQueryUrlFromObject({ projectType });
    return this.http.getJson(`/projects/description/count${queryString}`);
  }
};

// src/property.ts
var PropertyClient = class {
  constructor(http) {
    this.http = http;
  }
  async getProperties(objectType) {
    return this.http.getJson(`/properties/${objectType}`);
  }
  async getProperty(objectType, propertyName) {
    return this.http.getJson(`/properties/${objectType}/${propertyName}`);
  }
  async deleteProperty(objectType, propertyName) {
    return this.http.delete(`/properties/${objectType}/${propertyName}`);
  }
  async createProperty(objectType, property) {
    return this.http.post(`/properties/${objectType}`, JSON.stringify(property));
  }
  async updateProperty(objectType, propertyName, property) {
    return this.http.put(`/properties/${objectType}/${propertyName}`, JSON.stringify(property));
  }
  async countPropertyUses(objectType, propertyName) {
    return this.http.getJson(`/properties/${objectType}/${propertyName}/used-in`);
  }
  async countPropertyOptionUses(objectType, propertyName, optionName) {
    return this.http.getJson(`/properties/${objectType}/${propertyName}/${optionName}/used-in`);
  }
  async getAletiqPropertiesDefaultDisplay(objectType) {
    return this.http.getJson(`/properties/${objectType}/builtin/display`);
  }
  async setAletiqPropertyDisplayByDefault(objectType, propertyName, value) {
    return this.http.put(`/properties/${objectType}/builtin/${propertyName}/display`, JSON.stringify(value));
  }
};

// src/search.ts
var SearchClient = class {
  constructor(http) {
    this.http = http;
  }
  async search(query) {
    return this.http.getJson(`/search?query=${encodeURIComponent(query)}`);
  }
  async getSearchHistory(search) {
    return this.http.getJson(`/search/history?query=${encodeURIComponent(search)}`);
  }
  async accessSearchResult(entity) {
    return this.http.put("/search/history", JSON.stringify(entity));
  }
};

// src/tasks.ts
var TaskClient = class {
  constructor(http) {
    this.http = http;
  }
  async getTasks(params = {}) {
    const query = getQueryUrlFromObject(params);
    return this.http.getJson(`/tasks${query}`);
  }
  async getTask(taskId) {
    return this.http.getJson(`/tasks/${taskId}`);
  }
  async getProcesses(params = {}) {
    const qs = getQueryUrlFromObject(params);
    return this.http.getJson(`/processes${qs}`);
  }
  async getProcess(processId) {
    return this.http.getJson(`/processes/${processId}`);
  }
  async createProcess(workflow) {
    const { title, model } = workflow;
    return this.http.post(`/processes`, JSON.stringify({
      title,
      model: model ?? null,
      owner: null
    })).then((r) => r.json());
  }
  async deleteProcess(processId) {
    return this.http.delete(`/processes/${processId}`);
  }
  async archiveProcess(processId) {
    return this.http.post(`/processes/archived`, JSON.stringify(processId));
  }
  async restoreProcess(processId) {
    return this.http.delete(`/processes/archived`, JSON.stringify(processId));
  }
  async deleteTask(taskId) {
    return this.http.delete(`/tasks/${taskId}`);
  }
  async updateProcessTasks(processId, specs) {
    return this.http.put(`/processes/${processId}/tasks`, JSON.stringify(specs));
  }
  async updateTaskStatus(task, status) {
    return this.http.put(`/tasks/${task}/status`, JSON.stringify(status));
  }
  async createTask(task, workflow) {
    const response = await this.http.post(`/processes/${workflow}/tasks`, JSON.stringify({
      title: task.title,
      description: task.description,
      dueDate: task.dueDate,
      owners: task.owners
    }));
    return response.json();
  }
  async setTaskDependencies(task, dependencies) {
    await this.http.put(`/tasks/${task}/dependencies`, JSON.stringify(dependencies));
  }
  async getProcessRelations(process) {
    return await this.http.getJson(`/processes/${process}/relations`);
  }
  async addProcessRelation(process, entity) {
    await this.http.post(`/processes/${process}/relations`, JSON.stringify(entity));
  }
  async deleteProcessRelation(process, entity) {
    await this.http.delete(`/processes/${process}/relations`, JSON.stringify(entity));
  }
  async updateProcess(spec, workflow) {
    await this.http.put(`/processes/${workflow}`, JSON.stringify(spec));
  }
  async listProcessModels() {
    return this.http.getJson(`/processes/models`);
  }
  async createProcessModel(model) {
    return this.http.post(`/processes/models`, JSON.stringify(model)).then((resp) => resp.json());
  }
  async updateProcessModel(modelId, model) {
    return this.http.put(`/processes/models/${modelId}`, JSON.stringify(model));
  }
  async deleteProcessModel(modelId) {
    return this.http.delete(`/processes/models/${modelId}`);
  }
  async getAnalysisModelsRepartition(filter) {
    return this.http.post("/processes/models/analysis/repartition", JSON.stringify(filter)).then((res) => res.json());
  }
  async getAnalysisModelsState(filter) {
    return this.http.post("/processes/models/analysis/state", JSON.stringify(filter)).then((res) => res.json());
  }
  async getAnalysisModelsVolume(filter) {
    return this.http.post("/processes/models/analysis/volume", JSON.stringify(filter)).then((res) => res.json());
  }
  async getAnalysisModelsResolution(filter) {
    return this.http.post("/processes/models/analysis/resolution", JSON.stringify(filter)).then((res) => res.json());
  }
  async exportWorkflows() {
    return this.http.get("/processes/export").then((response) => response.blob());
  }
};

// src/user.ts
var UserClient = class {
  constructor(http) {
    this.http = http;
  }
  async updateUserPassword(userId, password) {
    return this.http.put(`/users/${userId}/password`, JSON.stringify(password));
  }
  async deactivateUser(userId) {
    return this.http.delete(`/users/${userId}`);
  }
  async createUser(values) {
    return this.http.post("/users", JSON.stringify(values));
  }
  async updateUserName(userId, name) {
    return this.http.put(`/users/${userId}/name`, JSON.stringify(name));
  }
  async updateUserEmail(userId, email) {
    return this.http.put(`/users/${userId}/email`, JSON.stringify(email));
  }
  async updateUserRole(userId, role) {
    return this.http.put(`/users/${userId}/role`, JSON.stringify(role));
  }
  async getMyEntity() {
    return this.http.getJson("/user/entities");
  }
  async listUserGroups() {
    return this.http.getJson("/users/groups");
  }
  async createUserGroup(spec) {
    const r = await this.http.post("/users/groups", JSON.stringify(spec));
    return r.json();
  }
  async updateUserGroup(groupId, spec) {
    return this.http.put(`/users/groups/${groupId}`, JSON.stringify(spec));
  }
  async deleteUserGroup(groupId) {
    return this.http.delete(`/users/groups/${groupId}`);
  }
  async updateOwnNotificationSettings(allowEmails) {
    return this.http.put("/user/notifications", JSON.stringify(allowEmails));
  }
  async acknowledgeAppUpdates() {
    return this.http.put("/user/updates");
  }
};

// src/api.ts
var API = class {
  constructor(http) {
    this.http = http;
    this.comment = new CommentClient(http);
    this.document = new DocumentClient(http);
    this.task = new TaskClient(http);
    this.activity = new ActivityClient(http);
    this.auth = new AuthClient(http);
    this.pdm = new PDMClient(http);
    this.project = new ProjectClient(http);
    this.passport = new PassportClient(http);
    this.property = new PropertyClient(http);
    this.integration = new IntegrationClient(http);
    this.user = new UserClient(http);
    this.operations = new OperationClient(http);
    this.search = new SearchClient(http);
  }
  async getTenant() {
    return this.http.getJson("/tenant");
  }
  async listNotifications() {
    return this.http.getJson("/notifications");
  }
  async newNotificationCount() {
    return this.http.getJson("/notifications/new");
  }
  async setNotificationsReadStatus(from, to, status) {
    return this.http.post(`/notifications?action=read&from=${from}&to=${to}`, JSON.stringify(status));
  }
  async acknowledgeNotifications() {
    return this.http.post(`/notifications?action=acknowledge`);
  }
  async listClients() {
    return this.http.getJson(`/clients`);
  }
  async addClient(value) {
    const res = await this.http.post(`/clients`, JSON.stringify(value));
    return await res.json();
  }
  async updateClient(client, spec) {
    return await this.http.put(`/clients/${client}`, JSON.stringify(spec));
  }
  async listPassportStates() {
    return this.http.getJson(`/passport_states`);
  }
  async addPassportState(value) {
    const res = await this.http.post(`/passport_states`, JSON.stringify(value));
    return await res.json();
  }
  async updatePassportState(stateId, spec) {
    return await this.http.put(`/passport_states/${stateId}`, JSON.stringify(spec));
  }
  async listExports() {
    return await this.http.getJson("/exports");
  }
  async getExportSchedule() {
    return await this.http.getJson("/exports/schedule");
  }
  async getExportFileDownloadToken(exportId, fileId) {
    return await this.http.getJson(`/exports/${exportId}/files/${fileId}`);
  }
  async setExportEnabled(enabled) {
    await this.http.put(`/exports/schedule?enabled=${enabled}`);
  }
  async getJobResult(jobResultId) {
    return await this.http.getJson(`/job/${jobResultId}`);
  }
  async getJobResultFileDownloadToken(jobResultId) {
    return await this.http.getJson(`/job/${jobResultId}/file`);
  }
};
export {
  API,
  APIError,
  HTTPClient
};
