import { Dialog, Field, Input } from "@aletiq/design-system";
import React, { useState } from "react";
import { useTranslations } from "../../../util";
import styles from "./EditBillIndex.module.scss";
import useRenameBill from "./hooks/useRenameBill";
export default function EditBillIndex(props: {
  projectId: number;
  billId: number;
  onClose: () => void;
  value: string;
}) {
  const { projectId, billId, onClose, value: initialValue } = props;
  const tr = useTranslations();

  const [value, setValue] = useState(initialValue);

  const { isLoading, mutate } = useRenameBill(projectId, billId);

  const handleRename = () => {
    mutate(value, {
      onSuccess: () => onClose(),
      onError: () => setValue(initialValue),
    });
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      title={tr.translateAsString("project.operation-bill.rename-index")}
      icon="edit"
      submitting={isLoading}
      onPrimaryClick={handleRename}
    >
      <div className={styles.index_editor_hint}>
        {tr.translate("project.operation-bill.rename-index.caption")}
      </div>
      <Field
        label={tr.translate("project.operation-bill.bill-index.field-label")}
        labelClassName={styles.index_editor_label}
      >
        <Input
          fill
          removeClearButton
          onChange={setValue}
          value={value}
          intent="primary"
        />
      </Field>
    </Dialog>
  );
}
