import { OperationBill } from "@aletiq/types";
import React, { useState } from "react";
import { Button, Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import useAddOperations from "../hooks/useAddOperations";
import { OperationsInfoForm } from "../OperationsInfoForm";
import styles from "./AddOperationsForm.module.scss";

export default function AddOperationsForm(props: {
  projectId: number;
  bill: OperationBill;
  onClose?: () => void;
  onSuccess?: () => void;
}) {
  const { projectId, bill, onClose = () => {}, onSuccess = () => {} } = props;
  const tr = useTranslations();

  const [operations, setOperations] = useState<
    { number: string; name: string; description: string }[]
  >([{ number: "", name: "", description: "" }]);

  const { isLoading: addOperationsLoading, mutate: addOperations } =
    useAddOperations(projectId, bill);

  const handleChangeNumber = (index: number) => (value: string) =>
    setOperations((ops) => {
      const newOps = [...ops];
      newOps[index] = { ...newOps[index], number: value };
      return newOps;
    });

  const handleChangeName = (index: number) => (value: string) =>
    setOperations((ops) => {
      const newOps = [...ops];
      newOps[index] = { ...newOps[index], name: value };
      return newOps;
    });

  const handleAdd = () =>
    setOperations((ops) => [...ops, { number: "", name: "", description: "" }]);

  const isValid = (op: { number: string; name: string }) =>
    op.number === "" || !Number.isNaN(Number.parseInt(op.number));

  const allValid = operations.every(isValid);

  const handleSubmit = () => {
    if (!allValid) {
      return;
    }
    const validOperations = operations
      .filter((op) => op.number !== "")
      .map((op) => ({
        number: Number.parseInt(op.number),
        name: op.name,
        description: op.description,
      }));

    addOperations(validOperations, { onSuccess: onSuccess });
  };

  return (
    <Dialog
      title={tr.translateAsString(
        "project.operation-bill.add-operations.title"
      )}
      icon="plus"
      isOpen={true}
      onClose={onClose}
      disablePrimary={!allValid}
      onPrimaryClick={handleSubmit}
      submitting={addOperationsLoading}
    >
      <div className={styles.caption}>
        {tr.translate("project.operation-bill.add-operations.caption")}
      </div>
      <OperationsInfoForm
        operations={operations}
        handleChangeNumber={handleChangeNumber}
        handleChangeName={handleChangeName}
      />
      <Button isDense icon="plus" intent="secondary" onClick={handleAdd}>
        {tr.translate("project.operation-bill.add-operations.add-button")}
      </Button>
    </Dialog>
  );
}
