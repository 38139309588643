import { ProjectField } from "@aletiq/types";
import { FormGroup } from "@blueprintjs/core";
import React from "react";
import { Icon, MultiSelect, SingleSelectionIcon } from "../../../../components";
import { isNotUndefined, useTranslations } from "../../../../util";
import styles from "./PassportConfigurationInput.module.scss";

export default function PassportConfigurationsInput(props: {
  field: ProjectField;
  config: Record<number, number[]>;
  onSetConfig: (fieldId: number, values: number[]) => void;
}) {
  const { field, config, onSetConfig } = props;
  const tr = useTranslations();

  const values: number[] = !config[field.id]
    ? []
    : config[field.id]
        .map((valueId) => field?.values.find((value) => value.id === valueId))
        .filter(isNotUndefined)
        .map((val) => val.id);

  const isValueSelected = (fieldId: number, valueId: number) => {
    if (!config[fieldId]) return false;
    else return config[fieldId].includes(valueId);
  };

  const handleClickValue = (valueId: number) => {
    if (isValueSelected(field.id, valueId)) handleUnselectValue(field, valueId);
    else handleSelectValue(field, valueId);
  };

  const handleRemoveTag = (valueId: number) => {
    if (valueId) handleUnselectValue(field, valueId);
  };

  const handleSelectValue = (field: ProjectField, valueId: number) => {
    const existingValues = config[field.id];
    if (!existingValues) return;
    if (valueId === -1 && !field.isMandatory) {
      onSetConfig(field.id, []);
    } else if (valueId !== 0 && field.allowMultiValues) {
      onSetConfig(field.id, [...existingValues, valueId]);
    } else if (valueId !== 0) {
      onSetConfig(field.id, [valueId]);
    }
  };

  const handleUnselectValue = (field: ProjectField, valueId: number) => {
    const existingValues = config[field.id];
    if (!existingValues) return;
    if (field.allowMultiValues)
      onSetConfig(
        field.id,
        existingValues.filter((value) => value !== valueId)
      );
    else onSetConfig(field.id, []);
  };

  const shareFieldItems = field.values.map((val) => ({
    key: val.id,
    text: val.isDefault
      ? tr.translateAsString("passport.dialogs.config-input.default", {
          name: val.name,
        })
      : val.name,
  }));
  const fieldItems = field.isMandatory
    ? shareFieldItems
    : [
        {
          key: -1,
          text: tr.translateAsString("generic.label.none", {
            name: field.name,
          }),
        },
        ...shareFieldItems,
      ];

  const style = {
    backgroundColor: `var(--tag-color-${((field?.id ?? 1) % 10) + 1})`,
  } as React.CSSProperties;

  return (
    <FormGroup
      className={styles.multiselect}
      key={field.id}
      label={<FieldLabel field={field} />}
    >
      <MultiSelect
        popoverProps={{
          position: "bottom-left",
        }}
        filterable={false}
        selectedItems={values}
        items={fieldItems}
        handleRemoveTag={handleRemoveTag}
        handleAddTag={handleClickValue}
        placeholder={tr.translateAsString("generic.label.none")}
        fill
        tagProps={{ style, className: styles.tag }}
      />
    </FormGroup>
  );
}

function FieldLabel(props: { field: ProjectField }) {
  const { field } = props;
  return (
    <div className={styles.field_label}>
      <span>
        {field.allowMultiValues && <Icon inline icon={"properties"} />}
        {!field.allowMultiValues && (
          <SingleSelectionIcon className={styles.inline} />
        )}
        {field.name}
      </span>
      <p className={styles.description}>{field.description}</p>
    </div>
  );
}
