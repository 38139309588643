import React from "react";
import classNames from "classnames";
import styles from "./UserInitial.module.scss";

export default function UserInitial(props: {
  userName: string;
  size?: "default" | "dense" | "small";
  inline?: boolean;
  className?: string;
}) {
  const { userName, size = "default", inline, className } = props;

  const initial = userName.slice(0, 1).toLocaleUpperCase() || "";

  return (
    <div
      className={classNames(
        styles.initial,
        styles[`size--${size}`],
        inline && styles.inline,
        className
      )}
    >
      <div>{initial}</div>
    </div>
  );
}
