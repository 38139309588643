import {
  IgnoreTag,
  Menu,
  MenuItem,
  Popover2,
  UpdateTag,
} from "../../../../../components";
import React from "react";

export default function StandardPartActionSelect(props: {
  children: React.ReactNode;
  disabled?: boolean;
  onChangePartAction: (action: "update" | "ignore") => void;
  selectedAction: "update" | "ignore";
}) {
  const { children, disabled, onChangePartAction, selectedAction } = props;

  return (
    <Popover2
      fill
      minimal
      disabled={disabled}
      position="bottom-right"
      content={
        <Menu isDense>
          <MenuItem
            isDense
            intent="primary"
            disabled={disabled}
            active={selectedAction === "update"}
            onClick={() => onChangePartAction("update")}
            text={<UpdateTag />}
          />
          <MenuItem
            isDense
            intent="primary"
            disabled={disabled}
            active={selectedAction === "ignore"}
            onClick={() => onChangePartAction("ignore")}
            text={<IgnoreTag />}
          />
        </Menu>
      }
    >
      {children}
    </Popover2>
  );
}
