import { EditableText, Field } from "@aletiq/design-system";
import classname from "classnames";
import React, { useLayoutEffect, useState } from "react";
import { useTranslations } from "../../../util";
import styles from "./EditableField.module.scss";

export default function EditableField(props: {
  name: string;
  value: string;
  placeholder?: string;
  minWidth?: number;
  intent?: "primary" | "default" | "danger";
  className?: string;
  labelClassName?: string;
  inline?: boolean;
  multiline?: boolean;
  submitting?: boolean;
  onSubmit: (value: string) => void;
}) {
  const {
    name,
    value,
    placeholder,
    minWidth,
    intent,
    className,
    labelClassName,
    inline,
    multiline,
    submitting,
    onSubmit,
  } = props;
  const tr = useTranslations();

  const [editValue, setEditValue] = useState(value);
  useLayoutEffect(() => {
    setEditValue(value);
  }, [value]);

  const editableClass = classname(
    {
      [styles.grayed]: submitting,
    },
    className
  );

  return (
    <Field inline={inline} label={name} labelClassName={labelClassName}>
      <EditableText
        multiline={multiline}
        minLines={multiline ? 3 : undefined}
        intent={intent}
        className={editableClass}
        disabled={submitting}
        placeholder={
          placeholder ??
          tr.translateAsString("generic.action.modify-value", { value: name })
        }
        value={editValue}
        onChange={setEditValue}
        onCancel={() => setEditValue(value)}
        onConfirm={() => onSubmit(editValue)}
        minWidth={minWidth}
      />
    </Field>
  );
}
