import React from "react";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuSP,
  Popover2,
} from "../../../../components";
import { useTranslations } from "../../../../util";
export default function ProjectDefinitionDocumentDraftCardHeaderAction(props: {
  onOpenPreview: () => void;
  onDelete: () => void;
  onDownload: () => void;
}) {
  const { onOpenPreview, onDelete, onDownload } = props;

  const tr = useTranslations();

  return (
    <ButtonGroup isDense>
      <Button isDense icon="eye-open" onClick={onOpenPreview} />
      <Popover2
        content={
          <MenuSP isDense>
            <MenuItem
              isDense
              icon="download"
              text={tr.translate("generic.action.download")}
              onClick={onDownload}
            />
            <MenuItem
              isDense
              icon="trash"
              text={tr.translate("generic.action.delete")}
              onClick={onDelete}
            />
          </MenuSP>
        }
      >
        <Button isDense icon="more" />
      </Popover2>
    </ButtonGroup>
  );
}
