import { User } from "@aletiq/types";
import React, { useState } from "react";
import { Button, Dialog, Field, Input } from "../../../../components";
import { useTranslations } from "../../../../util";
import { EmailInput } from "../common";
import { useUpdateShareUser } from "../hooks";
import { ChangePasswordUserDialog } from "./ChangePasswordUserDialog";
import styles from "./Dialogs.module.scss";

export function ModifySharedUserDialog(props: {
  onClose: () => void;
  user: User;
}) {
  const { onClose, user } = props;
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [name, setName] = useState(user.displayName);
  const [email, setEmail] = useState(user?.email ?? "");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const tr = useTranslations();

  const handleEmailChange = (value: string, isEmailValidProps: boolean) => {
    setEmail(value);
    setIsEmailValid(isEmailValidProps);
  };
  const { mutate: updateShareUser, isLoading } = useUpdateShareUser(user.id);
  const canSubmit = name !== "" && isEmailValid;

  const onSubmit = () => {
    updateShareUser(
      { name, email },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  const handleIsDialogOpened = (isDialogOpened: boolean) => () => {
    setIsDialogOpened(isDialogOpened);
  };

  return (
    <>
      <Dialog
        isOpen
        icon="edit"
        className={styles.dialog}
        onClose={onClose}
        title={tr.translateAsString(
          "admin.users.dialog.modify-shared-account.title"
        )}
        onPrimaryClick={onSubmit}
        disablePrimary={!canSubmit}
        submitting={isLoading}
      >
        <div className={styles.dialog__inner}>
          <div className={styles.dialog__inner__description}>
            {tr.translate(
              "admin.users.dialog.modify-shared-account.description"
            )}
          </div>
          <Field
            isMandatory
            label={tr.translateAsString(
              "admin.users.dialog.add-shared-user.name"
            )}
          >
            <Input
              fill
              value={name}
              onChange={setName}
              className={styles.flexItem}
              placeholder={tr.translateAsString("generic.label.name") + "..."}
            />
          </Field>
          <EmailInput
            label={tr.translateAsString(
              "admin.users.dialog.add-shared-user.email"
            )}
            placeholder={tr.translateAsString(
              "admin.users.dialog.add-user.email.placeholder"
            )}
            helperText={tr.translateAsString(
              "admin.users.dialog.add-named-user.email"
            )}
            value={email}
            setValue={handleEmailChange}
            containerClassName={styles.spaceTop}
            defaultValue={user?.email}
            isMandatory
          />
          <Button
            intent="secondary"
            onClick={handleIsDialogOpened(true)}
            className={styles.spaceTop}
          >
            {tr.translate(
              "admin.users.dialog.modify-shared-account.modify-password"
            )}
          </Button>
        </div>
      </Dialog>
      {isDialogOpened && (
        <ChangePasswordUserDialog
          onClose={handleIsDialogOpened(false)}
          userId={user.id}
        />
      )}
    </>
  );
}
