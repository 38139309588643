import React, { useState } from "react";
import {
  DialogBase,
  DialogContent,
  DialogFooter,
  Field,
} from "../../../components";
import { useTranslations } from "../../../util";
import useListProjectDefinitions from "../../project/hooks/useListProjectDefinitions";
import { ProjectDefinitionSelectMenu } from "../../project/ProjectNomenclature/ProjectDefinitionSelectMenu";
import { useUpdatePassports } from "../hooks";

export default function UpdatePassportsDefinitionIndexDialog(props: {
  onClose: () => void;
  passports: number[];
  productId: number;
}) {
  const { passports, productId, onClose } = props;
  const tr = useTranslations();
  const [value, setValue] = useState(1);
  const { data: definitions = [] } = useListProjectDefinitions(productId);
  const activeDefinition = definitions.find((d) => d.index === value);
  const { mutate: updatePassports, isLoading } = useUpdatePassports();

  const handleSubmit = () => {
    updatePassports(
      {
        passports,
        updates: [
          {
            type: "definition",
            value: value,
          },
        ],
      },
      {
        onSettled: () => onClose(),
      }
    );
  };

  return (
    <DialogBase isOpen isDense onClose={onClose}>
      <DialogContent>
        <Field label={tr.translateAsString("project.definition.index")}>
          {activeDefinition && (
            <ProjectDefinitionSelectMenu
              activeDefinition={activeDefinition}
              definitions={definitions}
              onSelect={setValue}
            />
          )}
        </Field>
      </DialogContent>
      <DialogFooter
        isDense
        primaryButtonProps={{ onClick: handleSubmit, isLoading }}
        secondaryButtonProps={{ onClick: onClose }}
      />
    </DialogBase>
  );
}
