import { Part } from "@aletiq/types";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { activityKeys } from "../../../activities/hooks/queries";
import { pdmKeys } from "../../hooks/queries";
import { isPartParent } from "../../services";
import waitComponentsComplete from "../UploadPartsWizard/services/waitComponentsComplete";
import { Values } from "./PartCreationDialog";

export type CreationStatus = "idle" | "saving" | "approving" | "success";

export default function useCreatePart() {
  const api = useApi();
  const queryClient = useQueryClient();

  const [error, setError] = useState<Error | undefined>();
  const [status, setStatus] = useState<CreationStatus>("idle");

  const mutateFunction = useCallback(
    async (
      values: Values,
      cbs?: {
        onSuccess?: (part: Part) => void;
        onSettled?: (values: Values, error: unknown) => void;
      }
    ) => {
      try {
        setStatus("saving");
        const { spec, file, approveRevision } = values;
        const partId = await api.pdm.createPart(spec, file);

        if (approveRevision) {
          setStatus("approving");
          if (isPartParent(spec.type)) {
            const registered = await waitComponentsComplete(api, partId);
            if (!registered) throw new Error("incomplete_bom");
          }
          await api.pdm.savePartRevision(partId, spec.revisionSpec);
        }
        setStatus("success");

        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);

        const part = await api.pdm.getPart(partId);
        cbs?.onSuccess?.(part);

        return partId;
      } catch (error) {
        setError(error as Error);
      } finally {
        cbs?.onSettled?.(values, error);
      }
    },
    [api, error, queryClient]
  );

  return useMemo(
    () => ({
      mutate: (
        values: Values,
        cbs?: {
          onSuccess?: (part: Part) => void;
          onSettled?: (values: Values, error: unknown) => void;
        }
      ) => mutateFunction(values, cbs),
      status,
      error,
    }),
    [mutateFunction, status, error]
  );
}
