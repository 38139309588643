import { useMemo } from "react";
import { SortDirection } from "@aletiq/types";
import useSortKey from "./useSortKey";

/**
 * `useSort` manages the state of sorting options for an array along with a memoized sorted array.
 * ```ts
 *  {
 *   sortKey,
 *   sortedList: sortedDocuments,
 *   onSortKeyChange,
 *  } = useSort(documents, "name");
 * ```

 */
export function useSort<T, K extends keyof T = keyof T>(
  list: T[],
  defaultKey: K,
  defaultDirection?: SortDirection
): {
  sortKey: K;
  sortedList: T[];
  sortDirection: SortDirection;
  onSortKeyChange: (k: K) => void;
} {
  const { sortState, setSortKey } = useSortKey(defaultKey, defaultDirection);

  const sortedList = useMemo(
    () =>
      [...list].sort((item1, item2) => {
        const value1 = item1[sortState.key];
        const value2 = item2[sortState.key];
        const direction = sortState.direction === "asc" ? 1 : -1;

        switch (typeof value1) {
          case "string":
            // Managing empty string
            if (value1 === "") return direction;
            if (typeof value2 === "string") {
              // Managing empty string
              if (value2 === "") return 0 - direction;
              return value1.localeCompare(value2) * direction;
            }
            return 0 - direction;
          case "number":
            if (typeof value2 === "number")
              return (value1 - value2) * direction;
            return 0 - direction;
          default:
            return direction; // if value 1 is undefined push the first row at the end for "asc" or start for "asc"
        }
      }),
    [list, sortState]
  );

  return {
    sortKey: sortState.key,
    sortDirection: sortState.direction,
    sortedList,
    onSortKeyChange: setSortKey,
  };
}
