import React from "react";
import { ItemSelect, Select } from "../../../../components";
import { useTranslations } from "../../../../util";

export type ProjectComponentsSelection = "uses" | "used-in";

export default function ProjectComponentSelect(props: {
  selected: ProjectComponentsSelection;
  onSelectChange: (select: ProjectComponentsSelection) => void;
}) {
  const { selected, onSelectChange } = props;

  const tr = useTranslations();

  const items: ItemSelect<ProjectComponentsSelection>[] = [
    { key: "uses", text: tr.translateAsString("product.bom.components") },
    { key: "used-in", text: tr.translateAsString("product.bom.used-in") },
  ];

  const selectedText = items.find((i) => i.key === selected)?.text ?? "";

  return (
    <Select
      isDense
      intent="default"
      onItemSelect={onSelectChange}
      items={items}
    >
      {selectedText}
    </Select>
  );
}
