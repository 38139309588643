var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Alert/AlertPopover.tsx
import { useTranslations } from "@aletiq/translation";
import { Icon as Icon2 } from "@blueprintjs/core";
import { Classes as Classes2 } from "@blueprintjs/popover2";
import classNames from "classnames";
import React3, { useRef } from "react";

// src/Button/ButtonSP.tsx
import {
  Classes,
  Icon,
  IconSize,
  Spinner
} from "@blueprintjs/core";
import cx from "classnames";
import React from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/common/color.module.scss
var color_module_default = {
  "primary": "_primary_vdg39_2",
  "danger": "_danger_vdg39_23",
  "success": "_success_vdg39_44",
  "warning": "_warning_vdg39_65",
  "warning_callout": "_warning_callout_vdg39_86",
  "default": "_default_vdg39_106",
  "primary_alt": "_primary_alt_vdg39_126",
  "danger_alt": "_danger_alt_vdg39_146",
  "success_alt": "_success_alt_vdg39_166",
  "warning_alt": "_warning_alt_vdg39_186"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Button/ButtonType.module.scss
var ButtonType_module_default = {
  "default": "_default_1i8cc_1",
  "dense": "_dense_1i8cc_2",
  "bp4-icon": "_bp4-icon_1i8cc_20",
  "card": "_card_1i8cc_64",
  "circle": "_circle_1i8cc_117",
  "action": "_action_1i8cc_130",
  "icon": "_icon_1i8cc_172",
  "fill": "_fill_1i8cc_186"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Button/ButtonView.module.scss
var ButtonView_module_default = {
  "flat": "_flat_1gtbv_2",
  "focused": "_focused_1gtbv_22",
  "filled": "_filled_1gtbv_44",
  "smooth": "_smooth_1gtbv_83",
  "outlined": "_outlined_1gtbv_130",
  "raised": "_raised_1gtbv_176",
  "minimal": "_minimal_1gtbv_212"
};

// src/Button/ButtonSP.tsx
function ButtonSP(props) {
  const _a = props, {
    view = "smooth",
    color = "default",
    isFill,
    isLoading,
    isDisabled,
    isDense,
    active,
    className,
    icon,
    children,
    text,
    rightIcon
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "isFill",
    "isLoading",
    "isDisabled",
    "isDense",
    "active",
    "className",
    "icon",
    "children",
    "text",
    "rightIcon"
  ]);
  const buttonClassname = cx([Classes.BUTTON], {
    [Classes.FILL]: isFill,
    [Classes.DISABLED]: isLoading || isDisabled,
    [Classes.LOADING]: isLoading,
    [ButtonType_module_default["default"]]: true,
    [ButtonType_module_default["fill"]]: isFill,
    [ButtonType_module_default["dense"]]: isDense,
    [ButtonView_module_default[view]]: true,
    [color_module_default[color]]: true,
    [ButtonView_module_default["focused"]]: active
  }, className);
  const iconSize = isDense ? IconSize.STANDARD : IconSize.LARGE;
  return /* @__PURE__ */ React.createElement("button", __spreadProps(__spreadValues({}, restProps), {
    type: "button",
    className: buttonClassname,
    disabled: isDisabled || isLoading
  }), isLoading ? /* @__PURE__ */ React.createElement(Spinner, {
    className: Classes.BUTTON_SPINNER,
    size: IconSize.LARGE
  }) : null, /* @__PURE__ */ React.createElement(Icon, {
    icon,
    size: iconSize
  }), children || text ? /* @__PURE__ */ React.createElement("span", {
    className: Classes.BUTTON_TEXT
  }, children || text) : null, /* @__PURE__ */ React.createElement(Icon, {
    icon: rightIcon,
    size: iconSize
  }));
}

// src/Popover2/Popover2.tsx
import {
  Popover2 as PopoverSource
} from "@blueprintjs/popover2";
import cx2 from "classnames";
import React2 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Popover2/Popover2.module.scss
var Popover2_module_default = {
  "popover": "_popover_18p0l_1",
  "children": "_children_18p0l_10"
};

// src/Popover2/Popover2.tsx
var Popover2 = React2.forwardRef((props, ref) => {
  const _a = props, {
    minimal = true,
    position = "bottom-right",
    intent = "default",
    children,
    offset,
    popoverClassName,
    className
  } = _a, restProps = __objRest(_a, [
    "minimal",
    "position",
    "intent",
    "children",
    "offset",
    "popoverClassName",
    "className"
  ]);
  const defaultOffset = [0, 7];
  return /* @__PURE__ */ React2.createElement(PopoverSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    popoverClassName: cx2(color_module_default[intent], Popover2_module_default.popover, popoverClassName),
    minimal,
    position,
    className: cx2(Popover2_module_default.children, className),
    modifiers: {
      offset: {
        enabled: true,
        options: { offset: offset ?? defaultOffset }
      }
    }
  }), children);
});
var Popover2_default = Popover2;

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Alert/AlertPopoverType.module.scss
var AlertPopoverType_module_default = {
  "default": "_default_9mf39_3",
  "title": "_title_9mf39_11",
  "header": "_header_9mf39_20",
  "content": "_content_9mf39_26",
  "footer": "_footer_9mf39_40",
  "dense": "_dense_9mf39_46",
  "close-button": "_close-button_9mf39_84"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Alert/AlertPopoverView.module.scss
var AlertPopoverView_module_default = {
  "smooth": "_smooth_1ahr2_3",
  "raised": "_raised_1ahr2_9",
  "filled": "_filled_1ahr2_15"
};

// src/Alert/AlertPopover.tsx
function AlertPopover(props) {
  const _a = props, {
    isDense,
    intent = "primary",
    icon,
    title,
    content,
    primaryText,
    secondaryText,
    disablePrimary,
    onClose,
    onPrimaryClick,
    onSecondaryClick,
    submitting
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "intent",
    "icon",
    "title",
    "content",
    "primaryText",
    "secondaryText",
    "disablePrimary",
    "onClose",
    "onPrimaryClick",
    "onSecondaryClick",
    "submitting"
  ]);
  const ref = useRef(null);
  const handlePrimaryClick = (ev) => {
    var _a2;
    onPrimaryClick && onPrimaryClick(ev);
    (_a2 = ref.current) == null ? void 0 : _a2.click();
  };
  const primaryButtonProps = {
    onClick: handlePrimaryClick,
    isDisabled: disablePrimary,
    text: primaryText
  };
  const secondaryButtonProps = {
    onClick: onSecondaryClick ?? onClose,
    isLoading: submitting,
    text: secondaryText
  };
  return /* @__PURE__ */ React3.createElement(AlertPopoverBase, __spreadProps(__spreadValues({}, restProps), {
    isDense,
    intent,
    content: /* @__PURE__ */ React3.createElement(React3.Fragment, null, (title || icon) && /* @__PURE__ */ React3.createElement(AlertPopoverHeader, {
      icon,
      isDense,
      title
    }), /* @__PURE__ */ React3.createElement(AlertPopoverContent, null, content), /* @__PURE__ */ React3.createElement(AlertPopoverFooter, {
      intent,
      isDense,
      primaryButtonProps,
      secondaryButtonProps
    }), /* @__PURE__ */ React3.createElement("div", {
      className: Classes2.POPOVER2_DISMISS,
      ref
    }))
  }));
}
function AlertPopoverHeader(props) {
  const { isDense, icon, title } = props;
  return /* @__PURE__ */ React3.createElement(AlertPopoverHeaderBase, null, /* @__PURE__ */ React3.createElement(Icon2, {
    icon,
    iconSize: isDense ? 20 : 24
  }), /* @__PURE__ */ React3.createElement(AlertPopoverTitle, null, title));
}
function AlertPopoverFooter(props) {
  const {
    intent = "primary",
    isDense,
    primaryButtonProps,
    secondaryButtonProps
  } = props;
  const tr = useTranslations();
  return /* @__PURE__ */ React3.createElement(AlertPopoverFooterBase, null, secondaryButtonProps && /* @__PURE__ */ React3.createElement("div", {
    className: AlertPopoverType_module_default["left-button"]
  }, /* @__PURE__ */ React3.createElement(ButtonSP, {
    color: intent,
    isDense,
    view: "flat",
    onClick: secondaryButtonProps.onClick,
    className: Classes2.POPOVER2_DISMISS,
    isLoading: secondaryButtonProps.isLoading,
    isDisabled: secondaryButtonProps.isDisabled
  }, secondaryButtonProps.text || tr.translate("generic.action.cancel"))), primaryButtonProps && /* @__PURE__ */ React3.createElement("div", {
    className: AlertPopoverType_module_default["right-button"]
  }, /* @__PURE__ */ React3.createElement(ButtonSP, {
    color: intent,
    isDense,
    view: "smooth",
    onClick: primaryButtonProps.onClick,
    isLoading: primaryButtonProps.isLoading,
    isDisabled: primaryButtonProps.isDisabled
  }, primaryButtonProps.text || tr.translate("generic.action.submit"))));
}
function AlertPopoverBase(props) {
  const _a = props, {
    isDense,
    view = "raised",
    intent = "primary",
    position = "bottom-right",
    content,
    children,
    className,
    onClose
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "view",
    "intent",
    "position",
    "content",
    "children",
    "className",
    "onClose"
  ]);
  return /* @__PURE__ */ React3.createElement(Popover2_default, __spreadProps(__spreadValues({}, restProps), {
    onClose,
    position,
    captureDismiss: true,
    intent,
    popoverClassName: classNames(AlertPopoverType_module_default[isDense ? "dense" : "default"], AlertPopoverView_module_default[view], color_module_default[intent], className),
    content
  }), children);
}
function AlertPopoverHeaderBase(props) {
  const _a = props, { className } = _a, other = __objRest(_a, ["className"]);
  return /* @__PURE__ */ React3.createElement("div", __spreadValues({
    className: classNames(AlertPopoverType_module_default["header"], className)
  }, other));
}
function AlertPopoverTitle(props) {
  const _a = props, { className } = _a, other = __objRest(_a, ["className"]);
  return /* @__PURE__ */ React3.createElement("div", __spreadValues({
    className: classNames(AlertPopoverType_module_default["title"], className)
  }, other));
}
function AlertPopoverContent(props) {
  const _a = props, { className } = _a, other = __objRest(_a, ["className"]);
  return /* @__PURE__ */ React3.createElement("div", __spreadValues({
    className: classNames(AlertPopoverType_module_default["content"], className)
  }, other));
}
function AlertPopoverFooterBase(props) {
  const _a = props, { className } = _a, other = __objRest(_a, ["className"]);
  return /* @__PURE__ */ React3.createElement("div", __spreadValues({
    className: classNames(AlertPopoverType_module_default["footer"], className)
  }, other));
}

// src/AnchorButton/AnchorButton.tsx
import React5 from "react";

// src/AnchorButton/AnchorButtonSP.tsx
import {
  AnchorButton as AnchorButtonSource,
  Icon as Icon3
} from "@blueprintjs/core";
import cx3 from "classnames";
import React4 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/AnchorButton/AnchorButtonType.module.scss
var AnchorButtonType_module_default = {
  "default": "_default_1gbyu_1",
  "dense": "_dense_1gbyu_2",
  "bp4-icon": "_bp4-icon_1gbyu_20",
  "card": "_card_1gbyu_64",
  "circle": "_circle_1gbyu_117",
  "action": "_action_1gbyu_130",
  "icon": "_icon_1gbyu_172",
  "fill": "_fill_1gbyu_186",
  "leftIcon": "_leftIcon_1gbyu_191"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/AnchorButton/AnchorButtonView.module.scss
var AnchorButtonView_module_default = {
  "flat": "_flat_1dhbf_2",
  "focused": "_focused_1dhbf_22",
  "filled": "_filled_1dhbf_44",
  "smooth": "_smooth_1dhbf_85",
  "outlined": "_outlined_1dhbf_132",
  "raised": "_raised_1dhbf_175",
  "minimal": "_minimal_1dhbf_209"
};

// src/AnchorButton/AnchorButtonSP.tsx
var AnchorButtonSP = React4.forwardRef((props, ref) => {
  const _a = props, {
    type = "default",
    view = "smooth",
    color = "primary",
    isFill,
    isLoading,
    isDisabled,
    isDense,
    active,
    className,
    icon,
    children,
    rightIcon,
    text
  } = _a, restProps = __objRest(_a, [
    "type",
    "view",
    "color",
    "isFill",
    "isLoading",
    "isDisabled",
    "isDense",
    "active",
    "className",
    "icon",
    "children",
    "rightIcon",
    "text"
  ]);
  const buttonClassname = cx3(color_module_default[color], {
    [AnchorButtonView_module_default[view]]: true,
    [AnchorButtonView_module_default["focused"]]: active,
    [AnchorButtonType_module_default["dense"]]: isDense,
    [AnchorButtonType_module_default[type]]: true,
    [AnchorButtonType_module_default["fill"]]: isFill
  }, className);
  return /* @__PURE__ */ React4.createElement(AnchorButtonSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: buttonClassname,
    fill: isFill,
    loading: isLoading,
    disabled: isDisabled,
    minimal: false,
    rightIcon,
    icon: icon && /* @__PURE__ */ React4.createElement(Icon3, {
      icon,
      className: type !== "circle" ? AnchorButtonType_module_default.leftIcon : void 0,
      iconSize: isDense ? type === "action" ? 24 : type === "circle" ? 20 : 16 : type === "action" ? 32 : type === "circle" ? 24 : 20
    })
  }), children || text);
});
var AnchorButtonSP_default = AnchorButtonSP;

// src/AnchorButton/AnchorButton.tsx
var AnchorButton = React5.forwardRef((props, ref) => {
  const _a = props, { intent = "tertiary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP[intent];
  const color = COLOR_MAP[intent];
  return /* @__PURE__ */ React5.createElement(AnchorButtonSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var AnchorButton_default = AnchorButton;
var VIEW_MAP = {
  secondary: "smooth",
  primary: "filled",
  outlined: "outlined",
  tertiary: "flat",
  success: "flat",
  danger: "flat",
  array_primary: "raised",
  array_default: "raised"
};
var COLOR_MAP = {
  secondary: "primary",
  primary: "primary",
  tertiary: "default",
  outlined: "primary",
  success: "success",
  danger: "danger",
  array_primary: "primary",
  array_default: "default"
};

// src/Badge/Badge.tsx
import React7 from "react";

// src/Badge/BadgeSP.tsx
import cx4 from "classnames";
import React6 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Badge/Badge.module.scss
var Badge_module_default = {
  "container": "_container_6xhjf_7",
  "wrapper": "_wrapper_6xhjf_14",
  "position": "_position_6xhjf_25",
  "right": "_right_6xhjf_37",
  "top": "_top_6xhjf_43",
  "left": "_left_6xhjf_49",
  "bottom": "_bottom_6xhjf_55",
  "show": "_show_6xhjf_63",
  "hide": "_hide_6xhjf_68",
  "default": "_default_6xhjf_75 _wrapper_6xhjf_14 _position_6xhjf_25",
  "dense": "_dense_6xhjf_84 _wrapper_6xhjf_14 _position_6xhjf_25",
  "dot": "_dot_6xhjf_93 _wrapper_6xhjf_14 _position_6xhjf_25"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Badge/BadgeView.module.scss
var BadgeView_module_default = {
  "filled": "_filled_1trco_1",
  "smooth": "_smooth_1trco_7",
  "outlined": "_outlined_1trco_13"
};

// src/Badge/BadgeSP.tsx
function BadgeSP(props) {
  const _a = props, {
    Component,
    label = "",
    view = "filled",
    color = "danger",
    isDense = false,
    dot = false,
    hide = false,
    position,
    children,
    className
  } = _a, rest = __objRest(_a, [
    "Component",
    "label",
    "view",
    "color",
    "isDense",
    "dot",
    "hide",
    "position",
    "children",
    "className"
  ]);
  const badgeClasses = cx4(dot ? Badge_module_default["dot"] : isDense ? Badge_module_default["dense"] : Badge_module_default["default"], (position || "").includes("left") ? Badge_module_default["left"] : Badge_module_default["right"], (position || "").includes("bottom") ? Badge_module_default["bottom"] : Badge_module_default["top"], hide ? Badge_module_default["hide"] : Badge_module_default["show"], BadgeView_module_default[view], color_module_default[color]);
  return /* @__PURE__ */ React6.createElement(Component, __spreadProps(__spreadValues({}, rest), {
    className: cx4(Badge_module_default["container"], className)
  }), children, /* @__PURE__ */ React6.createElement("span", {
    className: badgeClasses
  }, !dot && label));
}
var BadgeSP_default = BadgeSP;

// src/Badge/Badge.tsx
var Badge = (props) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP2[intent];
  const color = COLOR_MAP2[intent];
  return /* @__PURE__ */ React7.createElement(BadgeSP_default, __spreadValues({
    view,
    color
  }, restProps));
};
var Badge_default = Badge;
var VIEW_MAP2 = {
  danger: "filled",
  primary: "filled"
};
var COLOR_MAP2 = {
  primary: "primary",
  danger: "danger"
};

// src/Breadcrumbs/Breadcrumbs.tsx
import cx5 from "classnames";
import React8 from "react";
import {
  Breadcrumbs as BreadcrumbsSource
} from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Breadcrumbs/BreadcrumbsType.module.scss
var BreadcrumbsType_module_default = {
  "bread": "_bread_13kj5_1",
  "bp4-breadcrumb-current": "_bp4-breadcrumb-current_13kj5_1",
  "default": "_default_13kj5_6"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Breadcrumbs/BreadcrumbsView.module.scss
var BreadcrumbsView_module_default = {
  "primary": "_primary_k014g_2",
  "success": "_success_k014g_6",
  "warning": "_warning_k014g_10",
  "danger": "_danger_k014g_14",
  "default": "_default_k014g_18",
  "primary_alt": "_primary_alt_k014g_22",
  "success_alt": "_success_alt_k014g_26",
  "warning_alt": "_warning_alt_k014g_30",
  "danger_alt": "_danger_alt_k014g_34",
  "smooth": "_smooth_k014g_77",
  "popover": "_popover_k014g_100"
};

// src/Breadcrumbs/Breadcrumbs.tsx
var Breadcrumbs = React8.forwardRef((props, ref) => {
  const _a = props, {
    view = "smooth",
    color = "primary",
    isDense,
    className
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "isDense",
    "className"
  ]);
  return /* @__PURE__ */ React8.createElement(BreadcrumbsSource, __spreadProps(__spreadValues({}, restProps), {
    className: cx5(BreadcrumbsType_module_default.bread, BreadcrumbsType_module_default[isDense ? "dense" : "def"], BreadcrumbsView_module_default[view], BreadcrumbsView_module_default[color], color_module_default[color], className),
    popoverProps: {
      popoverClassName: cx5(BreadcrumbsView_module_default["popover"], color_module_default[color])
    },
    ref
  }));
});
var Breadcrumbs_default = Breadcrumbs;

// src/Button/Button.tsx
import React9 from "react";
function Button(props) {
  const _a = props, { intent = "tertiary", isDense } = _a, restProps = __objRest(_a, ["intent", "isDense"]);
  const view = VIEW_MAP3[intent];
  const color = COLOR_MAP3[intent];
  return /* @__PURE__ */ React9.createElement(ButtonSP, __spreadValues({
    view,
    color,
    isDense: intent.includes("array") ? true : isDense
  }, restProps));
}
var VIEW_MAP3 = {
  secondary: "smooth",
  primary: "filled",
  outlined: "outlined",
  tertiary: "flat",
  success: "flat",
  danger: "flat",
  array_primary: "raised",
  array_default: "raised"
};
var COLOR_MAP3 = {
  secondary: "primary",
  primary: "primary",
  tertiary: "default",
  outlined: "primary",
  success: "success",
  danger: "danger",
  array_primary: "primary",
  array_default: "default"
};

// src/ButtonGroup/ButtonGroup.tsx
import React11 from "react";

// src/ButtonGroup/ButtonGroupSP.tsx
import cx6 from "classnames";
import React10 from "react";
import {
  ButtonGroup as ButtonGroupKitSource
} from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/ButtonGroup/ButtonGroupType.module.scss
var ButtonGroupType_module_default = {
  "default": "_default_1en8h_3",
  "icon": "_icon_1en8h_4",
  "dense": "_dense_1en8h_13",
  "right": "_right_1en8h_34",
  "bp4-divider": "_bp4-divider_1en8h_38"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/ButtonGroup/ButtonGroupView.module.scss
var ButtonGroupView_module_default = {
  "flat": "_flat_120gh_2",
  "filled": "_filled_120gh_28",
  "smooth": "_smooth_120gh_54",
  "outlined": "_outlined_120gh_80",
  "raised": "_raised_120gh_110"
};

// src/ButtonGroup/ButtonGroupSP.tsx
var ButtonGroupSP = React10.forwardRef((props, ref) => {
  const _a = props, {
    isDense = false,
    view = "flat",
    color = "default",
    className,
    isFill,
    displayAtRight
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "view",
    "color",
    "className",
    "isFill",
    "displayAtRight"
  ]);
  return /* @__PURE__ */ React10.createElement(ButtonGroupKitSource, __spreadProps(__spreadValues({
    ref,
    fill: isFill
  }, restProps), {
    className: cx6(!isDense && ButtonGroupType_module_default["default"], isDense && ButtonGroupType_module_default["dense"], displayAtRight && ButtonGroupType_module_default["right"], ButtonGroupView_module_default[view], color_module_default[color], className)
  }));
});
var ButtonGroupSP_default = ButtonGroupSP;

// src/ButtonGroup/ButtonGroup.tsx
var ButtonGroup = React11.forwardRef((props, ref) => {
  const _a = props, { intent = "tertiary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP4[intent];
  const color = COLOR_MAP4[intent];
  return /* @__PURE__ */ React11.createElement(ButtonGroupSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var ButtonGroup_default = ButtonGroup;
var VIEW_MAP4 = {
  secondary: "smooth",
  primary: "filled",
  outlined: "outlined",
  tertiary: "flat",
  success: "flat",
  danger: "flat"
};
var COLOR_MAP4 = {
  secondary: "primary",
  primary: "primary",
  tertiary: "default",
  outlined: "default",
  success: "success",
  danger: "danger"
};

// src/Callout/Callout.tsx
import React13 from "react";

// src/Callout/CalloutSP.tsx
import {
  Callout as CalloutSource,
  Icon as Icon4
} from "@blueprintjs/core";
import cx7 from "classnames";
import React12 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Callout/CalloutType.module.scss
var CalloutType_module_default = {
  "def": "_def_u9j51_2",
  "dense": "_dense_u9j51_8",
  "callout": "_callout_u9j51_14"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Callout/CalloutView.module.scss
var CalloutView_module_default = {
  "filled": "_filled_1994q_3",
  "smooth": "_smooth_1994q_15",
  "outlined": "_outlined_1994q_27",
  "raised": "_raised_1994q_43"
};

// src/Callout/CalloutSP.tsx
var CalloutSP = React12.forwardRef((props, ref) => {
  const _a = props, {
    view = "smooth",
    color = "primary",
    children,
    className,
    icon,
    isDense = false
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "children",
    "className",
    "icon",
    "isDense"
  ]);
  return /* @__PURE__ */ React12.createElement(CalloutSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx7(CalloutType_module_default[isDense ? "dense" : "def"], CalloutView_module_default[view], color_module_default[color], className),
    icon: icon && /* @__PURE__ */ React12.createElement(Icon4, {
      icon,
      className: CalloutType_module_default.callout,
      iconSize: isDense ? 16 : 24
    })
  }), children);
});
var CalloutSP_default = CalloutSP;

// src/Callout/Callout.tsx
var Callout = React13.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP5[intent];
  const color = COLOR_MAP5[intent];
  return /* @__PURE__ */ React13.createElement(CalloutSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Callout_default = Callout;
var VIEW_MAP5 = {
  danger: "smooth",
  primary: "smooth",
  success: "smooth",
  default: "smooth",
  warning: "smooth"
};
var COLOR_MAP5 = {
  danger: "danger",
  primary: "primary",
  success: "success",
  default: "default",
  warning: "warning_callout"
};

// src/card/Card.tsx
import { Icon as Icon5 } from "@blueprintjs/core";
import classNames2 from "classnames";
import React14 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/card/Card.module.scss
var Card_module_default = {
  "card": "_card_lxbsj_1",
  "active": "_active_lxbsj_7",
  "interactive": "_interactive_lxbsj_11",
  "header": "_header_lxbsj_18",
  "unfolded": "_unfolded_lxbsj_29",
  "title": "_title_lxbsj_33",
  "actions": "_actions_lxbsj_47"
};

// src/card/Card.tsx
function Card(props) {
  const {
    children,
    className,
    headerActions,
    intent = "default",
    isActive,
    isFolded,
    onClick,
    onToggleFold,
    title,
    titleClassName,
    icon
  } = props;
  const handleHeaderClick = (event) => {
    event.stopPropagation();
  };
  const handleToggleFold = (event) => {
    event.stopPropagation();
    onToggleFold && onToggleFold();
  };
  return /* @__PURE__ */ React14.createElement("div", {
    className: classNames2(color_module_default[intent], {
      [Card_module_default.card]: true,
      [Card_module_default.active]: isActive,
      [Card_module_default.interactive]: !!onClick
    }, className),
    onClick
  }, (icon || title || headerActions) && /* @__PURE__ */ React14.createElement("div", {
    className: classNames2(Card_module_default.header, {
      [Card_module_default.unfolded]: !isFolded && !!children
    })
  }, onToggleFold && /* @__PURE__ */ React14.createElement(Button, {
    isDense: true,
    icon: isFolded ? "chevron-right" : "chevron-down",
    onClick: handleToggleFold
  }), icon && /* @__PURE__ */ React14.createElement(Icon5, {
    icon
  }), title && /* @__PURE__ */ React14.createElement("div", {
    className: classNames2(Card_module_default.title, titleClassName)
  }, title), headerActions && /* @__PURE__ */ React14.createElement("div", {
    className: Card_module_default.actions,
    onClick: handleHeaderClick
  }, headerActions)), !isFolded && children);
}

// src/card/CardList.tsx
import classNames3 from "classnames";
import React15 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/card/CardList.module.scss
var CardList_module_default = {
  "list": "_list_b6e93_1"
};

// src/card/CardList.tsx
function CardList(props) {
  const { children, gap = 8, className } = props;
  return /* @__PURE__ */ React15.createElement("div", {
    className: classNames3(CardList_module_default.list, className),
    style: { gap: `${gap}px` }
  }, children);
}

// src/CheckBox/CheckBox.tsx
import React17 from "react";

// src/CheckBox/CheckBoxSP.tsx
import cx8 from "classnames";
import React16 from "react";
import { Checkbox as CheckboxSource } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/CheckBox/CheckBoxType.module.scss
var CheckBoxType_module_default = {
  "container": "_container_60mkl_3",
  "fill": "_fill_60mkl_16",
  "box_layout": "_box_layout_60mkl_20",
  "text": "_text_60mkl_25",
  "right": "_right_60mkl_31",
  "def": "_def_60mkl_37",
  "with_helper": "_with_helper_60mkl_50",
  "checked": "_checked_60mkl_81",
  "dense": "_dense_60mkl_82",
  "indeterminate": "_indeterminate_60mkl_141",
  "helperBox": "_helperBox_60mkl_153",
  "helperText": "_helperText_60mkl_159",
  "rtl": "_rtl_60mkl_167"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/CheckBox/CheckBoxView.module.scss
var CheckBoxView_module_default = {
  "flat": "_flat_1jx9u_2",
  "box_layout": "_box_layout_1jx9u_6",
  "indeterminate": "_indeterminate_1jx9u_23",
  "checked": "_checked_1jx9u_24",
  "has_label": "_has_label_1jx9u_24",
  "disabled": "_disabled_1jx9u_50",
  "helperText": "_helperText_1jx9u_77",
  "smooth": "_smooth_1jx9u_84",
  "outlined": "_outlined_1jx9u_172",
  "raised": "_raised_1jx9u_257"
};

// src/CheckBox/CheckBoxSP.tsx
var CheckboxSP = React16.forwardRef((props, ref) => {
  const _a = props, {
    view = "flat",
    color = "primary",
    checked,
    indeterminate,
    label,
    margin = 0,
    isDisabled,
    helperText,
    fill = false,
    rtl,
    right = false,
    className,
    isDense
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "checked",
    "indeterminate",
    "label",
    "margin",
    "isDisabled",
    "helperText",
    "fill",
    "rtl",
    "right",
    "className",
    "isDense"
  ]);
  return /* @__PURE__ */ React16.createElement("div", {
    className: cx8(CheckBoxType_module_default["container"], CheckBoxView_module_default["container"], checked && CheckBoxView_module_default["checked"], label && label !== "" && CheckBoxView_module_default["has_label"], isDisabled && CheckBoxView_module_default["disabled"], fill && CheckBoxType_module_default["fill"], color_module_default[color]),
    style: margin ? { margin } : {}
  }, /* @__PURE__ */ React16.createElement(CheckboxSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx8(CheckBoxType_module_default[isDense ? "dense" : "def"], CheckBoxView_module_default[view], color_module_default[color], checked && CheckBoxType_module_default["checked"], checked && CheckBoxView_module_default["checked"], label && label !== "" && CheckBoxView_module_default["has_label"], isDisabled && CheckBoxView_module_default["disabled"], helperText && CheckBoxType_module_default["with_helper"], rtl && CheckBoxType_module_default["rtl"], right && CheckBoxType_module_default["right"], indeterminate && CheckBoxType_module_default["indeterminate"], indeterminate && CheckBoxView_module_default["indeterminate"], !isDense && CheckBoxType_module_default["large"], className),
    checked,
    indeterminate,
    disabled: isDisabled,
    alignIndicator: right ? "right" : void 0
  }), /* @__PURE__ */ React16.createElement("div", {
    className: cx8(CheckBoxType_module_default["box_layout"], CheckBoxView_module_default["box_layout"])
  }), (helperText || label) && /* @__PURE__ */ React16.createElement("div", {
    className: cx8(helperText && CheckBoxType_module_default["helperBox"], isDisabled && CheckBoxView_module_default["disabled"], CheckBoxType_module_default["text"])
  }, label, helperText && /* @__PURE__ */ React16.createElement("div", {
    className: cx8(CheckBoxType_module_default["helperText"], CheckBoxView_module_default["helperText"])
  }, helperText))));
});
var CheckBoxSP_default = CheckboxSP;

// src/CheckBox/CheckBox.tsx
var CheckBox = React17.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP6[intent];
  const color = COLOR_MAP6[intent];
  return /* @__PURE__ */ React17.createElement(CheckBoxSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var CheckBox_default = CheckBox;
var VIEW_MAP6 = {
  primary: "flat",
  default: "flat"
};
var COLOR_MAP6 = {
  primary: "primary",
  default: "default"
};

// src/Counter/Counter.tsx
import React19 from "react";

// src/Counter/CounterSP.tsx
import cx9 from "classnames";
import React18 from "react";
import { Button as Button2 } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Counter/CounterType.module.scss
var CounterType_module_default = {
  "default": "_default_1fwp3_3",
  "square": "_square_1fwp3_12 _default_1fwp3_3",
  "button": "_button_1fwp3_17",
  "dense": "_dense_1fwp3_24",
  "horizontal": "_horizontal_1fwp3_31",
  "vertical": "_vertical_1fwp3_36",
  "value": "_value_1fwp3_43",
  "verticalValue": "_verticalValue_1fwp3_59",
  "denseValue": "_denseValue_1fwp3_66"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Counter/CounterView.module.scss
var CounterView_module_default = {
  "flat": "_flat_1eku8_2",
  "focused": "_focused_1eku8_23",
  "filled": "_filled_1eku8_41",
  "smooth": "_smooth_1eku8_71",
  "outlined": "_outlined_1eku8_102",
  "raised": "_raised_1eku8_132"
};

// src/Counter/CounterSP.tsx
var CounterSP = React18.forwardRef((props, ref) => {
  const _a = props, {
    view = "smooth",
    color = "primary",
    isDense,
    leftIcon,
    rightIcon,
    value,
    isActive,
    isVertical,
    className,
    onClick
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "isDense",
    "leftIcon",
    "rightIcon",
    "value",
    "isActive",
    "isVertical",
    "className",
    "onClick"
  ]);
  const handleClick = (type) => () => {
    onClick(type);
  };
  return /* @__PURE__ */ React18.createElement("div", {
    className: isVertical ? CounterType_module_default["vertical"] : CounterType_module_default["horizontal"]
  }, /* @__PURE__ */ React18.createElement(Button2, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx9(CounterType_module_default.button, CounterType_module_default[isDense ? "dense" : "default"], CounterView_module_default[view], color_module_default[color], isActive && CounterView_module_default["focused"], className),
    icon: rightIcon || isVertical ? "chevron-down" : "minus",
    onClick: handleClick("decrement")
  })), /* @__PURE__ */ React18.createElement("div", {
    className: cx9(CounterType_module_default["value"], isDense && CounterType_module_default["denseValue"], isVertical && CounterType_module_default["verticalValue"])
  }, value), /* @__PURE__ */ React18.createElement(Button2, __spreadProps(__spreadValues({}, restProps), {
    className: cx9(CounterType_module_default.button, CounterType_module_default[isDense ? "dense" : "default"], CounterView_module_default[view], color_module_default[color], isActive && CounterView_module_default["focused"], className),
    icon: leftIcon || isVertical ? "chevron-up" : "plus",
    onClick: handleClick("increment")
  })));
});
var CounterSP_default = CounterSP;

// src/Counter/Counter.tsx
var Counter = React19.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP7[intent];
  const color = COLOR_MAP7[intent];
  return /* @__PURE__ */ React19.createElement(CounterSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Counter_default = Counter;
var VIEW_MAP7 = {
  primary: "smooth"
};
var COLOR_MAP7 = {
  primary: "primary"
};

// src/DatePicker/DatePicker.tsx
import React21 from "react";

// src/DatePicker/DatePickerSP.tsx
import { dateLocalizationHelper } from "@aletiq/translation";
import {
  DatePicker as DatePickerSource
} from "@blueprintjs/datetime";
import cx10 from "classnames";
import React20 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/DatePicker/DatePickerType.module.scss
var DatePickerType_module_default = {
  "default": "_default_1a3kb_1"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/DatePicker/DatePickerView.module.scss
var DatePickerView_module_default = {
  "flat": "_flat_17pyn_2",
  "smooth": "_smooth_17pyn_101",
  "raised": "_raised_17pyn_231"
};

// src/DatePicker/DatePickerSP.tsx
var DatePickerSP = React20.forwardRef(function DatePicker(props, ref) {
  const _a = props, {
    view = "raised",
    color = "primary",
    active,
    className,
    dayPickerProps
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "active",
    "className",
    "dayPickerProps"
  ]);
  return /* @__PURE__ */ React20.createElement(DatePickerSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx10(DatePickerType_module_default["default"], DatePickerView_module_default[view], color_module_default[color], active && DatePickerView_module_default["focused"], className),
    dayPickerProps: __spreadProps(__spreadValues({}, dayPickerProps), {
      locale: navigator.language,
      localeUtils: dateLocalizationHelper
    })
  }));
});
var DatePickerSP_default = DatePickerSP;

// src/DatePicker/DatePicker.tsx
var DatePicker2 = React21.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP8[intent];
  const color = COLOR_MAP8[intent];
  return /* @__PURE__ */ React21.createElement(DatePickerSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var DatePicker_default = DatePicker2;
var VIEW_MAP8 = {
  primary: "raised"
};
var COLOR_MAP8 = {
  primary: "primary"
};

// src/DateRangePicker/DateRangePicker.tsx
import React23 from "react";

// src/DateRangePicker/DateRangePickerSP.tsx
import { dateLocalizationHelper as dateLocalizationHelper2 } from "@aletiq/translation";
import {
  DateRangePicker as DateRangePickerSource
} from "@blueprintjs/datetime";
import cx11 from "classnames";
import React22 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/DateRangePicker/DateRangePickerType.module.scss
var DateRangePickerType_module_default = {
  "default": "_default_1c1cr_1"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/DateRangePicker/DateRangePickerView.module.scss
var DateRangePickerView_module_default = {
  "flat": "_flat_1fxrg_2",
  "smooth": "_smooth_1fxrg_149",
  "raised": "_raised_1fxrg_307"
};

// src/DateRangePicker/DateRangePickerSP.tsx
var DateRangePickerSP = React22.forwardRef((props, ref) => {
  const _a = props, {
    view = "raised",
    color = "primary",
    className,
    dayPickerProps,
    maxDate,
    minDate
  } = _a, rest = __objRest(_a, [
    "view",
    "color",
    "className",
    "dayPickerProps",
    "maxDate",
    "minDate"
  ]);
  const today = new Date();
  const PreviousTenYear = new Date();
  PreviousTenYear.setFullYear(today.getFullYear() - 5);
  const NextTenYear = new Date();
  NextTenYear.setFullYear(today.getFullYear() + 5);
  return /* @__PURE__ */ React22.createElement(DateRangePickerSource, __spreadProps(__spreadValues({}, rest), {
    ref,
    className: cx11(DateRangePickerType_module_default["default"], DateRangePickerView_module_default[view], color_module_default[color], className),
    maxDate: maxDate || NextTenYear,
    minDate: minDate || PreviousTenYear,
    dayPickerProps: __spreadProps(__spreadValues({}, dayPickerProps), {
      locale: navigator.language,
      localeUtils: dateLocalizationHelper2
    })
  }));
});
var DateRangePickerSP_default = DateRangePickerSP;

// src/DateRangePicker/DateRangePicker.tsx
var DateRangePicker = React23.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP9[intent];
  const color = COLOR_MAP9[intent];
  return /* @__PURE__ */ React23.createElement(DateRangePickerSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var DateRangePicker_default = DateRangePicker;
var VIEW_MAP9 = {
  primary: "raised"
};
var COLOR_MAP9 = {
  primary: "primary"
};

// src/Dialog/Dialog.tsx
import { useTranslations as useTranslations2 } from "@aletiq/translation";
import {
  Dialog as BPDialog
} from "@blueprintjs/core";
import { default as classNames5 } from "classnames";
import React25 from "react";

// src/Icon/Icon.tsx
import { Icon as IconSource } from "@blueprintjs/core";
import classNames4 from "classnames";
import React24 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Icon/Icon.module.scss
var Icon_module_default = {
  "icon": "_icon_18jrp_1",
  "clickable": "_clickable_18jrp_8",
  "none": "_none_18jrp_12",
  "primary": "_primary_18jrp_17",
  "success": "_success_18jrp_22",
  "warning": "_warning_18jrp_27",
  "danger": "_danger_18jrp_32"
};

// src/Icon/Icon.tsx
function Icon6(props) {
  const _a = props, { inline, clickable, intent = "none", className } = _a, restProps = __objRest(_a, ["inline", "clickable", "intent", "className"]);
  return /* @__PURE__ */ React24.createElement("div", {
    className: classNames4({ [Icon_module_default.icon]: inline, [Icon_module_default.clickable]: clickable }, className)
  }, /* @__PURE__ */ React24.createElement(IconSource, __spreadValues({
    className: classNames4({ [Icon_module_default[intent]]: true })
  }, restProps)));
}

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Dialog/DialogType.module.scss
var DialogType_module_default = {
  "default": "_default_1aqu5_3",
  "full_page": "_full_page_1aqu5_9",
  "content": "_content_1aqu5_14",
  "title": "_title_1aqu5_18",
  "header": "_header_1aqu5_27",
  "footer": "_footer_1aqu5_49",
  "dense": "_dense_1aqu5_58",
  "close-button": "_close-button_1aqu5_99",
  "hint": "_hint_1aqu5_106"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Dialog/DialogView.module.scss
var DialogView_module_default = {
  "smooth": "_smooth_u5eqw_3",
  "raised": "_raised_u5eqw_9",
  "filled": "_filled_u5eqw_15",
  "backdrop-0": "_backdrop-0_u5eqw_60",
  "backdrop-10": "_backdrop-10_u5eqw_64",
  "backdrop-20": "_backdrop-20_u5eqw_68",
  "backdrop-30": "_backdrop-30_u5eqw_72",
  "backdrop-40": "_backdrop-40_u5eqw_76",
  "backdrop-50": "_backdrop-50_u5eqw_80",
  "backdrop-60": "_backdrop-60_u5eqw_84",
  "backdrop-70": "_backdrop-70_u5eqw_88",
  "backdrop-80": "_backdrop-80_u5eqw_92",
  "backdrop-90": "_backdrop-90_u5eqw_96",
  "backdrop-100": "_backdrop-100_u5eqw_100"
};

// src/Dialog/Dialog.tsx
function Dialog(props) {
  const _a = props, {
    isDense,
    intent = "primary",
    icon,
    title,
    children,
    primaryText,
    secondaryText,
    disablePrimary,
    submitting,
    onClose,
    onPrimaryClick,
    onSecondaryClick
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "intent",
    "icon",
    "title",
    "children",
    "primaryText",
    "secondaryText",
    "disablePrimary",
    "submitting",
    "onClose",
    "onPrimaryClick",
    "onSecondaryClick"
  ]);
  const primaryButtonProps = {
    text: primaryText,
    onClick: onPrimaryClick,
    isDisabled: disablePrimary,
    isLoading: submitting
  };
  const secondaryButtonProps = {
    text: secondaryText,
    onClick: onSecondaryClick ?? onClose
  };
  return /* @__PURE__ */ React25.createElement(DialogBase, __spreadProps(__spreadValues({}, restProps), {
    isDense,
    intent,
    onClose
  }), /* @__PURE__ */ React25.createElement(DialogHeader, {
    icon,
    title,
    isDense,
    onClose
  }), /* @__PURE__ */ React25.createElement(DialogContent, null, children), /* @__PURE__ */ React25.createElement(DialogFooter, {
    intent,
    isDense,
    primaryButtonProps,
    secondaryButtonProps
  }));
}
function DialogBase(props) {
  const _a = props, {
    isDense,
    isFullPage,
    view = "raised",
    intent = "primary",
    children,
    backdropOpacity = "20",
    className,
    onClose
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "isFullPage",
    "view",
    "intent",
    "children",
    "backdropOpacity",
    "className",
    "onClose"
  ]);
  return /* @__PURE__ */ React25.createElement(BPDialog, __spreadProps(__spreadValues({}, restProps), {
    className: classNames5(DialogType_module_default[isDense ? "dense" : "default"], isFullPage && DialogType_module_default["full_page"], DialogView_module_default[view], color_module_default[intent], className),
    backdropClassName: DialogView_module_default[`backdrop-${backdropOpacity}`],
    onClose
  }), children);
}
function DialogHint(props) {
  const { children } = props;
  return /* @__PURE__ */ React25.createElement("div", {
    className: DialogType_module_default["hint"]
  }, children);
}
function DialogHeader(props) {
  const { isDense, icon, title, onClose } = props;
  return /* @__PURE__ */ React25.createElement(DialogHeaderBase, null, /* @__PURE__ */ React25.createElement(Icon6, {
    icon,
    iconSize: isDense ? 20 : 24
  }), /* @__PURE__ */ React25.createElement(DialogTitle, null, title), /* @__PURE__ */ React25.createElement(DialogCloseButton, {
    isDense,
    onClick: onClose
  }));
}
function DialogFooter(props) {
  const {
    intent = "primary",
    isDense,
    primaryButtonProps,
    secondaryButtonProps,
    tertiaryButtonProps
  } = props;
  const tr = useTranslations2();
  return /* @__PURE__ */ React25.createElement(DialogFooterBase, null, secondaryButtonProps && /* @__PURE__ */ React25.createElement(ButtonSP, {
    color: intent,
    isDense,
    isDisabled: secondaryButtonProps.isDisabled,
    isLoading: secondaryButtonProps.isLoading,
    view: "flat",
    onClick: secondaryButtonProps.onClick
  }, secondaryButtonProps.text || tr.translateAsString("generic.action.cancel")), tertiaryButtonProps && /* @__PURE__ */ React25.createElement(ButtonSP, {
    color: intent,
    isDense,
    isDisabled: tertiaryButtonProps.isDisabled,
    isLoading: tertiaryButtonProps.isLoading,
    view: "flat",
    onClick: tertiaryButtonProps.onClick
  }, tertiaryButtonProps.text || tr.translateAsString("generic.action.reset")), primaryButtonProps && /* @__PURE__ */ React25.createElement(ButtonSP, {
    color: intent,
    isDense,
    isDisabled: primaryButtonProps.isDisabled,
    isLoading: primaryButtonProps.isLoading,
    view: "smooth",
    onClick: primaryButtonProps.onClick
  }, primaryButtonProps.text || tr.translateAsString("generic.action.submit")));
}
function DialogHeaderBase(props) {
  const _a = props, { className } = _a, other = __objRest(_a, ["className"]);
  return /* @__PURE__ */ React25.createElement("div", __spreadValues({
    className: classNames5(DialogType_module_default["header"], className)
  }, other));
}
function DialogTitle(props) {
  const _a = props, { className } = _a, other = __objRest(_a, ["className"]);
  return /* @__PURE__ */ React25.createElement("div", __spreadValues({
    className: classNames5(DialogType_module_default["title"], className)
  }, other));
}
function DialogCloseButton(props) {
  const { isDense = "false", onClick } = props;
  return /* @__PURE__ */ React25.createElement("div", {
    className: DialogType_module_default["close-button"],
    onClick
  }, /* @__PURE__ */ React25.createElement(Icon6, {
    icon: "cross",
    iconSize: isDense ? 20 : 24
  }));
}
function DialogContent(props) {
  const _a = props, { className } = _a, other = __objRest(_a, ["className"]);
  return /* @__PURE__ */ React25.createElement("div", __spreadValues({
    className: classNames5(DialogType_module_default["content"], className)
  }, other));
}
function DialogFooterBase(props) {
  const _a = props, { className } = _a, other = __objRest(_a, ["className"]);
  return /* @__PURE__ */ React25.createElement("div", __spreadValues({
    className: classNames5(DialogType_module_default["footer"], className)
  }, other));
}

// src/Dialog2/Dialog2.tsx
import React26 from "react";
function Dialog2(props) {
  const _a = props, {
    isDense,
    intent = "primary",
    icon,
    title,
    children,
    primaryButtonProps,
    secondaryButtonProps,
    tertiaryButtonProps,
    onClose
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "intent",
    "icon",
    "title",
    "children",
    "primaryButtonProps",
    "secondaryButtonProps",
    "tertiaryButtonProps",
    "onClose"
  ]);
  return /* @__PURE__ */ React26.createElement(DialogBase, __spreadProps(__spreadValues({}, restProps), {
    isDense,
    intent,
    onClose
  }), /* @__PURE__ */ React26.createElement(DialogHeader, {
    icon,
    title,
    isDense,
    onClose
  }), /* @__PURE__ */ React26.createElement(DialogContent, null, children), /* @__PURE__ */ React26.createElement(DialogFooter, {
    intent,
    isDense,
    primaryButtonProps,
    secondaryButtonProps,
    tertiaryButtonProps
  }));
}

// src/Divider/Divider.tsx
import React28 from "react";

// src/Divider/DividerSP.tsx
import cx12 from "classnames";
import React27 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Divider/DividerType.module.scss
var DividerType_module_default = {
  "default": "_default_886xy_1",
  "vertical": "_vertical_886xy_6"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Divider/DividerView.module.scss
var DividerView_module_default = {
  "color0": "_color0_5znae_1",
  "color5": "_color5_5znae_5",
  "color10": "_color10_5znae_9",
  "color20": "_color20_5znae_13",
  "color30": "_color30_5znae_17",
  "color40": "_color40_5znae_21",
  "color50": "_color50_5znae_25",
  "color60": "_color60_5znae_29",
  "color70": "_color70_5znae_33",
  "color80": "_color80_5znae_37",
  "color90": "_color90_5znae_41",
  "color100": "_color100_5znae_45"
};

// src/Divider/DividerSP.tsx
var DividerSP = React27.forwardRef((props, ref) => {
  const {
    color = "default",
    size = 2,
    colorStep = 5,
    className,
    isVertical = false,
    style
  } = props;
  return /* @__PURE__ */ React27.createElement("div", {
    ref,
    className: cx12(DividerType_module_default["default"], isVertical && DividerType_module_default["vertical"], DividerView_module_default[`color${colorStep}`], color_module_default[color], className && className),
    style: !isVertical ? __spreadValues({ height: `${size}px`, margin: `${size}px 0` }, style) : __spreadValues({ width: `${size}px`, margin: `0 ${size}px` }, style)
  });
});
var DividerSP_default = DividerSP;

// src/Divider/Divider.tsx
var Divider = React28.forwardRef((props, ref) => {
  const _a = props, { intent = "default" } = _a, restProps = __objRest(_a, ["intent"]);
  const color = COLOR_MAP10[intent];
  return /* @__PURE__ */ React28.createElement(DividerSP_default, __spreadValues({
    color,
    ref
  }, restProps));
});
var Divider_default = Divider;
var COLOR_MAP10 = {
  default: "default",
  primary: "primary"
};

// src/Dropdown/DropdownSP.tsx
import cx13 from "classnames";
import React29 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Dropdown/_type.module.css
var type_module_default = {
  "dropdown": "_dropdown_1dq0l_1",
  "dropdown--dense": "_dropdown--dense_1dq0l_3",
  "fill": "_fill_1dq0l_89"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Dropdown/_view.module.css
var view_module_default = {
  "popover": "_popover_18e28_1",
  "outlined": "_outlined_18e28_19",
  "raised": "_raised_18e28_21",
  "smooth": "_smooth_18e28_23",
  "filled": "_filled_18e28_55"
};

// src/Dropdown/DropdownSP.tsx
var DropdownSP = React29.forwardRef((props, ref) => {
  const _a = props, { content, position = "bottom-left" } = _a, buttonProps = __objRest(_a, ["content", "position"]);
  return /* @__PURE__ */ React29.createElement(Popover2_default, {
    targetTagName: "div",
    content,
    position
  }, /* @__PURE__ */ React29.createElement(DropdownButton, __spreadProps(__spreadValues({}, buttonProps), {
    ref
  })));
});
var DropdownSP_default = DropdownSP;
var DropdownButton = React29.forwardRef((props, ref) => {
  const _a = props, {
    view = "outlined",
    dense = false,
    fill,
    text,
    intent = "default",
    className = ""
  } = _a, restProps = __objRest(_a, [
    "view",
    "dense",
    "fill",
    "text",
    "intent",
    "className"
  ]);
  return /* @__PURE__ */ React29.createElement("button", __spreadProps(__spreadValues({}, restProps), {
    ref,
    type: "button",
    className: cx13(dense ? type_module_default["dropdown--dense"] : type_module_default["dropdown"], fill && type_module_default["fill"], view_module_default[view], color_module_default[intent], className && className)
  }), text);
});

// src/EditableText/EditableText.tsx
import {
  EditableText as EditableTextSource
} from "@blueprintjs/core";
import cx14 from "classnames";
import React30, { useState } from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/EditableText/EditableTextType.module.scss
var EditableTextType_module_default = {
  "dense": "_dense_662w8_1",
  "icon": "_icon_662w8_5",
  "wrapper": "_wrapper_662w8_16",
  "multiline": "_multiline_662w8_22",
  "isFill": "_isFill_662w8_29"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/EditableText/EditableTextView.module.scss
var EditableTextView_module_default = {
  "outlined": "_outlined_1uw97_3"
};

// src/EditableText/EditableText.tsx
var EditableText = React30.forwardRef((props, ref) => {
  const _a = props, {
    isFill,
    className,
    onEdit,
    multiline,
    onConfirm,
    autoFocus = false,
    intent = "default"
  } = _a, restProps = __objRest(_a, [
    "isFill",
    "className",
    "onEdit",
    "multiline",
    "onConfirm",
    "autoFocus",
    "intent"
  ]);
  const [focusInput, setFocusInput] = useState(autoFocus);
  const wrapperClassname = cx14(EditableTextType_module_default.wrapper, color_module_default[COLOR_MAP11[intent]], {
    [EditableTextType_module_default.isFill]: isFill,
    [EditableTextType_module_default.multiline]: multiline
  }, className);
  const handleIconClick = () => {
    setFocusInput(true);
  };
  const handleEdit = (value) => {
    setFocusInput(true);
    onEdit && onEdit(value);
  };
  const handleConfirm = (value) => {
    setFocusInput(false);
    onConfirm && onConfirm(value);
  };
  return /* @__PURE__ */ React30.createElement("div", {
    className: wrapperClassname
  }, /* @__PURE__ */ React30.createElement(EditableTextSource, __spreadProps(__spreadValues({}, restProps), {
    multiline,
    isEditing: focusInput,
    ref,
    onEdit: handleEdit,
    onConfirm: handleConfirm,
    className: cx14(isFill && EditableTextType_module_default["isFill"], EditableTextType_module_default["dense"], EditableTextView_module_default["outlined"])
  })), /* @__PURE__ */ React30.createElement(Icon6, {
    icon: "edit",
    className: EditableTextType_module_default.icon,
    onClick: handleIconClick
  }));
});
var EditableText_default = EditableText;
var COLOR_MAP11 = {
  primary: "primary",
  default: "default",
  danger: "danger"
};

// src/Field/Field.tsx
import classnames2 from "classnames";
import React32 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Field/Field.module.scss
var Field_module_default = {
  "label": "_label_11ejv_1",
  "inline_label": "_inline_label_11ejv_12",
  "container": "_container_11ejv_23",
  "inline_container": "_inline_container_11ejv_27"
};

// src/Field/FieldLabel.tsx
import { Text } from "@blueprintjs/core";
import classnames from "classnames";
import React31 from "react";
function FieldLabel(props) {
  const { style, inline, className, children } = props;
  const labelClassname = classnames({
    [Field_module_default.inline_label]: inline,
    [Field_module_default.label]: !inline
  }, className);
  return /* @__PURE__ */ React31.createElement("div", {
    style,
    className: labelClassname
  }, /* @__PURE__ */ React31.createElement(Text, {
    ellipsize: true
  }, children));
}

// src/Field/Field.tsx
function Field(props) {
  const {
    className,
    inline,
    children,
    label,
    labelClassName,
    minWidth = 200,
    isMandatory
  } = props;
  const fieldClassname = classnames2(className, {
    [Field_module_default.inline_container]: inline,
    [Field_module_default.container]: !inline
  });
  return /* @__PURE__ */ React32.createElement("div", {
    className: fieldClassname
  }, label && /* @__PURE__ */ React32.createElement(FieldLabel, {
    inline,
    className: labelClassName,
    style: {
      minWidth: inline ? minWidth : void 0
    }
  }, label, isMandatory && "*"), children);
}

// src/FileInput/FileInput.tsx
import React34 from "react";

// src/FileInput/FileInputSP.tsx
import { useTranslations as useTranslations3 } from "@aletiq/translation";
import { FileInput } from "@blueprintjs/core";
import cx15 from "classnames";
import React33 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/FileInput/FileInputView.module.scss
var FileInputView_module_default = {
  "input": "_input_19u7a_1",
  "label": "_label_19u7a_5",
  "fileCounter": "_fileCounter_19u7a_15",
  "value": "_value_19u7a_62",
  "placehodler": "_placehodler_19u7a_65"
};

// src/FileInput/FileInputSP.tsx
var InputSP = React33.forwardRef((props, ref) => {
  const _a = props, {
    color = "primary",
    className,
    text,
    buttonText,
    label,
    onInputChange,
    files = [],
    inputProps,
    placeholder,
    hasSelection = files.length > 0,
    autoFocus = false
  } = _a, restProps = __objRest(_a, [
    "color",
    "className",
    "text",
    "buttonText",
    "label",
    "onInputChange",
    "files",
    "inputProps",
    "placeholder",
    "hasSelection",
    "autoFocus"
  ]);
  const tr = useTranslations3();
  const handleChange = (ev) => {
    onInputChange && onInputChange(ev);
  };
  const filesName = files == null ? void 0 : files.map((file) => file.name).join(", ");
  return /* @__PURE__ */ React33.createElement("div", {
    className: cx15(color_module_default[color], FileInputView_module_default.input)
  }, /* @__PURE__ */ React33.createElement("div", {
    className: FileInputView_module_default.label
  }, label), /* @__PURE__ */ React33.createElement(FileInput, __spreadProps(__spreadValues({}, restProps), {
    ref,
    onInputChange: handleChange,
    inputProps: __spreadProps(__spreadValues({}, inputProps), {
      className,
      autoFocus
    }),
    hasSelection,
    text: hasSelection ? /* @__PURE__ */ React33.createElement("span", {
      className: FileInputView_module_default.value
    }, filesName || text) : /* @__PURE__ */ React33.createElement("span", {
      className: FileInputView_module_default.placeholder
    }, placeholder || tr.translateAsString((inputProps == null ? void 0 : inputProps.multiple) ? "generic.file-input.placeholder-multi" : "generic.file-input.placeholder")),
    buttonText: buttonText || tr.translateAsString("generic.file-input.button")
  })), (files == null ? void 0 : files.length) > 0 && (inputProps == null ? void 0 : inputProps.multiple) && /* @__PURE__ */ React33.createElement("div", {
    className: FileInputView_module_default.fileCounter
  }, tr.translate(files.length > 1 ? "generic.file-input.files-selected" : "generic.file-input.file-selected", {
    nbr: files.length
  })));
});
var FileInputSP_default = InputSP;

// src/FileInput/FileInput.tsx
var FileInput2 = React34.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const color = COLOR_MAP12[intent];
  return /* @__PURE__ */ React34.createElement(FileInputSP_default, __spreadValues({
    color,
    ref
  }, restProps));
});
var FileInput_default = FileInput2;
var COLOR_MAP12 = {
  default: "default",
  primary: "primary"
};

// src/Header/H1.tsx
import React35 from "react";
import cx16 from "classnames";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Header/Header.module.scss
var Header_module_default = {
  "H1": "_H1_1eieb_1",
  "H2": "_H2_1eieb_11",
  "H3": "_H3_1eieb_21"
};

// src/Header/H1.tsx
var H1 = React35.forwardRef((props, ref) => {
  const _a = props, { children, className } = _a, restProps = __objRest(_a, ["children", "className"]);
  return /* @__PURE__ */ React35.createElement("h1", __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx16(Header_module_default.H1, className)
  }), children);
});
var H1_default = H1;

// src/Header/H2.tsx
import React36 from "react";
import cx17 from "classnames";
var H2 = React36.forwardRef((props, ref) => {
  const _a = props, { children, className } = _a, restProps = __objRest(_a, ["children", "className"]);
  return /* @__PURE__ */ React36.createElement("h2", __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx17(Header_module_default.H2, className)
  }), children);
});
var H2_default = H2;

// src/Header/H3.tsx
import React37 from "react";
import cx18 from "classnames";
var H3 = React37.forwardRef((props, ref) => {
  const _a = props, { children, className } = _a, restProps = __objRest(_a, ["children", "className"]);
  return /* @__PURE__ */ React37.createElement("h3", __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx18(Header_module_default.H3, className)
  }), children);
});
var H3_default = H3;

// src/HighlightedText/HighlightedText.tsx
import { regexSanitize } from "@aletiq/util";
import React38 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/HighlightedText/HighlightedText.module.scss
var HighlightedText_module_default = {
  "highlighted": "_highlighted_lil43_1"
};

// src/HighlightedText/HighlightedText.tsx
function HighlightedText(props) {
  const { text, highlight, className } = props;
  const regex = new RegExp(regexSanitize((highlight == null ? void 0 : highlight.toLowerCase()) ?? ""));
  const firstIndex = ((text == null ? void 0 : text.toLowerCase()) ?? "").search(regex);
  if ((highlight ?? false) && firstIndex >= 0) {
    return /* @__PURE__ */ React38.createElement("span", {
      className
    }, text == null ? void 0 : text.substring(0, firstIndex), /* @__PURE__ */ React38.createElement("span", {
      className: HighlightedText_module_default.highlighted
    }, text == null ? void 0 : text.substring(firstIndex, firstIndex + ((highlight == null ? void 0 : highlight.length) ?? 0))), text == null ? void 0 : text.substring(firstIndex + ((highlight == null ? void 0 : highlight.length) ?? 0)));
  } else {
    return /* @__PURE__ */ React38.createElement("span", {
      className
    }, text);
  }
}

// src/Input/Input.tsx
import React40 from "react";

// src/Input/InputSP.tsx
import {
  Button as Button3,
  Icon as Icon7,
  InputGroup
} from "@blueprintjs/core";
import cx19 from "classnames";
import React39 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Input/InputType.module.scss
var InputType_module_default = {
  "reverseFlex": "_reverseFlex_eptb1_1",
  "error-helper-text": "_error-helper-text_eptb1_5",
  "def": "_def_eptb1_5",
  "dense": "_dense_eptb1_14",
  "fill": "_fill_eptb1_23",
  "clearButton": "_clearButton_eptb1_111",
  "right-icon": "_right-icon_eptb1_136",
  "shake-horizontal": "_shake-horizontal_eptb1_1"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Input/InputView.module.scss
var InputView_module_default = {
  "smooth": "_smooth_lfm7d_3",
  "active": "_active_lfm7d_10",
  "outlined": "_outlined_lfm7d_56",
  "raised": "_raised_lfm7d_133",
  "success": "_success_lfm7d_147",
  "error-helper-text": "_error-helper-text_lfm7d_148",
  "filled": "_filled_lfm7d_191",
  "clearButton": "_clearButton_lfm7d_268"
};

// src/Input/InputSP.tsx
var InputSP2 = React39.forwardRef((props, ref) => {
  const _a = props, {
    view = "outlined",
    color = "primary",
    className,
    label,
    removeClearButton = false,
    clearIcon,
    value,
    rightElement,
    isDense,
    isSuccess,
    hasError,
    isFixed,
    fill,
    errorHelperText,
    onChange,
    autoFocus = isDense ? false : true
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "className",
    "label",
    "removeClearButton",
    "clearIcon",
    "value",
    "rightElement",
    "isDense",
    "isSuccess",
    "hasError",
    "isFixed",
    "fill",
    "errorHelperText",
    "onChange",
    "autoFocus"
  ]);
  const [isActive, setActive] = React39.useState(false);
  const successIcon = /* @__PURE__ */ React39.createElement(Icon7, {
    icon: restProps.successIcon || "tick",
    iconSize: isDense ? 16 : 20
  });
  const errorIcon = /* @__PURE__ */ React39.createElement(Icon7, {
    icon: restProps.errorIcon || "error",
    iconSize: isDense ? 16 : 20
  });
  const resultIcon = isSuccess ? successIcon : hasError ? errorIcon : rightElement;
  const hasClearButton = !removeClearButton && !isSuccess && !hasError;
  const handleFocus = () => {
    setActive(true);
  };
  const handleBlur = () => {
    setActive(false);
  };
  const handleClear = () => {
    onChange("");
  };
  const handleChange = (ev) => {
    onChange((ev == null ? void 0 : ev.target.value) ?? "");
  };
  return /* @__PURE__ */ React39.createElement("div", {
    className: cx19(InputType_module_default.reverseFlex, className, {
      [InputType_module_default.fill]: fill
    })
  }, errorHelperText && hasError && /* @__PURE__ */ React39.createElement("div", {
    className: cx19(InputType_module_default["error-helper-text"], InputView_module_default["error-helper-text"], isDense ? InputType_module_default["dense"] : InputType_module_default["def"])
  }, errorHelperText), /* @__PURE__ */ React39.createElement(InputGroup, __spreadProps(__spreadValues({}, restProps), {
    ref,
    value,
    onChange: handleChange,
    className: cx19(isFixed ? InputType_module_default["fixed"] : isDense ? InputType_module_default["dense"] : InputType_module_default["def"], fill && InputType_module_default["fill"], isDense ? InputType_module_default["dense"] : InputType_module_default["def"], isActive && InputView_module_default["active"], hasClearButton && InputType_module_default["clearButton"], hasClearButton && InputView_module_default["clearButton"], isSuccess && InputView_module_default["success"], color_module_default[color], hasError && InputView_module_default["error-helper-text"], resultIcon && InputType_module_default["right-icon"], isFixed ? InputView_module_default["fixed"] : InputView_module_default[view]),
    onFocus: handleFocus,
    autoFocus,
    onBlur: handleBlur,
    rightElement: hasClearButton && value ? /* @__PURE__ */ React39.createElement(Button3, {
      icon: /* @__PURE__ */ React39.createElement(Icon7, {
        icon: clearIcon || "cross",
        iconSize: 20
      }),
      minimal: true,
      onClick: handleClear
    }) : resultIcon
  })), label && /* @__PURE__ */ React39.createElement("label", {
    className: color_module_default[color]
  }, label));
});
var InputSP_default = InputSP2;

// src/Input/Input.tsx
var Input = React40.forwardRef((props, ref) => {
  const _a = props, { intent = "primary", removeClearButton = true } = _a, restProps = __objRest(_a, ["intent", "removeClearButton"]);
  const view = VIEW_MAP10[intent];
  const color = COLOR_MAP13[intent];
  return /* @__PURE__ */ React40.createElement(InputSP_default, __spreadValues({
    view,
    color,
    ref,
    removeClearButton
  }, restProps));
});
var Input_default = Input;
var VIEW_MAP10 = {
  primary: "outlined",
  default: "outlined"
};
var COLOR_MAP13 = {
  primary: "primary",
  default: "default"
};

// src/Menu/Menu.tsx
import React42 from "react";

// src/Menu/MenuSP.tsx
import cx20 from "classnames";
import React41 from "react";
import { Menu as MenuSource } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Menu/MenuType.module.scss
var MenuType_module_default = {
  "def": "_def_11mla_3",
  "dense": "_dense_11mla_32",
  "slide-fwd-right": "_slide-fwd-right_11mla_1"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Menu/MenuView.module.scss
var MenuView_module_default = {
  "smooth": "_smooth_15hc4_3",
  "outlined": "_outlined_15hc4_41",
  "raised": "_raised_15hc4_81",
  "filled": "_filled_15hc4_124",
  "default": "_default_15hc4_165"
};

// src/Menu/MenuSP.tsx
var MenuSP = React41.forwardRef((props, ref) => {
  const _a = props, {
    view = "default",
    color,
    children,
    className,
    isDense
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "children",
    "className",
    "isDense"
  ]);
  return /* @__PURE__ */ React41.createElement(MenuSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx20(MenuType_module_default[isDense ? "dense" : "def"], view ? MenuView_module_default[view] : "", color && color_module_default[color], className)
  }), children);
});
var MenuSP_default = MenuSP;

// src/Menu/Menu.tsx
var Menu = React42.forwardRef((props, ref) => {
  const _a = props, { intent = "minimal" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP11[intent];
  const color = COLOR_MAP14[intent];
  return /* @__PURE__ */ React42.createElement(MenuSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Menu_default = Menu;
var VIEW_MAP11 = {
  primary: "outlined",
  default: "outlined",
  minimal: void 0
};
var COLOR_MAP14 = {
  primary: "primary",
  default: "default",
  minimal: "default"
};

// src/MenuDivider/MenuDivider.tsx
import React44 from "react";

// src/MenuDivider/MenuDividerSP.tsx
import cx21 from "classnames";
import React43 from "react";
import {
  MenuDivider as MenuDividerSource
} from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/MenuDivider/MenuDividerType.module.scss
var MenuDividerType_module_default = {
  "def": "_def_p3dpo_2",
  "dense": "_dense_p3dpo_3"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/MenuDivider/MenuDividerView.module.scss
var MenuDividerView_module_default = {
  "smooth": "_smooth_2xo6u_2",
  "outlined": "_outlined_2xo6u_3",
  "filled": "_filled_2xo6u_7"
};

// src/MenuDivider/MenuDividerSP.tsx
var MenuDividerSP = React43.forwardRef((props, ref) => {
  const _a = props, {
    view = "smooth",
    color = "primary",
    className,
    isDense = false
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "className",
    "isDense"
  ]);
  return /* @__PURE__ */ React43.createElement(MenuDividerSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx21(MenuDividerType_module_default[isDense ? "dense" : "def"], MenuDividerView_module_default[view], color_module_default[color], className)
  }));
});
var MenuDividerSP_default = MenuDividerSP;

// src/MenuDivider/MenuDivider.tsx
var MenuDivider = React44.forwardRef((props, ref) => {
  const _a = props, { intent = "default" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP12[intent];
  const color = COLOR_MAP15[intent];
  return /* @__PURE__ */ React44.createElement(MenuDividerSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var MenuDivider_default = MenuDivider;
var VIEW_MAP12 = {
  primary: "smooth",
  default: "smooth"
};
var COLOR_MAP15 = {
  primary: "primary",
  default: "default"
};

// src/MenuItem/MenuItem.tsx
import React46 from "react";

// src/MenuItem/MenuItemSP.tsx
import cx22 from "classnames";
import React45 from "react";
import {
  MenuItem as MenuItemSource
} from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/MenuItem/MenuItemType.module.scss
var MenuItemType_module_default = {
  "def": "_def_ci072_3",
  "dense": "_dense_ci072_4"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/MenuItem/MenuItemView.module.scss
var MenuItemView_module_default = {
  "smooth": "_smooth_1ktsp_2",
  "note": "_note_1ktsp_2",
  "outlined": "_outlined_1ktsp_3",
  "raised": "_raised_1ktsp_4",
  "filled": "_filled_1ktsp_8",
  "has_colored_text": "_has_colored_text_1ktsp_12"
};

// src/MenuItem/MenuItemSP.tsx
var MenuItemSP = React45.forwardRef((props, ref) => {
  const _a = props, {
    view = "smooth",
    color = "primary",
    className,
    isDense,
    hasNote
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "className",
    "isDense",
    "hasNote"
  ]);
  return /* @__PURE__ */ React45.createElement(MenuItemSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx22(MenuItemType_module_default[isDense ? "dense" : "def"], MenuItemView_module_default[view], color !== "default" && color !== "primary" && MenuItemView_module_default.has_colored_text, hasNote && MenuItemView_module_default["note"], color_module_default[color], className)
  }));
});
var MenuItemSP_default = MenuItemSP;

// src/MenuItem/MenuItem.tsx
var MenuItem = React46.forwardRef((props, ref) => {
  const _a = props, { intent = "default" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP13[intent];
  const color = COLOR_MAP16[intent];
  return /* @__PURE__ */ React46.createElement(MenuItemSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var MenuItem_default = MenuItem;
var VIEW_MAP13 = {
  primary: "smooth",
  default: "smooth",
  success: "smooth",
  warning: "smooth",
  danger: "smooth"
};
var COLOR_MAP16 = {
  primary: "primary",
  default: "default",
  success: "success",
  warning: "warning",
  danger: "danger"
};

// src/MultistepDialog/MultistepDialog.tsx
import { useTranslations as useTranslations4 } from "@aletiq/translation";
import classNames6 from "classnames";
import React47, { useState as useState2 } from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/MultistepDialog/MultistepDialogType.module.scss
var MultistepDialogType_module_default = {
  "steps": "_steps_1trk9_1",
  "step": "_step_1trk9_1",
  "step_num": "_step_num_1trk9_18",
  "active": "_active_1trk9_27"
};

// src/MultistepDialog/MultistepDialog.tsx
function MultistepDialog(props) {
  const _a = props, {
    panels,
    initialStep = 0,
    onClose: onDialogClose,
    isDense,
    intent = "primary",
    icon,
    title,
    nextButtonProps,
    submitButtonProps,
    backButtonProps,
    closeButtonProps
  } = _a, restProps = __objRest(_a, [
    "panels",
    "initialStep",
    "onClose",
    "isDense",
    "intent",
    "icon",
    "title",
    "nextButtonProps",
    "submitButtonProps",
    "backButtonProps",
    "closeButtonProps"
  ]);
  const { onClick: onNext, isDisabled: isNextDisabled } = nextButtonProps ?? {};
  const { onClick: onBack, isDisabled: isBackDisabled } = backButtonProps ?? {};
  const { onClick: onSubmit, isDisabled: isSubmitDisabled } = submitButtonProps ?? {};
  const { onClick: onClose, isDisabled: isCloseDisabled } = closeButtonProps ?? {};
  const tr = useTranslations4();
  const [currentStep, setCurrentStep] = useState2(initialStep);
  const isLastStep = currentStep === panels.length - 1;
  const isFirstStep = currentStep === 0;
  const handlePrimaryClick = () => {
    if (!isLastStep) {
      onNext && onNext(currentStep, panels[currentStep].id);
      setCurrentStep((step) => step + 1);
    } else {
      onSubmit && onSubmit(currentStep, panels[currentStep].id);
    }
  };
  const handleSecondaryClick = () => {
    if (onClose) {
      onClose(currentStep, panels[currentStep].id);
    } else if (onDialogClose) {
      onDialogClose();
    }
  };
  const handleTertiaryClick = () => {
    if (!isFirstStep) {
      onBack && onBack(currentStep, panels[currentStep].id);
      setCurrentStep((step) => step - 1);
    }
  };
  const isPrimaryDisabled = () => {
    if (!isLastStep) {
      return isNextDisabled && isNextDisabled(currentStep, panels[currentStep].id);
    }
    return isSubmitDisabled && isSubmitDisabled(currentStep, panels[currentStep].id);
  };
  const isSecondaryDisabled = () => {
    return isCloseDisabled && isCloseDisabled(currentStep, panels[currentStep].id);
  };
  const isTertiaryDisabled = () => {
    return isFirstStep || isBackDisabled && isBackDisabled(currentStep, panels[currentStep].id);
  };
  const isPrimaryLoading = isLastStep ? submitButtonProps == null ? void 0 : submitButtonProps.isLoading : nextButtonProps == null ? void 0 : nextButtonProps.isLoading;
  const isSecondaryLoading = closeButtonProps == null ? void 0 : closeButtonProps.isLoading;
  const isTertiaryLoading = backButtonProps == null ? void 0 : backButtonProps.isLoading;
  const primaryText = isLastStep ? (submitButtonProps == null ? void 0 : submitButtonProps.text) ?? tr.translateAsString("generic.action.submit") : (nextButtonProps == null ? void 0 : nextButtonProps.text) ?? tr.translateAsString("generic.action.next");
  const secondaryText = closeButtonProps == null ? void 0 : closeButtonProps.text;
  const tertiaryText = (backButtonProps == null ? void 0 : backButtonProps.text) ?? tr.translateAsString("generic.action.go-back");
  const hasPrimary = isLastStep ? !!submitButtonProps : !!nextButtonProps;
  const hasSecondary = !!closeButtonProps;
  const hasTertiary = !!backButtonProps;
  return /* @__PURE__ */ React47.createElement(DialogBase, __spreadValues(__spreadProps(__spreadValues({}, restProps), {
    isDense,
    intent,
    onClose: onDialogClose
  }), restProps), /* @__PURE__ */ React47.createElement(DialogHeader, {
    icon,
    title,
    isDense,
    onClose: onDialogClose
  }), /* @__PURE__ */ React47.createElement(DialogContent, null, /* @__PURE__ */ React47.createElement(DialogSteps, null, panels.map(({ id, title: title2 }, idx) => /* @__PURE__ */ React47.createElement(DialogStepTitle, {
    key: id,
    title: title2,
    number: idx + 1,
    isActive: idx === currentStep
  }))), panels[currentStep].panel), /* @__PURE__ */ React47.createElement(DialogFooter, {
    intent,
    isDense,
    primaryButtonProps: hasPrimary && {
      onClick: handlePrimaryClick,
      isDisabled: isPrimaryDisabled(),
      isLoading: isPrimaryLoading,
      text: primaryText
    },
    secondaryButtonProps: hasSecondary && {
      onClick: handleSecondaryClick,
      isDisabled: isSecondaryDisabled(),
      isLoading: isSecondaryLoading,
      text: secondaryText
    },
    tertiaryButtonProps: hasTertiary && {
      onClick: handleTertiaryClick,
      isDisabled: isTertiaryDisabled(),
      isLoading: isTertiaryLoading,
      text: tertiaryText
    }
  }));
}
function DialogSteps(props) {
  const { children } = props;
  return /* @__PURE__ */ React47.createElement("div", {
    className: MultistepDialogType_module_default["steps"]
  }, children);
}
function DialogStepTitle(props) {
  const { number, title, isActive: active } = props;
  return /* @__PURE__ */ React47.createElement("div", {
    className: classNames6(MultistepDialogType_module_default["step"], {
      [MultistepDialogType_module_default["active"]]: active
    })
  }, /* @__PURE__ */ React47.createElement("div", {
    className: MultistepDialogType_module_default["step_num"]
  }, number), /* @__PURE__ */ React47.createElement("div", null, title));
}

// src/NumericInput/NumericInput.tsx
import {
  NumericInput as NumericInputSource
} from "@blueprintjs/core";
import cx23 from "classnames";
import React48 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/NumericInput/NumericInputType.module.scss
var NumericInputType_module_default = {
  "reverseFlex": "_reverseFlex_4an30_1",
  "fill": "_fill_4an30_5",
  "def": "_def_4an30_10",
  "dense": "_dense_4an30_11",
  "shake-horizontal": "_shake-horizontal_4an30_1"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/NumericInput/NumericInputView.module.scss
var NumericInputView_module_default = {
  "outlined": "_outlined_1qwyi_1",
  "active": "_active_1qwyi_19",
  "focused": "_focused_1qwyi_89"
};

// src/NumericInput/NumericInput.tsx
var NumericInput = React48.forwardRef((props, ref) => {
  const _a = props, {
    intent = "default",
    buttonPosition = "none",
    className,
    label,
    isDense,
    isFill,
    autoFocus = isDense ? false : true
  } = _a, restProps = __objRest(_a, [
    "intent",
    "buttonPosition",
    "className",
    "label",
    "isDense",
    "isFill",
    "autoFocus"
  ]);
  const [isActive, setActive] = React48.useState(false);
  const handleFocus = () => {
    setActive(true);
  };
  const handleBlur = () => {
    setActive(false);
  };
  const color = COLOR_MAP17[intent];
  return /* @__PURE__ */ React48.createElement("div", {
    className: cx23(NumericInputType_module_default.reverseFlex, className, {
      [NumericInputType_module_default.fill]: isFill
    })
  }, /* @__PURE__ */ React48.createElement(NumericInputSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    onFocus: handleFocus,
    autoFocus,
    onBlur: handleBlur,
    buttonPosition,
    fill: isFill,
    className: cx23(isDense ? NumericInputType_module_default["dense"] : NumericInputType_module_default["def"], isActive && NumericInputView_module_default["active"], isFill && NumericInputType_module_default["fill"], NumericInputView_module_default["outlined"], color_module_default[color])
  })), label && /* @__PURE__ */ React48.createElement("label", {
    className: color_module_default[color]
  }, label));
});
var NumericInput_default = NumericInput;
var COLOR_MAP17 = {
  primary: "primary",
  default: "default",
  danger: "danger"
};

// src/Radio/Radio.tsx
import React50 from "react";

// src/Radio/RadioSP.tsx
import { Radio as RadioSource } from "@blueprintjs/core";
import cx24 from "classnames";
import React49 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Radio/RadioType.module.scss
var RadioType_module_default = {
  "container": "_container_q78r1_3",
  "def": "_def_q78r1_11",
  "dense": "_dense_q78r1_12",
  "inline": "_inline_q78r1_18",
  "fill": "_fill_q78r1_23",
  "with_helper": "_with_helper_q78r1_100",
  "helperBox": "_helperBox_q78r1_111",
  "text": "_text_q78r1_117",
  "helperText": "_helperText_q78r1_121",
  "leftPosition": "_leftPosition_q78r1_129",
  "rtl": "_rtl_q78r1_145"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Radio/RadioView.module.scss
var RadioView_module_default = {
  "flat": "_flat_dfud_3",
  "disabled": "_disabled_dfud_14",
  "checked": "_checked_dfud_25",
  "has_label": "_has_label_dfud_25",
  "helperText": "_helperText_dfud_80",
  "smooth": "_smooth_dfud_87",
  "raised": "_raised_dfud_162",
  "outlined": "_outlined_dfud_241",
  "minimal": "_minimal_dfud_318"
};

// src/Radio/RadioSP.tsx
var RadioSP = React49.forwardRef((props, ref) => {
  const _a = props, {
    isDense,
    view = "flat",
    color = "primary",
    checked,
    inline,
    helperText,
    leftPosition,
    rtl,
    fill,
    style,
    label,
    className,
    isDisabled,
    minimal
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "view",
    "color",
    "checked",
    "inline",
    "helperText",
    "leftPosition",
    "rtl",
    "fill",
    "style",
    "label",
    "className",
    "isDisabled",
    "minimal"
  ]);
  const mock = () => {
  };
  return /* @__PURE__ */ React49.createElement("div", {
    className: cx24(RadioType_module_default["container"], RadioType_module_default[isDense ? "dense" : "def"], inline && RadioType_module_default["inline"], RadioView_module_default[view], checked && RadioView_module_default["checked"], label && label !== "" && RadioView_module_default["has_label"], color_module_default[color], helperText && RadioType_module_default["with_helper"], leftPosition && RadioType_module_default["leftPosition"], isDisabled && RadioView_module_default["disabled"], fill && RadioType_module_default["fill"], minimal && RadioView_module_default["minimal"], className),
    style: style && style
  }, /* @__PURE__ */ React49.createElement(RadioSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx24(color_module_default[color], helperText && RadioType_module_default["helperText"], rtl && RadioType_module_default["rtl"]),
    checked,
    large: !isDense,
    disabled: isDisabled,
    onChange: mock
  }), (label || helperText) && /* @__PURE__ */ React49.createElement("div", {
    className: cx24(helperText && RadioType_module_default["helperBox"], RadioType_module_default["text"])
  }, label, helperText && /* @__PURE__ */ React49.createElement("div", {
    className: cx24(RadioType_module_default["helperText"], RadioView_module_default["helperText"])
  }, helperText))));
});
var RadioSP_default = RadioSP;

// src/Radio/Radio.tsx
var Radio = React50.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP14[intent];
  const color = COLOR_MAP18[intent];
  return /* @__PURE__ */ React50.createElement(RadioSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Radio_default = Radio;
var VIEW_MAP14 = {
  primary: "flat",
  default: "flat"
};
var COLOR_MAP18 = {
  primary: "primary",
  default: "default"
};

// src/SearchInput/SearchInput.tsx
import React52 from "react";

// src/SearchInput/SearchInputSP.tsx
import { useTranslations as useTranslations5 } from "@aletiq/translation";
import classname from "classnames";
import React51 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/SearchInput/SearchInput.module.scss
var SearchInput_module_default = {
  "searchInput": "_searchInput_18u5s_1",
  "isDense": "_isDense_18u5s_8"
};

// src/SearchInput/SearchInputSP.tsx
var SearchInputSP = React51.forwardRef((props, ref) => {
  const _a = props, {
    view = "outlined",
    color = "primary",
    value,
    isDense = false,
    placeholder,
    className,
    onChange
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "value",
    "isDense",
    "placeholder",
    "className",
    "onChange"
  ]);
  const tr = useTranslations5();
  return /* @__PURE__ */ React51.createElement(InputSP_default, __spreadProps(__spreadValues({}, restProps), {
    className: classname(SearchInput_module_default.searchInput, className, {
      [SearchInput_module_default.isDense]: isDense
    }),
    view,
    color,
    onChange,
    isDense,
    ref,
    leftElement: /* @__PURE__ */ React51.createElement(Icon6, {
      iconSize: isDense ? 14 : 18,
      icon: "search"
    }),
    placeholder: placeholder || tr.translateAsString("generic.action.search"),
    value
  }));
});
var SearchInputSP_default = SearchInputSP;

// src/SearchInput/SearchInput.tsx
var SearchInput = React52.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP15[intent];
  const color = COLOR_MAP19[intent];
  return /* @__PURE__ */ React52.createElement(SearchInputSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var SearchInput_default = SearchInput;
var VIEW_MAP15 = {
  primary: "outlined",
  default: "outlined"
};
var COLOR_MAP19 = {
  primary: "primary",
  default: "default"
};

// src/select/MultiSelect/MultiSelectSP.tsx
import { useTranslations as useTranslations6 } from "@aletiq/translation";
import { isInString } from "@aletiq/util";
import {
  MultiSelect
} from "@blueprintjs/select";
import cx26 from "classnames";
import React54, { useState as useState3 } from "react";

// src/MenuSelectGenerator/MenuSelectGenerator.tsx
import { default as classNames7, default as cx25 } from "classnames";
import React53 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/MenuSelectGenerator/MenuSelectGenerator.module.scss
var MenuSelectGenerator_module_default = {
  "contentScroll": "_contentScroll_1lkww_1",
  "no_search": "_no_search_1lkww_6",
  "menu": "_menu_1lkww_10",
  "search": "_search_1lkww_15",
  "no_results": "_no_results_1lkww_19"
};

// src/MenuSelectGenerator/MenuSelectGenerator.tsx
var MenuSelectGenerator = (tr, color, filterable, search, itemPredicate, handleSearch, isDense, autoFocus) => (props) => {
  const { items, renderItem } = props;
  const renderedItems = items.filter((item) => itemPredicate(search, item)).map(renderItem);
  const hasNoResults = renderedItems.length === 0;
  return /* @__PURE__ */ React53.createElement(MenuSP_default, {
    isDense,
    className: cx25(MenuSelectGenerator_module_default.menu, color_module_default[color]),
    view: "raised"
  }, filterable && /* @__PURE__ */ React53.createElement(SearchInput_default, {
    autoFocus,
    className: MenuSelectGenerator_module_default.search,
    intent: color === "default" ? "default" : "primary",
    isDense,
    onChange: handleSearch,
    value: search,
    placeholder: tr.translateAsString("generic.action.search"),
    fill: true
  }), /* @__PURE__ */ React53.createElement("div", {
    className: classNames7(MenuSelectGenerator_module_default.contentScroll, !filterable && MenuSelectGenerator_module_default.no_search)
  }, hasNoResults ? /* @__PURE__ */ React53.createElement("span", {
    className: MenuSelectGenerator_module_default.no_results
  }, /* @__PURE__ */ React53.createElement("em", null, tr.translate("generic.action.search.no-results"))) : renderedItems));
};
var MenuSelectGenerator_default = MenuSelectGenerator;

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/select/MultiSelect/MultiSelect.module.scss
var MultiSelect_module_default = {
  "popover": "_popover_10tsh_1",
  "default": "_default_10tsh_10",
  "input_tag": "_input_tag_10tsh_10",
  "disabled": "_disabled_10tsh_48",
  "dense": "_dense_10tsh_95",
  "active": "_active_10tsh_95",
  "divider": "_divider_10tsh_134",
  "isDense": "_isDense_10tsh_137"
};

// src/select/MultiSelect/MultiSelectSP.tsx
function MultiSelectSP(props) {
  const _a = props, {
    intent = "primary",
    items,
    selectedItems,
    isDense = false,
    isDisabled = false,
    hasPopoverMinimal = true,
    placeholder,
    filterable = true,
    className,
    tagProps,
    popoverProps,
    handleRemoveTag,
    handleAddTag,
    itemPredicate
  } = _a, restProps = __objRest(_a, [
    "intent",
    "items",
    "selectedItems",
    "isDense",
    "isDisabled",
    "hasPopoverMinimal",
    "placeholder",
    "filterable",
    "className",
    "tagProps",
    "popoverProps",
    "handleRemoveTag",
    "handleAddTag",
    "itemPredicate"
  ]);
  const tr = useTranslations6();
  const [search, setSearch] = useState3("");
  const [isOpen, setIsOpen] = useState3(false);
  const handleSearch = (value) => {
    if (isDisabled) {
      return;
    }
    setSearch(value);
    if (!isOpen) {
      setIsOpen(true);
    }
  };
  const predicate = itemPredicate || ((query, item) => isInString(item.text, query));
  const onMenuClick = (item) => {
    !isDisabled && handleAddTag(item.key);
  };
  const handleRemove = (_, index) => {
    var _a2, _b;
    const key = (_b = (_a2 = items.filter((item) => selectedItems.includes(item.key))) == null ? void 0 : _a2[index]) == null ? void 0 : _b.key;
    key && handleRemoveTag(key);
  };
  const handleToggleMenu = () => !isDisabled && setIsOpen(!isOpen);
  const handleCloseMenu = () => setIsOpen(false);
  return /* @__PURE__ */ React54.createElement(MultiSelect, __spreadProps(__spreadValues({}, restProps), {
    className: cx26(MultiSelect_module_default.input_tag, color_module_default[intent], !isDense && MultiSelect_module_default.default, isDisabled && MultiSelect_module_default.disabled, className),
    popoverProps: __spreadValues({
      isOpen,
      disabled: isDisabled,
      onClose: handleCloseMenu,
      minimal: hasPopoverMinimal,
      position: "bottom-left",
      popoverClassName: MultiSelect_module_default.popover
    }, popoverProps),
    items: items.filter((i) => !i.hidden),
    itemRenderer: (item, { handleClick }) => /* @__PURE__ */ React54.createElement(React54.Fragment, {
      key: item.key
    }, (item == null ? void 0 : item.addDividerOnTop) && /* @__PURE__ */ React54.createElement("div", {
      className: cx26(MultiSelect_module_default.divider, {
        [MultiSelect_module_default.isDense]: isDense
      })
    }, /* @__PURE__ */ React54.createElement(Divider_default, {
      intent: intent === "default" ? "default" : "primary"
    })), /* @__PURE__ */ React54.createElement(MenuItemSP_default, {
      key: item.key ?? 0,
      disabled: item.disabled,
      view: "smooth",
      color: intent,
      icon: selectedItems.includes(item.key) ? "tick" : "blank",
      active: selectedItems.includes(item.key),
      shouldDismissPopover: false,
      href: item.href,
      labelElement: item.labelElement,
      text: item.node || /* @__PURE__ */ React54.createElement(HighlightedText, {
        text: item.text,
        highlight: search
      }),
      isDense,
      onClick: handleClick
    })),
    query: filterable ? search : void 0,
    onQueryChange: filterable ? handleSearch : void 0,
    itemListRenderer: MenuSelectGenerator_default(tr, intent, false, search, predicate, handleSearch, isDense),
    tagRenderer: (item) => item.text,
    tagInputProps: {
      onRemove: isDisabled ? void 0 : handleRemove,
      inputProps: { autoFocus: true, onClick: handleToggleMenu },
      tagProps: () => __spreadProps(__spreadValues({}, tagProps), {
        large: !isDense,
        minimal: isDense,
        unclickable: true
      })
    },
    selectedItems: items.filter((item) => selectedItems.includes(item.key)) ?? null,
    placeholder,
    onItemSelect: (item) => onMenuClick(item)
  }));
}
var MultiSelectSP_default = MultiSelectSP;

// src/select/Select/Select.tsx
import React56 from "react";

// src/select/Select/SelectSP.tsx
import { useTranslations as useTranslations7 } from "@aletiq/translation";
import { isInString as isInString2 } from "@aletiq/util";
import {
  Select as SelectSource
} from "@blueprintjs/select";
import { default as classNames8, default as cx27 } from "classnames";
import React55, { useState as useState4 } from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/select/Select/Select.module.scss
var Select_module_default = {
  "select_button": "_select_button_sw7hd_1",
  "outlined": "_outlined_sw7hd_13",
  "disabled": "_disabled_sw7hd_16",
  "filled": "_filled_sw7hd_19",
  "isFill": "_isFill_sw7hd_22",
  "popover": "_popover_sw7hd_27",
  "filterable": "_filterable_sw7hd_31",
  "divider": "_divider_sw7hd_37",
  "isDense": "_isDense_sw7hd_40",
  "menu_item": "_menu_item_sw7hd_44"
};

// src/select/Select/SelectSP.tsx
function SelectSP(props) {
  const _a = props, {
    view = "filled",
    color = "primary",
    children,
    hasPopoverMinimal = true,
    isFill,
    isDense,
    filterable = false,
    isDisabled,
    items,
    activeItem,
    buttonClassName,
    isLoading,
    itemPredicate,
    onItemSelect,
    popoverProps,
    autoFocus
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "children",
    "hasPopoverMinimal",
    "isFill",
    "isDense",
    "filterable",
    "isDisabled",
    "items",
    "activeItem",
    "buttonClassName",
    "isLoading",
    "itemPredicate",
    "onItemSelect",
    "popoverProps",
    "autoFocus"
  ]);
  const tr = useTranslations7();
  const [search, setSearch] = useState4("");
  const handleSearch = (value) => {
    var _a2;
    setSearch(value);
    (_a2 = props.onQueryChange) == null ? void 0 : _a2.call(props, value);
  };
  const buttonClass = cx27(Select_module_default.select_button, Select_module_default[view], buttonClassName, {
    [Select_module_default.disabled]: isDisabled
  });
  const predicate = itemPredicate || ((query, item) => isInString2(item.text, query));
  const onMenuClick = (item) => {
    onItemSelect(item.key, item);
  };
  const visibleItems = items.filter((i) => !i.hidden);
  return /* @__PURE__ */ React55.createElement(SelectSource, __spreadProps(__spreadValues({}, restProps), {
    popoverProps: __spreadValues({
      minimal: hasPopoverMinimal,
      position: "bottom-left",
      popoverClassName: classNames8(Select_module_default.popover, filterable && Select_module_default.filterable),
      fill: isFill,
      targetTagName: "div"
    }, popoverProps),
    disabled: isDisabled,
    items: visibleItems,
    itemRenderer: (item, { handleClick, modifiers: { active }, index }) => /* @__PURE__ */ React55.createElement(React55.Fragment, {
      key: item.key
    }, (item == null ? void 0 : item.addDividerOnTop) && /* @__PURE__ */ React55.createElement("div", {
      className: cx27(Select_module_default.divider, {
        [Select_module_default.isDense]: isDense
      })
    }, /* @__PURE__ */ React55.createElement(Divider_default, {
      intent: color === "default" ? "default" : "primary"
    })), /* @__PURE__ */ React55.createElement(MenuItemSP_default, {
      disabled: item.disabled,
      view: "smooth",
      color: item.intent || color,
      icon: (item == null ? void 0 : item.icon) ? item.icon : void 0,
      href: item.href,
      labelElement: item.labelElement,
      className: classNames8({
        [Select_module_default.menu_item]: (index || 0) < visibleItems.length - 1
      }),
      text: item.node || /* @__PURE__ */ React55.createElement(HighlightedText, {
        text: item.text,
        highlight: search
      }),
      isDense,
      active,
      onClick: handleClick
    })),
    onItemSelect: (item) => onMenuClick(item),
    activeItem: items.find((item) => item.key === activeItem) ?? null,
    query: search,
    onQueryChange: handleSearch,
    itemListRenderer: MenuSelectGenerator_default(tr, color, filterable, search, predicate, handleSearch, isDense, autoFocus),
    filterable: false
  }), typeof children !== "string" && children, typeof children === "string" && /* @__PURE__ */ React55.createElement(ButtonSP, {
    className: buttonClass,
    isDense,
    color,
    view,
    rightIcon: "caret-down",
    isFill,
    isDisabled,
    isLoading
  }, children));
}
var SelectSP_default = SelectSP;

// src/select/Select/Select.tsx
function Select(props) {
  const _a = props, {
    intent = "primary",
    filterable,
    autoFocus = filterable,
    items
  } = _a, restProps = __objRest(_a, [
    "intent",
    "filterable",
    "autoFocus",
    "items"
  ]);
  const view = VIEW_MAP16[intent];
  const color = COLOR_MAP20[intent];
  return /* @__PURE__ */ React56.createElement(SelectSP_default, __spreadValues({
    view,
    color,
    items,
    filterable,
    autoFocus
  }, restProps));
}
var Select_default = Select;
var VIEW_MAP16 = {
  outlined: "outlined",
  primary: "filled",
  default: "outlined"
};
var COLOR_MAP20 = {
  primary: "primary",
  outlined: "primary",
  default: "default"
};

// src/Slider/Slider.tsx
import React58 from "react";

// src/Slider/SliderSP.tsx
import cx28 from "classnames";
import React57 from "react";
import { Slider as SliderSource } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Slider/SliderType.module.scss
var SliderType_module_default = {
  "def": "_def_14pzv_2"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Slider/SliderView.module.scss
var SliderView_module_default = {
  "filled": "_filled_1m93u_2"
};

// src/Slider/SliderSP.tsx
var SliderSP = React57.forwardRef((props, ref) => {
  const _a = props, {
    view = "filled",
    color = "primary",
    isDense,
    className
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "isDense",
    "className"
  ]);
  return /* @__PURE__ */ React57.createElement(SliderSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx28(SliderType_module_default[isDense ? "dense" : "def"], SliderView_module_default[view], color_module_default[color], className)
  }));
});
var SliderSP_default = SliderSP;

// src/Slider/Slider.tsx
var Slider = React58.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP17[intent];
  const color = COLOR_MAP21[intent];
  return /* @__PURE__ */ React58.createElement(SliderSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Slider_default = Slider;
var VIEW_MAP17 = {
  primary: "filled"
};
var COLOR_MAP21 = {
  primary: "primary"
};

// src/Switch/Switch.tsx
import React60 from "react";

// src/Switch/SwitchSP.tsx
import cx29 from "classnames";
import React59 from "react";
import { Switch as SwitchSource } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Switch/SwitchType.module.scss
var SwitchType_module_default = {
  "container": "_container_4day5_3",
  "def": "_def_4day5_14",
  "dense": "_dense_4day5_15",
  "inline": "_inline_4day5_21",
  "fill": "_fill_4day5_26",
  "bp4-align-right": "_bp4-align-right_4day5_38",
  "has_label": "_has_label_4day5_44",
  "i-style": "_i-style_4day5_66",
  "with_helper": "_with_helper_4day5_143",
  "helperBox": "_helperBox_4day5_154",
  "helperText": "_helperText_4day5_160",
  "helperText--dense": "_helperText--dense_4day5_166",
  "leftPosition": "_leftPosition_4day5_174",
  "rtl": "_rtl_4day5_190"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Switch/SwitchView.module.scss
var SwitchView_module_default = {
  "flat": "_flat_1amjl_3",
  "checked": "_checked_1amjl_7",
  "has_label": "_has_label_1amjl_22",
  "disabled": "_disabled_1amjl_60",
  "smooth": "_smooth_1amjl_66",
  "outlined": "_outlined_1amjl_135"
};

// src/Switch/SwitchSP.tsx
var SwitchSP = React59.forwardRef((props, ref) => {
  const _a = props, {
    isDense,
    view = "flat",
    color = "primary",
    checked,
    inline,
    helperText,
    isDisabled,
    margin,
    label,
    rtl,
    leftPosition,
    fill,
    className,
    iosStyle,
    autoFocus = isDense ? false : true
  } = _a, rest = __objRest(_a, [
    "isDense",
    "view",
    "color",
    "checked",
    "inline",
    "helperText",
    "isDisabled",
    "margin",
    "label",
    "rtl",
    "leftPosition",
    "fill",
    "className",
    "iosStyle",
    "autoFocus"
  ]);
  return /* @__PURE__ */ React59.createElement("div", {
    className: cx29(SwitchType_module_default["container"], inline && SwitchType_module_default["inline"], helperText && SwitchType_module_default["helperText"], fill && SwitchType_module_default["fill"], SwitchView_module_default["container"], checked && SwitchView_module_default["checked"], SwitchType_module_default[isDense ? "dense" : "def"], iosStyle && SwitchType_module_default["i-style"], SwitchView_module_default[view], color_module_default[color], helperText && SwitchType_module_default["with_helper"], leftPosition && SwitchType_module_default["leftPosition"], isDisabled && SwitchView_module_default["disabled"], label && label !== "" && SwitchView_module_default["has_label"], label && label !== "" && SwitchType_module_default["has_label"], className),
    style: margin ? { margin } : {}
  }, /* @__PURE__ */ React59.createElement(SwitchSource, __spreadProps(__spreadValues({}, rest), {
    ref,
    className: cx29(color_module_default[color], rtl && SwitchType_module_default["rtl"], className),
    checked,
    large: isDense,
    disabled: isDisabled,
    autoFocus
  }), /* @__PURE__ */ React59.createElement("div", {
    className: cx29(helperText && SwitchType_module_default["helperBox"], SwitchType_module_default["text"])
  }, label, helperText && /* @__PURE__ */ React59.createElement("div", {
    className: cx29(!isDense ? SwitchType_module_default["helperText"] : SwitchType_module_default["helperText--dense"], SwitchView_module_default["helperText"])
  }, helperText))));
});
var SwitchSP_default = SwitchSP;

// src/Switch/Switch.tsx
var Switch = React60.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP18[intent];
  const color = COLOR_MAP22[intent];
  return /* @__PURE__ */ React60.createElement(SwitchSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Switch_default = Switch;
var VIEW_MAP18 = {
  primary: "flat",
  default: "flat"
};
var COLOR_MAP22 = {
  primary: "primary",
  default: "default"
};

// src/Tabs/Tabs.tsx
import React62 from "react";

// src/Tabs/TabsSP.tsx
import cx30 from "classnames";
import React61 from "react";
import { Tabs as TabsSource } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Tabs/TabsType.module.scss
var TabsType_module_default = {
  "def": "_def_9a2aa_2",
  "dense": "_dense_9a2aa_7",
  "segmented": "_segmented_9a2aa_71",
  "center": "_center_9a2aa_94"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Tabs/TabsView.module.scss
var TabsView_module_default = {
  "segmented": "_segmented_1j3lv_2",
  "raised": "_raised_1j3lv_3",
  "smooth": "_smooth_1j3lv_4",
  "filled": "_filled_1j3lv_5",
  "flat": "_flat_1j3lv_6",
  "dense": "_dense_1j3lv_216",
  "outlined": "_outlined_1j3lv_247",
  "bp4-tab-flip": "_bp4-tab-flip_1j3lv_1"
};

// src/Tabs/TabsSP.tsx
var TabsSP = React61.forwardRef((props, ref) => {
  const _a = props, {
    view = "flat",
    color = "primary",
    isFill = false,
    className,
    children,
    isDense,
    center
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "isFill",
    "className",
    "children",
    "isDense",
    "center"
  ]);
  const type = isDense ? "dense" : "def";
  return /* @__PURE__ */ React61.createElement(TabsSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx30(TabsType_module_default[type], TabsType_module_default[view], isFill && TabsType_module_default["fill"], TabsView_module_default[type], TabsView_module_default[view], center && TabsType_module_default["center"], color_module_default[color], className)
  }), children);
});
var TabsSP_default = TabsSP;

// src/Tabs/Tabs.tsx
var Tabs = React62.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP19[intent];
  const color = COLOR_MAP23[intent];
  return /* @__PURE__ */ React62.createElement(TabsSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Tabs_default = Tabs;
var VIEW_MAP19 = {
  primary: "flat"
};
var COLOR_MAP23 = {
  primary: "primary"
};

// src/TabsSegmented/TabsSegmented.tsx
import React64 from "react";

// src/TabsSegmented/TabsSegmentedSP.tsx
import cx31 from "classnames";
import React63 from "react";
import { Tabs as Tabs2 } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/TabsSegmented/TabsSegmentedType.module.scss
var TabsSegmentedType_module_default = {
  "def": "_def_q3g06_2",
  "dense": "_dense_q3g06_7",
  "right": "_right_q3g06_73"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/TabsSegmented/TabsSegmentedView.module.scss
var TabsSegmentedView_module_default = {
  "flat": "_flat_1ijzm_3",
  "filled": "_filled_1ijzm_52",
  "smooth": "_smooth_1ijzm_101",
  "outlined": "_outlined_1ijzm_150",
  "raised": "_raised_1ijzm_203",
  "segmented": "_segmented_1ijzm_255",
  "tab-flip": "_tab-flip_1ijzm_1"
};

// src/TabsSegmented/TabsSegmentedSP.tsx
var TabsSegmentedSP = React63.forwardRef((props, ref) => {
  const _a = props, {
    isDense,
    view = "smooth",
    color = "primary",
    right,
    className,
    children
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "view",
    "color",
    "right",
    "className",
    "children"
  ]);
  return /* @__PURE__ */ React63.createElement(Tabs2, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx31(TabsSegmentedType_module_default[isDense ? "dense" : "def"], TabsSegmentedType_module_default[view], right && TabsSegmentedType_module_default["right"], TabsSegmentedView_module_default[view], color_module_default[color], className)
  }), children);
});
var TabsSegmentedSP_default = TabsSegmentedSP;

// src/TabsSegmented/TabsSegmented.tsx
var TabsSegmented = React64.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP20[intent];
  const color = COLOR_MAP24[intent];
  return /* @__PURE__ */ React64.createElement(TabsSegmentedSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var TabsSegmented_default = TabsSegmented;
var VIEW_MAP20 = {
  primary: "smooth"
};
var COLOR_MAP24 = {
  primary: "primary"
};

// src/Tag/Tag.tsx
import React66 from "react";

// src/Tag/TagSP.tsx
import {
  Tag as TagSource
} from "@blueprintjs/core";
import cx32 from "classnames";
import React65 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Tag/TagType.module.scss
var TagType_module_default = {
  "def": "_def_cwjsk_2",
  "dense": "_dense_cwjsk_19",
  "round": "_round_cwjsk_103",
  "number": "_number_cwjsk_109",
  "avatar": "_avatar_cwjsk_146",
  "outlined": "_outlined_cwjsk_186",
  "counter": "_counter_cwjsk_198"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Tag/TagView.module.scss
var TagView_module_default = {
  "smooth": "_smooth_1xpr2_3",
  "unclickable": "_unclickable_1xpr2_10",
  "disabled": "_disabled_1xpr2_48",
  "active": "_active_1xpr2_50",
  "outlined": "_outlined_1xpr2_66"
};

// src/Tag/TagSP.tsx
var TagSP = React65.forwardRef((props, ref) => {
  const _a = props, {
    isDense,
    view = "smooth",
    color = "primary",
    icon,
    avatar,
    rightIcon,
    tag,
    children,
    isRemovable = false,
    unclickable,
    isRound = true,
    withTick,
    counter,
    isDisabled,
    className,
    onRemove,
    onClick
  } = _a, rest = __objRest(_a, [
    "isDense",
    "view",
    "color",
    "icon",
    "avatar",
    "rightIcon",
    "tag",
    "children",
    "isRemovable",
    "unclickable",
    "isRound",
    "withTick",
    "counter",
    "isDisabled",
    "className",
    "onRemove",
    "onClick"
  ]);
  const handleClick = (event) => {
    if (!isClickable || isDisabled) {
      return;
    }
    onClick && onClick(event);
  };
  const isClickable = isRemovable || !unclickable;
  return /* @__PURE__ */ React65.createElement(TagSource, __spreadProps(__spreadValues({}, rest), {
    ref,
    className: cx32(TagType_module_default[isDense ? "dense" : "def"], TagType_module_default[view], isDense && TagView_module_default["dense"], TagView_module_default[view], color_module_default[color], isRound && TagType_module_default["round"], !isClickable && TagView_module_default["unclickable"], isDisabled && TagView_module_default["disabled"], counter && TagType_module_default["number"], counter && TagView_module_default["number"], avatar && TagType_module_default["avatar"], className),
    interactive: isClickable,
    onRemove: onRemove && !isDisabled ? onRemove : void 0,
    onClick: handleClick,
    icon: withTick && !isClickable ? "tick" : icon && icon,
    rightIcon: rightIcon && rightIcon,
    large: true
  }), counter && /* @__PURE__ */ React65.createElement("div", {
    className: TagType_module_default.counter
  }, counter), avatar && /* @__PURE__ */ React65.createElement("div", null, /* @__PURE__ */ React65.createElement("img", {
    src: avatar,
    alt: ""
  })), avatar ? /* @__PURE__ */ React65.createElement("div", null, tag) : tag, children);
});
var TagSP_default = TagSP;

// src/Tag/Tag.tsx
var Tag = React66.forwardRef((props, ref) => {
  const _a = props, { intent = "default" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP21[intent];
  const color = COLOR_MAP25[intent];
  return /* @__PURE__ */ React66.createElement(TagSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Tag_default = Tag;
var VIEW_MAP21 = {
  primary: "smooth",
  default: "smooth",
  success: "smooth",
  warning: "smooth",
  danger: "smooth"
};
var COLOR_MAP25 = {
  primary: "primary",
  default: "default",
  success: "success",
  warning: "warning",
  danger: "danger"
};

// src/TagInput/TagInput.tsx
import React68 from "react";

// src/TagInput/TagInputSP.tsx
import cx33 from "classnames";
import React67 from "react";
import { TagInput as TagInputSource } from "@blueprintjs/core";
import classNames9 from "classnames";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/TagInput/TagInput.module.scss
var TagInput_module_default = {
  "input_tag": "_input_tag_x6jys_1",
  "tag": "_tag_x6jys_39",
  "dense": "_dense_x6jys_43",
  "active": "_active_x6jys_91"
};

// src/TagInput/TagInputSP.tsx
var TagInputSP = React67.forwardRef((props, ref) => {
  const _a = props, {
    className,
    autoFocus = false,
    intent = "primary",
    isDense,
    tagProps,
    inputProps
  } = _a, rest = __objRest(_a, [
    "className",
    "autoFocus",
    "intent",
    "isDense",
    "tagProps",
    "inputProps"
  ]);
  return /* @__PURE__ */ React67.createElement(TagInputSource, __spreadProps(__spreadValues({}, rest), {
    ref,
    className: cx33(color_module_default[intent], TagInput_module_default.input_tag, className),
    tagProps: __spreadProps(__spreadValues({}, tagProps), {
      minimal: true,
      className: classNames9(TagInput_module_default.tag, isDense && TagInput_module_default.dense),
      intent: intent === "primary" ? intent : "none"
    }),
    intent: intent === "primary" ? intent : "none",
    inputProps: __spreadProps(__spreadValues({}, inputProps), { autoFocus })
  }));
});
var TagInputSP_default = TagInputSP;

// src/TagInput/TagInput.tsx
var TagInput = React68.forwardRef((props, ref) => {
  return /* @__PURE__ */ React68.createElement(TagInputSP_default, __spreadValues({
    ref
  }, props));
});
var TagInput_default = TagInput;

// src/TextArea/TextArea.tsx
import React70 from "react";

// src/TextArea/TextAreaSP.tsx
import {
  TextArea
} from "@blueprintjs/core";
import cx34 from "classnames";
import React69 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/TextArea/TextAreaType.module.scss
var TextAreaType_module_default = {
  "reverseFlex": "_reverseFlex_10w8f_1",
  "error-helper-text": "_error-helper-text_10w8f_5",
  "def": "_def_10w8f_5",
  "dense": "_dense_10w8f_14",
  "isFull": "_isFull_10w8f_23",
  "grow": "_grow_10w8f_34",
  "full": "_full_10w8f_57",
  "clearButton": "_clearButton_10w8f_109",
  "right-icon": "_right-icon_10w8f_132",
  "shake-horizontal": "_shake-horizontal_10w8f_1"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/TextArea/TextAreaView.module.scss
var TextAreaView_module_default = {
  "smooth": "_smooth_113gf_3",
  "active": "_active_113gf_10",
  "outlined": "_outlined_113gf_55",
  "raised": "_raised_113gf_132",
  "success": "_success_113gf_146",
  "error-helper-text": "_error-helper-text_113gf_147",
  "filled": "_filled_113gf_189",
  "clearButton": "_clearButton_113gf_265"
};

// src/TextArea/TextAreaSP.tsx
var TextAreaSP = React69.forwardRef((props, ref) => {
  const _a = props, {
    view = "outlined",
    color = "primary",
    className,
    label,
    value,
    isDense,
    isSuccess,
    isFixed,
    fill,
    errorHelperText,
    onChange,
    growVertically = true,
    autoFocus = isDense ? false : true
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "className",
    "label",
    "value",
    "isDense",
    "isSuccess",
    "isFixed",
    "fill",
    "errorHelperText",
    "onChange",
    "growVertically",
    "autoFocus"
  ]);
  const [isActive, setActive] = React69.useState(false);
  const handleFocus = () => {
    setActive(true);
  };
  const handleBlur = () => {
    setActive(false);
  };
  const handleChange = (ev) => {
    onChange((ev == null ? void 0 : ev.target.value) ?? "");
  };
  return /* @__PURE__ */ React69.createElement("div", {
    className: cx34(TextAreaType_module_default.reverseFlex, className, {
      [TextAreaType_module_default.isFull]: fill
    })
  }, errorHelperText && /* @__PURE__ */ React69.createElement("div", {
    className: cx34(TextAreaType_module_default["error-helper-text"], TextAreaView_module_default["error-helper-text"], isDense ? TextAreaType_module_default["dense"] : TextAreaType_module_default["def"])
  }, errorHelperText), /* @__PURE__ */ React69.createElement(TextArea, __spreadProps(__spreadValues({}, restProps), {
    ref,
    value,
    onChange: handleChange,
    className: cx34(isFixed ? TextAreaType_module_default["fixed"] : isDense ? TextAreaType_module_default["dense"] : TextAreaType_module_default["def"], fill && TextAreaType_module_default["full"], isDense ? TextAreaType_module_default["dense"] : TextAreaType_module_default["def"], growVertically && TextAreaType_module_default["grow"], isActive && TextAreaView_module_default["active"], isSuccess && TextAreaView_module_default["success"], color_module_default[color], isFixed ? TextAreaView_module_default["fixed"] : TextAreaView_module_default[view]),
    onFocus: handleFocus,
    autoFocus,
    onBlur: handleBlur
  })), label && /* @__PURE__ */ React69.createElement("label", {
    className: color_module_default[color]
  }, label));
});
var TextAreaSP_default = TextAreaSP;

// src/TextArea/TextArea.tsx
var TextArea2 = React70.forwardRef((props, ref) => {
  const _a = props, { intent = "primary", autoFocus = false } = _a, restProps = __objRest(_a, ["intent", "autoFocus"]);
  const view = VIEW_MAP22[intent];
  const color = COLOR_MAP26[intent];
  return /* @__PURE__ */ React70.createElement(TextAreaSP_default, __spreadValues({
    view,
    color,
    autoFocus,
    ref
  }, restProps));
});
var TextArea_default = TextArea2;
var VIEW_MAP22 = {
  primary: "outlined",
  default: "outlined"
};
var COLOR_MAP26 = {
  primary: "primary",
  default: "default"
};

// src/TimePicker/TimePicker.tsx
import React72 from "react";

// src/TimePicker/TimePickerSP.tsx
import cx35 from "classnames";
import React71 from "react";
import {
  TimePicker as TimePickerSource
} from "@blueprintjs/datetime";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/TimePicker/TimePickerType.module.scss
var TimePickerType_module_default = {
  "default": "_default_1r2e3_2"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/TimePicker/TimePickerView.module.scss
var TimePickerView_module_default = {
  "outlined": "_outlined_1ro76_2"
};

// src/TimePicker/TimePickerSP.tsx
var TimePickerSP = React71.forwardRef((props, ref) => {
  const _a = props, {
    isDense,
    view = "outlined",
    color = "primary",
    precision,
    isActive,
    className
  } = _a, restProps = __objRest(_a, [
    "isDense",
    "view",
    "color",
    "precision",
    "isActive",
    "className"
  ]);
  return /* @__PURE__ */ React71.createElement(TimePickerSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx35(TimePickerType_module_default[isDense ? "dense" : "default"], isDense && TimePickerType_module_default["dense"], TimePickerView_module_default[view], color_module_default[color], isActive && TimePickerView_module_default["focused"], className),
    precision
  }));
});
var TimePickerSP_default = TimePickerSP;

// src/TimePicker/TimePicker.tsx
var TimePicker = React72.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP23[intent];
  const color = COLOR_MAP27[intent];
  return /* @__PURE__ */ React72.createElement(TimePickerSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var TimePicker_default = TimePicker;
var VIEW_MAP23 = {
  primary: "outlined"
};
var COLOR_MAP27 = {
  primary: "primary"
};

// src/Toaster/ToasterSP.tsx
import cx36 from "classnames";
import React73 from "react";
import { Toaster as ToasterSource } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Toaster/ToasterType.module.scss
var ToasterType_module_default = {
  "def": "_def_1bfvp_2",
  "no-close": "_no-close_1bfvp_54"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Toaster/ToasterView.module.scss
var ToasterView_module_default = {
  "filled": "_filled_1bob5_4",
  "smooth": "_smooth_1bob5_38",
  "outlined": "_outlined_1bob5_88",
  "raised": "_raised_1bob5_141"
};

// src/Toaster/ToasterSP.tsx
var ToasterSP = React73.forwardRef((props, ref) => {
  const _a = props, {
    view = "raised",
    color,
    isDense,
    withoutClose,
    className
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "isDense",
    "withoutClose",
    "className"
  ]);
  return /* @__PURE__ */ React73.createElement(ToasterSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    className: cx36(ToasterType_module_default[isDense ? "dense" : "def"], ToasterView_module_default[view], color && color_module_default[color], withoutClose && ToasterType_module_default["no-close"], className)
  }));
});
var ToasterSP_default = ToasterSP;

// src/Tooltip/Tooltip.tsx
import React75 from "react";

// src/Tooltip/TooltipSP.tsx
import {
  Tooltip2 as TooltipSource
} from "@blueprintjs/popover2";
import cx37 from "classnames";
import React74 from "react";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Tooltip/TooltipType.module.scss
var TooltipType_module_default = {
  "def": "_def_4ccho_2"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Tooltip/TooltipView.module.scss
var TooltipView_module_default = {
  "filled": "_filled_trbal_3",
  "smooth": "_smooth_trbal_32",
  "raised": "_raised_trbal_61"
};

// src/Tooltip/TooltipSP.tsx
var TooltipSP = React74.forwardRef((props, ref) => {
  const _a = props, {
    view = "smooth",
    color = "primary",
    children,
    className,
    minimal = true,
    isDense = false,
    isDisabled,
    offset
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "children",
    "className",
    "minimal",
    "isDense",
    "isDisabled",
    "offset"
  ]);
  const defaultOffset = [0, 7];
  return /* @__PURE__ */ React74.createElement(TooltipSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    popoverClassName: cx37(TooltipType_module_default[isDense ? "dense" : "def"], TooltipView_module_default[view], color_module_default[color], className),
    minimal,
    disabled: isDisabled,
    modifiers: {
      offset: {
        enabled: true,
        options: { offset: offset ?? defaultOffset }
      }
    }
  }), children);
});
var TooltipSP_default = TooltipSP;

// src/Tooltip/Tooltip.tsx
var Tooltip = React75.forwardRef((props, ref) => {
  const _a = props, { intent = "default" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP24[intent];
  const color = COLOR_MAP28[intent];
  return /* @__PURE__ */ React75.createElement(TooltipSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Tooltip_default = Tooltip;
var VIEW_MAP24 = {
  default: "smooth"
};
var COLOR_MAP28 = {
  default: "default"
};

// src/Tree/Tree.tsx
import React77 from "react";

// src/Tree/TreeSP.tsx
import cx38 from "classnames";
import React76 from "react";
import { Tree as TreeSource } from "@blueprintjs/core";

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Tree/TreeType.module.scss
var TreeType_module_default = {
  "def": "_def_ox70o_2",
  "dense": "_dense_ox70o_9"
};

// stylePlugin:/tmp/nix-build-aletiq-design-system-1.0.0.drv-0/design-system/deps/@aletiq/design-system/src/Tree/TreeView.module.scss
var TreeView_module_default = {
  "flat": "_flat_1clk0_2"
};

// src/Tree/TreeSP.tsx
var TreeSP = React76.forwardRef((props, ref) => {
  const _a = props, {
    view = "flat",
    color = "primary",
    isDense,
    contents,
    className
  } = _a, restProps = __objRest(_a, [
    "view",
    "color",
    "isDense",
    "contents",
    "className"
  ]);
  return /* @__PURE__ */ React76.createElement(TreeSource, __spreadProps(__spreadValues({}, restProps), {
    ref,
    contents,
    className: cx38(TreeType_module_default[isDense ? "dense" : "def"], TreeView_module_default[view], color_module_default[color], className)
  }));
});
var TreeSP_default = TreeSP;

// src/Tree/Tree.tsx
var Tree = React77.forwardRef((props, ref) => {
  const _a = props, { intent = "primary" } = _a, restProps = __objRest(_a, ["intent"]);
  const view = VIEW_MAP25[intent];
  const color = COLOR_MAP29[intent];
  return /* @__PURE__ */ React77.createElement(TreeSP_default, __spreadValues({
    view,
    color,
    ref
  }, restProps));
});
var Tree_default = Tree;
var VIEW_MAP25 = {
  primary: "flat"
};
var COLOR_MAP29 = {
  primary: "primary"
};
export {
  AlertPopover,
  AlertPopoverBase,
  AlertPopoverContent,
  AlertPopoverFooter,
  AlertPopoverFooterBase,
  AlertPopoverHeader,
  AlertPopoverHeaderBase,
  AlertPopoverTitle,
  AnchorButton_default as AnchorButton,
  AnchorButtonSP_default as AnchorButtonSP,
  Badge_default as Badge,
  Breadcrumbs_default as Breadcrumbs,
  Button,
  ButtonGroup_default as ButtonGroup,
  ButtonSP,
  Callout_default as Callout,
  Card,
  CardList,
  CheckBox_default as CheckBox,
  Counter_default as Counter,
  DatePicker_default as DatePicker,
  DateRangePicker_default as DateRangePicker,
  Dialog,
  Dialog2,
  DialogBase,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogFooterBase,
  DialogHeader,
  DialogHeaderBase,
  DialogHint,
  DialogStepTitle,
  DialogSteps,
  DialogTitle,
  Divider_default as Divider,
  DropdownSP_default as DropdownButton,
  DropdownSP_default as DropdownSP,
  EditableText_default as EditableText,
  Field,
  FieldLabel,
  FileInput_default as FileInput,
  H1_default as H1,
  H2_default as H2,
  H3_default as H3,
  HighlightedText,
  Icon6 as Icon,
  Input_default as Input,
  Menu_default as Menu,
  MenuDivider_default as MenuDivider,
  MenuItem_default as MenuItem,
  MenuSP_default as MenuSP,
  MultiSelectSP_default as MultiSelect,
  MultiSelectSP_default as MultiSelectSP,
  MultistepDialog,
  NumericInput_default as NumericInput,
  Popover2_default as Popover2,
  Radio_default as Radio,
  SearchInput_default as SearchInput,
  SearchInputSP_default as SearchInputSP,
  Select_default as Select,
  Slider_default as Slider,
  Switch_default as Switch,
  Tabs_default as Tabs,
  TabsSegmented_default as TabsSegmented,
  Tag_default as Tag,
  TagInput_default as TagInput,
  TagSP_default as TagSP,
  TextArea_default as TextArea,
  TimePicker_default as TimePicker,
  ToasterSP_default as ToasterSP,
  Tooltip_default as Tooltip,
  Tree_default as Tree
};
