import { Process } from "@aletiq/types";
import { useCurrentUser } from "../../../hooks";
import { Filter } from "../types";
import useUserParticipatingWorkflows from "./useUserParticipatingWorkflows";

export default function useFilteredWorkflows(
  workflows: Process[],
  filter: Filter
) {
  const { data: currentUser } = useCurrentUser();
  const participatedWorkflows = useUserParticipatingWorkflows(
    currentUser?.id || 0,
    workflows
  );

  const participatedWorkflowsId = participatedWorkflows.map(
    (process) => process.id
  );

  const hasValidOwner = (
    myContributionWorkflow: boolean = false,
    workflow: Process
  ) => {
    if (!myContributionWorkflow) return true;
    if (myContributionWorkflow && participatedWorkflowsId.includes(workflow.id))
      return true;
    return false;
  };

  return workflows.filter((workflow) => {
    const { myContributionWorkflow = false } = filter;

    if (!hasValidOwner(myContributionWorkflow, workflow)) return false;

    return true;
  });
}
