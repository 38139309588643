import React, { useCallback, useMemo } from "react";
import { CellProps } from "react-table";
import {
  AlertPopover,
  Button,
  EditableNumberCell,
  EditableTextCell,
  ExtendedColumn,
  ProjectNameLink,
} from "../../../components";
import { useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import {
  useDeleteToolingRequirement,
  useUpdateToolingRequirement,
} from "../hooks";
import { ResolvedToolingRequirement } from "./ProjectTooling";
import styles from "./ProjectTooling.module.scss";

export default function useToolingColumns(articleId: number) {
  const tr = useTranslations();

  const canUpdateProduct = useHasPermission("update:projects");
  const { mutate: removeTool } = useDeleteToolingRequirement();
  const { mutate: updateTool } = useUpdateToolingRequirement();

  const handleRemoveTool = useCallback(
    (requirementId: number) => {
      removeTool({ articleId, requirementId });
    },
    [articleId, removeTool]
  );

  const handleUpdateTool = useCallback(
    ({ tool, quantity, comment, id }: ResolvedToolingRequirement) => {
      updateTool({
        articleId: articleId,
        requirementId: id,
        spec: { tool, quantity, comment },
      });
    },
    [articleId, updateTool]
  );

  return useMemo<ExtendedColumn<ResolvedToolingRequirement>[]>(
    () => [
      {
        Header: tr.translate("generic.label.name"),
        id: "tool",
        accessor: "tool",
        Cell: (cellProps: CellProps<ResolvedToolingRequirement>) => {
          const toolInfo = cellProps.row.original;
          return (
            <ProjectNameLink
              hasIcon
              projectId={toolInfo.tool}
              search={cellProps.searchString}
              className={styles.link}
            />
          );
        },
        hoverButton: (component, className?: string) => (
          <div className={className}>
            <AlertPopover
              onPrimaryClick={() => handleRemoveTool(component.id)}
              title={tr.translateAsString("project.tooling.remove")}
              content={tr.translate("project.tooling.remove.description")}
              position="bottom-right"
              isDense
            >
              <Button
                isDense
                intent="array_primary"
                icon="trash"
                className={styles.hover_button}
                isDisabled={!canUpdateProduct}
              />
            </AlertPopover>
          </div>
        ),
      },
      {
        Header: tr.translate("generic.label.quantity"),
        id: "quantity",
        accessor: "quantity",
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ResolvedToolingRequirement>) => {
          const tool = cellProps.row.original;
          return (
            <EditableNumberCell
              value={tool.quantity}
              onConfirmNewValue={(quantity) =>
                handleUpdateTool({ ...tool, quantity })
              }
            />
          );
        },
      },
      {
        Header: tr.translate("generic.label.comment"),
        id: "comment",
        accessor: "comment",
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ResolvedToolingRequirement>) => {
          const tool = cellProps.row.original;
          return (
            <EditableTextCell
              value={tool.comment}
              highlight={cellProps.searchString}
              onConfirmNewValue={(comment) =>
                handleUpdateTool({ ...tool, comment })
              }
            />
          );
        },
      },
    ],
    [tr, canUpdateProduct, handleRemoveTool, handleUpdateTool]
  );
}
