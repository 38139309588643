import { RevisionSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { pdmKeys } from "./queries";

export default function useUploadCadFile() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { partId: number; revision: RevisionSpec; file: File }) => {
      const { partId, revision, file } = values;
      return await api.pdm.uploadCadFile(partId, revision, file);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pdmKeys.all);
      },
    }
  );
}
