import { AccessEntity, Document } from "@aletiq/types";
import { Classes, Spinner } from "@blueprintjs/core";
import React from "react";
import { Dialog } from "../../../../components";
import { useSortedAccess } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useDocumentAccess } from "../../hooks";
import EditLoadedEntityAccess from "./EditLoadedEntityAccess";
import { useSubmitAccessChanges } from "./hooks";
import styles from "./ShareDocumentDialog.module.scss";

export default function ShareDocumentDialog(props: {
  document: Document;
  onClose: () => void;
}) {
  const { document, onClose } = props;
  const tr = useTranslations();

  const { data: accessEntities = [], isLoading } = useDocumentAccess(
    document.id
  );
  const { isPublic, usersWithAccess, groupsWithAccess } =
    useSortedAccess(accessEntities);

  const { mutate: editDocumentAccess, isLoading: isSubmitting } =
    useSubmitAccessChanges(document.id);

  const handleSubmit = (newAccessRights: AccessEntity[], newOwner?: number) => {
    editDocumentAccess({ newAccessRights, newOwner }, { onSettled: onClose });
  };

  const dialogTitle = tr.translateAsString("document.dialog.share.title");

  return (
    <>
      {isLoading && (
        <Dialog
          isOpen
          enforceFocus={false}
          icon="people"
          onClose={onClose}
          title={dialogTitle}
          className={styles.dialog}
          disablePrimary
          onSecondaryClick={onClose}
        >
          <div className={Classes.DIALOG_BODY}>
            <Spinner />
          </div>
        </Dialog>
      )}
      {!isLoading && (
        <EditLoadedEntityAccess
          initialOwner={document.user}
          groupsWithAccess={groupsWithAccess}
          usersWithAccess={usersWithAccess}
          onClose={onClose}
          onSubmit={handleSubmit}
          isPublic={isPublic}
          isSubmitting={isSubmitting}
          title={dialogTitle}
        />
      )}
    </>
  );
}
