export default function partitionWith<T>(
  values: T[],
  ...predFns: ((elem: T) => boolean)[]
) {
  const result: T[][] = new Array(predFns.length + 1)
    .fill(undefined)
    .map(() => []);

  values.forEach((v) => {
    for (let i = 0; i < predFns.length; i++) {
      if (predFns[i](v)) {
        result[i].push(v);
        return;
      }
    }
    result[predFns.length].push(v);
  });
  return result;
}
