import { Process, Task } from "@aletiq/types";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useCurrentUser, useHasPermission } from "../../../../hooks";
import { useProcessEditorState, useUpdateWorkflowAndTasks } from "./hooks";
import { makeTasksFromEditorState } from "./taskEditUtils";
import WorkflowBasicView from "./WorkflowBasicView";
import WorkflowEditorView from "./WorkflowEditorView";

export default function WorkflowView(props: {
  isFolded: boolean;
  toggleFold: (workflow: number) => void;
  isSelected: boolean;
  tasks: Task[];
  workflow: Process;
  setActive: (active: {
    task?: number | undefined;
    workflow?: number | undefined;
  }) => void;
  search?: string;
}) {
  const {
    isFolded,
    toggleFold,
    tasks,
    workflow,
    setActive,
    isSelected,
    search,
  } = props;

  const [isBeingEdited, setIsBeingEdited] = useState(false);

  const { data: currentUser } = useCurrentUser();

  const canUpdateAllWorkflows = useHasPermission("update:all-workflows");
  const canUpdateOwnWorkflows = useHasPermission("update:own-workflows");
  const canUpdateWorkflow =
    canUpdateAllWorkflows ||
    (workflow.owner === currentUser?.id && canUpdateOwnWorkflows);

  const [state, dispatch] = useProcessEditorState(
    currentUser?.id ?? 0,
    workflow.id
  );

  const currentTasks = useMemo(
    () => makeTasksFromEditorState(state.tasks),
    [state]
  );

  const { mutate: updateWorkflow, isLoading: isUpdatingWorkflow } =
    useUpdateWorkflowAndTasks();

  const handleDiscardChanges = () => {
    dispatch({
      type: "resetFomWorkflow",
      tasks,
      title: workflow.title,
      id: workflow.id,
    });
    setIsBeingEdited(false);
  };

  const handleSaveChanges = () => {
    updateWorkflow(
      {
        workflow: { title: state.title },
        tasks: state.tasks,
        workflowId: workflow.id,
      },
      { onSettled: () => setIsBeingEdited(false) }
    );
  };

  const handleStartEditing = () => {
    setIsBeingEdited(true);
    dispatch({
      type: "resetFomWorkflow",
      tasks,
      title: workflow.title,
      id: workflow.id,
    });
    isFolded && toggleFold(workflow.id);
  };

  const location = useLocation();
  const workflowRef: React.RefObject<any> = useRef();

  //scroll to workflow if redirected to it from other page and open it
  useLayoutEffect(() => {
    if (workflowRef && location.hash === `#${workflow.id}`) {
      workflowRef.current.scrollIntoView();
      toggleFold(workflow.id);
    }
  }, [workflow.id, workflowRef, location, toggleFold]);

  return (
    <div ref={workflowRef}>
      {!isBeingEdited && (
        <WorkflowBasicView
          canUpdateWorkflow={canUpdateWorkflow}
          isFolded={isFolded}
          isSelected={isSelected}
          onSetActive={setActive}
          onStartEdit={handleStartEditing}
          tasks={tasks}
          toggleFold={toggleFold}
          workflow={workflow}
          search={search}
        />
      )}
      {isBeingEdited && (
        <WorkflowEditorView
          currentTitle={state.title}
          currentTasks={currentTasks}
          workflow={workflow}
          isFolded={isFolded}
          isSelected={isSelected}
          editorDispatch={dispatch}
          onSaveChanges={handleSaveChanges}
          onDiscardChanges={handleDiscardChanges}
          isUpdatingWorkflow={isUpdatingWorkflow}
          onSetActive={setActive}
          toggleFold={toggleFold}
        />
      )}
    </div>
  );
}
