import { API } from "@aletiq/api";
import { DocumentRevisionSpec, DocumentSpec } from "@aletiq/types";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

type FilesState = {
  fileUpload: Record<
    string,
    { name: string; progress: number; failed?: boolean; done?: boolean }
  >;
};

const filesInitialState: FilesState = {
  fileUpload: {},
};

const filesSlice = createSlice({
  name: "files",
  initialState: filesInitialState,
  reducers: {
    fileUploadStarted(
      state,
      { payload }: PayloadAction<{ key: string; name: string }>
    ) {
      state.fileUpload[payload.key] = { name: payload.name, progress: 0 };
    },
    fileUploadProgressed(
      state,
      { payload }: PayloadAction<{ key: string; progress: number }>
    ) {
      state.fileUpload[payload.key] = {
        ...state.fileUpload[payload.key],
        progress: payload.progress,
      };
    },
    fileUploadFinished(state, { payload }: PayloadAction<{ key: string }>) {
      state.fileUpload[payload.key] = {
        ...state.fileUpload[payload.key],
        done: true,
      };
    },
    fileUploadFailed(state, { payload }: PayloadAction<{ key: string }>) {
      state.fileUpload[payload.key] = {
        ...state.fileUpload[payload.key],
        failed: true,
      };
    },
    fileUploadDissmissed(state, { payload }: PayloadAction<{ key: string }>) {
      delete state.fileUpload[payload.key];
    },
  },
});

export const files = filesSlice.reducer;

const {
  fileUploadStarted: updateFileStart,
  fileUploadProgressed: updateFileProgress,
  fileUploadFinished: updateFileDone,
  fileUploadFailed: updateFileFailed,
} = filesSlice.actions;

export const { fileUploadDissmissed } = filesSlice.actions;

export async function updateDocument(
  api: API,
  dispatch: Dispatch,
  documentId: number,
  revisionSpec: DocumentRevisionSpec | null,
  file: File
): Promise<void> {
  const key = `${documentId}`;
  dispatch(updateFileStart({ key, name: file.name }));
  try {
    await api.document.updateDocument(
      documentId,
      revisionSpec,
      file,
      (progress) => {
        dispatch(updateFileProgress({ key, progress }));
      }
    );
    dispatch(updateFileDone({ key }));
  } catch (err) {
    dispatch(updateFileFailed({ key }));
  }
}

export async function createDocument(
  api: API,
  dispatch: Dispatch,
  spec: DocumentSpec,
  content: File
): Promise<void> {
  const key = `${spec.name}`;
  dispatch(updateFileStart({ key, name: spec.name }));
  try {
    await api.document.createDocument(spec, content, (progress) =>
      updateFileProgress({ key, progress })
    );
    dispatch(updateFileDone({ key }));
  } catch (err) {
    dispatch(updateFileFailed({ key }));
  }
}
