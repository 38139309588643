import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useProductVersions(product: number | null) {
  const api = useApi();
  return useQuery(
    projectKeys.allVersions(product!),
    () => api.project.getProjectVersions(product!),
    { enabled: product !== null }
  );
}
