import { useProductVersions } from "../../project/hooks";
import useDocument from "./useDocument";

//product version a given doc is used in
export type ProductVersionUsedIn = {
  id: number;
  isDraft: boolean;
  product: number;
  name: string;
  docRevisionId: number;
  docRevisionName: string;
};

export default function useProductVersionsUsedIn(
  documentId: number,
  productId: number
): ProductVersionUsedIn[] {
  const { data: productVersions = [] } = useProductVersions(productId);
  const { data: document } = useDocument(documentId);

  const docRevisions = document?.revisions ?? [];
  const versionsUsedIn = productVersions.filter((v) =>
    v.documents.some((d) => d.document.id === documentId)
  );

  return versionsUsedIn.map(
    ({ id, project: product, version: name, documents, isDraft }) => {
      const docRevisionId = documents.find((d) => d.document.id === documentId)
        ?.revision.revisionId!;

      const docRevisionName =
        docRevisions.find((r) => r.id === docRevisionId)?.revisionName ?? "";

      return {
        id,
        isDraft,
        product,
        name,
        docRevisionId,
        docRevisionName,
      };
    }
  );
}
