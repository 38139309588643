import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";

export default function SwAssemblyIcon(
  props: { size?: string | number } & React.HTMLProps<any>
) {
  const { size = 20 } = props;
  return (
    <span {...props} className={classNames(Classes.ICON, props.className)}>
      <svg
        width={size}
        height={size}
        viewBox="-0.2 -0.2 20.4 14.4"
        fill="none"
        stroke="#000F55"
        strokeWidth="0.4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 6 H6 V0 H14 V6 H20 V12 H0 Z" fill="#FFE35A" />
      </svg>
    </span>
  );
}
