import { ToolingRequirementSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks/toaster";
import { useTranslations } from "../../../util";
import { activityKeys } from "../../activities/hooks/queries";
import { projectKeys } from "./queries";

export default function useAddToolingRequirements(articleId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (tools: ToolingRequirementSpec[]) => {
      for (const toolSpec of tools) {
        await api.project.addToolingRequirement(articleId, toolSpec);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          projectKeys.toolingRequirementById(articleId)
        );
        queryClient.invalidateQueries(projectKeys.toolingArticles);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.product.tool.add"),
        });
      },
    }
  );
}
