import { Spinner } from "@blueprintjs/core";
import React from "react";
import { Dialog } from "../../../../components";
import { useTranslations } from "../../../../util";
import {
  useDefaultAccess,
  useReducerAccess,
} from "../../../documents/dialogs/ShareDocumentsDialog/hooks";
import ShareEntitiesDialogSelection from "../../../documents/dialogs/ShareDocumentsDialog/ShareEntitiesDialogSelection";
import mergeStateAndEntityAccess from "../../../documents/dialogs/ShareDocumentsDialog/mergeStateAndEntityAccess";
import { usePartsAccess, useUpdatePartsAccesses } from "../../hooks";
import styles from "./SharePartsDialog.module.scss";

export default function ShareDocumentsDialog(props: {
  partIds: number[];
  onClose: () => void;
}) {
  const { partIds, onClose } = props;
  const tr = useTranslations();

  const { data: partsAccess, isLoading } = usePartsAccess(partIds);
  const { mutate: updatePartAccesses, isLoading: isSubmitting } =
    useUpdatePartsAccesses();
  const [accessState, dispatch] = useReducerAccess();

  const handleSubmit = () => {
    const newPartsAccesses = mergeStateAndEntityAccess(
      partIds,
      partsAccess,
      accessState
    );
    updatePartAccesses(newPartsAccesses, {
      onSettled: () => {
        onClose();
      },
    });
  };

  const {
    defaultUsers,
    defaultGroups,
    defaultPublicEntities: defaultPublicParts,
  } = useDefaultAccess(partIds, partsAccess);

  return (
    <Dialog
      isOpen
      onClose={onClose}
      icon="people"
      title={tr.translateAsString("part.dialog.share-parts.title")}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={onClose}
      className={styles.dialog}
      submitting={isSubmitting}
      enforceFocus={false}
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <ShareEntitiesDialogSelection
          total={partIds.length}
          defaultPublicDocuments={defaultPublicParts}
          defaultUsers={defaultUsers}
          defaultGroups={defaultGroups}
          accessState={accessState}
          accessDispatch={dispatch}
        />
      )}
    </Dialog>
  );
}
