import { Document, Property } from "@aletiq/types";
import React from "react";
import { DateWithSpaces, Field } from "../../../components";
import { useTranslations } from "../../../util";
import {
  useAletiqPropertiesDefaultDisplay,
  useProperties,
} from "../../administration/Attributes/hooks";
import { PropertyField } from "../../passport/PassportDetails/ConfigurationField";

export default function VisibleDocumentProperties(props: {
  document: Document;
}) {
  const { document } = props;
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("document");
  const { data: aletiqProperties = [] } =
    useAletiqPropertiesDefaultDisplay("document");

  const visibleProperties = properties.filter((p) => p.displayByDefault);

  return (
    <>
      {aletiqProperties.some(
        (p) => p.name === "created_at" && p.displayByDefault
      ) && (
        <Field
          inline
          minWidth={200}
          label={tr.translate("generic.label.creation-date.title")}
        >
          <DateWithSpaces date={document.createdAt} />
        </Field>
      )}

      {aletiqProperties.some(
        (p) => p.name === "updated_at" && p.displayByDefault
      ) && (
        <Field
          inline
          minWidth={200}
          label={tr.translate("generic.label.last-update.title")}
        >
          <DateWithSpaces date={document.updatedAt} />
        </Field>
      )}

      {visibleProperties?.map((property: Property) => (
        <PropertyField
          key={`property-${property.id}`}
          property={property}
          value={document.properties[property.name]}
          hasDescriptionTooltip
          canUpdate={false}
          minWidth={200}
        />
      ))}
    </>
  );
}
