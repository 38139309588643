import { Card, EditableText } from "@aletiq/design-system";
import { Process, Task } from "@aletiq/types";
import React, { Dispatch, useMemo } from "react";
import { useWorkflowColor } from "../../hooks";
import WorkflowCardHeader from "../WorkflowCardHeader";
import { WorkflowEditorTaskTable } from "./EditorTaskTable";
import TaskAddButton from "./TaskAddButton";
import { makeDispatchHandlers, WorkflowEditorAction } from "./taskEditReducer";
import styles from "./WorkflowView.module.scss";

export default function WorkflowEditorView(props: {
  currentTitle: string;
  currentTasks: Task[];
  workflow: Process;
  isFolded: boolean;
  isSelected: boolean;
  isUpdatingWorkflow: boolean;
  editorDispatch: Dispatch<WorkflowEditorAction>;
  onSaveChanges: () => void;
  onDiscardChanges: () => void;
  onSetActive: (active: {
    task?: number | undefined;
    workflow?: number | undefined;
  }) => void;
  toggleFold: (workflow: number) => void;
}) {
  const {
    currentTitle,
    currentTasks,
    isFolded,
    isSelected,
    isUpdatingWorkflow,
    editorDispatch,
    onSaveChanges,
    onDiscardChanges,
    onSetActive,
    toggleFold,
    workflow,
  } = props;

  const {
    handleEditTitle,
    handleDragAndDrop,
    handleNewSpecEdited,
    handleTaskEdited,
    handleRemoveTask,
    handleMarkForDeletion,
    handleCreateTask,
  } = useMemo(() => makeDispatchHandlers(editorDispatch), [editorDispatch]);

  const workflowColor = useWorkflowColor(workflow);

  const handleOpenDetails = () => onSetActive({ workflow: workflow.id });

  const handleToggleFold = () => toggleFold(workflow.id);

  return (
    <Card
      isFolded={isFolded}
      isActive={isSelected}
      onClick={handleOpenDetails}
      onToggleFold={handleToggleFold}
      icon="flow-linear"
      title={
        <EditableText
          value={currentTitle}
          onChange={handleEditTitle}
          className={styles.workflow_title}
        />
      }
      headerActions={
        <WorkflowCardHeader
          isBeingEdited
          isFolded={isFolded}
          onSaveChanges={onSaveChanges}
          onDiscardChanges={onDiscardChanges}
          isSavingChanges={isUpdatingWorkflow}
          workflow={{ ...workflow, title: currentTitle }}
        />
      }
    >
      <WorkflowEditorTaskTable
        workflowColor={workflowColor}
        tasks={currentTasks}
        onDragAndDrop={handleDragAndDrop}
        onNewSpecEdited={handleNewSpecEdited}
        onMarkForDeletion={handleMarkForDeletion}
        onRemoveNewTask={handleRemoveTask}
        onTaskEdited={handleTaskEdited}
      />
      <TaskAddButton onClick={handleCreateTask} />
    </Card>
  );
}
