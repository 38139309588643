import { RevisionState } from "@aletiq/types";
import React from "react";
import { ChangeRevisionPopover } from ".";
import { GenericStateTag } from "../../components";
import { nextState } from "../../util";
import { StateNotification } from "../tags/GenericStateTag/GenericStateTag";
import { ChangeRevisionHandler, ObjectType } from "./types";

export default function ChangeRevisionEditButton(props: {
  onChange: ChangeRevisionHandler;
  state: RevisionState;
  notification?: StateNotification;
  canChange?: boolean;
  objectType: ObjectType;
  isPublished?: boolean;
}) {
  const {
    onChange,
    canChange = true,
    state,
    notification,
    objectType,
    isPublished,
  } = props;
  const finalState = nextState(state);
  if (!finalState || !canChange) {
    return <GenericStateTag state={state} notification={notification} />;
  }
  return (
    <ChangeRevisionPopover
      onConfirm={onChange[finalState]}
      objectType={objectType}
      initial={{
        state,
        notification,
      }}
      final={{
        state: finalState,
      }}
      isPublished={isPublished}
    />
  );
}
