import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { userKeys } from "../../hooks/queries";

export default function useDeleteUserGroup() {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation<Response, Error, number>(
    (groupId) => api.user.deleteUserGroup(groupId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userKeys.groups);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.group.delete"),
        });
      },
    }
  );
}
