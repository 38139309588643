import { AnchorButton, Tooltip } from "@aletiq/design-system";
import React from "react";
import { useNavigateTo } from "../../hooks";
import { useTranslations } from "../../util";

export default function PanelCloseButton() {
  const tr = useTranslations();
  const handleClosePanel = useNavigateTo({ panel: null, panelTab: null });

  return (
    <Tooltip
      position="top-right"
      content={tr.translateAsString("panel.action.close")}
    >
      <AnchorButton isDense icon="cross" onClick={handleClosePanel} />
    </Tooltip>
  );
}
