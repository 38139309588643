import React, {
  createContext,
  Fragment,
  ReactNode,
  RefObject,
  useMemo,
  useRef,
} from "react";
import { API_URL } from "../../config";
import { useTranslations } from "../../util";

export const DownloadContext = createContext<DownloadHandler>({
  handleDownload: () =>
    Promise.reject(new Error("Download handler not initialised")),
  handleFile: () => {},
});

export type DownloadHandler = {
  handleDownload: (request: DownloadRequest) => Promise<void>;
  handleFile: (blob: File) => void;
};

export type DownloadRequest = {
  fetchToken: () => Promise<string>;
  forceDownload?: boolean;
};

export function makeDownloadHandler(linkref: RefObject<HTMLAnchorElement>) {
  return async (request: DownloadRequest) => {
    const anchor = linkref.current;
    if (anchor && request) {
      const key = await request.fetchToken();
      anchor.setAttribute(
        "href",
        `${API_URL}${key}${request.forceDownload ? "?forceDownload" : ""}`
      );
      if (request.forceDownload) {
        anchor.setAttribute("target", "_self");
      } else {
        anchor.setAttribute("target", "_blank");
      }
      anchor.click();
    }
  };
}

export function makeFileHandler(linkref: RefObject<HTMLAnchorElement>) {
  return async (file: File) => {
    const anchor = linkref.current;
    if (anchor && file) {
      const url = URL.createObjectURL(file);
      anchor.setAttribute("href", url);
      anchor.setAttribute("target", "_self");
      anchor.setAttribute("download", file.name);
      anchor.click();
    }
  };
}

export default function DownloadProvider(props: { children?: ReactNode }) {
  const { children } = props;
  const tr = useTranslations();
  const linkref = useRef<HTMLAnchorElement>(null);

  const downloadHandler = useMemo(() => {
    return {
      handleDownload: makeDownloadHandler(linkref),
      handleFile: makeFileHandler(linkref),
    };
  }, [linkref]);

  return (
    <Fragment>
      <a
        style={{ display: "none" }}
        rel="noreferrer noopener"
        href="/"
        ref={linkref}
        target="_blank"
      >
        {tr.translate("generic.action.download")}
      </a>
      <DownloadContext.Provider value={downloadHandler}>
        {children}
      </DownloadContext.Provider>
    </Fragment>
  );
}
