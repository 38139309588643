import { Button, ButtonGroup, Divider, Tooltip } from "@aletiq/design-system";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import { SubMentionComponentProps } from "@draft-js-plugins/mention/lib/Mention";
import { ContentBlock, EditorState, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useUser, useUsers } from "../../hooks";
import { isInString, useTranslations } from "../../util";
import "./CommentEditor.css";
import styles from "./CommentEditor.module.scss";
import MentionRow from "./MentionRow";
import PopoverMention from "./PopoverMention";

export default function CommentEditor(props: {
  editorState: EditorState;
  setEditorState: (
    state: EditorState | ((current: EditorState) => EditorState)
  ) => void;
}) {
  const { editorState, setEditorState } = props;
  const editor = useRef<Editor>(null);
  const tr = useTranslations();
  const [open, setOpen] = useState(false);
  const [searchUser, setSearchUser] = useState("");

  const currentBlockType = editorState.getCurrentContent().toJS()?.blockMap?.[
    editorState.getSelection().toJS()?.anchorKey
  ]?.type;
  const currentInlineStyles = editorState.getCurrentInlineStyle().toJS();

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(mentionProps) {
        return <MentionComponent {...mentionProps} />;
      },
      mentionPrefix: "@",
      entityMutability: "IMMUTABLE", // delete all name at delete key we have no way to manage white space otherwise
    });

    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const { data: users } = useUsers();
  const usersMention =
    users
      ?.filter((mention) => isInString(mention.displayName, searchUser))
      .map((user) => ({
        name: user?.displayName,
        id: user?.id,
      })) || [];

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSearchUser(value);
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  function toggleInlineStyle(type: string) {
    return function () {
      editor.current?.focus();
      setEditorState((editorState) =>
        RichUtils.toggleInlineStyle(editorState, type)
      );
    };
  }

  function toggleBlockType(type: string) {
    return function () {
      editor.current?.focus();
      setEditorState((editorState) =>
        RichUtils.toggleBlockType(editorState, type)
      );
    };
  }

  return (
    <div>
      <ButtonGroup isDense>
        <Tooltip
          content={tr.translate("comment.editor.bold")}
          position="top-left"
        >
          <Button
            active={currentInlineStyles.includes("BOLD")}
            icon="bold"
            onClick={toggleInlineStyle("BOLD")}
            isDense
          />
        </Tooltip>
        <Tooltip
          content={tr.translate("comment.editor.italic")}
          position="top-left"
        >
          <Button
            isDense
            active={currentInlineStyles.includes("ITALIC")}
            icon="italic"
            onClick={toggleInlineStyle("ITALIC")}
          />
        </Tooltip>
        <Divider isVertical />
        <Tooltip
          content={tr.translate("comment.editor.unordered-list")}
          position="top-left"
        >
          <Button
            active={currentBlockType === "unordered-list-item"}
            isDense
            icon="properties"
            onClick={toggleBlockType("unordered-list-item")}
          />
        </Tooltip>
        <Tooltip
          content={tr.translate("comment.editor.ordered-list")}
          position="top-left"
        >
          <Button
            active={currentBlockType === "ordered-list-item"}
            isDense
            icon="numbered-list"
            onClick={toggleBlockType("ordered-list-item")}
          />
        </Tooltip>
        <Tooltip
          content={tr.translate("comment.editor.header-one")}
          position="top-left"
        >
          <Button
            active={currentBlockType === "header-one"}
            isDense
            icon="header-one"
            onClick={toggleBlockType("header-one")}
          />
        </Tooltip>
        <Tooltip
          content={tr.translate("comment.editor.header-two")}
          position="top-left"
        >
          <Button
            active={currentBlockType === "header-two"}
            isDense
            icon="header-two"
            onClick={toggleBlockType("header-two")}
          />
        </Tooltip>
      </ButtonGroup>
      <div className={styles.editor} onClick={() => editor.current?.focus()}>
        <Editor
          ref={editor}
          placeholder={
            currentBlockType === "unstyled"
              ? tr.translateAsString("component.comment.placeholder")
              : ""
          }
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          blockStyleFn={blockStyle}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={usersMention}
          onSearchChange={onSearchChange}
          entryComponent={MentionRow}
          popoverComponent={<PopoverMention />}
        />
      </div>
    </div>
  );
}

function MentionComponent(props: SubMentionComponentProps) {
  const userId = props.mention?.id as number;
  const nameMention = props.mention?.name;
  const { data: user } = useUser(userId);
  const ref = useRef<HTMLDivElement>(null);

  if (nameMention) {
    return <span className={styles.mention}>{props.children}</span>;
  }

  return (
    <div
      className={styles.mentionEditWrapper}
      style={{
        width: ref?.current?.clientWidth ?? 0,
      }}
    >
      {props.children}
      <div
        className={styles.mentionEdit}
        ref={ref}
      >{`@${user?.displayName}`}</div>
    </div>
  );
}

// Add classes to draftjs block
const blockStyle = (contentBlock: ContentBlock) => {
  const type = contentBlock.getType();
  if (type === "header-one") {
    return styles.draftH1;
  }
  if (type === "header-two") {
    return styles.draftH2;
  }

  return "";
};
