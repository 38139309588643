import {
  Part,
  PartFormat,
  PartSpec,
  PartType,
  RevisionSpec,
} from "@aletiq/types";
import { Spinner } from "@blueprintjs/core";
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  ANALYTICS_MUTATION_STATUS,
  ANALYTICS_PARTS_COUNT,
  ANALYTICS_PARTS_CREATED,
  ANALYTICS_PARTS_STANDARD_COUNT,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../../analytics";
import useApi from "../../../../app/useApi";
import {
  Dialog,
  Divider,
  Field,
  FileInput,
  Icon,
  PartIcon,
} from "../../../../components";
import {
  ALLOWED_PART_FILE_TYPES,
  removeFileExtension,
  useTranslations,
} from "../../../../util";
import { pdmQueries } from "../../hooks/queries";
import { partFormatFromFileName } from "../../services";
import styles from "./PartCreationDialog.module.scss";
import PartInfoForm from "./PartInfoForm";
import PartRevisionInfoForm from "./PartRevisionInfoForm";
import useCreatePart from "./useCreatePart";

export type NewPartInfo = {
  name: string;
  description: string;
  file?: File;
  revision: RevisionSpec;
  approveRevision: boolean;
  format: PartFormat;
};

const emptyPartInfo: NewPartInfo = {
  name: "",
  description: "",
  format: "solidworks",
  revision: {
    name: "",
    description: "",
  },
  approveRevision: false,
};

export type CreationStage = "filling-form" | "submitting";

export type Values = {
  spec: PartSpec;
  file: File;
  approveRevision?: boolean;
};

export default function PartCreationDialog(props: {
  projects?: number[];
  type: PartType;
  isStandard?: boolean;
  onClose: () => void;
  onSuccess?: (part: Part) => void;
}) {
  const { onClose, onSuccess, isStandard, type } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const [partInfo, setPartInfo] = useState<NewPartInfo>(emptyPartInfo);
  const [stage, setStage] = useState<CreationStage>("filling-form");

  const { mutate: submitNewPart, status, error } = useCreatePart();

  const handleFileInput = (ev: React.FormEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files;
    const newName =
      partInfo.name === ""
        ? removeFileExtension(files?.[0]?.name || "")
        : partInfo.name;
    const newFormat = files?.[0]
      ? partFormatFromFileName(files[0].name)
      : partInfo.format;

    setPartInfo({
      ...partInfo,
      name: newName,
      format: newFormat ?? partInfo.format,
      file: files?.[0],
    });
  };

  const resetState = () => {
    setPartInfo(emptyPartInfo);
  };

  const handleSubmit = async () => {
    if (stage === "filling-form") {
      setStage("submitting");
      submitNewPart(
        {
          spec: {
            name: partInfo.name,
            description: partInfo.description,
            type: props.type,
            format: partInfo.format,
            projects: props.projects || [],
            isStandard: isStandard ?? false,
            revisionSpec: partInfo.revision,
          },
          file: partInfo.file!,
          approveRevision: partInfo.approveRevision,
        },
        {
          onSuccess,
          onSettled: (_, error) => {
            analytics.track(ANALYTICS_PARTS_CREATED, {
              [isStandard
                ? ANALYTICS_PARTS_STANDARD_COUNT
                : ANALYTICS_PARTS_COUNT]: 1,
              [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
            });
          },
        }
      );
    } else {
      resetState();
      onClose();
    }
  };

  const handleCancel = () => {
    onClose();
    resetState();
  };

  const api = useApi();
  const { data: existingPartData } = useQuery(
    pdmQueries.byName(api, partInfo.name)
  );
  const partNameExists =
    (partInfo.name !== "" &&
      existingPartData?.list &&
      existingPartData.list[0] !== undefined) ??
    false;

  // part name cannot be empty, nor revision name if "create revision" is checked
  const canSubmitForm =
    stage === "submitting" ||
    (partInfo.name !== "" &&
      !partNameExists &&
      (!partInfo.approveRevision || partInfo.revision.name !== "") &&
      partInfo.file);

  return (
    <Dialog
      icon={
        <PartIcon isStandard={isStandard} type={props.type} iconSize={20} />
      }
      isOpen
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("part.dialog.new.title", { type })}
      onSecondaryClick={handleCancel}
      onPrimaryClick={handleSubmit}
      disablePrimary={!canSubmitForm}
      primaryText={tr.translateAsString(
        stage === "filling-form"
          ? "generic.action.submit"
          : "generic.action.close"
      )}
    >
      <Field label={tr.translate("part.dialog.new.cad.label", { type })}>
        {stage === "filling-form" ? (
          <FileInput
            fill
            files={partInfo.file ? [partInfo.file] : undefined}
            onInputChange={handleFileInput}
            inputProps={{ accept: ALLOWED_PART_FILE_TYPES[type].join() }}
          />
        ) : partInfo.file ? (
          <div className={styles.upload_status}>
            {status === "saving" && error ? error.message : partInfo.file.name}
            {status === "saving" && !error ? (
              <Spinner size={16} />
            ) : status === "saving" && !error ? (
              <Icon icon="warning-sign" intent="warning" />
            ) : (
              <Icon icon="tick" intent="success" />
            )}
          </div>
        ) : (
          tr.translateAsString("generic.label.none")
        )}
      </Field>

      {!isStandard && <Divider intent="primary" className={styles.divider} />}

      <PartInfoForm
        partInfo={partInfo}
        onEditName={(name) => setPartInfo({ ...partInfo, name })}
        onEditDescription={(description) =>
          setPartInfo({ ...partInfo, description })
        }
        disabled={stage === "submitting"}
        partNameExists={partNameExists && stage === "filling-form"}
      />

      {!isStandard && <Divider intent="primary" className={styles.divider} />}
      {!isStandard && (
        <PartRevisionInfoForm
          partInfo={partInfo}
          stage={stage}
          status={status}
          error={error}
          onEditName={(name) => {
            if (partInfo.revision)
              setPartInfo({
                ...partInfo,
                revision: {
                  ...partInfo.revision,
                  name,
                },
              });
          }}
          onEditDescription={(description) => {
            if (partInfo.revision)
              setPartInfo({
                ...partInfo,
                revision: {
                  ...partInfo.revision,
                  description,
                },
              });
          }}
          onToggleApproveRevision={() => {
            setPartInfo({
              ...partInfo,
              approveRevision: !partInfo.approveRevision,
            });
          }}
        />
      )}
    </Dialog>
  );
}
