import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { notificationKeys } from "./queries";

export default function useAcknowledgeNotifications() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(() => api.acknowledgeNotifications(), {
    onSuccess: () => queryClient.invalidateQueries(notificationKeys.count()),
  });
}
