import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { documentKeys } from "./queries";

export default function useDeleteDocument(documentId: number) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation<Response, Error, void>(
    () => api.document.deleteDocument(documentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentKeys.all);
      },
      onError: (error: Error) => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.document.delete", {
            errMsg: error.message,
          }),
        });
      },
    }
  );
}
