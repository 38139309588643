import { Process, Task } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import React from "react";
import { Card, HighlightedText } from "../../../../components";
import { sortByNumber } from "../../../../util";
import { useWorkflowColor } from "../../hooks";
import EmptyWorkflow from "../EmptyWorkflow";
import WorkflowCardHeader from "../WorkflowCardHeader";
import WorkflowTaskTable from "../WorkflowTaskTable";
import { ArchivedTaskRow } from "./ArchivedTaskRow";

export default function ArchivedWorkflowView(props: {
  isFolded: boolean;
  isSelected: boolean;
  tasks: Task[];
  workflow: Process;
  setActive: (active: {
    task?: number | undefined;
    workflow?: number | undefined;
  }) => void;
  disableEdit: boolean;
  toggleFold: (workflow: number) => void;
  search?: string;
}) {
  const {
    tasks,
    workflow,
    setActive,
    disableEdit,
    isSelected,
    isFolded,
    toggleFold,
    search,
  } = props;

  const handleOpenDetails = () => setActive({ workflow: workflow.id });
  const workflowColor = useWorkflowColor(workflow);

  const handleToggleFold = () => toggleFold(workflow.id);

  return (
    <Card
      isActive={isSelected}
      onClick={handleOpenDetails}
      isFolded={isFolded}
      onToggleFold={handleToggleFold}
      icon="flow-linear"
      title={
        <Text ellipsize>
          <HighlightedText text={workflow.title} highlight={search} />
        </Text>
      }
      headerActions={
        <WorkflowCardHeader
          isArchived
          disableEdit={disableEdit}
          workflow={workflow}
        />
      }
    >
      {tasks.length === 0 ? (
        <EmptyWorkflow />
      ) : (
        <WorkflowTaskTable color={workflow.color} noActions>
          {sortByNumber(tasks, (task) => task.id).map((task) => (
            <ArchivedTaskRow
              key={`${workflow.id} -${task.id}`}
              task={task}
              workflowTasks={tasks.filter((t) => t.id !== task.id)}
              workflowColor={workflowColor}
              onClick={() => setActive({ task: task.id })}
              isSelected={isSelected}
            />
          ))}
        </WorkflowTaskTable>
      )}
    </Card>
  );
}
