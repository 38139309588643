import { useCallback } from "react";
import { useTranslations } from "../util";
import { useDownloadQueue } from "./service";
import { useToaster } from "./toaster";

export default function useFilePreviewOverlay<A>(
  onGetPreviewToken: (value: A) => Promise<string>
) {
  const tr = useTranslations();
  const { handleDownload } = useDownloadQueue();
  const toaster = useToaster();

  return useCallback(
    async function (value: A) {
      //check if file type can be previewed
      try {
        // Try get pdf preview / open video file
        await handleDownload({
          fetchToken: () => onGetPreviewToken(value),
        });
      } catch (_) {
        toaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: tr.translateAsString("toaster.error.preview"),
        });
      }
    },
    [handleDownload, onGetPreviewToken, toaster, tr]
  );
}
