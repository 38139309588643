import React from "react";
import UserInitial from "../../name/UserInitial";
import NavigationButton from "../NavigationButton";
import styles from "./UserButton.module.scss";

export default function UserButton(props: {
  user: string;
  currentTenant: string;
}) {
  const { user, currentTenant } = props;

  return (
    <NavigationButton
      icon={<UserInitial userName={user} />}
      className={styles.button}
    >
      <div className={styles.name}>{user}</div>
      <div className={styles.tenant}>{currentTenant}</div>
    </NavigationButton>
  );
}
