import React from "react";
import { Field, Input } from "../../../../components";
import { useHasPermission, useUsers } from "../../../../hooks";
import { useTranslations } from "../../../../util";

export default function EmailInput(props: {
  label?: string;
  helperText?: string;
  placeholder: string;
  value: string;
  isMandatory?: boolean;
  className?: string;
  containerClassName?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  setValue: (value: string, isEmailValid: boolean) => void;
}) {
  const {
    placeholder,
    helperText,
    value,
    isMandatory = false,
    className = "",
    label,
    containerClassName,
    defaultValue = "",
    autoFocus,
    setValue,
  } = props;
  const tr = useTranslations();

  const withEmail = true;
  const { data: users } = useUsers(withEmail);

  const canReadEmail = useHasPermission("read:user-emails");

  if (!canReadEmail) {
    return <></>;
  }

  const regexEmail = new RegExp(/\S+@\S+\.\S+/g);
  const emailAlreadyInUse =
    defaultValue !== value && !!users?.find((user) => user?.email === value);

  const isEmailInvalid = value && !value.match(regexEmail);
  const handleValue = (newValue: string) => {
    const emailAlreadyInUse_ = !!users?.find(
      (user) => user?.email === newValue
    );

    setValue(
      newValue,
      !!newValue.match(regexEmail) &&
        (defaultValue === newValue || !emailAlreadyInUse_)
    );
  };

  return (
    <Field
      label={label}
      className={containerClassName}
      isMandatory={isMandatory}
    >
      <Input
        value={value}
        placeholder={placeholder}
        onChange={handleValue}
        className={className}
        hasError={isEmailInvalid || emailAlreadyInUse}
        errorHelperText={tr.translateAsString(
          isEmailInvalid
            ? "admin.users.email.invalid"
            : "admin.users.email.already-use"
        )}
        helperText={helperText}
        autoFocus={autoFocus}
        fill
      />
    </Field>
  );
}
