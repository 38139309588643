import { Part, PartType } from "@aletiq/types";
import cadex, {
  ModelData_Assembly,
  ModelData_Part,
  ModelPrs_View3dObject,
} from "@cadexchanger/web-toolkit";

export type SceneObjectInfo = ModelPrs_View3dObject & {
  aa?: { object?: { uuid: string } };
};

export type ViewSettings = {
  projection: cadex.ModelPrs_CameraProjectionType;
  displayMode: cadex.ModelPrs_DisplayMode;
  explodeFactor: number;
};

export type SceneComponent = {
  id: number;
  name: string;
  instanceName: string;
  type?: PartType;
  depth: number;
  isVisible: boolean;
  isSelected: boolean;
  isExpanded: boolean;
  sceneObject?: ModelPrs_View3dObject;
  sceneObjectId?: string;
};

export default class SceneGraphVisitor extends cadex.ModelData_SceneGraphElementVisitor {
  private depth: number;
  private components: SceneComponent[];
  private sceneObjects: ModelPrs_View3dObject[];

  constructor(rootElement: Part, sceneObjects: ModelPrs_View3dObject[]) {
    super();
    this.depth = 0;
    this.sceneObjects = sceneObjects;
    this.components = [
      {
        id: -1,
        name: rootElement.name,
        instanceName: rootElement.name,
        depth: 0,
        isVisible: true,
        isSelected: false,
        isExpanded: true,
        type: rootElement.type,
      },
    ];
  }

  visitInstanceEnter(instance: cadex.ModelData_Instance) {
    if (!this.components.some((c) => c.instanceName === instance.name)) {
      const sceneObject = this.sceneObjects[this.components.length - 1];

      // use JSON.parse because
      // casting to SceneObjectInfo always returns an undefined uuid
      const sceneObjectId: string | undefined =
        sceneObject &&
        JSON.parse(JSON.stringify(sceneObject))[0]?.aa?.object?.uuid;

      this.components.push({
        id: this.components.length - 1,
        instanceName: instance.name,
        name: instance.reference?.name || instance.name,
        depth: this.depth,
        isVisible: true,
        isSelected: false,
        isExpanded: true,
        sceneObject,
        sceneObjectId,
      });
    }
    return true;
  }

  visitPart(part: ModelData_Part) {
    const partIndex = this.components.findIndex(
      (c) => c.name === part.name && c.depth === this.depth && !c.type
    );
    if (partIndex) {
      this.components[partIndex] = {
        ...this.components[partIndex],
        type: "part",
      };
    }
  }

  visitAssemblyEnter(assembly: ModelData_Assembly) {
    const partIndex = this.components.findIndex(
      (c) => c.name === assembly.name && c.depth === this.depth && !c.type
    );
    if (partIndex > 0) {
      this.components[partIndex] = {
        ...this.components[partIndex],
        type: "assembly",
      };
    }
    this.depth += 1;
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visitAssemblyLeave(_: ModelData_Assembly) {
    this.depth -= 1;
  }

  getComponents() {
    return this.components.filter((c) => c.sceneObject || c.depth === 0);
  }
}
