import { Tag } from "@aletiq/design-system";
import { ApiActivity, EntityId } from "@aletiq/types";
import React from "react";
import { GenericStateTag } from "..";
import { useProperty } from "../../features/administration/Attributes/hooks";
import ArticleBillIndexLink from "../../features/project/Version/ArticleBillIndexLink";
import { useTranslations } from "../../util";
import { DateWithSpaces } from "../date";
import { UserInitialsList } from "../user-list";
import styles from "./Activities.module.scss";
import ActivityValue from "./ActivityValue";
import { findEntity, findProduct } from "./findEntity";
import ActivityEntityLink from "./links/ActivityEntityLink";
import { ActivityProductLink } from "./links/ActivityProductLink";

export default function ProductActivity(props: { activity: ApiActivity }) {
  const { activity } = props;
  const tr = useTranslations();

  const productLink = (id: number) => {
    const product = findProduct(activity.entities, id);
    return product ? <ActivityProductLink product={product} /> : null;
  };

  const entityLink = (id: EntityId) => {
    const entity = findEntity(activity.entities, id);
    return entity ? <ActivityEntityLink entity={entity} /> : null;
  };

  switch (activity.type) {
    case "product_created":
      return tr.translate("activity.product_created", {
        product: productLink(activity.metadata.product),
      });
    case "product_deleted":
      return tr.translate("activity.product_deleted", {
        product: productLink(activity.metadata.product),
      });
    case "product_type_updated":
      return tr.translate("activity.product_type_updated", {
        product: productLink(activity.metadata.product),
        type: activity.metadata.type,
      });
    case "product_component_added":
      return tr.translate("activity.product_component_added", {
        product: productLink(activity.metadata.product),
        component: productLink(activity.metadata.component),
      });
    case "product_component_removed":
      return tr.translate("activity.product_component_removed", {
        product: productLink(activity.metadata.product),
        component: productLink(activity.metadata.component),
      });
    case "product_relation_added":
      return tr.translate("activity.product_relation_added", {
        product: productLink(activity.metadata.product),
        entity: entityLink(activity.metadata.entity),
      });
    case "product_relation_removed":
      return tr.translate("activity.product_relation_removed", {
        product: productLink(activity.metadata.product),
        entity: entityLink(activity.metadata.entity),
      });
    case "product_tool_added":
      return tr.translate("activity.product_tool_added", {
        product: productLink(activity.metadata.product),
        tool: productLink(activity.metadata.tool),
      });
    case "product_tool_removed":
      return tr.translate("activity.product_tool_removed", {
        product: productLink(activity.metadata.product),
        tool: productLink(activity.metadata.tool),
      });
    case "product_property_updated":
      return <ProductPropertyUpatedActivity activity={activity} />;
    case "product_description_updated":
      if (activity.metadata.value === "") {
        return tr.translate("activity.product_description_cleared", {
          product: productLink(activity.metadata.product),
        });
      }
      return tr.translate("activity.product_description_updated", {
        product: productLink(activity.metadata.product),
        description: <ActivityValue>{activity.metadata.value}</ActivityValue>,
      });
    case "product_definition_validated":
      return tr.translate("activity.product_definition_validated", {
        product: productLink(activity.metadata.product),
        definition: (
          <ActivityValue>{activity.metadata.definition}</ActivityValue>
        ),
      });
    case "product_definition_drafted":
      return tr.translate("activity.product_definition_reverted", {
        product: productLink(activity.metadata.product),
        definition: (
          <ActivityValue>{activity.metadata.definition}</ActivityValue>
        ),
        tag: <GenericStateTag state={"draft"} />,
      });
    case "product_operation_validated":
      return tr.translate("activity.product_operation_validated", {
        product: productLink(activity.metadata.product),
        bill: (
          <ArticleBillIndexLink
            product={activity.metadata.product}
            billId={activity.metadata.bill}
          />
        ),
      });

    case "product_operation_drafted":
      return tr.translate("activity.product_operation_reverted", {
        product: productLink(activity.metadata.product),
        bill: (
          <ArticleBillIndexLink
            product={activity.metadata.product}
            billId={activity.metadata.bill}
          />
        ),
        tag: <GenericStateTag state={"draft"} />,
      });

    default:
      return null;
  }
}

function ProductPropertyUpatedActivity(props: {
  activity: ApiActivity & {
    type: "product_property_updated";
  };
}) {
  const { activity } = props;
  const {
    product: productId,
    value: propertyValue,
    property: propertyId,
  } = activity.metadata;
  const tr = useTranslations();

  const product = findProduct(activity.entities, productId);

  const { data: property } = useProperty(propertyId, "product");
  const options = property?.options ?? [];

  if (!activity.metadata.value) {
    return tr.translate("activity.entity_property_cleared", {
      entitty: product ? <ActivityProductLink product={product} /> : null,
      property: property?.label,
    });
  }

  const getValueLabel = (value: string) =>
    options.find((option) => option.name === value)?.label ?? "";

  return (
    <>
      {tr.translate("activity.entity_property_updated", {
        entity: product ? <ActivityProductLink product={product} /> : null,
        property: property?.label,
        value:
          propertyValue?.type === "multi-enum" ? (
            propertyValue?.value?.map((val) => (
              <Tag isDense unclickable className={styles.tag}>
                {getValueLabel(val)}
              </Tag>
            ))
          ) : propertyValue?.type === "enum" ? (
            <ActivityValue>
              {getValueLabel(propertyValue?.value ?? 0)}
            </ActivityValue>
          ) : propertyValue?.type === "user" ? (
            <ActivityValue>
              <UserInitialsList
                users={propertyValue?.value ?? []}
                type="small"
              />
            </ActivityValue>
          ) : propertyValue?.type === "date" ? (
            <ActivityValue>
              <DateWithSpaces date={propertyValue.value} />
            </ActivityValue>
          ) : (
            <ActivityValue>{propertyValue?.value}</ActivityValue>
          ),
      })}
    </>
  );
}
