import React, { useState } from "react";
import { formatDate } from "../../util";
import { DateInput, DateWithSpaces } from "../date";
import { EditablePropertyButtonGroup } from "./buttons";
import EditablePropertyTemplate from "./EditablePropertyTemplate";

export default function EditablePropertyDate(props: {
  label: string;
  isEditable: boolean;
  intent?: "primary" | "default";
  value: Date | null;
  emptyState: string;
  inline?: boolean;
  minWidth?: number;
  onSubmit: (value: Date | null) => void;
  tooltipText?: string;
  search?: string;
}) {
  const {
    label,
    isEditable,
    intent = "default",
    value: initialValue,
    emptyState,
    inline,
    minWidth,
    onSubmit,
    tooltipText,
    search,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState(initialValue);

  const handleToggleEditMode = () => {
    setEditedValue(initialValue);
    setEditMode(!editMode);
  };

  const handleSubmit = () => {
    onSubmit(editedValue);
    setEditMode(false);
  };

  return (
    <EditablePropertyTemplate
      intent={intent}
      isEditable={isEditable}
      label={label}
      triggerEditMode={handleToggleEditMode}
      isInEditMode={editMode}
      inline={inline}
      minWidth={minWidth}
      tooltipText={tooltipText}
      search={search}
    >
      {!editMode && initialValue && (
        <DateWithSpaces date={formatDate(initialValue)} />
      )}
      {!editMode && !initialValue && emptyState}
      {editMode && (
        <>
          <DateInput
            isDense
            addSpaces
            onChange={setEditedValue}
            value={editedValue}
          />
          <EditablePropertyButtonGroup
            onSubmit={handleSubmit}
            onReset={handleToggleEditMode}
          />
        </>
      )}
    </EditablePropertyTemplate>
  );
}
