import { regexSanitize } from "../../../util";
import { ParserOption } from "./types";
/*eslint no-useless-escape: 0*/

const replace = (regex?: any): any => {
  let regexSource = regex.source;

  return (name?: string, val?: RegExp | string) => {
    if (!name) {
      return new RegExp(regexSource);
    }

    let valSource = "";
    if (typeof val === "string") {
      valSource = val;
    }

    if (val instanceof RegExp) {
      valSource = val?.source;
    }

    valSource = (valSource || "").replace(/(^|[^\[])\^/g, "$1");
    regexSource = regexSource.replace(
      new RegExp(regexSanitize(name), "g"),
      valSource
    );
    return replace(new RegExp(regexSource));
  };
};

const noop = () => {};
noop.exec = noop;

const defaultOptions: ParserOption = {
  silent: false,
};

const assign =
  Object.assign ||
  ((obj: { [key: string]: any }, ...args: { [key: string]: any }[]) => {
    for (let i = 1; i < args.length; i++) {
      const target = args[i];
      for (const key in target) {
        if (Object.prototype.hasOwnProperty.call(target, key)) {
          obj[key] = target[key];
        }
      }
    }

    return obj;
  });

export { replace, assign, noop, defaultOptions };
