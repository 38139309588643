import { OperationBill, OperationSpec } from "@aletiq/types";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { projectKeys } from "../../hooks/queries";

export default function useAddOperations(
  projectId: number,
  bill: OperationBill
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const tr = useTranslations();
  const toaster = useToaster();

  return useMutation(
    async (operations: OperationSpec[]) => {
      const existingOps = bill.operations.map((op) => op.number);
      const ignoredOps: number[] = [];
      const addedOps: number[] = [];

      for (const operation of operations) {
        const operationsExists =
          existingOps.includes(operation.number) ||
          addedOps.includes(operation.number);

        if (!operationsExists) {
          await api.operations.addProjectOperation(
            projectId,
            bill.id,
            operation
          );
          addedOps.push(operation.number);
        }

        if (operationsExists && !ignoredOps.includes(operation.number)) {
          ignoredOps.push(operation.number);
        }
      }

      return { addedOps, ignoredOps };
    },
    {
      onSettled: (result) => {
        queryClient.invalidateQueries(projectKeys.allBills);
        if (result && result.ignoredOps.length > 0) {
          toaster.show({
            intent: "danger",
            icon: "warning-sign",
            message: (
              <>
                {tr.translate("toaster.error.operation-bill.add.duplicate")}
                <div>{result.ignoredOps.join(", ")}</div>
              </>
            ),
          });
        }
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.generic"),
        });
      },
    }
  );
}
