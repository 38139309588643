import { OperationBill } from "@aletiq/types";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router";
import {
  ANALYTICS_VISITED_PRODUCT_OPERATION_DETAILS,
  useTrackEvent,
} from "../../../analytics";
import { Button, PageTitle } from "../../../components";
import { useHasPermission, useNavigate } from "../../../hooks";
import { useTranslations } from "../../../util";
import { ProjectPage } from "../common";
import { diffDocuments, diffOperations, diffTools } from "./compareBill";
import useProjectBills from "./hooks/useProjectBills";
import OperationDetails from "./OperationDetails";
import OperationDetailsEditor from "./OperationDetailsEditor";
import styles from "./OperationDetailsPage.module.scss";

export default function OperationDetailsPage(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_OPERATION_DETAILS);

  const routeParams = useRouteMatch<{ bill: string; operation: string }>();
  const billId = Number.parseInt(routeParams.params.bill);
  const operationNumber = Number.parseInt(routeParams.params.operation);

  const hasUpdatePermission = useHasPermission("update:article-bills");

  const { data: bills = [] } = useProjectBills(projectId);

  const activeBillIndex = bills.findIndex((b) => b.id === billId);
  const activeBill: OperationBill | undefined = bills[activeBillIndex];
  const previousBill: OperationBill | null =
    bills.find(
      (b, billIndex) =>
        b.branch === activeBill?.branch && billIndex > activeBillIndex
    ) || null;

  const activeOperation = activeBill?.operations?.find(
    (o) => o.number === operationNumber
  );

  const previousOperation = previousBill?.operations?.find(
    (o) => o.number === operationNumber
  );

  const diff =
    previousBill && activeBill
      ? diffOperations(
          activeBill?.operations || [],
          previousBill?.operations || []
        )
      : {};

  const operationDiff = diff[operationNumber] ?? {};

  const documentsDiff = diffDocuments(
    activeOperation?.documents ?? [],
    previousOperation?.documents ?? []
  );

  const toolsDiff = diffTools(
    activeOperation?.tools ?? [],
    previousOperation?.tools ?? []
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (activeBill && !activeOperation) {
      navigate({
        path: `/project/${projectId}/operation-bills/${activeBill.id}`,
      });
    }
  }, [navigate, projectId, activeBill, activeOperation]);

  const handleShowOperationList = () =>
    navigate({ path: `/project/${projectId}/operation-bills/${billId}` });

  return (
    <ProjectPage
      projectId={projectId}
      domTitle={tr.translateAsString("page.title.operation-bill")}
    >
      <PageTitle icon="numbered-list">
        {tr.translateAsString("project.operation-bill.title")}
      </PageTitle>
      <Button
        intent="tertiary"
        icon="arrow-left"
        className={styles.back_button}
        onClick={handleShowOperationList}
      >
        {tr.translate("project.operation-bill.operation-list")}
      </Button>
      {activeBill && activeOperation ? (
        activeBill.state === "draft" && hasUpdatePermission ? (
          <OperationDetailsEditor
            projectId={projectId}
            bill={activeBill}
            bills={bills}
            diff={operationDiff}
            documentsDiff={documentsDiff}
            toolsDiff={toolsDiff}
            operation={activeOperation}
          />
        ) : (
          <OperationDetails
            projectId={projectId}
            bill={activeBill}
            diff={operationDiff}
            documentsDiff={documentsDiff}
            toolsDiff={toolsDiff}
            bills={bills}
            operation={activeOperation}
          />
        )
      ) : null}
    </ProjectPage>
  );
}
