import { DocumentQueryFilterParams } from "@aletiq/types";
import React, { useState } from "react";
import { useHasPermission } from "../../../hooks";
import useDragNDropFiles from "../../../hooks/useDragNDropFiles";
import DocumentsUploadWizard from "../dialogs/DocumentsUploadWizard";
import { useGroupDocumentFiles } from "../hooks";
import { DocumentColumn } from "../hooks/useDocumentTableColumns";
import styles from "./Document.module.scss";
import DocumentListPaginated from "./DocumentListPaginated";

export default function DocumentViews(props: {
  projectId?: number;
  onShowFilePreview: (documentId: number) => void;
  filter: DocumentQueryFilterParams;
  columnConfig: Record<DocumentColumn, boolean> | undefined;
}) {
  const { onShowFilePreview, filter, projectId, columnConfig } = props;
  const canCreateDocuments = useHasPermission("create:documents");
  const [files, setFiles] = useState<File[]>([]);
  const { data, isLoading } = useGroupDocumentFiles(files);
  const { files: groupedFiles } = data ?? {};

  const { onDrop, onDragEnter, onDragOver } = useDragNDropFiles(
    (f) => setFiles(f),
    canCreateDocuments
  );

  const handleClose = () => {
    setFiles([]);
  };

  return (
    <>
      <div
        className={styles.container}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
      >
        <DocumentListPaginated
          onShowFilePreview={onShowFilePreview}
          filter={filter}
          projectId={projectId}
          columnConfig={columnConfig}
        />
      </div>
      {!isLoading && groupedFiles && groupedFiles?.length > 0 && (
        <DocumentsUploadWizard
          groupedFiles={groupedFiles}
          projectId={projectId}
          onClose={handleClose}
        />
      )}
    </>
  );
}
