import { promisify } from "../format";

export default async function readFSEntries(item: any): Promise<File[]> {
  if (!item) return [];
  else if (item.isDirectory) {
    // Handle folder content
    const directoryReader = item.createReader();
    let files: File[] = [];
    for (
      let entries: any[] = await promisify(
        directoryReader.readEntries.bind(directoryReader)
      );
      entries.length > 0;
      entries = await promisify(
        directoryReader.readEntries.bind(directoryReader)
      )
    ) {
      for (const entry of entries) {
        files = [...files, ...(await readFSEntries(entry))];
      }
    }
    return files;
  } else {
    const file: File = await promisify(item.file.bind(item));
    return [file];
  }
}
