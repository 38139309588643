import { Operation, OperationDocument, OperationTool } from "@aletiq/types";
import { Diff, diffBy, diffThese, someChanges, These } from "../../../util";

function diffDocument(documents: These<OperationDocument>) {
  return diffThese(documents, (current, previous) => {
    if (
      current.revision.type === "specific" &&
      previous.revision.type === "specific" &&
      current.revision.revision?.id === previous.revision.revision?.id
    ) {
      return false;
    }

    if (current.revision.type === "last" && previous.revision.type === "last") {
      return false;
    }

    return true;
  });
}

function diffTool(tools: These<OperationTool>) {
  return diffThese(tools, (current, previous) => {
    if (!current && !previous) {
      return false;
    }
    if (
      current?.version?.type === "specific" &&
      previous?.version?.type === "specific" &&
      current.version === previous.version
    ) {
      return false;
    }
    if (
      current?.version?.type === "last" &&
      previous?.version?.type === "last"
    ) {
      return false;
    }

    return true;
  });
}

function diffOperation(operations: These<Operation>) {
  return diffThese(operations, (current, previous) => {
    if (
      current.name !== previous.name ||
      current.description !== previous.description
    ) {
      return true;
    }

    if (someChanges(diffDocuments(current.documents, previous.documents))) {
      return true;
    }

    if (someChanges(diffTools(current.tools, previous.tools))) {
      return true;
    }

    return false;
  });
}

export function diffDocuments(
  first: OperationDocument[],
  second: OperationDocument[]
): Record<number, Diff<OperationDocument>> {
  return diffBy(first, second, "document", diffDocument);
}

export function diffTools(
  first: OperationTool[],
  second: OperationTool[]
): Record<number, Diff<OperationTool>> {
  return diffBy(first, second, "tool", diffTool);
}

export function diffOperations(
  first: Operation[],
  second: Operation[]
): Record<number, Diff<Operation>> {
  return diffBy(first, second, "number", diffOperation);
}
