import { UserRole } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { userKeys } from "../../hooks/queries";

export default function useUpdateUserRole(userId: number) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (role: UserRole) => api.user.updateUserRole(userId, role),
    { onSuccess: () => queryClient.invalidateQueries(userKeys.all) }
  );
}
