import { API } from "@aletiq/api";
import {
  DocumentDefinitionQueryParams,
  DocumentOperationQueryParams,
  DocumentQueryParams,
} from "@aletiq/types";
export const documentKeys = {
  all: ["documents"] as const,
  list: (params?: DocumentQueryParams) =>
    [...documentKeys.all, params ?? {}] as const,
  byId: (id: number) => [...documentKeys.all, id] as const,
  byFilenames: (filenames: string[]) =>
    [...documentKeys.all, { filenames }] as const,
  access: (id: number) => [...documentKeys.all, "access", id] as const,

  thumbnail: (id: number) => [...documentKeys.byId(id), "thumbnail"] as const,

  allRevisions: (id: number) =>
    [...documentKeys.byId(id), "revisions"] as const,
  revision: (id: number, revision?: number) =>
    [...documentKeys.allRevisions(id), revision ?? "last"] as const,
  revisionSignatures: (id: number, rev: number) =>
    [...documentKeys.revision(id, rev), "signatures"] as const,

  allProjects: (id: number) => [...documentKeys.byId(id), "projects"] as const,

  allDefinitionProjects: (
    id: number,
    rev: number,
    params?: DocumentDefinitionQueryParams
  ) =>
    [...documentKeys.revision(id, rev), "definitions", params ?? {}] as const,

  allOperationBills: (
    id: number,
    rev: number,
    params?: DocumentOperationQueryParams
  ) => [...documentKeys.revision(id, rev), "operations", params ?? {}] as const,

  citations: (id: number) => [...documentKeys.byId(id), "citations"] as const,
  citedBy: (id: number) => [...documentKeys.byId(id), "cited-by"] as const,
};

export const documentQueries = {
  byId: (api: API, id: number) => ({
    queryKey: documentKeys.byId(id),
    queryFn: () => api.document.getDocument(id),
  }),
  access: (api: API, id: number) => ({
    queryKey: documentKeys.access(id),
    queryFn: () => api.document.getDocumentAccess(id),
  }),

  allProjects: (api: API, id: number) => ({
    queryKey: documentKeys.allProjects(id),
    queryFn: () => api.document.listDocumentProjects(id),
  }),

  citations: (api: API, id: number) => ({
    queryKey: documentKeys.citations(id),
    queryFn: () => api.document.listDocumentCitations(id),
  }),
  citedBy: (api: API, id: number) => ({
    queryKey: documentKeys.citedBy(id),
    queryFn: () => api.document.listDocumentCitedBy(id),
  }),
};
