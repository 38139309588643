import { Part } from "@aletiq/types";
import React from "react";
import { HighlightedText, MenuItem, PartIcon } from "../../../../../components";

export default function PartSelectItem(props: {
  part: Part;
  onSelect: (part: Part) => void;
  search: string;
}) {
  const { part, onSelect, search } = props;
  return (
    <MenuItem
      onClick={() => onSelect(part)}
      text={<HighlightedText text={part.name} highlight={search} />}
      icon={<PartIcon type={part.type} isStandard={part.isStandard} />}
      isDense
    />
  );
}
