import { DocumentQueryFilterParams } from "@aletiq/types";
import React from "react";
import {
  ActionBar,
  AlignRight,
  AnchorButton,
  Button,
  DefaultFilters,
  EditColumnButton,
  Tooltip,
} from "../../../../components";
import {
  ColumnOptionConfig,
  HandleClear,
  HandleFilter,
  useHasPermission,
} from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { MoreDocumentFilters } from "../../common";
import { useExportDocuments } from "../../hooks";
import { DocumentColumn } from "../../hooks/useDocumentTableColumns";
import SelectDocumentColumn from "../SelectDocumentColumn";
import styles from "./DocumentsActionBar.module.scss";

export default function DocumentsActionBar(props: {
  filter: DocumentQueryFilterParams;
  handleFilter: HandleFilter<DocumentQueryFilterParams>;
  handleClear: HandleClear<DocumentQueryFilterParams>;
  onNewDocument: () => void;
  onLinkDocuments?: () => void;
  columnConfig: ColumnOptionConfig<DocumentColumn> | undefined;
  onToggleColumn: (column: DocumentColumn) => void;
}) {
  const {
    filter: documentFilters,
    handleFilter,
    handleClear,
    onNewDocument,
    onLinkDocuments,
    columnConfig,
    onToggleColumn,
  } = props;

  const tr = useTranslations();

  const canUpdateDocuments = useHasPermission("update:documents");
  const canCreateDocuments = useHasPermission("create:documents");
  const canExportDocuments = useHasPermission("export:documents");

  const { mutate: exportAsCsv } = useExportDocuments();
  const handleExportDocuments = () => exportAsCsv();

  return (
    <ActionBar>
      <DefaultFilters
        objectType="document"
        filters={documentFilters}
        handleFilter={handleFilter}
      />
      <MoreDocumentFilters
        filters={documentFilters}
        handleFilter={handleFilter}
        handleClear={handleClear}
      />
      <Tooltip content={tr.translate("documents.export.button")} position="top">
        <AnchorButton
          isDense
          icon="th-derived"
          intent="secondary"
          onClick={handleExportDocuments}
          isDisabled={!canExportDocuments}
        />
      </Tooltip>
      <AlignRight />

      <SelectDocumentColumn
        columnConfig={columnConfig}
        onToggleColumn={onToggleColumn}
      >
        <EditColumnButton />
      </SelectDocumentColumn>

      {onLinkDocuments && (
        <Button
          icon="new-link"
          className={styles.flex_none}
          intent="primary"
          onClick={onLinkDocuments}
          isDisabled={!canUpdateDocuments}
          isDense
        >
          {tr.translate("documents.link.button")}
        </Button>
      )}
      <Button
        className={styles.flex_none}
        icon="plus"
        intent="secondary"
        onClick={onNewDocument}
        isDisabled={!canCreateDocuments}
        isDense
      >
        {tr.translate("documents.new.button")}
      </Button>
    </ActionBar>
  );
}
