import {
  Dialog,
  Field,
  FileInput,
  Input,
  Radio,
  TextArea,
} from "@aletiq/design-system";
import {
  DocumentRevisionSpec,
  PropertyValue,
  PropertyValueUpdate,
} from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_DOCUMENTS_COUNT,
  ANALYTICS_DOCUMENTS_CREATED,
  ANALYTICS_MUTATION_STATUS,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../../analytics";
import {
  EditCustomPropertyField,
  GenericStateTag,
} from "../../../../components";
import { removeFileExtension, useTranslations } from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import { useCreateDocument } from "../../hooks";
import styles from "./NewDocumentDialog.module.scss";

export default function NewDocumentDialog(props: {
  projectId?: number;
  onClose: () => void;
}) {
  const { projectId, onClose } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const [file, setFile] = useState<File | null>(null);
  const [properties, setProperties] = useState<PropertyValueUpdate[]>([]);
  const [documentName, setDocumentName] = useState("");

  const [revisionName, setRevisionName] = useState("");
  const [revisionDescription, setRevisionDescription] = useState("");
  const [approveRevision, setApproveRevision] = useState(false);

  const { data: docProperties = [] } = useProperties("document");
  const visibleProperties = docProperties.filter((p) => p.displayByDefault);

  const { mutate: createDocument } = useCreateDocument();

  const handleSubmit = () => {
    // TODO validate name

    if (!file) {
      return;
    }

    const revision: DocumentRevisionSpec = {
      name: revisionName,
      description: revisionDescription,
      state: approveRevision ? "approved" : "draft",
    };
    const spec = {
      name: documentName,
      category: null,
      type: null,
      revision,
      owner: null,
      projects: projectId ? [projectId] : [],
      properties,
    };
    createDocument(
      { file, spec },
      {
        onSettled: (_, error) => {
          analytics.track(ANALYTICS_DOCUMENTS_CREATED, {
            [ANALYTICS_DOCUMENTS_COUNT]: 1,
            [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
          });
        },
      }
    );

    onClose();
  };

  const handleFileInput = (ev: React.FormEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const files = ev.currentTarget.files;
    setFile((files && files[0]) || null);
    if (files && files[0]) {
      setDocumentName(removeFileExtension(files[0].name));
    }
  };

  const handleUpdateProperty = (propertyName: string, value: PropertyValue) => {
    const propertyIsSet = properties.some((p) => p.property === propertyName);
    if (propertyIsSet) {
      setProperties(
        properties.map((p) =>
          p.property === propertyName ? { ...p, value } : p
        )
      );
      return;
    }
    setProperties([...properties, { property: propertyName, value }]);
  };

  const isComplete = file !== null && revisionName !== "";

  return (
    <Dialog
      icon="plus"
      isOpen
      onClose={onClose}
      title={tr.translateAsString("storage.dialog.upload-file.title")}
      onSecondaryClick={onClose}
      onPrimaryClick={handleSubmit}
      disablePrimary={!isComplete}
    >
      <div className={styles.dialog_contents}>
        <Field label={tr.translate("storage.dialog.upload-file.file.label")}>
          <FileInput
            fill
            onInputChange={handleFileInput}
            files={file ? [file] : []}
          />
        </Field>
        <Field label={tr.translateAsString("document.dialog.upload-file.name")}>
          <Input
            autoFocus
            value={documentName}
            onChange={setDocumentName}
            placeholder={tr.translateAsString(
              "document.dialog.upload-file.name.placeholder"
            )}
            fill
          />
        </Field>
        <Field
          label={tr.translateAsString(
            "document.dialog.upload-file.revision.label"
          )}
        >
          <Input
            autoFocus={false}
            value={revisionName}
            onChange={setRevisionName}
            placeholder={tr.translateAsString(
              "document.dialog.upload-file.revision.placeholder"
            )}
            fill
          />
        </Field>
        <Field
          label={tr.translate("document.dialog.upload-file.revision.desc")}
        >
          <TextArea
            value={revisionDescription}
            onChange={setRevisionDescription}
            placeholder={tr.translateAsString(
              "document.dialog.upload-file.revision.desc.placeholder"
            )}
            fill
          />
        </Field>

        {visibleProperties.map((property) => (
          <Field label={property.label} key={property.id}>
            <EditCustomPropertyField
              property={property}
              propertyValue={
                properties.find((p) => p.property === property.name)?.value ??
                null
              }
              onEditValue={(value) =>
                handleUpdateProperty(property.name, value)
              }
            />
          </Field>
        ))}

        <Field
          label={tr.translate("storage.dialog.upload-file.revision.state")}
        >
          <div className={styles.validation}>
            <div className={styles.radio}>
              <Radio
                checked={!approveRevision}
                onClick={() => setApproveRevision(false)}
                isDense
              />
              <GenericStateTag state="draft" />
            </div>
            <div className={styles.radio}>
              <Radio
                checked={approveRevision}
                onClick={() => setApproveRevision(true)}
                isDense
              />
              <GenericStateTag state="approved" />
            </div>
          </div>
        </Field>
      </div>
    </Dialog>
  );
}
