import { DropdownButton, ItemSelect, Select } from "@aletiq/design-system";
import React from "react";
import {
  blue,
  brown,
  darkGrey,
  green,
  orange,
  pink,
  purple,
  red,
  teal,
  useTranslations,
  yellow,
} from "../../../../util";
import styles from "./Editor.module.scss";

export const WORKFLOW_COLORS: {
  hex: string;
  translation: string;
}[] = [
  { hex: darkGrey, translation: "model.color.grey" },
  { hex: brown, translation: "model.color.brown" },
  { hex: orange, translation: "model.color.orange" },
  { hex: yellow, translation: "model.color.yellow" },
  { hex: green, translation: "model.color.green" },
  { hex: teal, translation: "model.color.teal" },
  { hex: blue, translation: "model.color.blue" },
  { hex: purple, translation: "model.color.purple" },
  { hex: pink, translation: "model.color.pink" },
  { hex: red, translation: "model.color.red" },
];

export default function ModelColorSelect(props: {
  colorHex: string;
  onSetColor: (color: string) => void;
  className?: string;
}) {
  const { colorHex, className, onSetColor } = props;
  const tr = useTranslations();
  const color = useModelColor(colorHex);

  const colorOptions: ItemSelect<string>[] = WORKFLOW_COLORS.map(
    (colorOption) => ({
      key: colorOption.hex,
      text: tr.translateAsString(colorOption.translation),
      icon: (
        <div
          className={styles.color_icon}
          style={{ backgroundColor: colorOption.hex }}
        />
      ),
    })
  );

  return (
    <Select
      isDense
      items={colorOptions}
      activeItem={colorHex}
      onItemSelect={onSetColor}
      popoverProps={{ position: "bottom-right" }}
    >
      <DropdownButton
        dense
        className={className}
        text={
          <>
            <div
              className={styles.color_icon}
              style={{ backgroundColor: colorHex }}
            />
            {color && tr.translate(color?.translation)}
          </>
        }
      />
    </Select>
  );
}

export function useModelColor(hexCode: string) {
  return WORKFLOW_COLORS.find(
    (colorSpec) => colorSpec.hex === hexCode.toLowerCase()
  );
}
