import { Divider } from "@aletiq/design-system";
import React from "react";
import styles from "./NavBar.module.scss";

export default function MenuDivider(props: { title?: string }) {
  const { title } = props;
  return (
    <>
      <Divider className={styles.divider} />
      {title && <div className={styles.dividerTitle}>{title}</div>}
    </>
  );
}
