import React from "react";
import {
  PassportFileTypeFilter,
  SearchInput,
  Tooltip,
} from "../../../../components";
import { HandleClear, HandleFilter } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import styles from "./PassportFiles.module.scss";
import { Filter } from "./types";

export default function PassportFilesFilter(props: {
  filter: Filter;
  handleFilter: HandleFilter<Filter>;
  handleClear: HandleClear<Filter>;
}) {
  const { filter, handleFilter, handleClear } = props;
  const tr = useTranslations();

  return (
    <>
      <Tooltip
        position="top-left"
        content={tr.translateAsString("passport.details.file.search")}
      >
        <SearchInput
          value={filter.search ?? ""}
          onChange={handleFilter("search")}
          placeholder={tr.translateAsString("generic.action.search")}
          className={styles.searchbar}
          intent="primary"
          isDense
        />
      </Tooltip>
      <PassportFileTypeFilter
        selected={filter.types ?? []}
        noTypeSelected={filter?.emptyType ?? false}
        onSelect={handleFilter("types")}
        onSelectNoType={handleFilter("emptyType")}
        onClear={handleClear(["types", "emptyType"])}
      />
    </>
  );
}
