import React from "react";
import { DeleteAlert } from "../../../../components";
import { useTranslations } from "../../../../util";
import { useDeleteDocuments } from "../../hooks";
import styles from "./DeleteDocumentsDialog.module.scss";

export default function DeleteDocumentsDialog(props: {
  documents: number[];
  onClose: () => void;
}) {
  const { documents, onClose } = props;
  const tr = useTranslations();

  const { mutate: deleteDocuments } = useDeleteDocuments();

  const handleDelete = () => {
    deleteDocuments(documents, {
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <DeleteAlert
      title={tr.translateAsString("document.dialog.delete.title", {
        nbr: documents.length,
      })}
      message={tr.translateAsString("document.dialog.delete.subtitle", {
        nbr: documents.length,
      })}
      elementName=""
      className={styles.dialog}
      onDelete={handleDelete}
      onClose={onClose}
    />
  );
}
