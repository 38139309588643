import React from "react";
import {
  Button,
  GenericStateTag,
  Menu,
  MenuItem,
  Popover2,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { useListProjectDefinition } from "../../hooks";
import { ProductComponentTree } from "../../ProjectNomenclature/hooks";
import styles from "./ApproveNomenclatureDialog.module.scss";

export default function ComponentDefinitionSelectPopover(props: {
  tree: ProductComponentTree;
  className?: string;
  onSelect: (tree: ProductComponentTree, definition: number) => void;
}) {
  const { tree, className, onSelect } = props;

  const { data: definitions = [] } = useListProjectDefinition(tree.product.id);

  const handleSelect = (definition: number) => () => {
    onSelect(tree, definition);
  };

  const tr = useTranslations();

  return (
    <Popover2
      className={className}
      content={
        <Menu isDense>
          {definitions.map((definition) => {
            return (
              <MenuItem
                isDense
                key={definition.index}
                text={
                  <GenericStateTag
                    name={definition.name}
                    state={definition.state}
                  />
                }
                onClick={handleSelect(definition.index)}
              />
            );
          })}
        </Menu>
      }
    >
      <Button
        isDense
        intent="array_primary"
        text={tr.translateAsString("generic.action.modify")}
        className={styles.hover_button}
      />
    </Popover2>
  );
}
