import React, { ReactNode } from "react";
import { PageContents } from "../../../components";
import ProjectTabs from "./ProjectTabs";

export default function ProjectPage(props: {
  projectId: number;
  children?: ReactNode;
  domTitle?: string;
}) {
  const { projectId, children, domTitle } = props;
  return (
    <PageContents
      leftPanel={<ProjectTabs projectId={projectId} />}
      domTitle={domTitle}
    >
      {children}
    </PageContents>
  );
}
