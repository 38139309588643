import { PassportUpdate } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { activityKeys } from "../../activities/hooks/queries";
import { passportKeys } from "./queries";

export default function useUpdatePassports() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: { passports: number[]; updates: PassportUpdate[] }) => {
      const { passports, updates } = values;
      for (const passport of passports) {
        await api.passport.updatePassport(passport, updates);
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(passportKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
    }
  );
}
