import { ToolingRequirement } from "@aletiq/types";
import React, { useState } from "react";
import { Button } from "../../../../components";
import { useTranslations } from "../../../../util";
import {
  DeleteToolingRequirementsDialog,
  EditToolingRequirementsDialog,
} from "../../dialogs";
import { ResolvedToolingRequirement } from "../../ProjectTooling/ProjectTooling";
import styles from "./ToolingBatchActionHeader.module.scss";

export default function ToolingBatchActionHeader(props: {
  selectedToolingRequirements:
    | ToolingRequirement[]
    | ResolvedToolingRequirement[];
}) {
  const { selectedToolingRequirements } = props;
  const tr = useTranslations();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const toggleEditDialog = () => {
    setShowEditDialog(!showEditDialog);
  };

  return (
    <>
      <div className={styles.subtitle}>
        {tr.translate("product.bom.components.selected", {
          length: selectedToolingRequirements.length,
        })}
      </div>
      <Button isDense icon="trash" onClick={toggleDeleteDialog}>
        {tr.translate("project.tooling.remove")}
      </Button>
      <Button isDense icon="edit" onClick={toggleEditDialog}>
        {tr.translate("generic.action.modify")}
      </Button>

      {showDeleteDialog && (
        <DeleteToolingRequirementsDialog
          toolingRequirements={selectedToolingRequirements}
          onClose={toggleDeleteDialog}
        />
      )}

      {showEditDialog && (
        <EditToolingRequirementsDialog
          toolingRequirements={selectedToolingRequirements}
          onClose={toggleEditDialog}
        />
      )}
    </>
  );
}
