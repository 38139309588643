import { Text } from "@blueprintjs/core";
import React from "react";
import { PartNameLink } from "../../../../components";
import { usePartIterations } from "../../hooks";
import styles from "./PartUsedIn.module.scss";

export default function PartUsedInRow(props: {
  partId: number;
  iterations: number[];
}) {
  const { partId, iterations } = props;
  const { data: revisions = [] } = usePartIterations(partId);
  const revisionUsed = revisions.filter((r) => iterations.includes(r.number));
  const revivisionName = revisionUsed.map((r) => r.name).join(", ");

  return (
    <div className={styles.row}>
      <div className={styles.name}>
        <PartNameLink hasIcon partId={partId} />
      </div>
      <div className={styles.dividerColumn} />
      <div className={styles.revision}>
        <Text ellipsize>{revivisionName}</Text>
      </div>
    </div>
  );
}
