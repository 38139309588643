import { Property, PropertyFilter } from "@aletiq/types";
import React, { useState } from "react";
import { isInString } from "../../util";
import DateFilter from "./DateFilter";
import FilterView from "./FilterView";
import OwnerFilter from "./OwnerFilter";
import TextFilter from "./TextFilter";

export default function CustomPropertyFilter(props: {
  property: Property;
  value?: PropertyFilter;
  onSetValue: (value: PropertyFilter) => void;
  onClear: (propertyId: number) => void;
}) {
  const { property, value, onSetValue, onClear } = props;

  const [search, setSearch] = useState("");

  const handleClearFilter = () => {
    onClear(property.id);
  };

  const handleSetDateFilter = (values?: { from: string; to: string }) => {
    if (!values) {
      onClear(property.id);
      return;
    }
    onSetValue({
      type: "date",
      id: property.id,
      from: values.from,
      to: values.to,
    });
  };

  const handleSetUserFilter = (userIds?: number[]) => {
    if (!userIds || userIds.length === 0) {
      onClear(property.id);
      return;
    }
    onSetValue({
      type: "user",
      id: property.id,
      values: userIds,
    });
  };

  const handleSetEnumFilter = (values: string[]) => {
    if (values.length === 0) {
      onClear(property.id);
      return;
    }
    onSetValue({
      type: "enum",
      id: property.id,
      values,
    });
  };

  const handleSetTextFilter = (value: string) => {
    if (value === "") {
      onClear(property.id);
      return;
    }
    onSetValue({ type: "string", id: property.id, value });
  };

  if (property.type === "date" && (!value || value.type === "date")) {
    return (
      <DateFilter
        selected={value ? { from: value.from, to: value.to } : undefined}
        onSelect={handleSetDateFilter}
        label={property.label}
      />
    );
  }

  if (property.type === "user" && (!value || value.type === "user")) {
    return (
      <OwnerFilter
        selected={value?.values ?? []}
        onSelect={handleSetUserFilter}
        onClear={handleClearFilter}
        placeholder={property.label}
      />
    );
  }

  if (
    (property.type === "multi-enum" || property.type === "enum") &&
    (!value || value.type === "enum")
  ) {
    const filteredOptions = property.options
      .filter((o) => isInString(o.label, search))
      .map((o) => ({
        id: o.name,
        text: o.label,
      }));

    return (
      <FilterView
        hasSelectAll
        search={search}
        onSearch={setSearch}
        selected={value?.values ?? []}
        placeholder={property.label}
        onSelect={handleSetEnumFilter}
        onClear={handleClearFilter}
        items={filteredOptions}
      />
    );
  }

  if (property.type === "string" && (!value || value.type === "string")) {
    return (
      <TextFilter
        value={value?.value}
        onChange={handleSetTextFilter}
        onClear={handleClearFilter}
        label={property.label}
        placeholder={property.description}
      />
    );
  }

  return null;
}
