import { useLocation } from "react-router-dom";

export default function useProjectType() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const typeParam = searchParams.get("type") ?? null;
  if (typeParam === "tool") {
    return "tool";
  }
  return "article";
}
