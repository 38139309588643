import { ProjectOptionFieldUpdate } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { useToaster } from "../../../hooks";
import { useTranslations } from "../../../util";
import { projectKeys } from "./queries";

export default function useUpdateProjectOptionField() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (values: {
      projectId: number;
      fieldId: number;
      updates: ProjectOptionFieldUpdate[];
    }) => {
      const { projectId, fieldId, updates } = values;

      await api.project.updateProjectOptionField(projectId, fieldId, updates);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.project.config.update"),
        });
      },
    }
  );
}
