import { DocumentRevision } from "@aletiq/types";
import React from "react";
import {
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Popover2,
} from "../../../../components";
import { RevisionPreviewButton } from "../../../../components/revisions";
import { useTranslations } from "../../../../util";
import SignatureIcon from "./SignatureIcon";

export default function RevisionButtonGroup(props: {
  isEditable?: boolean;
  onShowFile: (revisionId: number) => void;
  onDownload: (revisionId: number) => void;
  onDownloadStamped: (revisionId: number) => void;
  onSign: () => void;
  onDelete: () => void;
  revision: DocumentRevision;
  isDeleteDisabled?: boolean;
}) {
  const {
    onShowFile,
    onDownload,
    onDownloadStamped,
    onSign,
    onDelete,
    revision,
    isDeleteDisabled,
    isEditable,
  } = props;

  const tr = useTranslations();

  return (
    <ButtonGroup isDense>
      <RevisionPreviewButton onPreview={() => onShowFile(revision.id)} />
      <Popover2
        content={
          <Menu isDense>
            {isEditable && (
              <MenuItem
                isDense
                icon={<SignatureIcon />}
                onClick={onSign}
                text={tr.translateAsString(
                  "document.details.revisions.sign-document"
                )}
              />
            )}
            <MenuItem
              isDense
              icon="download"
              onClick={() => onDownload(revision.id)}
              text={tr.translateAsString("document.details.revisions.download")}
            />
            <MenuItem
              isDense
              icon="download"
              disabled={revision.stampedPdfFile === null}
              onClick={() => onDownloadStamped(revision.id)}
              text={tr.translateAsString(
                "document.details.revisions.download-stamped"
              )}
            />
            {isEditable && (
              <MenuItem
                isDense
                icon="trash"
                disabled={isDeleteDisabled || !isEditable}
                onClick={onDelete}
                text={tr.translateAsString(
                  "document.details.revisions.delete-draft"
                )}
              />
            )}
          </Menu>
        }
      >
        <Button isDense icon="more" />
      </Popover2>
    </ButtonGroup>
  );
}
