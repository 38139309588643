import { API } from "@aletiq/api";
import { PartQueryParams } from "@aletiq/types";
export const pdmKeys = {
  all: ["parts"] as const,
  list: (params?: PartQueryParams) => [...pdmKeys.all, params ?? {}] as const,
  byId: (id: number) => [...pdmKeys.all, id] as const,
  byName: (name: string) => [...pdmKeys.all, { name }] as const,
  image: (id: number) => [...pdmKeys.byId(id), "image"] as const,
  access: (id: number) => [...pdmKeys.all, "access", id] as const,
  usedIn: (id: number) => [...pdmKeys.byId(id), "used-in"] as const,
  previewLink: (id: number) => [...pdmKeys.byId(id), "preview-link"] as const,

  available: (params?: PartQueryParams) =>
    [...pdmKeys.list(params), "available"] as const,
  ancestors: (id: number) => [...pdmKeys.byId(id), "ancestors"] as const,
  countWithDescription: () =>
    [...pdmKeys.all, "parts-with-descriptions"] as const,

  allIterations: (id: number) => [...pdmKeys.byId(id), "iterations"] as const,
  iteration: (id: number, revision?: number) =>
    [...pdmKeys.allIterations(id), revision ?? "last"] as const,
  iterationView: (id: number, rev: number) =>
    [...pdmKeys.iteration(id, rev), "view"] as const,
  iterationComponents: (id: number, rev?: number) =>
    [...pdmKeys.iteration(id, rev), "components"] as const,
  iterationTree: (id: number, rev?: number) =>
    [...pdmKeys.iteration(id, rev), "tree"] as const,
  iterationExpandTree: (id: number, rev?: number, nodes?: number[]) =>
    [...pdmKeys.iterationTree(id, rev), nodes ?? []] as const,

  allProjects: (id: number) => [pdmKeys.byId(id), "projects"] as const,
  projectBom: (id: number) => [pdmKeys.allProjects(id), "bom"] as const,
};

export const pdmQueries = {
  byId: (api: API, id: number) => ({
    queryKey: pdmKeys.byId(id),
    queryFn: () => api.pdm.getPart(id),
  }),
  byName: (api: API, name: string) => ({
    queryKey: pdmKeys.byName(name),
    queryFn: () => api.pdm.listPartsPage({ limit: 1, name }),
  }),
  access: (api: API, id: number) => ({
    queryKey: pdmKeys.access(id),
    queryFn: () => api.pdm.getPartAccess(id),
  }),

  allIterations: (api: API, id: number) => ({
    queryKey: pdmKeys.allIterations(id),
    queryFn: () => api.pdm.listPartIterations(id),
  }),
  iterationComponents: (api: API, id: number, rev: number) => ({
    queryKey: pdmKeys.iterationComponents(id, rev),
    queryFn: () => api.pdm.listAssemblyIterationComponents(id, rev),
  }),
};
