import { useQueries } from "react-query";
import useApi from "../../../app/useApi";
import { isNotUndefined } from "../../../util";
import { documentQueries } from "./queries";

export default function useDocumentsAccess(documentIds: number[]) {
  const api = useApi();
  const accessQueries = useQueries(
    documentIds.map((documentId) => documentQueries.access(api, documentId))
  );

  const isLoading = accessQueries.some((query) => query.isLoading);

  const documentsAccess = accessQueries
    .map((r) => r.data)
    .filter(isNotUndefined);

  return {
    isLoading,
    data: documentsAccess,
  };
}
