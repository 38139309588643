import { IconName } from "@blueprintjs/core";
import React from "react";
import { PropertyCard } from "../../../../components";
import { useTranslations } from "../../../../util";
import { PropertyFilters } from "../types";

export default function CreationDatePropertyCard(props: {
  entity: "document" | "part" | "passport" | "product";
  filters?: PropertyFilters;
  usedIn?: { count: number; icon: IconName }[];
  target?: JSX.Element;
}) {
  const { entity, filters, usedIn, target } = props;
  const tr = useTranslations();

  return (
    <PropertyCard
      type="date"
      filters={filters}
      label={tr.translateAsString("generic.label.creation-date.title")}
      description={tr.translateAsString(
        "generic.label.creation-date.description",
        { entity }
      )}
      usedIn={usedIn}
      target={target}
    />
  );
}
