import { Input } from "@aletiq/design-system";
import React, { useState } from "react";
import { useTranslations } from "../../util";
import { EditablePropertyButtonGroup } from "./buttons";
import EditablePropertyTemplate from "./EditablePropertyTemplate";

export default function EditablePropertyInputNumber(props: {
  label: string;
  isEditable: boolean;
  intent?: "primary" | "default";
  value?: number;
  placeholder: string;
  emptyState: string;
  inline?: boolean;
  tooltipText?: string;
  search?: string;
  onSubmit: (value: number) => void;
  minWidth?: number;
}) {
  const {
    label,
    isEditable,
    intent = "default",
    value: initialValue,
    placeholder,
    emptyState,
    inline,
    onSubmit,
    tooltipText,
    search,
    minWidth,
  } = props;
  const tr = useTranslations();

  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState<string>(String(initialValue));

  const handleToggleEditMode = () => {
    setEditedValue(String(initialValue));
    setEditMode(!editMode);
  };

  const handleSubmit = () => {
    onSubmit(parseInt(editedValue, 10));
    setEditMode(false);
  };

  const isNumber = (test: string) => !isNaN(Number(test));

  return (
    <EditablePropertyTemplate
      intent={intent}
      isEditable={isEditable}
      label={label}
      triggerEditMode={handleToggleEditMode}
      isInEditMode={editMode}
      inline={inline}
      tooltipText={tooltipText}
      search={search}
      minWidth={minWidth}
    >
      {!editMode && (initialValue || emptyState)}
      {editMode && (
        <>
          <Input
            autoFocus
            value={editedValue}
            onChange={setEditedValue}
            placeholder={placeholder}
            intent={intent}
            hasError={!isNumber(editedValue)}
            errorHelperText={tr.translateAsString(
              "configuration.error.numeric.warning"
            )}
            isDense
            fill
          />
          <EditablePropertyButtonGroup
            onSubmit={handleSubmit}
            onReset={handleToggleEditMode}
            isSubmitDisabled={!isNumber(editedValue)}
          />
        </>
      )}
    </EditablePropertyTemplate>
  );
}
