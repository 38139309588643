import { HighlightedText, Tooltip } from "@aletiq/design-system";
import React, { ReactNode } from "react";
import { PageTarget } from "../../../util";
import { PageLink } from "../../page";
import styles from "./ActivityLink.module.scss";

export default function ActivityLink(props: {
  icon?: ReactNode;
  tooltip?: string;
  text: string;
  highlight?: string;
  location: PageTarget;
  isDisabled?: boolean;
  onClick?: () => void;
}) {
  const { tooltip, text, highlight, icon, location, isDisabled, onClick } =
    props;

  const textContent = <HighlightedText text={text} highlight={highlight} />;
  const content = (
    <PageLink
      to={location}
      className={styles.relative}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {icon && (
        <>
          <span className={styles.text}>{textContent}</span>
          <span className={styles.icon}>{icon}</span>
        </>
      )}
      {!icon && textContent}
    </PageLink>
  );

  if (tooltip) {
    return (
      <Tooltip className={styles.inline} position="top-left" content={tooltip}>
        {content}
      </Tooltip>
    );
  }

  return content;
}
