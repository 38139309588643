import { FileMeta, Part } from "@aletiq/types";
import cadex, {
  ModelPrs_SelectionMode,
  ModelPrs_View3dObject,
} from "@cadexchanger/web-toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import useApi from "../../../../app/useApi";
import { API_URL } from "../../../../config";
import { useToaster } from "../../../../hooks";
import { isNotUndefined, useTranslations } from "../../../../util";
import SceneGraphVisitor, {
  SceneComponent,
  SceneObjectInfo,
  ViewSettings,
} from "./types";

export default function usePartView(
  part: Part,
  iteration: number,
  ref: HTMLDivElement | null,
  files: FileMeta[]
) {
  const tr = useTranslations();

  const [isLoading, setIsLoading] = useState(true);
  const [components, setComponents] = useState<SceneComponent[]>([]);

  const [settings, setSettings] = useState<ViewSettings>({
    projection: cadex.ModelPrs_CameraProjectionType.Isometric,
    displayMode: cadex.ModelPrs_DisplayMode.ShadedWithBoundaries,
    explodeFactor: 0,
  });

  const api = useApi();
  const toaster = useToaster();

  const model = useMemo(() => new cadex.ModelData_Model(), []);
  const scene = useMemo(() => new cadex.ModelPrs_Scene(), []);
  const defaultDisplayerParams = useMemo(
    () =>
      new cadex.ModelPrs_DisplayParams(
        new cadex.ModelPrs_SceneDisplayer(scene),
        cadex.ModelPrs_DisplayMode.ShadedWithBoundaries,
        new cadex.ModelData_RepresentationMaskSelector(
          cadex.ModelData_RepresentationMask.ModelData_RM_Any
        )
      ),
    [scene]
  );

  const viewPort = useMemo(
    () =>
      ref !== null
        ? new cadex.ModelPrs_ViewPort(
            {
              showViewCube: true,
              cameraType: cadex.ModelPrs_CameraProjectionType.Isometric,
              autoZFitAll: true,
              tabIndex: 0,
            },
            ref
          )
        : new cadex.ModelPrs_ViewPort({
            showViewCube: true,
            cameraType: cadex.ModelPrs_CameraProjectionType.Isometric,
            autoZFitAll: true,
            tabIndex: 0,
          }),
    [ref]
  );

  const loadFile = useCallback(
    async (_: string, fileName: string) => {
      const file = files.find((f) => f.name === fileName);
      const token = await api.pdm.getPartIterationCdxfbFileLink(
        part.id,
        iteration,
        file?.id || 0
      );

      const response = await fetch(`${API_URL}${token}`);
      if (response.status === 200) {
        return response.arrayBuffer();
      }
      throw new Error(response.statusText);
    },
    [part.id, iteration, api.pdm, files]
  );

  const loadModel = useCallback(async () => {
    try {
      viewPort.attachToScene(scene);
      scene.globalSelectionMode = ModelPrs_SelectionMode.Shape;

      const loadResult = await model.loadFile(part.name, loadFile, true);
      const sceneObjects = await cadex.ModelPrs_DisplayerApplier.apply(
        loadResult.roots,
        [],
        defaultDisplayerParams
      );

      const sceneComponents = await getSceneComponents(
        model,
        part,
        sceneObjects
      );
      setComponents(sceneComponents);
      setIsLoading(false);
      viewPort.fitAll();
    } catch (error) {
      setIsLoading(false);
      toaster.show({
        intent: "warning",
        icon: "warning-sign",
        message: tr.translate("toaster.error.part.view"),
      });
    }
  }, [
    model,
    scene,
    viewPort,
    loadFile,
    part,
    toaster,
    tr,
    defaultDisplayerParams,
  ]);

  const closeView = useCallback(() => {
    scene.removeAll();
  }, [scene]);

  // center view on content
  const zoomToFit = () => viewPort.fitAll();

  const updateSettings = async (newSettings: ViewSettings) => {
    // update camera projection type
    if (newSettings.projection !== settings.projection) {
      viewPort.cameraProjectionType = newSettings.projection;
    }

    // update display mode
    if (newSettings.displayMode !== settings.displayMode) {
      scene.globalDisplayMode = newSettings.displayMode;
    }

    // Update explode factor
    if (newSettings.explodeFactor !== settings.explodeFactor) {
      viewPort.exploder.value = newSettings.explodeFactor / 100;
    }

    setSettings(newSettings);
  };

  const getLastChildIndex = (component: SceneComponent) => {
    const lastChildIndex =
      component.depth === 0
        ? components.length
        : components.findIndex(
            (c) => c.id > component.id && c.depth <= component.depth
          );

    const firstChild = components.find(
      (c) => c.id > component.id && c.depth > component.depth
    );

    // if the clicked component has children but no id is found for the first
    // non-child component, then its last child is the last component
    if (lastChildIndex < 0 && firstChild) {
      return components.length;
    }
    return lastChildIndex;
  };

  /*
   * Toggle component visibility
   */
  const onHideComponent = (component: SceneComponent) => {
    const lastChildIndex = getLastChildIndex(component);

    const toHide =
      lastChildIndex > 0
        ? components.slice(component.id + 1, lastChildIndex).map((c) => c.id)
        : [component.id];

    setComponents(
      components.map((c) => {
        if (toHide.includes(c.id)) {
          // set visibility to opposite of current parent component visibility
          if (c.isVisible && component.isVisible && c.sceneObject) {
            c.isSelected && scene.deselect(c.sceneObject);
            scene.hide(c.sceneObject);
          } else if (!c.isVisible && !component.isVisible && c.sceneObject) {
            scene.display(c.sceneObject, settings.displayMode);
          }

          return {
            ...c,
            isVisible: !component.isVisible,
            isSelected: component.isVisible ? false : c.isSelected,
          };
        }
        return { ...c };
      })
    );
  };

  /*
   * Toggle component selection (using component list)
   * Selecting a new component in the list always replaces
   * the previous selection
   */
  const onSelectComponent = async (component: SceneComponent) => {
    const lastChildIndex = getLastChildIndex(component);

    const toSelect =
      lastChildIndex > 0
        ? components.slice(component.id + 1, lastChildIndex).map((c) => c.id)
        : [component.id];

    if (!component.isVisible) {
      return;
    }

    scene.deselectAll();
    setComponents(
      components.map((c) => {
        if (toSelect.includes(c.id)) {
          // set selection status to opposite of current parent component status
          if (c.isSelected && component.isSelected && c.sceneObject) {
            scene.deselect(c.sceneObject);
          } else if (!c.isSelected && !component.isSelected && c.sceneObject) {
            scene.select(c.sceneObject, false);
          }

          return {
            ...c,
            isSelected: !component.isSelected,
          };
        }
        return { ...c, isSelected: false };
      })
    );
  };

  /*
   * Fld/unfold an assembly and its children
   */
  const onFoldComponent = (component: SceneComponent) => {
    if (component.type === "part") {
      return;
    }
    const lastChildIndex = getLastChildIndex(component);

    const toFold =
      lastChildIndex > 0
        ? components.slice(component.id + 1, lastChildIndex).map((c) => c.id)
        : [component.id];

    if (!component.isVisible) {
      return;
    }
    setComponents(
      components.map((c) => {
        if (toFold.includes(c.id)) {
          // set fold status to opposite of current parent component status
          return {
            ...c,
            isExpanded: !component.isExpanded,
          };
        }
        return c;
      })
    );
  };

  const expandedComponents = useMemo(
    () =>
      components.filter((c1) => {
        if (c1.depth === 0 || c1.isExpanded) {
          return true;
        }
        if (c1.type !== "assembly") {
          return false;
        }
        const hasFoldedParentNode = components.find(
          (c2) => c2.id < c1.id && c2.depth <= c1.depth && !c2.isExpanded
        );
        return !hasFoldedParentNode;
      }),
    [components]
  );

  const selectedComponents = useMemo(
    () => components.filter((c) => c.isSelected).map((c) => c.id),
    [components]
  );

  /*
   * Handle click on element to change selection
   */
  const onViewSelectionChanged = useCallback(
    (event: cadex.ModelPrs_SelectionChangedEvent) => {
      const addedObjectIds = event.added
        .map((added) => convertToSceneObject(added.object)?.aa?.object?.uuid)
        .filter(isNotUndefined);
      const removedComponentsIds = event.removed
        .map((added) => convertToSceneObject(added.object)?.aa?.object?.uuid)
        .filter(isNotUndefined);

      const addedComponents = components
        .filter(
          (c) => c.sceneObjectId && addedObjectIds.includes(c.sceneObjectId)
        )
        .map((c) => c.id);

      const removedComponents = components
        .filter(
          (c) =>
            c.sceneObjectId && removedComponentsIds.includes(c.sceneObjectId)
        )
        .map((c) => c.id);

      // check if changes have been made to the selection
      if (
        addedComponents.some((c) => !selectedComponents.includes(c)) ||
        removedComponents.some((c) => selectedComponents.includes(c))
      ) {
        setComponents(
          components.map((c) =>
            addedComponents.includes(c.id)
              ? { ...c, isSelected: true }
              : removedComponents.includes(c.id)
              ? { ...c, isSelected: false }
              : c
          )
        );
      }
    },
    [components, selectedComponents]
  );

  useEffect(() => {
    scene.addEventListener("selectionChanged", onViewSelectionChanged);
    return () => {
      scene.removeEventListener("selectionChanged", onViewSelectionChanged);
    };
  });

  return {
    isLoading,
    loadModel,
    closeView,
    zoomToFit,
    settings,
    updateSettings,
    components: expandedComponents,
    onFoldComponent,
    onHideComponent,
    onSelectComponent,
  };
}

/*
 * Convert View3dObject to SceneObjectInfo using JSON.parse
 * (Casting to SceneObjectInfo directly always returns the required fields as undefined)
 */
function convertToSceneObject(view3DObject: ModelPrs_View3dObject) {
  const sceneObject: SceneObjectInfo = JSON.parse(JSON.stringify(view3DObject));
  return sceneObject;
}

async function getSceneComponents(
  model: cadex.ModelData_Model,
  part: Part,
  sceneObjects: cadex.ModelPrs_View3dObject[]
) {
  const visitor = new SceneGraphVisitor(part, sceneObjects);
  await model.accept(visitor);
  return visitor.getComponents();
}
