import { Part } from "@aletiq/types";
import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import {
  DialogBase,
  DialogContent,
  DialogHeader,
} from "../../../../components";
import { usePartIterationViewFiles } from "../../hooks";
import styles from "./PartIterationViewer.module.scss";
import PartViewerBom from "./PartViewerBom";
import PartViewerMenu from "./PartViewerMenu";
import usePartView from "./usePartView";

export default function PartIterationViewer(props: {
  part: Part;
  iteration: number;
  onClose: () => void;
}) {
  const { part, iteration, onClose } = props;

  const [showBom, setShowBom] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const { data: viewFiles = [] } = usePartIterationViewFiles(
    part.id,
    iteration
  );

  const {
    loadModel,
    isLoading,
    closeView,
    zoomToFit,
    updateSettings,
    settings,
    components,
    onFoldComponent,
    onHideComponent,
    onSelectComponent,
  } = usePartView(part, iteration, containerRef.current, viewFiles);

  const handleClose = () => {
    closeView();
    onClose();
  };

  const handleToggleBom = () => {
    setShowBom(!showBom);
  };

  useEffect(() => {
    if (viewFiles.length > 0 && containerRef.current !== null) {
      loadModel();
    }
  }, [containerRef, loadModel, viewFiles]);

  return (
    <DialogBase isOpen isFullPage onClose={handleClose}>
      <DialogHeader title={part.name} icon="eye-open" onClose={handleClose} />
      <DialogContent className={styles.dialog_contents}>
        <PartViewerMenu
          settings={settings}
          onUpdateSettings={updateSettings}
          onZoomToFit={zoomToFit}
          onToggleBom={handleToggleBom}
          partType={part.type}
        />
        <div ref={containerRef} className={styles.cad_viewer}>
          {isLoading && <Spinner />}
        </div>
        {showBom && (
          <PartViewerBom
            components={components}
            onFoldComponent={onFoldComponent}
            onHideComponent={onHideComponent}
            onSelectComponent={onSelectComponent}
            onClose={handleToggleBom}
          />
        )}
      </DialogContent>
    </DialogBase>
  );
}
