import React from "react";
import {
  CancelButton,
  DeleteButton,
  MoveButton,
  TreeButtonGroup,
} from "../../../../components/tree";

export default function AdditionalProductsTreeActionBar(props: {
  isMoved: boolean;
  isMoving: boolean;
  onMove: () => void;
  onDelete: () => void;
  onCancel: () => void;
}) {
  const { isMoved, isMoving, onMove, onDelete, onCancel } = props;

  return (
    <TreeButtonGroup>
      {isMoved && <CancelButton onClick={onCancel} />}
      {!isMoved && !isMoving && (
        <>
          <MoveButton onClick={onMove} />
          <DeleteButton onClick={onDelete} />
        </>
      )}
    </TreeButtonGroup>
  );
}
