import { PostProperty, PropertyObject } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { useToaster } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { propertyKeys } from "./queries";

export default function useCreateCustomProperty(objectType: PropertyObject) {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    (property: PostProperty) =>
      api.property.createProperty(objectType, property),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(propertyKeys.byObjectType(objectType));
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.custom-property.creation"),
        });
      },
    }
  );
}
