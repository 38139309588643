import { Icon } from "@aletiq/design-system";
import { PartType } from "@aletiq/types";
import { IconName, Intent, MaybeElement } from "@blueprintjs/core";
import React from "react";
import Gears from "./Gears";

type PartIconMap = Record<PartType, IconName | JSX.Element>;

export const partIcon: PartIconMap = {
  assembly: "shapes",
  part: "cube",
  drawing: "annotation",
};

export default function PartIcon(props: {
  type?: PartType;
  isStandard?: boolean;
  inline?: boolean;
  iconSize?: number;
  intent?: Intent;
  color?: string;
  className?: string;
}) {
  const {
    isStandard = false,
    className,
    type,
    inline,
    intent,
    iconSize,
    color,
  } = props;

  if (!type) return <></>;

  if (isStandard) {
    return (
      <StandardPartIcon
        className={className}
        type={type}
        inline={inline}
        color={color}
        iconSize={iconSize}
        intent={intent}
      />
    );
  }

  const icon = partIcon[type] ?? "blank";

  return (
    <Icon
      intent={intent}
      className={className}
      icon={icon}
      iconSize={iconSize}
      color={color}
      inline={inline}
    />
  );
}

export function StandardPartIcon(props: {
  type: PartType;
  inline?: boolean;
  iconSize?: number;
  color?: string;
  className?: string;
  intent?: Intent;
}) {
  const { className, type, inline, iconSize, color, intent } = props;

  return (
    <Icon
      icon={iconMapper[type]}
      iconSize={iconSize}
      color={color}
      inline={inline}
      className={className}
      intent={intent}
    />
  );
}

const iconMapper: Record<PartType, IconName | MaybeElement> = {
  drawing: "blank",
  part: "cog",
  assembly: <Gears />,
};
