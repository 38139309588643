/*
 * look for a cycle in existing task dependencies
 * tasks are represented by their index in the array
 * for each task, the corresponding array is the list of its dependencies
 * (represented by their own indexes)
 */
export const findExistingCycle = (tasks: number[][]) => {
  const [, cycle] = visitNodes(
    tasks,
    tasks.map((n, index) => index),
    [],
    []
  );
  return cycle;
};

/*
 * Check ancestors (dependencies and their own dependencies) of a task
 * to be added as dependency to avoid the creation of a dependency cycle
 */
export const taskIsValidDependency = (
  tasks: number[][],
  dependency: number,
  parentTask: number
) => {
  const [, cycle] = visitNodes(tasks, [dependency], [], [parentTask]);
  return cycle.length === 0;
};

const visitNodes = (
  tasks: number[][],
  nodes: number[], //index of task nodes to visit
  visited: number[],
  ancestors: number[]
) => {
  let newVisited: number[] = visited;
  let cycle: number[] = [];
  for (const node of nodes) {
    [newVisited, cycle] = visitNode(tasks, node, newVisited, ancestors);
    if (cycle.length > 0) return [newVisited, cycle];
  }
  return [newVisited, cycle];
};

const visitNode = (
  tasks: number[][],
  node: number,
  visited: number[],
  ancestors: number[]
) => {
  const children = tasks[node] || [];
  // We are back to an ancestor node, it means the current branch forms a cycle
  if (ancestors.includes(node)) return [visited, ancestors];
  // This branch has already been visited, continue
  if (visited.includes(node)) return [visited, []];
  // Add self to ancestors, so we know if we are back to the same node while exploring children
  const ancestorsForChildren = [...ancestors, node];
  const [visitedByChildren, cycle] = visitNodes(
    tasks,
    children,
    visited,
    ancestorsForChildren
  );

  // This task and its dependencies are fully explored
  return [[...visitedByChildren, node], cycle];
};
