import {
  Project,
  ProjectQueryOrder,
  ProjectType,
  Property,
} from "@aletiq/types";
import React, { useCallback, useMemo } from "react";
import { CellProps } from "react-table";
import {
  defaultCreatedAtColumn,
  defaultDescriptionColumn,
  defaultPropertyColumn,
  defaultUpdatedAtColumn,
  ExtendedColumn,
} from "../../../components";
import ProductLink from "../../../components/link/ProductLink";
import { ColumnOptionConfig } from "../../../hooks";
import { extractBy, indexBy, mapRecord, useTranslations } from "../../../util";
import {
  useAletiqPropertiesDefaultDisplay,
  useProperties,
} from "../../administration/Attributes/hooks";

export type ProductColumn = ProjectQueryOrder | number;

export function useDefaultProductColumnConfig(productType?: ProjectType) {
  const { data: properties = [], isLoading: isLoadingCustomProperties } =
    useProperties("product");
  const { data: aletiqProperties = [], isLoading: isLoadingAletiqProperties } =
    useAletiqPropertiesDefaultDisplay("product");

  const isLoading = useMemo(
    () => isLoadingAletiqProperties || isLoadingCustomProperties,
    [isLoadingAletiqProperties, isLoadingCustomProperties]
  );

  const config = useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    const config: ColumnOptionConfig<ProductColumn> = {
      name: true,
      description: true,
      createdAt:
        aletiqProperties.find((p) => p.name === "created_at")
          ?.displayByDefault ?? false,
      updatedAt:
        aletiqProperties.find((p) => p.name === "updated_at")
          ?.displayByDefault ?? false,
    };

    for (const property of properties) {
      config[property.id] =
        property.displayByDefault &&
        (property.group === "all" || property.group === productType);
    }

    return config;
  }, [properties, aletiqProperties, isLoading, productType]);

  return { isLoading, config };
}

export function useProductColumns(options: {
  productType?: ProjectType;
  config?: ColumnOptionConfig<ProductColumn>;
  onOpenProduct?: (productId: number) => void;
}) {
  const {
    productType,
    config = {
      name: true,
      description: true,
      createdAt: false,
      updatedAt: false,
    },
    onOpenProduct,
  } = options;
  const tr = useTranslations();

  const { data: properties = [] } = useProperties("product");

  const filteredProperties = properties.filter(
    (prop) => prop.group === "all" || prop.group === productType
  );

  const propertyRecord: Record<ProductColumn, Property> = indexBy(
    filteredProperties,
    "id"
  );

  const handleOpenProduct = useCallback(
    (productId: number) => {
      onOpenProduct && onOpenProduct(productId);
    },
    [onOpenProduct]
  );

  return useMemo(() => {
    const columns: Record<
      ProductColumn,
      ExtendedColumn<Project, ProjectQueryOrder>
    > = {
      name: {
        Header: tr.translate("project.list.name"),
        accessor: "name",
        Cell: (props: CellProps<Project>) => (
          <ProductLink
            hasIcon
            product={props.row.original}
            search={props.searchString}
            onClick={() => handleOpenProduct(props.row.original.id)}
          />
        ),
      },
      description: defaultDescriptionColumn(),
      createdAt: defaultCreatedAtColumn(),
      updatedAt: defaultUpdatedAtColumn(),
    };

    const propertyColumns: Record<
      ProductColumn,
      ExtendedColumn<Project, ProjectQueryOrder>
    > = mapRecord(propertyRecord, (prop) => defaultPropertyColumn(prop));

    return [
      ...extractBy(columns, config),
      ...extractBy(propertyColumns, config),
    ];
  }, [tr, propertyRecord, config, handleOpenProduct]);
}
