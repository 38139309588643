import { Tag } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../../../../util";
import { Target } from "../Dialogs/CreatePassportPropertyDialog";
import styles from "./PassportPropertyCard.module.scss";

export default function PassportPropertyTarget(props: { target: Target }) {
  const { target } = props;
  const tr = useTranslations();

  return (
    <div className={styles.target}>
      {(target === "all" || target === "article") && (
        <Tag isDense rightIcon="projects" unclickable>
          {tr.translate("admin.attribute.target.articles-passports")}
        </Tag>
      )}
      {(target === "all" || target === "tool") && (
        <Tag isDense rightIcon="wrench" unclickable>
          {tr.translate("admin.attribute.target.tools-passports")}
        </Tag>
      )}
    </div>
  );
}
