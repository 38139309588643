import { API } from "@aletiq/api";
import { PartError, PartStage, PartState } from ".././types";

export default async function handleNewPart(
  api: API,
  state: PartState,
  projects: number[]
): Promise<PartError | number> {
  const stage: PartStage = "uploading";
  try {
    const partId = await api.pdm.createPart(
      {
        projects,
        type: state.partInfo.type,
        format: state.partInfo.format,
        revisionSpec: state.partInfo.revision,
        name: state.partInfo.name,
        isStandard: false,
        description: "",
      },
      state.file
    );
    return partId;
  } catch (error) {
    return { name: state.file.name, stage, error: error as Error };
  }
}
