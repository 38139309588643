import { ItemSelect, Select } from "@aletiq/design-system";
import { Task } from "@aletiq/types";
import React, { useState } from "react";
import {
  ANALYTICS_MUTATION_STATUS,
  ANALYTICS_TASK_COMPLETED,
  ANALYTICS_WORKFLOW_COMPLETED,
  ANALYTICS_WORKFLOW_MODEL_ID,
  ANALYTICS_WORKFLOW_MODEL_NAME,
  makeAnalyticsMutationStatus,
  makeAnalyticsOptional,
  useAnalytics,
} from "../../analytics";
import {
  useDelayedAction,
  useProcess,
  useTasks,
  useUpdateTaskStatus,
} from "../../features/tasks/hooks";
import { ResolvedTask } from "../../features/tasks/hooks/useUserTasks";
import { useProcessModel } from "../../features/tasks/tasksSlice";
import { useTranslations } from "../../util";
import StatusTag from "./StatusTag";
import { statusToIntent, statusToText } from "./util";

export default function StatusSelect(props: {
  task: Task | ResolvedTask;
  hasUpdateRights: boolean;
  hasLockedDependencies: boolean;
  hasShorthandText?: boolean;
  isDense?: boolean;
}) {
  const {
    task,
    hasUpdateRights,
    isDense = true,
    hasShorthandText,
    hasLockedDependencies,
  } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const workflowId =
    typeof task.process === "number" ? task.process : task.process.id;

  const { data: tasks } = useTasks({ workflows: [workflowId] });
  const { data: workflow } = useProcess(workflowId);
  const { data: workflowModel } = useProcessModel(workflow?.model ?? 0);

  const [nextStatus, setNextStatus] = useState(task.status);
  const { mutate: applyStatusChange, isLoading } = useUpdateTaskStatus();

  const { isDelayPending, delayAction } = useDelayedAction(
    tr.translate("toaster.cancel.task.validation", {
      title: task.title,
    })
  );

  const handleChangeStatus = (status?: TaskStatus) => {
    if (!status) return;
    setNextStatus(status);
    if (status === "done") {
      delayAction(() => {
        applyStatusChange(
          { task: task.id, title: task.title, status },
          {
            onSettled: (_, error) => {
              const eventProperties = {
                [ANALYTICS_WORKFLOW_MODEL_NAME]: makeAnalyticsOptional(
                  workflowModel?.title
                ),
                [ANALYTICS_WORKFLOW_MODEL_ID]: makeAnalyticsOptional(
                  workflowModel?.id
                ),
                [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
              };
              analytics.track(ANALYTICS_TASK_COMPLETED, eventProperties);

              const isLastTask =
                tasks?.filter((t) => t.completionDate === undefined).length ===
                0;
              if (isLastTask) {
                analytics.track(ANALYTICS_WORKFLOW_COMPLETED, eventProperties);
              }
            },
          }
        );
      });
    } else {
      applyStatusChange({ task: task.id, title: task.title, status });
    }
  };

  const status = task.status;

  const isSelectedDisabled =
    !hasUpdateRights ||
    isDelayPending ||
    hasLockedDependencies ||
    task.status === "done";

  const statusList: TaskStatus[] = ["not_started", "started", "done"];
  const items: ItemSelect<TaskStatus>[] = statusList.map(
    (status: TaskStatus) => ({
      key: status,
      text: statusToText(tr, status),
      intent: statusToIntent(status),
      isDense,
    })
  );

  return (
    <Select
      isDisabled={isSelectedDisabled}
      popoverProps={{
        targetProps: { onClick: (ev) => ev.stopPropagation() },
        minimal: true,
        position: "bottom-right",
      }}
      filterable={false}
      items={items.filter((s) => s.key !== status)}
      onItemSelect={handleChangeStatus}
      isDense={isDense}
      hasCustomButton
    >
      <StatusTag
        isDisabled={isSelectedDisabled}
        isTaskLocked={hasLockedDependencies}
        hasShorthandText={hasShorthandText}
        completionDate={task.completionDate}
        isDense={isDense}
        isLoading={isLoading}
      >
        {isDelayPending || isLoading ? nextStatus : status}
      </StatusTag>
    </Select>
  );
}

export type TaskStatus = "not_started" | "started" | "done";
