import React from "react";
import { useTranslations } from "../../../util";
import TreeButton, { TreeButtonProps } from "./TreeButton";

export default function AssignButton(props: TreeButtonProps) {
  const tr = useTranslations();

  return (
    <TreeButton
      {...props}
      isDense
      view="filled"
      color="primary"
      text={tr.translateAsString("generic.action.move")}
    />
  );
}
