import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useProjectImage(projectId: number | null) {
  const api = useApi();
  return useQuery(
    projectKeys.image(projectId!),
    () => api.project.getProjectImage(projectId!),
    { enabled: projectId !== null, staleTime: 600_000 }
  );
}
