import React from "react";
import { StateNotification } from "../../../components/tags/GenericStateTag/GenericStateTag";
import { useTranslations } from "../../../util";

export function OperationBillNotificationText(params: {
  hasObsoleteDocs?: boolean;
}) {
  const { hasObsoleteDocs } = params;

  const tr = useTranslations();

  if (hasObsoleteDocs) {
    return tr.translate("project.operation-bill.bill-state.warning");
  }

  return null;
}

export default function operationBillNotification(params: {
  hasObsoleteDocs?: boolean;
}): StateNotification | undefined {
  const { hasObsoleteDocs } = params;

  if (!hasObsoleteDocs) {
    return undefined;
  }

  return {
    content: <OperationBillNotificationText {...params} />,
    intent: "danger",
  };
}
