import { CheckBox, Tooltip } from "@aletiq/design-system";
import { Project, ProjectQueryOrder } from "@aletiq/types";
import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { ExtendedColumn } from "../../../components";
import { useTranslations } from "../../../util";
import { useDefaultProductColumnConfig, useProductColumns } from "../hooks";

export function useBomComponentsDialogColumns(
  selectedProjects: Project[],
  availableComponents: number[],
  onSelect: (project: Project) => void,
  onSelectAll: () => void
) {
  const tr = useTranslations();

  const { config } = useDefaultProductColumnConfig();
  const defaultColumns = useProductColumns({ config });

  const columns = useMemo<ExtendedColumn<Project, ProjectQueryOrder>[]>(
    () => [
      {
        id: "selection",
        width: 50,
        minWidth: 50,
        disableResizing: true,
        fullWidthContent: true,
        canSort: false,
        Header: () => (
          <CheckBox
            isDense
            intent="default"
            onClick={onSelectAll}
            checked={
              selectedProjects.length === availableComponents.length &&
              selectedProjects.length > 0
            }
            indeterminate={
              selectedProjects.length > 0 &&
              selectedProjects.length !== availableComponents.length
            }
          />
        ),
        accessor: (project) =>
          selectedProjects.find((p) => p.id === project.id) !== undefined,
        Cell: (cellProps: CellProps<Project>) => (
          <Tooltip
            isDense
            isDisabled={availableComponents.includes(cellProps.row.original.id)}
            content={tr.translate(
              "project.dialog.components.add.cycle-warning"
            )}
          >
            <CheckBox
              isDense
              checked={cellProps.value}
              onClick={() => onSelect(cellProps.row.original)}
              intent="default"
              isDisabled={
                !availableComponents.includes(cellProps.row.original.id)
              }
            />
          </Tooltip>
        ),
      },
      ...defaultColumns,
    ],
    [
      tr,
      availableComponents,
      selectedProjects,
      onSelect,
      onSelectAll,
      defaultColumns,
    ]
  );

  return columns;
}
