import {
  AlertPopover,
  AnchorButtonSP,
  Input,
  Tooltip,
} from "@aletiq/design-system";
import React, { useState } from "react";
import { useTranslations } from "../../../../../util";
import { PartAction } from "../types";
import PartActionSelect from "./PartActionSelect";
import styles from "./UploadedPartsList.module.scss";

export default function PartTableHeader(props: {
  isLoading?: boolean;
  canApproveRevision: boolean;
  onBatchUpdateIndex: (index: string) => void;
  onBatchUpdateDescription: (index: string) => void;
  onBatchUpdateAction: (action: PartAction) => void;
  scrollbarSize: number;
  isScrollbarVisible: boolean;
}) {
  const {
    isLoading,
    canApproveRevision,
    onBatchUpdateAction,
    onBatchUpdateDescription,
    onBatchUpdateIndex,
    scrollbarSize,
    isScrollbarVisible,
  } = props;

  const tr = useTranslations();

  const [description, setDescription] = useState("");
  const [revisionIndex, setRevisionIndex] = useState("");

  return (
    <thead>
      <tr>
        <th className={styles.tag_col}>
          {tr.translate("part.dialog.drop-parts.tag")}
        </th>
        <th>{tr.translate("part.dialog.drop-parts.part.name")}</th>
        <th className={styles.action_col}>
          <div className={styles.batch_action}>
            {tr.translate("part.dialog.drop-parts.action")}
            <PartActionSelect
              canUpdateDraft
              isBatchSelector
              canApproveRevision={canApproveRevision}
              onChangePartAction={onBatchUpdateAction}
            >
              <EditButton isDisabled={isLoading} />
            </PartActionSelect>
          </div>
        </th>
        <th className={styles.revision_col}>
          <div className={styles.batch_action}>
            {tr.translate("part.dialog.drop-parts.revision.name")}
            <AlertPopover
              isDense
              disabled={isLoading}
              disablePrimary={isLoading}
              onPrimaryClick={() => onBatchUpdateIndex(revisionIndex)}
              primaryText={tr.translateAsString(
                "part.dialog.drop-parts.apply-all"
              )}
              content={
                <Input
                  fill
                  isDense
                  disabled={isLoading}
                  placeholder={tr.translateAsString(
                    "part.dialog.drop-parts.apply-all.placeholder",
                    { type: "index" }
                  )}
                  value={revisionIndex}
                  onChange={setRevisionIndex}
                />
              }
            >
              <EditButton isDisabled={isLoading} />
            </AlertPopover>
          </div>
        </th>
        <th className={styles.description_col}>
          <div className={styles.batch_action}>
            {tr.translate("part.dialog.drop-parts.revision.desc")}
            <AlertPopover
              isDense
              disabled={isLoading}
              disablePrimary={isLoading}
              onPrimaryClick={() => onBatchUpdateDescription(description)}
              primaryText={tr.translateAsString(
                "part.dialog.drop-parts.apply-all"
              )}
              content={
                <Input
                  fill
                  isDense
                  disabled={isLoading}
                  placeholder={tr.translateAsString(
                    "part.dialog.drop-parts.apply-all.placeholder",
                    { type: "description" }
                  )}
                  value={description}
                  onChange={setDescription}
                />
              }
            >
              <EditButton isDisabled={isLoading} />
            </AlertPopover>
          </div>
        </th>
        {isScrollbarVisible && (
          <th style={{ width: `${scrollbarSize}px`, borderLeft: "none" }} />
        )}
      </tr>
    </thead>
  );
}

function EditButton(props: { isLoading?: boolean; isDisabled?: boolean }) {
  const { isLoading, isDisabled } = props;
  const tr = useTranslations();

  return (
    <Tooltip
      position="top"
      content={tr.translate("part.dialog.drop-parts.apply-all-parts")}
    >
      <AnchorButtonSP
        isDense
        isLoading={isLoading}
        isDisabled={isDisabled}
        icon="edit"
        color="primary"
        view="flat"
      />
    </Tooltip>
  );
}
