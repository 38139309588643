import { Passport } from "@aletiq/types";
import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  ItemSelect,
  Select,
} from "../../../../components";
import { useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useAddPassportComponent, usePassport } from "../../hooks";
import styles from "./PassportNomenclature.module.scss";

export default function PassportSelector(props: {
  product: number;
  passportId: number;
  passports: Passport[];
}) {
  const { product, passportId, passports } = props;
  const tr = useTranslations();

  const [selectedPassport, setSelectedPassport] = useState<
    undefined | number
  >();

  const canEditNomenclature = useHasPermission("update:passport-nomenclature");
  const { data: passport } = usePassport(selectedPassport ?? null);
  const { mutate: handleAddComponent, isLoading: savingComponent } =
    useAddPassportComponent(passportId, () => setSelectedPassport(undefined));

  const productPassport = passports.filter(
    (passport) => passport.parentProject === product
  );

  const productPassports: ItemSelect<number>[] = productPassport.map(
    (passport) => ({
      text: passport.name,
      key: passport.id,
      icon: "id-number",
    })
  );

  return (
    <div className={styles.select}>
      <Select
        isFill
        buttonClassName={
          selectedPassport ? styles.validate : styles.selector_button
        }
        filterable
        items={productPassports}
        onItemSelect={(passportId) => setSelectedPassport(passportId)}
        intent="default"
        activeItem={selectedPassport}
        isDisabled={!canEditNomenclature}
        isDense
      >
        {passport?.name ??
          tr.translateAsString("passport.bom.components.passport.select")}
      </Select>
      {selectedPassport && (
        <ButtonGroup className={styles.buttons}>
          <Button
            isDense
            icon="tick"
            intent="success"
            isLoading={savingComponent}
            isDisabled={!canEditNomenclature}
            onClick={() => handleAddComponent(selectedPassport)}
          />
          <Button
            isDense
            icon="cross"
            onClick={() => setSelectedPassport(undefined)}
          />
        </ButtonGroup>
      )}
    </div>
  );
}
