import React from "react";
import { Divider, H3, UserMultiselect } from "../../../../components";
import { unique, useTranslations } from "../../../../util";
import GroupMultiselect from "../ShareDocumentDialog/GroupMultiselect";
import { ReducerAccessAction, ReducerAccessState } from "./hooks";
import { GroupRow, PublicRow, UserRow } from "./Rows";
import styles from "./ShareDocumentsDialog.module.scss";

export default function ShareEntitiesDialogSelection(props: {
  total: number;
  defaultPublicDocuments: number;
  defaultUsers: Record<number, number[]>;
  defaultGroups: Record<number, number[]>;
  accessState: ReducerAccessState;
  accessDispatch: React.Dispatch<ReducerAccessAction>;
}) {
  const {
    total,
    defaultPublicDocuments,
    defaultUsers,
    defaultGroups,
    accessState,
    accessDispatch,
  } = props;
  const tr = useTranslations();

  const handleDispatch = (action: ReducerAccessAction) => () => {
    accessDispatch(action);
  };

  const handleNewGroups = (groups: number[]) => {
    accessDispatch({ type: "addGroups", groups });
  };

  const handleNewUsers = (users: number[]) => {
    accessDispatch({ type: "addUsers", users });
  };

  const isPublicRowAvailable =
    (defaultPublicDocuments > 0 && !accessState.isNonePublic) ||
    accessState.isAllPublic;

  const defaultGroupsId = Object.keys(defaultGroups).map((g) =>
    parseInt(g, 10)
  );
  const groupsId = unique(
    [...defaultGroupsId, ...accessState.groupsAdded].filter(
      (g) => !accessState.groupsRemoved.includes(g)
    )
  );

  const defaultUsersId = Object.keys(defaultUsers).map((u) => parseInt(u, 10));
  const usersId = unique(
    [...defaultUsersId, ...accessState.usersAdded].filter(
      (u) => !accessState.usersRemoved.includes(u)
    )
  );

  const getCountGroup = (groupId: number) =>
    accessState.groupsAdded.includes(groupId)
      ? total
      : defaultGroups[groupId]?.length;

  const getCountUser = (userId: number) =>
    accessState.usersAdded.includes(userId)
      ? total
      : defaultUsers[userId]?.length;

  return (
    <div className={styles.content}>
      {/* Groups */}
      <div className={styles.wrapper}>
        <H3 className={styles.header}>
          {tr.translate("document.dialog.share.groups")}
        </H3>
        <Divider intent="primary" />
        {isPublicRowAvailable && (
          <>
            <PublicRow
              count={accessState.isAllPublic ? total : defaultPublicDocuments}
              total={total}
              onAddAll={handleDispatch({ type: "addAllPublic" })}
              onRemoveAll={handleDispatch({ type: "removeAllPublic" })}
            />
          </>
        )}
        {groupsId.map((group) => (
          <React.Fragment key={`group-${group}`}>
            <GroupRow
              groupId={group}
              count={getCountGroup(group)}
              total={total}
              onAddAll={handleDispatch({ type: "addGroup", group })}
              onRemoveAll={handleDispatch({ type: "removeGroup", group })}
            />
          </React.Fragment>
        ))}
        <GroupMultiselect
          isDocPublic={isPublicRowAvailable}
          onAddGroups={handleNewGroups}
          onAddOpenAccess={handleDispatch({ type: "addAllPublic" })}
          groupsToIgnore={groupsId}
        />
      </div>
      {/* Users */}
      <div className={styles.wrapper}>
        <H3 className={styles.header}>
          {tr.translate("document.dialog.share.other-users")}
        </H3>
        <Divider intent="primary" />
        {usersId.map((user) => (
          <React.Fragment key={`user-${user}`}>
            <UserRow
              userId={user}
              count={getCountUser(user)}
              total={total}
              onAddAll={handleDispatch({ type: "addUser", user })}
              onRemoveAll={handleDispatch({ type: "removeUser", user })}
            />
          </React.Fragment>
        ))}
        <UserMultiselect onSubmit={handleNewUsers} usersToIgnore={usersId} />
      </div>
    </div>
  );
}
