import { PassportQueryParams } from "@aletiq/types";
export const passportKeys = {
  all: ["passports"],
  list: (params?: PassportQueryParams) =>
    [...passportKeys.all, params ?? {}] as const,
  byId: (id: number) => [...passportKeys.all, id] as const,
  usedIn: (ids: number[]) => [...passportKeys.all, "used-in", ids] as const,

  countWithDescription: () =>
    [...passportKeys.all, "parts-with-descriptions"] as const,

  allComponents: (id: number) =>
    [...passportKeys.byId(id), "components"] as const,
  componentsUsedIn: (id: number) =>
    [...passportKeys.allComponents(id), "used-in"] as const,

  allStates: () => [...passportKeys.all, "states"] as const,

  allFiletypes: () => [...passportKeys.all, "filetypes"] as const,

  allFiles: (id: number) => [...passportKeys.byId(id), "files"] as const,
};
