import React from "react";
import { useTranslations } from "../../util";
import NameLink from "./NameLink";
import productLinkProps from "./props/productLinkProps";

export default function ProductLink(props: {
  product:
    | { id: number; isTool: boolean; isDeleted: boolean; name: string }
    | undefined;
  hasIcon?: boolean;
  hasThumbnail?: boolean;
  className?: string;
  search?: string;
  onClick?: () => void;
}) {
  const { className, product, hasIcon, hasThumbnail, search, onClick } = props;
  const tr = useTranslations();
  const linkProps = productLinkProps(tr, product, hasThumbnail);

  return (
    <NameLink
      className={className}
      hasIcon={hasIcon || hasThumbnail}
      search={search}
      onClick={onClick}
      {...linkProps}
    />
  );
}
