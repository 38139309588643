import React, { Fragment } from "react";
import { SingleSelectionIcon, Icon } from "../../../components";
import { useTranslations } from "../../../util";
import styles from "./ProjectConfig.module.scss";

export default function ConfigFieldType(props: {
  allowMultipleValue: boolean;
}) {
  const tr = useTranslations();

  return (
    <Fragment>
      {props.allowMultipleValue ? (
        <span className={styles.config_type}>
          <Icon inline icon="properties" />
          <span>
            {tr.translate("product.config.type.select", { type: "multi" })}
          </span>
        </span>
      ) : (
        <span className={styles.config_type}>
          <Icon inline icon={<SingleSelectionIcon />} />
          <span>
            {tr.translate("product.config.type.select", { type: "single" })}
          </span>
        </span>
      )}
    </Fragment>
  );
}
