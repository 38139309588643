export const blue = "#2b33d4";
export const brown = "#65473a";
export const darkGrey = "#4a4b57";
export const green = "#37833b";
export const orange = "#fb8200";
export const purple = "#8353c7";
export const pink = "#ec407a";
export const red = "#c8372d";
export const teal = "#5cc7df";
export const white = "#ffffff";
export const yellow = "#e5ae40";

// "var(--colorName)"" is not recognized by the graph library
export const graphBlue40 = "#a7abff";
export const graphBlue50 = "#868dff";
export const graphBlue70 = blue;
export const graphBlue80 = "#3d428e";
export const graphGrey10 = "#e1e1e3";
export const graphGrey50 = "#787885";
export const graphGrey100 = "#19191d";

export const workflowColor1 = darkGrey;
export const workflowColor2 = brown;
export const workflowColor3 = orange;
export const workflowColor4 = yellow;
export const workflowColor5 = green;
export const workflowColor6 = teal;
export const workflowColor7 = blue;
export const workflowColor8 = purple;
export const workflowColor9 = pink;
export const workflowColor10 = red;
export const workflowDefault = darkGrey;
