import { useCallback, useState } from "react";
import { LIMIT_PAGE_SELECT } from "../../components";

export type Pagination = {
  selectedPage: number;
  limit: number;
  resetSelectedPage: () => void;
  handleSelectPage: (page: number) => void;
  handleLimit: (limit: number) => void;
};

export default function usePagination(options?: {
  onChange?: () => void;
}): Pagination {
  const [selectedPage, setSelectedPage] = useState(0);
  const [limit, setLimit] = useState(LIMIT_PAGE_SELECT[0]);

  const handleSelectPage = useCallback(
    (page: number) => {
      setSelectedPage(page);
      options?.onChange && options.onChange();
    },
    [options]
  );

  const handleLimit = useCallback(
    (limit: number) => {
      setLimit(limit);
      options?.onChange && options.onChange();
    },
    [options]
  );

  const onChange = options?.onChange;

  const resetSelectedPage = useCallback(() => {
    setSelectedPage(0);
    onChange?.();
  }, [onChange]);

  return {
    selectedPage,
    limit,
    resetSelectedPage,
    handleSelectPage,
    handleLimit,
  };
}
