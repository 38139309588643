import { Passport } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../util";
import passportLinkProps from "../../link/props/passportLinkProps";
import ActivityLink from "./ActivityLink";

export default function ActivityPassportLink(props: {
  passport: Passport;
  onClick?: () => void;
}) {
  const { passport, onClick } = props;
  const tr = useTranslations();

  const linkProps = passportLinkProps(tr, passport);

  return <ActivityLink {...linkProps} onClick={onClick} />;
}
