import { Part } from "@aletiq/types";
import React from "react";
import { Translations } from "../../../util";
import { PartIcon } from "../../icons";

export default function partLinkProps(
  tr: Translations,
  part: Part | undefined
) {
  const partType = part?.type ?? "part";
  const icon = (
    <PartIcon
      inline
      isStandard={part?.isStandard}
      type={partType}
      intent="primary"
    />
  );

  let tooltip = undefined;
  if (part?.isDeleted) {
    tooltip = tr.translateAsString("link.deleted-object", { type: "part" });
  }
  if (part && !part.hasAccess) {
    tooltip = tr.translateAsString("part.details.private");
  }

  return {
    icon: icon,
    isDisabled: part ? part.isDeleted || !part.hasAccess : false,
    location: { panel: `partId-${part?.id}` },
    text: part?.name ?? "",
    tooltip: tooltip,
  };
}
