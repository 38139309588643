import { DocumentRevisionSpec, PropertyValueUpdate } from "@aletiq/types";

export type Status = "idle" | "loading" | "error" | "success";
export type Stage =
  | { type: "done" }
  | { type: "updates" | "creations"; status: Status };

export type State = {
  stage: Stage;
  documentCreations: DocumentState[];
};

export type Action =
  | {
      type: "stageChanged";
      payload: { stage: Stage };
    }
  | {
      type: "documentCreationModified";
      payload: { state: DocumentState };
    };

export const stageChanged = (stage: Stage): Action => ({
  type: "stageChanged",
  payload: { stage },
});

export const documentCreationModified = (state: DocumentState): Action => ({
  type: "documentCreationModified",
  payload: { state },
});

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "stageChanged": {
      return { ...state, stage: action.payload.stage };
    }
    case "documentCreationModified": {
      const { payload } = action;
      return {
        ...state,
        documentCreations: atName(
          payload.state.file.name,
          payload.state
        )(state.documentCreations),
      };
    }
  }
};

const atName =
  (name: string, state: DocumentState) => (documents: DocumentState[]) =>
    documents.map((doc) => (doc.file.name === name ? state : doc));

export const initialState = (fileState: DocumentState[]): State => {
  return {
    stage: fileState.some((doc) => doc.documentId)
      ? { type: "updates", status: "idle" }
      : { type: "creations", status: "idle" },
    documentCreations: fileState,
  };
};

export type DocumentState = {
  file: File;
  documentId: number | null;
  action: DocumentAction;
};

export type DocumentAction = {
  action: FileAction;
  name: string;
  owner: number | null;
  properties: PropertyValueUpdate[];
  revision: DocumentRevisionSpec;
};

export type FileAction = "update" | "create" | "ignore";

export const projectStatus = (updatesStatus: string) => {
  const isIdle = updatesStatus === "idle";
  const isError = updatesStatus === "error";
  const isLoading = updatesStatus === "loading";
  const isSuccess = updatesStatus === "success";
  return { isIdle, isError, isSuccess, isLoading };
};
