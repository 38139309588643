import { Passport, Property, PropertyValue } from "@aletiq/types";
import React from "react";
import { Field } from "../../../components";
import ProductLink from "../../../components/link/ProductLink";
import { useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { useProperties } from "../../administration/Attributes/hooks";
import { useProject } from "../../project/hooks";
import { useUpdatePassport } from "../hooks";
import {
  BillIndexField,
  ClientField,
  DescriptionField,
  PassportConfigurationField,
  PropertyField,
  StateField,
  VersionField,
} from "./ConfigurationField";
import DefinitionIndexField from "./ConfigurationField/DefinitionIndexField";
import styles from "./PassportDetails.module.scss";

export default function PassportDetailsProperties(props: {
  passport: Passport;
}) {
  const { passport } = props;
  const tr = useTranslations();

  const canUpdateProperties = useHasPermission("update:passports");
  const { data: parentProject } = useProject(passport?.parentProject ?? null);
  const { data: properties } = useProperties("passport");
  const { mutate: updatePassport } = useUpdatePassport(passport.id);

  const filterProperties = properties?.filter(
    (prop) =>
      prop.group === "all" ||
      (parentProject?.isArticle && prop.group === "article") ||
      (parentProject?.isTool && prop.group === "tool")
  );

  const handleUpdatePropertyValue = (
    propertyName: string,
    value: PropertyValue
  ) => {
    updatePassport([
      {
        type: "property",
        value: {
          property: propertyName,
          value,
        },
      },
    ]);
  };

  if (passport.isDeleted) return null;
  return (
    <>
      <DescriptionField passport={passport} />
      {parentProject && (
        <Field
          inline
          className={styles.property}
          label={tr.translate("passports.details.configuration.product", {
            type: parentProject?.isTool ? "tool" : "article",
          })}
        >
          <ProductLink
            hasIcon
            className={styles.flex}
            product={parentProject}
          />
        </Field>
      )}
      <DefinitionIndexField hasIcon passport={passport} />
      {parentProject?.isTool && <VersionField hasIcon passport={passport} />}
      {parentProject?.isArticle && (
        <BillIndexField hasIcon passport={passport} />
      )}
      <StateField passportId={passport.id} />
      <ClientField passportId={passport.id} />
      <PassportConfigurationField passport={passport} />
      {passport &&
        filterProperties?.map((property: Property) => (
          <PropertyField
            key={`property-${property.id}`}
            property={property}
            value={passport.properties[property.name]}
            onEditValue={handleUpdatePropertyValue}
            canUpdate={canUpdateProperties}
          />
        ))}
    </>
  );
}
