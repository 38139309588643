import { workflowDefault } from "../../../util";

export default function useWorkflowColor<
  T extends { id: number; color?: string }
>(workflow?: T): string {
  if (workflow?.color) {
    return workflow.color;
  }

  //default color
  return workflowDefault;
}
