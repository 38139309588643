import { EntityId } from "@aletiq/types";
import React from "react";
import { useTranslations } from "../../../../../util";
import {
  LinkEntitiesToProcessesDialog,
  ProcessCreationDialog,
} from "../../../../tasks/dialogs";
import {
  DeleteDocumentsDialog,
  EditPropertiesDialog,
  LinkProductsDialog,
  ShareDocumentsDialog,
  UnlinkProjectDialogs,
} from "../../../dialogs";
import { ResolvedDocument } from "../../documentTypes";
import { DialogType } from "./DocumentBatchActionHeader";

export default function DocumentBatchActionHeaderDialogs(props: {
  selectedDocuments: ResolvedDocument[];
  projectId?: number;
  dialog: DialogType;
  handleCloseDialog: () => void;
  handleCloseResetSelection: () => void;
}) {
  const {
    dialog,
    projectId,
    selectedDocuments,
    handleCloseDialog,
    handleCloseResetSelection,
  } = props;
  const tr = useTranslations();

  const documentEntities = selectedDocuments.map(({ id }) => ({
    type: "file",
    id,
  })) as EntityId[];

  const docIds = selectedDocuments.map((p) => p.id);

  return (
    <>
      {dialog === "create-workflow" && (
        <ProcessCreationDialog
          initialEntities={documentEntities}
          onClose={handleCloseDialog}
        />
      )}
      {dialog === "link-workflow" && (
        <LinkEntitiesToProcessesDialog
          entities={documentEntities}
          onClose={handleCloseDialog}
          title={tr.translateAsString("document.dialog.link.workflows.title", {
            count: documentEntities.length,
          })}
          subtitle={tr.translate("document.dialog.link.workflows.subtitle")}
        />
      )}
      {dialog === "delete" && (
        <DeleteDocumentsDialog
          documents={docIds}
          onClose={handleCloseResetSelection}
        />
      )}
      {dialog === "link-project" && (
        <LinkProductsDialog
          documentIds={docIds}
          onClose={handleCloseDialog}
          currentProducts={[]}
        />
      )}
      {dialog === "edit-property" && (
        <EditPropertiesDialog
          documentIds={docIds}
          onClose={handleCloseDialog}
        />
      )}
      {dialog === "share" && (
        <ShareDocumentsDialog
          documentIds={docIds}
          onClose={handleCloseResetSelection}
        />
      )}
      {dialog === "unlink-project" && projectId && (
        <UnlinkProjectDialogs
          documentsIds={docIds}
          onClose={handleCloseResetSelection}
          projectId={projectId}
        />
      )}
    </>
  );
}
