import { Process } from "@aletiq/types";

const useProcessStatus = (
  workflow: Process
): "archived" | "done" | "active" => {
  const isWorkflowDone = !workflow.tasks.some((t) => t.status !== "done");
  return workflow?.isArchived ? "archived" : isWorkflowDone ? "done" : "active";
};

export default useProcessStatus;
