import { useProcess } from "../../../features/tasks/hooks";
import { useTranslations } from "../../../util";
import workflowLinkProps from "../props/workflowLinkProps";

export default function useWorkflowLink(workflowId: number) {
  const tr = useTranslations();

  const { data: workflow } = useProcess(workflowId);

  return workflowLinkProps(tr, workflow);
}
