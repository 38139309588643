import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../app/useApi";
import { userKeys } from "../../hooks/queries";

export default function useDeactivateUser(userId: number) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(() => api.user.deactivateUser(userId), {
    onSuccess: () => queryClient.invalidateQueries(userKeys.all),
  });
}
