import { Button } from "@aletiq/design-system";
import { IconName } from "@blueprintjs/core";
import React, { useState } from "react";
import { ActionBar, AlignRight, PropertyCard } from "../../../../components";
import { HandleClear, HandleFilter, useHasPermission } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useDocuments } from "../../../documents/hooks";
import AttributeCards from "../../AttributeCards";
import {
  CreationDatePropertyCard,
  LastUpdatePropertyCard,
  PropertyCardFilters,
  SelectDefaultDisplayedProperties,
} from "../common";
import { useProperties } from "../hooks";
import { PropertyFilters } from "../types";
import { CreateDocumentPropertyDialog } from "./Dialogs";
import { DocumentPropertyCard } from "./PropertyCard";

export default function DocumentAttributes(props: {
  filters: PropertyFilters;
  handleFilter: HandleFilter<PropertyFilters>;
  handleClear: HandleClear<PropertyFilters>;
  openPopoverOnStart?: boolean;
}) {
  const { filters, handleClear, handleFilter, openPopoverOnStart } = props;
  const tr = useTranslations();

  const [showCreationDialog, setShowCreationDialog] = useState(false);

  const { data } = useDocuments({ limit: 1 });
  const { data: properties = [] } = useProperties("document");
  const canCreateAttribute = useHasPermission("create:custom-attributes");
  const canEditDefaultDisplay = useHasPermission(
    "update:default-property-display"
  );

  const documentCount = data?.count || 0;
  const docPropertyUses: { count: number; icon: IconName }[] = [
    {
      count: documentCount,
      icon: "document",
    },
  ];

  const handleToggleDialog = () => {
    setShowCreationDialog(!showCreationDialog);
  };

  return (
    <AttributeCards>
      <ActionBar>
        <PropertyCardFilters
          filters={filters}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
        <AlignRight />
        {canEditDefaultDisplay && (
          <SelectDefaultDisplayedProperties
            properties={properties}
            objectType="document"
            openOnStart={openPopoverOnStart}
          />
        )}
        <Button
          intent="secondary"
          icon="plus"
          isDisabled={!canCreateAttribute}
          onClick={handleToggleDialog}
          isDense
        >
          {tr.translate("admin.attribute.new.button")}
        </Button>
      </ActionBar>
      <PropertyCard
        type="user"
        filters={filters}
        usedIn={docPropertyUses}
        label={tr.translateAsString("admin.attribute.documents.owner")}
        description={tr.translateAsString(
          "admin.attribute.documents.owner.description"
        )}
      />
      <CreationDatePropertyCard
        entity="document"
        filters={filters}
        usedIn={docPropertyUses}
      />
      <LastUpdatePropertyCard
        entity="document"
        filters={filters}
        usedIn={docPropertyUses}
      />
      {properties.map((property) => (
        <DocumentPropertyCard
          key={property.id}
          property={property}
          filters={filters}
        />
      ))}
      {canCreateAttribute && showCreationDialog && (
        <CreateDocumentPropertyDialog onClose={handleToggleDialog} />
      )}
    </AttributeCards>
  );
}
