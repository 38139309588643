import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../../../app/useApi";
import { useToaster } from "../../../../../hooks";
import { useTranslations } from "../../../../../util";
import { activityKeys } from "../../../../activities/hooks/queries";
import { pdmKeys } from "../../../../pdm/hooks/queries";
import { projectKeys } from "../../../hooks/queries";
import { ProductMutationList } from "../partProjectBomUtils";

export default function useUpdatePartProjectBomTree() {
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const tr = useTranslations();

  return useMutation(
    async (mutations: ProductMutationList) => {
      await Promise.all(
        mutations.deletions.map((deletion) =>
          api.project.deleteBOMEntry(
            deletion.parent.productId,
            deletion.parent.definitionIdx,
            deletion.productId
          )
        )
      );

      await Promise.all(
        mutations.additions.map((addition) =>
          api.project.addBOMEntry(
            addition.parent.productId,
            addition.parent.definitionIdx,
            {
              entity: addition.productId,
              quantity: 1,
              definition: addition.definitionIdx,
            }
          )
        )
      );

      await Promise.all(
        mutations.assignations.map((assignment) =>
          api.pdm.updatePartProjectBom(
            assignment.product.productId,
            assignment.product.definitionIdx,
            assignment.part
          )
        )
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectKeys.all);
        queryClient.invalidateQueries(pdmKeys.all);
        queryClient.invalidateQueries(activityKeys.all);
      },
      onError: () => {
        toaster.show({
          intent: "danger",
          icon: "warning-sign",
          timeout: 2000,
          message: tr.translate("toaster.error.part.components.register"),
        });
      },
    }
  );
}
