import { AnchorButton, Tooltip } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../util";

export default function RevisionPreviewButton(props: {
  onPreview: () => void;
  isDisabled?: boolean;
}) {
  const { onPreview, isDisabled } = props;

  const tr = useTranslations();

  return (
    <Tooltip
      position="top-right"
      content={tr.translateAsString("document.details.revisions.preview")}
    >
      <AnchorButton
        isDense
        icon="eye-open"
        onClick={onPreview}
        isDisabled={isDisabled}
      />
    </Tooltip>
  );
}
