import { Property, PropertyValue } from "@aletiq/types";
import React, { useState } from "react";
import {
  EditablePropertyButtonGroup,
  EditablePropertyTemplate,
  EditableUserList,
  UserInitialsList,
} from "../../../../components";
import { useTranslations } from "../../../../util";

export default function CustomUserField(props: {
  property: Property;
  propertyValue?: { type: "user"; value: number[] };
  onSubmit: (value: PropertyValue) => void;
  isSubmitting?: boolean;
  canEdit: boolean;
  hasDescriptionTooltip?: boolean;
  search?: string;
  minWidth?: number;
}) {
  const {
    property,
    propertyValue,
    canEdit,
    onSubmit,
    hasDescriptionTooltip,
    search,
    minWidth,
  } = props;
  const tr = useTranslations();

  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(propertyValue?.value);

  const handleToggleEdition = () => {
    setEditedValue(propertyValue?.value);
    setIsEditing(!isEditing);
  };

  const handleSubmit = () => {
    if (!editedValue) {
      onSubmit(null);
    } else {
      onSubmit({ type: "user", value: editedValue });
    }
    handleToggleEdition();
  };

  const handleReset = () => {
    handleToggleEdition();
  };

  return (
    <EditablePropertyTemplate
      tooltipText={hasDescriptionTooltip ? property.description : undefined}
      search={search}
      label={property.label}
      isEditable={canEdit}
      triggerEditMode={handleToggleEdition}
      isInEditMode={isEditing}
      intent="default"
      minWidth={minWidth}
      inline
    >
      {!isEditing && propertyValue && propertyValue.value.length > 0 && (
        <UserInitialsList users={propertyValue?.value ?? []} fill />
      )}
      {!isEditing &&
        (!propertyValue || propertyValue.value.length === 0) &&
        tr.translate("generic.label.none")}
      {isEditing && (
        <>
          <EditableUserList
            onEditSelection={setEditedValue}
            users={editedValue ?? []}
          />

          <EditablePropertyButtonGroup
            onSubmit={handleSubmit}
            onReset={handleReset}
          />
        </>
      )}
    </EditablePropertyTemplate>
  );
}
