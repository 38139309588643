import { useTranslations } from "../../../../../util";
import { usePart } from "../../../hooks";

export default function DeletePartErrorToaster(props: { partId: number }) {
  const { partId } = props;
  const tr = useTranslations();

  const { data: part } = usePart(partId);

  return tr.translate("part.action.delete.failure", { name: part?.name });
}
