import {
  AnchorButton,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Popover2,
  Tooltip,
} from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../../util";
import styles from "./ListPaginatedStepper.module.scss";

export const LIMIT_PAGE_SELECT = [25, 50, 100];
export const MAX_DISPLAYED_PAGES = 5;

export default function ListPaginatedStepper(props: {
  itemCount: number;
  pageSelected: number;
  limit: number;
  onSelectLimit: (limit: number) => void;
  onSelectPage: (page: number) => void;
}) {
  const { itemCount, pageSelected, limit, onSelectPage, onSelectLimit } = props;
  const tr = useTranslations();

  const numberOfPages = Math.max(Math.ceil(itemCount / limit), 1);
  const pages = Array(numberOfPages ?? 0)
    .fill(0)
    .map((_, index) => index);

  // the lower bound of the displayed pages is either:
  // the selected page -2 or the last page -MAX_DISPLAYED_PAGES
  // if that number < 0, use the first page (0)
  const min = Math.max(
    Math.min(pageSelected - 2, numberOfPages - MAX_DISPLAYED_PAGES),
    0
  );

  const max = Math.min(min + MAX_DISPLAYED_PAGES, numberOfPages);

  const displayedPages = pages.slice(min, max);

  const handleButton = (value: number) => () => {
    if (pageSelected + value > numberOfPages || pageSelected + value < 0)
      return;

    onSelectPage(pageSelected + value);
  };

  const handleSelectPage = (page: number) => () => onSelectPage(page);

  const handleSelectLimit = (limit: number) => () => onSelectLimit(limit);

  return (
    <div className={styles.stepperWrapper}>
      <div className={styles.stepper}>
        <ButtonGroup isDense>
          <Tooltip content={tr.translate("paginate.first")} position="top">
            <AnchorButton
              isDense
              icon="chevron-backward"
              isDisabled={pageSelected === 0}
              onClick={handleSelectPage(0)}
            />
          </Tooltip>
          <Tooltip content={tr.translate("paginate.previous")} position="top">
            <AnchorButton
              isDense
              icon="chevron-left"
              isDisabled={pageSelected === 0}
              onClick={handleButton(-1)}
            />
          </Tooltip>
          {displayedPages.map((page) => (
            <Button
              key={`button-${page}`}
              active={page === pageSelected}
              onClick={handleSelectPage(page)}
              isDense
            >
              {page + 1}
            </Button>
          ))}
        </ButtonGroup>
        <Tooltip content={tr.translate("paginate.next")} position="top">
          <AnchorButton
            isDense
            className={styles.button}
            rightIcon="chevron-right"
            isDisabled={pageSelected + 1 === numberOfPages}
            onClick={handleButton(+1)}
          />
        </Tooltip>
        <Popover2
          popoverClassName={styles.popover}
          content={
            <Menu isDense intent="minimal">
              {LIMIT_PAGE_SELECT.map((property) => (
                <MenuItem
                  isDense
                  active={property === limit}
                  key={`page-${property}`}
                  text={tr.translate("paginate.number-by-page", {
                    nbr: property,
                  })}
                  onClick={handleSelectLimit(property)}
                />
              ))}
            </Menu>
          }
          minimal
          position="bottom"
        >
          <Button isDense rightIcon="caret-down">
            {tr.translate("paginate.number-by-page", { nbr: limit })}
          </Button>
        </Popover2>
      </div>
    </div>
  );
}
