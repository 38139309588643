import { useQuery } from "react-query";
import { Period } from "@aletiq/types";
import useApi from "../../../../app/useApi";
import { processKeys } from "../../hooks/queries";

export default function useAnalysisModelsResolution(filter: {
  selectedModels: number[];
  period: Period;
}) {
  const { selectedModels, period } = filter;
  const api = useApi();

  return useQuery(
    processKeys.analysisResolution(filter),
    () =>
      api.task.getAnalysisModelsResolution({
        processModels: selectedModels,
        period,
      }),
    { enabled: filter.selectedModels.length > 0 }
  );
}
