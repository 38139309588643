import React from "react";
import classNames from "classnames";
import styles from "./FileActionTable.module.scss";

export default function FileActionTable(props: {
  children?: React.ReactNode;
  classname?: string;
}) {
  return (
    <table className={classNames(styles.container, props.classname)}>
      {props.children}
    </table>
  );
}
