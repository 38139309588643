import React, { useEffect } from "react";
import { IToastProps, ProgressBar } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { fileUploadDissmissed } from "../filesSlice";
import { State as AppState } from "../../../app/reducer";
import { useTranslations } from "../../../util";
import { useToaster } from "../../../hooks";

export default function useFileUpdate() {
  const toaster = useToaster();
  const tr = useTranslations();
  const fileUpdate = useSelector((state: AppState) => state.files.fileUpload);
  const dispatch = useDispatch();

  useEffect(() => {
    Object.entries(fileUpdate).forEach(
      ([file, { name, progress, done, failed }]) => {
        let toast: IToastProps;
        if (done) {
          toast = {
            icon: "cloud-upload",
            timeout: 2000,
            onDismiss: () => dispatch(fileUploadDissmissed({ key: file })),
            message: (
              <div>
                <div style={{ marginBottom: "5px" }}>
                  {tr.translate("file.hook.uploading", { name })}
                </div>
                <ProgressBar intent={"success"} stripes={false} value={1} />
              </div>
            ),
          };
        } else if (failed) {
          toast = {
            icon: "cloud-upload",
            timeout: -1,
            message: (
              <div>
                <div style={{ marginBottom: "5px" }}>
                  {tr.translate("file.hook.failed.to.upload")}
                </div>
                <ProgressBar intent={"danger"} value={progress} />
              </div>
            ),
          };
        } else {
          toast = {
            icon: "cloud-upload",
            timeout: -1,
            message: (
              <div>
                <div style={{ marginBottom: "5px" }}>
                  {tr.translate("file.hook.uploading", { name })}
                </div>
                <ProgressBar intent={"primary"} value={progress} />
              </div>
            ),
          };
        }
        toaster.show(toast, "updateFile-" + file);
      }
    );
  }, [fileUpdate, dispatch, toaster, tr]);
}
