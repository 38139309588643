import { PartComponent } from "@aletiq/types";
import React, { useCallback, useMemo } from "react";
import { CellProps } from "react-table";
import { Dialog, ExtendedColumn, Table } from "../../../../components";
import { useCurrentUser } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { ComponentTree } from "../../AllParts/AllPartsType";
import PartLockOwner from "../../common/PartLockOwner";
import { usePartIterations, useRegisterPartComponent } from "../../hooks";
import styles from "./ApproveAssemblyIterationDialog.module.scss";
import assemblyComponentsToApprove from "./assemblyComponentsToApprove";
import ComponentIterationCell from "./ComponentIterationCell";
import ComponentNameCell from "./ComponentNameCell";
import SelectedComponentAction from "./SelectedComponentAction";
import useAssemblyTree from "./useAssemblyTree";
import useSavePartRevisions from "./useSavePartRevisions";

export default function ApproveAssemblyIterationDialog(props: {
  partId: number;
  onClose: () => void;
}) {
  const { partId, onClose } = props;
  const tr = useTranslations();

  const { data: assemblyTree, isLoading } = useAssemblyTree(partId);
  const components = assemblyTree?.subRows || [];
  const part = assemblyTree?.part;
  const iteration = part?.lastIteration;
  const revisionName = iteration?.name ?? "";

  const { data: currentUser } = useCurrentUser();

  const {
    mutate: approvePartRevisions,
    isLoading: approvingAssemblyIterations,
  } = useSavePartRevisions();
  const { mutate: registerPartComponent } = useRegisterPartComponent();

  const handleUpdateComponentIteration = useCallback(
    (assemblyId: number, component: PartComponent) => {
      if (!component.part || !component.iteration) return;
      registerPartComponent({
        partId: assemblyId,
        component: {
          name: component.name,
          part: component.part.id,
          isIgnored: false,
          iteration: component.iteration.number,
        },
      });
    },
    [registerPartComponent]
  );

  const { hasLockedComponentDrafts, componentsToApprove } =
    assemblyComponentsToApprove(components, currentUser?.id!);

  const handleSubmit = () => {
    part &&
      approvePartRevisions(
        {
          assembly: {
            ...part,
            lastIteration: { ...part.lastIteration, name: revisionName },
          },
          components: componentsToApprove,
        },
        { onSuccess: () => onClose() }
      );
  };

  const { data: revisions = [] } = usePartIterations(partId);

  const isRevisionNameUsed = revisions.some(
    (revisions) =>
      revisions.state !== "draft" &&
      revisions.name.toLocaleLowerCase() === revisionName.toLocaleLowerCase()
  );

  const columns: ExtendedColumn<ComponentTree>[] = useMemo(
    () => [
      {
        id: "name",
        accessor: "name",
        Header: tr.translate("generic.label.name"),
        Cell: (cell: CellProps<ComponentTree>) => (
          <ComponentNameCell
            {...cell}
            {...cell.row.getToggleRowExpandedProps()}
          />
        ),
      },
      {
        id: "iteration",
        accessor: (c) => c.iteration?.name,
        Header: tr.translate("generic.label.revision"),
        Cell: (cellProps: CellProps<ComponentTree>) => (
          <ComponentIterationCell
            {...cellProps}
            onUpdateComponentIteration={handleUpdateComponentIteration}
          />
        ),
      },
      {
        id: "action",
        canSort: false,
        width: 80,
        minWidth: 80,
        Header: tr.translate("generic.label.selected-action"),
        Cell: (cell: CellProps<ComponentTree>) => (
          <SelectedComponentAction
            component={cell.row.original}
            currentUserId={currentUser?.id ?? 0}
          />
        ),
      },
      {
        id: "lock",
        canSort: false,
        width: 100,
        minWidth: 100,
        accessor: (c: ComponentTree) => c.part?.lockOwner,
        Header: tr.translate("part.dialog.approve-asm-revision.part.lock"),
        Cell: (cell: CellProps<ComponentTree>) => (
          <PartLockOwner lockOwner={cell.value} />
        ),
      },
    ],
    [tr, currentUser, handleUpdateComponentIteration]
  );

  const tableData = useMemo(
    () => (assemblyTree ? [assemblyTree] : []),
    [assemblyTree]
  );

  return (
    <Dialog
      isOpen
      onClose={onClose}
      onSecondaryClick={onClose}
      onPrimaryClick={handleSubmit}
      icon="diagram-tree"
      className={styles.dialog}
      title={tr.translateAsString("part.dialog.approve-asm-revision.title", {
        type: part?.type,
      })}
      submitting={approvingAssemblyIterations}
      disablePrimary={hasLockedComponentDrafts || isRevisionNameUsed}
    >
      <Table
        isFetching={isLoading}
        data={tableData}
        columns={columns}
        className={styles.table}
        initialState={{ expandAll: true }}
      />
    </Dialog>
  );
}
