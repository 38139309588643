import Chart from "chart.js/auto";
import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import { Period } from "@aletiq/types";
import {
  createCustomGraphTooltip,
  graphBlue40,
  graphBlue50,
  graphBlue70,
  graphBlue80,
  graphGrey10,
  graphGrey100,
  graphGrey50,
  sum,
  useTranslations,
} from "../../../../util";
import ModelAnalysisCard from "../common/ModelAnalysisCard";
import { mkVolumeTooltip } from "../common/ModelAnalysisTooltip/ModelAnalysisTooltip";
import { useAnalysisModelsVolume } from "../hooks";
import {
  addOpacityToHexColor,
  getAbscissaLabels,
  resetOpactiyForHexColor,
} from "../util";

export default function ModelAnalysisVolume(props: {
  selectedModels: number[];
  period: Period;
}) {
  const { selectedModels, period } = props;
  const tr = useTranslations();

  const { data: volumeData, isLoading } = useAnalysisModelsVolume({
    selectedModels,
    period,
  });
  const graph = useRef<Chart>(null);

  const abscissaLabel = getAbscissaLabels(period);

  const totalCreated = sum(volumeData?.created ?? []);
  const totalEnded = sum(volumeData?.ended ?? []);
  const totalOngoing = sum(volumeData?.ongoing ?? []);

  const data = (canvas: any) => {
    // gradients must be declared as CanvasGradient objects :
    // https://www.chartjs.org/docs/latest/general/colors.html

    const ctx = canvas.getContext("2d");
    const height = 0.75 * ctx.canvas.scrollHeight || 200;
    const gradient = ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, graphBlue40);
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    return {
      labels: abscissaLabel,
      datasets: [
        {
          label: tr.translateAsString("analysis.workflow.volume.created", {
            nbr: totalCreated,
          }),
          data: volumeData?.created,
          backgroundColor: graphBlue50,
          borderColor: graphBlue50,
          order: 2,
        },
        {
          label: tr.translateAsString("analysis.workflow.volume.ended", {
            nbr: totalEnded,
          }),
          data: volumeData?.ended,
          backgroundColor: graphBlue80,
          borderColor: graphBlue80,
          order: 2,
        },
        {
          label: tr.translateAsString("analysis.workflow.volume.balance"),
          data: volumeData?.ongoing,
          backgroundColor: gradient,
          borderColor: graphBlue70,
          fill: "origin",
          pointRadius: 0,
          pointHitRadius: 10,
          cubicInterpolationMode: "monotone", // Smooth line
          borderWidth: 2,
          type: "line",
          order: 1,
        },
      ],
    };
  };

  /*
   * bar graph options: https://www.chartjs.org/docs/latest/charts/bar.html
   * legend options: https://www.chartjs.org/docs/latest/configuration/legend.html
   */

  const options = {
    responsive: true,
    maintainAspectRatio: false, // needed to set custom canvas sizing
    plugins: {
      legend: {
        align: "start",
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 15,
          padding: 24,
          color: graphGrey100,
          font: {
            size: 14,
          },
        },
        onClick: () => {}, // override the default behavior
        onHover: (
          _: React.MouseEvent,
          item: { datasetIndex: number },
          legend: Chart.Legend
        ) => {
          legend.chart.data.datasets.forEach((set: any, index: number) => {
            //if not balance gradient
            if (index < 2) {
              const color = addOpacityToHexColor(
                set.backgroundColor,
                index === item.datasetIndex
              );

              set.backgroundColor = color;
              set.borderColor = color;
            }
          });

          legend.chart.update();
        },
        onLeave: (
          _: React.MouseEvent,
          __: { index: number },
          legend: Chart.Legend
        ) => {
          legend.chart.data.datasets.forEach((set: any, index: number) => {
            //if not balance gradient
            if (index < 2) {
              const color = resetOpactiyForHexColor(set.backgroundColor);

              set.backgroundColor = color;
              set.borderColor = color;
            }
          });

          legend.chart.update();
        },
      },
      tooltip: {
        enabled: false,
        yAlign: "top",
        mode: "point", // allow tooltip on each dataset and not group the data
        external: createCustomGraphTooltip({
          graphId: "volume",
          tooltipComponent: mkVolumeTooltip(abscissaLabel),
        }),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        color: graphGrey50,
        grid: {
          drawBorder: false,
          color: graphGrey10,
        },
        ticks: {
          stepSize: 1,
        },
        title: {
          display: true,
          text: tr.translateAsString("analysis.workflow.volume.worfklows"),
          align: "end",
          color: graphGrey50,
        },
      },
      x: {
        color: graphGrey50,
        grid: {
          display: false,
        },
      },
    },
  };

  const isEmptyState =
    !isLoading &&
    totalOngoing === 0 &&
    totalCreated === 0 &&
    totalCreated === 0;

  return (
    <ModelAnalysisCard
      icon="grouped-bar-chart"
      title={tr.translateAsString("analysis.workflow.volume")}
      tooltipText={tr.translateAsString("analysis.workflow.volume.tooltip")}
      isLoading={isLoading}
      isEmptyState={isEmptyState}
    >
      <Bar ref={graph} data={data} options={options} type="bar" />
    </ModelAnalysisCard>
  );
}
