import React from "react";
import { CellProps } from "react-table";
import { AlignRight, Tag, Tooltip } from "../../../components";
import { TagPassportConfiguration } from "../../passport/common";
import { ResolvedPassport } from "../../passport/types";
import styles from "./ProjectPassports.module.scss";

export default function PassportConfigCell(props: CellProps<ResolvedPassport>) {
  const {
    row: { original: passport },
  } = props;

  const configValues = passport.config.flatMap((field) =>
    field.values.map((value) => ({ ...value, fieldId: field.id }))
  );

  const firstValue = configValues.length > 0 ? configValues[0] : null;
  const hiddenValues = configValues.length > 1 ? configValues.slice(1) : [];

  return (
    <>
      {firstValue && (
        <TagPassportConfiguration
          fieldId={firstValue.fieldId}
          fieldValue={firstValue}
          className={styles.visible_config}
        />
      )}
      <AlignRight />
      {hiddenValues.length > 0 && (
        <span>
          <Tooltip
            content={
              <>
                {hiddenValues.map((value) => (
                  <div key={`${value.fieldId}-${value.id}`}>{value.name}</div>
                ))}
              </>
            }
          >
            <Tag isDense>+{hiddenValues.length}</Tag>
          </Tooltip>
        </span>
      )}
    </>
  );
}
