import { Field } from "@aletiq/design-system";
import { OperationDocument } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  GenericStateTag,
  GenericStatusTag,
  HighlightedText,
} from "../../../components";
import { ChangeStatus, useTranslations } from "../../../util";
import { useDocument, useShowDocumentPreview } from "../../documents/hooks";
import { useDownloadRevision, useDownloadStampedRevision } from "./hooks";
import styles from "./OperationDocumentEditorView.module.scss";
import VisibleDocumentProperties from "./VisibleDocumentProperties";

export default function OperationDocumentView(props: {
  operationDocument: OperationDocument;
  status?: ChangeStatus;
  search: string;
}) {
  const { operationDocument, status = "unchanged", search } = props;
  const tr = useTranslations();

  const { data: document } = useDocument(operationDocument.document);

  const isDocumentVisible = document?.name.includes(search);
  const revision = operationDocument.revision.revision;

  const downloadDocumentRevision = useDownloadRevision();
  const downloadDocumentRevisionPreview = useDownloadStampedRevision();

  const handleDownload = () => {
    if (!revision) {
      return;
    }
    if (revision.pdfFile || revision.stampedPdfFile) {
      return downloadDocumentRevisionPreview(
        operationDocument.document,
        revision.id
      );
    }
    return downloadDocumentRevision(operationDocument.document, revision.id);
  };

  const showPreview = useShowDocumentPreview();
  const handleOpenPreview = () =>
    showPreview({
      documentId: operationDocument.document,
      revisionId: revision?.id,
    });

  if (!isDocumentVisible) {
    return null;
  }

  return (
    <Card
      icon="document"
      title={<HighlightedText text={document?.name} highlight={search} />}
      titleClassName={classNames(
        status === "deleted" && styles.document_deleted
      )}
      headerActions={
        <>
          <GenericStatusTag status={status} />
          <ButtonGroup isDense className={styles.button_group}>
            <Button
              isDense
              icon="eye-open"
              onClick={handleOpenPreview}
              isDisabled={
                status === "deleted" || revision?.state === "obsolete"
              }
            />
            <Button
              isDense
              icon="download"
              onClick={handleDownload}
              isDisabled={status === "deleted"}
            />
          </ButtonGroup>
        </>
      }
      className={styles.document_card}
    >
      {document && <VisibleDocumentProperties document={document} />}
      <Field
        inline
        minWidth={180}
        label={tr.translate("generic.label.revision")}
      >
        {operationDocument.revision.type === "last" ? (
          tr.translateAsString(
            "project.operation-bill.operation-document.revision.last-value",
            { value: revision?.revisionName ?? "" }
          )
        ) : revision ? (
          <GenericStateTag
            name={revision.revisionName}
            state={revision.state}
          />
        ) : null}
      </Field>
    </Card>
  );
}
