import { ProjectDefinitionDocument } from "@aletiq/types";
import React from "react";
import { Callout, GenericStateTag } from "../../../components";
import { useTranslations } from "../../../util";
import { useDocumentsById } from "../Operations/hooks";

export default function ProjectDefinitionValidationErrorMessage(props: {
  draftDocumentDefinitions: ProjectDefinitionDocument[];
  obsoleteDocumentDefinitions: ProjectDefinitionDocument[];
  isDraftIteration?: boolean;
}) {
  const {
    isDraftIteration,
    draftDocumentDefinitions,
    obsoleteDocumentDefinitions,
  } = props;

  const tr = useTranslations();

  const hasDraftDocuments = draftDocumentDefinitions.length > 0;
  const hasObsoleteDocuments = obsoleteDocumentDefinitions.length > 0;

  const draftDocuments = Object.values(
    useDocumentsById(draftDocumentDefinitions.map((d) => d.document))
  );

  const obsoleteDocuments = Object.values(
    useDocumentsById(obsoleteDocumentDefinitions.map((d) => d.document))
  );

  return (
    <>
      <div>{tr.translate("toaster.error.project.definition.validate")}</div>
      {isDraftIteration && (
        <Callout intent={"danger"} isDense>
          {tr.translate("toaster.error.project.definition.validate.draft-part")}
          <GenericStateTag state="draft" />
        </Callout>
      )}

      {hasDraftDocuments && (
        <Callout intent={"danger"} isDense>
          {tr.translate("toaster.error.project.definition.validate.draft-part")}
          <GenericStateTag state="draft" />
          <ul>
            {draftDocuments.map((d) => (
              <li>{d.name}</li>
            ))}
          </ul>
        </Callout>
      )}

      {hasObsoleteDocuments && (
        <Callout intent={"danger"} isDense>
          {tr.translate(
            "toaster.error.project.definition.validate.invalid-document"
          )}
          <GenericStateTag state="obsolete" />
          <ul>
            {obsoleteDocuments.map((d) => (
              <li>{d.name}</li>
            ))}
          </ul>
        </Callout>
      )}
    </>
  );
}
