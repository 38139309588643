import { PassportQueryParams } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { passportKeys } from "./queries";

export default function usePassports(params?: PassportQueryParams) {
  const api = useApi();
  const { data } = useQuery(
    passportKeys.list(params),
    async () => api.passport.listPassportsPage(params),
    {
      keepPreviousData: true,
    }
  );

  return { data: data?.list };
}
