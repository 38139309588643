import { Project } from "@aletiq/types";
import React from "react";
import { MenuDivider, Subtitle } from "../../../../components";
import TooManyResultsCallout from "../../../../components/callouts";
import FilterMenu from "../../../../components/filter/FilterMenu";
import { useTranslations } from "../../../../util";
import styles from "./EditProductBomDialog.module.scss";
import ProductSelectItem from "./ProductSelectItem";

export default function ProductSelectMenu(props: {
  search: string;
  linkedProducts: Project[];
  libraryProducts: Project[];
  otherProducts: Project[];
  hasTooManyResults?: boolean;
  onAssign: (product: Project) => void;
  onSearchChange: (search: string) => void;
}) {
  const {
    search,
    linkedProducts,
    libraryProducts,
    otherProducts,
    onAssign,
    onSearchChange,
    hasTooManyResults = false,
  } = props;
  const tr = useTranslations();
  return (
    <FilterMenu
      search={search}
      onSearchChange={onSearchChange}
      className={styles.menu}
    >
      {hasTooManyResults && <TooManyResultsCallout />}

      <ProductSelectSection
        products={linkedProducts}
        title={tr.translateAsString(
          "project.dialog.link.product.bom.linked-product"
        )}
        onAssign={onAssign}
        search={search}
      />

      <ProductSelectSection
        products={libraryProducts}
        title={tr.translateAsString(
          "project.dialog.link.product.bom.library-product"
        )}
        onAssign={onAssign}
        search={search}
        hasDivider
      />

      <ProductSelectSection
        products={otherProducts}
        title={tr.translateAsString(
          "project.dialog.link.product.bom.other-product"
        )}
        onAssign={onAssign}
        search={search}
        hasDivider
      />
    </FilterMenu>
  );
}

function ProductSelectSection(props: {
  hasDivider?: boolean;
  products: Project[];
  title: string;
  search: string;
  onAssign: (product: Project) => void;
}) {
  const { products, hasDivider, title, search, onAssign } = props;
  return (
    <>
      {products.length > 0 && (
        <>
          <Subtitle text={title} />
          {hasDivider && <MenuDivider />}
        </>
      )}

      {products.map((product) => (
        <ProductSelectItem
          key={product.id}
          product={product}
          onSelect={onAssign}
          isSelected={false}
          search={search}
        />
      ))}
    </>
  );
}
