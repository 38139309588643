import { EntityId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { commentKeys } from "./queries";

export default function useCreateComment(entityId: EntityId) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (values: { content: string; mentions: string[] }) =>
      api.comment.createComment(entityId, values.content, values.mentions),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(commentKeys.byEntity(entityId)),
    }
  );
}
