import { Project } from "@aletiq/types";
import { useQueries } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useProjectsByName(
  projectNames: string[]
): Record<string, Project> {
  const api = useApi();
  const projectsQueries = useQueries(
    projectNames.map((name) => ({
      queryKey: projectKeys.byName(name),
      queryFn: () => api.project.getProjectByName(name),
    }))
  );

  const result: Record<string, Project> = {};
  projectNames.forEach((name) => {
    const document = projectsQueries.find(
      (q) => q.data && q.data.name === name
    );
    if (document && document.data) {
      result[name] = document.data;
    }
  });
  return result;
}
