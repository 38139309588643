import { Button, Callout } from "@aletiq/design-system";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useTranslations } from "../util";

export function AccessDeniedCallout() {
  const authO = useAuth0();
  const tr = useTranslations();
  return (
    <Callout
      intent="danger"
      icon="ban-circle"
      title="Access denied"
      style={{ margin: "10px auto 0px auto", width: "500px" }}
    >
      <Button onClick={() => authO.logout()}>
        {tr.translate("navbar.logout")}
      </Button>
    </Callout>
  );
}
