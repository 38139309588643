import { ProcessCompleteNotification } from "@aletiq/types";
import React from "react";
import { AlignRight, Button } from "../../../components";
import ActivityWorkflowLink from "../../../components/activities/links/ActivityWorkflowLink";
import { useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { useArchiveProcess, useProcess } from "../../tasks/hooks";
import NotificationCard from "../NotificationCard";
import styles from "./NotificationsViews.module.scss";

export default function WorkflowCompleteNotificationView(props: {
  notification: ProcessCompleteNotification;
  isNew: boolean;
  onFollowLink: () => void;
}) {
  const {
    isNew,
    notification: {
      id,
      meta: { read, date, content },
    },
    onFollowLink,
  } = props;
  const workflowId = content.process;
  const tr = useTranslations();

  const { data: workflow } = useProcess(workflowId);
  const { mutate: archiveWorkflow, isLoading } = useArchiveProcess();
  const canArchiveWorkflow = useHasPermission("update:own-workflows");

  const isArchiveButtonDisabled =
    !canArchiveWorkflow || workflow?.isArchived || workflow?.isDeleted;

  const handleArchiveWorkflow = (
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    event.stopPropagation();
    archiveWorkflow(workflowId);
  };

  return (
    <NotificationCard
      isRead={read}
      isNew={isNew}
      notificationId={id}
      date={date}
    >
      {tr.translate("notification.workflow.done", {
        workflow: workflow ? (
          <ActivityWorkflowLink workflow={workflow} onClick={onFollowLink} />
        ) : null,
      })}
      <div className={styles.button_wrapper}>
        <AlignRight />
        <Button
          isDense
          intent="array_default"
          icon="archive"
          isDisabled={isArchiveButtonDisabled}
          isLoading={isLoading}
          onClick={handleArchiveWorkflow}
        >
          <span className={styles.button_text}>
            {tr.translate("task.action.archive")}
          </span>
        </Button>
      </div>
    </NotificationCard>
  );
}
