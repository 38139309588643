import { Property, PropertyValue, PropertyValueUpdate } from "@aletiq/types";
import React, { useState } from "react";
import {
  AlertPopover,
  ButtonSP,
  EditCustomPropertyField,
  Tooltip,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import styles from "./DocumentUploadWizard.module.scss";

export default function BatchSelectPropertyValue(props: {
  property: Property;
  isDisabled?: boolean;
  onSubmit: (value: PropertyValueUpdate) => void;
}) {
  const { isDisabled, property, onSubmit } = props;
  const tr = useTranslations();

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<PropertyValue>(null);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    // reset value
    setValue(null);
  };

  const handleSubmitValue = () => {
    onSubmit({ property: property.name, value });
    handleClose();
  };

  return (
    <div className={styles.batch_action}>
      {property.label}
      <AlertPopover
        isDense
        isOpen={isOpen}
        onClose={handleClose}
        disabled={isDisabled}
        disablePrimary={isDisabled}
        onPrimaryClick={handleSubmitValue}
        primaryText={tr.translateAsString(
          "storage.dialog.upload-file.apply-all"
        )}
        content={
          <EditCustomPropertyField
            isDense
            property={property}
            propertyValue={value}
            onEditValue={setValue}
          />
        }
      >
        <Tooltip
          content={tr.translate(
            "storage.dialog.upload-file.action.batch.property",
            { property: property.label }
          )}
          position="top"
        >
          <EditButton isDisabled={isDisabled} onClick={handleOpen} />
        </Tooltip>
      </AlertPopover>
    </div>
  );
}

export function EditButton(props: {
  onClick?: () => void;
  isDisabled?: boolean;
}) {
  const { onClick, isDisabled } = props;

  return (
    <ButtonSP
      isDense
      onClick={onClick}
      isDisabled={isDisabled}
      icon="edit"
      color="primary"
      view="flat"
    />
  );
}
