import { ToolingRequirement } from "@aletiq/types";
import React, { useCallback, useMemo } from "react";
import { CellProps } from "react-table";
import {
  AlertPopover,
  Button,
  EditableNumberCell,
  EditableTextCell,
  ExtendedColumn,
  ProjectNameLink,
} from "../../../components";
import { useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import {
  useDeleteToolingRequirement,
  useUpdateToolingRequirement,
} from "../hooks";
import styles from "./ProjectArticles.module.scss";

export type ToolingRequirementColumn = keyof ToolingRequirement | "name";

export default function useArticleColumns(toolId: number) {
  const tr = useTranslations();

  const canUpdateProduct = useHasPermission("update:projects");
  const { mutate: removeArticle } = useDeleteToolingRequirement();
  const { mutate: updateArticle } = useUpdateToolingRequirement();

  const handleRemoveArticleFromTool = useCallback(
    ({ article, id }: ToolingRequirement) => {
      removeArticle({ articleId: article, requirementId: id });
    },
    [removeArticle]
  );

  const handleUpdateArticle = useCallback(
    ({ quantity, comment, id, article }: ToolingRequirement) => {
      updateArticle({
        articleId: article,
        requirementId: id,
        spec: { tool: toolId, quantity, comment },
      });
    },
    [toolId, updateArticle]
  );

  return useMemo<
    ExtendedColumn<ToolingRequirement, ToolingRequirementColumn>[]
  >(
    () => [
      {
        Header: tr.translate("generic.label.name"),
        id: "article",
        accessor: "article",
        Cell: (cellProps: CellProps<ToolingRequirement>) => (
          <ProjectNameLink
            projectId={cellProps.row.original.article}
            search={cellProps.searchString}
            hasIcon
          />
        ),
        hoverButton: (component, className?: string) => (
          <div className={className}>
            <AlertPopover
              onPrimaryClick={() => handleRemoveArticleFromTool(component)}
              title={tr.translateAsString("project.tooling.remove")}
              content={tr.translate("project.tooling.remove.description")}
              position="bottom-right"
              isDense
            >
              <Button
                isDense
                intent="array_primary"
                icon="trash"
                isDisabled={!canUpdateProduct}
                className={styles.hover_button}
              />
            </AlertPopover>
          </div>
        ),
      },
      {
        Header: tr.translate("generic.label.quantity"),
        id: "quantity",
        accessor: "quantity",
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ToolingRequirement>) => {
          const article = cellProps.row.original;
          return (
            <EditableNumberCell
              value={article.quantity}
              onConfirmNewValue={(quantity) =>
                handleUpdateArticle({ ...article, quantity })
              }
            />
          );
        },
      },
      {
        Header: tr.translate("generic.label.comment"),
        id: "comment",
        accessor: "comment",
        fullWidthContent: true,
        Cell: (cellProps: CellProps<ToolingRequirement>) => {
          const article = cellProps.row.original;
          return (
            <EditableTextCell
              value={article.comment}
              highlight={cellProps.searchString}
              onConfirmNewValue={(comment) =>
                handleUpdateArticle({ ...article, comment })
              }
            />
          );
        },
      },
    ],
    [tr, canUpdateProduct, handleUpdateArticle, handleRemoveArticleFromTool]
  );
}
