import React from "react";
import { AlertPopover, Button } from "../../../../../components";
import { useTranslations } from "../../../../../util";
import styles from "../ShareDocumentsDialog.module.scss";

export default function ShareDocumentsRow(props: {
  title: string;
  deleteAlertText: string;
  count: number;
  total: number;
  icon: React.ReactNode;
  onAddAll: () => void;
  onRemoveAll: () => void;
}) {
  const { count, total, title, icon, deleteAlertText, onAddAll, onRemoveAll } =
    props;
  const tr = useTranslations();

  return (
    <div className={styles.row}>
      <div className={styles.left}>
        <div className={styles.icons}>{icon}</div>
        <div className={styles.infos}>
          <div className={styles.description}>{title}</div>
          <div className={styles.count}>
            {tr.translate("document.dialog.shared-documents.proportion", {
              count,
              total,
            })}
          </div>
        </div>
      </div>
      <div>
        <Button
          className={styles.buttonSpacing}
          isDisabled={count === total}
          intent="outlined"
          onClick={onAddAll}
        >
          {tr.translate("document.dialog.shared-documents.apply-all")}
        </Button>
        <AlertPopover
          isDense
          onPrimaryClick={onRemoveAll}
          content={deleteAlertText}
        >
          <Button intent="outlined">
            {tr.translate("document.dialog.share.button.remove")}
          </Button>
        </AlertPopover>
      </div>
    </div>
  );
}
