import { Part } from "@aletiq/types";
import React from "react";
import {
  EditablePropertyInput,
  Field,
  OpenableImage,
} from "../../../components";
import { useHasPermission } from "../../../hooks";
import { useTranslations } from "../../../util";
import { usePartImage, useUpdatePartDescription } from "../hooks";
import styles from "./StandardPartDetails.module.scss";

export default function StandardPartProperties(props: { part: Part }) {
  const { part } = props;
  const tr = useTranslations();

  const canUpdateParts = useHasPermission("update:parts");

  const { data: image } = usePartImage(part.id);
  const { mutate: updatePartDescription } = useUpdatePartDescription();

  const handleDescription = (description: string) => {
    updatePartDescription({ partId: part.id, description });
  };

  return (
    <div className={styles.properties}>
      {image && (
        <OpenableImage
          imgUrl={URL.createObjectURL(image)}
          className={styles.img}
          alt={part.name}
        />
      )}
      <div className={styles.properties_inner}>
        <Field inline={!image} label={tr.translate("part.details.format")}>
          <img alt={part.format} src={`/assets/${part.format}.png`} />
        </Field>
        <EditablePropertyInput
          value={part?.description ?? ""}
          isEditable={canUpdateParts}
          label={tr.translateAsString("generic.label.description")}
          placeholder={tr.translateAsString("generic.action.modify")}
          emptyState={tr.translateAsString("generic.label.none")}
          onSubmit={handleDescription}
          inline={!image}
        />
      </div>
    </div>
  );
}
