import { CardList } from "@aletiq/design-system";
import { Process, Task } from "@aletiq/types";
import { Spinner } from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useHandleOnScreenVisible,
  useNavigate,
  useWorkflowId,
} from "../../../hooks";
import { WorkflowView } from "./WorkflowView";

const SIZE_PAGE = 25;

export default function WorkflowListOngoing(props: {
  workflows: Process[];
  openWorkflows: number[];
  toggleFoldWorkflow: (value: number) => void;
  search?: string;
}) {
  const { workflows, openWorkflows, toggleFoldWorkflow, search } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const getIndexFromHash = useCallback(
    () =>
      workflows.findIndex(
        (pro) => pro.id === parseInt(location.hash.slice(1), 10)
      ),
    [workflows, location]
  );

  const [visibleRow, setVisibleRow] = useState(
    location.hash ? getIndexFromHash() + 1 : SIZE_PAGE
  );

  useEffect(() => {
    if (getIndexFromHash() > visibleRow) {
      setVisibleRow(getIndexFromHash() + 1);
    }
  }, [workflows, visibleRow, getIndexFromHash]);

  const activeProcess = useWorkflowId();
  const handleSetActiveWorkflow = (workflowId: number) => () => {
    navigate({ panel: `workflowId-${workflowId}`, hash: null });
  };

  const containerRef = useHandleOnScreenVisible(
    {
      root: document.getElementsByTagName("body")[0],
      rootMargin: "400px",
      threshold: 0,
    },
    () => {
      setVisibleRow((visibleRow) => visibleRow + SIZE_PAGE);
    }
  );

  return (
    <CardList>
      {workflows.slice(0, visibleRow).map((workflow) => (
        <ProcessViewMemo
          isFolded={!openWorkflows.includes(workflow.id)}
          toggleFold={toggleFoldWorkflow}
          isSelected={activeProcess === workflow.id}
          key={workflow.id}
          tasks={workflow.tasks}
          process={workflow}
          setActive={handleSetActiveWorkflow(workflow.id)}
          search={search}
        />
      ))}
      {visibleRow < workflows.length && (
        <div ref={containerRef}>
          <Spinner></Spinner>
        </div>
      )}
    </CardList>
  );
}

const ProcessViewMemo = React.memo(
  (props: {
    isFolded: boolean;
    isSelected: boolean;
    tasks: Task[];
    process: Process;
    toggleFold: (value: number) => void;
    setActive: () => void;
    search?: string;
  }) => {
    const {
      isFolded,
      isSelected,
      toggleFold,
      tasks,
      process,
      setActive,
      search,
    } = props;

    return (
      <WorkflowView
        isFolded={isFolded}
        isSelected={isSelected}
        tasks={tasks}
        workflow={process}
        toggleFold={toggleFold}
        setActive={setActive}
        search={search}
      />
    );
  }
);
