import { OperationTool } from "@aletiq/types";
import { useProductVersions } from "../../hooks";

export default function useToolVersion(tool: OperationTool) {
  const { data: versions } = useProductVersions(tool.tool);

  const version =
    //find specific version
    tool.version?.type === "specific"
      ? versions?.find(
          (v) =>
            tool.version?.type === "specific" && v.id === tool.version.version
        )
      : //find last approved version
      tool.version?.type === "last"
      ? versions?.find((v) => tool.version?.type === "last" && !v.isDraft)
      : undefined;

  return version;
}
