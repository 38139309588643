import { useQueries } from "react-query";
import useApi from "../../../app/useApi";
import { documentQueries } from "./queries";

export default function useDocumentsProjects(documentIds: number[]) {
  const api = useApi();

  const projectsQueries = useQueries(
    documentIds.map((documentId) =>
      documentQueries.allProjects(api, documentId)
    )
  );

  const isLoading = projectsQueries.some((query) => query.isLoading);

  const documentsProjects = isLoading
    ? []
    : projectsQueries.reduce((acc, res, index) => {
        if (res.data === undefined) return acc;

        acc.push({
          id: documentIds[index],
          projects: res.data,
        });
        return acc;
      }, [] as { id: number; projects: number[] }[]);

  return {
    data: documentsProjects,
    isLoading,
  };
}
