import { UserRole } from "@aletiq/types";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import React, { Fragment, useState } from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  SearchInput,
  Tooltip,
  UserTypeFilter,
} from "../../../components";
import { useHasPermission, useUsers } from "../../../hooks";
import { isInString, sortByString, useTranslations } from "../../../util";
import { CreateUserDialog } from "./dialogs";
import UserRow from "./UserRow";
import styles from "./Users.module.scss";

export default function UserList() {
  const tr = useTranslations();
  const [search, setSearch] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState<UserRole[]>([]);
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);

  const withEmail = true;
  const withDeactivated = true;
  const { data: users = [], isLoading } = useUsers(withEmail, withDeactivated);
  const canReadEmail = useHasPermission("read:user-emails");
  const canReadDeactivated = useHasPermission("read:deactivated-users");

  if (!canReadEmail || !canReadDeactivated) {
    return <></>;
  }

  const handleToggleDialog = () =>
    setShowCreateUserDialog(!showCreateUserDialog);

  const handleClear = () => {
    setUserTypeFilter([]);
  };

  const handleUserTypeFilter = (roles: UserRole[]) => {
    setUserTypeFilter(roles);
  };

  const clientUsers = users.filter((user) => !user.isAletiq);

  let usersWithType = clientUsers;
  if (userTypeFilter.length > 0) {
    usersWithType = clientUsers.filter((user) =>
      userTypeFilter.includes(user.role)
    );
  }

  let searchedUsers = usersWithType;
  if (search !== "") {
    searchedUsers = usersWithType.filter(
      (user) =>
        isInString(user.displayName, search) ||
        (user.email && isInString(user.email, search))
    );
  }

  const sortedUsers = sortByString(searchedUsers, (user) => user.displayName);

  return (
    <Fragment>
      <ActionBar>
        <Tooltip
          content={tr.translate("admin.users.search")}
          position="top-left"
        >
          <SearchInput
            isDense
            className={styles.search}
            value={search}
            onChange={setSearch}
          />
        </Tooltip>
        <UserTypeFilter
          selected={userTypeFilter}
          onClear={handleClear}
          onSelect={handleUserTypeFilter}
        />
        <AlignRight />
        <Button
          intent="secondary"
          icon="plus"
          isDense
          onClick={handleToggleDialog}
        >
          {tr.translate("admin.users.add-user")}
        </Button>
      </ActionBar>
      <div className={styles.list}>
        {isLoading && <Spinner size={SpinnerSize.LARGE} />}
        {!isLoading &&
          sortedUsers.map((user) => (
            <UserRow user={user} key={user.id} search={search} />
          ))}
      </div>
      {showCreateUserDialog && (
        <CreateUserDialog onClose={handleToggleDialog} />
      )}
    </Fragment>
  );
}
