import { Tenant } from "@aletiq/types";
import React, { useState } from "react";
import {
  ActionBar,
  AlignRight,
  Button,
  ExtendedColumn,
  PageContents,
  PageTitle,
  Table,
} from "../../components";
import { useNavigate } from "../../hooks";
import CreateTenantDialog from "./CreateTenantDialog";
import { useTenants } from "./hooks";

const columns: ExtendedColumn<Tenant>[] = [
  {
    id: "id",
    Header: "Id",
    accessor: "id",
  },
  { id: "name", Header: "Name", accessor: "name" },
];

export default function AletiqAdminTenants() {
  const { data, isLoading } = useTenants();

  const navigate = useNavigate();

  const [dialog, setDialog] = useState<"create" | null>(null);

  return (
    <PageContents domTitle={"Aletiq Tenants"}>
      <PageTitle>List of all Tenants</PageTitle>
      <ActionBar>
        <AlignRight />
        <Button
          isDense
          icon="plus"
          intent="secondary"
          onClick={() => setDialog("create")}
        >
          Create a tenant
        </Button>
      </ActionBar>
      <Table
        columns={columns}
        isFetching={isLoading}
        data={data ?? []}
        openRowOptions={{
          onOpen: (tenant) =>
            navigate({ path: `/aletiq-admin/${tenant.id}/users` }),
        }}
      ></Table>

      {dialog ? <CreateTenantDialog onClose={() => setDialog(null)} /> : null}
    </PageContents>
  );
}
