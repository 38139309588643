import { Dialog } from "@aletiq/design-system";
import React, { useState } from "react";
import {
  AletiqPropertyOption,
  EditAletiqProperty,
} from "../../../../../components";
import {
  propertyOptionsDifference,
  useTranslations,
} from "../../../../../util";
import { useUpdateAndCreatePassportState } from "../../../../passport/hooks";
import styles from "./Dialogs.module.scss";

export default function EditPassportStateDialog(props: {
  label: string;
  description: string;
  options: AletiqPropertyOption[];
  onClose: () => void;
}) {
  const { label, description, options, onClose } = props;
  const tr = useTranslations();

  const [updatedOptions, setOptions] =
    useState<AletiqPropertyOption[]>(options);
  const { mutate: createAndUpdatePassportState, isLoading } =
    useUpdateAndCreatePassportState();

  const onSubmit = () => {
    const { optionsToAdd, optionsToUpdate } = propertyOptionsDifference(
      options,
      updatedOptions
    );
    createAndUpdatePassportState(
      { statesToAdd: optionsToAdd, statesToUpdate: optionsToUpdate },
      {
        onSettled: () => {
          onClose();
        },
      }
    );
  };

  return (
    <Dialog
      isOpen
      icon="edit"
      className={styles.dialog}
      onClose={onClose}
      title={tr.translateAsString("passport.dialog-edit.property.title", {
        name: label,
      })}
      onPrimaryClick={onSubmit}
      submitting={isLoading}
    >
      <EditAletiqProperty
        icon="id-number"
        label={label}
        description={description}
        options={updatedOptions.filter((o) => !o.deleted)}
        onEditOptions={setOptions}
      />
    </Dialog>
  );
}
