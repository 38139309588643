import { ButtonSP, Field, Icon, Input } from "@aletiq/design-system";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslations } from "../../../../../util";
import styles from "./PasswordCreator.module.scss";
export default function PasswordCreator(props: {
  value: string;
  onSetValue: (value: string, isPasswordValid: boolean) => void;
  valueConfirm?: string;
  onSetConfirmValue?: (value: string) => void;
  isMandatory?: boolean;
  label?: string;
  className?: string;
  containerClassName?: string;
  autoFocus?: boolean;
}) {
  const {
    value,
    valueConfirm,
    isMandatory = false,
    className = "",
    label,
    onSetValue,
    onSetConfirmValue,
    containerClassName,
    autoFocus = false,
  } = props;

  const tr = useTranslations();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const regexLowerCase = new RegExp("[a-z]");
  const regexUpperCase = new RegExp("[A-Z]");
  const regexNumber = new RegExp("[0-9]");

  const haveLowerCase = value.search(regexLowerCase) !== -1;
  const haveUpperCase = value.search(regexUpperCase) !== -1;
  const haveNumber = value.search(regexNumber) !== -1;
  const isLongEnough = value.length >= 8;
  const passwordsAreDifferent = valueConfirm !== "" && value !== valueConfirm;

  const handleValue = (value: string) => {
    const haveLowerCase_ = value.search(regexLowerCase) !== -1;
    const haveUpperCase_ = value.search(regexUpperCase) !== -1;
    const haveNumber_ = value.search(regexNumber) !== -1;
    const isLongEnough_ = value.length >= 8;

    onSetValue(
      value,
      haveLowerCase_ && haveUpperCase_ && haveNumber_ && isLongEnough_
    );
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const toggleShowConfirmPassword = () => setShowConfirm(!showConfirm);

  return (
    <>
      <Field
        label={label ?? tr.translate("generic.label.password")}
        className={containerClassName}
        isMandatory={isMandatory}
      >
        <Input
          fill
          autoFocus={autoFocus ?? false}
          value={value}
          placeholder={tr.translateAsString("generic.label.password") + "..."}
          onChange={handleValue}
          className={className}
          type={showPassword ? "text" : "password"}
          rightElement={
            <ButtonSP
              view="flat"
              color="primary"
              icon={showPassword ? "eye-open" : "eye-off"}
              onClick={toggleShowPassword}
              className={styles.eye_button}
            />
          }
        />
      </Field>
      {onSetConfirmValue && (
        <Field
          label={tr.translate("admin.users.password.confirm")}
          className={containerClassName}
          isMandatory={isMandatory}
        >
          <Input
            fill
            autoFocus={false}
            value={valueConfirm ?? ""}
            placeholder={tr.translateAsString("generic.label.password") + "..."}
            onChange={onSetConfirmValue}
            className={className}
            type={showConfirm ? "text" : "password"}
            hasError={passwordsAreDifferent}
            errorHelperText={tr.translateAsString(
              "admin.users.password.confirm.warning"
            )}
            rightElement={
              <ButtonSP
                view="flat"
                color="primary"
                icon={showConfirm ? "eye-open" : "eye-off"}
                onClick={toggleShowConfirmPassword}
                className={styles.eye_button}
              />
            }
          />
        </Field>
      )}
      <div className={styles.helperContainer}>
        {tr.translate("admin.users.password.helper-info")}
        <div className={styles.row}>
          <Icon
            inline
            className={classNames(!haveLowerCase && styles.invalid)}
            icon={haveLowerCase ? "tick" : "cross"}
            intent={haveLowerCase ? "success" : "none"}
          />
          {tr.translate("admin.users.password.helper-lower-case")}
        </div>
        <div className={styles.row}>
          <Icon
            inline
            className={classNames(!haveUpperCase && styles.invalid)}
            icon={haveUpperCase ? "tick" : "cross"}
            intent={haveUpperCase ? "success" : "none"}
          />
          {tr.translate("admin.users.password.helper-upper-case")}
        </div>
        <div className={styles.row}>
          <Icon
            inline
            className={classNames(!haveNumber && styles.invalid)}
            icon={haveNumber ? "tick" : "cross"}
            intent={haveNumber ? "success" : "none"}
          />
          {tr.translate("admin.users.password.helper-number")}
        </div>
        <div className={styles.row}>
          <Icon
            inline
            className={classNames(!isLongEnough && styles.invalid)}
            icon={isLongEnough ? "tick" : "cross"}
            intent={isLongEnough ? "success" : "none"}
          />
          {tr.translate("admin.users.password.helper-length")}
        </div>
      </div>
    </>
  );
}
