import classNames from "classnames";
import React from "react";
import { Input } from "../../../components";
import { useTranslations } from "../../../util";
import styles from "./OperationsInfoForm.module.scss";

type OperationInfo = { number: string; name: string };

export function OperationsInfoForm(props: {
  operations: OperationInfo[];
  handleChangeNumber: (index: number) => (value: string) => void;
  handleChangeName: (index: number) => (value: string) => void;
}) {
  const { operations, handleChangeNumber, handleChangeName } = props;
  const tr = useTranslations();

  const isValid = (op: OperationInfo) =>
    op.number === "" || !Number.isNaN(Number.parseInt(op.number));

  return (
    <div className={styles.table}>
      <div className={classNames(styles.header, styles.row)}>
        <div className={styles.number_col}>
          {tr.translate("project.operation-bill.add-operations.number-label")}
        </div>
        <div className={styles.name_col}>
          {tr.translate("project.operation-bill.add-operations.name-label")}
        </div>
      </div>
      {operations.map((op, index) => (
        <div className={styles.row}>
          <Input
            hasError={!isValid(op)}
            errorHelperText={tr.translateAsString(
              "project.operation-bill.add-operations.number-error-caption"
            )}
            className={styles.number_col}
            onChange={handleChangeNumber(index)}
            value={op.number}
          />
          <Input
            fill
            autoFocus={false}
            className={styles.name_col}
            onChange={handleChangeName(index)}
            value={op.name}
          />
        </div>
      ))}
    </div>
  );
}
