import { MenuSP, Popover2 } from "@aletiq/design-system";
import React, { ReactNode } from "react";
import NavigationButton from "../NavigationButton";

export default function NavigationMenu(props: {
  label: string;
  children: ReactNode;
  path?: string | string[];
}) {
  const { label, path, children } = props;
  return (
    <Popover2
      content={
        <MenuSP view="raised" color="primary" isDense>
          {children}
        </MenuSP>
      }
      minimal
      position="bottom-left"
    >
      <NavigationButton path={path} rightIcon="caret-down">
        {label}
      </NavigationButton>
    </Popover2>
  );
}
