import { FormattedDate } from "@aletiq/translation";
import React from "react";
import { toDate } from "../../util";

export default function DateWithSpaces(props: { date: string }) {
  const { date } = props;

  return (
    <FormattedDate value={toDate(date)}>
      {(formattedDate: string) => formattedDate.split("/").join(" / ")}
    </FormattedDate>
  );
}
