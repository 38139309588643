import { Property, PropertyValue } from "@aletiq/types";
import React from "react";
import CustomDateField from "./CustomDateField";
import { CustomMultiSelectField } from "./CustomMultiSelectField";
import { CustomNumberField } from "./CustomNumberField";
import { CustomSingleSelectField } from "./CustomSingleSelectField";
import { CustomTextField } from "./CustomTextField";
import CustomUserField from "./CustomUserField";

export default function PropertyField(props: {
  property: Property;
  value: PropertyValue | undefined;
  onEditValue?: (propertyName: string, value: PropertyValue) => void;
  canUpdate: boolean;
  isLoading?: boolean;
  hasDescriptionTooltip?: boolean;
  search?: string;
  minWidth?: number;
}) {
  const {
    property,
    value,
    canUpdate,
    onEditValue,
    isLoading,
    search,
    hasDescriptionTooltip,
    minWidth,
  } = props;

  const handleSubmit = (value: PropertyValue) => {
    onEditValue && onEditValue(property.name, value);
  };

  switch (property.type) {
    case "enum":
      return (
        <CustomSingleSelectField
          property={property}
          propertyValue={value as { type: "enum"; value: string }}
          canEdit={canUpdate}
          onSubmit={handleSubmit}
          isSubmitting={isLoading}
          hasDescriptionTooltip={hasDescriptionTooltip}
          search={search}
          minWidth={minWidth}
        />
      );
    case "multi-enum":
      return (
        <CustomMultiSelectField
          property={property}
          propertyValue={value as { type: "multi-enum"; value: string[] }}
          canEdit={canUpdate}
          onSubmit={handleSubmit}
          hasDescriptionTooltip={hasDescriptionTooltip}
          search={search}
          minWidth={minWidth}
        />
      );
    case "string":
      return (
        <CustomTextField
          property={property}
          propertyValue={value as { type: "string"; value: string }}
          canEdit={canUpdate}
          onSubmit={handleSubmit}
          isSubmitting={isLoading}
          hasDescriptionTooltip={hasDescriptionTooltip}
          search={search}
          minWidth={minWidth}
        />
      );
    case "number":
      return (
        <CustomNumberField
          property={property}
          propertyValue={value as { type: "number"; value: number }}
          canEdit={canUpdate}
          onSubmit={handleSubmit}
          hasDescriptionTooltip={hasDescriptionTooltip}
          search={search}
          minWidth={minWidth}
        />
      );
    case "user":
      return (
        <CustomUserField
          property={property}
          propertyValue={value as { type: "user"; value: number[] }}
          canEdit={canUpdate}
          onSubmit={handleSubmit}
          hasDescriptionTooltip={hasDescriptionTooltip}
          search={search}
          minWidth={minWidth}
        />
      );
    case "date":
      return (
        <CustomDateField
          property={property}
          propertyValue={value as { type: "date"; value: string }}
          canEdit={canUpdate}
          onSubmit={handleSubmit}
          hasDescriptionTooltip={hasDescriptionTooltip}
          search={search}
          minWidth={minWidth}
        />
      );
    default:
      return <></>;
  }
}
