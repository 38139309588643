import { Part } from "@aletiq/types";
import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import {
  ANALYTICS_PARTS_COUNT,
  ANALYTICS_PARTS_CREATED,
  ANALYTICS_PARTS_IGNORED,
  ANALYTICS_PARTS_STANDARD_COUNT,
  ANALYTICS_PARTS_UPDATED,
  useAnalytics,
} from "../../../../analytics";
import useApi from "../../../../app/useApi";
import { Dialog } from "../../../../components";
import { partitionWith, useTranslations } from "../../../../util";
import groupPartFiles from "./services/groupPartFiles";
import useSubmitParts from "./services/useSubmitParts";
import { PartState, StandardPartState, WizardScreenStatus } from "./types";
import UploadedPartsList from "./UploadedPartsList";

export default function UploadPartsWizard(props: {
  files: File[];
  projectId?: number;
  onClose: () => void;
}) {
  const { files, projectId, onClose } = props;

  const tr = useTranslations();
  const analytics = useAnalytics();
  const api = useApi();

  const [stage, setStage] = useState<WizardScreenStatus>("init");
  const [unsupported, setUnsupported] = useState<File[]>([]);
  const [standardParts, setStandardParts] = useState<StandardPartState[]>([]);
  const [lockedParts, setLockedParts] = useState<Part[]>([]);
  const [partStates, setPartStates] = useState<PartState[]>([]);

  const isLoading = stage !== "filling-form";

  //non-ignored parts must have a name and a revision name
  const isSubmitDisabled = partStates.some(
    (p) =>
      p.partInfo.action.type !== "ignore" &&
      (!p.partInfo.revision.name || !p.partInfo.name)
  );

  useEffect(() => {
    let cancel = false;
    if (stage === "init") {
      (async () => {
        const sortedFiles = await groupPartFiles(api, files);
        if (cancel) return;
        setStage("filling-form");
        setPartStates(sortedFiles.valid);
        setUnsupported(sortedFiles.unsupported);
        setStandardParts(sortedFiles.standard);
        setLockedParts(sortedFiles.locked);
      })();
    }

    return () => {
      cancel = true;
    };
  }, [api, files, stage]);

  const submitParts = useSubmitParts(partStates, standardParts, projectId);

  const handleSubmit = () => {
    const [createdParts, updatedParts, ignoredParts] = partitionWith(
      partStates,
      (p) => p.partInfo.action.type === "create",
      (p) => p.partInfo.action.type === "update",
      (p) => p.partInfo.action.type === "ignore"
    );

    const [updatedStandardParts, ignoredStandardParts] = partitionWith(
      standardParts,
      (p) => p.action === "update",
      (p) => p.action === "ignore"
    );

    if (createdParts.length > 0) {
      analytics.track(ANALYTICS_PARTS_CREATED, {
        [ANALYTICS_PARTS_COUNT]: createdParts.length,
      });
    }

    if (updatedParts.length > 0 || updatedStandardParts.length > 0) {
      analytics.track(ANALYTICS_PARTS_UPDATED, {
        [ANALYTICS_PARTS_COUNT]: updatedParts.length,
        [ANALYTICS_PARTS_STANDARD_COUNT]: updatedStandardParts.length,
      });
    }

    if (ignoredParts.length > 0 || ignoredStandardParts.length > 0) {
      analytics.track(ANALYTICS_PARTS_IGNORED, {
        [ANALYTICS_PARTS_COUNT]: ignoredParts.length,
        [ANALYTICS_PARTS_STANDARD_COUNT]: ignoredStandardParts.length,
      });
    }

    setStage("submitting");
    submitParts();
    onClose();
  };

  return (
    <Dialog
      isOpen
      icon="upload"
      title={tr.translateAsString("part.dialog.drop-parts.title")}
      onClose={onClose}
      onSecondaryClick={onClose}
      onPrimaryClick={handleSubmit}
      submitting={stage === "submitting"}
      disablePrimary={isSubmitDisabled}
      isFullPage
    >
      {stage === "init" ? (
        <Spinner />
      ) : (
        <>
          {tr.translate("part.dialog.drop-parts.description")}
          <UploadedPartsList
            isLoading={isLoading}
            lockedParts={lockedParts}
            partStates={partStates}
            unsupportedFiles={unsupported}
            onUpdatePartStates={setPartStates}
            standardParts={standardParts}
            onUpdateStandards={setStandardParts}
          />
        </>
      )}
    </Dialog>
  );
}
