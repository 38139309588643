import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { passportKeys } from "./queries";

export default function useDeletePassportFile(passportId: number) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (fileId: number) => {
      await api.passport.removePassportFile(passportId, fileId);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(passportKeys.allFiles(passportId)),
    }
  );
}
