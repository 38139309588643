import { NonIdealState, Spinner } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { Button, Card } from "../../components";
import { useFilter } from "../../hooks";
import { useTranslations } from "../../util";
import { WorkflowActionBar, WorkflowListOngoing } from "./common";
import { ProcessCreationDialog } from "./dialogs";
import { useFilteredWorkflows, useProcesses } from "./hooks";
import { Filter } from "./types";
import styles from "./Workflows.module.scss";

export default function ActiveWorkflows(props: { projectId?: number }) {
  const { projectId } = props;
  const tr = useTranslations();

  const { filter, handleFilter, handleClear } = useFilter<Filter>({
    search: "",
    isArchived: false,
  });

  const { data: workflows = [], isLoading } = useProcesses({
    relation: projectId ? { type: "project", id: projectId } : undefined,
    order: "updatedAt",
    orderDirection: "desc",
    ...filter,
  });

  const [createProcessDialog, setCreateProcessDialog] = useState(false);

  const [openWorkflows, setOpenWorkflows] = useState<number[]>([]);
  const allOpen = workflows.length === openWorkflows.length;

  const toggleAll = () => {
    setOpenWorkflows(allOpen ? [] : workflows.map((w) => w.id));
  };

  const toggleWorkflow = useCallback(
    (workflow: number) =>
      setOpenWorkflows((openWorkflows) => {
        const isFolded = openWorkflows.includes(workflow);
        if (isFolded) {
          return openWorkflows.filter((id) => id !== workflow);
        }
        return [workflow, ...openWorkflows];
      }),
    [setOpenWorkflows]
  );

  const handleCreateWorkflow = () => {
    setCreateProcessDialog(true);
  };

  const onGoingWorkflows = useFilteredWorkflows(workflows, filter);

  return (
    <>
      <div>
        <WorkflowActionBar
          filter={filter}
          handleFilter={handleFilter}
          handleClear={handleClear}
          onCreateWorkflow={handleCreateWorkflow}
          toggleAll={toggleAll}
          allOpen={allOpen}
          projectId={projectId}
        />
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            {onGoingWorkflows.length > 0 && (
              <>
                <WorkflowListOngoing
                  search={filter.search}
                  workflows={onGoingWorkflows}
                  openWorkflows={openWorkflows}
                  toggleFoldWorkflow={toggleWorkflow}
                />
              </>
            )}
            {onGoingWorkflows.length === 0 && (
              <Card className={styles.no_workflows_card}>
                <NonIdealState
                  icon={
                    <img src="/assets/no-data.svg" height={80} alt="no data" />
                  }
                  description={
                    <>
                      {tr.translate(
                        filter.search === ""
                          ? "workflow.page.ongoing.empty"
                          : "workflow.page.ongoing.empty.filtered"
                      )}
                      <Button
                        isDense
                        intent="primary"
                        icon="plus"
                        onClick={handleCreateWorkflow}
                        className={styles.emptyButton}
                      >
                        {tr.translate("workflow.page.ongoing.empty.create")}
                      </Button>
                    </>
                  }
                />
              </Card>
            )}
          </>
        )}
      </div>

      {createProcessDialog && (
        <ProcessCreationDialog
          onClose={() => setCreateProcessDialog(false)}
          initialEntities={
            projectId ? [{ type: "project", id: projectId }] : undefined
          }
        />
      )}
    </>
  );
}
