import { Part, ProjectType } from "@aletiq/types";
import React, { useState } from "react";
import {
  AlertPopover,
  AnchorButton,
  Button,
  Popover2,
  ProjectNameLink,
  RemoveLinkIcon,
  Tooltip,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import ProductCreationMenu from "../../../project/common/ProductCreationMenu";
import { ProjectCreationDialog } from "../../../project/dialogs";
import { LinkProjectsDialog } from "../../dialogs";
import { useUpdatePartProjects } from "../../hooks";
import styles from "./PartProducts.module.scss";

type CreationDialog = { isOpen: false } | { isOpen: true; type: ProjectType };

export default function PartProducts(props: {
  partId: number;
  part?: Part;
  disableEdit: boolean;
}) {
  const tr = useTranslations();

  const { part, partId, disableEdit } = props;
  const [linkDialog, setLinkDialog] = useState(false);
  const [creationDialog, setCreationDialog] = useState<CreationDialog>({
    isOpen: false,
  });

  const { mutate: updatePartProjects } = useUpdatePartProjects();
  const handleRemoveLink = (projectId: number) => () => {
    updatePartProjects({
      partId: partId,
      projects: part?.projects.filter((project) => project !== projectId) || [],
    });
  };
  const handleCloseCreationDialog = () => setCreationDialog({ isOpen: false });

  const handleOpenCreationDialog = (type: ProjectType) =>
    setCreationDialog({
      isOpen: true,
      type,
    });

  return (
    <div className={styles.container}>
      <Tooltip
        content={tr.translateAsString("part.details.projects.link.tooltip")}
        position="top"
      >
        <Button
          className={styles.button}
          intent="primary"
          isDisabled={disableEdit}
          onClick={() => setLinkDialog(true)}
          icon="new-link"
          isDense
        >
          {tr.translate("part.details.projects.link.button")}
        </Button>
      </Tooltip>
      <Tooltip
        content={tr.translateAsString("part.details.projects.new.tooltip")}
        position="top"
      >
        <Popover2
          intent="primary"
          content={
            <ProductCreationMenu onCreateProduct={handleOpenCreationDialog} />
          }
          minimal
          position="bottom-right"
        >
          <Button
            className={styles.button}
            intent="secondary"
            isDisabled={disableEdit}
            icon="plus"
            isDense
          >
            {tr.translate("part.details.projects.new.button")}
          </Button>
        </Popover2>
      </Tooltip>
      <div className={styles.header}>
        <div>{tr.translate("part.details.projects")}</div>
      </div>
      <div>
        {part?.projects.map((projectId) => (
          <div className={styles.row} key={projectId}>
            <div className={styles.name}>
              <ProjectNameLink hasIcon projectId={projectId} />
            </div>
            <div className={styles.action}>
              <AlertPopover
                icon={<RemoveLinkIcon />}
                title={tr.translateAsString("part.details.info.title")}
                content={tr.translateAsString("part.details.info.description")}
                onPrimaryClick={handleRemoveLink(projectId)}
                isDense
              >
                <Tooltip content={tr.translate("part.details.projects.unlink")}>
                  <AnchorButton isDense icon={<RemoveLinkIcon />} />
                </Tooltip>
              </AlertPopover>
            </div>
          </div>
        ))}
      </div>

      {linkDialog && part && (
        <LinkProjectsDialog
          parts={[part]}
          onClose={() => setLinkDialog(false)}
          currentProjects={part.projects}
        />
      )}
      {creationDialog.isOpen && (
        <ProjectCreationDialog
          onClose={handleCloseCreationDialog}
          type={creationDialog.type}
          part={part}
        />
      )}
    </div>
  );
}
