import { EntityId } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { processKeys } from "./queries";

export default function useAddProcessRelation() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (values: { process: number; entity: EntityId }) =>
      api.task.addProcessRelation(values.process, values.entity),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(processKeys.allRelations());
        queryClient.invalidateQueries(processKeys.all);
      },
    }
  );
}
