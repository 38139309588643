import {
  PartIterationId,
  PartType,
  PartWithIteration,
  Project,
  ProjectDefinitionId,
  ProjectType,
} from "@aletiq/types";

type ProductItem = {
  id: number;
  name: string;
  definition: number;
  type: ProjectType;
};

type PartItem = {
  id: number;
  name: string;
  isStandard: boolean;
  type: PartType;
  iteration: number;
  projects: number[];
};

export type ProductTree = {
  part: PartItem | null;
  product: ProductItem | null;
  pathId: string;
  linkedProducts: ProductItem[];
  components: ProductTree[];
};

export type PartProductBomMutation = {
  product: ProjectDefinitionId;
  part: PartIterationId;
};

export type ProductBomMutation = {
  productId: number;
  definitionIdx: number;
  parent: ProjectDefinitionId;
};

export type ProductMutationList = {
  assignations: PartProductBomMutation[];
  additions: ProductBomMutation[];
  deletions: ProductBomMutation[];
};

export type ProductMutation = {
  assignation?: PartProductBomMutation;
  addition?: ProductBomMutation;
  deletion?: ProductBomMutation;
};

export function makeMutationBatch(
  mutation: ProductMutation
): ProductMutationList {
  const { assignation, addition, deletion } = mutation;
  return {
    assignations: assignation ? [assignation] : [],
    additions: addition ? [addition] : [],
    deletions: deletion ? [deletion] : [],
  };
}

export function makeAutocomplete(tree: ProductTree): ProductBomMutation[] {
  const additions: ProductBomMutation[] = tree.components
    .filter(hasInferrableProduct)
    .map((t) => makeInferrableMutation(t, tree));

  return [...additions, ...tree.components.flatMap((t) => makeAutocomplete(t))];
}

export function isComplete(tree: ProductTree): boolean {
  if (tree.components.find(hasInferrableProduct)) {
    return false;
  }
  return tree.components.reduce(
    (acc: boolean, t) => acc && isComplete(t),
    true
  );
}

export function makeInferrableMutation(
  tree: ProductTree,
  parent: ProductTree
): ProductBomMutation {
  return {
    parent: {
      productId: parent.product!.id,
      definitionIdx: parent.product!.definition,
    },
    productId: tree.linkedProducts[0].id,
    definitionIdx: tree.linkedProducts[0].definition,
  };
}

export function hasInferrableProduct(tree: ProductTree) {
  return tree.linkedProducts.length === 1 && tree.product === null;
}

export function isKnownTree(tree: ProductTree) {
  return tree.product !== null && tree.part !== null;
}

export function hasPart(tree: ProductTree) {
  return tree.part !== null;
}

export function notHasPart(tree: ProductTree) {
  return tree.part === null;
}

export function hasProduct(tree: ProductTree) {
  return tree.product !== null;
}

export function makeEmptyNode(): ProductTree {
  return {
    product: null,
    part: null,
    pathId: "",
    components: [],
    linkedProducts: [],
  };
}

function makeProductItem(product: Project): ProductItem {
  return {
    id: product.id,
    name: product.name,
    type: product.isTool ? "tool" : ("article" as ProjectType),
    definition: product.lastDefinition.index,
  };
}

function makePartItem(component: PartWithIteration): PartItem {
  const { part, iteration } = component;
  return {
    id: part.id,
    name: part.name,
    isStandard: part.isStandard,
    iteration: iteration.number,
    type: part.type,
    projects: part.projects,
  };
}

export function makeProductNode(
  part: PartWithIteration | null,
  product: Project | null,
  pathId: string,
  components: ProductTree[],
  linkedProducts: Project[]
): ProductTree {
  return {
    product: product !== null ? makeProductItem(product) : null,
    part: part !== null ? makePartItem(part) : null,
    pathId,
    components,
    linkedProducts: linkedProducts.map(makeProductItem),
  };
}

export function makeAdditionalTrees(tree: ProductTree): ProductTree[] {
  return tree.components.filter(notHasPart).filter(hasProduct);
}
