import React from "react";
import ReactDOM from "react-dom";
import { hotjar } from "react-hotjar";
import { HOTJAR_ID } from "./config";
// Load CSS Before any aletiq component to ensure the order of application
import "./index.scss";

if (HOTJAR_ID) {
  const hotjarSnippetVersion = 6;
  hotjar.initialize(parseInt(HOTJAR_ID), hotjarSnippetVersion);
}

const render = () => {
  /*eslint @typescript-eslint/no-var-requires:off*/
  const App = require("./app/App").default;
  ReactDOM.render(<App />, document.getElementById("root"));
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render);
}
