import { MenuItem } from "@aletiq/design-system";
import { IconName, MaybeElement } from "@blueprintjs/core";
import React, { ReactNode } from "react";
import { useNavigateTo } from "../../../hooks";
import styles from "../NavBar.module.scss";

export default function NavigationMenuItem(props: {
  icon?: IconName | MaybeElement;
  text?: ReactNode;
  path?: string;
  panel?: string;
  search?: string;
  href?: string;
  openNewPage?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}) {
  const {
    icon,
    text,
    path,
    search,
    panel,
    href,
    openNewPage = false,
    disabled,
    onClick,
  } = props;
  const handleClick = useNavigateTo({ path, search, panel });

  return (
    <MenuItem
      icon={icon}
      text={text}
      disabled={disabled}
      onClick={onClick || (() => (path || search || panel) && handleClick())}
      href={href}
      target={openNewPage ? "_blank" : undefined}
      intent="primary"
      className={styles.menuItem}
      isDense
    />
  );
}
