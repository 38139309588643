import { Property, PropertyValue } from "@aletiq/types";
import React from "react";
import { EditablePropertySingleSelect } from "../../../../components";
import { useTranslations } from "../../../../util";

export function CustomSingleSelectField(props: {
  property: Property;
  propertyValue?: { type: "enum"; value: string };
  onSubmit: (value: PropertyValue) => void;
  isSubmitting?: boolean;
  hasDescriptionTooltip?: boolean;
  canEdit: boolean;
  search?: string;
  minWidth?: number;
}) {
  const {
    property,
    propertyValue,
    canEdit,
    onSubmit,
    search,
    hasDescriptionTooltip,
    minWidth,
  } = props;
  const tr = useTranslations();

  const handleSubmit = (value: string) => {
    if (value === "") {
      onSubmit(null);
    } else {
      onSubmit({ type: "enum", value });
    }
  };

  const items = [
    { key: "", text: tr.translateAsString("generic.label.none") },
    ...property.options
      .filter((opt) => !opt.deleted)
      .map((opt) => ({
        key: opt.name,
        text: opt.label,
      })),
  ];

  return (
    <EditablePropertySingleSelect
      value={propertyValue?.value ?? ""}
      items={items}
      isEditable={canEdit}
      label={property.label}
      placeholder={tr.translateAsString("generic.action.modify-value", {
        value: property.label,
      })}
      emptyState={tr.translateAsString("generic.label.none")}
      onSubmit={handleSubmit}
      tooltipText={hasDescriptionTooltip ? property.description : undefined}
      search={search}
      minWidth={minWidth}
      inline
    />
  );
}
