import { Project, ProjectDefinitionId } from "@aletiq/types";
import { TreeNodeInfo } from "@blueprintjs/core";
import React from "react";
import { ProductIcon, Tree } from "../../../../components";
import TreeItemName from "../../../../components/name/TreeItemName";
import AdditionalProductsTreeActionBar from "./AdditionalProductsTreeActionBar";
import { ProductBomMutation, ProductTree } from "./partProjectBomUtils";

export default function EditAdditionalProductsTree(props: {
  productId: number;
  definitionIdx: number;
  additionalTrees: ProductTree[];
  movedProduct?: Project;
  fetchingPathId?: string;
  onDelete: (pathId: string, deletion: ProductBomMutation) => void;
  onMove: (productId: number) => void;
  onCancel: () => void;
}) {
  const {
    productId,
    definitionIdx,
    additionalTrees,
    movedProduct,
    onDelete,
    onMove,
    onCancel,
    fetchingPathId,
  } = props;

  function makePartNodeInfos(
    tree: ProductTree,
    parent: ProjectDefinitionId
  ): TreeNodeInfo<{}> {
    const { product, pathId } = tree;
    const isMoving = movedProduct !== undefined;
    const isMoved = movedProduct?.id === product!.id;
    const isFetching = fetchingPathId === pathId;

    const handleMove = () => {
      onMove(product!.id);
    };

    const handleDelete = () => {
      const deletion = {
        parent,
        productId: product!.id,
        definitionIdx: product!.definition,
      };
      onDelete(pathId, deletion);
    };

    return {
      id: pathId,
      label: (
        <TreeItemName
          name={product?.name}
          isHighlighted={isMoving}
          isLoading={isFetching}
        />
      ),
      icon: !isFetching && (
        <ProductIcon
          inline
          color={!isMoving ? "var(--blue100)" : undefined}
          intent={isMoving ? "primary" : "none"}
          type={product!.type}
        />
      ),
      secondaryLabel: (
        <AdditionalProductsTreeActionBar
          isMoved={isMoved}
          isMoving={isMoving}
          onMove={handleMove}
          onDelete={handleDelete}
          onCancel={onCancel}
        />
      ),
    };
  }

  return (
    <>
      {additionalTrees.map((t) => (
        <Tree
          key={t.pathId}
          contents={[makePartNodeInfos(t, { productId, definitionIdx })]}
        />
      ))}
    </>
  );
}
