import { Property, PropertyValue } from "@aletiq/types";
import { IconName } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  Dialog,
  DropdownButton,
  EditCustomPropertyField,
  Field,
  getPropertyTypeIcon,
  Menu,
  MenuItem,
  Popover2,
  User,
} from "../../../../components";
import { useUsers } from "../../../../hooks";
import { useTranslations } from "../../../../util";
import { useProperties } from "../../../administration/Attributes/hooks";
import {
  useUpdateDocumentsOwner,
  useUpdateDocumentsProperties,
} from "../../hooks";
import styles from "./EditPropertiesDialog.module.scss";
import PropertySelect from "./PropertySelect";

type DocumentProperty =
  | {
      type: "custom";
      property: Property;
      value: PropertyValue | null;
    }
  | { type: "owner"; value?: number };

export default function EditPropertiesDialog(props: {
  documentIds: number[];
  onClose: () => void;
}) {
  const { documentIds, onClose } = props;
  const tr = useTranslations();

  const [property, setProperty] = useState<DocumentProperty>();

  const { data: allUsers = [] } = useUsers();
  const { data: customProperties = [] } = useProperties("document");

  const documentOwnerUsers = allUsers.filter((u) =>
    u.permissions.includes("update:documents")
  );

  const { mutate: updateOwner, isLoading: isLoadingOwner } =
    useUpdateDocumentsOwner();
  const { mutate: updateProperties, isLoading: isUpdatingProperty } =
    useUpdateDocumentsProperties(documentIds);

  const handleConfirm = () => {
    if (!property) {
      return;
    }

    switch (property.type) {
      case "owner": {
        property.value &&
          updateOwner(
            documentIds.map((id) => ({ id, owner: property.value ?? 0 })),
            { onSettled: onClose }
          );
        break;
      }
      case "custom": {
        updateProperties(
          [{ property: property.property.name, value: property.value }],
          { onSettled: onClose }
        );
        break;
      }
    }
  };

  const handleSelectAletiqProperty = (type: "owner") => () => {
    setProperty({ type });
  };

  const handleSelectCustomProperty = (property: Property) => () => {
    setProperty({ type: "custom", property, value: null });
  };

  const handleSetAletiqProperty = (value?: number) => {
    if (property && property.type !== "custom") {
      setProperty({ ...property, value });
    }
  };

  const handleSetCustomProperty = (value: PropertyValue) => {
    if (property && property.type === "custom") {
      setProperty({ ...property, value });
    }
  };

  const isSubmitting = isLoadingOwner || isUpdatingProperty;
  const isSubmitDisabled =
    !property || (property.type === "owner" && !property.value);

  const aletiqDocProperties: {
    type: "owner";
    text: string;
    icon: IconName;
  }[] = [
    {
      type: "owner",
      text: tr.translateAsString(
        "document.dialog.edit.property.property.owner"
      ),
      icon: "person",
    },
  ];

  const selectedPropertyLabel = !property
    ? tr.translate("passport.dialogs.edit-property.name")
    : property.type === "custom"
    ? property.property.label
    : aletiqDocProperties.find((p) => p.type === property.type)?.text ?? "";
  return (
    <Dialog
      isOpen
      title={tr.translateAsString("document.dialog.edit.property.title", {
        nbr: documentIds.length,
      })}
      className={styles.dialog}
      icon="edit"
      onClose={onClose}
      onPrimaryClick={handleConfirm}
      onSecondaryClick={onClose}
      submitting={isSubmitting}
      disablePrimary={isSubmitDisabled}
    >
      <Field
        label={
          <span className={styles.label}>
            {tr.translate("document.dialog.edit.property.property.picker")}
          </span>
        }
      >
        <Popover2
          fill
          content={
            <Menu>
              {aletiqDocProperties.map(({ type, text, icon }) => (
                <MenuItem
                  key={type}
                  icon={icon}
                  text={text}
                  onClick={handleSelectAletiqProperty(type)}
                  intent="primary"
                />
              ))}
              {customProperties.map((p) => (
                <MenuItem
                  key={p.id}
                  text={p.label}
                  intent="primary"
                  icon={getPropertyTypeIcon(p.type)}
                  onClick={handleSelectCustomProperty(p)}
                  active={
                    property?.type === "custom" && property.property.id === p.id
                  }
                />
              ))}
            </Menu>
          }
        >
          <DropdownButton fill intent="primary" text={selectedPropertyLabel} />
        </Popover2>
      </Field>

      {property?.type === "owner" && (
        <Field
          label={tr.translate("document.dialog.edit.property.property.owner")}
        >
          <PropertySelect
            items={documentOwnerUsers
              .filter((o) => o.isActive)
              .map((o) => ({
                key: o.id,
                label: o.displayName,
                node: <User userId={o.id} size="small" />,
              }))}
            selectedItem={property.value}
            handleFunction={handleSetAletiqProperty}
          />
        </Field>
      )}

      {property?.type === "custom" && (
        <Field label={selectedPropertyLabel}>
          <EditCustomPropertyField
            propertyValue={property.value}
            onEditValue={handleSetCustomProperty}
            property={property.property}
          />
        </Field>
      )}
    </Dialog>
  );
}
