import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslations } from "../../../../util";
import {
  ANALYTICS_VISITED_PRODUCT_VERSION_DETAILS,
  useTrackEvent,
} from "../../../../analytics";
import { ProjectPage } from "../../common";
import { useProductVersions } from "../../hooks";
import { addChangeStatus } from "../projectVersionActions";
import VersionDetails from "./VersionDetails";

export default function VersionDetailsPage(props: { projectId: number }) {
  const { projectId } = props;
  const tr = useTranslations();
  const { params } = useRouteMatch<{ version: string }>();
  const versionId = Number.parseInt(params.version || "");
  useTrackEvent(ANALYTICS_VISITED_PRODUCT_VERSION_DETAILS);

  const { data: versions = [] } = useProductVersions(projectId);

  const version = versions.find((it) => it.id === versionId);
  const versionName = version?.version ?? "";

  const documents = addChangeStatus(version?.documents ?? [])(
    version?.actions ?? []
  );

  return (
    <ProjectPage projectId={projectId}>
      <VersionDetails
        projectId={projectId}
        documents={documents}
        title={tr.translateAsString(
          "project.version.details.sidebar.version.name",
          {
            name: versionName,
          }
        )}
      />
    </ProjectPage>
  );
}
