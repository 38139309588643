import { ProjectFieldValue } from "@aletiq/types";
import { Text } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { Tag } from "../../../../components";
import styles from "./TagPassportConfiguration.module.scss";

export default function TagPassportConfiguration(props: {
  fieldValue: ProjectFieldValue;
  fieldId: number;
  className?: string;
}) {
  const { fieldValue, fieldId, className } = props;

  return (
    <Tag
      style={{ backgroundColor: `var(--tag-color-${(fieldId % 10) + 1})` }}
      className={classNames(styles.tag, className)}
      isDense
      unclickable
      icon={fieldValue.isDefault ? "pin" : undefined}
    >
      <Text ellipsize>{fieldValue.name}</Text>
    </Tag>
  );
}
