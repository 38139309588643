import { ProjectQueryParams } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../app/useApi";
import { projectKeys } from "./queries";

export default function useProductUsedIn(
  productId: number | null,
  queryParams: ProjectQueryParams
) {
  const api = useApi();

  return useQuery(
    projectKeys.bomUsedIn(productId!, queryParams),
    () => api.project.getProjectUsedIn(productId!, queryParams),
    { enabled: productId !== null }
  );
}
