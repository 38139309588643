import { PartQueryOrder } from "@aletiq/types";
import { ColumnOptionConfig } from "../../../hooks";
import { TreePath } from "../../../util";
import usePartTableColumns, { PartColumn } from "./usePartTableColumns";

export type StandardPartColumn = Exclude<
  PartQueryOrder,
  "lastIterationName" | "lockOwnerName" | "ownerName"
>;

export const defaultStandardPartColumnConfig: ColumnOptionConfig<StandardPartColumn> =
  {
    name: true,
    description: true,
    createdAt: false,
    updatedAt: false,
    format: false,
  };

function makePartConfig(
  config: ColumnOptionConfig<StandardPartColumn>
): ColumnOptionConfig<PartColumn> {
  return {
    ...config,
    lastIterationName: false,
    lockOwnerName: false,
    ownerName: false,
  };
}

const noop = () => undefined;
export default function useStandardPartTableColumns(
  onToggleExpand: (paths: TreePath[]) => (enabled: boolean) => void,
  onEditBom: (partId: number) => () => void,
  columnConfig: ColumnOptionConfig<StandardPartColumn>
) {
  return usePartTableColumns(
    onToggleExpand,
    onEditBom,
    noop,
    makePartConfig(columnConfig)
  );
}
