import {
  Button,
  ButtonGroup,
  HighlightedText,
  Input,
} from "@aletiq/design-system";
import React, { useState } from "react";
import styles from "./EditableCell.module.scss";

export default function EditableTextCell(props: {
  value: string;
  onConfirmNewValue: (newValue: string) => void;
  highlight?: string;
}) {
  const { value, onConfirmNewValue, highlight } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleSubmitNewValue = () => {
    if (editedValue !== value) {
      onConfirmNewValue(editedValue);
    }
    setIsEditing(false);
  };

  const handleToggleEdition = () => {
    setIsEditing(!isEditing);
  };

  return (
    <>
      {isEditing ? (
        <div className={styles.editable}>
          <Input
            autoFocus
            value={editedValue}
            className={styles.input}
            onChange={setEditedValue}
            intent="default"
            isDense
            fill
          />
          <ButtonGroup isDense>
            <Button
              isDense
              intent="success"
              icon="tick"
              onClick={handleSubmitNewValue}
            />
            <Button isDense icon="cross" onClick={handleToggleEdition} />
          </ButtonGroup>
        </div>
      ) : (
        <>
          <HighlightedText highlight={highlight} text={value} />
          <Button
            isDense
            intent="array_primary"
            icon="edit"
            className={styles.hover_button}
            onClick={handleToggleEdition}
          />
        </>
      )}
    </>
  );
}
