import classnames from "classnames";
import React from "react";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownProps } from "react-markdown/src/ast-to-react";
import { UserName } from "../name";
import styles from "./CleanerReactMarkdown.module.scss";

export default function CleanerReactMarkdown(props: {
  source: string;
  className?: string;
}) {
  const { source, className } = props;

  return (
    <ReactMarkdown
      components={{ pre: PreAsIndent, a: Link }}
      className={classnames(styles.content, className)}
      children={source}
    />
  );
}
const PreAsIndent = (
  props: React.ClassAttributes<HTMLPreElement> &
    React.HTMLAttributes<HTMLPreElement> &
    ReactMarkdownProps
) => {
  // remove the markdown for code block as the use of space is more commonly use for text indentation
  // so we add padding

  const code = props.node.children[0];
  if (!code || code.type !== "element") {
    return <pre {...props} />;
  }

  const content = code.children[0];
  if (!content || content.type !== "text") {
    return <pre {...props} />;
  }

  return (
    <CleanerReactMarkdown
      source={content.value}
      className={styles.paddingLeft}
    />
  );
};

function Link(
  props: React.ClassAttributes<HTMLAnchorElement> &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    ReactMarkdownProps
) {
  if (props.href === "aletiq-user") {
    const id = props.node.children[0];
    if (id && id.type === "text") {
      return (
        <span className={styles.suggestion}>
          {"@"}
          <UserName userId={parseInt(id.value, 10)} />
        </span>
      );
    }
  }
  return <a {...props}>{props.children}</a>;
}
