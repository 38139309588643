import React from "react";
import { useTranslations } from "../../../util";
import TreeButton, { TreeButtonProps } from "./TreeButton";

export default function SelectButton(props: TreeButtonProps) {
  const tr = useTranslations();

  return (
    <TreeButton
      {...props}
      isDense
      color="primary"
      view="flat"
      icon="search"
      text={tr.translate("generic.action.select")}
    />
  );
}
