import { Callout } from "@aletiq/design-system";
import React from "react";
import { useTranslations } from "../../../util";

export default function CyclicModelWarning(props: { className?: string }) {
  const tr = useTranslations();

  return (
    <Callout intent="danger" className={props.className}>
      {tr.translate("workflow.model.warning.cycle")}
    </Callout>
  );
}
