import React from "react";
import styles from "./Tasks.module.scss";
import { useTranslations } from "../../../util";
import TaskTable from "../common/TaskTable";

export default function TasksHeader(props: {
  isScrollbarVisible?: boolean;
  verticalScrollbarSize?: number;
}) {
  const { isScrollbarVisible = false, verticalScrollbarSize } = props;
  const tr = useTranslations();
  return (
    <TaskTable>
      <thead>
        <tr>
          <th className={styles.td}>{tr.translate("tasklist.task")}</th>
          <th>{tr.translate("tasklist.process")}</th>
          <th className={styles.owner_cell}>
            {tr.translate("tasklist.assignee")}
          </th>
          <th className={styles.date_cell}>
            {tr.translate("tasklist.duedate")}
          </th>
          <th className={styles.status_cell}>
            {tr.translate("tasklist.status")}
          </th>
          {isScrollbarVisible && (
            <th
              style={{ width: verticalScrollbarSize }}
              className={styles.filler_th}
            />
          )}
        </tr>
      </thead>
    </TaskTable>
  );
}
