import React, { Reducer, useReducer } from "react";
import {
  ANALYTICS_DOCUMENTS_COUNT,
  ANALYTICS_DOCUMENTS_CREATED,
  ANALYTICS_DOCUMENTS_IGNORED,
  ANALYTICS_DOCUMENTS_UPDATED,
  ANALYTICS_MUTATION_STATUS,
  makeAnalyticsMutationStatus,
  useAnalytics,
} from "../../../../analytics";
import { Dialog } from "../../../../components/";
import { partitionWith, useTranslations } from "../../../../util";
import styles from "./DocumentUploadWizard.module.scss";
import {
  Action,
  documentCreationModified,
  DocumentState,
  initialState,
  reducer,
  State,
} from "./state";
import { UploadedDocumentsList } from "./UploadedDocumentsList";
import useCreateOrUpdateDocuments from "./useCreateOrUpdateDocuments";

export default function DocumentsUploadWizard(props: {
  groupedFiles: DocumentState[];
  projectId?: number;
  onClose: () => void;
}) {
  const { groupedFiles, projectId, onClose } = props;
  const tr = useTranslations();
  const analytics = useAnalytics();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    initialState(groupedFiles)
  );

  const { documentCreations, stage } = state;

  const isSubmitting =
    (stage.type === "creations" || stage.type === "updates") &&
    stage.status !== "idle";

  const createOrUpdateDocuments = useCreateOrUpdateDocuments(
    projectId ?? null,
    documentCreations
  );

  const handleSubmit = () => {
    const [createdDocuments, updatedDocuments, ignoredDocuments] =
      partitionWith(
        groupedFiles,
        (file) => file.action.action === "create",
        (file) => file.action.action === "update",
        (file) => file.action.action === "ignore"
      );

    createOrUpdateDocuments({
      onSettled: (error) => {
        const status = makeAnalyticsMutationStatus(error);
        createdDocuments.length > 0 &&
          analytics.track(ANALYTICS_DOCUMENTS_CREATED, {
            [ANALYTICS_DOCUMENTS_COUNT]: createdDocuments.length,
            [ANALYTICS_MUTATION_STATUS]: status,
          });
        updatedDocuments.length > 0 &&
          analytics.track(ANALYTICS_DOCUMENTS_UPDATED, {
            [ANALYTICS_DOCUMENTS_COUNT]: updatedDocuments.length,
            [ANALYTICS_MUTATION_STATUS]: status,
          });
        ignoredDocuments.length > 0 &&
          analytics.track(ANALYTICS_DOCUMENTS_IGNORED, {
            [ANALYTICS_DOCUMENTS_COUNT]: ignoredDocuments.length,
            [ANALYTICS_MUTATION_STATUS]: status,
          });
      },
    });
    onClose();
  };

  const creationsCompleted = documentCreations.every(
    (document) =>
      document.action.action === "ignore" ||
      (document.action.action === "create" &&
        document.action.revision.name !== "") ||
      (document.action.action === "update" &&
        (document.action.revision?.name !== "" || !document.action.revision))
  );

  if (stage.type === "done") return null;

  return (
    <Dialog
      isOpen
      isFullPage
      icon="upload"
      title={tr.translateAsString("storage.dialog.upload-files.title")}
      onClose={onClose}
      onPrimaryClick={handleSubmit}
      disablePrimary={!creationsCompleted}
      submitting={isSubmitting}
      className={styles.dialog}
    >
      {tr.translate("storage.dialog.upload-files.description")}
      <UploadedDocumentsList
        creationsStatus={stage.status}
        state={documentCreations}
        onUpdateDocument={(state) => dispatch(documentCreationModified(state))}
      />
    </Dialog>
  );
}
