import React from "react";
import { UserName } from "..";
import { useTranslations } from "../../util";
import styles from "./Revisions.module.scss";

export default function RevisionInfos(props: { userId: number; date: string }) {
  const { userId, date } = props;

  const tr = useTranslations();

  return (
    <div className={styles.revision_info}>
      {tr.translate("document.details.revisions.revision-last-change", {
        user: <UserName userId={userId} />,
        date: new Date(date),
      })}
    </div>
  );
}
