import {
  ButtonSP,
  EditableText,
  Icon,
  Input,
  TagSP,
  Tooltip,
} from "@aletiq/design-system";
import { Property, PropertyValue } from "@aletiq/types";
import classNames from "classnames";
import React from "react";
import {
  CreateTag,
  EditCustomPropertyField,
  GenericStatusTag,
  IgnoreTag,
  UpdateTag,
  ValidateTag,
} from "../../../../components";
import { useTranslations } from "../../../../util";
import { useDocument, useDocumentRevisionSignatures } from "../../hooks/";
import DocumentActionSelect from "./DocumentActionSelect";
import styles from "./DocumentUploadWizard.module.scss";
import { DocumentAction, FileAction } from "./state";

export default function FileRow(props: {
  submitted?: boolean;
  onUpdate: (spec: DocumentAction) => void;
  visibleProperties: Property[];
  documentId: number | null;
  spec: DocumentAction;
  file: File;
}) {
  const { documentId, visibleProperties, submitted, onUpdate, spec } = props;
  const tr = useTranslations();

  const { action, revision, properties } = spec;
  const { data: document } = useDocument(documentId);

  const handleUpdateName = (name: string) => onUpdate({ ...spec, name });

  const handleUpdateRevisionName = (name: string) =>
    revision &&
    onUpdate({
      ...spec,
      revision: { ...revision, name },
    });

  const handleUpdateAction = (
    action: FileAction,
    revisionState?: "draft" | "approved"
  ) => {
    onUpdate({
      ...spec,
      action,
      revision: {
        ...spec.revision,
        state: revisionState || spec.revision.state,
      },
    });
  };

  const handleUpdateProperty = (propertyName: string, value: PropertyValue) => {
    if (action !== "create") {
      return;
    }
    const propertyIsSet = properties.some((p) => p.property === propertyName);
    const updatedProperties = propertyIsSet
      ? properties.map((p) =>
          p.property === propertyName ? { ...p, value } : p
        )
      : [...properties, { property: propertyName, value }];

    onUpdate({ ...spec, properties: updatedProperties });
  };

  const isApproved = revision.state === "approved";

  const lastRevision =
    document && document.revisions.length > 0
      ? document?.revisions[0]
      : undefined;

  const { data: signatures = [] } = useDocumentRevisionSignatures(
    documentId ?? undefined,
    lastRevision?.id
  );

  const callout =
    lastRevision && lastRevision.state === "draft" && signatures.length > 0;

  // for updated documents, is the revision name already used in previous revisions
  const isRevisionNameUsed =
    action === "update" &&
    revision.name !== "" &&
    document?.revisions.some(
      (r) =>
        r.state !== "draft" &&
        r.revisionName.toLocaleLowerCase() === revision.name.toLocaleLowerCase()
    ) &&
    !submitted;

  return (
    <tr className={isRevisionNameUsed ? styles.duplicate_index_row : undefined}>
      <td className={styles.tag_col}>
        {!documentId && action !== "ignore" && (
          <GenericStatusTag status="new" />
        )}
        {callout && (
          <Tooltip
            content={tr.translateAsString(
              "storage.dialog.upload-file.tag.signed.hint"
            )}
          >
            <TagSP
              isDense
              intent="warning"
              view="outlined"
              rightIcon="warning-sign"
              unclickable
            >
              {tr.translate("storage.dialog.upload-file.tag.signed")}
            </TagSP>
          </Tooltip>
        )}
      </td>
      <td>
        <div
          className={classNames(
            styles.name_td,
            action === "ignore" && styles.ignore
          )}
        >
          <Icon
            inline
            icon="document"
            color={action === "ignore" ? "var(--blue30)" : "var(--blue80)"}
            size={20}
          />
          {action !== "create" ? (
            document?.name || spec.name
          ) : (
            <EditableText
              isFill
              intent="primary"
              disabled={submitted}
              value={spec.name}
              onChange={handleUpdateName}
            />
          )}
        </div>
      </td>
      <td className={styles.action_col}>
        <DocumentActionSelect
          disabled={submitted}
          onChange={handleUpdateAction}
          isNew={documentId === null}
          selectedAction={action}
          isApproved={isApproved}
        >
          <ButtonSP
            isDense
            isFill
            view="flat"
            color="primary"
            rightIcon="caret-down"
          >
            {action === "create" && <CreateTag />}
            {action === "update" && <UpdateTag />}
            {action === "ignore" && <IgnoreTag />}
            {action !== "ignore" && revision.state === "approved" && (
              <>
                {" & "}
                <ValidateTag />
              </>
            )}
          </ButtonSP>
        </DocumentActionSelect>
      </td>
      <td className={styles.revision_col}>
        <Input
          isDense
          fill
          disabled={submitted || action === "ignore"}
          value={revision?.name}
          onChange={handleUpdateRevisionName}
          placeholder={tr.translateAsString(
            "storage.dialog.upload-file.name.placeholder",
            {
              action: action === "create" ? "create" : "update",
              name: lastRevision?.revisionName || "",
            }
          )}
          hasError={isRevisionNameUsed}
          errorHelperText={tr.translateAsString(
            "storage.dialog.upload-file.name.in-use"
          )}
        />
      </td>

      {visibleProperties.map((property) => (
        <td key={property.id}>
          <EditCustomPropertyField
            isDense
            isDisabled={action !== "create"}
            property={property}
            propertyValue={
              properties.find((p) => p.property === property.name)?.value ??
              null
            }
            onEditValue={(value) => handleUpdateProperty(property.name, value)}
          />
        </td>
      ))}
    </tr>
  );
}
