import { API } from "@aletiq/api";
import { PartComponent } from "@aletiq/types";
import { useQuery } from "react-query";
import useApi from "../../../../../app/useApi";
import { makePathId } from "../../../../../util";
import { pdmKeys } from "../../../../pdm/hooks/queries";
import { makePartNode, PartTree } from "../partBomUtils";

export default function usePartIterationTree(
  partId: number,
  expandedNodes: number[],
  enabled: boolean = true,
  onSettled: (data?: PartTree, error?: unknown) => void,
  iterationNumber?: number
) {
  const api = useApi();
  return useQuery(
    pdmKeys.iterationExpandTree(partId, undefined, expandedNodes),
    async (): Promise<PartTree> => {
      const part = await api.pdm.getPart(partId);
      const pathId = makePathId(part.id);
      const name = part?.lastIteration?.file?.name ?? "";
      const it = iterationNumber ?? part?.lastIteration?.number ?? 1;
      const iterations = await api.pdm.listPartIterations(part.id);
      const iteration = iterations.find((i) => i.number === it);
      const components = await api.pdm.listAssemblyIterationComponents(
        partId,
        it
      );
      const resolvedComponents = await Promise.all(
        components.map((c) => resolveComponent(api, c, expandedNodes, pathId))
      );
      return makePartNode(
        name,
        part,
        iteration,
        false,
        pathId,
        resolvedComponents
      );
    },
    { enabled, onSettled }
  );
}

async function resolveComponent(
  api: API,
  component: PartComponent,
  expandedNodes: number[],
  parentPath: string
): Promise<PartTree> {
  const { part, name, isIgnored } = component;
  if (!part) {
    return makePartNode(name, null, component.iteration, isIgnored, "", []);
  }
  const iteration = component.iteration ?? part.lastIteration;
  const pathId = makePathId(part.id, parentPath);

  let resolvedSubComponents: PartTree[] = [];
  if (expandedNodes.includes(part.id)) {
    const subComponents = await api.pdm.listAssemblyIterationComponents(
      part.id,
      iteration.number
    );
    resolvedSubComponents = await Promise.all(
      subComponents.map((c) => resolveComponent(api, c, expandedNodes, pathId))
    );
  }
  return makePartNode(
    name,
    part,
    iteration,
    false,
    pathId,
    resolvedSubComponents
  );
}
