import { TreeNodeInfo } from "@blueprintjs/core";
import React from "react";
import { PartSwIcon, Tree } from "../../../../components";
import TreeItemName from "../../../../components/name/TreeItemName";
import {
  isPartParent,
  partFormatFromFileName,
  partTypeFromFileName,
} from "../../../pdm/services";
import styles from "./EditPartBomDialog.module.scss";
import { PartTree } from "./partBomUtils";

export default function EditCadTree(props: {
  tree: PartTree;
  isExpanded: (pathId: string) => boolean;
  onExpand: (pathId: string) => void;
  onToggleExpandNode: (node: TreeNodeInfo<{ onExpand?: () => void }>) => void;
}) {
  const { tree, isExpanded, onExpand, onToggleExpandNode } = props;

  function makeCadNodeInfos(
    tree: PartTree
  ): TreeNodeInfo<{ onExpand?: () => void }> {
    const { name, part, components, pathId } = tree;
    const isKnown = part !== null;
    return {
      id: pathId,
      hasCaret: isPartParent(partTypeFromFileName(name) ?? "part") && isKnown,
      isExpanded: isExpanded(pathId),
      label: <TreeItemName name={name} />,
      icon: (
        <PartSwIcon
          type={partTypeFromFileName(name)}
          format={partFormatFromFileName(name)}
        />
      ),
      nodeData: {
        onExpand: () => onExpand(pathId),
      },
      childNodes: components.map((tree) => makeCadNodeInfos(tree)),
    };
  }

  return (
    <Tree
      onNodeExpand={onToggleExpandNode}
      onNodeCollapse={onToggleExpandNode}
      className={styles.selectable}
      contents={[
        {
          isExpanded: true,
          id: tree.pathId,
          hasCaret: false,
          label: <TreeItemName name={tree.name} />,
          icon: (
            <PartSwIcon type={tree.part!.type} format={tree.part!.format} />
          ),
          childNodes: tree.components.map(makeCadNodeInfos),
        },
      ]}
    />
  );
}
