import { DocumentRevisionSpec } from "@aletiq/types";
import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { documentKeys } from "./queries";

export default function useUpdateDocumentRevisionSpec() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (values: {
      documentId: number;
      revisionId: number;
      spec: DocumentRevisionSpec;
    }) => {
      const { documentId, revisionId, spec } = values;
      await api.document.updateDocumentRevisionSpec(
        documentId,
        revisionId,
        spec
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentKeys.all);
      },
    }
  );
}
