import { useMutation, useQueryClient } from "react-query";
import useApi from "../../app/useApi";
import { userKeys } from "../../features/administration/hooks/queries";

export default function useAcknowledgeAppUpdates() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(() => api.user.acknowledgeAppUpdates(), {
    onSuccess: () => queryClient.invalidateQueries(userKeys.all),
  });
}
