import { IconName } from "@blueprintjs/core";
import React, { ReactNode } from "react";
import { Icon } from "../../components";

export default function TabHeaderSearch(props: {
  icon: IconName;
  text: ReactNode;
}) {
  const { text, icon } = props;

  return (
    <>
      <Icon inline icon={icon} />
      {text}
    </>
  );
}
