import React from "react";
import { Button } from "../../../../components";
import { useTranslations } from "../../../../util";

export default function ProjectDefinitionDocumentAddButton(props: {
  isDisabled?: boolean;
  onClick: () => void;
}) {
  const { isDisabled, onClick } = props;

  const tr = useTranslations();

  return (
    <Button
      isDense
      isDisabled={isDisabled}
      onClick={onClick}
      intent="primary"
      icon="new-link"
    >
      {tr.translate("project.definition.docs.add")}
    </Button>
  );
}
