import { MultistepDialog } from "@aletiq/design-system";
import { EntityId } from "@aletiq/types";
import React, { useMemo, useState } from "react";
import {
  ANALYTICS_MUTATION_STATUS,
  ANALYTICS_OPENED_WORKFLOW_CREATION,
  ANALYTICS_WORKFLOW_CREATED,
  ANALYTICS_WORKFLOW_MODEL_ID,
  ANALYTICS_WORKFLOW_MODEL_NAME,
  makeAnalyticsMutationStatus,
  makeAnalyticsOptional,
  useAnalytics,
  useTrackEvent,
} from "../../../analytics";
import { useCurrentUser, useNavigate } from "../../../hooks";
import { useTranslations } from "../../../util";
import useProcessForm from "../common/useProcessForm";
import {
  WorkflowCreationEditorView,
  WorkflowCreationView,
} from "../common/WorkflowView/";
import {
  useCreateWorkflowAndTasks,
  useProcessEditorState,
} from "../common/WorkflowView/hooks";
import { makeTasksFromEditorState } from "../common/WorkflowView/taskEditUtils";
import { useProcessModel } from "../tasksSlice";
import styles from "./Dialogs.module.scss";

export function ProcessCreationDialog(props: {
  initialEntities?: EntityId[];
  onClose: () => void;
}) {
  const { initialEntities, onClose } = props;

  const tr = useTranslations();
  const navigate = useNavigate();
  useTrackEvent(ANALYTICS_OPENED_WORKFLOW_CREATION);
  const analytics = useAnalytics();

  const [isFullScreen, setIsFullScreen] = useState(false);

  const { mutate: createWorkflow, isLoading: isCreatingWorkflow } =
    useCreateWorkflowAndTasks();

  const { title, modelId, setCustomTitle, setModelId, selectedModel } =
    useProcessForm();

  const { data: workflowCreator } = useCurrentUser();
  const { data: workflowModel } = useProcessModel(modelId ?? 0);

  const [processRelations, setProcessRelations] = useState<EntityId[]>(
    initialEntities ?? []
  );
  const [state, dispatch] = useProcessEditorState(workflowCreator?.id ?? 0);

  const currentTasks = useMemo(
    () => makeTasksFromEditorState(state.tasks),
    [state]
  );

  const handleAddRelation = (entity: EntityId) => {
    setProcessRelations((relations) => [...relations, entity]);
  };

  const handleDeleteRelation = (entity: EntityId) => {
    setProcessRelations((relations) =>
      relations.filter(
        (relation) =>
          !(relation.type === entity.type && relation.id === entity.id)
      )
    );
  };

  const handleResetRelation = () => {
    setProcessRelations(() => []);
  };

  const handleNext = () => {
    dispatch({
      type: "resetFromModel",
      tasks: selectedModel?.taskModels ?? [],
      title,
    });
    setIsFullScreen(true);
  };

  const handleBack = () => {
    setIsFullScreen(false);
  };

  const handleSubmit = () => {
    createWorkflow(
      {
        workflow: {
          title: state.title,
          model: modelId,
        },
        tasks: state.tasks,
        entities: processRelations,
      },
      {
        onSettled: (_, error) => {
          analytics.track(ANALYTICS_WORKFLOW_CREATED, {
            [ANALYTICS_WORKFLOW_MODEL_ID]: makeAnalyticsOptional(
              workflowModel?.id
            ),
            [ANALYTICS_WORKFLOW_MODEL_NAME]: makeAnalyticsOptional(
              workflowModel?.title
            ),
            [ANALYTICS_MUTATION_STATUS]: makeAnalyticsMutationStatus(error),
          });
        },
        onSuccess: (workflowId) => {
          navigate({
            path: "/workflows",
            panel: `workflowId-${workflowId}`,
            hash: workflowId.toString(),
          });

          onClose();
        },
      }
    );
  };

  const handleDisablePrimary = () => title === "";

  return (
    <MultistepDialog
      isOpen
      isFullPage={isFullScreen}
      title={tr.translateAsString("task.dialog.new-process.title")}
      icon="flow-linear"
      nextButtonProps={{
        onClick: handleNext,
        isDisabled: handleDisablePrimary,
      }}
      submitButtonProps={{
        onClick: handleSubmit,
        isDisabled: handleDisablePrimary,
        isLoading: isCreatingWorkflow,
      }}
      backButtonProps={{
        onClick: handleBack,
      }}
      closeButtonProps={{
        onClick: onClose,
      }}
      className={styles.dialog}
      onClose={onClose}
      panels={[
        {
          id: "creation",
          title: tr.translateAsString(
            "task.dialog.new-process.model.creation-step"
          ),
          panel: (
            <WorkflowCreationView
              modelId={modelId}
              onModelChange={setModelId}
              title={title}
              onTitleChange={setCustomTitle}
              entities={processRelations}
              onAddRelation={handleAddRelation}
              onDeleteRelation={handleDeleteRelation}
              onResetRelation={handleResetRelation}
            />
          ),
        },
        {
          id: "edition",
          title: tr.translateAsString(
            "task.dialog.new-process.model.edition-step"
          ),
          panel: (
            <WorkflowCreationEditorView
              workflowColor={selectedModel?.color ?? "black"}
              currentTitle={state.title}
              currentTasks={currentTasks}
              editorDispatch={dispatch}
            />
          ),
        },
      ]}
    />
  );
}
