import { Button, ButtonGroup, Icon, Input } from "@aletiq/design-system";
import { IconName, MaybeElement, Text } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslations } from "../../util";
import styles from "./PanelDetails.module.scss";

export default function PanelRenamableTitle(props: {
  name: string;
  icon: IconName | MaybeElement;
  isRenaming: boolean;
  resetIsRenaming: () => void;
  onSubmit: (value: string) => void;
}) {
  const { name, icon, isRenaming, resetIsRenaming, onSubmit } = props;
  const tr = useTranslations();

  const [editedName, setEditedName] = useState<string>(name);

  const handleSubmit = () => {
    onSubmit(editedName);
  };
  return (
    <div className={classNames(styles.flex, styles.fill)}>
      <Icon icon={icon} size={20} />
      {!isRenaming && (
        <Text ellipsize className={styles.h2}>
          {name}
        </Text>
      )}
      {isRenaming && (
        <>
          <Input
            fill
            autoFocus
            placeholder={tr.translateAsString("generic.label.name")}
            value={editedName}
            onChange={setEditedName}
            removeClearButton
            isDense
            className={styles.input}
          />
          <ButtonGroup isDense className={styles.button_group}>
            <Button
              intent="success"
              isDense
              isDisabled={editedName === ""}
              icon="tick"
              onClick={handleSubmit}
            />
            <Button
              isDense
              icon="cross"
              onClick={() => {
                resetIsRenaming();
                setEditedName(name);
              }}
            />
          </ButtonGroup>
        </>
      )}
    </div>
  );
}
