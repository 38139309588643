import { DocumentQueryFilterParams, DocumentQueryOrder } from "@aletiq/types";
import React, { useCallback, useEffect, useState } from "react";
import { ActionBar, DefaultFilters, Dialog, Table } from "../../../components";
import { useFilter, usePagination, useSortKey } from "../../../hooks";
import { ResolvedDocument } from "../../documents/Documents/documentTypes";
import {
  useDefaultDocumentColumnConfig,
  useDocuments,
  useDocumentTableColumns,
  useResolvedDocuments,
} from "../../documents/hooks";
import styles from "./AddDocumentsForm.module.scss";

export default function AddDocumentsForm(props: {
  title: string;
  caption: string;
  projectId?: number;
  onClose: () => void;
  onSuccess?: () => void;
  onSubmit: (selected: number[], options: { onSuccess: () => void }) => void;
  isSubmitting: boolean;
}) {
  const {
    title,
    caption,
    projectId,
    onClose,
    onSuccess = () => {},
    onSubmit,
    isSubmitting,
  } = props;

  const { filter, handleFilter } = useFilter<DocumentQueryFilterParams>({
    search: "",
    projects: projectId ? [projectId] : undefined,
  });

  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);

  const handleSubmit = () => {
    onSubmit(selectedDocuments, { onSuccess });
  };

  return (
    <Dialog
      icon="plus"
      title={title}
      isOpen
      className={styles.form}
      onClose={onClose}
      submitting={isSubmitting}
      onPrimaryClick={handleSubmit}
    >
      <div className={styles.caption}>{caption}</div>

      <ActionBar>
        <DefaultFilters
          objectType="document"
          filters={filter}
          handleFilter={handleFilter}
          autoFocusSearch
        />
      </ActionBar>
      <AddDocumentsTable filter={filter} onSelect={setSelectedDocuments} />
    </Dialog>
  );
}

function AddDocumentsTable(props: {
  filter: DocumentQueryFilterParams;
  onSelect?: (documents: number[]) => void;
}) {
  const { filter, onSelect = () => {} } = props;

  const { sortState, setSortKey } = useSortKey<DocumentQueryOrder>("name");

  const { limit, selectedPage, resetSelectedPage } = usePagination();

  useEffect(() => {
    resetSelectedPage();
  }, [filter, sortState, resetSelectedPage]);

  const { data: allDocuments } = useDocuments({
    ...filter,
    limit,
    offset: selectedPage * limit,
    order: sortState.key,
    orderDirection: sortState.direction,
  });

  const { documents } = useResolvedDocuments(allDocuments?.list);

  const { config } = useDefaultDocumentColumnConfig();
  const columns = useDocumentTableColumns(config);

  const handleSelect = useCallback(
    (documents: ResolvedDocument[]) => onSelect(documents.map((d) => d.id)),
    [onSelect]
  );

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={documents}
      searchString={filter.search}
      onSelect={handleSelect}
      sortOptions={{
        sortState,
        onSort: setSortKey,
      }}
    />
  );
}
