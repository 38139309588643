import { useMutation, useQueryClient } from "react-query";
import useApi from "../../../app/useApi";
import { processKeys } from "./queries";

export default function useArchiveProcess() {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation((process: number) => api.task.archiveProcess(process), {
    onSuccess: () => {
      queryClient.invalidateQueries(processKeys.all);
    },
  });
}
