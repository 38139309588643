import { Field } from "@aletiq/design-system";
import {
  Operation,
  OperationBill,
  OperationDocument,
  OperationTool,
} from "@aletiq/types";
import { Tab } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  Card,
  CardList,
  GenericStatusTag,
  Icon,
  SearchInput,
  Tabs,
  Tooltip,
} from "../../../components";
import { useNavigate } from "../../../hooks";
import { Diff, useTranslations } from "../../../util";
import NoDocument from "./NoDocument";
import OperationBillSelect from "./OperationBillSelect";
import styles from "./OperationDetailsEditor.module.scss";
import OperationDocumentView from "./OperationDocumentView";
import OperationItemsCount from "./OperationItemsCount";
import NoTool from "./OperationTool/NoTool";
import OperationToolView from "./OperationTool/OperationToolView";
import PageContent from "./PageContent";
export default function OperationDetails(props: {
  projectId: number;
  bill: OperationBill;
  bills: OperationBill[];
  diff: Diff<Operation>;
  documentsDiff: Record<number, Diff<OperationDocument>>;
  toolsDiff: Record<number, Diff<OperationTool>>;
  operation: Operation;
}) {
  const { projectId, bill, bills, diff, documentsDiff, toolsDiff, operation } =
    props;
  const tr = useTranslations();

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const handleShowBillOperation = (billId: number) =>
    navigate({
      path: `/project/${projectId}/operation-bills/${billId}/operations/${operation.number}`,
    });

  return (
    <PageContent>
      <Field label={tr.translate("project.operation-bill.bill-index")}>
        <OperationBillSelect
          activeBill={bill}
          bills={bills}
          onSelect={handleShowBillOperation}
        />
      </Field>
      <Field>
        <Tooltip
          position="top"
          content={tr.translate("project.operation-bill.search-documents")}
        >
          <SearchInput isDense value={search} onChange={setSearch} />
        </Tooltip>
      </Field>
      <Card
        title={`${operation.number} - ${operation.name}`}
        titleClassName={styles.card_title}
        headerActions={
          <>
            <GenericStatusTag status={diff.status} />
            <OperationItemsCount
              documentCount={operation.documents.length}
              toolCount={operation.tools.length}
            />
          </>
        }
      >
        <Field label={tr.translate("generic.label.description")}>
          {operation.description}
        </Field>
        <Tabs defaultSelectedTabId="documents" isDense>
          <Tab
            id="documents"
            title={
              <>
                <Icon icon="document" inline />
                {tr.translate("project.operation-bill.tab.documents", {
                  count: operation.documents.length,
                })}
              </>
            }
            panel={
              <CardList>
                {operation.documents.map((operationDoc) => {
                  const changeStatus =
                    documentsDiff[operationDoc.document].status;
                  return (
                    <OperationDocumentView
                      key={operationDoc.document}
                      operationDocument={operationDoc}
                      status={changeStatus}
                      search={search}
                    />
                  );
                })}
                {operation.documents.length === 0 && (
                  <NoDocument
                    projectId={projectId}
                    billId={bill.id}
                    operationNumber={operation.number}
                  />
                )}
              </CardList>
            }
          />
          <Tab
            id="tools"
            title={
              <>
                <Icon icon="wrench" inline />
                {tr.translate("project.operation-bill.tab.tools", {
                  count: operation.tools.length,
                })}
              </>
            }
            panel={
              <CardList>
                {operation.tools.map((operationTool) => {
                  const changeStatus = toolsDiff[operationTool.tool].status;
                  return (
                    <OperationToolView
                      key={operationTool.tool}
                      operationTool={operationTool}
                      status={changeStatus}
                      search={search}
                    />
                  );
                })}
                {operation.tools.length === 0 && (
                  <NoTool
                    projectId={projectId}
                    billId={bill.id}
                    operation={operation}
                  />
                )}
              </CardList>
            }
          />
        </Tabs>
      </Card>
    </PageContent>
  );
}
